
import { SortablejsModule } from 'ngx-sortablejs';
import { SearchPipePipe } from './../pipes/search-pipe.pipe';
import { PagesContainersModule } from './../containers/pages/pages.containers.module';
import { ComponentsCarouselModule } from './../components/carousel/components.carousel.module';
import { SharedModule } from './../shared/shared.module';
import { environment } from './../../environments/environment';
import { LayoutContainersModule } from './../containers/layout/layout.containers.module';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ModalModule } from 'ngx-bootstrap/modal';
import { ArchwizardModule } from 'angular-archwizard';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireAuthGuardModule } from '@angular/fire/auth-guard';
import { FormsModule as FormsModuleAngular } from '@angular/forms';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { HeadroomModule } from '@ctrl/ngx-headroom';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgSelectModule } from '@ng-select/ng-select';
import { SidePopupComponent } from '../views/app/aio/shared/side-popup/side-popup.component';
import { PaginationConfig, PaginationModule } from 'ngx-bootstrap/pagination';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { SimpleNotificationsModule } from 'angular2-notifications';
import {MatTabsModule} from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FileUploadModule } from 'ng2-file-upload';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { RatingModule } from 'ngx-bootstrap/rating';
import { ComponentsPlayerModule } from '../components/player/components.player.module';
import { LightboxModule } from 'ngx-lightbox';
import { RouterModule } from '@angular/router';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { BootstrapModule } from '../components/bootstrap/bootstrap.module';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MyPharmacyRoutingModule } from './my-pharmacy.routing';
import { MyPharmacyComponent } from './my-pharmacy/my-pharmacy.component';
import { MyPharmacyPackagesComponent } from './my-pharmacy-packages/components/my-pharmacy-packages/my-pharmacy-packages.component';
import { MyPharmacySubscriptionComponent } from './my-pharmacy-subscription/components/my-pharmacy-subscription/my-pharmacy-subscription.component';
import { CreateMyPharmacySubscriptionComponent } from './my-pharmacy-subscription/components/create-my-pharmacy-subscription/create-my-pharmacy-subscription.component';

@NgModule({
  imports: [
    CommonModule,
    MyPharmacyRoutingModule,
    LayoutContainersModule,
    MatRadioModule,
    MatTabsModule,
    TranslateModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    FormsModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    ArchwizardModule,
    SharedModule,
    NgSelectModule,
    FormsModuleAngular,
    AngularFireAuthModule,
    AngularFireAuthGuardModule,
    ComponentsCarouselModule,
    TabsModule.forRoot(),
    HeadroomModule,
    ScrollToModule.forRoot(),
    NgxDatatableModule,
    CollapseModule,
    PagesContainersModule,
    PopoverModule.forRoot(),
    TooltipModule.forRoot(),
    BsDropdownModule,
    SortablejsModule,
    PaginationModule,
    SimpleNotificationsModule.forRoot(),
    SimpleNotificationsModule,
    LayoutContainersModule,
    MatTooltipModule,
    FileUploadModule,
    AccordionModule,
    MatTreeModule,
    MatIconModule,
    MatButtonModule   ,
MatCheckboxModule ,
MatFormFieldModule,
MatInputModule    ,
MatProgressBarModule,

RatingModule.forRoot(),
ComponentsPlayerModule,
LightboxModule,
RouterModule,
PerfectScrollbarModule,
BootstrapModule,

TimepickerModule.forRoot(),
  ],
  declarations: [
    MyPharmacyComponent,
    MyPharmacyPackagesComponent,
    MyPharmacySubscriptionComponent,
    CreateMyPharmacySubscriptionComponent
  ],
  providers: [DatePipe,PaginationConfig],
//   bootstrap: [InvoiceComponent]
})
export class MyPharmacyModule { }
