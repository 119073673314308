<app-list-page-header *ngIf="tableView"[itemOptionsPerPage]="[5,10,20]"
    [itemsPerPage]="tableEngine.itemsPerPage" [selectAllState]="tableEngine.selectAllState" (addNewItem)="showModal(null,'Add')"
    [tableEngine]="this.tableEngine" (selectAllChange)="tableEngine.selectAll($event)" (changeOrderBy)="prepChangeSort($event)"
    (searchKeyUp)="tableEngine.filterDataByDateRange(this.SearchFrom,this.SearchTo,'Date')" [dateSearch]=true>
</app-list-page-header>

<div class="form-group" *ngIf="!tableView && mainForm">
    <div class="modal-body">
        <form class="md-float-material form-material" [formGroup]="mainForm">

            <div style="text-align: center;">
                <button class="btn btn-light" (click)="goBack()"
                    style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Back"  | translate}}</button>
                <button *ngIf="tableEngine.currentFormMode==='Add'"
                    [disabled]="mainForm.invalid || tableEngine.currentAction==='loading' "
                    id="smallModalSubmitButton" class="btn btn-secondary" (click)="addObject()"
                    style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Add"  | translate}}</button>
                <button *ngIf="tableEngine.currentFormMode==='Add'" class="btn btn-secondary" (click)="addAndNew()"
                    [disabled]="mainForm.invalid || tableEngine.currentAction==='loading' "
                    style="font-weight: bold; width: 250px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"AddAndNew"  | translate}}</button>
                <button *ngIf="tableEngine.currentFormMode==='Edit'"
                    [disabled]="mainForm.invalid || tableEngine.currentAction==='loading' "
                    id="smallModalSubmitButton" class="btn btn-secondary" (click)="editObject()"
                    style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Edit" | translate}}</button>
            </div>
            <div class="form-group" style="margin-top: 10px;">
                <div class="row">
                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"Name" | translate}}</label>
                        <div *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'">
                          
                            <input type="text"  name="Name" class="form-control" [ngModelOptions]="{standalone: true}"
                            formControlName="Name" style=" margin: 0px;"
                            placeholder='{{"Name" | translate}}'>
                        </div>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                        style="white-space:pre-wrap; word-wrap:break-word; overflow: auto;"
                        name="Description">{{modelObject.Name}}</label>
                        <!-- <div class="messages text-danger"
                            *ngIf=" (mainForm.get('Name').hasError('required') || mainForm.get('Name').invalid) && ( mainForm.get('Name').dirty ||  mainForm.get('Name').touched)">
                            {{"Name Required" | translate}}</div> -->
                        <span class="form-bar"></span>
                    </div>

                    <br>
                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"Purchase Date" | translate}}</label>
                        <input *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                            [type]="modelObject.Id>0?'date':'text'" onfocus="(this.type='date')"
                            onblur="if(!this.value)this.type='text'" onchange="if(!this.value)this.type='text'"
                            name="PurchaseDate" class="form-control" formControlName="PurchaseDate"
                            placeholder='{{"Purchase Date" | translate}}'>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="Date">{{modelObject.PurchaseDate | date:"dd/MM/yyyy"}}</label>
                        <!-- <div class="messages text-danger"
                            *ngIf=" (mainForm.get('PurchaseDate').hasError('required') || mainForm.get('PurchaseDate').invalid) && ( mainForm.get('PurchaseDate').dirty ||  mainForm.get('PurchaseDate').touched)">
                            {{"Purchase Date Required" | translate}}</div> -->
                        <span class="form-bar"></span>
                    </div>
                    <br>
                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"Service Start Date" | translate}}</label>
                        <input *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                            [type]="modelObject.Id>0?'date':'text'" onfocus="(this.type='date')"
                            onblur="if(!this.value)this.type='text'" onchange="if(!this.value)this.type='text'"
                            name="PurchaseDate" class="form-control" formControlName="ServiceStartDate"
                            placeholder='{{"Service Start Date" | translate}}'>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="Date">{{modelObject.ServiceStartDate | date:"dd/MM/yyyy"}}</label>
  
                        <span class="form-bar"></span>
                    </div>
                  

                    <br>

              



                </div>

                <br>

                <div class="row">
                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"Main Account" | translate}}</label>
                        <div *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'">
                            <ng-select appearance="outline" name="MainAccountId" formControlName="MainAccountId"
                                placeholder='{{"Main Account" | translate}}' #PurchaseOrderIsPostedSelect
                                labelForId="txtycd">
                                <ng-option selected desabled [value]="0">{{"Select Account" | translate}}</ng-option>
                                <ng-option *ngFor="let item of mainAccounts" [value]="item.Id">{{lang==='ar-EG'?item.NameAr:item.NameEn}}</ng-option>
                            </ng-select>
                        </div>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="IsPosted">{{getMainAccountName(modelObject.MainAccountId)
                            | translate}}</label>
                        <!-- <div class="messages text-danger"
                            *ngIf=" (mainForm.get('MainAccountId').hasError('required') || mainForm.get('MainAccountId').invalid) && ( mainForm.get('MainAccountId').dirty ||  mainForm.get('MainAccountId').touched)">
                            {{"Main Account Required" | translate}}</div> -->
                        <span class="form-bar"></span>
                    </div>
                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"Place" | translate}}</label>
                        <div *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'">
                            <ng-select appearance="outline" name="PlaceId" formControlName="PlaceId"
                                placeholder='{{"Place" | translate}}' #PlaceId
                                labelForId="txtycd">
                                <ng-option [value]="0">{{"Select Place" | translate}}</ng-option>
                                <!-- <ng-option [value]="true">{{"PurchaseOrderIsPostedTrue" | translate}}</ng-option> -->
                            </ng-select>
                        </div>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="IsPosted">{{getPlace(modelObject.PlaceId)
                            | translate}}</label>
                        <!-- <div class="messages text-danger"
                            *ngIf=" (mainForm.get('PlaceId').hasError('required') || mainForm.get('PlaceId').invalid) && ( mainForm.get('PlaceId').dirty ||  mainForm.get('PlaceId').touched)">
                            {{"Place Required" | translate}}</div> -->
                        <span class="form-bar"></span>
                    </div>
                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"Employee" | translate}}</label>
                        <div *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'">
                            <ng-select appearance="outline" name="EmployeeId" formControlName="EmployeeId"
                                placeholder='{{"Employee" | translate}}' #EmployeeId
                                labelForId="txtycd">
                                <ng-option [value]="0">{{"Select Employee" | translate}}</ng-option>
                              <ng-option *ngFor="let car of employees" [value]="car.Id">{{ lang==='ar-EG'? car.Name:car.Name}}</ng-option>

                                <!-- <ng-option [value]="true">{{"PurchaseOrderIsPostedTrue" | translate}}</ng-option> -->
                            </ng-select>
                        </div>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="IsPosted">{{getEmployee(modelObject.EmployeeId)
                            | translate}}</label>
                        <!-- <div class="messages text-danger"
                            *ngIf=" (mainForm.get('PlaceId').hasError('required') || mainForm.get('PlaceId').invalid) && ( mainForm.get('PlaceId').dirty ||  mainForm.get('PlaceId').touched)">
                            {{"Place Required" | translate}}</div> -->
                        <span class="form-bar"></span>
                    </div>
                </div>

                <br>

                <div class="row">
              
                </div>

                <br>

                <div class="row" >
                    
                <div class=" col-md-4">
                    <label class="float-label push righ">{{"Productive Age" | translate}}</label>
                    <div *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'">
                      
                        <input type="number" min="0" name="ProductiveAge" class="form-control" [ngModelOptions]="{standalone: true}"
                        formControlName="ProductiveAge" style=" margin: 0px;"
                        placeholder='{{"Productive Age" | translate}}'>
                    </div>
                    <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                    name="IsPosted">{{modelObject.ProductiveAge
                    | translate}}</label>
                    <!-- <div class="messages text-danger"
                        *ngIf=" (mainForm.get('Name').hasError('required') || mainForm.get('Name').invalid) && ( mainForm.get('Name').dirty ||  mainForm.get('Name').touched)">
                        {{"Name Required" | translate}}</div> -->
                    <span class="form-bar"></span>
                </div>
                    <div class=" col-md-8">
                        <label class="float-label push righ">{{"Description" | translate}}</label>
                        <textarea *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                            type="text" name="Description" class="form-control" formControlName="Description"
                            placeholder='{{"Description" | translate}}'></textarea>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            style="white-space:pre-wrap; word-wrap:break-word; overflow: auto;"
                            name="Description">{{modelObject.Description}}</label>
                        <span class="form-bar"></span>
                    </div>
                </div>

                <br>
                <div class="row" >
                    
                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"Purchase Amount" | translate}}</label>
                        <div class="input-group mb-3" *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'">
                          
                            <input type="number" min="0" name="PurchaseAmount" class="form-control" [ngModelOptions]="{standalone: true}"
                            formControlName="PurchaseAmount" style=" margin: 0px;"
                            placeholder='{{"Purchase Amount" | translate}}'>
                            <ng-select appearance="outline" 
                            name="CurrencyId" class="input-group-append" 
                            formControlName="CurrencyId"
                            (change)="changeCurrency()"
                            placeholder='{{"Currency" | translate}}' #EmployeeId
                            labelForId="txtycd">
                            <ng-option [value]="0">{{"Currency" | translate}}</ng-option>
                            <ng-option *ngFor="let item of currencies" [value]="item.Id">{{lang==='ar-EG'?item.Code:item.Code}}</ng-option>
                        </ng-select>    
                        </div>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                        name="IsPosted">{{modelObject.PurchaseAmount 
                        | translate}}{{getCurrency(modelObject.CurrencyId)}}</label>

                        <!-- <div class="messages text-danger"
                            *ngIf=" (mainForm.get('Name').hasError('required') || mainForm.get('Name').invalid) && ( mainForm.get('Name').dirty ||  mainForm.get('Name').touched)">
                            {{"Name Required" | translate}}</div> -->
                        <span class="form-bar"></span>
                    </div>
                    <div class=" col-md-8">
                        <label class="float-label push righ">{{"Cash Account" | translate}}</label>
                        <div *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'">
                            <ng-select appearance="outline" name="CashAccountId" formControlName="CashAccountId"
                                placeholder='{{"Cash Account" | translate}}' #EmployeeId
                                labelForId="txtycd">
                                <ng-option [value]="0">{{"Select Cash Account" | translate}}</ng-option>
                                <ng-option *ngFor="let item of subAccounts" [value]="item.Id">{{lang==='ar-EG'?item.NameAr:item.NameEn}}</ng-option>
                             </ng-select>
                        </div>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="IsPosted">{{getSubAccountName(modelObject.CashAccountId)
                            | translate}}</label>
                        <span class="form-bar"></span>
                    </div>
                    </div>
                    <br>
                    <div class="row" >
                    
                        <div class=" col-md-4">
                            <label class="float-label push righ">{{"Tax 1" | translate}}</label>
                            <div *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'">
                                <ng-select appearance="outline" name="Tax1Id" formControlName="Tax1Id"
                                    placeholder='{{"Select Tax" | translate}}' #Tax2Id
                                    labelForId="txtycd">
                                    <ng-option [value]="0">{{"Select Tax" | translate}}</ng-option>
                                    <ng-option  *ngFor="let item of taxes" [value]="item.Id">{{lang==='ar-EG'?item.NameAr:item.NameEn}}</ng-option>
                                </ng-select>
                            </div>
                            <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                                name="IsPosted">{{getTax(modelObject.Tax1Id)
                                | translate}}</label>
                            <span class="form-bar"></span>
                        </div>
                        <div class=" col-md-8">
                            <label class="float-label push righ">{{"Tax 2" | translate}}</label>
                            <div *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'">
                                <ng-select appearance="outline" name="Tax2Id" formControlName="Tax2Id"
                                    placeholder='{{"Select Tax" | translate}}' #Tax2Id
                                    labelForId="txtycd">
                                    <ng-option [value]="0">{{"Select Tax" | translate}}</ng-option>
                                    <ng-option *ngFor="let item of taxes" [value]="item.Id">{{lang==='ar-EG'?item.NameAr:item.NameEn}}</ng-option>
                                </ng-select>
                            </div>
                            <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                                name="IsPosted">{{getTax(modelObject.Tax2Id)
                                | translate}}</label>
                            <span class="form-bar"></span>
                        </div>
                        </div>
                        <br>
                        <div class="card mb-4">
                            <div class="card-body">
                              <h5 class="mb-4">{{"asset depreciation" | translate}}</h5>
                      
                                <div class="form-row">
                                    <div class=" col-md-6">
                                        <label class="float-label push righ">{{"Scrap Value" | translate}}</label>
                                            <div class="input-group mb-3" *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'">
                                                <input type="number" min="0" name="ScrapValue" class="form-control" [ngModelOptions]="{standalone: true}"
                                                formControlName="ScrapValue" style=" margin: 0px;"
                                                placeholder='{{"Scrap Value" | translate}}'>
                                                <div class="input-group-append">
                                                    <span class="input-group-text" id="basic-addon2">{{selectedCurrency}}</span>
                                                  </div>
                                            
                                            </div>
                                            <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                                            name="IsPosted">{{modelObject.ScrapValue
                                            | translate}}</label>
                                            <span class="form-bar"></span>
                                        </div>
                                        <div class=" col-md-6">
                                            <label class="float-label push righ">{{"Depreciation Method" | translate}}</label>
                                            <div *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'">
                                                <ng-select appearance="outline" name="ScrapType" formControlName="ScrapType"
                                                    placeholder='{{"Depreciation Method" | translate}}' #ScrapType
                                                    labelForId="txtycd">
                                                    <ng-option [value]="'0'">{{"Select Method" | translate}}</ng-option>
                                                    <ng-option [value]="'Fixed installment'">{{"Fixed installment" | translate}}</ng-option>
                                                    <ng-option [value]="'Declining Equity (Ratio)'">{{"Declining Equity (Ratio)" | translate}}</ng-option>
                                                    <ng-option [value]="'Production Units'">{{"Production Units" | translate}}</ng-option>
                                                    <!-- <ng-option [value]="true">{{"PurchaseOrderIsPostedTrue" | translate}}</ng-option> -->
                                                </ng-select>
                                            </div>
                                            <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                                                name="IsPosted">{{modelObject.ScrapType
                                                | translate}}</label>
                                            <span class="form-bar"></span>
                                        </div>
                                </div>
                                <div *ngIf="mainForm.get('ScrapType').value == 'Fixed installment'">
                                    <div class="form-row">
                                        <div class=" col-md-6">
                                            <label class="float-label push righ">{{"Period" | translate}}</label>
                                                <div class="input-group mb-3" *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'">
                                                    <input type="number" min="0" name="PeriodValue" class="form-control" [ngModelOptions]="{standalone: true}"
                                                    formControlName="PeriodValue" style=" margin: 0px;"
                                                    placeholder='{{"Period" | translate}}'>
                                                      <ng-select appearance="outline" class="input-group-append" name="PeriodType" formControlName="PeriodType"
                                                        placeholder='{{"Period" | translate}}' #ScrapType
                                                        labelForId="txtycd">
                                                        <ng-option [value]="'Month'">{{"Month" | translate}}</ng-option>
                                                        <ng-option [value]="'Day'">{{"Day" | translate}}</ng-option>
                                                        <ng-option [value]="'Week'">{{"Week" | translate}}</ng-option>
                                                    </ng-select>
                                                </div>
                                                <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                                                name="IsPosted">{{modelObject.PeriodValue
                                                | translate}}</label>
                                                <span class="form-bar"></span>
                                            </div>
                                            <div class=" col-md-6">
                                                <label class="float-label push righ">{{"Fixed Depreciation Value" | translate}}</label>
                                                    <div class="input-group mb-3" *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'">
                                                        <input type="number" min="0" name="FixedDepreciationValue" class="form-control" [ngModelOptions]="{standalone: true}"
                                                        formControlName="FixedDepreciationValue" style=" margin: 0px;"
                                                        placeholder='{{"Fixed Depreciation Value" | translate}}'>
                                                        <div class="input-group-append">
                                                            <span class="input-group-text" id="basic-addon2">{{selectedCurrency}}</span>
                                                          </div>
                                                    
                                                    </div>
                                                    <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                                                    name="IsPosted">{{modelObject.FixedDepreciationValue
                                                    | translate}}</label>
                                                    <span class="form-bar"></span>
                                                </div>
                                    </div>
                                    <div class="form-row">
                                        <div class=" col-md-6">
                                            <label class="float-label push righ">{{"The End Date Of Depreciation" | translate}}</label>
                                            <input *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                                                [type]="modelObject.Id>0?'date':'text'" onfocus="(this.type='date')"
                                                onblur="if(!this.value)this.type='text'" onchange="if(!this.value)this.type='text'"
                                                name="TheEndDateOfDepreciation" class="form-control" formControlName="TheEndDateOfDepreciation"
                                                placeholder='{{"The End Date Of Depreciation" | translate}}'>
                                            <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                                                name="Date">{{modelObject.TheEndDateOfDepreciation | date:"dd/MM/yyyy"}}</label>
                      
                                            <span class="form-bar"></span>
                                            </div>
                                   
                                    </div>
                                    <br>
                                </div>
                                <div *ngIf="mainForm.get('ScrapType').value == 'Declining Equity (Ratio)'">
                                    <div class="form-row">
                                        <div class=" col-md-6">
                                            <label class="float-label push righ">{{"Period" | translate}}</label>
                                                <div class="input-group mb-3" *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'">
                                                    <input type="number" min="0" name="PeriodValue" class="form-control" [ngModelOptions]="{standalone: true}"
                                                    formControlName="PeriodValue" style=" margin: 0px;"
                                                    placeholder='{{"Period" | translate}}'>
                                                      <ng-select appearance="outline" class="input-group-append" name="PeriodType" formControlName="PeriodType"
                                                        placeholder='{{"Period" | translate}}' #ScrapType
                                                        labelForId="txtycd">
                                                        <ng-option [value]="'Month'">{{"Month" | translate}}</ng-option>
                                                        <ng-option [value]="'Day'">{{"Day" | translate}}</ng-option>
                                                        <ng-option [value]="'Week'">{{"Week" | translate}}</ng-option>
                                                    </ng-select>
                                                </div>
                                                <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                                                name="IsPosted">{{modelObject.PeriodType
                                                | translate}}</label>
                                                <span class="form-bar"></span>
                                            </div>
                                            <div class=" col-md-6">
                                                <label class="float-label push righ">{{"Period Depreciation" | translate}}</label>
                                                    <div class="input-group mb-3" *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'">
                                                        <input type="number" min="0" name="PeriodDepreciation" class="form-control" [ngModelOptions]="{standalone: true}"
                                                        formControlName="PeriodDepreciation" style=" margin: 0px;"
                                                        placeholder='{{"Period Depreciation" | translate}}'>
                                                        <div class="input-group-append">
                                                            <span class="input-group-text" id="basic-addon2">%</span>
                                                          </div>
                                                    
                                                    </div>
                                                    <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                                                    name="IsPosted">{{modelObject.PeriodDepreciation
                                                    | translate}}%
                                               </label>
                                                    <span class="form-bar"></span>
                                                </div>
                                    </div>
                                    <div class="form-row">
                                        <div class=" col-md-6">
                                            <label class="float-label push righ">{{"The End Date Of Depreciation" | translate}}</label>
                                            <input *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                                                [type]="modelObject.Id>0?'date':'text'" onfocus="(this.type='date')"
                                                onblur="if(!this.value)this.type='text'" onchange="if(!this.value)this.type='text'"
                                                name="TheEndDateOfDepreciation" class="form-control" formControlName="TheEndDateOfDepreciation"
                                                placeholder='{{"The End Date Of Depreciation" | translate}}'>
                                            <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                                                name="Date">{{modelObject.TheEndDateOfDepreciation | date:"dd/MM/yyyy"}}</label>
                      
                                            <span class="form-bar"></span>
                                            </div>
                                   
                                    </div>
                                    <br>
                                </div>
                                <div>
                                    <div *ngIf="mainForm.get('ScrapType').value == 'Production Units'">
                                    <div class="form-row">
                                        <div class=" col-md-6">
                                            <label class="float-label push righ">{{"Price Per Unit" | translate}}</label>
                                                <div class="input-group mb-3" *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'">
                                                    <input type="number" min="0" name="PricePerUnit" class="form-control" [ngModelOptions]="{standalone: true}"
                                                    formControlName="PricePerUnit" style=" margin: 0px;"
                                                    placeholder='{{"Price Per Unit" | translate}}'>
                                        
                                                </div>
                                                <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                                                name="Date">{{modelObject.PricePerUnit}}</label>
                                                <span class="form-bar"></span>
                                            </div>
                                            <div class=" col-md-6">
                                                <label class="float-label push righ">{{"The Name Of The Largest Unit" | translate}}</label>
                                                    <div class="input-group mb-3" *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'">
                                                        <input type="text"  name="TheNameOfTheLargestUnit" class="form-control" [ngModelOptions]="{standalone: true}"
                                                        formControlName="TheNameOfTheLargestUnit" style=" margin: 0px;"
                                                        placeholder='{{"The Name Of The Largest Unit" | translate}}'>
                                            
                                                    </div>
                                                    <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                                                    name="Date">{{modelObject.TheNameOfTheLargestUnit}}</label>
                                                    <span class="form-bar"></span>
                                                </div>
                                 
                                    </div>
                                    <div class="form-row">
                                        <div class=" col-md-6">
                                            <label class="float-label push righ">{{"Total Parent Units" | translate}}</label>
                                                <div class="input-group mb-3" *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'">
                                                    <input type="number" min="0" name="TotalParentUnits" class="form-control" [ngModelOptions]="{standalone: true}"
                                                    formControlName="TotalParentUnits" style=" margin: 0px;"
                                                    placeholder='{{"Total Parent Units" | translate}}'>
                                        
                                                </div>
                                                <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                                                name="Date">{{modelObject.TotalParentUnits}}</label>
                                                <span class="form-bar"></span>
                                            </div>
                                        <div class=" col-md-6">
                                            <label class="float-label push righ">{{"The End Date Of Depreciation" | translate}}</label>
                                            <input *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                                                [type]="modelObject.Id>0?'date':'text'" onfocus="(this.type='date')"
                                                onblur="if(!this.value)this.type='text'" onchange="if(!this.value)this.type='text'"
                                                name="TheEndDateOfDepreciation" class="form-control" formControlName="TheEndDateOfDepreciation"
                                                placeholder='{{"The End Date Of Depreciation" | translate}}'>
                                            <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                                                name="Date">{{modelObject.TheEndDateOfDepreciation | date:"dd/MM/yyyy"}}</label>
                      
                                            <span class="form-bar"></span>
                                            </div>
                                   
                                    </div>
                                </div>
                                    <br>
                                </div>
                      
                            </div>
                          </div>

                   
            </div>
        </form>

           <div class="card">
        
            <div class="card-body">
                <label class="float-label push righ">{{"Cost Center" | translate}}</label>

                <ng-select  *ngIf="lang==='ar-EG'" (change)="selectCenter()" [(ngModel)]="costCenterId" bindValue="Id"[items]="centers"   bindLabel="NameAr">
                </ng-select>
                <ng-select *ngIf="lang!=='ar-EG'" (change)="selectCenter()" [(ngModel)]="costCenterId" bindValue="Id"[items]="centers"   bindLabel="NameEn">
              </ng-select>
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">{{'Cost Center'| translate}}</th>
                            <th scope="col">{{'Percent'| translate}}</th>
                            <th scope="col">{{'Amount'| translate}}</th>
                            <th scope="col">{{'Delete'| translate}}</th>
         
                        </tr>
                    </thead>
                    <tbody>
                        <tr  *ngFor="let cost of selectedCostCenters; let i=index;">
                            <th style="width: 10%;" scope="row">{{i + 1}}</th>
                            <td style="width: 30%;">
                                {{getCostName(cost.CostCenterId)}}
                            </td>
                    
                            <td style="width: 20%;"><input [(ngModel)]="cost.Percent" [max]="100" (change)="changePercent(cost,$event)"type="number" class="form-control"></td>
                            <td style="width: 20%;"><input [(ngModel)]="cost.Amount" [max]="mainForm.controls['PurchaseAmount'].value" (change)="changeAmount(cost,$event)"  type="number" class="form-control "></td>
                            <td style="width: 20%;">
                                <a href="javascript:" tooltip="{{'Delete'|translate}}" (click)="deleteCostCenter(cost)"><i class="simple-icon-trash"></i></a>
                            </td>
                        </tr>
                    </tbody>
                </table>
        
            </div>
        
        </div>
<br />
        <div class="card">
        
            <div class="card-body">
                
                <label for="inputEmail4">{{ 'Documents' | translate }}</label>
                <upload-assets-file *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'" [(url)]=fileUrl (urlChange)="setFileToList()" [editmode]=editEnabled></upload-assets-file>
                <table class="table">
                   <tbody>
                    <tr *ngFor="let file of files;let i = index">
                        <td>#{{i+1}}</td>
                        <td><a href="{{file.FileUrl}}" target="_blank" style="cursor: pointer;" download="">{{"Download"|translate}}</a></td>
                        <td *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'">
                            <a href="javascript:" (click)="deleteFile(file)"><i class="simple-icon-trash"></i></a>
                        </td>
                    </tr>
                   </tbody>
                </table>
            </div>
        
        </div>
    </div>

</div>

<div bsModal #delModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <span aria-hidden="true">&nbsp;</span>
            <div class="modal-header" style="display: inline; padding: 0px 0px 5px 0px;">
                <h4 id="dialog-child-name" class="modal-title pull-left" style="text-align: center; color: #DC3545;">
                    {{"ConfirmDel"+translationName |
                    translate}}</h4>
            </div>
            <div class="modal-body">
                <div class="form-group form-primary" *ngIf="tableEngine.deletionList.length < tableEngine.ogData.length 
                || tableEngine.deletionList.length === 1" style="text-align: center;">
                    <div *ngFor="let item of tableEngine.deletionList;" style="text-align: center;">
                        <label type="text">- {{translationName|translate}} {{"Number" | translate}}
                            {{lang==='ar-EG'?item.Code:item.Code}}</label>
                        <span class="form-bar"></span>
                    </div>
                </div>
                <div class="form-group form-primary" style="text-align: center;" *ngIf="tableEngine.deletionList.length > 1 && 
                    tableEngine.deletionList.length === tableEngine.ogData.length">
                    <label type="text">
                        <h1 style="font-weight: bolder; text-align: center;">{{translationName+"DeleteAll" | translate}}
                        </h1>
                    </label>
                    <span class="form-bar"></span>
                </div>

                <div style="margin-left: 5px; text-align: center; margin-top: 30px;">
                    <button class="btn btn-danger" id="smallModalDeleteButton" (click)="deleteObject()"
                        [disabled]="tableEngine.currentAction==='loading'"
                        style="font-weight: bold; width: 100%; text-align: center;">{{"Delete" | translate}}</button>
                    <button class="btn btn-light" (click)="delModal.hide()"
                        style="font-weight: bold; width: 100%; text-align: center; margin-top: 10px;">{{"Close" |
                        translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="tableView" class="FormScreen" class="tableScreen">
    <div id="divmiddle" style="display: block; margin-top: 5px;">

        <div style="width: 100%;">
            <div>
                <div class="card" style="min-width: 900px !important;">
                    <div class="card-body">
                        <table class="table table-hover" style="margin: unset !important;">
                            <thead class="no-border-top">
                                <th style="text-align: center; padding:5px;">
                                    <div class=" text-zero" style="display: inline-block;">
                                        <div class="top-right-button-container2 btn-group" dropdown>
                                            <div class="btn btn-primary pl-4 pr-0 check-button">
                                                <label class="custom-control custom-checkbox mb-0 d-inline-block"
                                                    style="width: 10px;">
                                                    <input type="checkbox" class="custom-control-input"
                                                        [checked]="tableEngine.selectAllState==='checked'"
                                                        [indeterminate]="tableEngine.selectAllState === 'indeterminate'"
                                                        (change)="tableEngine.selectAll($event)">
                                                        <span class="custom-control-label">&nbsp;</span>
                                                </label>
                                            </div>
                                            <button id="button-split" type="button" dropdownToggle style="width: 10px;"
                                                
                                                [ngClass]="(tableEngine.selected.length == 0)?
                                                'btn btn-outline-primary dropdown-toggle dropdown-toggle-split':
                                                'btn btn-primary dropdown-toggle dropdown-toggle-split'"
                                                aria-controls="dropdown-split">
                                                <span class="caret"></span>
                                            </button>
                                            <ul id="dropdown-split" *dropdownMenu
                                                class="dropdown-menu dropdown-menu-right" role="menu"
                                                aria-labelledby="button-split">
                                                <li role="menuitem"><a class="dropdown-item">
                                                        <button class="btn btn-danger d-inline-block"
                                                            style=" font-weight: bold; text-align: center; padding: 0px; width: 150px; height: 30px;"
                                                            (click)="tableEngine.showDelModal('delMulti')"
                                                            [disabled]="tableEngine.selected.length===0 || tableEngine.currentAction==='loading'">{{"DeleteSelected"
                                                            |
                                                            translate}}</button></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </th>

                                <th class="sorticon" (click)="prepChangeSort('Name')"
                                    [ngClass]="(itemOrder.label==='Name')?'sortkey sorticon':'sorticon'">
                                    <div
                                        [ngClass]="{'iconsminds-up---down': (itemOrder.label!='Name'), 
                                    'iconsminds-down': ((itemOrder.label==='Name') && tableEngine.orderAsc === true),
                                    'iconsminds-up': ((itemOrder.label==='Name') && tableEngine.orderAsc === false)}">
                                    </div>
                                </th>
                                <th class="sortOption" (click)="prepChangeSort('Name')"
                                    [ngClass]="{'sortkey': (itemOrder.label==='Name')}">
                                    {{"Name" | translate}}
                                </th>


                                <th class="sorticon" (click)="prepChangeSort('PurchaseDate')"
                                    [ngClass]="(itemOrder.label==='PurchaseDate')?'sortkey sorticon':'sorticon'">
                                    <div
                                        [ngClass]="{'iconsminds-up---down': (itemOrder.label!='PurchaseDate'), 
                                    'iconsminds-down': ((itemOrder.label==='PurchaseDate') && tableEngine.orderAsc === true),
                                    'iconsminds-up': ((itemOrder.label==='PurchaseDate') && tableEngine.orderAsc === false)}">
                                    </div>
                                </th>
                                <th class="sortOption" (click)="prepChangeSort('PurchaseDate')"
                                [ngClass]="{'sortkey': (itemOrder.label==='PurchaseDate')}">
                                {{"Purchase Date" | translate}}
                            </th>
                            <th class="sorticon" (click)="prepChangeSort('ServiceStartDate')"
                            [ngClass]="(itemOrder.label==='ServiceStartDate')?'sortkey sorticon':'sorticon'">
                            <div
                                [ngClass]="{'iconsminds-up---down': (itemOrder.label!='ServiceStartDate'), 
                            'iconsminds-down': ((itemOrder.label==='ServiceStartDate') && tableEngine.orderAsc === true),
                            'iconsminds-up': ((itemOrder.label==='ServiceStartDate') && tableEngine.orderAsc === false)}">
                            </div>
                        </th>
                        <th class="sortOption" (click)="prepChangeSort('ServiceStartDate')"
                        [ngClass]="{'sortkey': (itemOrder.label==='ServiceStartDate')}">
                        {{"Service Start Date" | translate}}
                    </th>
                      
                               
                                <th [colSpan]="3"
                                    style="text-align: center; padding: unset !important; vertical-align: middle;">

                                    <div id="displayOptions" style="
                                        display: inline; margin-top: 10px; margin-left: 5px; margin-right: 5px;">
                                        <span class="text-muted text-small" style=" width: 50px; height: 40px;">
                                            {{"ResultCount" | translate}}
                                        </span>
                                        <span dropdown class="d-inline-block position-relative">
                                            <button class="btn btn-outline-dark btn-xs dropdown-toggle" type="button"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                                dropdownToggle>
                                                {{tableEngine.itemsPerPage}}
                                            </button>
                                            <div class="dropdown-menu dropdown-menu-right dropdown-menu-sm text-right"
                                                *dropdownMenu>
                                                <li role="menuitem" *ngFor="let item of tableEngine.itemOptionsPerPage">
                                                    <a [ngClass]="item === tableEngine.itemsPerPage ? 'dropdown-item active' : 'dropdown-item'"
                                                        (click)="tableEngine.paginateData(item, 1)">{{item}}</a></li>
                                            </div>
                                        </span>
                                    </div>

                                </th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let obj of tableEngine.paginatedData;  let i=index">
                                    <td style="text-align: center; padding:5px;">
                                        <div class="itemCheck mb-0 custom-checkbox custom-control"
                                            style="display: inline-block;">
                                            <input type="checkbox" class="custom-control-input" [id]="'customCheck'+i"
                                                [checked]="tableEngine.isSelected(obj)"
                                                (change)="tableEngine.onSelect(obj)">
                                            <label class="custom-control-label" [for]="'customCheck'+i"></label>
                                        </div>
                                    </td>
                                    <td colspan="2">{{obj.Name}}</td>
                                    <td colspan="2">{{obj.PurchaseDate|date:'dd/MM/yyyy'}}</td>
                                    <td colspan="2">{{obj.ServiceStartDate|date:'dd/MM/yyyy'}}</td>
                                    
                                    <td style="text-align: center;">
                                        <i class="simple-icon-info" (click)="showModal(obj.Id, 'Info')"
                                            tooltip="{{'Info'|translate}}" placement="auto" style="font-size: 20px; font-weight: bolder; cursor: pointer; 
                                                vertical-align: middle; color: #17A2B8;"></i>
                                    </td>
                                    <td style="text-align: center;">
                                        <i class="simple-icon-pencil" (click)="showModal(obj.Id, 'Edit')"
                                            tooltip="{{'Edit'|translate}}" placement="auto" style="font-size: 20px; font-weight: bolder; cursor: pointer; 
                                                vertical-align: middle; color: #FFC107;"></i>
                                    </td>
                                    <td style="text-align: center;">
                                        <i class="simple-icon-close" (click)="tableEngine.showDelModal(obj.Id)"
                                            tooltip="{{'Delete'|translate}}" placement="auto" style="font-size: 20px; font-weight: bolder; cursor: pointer; 
                                                vertical-align: middle; color: #DC3545;"></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div id="divsearchmiddle" style="display: inline-block; width: 100%; height: 100%;">
                            <div style="text-align: center;">
                                <!-- <div class="centerside" style="display: inline-block; width: 15%; height: 100%; ">
                                    &nbsp;

                                </div> -->
                                <div class="centercenter"
                                    style="display: inline-block; width: 70%; height: 100%;  text-align: center;">

                                    <bdo dir="ltr">
                                        <pagination class="pagination-sm2" style="padding: 0px, 0px, 0px, 0px; margin: 0px, 0px, 0px, 0px;
                                        display: inline-block;" [(ngModel)]="currentPage" [boundaryLinks]="true"
                                            [totalItems]="tableEngine.filteredData.length"
                                            [itemsPerPage]="tableEngine.itemsPerPage"
                                            [customFirstTemplate]="firstTemplate" [customLastTemplate]="lastTemplate"
                                            [customPreviousTemplate]="prevTemplate" [customNextTemplate]="nextTemplate"
                                            [maxSize]="5" (pageChanged)="tableEngine.changePage($event)">
                                        </pagination>
                                    </bdo>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

            </div>
        </div>
    </div>

    <div id="divbottom" style="display: block; height: 47px;">

        <div id="divsearchright" style="float: right;  width: 33.33%; padding: 0px;">&nbsp;</div>


        <div id="divsearchleft" style="float: left; width: 33.33%; height: 100%;">&nbsp;</div>

    </div>
</div>

<div>

</div>

<ng-template #nextTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i *ngIf="lang==='en-US'" class='simple-icon-arrow-right'></i>
    <i *ngIf="lang==='ar-EG'" class='simple-icon-arrow-left'></i>
</ng-template>

<ng-template #prevTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i *ngIf="lang==='en-US'" class='simple-icon-arrow-left'></i>
    <i *ngIf="lang==='ar-EG'" class='simple-icon-arrow-right'></i>
</ng-template>

<ng-template #lastTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i class='simple-icon-control-end'></i>
</ng-template>

<ng-template #firstTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i class='simple-icon-control-start'></i>
</ng-template>


<simple-notifications></simple-notifications>