
  <div class="form-group">
    <br>
    <br>
    <div class="row">
      <div class=" col-md-4">
          <label for="inputState">{{ "Account Type" | translate }}</label>
          <select    class="form-control">
              <option >Name</option>
          </select>
      </div>
      <div class=" col-md-4">
          <label for="inputState">{{ 'Nature Account' | translate }}</label>
          <select  id="inputState" class="form-control">
              <option value="Invoive" >Invoive</option>
              <option value="Discount notice" >Discount</option>
              <option value="Add notice" >notice</option>
          </select>
      </div>
      <div class=" col-md-4">
          <label for="inputState">{{ 'Total Account' | translate }}</label>
          <select id="inputState" class="form-control">
              <option value="Discount notice" >projrect0</option>
              <option value="Discount notice" >projrect1</option>
          </select>
      </div>
  </div>
  <br>
    <div class="form-row">
        <div class="form-group col-md-6">
            <label>{{ 'NameAr' | translate }}</label>
            <input  type="text" class="form-control">
        </div>
        <div class="form-group col-md-6">
            <label for="inputPassword4">{{ 'NameEn' | translate }}</label>
            <input  type="text" id="inputPassword4" class="form-control">
        </div>
      
    </div>
    <div class="form-row">
        <div class="form-group col-md-6">
            <label >{{ 'Account Number' | translate }}</label>
            <input type="text" class="form-control">
        </div>
        <div class="form-group col-md-5">
            <label for="inputPassword4">{{ 'Level' | translate }}</label>
            <input type="text" id="inputPassword4" class="form-control" >
        </div>
     
    </div>

    <br>
    <div class="form-group row"><label for="inputEmail3" class="col-sm-2 col-form-label">{{ 'Top Account' | translate }}</label>
      <div class="col-sm-4"><input type="email" id="inputEmail3" class="form-control" >
      </div>
      <div class="col-sm-5"><input type="email" id="inputEmail3" class="form-control" >
      </div>
    </div>
    <div class="form-group row"><label for="inputEmail3" class="col-sm-2 col-form-label">{{ 'Balancing Item' | translate }}</label>
      <div class="col-sm-4"><input type="email" id="inputEmail3" class="form-control" >
      </div>
      <div class="col-sm-5"><input type="email" id="inputEmail3" class="form-control" >
      </div>
    </div>
   
</div>