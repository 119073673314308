import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ConfigPoSModel } from "./ConfigPoSModel";

@Injectable({
  providedIn: 'root'
})
export class ConfigPoSService {
    constructor(private http: HttpClient) {
  
    }

    debugData: ConfigPoSModel [] = []
    // debugData: ConfigPoSModel [] = [
    //   {
    //     Id:1, Code:1001, DefaultClientId:0, DefaultTemplateId:0, DefaultPaymentMethodId:0, EnableDiscountLimit:false, 
    //     DiscountLimit:0, EnableAutoCodes:false, StartingCode:0, ActivePaymentMethods:'1,2', ActiveItems:'1,2', EnableDigitButtons:false, 
    //     EnableItemImages:false, EnableAccountingPerInvoice:false, EnableAddingItemsViaSerial:false, EnableDelayedPayment:false, 
    //     EnableAutoSettlement:false,   DefaultEarningsAccountId:0, DefaultLossesAccountId:0,
    //   }
    // ];
    debugMode: boolean = false;

    baseUrl = environment.baseApiUrl;
    // baseUrl = 'http://localhost:60289/';
     token = localStorage.getItem("token");
     httpOptions = {
      headers: new HttpHeaders({
        // 'Content-Type': 'application/json; charset=utf-8',
        // 'Authorization': "Bearer " + token,
         "Authorization": "Bearer " + this.token,
         "Content-Type": "application/json"
      })
    };
    getFirst(): Observable<any> {
      if(this.debugMode){
        return of(this.debugData)
      }
      return this.http
        .get<any>(`${this.baseUrl}RestaurantsConfigPoS/GetFirst`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    update(payload: ConfigPoSModel): Observable<any> {
      if(this.debugMode){
        this.debugData[this.debugData.findIndex(x=>x.Id==payload.Id)] = payload;
        return of(true)
      }
      return this.http
        .post<any>(`${this.baseUrl}RestaurantsConfigPoS/Update`, payload,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    checkCashierCode(code:number): Observable<any> {
      // if(this.debugMode){
      //   return of(this.debugData.find(x=>x.Code==code)?false:true)
      // }
      return this.http
        .get<any>(`${this.baseUrl}RestaurantsConfigPoS/CheckCashierCode/${code}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    checkOrderCode(code:number): Observable<any> {
      return this.http
        .get<any>(`${this.baseUrl}RestaurantsConfigPoS/CheckOrderCode/${code}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    checkSessionCode(code:number): Observable<any> {
      return this.http
        .get<any>(`${this.baseUrl}RestaurantsConfigPoS/CheckSessionCode/${code}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    checkCashRegisterCode(code:number): Observable<any> {
      return this.http
        .get<any>(`${this.baseUrl}RestaurantsConfigPoS/CheckCashRegisterCode/${code}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    checkWorkShiftCode(code:number): Observable<any> {
      return this.http
        .get<any>(`${this.baseUrl}RestaurantsConfigPoS/CheckWorkShiftCode/${code}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    checkCategoryCode(code:number): Observable<any> {
      return this.http
        .get<any>(`${this.baseUrl}RestaurantsConfigPoS/CheckCategoryCode/${code}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    checkMenuItemCode(code:number): Observable<any> {
      return this.http
        .get<any>(`${this.baseUrl}RestaurantsConfigPoS/CheckMenuItemCode/${code}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }

    

  // getNextCode(startingCode:any, list:any) : number{
  //   let code = startingCode;
  //   for (let index = 0; index < list.length; index++) {
  //     const element = list[index];
  //     if(list.find(x=>x.Code==code)){
  //       code += 1;
  //     }
  //     else{
  //       return code;
  //     }
  //   }
  //   return code;
  // }
}
