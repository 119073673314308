import { environment } from './../../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ProductPurchasesSearchModel } from './models/product-purchases-search.model';
@Injectable({
  providedIn: 'root'
})
export class ProductPurchasesService {
    constructor(private http: HttpClient) {
  
    }

    baseUrl = environment.baseApiUrl;
     token = localStorage.getItem("token");
     httpOptions = {
      headers: new HttpHeaders({
        // 'Content-Type': 'application/json; charset=utf-8',
        // 'Authorization': "Bearer " + token,
         "Authorization": "Bearer " + this.token,
         "Content-Type": "application/json"
      })
    };
    search(search:ProductPurchasesSearchModel): Observable<any> {
      return this.http
        .post<any>(`${this.baseUrl}ProductPurchases/Search`,search,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }

}
  