export class CatchReceiptAccountsModel{
    Id?: number;
    AccountId?: number;
    CatchReceiptId?: number;
    Description?: string;
    Amount?: number;
    constructor(item ?: CatchReceiptAccountsModel){
        item = item ? item : {};
        this.Id = item.Id ? item.Id : 0;
        this.AccountId = item.AccountId ? item.AccountId : 0;
        this.CatchReceiptId = item.CatchReceiptId ? item.CatchReceiptId : 0;
        this.Amount = item.Amount ? item.Amount : 0;
        this.Description = item.Description ? item.Description : '';
    }
}