import { SalarySlipDeductionModel } from "./SalarySlipDeductionModel";
import { SalarySlipDueModel } from "./SalarySlipDueModel";

export class SalarySlipModel{
    Id?: number;
    dateOfRegistration?:Date;
    StartingDate?:Date;
    EndDate?:Date;
    Code?: number;
    EmployeeId?: number;
    CurrencyId?: number;
    TotalSalary?: number;
    TotalDiscount?: number;
    NetSalary?: number;
    Statuse?: boolean;
    SalarySlipDeductionList?:SalarySlipDeductionModel[];
    SalarySlipDueList?:SalarySlipDueModel[];
    


    constructor(item?:SalarySlipModel){
        item = item? item:{};
        this.Id = item.Id? item.Id:0;
        this.EmployeeId = item.EmployeeId? item.EmployeeId:0;
        this.CurrencyId = item.CurrencyId? item.CurrencyId:0;
        this.TotalSalary = item.TotalSalary? item.TotalSalary:0;
        this.TotalDiscount = item.TotalDiscount? item.TotalDiscount:0;
        this.NetSalary = item.NetSalary? item.NetSalary:0;
        this.dateOfRegistration = item.dateOfRegistration? item.dateOfRegistration:new Date();
        this.StartingDate = item.StartingDate? item.StartingDate:new Date();
        this.EndDate = item.EndDate? item.EndDate:new Date();
        this.Code = item.Code? item.Code:0;
        
        this.SalarySlipDeductionList = item.SalarySlipDeductionList? item.SalarySlipDeductionList:[];
        this.SalarySlipDueList = item.SalarySlipDueList? item.SalarySlipDueList:[];
        this.Statuse = item.Statuse? item.Statuse:false;

    }

}