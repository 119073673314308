<button type="button" class="btn btn-primary" (click)="showAddModal()">{{'Add New Project' | translate}}</button>
<button type="button" class="btn btn-primary" (click)="showEditModal()">{{'Edit Project' | translate}}</button>

<div class="modal fade" bsModal #staticModal="bs-modal" [config]="{backdrop: 'static'}"
     tabindex="-1" role="dialog" aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideAddModal()"
            style="margin-right: 20px; margin-top: 10px; width: 20px; display: inline;">
            <span aria-hidden="true">&times;</span>
        </button>
        <div class="modal-header" style="display: inline; padding: 0px 0px 5px 0px;">
            <h4 id="dialog-child-name" class="modal-title pull-left" style="text-align: center;">{{"Add New Project" | translate}}</h4>
        </div>
        <div class="modal-body" *ngIf="ProjectForm">
            <form class="md-float-material form-material" [formGroup]="ProjectForm">
                <div class="form-group form-primary">
                    <label class="float-label push righ">{{"Project-EN" | translate}}</label>
                    <input type="text" name="Name" class="form-control"  (change)="sanitizeEngNames()" formControlName="Name" placeholder='{{"Project-EN" | translate}}'>
                    <div class="messages text-danger"
                        *ngIf=" (ProjectForm.get('Name').hasError('required') || ProjectForm.get('Name').invalid) && ( ProjectForm.get('Name').dirty ||  ProjectForm.get('Name').touched)">
                        {{"Project-EN-Required" | translate}}</div>
                    <span class="form-bar"></span>
                </div>
                <div class="form-group form-primary">
                    <label class="float-label push righ">{{"Project-AR" | translate}}</label>
                    <input type="text" name="ArName" class="form-control" formControlName="ArName" (change)="sanitizeArNames()" placeholder='{{"Project-AR" | translate}}'>
                    <span class="form-bar"></span>
                    <div class="messages text-danger"
                    *ngIf=" (ProjectForm.get('ArName').hasError('required') || ProjectForm.get('ArName').invalid) && ( ProjectForm.get('ArName').dirty ||  ProjectForm.get('ArName').touched)">
                    {{"Project-AR-Required" | translate}}</div>
                </div>
                <!-- <div class="form-group form-primary">
                    <label class="float-label push righ">{{"ExchangeValue" | translate}}</label>
                    <span class="form-bar"></span>
                    <input type="number" min="0" name="Value" (change)="sanitizeNumbers()" class="form-control" formControlName="Value">
                    <div class="messages text-danger" 
                        *ngIf=" (ProjectForm.get('Value').hasError('required') || ProjectForm.get('Value').invalid) && ( ProjectForm.get('Value').dirty ||  ProjectForm.get('Value').touched)">
                        {{"ValueRequired" | translate}}</div>
                </div> -->
                <div style="margin-left: 5px; text-align: center; margin-top: 30px;">
                    <button class="btn btn-secondary" [disabled]="ProjectForm.invalid" (click)="addProject()"
                        style="font-weight: bold; width: 100%; text-align: center;">{{"Add" | translate}}</button>
                </div>
            </form>
        </div>
    </div>
  </div>
</div>
