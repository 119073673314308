import { DatePipe } from '@angular/common';
import { TreasuryAndBankAccountsService } from './../../treasury-and-bank-accounts.service';
import { Component, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { CurrencyService } from 'src/app/invoices/currencies/CurrencyService';
import { CurrencyModel } from 'src/app/invoices/currencies/CurrencyModel';
import { ListPageHeaderComponent } from 'src/app/containers/pages/list-page-header/list-page-header.component';
import { TreasuryAndBankAccountsModel } from '../../model/treasury-and-bank-accounts-model';
import { SidePopupComponent } from '../../../../shared/side-popup/side-popup.component';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { TableEngine } from '../../../../shared/TableEngine';
import { CreditTreasuryAndBankRolesBranchesModel } from '../../model/credit-treasury-and-bank-roles-branches-model';
import { CreditTreasuryAndBankRolesEmployeesModel } from '../../model/credit-treasury-and-bank-roles-employees';
import { CreditTreasuryAndBankRolesFunctionalRolesModel } from '../../model/credit-treasury-and-bank-roles-functional-roles-model';
import { DepositTreasuryAndBankRolesBranchesModel } from '../../model/deposit-treasury-and-bank-roles-branches-model';
import { DepositTreasuryAndBankRolesEmployeesModel } from '../../model/deposit-treasury-and-bank-roles-employees-model';
import { DepositTreasuryAndBankRolesFunctionalRolesModel } from '../../model/deposit-treasury-and-bank-roles-functional-roles-model';
import { BrandServiceService } from 'src/app/Invintory/warehouses/brand-service.service';

@Component({
    selector: 'treasury-and-bank-accounts',
    templateUrl: 'treasury-and-bank-accounts.component.html',
    styleUrls: ['treasury-and-bank-accounts.component.scss']
})
export class TreasuryAndBankAccountsComponent {
    ////////////////////////////////////////////////////////////////////////////
    //  VARYING :
    //  Modify per similar component.
    ////////////////////////////////////////////////////////////////////////////
  
    //Enable debugMode to make API modules return static data from within the service class
    debugMode: boolean = false;
  
    constructor(private formBuilder: FormBuilder, private modelService: TreasuryAndBankAccountsService, private renderer: Renderer2,
      private notifications: NotificationsService, private translate: TranslateService,
       private datePipe: DatePipe,
       private currencyService:CurrencyService,
       private branchService:BrandServiceService
    ) {
    }
    currencies:CurrencyModel[]=[];
    listHeader = new ListPageHeaderComponent();
    @ViewChild('search') search: any;
    tableEngine = new TableEngine(this.translate, this.notifications);
    currentScreen: any = 'Table';
    tableView: any = true;
  
    translationName: string = 'TreasuryAndBankAccounts'
  
    sortableKeys = [];
    searchableKeys = ['Id'];
  
    modelObject: TreasuryAndBankAccountsModel = new TreasuryAndBankAccountsModel();
  
    ogData: TreasuryAndBankAccountsModel[] = [];
    mainForm: FormGroup = new FormGroup({
      Id                      : new FormControl(),
      Type                                          : new FormControl(),
      Status                                        : new FormControl(),
      Name                                          : new FormControl(),
      Description                                   : new FormControl(0),
      AccountName                                   : new FormControl(0),
      AccountNumber                                 : new FormControl(),
      CurrencyId                                    : new FormControl(),
      DepositRolesType                              : new FormControl(),
      CreditRolesType                               : new FormControl(),
      DepositTreasuryAndBankRolesBranchesList       : new FormControl(),
      DepositTreasuryAndBankRolesEmployeesList      : new FormControl(),
      DepositTreasuryAndBankRolesFunctionalRolesList: new FormControl(),
      CreditTreasuryAndBankRolesBranchesList        : new FormControl(),
      CreditTreasuryAndBankRolesEmployeesList       : new FormControl(),
      CreditTreasuryAndBankRolesFunctionalRolesList : new FormControl(),
      SelectedDepositTreasuryAndBankRolesBranchesList       :new FormControl(),
      SelectedDepositTreasuryAndBankRolesEmployeesList      :new FormControl(),
      SelectedDepositTreasuryAndBankRolesFunctionalRolesList:new FormControl(),
      SelectedCreditTreasuryAndBankRolesBranchesList        :new FormControl(),
      SelectedCreditTreasuryAndBankRolesEmployeesList       :new FormControl(),
      SelectedCreditTreasuryAndBankRolesFunctionalRolesList :new FormControl()
    });
  
    declare lang: any;
    declare itemOrder: any;
    currentAction = '';
    savedPage = 0;
    currentPage = 1;
    deletionList: any[] = [];
    branches:any[]=[];
    employees:any[]=[];
    roles:any[]=[];
    @ViewChild('sideModalRef', { static: true }) sideModalRef: SidePopupComponent = null;
    @ViewChild('delModal', { static: true }) delModal: ModalDirective=null;
    @ViewChild('smallModal', { static: true }) smallModal: ModalDirective =null;
    formPresentationModal: any;
    ngOnInit(): void {
      this.branchService.getAll()
      .subscribe(branches=>{
        this.branches = branches;
      })
      this.currencyService.getAll()
      .subscribe(data => {
        this.currencies = data;
      })
      if (this.debugMode) {
        this.enableDebugOnAllServices();
      }
  
      this.lang = localStorage.getItem('theme_lang');
      this.itemOrder = { label: 'Name', property: 'Name' };
      this.sortableKeys = [
        { label: 'Name', property: 'Name' },
        { label: 'Type', property: 'Type' }
   
      ];
      this.tableEngine.loadTableEngine(this.ogData, this.modelService, this.modelObject,
        this.searchableKeys, this.sortableKeys, this.mainForm, this.translationName, this.itemOrder,
        this.sideModalRef, this.delModal, this.smallModal, this.lang
      )
  
      this.loadServices();
    }
  
  
    enableDebugOnAllServices() {
      //this.modelService.debugMode = true;
      // this.itemService.debugMode = true;
      //this.codingUnitService.debugMode = true;
      //this.storeDataService.debugMode = true;
      //this.supplierService.debugMode = true;
    }
  
    lastCode = 0;
    showModal(Id, action) {
      debugger;
      this.mainForm = this.createForm(this.modelObject, this.formBuilder);
  
      if (action === 'Add') {
        this.newTreasuryAndBankAccountsItemInternalId = 0;
        this.checkCode();
        this.tableEngine.showAddNewModalRef(this.createForm, this.formBuilder)
        this.currencies.forEach(element => {
          if(element.IsMain == true){
             this.modelObject.CurrencyId = element.Id;
              this.mainForm.controls['CurrencyId'].setValue(element.Id);
          }
      });
      }
      else if (action === 'Edit') {
        //this.tableEngine.showEditModalRef(Id, this.createForm, this.formBuilder)
        this.modelService.getById(Id).subscribe(
          detailedObject => {
            console.log("detailed object", detailedObject);
            
            let targetIndex = this.tableEngine.ogData.findIndex(i => i.Id === Id);
            this.tableEngine.ogData[targetIndex] = detailedObject;
            this.tableEngine.showEditModalRef(Id, this.createForm, this.formBuilder)
            
            this.lastCode = this.tableEngine.modelObject.Code
            
            this.modelObject = this.tableEngine.modelObject;
            this.mainForm = this.tableEngine.mainForm;
            this.tableView = false;
  
  
          }
        )
      }
      else if (action === 'Info') {
        console.log("entered info");
        this.modelService.getById(Id).subscribe(
          detailedObject => {
            console.log("detailed object", detailedObject);
            
            let targetIndex = this.tableEngine.ogData.findIndex(i => i.Id === Id);
            this.tableEngine.ogData[targetIndex] = detailedObject;
            this.tableEngine.showInfoModalRef(Id, this.createForm, this.formBuilder)
            
            this.lastCode = this.tableEngine.modelObject.Code
            
            this.modelObject = this.tableEngine.modelObject;
            this.mainForm = this.tableEngine.mainForm;
            this.tableView = false;
  
  
          } 
        )
      }
      this.tableView = false;
      this.modelObject = this.tableEngine.modelObject;
      this.mainForm = this.tableEngine.mainForm;
      console.log((action + " object"), this.modelObject);
  
  
      console.log("modelObject after entering edit", this.modelObject);
    }
    addAccountBanck(Id, action,type) {
        debugger;
        this.modelObject.Type = type;
        this.mainForm = this.createForm(this.modelObject, this.formBuilder);
    
        if (action === 'Add') {
          this.newTreasuryAndBankAccountsItemInternalId = 0;
          this.checkCode();
          this.tableEngine.showAddNewWithModelObjectModalRef(this.createForm, this.formBuilder,this.modelObject)
        }
        else if (action === 'Edit') {
          //this.tableEngine.showEditModalRef(Id, this.createForm, this.formBuilder)
          this.modelService.getById(Id).subscribe(
            detailedObject => {
              console.log("detailed object", detailedObject);
              
              let targetIndex = this.tableEngine.ogData.findIndex(i => i.Id === Id);
              this.tableEngine.ogData[targetIndex] = detailedObject;
              this.tableEngine.showEditModalRef(Id, this.createForm, this.formBuilder)
              
              this.lastCode = this.tableEngine.modelObject.Code
              
              this.modelObject = this.tableEngine.modelObject;
              this.mainForm = this.tableEngine.mainForm;
              this.tableView = false;
    
    
            }
          )
        }
        else if (action === 'Info') {
          console.log("entered info");
          this.modelService.getById(Id).subscribe(
            detailedObject => {
              console.log("detailed object", detailedObject);
              
              let targetIndex = this.tableEngine.ogData.findIndex(i => i.Id === Id);
              this.tableEngine.ogData[targetIndex] = detailedObject;
              this.tableEngine.showInfoModalRef(Id, this.createForm, this.formBuilder)
              
              this.lastCode = this.tableEngine.modelObject.Code
              
              this.modelObject = this.tableEngine.modelObject;
              this.mainForm = this.tableEngine.mainForm;
              this.tableView = false;
    
    
            } 
          )
        }
        this.tableView = false;
        this.modelObject = this.tableEngine.modelObject;
        this.mainForm = this.tableEngine.mainForm;
        console.log((action + " object"), this.modelObject);
    
    
        console.log("modelObject after entering edit", this.modelObject);
      }
    currentDate='';
    createForm(modelObject, formBuilder): FormGroup {
      debugger;
      return formBuilder.group({
        Id: [modelObject.Id >= 0 ? modelObject.Id : 0],
        Type                                          : [modelObject.Type                                          ],
        Status                                        : [modelObject.Status                                        ],
        Name                                          : [modelObject.Name                                          ],
        Description                                   : [modelObject.Description                                   ],
        AccountName                                   : [modelObject.AccountName                                   ],
        AccountNumber                                 : [modelObject.AccountNumber                                 ],
        CurrencyId                                    : [modelObject.CurrencyId                                    ],
        DepositRolesType                              : [modelObject.DepositRolesType                              ],
        CreditRolesType                               : [modelObject.CreditRolesType                               ],
        DepositTreasuryAndBankRolesBranchesList       : [modelObject.DepositTreasuryAndBankRolesBranchesList       ],
        DepositTreasuryAndBankRolesEmployeesList      : [modelObject.DepositTreasuryAndBankRolesEmployeesList      ],
        DepositTreasuryAndBankRolesFunctionalRolesList: [modelObject.DepositTreasuryAndBankRolesFunctionalRolesList],
        CreditTreasuryAndBankRolesBranchesList        : [modelObject.CreditTreasuryAndBankRolesBranchesList        ],
        CreditTreasuryAndBankRolesEmployeesList       : [modelObject.CreditTreasuryAndBankRolesEmployeesList       ],
        CreditTreasuryAndBankRolesFunctionalRolesList : [modelObject.CreditTreasuryAndBankRolesFunctionalRolesList ],
        SelectedDepositTreasuryAndBankRolesBranchesList       :[modelObject.SelectedDepositTreasuryAndBankRolesBranchesList       ],
        SelectedDepositTreasuryAndBankRolesEmployeesList      :[modelObject.SelectedDepositTreasuryAndBankRolesEmployeesList      ],
        SelectedDepositTreasuryAndBankRolesFunctionalRolesList:[modelObject.SelectedDepositTreasuryAndBankRolesFunctionalRolesList],
        SelectedCreditTreasuryAndBankRolesBranchesList        :[modelObject.SelectedCreditTreasuryAndBankRolesBranchesList        ],
        SelectedCreditTreasuryAndBankRolesEmployeesList       :[modelObject.SelectedCreditTreasuryAndBankRolesEmployeesList       ],
        SelectedCreditTreasuryAndBankRolesFunctionalRolesList :[modelObject.SelectedCreditTreasuryAndBankRolesFunctionalRolesList ],
      });
    }
  
    addObject() {
      debugger;
      this.currentAction = 'loading';
      let lastId = this.tableEngine.ogData.length>0?this.tableEngine.ogData[this.tableEngine.ogData.length - 1].Id:0;
      this.modelObject = new TreasuryAndBankAccountsModel();
      //this.modelObject.Id = this.debugMode ? lastId + 1 : 0;
      this.modelObject.Id = 0;
      // let date = new Date(this.mainForm.getRawValue().Date);
      this.modelObject = this.mainForm.getRawValue();
      if(this.modelObject.SelectedCreditTreasuryAndBankRolesBranchesList && this.modelObject.SelectedCreditTreasuryAndBankRolesBranchesList.length > 0){
        this.modelObject.SelectedCreditTreasuryAndBankRolesBranchesList.forEach(element => {
        if(element > 0){
          var item = new CreditTreasuryAndBankRolesBranchesModel({BranchId:element,Id:0,TreasuryAndBankAccountId:this.modelObject.Id});
          this.modelObject.CreditTreasuryAndBankRolesBranchesList.push(item);
        }
      });
    }

    if(this.modelObject.SelectedCreditTreasuryAndBankRolesEmployeesList && this.modelObject.SelectedCreditTreasuryAndBankRolesEmployeesList.length > 0){
      this.modelObject.SelectedCreditTreasuryAndBankRolesEmployeesList.forEach(element => {
        if(element > 0){
       
        var item = new CreditTreasuryAndBankRolesEmployeesModel({EmployeeId:element,Id:0,TreasuryAndBankAccountId:this.modelObject.Id});
        this.modelObject.CreditTreasuryAndBankRolesEmployeesList.push(item);
        }
      });
    }
    if(this.modelObject.SelectedCreditTreasuryAndBankRolesFunctionalRolesList && this.modelObject.SelectedCreditTreasuryAndBankRolesFunctionalRolesList.length > 0){
      this.modelObject.SelectedCreditTreasuryAndBankRolesFunctionalRolesList.forEach(element => {
        if(element > 0){
          var item = new CreditTreasuryAndBankRolesFunctionalRolesModel({RoleId:element,Id:0,TreasuryAndBankAccountId:this.modelObject.Id});
        this.modelObject.CreditTreasuryAndBankRolesFunctionalRolesList.push(item);
        }
      });
    }
    if(this.modelObject.SelectedDepositTreasuryAndBankRolesBranchesList && this.modelObject.SelectedDepositTreasuryAndBankRolesBranchesList.length > 0){
      this.modelObject.SelectedDepositTreasuryAndBankRolesBranchesList.forEach(element => {
        if(element > 0){
          var item = new DepositTreasuryAndBankRolesBranchesModel({BranchId:element,Id:0,TreasuryAndBankAccountId:this.modelObject.Id});
        this.modelObject.DepositTreasuryAndBankRolesBranchesList.push(item);
        }
      });
    }
    if(this.modelObject.SelectedDepositTreasuryAndBankRolesEmployeesList && this.modelObject.SelectedDepositTreasuryAndBankRolesEmployeesList.length > 0){
      this.modelObject.SelectedDepositTreasuryAndBankRolesEmployeesList.forEach(element => {
        if(element > 0){
          var item = new DepositTreasuryAndBankRolesEmployeesModel({EmployeeId:element,Id:0,TreasuryAndBankAccountId:this.modelObject.Id});
        this.modelObject.DepositTreasuryAndBankRolesEmployeesList.push(item);
        }
      });
    }
    if(this.modelObject.SelectedDepositTreasuryAndBankRolesFunctionalRolesList && this.modelObject.SelectedDepositTreasuryAndBankRolesFunctionalRolesList.length > 0){
      this.modelObject.SelectedDepositTreasuryAndBankRolesFunctionalRolesList.forEach(element => {
        
        if(element > 0){
          var item = new DepositTreasuryAndBankRolesFunctionalRolesModel({RoleId:element,Id:0,TreasuryAndBankAccountId:this.modelObject.Id});
        this.modelObject.DepositTreasuryAndBankRolesFunctionalRolesList.push(item);
        }
      });
    }
      console.log(this.modelObject);
      this.executeAddObject();
    }
  
    executeAddObject() {
      debugger;
      this.modelService.insert(this.modelObject).subscribe(result => {
        if (result === true || result > 0) {
          this.tableEngine.loadData();
          this.currentAction = '';
          this.tableEngine.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyAdded"));
          if (this.sideModalRef === undefined) {
            if (this.smallModal != undefined) {
              this.tableEngine.smallModal.hide();
            }
            else {
              this.tableView = this.prevAction === 'AddThenNew' ? false : true;
              
              this.prevAction = '';
            }
          }
          else {
            this.tableEngine.sideModalRef.modalRef.hide();
          }
          
          if(this.prevAction != 'AddThenNew'){
            this.goBack();
          }
        }
        else {
          this.tableEngine.toastError(this.translate.instant("Error"), this.translate.instant("FailedAdd"));
          this.currentAction = '';
        }
      })
    }
  
    editObject() {
      this.currentAction = 'loading';
      this.tableEngine.savedPage = this.tableEngine.currentPage;
      //this.calculateTotalItemValue();
      //let newString = new DatePipe('en-US').transform(this.mainForm.getRawValue().Date, 'dd/MM/yyyy');
      //console.log("newstring", newString);
      
      //let date: Date = this.getFormattedDate(new DatePipe('en-US').transform(this.mainForm.getRawValue().Date, 'dd/MM/yyyy'));
      let tempObj = new TreasuryAndBankAccountsModel();
      tempObj = this.mainForm.getRawValue();
      tempObj.Id = this.modelObject.Id;
      this.executeEditObject(tempObj);
      console.log("saved object", tempObj);
    }
  
    executeEditObject(Object) {
  
  
      this.modelService.update(Object).subscribe(result => {
        if (result === true || result > 0) {
          this.tableEngine.loadData();
          this.currentAction = '';
          this.tableEngine.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyEdited"));
  
          if (this.sideModalRef === undefined) {
            if (this.smallModal != undefined) {
              this.tableEngine.smallModal.hide();
            }
            else {
              this.tableView = true;
            }
          }
          else {
            this.tableEngine.sideModalRef.modalRef.hide();
          }
          
          this.goBack();
          
        }
        else {
          this.tableEngine.toastError(this.translate.instant("Error"), this.translate.instant("FailedEdit"));
          this.currentAction = '';
        }
      })
    }
  
    //Deletion methods:
    //Customize if needed.
    //Pass the target object as parameter if the target API can only delete singular objects and not lists.
    deleteObject() {
      this.savedPage = this.currentPage;
      //prevent multiclicks. re enable after API response
      this.currentAction = 'loading';
      this.executeDeleteObject();
    }
  
    //Same here...
    executeDeleteObject() {
      this.modelService.deleteList(this.tableEngine.deletionList).subscribe(result => {
        if (result === true || result > 0) {
          this.tableEngine.loadData();
          this.currentAction = '';
          this.tableEngine.finalizeDeletion();
        }
        else {
          this.tableEngine.toastError(this.translate.instant("Error"), this.translate.instant("FailedDelete"));
          this.currentAction = '';
        }
      })
    }
  
    
    //lastCode = 0;
    codeWasChecked = false;
    checkCode() : boolean{
      if(!(this.lastCode > 0)){
        this.codeWasChecked = false;
        console.log("bad code", this.lastCode);
        
        return this.codeWasChecked;
      }
      return this.codeWasChecked;
    }
    ////////////////////////////////////////////////////////////////////////////
    //
    //
    //
    //
    //
    ////////////////////////////////////////////////////////////////////////////
    //  EXCLUSIVE :
    //  Specifically for this component.
    ///////////////////////////////
    //Get any additional data here
    SearchFrom: Date = null ;
    SearchTo: Date= null;
  
    clearSearch() {
      this.SearchFrom= null ;
      this.SearchTo = null;
    }
  
    prevAction = '';
    addAndNew() {
      this.prevAction = 'AddThenNew';
      this.addObject();
      this.addAccountBanck(null, 'Add',this.modelObject.Type);
    }
  
  
    loadServices() {
      this.currentAction = 'loading';
      this.tableEngine.loadData();

  
  
    }
  
    TreasuryAndBankAccountsItemForm: FormGroup = new FormGroup({
      Id: new FormControl(),
      ItemId: new FormControl(),
      //UnitId: new FormControl(),
      Quantity: new FormControl(),
      UnitCost: new FormControl(),
      TotalPrice: new FormControl(),
    });
    createTreasuryAndBankAccountsItemForm(TreasuryAndBankAccountsItemObject, formBuilder): FormGroup {
      return formBuilder.group({

      });
    }
    newTreasuryAndBankAccountsItemInternalId = 0;
  
    currentTotal = 0;
 
    goBack(){
      console.log("back");
      
      this.modelObject = {};
      this.currentTotal = 0;
      this.currentAction = "";
      this.tableView = true;
    }
    ////////////////////////////////////////////////////////////////////////////
    //
    //
    //
    //
    //
    ////////////////////////////////////////////////////////////////////////////
    //  CONSTANT :
    //  Do not change.
    ///////////////////////////////
    prepChangeSort(sortKey) {
      this.SearchFrom ;
      this.SearchTo ;
      
      
      this.tableEngine.changeSort(this.tableEngine.getSort(sortKey));
      this.itemOrder = this.tableEngine.itemOrder;
    }
  
    getFormattedDate(dateString: any){
      let splitDate: number[] = dateString.toString().split("/");
  
      return new Date(splitDate[2], splitDate[1]-1, splitDate[0])
    }
    ///////////////////////////////////////////////////////
    
    displayMode = 'list';
    changeDisplayMode(mode): void {
      this.displayMode = mode;
    }
    itemsPerPageChange(perPage: number): void {
      this.tableEngine.paginateData(perPage, 1)
    }
    selectedCurrency = '';
    changeCurrency(){
      var i = this.currencies.findIndex(x=>x.Id == this.mainForm.controls['CurrencyId'].value);
      if(i>-1){
        this.selectedCurrency = this.currencies[i].Code
      }
    }
   
    getCurrency(CurrencyId){
      return this.currencies.findIndex(x=>x.Id == CurrencyId)>-1?(this.lang=='ar-EG'? this.currencies.find(x=>x.Id == CurrencyId).NameAr:this.currencies.find(x=>x.Id == CurrencyId).NameEn):""
    }
    getEmployee(EmployeeId){
      return ''// this.lang=='ar-EG'? this.mainAccounts.find(x=>x.Id == EmployeeId).NameAr:this.mainAccounts.find(x=>x.Id == accountId).NameEn
    }
    getPlace(PlaceId){
      return "" //this.lang=='ar-EG'? this..find(x=>x.Id == PlaceId).NameAr:this.mainAccounts.find(x=>x.Id == accountId).NameEn
    }
    SearchKey = ''
    AddEnabled: boolean = true;
    dateSearch: boolean = true;
    searchDateRange(): void {
        this.tableEngine.filterDataByDateRange(this.SearchFrom,this.SearchTo,this.SearchKey==''?'Date':this.SearchKey);
        console.log("datefrom header",this.SearchFrom);
      }
      searchUsingKeys(): void {
        this.tableEngine.filterData();
      }
      
      deleteSelected(): void {
        this.tableEngine.showDelModal('delMulti');
      }
      getRolesType(Type){
        if(Type == 0){
          return 'All';
        }
        else if(Type == 1){
          return 'Specific Employee';
        }
        else if(Type == 2){
          return 'Specific Job Role';
        }
        else if(Type == 3){
          return 'Specific Branch';
        }
        else{
          return;
        }
      }
      
  }
  