import { HrDashboardComponent } from './hr-dashboard/hr-dashboard.component';
import { NotesAndAttachmentComponent } from './Employees/EmployeesManagement/Actions-and-Notes/notes-and-attachment/notes-and-attachment.component';
import { AddActionsComponent } from './Employees/EmployeesManagement/Actions-and-Notes/add-actions/add-actions.component';
import { AddEmployeesManagementComponent } from './Employees/EmployeesManagement/add-employees-management/add-employees-management.component';
import { ListJobTypesComponent } from './Organization_Structure/JobTypesComponentes/list-job-types/list-job-types.component';
import { InfoJobLevelsComponent } from './Organization_Structure/JobLevelsComponentes/info-job-levels/info-job-levels.component';
import { InfoDepartmentComponent } from './Organization_Structure/Department_components/info-department/info-department.component';
import { ListDepartmentComponent } from './Organization_Structure/Department_components/list-department/list-department.component';
import { ListJobLevelsComponent } from './Organization_Structure/JobLevelsComponentes/list-job-levels/list-job-levels.component';
import { InfoJobTypesComponent } from './Organization_Structure/JobTypesComponentes/info-job-types/info-job-types.component';
import { InfoJobTitlesComponent } from './Organization_Structure/JobTitels_Componentes/info-job-titles/info-job-titles.component';
import { ListJobTitlesComponent } from './Organization_Structure/JobTitels_Componentes/list-job-titles/list-job-titles.component';
import { InfoEmployeesManagementComponent } from './Employees/EmployeesManagement/info-employees-management/info-employees-management.component';
import { ListEmployeesManagementComponent } from './Employees/EmployeesManagement/list-employees-management/list-employees-management.component';
import { ActionStatusComponent } from './Employees/EmployeesManagement/Actions-and-Notes/action-status/action-status.component';
import { AddUsersComponent } from './Employees/Users/add-users/add-users.component';
import { InfoUsersComponent } from './Employees/Users/info-users/info-users.component';
import { AllSessionsComponent } from './Attendance/attendance-session/components/all-sessions/all-sessions.component';
import { ViewSessionComponent } from './Attendance/attendance-session/components/view-session/view-session.component';
import { VacationsTypesComponent } from './Attendance/Vacations/VacationsTypes/components/vacations-types/vacations-types.component';
import { VacationsComponent } from './Attendance/Vacations/Vacation/components/vacations/vacations.component';
import { HolidaysComponent } from './Attendance/holidays/components/holidays/holidays.component';
import { CreateHolidayComponent } from './Attendance/holidays/components/create-holiday/create-holiday.component';
import { ShiftsComponent } from './Attendance/shifts/components/shifts/shifts.component';


import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { SalaryItemsComponent } from './Salaries/salary-items/components/salary-items/salary-items.component';
import { ContractsComponent } from './Salaries/contract/components/contracts/contracts.component';
import { PredecessorComponent } from './Predecessor/components/predecessor/predecessor.component';
import { AddSalariesManagerComponent } from './Salaries/add-salaries-manager/add-salaries-manager.component';
import { ListSalariesManagerComponent } from './Salaries/list-salaries-manager/list-salaries-manager.component';
import { InfoSalariesManagerComponent } from './Salaries/info-salaries-manager/info-salaries-manager.component';
import { AddSalariesPaymentComponent } from './Salaries/add-salaries-payment/add-salaries-payment.component';
import { AddSalarySlipComponent } from './Salaries/add-salary-slip/add-salary-slip.component';
import { ListSalarySlipComponent } from './Salaries/list-salary-slip/list-salary-slip.component';

const routes: Routes = [
    {
        path: '', component: HrDashboardComponent,
        
    },
   
    { path: 'Info-Department/:Id', component: InfoDepartmentComponent },
    { path: 'List-Department', component: ListDepartmentComponent },
   
    { path: 'Info-JobLevels/:Id', component: InfoJobLevelsComponent },
    { path: 'List-JobLevels', component: ListJobLevelsComponent },


    { path: 'Info-JobTypes/:Id', component: InfoJobTypesComponent },
    { path: 'List-JobTypes', component: ListJobTypesComponent },
    

    { path: 'Info-JobTitles/:Id', component: InfoJobTitlesComponent },
    { path: 'List-JobTitles', component: ListJobTitlesComponent },
    
    { path: 'Add-Employee/:Id', component: AddEmployeesManagementComponent },
    { path: 'Info-Employee/:Id', component: InfoEmployeesManagementComponent},
    { path: 'List-Employees', component: ListEmployeesManagementComponent },
    

    // { path: 'app/HRList-All-InventoryManagement', component: ListInventoryManagementComponent },
    { path: 'Add-Actions', component: AddActionsComponent },
    { path: 'Add-Attachment/:Id', component: NotesAndAttachmentComponent },
    { path: 'ActionStatus', component: ActionStatusComponent },


    
    { path: 'Add-User/:Id', component: AddUsersComponent },
    { path: 'Info-User/:Id', component: InfoUsersComponent},
    { path: 'all-sessions', component: AllSessionsComponent},
    { path: 'vew-session/:Id', component: ViewSessionComponent},
    { path: 'vacations-types', component: VacationsTypesComponent},
    { path: 'vacations', component: VacationsComponent},
    { path: 'holidays', component: HolidaysComponent},
    { path: 'shifts', component: ShiftsComponent},
    { path: 'create-holiday/:Id', component: CreateHolidayComponent},
    { path: 'salary-items', component: SalaryItemsComponent},
    { path: 'contracts', component: ContractsComponent},
    { path: 'predecessors', component: PredecessorComponent},
    { path: 'Add-Salaries-Manager/:Id', component: AddSalariesManagerComponent },
    { path: 'List-Salaries-Manager', component: ListSalariesManagerComponent },
    { path: 'Info-Salaries-Manager/:Id', component: InfoSalariesManagerComponent},


    { path: 'Add-Salaries-Payment/:Id', component: AddSalariesPaymentComponent },
    { path: 'Add-Salaries-Slip/:Id', component: AddSalarySlipComponent },
    { path: 'List-Salaries-Slip', component: ListSalarySlipComponent },
    
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class HRRoutingModule { }