<!--component html goes here -->
<h5 class="mb-4">{{ 'wizard.basic' | translate }}</h5>
<div class="card mb-5">
  <div class="card-body">
    <aw-wizard>
      <aw-wizard-step stepTitle="{{ 'wizard.step-name-1' | translate }}" style="min-height: 120px;">
        <acounting-base-data></acounting-base-data>
        <div class="text-center">
          <button type="button" class="btn btn-primary" awPreviousStep disabled>{{ 'wizard.prev' | translate}}</button>{{" "}}
          <button type="button" class="btn btn-primary" awNextStep>{{ 'wizard.next' | translate }}</button>
        </div>
      </aw-wizard-step>
      <aw-wizard-step stepTitle="{{ 'wizard.step-name-2' | translate }}" style="min-height: 120px;">
        <account-power></account-power>
        <div class="text-center">
          <button type="button" class="btn btn-primary" awPreviousStep>{{ 'wizard.prev' | translate }}</button>{{" "}}
          <button type="button" class="btn btn-primary" awNextStep>{{ 'wizard.next' | translate }}</button>
        </div>
      </aw-wizard-step>
      <aw-wizard-step stepTitle="{{ 'wizard.done' | translate }}" style="height: 120px;">
        <div class="text-center">
          <h2 class="mb-2">{{ 'wizard.content-thanks' | translate }}</h2>
          <p>{{ 'wizard.registered' | translate }}</p>
        </div>
        <div class="text-center">
          <button type="button" class="btn btn-primary" awPreviousStep>{{ 'wizard.prev' | translate }}</button>{{" "}}
          <button type="button" class="btn btn-primary" awNextStep disabled>{{ 'wizard.next' | translate }}</button>
        </div>
      </aw-wizard-step>
    </aw-wizard>
  </div>
</div>