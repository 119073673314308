import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, Renderer2, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { ModalModule } from 'ngx-bootstrap/modal';
import { Observable, of } from 'rxjs';
import { catchError, min } from 'rxjs/operators';
import { CompanyModel } from "src/app/views/companies/CompanyModel";
import { environment } from 'src/environments/environment';
import { CompanyService } from 'src/app/views/companies/CompanyService'
import { SidePopupComponent } from 'src/app/views/app/aio/shared/side-popup/side-popup.component';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { SearchPipePipe } from 'src/app/pipes/search-pipe.pipe';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss']
})
export class CompaniesComponent {
  lang:string;
  sidepopupOrientation:string;
  constructor(private formBuilder: FormBuilder, private companyService: CompanyService, private renderer: Renderer2
    ,private notifications: NotificationsService, private translate: TranslateService) {
    this.lang = localStorage.getItem('theme_lang');
    this.sidepopupOrientation = this.lang === 'ar-EG'?'right':'left';
  }
  currentFormMode:string = '';
  searchPipe = new SearchPipePipe();

  //paginatedData: CompanyModel[] = [];
  currentData: CompanyModel[] = [
    new CompanyModel({Id:0, NameEn:'Company 1', NameAr:'شركة 1', Email:'bompany1@gmail.com', Landline:'12345678901', Phone:'01123456789', Notes:'notes...1'}),
    new CompanyModel({Id:1, NameEn:'Dompany 2', NameAr:'شركة 2', Email:'aompany2@gmail.com', Landline:'22345678901', Phone:'01223456789', Notes:'notes...2\n....'}),
    new CompanyModel({Id:2, NameEn:'Eompany 3', NameAr:'شركة 3', Email:'company3@gmail.com', Landline:'32345678901', Phone:'01323456789', Notes:'notes...3\n....\n....'}),
    new CompanyModel({Id:3, NameEn:'Zompany 3', NameAr:'شركة 4', Email:'sompany4@gmail.com', Landline:'42345678901', Phone:'01423456789', Notes:'notes...4\n....\n....'}),
    new CompanyModel({Id:4, NameEn:'Company 3', NameAr:'شركة 5', Email:'jompany5@gmail.com', Landline:'52345678901', Phone:'01523456789', Notes:'notes...5\n....\n....'}),
    new CompanyModel({Id:5, NameEn:'Fompany 3', NameAr:'شركة 6', Email:'lompany6@gmail.com', Landline:'62345678901', Phone:'01623456789', Notes:'notes...6\n....\n....'}),
    new CompanyModel({Id:6, NameEn:'Company 3', NameAr:'شركة 7', Email:'xompany7@gmail.com', Landline:'72345678901', Phone:'01723456789', Notes:'notes...7\n....\n....'}),
    new CompanyModel({Id:7, NameEn:'Wompany 3', NameAr:'شركة 8', Email:'company8@gmail.com', Landline:'82345678901', Phone:'01823456789', Notes:'notes...8\n....\n....'}),
    new CompanyModel({Id:8, NameEn:'Wompany 3', NameAr:'شركة 9', Email:'company8@gmail.com', Landline:'82345678901', Phone:'01823456789', Notes:'notes...8\n....\n....'}),
    new CompanyModel({Id:9, NameEn:'Wompany 3', NameAr:'شركة 10', Email:'company8@gmail.com', Landline:'82345678901', Phone:'01823456789', Notes:'notes...8\n....\n....'}),
    new CompanyModel({Id:10, NameEn:'Wompany 3', NameAr:'شركة 11', Email:'company8@gmail.com', Landline:'82345678901', Phone:'01823456789', Notes:'notes...8\n....\n....'}),
    new CompanyModel({Id:11, NameEn:'Wompany 3', NameAr:'شركة 12', Email:'company8@gmail.com', Landline:'82345678901', Phone:'01823456789', Notes:'notes...8\n....\n....'}),
    new CompanyModel({Id:12, NameEn:'Wompany 3', NameAr:'شركة 13', Email:'company8@gmail.com', Landline:'82345678901', Phone:'01823456789', Notes:'notes...8\n....\n....'}),
    new CompanyModel({Id:13, NameEn:'Wompany 3', NameAr:'شركة 14', Email:'company8@gmail.com', Landline:'82345678901', Phone:'01823456789', Notes:'notes...8\n....\n....'}),
    new CompanyModel({Id:14, NameEn:'Wompany 3', NameAr:'شركة 15', Email:'company8@gmail.com', Landline:'82345678901', Phone:'01823456789', Notes:'notes...8\n....\n....'}),
    new CompanyModel({Id:15, NameEn:'Wompany 3', NameAr:'شركة 16', Email:'company8@gmail.com', Landline:'82345678901', Phone:'01823456789', Notes:'notes...8\n....\n....'}),
    new CompanyModel({Id:16, NameEn:'Wompany 3', NameAr:'شركة 17', Email:'company8@gmail.com', Landline:'82345678901', Phone:'01823456789', Notes:'notes...8\n....\n....'}),
    new CompanyModel({Id:17, NameEn:'Wompany 3', NameAr:'شركة 18', Email:'company8@gmail.com', Landline:'82345678901', Phone:'01823456789', Notes:'notes...8\n....\n....'}),
  
    new CompanyModel({Id:18, NameEn:'Company 1', NameAr:'شركة 1', Email:'bompany1@gmail.com', Landline:'12345678901', Phone:'01123456789', Notes:'notes...1'}),
    new CompanyModel({Id:19, NameEn:'Dompany 2', NameAr:'شركة 2', Email:'aompany2@gmail.com', Landline:'22345678901', Phone:'01223456789', Notes:'notes...2\n....'}),
    new CompanyModel({Id:20, NameEn:'Eompany 3', NameAr:'شركة 3', Email:'company3@gmail.com', Landline:'32345678901', Phone:'01323456789', Notes:'notes...3\n....\n....'}),
    new CompanyModel({Id:21, NameEn:'Zompany 3', NameAr:'شركة 4', Email:'sompany4@gmail.com', Landline:'42345678901', Phone:'01423456789', Notes:'notes...4\n....\n....'}),
    new CompanyModel({Id:22, NameEn:'Company 3', NameAr:'شركة 5', Email:'jompany5@gmail.com', Landline:'52345678901', Phone:'01523456789', Notes:'notes...5\n....\n....'}),
    new CompanyModel({Id:23, NameEn:'Fompany 3', NameAr:'شركة 6', Email:'lompany6@gmail.com', Landline:'62345678901', Phone:'01623456789', Notes:'notes...6\n....\n....'}),
    new CompanyModel({Id:24, NameEn:'Company 3', NameAr:'شركة 7', Email:'xompany7@gmail.com', Landline:'72345678901', Phone:'01723456789', Notes:'notes...7\n....\n....'}),
    new CompanyModel({Id:25, NameEn:'Wompany 3', NameAr:'شركة 8', Email:'company8@gmail.com', Landline:'82345678901', Phone:'01823456789', Notes:'notes...8\n....\n....'}),
    new CompanyModel({Id:26, NameEn:'Wompany 3', NameAr:'شركة 9', Email:'company8@gmail.com', Landline:'82345678901', Phone:'01823456789', Notes:'notes...8\n....\n....'}),
    new CompanyModel({Id:27, NameEn:'Wompany 3', NameAr:'شركة 10', Email:'company8@gmail.com', Landline:'82345678901', Phone:'01823456789', Notes:'notes...8\n....\n....'}),
    new CompanyModel({Id:28, NameEn:'Wompany 3', NameAr:'شركة 11', Email:'company8@gmail.com', Landline:'82345678901', Phone:'01823456789', Notes:'notes...8\n....\n....'}),
    new CompanyModel({Id:29, NameEn:'Wompany 3', NameAr:'شركة 12', Email:'company8@gmail.com', Landline:'82345678901', Phone:'01823456789', Notes:'notes...8\n....\n....'}),
    new CompanyModel({Id:30, NameEn:'Wompany 3', NameAr:'شركة 13', Email:'company8@gmail.com', Landline:'82345678901', Phone:'01823456789', Notes:'notes...8\n....\n....'}),
    new CompanyModel({Id:31, NameEn:'Wompany 3', NameAr:'شركة 14', Email:'company8@gmail.com', Landline:'82345678901', Phone:'01823456789', Notes:'notes...8\n....\n....'}),
    new CompanyModel({Id:32, NameEn:'Wompany 3', NameAr:'شركة 15', Email:'company8@gmail.com', Landline:'82345678901', Phone:'01823456789', Notes:'notes...8\n....\n....'}),
    new CompanyModel({Id:33, NameEn:'Wompany 3', NameAr:'شركة 16', Email:'company8@gmail.com', Landline:'82345678901', Phone:'01823456789', Notes:'notes...8\n....\n....'}),
    new CompanyModel({Id:34, NameEn:'Wompany 3', NameAr:'شركة 17', Email:'company8@gmail.com', Landline:'82345678901', Phone:'01823456789', Notes:'notes...8\n....\n....'}),
    new CompanyModel({Id:35, NameEn:'Wompany 3', NameAr:'شركة 18', Email:'company8@gmail.com', Landline:'82345678901', Phone:'01823456789', Notes:'notes...8\n....\n....'}),
  
    new CompanyModel({Id:36, NameEn:'Company 1', NameAr:'شركة 1', Email:'bompany1@gmail.com', Landline:'12345678901', Phone:'01123456789', Notes:'notes...1'}),
    new CompanyModel({Id:37, NameEn:'Dompany 2', NameAr:'شركة 2', Email:'aompany2@gmail.com', Landline:'22345678901', Phone:'01223456789', Notes:'notes...2\n....'}),
    new CompanyModel({Id:38, NameEn:'Eompany 3', NameAr:'شركة 3', Email:'company3@gmail.com', Landline:'32345678901', Phone:'01323456789', Notes:'notes...3\n....\n....'}),
    new CompanyModel({Id:39, NameEn:'Zompany 3', NameAr:'شركة 4', Email:'sompany4@gmail.com', Landline:'42345678901', Phone:'01423456789', Notes:'notes...4\n....\n....'}),
    new CompanyModel({Id:40, NameEn:'Company 3', NameAr:'شركة 5', Email:'jompany5@gmail.com', Landline:'52345678901', Phone:'01523456789', Notes:'notes...5\n....\n....'}),
    new CompanyModel({Id:41, NameEn:'Fompany 3', NameAr:'شركة 6', Email:'lompany6@gmail.com', Landline:'62345678901', Phone:'01623456789', Notes:'notes...6\n....\n....'}),
    new CompanyModel({Id:42, NameEn:'Company 3', NameAr:'شركة 7', Email:'xompany7@gmail.com', Landline:'72345678901', Phone:'01723456789', Notes:'notes...7\n....\n....'}),
    new CompanyModel({Id:43, NameEn:'Wompany 3', NameAr:'شركة 8', Email:'company8@gmail.com', Landline:'82345678901', Phone:'01823456789', Notes:'notes...8\n....\n....'}),
    new CompanyModel({Id:44, NameEn:'Wompany 3', NameAr:'شركة 9', Email:'company8@gmail.com', Landline:'82345678901', Phone:'01823456789', Notes:'notes...8\n....\n....'}),
    new CompanyModel({Id:45, NameEn:'Wompany 3', NameAr:'شركة 10', Email:'company8@gmail.com', Landline:'82345678901', Phone:'01823456789', Notes:'notes...8\n....\n....'}),
    new CompanyModel({Id:46, NameEn:'Wompany 3', NameAr:'شركة 11', Email:'company8@gmail.com', Landline:'82345678901', Phone:'01823456789', Notes:'notes...8\n....\n....'}),
    new CompanyModel({Id:47, NameEn:'Wompany 3', NameAr:'شركة 12', Email:'company8@gmail.com', Landline:'82345678901', Phone:'01823456789', Notes:'notes...8\n....\n....'}),
    new CompanyModel({Id:48, NameEn:'Wompany 3', NameAr:'شركة 13', Email:'company8@gmail.com', Landline:'82345678901', Phone:'01823456789', Notes:'notes...8\n....\n....'}),
    new CompanyModel({Id:49, NameEn:'Wompany 3', NameAr:'شركة 14', Email:'company8@gmail.com', Landline:'82345678901', Phone:'01823456789', Notes:'notes...8\n....\n....'}),
    new CompanyModel({Id:50, NameEn:'Wompany 3', NameAr:'شركة 15', Email:'company8@gmail.com', Landline:'82345678901', Phone:'01823456789', Notes:'notes...8\n....\n....'}),
    new CompanyModel({Id:51, NameEn:'Wompany 3', NameAr:'شركة 16', Email:'company8@gmail.com', Landline:'82345678901', Phone:'01823456789', Notes:'notes...8\n....\n....'}),
    new CompanyModel({Id:52, NameEn:'Wompany 3', NameAr:'شركة 17', Email:'company8@gmail.com', Landline:'82345678901', Phone:'01823456789', Notes:'notes...8\n....\n....'}),
    new CompanyModel({Id:53, NameEn:'Wompany 3', NameAr:'شركة 18', Email:'company8@gmail.com', Landline:'82345678901', Phone:'01823456789', Notes:'notes...8\n....\n....'}),
  ];
  ogData = [...this.currentData];
  filteredData = [...this.ogData];
  sortedData = [...this.filteredData];
  paginatedData = [...this.filteredData];
  lastId: number = this.ogData.length+1;
  SelectAllBox:any = false;
  
  itemsPerPage = 10;
  itemOptionsPerPage = [5, 10, 20];
  selected = [];
  selectAllState = '';
  itemOrder = 'Name';
  itemOptionsOrders = ['Name', 'Email', 'Landline'];
  displayOptionsCollapsed = false;

  
  endOfTheList = false;
  totalItem = 0;
  totalPage = 0;
  currentPage = 1;




  ngOnInit(): void {
    this.loadData();
    //this.loadData(this.itemsPerPage, this.currentPage, this.SearchTerm, this.itemOrder);
    //this.returnList(4, 2, 'Email');
  }
  // loadData(pageSize: number = 10, currentPage: number = 1, search: string = '', orderBy: string = ''): void {
  //   this.itemsPerPage = pageSize;
  //   this.currentPage = currentPage;
  //   this.SearchTerm = search;
  //   this.itemOrder = orderBy;

    // this.apiService.getProducts(pageSize, currentPage, search, orderBy).subscribe(
    //   data => {
    //     if (data.status) {
    //       //this.isLoading = false;
    //       this.paginatedData = data.data.map(x => {
    //         return {
    //           ...x,
    //           //img: x.img.replace('/img/', '/img/products/')
    //         };
    //       });
    //       this.totalItem = data.totalItem;
    //       this.totalPage = data.totalPage;
    //       this.setSelectAllState();
    //     } else {
    //       this.endOfTheList = true;
    //     }
    //   },
    //   error => {
    //     //this.isLoading = false;
    //   }
    //);
  //}

  loadData()
  {
    this.sortData();
  }
  sortData(){
    let result = [...this.ogData];
    switch(this.itemOrder)
    {
      case 'Id': {
        result = result.sort((n1,n2) => {
          if (n1.Id > n2.Id) {
              return 1;
          }
          if (n1.Id < n2.Id) {
              return -1;
          }
          return 0;
          });
        break;
      }
      case 'Email': {
        result = result.sort((n1,n2) => {
          if (n1.Email > n2.Email) {
              return 1;
          }
          if (n1.Email < n2.Email) {
              return -1;
          }
          return 0;
          });
        break;
      }
      case 'NameEn': {
        result = result.sort((n1,n2) => {
          if (n1.NameEn > n2.NameEn) {
              return 1;
          }
          if (n1.NameEn < n2.NameEn) {
              return -1;
          }
          return 0;
          });
        break;
      }
      case 'NameAr': {
        result = result.sort((n1,n2) => {
          if (n1.NameAr > n2.NameAr) {
              return 1;
          }
          if (n1.NameAr < n2.NameAr) {
              return -1;
          }
          return 0;
          });
        break;
      }
      case 'Landline': {
        result = result.sort((n1,n2) => {
          if (n1.Landline > n2.Landline) {
              return 1;
          }
          if (n1.Landline < n2.Landline) {
              return -1;
          }
          return 0;
          });
        break;
      }
    }
    this.sortedData = result;
    this.filterData();
    //this.paginateData(1, this.itemsPerPage)
    //this.paginatedData = [...this.sortedData]
  }
  filterData(){
    this.filteredData = this.searchPipe.transform(this.sortedData, this.SearchTerm, 'company');
    //console.log('ogData after filter: '+this.ogData.map(item => item.NameAr));
    // console.log(this.filteredData);
    this.paginateData(this.itemsPerPage, 1);
  }
  paginateData(functionItemsPerPage:number, functionCurrentPage:number){
    //also runs when items per is changed via dropdown list
    this.itemsPerPage = functionItemsPerPage;
    this.currentPage = functionCurrentPage;
    this.paginatedData = this.filteredData.slice((functionCurrentPage-1)*(functionItemsPerPage), (functionItemsPerPage*functionCurrentPage));
  }
  changeSort(newSort:string){
    this.itemOrder = newSort;
    this.currentPage = 1;

    //this.loadData();
    this.sortData();
    //this.filterData();
    //this.paginateData(1, this.itemsPerPage);
  }
  changePage(event: PageChangedEvent): void {
    this.currentPage = event.page;
    this.paginateData(this.itemsPerPage, this.currentPage);
    //this.loadData();
  }
  changeFilter() {
    //this.currentPage = 1;
    this.filterData();
    //this.loadData();
  }
  
  


  // pageChanged(event: any): void {
  //   //this.loadData(this.itemsPerPage, event.page, this.SearchTerm, this.itemOrder);
  // }
  // itemsPerPageChange(perPage: number): void {
  //   //this.loadData(perPage, 1, this.SearchTerm, this.itemOrder);
  // }
  // changeOrderBy(item: any): void {
  //  // this.loadData(this.itemsPerPage, 1, this.SearchTerm, item.value);
  //  //this.sortList(item)
  //  //console.log(item);
   
  // }
  // searchKeyUp(event): void {
  //   //const val = event.target.value.toLowerCase().trim();
  //   //this.loadData(this.itemsPerPage, 1, val, this.itemOrder);
  // }
  // onContextMenuClick(action: string, item: CompanyModel): void {
  //   //console.log('onContextMenuClick -> action :  ', action, ', item.title :', item.title);
  // }

  // getItems(): void {
  //   of(this.paginatedData).subscribe(data => {
  //     this.paginatedData = data;
  //   })
  // }

  selectAll($event): void {
    
    if ($event.target.checked) {
      this.selected = [...this.paginatedData];
    } else {
      this.selected = [];
    }
    this.setSelectAllState();
  }
  setSelectAllState(): void {
    if (this.selected.length >= this.paginatedData.length) {
      this.selectAllState = 'checked';
    } else {
      this.selectAllState = '';
    }
    //console.log(this.selected);
  }
  isSelected(p: CompanyModel): boolean {
    return this.selected.findIndex(x => x.Id === p.Id) > -1;
  }
  onSelect(item: CompanyModel): void {
    if (this.isSelected(item)) {
      this.selected = this.selected.filter(x => x.Id !== item.Id);
    } else {
      this.selected.push(item);
    }
    this.setSelectAllState();
    console.log(this.selected);
  }



  // ngOnInit(): void {
  //   this.loadAllData();
  // }

  
  
  
  
  // @ViewChild('addModal') delModal: ModalDirective;
  // @ViewChild('editModal') editModal: ModalDirective;
  // @ViewChild('infoModal') infoModal: ModalDirective;

  @ViewChild('companyModalRef', { static: true }) companyModalRef: SidePopupComponent;
  @ViewChild('delModal') delModal: ModalDirective;
  // @ViewChild('addNewModalRef', { static: true }) addNewModalRef: SidePopupComponent;
  // @ViewChild('editModalRef', { static: true }) editModalRef: SidePopupComponent;
  // @ViewChild('infoModalRef', { static: true }) infoModalRef: SidePopupComponent;
  showAddNewModalRef(): void {

    this.currentFormMode='Add'
    this.company = new CompanyModel;
    this.CompanyForm = this.createForm();
    //this.addNewModalRef.show();
    this.companyModalRef.show();
    //remove this when API connection is finished
    document.getElementById('ModalRefSubmitButton')?.removeAttribute('disabled');
  }
  showEditModalRef(Id: any): void {
    
    this.currentFormMode='Edit'
    this.company = this.ogData.find(i => i.Id === Id);
    of(this.company).subscribe(data => {
      this.company = data;
    })
    this.CompanyForm = this.createForm();
    //this.editModalRef.show();
    this.companyModalRef.show();
    
    //remove this when API connection is finished
    document.getElementById('ModalRefSubmitButton')?.removeAttribute('disabled');
  }
  showInfoModalRef(Id: any): void {
    this.currentFormMode='Info'
    this.company = this.ogData.find(i => i.Id === Id);
    of(this.company).subscribe(data => {
      this.company = data;
    })
    this.CompanyForm = this.createForm();
    //this.infoModalRef.show();
    this.companyModalRef.show();
  }
  
  deletionList: CompanyModel[] = [];
  showDelModal(Id: any): void {

    if(Id >= 0)
    {
      this.currentFormMode='Del'
      this.deletionList = [  this.ogData.find(i => i.Id === Id)  ];
      //console.log(this.deletionList);
      //this.infoModalRef.show();
      this.delModal.show();
    }
    else if (Id === 'delMulti')
    {
      this.currentFormMode='MultiDel'
      this.deletionList = [...this.selected]
      // this.selected.push({NameAr:"testElement"})
      // console.log(this.deletionList);
      // console.log(this.selected);
      //this.infoModalRef.show();
      this.delModal.show();
    }
    
    //remove this when API connection is finished
    document.getElementById('ModalRefDeleteButton')?.removeAttribute('disabled');
  }


  SearchTerm: any = '';
  company: CompanyModel = new CompanyModel({});
  CompanyForm: FormGroup = new FormGroup({
    Id: new FormControl(),
    NameEn: new FormControl(),
    NameAr: new FormControl(),
    Api: new FormControl(),
    CommercialRegister: new FormControl(),
    TaxCard: new FormControl(),
    AuthorizedPerson: new FormControl(),
    Email: new FormControl(),
    Landline: new FormControl(),
    Phone: new FormControl(),
    Notes: new FormControl(),
    Token: new FormControl()
  });

  // showAddModal(): void {
  //   this.company = new CompanyModel;
  //   this.CompanyForm = this.createForm();
  //   this.addModal.show();
  //   console.log(this.paginatedData);
    
  // }
  // hideAddModal(): void {
  //   this.addModal.hide();
  // }

  // showEditModal(Id: any): void {
  //   this.company = this.paginatedData.find(i => i.Id === Id);
  //   of(this.company).subscribe(data => {
  //     this.company = data;
  //   })
  //   this.CompanyForm = this.createForm();
  //   this.editModal.show();
  // }
  // hideEditModal(): void {
  //   this.company = new CompanyModel();
  //   this.editModal.hide();
  // }

  // showInfoModal(Id: any): void {
  //   this.company = this.paginatedData.find(i => i.Id === Id);
  //   of(this.company).subscribe(data => {
  //     this.company = data;
  //   })
  //   this.CompanyForm = this.createForm();
  //   this.infoModal.show();
  // }
  // hideInfoModal(): void {
  //   this.company = new CompanyModel();
  //   this.infoModal.hide();
  // }

  addCompany() {
    //prevent multiclick submits. enable again after API response.
    document.getElementById('ModalRefSubmitButton')?.setAttribute('disabled','');

    this.company = new CompanyModel();
    this.company.Id = this.lastId;
    //this.company.Id = 0;
    this.company.NameEn = this.CompanyForm.getRawValue().NameEn;
    this.company.NameAr = this.CompanyForm.getRawValue().NameAr;
    this.company.Api = this.CompanyForm.getRawValue().Api;
    this.company.CommercialRegister = this.CompanyForm.getRawValue().CommercialRegister;
    this.company.TaxCard = this.CompanyForm.getRawValue().TaxCard;
    this.company.AuthorizedPerson = this.CompanyForm.getRawValue().AuthorizedPerson;
    this.company.Email = this.CompanyForm.getRawValue().Email;
    this.company.Landline = this.CompanyForm.getRawValue().Landline;
    this.company.Phone = this.CompanyForm.getRawValue().Phone;
    this.company.Notes = this.CompanyForm.getRawValue().Notes;
    this.company.Token = this.CompanyForm.getRawValue().Token;
    this.ogData.push(this.company);
    // this.companyService.insert(this.company).subscribe(result => {
    //   if (result === true) {
    //     this.loadAllData();
    //   }
    // })

    this.lastId+=1;
    //this.addModal.hide();
    this.loadData();
    this.companyModalRef.modalRef.hide();
    this.makeToast(this.translate.instant("Success"), this.translate.instant("SuccessfullyAdded"));
  }

  editCompany() {
    //prevent multiclicks. re enable after API response
    document.getElementById('ModalRefSubmitButton')?.setAttribute('disabled','');


    let tempCompany = new CompanyModel({
      Id: this.company.Id,
      NameEn: this.CompanyForm.getRawValue().NameEn,
      NameAr: this.CompanyForm.getRawValue().NameAr,
      Api: this.CompanyForm.getRawValue().Api,
      CommercialRegister: this.CompanyForm.getRawValue().CommercialRegister,
      TaxCard: this.CompanyForm.getRawValue().TaxCard,
      AuthorizedPerson: this.CompanyForm.getRawValue().AuthorizedPerson,
      Email: this.CompanyForm.getRawValue().Email,
      Landline: this.CompanyForm.getRawValue().Landline,
      Phone: this.CompanyForm.getRawValue().Phone,
      Notes: this.CompanyForm.getRawValue().Notes,
      Token: this.CompanyForm.getRawValue().Token 
    });
    this.ogData[this.ogData.findIndex(i => i.Id === this.company.Id)] = tempCompany;
    // this.companyService.update(tempCompany).subscribe(result => {
    //   if (result === true) {
    //     this.loadAllData();
    //   }
    // })
    
    //this.hideEditModal();
    //this.editModalRef.modalRef.hide();
    this.loadData();
    this.companyModalRef.modalRef.hide();

    this.makeToast(this.translate.instant("Success"), this.translate.instant("SuccessfullyEdited"));
  }

  deleteCompany(Id: any) {
    //prevent multiclicks. re enable after API response
    document.getElementById('ModalRefDeleteButton')?.setAttribute('disabled','');

    const tempCompany = this.ogData.find(i => i.Id === Id);
    // this.companyService.delete(tempCompany).subscribe(result => {
    //   if (result === true) {
    //     this.loadAllData();
    //   }
    // })
    // console.log('paginatedData: '+this.paginatedData.map(item => item.NameAr));
    // console.log('selectedlist: '+this.selected.map(item => item.NameAr));
    // console.log('deletionlist: '+this.deletionList.map(item => item.NameAr));
    // console.log("DELEEETING");
    
    // console.log('ogData: '+this.paginatedData.map(item => item.NameAr));
    // console.log('selectedlist: '+this.selected.map(item => item.NameAr));
    // console.log('deletionlist: '+this.deletionList.map(item => item.NameAr));

    
    if(this.deletionList.length === 1)
    {
      this.makeToast(this.translate.instant("Success"), this.translate.instant("SuccessfullyDeleted") + " " +
      this.translate.instant("OneCompany"));
    }
    else if(this.deletionList.length === 2)
    {
      this.makeToast(this.translate.instant("Success"), this.translate.instant("SuccessfullyDeleted") + " " +
      this.translate.instant("TwoCompanies"));
    }
    else if(this.deletionList.length > 2 && this.deletionList.length < 11)
    {
      this.makeToast(this.translate.instant("Success"), this.translate.instant("SuccessfullyDeleted") + " " + 
      this.deletionList.length + " " + this.translate.instant("CompanyRowsPlur"));
    }
    else
    {
      this.makeToast(this.translate.instant("Success"), this.translate.instant("SuccessfullyDeleted") + " " + 
      this.deletionList.length + " " + this.translate.instant("CompanyRows"));
    }

    
    this.ogData = this.ogData.filter(item => !this.deletionList.includes(item));
    //this.selected = this.selected.filter(item => !this.deletionList.includes(item));
    this.deletionList = [];
    this.selected = [];

    this.setSelectAllState();
    console.log(this.paginatedData.length);
    console.log(this.selected.length);
    
    if(this.selectAllState === '')
    {
      this.SelectAllBox = false;
    }

    this.loadData();
    this.delModal.hide();
  }
  
  makeToast(toastHeader:string, toastBody:string): void  {
    this.notifications.create(toastHeader, toastBody,
      NotificationType.Success, { timeOut: 3000, showProgressBar: true });
  }
  deleteMultiCompany() {
    // this.companyService.delete(tempCompany).subscribe(result => {
    //   if (result === true) {
    //     this.loadAllData();
    //   }
    // })
    
    this.loadData();
    this.delModal.hide();
  }


  loadAllData() {
    this.companyService.getAll().subscribe(
      data => {
        this.ogData = data;
        this.loadSearch();
      }
    )
  }

  loadSearch() {
    const prevSearch = this.SearchTerm;
    this.SearchTerm = '';
    setTimeout(() => {
      this.SearchTerm = prevSearch;
    }, 1);
  }

  createForm(): FormGroup {
    return this.formBuilder.group({
      Id: [this.company.Id >= 0 ? this.company.Id : 0],
      NameEn: [this.company.NameEn, Validators.compose([Validators.required, Validators.minLength(2)])],
      NameAr: [this.company.NameAr, Validators.compose([Validators.required, Validators.pattern(/^[\u0621-\u064A]+([\s]*[\u0621-\u064A]+)*$/)])],
      Api: [this.company.Api, Validators.compose([Validators.required, Validators.minLength(2)])],
      CommercialRegister: [this.company.CommercialRegister, Validators.compose([Validators.required,
         Validators.pattern(/^\d+$/), Validators.minLength(5), Validators.maxLength(14)])],
      TaxCard: [this.company.TaxCard, Validators.compose([Validators.required,       Validators.pattern(/^\d{9}(\d{5})?$/)])],
      AuthorizedPerson: [this.company.AuthorizedPerson, Validators.compose([Validators.required, Validators.minLength(2)])],
      Email: [this.company.Email, Validators.compose([Validators.required, Validators.email])],
      Landline: [this.company.Landline, Validators.compose([Validators.required,  Validators.pattern(/^\d{10}$/)])],
      Phone: [this.company.Phone, Validators.compose([Validators.required,  Validators.pattern(/^\d{11}$/)])],
      Notes: [this.company.Notes],
      Token: [this.company.Token, Validators.compose([Validators.required, Validators.minLength(2)])]
    });
  }
}
