import { Component, HostBinding, Renderer2, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';

import { TableEngine } from '../../../invoices/TableEngine'
import { ModalDirective } from 'ngx-bootstrap/modal';
import { SidePopupComponent } from '../../app/aio/shared/side-popup/side-popup.component';
import { Observable, of, Subscription } from 'rxjs';

import { NgSelectComponent } from '@ng-select/ng-select';

import { DatePipe, formatDate } from '@angular/common';


import { CashierStaffService } from "./CashierStaffService";
import { CashierStaffModel } from "./CashierStaffModel";

import { BranchService } from '../../../invoices/branches/BranchService';
import { BranchModel } from '../../../invoices/branches/BranchModel';
import { CashRegisterService } from '../cashier-machines/CashRegisterService';
import { CashRegisterModel } from '../cashier-machines/CashRegisterModel';
import { StaffService } from '../../../invoices/staff/StaffService';
import { StaffModel } from '../../../invoices/staff/StaffModel';
import { ConfigPoSService } from '../config-pos-general/ConfigPoSService';
import { ConfigPoSModel } from '../config-pos-general/ConfigPoSModel';
import { ListPageHeaderComponent } from 'src/app/containers/pages/list-page-header/list-page-header.component';




@Component({
  selector: 'app-cashier-staff',
  templateUrl: './cashier-staff.component.html',
  styleUrls: ['./cashier-staff.component.scss']
})
export class RestaurantsCashierStaffComponent {
  // ////////////////////////////////////////////////////////////////////////////
  // //  VARYING :
  // //  Modify per similar component.
  // ////////////////////////////////////////////////////////////////////////////

  //Enable debugMode to make API modules return static data from within the service class
  debugMode: boolean = false;

  constructor(private formBuilder: FormBuilder, private modelService: CashierStaffService, private renderer: Renderer2,
    private notifications: NotificationsService, private translate: TranslateService,
    private branchService: BranchService, private CashRegisterService: CashRegisterService, 
    private staffService: StaffService, private configPoSService: ConfigPoSService,
    private datePipe: DatePipe,
  ) {
  }


  listHeader = new ListPageHeaderComponent();

  tableEngine = new TableEngine(this.translate, this.notifications);
  currentScreen: any = 'Table';
  tableView: any = true;

  translationName: string = 'CashierStaff'

  sortableKeys = [];
  searchableKeys = ['NameAr', 'NameEn'];

  modelObject: CashierStaffModel = new CashierStaffModel();

  ogData: CashierStaffModel[] = [];
  
  mainForm: FormGroup = new FormGroup({
    Id: new FormControl(),
    StaffId: new FormControl(),
    BranchId: new FormControl(),
  });


  declare lang: any;
  declare itemOrder: any;
  currentAction = '';
  savedPage = 0;
  currentPage = 1;
  deletionList: any[] = [];

  @ViewChild('sideModalRef', { static: true }) sideModalRef: SidePopupComponent = null;
  @ViewChild('delModal', { static: true }) delModal: ModalDirective;
  @ViewChild('smallModal', { static: true }) smallModal: ModalDirective = null;
  formPresentationModal: any;
  ngOnInit(): void {
    if (this.debugMode) {
      this.enableDebugOnAllServices();
    }

    this.lang = localStorage.getItem('theme_lang');
    this.itemOrder = { label: 'CashierStaffCode', property: 'Code' };
    this.sortableKeys = [
      { label: 'CashierStaffCode', property: 'Code' },
      { label: 'CashierStaffName', property: this.lang === 'ar-EG' ? 'NameAr' : 'NameEn' },
      { label: 'CashierStaffBranch', property: this.lang === 'ar-EG' ? 'BranchNameAr' : 'BranchNameEn' },
      { label: 'CashierStaffRole', property: 'Role' },
      { label: 'CashierStaffEmail', property: 'Email' },
      { label: 'CashierStaffLastRegister', property: 'LastRegisterCode' },
    ];


    this.tableEngine.loadTableEngine(this.ogData, this.modelService, this.modelObject,
      this.searchableKeys, this.sortableKeys, this.mainForm, this.translationName, this.itemOrder,
      this.sideModalRef, this.delModal, this.smallModal, this.lang
    )

    this.loadServices();
  }


  enableDebugOnAllServices() {
    //this.modelService.debugMode = true;
    // this.branchService.debugMode = true;
    // this.staffService.debugMode = true;
  }

  lastCode = 0;
  codeWasChecked = false;
  checkCode() : boolean{
    if(!(this.lastCode > 0)){
      this.codeWasChecked = false;
      console.log("bad code", this.lastCode);
      
      return this.codeWasChecked;
    }
    this.configPoSService.checkCashierCode(this.lastCode).subscribe(
        result => {
          this.codeWasChecked = result;
        }
      );
      console.log("code availability:",this.codeWasChecked);
      
    return this.codeWasChecked;
  }
  showModal(Id, action) {
    if (action === 'Add') {
      //this.lastCode = this.tableEngine.ogData.length>0?this.tableEngine.ogData[this.tableEngine.ogData.length - 1].Code:0;
      this.lastCode = this.configPoS.StartingCodeCashiers;
      for (let index = 0; index < this.tableEngine.ogData.length; index++) {
        if(this.tableEngine.ogData.findIndex(x=>x.Code == this.lastCode) >= 0)
        { this.lastCode++ } 
        else { break; }
      }
      this.checkCode();
      this.tableEngine.showAddNewModalRef(this.createForm, this.formBuilder);
    }
    else if (action === 'Edit') {
      this.tableEngine.showEditModalRef(Id, this.createForm, this.formBuilder);
      this.lastCode = this.tableEngine.modelObject.Code
    }
    else if (action === 'Info') {
      this.tableEngine.showInfoModalRef(Id, this.createForm, this.formBuilder);
      this.lastCode = this.tableEngine.modelObject.Code
    }
    this.tableView = false;
    this.modelObject = this.tableEngine.modelObject;
    this.mainForm = this.tableEngine.mainForm;
    console.log((action + " object"), this.modelObject);

    
    console.log("modelObject after entering edit", this.modelObject);
  }
  currentDate='';
  currentExpirationDate='';
  createForm(modelObject, formBuilder): FormGroup {
    
    return formBuilder.group({
      BranchId: [modelObject.BranchId, Validators.compose([Validators.required])],
      StaffId: [modelObject.StaffId, Validators.compose([Validators.required])],
    });
  }

  addObject() {
    this.currentAction = 'loading';

    let lastId = this.tableEngine.ogData.length>0?this.tableEngine.ogData[this.tableEngine.ogData.length - 1].Id:0;

    let branch: BranchModel = this.getBranchById(this.mainForm.getRawValue().BranchId);
    let staff: StaffModel = this.getStaffById(this.mainForm.getRawValue().StaffId);

    this.modelObject = new CashierStaffModel();
    
    this.modelObject.Id = this.debugMode ? lastId + 1 : 0;
    this.modelObject.Code = this.lastCode;
    this.modelObject.StaffId = staff.Id;
    this.modelObject.NameAr = staff.NameAr;
    this.modelObject.NameEn = staff.NameEn;
    this.modelObject.Email = staff.Email;
    this.modelObject.Role = staff.Role;
    this.modelObject.BranchId = branch.Id;
    this.modelObject.BranchNameAr = branch.NameAr;
    this.modelObject.BranchNameEn = branch.NameEn;
    
    this.executeAddObject();
  }

  executeAddObject() {
    this.modelService.insert(this.modelObject).subscribe(result => {
      if (result === true || result > 0) {
        this.tableEngine.loadData();
        this.currentAction = '';
        this.tableEngine.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyAdded"));
        if (this.sideModalRef === undefined) {
          if (this.smallModal != undefined) {
            this.tableEngine.smallModal.hide();
          }
          else {
            this.tableView = this.prevAction === 'AddThenNew' ? false : true;
            
            this.prevAction = '';
          }
        }
        else {
          this.tableEngine.sideModalRef.modalRef.hide();
        }
        
        if(this.prevAction != 'AddThenNew'){
          this.goBack();
        }
      }
      else {
        this.tableEngine.toastError(this.translate.instant("Error"), this.translate.instant("FailedAdd"));
        this.currentAction = '';
      }
    })
  }

  editObject() {
    this.currentAction = 'loading';
    this.tableEngine.savedPage = this.tableEngine.currentPage;

    let branch: BranchModel = this.getBranchById(this.mainForm.getRawValue().BranchId);
    let staff: StaffModel = this.getStaffById(this.mainForm.getRawValue().StaffId);
    // let tempObj = new CashierStaffModel({
    //   Id: this.modelObject.Id,
    //   Code: this.modelObject.Code,
    //   BranchId: branch.Id,
    //   BranchNameAr: branch.NameAr,
    //   BranchNameEn: branch.NameEn
    // });
    let tempObj = new CashierStaffModel();
    tempObj.Id =this.modelObject.Id;
    tempObj.Code = this.lastCode;
    tempObj.StaffId = staff.Id;
    tempObj.NameAr = staff.NameAr;
    tempObj.NameEn = staff.NameEn;
    tempObj.Role = staff.Role;
    tempObj.Email = staff.Email;
    tempObj.BranchId = branch.Id;
    tempObj.BranchNameAr = branch.NameAr;
    tempObj.BranchNameEn = branch.NameEn;
    tempObj.LastRegisterId = this.modelObject.LastRegisterId;
    tempObj.LastRegisterCode = this.modelObject.LastRegisterCode;
    this.executeEditObject(tempObj);
    console.log("saved object", tempObj);
    
  }

  executeEditObject(Object) {


    this.modelService.update(Object).subscribe(result => {
      if (result === true) {
        this.tableEngine.loadData();
        this.currentAction = '';
        this.tableEngine.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyEdited"));

        if (this.sideModalRef === undefined) {
          if (this.smallModal != undefined) {
            this.tableEngine.smallModal.hide();
          }
          else {
            this.tableView = true;
          }
        }
        else {
          this.tableEngine.sideModalRef.modalRef.hide();
        }
        
        this.goBack();
        
      }
      else {
        this.tableEngine.toastError(this.translate.instant("Error"), this.translate.instant("FailedEdit"));
        this.currentAction = '';
      }
    })
  }

  //Deletion methods:
  //Customize if needed.
  //Pass the target object as parameter if the target API can only delete singular objects and not lists.
  deleteObject() {
    this.savedPage = this.currentPage;
    //prevent multiclicks. re enable after API response
    this.currentAction = 'loading';
    this.executeDeleteObject();
  }

  //Same here...
  executeDeleteObject() {
    this.modelService.deleteList(this.tableEngine.deletionList).subscribe(result => {
      if (result === true) {
        this.tableEngine.loadData();
        this.currentAction = '';
        this.tableEngine.finalizeDeletion();
      }
      else {
        this.tableEngine.toastError(this.translate.instant("Error"), this.translate.instant("FailedDelete"));
        this.currentAction = '';
      }
    })
  }
  ////////////////////////////////////////////////////////////////////////////
  //
  //
  //
  //
  //
  ////////////////////////////////////////////////////////////////////////////
  //  EXCLUSIVE :
  //  Specifically for this component.
  ///////////////////////////////
  //Get any additional data here
  SearchFrom: Date = null;
  SearchTo: Date = null;

  clearSearch() {
    this.SearchFrom = null;
    this.SearchTo = null;
  }

  prevAction = '';
  addAndNew() {
    this.prevAction = 'AddThenNew';
    this.addObject();
    this.showModal(null, 'Add');
  }

  configPoS = new ConfigPoSModel();
  loadServices() {
    this.currentAction = 'loading';

    this.configPoSService.getFirst().subscribe(
      configPoSData => {
        this.configPoS = configPoSData;
    this.branchService.getAll().subscribe(
      branchData => {
        this.branchList = branchData;

        this.staffService.getAll().subscribe(
          staffData => {
            this.staffList = staffData;
            this.tableEngine.loadData();
          }
        )
      }
    )
      }
    );


  }
  
  branchList: BranchModel[] = [];
  staffList: StaffModel[] = [];
  getBranchById(Id: any): any {
    return this.branchList.find(i => i.Id === Id);
  }
  getStaffById(Id: any): any {
    console.log("staff:",Id);
    //console.log(this.staffList);
    
    //console.log(this.mainForm.getRawValue().StaffId);
    //console.log(this.mainForm.getRawValue().BranchId);
    
    
    
    return this.staffList.find(i => i.Id === Id);
  }


  goBack(){
    console.log("back");
    
    this.modelObject = {};
    this.currentAction = "";
    this.tableView = true;

    
    console.log(this.modelObject);
    console.log(this.lastCode);
  }

  ////////////////////////////////////////////////////////////////////////////
  //
  //
  //
  //
  //
  ////////////////////////////////////////////////////////////////////////////
  //  CONSTANT :
  //  Do not change.
  ///////////////////////////////
  prepChangeSort(sortKey) {
    this.SearchFrom = null;
    this.SearchTo = null;
    
    
    this.tableEngine.changeSort(this.tableEngine.getSort(sortKey));
    this.itemOrder = this.tableEngine.itemOrder;
  }

  getFormattedDate(dateString: any){
    let splitDate: number[] = dateString.toString().split("/");

    return new Date(splitDate[2], splitDate[1]-1, splitDate[0])
  }
  // ///////////////////////////////////////////////////////
  
  displayMode = 'list';
  changeDisplayMode(mode): void {
    this.displayMode = mode;
  }
  itemsPerPageChange(perPage: number): void {
    this.tableEngine.paginateData(perPage, 1)
  }
}
