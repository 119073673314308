import { Component, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { NotificationType, NotificationsService } from "angular2-notifications";
import { ModalDirective } from "ngx-bootstrap/modal";
import { TableEngine } from "src/app/invoices/TableEngine";
import { SidePopupComponent } from "src/app/views/invoice/side-popup/side-popup.component";
import {EmployeesManagementModel} from '../../EmployeesManagement/EmployeesManagementModel';
import{UsersService} from '../users.service';

@Component({
    // moduleId: module.id,
    selector: 'info-users',
    templateUrl: 'info-users.component.html',
    styleUrls: ['info-users.component.scss']
})
export class InfoUsersComponent {
    Id = 0;

    constructor(
      public router: Router,
      private fb: FormBuilder,
      private modelService:UsersService ,
      private notifications: NotificationsService,
      private translate: TranslateService,
      private route: ActivatedRoute
    ) {
      this.Id = +route.snapshot.paramMap.get("Id");
    }
  
    tableEngine = new TableEngine(this.translate, this.notifications);
    currentScreen: any = "Table";
    translationName: string = "Item";
    sortableKeys = [];
    searchableKeys = ["Name", "Address"];
    tableView: any = true;
  
    EmployeesManagementModel:EmployeesManagementModel[]=[];
  
    // WarehouseData: WarehouseModel[] = [];
  
    modelObject: EmployeesManagementModel = new EmployeesManagementModel();
    Form: FormGroup;
    declare lang: any;
    declare itemOrder: any;
    currentAction = "";
    savedPage = 0;
    currentPage = 1;
    deletionList: any[] = [];
    prevAction = "";
    addToListId = 0;
    ItemsBalanceHeaderId = 0;
    ItemBalanceId = 0;
    currentFormMode: string = "";
  
    ogData: EmployeesManagementModel[] = [];
    mainForm: FormGroup = new FormGroup({
        Id: new FormControl(),
        Name: new FormControl(),
        Nickname: new FormControl(),
        MiddleName: new FormControl(),
        EmployeeType: new FormControl(),
        EMail: new FormControl(),
        BirthDate: new FormControl(),
        Type: new FormControl(),
        Country: new FormControl(),
        MobileNumber: new FormControl(),
        GroundPhoneNumber: new FormControl(),
        PersonalEmail: new FormControl(),
        FirstAddress: new FormControl(),
        SecondAddress: new FormControl(),
        City: new FormControl(),
        Governorate: new FormControl(),
        PostalCode: new FormControl(),
        FirstPermanentAddress: new FormControl(),
        SecondPermanentAddress: new FormControl(),
        PermanentCity: new FormControl(),
        PermanentGovernorate: new FormControl(),
        PermanentPostalCode: new FormControl(),
        DepartmentId: new FormControl(),
        JobLevelId: new FormControl(),
        JoiningDate: new FormControl(),
        JobTitelId: new FormControl(),
        BranchId: new FormControl(),
        Month: new FormControl(),
        Day: new FormControl(),
        AttendanceShiftId: new FormControl(),
        VacationPolicyId: new FormControl(),
        AttendanceDeterminantsId: new FormControl(),
        Status: new FormControl(),
        IsAccessToTheSystem: new FormControl(),
        Notes: new FormControl(),
        JobTypeId: new FormControl(),
        JobLevelName: new FormControl(),
        DepartmentName: new FormControl(),
        JobTitelName: new FormControl(),
        BranchName: new FormControl(),
        JobTypeName: new FormControl(),

        Code: new FormControl(),
        LanguageId: new FormControl(),
        JobRoleId: new FormControl(),
        SendLoginDataByMail: new FormControl(),
    });  
  
    @ViewChild("sideModalRef", { static: true })
    sideModalRef: SidePopupComponent = null;
    @ViewChild("delModal", { static: true }) delModal: ModalDirective;
    @ViewChild("smallModal", { static: true }) smallModal: ModalDirective = null;
    formPresentationModal: any;
    ngOnInit(): void {
      // this.getAllCountryCode();
  
      this.lang = localStorage.getItem("theme_lang");
      this.itemOrder = {
        label: "Name",
        property: this.lang === "ar-EG" ? "Name" : "Name",
      };
      this.sortableKeys = [
        // { label: 'Date', property: 'Date' },
        { label: 'Name',property:  this.lang === 'ar-EG' ? 'Name' : 'Name'},
        { label: 'limitation', property: 'limitation' },
        
      ];
  
      this.tableEngine.loadTableEngine(
        this.ogData,
        this.modelService,
        this.modelObject,
        this.searchableKeys,
        this.sortableKeys,
        this.mainForm,
        this.translationName,
        this.itemOrder,
        this.sideModalRef,
        this.delModal,
        this.smallModal,
        this.lang
      );

      // this.mainForm = this.createForm(this.modelObject);
  
      // this.loadServices();
      // this.modelService.getById(this.Id).subscribe((data) => {
      //   this.modelObject = data;
      // });     
     
          if(this.Id > 0)
          {   debugger;                            
                  this.modelService.getById(this.Id).subscribe(data=>{                        
                  this.modelObject = data;
                  this.mainForm = this.createForm(this.modelObject);
              });
                 this.mainForm = this.createForm(this.modelObject);
          }
          else{
             this.mainForm = this.createForm(this.modelObject);       
              }
    }
  

    createForm(modelObject): FormGroup {
      debugger;
      return this.fb.group({
        Id: [modelObject.Id >= 0 ? modelObject.Id : 0],
        Name: [modelObject.Name, Validators.compose([Validators.required])],
        MiddleName: [ modelObject.MiddleName],
        Nickname: [modelObject.Nickname],
        EmployeeType: [modelObject.EmployeeType],
        EMail: [modelObject.EMail],
        BirthDate: [modelObject.BirthDate, Validators.compose([Validators.required])],
        Type: [modelObject.Type],
        Country: [modelObject.Country, Validators.compose([Validators.required])],
        MobileNumber: [modelObject.MobileNumber],
        GroundPhoneNumber: [modelObject.GroundPhoneNumber],
        PersonalEmail: [modelObject.PersonalEmail],
        FirstAddress: [modelObject.FirstAddress],
        SecondAddress: [modelObject.SecondAddress],
        City: [modelObject.City],
        Governorate: [modelObject.Governorate],
        PostalCode: [modelObject.PostalCode],
        FirstPermanentAddress: [modelObject.FirstPermanentAddress],
        SecondPermanentAddress: [modelObject.SecondPermanentAddress],
        PermanentCity: [modelObject.PermanentCity],
        PermanentGovernorate: [modelObject.PermanentGovernorate],
        PermanentPostalCode: [modelObject.PermanentPostalCode],
        DepartmentId: [modelObject.DepartmentId],
        JoiningDate: [modelObject.JoiningDate, Validators.compose([Validators.required])],
        JobTitelId: [modelObject.JobTitelId],
        BranchId: [modelObject.BranchId, Validators.compose([Validators.required])],
        Month: [modelObject.Month],
        Day: [modelObject.Day],
        AttendanceShiftId: [modelObject.AttendanceShiftId],
        VacationPolicyId: [modelObject.VacationPolicyId],
        AttendanceDeterminantsId: [modelObject.AttendanceDeterminantsId],
        Status: [modelObject.Status, Validators.compose([Validators.required])],
        IsAccessToTheSystem: [modelObject.IsAccessToTheSystem],
        Notes: [modelObject.Notes],
        JobLevelId: [modelObject.JobLevelId],
        JobTypeId: [modelObject.JobTypeId],
        Code: [modelObject.Code],
        LanguageId: [modelObject.LanguageId],
        JobRoleId: [modelObject.JobRoleId],
        SendLoginDataByMail: [modelObject.SendLoginDataByMail],

    
        });
    }


    showModal(Id, action) {
  
        if (action === 'Add') {          
         debugger;
          this.router.navigate(['app/HR/Add-Employee/',0])   
        }
        else if (action === 'Edit') {
          debugger;
          this.router.navigate(['app/HR/Add-Employee/',Id])
      
          
        }
        else if (action === 'Info') {
         debugger;
          
          this.router.navigate(['app/HR/Info-Employee/',Id])
          
         
        }
        //this.mainForm.get('IsActive').setValue(true);
         this.modelObject = this.tableEngine.modelObject;
         this.mainForm = this.tableEngine.mainForm;
      
      }
      showDelModal(Id: any) {
        
          this.currentFormMode = 'Del'
          this.modelObject = this.ogData.find(i => i.Id === Id);
          this.deletionList = [this.ogData.find(i => i.Id === Id)];
          this.delModal.show();
        
      }
      deleteObject() {
        this.savedPage = this.currentPage;
        //prevent multiclicks. re enable after API response
        this.currentAction = 'loading';
        this.executeDeleteObject();
      }
      

      AddNotesOnClick(Id)
      {
          this.router.navigate(['app/HR/Add-Attachment/',0])
      }
      //Same here...
      executeDeleteObject(){
        debugger;
        this.modelService.deleteList(this.tableEngine.deletionList).subscribe(result => {
          if (result === true) {
            this.tableEngine.loadData();
            this.currentAction = '';
            this.tableEngine.delModal.hide();
            // this.finalizeDeletion();
            this.tableEngine.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyDeleted"));
            if (this.sideModalRef === undefined) {
      
              this.tableEngine.smallModal.hide();
      
            } else {
      
              this.tableEngine.sideModalRef.modalRef.hide();
            }
          }
          else {
            this.toastError(this.translate.instant("Error"), this.translate.instant("FailedDelete"));
            this.currentAction = '';
          }
        })
      }
      toastError(toastHeader: string, toastBody: string): void {
        this.notifications.create(toastHeader, toastBody,
          NotificationType.Error, { timeOut: 3000, showProgressBar: true });
      }
      

    loadServices() {
      // debugger;
      this.currentAction = "loading";
      this.tableEngine.loadData();
    }
  
 
    type = 0;
    visable = "";
    visable2 = "";
    visable3 = "";
  
  
    goBack() {
      this.router.navigate(["app/HR/List-Employees"]);
    }
}
