import { Component, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { NotificationsService } from "angular2-notifications";
import { ModalDirective } from "ngx-bootstrap/modal";
import { TableEngine } from "src/app/invoices/TableEngine";
import { SidePopupComponent } from "src/app/views/invoice/side-popup/side-popup.component";
import { ItemsPriceListModel } from "../../models/ItemsPriceListModel";
import { ItemTaxModel } from "../../models/ItemTaxModel";
import { PriceListModel } from "../../models/PriceListModel";
import { StoreItemsModel } from "../../models/StoreItemsModel";
import { WarehouseModel } from "../../models/WarehouseModel";
import { PriceListService } from "../../Price List/price-list/price-list.service";
import { WarehouseService } from "../../warehouses/warehouse-service.service";
import { ProductesService } from "../productes.service";
import{WarehouseItemModel}from"src/app/Invintory/models/WarehouseItem";
import { BrandServiceService } from "../../warehouses/brand-service.service";
import { CategoryServiceService } from "../../warehouses/category-service.service";
import { CategoryItemsModel } from "../../models/CategoryItemsMosel";
import { BrandItemsModel } from "../../models/BrandItemsModel";
import { StockAdjustmentModel } from "../../models/StockAdjustmentModel";
import { StockAdjustmentService } from "../stock-adjustment/Add-stock-adjustment.service";
@Component({
    // moduleId: module.id,
    selector: 'info-productes',
    templateUrl: 'info-productes.component.html',
    styleUrls: ['info-productes.component.scss']
})
export class InfoProductesComponent {
    Id = 0;

    constructor(
      public router: Router,
      private fb: FormBuilder,
      private modelService: ProductesService,
      private PriceListService: PriceListService,
      private CategoryService: CategoryServiceService,
      private BrandService: BrandServiceService,
      private StockAdjustmentService: StockAdjustmentService,
      
      private WarehouseService: WarehouseService,
      private notifications: NotificationsService,
      private translate: TranslateService,
      private route: ActivatedRoute
    ) {
      this.Id = +route.snapshot.paramMap.get("Id");
    }
  
    tableEngine = new TableEngine(this.translate, this.notifications);
    currentScreen: any = "Table";
    translationName: string = "Item";
    sortableKeys = [];
    searchableKeys = ["Name", "Address"];
    tableView: any = true;
  
    StoreItemsModel:StoreItemsModel[]=[];
  
    // WarehouseData: WarehouseModel[] = [];
  
    modelObject: StoreItemsModel = new StoreItemsModel();
    Form: FormGroup;
    declare lang: any;
    declare itemOrder: any;
    currentAction = "";
    savedPage = 0;
    currentPage = 1;
    deletionList: any[] = [];
    prevAction = "";
    addToListId = 0;
    ItemsBalanceHeaderId = 0;
    ItemBalanceId = 0;
    currentFormMode: string = "";
  
    ogData: StoreItemsModel[] = [];
    mainForm: FormGroup = new FormGroup({
      Id: new FormControl(),
      Name: new FormControl(),
      SkuNumber: new FormControl(),
      Description: new FormControl(),
      PurchasingPrice: new FormControl(),
      SalePrice: new FormControl(),
      FirstTaxId: new FormControl(),
      SecondTaxId: new FormControl(),
      LowestSellingPrice: new FormControl(),
      Discount: new FormControl(),
      ProfitPercentage: new FormControl(),
      PriceListId: new FormControl(),
      WarehouseDataId: new FormControl(),
      Quantity: new FormControl(),
      Notes: new FormControl(),
      Tags: new FormControl(),
      IsDisable: new FormControl(),
      WarehouseName: new FormControl(),
      TotalQuantitySoled: new FormControl(),
      Last28DayesQuantitySoled: new FormControl(),
      Last7DayesQuantitySoled: new FormControl(),
      AverageUnitPrice: new FormControl(),
      
    });
  
    @ViewChild("sideModalRef", { static: true })
    sideModalRef: SidePopupComponent = null;
    @ViewChild("delModal", { static: true }) delModal: ModalDirective;
    @ViewChild("smallModal", { static: true }) smallModal: ModalDirective = null;
    formPresentationModal: any;
    ngOnInit(): void {
      // this.getAllCountryCode();
  
      this.lang = localStorage.getItem("theme_lang");
      this.itemOrder = {
        label: "Name",
        property: this.lang === "ar-EG" ? "Name" : "Name",
      };
      this.sortableKeys = [
        // { label: 'Date', property: 'Date' },
        { label: "Name", property: this.lang === "ar-EG" ? "Name" : "Name" },
        { label: "SkuNumber", property: "SkuNumber" },
        { label: "Description", property: "Description" },
        { label: "PurchasingPrice", property: "PurchasingPrice" },
        { label: "SalePrice", property: "SalePrice" },
        { label: "FirstTaxId", property: "FirstTaxId" },
        { label: "SecondTaxId", property: "SecondTaxId" },
        { label: "LowestSellingPrice", property: "LowestSellingPrice" },
        { label: "Discount", property: "Discount" },
        { label: "ProfitPercentage", property: "ProfitPercentage" },
        { label: "PriceListId", property: "PriceListId" },
        { label: "WarehouseDataId", property: "WarehouseDataId" },
        { label: "Quantity", property: "Quantity" },
        { label: "Notes", property: "Notes" },
        { label: "Tags", property: "Tags" },
        { label: "IsDisable", property: "IsDisable" },
        { label: "WarehouseName", property: "WarehouseName" },
        
      ];
  
      this.tableEngine.loadTableEngine(
        this.ogData,
        this.modelService,
        this.modelObject,
        this.searchableKeys,
        this.sortableKeys,
        this.mainForm,
        this.translationName,
        this.itemOrder,
        this.sideModalRef,
        this.delModal,
        this.smallModal,
        this.lang
      );

      // this.mainForm = this.createForm(this.modelObject);
  
      // this.loadServices();
      // this.modelService.getById(this.Id).subscribe((data) => {
      //   this.modelObject = data;
      // });     
     
          if(this.Id > 0)
          {   debugger;                            
                  this.modelService.getById(this.Id).subscribe(data=>{                        
                  this.modelObject = data;
                  this.ItemsPriceList2=data.ItemPriceList;
                  this.ItemsPriceList2=this.modelObject.ItemPriceList;
                  this.WarehouseList2=data.WarhouseItem;
                  this.mainForm = this.createForm(this.modelObject);
                  this.calculateQuantity();   

  
              });
              this.StockAdjustmentService.getByItemId(this.Id).subscribe(data=>{
                this.StockAdjustmentList=data;
                 })
                 debugger;
                
                 this.mainForm = this.createForm(this.modelObject);
           
          }
          else{
                  
             this.mainForm = this.createForm(this.modelObject);       
              }
    }
  

    // calcTotalQuentity(item){
    //   var Quantity = 0;
    //   item.WarhouseItem.forEach(element2 => {
    //     Quantity+=element2.Quantity
        
    //   });
    //   return Quantity;
    // }
    createForm(modelObject): FormGroup {
      debugger;
      return this.fb.group({
        // Id:[this.ItemBalance.Id],
        Id: [modelObject.Id >= 0 ? modelObject.Id : 0],
        Name: [modelObject.Name, Validators.compose([Validators.required])],
        SkuNumber: [
          modelObject.SkuNumber,
          Validators.compose([Validators.required, Validators.minLength(2)]),
        ],
        PurchasingPrice: [
          modelObject.PurchasingPrice,
          Validators.compose([Validators.required]),
        ],
        SalePrice: [modelObject.SalePrice],
        FirstTaxId: [modelObject.FirstTaxId],
        SecondTaxId: [modelObject.SecondTaxId],
        LowestSellingPrice: [modelObject.LowestSellingPrice],
        Description: [modelObject.Description],
        Discount: [modelObject.Discount],
        ProfitPercentage: [modelObject.ProfitPercentage],
        PriceListId: [modelObject.PriceListId],
        WarehouseDataId:[modelObject.WarehouseDataId],
        Quantity: [modelObject.Quantity],
        Alert: [modelObject.Alert],
        Notes: [modelObject.Notes],
        Tags: [modelObject.Tags],
        WarehouseName: [modelObject.WarehouseName],
        TotalQuantitySoled: [modelObject.TotalQuantitySoled],
        Last7DayesQuantitySoled: [modelObject.Last7DayesQuantitySoled],
        Last28DayesQuantitySoled: [modelObject.Last28DayesQuantitySoled],
        AverageUnitPrice: [modelObject.AverageUnitPrice],
        IsDisable: [modelObject.IsDisable == null ? true : modelObject.IsDisable],
        
      });
    }
  

    showModal(Id, action) {
  
      if (action === 'Add') {          
       debugger;
        // this.tableEngine.showAddNewModalRef(this.createForm, this.formBuilder)
        this.router.navigate(['app/aio/Inventory/Add-Productes/',0])   
      }
      else if (action === 'Edit') {
        debugger;
        this.router.navigate(['app/aio/Inventory/Add-Productes/',Id])
    
        
      }
      else if (action === 'Info') {
       debugger;
        
        this.router.navigate(['app/aio/Inventory/Info-Productes/',Id])
        
       
      }
      //this.mainForm.get('IsActive').setValue(true);
       this.modelObject = this.tableEngine.modelObject;
       this.mainForm = this.tableEngine.mainForm;
    
    }
    
    StockAdjustmentList:StockAdjustmentModel[]=[];
    PriceList: PriceListModel[] = [];
    WarehouseList: WarehouseModel[] = [];
    loadServices() {
      // debugger;
      this.currentAction = "loading";
      this.tableEngine.loadData();
    }
  
 
    ItemsPriceList2:ItemsPriceListModel[]=[{Id:0,IsDeleted:false,ItemId:this.Id,PriceListId:0,SalePrice:0}];
    WarehouseList2:WarehouseItemModel[]=[{Id:0,ItemId:this.Id,WarehouseDataId:0,Alert:0,Quantity:0,WarehouseName:''}];
    CategoryList:CategoryItemsModel[]=[];
    BrandList:BrandItemsModel[]=[];
    
  getWarehouseById(Id: any): any { 
    debugger;
    return this.WarehouseList.find(i => i.Id === Id);
  }
  StockAdjustment(){
    this.router.navigate(['app/aio/Inventory/Stock-Adjustment/',this.Id])   

  }
  currentTotal = 0;

    calculateQuantity() {
      debugger;
      let total = 0;
      this.WarehouseList2.forEach(item => {
        total += item.Quantity;
      });
      this.currentTotal = total;
      return total;
    }
    type = 0;
    visable = "";
    visable2 = "";
    visable3 = "";
  
  
  
    goBack() {
      this.router.navigate(["app/aio/Inventory/List-All-Productes"]);
    }
    Save() {
      debugger;
      this.currentAction = "loading";
      this.tableEngine.savedPage = this.tableEngine.currentPage;
      this.modelObject = new StoreItemsModel();

      this.modelObject.Id = 0;
      this.modelObject.Name = this.mainForm.getRawValue().Name;
      this.modelObject.SkuNumber = this.mainForm.getRawValue().SkuNumber;
      this.modelObject.IsDisable = this.mainForm.getRawValue().IsDisable;
      this.modelObject.Description = this.mainForm.getRawValue().Description;
      this.modelObject.Discount = this.mainForm.getRawValue().Discount;
      
      this.modelObject.LowestSellingPrice =
        this.mainForm.getRawValue().LowestSellingPrice;
      this.modelObject.ProfitPercentage =
        this.mainForm.getRawValue().ProfitPercentage;
      this.modelObject.PurchasingPrice =
        this.mainForm.getRawValue().PurchasingPrice;
      this.modelObject.SalePrice = this.mainForm.getRawValue().SalePrice;
      this.modelObject.Notes = this.mainForm.getRawValue().Notes;
      // this.modelObject.PriceListId = this.mainForm.getRawValue().PriceListId;
      this.modelObject.FirstTaxId = this.mainForm.getRawValue().FirstTaxId;
      this.modelObject.SecondTaxId = this.mainForm.getRawValue().SecondTaxId;
      this.modelObject.Quantity = this.mainForm.getRawValue().Quantity;
      this.modelObject.Alert = this.mainForm.getRawValue().Alert;
      
      this.modelObject.ItemPriceList = this.ItemsPriceList2;
      this.modelObject.WarhouseItem = this.WarehouseList2;
      // this.modelObject.WarehouseDataId =
      //   this.mainForm.getRawValue().WarehouseDataId;
      this.modelObject.Tags = this.mainForm.getRawValue().Tags;
      if (this.Id == 0) {
        this.modelService.insert(this.modelObject).subscribe((data) => {
          this.router.navigate(["app/aio/Inventory/List-All-Productes"]);
        });
      } else {
        debugger;
        this.modelService.update(this.modelObject).subscribe((data) => {
          this.router.navigate(["app/aio/Inventory/List-All-Productes"]);
        });
      }
    }
}
