export class StaffModel{
    Id?:number;
    NameAr?:string;
    NameEn?:string;
    Email?:string;
    Role?:string;
    constructor(obj?:StaffModel){
        obj = obj? obj:{};
        this.Id = obj.Id? obj.Id:0;
        this.NameAr = obj.NameAr? obj.NameAr:'';
        this.NameEn = obj.NameEn? obj.NameEn:'';
        this.Email = obj.Email? obj.Email:'';
        this.Role = obj.Role? obj.Role:'';
    }
}