
export class WarehouseValidityModel{
    Id?: number;
    Name?:string;
 
    
    
    constructor(item?:WarehouseValidityModel){
        item = item? item:{};
        this.Id = item.Id? item.Id:0;
        this.Name = item.Name? item.Name:'';        
    
    }

}