import { Component, OnInit } from '@angular/core';
import { ProductPurchasesDataModel } from '../../models/product-purchases-data.model';
import { ProductPurchasesService } from '../../product-purchases.service';
import { ProductPurchasesSearchModel } from '../../models/product-purchases-search.model';
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';
@Component({
    selector: 'product-purchases',
    templateUrl: 'product-purchases.component.html',
    styleUrls: ['product-purchases.component.scss']
})
export class ProductPurchasesComponent implements OnInit{
    declare lang: any;
    today:Date=new Date();
    list:ProductPurchasesDataModel[]=[];
    liabilities:ProductPurchasesDataModel[]=[];
    PropertyRights:ProductPurchasesDataModel[]=[];
    employees=[];
    nodeList:ProductPurchasesDataModel[]=[];
    constructor(private ProductPurchasesService:ProductPurchasesService){
      this.lang = localStorage.getItem('theme_lang');
    }
    TotalAssets = 0;
    Totalliabilities = 0;
    TotalPropertyRights = 0;
    data:ProductPurchasesDataModel[]=[];
    assets:ProductPurchasesDataModel[]=[];
    search:ProductPurchasesSearchModel={};
    ngOnInit(): void {
        this.ProductPurchasesService.search(this.search)
        .subscribe(data => {
          this.data = data;
          this.assets = data;
        })
    
    }
    Search(){
      this.ProductPurchasesService.search(this.search)
        .subscribe(data => {
          this.data = data;
        })
    }
    exportTable(){
      var data = document.getElementById('contentToConvert');
      data.style.display = 'inline-block';
      html2canvas(data,{}).then(canvas => {
      var imgWidth = 208;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save('new-file.pdf'); // Generated PDF
      data.style.display = 'block';

    //   data.style.display = 'none';
      
    });
    }
    print(){
        let printContents, popupWin;
        printContents = document.getElementById('contentToConvert').innerHTML;
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.open();
        window.print();
       
      }
}
