<div class="md-float-material form-material" >

    <div style="text-align: center;">
        <button class="btn btn-light" (click)="goBack()"
            style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Back"  | translate}}</button>
        <button
            id="smallModalSubmitButton" class="btn btn-secondary" (click)="Save()"
            style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Add"  | translate}}</button>
        
    </div>
    <form *ngIf="InvoiceForm" [formGroup]="InvoiceForm">
        <div class="form-group">
            <br>
            <h3>{{ 'wizard.step-name-1' | translate }}</h3>
            <br>
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label>{{ 'Invoice Number' | translate }}</label>
                    <input formControlName="InvoiceNumber" type="text" class="form-control">
                </div>
                <div class="form-group col-md-6">
                    <label>{{ 'Currency' | translate }}</label>
                    <ng-select   formControlName="Currency" 
               
                      >
                      <ng-option *ngFor="let option of currencies" 
                      [value]="option.Code">
                      {{ option.NameAr }}
                        </ng-option>
                    </ng-select>
                </div>
      
                <!-- <div class="form-group col-md-4">
                    <label for="inputPassword4">{{ 'Tax Activity Code' | translate }}</label>
                    <input  formControlName="TaxActivityCode" type="text" id="inputPassword4" class="form-control">
                </div> -->
              
            </div>
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label >{{'sales order'| translate}}</label>
                    <input formControlName="salesorder" type="text" class="form-control">
                </div>
                <div class="form-group col-md-6">
                    <label for="inputPassword4">{{'Initial invoice number'| translate}}</label>
                    <input formControlName="Initialinvoicenumber" type="text" id="inputPassword4" class="form-control" >
                </div>
              
            </div>
            <div class="form-row">
                <div class=" col-md-6">
                    <label for="inputState">{{ 'Payment Plan' | translate }}</label>
                    <select  formControlName="PaymentPlanId" (change)="changePaymentPlan()" id="inputState" class="form-control">
                        <option disabled selected value="0">{{'Payment Plan' | translate}}</option>
                        <option *ngFor="let item of paymentPlans" [value]="item.Id">{{item.Name}}</option>
                    </select>
                </div>
                <div class="form-group col-md-6">
                    <label for="inputPassword4">{{'Purchase Order'| translate}}</label>
                    <input formControlName="PurchaseOrder" type="text" id="inputPassword4" class="form-control" >
                </div>
            </div>
        <div class="row">
            <div class=" col-md-6">
                <label for="inputState">{{ 'Branch' | translate }}</label>
                <select  formControlName="BranchDropdwon" (change)="update($event)" id="inputState" class="form-control">
                    <option disabled selected value="0">{{'Branch' | translate}}</option>
                    <option *ngFor="let item of BranchHolderList" [value]="item.Id">{{lang==='ar-EG'?item.NameAr:item.NameEn}}</option>
                </select>
            </div>
            <div class=" col-md-6">
                <label for="inputState">{{ 'Type' | translate }}</label>
                <select formControlName="TypeDropdwon" id="inputState" class="form-control">
                    <option value="i" >{{'Invoice'|translate}}</option>
                    <option value="d" >{{'Debit Note'|translate}}</option>
                    <option value="c" >{{'Credit Note'|translate}}</option>
                </select>
            </div>
            <!-- <div class=" col-md-4">
                <label for="inputState">{{ 'project' | translate }}</label>
                <select formControlName="company" id="inputState" class="form-control">
                    <option value="Discount notice" >projrect0</option>
                    <option value="Discount notice" >projrect1</option>
                </select>
            </div> -->
        </div>
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label for="inputEmail4">{{ 'Release Date' | translate }}</label>
                    <input formControlName="ReleaseDate" type="date" id="inputEmail4" class="form-control">
                </div>
               
                <div class="form-group col-md-6">
                    <label for="inputPassword4">{{ 'Due Date' | translate }}</label>
                    <input formControlName="DueDate" type="date" (change)="changeDueDate()" id="inputPassword4" class="form-control">
                </div>

            </div>
           
        </div>
   
       </form>
       <form *ngIf="ItemForm" [formGroup]="ItemForm">    
        <div class="form-group">
            <div class="form-group col-md-12">
                <label for="inputState">{{ 'Item' | translate }}</label>
                <!-- <ng-select   (change)="getItemID($event)"
           
                >
                <ng-option *ngFor="let item of ItemHolderList"
                [value]="item.Id">
                {{lang==='ar-EG'?item.Name:item.Name}}
                  </ng-option>
              </ng-select> -->
              <div class="input-group">
                <!-- <input type="text" class="form-control" placeholder="" aria-label="" aria-describedby="basic-addon1"> -->
                <ng-select class="form-control"  (change)="getItemID($event)"
           
                >
                <ng-option *ngFor="let item of ItemHolderList"
                [value]="item.Id">
                {{lang==='ar-EG'?item.Name:item.Name}}
                  </ng-option>
              </ng-select>
                <div class="input-group-append">
                  <button class="btn btn-success" type="button" (click)="showAddNewModalRef()">{{'Add'|translate}}</button>
                </div>
              </div>
                <!-- <select (change)="getItemID($event)"  id="inputState" class="form-control">
                    <option [value]="0" selected disabled>{{'Item'|translate}}</option>
                    <option *ngFor="let item of ItemHolderList" [value]="item.Id">{{lang==='ar-EG'?item.NameAr:item.NameEn}}</option>
                </select> -->
            </div>
            <div class="col-lg-12 col-md-12 mb-12">
                <div class="card">
                    <div class="card-body">
                        <!-- <h5 class="card-title">Basic Table</h5> -->
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">{{'Item'| translate}}</th>
                                    <th scope="col">{{'Unit'| translate}}</th>
                                    <th scope="col">{{'Quantity'| translate}}</th>
                                    <th scope="col">{{'UnitPrice'| translate}}</th>
                                    <th scope="col">{{'Total Price'| translate}}</th>
                                    <th scope="col">{{'Discount'| translate}}</th>
                                    <th scope="col">{{'Price After Discount'| translate}}</th>
                                    <th scope="col">{{'Tax'| translate}}</th>
                                    <th scope="col">{{'Total Tax'| translate}}</th>
                                    <th scope="col">{{'After Tax'| translate}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <!-- [(ngModel)]="item.quantity" +
                                    [(ngModel)]="item.discount"
                                    <div class="glyph ng-star-inserted">
                                        <div class="glyph-icon simple-icon-plus"></div><div class="class-name">simple-icon-plus</div></div>
                                -->
                                
                                <tr *ngFor=" let item of ItemModelList" >
                                    <th scope="row">{{item.id}}</th>
                                    <td>{{ item.name}}</td>
                                    <th scope="col">{{'Unit'| translate}}</th>
                                    <td><input formControlName="quantity"  (change)="changeQuanity(item)"  type="number" class="form-control"></td>
                                    <td>{{item.price}}</td>
                                    <td>{{item.totalPrice}}</td>
                                    <td><input  formControlName="discount"  (change)="changeDescount(item)" type="number" class="form-control "></td>
                                    <td>{{item.totalAfterDiscount}}</td>
                                    <td color="grey100" class="sc-fFSRdu hnfBjN admin-bro_TableCell">
                                        <div *ngFor="let selecteditem of item.taxs" class="row">
                                        <span font-size="sm"  class="sc-bdnylx iyhwWd admin-bro_Badge row" style="margin-left: 5px; margin-right: 5px;">{{selecteditem.NameAr}}</span>
                                        </div>
                                        <button class="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary" tabindex="0" type="button">
                                            <span class="MuiButton-label"(click)="openModal(template, item.id)"><div class="glyph-icon simple-icon-plus"></div></span>
                                        <span class="MuiTouchRipple-root"></span></button>
                                    </td>
                                    <td>{{item.TotalTax}}</td>

                                    <td>{{item.TotalPriceAfterTax}}</td>
                                </tr>
                            </tbody>
                        </table>
                  
                    </div>
                
                </div>
            </div>
            <table class="table table-borderless col-lg-6 col-md-12 mb-4" >
                <tbody>
                <tr>
                    <th>{{ 'amountAfterDiscount:' | translate }}</th>
                    <td>{{amountAfterDiscount}}</td>
                </tr>
                
                <tr>
                    <th>{{'Total Tax'| translate}}</th>
                    <td>{{totalTax}}</td>
                </tr>
                <tr>
                    <th>{{'Total'| translate}}</th>
                    <td>{{totalAfterTax}}</td>
                </tr>
            </tbody>
            </table>
        </div>
 
</form>
<form  >  
    <div class="form-group ">
    <div class="form-group col-md-12">
        <label for="inputState">{{ 'Client' | translate }}</label>
        <ng-select   (change)="OnClickClientItem($event)"
       
            >
            <ng-option *ngFor="let item of ClientHolderList"
            [value]="item.Id">
            {{lang==='ar-EG'?item.NameAr:item.NameEn}}
              </ng-option>
          </ng-select>
         
        <!-- <select (change)="OnClickClientItem($event)" id="inputState" class="form-control">
            <option [value]="0" selected disabled>{{ 'Client' | translate }}</option>
            <option *ngFor="let item of ClientHolderList" [value]="item.Id">{{lang==='ar-EG'?item.NameAr:item.NameEn}}</option>
        </select> -->
        <br>
        
   <div class="card" *ngIf="showCard">
    <br>
    <table class="table">

        <tbody>
    
            <tr>
                <th scope="row">{{ 'Name:' | translate }}</th>
                <td>{{Name}}</td>
            </tr>
            <tr>
                <th scope="row">{{ 'Client Type:' | translate }}</th>
                <td>{{ClientType}}</td>
            </tr> 
            <tr>
                <th scope="row">{{ 'Tax Number:' | translate }}</th>
                <td>{{TaxNumber}}</td>
            </tr>
        </tbody>
    </table>
  
    <br>
   </div>
    </div>
   
</div>

</form> 

<div class="form-group ">
    <div class="form-group col-md-12">
        <label class="float-label push righ">{{"Cost Center" | translate}}</label>

        <ng-select  *ngIf="lang==='ar-EG'" (change)="selectCenter()" [(ngModel)]="costCenterId" bindValue="Id"[items]="centers"   bindLabel="NameAr">
        </ng-select>
        <ng-select *ngIf="lang!=='ar-EG'" (change)="selectCenter()" [(ngModel)]="costCenterId" bindValue="Id"[items]="centers"   bindLabel="NameEn">
      </ng-select>
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">{{'Cost Center'| translate}}</th>
                    <th scope="col">{{'Percent'| translate}}</th>
                    <th scope="col">{{'Amount'| translate}}</th>
                    <th scope="col">{{'Delete'| translate}}</th>
 
                </tr>
            </thead>
            <tbody>
                <tr  *ngFor="let cost of selectedCostCenters; let i=index;">
                    <th style="width: 10%;" scope="row">{{i + 1}}</th>
                    <td style="width: 30%;">
                        {{getCostName(cost.CostCenterId)}}
                    </td>
            
                    <td style="width: 20%;"><input [(ngModel)]="cost.Percent" [max]="100" (change)="changePercent(cost,$event)"type="number" class="form-control"></td>
                    <td style="width: 20%;"><input [(ngModel)]="cost.Amount" [max]="totalAfterTax" (change)="changeAmount(cost,$event)"  type="number" class="form-control "></td>
                    <td style="width: 20%;">
                        <a href="javascript:" tooltip="{{'Delete'|translate}}" (click)="deleteCostCenter(cost)"><i class="simple-icon-trash"></i></a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

</div>
<form *ngIf="PaymentForm"  [formGroup]="PaymentForm">
    <div class="form-group">
        <br>
        <h3>{{ 'Payment' | translate }}</h3>
        <br>
        <div class="form-row">
           <div class="form-group col-md-6">
               <label for="inputPassword4">{{ 'Bank Name' | translate }}</label>
               <input  formControlName="BankName" type="text" id="inputPassword4" class="form-control">
           </div>
            <div class="form-group col-md-6">
                <label>{{ 'Bank Account No' | translate }}</label>
                <input formControlName="BankAccountNo" type="text" class="form-control">
            </div>
         
          
        </div>
     
        <div class="form-row">
           <div class="form-group col-md-6">
               <label for="inputPassword4">{{ 'Bank Account IBAN' | translate }}</label>
               <input  formControlName="BankAccountIBAN" type="text" id="inputPassword4" class="form-control">
           </div>
            <div class="form-group col-md-6">
                <label>{{ 'Swift Code' | translate }}</label>
                <input formControlName="SwiftCode" type="text" class="form-control">
            </div>
         
          
        </div>
        <div class="form-row">
           
            <div class="form-group col-md-12">
                <label>{{ 'Bank Address' | translate }}</label>
                <textarea formControlName="BankAddress" type="text" class="form-control"></textarea>
            </div>
         
          
        </div>
       
    </div>
    <!-- [disabled]="InvoiceForm.invalid" -->

   </form>
   <form *ngIf="DelivaryForm"  [formGroup]="DelivaryForm">
    <div class="form-group">
        <br>
        <h3>{{ 'Delivary' | translate }}</h3>
        <br>
        <div class="form-row">
           <div class="form-group col-md-6">
               <label for="inputPassword4">{{ 'Approach' | translate }}</label>
               <input  formControlName="Approach" type="text" id="inputPassword4" class="form-control">
           </div>
            <div class="form-group col-md-6">
                <label>{{ 'Packaging' | translate }}</label>
                <input formControlName="Packaging" type="text" class="form-control">
            </div>
         
          
        </div>
     
        <div class="form-row">
           <div class="form-group col-md-6">
               <label for="inputPassword4">{{ 'Date Validity' | translate }}</label>
               <input  formControlName="DateValidity" type="date" id="inputPassword4" class="form-control">
           </div>
            <div class="form-group col-md-6">
                <label>{{ 'Export Port' | translate }}</label>
                <input formControlName="ExportPort" type="text" class="form-control">
            </div>
         
          
        </div>
        <div class="form-row">
           <div class="form-group col-md-6">
               <label for="inputPassword4">{{ 'Gross Weight' | translate }}</label>
               <input  formControlName="GrossWeight" type="text" id="inputPassword4" class="form-control">
           </div>
            <div class="form-group col-md-6">
                <label>{{ 'Net Weight' | translate }}</label>
                <input formControlName="NetWeight" type="text" class="form-control">
            </div>
         
          
        </div>
        <div class="form-row">
           <div class="form-group col-md-6">
               <label for="inputPassword4">{{ 'Country Of Origin' | translate }}</label>
               <input  formControlName="CountryOfOrigin" type="text" id="inputPassword4" class="form-control">
           </div>
        </div>
    </div>
    <!-- [disabled]="InvoiceForm.invalid" -->
  
   </form>
   <div class="form-group ">
    <div class="text-center">
        <h2 class="mb-2">{{ 'Invoice Summary' | translate }}</h2>
    </div>

    <div class="card">
        <br>
        <table class="table">

            <tbody>
        
                <tr>
                    <th scope="row">{{ 'Total Sales Amount:' | translate }}</th>
                    <td>{{totalSalesAmount}}</td>
                </tr>
                <tr>
                    <th scope="row">{{ 'Total Discount Amount:' | translate }}</th>
                    <td>{{totalDiscountAmount}}</td>
                </tr> 
                <!-- <tr>
                    <th scope="row">{{ 'Value Added Tax:' | translate }}</th>
                    <td>{{valueAddedTax}}</td>
                </tr> -->
                <tr>
                    <th scope="row">{{ 'amountAfterDiscount:' | translate }}</th>
                    <td>{{amountAfterDiscount}}</td>
                </tr>
            </tbody>
        </table>
        <!-- <div  class="form-group col-md-12 text-center">
            <label >{{ 'Total Sales Amount:' | translate }}</label>
    
            <label style="margin-left: 150px;;margin-right: 150px;"   >{{totalSalesAmount}}</label>
        </div>
        <div  class="form-group col-md-12 text-center">
            <label >{{ 'Total Discount Amount:' | translate }}</label>
    
            <label style="margin-left: 150px;;margin-right: 150px;">{{totalDiscountAmount}}</label>
        </div>
        <div  class="form-group col-md-12 text-center">
            <label >{{ 'Value Added Tax:' | translate }}</label>
    
            <label style="margin-left: 150px;;margin-right: 150px;" >{{valueAddedTax}}</label>
        </div>
        <div  class="form-group col-md-12 text-center">
            <label >{{ 'amountAfterDiscount:' | translate }}</label>
    
            <label style="margin-left: 150px;;margin-right: 150px;">{{amountAfterDiscount}}</label>
        </div> -->
        <br>
       </div>
</div>
<div style="text-align: center;">
    <button class="btn btn-light" (click)="goBack()"
        style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Back"  | translate}}</button>
    <button
       
        id="smallModalSubmitButton" class="btn btn-secondary" (click)="Save()"
        style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Add"  | translate}}</button>
    
</div>
</div>

<!-- <div class="card mb-5">
    <div class="card-body">
        <aw-wizard>
            <aw-wizard-step stepTitle="{{ 'wizard.step-name-1' | translate }}" style="min-height: 120px;">
               <form *ngIf="InvoiceForm" [formGroup]="InvoiceForm">
                <div class="form-group">
                    <br>
                    <h3>{{ 'wizard.step-name-1' | translate }}</h3>
                    <br>
                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label>{{ 'Invoice Number' | translate }}</label>
                            <input formControlName="InvoiceNumber" type="text" class="form-control">
                        </div>
                        <div class="form-group col-md-4">
                            <label>{{ 'Currency' | translate }}</label>
                            <ng-select   formControlName="Currency" 
                       
                              >
                              <ng-option *ngFor="let option of currencies" 
                              [value]="option.Code">
                              {{ option.NameAr }}
                                </ng-option>
                            </ng-select>
                        </div>
              
                    
                      
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label >{{'sales order'| translate}}</label>
                            <input formControlName="salesorder" type="text" class="form-control">
                        </div>
                        <div class="form-group col-md-4">
                            <label for="inputPassword4">{{'Initial invoice number'| translate}}</label>
                            <input formControlName="Initialinvoicenumber" type="text" id="inputPassword4" class="form-control" >
                        </div>
                        <div class="form-group col-md-4">
                            <label for="inputPassword4">{{'Purchase Order'| translate}}</label>
                            <input formControlName="PurchaseOrder" type="text" id="inputPassword4" class="form-control" >
                        </div>
                    </div>
                <div class="row">
                    <div class=" col-md-6">
                        <label for="inputState">{{ 'Branch' | translate }}</label>
                        <select  formControlName="BranchDropdwon" (change)="update($event)" id="inputState" class="form-control">
                            <option disabled selected value="0">{{'Branch' | translate}}</option>
                            <option *ngFor="let item of BranchHolderList" [value]="item.Id">{{lang==='ar-EG'?item.NameAr:item.NameEn}}</option>
                        </select>
                    </div>
                    <div class=" col-md-6">
                        <label for="inputState">{{ 'Type' | translate }}</label>
                        <select formControlName="TypeDropdwon" id="inputState" class="form-control">
                            <option value="i" >{{'Invoice'|translate}}</option>
                            <option value="d" >{{'Debit Note'|translate}}</option>
                            <option value="c" >{{'Credit Note'|translate}}</option>
                        </select>
                    </div>
                
                </div>
                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label for="inputEmail4">{{ 'Release Date' | translate }}</label>
                            <input formControlName="ReleaseDate" type="date" id="inputEmail4" class="form-control">
                        </div>
                        <div class=" col-md-4">
                            <label for="inputState">{{ 'Payment Plan' | translate }}</label>
                            <select  formControlName="PaymentPlanId" (change)="changePaymentPlan()" id="inputState" class="form-control">
                                <option disabled selected value="0">{{'Payment Plan' | translate}}</option>
                                <option *ngFor="let item of paymentPlans" [value]="item.Id">{{item.Name}}</option>
                            </select>
                        </div>
                        <div class="form-group col-md-4">
                            <label for="inputPassword4">{{ 'Due Date' | translate }}</label>
                            <input formControlName="DueDate" type="date" (change)="changeDueDate()" id="inputPassword4" class="form-control">
                        </div>

                    </div>
                   
                </div>
                <div class="text-center">
                    <button type="button" class="btn btn-primary" awPreviousStep disabled>{{ 'wizard.prev' | translate
                        }}</button>{{" "}}
                    <button type="button"class="btn btn-primary" awNextStep>{{ 'wizard.next' | translate }}</button>
                </div>
               </form>
            </aw-wizard-step>
            <aw-wizard-step stepTitle="{{ 'wizard.step-name-2' | translate }}" style="min-height: 120px;">
                <form *ngIf="ItemForm" [formGroup]="ItemForm">    
                    <div class="form-group">
                        <div class="form-group col-md-12">
                            <label for="inputState">{{ 'Item' | translate }}</label>
                            <ng-select   (change)="getItemID($event)"
                       
                            >
                            <ng-option *ngFor="let item of ItemHolderList"
                            [value]="item.Id">
                            {{lang==='ar-EG'?item.Name:item.Name}}
                              </ng-option>
                          </ng-select>
                          
                        </div>
                        <div class="col-lg-12 col-md-12 mb-12">
                            <div class="card">
                                <div class="card-body">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">{{'Item'| translate}}</th>
                                                <th scope="col">{{'Unit'| translate}}</th>
                                                <th scope="col">{{'Quantity'| translate}}</th>
                                                <th scope="col">{{'UnitPrice'| translate}}</th>
                                                <th scope="col">{{'Total Price'| translate}}</th>
                                                <th scope="col">{{'Discount'| translate}}</th>
                                                <th scope="col">{{'Price After Discount'| translate}}</th>
                                                <th scope="col">{{'Tax'| translate}}</th>
                                                <th scope="col">{{'Total Tax'| translate}}</th>
                                                <th scope="col">{{'After Tax'| translate}}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                               
                                            
                                            <tr *ngFor=" let item of ItemModelList" >
                                                <th scope="row">{{item.id}}</th>
                                                <td>{{ item.name}}</td>
                                                <th scope="col">{{'Unit'| translate}}</th>
                                                <td><input formControlName="quantity"  (change)="changeQuanity(item)"  type="number" class="form-control"></td>
                                                <td>{{item.price}}</td>
                                                <td>{{item.totalPrice}}</td>
                                                <td><input  formControlName="discount"  (change)="changeDescount(item)" type="number" class="form-control "></td>
                                                <td>{{item.totalAfterDiscount}}</td>
                                                <td color="grey100" class="sc-fFSRdu hnfBjN admin-bro_TableCell">
                                                    <div *ngFor="let selecteditem of item.taxs" class="row">
                                                    <span font-size="sm"  class="sc-bdnylx iyhwWd admin-bro_Badge row" style="margin-left: 5px; margin-right: 5px;">{{selecteditem.NameAr}}</span>
                                                    </div>
                                                    <button class="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary" tabindex="0" type="button">
                                                        <span class="MuiButton-label"(click)="openModal(template, item.id)"><div class="glyph-icon simple-icon-plus"></div></span>
                                                    <span class="MuiTouchRipple-root"></span></button>
                                                </td>
                                                <td>{{item.TotalTax}}</td>

                                                <td>{{item.TotalPriceAfterTax}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                              
                                </div>
                            
                            </div>
                        </div>
                        <table class="table table-borderless col-lg-6 col-md-12 mb-4" >
                            <tbody>
                            <tr>
                                <th>{{ 'amountAfterDiscount:' | translate }}</th>
                                <td>{{amountAfterDiscount}}</td>
                            </tr>
                            
                            <tr>
                                <th>{{'Total Tax'| translate}}</th>
                                <td>{{totalTax}}</td>
                            </tr>
                            <tr>
                                <th>{{'Total'| translate}}</th>
                                <td>{{totalAfterTax}}</td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    <div class="text-center">
                        <button type="button" class="btn btn-primary" awPreviousStep>{{ 'wizard.prev' | translate }}</button>{{" "}}
                        <button type="button" class="btn btn-primary" (click)="InvoiceSummary()"  awNextStep>{{ 'wizard.next' | translate }}</button>
                    </div>
            </form>
            </aw-wizard-step>
            <aw-wizard-step stepTitle="{{ 'wizard.step-name-3' | translate }}" style="min-height: 120px;">
                <form  >  
                    <div class="form-group ">
                    <div class="form-group col-md-12">
                        <label for="inputState">{{ 'Client' | translate }}</label>
                        <ng-select   (change)="OnClickClientItem($event)"
                       
                            >
                            <ng-option *ngFor="let item of ClientHolderList"
                            [value]="item.Id">
                            {{lang==='ar-EG'?item.NameAr:item.NameEn}}
                              </ng-option>
                          </ng-select>
                     
                        <br>
                        
                   <div class="card" *ngIf="showCard">
                    <br>
                    <table class="table">

                        <tbody>
                    
                            <tr>
                                <th scope="row">{{ 'Name:' | translate }}</th>
                                <td>{{Name}}</td>
                            </tr>
                            <tr>
                                <th scope="row">{{ 'Client Type:' | translate }}</th>
                                <td>{{ClientType}}</td>
                            </tr> 
                            <tr>
                                <th scope="row">{{ 'Tax Number:' | translate }}</th>
                                <td>{{TaxNumber}}</td>
                            </tr>
                        </tbody>
                    </table>
                  
                    <br>
                   </div>
                    </div>

                </div>
                <div class="text-center">
                    <button type="button" class="btn btn-primary"      awPreviousStep>{{ 'wizard.prev' | translate
                        }}</button>{{" "}}
                    <button type="button" class="btn btn-primary" awNextStep>{{ 'wizard.next' | translate }}</button>
                </div>
            </form> 
            </aw-wizard-step>
            <aw-wizard-step stepTitle="{{ 'Cost Centers' | translate }}" style="min-height: 120px;">
                <div  >  
                    <div class="form-group ">
                    <div class="form-group col-md-12">
                        <label class="float-label push righ">{{"Cost Center" | translate}}</label>

                        <ng-select  *ngIf="lang==='ar-EG'" (change)="selectCenter()" [(ngModel)]="costCenterId" bindValue="Id"[items]="centers"   bindLabel="NameAr">
                        </ng-select>
                        <ng-select *ngIf="lang!=='ar-EG'" (change)="selectCenter()" [(ngModel)]="costCenterId" bindValue="Id"[items]="centers"   bindLabel="NameEn">
                      </ng-select>
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">{{'Cost Center'| translate}}</th>
                                    <th scope="col">{{'Percent'| translate}}</th>
                                    <th scope="col">{{'Amount'| translate}}</th>
                                    <th scope="col">{{'Delete'| translate}}</th>
                 
                                </tr>
                            </thead>
                            <tbody>
                                <tr  *ngFor="let cost of selectedCostCenters; let i=index;">
                                    <th style="width: 10%;" scope="row">{{i + 1}}</th>
                                    <td style="width: 30%;">
                                        {{getCostName(cost.CostCenterId)}}
                                    </td>
                            
                                    <td style="width: 20%;"><input [(ngModel)]="cost.Percent" [max]="100" (change)="changePercent(cost,$event)"type="number" class="form-control"></td>
                                    <td style="width: 20%;"><input [(ngModel)]="cost.Amount" [max]="totalAfterTax" (change)="changeAmount(cost,$event)"  type="number" class="form-control "></td>
                                    <td style="width: 20%;">
                                        <a href="javascript:" tooltip="{{'Delete'|translate}}" (click)="deleteCostCenter(cost)"><i class="simple-icon-trash"></i></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
                <div class="text-center">
                    <button type="button" class="btn btn-primary"      awPreviousStep>{{ 'wizard.prev' | translate
                        }}</button>{{" "}}
                    <button type="button" class="btn btn-primary" awNextStep>{{ 'wizard.next' | translate }}</button>
                </div>
            </div> 
            </aw-wizard-step>
            
     
            <aw-wizard-step stepTitle="{{ 'Payment' | translate }}" style="min-height: 120px;">
                <form *ngIf="PaymentForm"  [formGroup]="PaymentForm">
                 <div class="form-group">
                     <br>
                     <h3>{{ 'Payment' | translate }}</h3>
                     <br>
                     <div class="form-row">
                        <div class="form-group col-md-6">
                            <label for="inputPassword4">{{ 'Bank Name' | translate }}</label>
                            <input  formControlName="BankName" type="text" id="inputPassword4" class="form-control">
                        </div>
                         <div class="form-group col-md-6">
                             <label>{{ 'Bank Account No' | translate }}</label>
                             <input formControlName="BankAccountNo" type="text" class="form-control">
                         </div>
                      
                       
                     </div>
                  
                     <div class="form-row">
                        <div class="form-group col-md-6">
                            <label for="inputPassword4">{{ 'Bank Account IBAN' | translate }}</label>
                            <input  formControlName="BankAccountIBAN" type="text" id="inputPassword4" class="form-control">
                        </div>
                         <div class="form-group col-md-6">
                             <label>{{ 'Swift Code' | translate }}</label>
                             <input formControlName="SwiftCode" type="text" class="form-control">
                         </div>
                      
                       
                     </div>
                     <div class="form-row">
                        
                         <div class="form-group col-md-12">
                             <label>{{ 'Bank Address' | translate }}</label>
                             <textarea formControlName="BankAddress" type="text" class="form-control"></textarea>
                         </div>
                      
                       
                     </div>
                    
                 </div>
                 <div class="text-center">
                     <button type="button" class="btn btn-primary" awPreviousStep disabled>{{ 'wizard.prev' | translate
                         }}</button>{{" "}}
                     <button type="button"class="btn btn-primary" awNextStep>{{ 'wizard.next' | translate }}</button>
                 </div>
                </form>
             </aw-wizard-step>
             <aw-wizard-step stepTitle="{{ 'Delivary' | translate }}" style="min-height: 120px;">
                <form *ngIf="DelivaryForm"  [formGroup]="DelivaryForm">
                 <div class="form-group">
                     <br>
                     <h3>{{ 'Delivary' | translate }}</h3>
                     <br>
                     <div class="form-row">
                        <div class="form-group col-md-6">
                            <label for="inputPassword4">{{ 'Approach' | translate }}</label>
                            <input  formControlName="Approach" type="text" id="inputPassword4" class="form-control">
                        </div>
                         <div class="form-group col-md-6">
                             <label>{{ 'Packaging' | translate }}</label>
                             <input formControlName="Packaging" type="text" class="form-control">
                         </div>
                      
                       
                     </div>
                  
                     <div class="form-row">
                        <div class="form-group col-md-6">
                            <label for="inputPassword4">{{ 'Date Validity' | translate }}</label>
                            <input  formControlName="DateValidity" type="date" id="inputPassword4" class="form-control">
                        </div>
                         <div class="form-group col-md-6">
                             <label>{{ 'Export Port' | translate }}</label>
                             <input formControlName="ExportPort" type="text" class="form-control">
                         </div>
                      
                       
                     </div>
                     <div class="form-row">
                        <div class="form-group col-md-6">
                            <label for="inputPassword4">{{ 'Gross Weight' | translate }}</label>
                            <input  formControlName="GrossWeight" type="text" id="inputPassword4" class="form-control">
                        </div>
                         <div class="form-group col-md-6">
                             <label>{{ 'Net Weight' | translate }}</label>
                             <input formControlName="NetWeight" type="text" class="form-control">
                         </div>
                      
                       
                     </div>
                     <div class="form-row">
                        <div class="form-group col-md-6">
                            <label for="inputPassword4">{{ 'Country Of Origin' | translate }}</label>
                            <input  formControlName="CountryOfOrigin" type="text" id="inputPassword4" class="form-control">
                        </div>
                     </div>
                 </div>
                 <div class="text-center">
                     <button type="button" class="btn btn-primary" awPreviousStep disabled>{{ 'wizard.prev' | translate
                         }}</button>{{" "}}
                     <button type="button"class="btn btn-primary" awNextStep>{{ 'wizard.next' | translate }}</button>
                 </div>
                </form>
             </aw-wizard-step>
            <aw-wizard-step stepTitle="{{ 'wizard.done' | translate }}" style="height: 260px;">
                <form  >  
                <div class="form-group ">
                <div class="text-center">
                    <h2 class="mb-2">{{ 'Invoice Summary' | translate }}</h2>
                </div>

                <div class="card">
                    <br>
                    <table class="table">

                        <tbody>
                    
                            <tr>
                                <th scope="row">{{ 'Total Sales Amount:' | translate }}</th>
                                <td>{{totalSalesAmount}}</td>
                            </tr>
                            <tr>
                                <th scope="row">{{ 'Total Discount Amount:' | translate }}</th>
                                <td>{{totalDiscountAmount}}</td>
                            </tr> 
                           
                            <tr>
                                <th scope="row">{{ 'amountAfterDiscount:' | translate }}</th>
                                <td>{{amountAfterDiscount}}</td>
                            </tr>
                        </tbody>
                    </table>
                   
                    <br>
                   </div>
            </div>
                <div class="text-center">
                    <button type="button" class="btn btn-primary" awPreviousStep>{{ 'wizard.prev' | translate
                        }}</button>{{" "}}
                    <button type="button" (click)="Save()" class="btn btn-primary" awNextStep >{{ 'wizard.save' | translate
                        }}</button>
                </div>
            </form> 
            </aw-wizard-step>
        </aw-wizard>
    </div>
</div> -->

<ng-template #template>

    <div class="modal-body">
        <h1>{{'Tax on item'|translate}} {{TaxHeaderName}}</h1>
        <div class="col-12 ">
            <ng-select  [items]="companies" [addTag]="addTagFn" [hideSelected]="true" multiple="true" bindLabel="NameAr"
            [bindValue]="TaxId" 
            (add)="OnAddSelectedItemTax($event)"
            (remove)="OnRemoveSelectedItemTax($event)"
              [(ngModel)]="selectedCompanies"
       
              >
            </ng-select>
          </div>
          <br>
          <div class="col-lg-12 col-md-12 mb-12">
            <div class="card">
                <div class="card-body">
                    <!-- <h5 class="card-title">Basic Table</h5> -->
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">{{'Tax'| translate}}</th>
                                <th scope="col">{{'Total Tax'| translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <!-- [(ngModel)]="item.quantity" +
                                [(ngModel)]="item.discount"
                            -->
                            <tr *ngFor=" let item of selectedCompanies" >
                                <!-- <div *ngIf="selecteditem.ItemID == item.id"></div> -->
                                <th scope="row">{{item.Id}}</th>
                                <td>{{item.Percentage}}</td>
                                <td>{{item.TotalTax}}</td>
                                <!-- <th scope="col">{{item.TotalPriceAfterTax}}</th> -->
                               
                            </tr>
                       
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
  </ng-template>



<ng-template #itemtemplate>
    <div class="modal-header">
      <!-- <h4 class="modal-title pull-left">{{'modal.modal-title' | translate}}</h4> -->
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <div class="row" *ngIf="mainForm" style="overflow: scroll;">
            <div class="row">
                <div class="col-md-6">
              <!-- <div class="col-md-12 col-lg-12 col-12 mb-12"> -->
                <div class="card">
                  <tabset class="card-tabs" [justified]="true">
                    <tab heading="{{ 'Product Details' | translate }}" class="card-body">
                      <!-- <h6 class="mb-4">{{ "Basic Data" | translate }}</h6> -->
                      <div class="modal-body">
                        <form
                          class="md-float-material form-material"
                          [formGroup]="mainForm"
                        >
                          <div class="row">
                            <div class="col-md-6">
                                <label for="inputState">{{ "SkuNumber" | translate }}</label>
                                <input
                                  class="form-control"
                                  formControlName="SkuNumber"
                                  rows="2"
                                  placeholder="{{ 'SkuNumber' | translate }}"
                                />
                               
                              </div>
                              
                            <div class="col-md-6">
                              <label for="inputState">{{ "Name" | translate }}</label>
                              <input
                                class="form-control"
                                formControlName="Name"
                                rows="2"
                                placeholder="{{ 'Name' | translate }}"
                              />
                              <!-- <div
                                class="messages text-danger"
                                *ngIf="
                                  (mainForm.get('Name').hasError('required') ||
                                    mainForm.get('Name').invalid) &&
                                  (mainForm.get('Name').dirty ||
                                    mainForm.get('Name').touched)
                                "
                              >
                                {{ "NameRequired" | translate }}
                              </div> -->
                            </div>
                          
            
                            <div class="col-md-12">
                              <label for="inputState">{{
                                "Description" | translate
                              }}</label>
                           
                              <textarea formControlName="Description" id="" cols="8" rows="3"
                                class="form-control"  placeholder="{{ 'Description' | translate }}"></textarea>
                              <!-- <div
                                class="messages text-danger"
                                *ngIf="
                                  (mainForm.get('Description').hasError('required') ||
                                    mainForm.get('Description').invalid) &&
                                  (mainForm.get('Description').dirty ||
                                    mainForm.get('Description').touched)
                                "
                              >
                                {{ "DescriptionRequired" | translate }}
                              </div> -->
                            </div>
            
            
                            <div class="col-md-12">
                              <label for="inputState">{{ "Barcode" | translate }}</label>
                              <!-- <barcode type="code128b">Hello</barcode> -->
                              <input
                                class="form-control"
                                formControlName="Barcode"
                                rows="2"
                                placeholder="{{ 'Barcode' | translate }}"
                              />
                              
                            </div>
            
            
                          <div class="col-md-6">
                            <label for="inputState">{{
                              "Category" | translate
                            }}</label>
                            <ng-select [items]="CategoryList"
                            bindLabel="Name"
                            bindValue="Id"
                            [multiple]="true"
                            groupBy="selectedAllGroup"
                            [selectableGroup]="true"
                            placeholder="Select Category"
                            (change)="onMaterialGroupChangeCategory($event)">
                            >
            
                            <ng-template 
                             ng-optgroup-tmp let-item="item" 
                             let-item$="item$" let-index="index">
                             <input id="item-{{index}}" type="checkbox"
                             [ngModel] ="item$.selected"/> Select All
                            </ng-template>
                            </ng-select>
                          
                          </div>
            
            
                          <div class="col-md-6">
                            <label for="inputState">{{
                              "Brand" | translate
                            }}</label>
                            <ng-select [items]="BrandList"
                            bindLabel="Name"
                            bindValue="Id"
                            [multiple]="true"
                            groupBy="selectedAllGroup"
                            [selectableGroup]="true"
                            placeholder="Select Brand"
                            (change)="onMaterialGroupChangeBrand($event)">
                            >
            
                            <ng-template 
                             ng-optgroup-tmp let-item="item" 
                             let-item$="item$" let-index="index">
                             <input id="item-{{index}}" type="checkbox"
                             [ngModel] ="item$.selected"/> Select All
                            </ng-template>
                            </ng-select>
            
                          </div>
            
                          <div class="col-md-12">
                            <div
                            class="itemCheck mb-0 custom-checkbox custom-control"
                            style="display: inline-block"
                          >
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              [id]="'valueCheck'"
                              formControlName="AvailableOnline"
                            />
                            <label class="custom-control-label" [for]="'valueCheck'"
                              >{{ "AvailableOnline" | translate }}
                            </label>
                          </div>
                          </div>
            
                           <div class="col-md-12">
                              <div
                                class="itemCheck mb-0 custom-checkbox custom-control"
                                style="display: inline-block" >
                                <input
                                  type="checkbox"
                                  class="custom-control-input"
                                  [id]="'value'"
                                  formControlName="FeaturedProduct"
                                />
                                <label class="custom-control-label" [for]="'value'"
                                  >{{ "FeaturedProduct" | translate }}
                                </label>
                                      </div>
                           </div>
            
                          </div>
                          <div class="row"></div>
                         
                        </form>
                      </div>
                    </tab>
                  </tabset>
                </div>
            </div>
            <div class="col-md-6">
            
                <div class="card" >
                    <tabset class="card-tabs" [justified]="true">
                        <tab heading="{{ 'Pricing' | translate }}" class="card-body"> 
                    <div class="modal-body">
                    <form class="md-float-material form-material" [formGroup]="mainForm">
                      <div class="row">
                        <div class="col-md-6">
                          <label for="inputState">{{
                            "PurchasingPrice" | translate
                          }}</label>
                          <input
                            class="form-control"
                            formControlName="PurchasingPrice"
                            rows="2"
                            placeholder="{{ 'PurchasingPrice' | translate }}"
                          />
                         
                        </div>
                        <div class="col-md-6">
                          <label for="inputState">{{ "SalePrice" | translate }}</label>
                          <input
                            class="form-control"
                            formControlName="SalePrice"
                            rows="2"
                            placeholder="{{ 'SalePrice' | translate }}"
                            (change)="calcProfitPrice()"
                          />
                      
                        </div>
                      </div>
                      
                       <div class="row">
                          <div class="col-md-6">
                            <label for="inputState">{{ "FirstTaxId" | translate }}</label>
                            <select
                              id="inputState"
                              class="form-control"
                              formControlName="FirstTaxId"
            
                            >
                              <option selected value="0">
                                {{ "SelectFirstTax" | translate }}
                              </option>
                              <option
                                *ngFor="let tax of FirstTaxesList"
                                value="{{ tax.Id }}"
                              >
                                {{ lang === "ar-EG" ? tax.NameAr : tax.NameEn }}
                              </option>
                            </select>
                          </div>
              
                          <div class="col-md-6">
                            <label for="inputState">{{ "SecondTaxId" | translate }}</label>
                            <select
                              id="inputState"
                              class="form-control"
                              formControlName="SecondTaxId"
                            >
                              <option selected value="0">
                                {{ "SelectSecondTax" | translate }}
                              </option>
                              <option
                                *ngFor="let tax of SecondTaxesList"
                                value="{{ tax.Id }}"
                              >
                                {{ lang === "ar-EG" ? tax.NameAr : tax.NameEn }}
                              </option>
                            </select>
                          </div>
                        </div>
            
                        <div class="row">
                          <div class="col-md-6">
                            <label for="inputState">{{
                              "LowestSellingPrice" | translate
                            }}</label>
                            <input
                              class="form-control"
                              formControlName="LowestSellingPrice"
                              rows="2"
                              placeholder="{{ 'LowestSellingPrice' | translate }}"
                            />
                            <div
                              class="messages text-danger"
                              *ngIf="
                                (mainForm.get('LowestSellingPrice').hasError('required') ||
                                  mainForm.get('LowestSellingPrice').invalid) &&
                                (mainForm.get('LowestSellingPrice').dirty ||
                                  mainForm.get('LowestSellingPrice').touched)
                              "
                            >
                              {{ "LowestSellingPriceRequired" | translate }}
                            </div>
                          </div>
                        <div class="col-md-3">
                          <label for="inputState">{{ "Discount" | translate }}</label>
                          <input
                            class="form-control"
                            formControlName="Discount"
                            rows="2"
                            placeholder="{{ 'Discount' | translate }}"
                          />
                          <div
                            class="messages text-danger"
                            *ngIf="
                              (mainForm.get('Discount').hasError('required') ||
                                mainForm.get('Discount').invalid) &&
                              (mainForm.get('Discount').dirty ||
                                mainForm.get('Discount').touched)
                            "
                          >
                            {{ "DiscountRequired" | translate }}
                          </div>
                        </div>
            
                        <div class="col-md-3">
                          <label for="inputState">{{ "DiscountType" | translate }}</label>
                          <select
                            id="inputState"
                            class="form-control"
                            formControlName="DiscountType"
                          >
                          <option selected value="1">
                            {{ "%" | translate }}                  
                          </option>
                            <option selected value="2">
                              {{ "$" | translate }}                  
                            </option>
                          
                          
                          </select>
                        </div>
                      </div>
            
                      <div class="row">
                        <div class="col-md-6">
                          <label for="inputState">{{
                            "ProfitPercentage" | translate
                          }}</label>
                          <input
                            class="form-control"
                            formControlName="ProfitPercentage"
                            rows="2"
                            placeholder="{{ 'ProfitPercentage' | translate }}"
                            (change)="calcSalePrice()"
                          />
                          <div
                            class="messages text-danger"
                            *ngIf="
                              (mainForm.get('ProfitPercentage').hasError('required') ||
                                mainForm.get('ProfitPercentage').invalid) &&
                              (mainForm.get('ProfitPercentage').dirty ||
                                mainForm.get('ProfitPercentage').touched)
                            "
                          >
                            {{ "ProfitPercentageRequired" | translate }}
                          </div>
                        </div>
            
                        <div class="col-md-6">
                          <label for="inputState">{{ "UnitType" | translate }}</label>
                          <select
                            id="inputState"
                            class="form-control"
                            formControlName="UnitId"
                          >
                            <option selected value="0">
                              {{ "UnitType" | translate }}
                            </option>
                            <option
                              *ngFor="let Unit of UnitList"
                              value="{{ Unit.Id }}"
                            >
                              {{ lang === "ar-EG" ? Unit.NameAr : Unit.NameEn }}
                            </option>
                          </select>
                        </div>
                      </div>
            <td></td>
            <td></td>
            <td></td>
                 
                    </form>
                  </div>
                </tab>
            </tabset>
                </div>
            </div>
            </div>
    
          </div>
    </div>
    <div class="modal-footer" dir="ltr">
        <button type="button" class="btn btn-primary" (click)="SaveItem()">{{'Add'|translate}}</button>
        <button type="button" class="btn btn-secondary" (click)="modalRef.hide()" data-dismiss="itemtemplate">{{'Close'|translate}}</button>
      </div>
  </ng-template>
  