export class BalanceNameModel{
    Id?: Number;
    NameAr?:string;
    NameEn?: string; 

    constructor(item?:BalanceNameModel){
        item = item? item:{};
        this.Id = item.Id? item.Id:0;
        this.NameAr=item.NameAr ? item.NameAr:'';
        this.NameEn=item.NameEn ? item.NameEn:'';    
    
    }

}