import { CurrencyService } from 'src/app/invoices/currencies/CurrencyService';
import { JobTitlesService } from 'src/app/HR/Organization_Structure/JobTitels_Componentes/job-titles.service';
import { JobLevelsService } from 'src/app/HR/Organization_Structure/JobLevelsComponentes/job-levels.service';
import { ContractModel } from './../../models/contract.model';
import { ContractService } from './../../contract.service';
import { Component, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NotificationType, NotificationsService } from 'angular2-notifications';
import { DataFilterer } from 'src/app/views/invoice/shared/DataFilterer';
import { DataSorter } from 'src/app/views/invoice/shared/DataSorter';
import { DataPaginator } from 'src/app/views/invoice/shared/DataPaginator';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { of, Subject } from 'rxjs';
import { SalaryDuesModel } from '../../models/salary-dues.model';
import { SalaryDeductionsModel } from '../../models/salary-deductions.model';
import { EmployeesManagementService } from 'src/app/HR/Employees/EmployeesManagement/employees-management.service';
import { EmployeesManagementModel } from 'src/app/HR/Employees/EmployeesManagement/EmployeesManagementModel';
import { JobLevelsModel } from 'src/app/HR/Organization_Structure/JobLevelsComponentes/JobLevelsModel';
import { JobTitlesModel } from 'src/app/HR/Organization_Structure/JobTitels_Componentes/JobTitlesModel';
import { CurrencyModel } from 'src/app/invoices/currencies/CurrencyModel';
import { SalaryItemsService } from '../../../salary-items/salary-items.service';
import { SalaryItemsModel } from '../../../salary-items/models/salary-items.model';
import { ContractFilesModel } from '../../models/contract-files.model';

@Component({
    selector: 'contracts',
    templateUrl: 'contracts.component.html',
    styleUrls: ['contracts.component.scss']
})
export class ContractsComponent {
    ////////////////////////////////////////////////////////////////////////////
    //  VARYING :
    //  Modify per similar component.
    ////////////////////////////////////////////////////////////////////////////
    constructor(private formBuilder: FormBuilder, private modelService: ContractService, private renderer: Renderer2,
      private notifications: NotificationsService, private translate: TranslateService,
      private employeeService:EmployeesManagementService,
      private JobLevelsService:JobLevelsService,
      private JobTitlesService:JobTitlesService,
      private CurrencyService:CurrencyService,
      private salaryItemsService:SalaryItemsService
      ) {  //Additional services for City and Country
    }
    currentScreen: any = 'Table';

    translationName: string = ' Contract'
  
    sortableKeys = [];
    searchableKeys = ['ContractCode'];
    modelObject: ContractModel = new ContractModel({});
    ogData: ContractModel [] = [ ];

  
    ngOnInit(): void {
      this.lang = localStorage.getItem('theme_lang');
      this.itemOrder = { label: 'ContractCode', property: 'ContractCode' };
      this.sortableKeys = [
        { label: 'ContractCode', property:'ContractCode' },
        { label: 'Employee', property:'EmployeeName' },
        { label: 'StartDate', property:'StartDate' },
        { label: 'EndDate', property:'EndDate' },
        { label: 'BasicSalary', property:'BasicSalary' },
        { label: 'Status', property:'Status' },
      ];
      //this.loadData();
      this.loadServices();
    }
  
    createForm(): FormGroup {
        debugger;
      return this.formBuilder.group({
        Id: [this.modelObject.Id >= 0 ? this.modelObject.Id : 0],
        ContractName                : [this.modelObject.ContractName                 ],
        BasicSalary                : [this.modelObject.BasicSalary                 ],
        Status                : [this.modelObject.Status                 ],
        ContractCode                : [this.modelObject.ContractCode                 ],
        EmployeeName                : [this.modelObject.EmployeeName                 ],
        EmployeeId                  : [this.modelObject.EmployeeId                   ],
        Description                 : [this.modelObject.Description                  ],
        JobTitleName                : [this.modelObject.JobTitleName                 ],
        JobTitleId                  : [this.modelObject.JobTitleId                   ],
        JobLevelName                : [this.modelObject.JobLevelName                 ],
        JobLevelId                  : [this.modelObject.JobLevelId                   ],
        StartDate                   : [ this.modelObject.StartDate != null ? new Date(this.modelObject.StartDate).toISOString().slice(0, 10):null],
        EndDate                     : [this.modelObject.EndDate != null ?  new Date(this.modelObject.EndDate).toISOString().slice(0, 10):null],
        JoiningDate                 : [this.modelObject.JoiningDate != null ?  new Date(this.modelObject.JoiningDate).toISOString().slice(0, 10):null],
        TestEndDate                 : [this.modelObject.TestEndDate != null ?  new Date(this.modelObject.TestEndDate).toISOString().slice(0, 10):null],
        TheDateOfSigningTheContract : [this.modelObject.TheDateOfSigningTheContract != null ?  new Date(this.modelObject.TheDateOfSigningTheContract).toISOString().slice(0, 10):null],
        Duration                    : [this.modelObject.Duration                     ],
        DurationType                : [this.modelObject.DurationType                 ],
        CurrencyId                  : [this.modelObject.CurrencyId                   ],
        ArrestCycle                 : [this.modelObject.ArrestCycle                  ],
        SalaryDues                  : [this.modelObject.SalaryDues                   ],
        SalaryDeductions            : [this.modelObject.SalaryDeductions             ],
        ContractFiles               : [this.modelObject.ContractFiles                ],
        endType:[1]

        });
    }
    mainForm : FormGroup;
    addObject() {
        debugger;
      //prevent multiclick submits. enable again after API response.
      this.currentAction = 'loading';
  
      this.modelObject = new ContractModel();
      this.modelObject = this.mainForm.getRawValue();
      this.modelObject.Id = 0;
      this.modelObject.SalaryDues = this.addedDuesList;
      this.modelObject.SalaryDeductions = this.addedDeductionsList;
      this.modelObject.ContractFiles = this.files;
      this.executeAddObject();
    }
  
    executeAddObject(){
      this.modelService.insert(this.modelObject).subscribe(result => {
        if (result === true) {
          this.loadData();
          this.currentAction = '';
          this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyAdded"));
          this.currentScreen = 'Table';
        }
        else {
          this.toastError(this.translate.instant("Error"), this.translate.instant("FailedAdd"));
          this.currentAction = '';
          
        }
      })
    }
  
    editObject() {
      this.savedPage = this.currentPage;
      this.currentAction = 'loading';
  
      let tempObj = this.mainForm.getRawValue();
      if(tempObj != this.modelObject){
        this.executeEditObject(tempObj);
      }
      else{
        this.currentScreen = 'Table';
    }
    }
  
    executeEditObject(Object)
    {
      this.modelService.update(Object).subscribe(result => {
        if (result === true) {
          this.loadData();
          this.currentAction = '';
          this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyEdited"));
          this.currentScreen = 'Table';

        }
        else {
          this.toastError(this.translate.instant("Error"), this.translate.instant("FailedEdit"));
          this.currentAction = '';
        }
      })
    }
  
    //Deletion methods:
    //Customize if needed.
    //Pass the target object as parameter if the target API can only delete singular objects and not lists.
    deleteObject() {
      this.savedPage = this.currentPage;
      //prevent multiclicks. re enable after API response
      this.currentAction = 'loading';
      this.executeDeleteObject();
    }
  
    //Same here...
    executeDeleteObject(){
      this.modelService.deleteList(this.deletionList).subscribe(result => {
        if (result === true) {
          this.loadData();
          this.currentAction = '';
          this.finalizeDeletion();
        }
        else {
          this.toastError(this.translate.instant("Error"), this.translate.instant("FailedDelete"));
          this.currentAction = '';
        }
      })
    }
    ////////////////////////////////////////////////////////////////////////////
    //
    //
    //
    //
    //
    ////////////////////////////////////////////////////////////////////////////
    //  EXCLUSIVE :
    //  Specifically for this component.
    ///////////////////////////////
  
    //Get any additional data here
    employees:EmployeesManagementModel[]=[];
    JobLevels:JobLevelsModel[]=[];
    JobTitles:JobTitlesModel[]=[];
    currencies:CurrencyModel[]=[];
    salaryItems:SalaryItemsModel[]=[];
    duesItems:SalaryItemsModel[]=[];
    deducationsItems:SalaryItemsModel[]=[];
    loadServices(){ 
      this.employeeService.getAll()
      .subscribe(result =>{
        this.employees = result;
      })
      this.JobLevelsService.getAll()
      .subscribe(result =>{
        this.JobLevels = result;
      })
      this.JobTitlesService.getAll()
      .subscribe(result =>{
        this.JobTitles = result;
      })
      this.CurrencyService.getAll()
      .subscribe(result =>{
        this.currencies = result;
      })
      this.salaryItemsService.getAll()
      .subscribe(result => {
        debugger;
        this.salaryItems = result;
        this.salaryItems.forEach(element => {
          if(element.SalaryItemTypeNameEn == 'Deduction'){
            this.deducationsItems.push(element)
          }
          else if(element.SalaryItemTypeNameEn == 'Due'){
            this.duesItems.push(element);
          }
        });
      })
      this.currentAction = 'loading';
      this.loadData();

    }
  
  
  
    ////////////////////////////////////////////////////////////////////////////
    //
    //
    //
    //
    //
    ////////////////////////////////////////////////////////////////////////////
    //  CONSTANT :
    //  Do not change.
    ///////////////////////////////
    lang: string;
    currentFormMode: string = '';
    currentAction: string = '';
  
  
    //ogData = [];
    filteredData = [...this.ogData];
    sortedData = [...this.filteredData];
    paginatedData = [...this.filteredData];
    lastId: number = this.ogData.length + 1;
  
    SelectAllBox: any = false;
  
    itemsPerPage = 10;
    itemOptionsPerPage = [5, 10, 20];
    selected = [];
    selectAllState = '';
    itemOrder: any;
    orderAsc: boolean = true;
  
    currentPage = 1;
    savedPage = 0;
  
  
    dataFilterer = new DataFilterer();
    dataSorter = new DataSorter();
    dataPaginator = new DataPaginator();
  
    loadData() {
        debugger;
      this.currentAction = 'loading';
      this.modelService.getAll().subscribe(
        data => {
          this.ogData = data;
          this.sortData();
          this.loadSearch();
          this.currentAction = '';
        }
      )
      
    }
  
    sortData() {
        debugger;
      this.sortedData = this.dataSorter.sortData(this.itemOrder.property, this.ogData);
      this.filterData();
    }
  
    reverseSortData() {
      this.savedPage = this.currentPage;
      this.sortedData = this.sortedData.slice().reverse();
      this.filterData();
    }
  
    filterData() {
        debugger;
      this.filteredData = this.dataFilterer.filterData(this.SearchTerm, this.searchableKeys, this.sortedData)
      if (this.savedPage === 0) {
        this.paginateData(this.itemsPerPage, 1);
      }
      else {
        this.paginateData(this.itemsPerPage,
          Math.ceil((this.filteredData.length / this.itemsPerPage)) >= this.savedPage ?
            this.savedPage :
            Math.ceil((this.filteredData.length / this.itemsPerPage))
        );
      }
      this.savedPage = 0;
    }
  
    paginateData(newItemsPerPage: number, newCurrentPage: number) {
        debugger;

      this.itemsPerPage = newItemsPerPage;
      this.currentPage = newCurrentPage;
      this.paginatedData = this.filteredData.slice((newCurrentPage - 1) * (newItemsPerPage), (newItemsPerPage * newCurrentPage));
    }
  
    changeSort(newSort: any) {
      if (this.itemOrder.label === newSort.label) {
        this.orderAsc = !this.orderAsc;
        const oldPage = this.currentPage;
        this.reverseSortData()
        this.currentPage = oldPage;
      }
      else {
        this.orderAsc = true;
        this.itemOrder = newSort;
        this.sortData();
      }
    }
  
    getSort(label: any) {
      return this.sortableKeys.find(i => i.label === label);
    }
  
    changePage(event: PageChangedEvent): void {
      this.currentPage = event.page;
      this.paginateData(this.itemsPerPage, this.currentPage);
    }
  
    changeFilter() {
      this.deletionList = [];
      this.selected = [];
      this.selectAllState = '';
      this.filterData();
    }
  
    selectAll($event): void {
      if ($event.target.checked) {
        this.selected = [...this.filteredData];
      } else {
        this.selected = [];
      }
      this.setSelectAllState();
    }
  
    setSelectAllState(): void {
      if (this.selected.length >= this.filteredData.length) {
        this.selectAllState = 'checked';
      } else if (this.selected.length !== 0) {
        this.selectAllState = 'indeterminate';
      } else  {
        this.selectAllState = '';
      }
    }
  
    isSelected(p: ContractModel): boolean {
      return this.selected.findIndex(x => x.Id === p.Id) > -1;
    }
  
    onSelect(item: ContractModel): void {
      if (this.isSelected(item)) {
        this.selected = this.selected.filter(x => x.Id !== item.Id);
      } else {
        this.selected.push(item);
      }
      this.setSelectAllState();
    }
  
    findByCode(list: any, code: any): any {
      return list.find(x => x.Code === code)
    }
  
    @ViewChild('delModal') delModal: ModalDirective;
    showAddNewModalRef(): void {
        debugger;
     
      this.modelObject = new ContractModel();
      this.mainForm = this.createForm();
      this.currentFormMode = 'Add'
      this.currentScreen = 'Add';
      this.modelService.GetLastCode()
      .subscribe(code=>{
        this.modelObject.ContractCode = code+1
        this.mainForm.controls['ContractCode'].setValue(this.modelObject.ContractCode);
      })
      this.addedDeductionsList = [];
      this.addedDuesList=[];
      this.files = [];
      this.salaryDueModel = new SalaryDuesModel();
      this.dueForm = this.createDueForm(this.salaryDueModel);
      this.salaryDeductionsModel = new SalaryDeductionsModel();
      this.DeductionsForm = this.createDueForm(this.salaryDeductionsModel);
    }
    showEditModalRef(Id: any): void {
      this.currentFormMode = 'Edit'
      this.currentScreen = 'Edit';

      this.modelObject = this.ogData.find(i => i.Id === Id);
      of(this.modelObject).subscribe(data => {
        this.modelObject = data;
        this.addedDuesList = this.modelObject.SalaryDues ;
        this.addedDeductionsList = this.modelObject.SalaryDeductions  ;
        this.files = this.modelObject.ContractFiles;
        this.salaryDueModel = new SalaryDuesModel();
        this.dueForm = this.createDueForm(this.salaryDueModel);
        this.salaryDeductionsModel = new SalaryDeductionsModel();
        this.DeductionsForm = this.createDueForm(this.salaryDeductionsModel);
      })
      this.mainForm = this.createForm();
    }
    
    showInfoModalRef(Id: any): void {
      debugger;
    
      this.modelObject = this.ogData.find(i => i.Id === Id);
      of(this.modelObject).subscribe(data => {
        this.modelObject = data;
        this.addedDuesList = this.modelObject.SalaryDues ;
        this.addedDeductionsList = this.modelObject.SalaryDeductions  ;
        this.files = this.modelObject.ContractFiles;
        this.mainForm = this.createForm();
        this.currentFormMode = 'Info'
        this.currentScreen = 'Info';
      })
    }

    detailsForm:FormGroup;

 
    deletionList: ContractModel[] = [];
    showDelModal(Id: any): void {
      if (Id >= 0) {
        this.currentFormMode = 'Del'
        this.modelObject = this.ogData.find(i => i.Id === Id);
        this.deletionList = [this.ogData.find(i => i.Id === Id)];
        this.delModal.show();
      }
      else if (Id === 'delMulti') {
        this.deletionList = [...this.selected]
        this.delModal.show();
      }
    }
  
    SearchTerm: any = '';
  
  
    finalizeDeletion(){
      if (this.deletionList.length === 1) {
        this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyDeleted") + " " +
          this.translate.instant("One" + this.translationName));
      }
      else if (this.deletionList.length === 2) {
        this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyDeleted") + " " +
          this.translate.instant("Two" + this.translationName + "s"));
      }
      else if (this.deletionList.length > 2 && this.deletionList.length < 11) {
        this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyDeleted") + " " +
          this.deletionList.length + " " + this.translate.instant(this.translationName + "RowsPlur"));
      }
      else {
        this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyDeleted") + " " +
          this.deletionList.length + " " + this.translate.instant(this.translationName + "Rows"));
      }
  
      this.deletionList = [];
      this.selected = [];
  
      this.setSelectAllState();
      if (this.selectAllState === '') {
        this.SelectAllBox = false;
      }
      this.delModal.hide();
    }
  
    toastSuccess(toastHeader: string, toastBody: string): void {
      this.notifications.create(toastHeader, toastBody,
        NotificationType.Success, { timeOut: 3000, showProgressBar: true });
    }
    toastError(toastHeader: string, toastBody: string): void {
      this.notifications.create(toastHeader, toastBody,
        NotificationType.Error, { timeOut: 3000, showProgressBar: true });
    }
    deleteMultiTaxTypes() {
      this.loadData();
      this.delModal.hide();
    }
  
    loadAllData() {
      this.modelService.getAll().subscribe(
        data => {
          this.ogData = data;
          this.loadSearch();
        }
      )
    }
  
    loadSearch() {
      const prevSearch = this.SearchTerm;
      this.SearchTerm = '';
      setTimeout(() => {
        this.SearchTerm = prevSearch;
      }, 1);
    }
    addedDuesList:SalaryDuesModel[]=[];
    salaryDueModel:SalaryDuesModel={};
    addedDeductionsList:SalaryDeductionsModel[]=[];
    salaryDeductionsModel:SalaryDeductionsModel={};
    dueForm:FormGroup;
    DeductionsForm:FormGroup;
    
    createDueForm(model):FormGroup{
      return this.formBuilder.group({
        Id:[model.Id],
        ContractId:[model.ContractId],
        SalaryItemId:[model.SalaryItemId,Validators.required],
        Amount:[model.Amount,Validators.required],
      })
    }
    addDeductions(){
      var detail = this.DeductionsForm.getRawValue();
      var itemIndex=this.deducationsItems.findIndex(x=>x.Id == detail.SalaryItemId);
      if(itemIndex > -1){
        detail.SalaryItemName = this.deducationsItems[itemIndex].ItemName;
      }
      this.addedDeductionsList.push(detail);
      this.salaryDeductionsModel = new SalaryDeductionsModel();
      this.DeductionsForm = this.createDueForm(this.salaryDeductionsModel );
    }
    removeDeductions(obj){
      var deletedIndex = this.addedDeductionsList.indexOf(obj);
      if(deletedIndex > -1){
          this.addedDeductionsList.splice(deletedIndex,1);
      }
    }
    addDue(){
      var detail = this.dueForm.getRawValue();
      var itemIndex=this.duesItems.findIndex(x=>x.Id == detail.SalaryItemId);
      if(itemIndex > -1){
        detail.SalaryItemName = this.duesItems[itemIndex].ItemName;
      }
      this.addedDuesList.push(detail);
      this.salaryDueModel = new SalaryDuesModel();
      this.dueForm = this.createDueForm(this.salaryDueModel );
    }
    removeDue(obj){
      var deletedIndex = this.addedDuesList.indexOf(obj);
      if(deletedIndex > -1){
          this.addedDuesList.splice(deletedIndex,1);
      }
    }
    public editEnabled = true;
    public picurl: string ='';
    files:ContractFilesModel[]=[];
    setFileToList(){
        var file = new ContractFilesModel();
        file.ContractId = this.modelObject.Id ;
        file.FileUrl = this.picurl;
        file.Id = 0;
        this.files.push(file);
    }
    deleteFile(file){
        var i = this.files.indexOf(file);
        if(i>-1){
            this.files.splice(i,1);
        }
    }
    getEmployeeName(Id){
      var i = this.employees.findIndex(x=>x.Id == Id);
      if(i>-1){
        return this.employees[i].Name;
      }
      else{
        return '';
      }
    }
    getJobTitleName(Id){
      var i = this.JobTitles.findIndex(x=>x.Id == Id);
      if(i>-1){
        return this.JobTitles[i].Name;
      }
      else{
        return '';
      }
    }
    getJobLevelName(Id){
      var i = this.JobLevels.findIndex(x=>x.Id == Id);
      if(i>-1){
        return this.JobLevels[i].Name;
      }
      else{
        return '';
      }
    }
  }
  