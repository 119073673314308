<!--component html goes here -->
<div bsModal #smallModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">
    <div class="modal-dialog modal-sm">
        <div class="modal-content"
        style="width: 90%; text-align: center; margin-top: 20px; margin-left: auto; margin-right: auto;">
        <div style="align-content: center; display: inline-block;">
            <div class="modal-header" style="display: block; padding: 0px 0px 5px 0px; text-align: center; ">
                <h1 id="dialog-child-name" class="modal-title">
                    {{ currentFormMode+translationName | translate}}</h1>
            </div>
            <div class="modal-body">
                <form class="md-float-material form-material" [formGroup]="mainForm">
                    <div class="form-group form-primary">
                        <label class="float-label push righ">{{"ArabicName" | translate}}</label>
                        <input *ngIf=" currentFormMode==='Add'|| currentFormMode==='Edit'" type="text" name="ArabicName"
                            class="form-control" formControlName="ArabicName"
                            placeholder='{{"ArabicName" | translate}}'>
                        <label *ngIf=" currentFormMode==='Info'" class="form-control" type="text"
                            name="ArabicName">{{modelObject.ArabicName}}</label>
                        <!-- <div class="messages text-danger"
                            *ngIf=" (mainForm.get('ArabicName').hasError('required') || mainForm.get('ArabicName').invalid) && ( mainForm.get('ArabicName').dirty ||  mainForm.get('ArabicName').touched)">
                            {{"ArabicNameRequired" | translate}}</div> -->
                        <span class="form-bar"></span>
                    </div>

                    <div class="form-group form-primary">
                        <label class="float-label push righ">{{"EnglishName" | translate}}</label>
                        <input *ngIf=" currentFormMode==='Add'|| currentFormMode==='Edit'" type="text" name="EnglishName"
                            class="form-control" formControlName="EnglishName"
                            placeholder='{{"EnglishName" | translate}}'>
                        <label *ngIf=" currentFormMode==='Info'" class="form-control" type="text"
                            name="EnglishName">{{modelObject.EnglishName}}</label>
                        <!-- <div class="messages text-danger"
                            *ngIf=" (mainForm.get('EnglishName').hasError('required') || mainForm.get('EnglishName').invalid) && ( mainForm.get('EnglishName').dirty ||  mainForm.get('EnglishName').touched)">
                            {{"EnglishNameRequired" | translate}}</div> -->
                        <span class="form-bar"></span>
                    </div>
                    
                    <div style="margin-left: 5px; text-align: center; margin-top: 30px;">
                        <button *ngIf=" currentFormMode==='Add'"
                            [disabled]="mainForm.invalid ||  currentAction==='loading'" id="smallModalSubmitButton"
                            class="btn btn-secondary" (click)="addObject()"
                            style="font-weight: bold; width: 100%; text-align: center;">{{"Add" |
                            translate}}</button>
                        <button *ngIf=" currentFormMode==='Edit'"
                            [disabled]="mainForm.invalid ||  currentAction==='loading'" id="smallModalSubmitButton"
                            class="btn btn-secondary" (click)="editObject()"
                            style="font-weight: bold; width: 100%; text-align: center;">{{"Edit"
                            | translate}}</button>
                        <button class="btn btn-light" (click)="smallModal.hide()"
                            style="font-weight: bold; width: 100%; text-align: center; margin-top: 10px;">{{"Close" |
                            translate}}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    </div>
</div>


<div bsModal #delModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">
    <div class="modal-dialog modal-sm">
        <div class="modal-content"  >
            <button type="button" class="close pull-right" aria-label="Close" (click)="delModal.hide()"
                style="margin-right: 20px; margin-left: 20px; margin-top: 10px; width: 20px; display: inline;">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-header" style="display: inline; padding: 0px 0px 5px 0px;">
                <h4 id="dialog-child-name" class="modal-title pull-left"  style="text-align: center;">
                    {{"ConfirmDel"+translationName |
                    translate}}</h4>
            </div>
            <div class="modal-body">
                <div class="form-group form-primary" *ngIf=" deletionList.length <  ogData.length 
                ||  deletionList.length === 1" 
                style="text-align: center;">
                    <div *ngFor="let item of  deletionList;" style="text-align: center;">
                        <label type="text">- {{lang==='ar-EG'?item.NameEn:item.NameEn}}</label>
                        <span class="form-bar"></span>
                    </div>
                </div>
                <div class="form-group form-primary" style="text-align: center;" *ngIf=" deletionList.length > 1 && 
                     deletionList.length ===  ogData.length">
                    <label type="text">
                        <h1 style="font-weight: bolder; text-align: center;">{{translationName+"DeleteAll" | translate}}</h1>
                    </label>
                    <span class="form-bar"></span>
                </div>

                <div style="margin-left: 5px; text-align: center; margin-top: 30px;">
                    <button class="btn btn-danger" id="smallModalDeleteButton" (click)="deleteObject()"
                        [disabled]=" currentAction==='loading'"
                        style="font-weight: bold; width: 100%; text-align: center;">{{"Delete" | translate}}</button>
                    <button class="btn btn-light" (click)="delModal.hide()"
                        style="font-weight: bold; width: 100%; text-align: center; margin-top: 10px;">{{"Close" |
                        translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div  *ngIf="currentScreen === 'Form'" class="FormScreen">
    <a (click)="currentScreen='Table'">back</a>
</div>

<div *ngIf="currentScreen === 'Table'" class="FormScreen" class="tableScreen">
    <div id="divtop" style="display: block;height: 47px; width: 100%;">
        <div id="divsearchmiddle" style="display: inline-block; width: 70%;
        height: inherit;  text-align: center; padding: 0px; margin: 0px;">
            <div class="search" style="display: inline-block; margin-left: 50px; margin-right: 50px;">
                <div class="d-inline-block">
                    <div class="search-sm d-inline-block mr-1 mb-1 align-top" style="width: 600px;">
                        <input type="search" ngDefaultControl [(ngModel)]=" SearchTerm" (keyup)=" changeFilter()" (change)=" changeFilter()"
                            class="form-control input-sm full-data-search" placeholder="{{translationName+'Search' | translate}}">
                    </div>
                </div>
            </div>
        </div>
    
        <div id="divsearchleft" style="display: inline-block; width: 30%; height: inherit;">
            <div style="display: block; height: 30%; ">
                &nbsp;
            </div>
            <div class="search" style="display: block; height: 70%; text-align: center;">
                <button class="btn btn-primary d-inline-block"
                    style="font-weight: bold; text-align: center; padding: 0px; width: 150px; height: 30px;"
                    (click)="showModal(null,'Add')" [disabled]=" currentAction==='loading'">{{"Add"+translationName |
                    translate}}</button>
    
            </div>
        </div>
    
    </div>
    
        
    <div id="divmiddle" style="display: block; margin-top: 5px;">
    
        <div style="width: 100%;">
            <div>
                <table class="table">
                    <thead class="thead-light">
                        <th colspan="2" style="text-align: center; padding:5px;">
                            <div class=" text-zero" style="display: inline-block;">
                                <div class="top-right-button-container2 btn-group" dropdown>
                                    <div class="btn btn-primary pl-4 pr-0 check-button">
                                        <label class="custom-control custom-checkbox mb-0 d-inline-block"
                                            style="width: 10px;">
                                            <input type="checkbox" class="custom-control-input"
                                                [checked]="selectAllState==='checked'"
                                                [indeterminate]="selectAllState === 'indeterminate'"
                                                (change)="selectAll($event)">
                                            <span class="custom-control-label">&nbsp;</span>
                                        </label>
                                    </div>
                                    <button id="button-split" type="button" dropdownToggle style="width: 10px;"
                                        class="btn btn-primary dropdown-toggle dropdown-toggle-split"
                                        aria-controls="dropdown-split">
                                        <span class="caret"></span>
                                    </button>
                                    <ul id="dropdown-split" *dropdownMenu class="dropdown-menu dropdown-menu-right"
                                        role="menu" aria-labelledby="button-split">
                                        <li role="menuitem"><a class="dropdown-item">
                                                <button class="btn btn-danger d-inline-block"
                                                    style=" font-weight: bold; text-align: center; padding: 0px; width: 150px; height: 30px;"
                                                    (click)="showDelModal('delMulti')"
                                                    [disabled]="selected.length===0 || currentAction==='loading'">{{"DeleteSelected"
                                                    |
                                                    translate}}</button></a></li>
                                                    <!-- <li role="menuitem"><a class="dropdown-item">
                                                        <button class="btn btn-primary d-inline-block"
                                                            style=" font-weight: bold; text-align: center; padding: 0px; width: 150px; height: 30px;"
                                                            
                                                            [disabled]="selected.length===0 || currentAction==='loading'">{{"SendSelected"
                                                            |
                                                            translate}}</button></a></li> -->
                                    </ul>
                                </div>
                            </div>
                        </th>
                        <th class="sorticon" (click)="changeSort(getSort('Id'))" [ngClass]="(itemOrder.label==='Id')?'sortkey sorticon':'sorticon'">
                            <div [ngClass]="{'iconsminds-up---down': (itemOrder.label!='Id'), 
                            'iconsminds-down': ((itemOrder.label==='Id') && orderAsc === true),
                            'iconsminds-up': ((itemOrder.label==='Id') && orderAsc === false)}"></div></th>
                        <th class="sortOption" (click)="changeSort(getSort('Id'))" 
                        [ngClass]="{'sortkey': (itemOrder.label==='Id')}">
                                {{"Id" | translate}}
                        </th>
                        
                        <!-- <th class="sorticon" (click)="changeSort(getSort('InvoiceTaxActivityCode'))" [ngClass]="(itemOrder.label==='InvoiceTaxActivityCode')?'sortkey sorticon':'sorticon'">
                            <div [ngClass]="{'iconsminds-up---down': (itemOrder.label!='InvoiceTaxActivityCode'), 
                            'iconsminds-down': ((itemOrder.label==='InvoiceTaxActivityCode') && orderAsc === true),
                            'iconsminds-up': ((itemOrder.label==='InvoiceTaxActivityCode') && orderAsc === false)}"></div></th>
                        <th class="sortOption" (click)="changeSort(getSort('InvoiceTaxActivityCode'))" 
                        [ngClass]="{'sortkey': (itemOrder.label==='InvoiceTaxActivityCode')}">
                                {{"InvoiceTaxActivityCode" | translate}}
                        </th> -->
                        
                        <th class="sorticon" (click)="changeSort(getSort('ArabicName'))" [ngClass]="(itemOrder.label==='ArabicName')?'sortkey sorticon':'sorticon'">
                            <div [ngClass]="{'iconsminds-up---down': (itemOrder.label!='ArabicName'), 
                            'iconsminds-down': ((itemOrder.label==='ArabicName') && orderAsc === true),
                            'iconsminds-up': ((itemOrder.label==='ArabicName') && orderAsc === false)}"></div></th>
                        <th class="sortOption" (click)="changeSort(getSort('ArabicName'))" 
                        [ngClass]="{'sortkey': (itemOrder.label==='ArabicName')}">
                                {{"ArabicName" | translate}}
                        </th>
                        
                        <th class="sorticon" (click)="changeSort(getSort('EnglishName'))" [ngClass]="(itemOrder.label==='EnglishName')?'sortkey sorticon':'sorticon'">
                            <div [ngClass]="{'iconsminds-up---down': (itemOrder.label!='EnglishName'), 
                            'iconsminds-down': ((itemOrder.label==='EnglishName') && orderAsc === true),
                            'iconsminds-up': ((itemOrder.label==='EnglishName') && orderAsc === false)}"></div></th>
                        <th class="sortOption" (click)="changeSort(getSort('EnglishName'))" 
                        [ngClass]="{'sortkey': (itemOrder.label==='EnglishName')}">
                                {{"EnglishName" | translate}}
                        </th>
                       
                        <th [colSpan]="2" style="text-align: center;">
                            <div id="displayOptions" style="
                            display: inline; margin-top: 10px; margin-left: 5px; margin-right: 5px;">
                                <span class="text-muted text-small" style=" width: 50px; height: 40px;">
                                    {{"ResultCount" | translate}}
                                </span>
                                <span dropdown class="d-inline-block position-relative">
                                    <button class="btn btn-outline-dark btn-xs dropdown-toggle" type="button"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" dropdownToggle>
                                        {{itemsPerPage}}
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-sm text-right"
                                        *dropdownMenu>
                                        <li role="menuitem" *ngFor="let item of itemOptionsPerPage"><a
                                                [ngClass]="item === itemsPerPage ? 'dropdown-item active' : 'dropdown-item'"
                                                (click)="paginateData(item, 1)">{{item}}</a></li>
                                    </div>
                                </span>
                            </div>
                        </th>
                        <th></th>
                        <th></th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let obj of paginatedData;  let i=index">
                            <td colspan="2" style="text-align: center;">
                                <div class="itemCheck mb-0 custom-checkbox custom-control" style="display: inline-block;">
                                    <input type="checkbox" class="custom-control-input" [id]="'customCheck'+i"
                                        [checked]="isSelected(obj)" (change)="onSelect(obj)">
                                    <label class="custom-control-label" [for]="'customCheck'+i"></label>
                                </div>
                            </td>
                            <td colspan="2">{{obj.Id}}</td>
                 
                            <td colspan="2">{{obj.ArabicName }}</td>
                            <td colspan="2">{{obj.EnglishName }}</td>
                       
                            <td><button class="btn btn-primary text-center" 
                                    [disabled]="currentAction==='loading' " (click)="showPINModalRef(obj.Id)" style="font-weight: bold; width: 100%;">{{"Edit"
                                    | translate}}</button></td>
                                    <td><button class="btn btn-info text-center" (click)="print(obj.Id)"
                                        [disabled]="currentAction==='loading'" style="font-weight: bold; width: 100%;">{{"View"
                                        | translate}}</button></td>
                            <td><button class="btn btn-outline-danger text-center" (click)="showDelModal(obj.Id)"
                                    [disabled]="currentAction==='loading'" style="font-weight: bold; width: 100%">{{"Delete"
                                    | translate}}</button></td>
                        </tr>
                    </tbody>
                    <!-- <tbody>
                        <tr *ngFor="let obj of  paginatedData;  let i=index">
                            <td style="text-align: center;">
                                <div class="itemCheck mb-0 custom-checkbox custom-control" style="display: inline-block;">
                                    <input type="checkbox" class="custom-control-input" [id]="'customCheck'+i"
                                        [checked]=" isSelected(obj)" (change)=" onSelect(obj)">
                                    <label class="custom-control-label" [for]="'customCheck'+i"></label>
                                </div>
                            </td>
                            <td colspan="2">{{lang==='ar-EG'?obj.ArabicName:obj.EnglishName}}</td>
                   
                                    <td style="text-align: center">
                                        <i
                                          class="simple-icon-info"
                                          (click)="showModal(obj.Id, 'Info')"                  
                                          tooltip="{{ 'Info' | translate }}"
                                          placement="auto"
                                          style="
                                            font-size: 20px;
                                            font-weight: bolder;
                                            cursor: pointer;
                                            vertical-align: middle;
                                            color: #17a2b8;
                                            margin: 10px;
                                          "
                                          
                                         
                                        ></i>
                                        <i
                                        class="simple-icon-pencil"
                                        (click)="showModal(obj.Id, 'Edit')"
                                        tooltip="{{ 'Edit' | translate }}"
                                        placement="auto"
                                        style="
                                          font-size: 20px;
                                          font-weight: bolder;
                                          cursor: pointer;
                                          vertical-align: middle;
                                          color: #ffc107;
                                          margin: 10px;
                                        "
                                       
                                       
                                      ></i>
                                      <i
                                      class="simple-icon-close"
                                      (click)=" showDelModal(obj.Id)"
                                      tooltip="{{ 'Delete' | translate }}"
                                      placement="auto"
                                      style="
                                        font-size: 20px;
                                        font-weight: bolder;
                                        cursor: pointer;
                                        vertical-align: middle;
                                        color: #dc3545;
                                        margin: 10px;
                                      "
                                    ></i>
                                      </td>
                        </tr>
                    </tbody> -->
                    <thead class="thead-light">
                  
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <!-- <th></th>
                        <th></th> -->
                    </thead>
                </table>
            </div>
        </div>
    </div>
    
    <div id="divbottom" style="display: block; height: 47px;">
    
        <div id="divsearchright" style="float: right;  width: 33.33%; padding: 0px;">&nbsp;</div>
    
        <div id="divsearchmiddle" style="display: inline-block; width: 33.33%; height: 100%;">
    
            <div style="text-align: center;">
                <bdo dir="ltr">
                    <pagination class="pagination-sm2" style="padding: 0px, 0px, 0px, 0px; margin: 0px, 0px, 0px, 0px;
                    display: inline-block;" ngDefaultControl [(ngModel)]="currentPage" [boundaryLinks]="true"
                        [totalItems]=" filteredData.length" [itemsPerPage]=" itemsPerPage"
                        [customFirstTemplate]="firstTemplate" [customLastTemplate]="lastTemplate"
                        [customPreviousTemplate]="prevTemplate" [customNextTemplate]="nextTemplate" [maxSize]="5"
                        (pageChanged)=" changePage($event)">
                    </pagination>
                </bdo>
            </div>
        </div>
    
        <div id="divsearchleft" style="float: left; width: 33.33%; height: 100%;">&nbsp;</div>
    
    </div>
</div>

<div>

</div>

<ng-template #nextTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i *ngIf="lang==='en-US'" class='simple-icon-arrow-right'></i>
    <i *ngIf="lang==='ar-EG'" class='simple-icon-arrow-left'></i>
</ng-template>

<ng-template #prevTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i *ngIf="lang==='en-US'" class='simple-icon-arrow-left'></i>
    <i *ngIf="lang==='ar-EG'" class='simple-icon-arrow-right'></i>
</ng-template>

<ng-template #lastTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i class='simple-icon-control-end'></i>
</ng-template>

<ng-template #firstTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i class='simple-icon-control-start'></i>
</ng-template>


<simple-notifications></simple-notifications>