import { Component, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { NotificationsService } from "angular2-notifications";
import { ModalDirective } from "ngx-bootstrap/modal";
import { TableEngine } from "src/app/invoices/TableEngine";
import { SidePopupComponent } from "src/app/views/invoice/side-popup/side-popup.component";
import { ItemsPriceListModel } from "../../models/ItemsPriceListModel";
import { ItemTaxModel } from "../../models/ItemTaxModel";
import { PriceListModel } from "../../models/PriceListModel";
import { StoreItemsModel } from "../../models/StoreItemsModel";
import { WarehouseModel } from "../../models/WarehouseModel";
import { PriceListService } from "../../Price List/price-list/price-list.service";
import { WarehouseService } from "../../warehouses/warehouse-service.service";
import { ProductesService } from "../productes.service";
import { BrandItemsModel } from "../../models/BrandItemsModel";
import { CategoryItemsModel } from "../../models/CategoryItemsMosel";
import{BrandServiceService}from'src/app/Invintory/warehouses/brand-service.service';
import{CategoryServiceService}from'src/app/Invintory/warehouses/category-service.service';
import { BrandModel } from "../../models/BrandModel";
import { CategoryModel } from "../../models/CategoryModel";
import { WarehouseItemModel } from "../../models/WarehouseItem";
import {UnitTypeService} from '../../unit-type.service';
import { UnitTypeModel } from "../../models/UnitTypeModel";

@Component({
  // moduleId: module.id,
  selector: "add-productes",
  templateUrl: "add-productes.component.html",
  styleUrls: ["add-productes.component.scss"],
})
export class AddProductesComponent {
  Id = 0;

  constructor(
    public router: Router,
    private fb: FormBuilder,
    private modelService: ProductesService,
    private PriceListService: PriceListService,
    private UnitTypeService:UnitTypeService,
    private WarehouseService: WarehouseService,private BrandService: BrandServiceService,
    private CategorySevice: CategoryServiceService,
    private notifications: NotificationsService,
    private translate: TranslateService,
    private route: ActivatedRoute
  ) {
    this.Id = +route.snapshot.paramMap.get("Id");
  }

  tableEngine = new TableEngine(this.translate, this.notifications);
  currentScreen: any = "Table";
  translationName: string = "Item";
  sortableKeys = [];
  searchableKeys = ["Name", "Address"];
  tableView: any = true;

  StoreItemsModel:StoreItemsModel[]=[];

  WarehouseData: WarehouseModel[] = [];

  modelObject: StoreItemsModel = new StoreItemsModel();
  Form: FormGroup;
  declare lang: any;
  declare itemOrder: any;
  currentAction = "";
  savedPage = 0;
  currentPage = 1;
  deletionList: any[] = [];
  prevAction = "";
  addToListId = 0;
  ItemsBalanceHeaderId = 0;
  ItemBalanceId = 0;
  currentFormMode: string = "";
  type = 0;
  visable = "";
  visable2 = "";
  visable3 = "";
AlertMessage :string="Alert me when the quantity reaches less than";
  ogData: StoreItemsModel[] = [];
  mainForm: FormGroup = new FormGroup({
    Id: new FormControl(),
    Name: new FormControl(),
    SkuNumber: new FormControl(),
    Description: new FormControl(),
    PurchasingPrice: new FormControl(),
    SalePrice: new FormControl(),
    FirstTaxId: new FormControl(),
    SecondTaxId: new FormControl(),
    LowestSellingPrice: new FormControl(),
    Discount: new FormControl(),
    ProfitPercentage: new FormControl(),
    PriceListId: new FormControl(),
    StoreDataId: new FormControl(),
    Quantity: new FormControl(),
    Notes: new FormControl(),
    Tags: new FormControl(),
    IsDisable: new FormControl(),
    WarehouseName: new FormControl(),
    Alert: new FormControl(),
    AvailableOnline: new FormControl(),
    FeaturedProduct: new FormControl(),
    Barcode:new FormControl(),
    UnitId:new FormControl(),
    DiscountType:new FormControl(),
    
  });

  @ViewChild("sideModalRef", { static: true })
  sideModalRef: SidePopupComponent = null;
  @ViewChild("delModal", { static: true }) delModal: ModalDirective;
  @ViewChild("smallModal", { static: true }) smallModal: ModalDirective = null;
  formPresentationModal: any;
  ngOnInit(): void {
    // this.getAllCountryCode();

    this.lang = localStorage.getItem("theme_lang");
    this.itemOrder = {
      label: "Name",
      property: this.lang === "ar-EG" ? "Name" : "Name",
    };
    this.sortableKeys = [
      // { label: 'Date', property: 'Date' },
      { label: "Name", property: this.lang === "ar-EG" ? "Name" : "Name" },
      { label: "SkuNumber", property: "SkuNumber" },
      { label: "Description", property: "Description" },
      { label: "PurchasingPrice", property: "PurchasingPrice" },
      { label: "SalePrice", property: "SalePrice" },
      { label: "FirstTaxId", property: "FirstTaxId" },
      { label: "SecondTaxId", property: "SecondTaxId" },
      { label: "LowestSellingPrice", property: "LowestSellingPrice" },
      { label: "Discount", property: "Discount" },
      { label: "ProfitPercentage", property: "ProfitPercentage" },
      { label: "PriceListId", property: "PriceListId" },
      { label: "StoreDataId", property: "StoreDataId" },
      { label: "Quantity", property: "Quantity" },
      { label: "Notes", property: "Notes" },
      { label: "Tags", property: "Tags" },
      { label: "IsDisable", property: "IsDisable" },
      { label: "IsAlertDisable", property: "Alert" },
      { label: "FeaturedProduct", property: "FeaturedProduct" },
      { label: "AvailableOnline", property: "AvailableOnline" },
      { label: "Barcode", property: "Barcode" },
      
      
    ];

    this.tableEngine.loadTableEngine(
      this.ogData,
      this.modelService,
      this.modelObject,
      this.searchableKeys,
      this.sortableKeys,
      this.mainForm,
      this.translationName,
      this.itemOrder,
      this.sideModalRef,
      this.delModal,
      this.smallModal,
      this.lang
    );
  
    this.loadServices();
    
    this.modelService.getAllTaxes().subscribe((data) => {
      this.FirstTaxesList = data;
    });
    this.modelService.getAllTaxes().subscribe((data) => {
        this.SecondTaxesList = data;
      });
    this.modelService.getLastNumber().subscribe((data) => {
      debugger;
        this.modelObject.SkuNumber = data;
        this.mainForm.controls['SkuNumber'].setValue(this.modelObject.SkuNumber+1);
      });
   
    this.WarehouseService.getAll().subscribe((data) => {
      this.WarehouseList = data;
    });
    this.UnitTypeService.getAll().subscribe(data=>{
      this.UnitList=data;
    })

    this.PriceListService.getAll()            
    .subscribe(data=>
        {
            this.PriceList=data
        });
        this.BrandService.getAll()            
        .subscribe(data=>
            {
                this.BrandList=data
            });
            
            this.CategorySevice.getAll()            
            .subscribe(data=>
                {
                    this.CategoryList=data
                });


        if(this.Id > 0)
        {   debugger;                            
                this.modelService.getById(this.Id).subscribe(data=>{                        
                this.modelObject = data;
                this.ItemsPriceList2=data.ItemPriceList;
                this.SelectedBrandList=data.ItemBrand;
                this.SelectedCategoryList=data.ItemCategory;
                this.WarehouseList2=data.WarhouseItem;
               
                this.mainForm = this.createForm(this.modelObject);
               

            });
           

        }
        else{
                
           this.mainForm = this.createForm(this.modelObject);                              


        }
    this.mainForm = this.createForm(this.modelObject);

  }

  createForm(modelObject): FormGroup {
    debugger;
    return this.fb.group({
      Id: [modelObject.Id >= 0 ? modelObject.Id : 0],
      Name: [modelObject.Name, Validators.compose([Validators.required])],
      SkuNumber: [
        modelObject.SkuNumber,
        Validators.compose([Validators.required, Validators.minLength(2)]),
      ],
      PurchasingPrice: [
        modelObject.PurchasingPrice,
        Validators.compose([Validators.required]),
      ],
      SalePrice: [modelObject.SalePrice],
      FirstTaxId: [modelObject.FirstTaxId],
      SecondTaxId: [modelObject.SecondTaxId],
      LowestSellingPrice: [modelObject.LowestSellingPrice],
      Description: [modelObject.Description],
      Discount: [modelObject.Discount],
      ProfitPercentage: [modelObject.ProfitPercentage],
      // WarehouseDataId:[modelObject.WarehouseDataId],
      // Quantity: [modelObject.Quantity],
      Notes: [modelObject.Notes],
      Tags: [modelObject.Tags],
      WarehouseName: [modelObject.WarehouseName],
      Alert: [modelObject.Alert],
      AvailableOnline: [modelObject.AvailableOnline],
      FeaturedProduct: [modelObject.FeaturedProduct],
      Barcode: [modelObject.Barcode],
      IsDisable: [modelObject.IsDisable == null ? true : modelObject.IsDisable],
      UnitId:[modelObject.UnitId],
      DiscountType:[modelObject.DiscountType],
    
      
    });
  }
  DiscountList:[{"%","$"}];
  FirstTaxesList: ItemTaxModel[] = [];
  SecondTaxesList: ItemTaxModel[] = [];
  UnitList:UnitTypeModel[]=[];
  PriceList: PriceListModel[] = [];
  WarehouseList: WarehouseModel[] = [];
  BrandList: BrandModel[] = [];
  CategoryList: CategoryModel[] = [];
  loadServices() {
    this.currentAction = "loading";
    this.tableEngine.loadData();
  }
  calcSalePrice(){
    // var i = this.StoreItemsModel.findIndex(x=>x.Id == Item);
    this.modelObject.PurchasingPrice = +this.mainForm.getRawValue().PurchasingPrice;
    this.modelObject.ProfitPercentage = +this.mainForm.getRawValue().ProfitPercentage;


        var pre=+(this.modelObject.ProfitPercentage/100)*this.modelObject.PurchasingPrice;
        this.modelObject.SalePrice = +this.modelObject.PurchasingPrice +pre;
        this.mainForm.controls['SalePrice'].setValue(this.modelObject.SalePrice)
    
  }
  calcProfitPrice(){
    this.modelObject.PurchasingPrice = +this.mainForm.getRawValue().PurchasingPrice;
    this.modelObject.SalePrice = +this.mainForm.getRawValue().SalePrice;

    // var pre=+(this.modelObject.SalePrice-this.modelObject.PurchasingPrice);

        var pre=this.modelObject.SalePrice-this.modelObject.PurchasingPrice;
        this.modelObject.ProfitPercentage = +((pre/this.modelObject.PurchasingPrice)*100);
        // this.modelObject.ProfitPercentage = +(pre/(this.modelObject.PurchasingPrice*100));

        this.mainForm.controls['ProfitPercentage'].setValue(this.modelObject.ProfitPercentage)
  }
 
 
  
  ItemsPriceList2:ItemsPriceListModel[]=[];
  WarehouseList2:WarehouseItemModel[]=[{Id:0,ItemId:this.Id,WarehouseDataId:0,Alert:0,Quantity:0}];
PriceListId=0;
  AddonClick(){
debugger;
    var PriceList= new ItemsPriceListModel();
    PriceList.Id = 0, 
    PriceList.ItemId = this.Id, 
    PriceList.PriceListId = 0, 
    PriceList.SalePrice=0;
    PriceList.IsDeleted= false, 
    // ItemsModel.Items=[]
    this.ItemsPriceList2.push(PriceList);
    PriceList={};

    
}
AddonWarehouseOnClick(){
  debugger;
      var WarehouseList= new WarehouseItemModel();
      WarehouseList.Id = 0, 
      WarehouseList.ItemId = this.Id, 
      WarehouseList.WarehouseDataId =+ 0, 
      WarehouseList.Alert=0;
      WarehouseList.Quantity=0, 
      // ItemsModel.Items=[]
      this.WarehouseList2.push(WarehouseList);
      WarehouseList={};
  
      
  }

removeItemsFooter(Id: any): any {

    let index = this.ItemsPriceList2.findIndex(x => x.PriceListId === Id);
    this.ItemsPriceList2.splice(index, 1);
                 
  }


onMaterialGroupChangeCategory(event) {
  this.SelectedCategoryList = event;

}
onMaterialGroupChangeBrand(event) {
  this.SelectedBrandList = event;

}
selectAllForDropdownItems(items: any[]) {
  let allSelect = (items) => {
    items.forEach((element) => {
      element['selectedAllGroup'] = 'selectedAllGroup';
    });
  };

  allSelect(items);
}
     getCategoryById(Id:any):any{
         if(Id>0)
         {
         return this.CategoryList.find(i=>i.Id===Id)
         }
         else
         {
         return new CategoryModel();
         }
         }



  SelectedCategoryList: CategoryModel[] = [];
  SelectedBrandList:BrandModel[]=[];
  goBack() {
    this.router.navigate(["app/aio/Inventory/List-All-Productes"]);
  }
  Save() {
    debugger;
    this.currentAction = "loading";
    this.tableEngine.savedPage = this.tableEngine.currentPage;
    this.modelObject = new StoreItemsModel();
    this.modelObject.Id = 0;
    this.modelObject.Name = this.mainForm.getRawValue().Name;
    this.modelObject.SkuNumber = this.mainForm.getRawValue().SkuNumber;
    this.modelObject.IsDisable = this.mainForm.getRawValue().IsDisable;
    this.modelObject.Description = this.mainForm.getRawValue().Description;
    this.modelObject.Discount = this.mainForm.getRawValue().Discount;
    this.modelObject.LowestSellingPrice =
      +this.mainForm.getRawValue().LowestSellingPrice;
    this.modelObject.ProfitPercentage =
      this.mainForm.getRawValue().ProfitPercentage;
    this.modelObject.PurchasingPrice =
      +this.mainForm.getRawValue().PurchasingPrice;
    this.modelObject.SalePrice = +this.mainForm.getRawValue().SalePrice;
    this.modelObject.Notes = this.mainForm.getRawValue().Notes;
    this.modelObject.FirstTaxId = +this.mainForm.getRawValue().FirstTaxId;
    this.modelObject.SecondTaxId = +this.mainForm.getRawValue().SecondTaxId;
    this.modelObject.UnitId = +this.mainForm.getRawValue().UnitId;
    this.modelObject.Quantity = 0;
    this.modelObject.Barcode = this.mainForm.getRawValue().Barcode;
    this.modelObject.ItemPriceList = this.ItemsPriceList2;
    this.modelObject.WarhouseItem = this.WarehouseList2;    
    this.modelObject.Tags = this.mainForm.getRawValue().Tags;
    this.modelObject.AvailableOnline = this.mainForm.getRawValue().AvailableOnline;
    this.modelObject.FeaturedProduct = this.mainForm.getRawValue().FeaturedProduct;
    this.modelObject.DiscountType = this.mainForm.getRawValue().DiscountType;
    this.modelObject.Alert = +this.mainForm.getRawValue().Alert;
    
    this.SelectedCategoryList.forEach(element => {
      var cat = new CategoryItemsModel();
      cat.CategoryId = element.Id;
      cat.Id = 0;
      cat.ItemId = this.Id;
      this.modelObject.ItemCategory.push(cat);
    });
    this.SelectedBrandList.forEach(element => {
      var cat = new BrandItemsModel();
      cat.BrandId = element.Id;
      cat.Id = 0;
      cat.ItemId = this.Id;
      this.modelObject.ItemBrand.push(cat);
    });
    if (this.Id == 0) {
      this.modelService.insert(this.modelObject).subscribe((data) => {
        this.router.navigate(["app/aio/Inventory/List-All-Productes"]);
      });
    } else {
      debugger;
      this.modelService.update(this.modelObject).subscribe((data) => {
        this.router.navigate(["app/aio/Inventory/List-All-Productes"]);
      });
    }
  }
}
