import { JournalFileModel } from './journal-file.model';
import { TaxTypesService } from './../../../../tax-types/TaxTypesService';
import { CostCenterService } from './../cost-center/services/cost-center.service';
import { CostCenterModel } from './../cost-center/models/cost-center.model';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CurrencyModel } from 'src/app/invoices/currencies/CurrencyModel';
import { CurrencyService } from 'src/app/invoices/currencies/CurrencyService';
import { AccountNameModel } from '../account-name/AccountNameModel';
import { AccountsNameService } from '../account-name/AccountNameService';
import { BasicModel } from '../account-type/helper/model';
import { AccountsTypeService } from '../account-type/helper/serves';
import { BankCodeModel } from '../bank-code/bank_code_model';
import { BankCodeService } from '../bank-code/serves';
import { DailyrestrictionsModel } from './DailyrestrictionsModel';
import { DailyrestrictionsService } from './DailyrestrictionsService';
import { DailyrestrictionsFooterModel } from './DailyrestrictionsModel';
import { SelectedDailyrestrictionsFooterModel } from './DailyrestrictionsModel';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { JournalFooterCostCenterModel } from './journal-footer-cost-center.model';
import { AccountsCostCentersService } from '../assign-cost-centers/services/account-cost-center.service';
import { BsModalRef, ModalDirective, BsModalService } from 'ngx-bootstrap/modal';
import { TreasuryAndBankAccountsService } from '../treasury-and-bank-accounts/treasury-and-bank-accounts.service';
import { TreasuryAndBankAccountsModel } from '../treasury-and-bank-accounts/model/treasury-and-bank-accounts-model';
import { NotificationType, NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { TaxTypesModel } from 'src/app/invoices/tax-types/TaxTypesModel';
import { FiscalYearService } from '../fiscal-years/fiscal-year.service';
@Component({
    // moduleId: module.id,
    selector: 'dailyrestrictions',
    templateUrl: 'dailyrestrictions.component.html',
    styleUrls: ['dailyrestrictions.component.scss']
})
export class DailyrestrictionsComponent implements OnInit {
    declare lang: any;

    // accountsTypeModel: BasicModel[] = [];
    AccountsNameModel: AccountNameModel[] = [];
    HeaderAccountNameList: AccountNameModel[] = [];
    PackUpAccounts: AccountNameModel[] = [];
    FooterAccountNameList: AccountNameModel[] = [];
    BankList: TreasuryAndBankAccountsModel[] = [];
    CurrencyList: CurrencyModel[] = [];
    
    DailyrestrictionsModel: DailyrestrictionsModel=new DailyrestrictionsModel();
    DailyrestrictionsFooterModel: DailyrestrictionsFooterModel[] = [];
    SelectedDailyrestrictionsFooterModel: SelectedDailyrestrictionsFooterModel[] = [
        {AccountNameId:0,Credit:0,Debit:0,Id:1,NameAr:'',NameEn:'',CostCenters:[],AccountNature:0},
        {AccountNameId:0,Credit:0,Debit:0,Id:2,NameAr:'',NameEn:'',CostCenters:[],AccountNature:0},
    ];
    SelectedDailyrestrictionsFooterModelForModal:DailyrestrictionsFooterModel={};
    // SaveIsValid=false;
    HeaderFormGroup:FormGroup;
    FooterFormGroup:FormGroup;
    TotalCreditor:number=0;
    TotalDebtor:number=0;
    Total:number=0;
    date:string;
    code=1;
    costCenters1:CostCenterModel[]=[];
    costCenters2:CostCenterModel[]=[];
    Id = 0;
    buttonName='';
    taxes:TaxTypesModel[]=[];
    constructor( 
        private FiscalYearService:FiscalYearService,
        private BankService: TreasuryAndBankAccountsService,
         private DailyrestrictionsService: DailyrestrictionsService,
         private CurrencyService: CurrencyService, 
         private accountsNameService: AccountsNameService,
         private costCenterService:CostCenterService,
        private route: ActivatedRoute,
        public router:Router,
        private accountCostCenter:AccountsCostCentersService,
        private modalService: BsModalService,
        private notifications: NotificationsService,
        private translate: TranslateService,
        private TaxService : TaxTypesService
         ){
      this.lang = localStorage.getItem('theme_lang');
      this.Id = + route.snapshot.paramMap.get('Id');
        
    }
    ngOnInit(): void {
        debugger;
        this.TaxService.getAll()
        .subscribe(reault=>{
            this.taxes = reault;
            console.log('this.taxes',this.taxes)
        })
        if(this.Id > 0){
            this.buttonName = 'Edit';

            this.DailyrestrictionsService.getById(this.Id)
            .subscribe(result => {
                this.DailyrestrictionsModel = result;
                this.files = this.DailyrestrictionsModel.Files;
                console.log(this.DailyrestrictionsModel);
                this.code = this.DailyrestrictionsModel.Code;
                this.TotalCreditor = this.DailyrestrictionsModel.Totalcredit;
                this.TotalDebtor = this.DailyrestrictionsModel.Totaldebit;
                this.date = this.DailyrestrictionsModel.Date ? this.DailyrestrictionsModel.Date.toString().slice(0, 10): this.date = new Date().toISOString().slice(0, 10);
                this.SelectedDailyrestrictionsFooterModel = this.DailyrestrictionsModel.Footer;
                this.SelectedDailyrestrictionsFooterModel.forEach(element => {
                if(element.CostCenters.length > 0){
                    this.costCenterService.GetAllByAccountId(element.AccountNameId)
                    .subscribe(data => {
                        element.centers = data;
                        element.addCostCenters = true;
                    })
                }
            });
            this.BankService.GetAllBankAccounts().subscribe(
                data=>{
                    this.BankList=data;
                }
            );
            this.CurrencyService.getAll().subscribe(
                data=>{
                   this. CurrencyList=data;
                }
            );
            this.accountsNameService.GetAllSubAccountes(0).subscribe(
                data=>{
                    this.AccountsNameModel=data;
                    this.AccountsNameModel.forEach(element => {
                        element.NameAr = element.AccountNumber +' # '+ element.NameAr;
                        element.NameEn = element.AccountNumber +' # '+ element.NameEn;
                    });
                    this.HeaderAccountNameList=data;
                    this.FooterAccountNameList=data;
                    this.PackUpAccounts = data.map(item=>Object.assign({}, item))
                    // this.SelectedDailyrestrictionsFooterModel =data;
                    // console.log("AccountsNameList",this.accountsTypeModel); 
                }
            );
       
                this.HeaderFormGroup = new FormGroup({
                    HeaderAccountNameDropdwon: new FormControl(this.DailyrestrictionsModel.AccountNameId, Validators.required,),
                    HeaderBankCodeDropdwon: new FormControl(this.DailyrestrictionsModel.BankCodeId, Validators.required,),
                    HeaderCurrencyDropdwon: new FormControl(this.DailyrestrictionsModel.CurrencyId, Validators.required,),
                    HeaderDescription: new FormControl(this.DailyrestrictionsModel.Description),
                    startDate: new FormControl(this.date, Validators.required),
                    Date: new FormControl(this.date, Validators.required),
                    Code:new FormControl(this.code, Validators.required),
                    NotebookReference:new FormControl(this.DailyrestrictionsModel.NotebookReference,Validators.required),
                    MovementNumber:new FormControl(this.DailyrestrictionsModel.MovementNumber,Validators.required),
                    PeriodicEntry:new FormControl(this.DailyrestrictionsModel.PeriodicEntry),
                    PeriodicType:new FormControl(this.DailyrestrictionsModel.PeriodicType)
                  });
            })
        }
        else{
            this.buttonName = 'Add';
            this.files = [];
            this.DailyrestrictionsModel = new DailyrestrictionsModel();
            this.DailyrestrictionsService.GetCode()
            .subscribe(code=>{
                if(code > -1){
                    console.log('code',code)
                    this.code = code + 1;
                    
            this.HeaderFormGroup = new FormGroup({
                HeaderAccountNameDropdwon: new FormControl(this.DailyrestrictionsModel.AccountNameId, Validators.required,),
                HeaderBankCodeDropdwon: new FormControl(this.DailyrestrictionsModel.BankCodeId, Validators.required,),
                HeaderCurrencyDropdwon: new FormControl(this.DailyrestrictionsModel.CurrencyId, Validators.required,),
                HeaderDescription: new FormControl(this.DailyrestrictionsModel.Description),
                startDate: new FormControl(this.date, Validators.required),
                Date: new FormControl(this.date, Validators.required),
                Code:new FormControl(this.code, Validators.required),
                NotebookReference:new FormControl(this.DailyrestrictionsModel.NotebookReference,Validators.required),
                MovementNumber:new FormControl(this.DailyrestrictionsModel.MovementNumber,Validators.required),
                PeriodicEntry:new FormControl(this.DailyrestrictionsModel.PeriodicEntry),
                PeriodicType:new FormControl(this.DailyrestrictionsModel.PeriodicType)
              });
              this.CurrencyList.forEach(element => {
                if(element.IsMain == true){
                    this.HeaderFormGroup.controls['HeaderCurrencyDropdwon'].setValue(element.Id);
                }
            });
                }
            })
        this.date = new Date().toISOString().slice(0, 10);
    
        this.BankService.GetAllBankAccounts().subscribe(
            data=>{
                this.BankList=data;
            }
        );
        this.CurrencyService.getAll().subscribe(
            data=>{
               this. CurrencyList=data;
            }
        );
        this.accountsNameService.GetAllSubAccountes(0).subscribe(
            data=>{
                this.AccountsNameModel=data;
                this.AccountsNameModel.forEach(element => {
                    element.NameAr = element.AccountNumber+' # ' + element.NameAr;
                    element.NameEn = element.AccountNumber+' # ' + element.NameEn;
                });
                this.HeaderAccountNameList=data;
                this.FooterAccountNameList=data;
                this.PackUpAccounts = data.map(item=>Object.assign({}, item))
                // this.SelectedDailyrestrictionsFooterModel =data;
                // console.log("AccountsNameList",this.accountsTypeModel); 
            }
        );
     
        }
      
    }
    ChckCode(){
        var code = this.HeaderFormGroup.controls['Code'].value;
        this.DailyrestrictionsService.ChckCode(code)
        .subscribe(data => {
            if(data == false){
                this.HeaderFormGroup.controls['Code'].setErrors({'incorrect': true})
            }
        })
    }
    // createMainFormForm():FormGroup{
    //     return   this.MainFormGroup = new FormGroup({
    //         BalanceNameDropdwon: new FormControl(null, Validators.required,),
    //         startDate: new FormControl(null, Validators.required),
    //         DebtorControll: new FormControl(0,),
    //         CreditorControll: new FormControl(0,),

    //       });
    //   }
    // onChangeHeaderAccountNameList(value){
    //     this.FooterAccountNameList=[];
    //    this.HeaderAccountNameList.forEach(
    //     item=>{
    //       if ( item.Parentcode == value ){
    //        this.FooterAccountNameList.push(item)
    //     }
    //     }
    //    )
        
    // }
    changeDebtor(Item,$event){
        var ItemValue = +$event.target.value;
         let indexselected = this.SelectedDailyrestrictionsFooterModel.indexOf(Item);
        this.SelectedDailyrestrictionsFooterModel[indexselected].Debit=ItemValue;
        this.TotalCreditor=0;
        this.TotalDebtor=0; 
        this.SelectedDailyrestrictionsFooterModel[indexselected].Credit=0;
        this.SelectedDailyrestrictionsFooterModel.forEach(
            item=>{
                this.TotalCreditor+=item.Credit;
                this.TotalDebtor+=item.Debit; 
            }
        )
       
        console.log("Item",Item);
                
    }
    changeCredit(Item,$event){
        var ItemValue =+ $event.target.value;
        let indexselected = this.SelectedDailyrestrictionsFooterModel.indexOf(Item);
        this.SelectedDailyrestrictionsFooterModel[indexselected].Credit=ItemValue;
        this.TotalCreditor=0;
        this.TotalDebtor=0; 
        this.SelectedDailyrestrictionsFooterModel[indexselected].Debit=0;
        this.SelectedDailyrestrictionsFooterModel.forEach(
            item=>{
            if(item.Credit>0){
             this.TotalCreditor+=item.Credit;
                
            }else{
                this.TotalDebtor+=item.Debit; 
            }
            }
        )

        console.log(".Credit",this.TotalCreditor);
        
      var  subAccountBalanceFooterModel = new DailyrestrictionsFooterModel();

        console.log("Item",Item);
                
    }
    splitCenter($event){
        debugger;
        var i = this.costCenters1.findIndex(x=>x.Id == $event.Id);
        if(i > -1){
            this.costCenters1.splice(i,1);
        }
    }
    // @ViewChild('delModal', { static: true }) delModal: ModalDirective=null;
    @ViewChild('template', { static: true }) template=null;
    @ViewChild('taxModal', { static: true }) taxModal=null;
    modalRef: BsModalRef;
    
    // OnAddSelectedItemFunc($event){}
    // OnRemoveSelectedItemFunc($event){
    //     var Item = $event.value;
    //     console.log(" OnRemoveSelected",Item);
    //     // let indexselected = this.SelectedDailyrestrictionsFooterModel.findIndex(i => i.Id == Item);
    //     var Credit = $event.value.Credit;
    //     var Debit = $event.value.Debit;
    //     if(Credit>0){
    //         this.TotalCreditor-=Credit;
    //     }else{
    //         this.TotalDebtor-=Debit;
    //     }
    // }

    //save 
    onClick(){
        debugger;
        if(this.FiscalYearService.openYear != null && this.FiscalYearService.openYear != undefined){
            this.Total = this.TotalCreditor - this.TotalDebtor;
            if(this.Total==0){
            console.log("secound condition completed total = 0");
            if(this.HeaderFormGroup.valid){
            console.log("theard condition completed Header Form Group Completed");
            // this.DailyrestrictionsModel.accountBalanceFooterList =
            console.log("this.DailyrestrictionsModel.accountBalanceFooterModel ",this.SelectedDailyrestrictionsFooterModel );
          
    
            this.DailyrestrictionsModel.Description=this.HeaderFormGroup.getRawValue().HeaderDescription;
            this.DailyrestrictionsModel.CurrencyId=this.HeaderFormGroup.getRawValue().HeaderCurrencyDropdwon;
            this.DailyrestrictionsModel.BankCodeId=this.HeaderFormGroup.getRawValue().HeaderBankCodeDropdwon;
            this.DailyrestrictionsModel.AccountNameId =this.HeaderFormGroup.getRawValue().HeaderAccountNameDropdwon;
            this.DailyrestrictionsModel.Date=this.HeaderFormGroup.getRawValue().startDate;
            this.DailyrestrictionsModel.Code=this.HeaderFormGroup.getRawValue().Code;
            this.DailyrestrictionsModel.NotebookReference=this.HeaderFormGroup.getRawValue().NotebookReference;
            this.DailyrestrictionsModel.MovementNumber=this.HeaderFormGroup.getRawValue().MovementNumber;
            this.DailyrestrictionsModel.PeriodicEntry=this.HeaderFormGroup.getRawValue().PeriodicEntry;
            this.DailyrestrictionsModel.PeriodicType=this.HeaderFormGroup.getRawValue().PeriodicType;
    
            this.DailyrestrictionsModel.Totalcredit=this.TotalCreditor;
            this.DailyrestrictionsModel.Totaldebit=this.TotalDebtor;
            this.DailyrestrictionsModel.Totalbalance=this.Total;
            this.DailyrestrictionsModel.Footer = [];
            this.DailyrestrictionsModel.Files = this.files;
            this.DailyrestrictionsModel.FiscalYearId = this.FiscalYearService.openYear.Id;
            this.SelectedDailyrestrictionsFooterModel.forEach(
                Item=>{
                    var  subAccountBalanceFooterModel = new DailyrestrictionsFooterModel();
                      subAccountBalanceFooterModel.Id =Item.Id ;
                      subAccountBalanceFooterModel.AccountNameId=Item.AccountNameId;
                      subAccountBalanceFooterModel.Credit =Item.Credit ;
                      subAccountBalanceFooterModel.Debit =Item.Debit ;
                      subAccountBalanceFooterModel.CostCenters =Item.CostCenters ;
                      subAccountBalanceFooterModel.Description =Item.Description ;
                    this.DailyrestrictionsModel.Footer.push(subAccountBalanceFooterModel)
                }
            );
            if(this.Id == 0 ){
                this.DailyrestrictionsService.insert(this.DailyrestrictionsModel).subscribe(
                    data=>{
                        if(data){
                        this.toastSuccess(this.translate.instant("Success"), this.translate.instant("Add Done Successfully"));
                         this.router.navigate(['app/accounts/all-daily']);
                            
                        }
                        else{
                        this.toastError(this.translate.instant("Error"), this.translate.instant("An error occurred while saving"));
    
                        }
                    }
                    )
            }
            else{
                this.DailyrestrictionsService.update(this.DailyrestrictionsModel).subscribe(
                    data=>{
                        if(data){
                            this.toastSuccess(this.translate.instant("Success"), this.translate.instant("Update Done Successfully"));
                            this.router.navigate(['app/accounts/all-daily']);
                        }
                        else{
                        this.toastError(this.translate.instant("Error"), this.translate.instant("An error occurred while saving"));
                        }
                    }
                    )
            }
            console.log("this.DailyrestrictionsModel.accountBalanceFooterModel ",this.DailyrestrictionsModel);
            }else{
                console.log("theard condition field Header Form Group Completed");
            }
    
            }else{
                console.log("secound condition field total = 0");
            } 
        }
        else{

        }
       
    } 
    addAndNew(){
        if(this.FiscalYearService.openYear != null && this.FiscalYearService.openYear != undefined){

        this.Total = this.TotalCreditor - this.TotalDebtor;
        if(this.Total==0){
        console.log("secound condition completed total = 0");
        if(this.HeaderFormGroup.valid){
        console.log("theard condition completed Header Form Group Completed");
        // this.DailyrestrictionsModel.accountBalanceFooterList =
        console.log("this.DailyrestrictionsModel.accountBalanceFooterModel ",this.SelectedDailyrestrictionsFooterModel );
      

        this.DailyrestrictionsModel.Description=this.HeaderFormGroup.getRawValue().HeaderDescription;
        this.DailyrestrictionsModel.CurrencyId=this.HeaderFormGroup.getRawValue().HeaderCurrencyDropdwon;
        this.DailyrestrictionsModel.BankCodeId=this.HeaderFormGroup.getRawValue().HeaderBankCodeDropdwon;
        this.DailyrestrictionsModel.AccountNameId =this.HeaderFormGroup.getRawValue().HeaderAccountNameDropdwon;
        this.DailyrestrictionsModel.Date=this.HeaderFormGroup.getRawValue().startDate;
        this.DailyrestrictionsModel.Code=this.HeaderFormGroup.getRawValue().Code;
        this.DailyrestrictionsModel.Totalcredit=this.TotalCreditor;
        this.DailyrestrictionsModel.Totaldebit=this.TotalDebtor;
        this.DailyrestrictionsModel.Totalbalance=this.Total;
        this.DailyrestrictionsModel.NotebookReference=this.HeaderFormGroup.getRawValue().NotebookReference;
        this.DailyrestrictionsModel.MovementNumber=this.HeaderFormGroup.getRawValue().MovementNumber;
        this.DailyrestrictionsModel.PeriodicEntry=this.HeaderFormGroup.getRawValue().PeriodicEntry;
        this.DailyrestrictionsModel.PeriodicType=this.HeaderFormGroup.getRawValue().PeriodicType;
        this.DailyrestrictionsModel.Footer = [];
        this.DailyrestrictionsModel.Files = this.files;
        this.DailyrestrictionsModel.FiscalYearId = this.FiscalYearService.openYear.Id;

        this.SelectedDailyrestrictionsFooterModel.forEach(
            Item=>{
                var  subAccountBalanceFooterModel = new DailyrestrictionsFooterModel();
                  subAccountBalanceFooterModel.Id =Item.Id ;
                  subAccountBalanceFooterModel.AccountNameId=Item.AccountNameId;
                  subAccountBalanceFooterModel.Credit =Item.Credit ;
                  subAccountBalanceFooterModel.Debit =Item.Debit ;
                  subAccountBalanceFooterModel.CostCenters =Item.CostCenters ;
                  subAccountBalanceFooterModel.Description =Item.Description ;

                this.DailyrestrictionsModel.Footer.push(subAccountBalanceFooterModel)
            }
        );
        if(this.Id == 0 ){
            this.DailyrestrictionsService.insert(this.DailyrestrictionsModel).subscribe(
                data=>{
                    if(data){
                    this.toastSuccess(this.translate.instant("Success"), this.translate.instant("Add Done Successfully"));
                        this.SelectedDailyrestrictionsFooterModel = [
                            {AccountNameId:0,Credit:0,Debit:0,Id:1,NameAr:'',NameEn:''},
                            {AccountNameId:0,Credit:0,Debit:0,Id:2,NameAr:'',NameEn:''},
                        ];
                        this.ngOnInit();
                        
                    }
                    else{
                    this.toastError(this.translate.instant("Error"), this.translate.instant("An error occurred while saving"));

                    }
                }
                )
        }
        else{
            this.DailyrestrictionsService.update(this.DailyrestrictionsModel).subscribe(
                data=>{
                    if(data){
                    this.toastSuccess(this.translate.instant("Success"), this.translate.instant("Update Done Successfully"));
                        this.SelectedDailyrestrictionsFooterModel = [
                            {AccountNameId:0,Credit:0,Debit:0,Id:1,NameAr:'',NameEn:''},
                            {AccountNameId:0,Credit:0,Debit:0,Id:2,NameAr:'',NameEn:''},
                        ];
                        this.ngOnInit();
                        
                    }
                    else{
                    this.toastError(this.translate.instant("Error"), this.translate.instant("An error occurred while saving"));

                    }
                }
                )
        }
    
        console.log("this.DailyrestrictionsModel.accountBalanceFooterModel ",this.DailyrestrictionsModel);
        
        }else{
            console.log("theard condition field Header Form Group Completed");

        }

        }else{
            console.log("secound condition field total = 0");

        } 
    }
    else{

    }
    }
    AddonClick(){
        var selectedDailyrestrictionsFooterModel =new SelectedDailyrestrictionsFooterModel();
        selectedDailyrestrictionsFooterModel.Id = this.SelectedDailyrestrictionsFooterModel.length+1 ;
        selectedDailyrestrictionsFooterModel.Debit = 0 ;
        selectedDailyrestrictionsFooterModel.Credit = 0 ;
        selectedDailyrestrictionsFooterModel.CostCenters = [] ;
        this.SelectedDailyrestrictionsFooterModel.push(selectedDailyrestrictionsFooterModel);
    }
    splitAccount(Item,$event){
        debugger;
        var selectedIndex = this.SelectedDailyrestrictionsFooterModel.indexOf(Item);
        var i = this.AccountsNameModel.findIndex(x=>x.Id == $event.Id);

        if(selectedIndex > -1){
            this.SelectedDailyrestrictionsFooterModel[selectedIndex].AccountNature = this.AccountsNameModel[i].AccountNature;
        }
        if(i > -1){
            this.AccountsNameModel.splice(i,1);
        }
    }
    deleteSelectedAccount(item){
        debugger;
        var i = this.SelectedDailyrestrictionsFooterModel.indexOf(item);
        console.log('i',i);
        if(i > -1){
            if(this.SelectedDailyrestrictionsFooterModel[i].Credit > 0){
                this.TotalCreditor -= this.SelectedDailyrestrictionsFooterModel[i].Credit;
            }
            if(this.SelectedDailyrestrictionsFooterModel[i].Debit > 0){
                this.TotalDebtor -= this.SelectedDailyrestrictionsFooterModel[i].Debit;
            }
            var x= this.PackUpAccounts.findIndex(x=>x.Id == this.SelectedDailyrestrictionsFooterModel[i].AccountNameId);
            if(x > -1){

                this.AccountsNameModel.push(this.PackUpAccounts[x]);
            }
            this.SelectedDailyrestrictionsFooterModel.splice(i,1);
        }
    }
    AssignCostCenter(Item){
        debugger;
        if(Item.AccountNameId > 0){
            var selectedIndex = this.SelectedDailyrestrictionsFooterModel.indexOf(Item);
            this.SelectedDailyrestrictionsFooterModel[selectedIndex].addCostCenters = true;
            if(!this.SelectedDailyrestrictionsFooterModel[selectedIndex].centers|| this.SelectedDailyrestrictionsFooterModel[selectedIndex].centers.length == 0){
                var cost = new JournalFooterCostCenterModel();
                cost.Amount = 0 ;
                cost.CostCenterId = 0;
                cost.Id = 0;
                cost.JournalFooterId = this.SelectedDailyrestrictionsFooterModel[selectedIndex].Id;
                cost.Percent = 0;
                this.costCenterService.GetAllByAccountId(Item.AccountNameId)
                .subscribe(data => {
                    if(data.length > 0){
                        this.SelectedDailyrestrictionsFooterModel[selectedIndex].centers = data;
                        this.SelectedDailyrestrictionsFooterModel[selectedIndex].centers.splice(0,0,{Id : 0, NameAr:'اختر المركز',NameEn:'Choose Center'})
                        console.log('Centers',this.SelectedDailyrestrictionsFooterModel[selectedIndex].centers);
                        this.SelectedDailyrestrictionsFooterModel[selectedIndex].CostCenters.push(cost);
                        this.SelectedDailyrestrictionsFooterModelForModal = this.SelectedDailyrestrictionsFooterModel[selectedIndex]
                        if(this.SelectedDailyrestrictionsFooterModelForModal.centers.length > 1){
                            this.openModalWithClass(this.template);
                        }
                        else{
                            this.onInfo(this.translate.instant("Info"), this.translate.instant("This Account Not Set To Any Cost Center"));
                        }
                    
                    }
                   else{
                    this.onInfo(this.translate.instant("Info"), this.translate.instant("This Account Not Set To Any Cost Center"));
                   }
    
                })
            }
            else{
                this.SelectedDailyrestrictionsFooterModelForModal = this.SelectedDailyrestrictionsFooterModel[selectedIndex]
                if(this.SelectedDailyrestrictionsFooterModelForModal.centers.length > 1){
                    this.openModalWithClass(this.template);
                }
                else{
                    this.onInfo(this.translate.instant("Info"), this.translate.instant("This Account Not Set To Any Cost Center"));
                }
            }
        }
   else{
    this.onInfo(this.translate.instant("Info"), this.translate.instant("Plase Choose Account"));
   }
     
    }
    openModalWithClass(template: TemplateRef<any>): void {
        this.modalRef = this.modalService.show(
          template,
          Object.assign({}, { class: 'gray modal-lg' })
        );
      }
    AddCostCenter(item){
        var cost = new JournalFooterCostCenterModel();
        cost.Amount = 0 ;
        cost.CostCenterId = 0;
        cost.Id = 0;
        cost.JournalFooterId = item.Id;
        cost.Percent = 0;
        item.centers = item.centers;
        item.CostCenters.push(cost);
    }
    changePercent(item,cost,$event){
        debugger;
        cost.Percent = + $event.target.value;
        var totalPercent = 0;
        item.CostCenters.forEach(element => {
            totalPercent += element.Percent;
        });
        totalPercent = totalPercent - cost.Percent;

        if(item.Debit > 0){
            if(cost.Percent <= 100 && cost.Percent > 0 && totalPercent < 100){
                if((cost.Percent+totalPercent) > 100){
                    cost.Percent = 100 - totalPercent ;
                    if(cost.Percent < 0){
                        cost.Percent *= -1
                    }
                }
                cost.Amount = (item.Debit * cost.Percent) / 100;

            }
            else if(totalPercent >= 100){
                cost.Percent = 0;
                cost.Amount = (item.Debit * cost.Percent) / 100;

            }
            else if(cost.Percent > 100){
                cost.Percent = 100;
                cost.Amount = (item.Debit * cost.Percent) / 100;

            }
        }
        else if(item.Credit > 0){
            if(cost.Percent <= 100 && cost.Percent > 0 && totalPercent < 100){
                if((cost.Percent+totalPercent) > 100){
                    cost.Percent = 100 - totalPercent ;
                    if(cost.Percent < 0){
                        cost.Percent *= -1
                    }

                }
                cost.Amount = (item.Credit * cost.Percent) / 100;

            }
            else if(totalPercent >= 100){
                cost.Percent = 0;
                cost.Amount = (item.Debit * cost.Percent) / 100;

            }
            else if(cost.Percent > 100){
                cost.Percent = 100;
                cost.Amount = (item.Credit * cost.Percent) / 100;

            }
        }
    }
    changeAmount(item,cost,$event){
        debugger;
        cost.Amount = + $event.target.value;
        var totalPercent = 0;
        item.CostCenters.forEach(element => {
            totalPercent += element.Percent;
        });
        if(item.Debit > 0){
            cost.Percent =(cost.Amount * 100)/ item.Debit 

            if(cost.Percent <= 100 && cost.Percent > 0&& totalPercent < 100){
                if((cost.Percent+totalPercent) > 100){
                    cost.Percent = 100 - totalPercent ;
                    if(cost.Percent < 0){
                        cost.Percent *= -1
                    }

                }
                cost.Amount = (item.Debit * cost.Percent) / 100;

            }
            else if(totalPercent >= 100){
                cost.Percent = 0;
                cost.Amount = (item.Debit * cost.Percent) / 100;

            }
            else if(cost.Percent > 100){
                cost.Percent = 100;
                cost.Amount = (item.Debit * cost.Percent) / 100;

            }
        }
        else if(item.Credit > 0){
            cost.Percent =(cost.Amount * 100)/ item.Credit 

            if(cost.Percent <= 100 && cost.Percent > 0&& totalPercent < 100){
                if((cost.Percent+totalPercent) > 100){
                    cost.Percent = 100 - totalPercent ;
                    if(cost.Percent < 0){
                        cost.Percent *= -1
                    }

                }
                cost.Amount = (item.Credit * cost.Percent) / 100;

            }
            else if(totalPercent >= 100){
                cost.Percent = 0;
                cost.Amount = (item.Debit * cost.Percent) / 100;

            }
            else if(cost.Percent > 100){
                cost.Percent = 100;
                cost.Amount = (item.Credit * cost.Percent) / 100;

            }
        }
    }
    deleteCostCenter(item,cost){
        var i = item.CostCenters.indexOf(cost);
        if(i > -1){
            item.CostCenters.splice(i,1);
        }
        if(item.CostCenters.length == 0 ){
            item.addCostCenters = false;
        }
    }
    getAccountName(Id){
        debugger;
        var i = this.PackUpAccounts.findIndex(x=>x.Id == Id);
        if(i>-1){

            return this.lang == 'ar-EG' ? this.PackUpAccounts[i].NameAr :this.PackUpAccounts[i].NameEn; 
        }
        else{
            return '';
        }
    }
    toastSuccess(toastHeader: string, toastBody: string): void {
        this.notifications.create(toastHeader, toastBody,
          NotificationType.Success, { timeOut: 3000, showProgressBar: true });
      }
      toastError(toastHeader: string, toastBody: string): void {
        debugger;
        this.notifications.create(toastHeader, toastBody,
          NotificationType.Error, { timeOut: 3000, showProgressBar: true });
      }
      onInfo(toastHeader: string, toastBody: string): void {
        debugger;
        this.notifications.create(toastHeader, toastBody,
          NotificationType.Info, { timeOut: 3000, showProgressBar: true });
      }
      selectedFooterForTax:any={};
      AssignTax(Item){
        debugger;
        if(Item.AccountNameId > 0 && (Item.Debit > 0 || Item.Credit > 0)){
            this.selectedFooterForTax= Item;
            this.openModalWithClass(this.taxModal);

        }
        else if(Item.AccountNameId == 0 ){
            this.onInfo(this.translate.instant("Info"), this.translate.instant("Plase Choose Account"));
        }
        else if(Item.Debit == 0 && Item.Credit == 0){
            this.onInfo(this.translate.instant("Info"), this.translate.instant("Plase Add Debit Or Credit Value"));
        }
   else{
    this.onInfo(this.translate.instant("Info"), this.translate.instant("Plase Choose Account"));
   }
     
    }
    AddTax(tax){
        debugger;
        var footer = new SelectedDailyrestrictionsFooterModel();
        var accountIndex = this.AccountsNameModel.findIndex(x=>x.Id == tax.AccountId);
        if(accountIndex > -1){
            footer.AccountNameId = tax.AccountId;
            if(this.selectedFooterForTax.Credit > 0){
                footer.Credit  =this.selectedFooterForTax.Credit*tax.Percentage/100;
                 this.SelectedDailyrestrictionsFooterModel.push(footer);
                this.changeCreditAfterTax(footer,footer.Credit);
                this.modalRef.hide();
            }
            if(this.selectedFooterForTax.Debit > 0){
                footer.Debit  =this.selectedFooterForTax.Debit*tax.Percentage/100;
                this.SelectedDailyrestrictionsFooterModel.push(footer);
                this.changeDebtorAfterTax(footer,footer.Debit);
                this.modalRef.hide();

            }
            // this.modalService.hide(this.taxModal);
        }


        
    }
    changeDebtorAfterTax(Item,value){
        debugger;
        var ItemValue = +value;
         let indexselected = this.SelectedDailyrestrictionsFooterModel.indexOf(Item);
        this.SelectedDailyrestrictionsFooterModel[indexselected].Debit=ItemValue;
        this.TotalCreditor=0;
        this.TotalDebtor=0; 
        this.SelectedDailyrestrictionsFooterModel[indexselected].Credit=0;
        this.SelectedDailyrestrictionsFooterModel.forEach(
            item=>{
                this.TotalCreditor+=item.Credit;
                this.TotalDebtor+=item.Debit; 
            }
        )
       
        console.log("Item",Item);
                
    }
    changeCreditAfterTax(Item,value){
        debugger;
        var ItemValue =+ value;
        let indexselected = this.SelectedDailyrestrictionsFooterModel.indexOf(Item);
        this.SelectedDailyrestrictionsFooterModel[indexselected].Credit=ItemValue;
        this.TotalCreditor=0;
        this.TotalDebtor=0; 
        this.SelectedDailyrestrictionsFooterModel[indexselected].Debit=0;
        this.SelectedDailyrestrictionsFooterModel.forEach(
            item=>{
            if(item.Credit>0){
             this.TotalCreditor+=item.Credit;
                
            }else{
                this.TotalDebtor+=item.Debit; 
            }
            }
        )

        console.log(".Credit",this.TotalCreditor);
        
      var  subAccountBalanceFooterModel = new DailyrestrictionsFooterModel();

        console.log("Item",Item);
                
    }
    public editEnabled = true;
    public fileUrl: string ='';
    files:JournalFileModel[]=[];
    setFileToList(){
        var file = new JournalFileModel();
        file.JournalHeaderId = this.Id ;
        file.FileUrl = this.fileUrl;
        file.Id = 0;
        this.files.push(file);
    }
    deleteFile(file){
        var i = this.files.indexOf(file);
        if(i>-1){
            this.files.splice(i,1);
        }
    }
}
