import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {UnitTypeModel} from './models/UnitTypeModel';

@Injectable({
  providedIn: 'root'
})
export class UnitTypeService {



  constructor(private http:HttpClient ) { }

  
  baseUrl = environment.baseApiUrl;
  token = localStorage.getItem("token");
  httpOptions = {
   headers: new HttpHeaders({
     // 'Content-Type': 'application/json; charset=utf-8',
     // 'Authorization': "Bearer " + token,
      "Authorization": "Bearer " + this.token,
      "Content-Type": "application/json"
   })
 };
 CompanyId:number =+ localStorage.getItem("CompanyId");

 getAll(): Observable<any> {
   
  return this.http
    .get<any>(`${this.baseUrl}CodingUnits/GetAll/${this.CompanyId}`,this.httpOptions)
    .pipe(catchError((error: any) => Observable.throw(error => error)));
}
getById(Id:number): Observable<any> {

  return this.http
    .get<any>(`${this.baseUrl}CodingUnits/GetById/${Id}`,this.httpOptions)
    .pipe(catchError((error: any) => Observable.throw(error => error)));
}
insert(payload: UnitTypeModel): Observable<any> {
  
  return this.http
    .post<any>(`${this.baseUrl}CodingUnits/Insert/${this.CompanyId}`, payload,this.httpOptions)
    .pipe(catchError((error: any) => Observable.throw(error => error)));
}

update(payload: UnitTypeModel): Observable<any> {
  return this.http
    .post<any>(`${this.baseUrl}CodingUnits/Update`, payload,this.httpOptions)
    .pipe(catchError((error: any) => Observable.throw(error => error)));
}

delete(Id:number): Observable<any> {
  return this.http
    .get<any>(`${this.baseUrl}CodingUnits/Delete/${Id}`,this.httpOptions)
    .pipe(catchError((error: any) => Observable.throw(error => error)));
}

  deleteList(payload: UnitTypeModel []): Observable<any> {
    return this.http
      .post<any>(`${this.baseUrl}CodingUnits/DeleteList`,JSON.stringify(payload),this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error => error)));
  }
}
