export class TaxTypesModel{
    Id?:number;
    NameAr?:string;
    NameEn?:string;
    TaxableTypeCode?:string;
    TaxableSubTypeCode?:string;
    Percentage?:number;
    Value?:number;
    IsRate?:boolean;
    AccountId?:number;
    ISMain?:boolean;
    constructor(obj?:TaxTypesModel){
        obj = obj? obj:{};
        this.Id = obj.Id? obj.Id:0;
        this.AccountId = obj.AccountId? obj.AccountId:0;
        this.NameAr = obj.NameAr? obj.NameAr:'';
        this.NameEn = obj.NameEn? obj.NameEn:'';
        this.TaxableTypeCode = obj.TaxableTypeCode? obj.TaxableTypeCode:null;
        this.TaxableSubTypeCode = obj.TaxableSubTypeCode? obj.TaxableSubTypeCode:null;
        this.Percentage = obj.Percentage? obj.Percentage:0;
        this.Value = obj.Value? obj.Value:0;
        this.IsRate = obj.IsRate? obj.IsRate:true;
        this.ISMain = obj.ISMain? obj.ISMain:false;
    }
}