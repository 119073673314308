<!--component html goes here -->


<div class="form-group">
    <br>
    <h3>{{ 'Project Information' | translate }}</h3>
    <br>
    <div class="form-row">
        <div class="form-group col-md-6">
            <label>{{ 'Project Number' | translate }}</label>
            <input type="text" class="form-control">
        </div> 
    </div>
    <div class="form-row">
   
        <div class="form-group col-md-6">
            <label >{{'DescriptionAr'| translate}}</label>
            <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
        </div>
        <div class="form-group col-md-6">
            <label >{{'DescriptionEn'| translate}}</label>
            <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
        </div>
    </div>


    <div class="form-row">
        <div class="form-group col-md-6">
            <label>p.o</label>
            <input type="text" class="form-control">
        </div> 
        <div class="form-group col-md-6">
            <label>{{ 'Project Status' | translate }}</label>
            <input type="text" class="form-control">
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-md-6">
            <label >{{ 'Release Date' | translate }}</label>
            <input  type="date"  class="form-control">
        </div>
        <div class="form-group col-md-6">
            <label>{{ 'Due Date' | translate }}</label>
            <input  type="date"  class="form-control">
        </div>

    </div>
    <br>
    <div class="form-group row"><label for="inputEmail3" class="col-sm-2 col-form-label">{{ 'Project Secretary' | translate }}</label>
      <div class="col-sm-4"><input type="email" id="inputEmail3" class="form-control" >
      </div>
      <div class="col-sm-5"><input type="email" id="inputEmail3" class="form-control" >
      </div>
    </div>
    <div class="form-group row"><label for="inputEmail3" class="col-sm-2 col-form-label">{{ 'Project Monitor' | translate }}</label>
      <div class="col-sm-4"><input type="email" id="inputEmail3" class="form-control" >
      </div>
      <div class="col-sm-5"><input type="email" id="inputEmail3" class="form-control" >
      </div>
    </div>
    <div class="form-group row"><label for="inputEmail3" class="col-sm-2 col-form-label">{{ 'project supervisor' | translate }}</label>
      <div class="col-sm-4"><input type="email" id="inputEmail3" class="form-control" >
      </div>
      <div class="col-sm-5"><input type="email" id="inputEmail3" class="form-control" >
      </div>
    </div>
    <div class="form-group row"><label for="inputEmail3" class="col-sm-2 col-form-label">{{ 'project supervisor' | translate }}</label>
      <div class="col-sm-4"><input type="email" id="inputEmail3" class="form-control" >
      </div>
      <div class="col-sm-5"><input type="email" id="inputEmail3" class="form-control" >
      </div>
    </div>
    <div class="form-group row"><label for="inputEmail3" class="col-sm-2 col-form-label">{{ 'Vice Project Manager' | translate }}</label>
      <div class="col-sm-4"><input type="email" id="inputEmail3" class="form-control" >
      </div>
      <div class="col-sm-5"><input type="email" id="inputEmail3" class="form-control" >
      </div>
    </div>
    <div class="form-group row"><label for="inputEmail3" class="col-sm-2 col-form-label">{{ 'Project manager' | translate }}</label>
      <div class="col-sm-4"><input type="email" id="inputEmail3" class="form-control" >
      </div>
      <div class="col-sm-5"><input type="email" id="inputEmail3" class="form-control" >
      </div>
    </div>

</div>
