import { StoreBalanceSummaryDataModel } from './../../models/store-balance-summary-data.model';
import { Component, OnInit } from '@angular/core';
import { StoreBalanceSummaryService } from '../../store-balance-summary.service';
import { CategoryServiceService } from 'src/app/Invintory/warehouses/category-service.service';
import { BrandServiceService } from 'src/app/Invintory/warehouses/brand-service.service';
import { WarehouseService } from 'src/app/Invintory/warehouses/warehouse-service.service';
import { StoreBalanceSummarySearchModel } from '../../models/store-balance-summary-search.model';
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';
@Component({
    selector: 'store-balance-summary',
    templateUrl: 'store-balance-summary.component.html',
    styleUrls: ['store-balance-summary.component.scss']
})
export class StoreBalanceSummaryComponent implements OnInit{
    declare lang: any;
    today:Date=new Date();
    list:StoreBalanceSummaryDataModel[]=[];
    liabilities:StoreBalanceSummaryDataModel[]=[];
    PropertyRights:StoreBalanceSummaryDataModel[]=[];
    employees=[];
    nodeList:StoreBalanceSummaryDataModel[]=[];
    categories=[];
    brnads=[];
    warehouses=[];
    constructor(private StoreBalanceSummaryService:StoreBalanceSummaryService,
      private categoryService:CategoryServiceService,
      private brandService:BrandServiceService,
      private warehouseService:WarehouseService
      ){
      this.lang = localStorage.getItem('theme_lang');
    }
    TotalAssets = 0;
    Totalliabilities = 0;
    TotalPropertyRights = 0;
    data:StoreBalanceSummaryDataModel[]=[];
    assets:StoreBalanceSummaryDataModel[]=[];
    search:StoreBalanceSummarySearchModel={};
    ngOnInit(): void {
        this.StoreBalanceSummaryService.search(this.search)
        .subscribe(data => {
          this.data = data;
          this.assets = data;
          this.categoryService.getAll()
          .subscribe(categories=>{
            this.categories = categories;
          })
          this.brandService.getAll()
          .subscribe(brands => {
            this.brnads = brands;
          })
          this.warehouseService.getAll()
          .subscribe(warehouses=>{
            this.warehouses = warehouses;
          })
        })
    
    }
    Search(){
      this.StoreBalanceSummaryService.search(this.search)
        .subscribe(data => {
          this.data = data;
        })
    }
    exportTable(){
      var data = document.getElementById('contentToConvert');
      data.style.display = 'inline-block';
      html2canvas(data,{}).then(canvas => {
      var imgWidth = 208;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save('new-file.pdf'); // Generated PDF
      data.style.display = 'block';

    //   data.style.display = 'none';
      
    });
    }
    print(){
        let printContents, popupWin;
        printContents = document.getElementById('contentToConvert').innerHTML;
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.open();
        window.print();
       
      }
}
