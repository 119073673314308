import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'select-cost-center',
    templateUrl: 'select-cost-center.component.html',
    styleUrls: ['select-cost-center.component.scss']
})
export class SelectCostCenterComponent implements OnInit {
    @Input()
    AccountId:number = 0;
    ngOnInit(): void {
        throw new Error('Method not implemented.');
    }

}
