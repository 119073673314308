import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AccountNameModel } from "./AccountNameModel";



@Injectable({
  providedIn: 'root'
})
export class AccountsNameService {
    constructor(private http: HttpClient) {
  
    }

    baseUrl = environment.baseApiUrl;
     token = localStorage.getItem("token");
     httpOptions = {
      headers: new HttpHeaders({
        // 'Content-Type': 'application/json; charset=utf-8',
        // 'Authorization': "Bearer " + token,
         "Authorization": "Bearer " + this.token,
         "Content-Type": "application/json"
      })
    };
    getAll(id:number): Observable<any> {
   
      return this.http
        .get<any>(`${this.baseUrl}AccountsName/GetAll/${id}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    GetAllFixedAssets(id:number): Observable<any> {
   
      return this.http
        .get<any>(`${this.baseUrl}AccountsName/GetAllFixedAssets/${id}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    
    GetAllSubAccountes(id:number): Observable<any> {
   
      return this.http
        .get<any>(`${this.baseUrl}AccountsName/GetAllSubAccountes/${id}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    GetAllMainAccountes(id:number): Observable<any> {
   
      return this.http
        .get<any>(`${this.baseUrl}AccountsName/GetAllMainAccountes/${id}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    
    GetLastAccountCode(): Observable<number> {
      return this.http
        .get<number>(`${this.baseUrl}AccountsName/GetLastAccountCode`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    getById(id:number): Observable<any> {
   
      return this.http
        .get<any>(`${this.baseUrl}AccountsName/GetById/${id}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    insert(payload: AccountNameModel): Observable<any> {
      
      return this.http
        .post<any>(`${this.baseUrl}AccountsName/Insert`, payload,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
    update(payload: AccountNameModel): Observable<any> {
      return this.http
        .post<any>(`${this.baseUrl}AccountsName/Update`, payload,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
    delete(payload: AccountNameModel): Observable<any> {
      return this.http
        .post<any>(`${this.baseUrl}AccountsName/Delete`,payload,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
    deleteList(payload: AccountNameModel []): Observable<any> {
      return this.http
        .post<any>(`${this.baseUrl}AccountsName/DeleteList`,JSON.stringify(payload),this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
}
  