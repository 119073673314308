import { RevenueCodesModule } from './RevenueCodesModel';
import { Component, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { of } from 'rxjs';
import { DataFilterer } from '../../shared/DataFilterer';
import { DataSorter } from '../../shared/DataSorter';
import { DataPaginator } from '../../shared/DataPaginator';
import { SidePopupComponent } from '../../shared/side-popup/side-popup.component';


@Component({
    selector: 'revenue-codes',
    templateUrl: 'revenue-codes.component.html',
    styleUrls: ['revenue-codes.component.scss']
})
export class RevenueCodesComponent {
    constructor(private formBuilder: FormBuilder,  private renderer: Renderer2,
        private notifications: NotificationsService, private translate: TranslateService
        ) {  //Additional services for City and Country
    }

    lang: string;
    currentFormMode: string = '';
    currentAction: string = '';
    itemOrder: any;

    currentScreen: any = 'Table';

    translationName: string = 'Income'

    sortableKeys = [];
    searchableKeys = ['RevenueEn', 'RevenueAr'];

    modelObject: RevenueCodesModule = new RevenueCodesModule({});
    ogData: RevenueCodesModule[] = [
        { Id: 2, RevenueCode: 1, RevenueEn: "Debit 1", RevenueAr: "ايراد 1", CreditorAccountNumber: 122, CreditorAccountNumberId: 11 },
        { Id: 3, RevenueCode: 2, RevenueEn: "Debit 2", RevenueAr: "ايراد 2", CreditorAccountNumber: 112, CreditorAccountNumberId: 55 },
        { Id: 4, RevenueCode: 3, RevenueEn: "Debit 3", RevenueAr: "ايراد 3", CreditorAccountNumber: 332, CreditorAccountNumberId: 14},
        { Id: 5, RevenueCode: 4, RevenueEn: "Debit 4", RevenueAr: "ايراد 4", CreditorAccountNumber: 442, CreditorAccountNumberId: 15 },
        { Id: 6, RevenueCode: 5, RevenueEn: "Debit 5", RevenueAr: "ايراد 5", CreditorAccountNumber: 552, CreditorAccountNumberId: 16},
    ];
    //   ogData: IncomeCodeModel [] = [ ];
      mainForm: FormGroup = new FormGroup({
        Id: new FormControl(),
        RevenueCode: new FormControl(),
        RevenueEn: new FormControl(),
        RevenueAr: new FormControl(),
        CreditorAccountNumber: new FormControl(),        
        CreditorAccountNumberId: new FormControl(),
      
       
      });


      ngOnInit(): void {
        this.lang = localStorage.getItem('theme_lang');
        this.itemOrder = { label: 'Revenue', property: this.lang === 'ar-EG' ? 'RevenueAr' : 'RevenueEn' };
        this.sortableKeys = [
            { label: 'RevenueCode', property: 'RevenueCode' },    
            { label: 'Revenue', property: this.lang === 'ar-EG' ? 'RevenueAr' : 'RevenueEn' },            
              // { label: 'descriptionAr', property: 'descriptionAr' },        
            // { label: 'descriptionEn', property: 'descriptionEn' },        
            { label: 'CreditorAccountNumber', property: 'CreditorAccountNumber' },
            // { label: 'ClientCountry', property: 'DebitAccountId' },
            // { label: 'DocumentaryCredits', property: 'DocumentaryCredits' },          
        ];
        //this.loadData();
        this.loadServices();
    }


    createForm(): FormGroup {
        return this.formBuilder.group({
          Id: [this.modelObject.Id >= 0 ? this.modelObject.Id : 0],
          RevenueEn: [this.modelObject.RevenueEn, Validators.compose([Validators.required, Validators.minLength(2)])],
          RevenueAr: [this.modelObject.RevenueAr, Validators.compose([Validators.required, Validators.pattern(/^[\u0621-\u064A]+([\s]*[\u0621-\u064A]+)*$/)])],
          CreditorAccountNumber: [this.modelObject.CreditorAccountNumber, Validators.compose([Validators.required, Validators.pattern(/^\d+$/)])],
          CreditorAccountNumberId: [this.modelObject.CreditorAccountNumberId, Validators.compose([Validators.required])],
          RevenueCode: [this.modelObject.RevenueCode, Validators.compose([Validators.required])],
         });
      }
    
      addObject() {
        //prevent multiclick submits. enable again after API response.
        this.currentAction = 'loading';
        this.modelObject = new RevenueCodesModule();
        this.modelObject.Id = 0;
        this.modelObject.RevenueCode = this.mainForm.getRawValue().RevenueCode;
        this.modelObject.RevenueEn = this.mainForm.getRawValue().RevenueEn;
        this.modelObject.RevenueAr = this.mainForm.getRawValue().RevenueAr;
        this.modelObject.CreditorAccountNumber = this.mainForm.getRawValue().CreditorAccountNumber;
        this.modelObject.CreditorAccountNumberId = this.mainForm.getRawValue().CreditorAccountNumberId;
        
        this.executeAddObject();
      }

      editObject() {
        // this.savedPage = this.currentPage;
        this.currentAction = 'loading';    
        let tempObj = new RevenueCodesModule({
          Id: this.modelObject.Id,
          RevenueCode: this.mainForm.getRawValue().RevenueCode,
          RevenueEn: this.mainForm.getRawValue().RevenueEn,
          RevenueAr : this.mainForm.getRawValue().RevenueAr,
          CreditorAccountNumber : this.mainForm.getRawValue().CreditorAccountNumber,
          CreditorAccountNumberId : this.mainForm.getRawValue().CreditorAccountNumberId,
   
        });
        
        if(tempObj != this.modelObject){
          this.executeEditObject(tempObj);
        }
        else{
          this.sideModalRef.modalRef.hide();
        }
      }

      executeAddObject(){
        this.ogData.push(this.modelObject);
        this.loadData();
        this.currentAction = '';
        this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyAdded"));
        this.sideModalRef.modalRef.hide();
        // this.modelService.insert(this.modelObject).subscribe(result => {
        //   if (result === true) {
        //     this.loadData();
        //     this.currentAction = '';
        //     this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyAdded"));
        //     this.sideModalRef.modalRef.hide();
        //   }
        //   else {
        //     this.toastError(this.translate.instant("Error"), this.translate.instant("FailedAdd"));
        //     this.currentAction = '';
        //   }
        // })
      }
  

      deleteObject(){
        // this.savedPage = this.currentPage;
        this.currentAction = 'loading';
    
        let tempObj = new RevenueCodesModule({
          Id: this.modelObject.Id,
          RevenueCode: this.mainForm.getRawValue().RevenueCode,
          RevenueEn: this.mainForm.getRawValue().RevenueEn,
          RevenueAr : this.mainForm.getRawValue().RevenueAr,
          CreditorAccountNumber : this.mainForm.getRawValue().CreditorAccountNumber,
          CreditorAccountNumberId : this.mainForm.getRawValue().CreditorAccountNumberId,
         
        });
        
        if(tempObj != this.modelObject){
          this.executeEditObject(tempObj);
        }
        else{
          this.sideModalRef.modalRef.hide();
        }
      }


      executeEditObject(Object)
      {
        this.ogData.findIndex(x=>x.Id==Object);
        this.loadData();
        this.currentAction = '';
        this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyEdited"));
        this.sideModalRef.modalRef.hide();
        // this.modelService.update(Object).subscribe(result => {
        //   if (result === true) {
        //     this.loadData();
        //     this.currentAction = '';
        //     this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyEdited"));
    
        //     this.sideModalRef.modalRef.hide();
        //   }
        //   else {
        //     this.toastError(this.translate.instant("Error"), this.translate.instant("FailedEdit"));
        //     this.currentAction = '';
        //   }
        // })
      }
     ////////////////////////////////////////////////////////////////////////////
  //
  //
  //
  //
  //
  ////////////////////////////////////////////////////////////////////////////
  //  EXCLUSIVE :
  //  Specifically for this component.
  ///////////////////////////////

  //Get any additional data here
  loadServices(){ 
    this.currentAction = 'loading';
    this.loadData();

    // this.modelService.getAll().subscribe(
    //   countryData => {
    //     this.loadData();

    //     //console.log(data);
    //     // this.countryList = countryData;
    //     // this.cityService.getAll().subscribe(
    //     //   cityData => {
    //     //     //console.log(data);
    //     //     // this.cityList = cityData;
    //     //     this.loadData();
    //     //     // console.log(this.cityList.map(i=>i.NameAr));
    //     //     // console.log(this.countryList.map(i=>i.NameAr));
    //     //   }
    //     // )
    //   }
    // )
  }
  DebitList: RevenueCodesModule [] = [];
    getCountryById(Id:any): any {
      return this.DebitList.find(i=>i.Id === Id);
    }
//   cityList: CityModel [] = [];
//   countryList: CountryModel [] = [];
//   getCountryById(Id:any): any {
//     return this.countryList.find(i=>i.Id === Id);
//   }
//   getCityById(Id:any): any {
//     return this.cityList.find(i=>i.Id === Id);
//   }

//   matchTaxNumberWithClientType(): ValidatorFn {
//     return (control:AbstractControl) : ValidationErrors | null => {
  
//         const value = control.value;
//         if (!value) {
//             return null;
//         }
//         if(this.mainForm.get('TaxNumber').value && this.mainForm.get('Type').value){
//           const taxNumber = this.mainForm.get('TaxNumber').value.toString();
//           const clientType = this.mainForm.get('Type').value.toString();
//           const taxMatch = (taxNumber===14&&clientType==='Personal') || (taxNumber===9&&clientType==='Company');
//           return taxMatch ? {match:true} : null;
//         }
//     }
// }

  ////////////////////////////////////////////////////////////////////////////
  //
  //
  //
  //
  //
  ////////////////////////////////////////////////////////////////////////////
  //  CONSTANT :
  //  Do not change.
  ///////////////////////////////
//   lang: string;
//   currentFormMode: string = '';
//   currentAction: string = '';


  //ogData = [];
  filteredData = [...this.ogData];
  sortedData = [...this.filteredData];
  paginatedData = [...this.filteredData];
  lastId: number = this.ogData.length + 1;

  SelectAllBox: any = false;

  itemsPerPage = 10;
  itemOptionsPerPage = [5, 10, 20];
  selected = [];
  selectAllState = '';
//   itemOrder: any;
  orderAsc: boolean = true;

  currentPage = 1;
  savedPage = 0;


  dataFilterer = new DataFilterer();
  dataSorter = new DataSorter();
  dataPaginator = new DataPaginator();

  loadData() {
    this.currentAction = 'loading';
    this.sortData();
    this.loadSearch();
    this.currentAction = '';
    // this.modelService.getAll().subscribe(
    //   data => {
    //     console.log(data);
        
    //     this.ogData = data;
    //     this.sortData();
    //     this.loadSearch();
    //     this.currentAction = '';
    //   }
    // )
    
  }

  sortData() {
    this.sortedData = this.dataSorter.sortData(this.itemOrder.property, this.ogData);
    this.filterData();
  }

  reverseSortData() {
    this.savedPage = this.currentPage;
    this.sortedData = this.sortedData.slice().reverse();
    this.filterData();
  }

  filterData() {
    this.filteredData = this.dataFilterer.filterData(this.SearchTerm, this.searchableKeys, this.sortedData)
    if (this.savedPage === 0) {
      this.paginateData(this.itemsPerPage, 1);
    }
    else {
      this.paginateData(this.itemsPerPage,
        Math.ceil((this.filteredData.length / this.itemsPerPage)) >= this.savedPage ?
          this.savedPage :
          Math.ceil((this.filteredData.length / this.itemsPerPage))
      );
    }
    this.savedPage = 0;
  }

  paginateData(newItemsPerPage: number, newCurrentPage: number) {
    this.itemsPerPage = newItemsPerPage;
    this.currentPage = newCurrentPage;
    this.paginatedData = this.filteredData.slice((newCurrentPage - 1) * (newItemsPerPage), (newItemsPerPage * newCurrentPage));
  }

  changeSort(newSort: any) {
    if (this.itemOrder.label === newSort.label) {
      this.orderAsc = !this.orderAsc;
      const oldPage = this.currentPage;
      this.reverseSortData()
      this.currentPage = oldPage;
    }
    else {
      this.orderAsc = true;
      this.itemOrder = newSort;
      this.sortData();
    }
  }

  getSort(label: any) {
    return this.sortableKeys.find(i => i.label === label);
  }

  changePage(event: PageChangedEvent): void {
    this.currentPage = event.page;
    this.paginateData(this.itemsPerPage, this.currentPage);
  }

  changeFilter() {
    this.deletionList = [];
    this.selected = [];
    this.selectAllState = '';
    this.filterData();
  }

  selectAll($event): void {
    if ($event.target.checked) {
      this.selected = [...this.filteredData];
    } else {
      this.selected = [];
    }
    this.setSelectAllState();
  }

  setSelectAllState(): void {
    if (this.selected.length >= this.filteredData.length) {
      this.selectAllState = 'checked';
    } else if (this.selected.length !== 0) {
      this.selectAllState = 'indeterminate';
    } else  {
      this.selectAllState = '';
    }
  }

  isSelected(p : RevenueCodesModule): boolean {
    return this.selected.findIndex(x => x.Id === p.Id) > -1;
  }

  onSelect(item: RevenueCodesModule): void {
    if (this.isSelected(item)) {
      this.selected = this.selected.filter(x => x.Id !== item.Id);
    } else {
      this.selected.push(item);
    }
    this.setSelectAllState();
  }

  findByCode(list: any, code: any): any {
    return list.find(x => x.Code === code)
  }

  @ViewChild('sideModalRef', { static: true }) sideModalRef: SidePopupComponent;
  @ViewChild('delModal') delModal: ModalDirective;
  showAddNewModalRef(): void {
    this.currentFormMode = 'Add'
    this.modelObject = new RevenueCodesModule;
    this.mainForm = this.createForm();
    this.sideModalRef.show();
  }

  showEditModalRef(Id: any): void {
    this.currentFormMode = 'Edit'
    this.modelObject = this.ogData.find(i => i.Id === Id);
    of(this.modelObject).subscribe(data => {
      this.modelObject = data;
    })
    this.mainForm = this.createForm();
    this.sideModalRef.show();
  }

  showInfoModalRef(Id: any): void {
    this.currentFormMode = 'Info'
    this.modelObject = this.ogData.find(i => i.Id === Id);
    of(this.modelObject).subscribe(data => {
      this.modelObject = data;
    })
    this.mainForm = this.createForm();
    this.sideModalRef.show();
  }

  deletionList: RevenueCodesModule[] = [];
  showDelModal(Id: any): void {
    if (Id >= 0) {
      this.currentFormMode = 'Del'
      this.modelObject = this.ogData.find(i => i.Id === Id);
      this.deletionList = [this.ogData.find(i => i.Id === Id)];
      this.delModal.show();
    }
    else if (Id === 'delMulti') {
      this.deletionList = [...this.selected]
      this.delModal.show();
    }
  }

  SearchTerm: any = '';


  finalizeDeletion(){
    if (this.deletionList.length === 1) {
      this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyDeleted") + " " +
        this.translate.instant("One" + this.translationName));
    }
    else if (this.deletionList.length === 2) {
      this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyDeleted") + " " +
        this.translate.instant("Two" + this.translationName + "s"));
    }
    else if (this.deletionList.length > 2 && this.deletionList.length < 11) {
      this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyDeleted") + " " +
        this.deletionList.length + " " + this.translate.instant(this.translationName + "RowsPlur"));
    }
    else {
      this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyDeleted") + " " +
        this.deletionList.length + " " + this.translate.instant(this.translationName + "Rows"));
    }

    this.deletionList = [];
    this.selected = [];

    this.setSelectAllState();
    if (this.selectAllState === '') {
      this.SelectAllBox = false;
    }
    this.delModal.hide();
  }

  toastSuccess(toastHeader: string, toastBody: string): void {
    this.notifications.create(toastHeader, toastBody,
      NotificationType.Success, { timeOut: 3000, showProgressBar: true });
  }
  toastError(toastHeader: string, toastBody: string): void {
    this.notifications.create(toastHeader, toastBody,
      NotificationType.Error, { timeOut: 3000, showProgressBar: true });
  }
  deleteMultiTaxTypes() {
    this.loadData();
    this.delModal.hide();
  }

  loadAllData() {
    this.loadSearch();

    // this.modelService.getAll().subscribe(
    //   data => {
    //     this.ogData = data;
    //   }
    // )
  }

  loadSearch() {
    const prevSearch = this.SearchTerm;
    this.SearchTerm = '';
    setTimeout(() => {
      this.SearchTerm = prevSearch;
    }, 1);
  }
}
