import { Component, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { NotificationType, NotificationsService } from "angular2-notifications";
import { ModalDirective } from "ngx-bootstrap/modal";
import { TableEngine } from "src/app/invoices/TableEngine";
import { SidePopupComponent } from "src/app/views/invoice/side-popup/side-popup.component";
import {SalariesManagerModel} from '../add-salaries-manager/SalariesManagerModel';
import{SalariesManagerService} from '../salaries-manager.service';
import { EmployeesChoiceModel } from "../add-salaries-manager/EmployeesChoiceModel";
import { EmployeesExcludedModel } from "../add-salaries-manager/EmployeesExcludedModel";
import { SalariesPaymentService } from "../add-salaries-payment/salaries-payment.service";
import { SalariesPaymentModel } from "../add-salaries-payment/SalariesPaymentModel";
import {SalarySlipService} from '../add-salary-slip/salary-slip.service';
import { SalarySlipModel } from "../add-salary-slip/SalarySlipModel";
@Component({
    // moduleId: module.id,
    selector: 'info-salaries-manager',
    templateUrl: 'info-salaries-manager.component.html',
    styleUrls: ['info-salaries-manager.component.scss']
})
export class InfoSalariesManagerComponent {
    Id = 0;

    constructor(
      public router: Router,
      private fb: FormBuilder,
      private modelService:SalariesManagerService ,
      private SalariesPaymentService:SalariesPaymentService ,
      private SalarySlipService:SalarySlipService ,
      private notifications: NotificationsService,

      private translate: TranslateService,
      private route: ActivatedRoute
    ) {
      this.Id = +route.snapshot.paramMap.get("Id");
    }
  
    tableEngine = new TableEngine(this.translate, this.notifications);
    currentScreen: any = "Table";
    translationName: string = "Item";
    sortableKeys = [];
    searchableKeys = ["Name", "Address"];
    tableView: any = true;
  
    SalariesManagerModel:SalariesManagerModel[]=[];
    
    // WarehouseData: WarehouseModel[] = [];
  
    modelObject: SalariesManagerModel = new SalariesManagerModel();
    Form: FormGroup;
    declare lang: any;
    declare itemOrder: any;
    currentAction = "";
    savedPage = 0;
    currentPage = 1;
    deletionList: any[] = [];
    prevAction = "";
    addToListId = 0;
    ItemsBalanceHeaderId = 0;
    ItemBalanceId = 0;
    currentFormMode: string = "";
  
    ogData: SalariesManagerModel[] = [];
    mainForm: FormGroup = new FormGroup({
        Id: new FormControl(),
        Name: new FormControl(),
        dateOfRegistration: new FormControl(),
        StartingDate: new FormControl(),
        EndDate: new FormControl(),
        CheckAttendance: new FormControl(),
        GrammarChoose: new FormControl(),
        EmployeesChoose: new FormControl(),
        BranchId: new FormControl(),
        DepartmentId: new FormControl(),
        JobTitletId: new FormControl(),
        ArrestCycleId: new FormControl(),
        
        
    });  
    // NotesObject: NotesAndAttachmentModel = new NotesAndAttachmentModel();


  
    @ViewChild("sideModalRef", { static: true })
    sideModalRef: SidePopupComponent = null;
    @ViewChild("delModal", { static: true }) delModal: ModalDirective;
    @ViewChild("smallModal", { static: true }) smallModal: ModalDirective = null;
    formPresentationModal: any;
    ngOnInit(): void {
      // this.getAllCountryCode();
  
      this.lang = localStorage.getItem("theme_lang");
      this.itemOrder = {
        label: "Name",
        property: this.lang === "ar-EG" ? "Name" : "Name",
      };
      this.sortableKeys = [
        // { label: 'Date', property: 'Date' },
        { label: 'Name',property:  this.lang === 'ar-EG' ? 'Name' : 'Name'},
        { label: 'limitation', property: 'limitation' },
        
      ];
  
      this.tableEngine.loadTableEngine(
        this.ogData,
        this.modelService,
        this.modelObject,
        this.searchableKeys,
        this.sortableKeys,
        this.mainForm,
        this.translationName,
        this.itemOrder,
        this.sideModalRef,
        this.delModal,
        this.smallModal,
        this.lang
      );

     
          if(this.Id > 0)
          {   debugger;                            
                  this.modelService.getById(this.Id).subscribe(data=>{                        
                  this.modelObject = data;
                  this.EmployeesChoiceList=data.EmployeesChoice;
                  this.EmployeesExcludedList=data.EmployeesExcluded;
                  this.SalariesPaymentService.GetAllBySalariesId(this.Id).subscribe(data=>{
                    this.SalariesPaymentList=data;
                  });
                  this.SalarySlipService.getAll().subscribe(data=>{
this.SalarySlipList=data;
                  })
                 this.mainForm = this.createForm(this.modelObject);
              });
                 this.mainForm = this.createForm(this.modelObject);
          }
          else{
             this.mainForm = this.createForm(this.modelObject);       
              }
    }
  
    EmployeesChoiceList:EmployeesChoiceModel[]=[];
    EmployeesExcludedList:EmployeesExcludedModel[]=[];
    SalariesPaymentList:SalariesPaymentModel[]=[];
    SalarySlipList:SalarySlipModel[]=[];
    createForm(modelObject): FormGroup {
      debugger;
      return this.fb.group({
        Id: [modelObject.Id >= 0 ? modelObject.Id : 0],
        Name: [modelObject.Name, Validators.compose([Validators.required])],
        dateOfRegistration: [modelObject.dateOfRegistration, Validators.compose([Validators.required])],
        StartingDate: [modelObject.StartingDate,Validators.compose([Validators.required])],
        EndDate: [modelObject.EndDate,Validators.compose([Validators.required])],
        CheckAttendance: [modelObject.CheckAttendance],
        GrammarChoose: [modelObject.GrammarChoose],
        EmployeesChoose: [modelObject.EmployeesChoose],
        BranchId: [modelObject.BranchId],
        DepartmentId: [modelObject.DepartmentId],
        JobTitletId: [modelObject.JobTitletId],
        ArrestCycleId: [modelObject.ArrestCycleId],
        
        
        });
    }

    AcceptAll(){
        debugger;
        if(this.modelObject.AcceptAll==true){
        this.modelObject.AcceptAll=false;
      }
      else{
        this.modelObject.AcceptAll=true;
      }
      }
      Payment(Id){
        this.router.navigate(['app/HR/Add-Salaries-Payment/',0])   
      }
    showModal(Id, action) {
  
        if (action === 'Add') {          
         debugger;
          this.router.navigate(['app/HR/Add-Salaries-Manager/',0])   
        }
        else if (action === 'Edit') {
          debugger;
          this.router.navigate(['app/HR/Add-Salaries-Manager/',Id])
      
          
        }
        else if (action === 'Info') {
         debugger;
          
          this.router.navigate(['app/HR/Info-Salaries-Manager/',Id])
          
         
        }
        //this.mainForm.get('IsActive').setValue(true);
         this.modelObject = this.tableEngine.modelObject;
         this.mainForm = this.tableEngine.mainForm;
      
      }
      showDelModal(Id: any) {
        debugger;
          this.currentFormMode = 'Del'
          this.modelObject = this.ogData.find(i => i.Id === Id);
          this.deletionList = [this.ogData.find(i => i.Id === Id)];
          this.delModal.show();
        
      }
    //   EditPayment(Id){
    //     debugger;
    //     this.router.navigate(['app/HR/Edit-Attachment/',Id])

    //   }
      
    //   DisableEmployee(){
    //     debugger;
    //     if(this.modelObject.Status==true){
    //     this.modelObject.Status=false;
    //   }
    //   else{
    //     this.modelObject.Status=true;
    //   }
    //   }
      DeletePayment(Id){
        debugger;

        if (Id >= 0) {
          this.currentFormMode = 'Del'
          this.delModal.show();
        }
        else if (Id === 'delMulti') {
          this.deletionList = [...this.selected]
          this.delModal.show();
        }
       
      }

      deleteObject() {
        this.savedPage = this.currentPage;
        //prevent multiclicks. re enable after API response
        this.currentAction = 'loading';
        this.executeDeleteObject();
      }
  selected : any[] = [];
      
      toastSuccess(toastHeader: string, toastBody: string): void {
        this.notifications.create(
          toastHeader,
          toastBody,
          NotificationType.Success,
          { timeOut: 3000, showProgressBar: true }
        );
      }
  
      //Same here...
      executeDeleteObject(){
        debugger;
        this.modelService.deleteList(this.tableEngine.deletionList).subscribe(result => {
          if (result === true) {
            this.tableEngine.loadData();
            this.currentAction = '';
            this.tableEngine.delModal.hide();
            // this.finalizeDeletion();
            this.tableEngine.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyDeleted"));
            if (this.sideModalRef === undefined) {
      
              this.tableEngine.smallModal.hide();
      
            } else {
      
              this.tableEngine.sideModalRef.modalRef.hide();
            }
          }
          else {
            this.toastError(this.translate.instant("Error"), this.translate.instant("FailedDelete"));
            this.currentAction = '';
          }
        })
      }
      toastError(toastHeader: string, toastBody: string): void {
        this.notifications.create(toastHeader, toastBody,
          NotificationType.Error, { timeOut: 3000, showProgressBar: true });
      }
      

    loadServices() {
      // debugger;
      this.currentAction = "loading";
      this.tableEngine.loadData();
    }
  
 
    type = 0;
    visable = "";
    visable2 = "";
    visable3 = "";
  
  
    goBack() {
      this.router.navigate(["app/HR/List-Salaries-Manager"]);
    }
}
