<!--component html goes here -->

<div class="form-group" *ngIf="!tableView">
    <div class="modal-body">
        <form class="md-float-material form-material" [formGroup]="mainForm">

            <div style="text-align: center;">
                <button class="btn btn-light" (click)="tableView=true"
                    style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Back" |
                    translate}}</button>
                <button *ngIf="tableEngine.currentFormMode==='Add'"
                    [disabled]="mainForm.invalid || tableEngine.currentAction==='loading'" id="smallModalSubmitButton"
                    class="btn btn-secondary" (click)="addObject()"
                    style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Add" |
                    translate}}</button>
                <button *ngIf="tableEngine.currentFormMode==='Add'"
                    [disabled]="mainForm.invalid || tableEngine.currentAction==='loading'" id="smallModalSubmitButton"
                    class="btn btn-secondary" (click)="addAndNew()"
                    style="font-weight: bold; width: 250px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"AddAndNew" |
                    translate}}</button>
                <button *ngIf="tableEngine.currentFormMode==='Edit'"
                    [disabled]="mainForm.invalid || tableEngine.currentAction==='loading'" id="smallModalSubmitButton"
                    class="btn btn-secondary" (click)="editObject()"
                    style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Edit"
                    | translate}}</button>
            </div>
            <div class="form-group" style="margin-top: 10px;">
                <div class="row">
                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"NameAr" | translate}}</label>
                        <input *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                            type="text" name="NameAr" class="form-control" formControlName="NameAr"
                            placeholder='{{"NameAr" | translate}}'>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="NameAr">{{modelObject.NameAr}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('NameAr').hasError('required') || mainForm.get('NameAr').invalid) && ( mainForm.get('NameAr').dirty ||  mainForm.get('NameAr').touched)">
                            {{"NameArRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>
                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"NameEn" | translate}}</label>
                        <input *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                            type="text" name="NameEn" class="form-control" formControlName="NameEn"
                            placeholder='{{"NameEn" | translate}}'>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="NameEn">{{modelObject.NameEn}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('NameEn').hasError('required') || mainForm.get('NameEn').invalid) && ( mainForm.get('NameEn').dirty ||  mainForm.get('NameEn').touched)">
                            {{"NameEnRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>
                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"StoreAddress" | translate}}</label>
                        <input *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                            type="text" name="StoreAddress" class="form-control" formControlName="StoreAddress"
                            placeholder='{{"StoreAddress" | translate}}'>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="StoreAddress">{{modelObject.StoreAddress}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('StoreAddress').hasError('required') || mainForm.get('StoreAddress').invalid) && ( mainForm.get('StoreAddress').dirty ||  mainForm.get('StoreAddress').touched)">
                            {{"StoreAddressRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>
                </div>

                <br>

                <div class="row">
                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"StoreMobil" | translate}}</label>
                        <input *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'" type="text"
                            name="StoreMobil" class="form-control" formControlName="StoreMobil"
                            placeholder='{{"StoreMobil" | translate}}'>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="StoreMobil">{{modelObject.StoreMobil}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('StoreMobil').hasError('required') || mainForm.get('StoreMobil').invalid) && ( mainForm.get('StoreMobil').dirty ||  mainForm.get('StoreMobil').touched)">
                            {{"StoreMobilRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>
                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"StorePhone" | translate}}</label>
                        <input *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'" type="text"
                            name="StorePhone" class="form-control" formControlName="StorePhone"
                            placeholder='{{"StorePhone" | translate}}'>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="StorePhone">{{modelObject.StorePhone}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('StorePhone').hasError('required') || mainForm.get('StorePhone').invalid) && ( mainForm.get('StorePhone').dirty ||  mainForm.get('StorePhone').touched)">
                            {{"StorePhoneRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>
                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"StoreFax" | translate}}</label>
                        <input *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'" type="text"
                            name="StoreFax" class="form-control" formControlName="StoreFax"
                            placeholder='{{"StoreFax" | translate}}'>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="StoreFax">{{modelObject.StoreFax}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('StoreFax').hasError('required') || mainForm.get('StoreFax').invalid) && ( mainForm.get('StoreFax').dirty ||  mainForm.get('StoreFax').touched)">
                            </div>
                        <span class="form-bar"></span>
                    </div>
                </div>

                <br>
                
                <div class="row">
                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"StoreWhatsapp" | translate}}</label>
                        <input *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'" type="text"
                            name="StoreWhatsapp" class="form-control" formControlName="StoreWhatsapp"
                            placeholder='{{"StoreWhatsapp" | translate}}'>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="StoreWhatsapp">{{modelObject.StoreWhatsapp}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('StoreWhatsapp').hasError('required') || mainForm.get('StoreWhatsapp').invalid) && ( mainForm.get('StoreWhatsapp').dirty ||  mainForm.get('StoreWhatsapp').touched)">
                           </div>
                        <span class="form-bar"></span>
                    </div>
                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"StoreEmail" | translate}}</label>
                        <input *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'" type="text"
                            name="StoreEmail" class="form-control" formControlName="StoreEmail"
                            placeholder='{{"StoreEmail" | translate}}'>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="StoreEmail">{{modelObject.StoreEmail}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('StoreEmail').hasError('required') || mainForm.get('StoreEmail').invalid) && ( mainForm.get('StoreEmail').dirty ||  mainForm.get('StoreEmail').touched)">
                            {{"StoreEmailRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>
                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"StoreManager" | translate}}</label>
                        <input *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'" type="text"
                            name="StoreManager" class="form-control" formControlName="StoreManager"
                            placeholder='{{"StoreManager" | translate}}'>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="StoreManager">{{modelObject.StoreManager}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('StoreManager').hasError('required') || mainForm.get('StoreManager').invalid) && ( mainForm.get('StoreManager').dirty ||  mainForm.get('StoreManager').touched)">
                            {{"StoreManagerRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>
                 
                </div>

                <br>
                
                <div class="row">
                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"StoreCountryCode" | translate}}</label>
                        <div *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'">
                            <ng-select appearance="outline" name="CountryCodeId" (change)="getAllGovernorateCode()" formControlName="CountryCodeId"
                                placeholder='{{"StoreCountryCode" | translate}}' #StoreCountryCodeSelect labelForId="txtycd"
                                [searchable]="true">
                                <ng-option *ngFor="let country of CountryCodeList" [value]="country.Id">
                                    {{lang==='ar-EG'?country.NameAr:country.NameEn}}</ng-option>
                            </ng-select>
                        </div>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="CountryCodeId">{{lang==='ar-EG'?getCountryCodeById(modelObject.CountryCodeId).NameAr:getCountryCodeById(modelObject.CountryCodeId).NameEn}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('CountryCodeId').hasError('required') || mainForm.get('CountryCodeId').invalid) && ( mainForm.get('CountryCodeId').dirty ||  mainForm.get('CountryCodeId').touched)">
                            {{"StoreCountryRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>


                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"StoreGovernorateCode" | translate}}</label>
                        <div *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'">
                            <ng-select appearance="outline" name="GovernorateCodeId" (change)="getAllCityCode()" formControlName="GovernorateCodeId"
                                placeholder='{{"StoreGovernorateCode" | translate}}' #StoreCountryCodeSelect labelForId="txtycd"
                                [searchable]="true">
                                <ng-option *ngFor="let Governorate of GovernorateCodeList" [value]="Governorate.GovernorateId">
                                    {{lang==='ar-EG'?Governorate.GovernorateNameL1:Governorate.GovernorateNameL2}}</ng-option>
                            </ng-select>
                        </div>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="GovernorateCodeId">{{lang==='ar-EG'?getGovernorateCodeById(modelObject.GovernorateCodeId).GovernorateNameL1:getGovernorateCodeById(modelObject.GovernorateCodeId).GovernorateNameL1}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('GovernorateCodeId').hasError('required') || mainForm.get('GovernorateCodeId').invalid) && ( mainForm.get('GovernorateCodeId').dirty ||  mainForm.get('GovernorateCodeId').touched)">
                            {{"StoreGovernorateRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>

    
                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"StoreCityCode" | translate}}</label>
                        <div *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'">
                            <ng-select appearance="outline" name="CityCodeId" formControlName="CityCodeId"
                                placeholder='{{"StoreCityCode" | translate}}' #StoreCityCodeSelect labelForId="txtycd"
                                [searchable]="true">
                                <ng-option *ngFor="let city of cityCodeList" [value]="city.CityId">
                                    {{lang==='ar-EG'?city.CityNameL1:city.CityNameL2}}</ng-option>
                            </ng-select>
                        </div>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="CityCodeId">{{lang==='ar-EG'?getCityCodeById(modelObject.CityCodeId).CityNameL1:getCityCodeById(modelObject.CityCodeId).CityNameL2}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('CityCodeId').hasError('required') || mainForm.get('CityCodeId').invalid) && ( mainForm.get('CityCodeId').dirty ||  mainForm.get('CityCodeId').touched)">
                            {{"StoreCityRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>
                   
                </div>
            </div>

            <br>           
                              
            <br>
            
            <div class="row" style="height: 160px;">
                <div class=" col-md-4">
                    <label class="float-label push righ">{{"StoreDescription" | translate}}</label>
                    <textarea *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'" type="text"
                        name="Description" class="form-control" formControlName="Description"
                        style="width: 600px; height: 150px;"
                        placeholder='{{"StoreDescription" | translate}}'></textarea>
                    <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                        style="white-space:pre-wrap; word-wrap:break-word; width: 600px; height: 150px; overflow: scroll;"
                        name="Description">{{modelObject.Description}}</label>
                    <span class="form-bar"></span>
                </div>
            </div>

            <br>

            <!-- <div id="divmiddle" style="display: block; margin-top: 5px;"> -->
                <div>
                <div style="width: 100%; margin-top: 15px;">
                    <div>
                       
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<div bsModal #delModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">
    <div class="modal-dialog modal-sm">
        <div class="modal-content"  >
            <button type="button" class="close pull-right" aria-label="Close" (click)="delModal.hide()"
                style="margin-right: 20px; margin-left: 20px; margin-top: 10px; width: 20px; display: inline;">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-header" style="display: inline; padding: 0px 0px 5px 0px;">
                <h4 id="dialog-child-name" class="modal-title pull-left"  style="text-align: center;">
                    {{"ConfirmDel"+translationName |
                    translate}}</h4>
            </div>
            <div class="modal-body">
                <div class="form-group form-primary" *ngIf="tableEngine.deletionList.length < tableEngine.ogData.length 
                || tableEngine.deletionList.length === 1" style="text-align: center;">
                    <div *ngFor="let item of tableEngine.deletionList;" style="text-align: center;">
                        <label type="text">- {{lang==='ar-EG'?item.NameAr:item.NameEn}}</label>
                        <span class="form-bar"></span>
                    </div>
                </div>
                <div class="form-group form-primary" style="text-align: center;" *ngIf="tableEngine.deletionList.length > 1 && 
                    tableEngine.deletionList.length === tableEngine.ogData.length">
                    <label type="text">
                        <h1 style="font-weight: bolder; text-align: center;">{{translationName+"DeleteAll" | translate}}
                        </h1>
                    </label>
                    <span class="form-bar"></span>
                </div>

                <div style="margin-left: 5px; text-align: center; margin-top: 30px;">
                    <button class="btn btn-danger" id="smallModalDeleteButton" (click)="deleteObject()"
                        [disabled]="tableEngine.currentAction==='loading'"
                        style="font-weight: bold; width: 100%; text-align: center;">{{"Delete" | translate}}</button>
                    <button class="btn btn-light" (click)="delModal.hide()"
                        style="font-weight: bold; width: 100%; text-align: center; margin-top: 10px;">{{"Close" |
                        translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="tableView" class="FormScreen" class="tableScreen">
    <div id="divtop" style="display: block;height: 47px; width: 100%;">
        <div id="divsearchmiddle" style="display: inline-block; width: 70%;
        height: inherit;  text-align: center; padding: 0px; margin: 0px;">
            <div class="search" style="display: inline-block; margin-left: 50px; margin-right: 50px;">
                <div class="d-inline-block">
                    <div class="search-sm d-inline-block mr-1 mb-1 align-top" style="width: 600px;">
                        <input type="search" [(ngModel)]="tableEngine.SearchTerm" (keyup)="tableEngine.changeFilter()"
                            (change)="tableEngine.changeFilter()" class="form-control input-sm full-data-search"
                            placeholder="{{translationName+'Search' | translate}}">
                    </div>
                </div>
            </div>
        </div>

        <div id="divsearchleft" style="display: inline-block; width: 30%; height: inherit;">
            <div style="display: block; height: 30%; ">
                &nbsp;
            </div>
            <div class="search" style="display: block; height: 70%; text-align: center;">
                <button class="btn btn-primary d-inline-block"
                    style="font-weight: bold; text-align: center; padding: 0px; width: 150px; height: 30px;"
                    (click)="showModal(null,'Add')"
                    [disabled]="tableEngine.currentAction==='loading'">{{"Add"+translationName |
                    translate}}</button>

            </div>
        </div>

    </div>


    <div id="divmiddle" style="display: block; margin-top: 5px;">

        <div style="width: 100%;">
            <div>
                <table class="table">
                    <thead class="thead-light">
                        <th style="text-align: center; padding:5px;">
                            <div class=" text-zero" style="display: inline-block;">
                                <div class="top-right-button-container2 btn-group" dropdown>
                                    <div class="btn btn-primary pl-4 pr-0 check-button">
                                        <label class="custom-control custom-checkbox mb-0 d-inline-block"
                                            style="width: 10px;">
                                            <input type="checkbox" class="custom-control-input"
                                                [checked]="tableEngine.selectAllState==='checked'"
                                                [indeterminate]="tableEngine.selectAllState === 'indeterminate'"
                                                (change)="tableEngine.selectAll($event)">
                                            <span class="custom-control-label">&nbsp;</span>
                                        </label>
                                    </div>
                                    <button id="button-split" type="button" dropdownToggle style="width: 10px;"
                                        class="btn btn-primary dropdown-toggle dropdown-toggle-split"
                                        aria-controls="dropdown-split">
                                        <span class="caret"></span>
                                    </button>
                                    <ul id="dropdown-split" *dropdownMenu class="dropdown-menu dropdown-menu-right"
                                        role="menu" aria-labelledby="button-split">
                                        <li role="menuitem"><a class="dropdown-item">
                                                <button class="btn btn-danger d-inline-block"
                                                    style=" font-weight: bold; text-align: center; padding: 0px; width: 150px; height: 30px;"
                                                    (click)="tableEngine.showDelModal('delMulti')"
                                                    [disabled]="tableEngine.selected.length===0 || tableEngine.currentAction==='loading'">{{"DeleteSelected"
                                                    |
                                                    translate}}</button></a></li>
                                    </ul>
                                </div>
                            </div>
                        </th>

                        <th class="sorticon" (click)="prepChangeSort('StoreName')"
                            [ngClass]="(itemOrder.label==='StoreName')?'sortkey sorticon':'sorticon'">
                            <div
                                [ngClass]="{'iconsminds-up---down': (itemOrder.label!='StoreName'), 
                            'iconsminds-down': ((itemOrder.label==='StoreName') && tableEngine.orderAsc === true),
                            'iconsminds-up': ((itemOrder.label==='StoreName') && tableEngine.orderAsc === false)}">
                            </div>
                        </th>
                        <th class="sortOption" (click)="prepChangeSort('StoreName')"
                            [ngClass]="{'sortkey': (itemOrder.label==='StoreName')}">
                            {{"StoreName" | translate}}
                        </th>

                        <th class="sorticon" (click)="prepChangeSort('StoreAddress')"
                            [ngClass]="(itemOrder.label==='StoreAddress')?'sortkey sorticon':'sorticon'">
                            <div
                                [ngClass]="{'iconsminds-up---down': (itemOrder.label!='StoreAddress'), 
                            'iconsminds-down': ((itemOrder.label==='StoreAddress') && tableEngine.orderAsc === true),
                            'iconsminds-up': ((itemOrder.label==='StoreAddress') && tableEngine.orderAsc === false)}">
                            </div>
                        </th>
                        <th class="sortOption" (click)="prepChangeSort('StoreAddress')"
                            [ngClass]="{'sortkey': (itemOrder.label==='StoreAddress')}">
                            {{"StoreAddress" | translate}}
                        </th>

                        <th class="sorticon" (click)="prepChangeSort('StoreEmail')"
                            [ngClass]="(itemOrder.label==='StoreEmail')?'sortkey sorticon':'sorticon'">
                            <div
                                [ngClass]="{'iconsminds-up---down': (itemOrder.label!='StoreEmail'), 
                            'iconsminds-down': ((itemOrder.label==='StoreEmail') && tableEngine.orderAsc === true),
                            'iconsminds-up': ((itemOrder.label==='StoreEmail') && tableEngine.orderAsc === false)}">
                            </div>
                        </th>
                        <th class="sortOption" (click)="prepChangeSort('StoreEmail')"
                            [ngClass]="{'sortkey': (itemOrder.label==='StoreEmail')}">
                            {{"StoreEmail" | translate}}
                        </th>


                        <th class="sorticon" (click)="prepChangeSort('StoreMobil')"
                            [ngClass]="(itemOrder.label==='StoreMobil')?'sortkey sorticon':'sorticon'">
                            <div
                                [ngClass]="{'iconsminds-up---down': (itemOrder.label!='StoreMobil'), 
                            'iconsminds-down': ((itemOrder.label==='StoreMobil') && tableEngine.orderAsc === true),
                            'iconsminds-up': ((itemOrder.label==='StoreMobil') && tableEngine.orderAsc === false)}">
                            </div>
                        </th>
                        <th class="sortOption" (click)="prepChangeSort('StoreMobil')"
                            [ngClass]="{'sortkey': (itemOrder.label==='StoreMobil')}">
                            {{"StoreMobil" | translate}}
                        </th>

                        <!-- <th class="sorticon" (click)="prepChangeSort('StoreDescription')"
                            [ngClass]="(itemOrder.label==='StoreDescription')?'sortkey sorticon':'sorticon'">
                            <div
                                [ngClass]="{'iconsminds-up---down': (itemOrder.label!='StoreDescription'), 
                            'iconsminds-down': ((itemOrder.label==='StoreDescription') && tableEngine.orderAsc === true),
                            'iconsminds-up': ((itemOrder.label==='StoreDescription') && tableEngine.orderAsc === false)}">
                            </div>
                        </th>
                        <th class="sortOption" (click)="prepChangeSort('StoreDescription')"
                            [ngClass]="{'sortkey': (itemOrder.label==='StoreDescription')}">
                            {{"StoreDescription" | translate}}
                        </th> -->

                        <th></th>
                        <th [colSpan]="2" style="text-align: center;">
                            <div id="displayOptions" style="
                            display: inline; margin-top: 10px; margin-left: 5px; margin-right: 5px;">
                                <span class="text-muted text-small" style=" width: 50px; height: 40px;">
                                    {{"ResultCount" | translate}}
                                </span>
                                <span dropdown class="d-inline-block position-relative">
                                    <button class="btn btn-outline-dark btn-xs dropdown-toggle" type="button"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                        dropdownToggle>
                                        {{tableEngine.itemsPerPage}}
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-sm text-right"
                                        *dropdownMenu>
                                        <li role="menuitem" *ngFor="let item of tableEngine.itemOptionsPerPage"><a
                                                [ngClass]="item === tableEngine.itemsPerPage ? 'dropdown-item active' : 'dropdown-item'"
                                                (click)="tableEngine.paginateData(item, 1)">{{item}}</a></li>
                                    </div>
                                </span>
                            </div>
                        </th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let obj of tableEngine.paginatedData;  let i=index">
                            <td style="text-align: center;">
                                <div class="itemCheck mb-0 custom-checkbox custom-control"
                                    style="display: inline-block;">
                                    <input type="checkbox" class="custom-control-input" [id]="'customCheck'+i"
                                        [checked]="tableEngine.isSelected(obj)" (change)="tableEngine.onSelect(obj)">
                                    <label class="custom-control-label" [for]="'customCheck'+i"></label>
                                </div>
                            </td>
                            <!-- <td colspan="2">{{lang==='ar-EG'?obj.NameAr:obj.NameEn}}</td>
                       
                            <td colspan="2">
                                {{lang==='ar-EG'?getCountryCodeById(obj.Id).NameAr:getCountryCodeById(obj.Id).NameEn}}
                            </td> -->
                            
                            <td colspan="2">{{lang==='ar-EG'?obj.NameAr:obj.NameEn}}</td>
                            <td colspan="2">{{obj.StoreAddress}}</td>
                            <td colspan="2">{{obj.StoreEmail}}</td>
                            <td colspan="2">{{obj.StoreMobil}}</td>
                            <!-- <td colspan="2">{{obj.Description}}</td> -->
                            <td><button class="btn btn-info text-center" (click)="showModal(obj.Id, 'Info')"
                                    [disabled]="tableEngine.currentAction==='loading'"
                                    style="font-weight: bold; width: 100%;">{{"More"
                                    | translate}}</button></td>
                            <td><button class="btn btn-outline-warning text-center" (click)="showModal(obj.Id, 'Edit')"
                                    [disabled]="tableEngine.currentAction==='loading'"
                                    style="font-weight: bold; width: 100%;">{{"Edit"
                                    | translate}}</button></td>
                            <td><button class="btn btn-outline-danger text-center"
                                    (click)="tableEngine.showDelModal(obj.Id)"
                                    [disabled]="tableEngine.currentAction==='loading'"
                                    style="font-weight: bold; width: 100%">{{"Delete"
                                    | translate}}</button></td>
                        </tr>
                    </tbody>
                    <thead class="thead-light">
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </thead>
                </table>
            </div>
        </div>
    </div>

    <div id="divbottom" style="display: block; height: 47px;">

        <div id="divsearchright" style="float: right;  width: 33.33%; padding: 0px;">&nbsp;</div>

        <div id="divsearchmiddle" style="display: inline-block; width: 33.33%; height: 100%;">

            <div style="text-align: center;">
                <bdo dir="ltr">
                    <pagination class="pagination-sm2" style="padding: 0px, 0px, 0px, 0px; margin: 0px, 0px, 0px, 0px;
                    display: inline-block;" [(ngModel)]="currentPage" [boundaryLinks]="true"
                        [totalItems]="tableEngine.filteredData.length" [itemsPerPage]="tableEngine.itemsPerPage"
                        [customFirstTemplate]="firstTemplate" [customLastTemplate]="lastTemplate"
                        [customPreviousTemplate]="prevTemplate" [customNextTemplate]="nextTemplate" [maxSize]="5"
                        (pageChanged)="tableEngine.changePage($event)">
                    </pagination>
                </bdo>
            </div>
        </div>

        <div id="divsearchleft" style="float: left; width: 33.33%; height: 100%;">&nbsp;</div>

    </div>
</div>

<div>

</div>

<ng-template #nextTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i *ngIf="lang==='en-US'" class='simple-icon-arrow-right'></i>
    <i *ngIf="lang==='ar-EG'" class='simple-icon-arrow-left'></i>
</ng-template>

<ng-template #prevTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i *ngIf="lang==='en-US'" class='simple-icon-arrow-left'></i>
    <i *ngIf="lang==='ar-EG'" class='simple-icon-arrow-right'></i>
</ng-template>

<ng-template #lastTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i class='simple-icon-control-end'></i>
</ng-template>

<ng-template #firstTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i class='simple-icon-control-start'></i>
</ng-template>


