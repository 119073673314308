import { TaxModel } from 'src/app/invoices/create-invoice/helper/model/taxModel';
import { TaxReturnReportDataModel } from './../../models/tax-return-report-data.model';
import { TaxReturnReportSearchModel } from './../../models/tax-return-report-search.model';
import { Component, OnInit } from '@angular/core';
import { TaxReturnReportService } from '../../tax-return-report.service';
import { BranchService } from 'src/app/invoices/branches/BranchService';
import { TaxTypesService } from 'src/app/invoices/tax-types/TaxTypesService';
import { BranchModel } from '../../../../test-component/BranchModel';
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';

@Component({
    selector: 'tax-return-report',
    templateUrl: 'tax-return-report.component.html',
    styleUrls: ['tax-return-report.component.scss']
})
export class TaxReturnReportComponent implements OnInit{

    declare lang: any;
    data:TaxReturnReportDataModel[]=[];
    search:TaxReturnReportSearchModel={};
    taxes:TaxModel[]=[];
    totalAmount = 0;
    totalAmountAdjustments = 0;
    totalTaxDue = 0;
    constructor(private TaxReturnReportService:TaxReturnReportService,
        private branchService:BranchService,
        private taxService:TaxTypesService,
        ){
      this.lang = localStorage.getItem('theme_lang');
    }

    branches:BranchModel[]=[];
    today=new Date();
    ngOnInit(): void {
      debugger;
        this.TaxReturnReportService.search(this.search)
        .subscribe(data => {
          this.data =data;
          this.data.forEach(element => {
            if(element.Type == 1){
                this.totalAmount += element.Amount;
                this.totalAmountAdjustments += element.AmountAdjustments;
                this.totalTaxDue += element.TaxDue;
            }
             else if(element.Type == 2){
                this.totalAmount -= element.Amount;
                this.totalAmountAdjustments -= element.AmountAdjustments;
                this.totalTaxDue -= element.TaxDue;
             }
            });
          console.log('data',this.data)
        })
        this.branchService.getAll()
        .subscribe(branches=>{
          this.branches = branches;
        })
      this.taxService.getAll()
        .subscribe(data => {
            this.taxes = data;
        })
    
    }
    Search(){
        this.TaxReturnReportService.search(this.search)
        .subscribe(data => {
          this.data =data;
       
        })
    }
    exportTable(){
      var data = document.getElementById('contentToConvert');
      data.style.display = 'inline-block';
      html2canvas(data,{}).then(canvas => {
      var imgWidth = 208;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save('new-file.pdf'); // Generated PDF
      data.style.display = 'block';

    //   data.style.display = 'none';
      
    });
    }
    print(){
        let printContents, popupWin;
        printContents = document.getElementById('contentToConvert').innerHTML;
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.open();
        window.print();
       
      }
  
      changeSubCostCenter(){
          
      }
}
