
import { CashierOrderModel } from "../cashier-orders/CashierOrderModel";
export class CashierSessionModel{
    Id?:number;
    Code?:number;
    CashierId?:number;
    CashierNameAr?:string;
    CashierNameEn?:string;
    CashRegisterId?:number;
    CashRegisterCode?:number;
    CashRegisterBranchId?:number;
    CashRegisterBranchNameAr?:string;
    CashRegisterBranchNameEn?:string;
    Active?:boolean;
    StartTime?:Date;
    EndTime?:Date;
    StartingCash?:number;
    StartingNote?:string;
    Orders?:CashierOrderModel[];
    OrderCount?:number;
    RefundCount?:number;
    SessionTotal?:number;
    LeaveCash?:number;
    LeaveNote?:string;
    LeaveBill025?:number;
    LeaveBill05?:number;
    LeaveBill1?:number;
    LeaveBill5?:number;
    LeaveBill10?:number;
    LeaveBill20?:number;
    LeaveBill50?:number;
    LeaveBill100?:number;
    LeaveBill200?:number;
    HasOrdersInDelivery?:boolean;
    WorkShiftId?:number; //new
    WorkShiftNameAr?:string; //new
    WorkShiftNameEn?:string; //new
    WorkShiftFrom?:Date; //new
    WorkShiftTo?:Date; //new
    constructor(obj?:CashierSessionModel){
        obj = obj? obj:{};
        this.Id = obj.Id? obj.Id:0;
        this.Code = obj.Code? obj.Code:0;
        this.CashierId = obj.CashierId? obj.CashierId:0;
        this.CashierNameAr = obj.CashierNameAr? obj.CashierNameAr:'';
        this.CashierNameEn = obj.CashierNameEn? obj.CashierNameEn:'';
        this.CashRegisterId = obj.CashRegisterId? obj.CashRegisterId:0;
        this.CashRegisterCode = obj.CashRegisterCode? obj.CashRegisterCode:0;
        this.CashRegisterBranchId = obj.CashRegisterBranchId? obj.CashRegisterBranchId:0;
        this.CashRegisterBranchNameAr = obj.CashRegisterBranchNameAr? obj.CashRegisterBranchNameAr:'';
        this.CashRegisterBranchNameEn = obj.CashRegisterBranchNameEn? obj.CashRegisterBranchNameEn:'';
        this.Active = obj.Active? obj.Active:false;
        this.StartTime = obj.StartTime? obj.StartTime:new Date();
        this.EndTime = obj.EndTime? obj.EndTime:new Date();
        this.StartingCash = obj.StartingCash? obj.StartingCash:0;
        this.StartingNote = obj.StartingNote? obj.StartingNote:'';
        this.Orders = obj.Orders? obj.Orders:[];
        this.OrderCount = obj.OrderCount? obj.OrderCount:0;
        this.RefundCount = obj.RefundCount? obj.RefundCount:0;
        this.SessionTotal = obj.SessionTotal? obj.SessionTotal:0;
        this.LeaveCash = obj.LeaveCash? obj.LeaveCash:0;
        this.LeaveNote = obj.LeaveNote? obj.LeaveNote:'';
        this.LeaveBill025 = obj.LeaveBill025? obj.LeaveBill025:0;
        this.LeaveBill05 = obj.LeaveBill05? obj.LeaveBill05:0;
        this.LeaveBill1 = obj.LeaveBill1? obj.LeaveBill1:0;
        this.LeaveBill5 = obj.LeaveBill5? obj.LeaveBill5:0;
        this.LeaveBill10 = obj.LeaveBill10? obj.LeaveBill10:0;
        this.LeaveBill20 = obj.LeaveBill20? obj.LeaveBill20:0;
        this.LeaveBill50 = obj.LeaveBill50? obj.LeaveBill50:0;
        this.LeaveBill100 = obj.LeaveBill100? obj.LeaveBill100:0;
        this.LeaveBill200 = obj.LeaveBill200? obj.LeaveBill200:0;
        this.HasOrdersInDelivery = obj.HasOrdersInDelivery? obj.HasOrdersInDelivery:false;
        this.WorkShiftId = obj.WorkShiftId? obj.WorkShiftId:0;
        this.WorkShiftNameAr = obj.WorkShiftNameAr? obj.WorkShiftNameAr:'';
        this.WorkShiftNameEn = obj.WorkShiftNameEn? obj.WorkShiftNameEn:'';
        this.WorkShiftFrom = obj.WorkShiftFrom? obj.WorkShiftFrom:new Date();
        this.WorkShiftTo = obj.WorkShiftTo? obj.WorkShiftTo:new Date();
    }
}