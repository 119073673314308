

export class CategoryItemsModel{
    Id?: number;
    ItemId?:number;
    CategoryId?:number;
    CategoryName?:string;


    constructor(item?:CategoryItemsModel){
        item = item? item:{};
        this.Id = item.Id? item.Id:0;
        this.ItemId = item.ItemId? item.ItemId:0;
        this.CategoryId = item.CategoryId? item.CategoryId:0;     
        this.CategoryName = item.CategoryName? item.CategoryName:'';     

    }

}