import { CustomerBalancesComponent } from './Reports/customer-balances/components/customer-balances/customer-balances.component';
import { DebtReconstructionComponent } from './Reports/debt-reconstruction/components/debt-reconstruction/debt-reconstruction.component';
import { AddSuppliersComponent } from './suppliers/add-suppliers/add-suppliers.component';
import { ExpensCategoryComponent } from './suppliers/expens-category/expens-category.component';
import { ExpensesAndPurchasesComponent } from './suppliers/expenses-and-purchases/expenses-and-purchases.component';
import { SortablejsModule } from 'ngx-sortablejs';
import { ProjectsComponent } from './projects/projects.component';
import { StaffComponent } from './staff/staff.component';
import { TaxTypesComponent } from './tax-types/tax-types.component';
import { CompanyApiComponent } from './company-api/company-api.component';
import { SearchPipePipe } from './../pipes/search-pipe.pipe';
import { TableFilterPipe } from './../pipes/table-filter.pipe';
import { PagesContainersModule } from './../containers/pages/pages.containers.module';
import { ComponentsCarouselModule } from './../components/carousel/components.carousel.module';
import { SharedModule } from './../shared/shared.module';
import { environment } from './../../environments/environment';
import { LayoutContainersModule } from './../containers/layout/layout.containers.module';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ModalModule } from 'ngx-bootstrap/modal';
import { ArchwizardModule } from 'angular-archwizard';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireAuthGuardModule } from '@angular/fire/auth-guard';
import { FormsModule as FormsModuleAngular } from '@angular/forms';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { HeadroomModule } from '@ctrl/ngx-headroom';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgSelectModule } from '@ng-select/ng-select';
import { InvoicesRoutingModule } from './invoices.routing';
import { ItemsComponent         } from './items/items.component';
import { BranchesComponent      } from './branches/branches.component';
import { CurrenciesComponent    } from './currencies/currencies.component';
import { InvoicesComponent      } from './invoices/invoices.component';
import { SidePopupComponent } from '../views/app/aio/shared/side-popup/side-popup.component';
import { InvoiceComponent } from './invoice.component';
import { ClientsComponent } from './clients/clients.component';
import {CreateInvoiceComponent  } from './create-invoice/create-invoice.component';
import { CodingUnitsComponent } from './coding-units/coding-units.component';
import { ProductTypesComponent } from './product-types/product-types.component';
import { CompaniesComponent } from '../views/companies/companies.component';
import { PaginationConfig, PaginationModule } from 'ngx-bootstrap/pagination';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { ShowSuppliersComponent } from './suppliers/show-suppliers/show-suppliers.component';
import { ProjectViewComponent } from './suppliers/project/project-view/project-view.component';
import { AddExpensesAndPurchasesComponent } from './suppliers/add-expenses-and-purchases/add-expenses-and-purchases.component';
import {MatTabsModule} from '@angular/material/tabs';
import { PurchasesComponent } from './purchases/purchases.component';
import { InvoiceReportComponent } from './Reports/components/invoice-report/invoice-report.component';
import { PurshasesReportComponent } from './Reports/components/purshases-report/purshases-report.component';
import { DailyInvoicesComponent } from './Reports/daily-invoices/components/daily-invoices/daily-invoices.component';
import { ClientInvoicesComponent } from './Reports/client-invoices/components/client-invoices/client-invoices.component';
import { CustomerSalesComponent } from './Reports/customer-sales/components/customer-sales/customer-sales.component';
import { ViewInvoiceComponent } from './view-invoice/view-invoice.component';
import { CustomerPaymentsComponent } from './Reports/customer-payments/components/customer-payments/customer-payments.component';
import { CustomerAccountStatementComponent } from './Reports/customer-account-statement/components/customer-account-statement/customer-account-statement.component';

@NgModule({
  imports: [
    CommonModule,
    InvoicesRoutingModule,
    LayoutContainersModule,
    MatTabsModule,
    TranslateModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    FormsModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    ArchwizardModule,
    SharedModule,
    NgSelectModule,
    FormsModuleAngular,
    AngularFireAuthModule,
    AngularFireAuthGuardModule,
    ComponentsCarouselModule,
    TabsModule.forRoot(),
    HeadroomModule,
    ScrollToModule.forRoot(),
    NgxDatatableModule,
    CollapseModule,
    PagesContainersModule,
    PopoverModule.forRoot(),
    TooltipModule.forRoot(),
    BsDropdownModule,
    SortablejsModule,
    PaginationModule,
    SimpleNotificationsModule.forRoot(),


  ],
  declarations: [
    InvoiceComponent,
    CurrenciesComponent,
    TableFilterPipe,
    ItemsComponent,
    ClientsComponent,
    InvoicesComponent,
    CreateInvoiceComponent,
    BranchesComponent,
    SearchPipePipe,
    CompaniesComponent,
    // SidePopupComponent,
    CodingUnitsComponent,
    CompanyApiComponent,
    TaxTypesComponent,
    ProductTypesComponent,
    StaffComponent,
    ProjectsComponent,
    

    ExpensesAndPurchasesComponent,
    AddExpensesAndPurchasesComponent,
    ProjectViewComponent,
    ExpensCategoryComponent,
    PurchasesComponent,
    InvoiceReportComponent,
    PurshasesReportComponent,
    DailyInvoicesComponent,
    ClientInvoicesComponent,
    ViewInvoiceComponent,
    DebtReconstructionComponent,
    CustomerBalancesComponent,
    CustomerSalesComponent,
    CustomerPaymentsComponent,
    CustomerAccountStatementComponent
  ],
  providers: [DatePipe,PaginationConfig],
  // exports:[SidePopupComponent],
  bootstrap: [InvoiceComponent]
})
export class InvoicesModule { }
