import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-constructionportal',
  templateUrl: './constructionportal.component.html',
  styleUrls: ['./constructionportal.component.scss']
})
export class ConstructionportalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
