import { TreasuryAndBankAccountsService } from './../../treasury-and-bank-accounts/treasury-and-bank-accounts.service';
import { CurrencyModel } from 'src/app/invoices/currencies/CurrencyModel';
import { CatchReceiptCategoryService } from './../../catch-receipt-category/services/catch-receipt-category.service';
import { CatchReceiptCategoryModel } from './../../catch-receipt-category/models/catch-receipt-category.model';
import { FormGroup, FormBuilder } from '@angular/forms';
import { CatchReceiptService } from './../services/catch-receipt.service';
import { CatchReceiptModel } from './../models/catch-receipt.model';
import { Component, OnInit } from '@angular/core';
import { AccountsNameService } from '../../account-name/AccountNameService';
import { CostCenterService } from '../../cost-center/services/cost-center.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CurrencyService } from 'src/app/invoices/currencies/CurrencyService';
import { CatchReceiptAccountsModel } from '../models/catch-receipt-accounts.model';
import { AccountNameModel } from '../../account-name/AccountNameModel';
import { CostCenterModel } from '../../cost-center/models/cost-center.model';
import { CatchReceiptCostCenterModel } from '../models/catch-receipt-cost-center.model';
import { LockerBasicModel } from '../../locker-data/helper/model';
import { LockersDataService } from '../../locker-data/helper/serves';
import { BankCodeModel } from '../../bank-code/bank_code_model';
import { BankCodeService } from '../../bank-code/serves';
import { TaxTypesModel } from 'src/app/invoices/tax-types/TaxTypesModel';
import { TaxTypesService } from 'src/app/invoices/tax-types/TaxTypesService';
import { CatchReceiptFilesModel } from '../models/catch-receipt-files.model';
import { TranslateService } from '@ngx-translate/core';
import { NotificationType, NotificationsService } from 'angular2-notifications';
import { EmployeesManagementService } from 'src/app/HR/Employees/EmployeesManagement/employees-management.service';
import { EmployeesManagementModel } from 'src/app/HR/Employees/EmployeesManagement/EmployeesManagementModel';
import { InvoiceService } from 'src/app/invoices/invoices/InvoiceService';
import { InvoiceModel } from 'src/app/invoices/invoices/InvoiceModel';

@Component({
    selector: 'create-catch-receipt',
    templateUrl: 'create-catch-receipt.component.html',
    styleUrls: ['create-catch-receipt.component.scss']
})
export class CreateCatchReceiptComponent implements OnInit{
    catchReceit:CatchReceiptModel={};
    declare lang: any;
    Id=0;
    Form:FormGroup;
    buttonName='Add';
    categories:CatchReceiptCategoryModel[]=[];
    curenncies:CurrencyModel[]=[];
    users:any[]=[];
    SelectedAccount:CatchReceiptAccountsModel[]=[{AccountId:0,Amount:0,CatchReceiptId:this.Id,Description:'',Id:0}];
    SelectedCostCenters:CatchReceiptCostCenterModel[]=[{CostCenterId:0,Amount:0,CatchReceiptId:this.Id,Percent:0,Id:0}];
    LockersData:LockerBasicModel[]=[];
    public editEnabled = true;
   public picurl: string ='';
   AccountsNameModel: AccountNameModel[] = [];
   PackUpAccounts: AccountNameModel[] = [];
   centers:CostCenterModel[]=[];
   Banks:BankCodeModel[]=[];
   Treasuries:any[]=[];
   taxes:TaxTypesModel[]=[]
   invoices:InvoiceModel[]=[];
   employees:EmployeesManagementModel[]=[];
    constructor(
        private CurrencyService: CurrencyService, 
        private accountsNameService: AccountsNameService,
        private costCenterService:CostCenterService,
       private route: ActivatedRoute,
       public router:Router,
       private catchReceiptService:CatchReceiptService,
       public fb:FormBuilder,
       private catchReceiptCategoryService:CatchReceiptCategoryService,
       private LockersDataService:LockersDataService,
       private BankCodeService:BankCodeService,
       private treasuryService:TreasuryAndBankAccountsService,
       private taxService:TaxTypesService,
       private translate: TranslateService,
        private notifications: NotificationsService,
        private employeeService:EmployeesManagementService,
        private invoiceService:InvoiceService
        ){
     this.lang = localStorage.getItem('theme_lang');
     this.Id = + route.snapshot.paramMap.get('Id');
       
   }
   centersLoaded= false;
   TreasuriesLoaded= false;
   taxesLoaded= false;
   AccountsLoaded= false;
   categoriesLoaded= false;
   curennciesLoaded= false;
    ngOnInit(): void {
        this.invoiceService.GetAllNotPayed()
        .subscribe(result =>{
            this.invoices = result;
        })
        this.employeeService.getAll().subscribe(data=>{
            this.employees = data;
        })
        this.treasuryService.getAll()
        .subscribe(data=>{

            this.Treasuries = data;
            this.TreasuriesLoaded = true;
        })
        this.taxService.getAll()
        .subscribe(data => {
            this.taxes = data;
            this.taxesLoaded = true;

        })
        this.accountsNameService.GetAllSubAccountes(0).subscribe(
            data=>{
                this.AccountsNameModel=data;
                this.PackUpAccounts = data.map(item=>Object.assign({}, item))
                this.AccountsLoaded = true;

            }
        );
        this.catchReceiptCategoryService.getAll()
        .subscribe(cstegorie=>{
            this.categories = cstegorie
            this.categoriesLoaded = true;

        })
        this.CurrencyService.getAll()
        .subscribe(data => {
            this.curenncies = data;
            this.curennciesLoaded = true;
        })
        this.costCenterService.getAll(0)
        .subscribe(cosetCenters=>{
            this.centers = cosetCenters;
            this.centersLoaded = true;
        })
        if(this.Id == 0){
            this.catchReceiptService.GetLastCode()
            .subscribe(code=>{
                this.catchReceit = new CatchReceiptModel();
                this.catchReceit.Code = code + 1;
                this.Form = this.createForm();
                this.curenncies.forEach(element => {
                    if(element.IsMain == true){
                        this.Form.controls['CurrencyId'].setValue(element.Id);
                    }
                });
            })
       
        }
        else{
            this.catchReceiptService.getById(this.Id)
            .subscribe(data=>{
                this.buttonName = "Edit"
                this.catchReceit =data;
                this.SelectedAccount    =this.catchReceit.Accounts    ;
                this.SelectedCostCenters=this.catchReceit.CostCenters ;
                this.files              =this.catchReceit.Files       ;
                this.Form = this.createForm();
            })
        }
        
    }
    createForm():FormGroup{
        return this.fb.group({
            Id              :[this.catchReceit.Id              ],
            Amount          :[this.catchReceit.Amount          ],
            CurrencyId      :[this.catchReceit.CurrencyId      ],
            Code            :[this.catchReceit.Code            ],
            Date            :[this.catchReceit.Date            ],
            TheRecipient    :[this.catchReceit.TheRecipient    ],
            CategoryId      :[this.catchReceit.CategoryId      ],
            TaxId           :[this.catchReceit.TaxId           ],
            TaxValue        :[this.catchReceit.TaxValue        ],
            IsMulti         :[this.catchReceit.IsMulti         ],
            WithCostCenters :[this.catchReceit.WithCostCenters ],
            IsFrequent      :[this.catchReceit.IsFrequent      ],
            FrequentType    :[this.catchReceit.FrequentType    ],
            ExpiryDate      :[this.catchReceit.ExpiryDate      ],
            Files         :[this.catchReceit.Files         ],
            Description     :[this.catchReceit.Description     ],
            Accounts        :[this.catchReceit.Accounts        ],
            CostCenters     :[this.catchReceit.CostCenters     ],
            AccountId      :[this.catchReceit.AccountId ],
            Treasury       :[this.catchReceit.Treasury  ],
            TreasuryType       :[this.catchReceit.TreasuryType  ],
            InvoiceId       :[this.catchReceit.InvoiceId  ],
        })
    }
    setIsMulti(IsMulti){
        this.Form.controls['IsMulti'].setValue(IsMulti);
        if(this.Form.controls['IsMulti'].value==false){
            this.SelectedAccount=[{AccountId:0,Amount:0,CatchReceiptId:this.Id,Description:'',Id:0}];
        }
        else if(this.Form.controls['IsMulti'].value==true){
            this.Form.controls['AccountId'].setValue(0);
            this.SelectedCostCenters=[{CostCenterId:0,Amount:0,CatchReceiptId:this.Id,Percent:0,Id:0}];
            this.Form.controls['TaxId'].setValue(0);
            this.Form.controls['TaxValue'].setValue(0);

        }
    }
    splitAccount($event){
        debugger;
        var i = this.AccountsNameModel.findIndex(x=>x.Id == $event.Id);
        if(i > -1){
            this.AccountsNameModel.splice(i,1);
        }
    }
    deleteSelectedAccount(item){
        debugger;
        var i = this.SelectedAccount.indexOf(item);
        if(i > -1){
         
            var x= this.PackUpAccounts.findIndex(x=>x.Id == this.SelectedAccount[i].AccountId);
            if(x > -1){

                this.AccountsNameModel.push(this.PackUpAccounts[x]);
            }
            this.SelectedAccount.splice(i,1);
            this.changeAccountAmount();
        }
    }
    AddonClick(){
        debugger;
        var selectedDailyrestrictionsFooterModel =new CatchReceiptAccountsModel();
        selectedDailyrestrictionsFooterModel.Id = this.SelectedAccount.length+1 ;
        selectedDailyrestrictionsFooterModel.Amount = 0 ;
        selectedDailyrestrictionsFooterModel.AccountId = 0 ;
        selectedDailyrestrictionsFooterModel.CatchReceiptId = this.Id ;
        selectedDailyrestrictionsFooterModel.Description = "" ;
        this.SelectedAccount.push(selectedDailyrestrictionsFooterModel);
    }
    AddCostCenter(){
        this.SelectedCostCenters.push(new CatchReceiptCostCenterModel());
    }
    deleteCostCenter(cost){
        var i = this.SelectedCostCenters.indexOf(cost);
        if(i > -1){
            this.SelectedCostCenters.splice(i,1);
        }
       
    }
    changePercent(cost,$event){
        debugger;
        var amount = this.Form.controls['Amount'].value;
        cost.Percent = + $event.target.value;
        var totalPercent = 0;
        this.SelectedCostCenters.forEach(element => {
            totalPercent += element.Percent;
        });
        totalPercent = totalPercent - cost.Percent;
        if(cost.Percent <= 100 && cost.Percent > 0 && totalPercent < 100){
            if((cost.Percent+totalPercent) > 100){
                cost.Percent = 100 - totalPercent ;
                if(cost.Percent < 0){
                    cost.Percent *= -1
                }
            }
            cost.Amount = (amount * cost.Percent) / 100;

        }
        else if(totalPercent >= 100){
            cost.Percent = 0;
            cost.Amount = (amount * cost.Percent) / 100;

        }
        else if(cost.Percent > 100){
            cost.Percent = 100;
            cost.Amount = (amount * cost.Percent) / 100;

        }
    }

    changeAmount(cost,$event){
        debugger;

        var amount = this.Form.controls['Amount'].value;
        cost.Amount = + $event.target.value;
        var totalPercent = 0;
        this.SelectedCostCenters.forEach(element => {
            totalPercent += element.Percent;
        });
        cost.Percent =(cost.Amount * 100)/ amount

        if(cost.Percent <= 100 && cost.Percent > 0&& totalPercent < 100){
            if((cost.Percent+totalPercent) > 100){
                cost.Percent = 100 - totalPercent ;
                if(cost.Percent < 0){
                    cost.Percent *= -1
                }

            }
            cost.Amount = (amount * cost.Percent) / 100;

        }
        else if(totalPercent >= 100){
            cost.Percent = 0;
            cost.Amount = (amount * cost.Percent) / 100;

        }
        else if(cost.Percent > 100){
            cost.Percent = 100;
            cost.Amount = (amount * cost.Percent) / 100;

        }
    }
    changeAccountAmount(){
        var total = 0;
        this.SelectedAccount.forEach(element => {
            total += element.Amount;
        });
        var amount = this.Form.controls['Amount'].setValue(total);
    }
    Save(){
        debugger;
        this.catchReceit = this.Form.getRawValue();
        this.catchReceit.Id = this.Id;
        this.catchReceit.Accounts = this.SelectedAccount;
        this.catchReceit.CostCenters = this.SelectedCostCenters;
        this.catchReceit.Files = this.files;
        if(this.Id > 0 ){
            this.catchReceiptService.update(this.catchReceit)
            .subscribe(result => {
                if(result == true){
                    this.router.navigate(['app/accounts/catch-receipt']);
                    this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyEdited"));
    
                }
                else{
                 this.toastError(this.translate.instant("Error"), this.translate.instant("FailedEdit"));

                }
            })
        }
        else{
            this.catchReceiptService.insert(this.catchReceit)
            .subscribe(result => {
                if(result == true){
                    this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyAdd"));
                    this.router.navigate(['app/accounts/catch-receipt']);
    
                }
                else{
                 this.toastError(this.translate.instant("Error"), this.translate.instant("FailedAdd"));

                }
            })
        }
   
    }
    addAndNew(){
        debugger;
        this.catchReceit = this.Form.getRawValue();
        this.catchReceit.Id = this.Id;
        this.catchReceit.Accounts = this.SelectedAccount;
        this.catchReceit.CostCenters = this.SelectedCostCenters;
        this.catchReceit.Files = this.files;
        if(this.Id > 0 ){
            this.catchReceiptService.update(this.catchReceit)
            .subscribe(result => {
                if(result == true){
                    this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyEdited"));
                    this.catchReceit = new CatchReceiptModel();
                   this.picurl = '';
                   this.SelectedAccount = [];
                   this.SelectedCostCenters = [];
                    this.files = [];
                }
                else{
                 this.toastError(this.translate.instant("Error"), this.translate.instant("FailedEdit"));
                    
                }
            })
        }
        else{
            this.catchReceiptService.insert(this.catchReceit)
            .subscribe(result => {
                if(result == true){
                    this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyAdd"));
                    this.catchReceit = new CatchReceiptModel();
                    this.picurl = '';
                    this.SelectedAccount = [];
                    this.SelectedCostCenters = [];
                    this.files = [];

                }
                else{
                    this.toastError(this.translate.instant("Error"), this.translate.instant("FailedAdd"));

                }
            })
        }
   
    }
    files:CatchReceiptFilesModel[]=[];
    setFileToList(){
        var file = new CatchReceiptFilesModel();
        file.CatchReceiptId = this.Id ;
        file.FileUrl = this.picurl;
        file.Id = 0;
        this.files.push(file);
    }
    deleteFile(file){
        var i = this.files.indexOf(file);
        if(i>-1){
            this.files.splice(i,1);
        }
    }
    maxAmount=0;
    GetPayedAmount(){
        var InvoiceId = this.Form.controls['InvoiceId'].value;
        this.catchReceiptService.GetPayedAmount(InvoiceId)
        .subscribe(result=>{
            console.log('PayedAmount',result);
            var invoiceIndex = this.invoices.findIndex(x=>x.Id == InvoiceId);
            if(invoiceIndex > -1){
                this.maxAmount = this.invoices[invoiceIndex].totalAmount - result;
                this.Form.controls['Amount'].setValue(this.maxAmount);

            }
        })
    }
    
    chickAmount(){
        var amount = this.Form.controls['Amount'].value;
        if(amount > this.maxAmount && this.maxAmount > 0){
            this.Form.controls['Amount'].setValue(this.maxAmount);
        }
    }
    toastSuccess(toastHeader: string, toastBody: string): void {
        this.notifications.create(toastHeader, toastBody,
          NotificationType.Success, { timeOut: 3000, showProgressBar: true });
      }
      toastError(toastHeader: string, toastBody: string): void {
        this.notifications.create(toastHeader, toastBody,
          NotificationType.Error, { timeOut: 3000, showProgressBar: true });
      }
}

