import { ShiftDetailsModel } from "./shift-details.model";

export class ShiftModel{
    Id?: number;
    ShiftName?: string;
    Details?:ShiftDetailsModel[];
    constructor(item ?: ShiftModel){
        item = item ? item : {};
        this.Id = item.Id ? item .Id : 0;
        this.ShiftName = item.ShiftName ? item.ShiftName : '';
        this.Details = item.Details ? item.Details : [];
    }
}