<!--component html goes here -->
<div style="text-align: center;">
    <a class="btn btn-light" 
        routerLink=".."
        style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Back" |
        translate}}</a>
    <button 
        *ngIf="Form && Form.controls['Id'].value == 0 && formCreated"
         id="smallModalSubmitButton"
        class="btn btn-secondary" 
        (click)="Save()"
        style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Add" |
        translate}}</button>
    <button 
       *ngIf="Form && Form.controls['Id'].value > 0 && formCreated"
        id="smallModalSubmitButton"
        (click)="Update()"
        class="btn btn-secondary" 
        style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Edit"
        | translate}}</button>
        <button 
        *ngIf="!formCreated && SetCostCenters && AccountId > 0"
         id="smallModalSubmitButton"
         (click)="SaveCenters()"
         class="btn btn-secondary" 
         style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Save"
         | translate}}</button>
        <button 
        *ngIf="Form && Form.controls['Id'].value > 0 && formCreated"
         id="smallModalSubmitButton"
         (click)="Delete()"
         class="btn btn-danger" 
         style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Delete"
         | translate}}</button>
         <button class="btn btn-light"
         *ngIf="Form && Form.controls['Id'].value > 0 && formCreated" 
         (click)="assignCostCenters()"
         style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Cost Centers" |
         translate}}</button>
         
  </div>
  <div class="big-wrapper row" >
  
  <div class="card mb-5 tree-explorer-side col-md-4">
    <div class="card-body ">
    <div class="row">
        <h1>{{"Accounts" | translate}}</h1>
     <div>
        <button  
        type="button" 
        [disabled]=" !lodingMenu ||  !lodingaccountFications  || !lodingaccountExpensesTypes" 
        class="btn btn-default add-node-button" style="margin-top:  7px; ;" (click)="AddNewCostCenter()">
            <a class="plus-sign" style="color: black;">+</a>
           </button>
           
           
     </div>
    </div>
        <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
            <!-- This is the tree node template for leaf nodes -->
  
  
            <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
                <li class="mat-tree-node" [ngStyle]="{'background-color':node.Id === AccountId ? '#f8f8f8' : '#ffffff' }">
                    <!-- use a disabled button to provide padding for tree leaf -->
                    <a
                    [disabled]=" !lodingMenu ||  !lodingaccountFications  || !lodingaccountExpensesTypes" 
                    class="node-text-clickable" (click)="EditNode(node.Id)">{{node.Name}}</a>
                    <button 
                    [disabled]=" !lodingMenu ||  !lodingaccountFications  || !lodingaccountExpensesTypes" 
                    *ngIf="node.Level<levelLimit-1 && node.Type == 1"
                    type="button" class="btn btn-default add-node-button" (click)="addChildCostCenter(node.Id)">
                        <a class="plus-sign" style="color: black;">+</a>
                       </button>
                       <!-- <button 
                       [disabled]=" !lodingMenu ||  !lodingaccountFications  || !lodingaccountExpensesTypes" 
                       *ngIf="node.Level<levelLimit-1"
                       type="button" class="btn btn-default add-node-button" (click)="assignCostCenters(node.Id)">
                           <a class="plus-sign" style="color: black;">
                            <i ng-reflect-ng-class="iconsminds-three-arrow-fork" class="iconsminds-three-arrow-fork"></i>
                          </a>
                          </button> -->
                       
                </li>
            </mat-tree-node>
            <!-- This is the tree node template for expandable nodes -->
            <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                <li [ngStyle]="{'background-color':node.Id === AccountId ? '#f8f8f8' : '#ffffff' }">
                    <div class="mat-tree-node">
                        <button mat-icon-button matTreeNodeToggle class="mat-toggle-button-custom"
                            [attr.aria-label]="'Toggle ' + node.Name">
                            <mat-icon class="mat-icon-rtl-mirror mat-toggle-icon-custom">
                                {{treeControl.isExpanded(node) ? 'expand_more' :
                                (lang==='ar-EG'?'chevron_left':'chevron_right')}}
                            </mat-icon>
                        </button>
                        <a class="node-text-clickable" (click)="EditNode(node.Id)">
                            {{node.Name}}
                            </a>
                            
                        <button  type="button" class="btn btn-default add-node-button" (click)="addChildCostCenter(node.Id)">
                         <a class="plus-sign" style="color: black;">+</a>
                        </button>
                    </div>
                    <ul [class.example-tree-invisible]="!treeControl.isExpanded(node)">
                        <ng-container matTreeNodeOutlet></ng-container>
                    </ul>
                </li>
            </mat-nested-tree-node>
  
        </mat-tree>
    </div>
  </div>
  <!-- <simple-notifications></simple-notifications> -->
  <div class="mb-7 col-md-7">
  <form *ngIf="Form && formCreated" [formGroup]="Form">
    <div class="form-row ">
    <!-- <div class="form-group col-md-2"></div> -->
  
    <!-- <div class="form-group col-md-4">
      <label for="inputState">{{'Expenses Type'|translate}}</label>
      <select id="inputState" formControlName="AccounExpensesTypeId" class="form-control">
        <option selected="" [ngValue]="0">{{'Choose...'|translate}}</option>
        <option *ngFor="let item of accountExpensesTypes" [ngValue]="item.Id">{{lang==='ar-EG'?item.NameAr:item.NameEn}}</option>
      </select>
    </div> -->
  
    <!-- <div class="form-group col-md-4">
        <label for="inputState">{{'Account Fications'|translate}}</label>
        <select id="inputState" formControlName="AccountFicationId" class="form-control">
          <option selected="" [ngValue]="0">{{'Choose...'|translate}}</option>
          <option *ngFor="let item of accountFications" [ngValue]="item.Id">{{lang==='ar-EG'?item.NameAr:item.NameEn}}</option>
        </select>
      </div> -->
      <!-- <div class="form-group col-md-4">
        <label for="inputState">{{'Fication Cost Type'|translate}}</label>
        <select id="inputState" formControlName="AccountFicationTypeId" class="form-control">
          <option selected="" [ngValue]="0">{{'Choose...'|translate}}</option>
          <option [ngValue]="1">{{'Directly'|translate}}</option>
          <option [ngValue]="2">{{'Not Directly'|translate}}</option>
        </select>
      </div> -->
  
  </div>
  <div class="form-row ">
    <div class="form-group col-md-4">
      <label for="inputState">{{'Account Type'|translate}}</label>
      <select id="inputState" formControlName="AccountType" class="form-control">
        <option selected="" [ngValue]="0">{{'Choose...'|translate}}</option>
        <option [ngValue]="1">{{'Main'|translate}}</option>
        <option [ngValue]="2">{{'Sub'|translate}}</option>
              </select>
    </div>
  
    <div class="form-group col-md-4">
        <label for="inputState">{{'Account Nature'|translate}}</label>
        <select id="inputState" formControlName="AccountNature" class="form-control">
          <option selected="" [ngValue]="0">{{'Choose...'|translate}}</option>
          <option [ngValue]="1">{{'Debt'|translate}}</option>
          <option [ngValue]="2">{{'Credit'|translate}}</option>
        </select>
      </div>
      <!-- <div class="form-group col-md-4">
        <label for="inputState">{{'Account Menu'|translate}}</label>
        <select id="inputState" formControlName="AccountMenuId" class="form-control">
          <option selected="" [ngValue]="0">{{'Choose...'|translate}}</option>
          <option *ngFor="let item of accountMenus" [ngValue]="item.Id">{{lang==='ar-EG'?item.NameAr:item.NameEn}}</option>
        </select>
      </div> -->
  
  </div>
  <div class="form-row">
    <div class="form-group col-md-6">
        <label>{{ 'NameAr' | translate }}</label>
        <input formControlName="NameAr" type="text" class="form-control">
    </div>
    <div class="form-group col-md-6">
        <label >{{ 'NameEn' | translate }}</label>
        <input formControlName="NameEn" type="text"  class="form-control">
    </div>

</div>
  <div class="form-row">
    <div class="form-group col-md-6">
        <label>{{ 'Higher Account Code' | translate }}</label>
        <input type="number" formControlName="Parentcode" class="form-control" readonly>
    </div>
    <div class="form-group col-md-6">
        <label >{{ 'Higher Account Name' | translate }}</label>
        <input type="text"  formControlName="ParentName"  id="inputEmail3" class="form-control" readonly>
        </div>

</div>

<div class="form-row">
    <div class="form-group col-md-6">
        <label for="inputState">{{ 'Account Number' | translate }}</label>
        <input formControlName="AccountNumber" type="number" class="form-control" readonly>
    </div>
    <div class="form-group col-md-6">
        <label for="inputState">{{ 'Level Code' | translate }}</label>
        <input formControlName="Levelcode"  type="number"  class="form-control" readonly>
    </div>

</div>
<div class="custom-control custom-checkbox " *ngIf="openIsCurrencyEvaluation">
  <input formControlName="IsCurrencyEvaluation" type="checkbox"  id="IsCurrencyEvaluation"
      class="custom-control-input">
      <label for="IsCurrencyEvaluation"  class="custom-control-label">{{'Will the currency of this account balance be revalued?'|translate}}</label>
  </div>
  <div class="custom-control custom-checkbox " *ngIf="openItIsLinkedToAcostCenter">
    <input formControlName="ItIsLinkedToAcostCenter" type="checkbox"  id="openItIsLinkedToAcostCenter"
        class="custom-control-input">
        <label for="openItIsLinkedToAcostCenter"  class="custom-control-label">{{'It is linked to a cost center?'|translate}}</label>
    </div>
<!-- <div class="custom-control custom-checkbox ">
    <input formControlName="IsNotMenu" type="checkbox"  id="customCheck"
        class="custom-control-input">
        <label for="customCheck"  class="custom-control-label">{{'Not Menu'|translate}}</label></div> -->
<!--  
  <div class="form-group row">
      <label  class="col-sm-2  col-form-label">{{"NumberCostCenter" | translate}}</label>
      <div class="col-sm-5"><input type="number" formControlName="CostCenterNumber" class="form-control" readonly>
      </div>
      
      <label  class=" col-form-label">{{'Level'|translate}}</label>
      <div class="col-sm-3"><input type="text" formControlName="Level"  class="form-control" readonly>
      </div>
      <div class="mb-4"><div class="custom-control custom-checkbox "><input formControlName="Stop" type="checkbox" id="customCheckThis" class="custom-control-input"><label for="customCheckThis" class="custom-control-label">{{'Stop'|translate}}</label></div></div>
    </div> -->
  
    <!-- <div class="form-group row">
      <label for="inputEmail3" class="col-sm-2 col-form-label">{{'Name English'|translate}}</label>
      <div class="col-sm-10"><input type="text" id="inputEmail3" formControlName="NameEn" class="form-control" >
      </div>
    </div>
    <div class="form-group row">
      <label for="inputEmail3" class="col-sm-2 col-form-label">{{'Name Arabic'|translate}}</label>
      <div class="col-sm-10"><input type="text" id="inputEmail3" formControlName="NameAr" class="form-control" >
      </div>
    </div>
    <div class="form-group row">
      <label for="inputEmail3" class="col-sm-2 col-form-label">{{'Higher Center'|translate}}</label>
      <div class="col-sm-4"><input type="number" id="inputEmail3" formControlName="ParentId" class="form-control" readonly>
      </div>
        <div class="col-sm-6"><input type="text"  formControlName="ParentName"  id="inputEmail3" class="form-control" readonly>
      </div>
    </div>
  
    <div class="form-group row">
      <label for="inputEmail3" class="col-sm-2 col-form-label">{{'Acronym'|translate}}</label>
      <div class="col-sm-10"><input type="text" formControlName="Acronym" id="inputEmail3" class="form-control" >
      </div>
    </div> -->
  </form>
  <assign-cost-centers *ngIf="!formCreated && SetCostCenters && AccountId > 0" [AccountId]="AccountId"></assign-cost-centers>
  </div>

  </div>