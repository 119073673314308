

export class ServiceCategoryModel{
    Id?: number;
    ServiceId?:number;
    CategoryId?:number;
    CategoryName?:string;

    constructor(item?:ServiceCategoryModel){
        item = item? item:{};
        this.Id = item.Id? item.Id:0;
        this.ServiceId = item.ServiceId? item.ServiceId:0;
        this.CategoryId = item.CategoryId? item.CategoryId:0;          
        this.CategoryName = item.CategoryName? item.CategoryName:'';          

    }

}