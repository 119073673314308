<!--component html goes here -->
<simple-notifications></simple-notifications>
<form [formGroup]="ExpensesForm" *ngIf="ExpensesForm" class="card ">
    <div class="form-group Card_Padding">
        <br>
        <h3>{{ 'Expenses_And_Purchases_Header' | translate }}</h3>
        <br>
        <div class="row">
            <div class=" col-md-4">
                <label for="inputState">{{ 'expensesType' | translate }}</label>
                <select formControlName="expensesTypeDropdwon" id="inputState" class="form-control">
                    <option *ngFor="let item of expensesTypeList" [value]="item.Id">{{item.NameEn}}</option>
                </select>
            </div>
            <div class=" col-md-4">
                <label for="inputState">{{ 'project' | translate }}</label>
                <select formControlName="projectDropdwon" id="inputState" class="form-control">
                    <option *ngFor="let item of projectList" [value]="item.Id">{{item.NameEn}}</option>

                </select>
            </div>
            <div class=" col-md-4">
                <label for="inputState">{{ 'Suppliers' | translate }}</label>
                <select formControlName="SuppliersDropdwon" id="inputState" class="form-control">
                    <option *ngFor="let item of SuppliersList" [value]="item.Id">{{item.NameEn}}</option>

                </select>
            </div>
        </div>
        <br>

        <div class="form-row ">
            <div class="form-group col-md-6">
                <label>{{ 'Date' | translate }}</label>
                <input formControlName="DateInputForm" type="date" class="form-control">
            </div>
            <div class="form-group col-md-6 text-center">
<br>
<br>
                <div class="mb-4">
                    <div class="custom-control custom-checkbox mb-4">
                        <input type="checkbox" id="customCheckThis" formControlName="checkboxInputForm"
                            class="custom-control-input">
                        <label for="customCheckThis"
                            class="custom-control-label">{{'CostOfMerchandise'|translate}}</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-md-6">
                <label>{{ 'NameAr' | translate }}</label>
                <input formControlName="NameArInputForm" type="text" class="form-control">
            </div>
            <div class="form-group col-md-6">
                <label>{{ 'NameEn' | translate }}</label>
                <input formControlName="NameEnInputForm" type="text" class="form-control">
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-md-6">
                <label>{{'Quantity'| translate}}</label>
                <input formControlName="QuantityInputForm" type="text" class="form-control">
            </div>
            <div class="form-group col-md-6">
                <label>{{'UnitPrice'| translate}}</label>
                <input formControlName="PriceInputForm" type="text" class="form-control">
            </div>

        </div>
        <div class="form-row">
            <div class="form-group col-md-6">
                <label>{{'DescriptionAr'| translate}}</label>
                <textarea formControlName="DescriptionArInputForm" id="" cols="4" rows="3"
                    class="form-control"></textarea>
            </div>
            <div class="form-group col-md-6">
                <label>{{'DescriptionEn'| translate}}</label>
                <textarea formControlName="DescriptionEnInputForm" id="" cols="4" rows="3"
                    class="form-control"></textarea>
            </div>
        </div>      
        <br>
        <div *ngIf="modelState != 'Show'" class="text-center">
            <button type="button" class="btn btn-primary" (click)="onClick()" [disabled]="ExpensesForm.invalid">{{ titleButton | translate }}</button>{{' '}}

        </div>
    </div>
    <!-- [disabled]="InvoiceForm.invalid" -->
    <!-- <div class="text-center">
        <button type="button" class="btn btn-primary" awPreviousStep disabled>{{ 'wizard.prev' | translate
            }}</button>{{" "}}
        <button type="button"class="btn btn-primary" awNextStep>{{ 'wizard.next' | translate }}</button>
    </div> -->
</form>
