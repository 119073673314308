export class FiscalYearModel{
    Id?: number;
    StartDate?: Date|null;
    EndDate?: Date|null;
    IsOpen?: boolean;
    Description?: string;
    constructor(item ?: FiscalYearModel){
        item = item ? item : {};
        this.Description = item.Description?item.Description:'';
        this.StartDate = item.StartDate ? item.StartDate : new Date();
        this.EndDate = item.EndDate ? item.EndDate : null;
        this.Id = item.Id ? item.Id : 0;
        this.IsOpen = item.IsOpen ? item.IsOpen : false;
    }
}