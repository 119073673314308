export class ExpensesAccountsModel{
    Id?: number;
    AccountId?: number;
    ExpensesId?: number;
    Description?: string;
    Amount?: number;
    constructor(item ?: ExpensesAccountsModel){
        item = item ? item : {};
        this.Id = item.Id ? item.Id : 0;
        this.AccountId = item.AccountId ? item.AccountId : 0;
        this.ExpensesId = item.ExpensesId ? item.ExpensesId : 0;
        this.Amount = item.Amount ? item.Amount : 0;
        this.Description = item.Description ? item.Description : '';
    }
}