
<div class="row" *ngIf="Form">
    <div class="col-12">
      <div class="card mb-4">
        <div class="card-body">
            <h5 class="float-left">{{ 'Create Client Business Extract' | translate }}</h5>
            <div class="top-right-button-container text-zero float-right">
                <button type="button" (click)="Save()" [disabled]="Form.invalid" class="top-right-button btn btn-primary  btn-lg mr-1">{{'Save'|translate}}</button>
             </div>
        </div>
      </div>
    </div>
    <div class="col-12">
        <div class="card mb-4">
          <div class="card-body">
            <form  *ngIf="Form" [formGroup]="Form">
                <div class="form-group">
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label>{{ 'Code' | translate }}</label>
                            <input formControlName="Code" type="number" class="form-control" readonly>
                        </div>
                        <div class="form-group col-md-6">
                            <label for="inputPassword4">{{ 'Business Insurance' | translate }}</label>
                            <input  formControlName="BusinessInsurance" type="number" (change)="calcBusinssInsurance()" id="inputPassword4" class="form-control">
                        </div>
                      
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label >{{'Deductions'| translate}}</label>
                            <input formControlName="Deductions" type="number" (change)="calcDeductions()" class="form-control">
                        </div>
                        <div class="form-group col-md-6">
                            <label for="inputPassword4">{{'Total'| translate}}</label>
                            <input formControlName="Total" type="number" id="inputPassword4" class="form-control" readonly>
                        </div>
                   
                    </div>
                    <div class="row">
                    
                        <div class=" col-md-6">
                            <label for="inputState">{{ 'Clients' | translate }}</label>
                            <select [disabled]="clients && clients.length > 0" (change)="chooseClient()" formControlName="ClientId"  id="inputState" class="form-control">
                                <option disabled selected value="0">{{'Clients' | translate}}</option>
                                <option *ngFor="let item of clients" [value]="item.Id">{{lang==='ar-EG'?item.NameAr:item.NameEn}}</option>
                            </select>
                        </div>
                        <div class=" col-md-6">
                            <label for="inputState">{{ 'Project' | translate }}</label>
                            <select  formControlName="ProjectId" (change)="getContractors()"  id="inputState" class="form-control">
                                <option disabled selected value="0">{{'Project' | translate}}</option>
                                <option *ngFor="let item of projects" [value]="item.Id">{{lang==='ar-EG'?item.NameAr:item.NameEn}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label for="inputPassword4">{{'Date'| translate}}</label>
                            <input formControlName="Date" type="date" id="inputPassword4" class="form-control" >
                        </div>
                   
                    </div>
             
                </div>
               </form>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-body">
              <h5 class="float-left">{{ 'Business Extract Details' | translate }}</h5>
              <div class="top-right-button-container text-zero float-right">
                  <button type="button" style="margin-bottom:15px;" (click)="AddDetail()" class="top-right-button btn btn-primary  btn-lg mr-1">{{'Add'|translate}}</button>
               </div>
               <table class="table">
                <thead>
                    <tr>
                        <th>{{'Statement'|translate}}</th>
                        <th>{{'Previous'|translate}}</th>
                        <th>{{'Current'|translate}}</th>
                        <th>{{'Total'|translate}}</th>
                        <th>{{'Price'|translate}}</th>
                        <th>{{'The Total Value'|translate}}</th>
                        <th>{{'Execution percentage'|translate}}</th>
                        <th>{{'Total'|translate}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of ClientBusinessExtract.Details">
                        <td>{{lang==='ar-EG'?item.itemNameAr:item.itemNameEn}}</td>
                        <td>{{item.Previous}}</td>
                        <td>{{item.Current}}</td>
                        <td>{{item.PreviousAndCurrentTotal}}</td>
                        <td>{{item.Price}}</td>
                        <td>{{item.TheTotalValue}}</td>
                        <td>{{item.ExcutionPercentage}}</td>
                        <td>{{item.Total}}</td>
                    </tr>
                </tbody>
               </table>
          </div>
        </div>
      </div>
  </div>

  <app-side-popup #sideModalRef>
    <div *ngIf="DetailsForm" class="modal-content"
        style="width: 90%; text-align: center; margin-top: 20px; margin-left: auto; margin-right: auto;">
        <div style="align-content: center; display: inline-block;">
            <div class="modal-header" style="display: block; padding: 0px 0px 5px 0px; text-align: center; ">
                <h1 id="dialog-child-name" class="modal-title">
                    {{currentFormMode+translationName | translate}}</h1>
            </div>
            <div class="modal-body">
                <form class="md-float-material form-material" [formGroup]="DetailsForm">
                   
                    <div class="form-group form-primary">
                        <label for="inputState">{{ 'Statement' | translate }}</label>
                        <select (change)="GetPreviousDetails()" [disabled]="workItems && workItems.length > 0" formControlName="ProjectWorkItemId"  id="inputState" class="form-control">
                            <option disabled selected value="0">{{'Statement' | translate}}</option>
                            <option *ngFor="let item of workItems" [value]="item.Id">{{lang==='ar-EG'?item.NameAr:item.NameEn}}</option>
                        </select>
                        <div class="messages text-danger"
                        *ngIf=" (DetailsForm.get('ProjectWorkItemId').hasError('required') || DetailsForm.get('ProjectWorkItemId').invalid) && ( DetailsForm.get('ProjectWorkItemId').dirty ||  DetailsForm.get('ProjectWorkItemId').touched)">
                        {{"StatementRequired" | translate}}</div>
                    <span class="form-bar"></span>
                    </div>
                    <div class="form-group form-primary">
                        <label class="float-label push righ">{{"Previous" | translate}}</label>
                        <input type="text" name="Statement"
                            class="form-control" formControlName="Previous"
                            placeholder='{{"Previous" | translate}}' readonly>
                  
                        <div class="messages text-danger"
                            *ngIf=" (DetailsForm.get('Previous').hasError('required') || DetailsForm.get('Previous').invalid) && ( DetailsForm.get('Previous').dirty ||  DetailsForm.get('Previous').touched)">
                            {{"PreviousRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>
                    <div class="form-group form-primary">
                        <label class="float-label push righ">{{"Current" | translate}}</label>
                        <input type="text" name="Statement"
                            class="form-control" formControlName="Current"
                            placeholder='{{"Current" | translate}}' (change)="changeCurrent()">
                  
                        <div class="messages text-danger"
                            *ngIf=" (DetailsForm.get('Current').hasError('required') || DetailsForm.get('Current').invalid) && ( DetailsForm.get('Current').dirty ||  DetailsForm.get('Current').touched)">
                            {{"CurrentRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>
                    <div class="form-group form-primary">
                        <label class="float-label push righ">{{"Totlal" | translate}}</label>
                        <input type="number" name="PreviousAndCurrentTotal"
                            class="form-control" formControlName="PreviousAndCurrentTotal"
                            placeholder='{{"Total" | translate}}' readonly>
                        
                        <div class="messages text-danger"
                            *ngIf=" (DetailsForm.get('PreviousAndCurrentTotal').hasError('required') || DetailsForm.get('PreviousAndCurrentTotal').invalid) && ( DetailsForm.get('PreviousAndCurrentTotal').dirty ||  DetailsForm.get('PreviousAndCurrentTotal').touched)">
                            {{"PreviousAndCurrentTotalRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>
                    <div class="form-group form-primary">
                        <label class="float-label push righ">{{"Price" | translate}}</label>
                        <input type="number" name="Price"
                            class="form-control" formControlName="Price"
                            placeholder='{{"Price" | translate}}' readonly>
                  
                        <div class="messages text-danger"
                            *ngIf=" (DetailsForm.get('Price').hasError('required') || DetailsForm.get('Price').invalid) && ( DetailsForm.get('Price').dirty ||  DetailsForm.get('Price').touched)">
                            {{"PriceRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>
                    <div class="form-group form-primary">
                        <label class="float-label push righ">{{"The Total Value" | translate}}</label>
                        <input type="number" name="Price"
                            class="form-control" formControlName="TheTotalValue"
                            placeholder='{{"The Total Value" | translate}}' readonly>
                  
                        <div class="messages text-danger"
                            *ngIf=" (DetailsForm.get('TheTotalValue').hasError('required') || DetailsForm.get('TheTotalValue').invalid) && ( DetailsForm.get('TheTotalValue').dirty ||  DetailsForm.get('TheTotalValue').touched)">
                            {{"TheTotalValueRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>
                    <div class="form-group form-primary">
                        <label class="float-label push righ">{{"Execution percentage" | translate}}</label>
                        <input type="number" name="Price"
                            class="form-control" formControlName="ExcutionPercentage"
                            placeholder='{{"Execution percentage" | translate}}' (change)="ExcutionPercentage()">
                  
                        <div class="messages text-danger"
                            *ngIf=" (DetailsForm.get('ExcutionPercentage').hasError('required') || DetailsForm.get('ExcutionPercentage').invalid) && ( DetailsForm.get('ExcutionPercentage').dirty ||  DetailsForm.get('ExcutionPercentage').touched)">
                            {{"ExcutionPercentageRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>
                    <div class="form-group form-primary">
                        <label class="float-label push righ">{{"Total" | translate}}</label>
                        <input type="number" name="Total"
                            class="form-control" formControlName="Total"
                            placeholder='{{"Total" | translate}}' readonly>
                  
                        <div class="messages text-danger"
                            *ngIf=" (DetailsForm.get('Total').hasError('required') || DetailsForm.get('Total').invalid) && ( DetailsForm.get('Total').dirty ||  DetailsForm.get('Total').touched)">
                            {{"TotalRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>
                    <div style="margin-left: 5px; text-align: center; margin-top: 30px;">
                        <button 
                            [disabled]="DetailsForm.invalid" id="smallModalSubmitButton"
                            class="btn btn-secondary" (click)="addObject()"
                            style="font-weight: bold; width: 100%; text-align: center;">{{"Add" |
                            translate}}</button>
                        <button class="btn btn-light" (click)="sideModalRef.modalRef.hide()"
                            style="font-weight: bold; width: 100%; text-align: center; margin-top: 10px;">{{"Close" |
                            translate}}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</app-side-popup>

