export class JobLevelsModel{
    Id?: number;
    Name?:string;
    Description?:string;
    Status?:boolean;


 
    constructor(item?:JobLevelsModel){
        item = item? item:{};
        this.Id = item.Id? item.Id:0;
        this.Name = item.Name? item.Name:'';
        this.Description = item.Description? item.Description:'';
        this.Status = item.Status? item.Status:true;

    }

}