import { DatePipe } from '@angular/common';
import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { config } from 'rxjs';
import { TableEngine } from '../../../invoices/TableEngine';
import { CashRegisterModel } from '../cashier-machines/CashRegisterModel';
import { CashRegisterService } from '../cashier-machines/CashRegisterService';
import { CashierOrderModel } from '../cashier-orders/CashierOrderModel';
import { CashierOrderService } from '../cashier-orders/CashierOrderService';
import { CashierSessionModel } from '../cashier-sessions/CashierSessionModel';
import { CashierSessionService } from '../cashier-sessions/CashierSessionService';
import { CashierStaffModel } from '../cashier-staff/CashierStaffModel';
import { CashierStaffService } from '../cashier-staff/CashierStaffService';
import { CashierWorkShiftModel } from '../cashier-work-shifts/CashierWorkShiftModel';
import { CashierWorkShiftService } from '../cashier-work-shifts/CashierWorkShiftService';
import { ConfigPoSModel } from '../config-pos-general/ConfigPoSModel';
import { ConfigPoSService } from '../config-pos-general/ConfigPoSService';

@Component({
  selector: 'app-pos-portal',
  templateUrl: './pos-portal.component.html',
  styleUrls: ['./pos-portal.component.scss']
})
export class RestaurantsPosPortalComponent implements OnInit {

  //Enable debugMode to make API modules return static data from within the service class
  debugMode: boolean = false;

  constructor(private formBuilder: FormBuilder, private modelService: CashierSessionService, private renderer: Renderer2,
    private notifications: NotificationsService, private translate: TranslateService,
    private datePipe: DatePipe, private cashierStaffService: CashierStaffService, private cashierWorkShiftService: CashierWorkShiftService,
    private cashRegisterService: CashRegisterService, private cashierSessionService: CashierSessionService,
    private cashierOrderService: CashierOrderService,
    private configPoSService: ConfigPoSService,
    private _router: Router
  ) {
  }

  tableEngine = new TableEngine(this.translate, this.notifications);
  declare lang: any;
  currentAction = '';

  @ViewChild('cashierLoginModal', { static: true }) cashierLoginModal: ModalDirective;
  ngOnInit(): void {
    if (this.debugMode) {
      this.enableDebugOnAllServices();
    }
    this.lang = localStorage.getItem('theme_lang');
    this.loadServices();
  }

  enableDebugOnAllServices() {
    //this.modelService.debugMode = true;
    //this.cashRegisterService.debugMode = true;
    //this.cashierSessionService.debugMode = true;
    //this.cashierStaffService.debugMode = true;
    //this.cashierWorkShiftService.debugMode = true;
  }

  cashierStaffList: CashierStaffModel[] = [];
  cashRegisterList: CashRegisterModel[] = [];
  cashierWorkShiftList: CashierWorkShiftModel[] = [];
  cashierOrderList: CashierOrderModel[] = [];
  cashierSessionList: CashierSessionModel[] = [];
  configPoS: ConfigPoSModel = new ConfigPoSModel();
  loadServices() {
    debugger;
    this.currentAction = 'loading';

    this.configPoSService.getFirst().subscribe(
      configPoSData => {
        this.configPoS = configPoSData;

        this.cashierStaffService.getAll().subscribe(
          cashierStaffData => {
            this.cashierStaffList = cashierStaffData;
    
            this.cashierSessionService.getAll().subscribe(
              cashierSessionData => {
                this.cashierSessionList = cashierSessionData;
    
                this.cashRegisterService.getAll().subscribe(
                  cashRegisterData => {
                    this.cashRegisterList = cashRegisterData;
    
                    this.cashierWorkShiftService.getAll().subscribe(
                      cashierWorkShiftData => {
                        this.cashierWorkShiftList = cashierWorkShiftData;
    
                        this.cashierOrderService.getAll().subscribe(
                          cashierOrderData => {
                            this.cashierOrderList = cashierOrderData;
        
                            this.currentAction = '';
                          }
                        )
                      }
                    )
                  }
                )
              }
            )
          }
        )
      }
    )


  }

  cashierStaffId = null;
  cashierWorkShiftId = null;
  cashRegisterId = null;
  startingNote = '';
  startingCash = 0;
  checkActiveSessions() {
    debugger;
    let counter = 0;
    if(this.cashierSessionList.length > 0){
      this.cashierSessionList.forEach(session => {
        counter += 1;
        if (session.Active) {
          this.cashierSessionService.getByIdWithOrders(session.Id).subscribe(
            result => {
              this.cashierSessionService.currentSession = result;
  
              console.log(this.cashierSessionService.currentSession);
              if (!this.cashierSessionService.currentSession.Orders) {
                this.cashierSessionService.currentSession.Orders = [];
              }
              this._router.navigate(['app/aio/restaurant/Cashier-Screen']);
  
              return;
            })
        }
        else {
          if (counter >= this.cashierSessionList.length) {
            this.cashierLoginModal.show();
            this.currentAction = '';
          }
        }
        console.log(this.cashierSessionService.currentSession.Id);
        console.log(counter);
      });
    }
    else{
      this.cashierLoginModal.show();
      this.currentAction = '';
    }

  }

  startSession() {
    this.currentAction = 'loading';
    if (this.cashierSessionService.currentSession.Id > 0) {
      console.log(this.cashierSessionService.currentSession);
      if (!this.cashierSessionService.currentSession.Orders) {
        this.cashierSessionService.currentSession.Orders = [];
      }
      this.currentAction = '';
      this._router.navigate(['app/aio/restaurant/Cashier-Screen']);
    }
    else {
      this.checkActiveSessions();
    }
  }

  LogIntoSession() {
    let lastSession = this.cashierSessionList.length > 0 ? this.cashierSessionList[this.cashierSessionList.length - 1] : new CashierSessionModel();

    let reverseSessionList = this.cashierSessionList.slice().reverse();
    let lastSessionOnMachine = reverseSessionList.find(x => x.CashRegisterId == this.cashRegisterId);
    if (!lastSessionOnMachine){
      lastSessionOnMachine = new CashierSessionModel();
    }

    if(lastSessionOnMachine.Id>0){
      this.cashierSessionService.getByIdWithOrders(lastSessionOnMachine.Id).subscribe(result => {
        if (result && (result === true || result.Id > 0)) {
  
          lastSessionOnMachine = result;
          this.cashierSessionService.currentSession = new CashierSessionModel();
          this.cashierSessionService.currentSession.Id = this.debugMode ? lastSession.Id + 1 : 0;
          //let nextSessionCode = this.configPoSService.getNextCode(this.configPoS.StartingCodeSessions, this.cashierSessionList);
          //this.cashierSessionService.currentSession.Code = this.debugMode ? lastSession.Code + 1 : 0;
          this.cashierSessionService.currentSession.Code = this.configPoS.StartingCodeSessions;
  
          if (lastSessionOnMachine.HasOrdersInDelivery) {
            let newOrderId = this.cashierSessionService.debugMode?1:0;
            let newOrderCode = this.configPoS.StartingCodeOrders;
            this.cashierSessionService.currentSession.HasOrdersInDelivery = true;
            if (lastSessionOnMachine.Orders) {
              lastSessionOnMachine.Orders.forEach(order => {
                if (order.Status == 'In-Delivery') {
                  order.Id = newOrderId;
                  //let nextOrderCode = this.configPoSService.getNextCode(this.configPoS.StartingCodeSessions, this.cashierSessionList);
                  order.Code = this.debugMode ? newOrderCode : 0;
                  newOrderCode += this.cashierSessionService.debugMode?1:0;
                  newOrderId += this.cashierSessionService.debugMode?1:0;
                  this.cashierSessionService.currentSession.Orders = this.cashierSessionService.currentSession.Orders.concat(order);
                }
              });
            } else {
              lastSessionOnMachine.Orders = [];
            }
          }
  
          this.insertAndStartSession();
        }
        else {
          this.tableEngine.toastError(this.translate.instant("Error"), this.translate.instant("FailedLogin"));
          this.currentAction = '';
        }
      })
    }
    else{
      this.insertAndStartSession();
    }
    console.log("sessions", reverseSessionList);

    console.log("last session", lastSessionOnMachine);


  }


  insertAndStartSession(){
    
    let cashier = this.cashierStaffList.find(x => x.Id === this.cashierStaffId);
    let machine = this.cashRegisterList.find(x => x.Id === this.cashRegisterId);
    let workShift = this.cashierWorkShiftList.find(x => x.Id === this.cashierWorkShiftId);

    if (!this.cashierSessionService.currentSession.Orders) {
      this.cashierSessionService.currentSession.Orders = [];
    }

    this.cashierSessionService.currentSession.StartTime = new Date();
    this.cashierSessionService.currentSession.StartingCash = this.startingCash;
    this.cashierSessionService.currentSession.StartingNote = this.startingNote;
    this.cashierSessionService.currentSession.CashierId = this.cashierStaffId;
    this.cashierSessionService.currentSession.WorkShiftId = this.cashierWorkShiftId;
    this.cashierSessionService.currentSession.CashierNameAr = cashier.NameAr;
    this.cashierSessionService.currentSession.CashierNameEn = cashier.NameEn;
    this.cashierSessionService.currentSession.CashRegisterId = this.cashRegisterId;
    this.cashierSessionService.currentSession.CashRegisterCode = machine.Code;
    this.cashierSessionService.currentSession.CashRegisterBranchId = machine.BranchId;
    this.cashierSessionService.currentSession.CashRegisterBranchNameAr = machine.BranchNameAr;
    this.cashierSessionService.currentSession.CashRegisterBranchNameEn = machine.BranchNameEn;
    this.cashierSessionService.currentSession.Active = true;

    console.log(this.cashierSessionService.currentSession);
    this.cashierSessionService.insertWithOrders(this.cashierSessionService.currentSession).subscribe(result => {
      if (result === true || result > 0) {
        console.log("result",result);
        
        //this.tableEngine.loadData();
      this.cashierSessionService.getByIdWithOrders(result).subscribe(newResult => {
        if (newResult === true || newResult.Id > 0) {
          this.cashierSessionService.currentSession = newResult;
          this.tableEngine.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyLoggedIn"));
          this._router.navigate(['app/aio/restaurants/Cashier-Screen']);
        }
        else{
          this.tableEngine.toastError(this.translate.instant("Error"), this.translate.instant("FailedLogin"));
          this.currentAction = '';
        }
      });
      }
      else {
        this.tableEngine.toastError(this.translate.instant("Error"), this.translate.instant("FailedLogin"));
        this.currentAction = '';
      }
    })
  }
}
