<div class="row">
    <div class="col-12">
        <div class="card-body">
            <a type="button" [routerLink]="[ '../../assets']" class="btn btn-primary default mb-1">{{'Back' | translate}}</a>{{" "}}
            <!-- <button type="button" (click)="print()" class="btn btn-secondary default mb-1">{{'Print' | translate}}</button>{{" "}}
            <button type="button" (click)="exportTable()" class="btn btn-success default mb-1">{{'PDF' | translate}}</button>{{" "}} -->
            <button type="button" (click)="showDepreciationModal()" class="btn btn-success default mb-1">{{'Add Depreciation' | translate}}</button>{{" "}}
            
        </div>
      <div class="display-options-container">
        <div class="separator mb-4"></div>
      </div>
    </div>
  </div>

<div class="FormScreen" class="tableScreen" id="contentToConvert">


    <div class="row mb-12">
        <div class="col-md-12 col-lg-12 col-12 mb-12">
          <div class="card">
            <tabset class="card-tabs" [justified]="true">
              <tab heading="{{'Details' | translate}}" class="card-body">
                <div class="px-3 pt-3">
                    <div class="panel">
                        <div class="panel-head">
                            <h3>{{"Asset Information"|translate}}</h3>
                        </div>
                    </div>
                    <div class="px-3 pt-3">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="mb-4">
                                    <p class="text-muted mb-1">{{"Name"|translate}}:</p>
                                    <p class="text-big-number">{{obj.Name}}</p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="mb-4">
                                    <p class="text-muted mb-1">{{"Purchase Date"|translate}}:</p>
                                    <p class="pre">{{obj.PurchaseDate | date:'dd/MM/yyyy'}}</p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="mb-4">
                                    <p class="text-muted mb-1">{{'Service Start Date'|translate}} :</p>
                                    <p>{{obj.ServiceStartDate|date:'dd/MM/yyyy'}}</p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="mb-4">
                                    <p class="text-muted mb-1">{{'Productive Age'|translate}} :</p>
                                    <p>{{obj.ProductiveAge}}</p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="mb-4">
                                    <p class="text-muted mb-1">{{"Purchase Amount"|translate}}:</p>
                                    <p class="pre">{{obj.PurchaseAmount}}</p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="mb-4">
                                    <p class="text-muted mb-1">{{'Description'|translate}} :</p>
                                    <p>{{obj.Description}}</p>
                                </div>
                            </div>
                             </div>
                    </div>

                </div>
              </tab>
              <tab heading="{{'Depreciations' | translate}}" class="card-body">
                <div class="px-3 pt-3">
                    <div class="panel">
                        <div class="panel-head">
                            <h3>{{"Depreciations"|translate}}</h3>
                        </div>
                    </div>
                    <div class="px-3 pt-3">
                        <div class="row">
                                <table class="table">
                                    <thead>
                                        <th>{{"Amount"|translate}}</th>
                                        <th>{{"Date"|translate}}</th>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of obj.DepreciationList">
                                            <td>{{item.Amount}}</td>
                                            <td>{{item.Date|date:'dd/MM/yyyy'}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                             </div>
                    </div>

                </div>
              </tab>
              <!-- <tab heading="{{'Contractors' | translate}}" class="card-body">
               
                   </tab> -->
      
            </tabset>
          </div>
        </div>
      </div>
</div>

<div bsModal #Depreciation="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">
    <div class="modal-dialog modal-sm">
        <div class="modal-content"  >
            <button type="button" class="close pull-right" aria-label="Close" (click)="Depreciation.hide()"
                style="margin-right: 20px; margin-left: 20px; margin-top: 10px; width: 20px; display: inline;">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-header" style="display: inline; padding: 0px 0px 5px 0px;">
                <h4 id="dialog-child-name"  class="modal-title pull-left"  style="text-align: center;">
                    {{"Add Depreciation" |
                    translate}}</h4>
            </div>
            <div class="modal-body">
                <Form [formGroup]="DepreciationForm" *ngIf="DepreciationForm">
                    <div class="row">
                        <div class="col-md-12">
                            <label for="inputEmail4">{{ 'Date' | translate }}</label>
                            <input formControlName="Date" type="Date" id="inputEmail4" class="form-control">
                        </div>
                        <div class="col-md-12" >
                            <label for="inputEmail4">{{ 'Amount' | translate }}</label>
                            <input formControlName="Amount" type="number" id="inputEmail4" class="form-control">
                        </div>
                  
                    </div>
                </Form>
           
                
                <div style="margin-left: 5px; text-align: center; margin-top: 30px;">
                    <button class="btn btn-success" id="smallModalDeleteButton" (click)="SaveDepreciation()"
                        style="font-weight: bold; width: 100%; text-align: center;">{{"Add" | translate}}</button>
                    <button class="btn btn-light" (click)="Depreciation.hide()"
                        style="font-weight: bold; width: 100%; text-align: center; margin-top: 10px;">{{"Close" |
                        translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>
