
<app-side-popup #companyModalRef>
    <div class="modal-content" style="width: 90%; text-align: center; margin-top: 20px; margin-left: auto; margin-right: auto;">
        <div style="align-content: center; display: inline-block;">
            <div class="modal-header" style="display: block; padding: 0px 0px 5px 0px; text-align: center; ">
                <h1 id="dialog-child-name" class="modal-title">
                    {{currentFormMode+"Company" |
                    translate}}</h1>
            </div>
            <div class="modal-body" *ngIf="CompanyForm">
                <form class="md-float-material form-material" [formGroup]="CompanyForm">

                    <div class="form-group form-primary">
                        <label class="float-label push righ">{{"CompanyNameEn" | translate}}</label>
                        <input *ngIf="currentFormMode==='Add'||currentFormMode==='Edit'" type="text" name="NameEn"
                            class="form-control" formControlName="NameEn" placeholder='{{"CompanyNameEn" | translate}}'>
                        <label *ngIf="currentFormMode==='Info'" class="form-control" type="text"
                            name="NameEn">{{company.NameEn}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (CompanyForm.get('NameEn').hasError('required') || CompanyForm.get('NameEn').invalid) && ( CompanyForm.get('NameEn').dirty ||  CompanyForm.get('NameEn').touched)">
                            {{"CompanyNameEnRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>

                    <div class="form-group form-primary">
                        <label class="float-label push righ">{{"CompanyNameAr" | translate}}</label>
                        <input *ngIf="currentFormMode==='Add'||currentFormMode==='Edit'" type="text" name="NameAr"
                            class="form-control" formControlName="NameAr" placeholder='{{"CompanyNameAr" | translate}}'>
                        <label *ngIf="currentFormMode==='Info'" class="form-control" type="text"
                            name="NameAr">{{company.NameAr}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (CompanyForm.get('NameAr').hasError('required') || CompanyForm.get('NameAr').invalid) && ( CompanyForm.get('NameAr').dirty ||  CompanyForm.get('NameAr').touched)">
                            {{"CompanyNameArRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>

                    <div style="margin-left: 5px; text-align: center; margin-top: 30px;">
                        <button *ngIf="currentFormMode==='Add'" id="ModalRefSubmitButton" class="btn btn-secondary" (click)="addCompany()"
                            style="font-weight: bold; width: 100%; text-align: center;">{{"Add" | translate}}</button>
                        <button *ngIf="currentFormMode==='Edit'" id="ModalRefSubmitButton" class="btn btn-secondary" (click)="editCompany()"
                            style="font-weight: bold; width: 100%; text-align: center;">{{"Edit" | translate}}</button>
                        <button class="btn btn-light" (click)="companyModalRef.modalRef.hide()"
                            style="font-weight: bold; width: 100%; text-align: center; margin-top: 10px;">{{"Close" | translate}}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</app-side-popup>
<div bsModal #delModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <button type="button" class="close pull-right" aria-label="Close" (click)="delModal.hide()"
                style="margin-right: 20px; margin-left: 20px; margin-top: 10px; width: 20px; display: inline;">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-header" style="display: inline; padding: 0px 0px 5px 0px;">
                <h4 id="dialog-child-name" class="modal-title pull-left"  style="text-align: center;">
                    {{"ConfirmDelCompany" |
                    translate}}</h4>
            </div>
            <div class="modal-body">
                <div class="form-group form-primary" style="text-align: center;" *ngFor="let item of deletionList;">
                    <label type="text">{{lang==='ar-EG'?item.NameAr:item.NameEn}}</label>
                    <span class="form-bar"></span>
                </div>

                <div style="margin-left: 5px; text-align: center; margin-top: 30px;">
                    <button class="btn btn-danger" id="ModalRefDeleteButton" (click)="deleteCompany(company.Id)" 
                        style="font-weight: bold; width: 100%; text-align: center;">{{"Delete" | translate}}</button>
                    <button class="btn btn-light" (click)="delModal.hide()"
                        style="font-weight: bold; width: 100%; text-align: center; margin-top: 10px;">{{"Close" | translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <app-side-popup #editModalRef>
        <div class="modal-dialog modal-sm">
            <div class="modal-content">
                <div class="modal-header" style="display: inline; padding: 0px 0px 5px 0px;">
                    <h4 id="dialog-child-name" class="modal-title pull-left" style="text-align: center;">{{"EditCompany" |
                        translate}}</h4>
                </div>
                <div class="modal-body">
                    <form class="md-float-material form-material" [formGroup]="CompanyForm">
                        <div class="form-group form-primary">
                            <label class="float-label push righ">{{"CompanyNameEn" | translate}}</label>
                            <input type="text" name="NameEn" class="form-control" formControlName="NameEn"
                                placeholder='{{"CompanyNameEn" | translate}}'>
                            <div class="messages text-danger"
                                *ngIf=" (CompanyForm.get('NameEn').hasError('required') || CompanyForm.get('NameEn').invalid) && ( CompanyForm.get('NameEn').dirty ||  CompanyForm.get('NameEn').touched)">
                                {{"CompanyNameEnRequired" | translate}}</div>
                            <span class="form-bar"></span>
                        </div>
    
                        <div class="form-group form-primary">
                            <label class="float-label push righ">{{"CompanyNameAr" | translate}}</label>
                            <input type="text" name="NameAr" class="form-control" formControlName="NameAr"
                                placeholder='{{"CompanyNameAr" | translate}}'>
                            <div class="messages text-danger"
                                *ngIf=" (CompanyForm.get('NameAr').hasError('required') || CompanyForm.get('NameAr').invalid) && ( CompanyForm.get('NameAr').dirty ||  CompanyForm.get('NameAr').touched)">
                                {{"CompanyNameArRequired" | translate}}</div>
                            <span class="form-bar"></span>
                        </div>
    
                        <div class="form-group form-primary">
                            <label class="float-label push righ">{{"CompanyApi" | translate}}</label>
                            <input type="text" name="Api" class="form-control" formControlName="Api"
                                placeholder='{{"CompanyApi" | translate}}'>
                            <div class="messages text-danger"
                                *ngIf=" (CompanyForm.get('Api').hasError('required') || CompanyForm.get('Api').invalid) && ( CompanyForm.get('Api').dirty ||  CompanyForm.get('Api').touched)">
                                {{"CompanyApiRequired" | translate}}</div>
                            <span class="form-bar"></span>
                        </div>
    
                        <div class="form-group form-primary">
                            <label class="float-label push righ">{{"CommercialRegister" | translate}}</label>
                            <input type="text" name="CommercialRegister" class="form-control"
                                formControlName="CommercialRegister" placeholder='{{"CommercialRegister" | translate}}'>
                            <div class="messages text-danger"
                                *ngIf=" (CompanyForm.get('CommercialRegister').hasError('required') || CompanyForm.get('CommercialRegister').invalid) && ( CompanyForm.get('CommercialRegister').dirty ||  CompanyForm.get('CommercialRegister').touched)">
                                {{"CommercialRegisterRequired" | translate}}</div>
                            <span class="form-bar"></span>
                        </div>
    
                        <div class="form-group form-primary">
                            <label class="float-label push righ">{{"TaxCard" | translate}}</label>
                            <input type="text" name="TaxCard" class="form-control" formControlName="TaxCard"
                                placeholder='{{"TaxCard" | translate}}'>
                            <div class="messages text-danger"
                                *ngIf=" (CompanyForm.get('TaxCard').hasError('required') || CompanyForm.get('TaxCard').invalid) && ( CompanyForm.get('TaxCard').dirty ||  CompanyForm.get('TaxCard').touched)">
                                {{"TaxCardRequired" | translate}}</div>
                            <span class="form-bar"></span>
                        </div>
    
                        <div class="form-group form-primary">
                            <label class="float-label push righ">{{"AuthorizedPerson" | translate}}</label>
                            <input type="text" name="AuthorizedPerson" class="form-control"
                                formControlName="AuthorizedPerson" placeholder='{{"AuthorizedPerson" | translate}}'>
                            <div class="messages text-danger"
                                *ngIf=" (CompanyForm.get('AuthorizedPerson').hasError('required') || CompanyForm.get('AuthorizedPerson').invalid) && ( CompanyForm.get('AuthorizedPerson').dirty ||  CompanyForm.get('AuthorizedPerson').touched)">
                                {{"AuthorizedPersonRequired" | translate}}</div>
                            <span class="form-bar"></span>
                        </div>
    
                        <div class="form-group form-primary">
                            <label class="float-label push righ">{{"Email" | translate}}</label>
                            <input type="text" name="Email" class="form-control" formControlName="Email"
                                placeholder='{{"Email" | translate}}'>
                            <div class="messages text-danger"
                                *ngIf=" (CompanyForm.get('Email').hasError('required') || CompanyForm.get('Email').invalid) && ( CompanyForm.get('Email').dirty ||  CompanyForm.get('Email').touched)">
                                {{"EmailRequired" | translate}}</div>
                            <span class="form-bar"></span>
                        </div>
    
                        <div class="form-group form-primary">
                            <label class="float-label push righ">{{"Landline" | translate}}</label>
                            <input type="text" name="Landline" class="form-control" formControlName="Landline"
                                placeholder='{{"Landline" | translate}}'>
                            <div class="messages text-danger"
                                *ngIf=" (CompanyForm.get('Landline').hasError('required') || CompanyForm.get('Landline').invalid) && ( CompanyForm.get('Landline').dirty ||  CompanyForm.get('Landline').touched)">
                                {{"LandlineRequired" | translate}}</div>
                            <span class="form-bar"></span>
                        </div>
    
                        <div class="form-group form-primary">
                            <label class="float-label push righ">{{"Phone" | translate}}</label>
                            <input type="text" name="Phone" class="form-control" formControlName="Phone"
                                placeholder='{{"Phone" | translate}}'>
                            <div class="messages text-danger"
                                *ngIf=" (CompanyForm.get('Phone').hasError('required') || CompanyForm.get('Phone').invalid) && ( CompanyForm.get('Phone').dirty ||  CompanyForm.get('Phone').touched)">
                                {{"PhoneRequired" | translate}}</div>
                            <span class="form-bar"></span>
                        </div>
    
                        <div class="form-group form-primary">
                            <label class="float-label push righ">{{"Notes" | translate}}</label>
                            <input type="text" name="Notes" class="form-control" formControlName="Notes"
                                placeholder='{{"Notes" | translate}}'>
                            <div class="messages text-danger"
                                *ngIf=" (CompanyForm.get('Notes').hasError('required') || CompanyForm.get('Notes').invalid) && ( CompanyForm.get('Notes').dirty ||  CompanyForm.get('Notes').touched)">
                                {{"NotesRequired" | translate}}</div>
                            <span class="form-bar"></span>
                        </div>
    
                        <div class="form-group form-primary">
                            <label class="float-label push righ">{{"Token" | translate}}</label>
                            <input type="text" name="Token" class="form-control" formControlName="Token"
                                placeholder='{{"Token" | translate}}'>
                            <div class="messages text-danger"
                                *ngIf=" (CompanyForm.get('Token').hasError('required') || CompanyForm.get('Token').invalid) && ( CompanyForm.get('Token').dirty ||  CompanyForm.get('Token').touched)">
                                {{"TokenRequired" | translate}}</div>
                            <span class="form-bar"></span>
                        </div>
    
                        <div style="margin-left: 5px; text-align: center; margin-top: 30px;">
                            <button class="btn btn-secondary" (click)="editCompany()"
                                style="font-weight: bold; width: 100%; text-align: center;">{{"Edit" | translate}}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
</app-side-popup>

<app-side-popup #infoModalRef>
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-header" style="display: inline; padding: 0px 0px 5px 0px;">
                <h4 id="dialog-child-name" class="modal-title pull-left" style="text-align: center;">{{"CompanyInfo" |
                    translate}}</h4>
            </div>
            <div class="modal-body">

                <div class="form-group form-primary">
                    <label class="float-label push righ">{{"CompanyNameEn" | translate}}</label>
                    <label type="text" name="NameEn" class="form-control">{{company.NameEn}}</label>
                    <span class="form-bar"></span>
                </div>

                <div class="form-group form-primary">
                    <label class="float-label push righ">{{"CompanyNameAr" | translate}}</label>
                    <label type="text" name="NameAr" class="form-control">{{company.NameAr}}</label>
                    <span class="form-bar"></span>
                </div>

                <div class="form-group form-primary">
                    <label class="float-label push righ">{{"CompanyApi" | translate}}</label>
                    <label type="text" name="Api" class="form-control">{{company.Api}}</label>
                    <span class="form-bar"></span>
                </div>

                <div class="form-group form-primary">
                    <label class="float-label push righ">{{"CommercialRegister" | translate}}</label>
                    <label type="text" name="CommercialRegister"
                        class="form-control">{{company.CommercialRegister}}</label>
                    <span class="form-bar"></span>
                </div>

                <div class="form-group form-primary">
                    <label class="float-label push righ">{{"CompanyTaxCard" | translate}}</label>
                    <label type="text" name="TaxCard" class="form-control">{{company.TaxCard}}</label>
                    <span class="form-bar"></span>
                </div>

                <div class="form-group form-primary">
                    <label class="float-label push righ">{{"CompanyAuthorizedPerson" | translate}}</label>
                    <label type="text" name="AuthorizedPerson" class="form-control">{{company.AuthorizedPerson}}</label>
                    <span class="form-bar"></span>
                </div>

                <div class="form-group form-primary">
                    <label class="float-label push righ">{{"CompanyEmail" | translate}}</label>
                    <label type="text" name="Email" class="form-control">{{company.Email}}</label>
                    <span class="form-bar"></span>
                </div>

                <div class="form-group form-primary">
                    <label class="float-label push righ">{{"CompanyLandline" | translate}}</label>
                    <label type="text" name="Landline" class="form-control">{{company.Landline}}</label>
                    <span class="form-bar"></span>
                </div>

                <div class="form-group form-primary">
                    <label class="float-label push righ">{{"CompanyPhone" | translate}}</label>
                    <label type="text" name="Phone" class="form-control">{{company.Phone}}</label>
                    <span class="form-bar"></span>
                </div>

                <div class="form-group form-primary">
                    <label class="float-label push righ">{{"CompanyNotes" | translate}}</label>
                    <label type="text" name="Notes" class="form-control"
                        style="white-space: pre-wrap;">{{company.Notes}}</label>
                    <span class="form-bar"></span>
                </div>

                <div class="form-group form-primary">
                    <label class="float-label push righ">{{"CompanyToken" | translate}}</label>
                    <label type="text" name="Token" class="form-control">{{company.Token}}</label>
                    <span class="form-bar"></span>
                </div>

            </div>
        </div>
    </div>
</app-side-popup> -->

<div id="divtop" style="display: block;
height: 47px;">
    <div id="divsearchright" style="float: right;  width: 33.33%; 
    padding: 0px;
    ">
        <div id="searchbar" style="display: inline-block; margin-top: 5px;">
            <div class="d-inline-block" style="margin-right: 3px; ">
                <div class="search" style="display: inline;">
                    <div class="d-inline-block" style=" margin-top: 5px;">
                        <div class="search-sm d-inline-block float-md-left mr-1 mb-1 align-top"
                        style="width: 275px;">
                            <input type="search" [(ngModel)]="SearchTerm" (keyup)="changeFilter()"
                                (change)="changeFilter()" class="form-control input-sm full-data-search"
                                placeholder="{{'CompanySearch' | translate}}">
                        </div>
                    </div>
                </div>
            </div>
            <div style="display: inline-block;">
                <div class="d-inline-block collapse dont-collapse-sm" id="displayOptions"
                    [collapse]="displayOptionsCollapsed">
                    <div class="d-block d-md-inline-block" style="margin-left: 10px; margin-right: 10px;">
                        <div class="float-md-left mr-1 mb-1" dropdown>
                            <span dropdown class="d-inline-block position-relative">
                                <button id="button-basic" dropdownToggle type="button"
                                    class="btn btn-outline-dark btn-xs dropdown-toggle" aria-controls="dropdown-basic"
                                    style="width: 120px; text-align: left;">
                                    {{itemOrder | translate}} <span class="caret"></span>
                                </button>
                                <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu"
                                    aria-labelledby="button-basic">
                                    <li role="menuitem" *ngFor="let item of itemOptionsOrders"><a
                                            [ngClass]="item === itemOrder ? 'dropdown-item active' : 'dropdown-item'"
                                            (click)="changeSort(item)">{{item | translate}}</a></li>
                                </ul>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div id="divsearchmiddle" style="display: inline-block; width: 33.33%; 
    height: 100%;
    ">

        <div style="text-align: center;">
            <bdo dir="ltr">
                <pagination class="pagination-sm2" style="padding: 0px, 0px, 0px, 0px; margin: 0px, 0px, 0px, 0px;
                display: inline-block;" [(ngModel)]="currentPage" [boundaryLinks]="true"
                    [totalItems]="filteredData.length" [itemsPerPage]="itemsPerPage"
                    [customFirstTemplate]="firstTemplate" [customLastTemplate]="lastTemplate"
                    [customPreviousTemplate]="prevTemplate" [customNextTemplate]="nextTemplate" [maxSize]="5"
                    (pageChanged)="changePage($event)">
                </pagination>
            </bdo>
        </div>
    </div>

    <div id="divsearchleft" style="float: left; width: 33.33%; 
    height: 100%;
    ">
        <div style="display: inline-block;
        width: 100%;  position: relative;
        margin-top: 10px;">
            <div style="text-align: center; float: right; margin-top: 3px;">
                <span *ngIf="filteredData.length!=0" class="text-muted text-small">
                    {{"DisplayingResults" | translate}}
                    {{(currentPage-1)*itemsPerPage+1}}
                    {{"resultsTo" | translate}}
                    {{((currentPage-1)*itemsPerPage+itemsPerPage) < filteredData.length ?
                        ((currentPage-1)*itemsPerPage+itemsPerPage) : filteredData.length}} ({{"resultsOf" | translate}}
                        {{filteredData.length}}) </span>
                <span *ngIf="filteredData.length===0" class="text-muted text-small">
                    {{"NoResults" | translate}} </span>
            </div>
            <div id="displayOptions" style=" float: left;">
                <span class="text-muted text-small">
                    {{"ResultCount" | translate}}
                </span>
                <span dropdown class="d-inline-block position-relative" style="margin-left: 10px; margin-right: 10px;">
                    <button class="btn btn-outline-dark btn-xs dropdown-toggle" type="button" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false" dropdownToggle>
                        {{itemsPerPage}}
                    </button>
                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-sm text-right" *dropdownMenu>
                        <li role="menuitem" *ngFor="let item of itemOptionsPerPage"><a
                                [ngClass]="item === itemsPerPage ? 'dropdown-item active' : 'dropdown-item'"
                                (click)="paginateData(item, 1)">{{item}}</a></li>
                    </div>
                </span>
            </div>
        </div>
    </div>

</div>

<div id="divmiddle" style="display: block;">
    <div style="width: 100%;">
        <div>
            <table class="table">
                <thead class="thead-light">
                    <th>
                        <label class="custom-control custom-checkbox mb-0 d-inline-block">
                            <input type="checkbox" class="custom-control-input" [checked]="selectAllState==='checked'"
                            (change)="selectAll($event)" [(ngModel)]="SelectAllBox">
                            <span class="custom-control-label">&nbsp;</span>
                        </label>
                    </th>
                    <th>{{"CompanyName" | translate}}</th>
                    <th>{{"CompanyEmail" | translate}}</th>
                    <th>{{"CompanyLandline" | translate}}</th>
                    <th>{{"CompanyPhone" | translate}}</th>
                    <th>{{"CompanyNotes" | translate}}</th>
                    <th></th>
                    <th style="text-align: center; padding: auto;">
                        <button class="btn btn-danger d-inline-block"
                            style="font-weight: bold; text-align: center; padding: 0px; width: 150px; height: 30px;"
                            (click)="showDelModal('delMulti')" [disabled]="selected.length===0">{{"DeleteSelected" | translate}}</button>
                    </th>
                    <th style="text-align: center; padding: auto;">
                        <button class="btn btn-secondary d-inline-block"
                            style="font-weight: bold; text-align: center; padding: 0px; width: 150px; height: 30px;"
                            (click)="showAddNewModalRef()">{{"AddCompany" | translate}}</button>
                    </th>
                </thead>
                <tbody>
                    <tr *ngFor="let company of paginatedData;  let i=index">
                        <td>
                            <div class="itemCheck mb-0 custom-checkbox custom-control">
                                <input type="checkbox" class="custom-control-input" [id]="'customCheck'+i"
                                    [checked]="isSelected(company)" (change)="onSelect(company)">
                                <label class="custom-control-label" [for]="'customCheck'+i"></label>
                            </div>
                        </td>
                        <td *ngIf="lang==='en-US'">{{company.NameEn}}</td>
                        <td *ngIf="lang==='ar-EG'">{{company.NameAr}}</td>
                        <td>{{company.Email}}</td>
                        <td>{{company.Landline}}</td>
                        <td>{{company.Phone}}</td>
                        <td>
                            <div style="white-space: pre-wrap;">{{company.Notes}}</div>
                        </td>
                        <td><button class="btn btn-info text-center" (click)="showInfoModalRef(company.Id)"
                                style="font-weight: bold; width: 100%;">{{"More" | translate}}</button></td>
                        <td><button class="btn btn-outline-warning text-center" (click)="showEditModalRef(company.Id)"
                                style="font-weight: bold; width: 100%;">{{"Edit" | translate}}</button></td>
                        <td><button class="btn btn-outline-danger text-center" (click)="showDelModal(company.Id)"
                                style="font-weight: bold; width: 100%">{{"Delete" | translate}}</button></td>
                    </tr>
                </tbody>
                <thead class="thead-light">
                    <th>
                        <label class="custom-control custom-checkbox mb-0 d-inline-block">
                            <input type="checkbox" class="custom-control-input" [checked]="selectAllState==='checked'"
                            (change)="selectAll($event)" [(ngModel)]="SelectAllBox">
                            <span class="custom-control-label">&nbsp;</span>
                        </label>
                    </th>
                    <th>{{"CompanyName" | translate}}</th>
                    <th>{{"CompanyEmail" | translate}}</th>
                    <th>{{"CompanyLandline" | translate}}</th>
                    <th>{{"CompanyPhone" | translate}}</th>
                    <th>{{"CompanyNotes" | translate}}</th>
                    <th></th>
                    <th style="text-align: center; padding: auto;">
                        <button class="btn btn-danger d-inline-block"
                            style="font-weight: bold; text-align: center; padding: 0px; width: 150px; height: 30px;"
                            (click)="showDelModal('delMulti')" [disabled]="selected.length===0">{{"DeleteSelected" | translate}}</button>
                    </th>
                    <th style="text-align: center; padding: auto;">
                        <button class="btn btn-secondary d-inline-block"
                            style="font-weight: bold; text-align: center; padding: 0px; width: 150px; height: 30px;"
                            (click)="showAddNewModalRef()">{{"AddCompany" | translate}}</button>
                    </th>
                </thead>
            </table>
        </div>
    </div>
</div>

<div id="divbottom" style="display: block;
height: 47px;">
    <div id="divsearchright" style="float: right;  width: 33.33%; 
    padding: 0px;
    ">
        <div id="searchbar" style="display: inline-block;">
            <div class="d-inline-block" style="margin-right: 3px; ">
                <div class="search" style="display: inline;">
                    <div class="d-inline-block" style=" margin-top: 5px;">
                        <div class="search-sm d-inline-block float-md-left mr-1 mb-1 align-top"
                        style="width: 275px;">
                            <input type="search" [(ngModel)]="SearchTerm" (keyup)="changeFilter()"
                                (change)="changeFilter()" class="form-control input-sm full-data-search"
                                placeholder="{{'CompanySearch' | translate}}">
                        </div>
                    </div>
                </div>
            </div>
            <div style="display: inline-block;">
                <div class="d-inline-block collapse dont-collapse-sm" id="displayOptions"
                    [collapse]="displayOptionsCollapsed">
                    <div class="d-block d-md-inline-block" style="margin-left: 10px; margin-right: 10px;">
                        <div class="float-md-left mr-1 mb-1" dropdown>
                            <span dropdown class="d-inline-block position-relative">
                                <button id="button-basic" dropdownToggle type="button"
                                    class="btn btn-outline-dark btn-xs dropdown-toggle" aria-controls="dropdown-basic"
                                    style="width: 100px; text-align: left;">
                                    {{itemOrder | translate}} <span class="caret"></span>
                                </button>
                                <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu"
                                    aria-labelledby="button-basic">
                                    <li role="menuitem" *ngFor="let item of itemOptionsOrders"><a
                                            [ngClass]="item === itemOrder ? 'dropdown-item active' : 'dropdown-item'"
                                            (click)="changeSort(item)">{{item}}</a></li>
                                </ul>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div id="divsearchmiddle" style="display: inline-block; width: 33.33%; 
    height: 100%;
    ">

        <div style="text-align: center;">
            <bdo dir="ltr">
                <pagination class="pagination-sm2" style="padding: 0px, 0px, 0px, 0px; margin: 0px, 0px, 0px, 0px;
                display: inline-block;" [(ngModel)]="currentPage" [boundaryLinks]="true"
                    [totalItems]="filteredData.length" [itemsPerPage]="itemsPerPage"
                    [customFirstTemplate]="firstTemplate" [customLastTemplate]="lastTemplate"
                    [customPreviousTemplate]="prevTemplate" [customNextTemplate]="nextTemplate" [maxSize]="5"
                    (pageChanged)="changePage($event)">
                </pagination>
            </bdo>
        </div>
    </div>

    <div id="divsearchleft" style="float: left; width: 33.33%; 
    height: 100%;
    ">
        <div style="display: inline-block;
        width: 100%;  position: relative;
        margin-top: 10px;">
            <div style="text-align: center; float: right; margin-top: 3px;">
                <span class="text-muted text-small">
                    {{"DisplayingResults" | translate}}
                    {{(currentPage-1)*itemsPerPage+1}}
                    {{"resultsTo" | translate}}
                    {{((currentPage-1)*itemsPerPage+itemsPerPage) < filteredData.length ?
                        ((currentPage-1)*itemsPerPage+itemsPerPage) : filteredData.length}} ({{"resultsOf" | translate}}
                        {{filteredData.length}}) </span>
            </div>
            <div id="displayOptions" style=" float: left;">
                <span class="text-muted text-small">
                    {{"ResultCount" | translate}}
                </span>
                <span dropdown class="d-inline-block position-relative" style="margin-left: 10px; margin-right: 10px;">
                    <button class="btn btn-outline-dark btn-xs dropdown-toggle" type="button" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false" dropdownToggle>
                        {{itemsPerPage}}
                    </button>
                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-sm text-right" *dropdownMenu>
                        <li role="menuitem" *ngFor="let item of itemOptionsPerPage"><a
                                [ngClass]="item === itemsPerPage ? 'dropdown-item active' : 'dropdown-item'"
                                (click)="paginateData(item, 1)">{{item}}</a></li>
                    </div>
                </span>
            </div>
        </div>
    </div>

</div>

<ng-template #nextTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i *ngIf="lang==='en-US'" class='simple-icon-arrow-right'></i>
    <i *ngIf="lang==='ar-EG'" class='simple-icon-arrow-left'></i>
</ng-template>

<ng-template #prevTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i *ngIf="lang==='en-US'" class='simple-icon-arrow-left'></i>
    <i *ngIf="lang==='ar-EG'" class='simple-icon-arrow-right'></i>
</ng-template>

<ng-template #lastTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i class='simple-icon-control-end'></i>
</ng-template>

<ng-template #firstTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i class='simple-icon-control-start'></i>
</ng-template>


<simple-notifications></simple-notifications>