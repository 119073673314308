export class ReturnPurchaseItemModel {
    Id?: number;
    Code?: number;
    ReturnPurchaseId?: number;
    ReturnPurchaseCode?: number;
    ItemId?: number;
    ItemNameAr?: string;
    ItemNameEn?: string;
    UnitId?: number;
    UnitNameAr?: string;
    UnitNameEn?: string;
    UnitCost?: number;
    Quantity?: number;
    TotalPrice?: number;
    DiscountIsRate?: boolean;
    DiscountValue?: number;
    NetPrice?: number;
    TaxValue?: number;
    GrossPrice?: number;
    MaxQuantity?: number;
    TaxId?: number;
    constructor(obj?: ReturnPurchaseItemModel) {
        obj = obj ? obj : {};
        this.Id = obj.Id ? obj.Id : 0;
        this.Code = obj.Code ? obj.Code : 0;
        this.ReturnPurchaseId = obj.ReturnPurchaseId ? obj.ReturnPurchaseId : 0;
        this.TaxId = obj.TaxId ? obj.TaxId : 0;
        this.ReturnPurchaseCode = obj.ReturnPurchaseCode ? obj.ReturnPurchaseCode : 0;
        this.ItemId = obj.ItemId ? obj.ItemId : 0;
        this.ItemNameAr = obj.ItemNameAr ? obj.ItemNameAr : '';
        this.ItemNameEn = obj.ItemNameEn ? obj.ItemNameEn : '';
        this.UnitId = obj.UnitId ? obj.UnitId : 0;
        this.UnitNameAr = obj.UnitNameAr ? obj.UnitNameAr : '';
        this.UnitNameEn = obj.UnitNameEn ? obj.UnitNameEn : '';
        this.UnitCost = obj.UnitCost ? obj.UnitCost : 0;
        this.Quantity = obj.Quantity ? obj.Quantity : 0;
        this.TotalPrice = obj.TotalPrice ? obj.TotalPrice : 0;
        this.DiscountIsRate = obj.DiscountIsRate ? obj.DiscountIsRate : false;
        this.DiscountValue = obj.DiscountValue ? obj.DiscountValue : 0;
        this.NetPrice = obj.NetPrice ? obj.NetPrice : 0;
        this.TaxValue = obj.TaxValue ? obj.TaxValue : 0;
        this.GrossPrice = obj.GrossPrice ? obj.GrossPrice : 0;
        this.MaxQuantity   = obj.MaxQuantity   ? obj.MaxQuantity   : null;
    }

}