import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ClientBusinessExtractModel } from './models/client-business-extract.model';

@Injectable({
  providedIn: 'root'
})
export class ClientBusinessExtractService {
    constructor(private http: HttpClient) {
  
    }
    baseUrl = environment.baseApiUrl;
     token = localStorage.getItem("token");
     httpOptions = {
      headers: new HttpHeaders({
        // 'Content-Type': 'application/json; charset=utf-8',
        // 'Authorization': "Bearer " + token,
         "Authorization": "Bearer " + this.token,
         "Content-Type": "application/json"
      })
    };
    getAll(): Observable<any> {
   
      return this.http
        .get<any>(`${this.baseUrl}ClientBusinessExtract/GetAll`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    getAllWorkItem(): Observable<any> {
   
      return this.http
        .get<any>(`${this.baseUrl}WorkItem/GetAll`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    getAllContractors(): Observable<any> {
   
      return this.http
        .get<any>(`${this.baseUrl}Contractor/GetAll`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    getById(id:number): Observable<any> {
   
      return this.http
        .get<any>(`${this.baseUrl}ClientBusinessExtract/GetById/${id}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    GetLastCode(): Observable<number> {
   
      return this.http
        .get<number>(`${this.baseUrl}ClientBusinessExtract/GetLastCode`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    
    GetPreviousDetails(projectId:number,contractorId:number,workItem:number): Observable<any> {
   
      return this.http
        .get<any>(`${this.baseUrl}ClientBusinessExtract/GetPreviousDetails/${projectId}/${contractorId}/${workItem}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    
    insert(payload: ClientBusinessExtractModel): Observable<boolean> {
      return this.http
        .post<boolean>(`${this.baseUrl}ClientBusinessExtract/Insert`, payload,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
    update(payload: ClientBusinessExtractModel): Observable<any> {
      return this.http
        .post<any>(`${this.baseUrl}ClientBusinessExtract/Update`, payload,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
    delete(payload: ClientBusinessExtractModel): Observable<any> {
      return this.http
        .get<any>(`${this.baseUrl}ClientBusinessExtract/Delete/${payload.Id}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
    deleteList(payload: ClientBusinessExtractModel []): Observable<any> {
      return this.http
        .post<any>(`${this.baseUrl}ClientBusinessExtract/DeleteList`,JSON.stringify(payload),this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
}
