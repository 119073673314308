<!--component html goes here -->
<div class="row mb-12">
    <div class="col-md-12 col-lg-12 col-12 mb-12">
      <div class="card"></div>
    </div>
  </div>
  <div class="row" *ngIf="mainForm">
    <div class="col-12">
      <div class="card mb-4">
        <div class="card-body">
          <h5 class="float-left">{{ "Create Price List" | translate }}</h5>
          <div class="top-right-button-container text-zero float-right">
            <button
              class="top-right-button btn btn-primary btn-lg mr-1"
              (click)="goBack()"
            >
              {{ "Back" | translate }}
            </button>
            <!-- <button
              type="button"
              (click)="Save()"
              [disabled]="mainForm.invalid"
              class="top-right-button btn btn-primary btn-lg mr-1"
            >
              {{ "Save" | translate }}
            </button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mb-12">
    <div class="col-md-12 col-lg-12 col-12 mb-12">
      <div class="card">
        <tabset class="card-tabs" [justified]="true">
          <tab heading="{{ 'Warehouse' | translate }}" class="card-body">
            <!-- <h6 class="mb-4">{{ "Basic Data" | translate }}</h6> -->
            <div class="modal-body">
              <form
                class="md-float-material form-material"
                [formGroup]="mainForm"
              >
                <div class="row">
                  <div class="col-md-6">
                    <label for="inputState">{{ "Name" | translate }}</label>
                    <input
                      class="form-control"
                      formControlName="Name"
                      rows="2"
                      placeholder="{{ 'Name' | translate }}"
                      readonly
                    />
                    <div
                      class="messages text-danger"
                      *ngIf="
                        (mainForm.get('Name').hasError('required') ||
                          mainForm.get('Name').invalid) &&
                        (mainForm.get('Name').dirty ||
                          mainForm.get('Name').touched)
                      "
                    >
                      {{ "NameRequired" | translate }}
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label for="inputState">{{ "Address" | translate }}</label>
                    <input
                      class="form-control"
                      formControlName="Address"
                      rows="2"
                      placeholder="{{ 'Address' | translate }}"
                      readonly

                    />
                    <div
                      class="messages text-danger"
                      *ngIf="
                        (mainForm.get('Address').hasError('required') ||
                          mainForm.get('Address').invalid) &&
                        (mainForm.get('Address').dirty ||
                          mainForm.get('Address').touched)
                      "
                    >
                      {{ "AddressRequired" | translate }}
                    </div>
                  </div>
  
                  <div class="col-md-6">
                    <label for="inputState">{{ "Mobil" | translate }}</label>
                    <input
                      class="form-control"
                      formControlName="Mobil"
                      rows="2"
                      placeholder="{{ 'Mobil' | translate }}"
                      readonly

                    />
                    <div
                      class="messages text-danger"
                      *ngIf="
                        (mainForm.get('Mobil').hasError('required') ||
                          mainForm.get('Mobil').invalid) &&
                        (mainForm.get('Mobil').dirty ||
                          mainForm.get('Mobil').touched)
                      "
                    >
                      {{ "MobilRequired" | translate }}
                    </div>
                  </div>
  
                  <div class="col-md-6">
                    <label for="inputState">{{ "Phone" | translate }}</label>
                    <input
                      class="form-control"
                      formControlName="Phone"
                      rows="2"
                      placeholder="{{ 'Phone' | translate }}"
                      readonly

                    />
                    <div
                      class="messages text-danger"
                      *ngIf="
                        (mainForm.get('Phone').hasError('required') ||
                          mainForm.get('Phone').invalid) &&
                        (mainForm.get('Phone').dirty ||
                          mainForm.get('Phone').touched)
                      "
                    >
                      {{ "PhoneRequired" | translate }}
                    </div>
                  </div>
  
                  <div class="col-md-6">
                    <label for="inputState">{{ "Fax" | translate }}</label>
                    <input
                      class="form-control"
                      formControlName="Fax"
                      rows="2"
                      placeholder="{{ 'Fax' | translate }}"
                      readonly

                    />
                    <div
                      class="messages text-danger"
                      *ngIf="
                        (mainForm.get('Fax').hasError('required') ||
                          mainForm.get('Fax').invalid) &&
                        (mainForm.get('Fax').dirty || mainForm.get('Fax').touched)
                      "
                    >
                      {{ "FaxRequired" | translate }}
                    </div>
                  </div>
  
                  <div class="col-md-6">
                    <label for="inputState">{{
                      "Description" | translate
                    }}</label>
                    <input
                      class="form-control"
                      formControlName="Description"
                      rows="2"
                      placeholder="{{ 'Description' | translate }}"
                      readonly

                    />
                    <div
                      class="messages text-danger"
                      *ngIf="
                        (mainForm.get('Description').hasError('required') ||
                          mainForm.get('Description').invalid) &&
                        (mainForm.get('Description').dirty ||
                          mainForm.get('Description').touched)
                      "
                    >
                      {{ "DescriptionRequired" | translate }}
                    </div>
                  </div>
                </div>
                <div class="row"></div>
                <div
                  class="itemCheck mb-0 custom-checkbox custom-control"
                  style="display: inline-block"
                >
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    [id]="'valueCheck'"
                    formControlName="IsPrime"
                    readonly
                    />
                  <label class="custom-control-label" [for]="'valueCheck'"
                    >{{ "Prime" | translate }}
                  </label>
                </div>
              </form>
            </div>
          </tab>
      
        </tabset>
       
        
      </div>
     
    </div>
  </div>
  <td></td>
  <td></td>
  <td></td>
  <!-- <div class="row mb-12">
  <div class="col-md-12 col-lg-12 col-12 mb-12">    
    <div class="card">
      
      <div class="modal-body">
      <h2 class="w3-center">{{"Validity"}}</h2>
        <div class="row">
          <div class="col-md-6">
            <label for="inputState">{{ "Show" | translate }}</label>
            <select
              id="inputState"
              class="form-control"
              [(ngModel)]="visable"
              readonly              
            >
              <option selected value="''">
                {{ "All" | translate }}
              </option>
              <option *ngFor="let item of itemsData" value="{{ item.Name }}">
                {{ lang === "ar-EG" ? item.Name : item.Name }}
              </option>
            </select>
          </div>
  
          <div class="col-md-6" *ngIf="visable == 'موظف محدد'">
            <label for="inputState">{{ "Employee" | translate }}</label>
            <select id="inputState" class="form-control">
              <option selected value="''">
                {{ "All" | translate }}
              </option>
              <option
                *ngFor="let Employee of EmployeesData"
                value="{{ Employee.Id }}"
                readonly

              >
                {{ lang === "ar-EG" ? Employee.Name : Employee.Name }}
              </option>
              
            </select>
          </div>
  
          <div class="col-md-6" *ngIf="visable == 'دور وظيفي محدد'">
            <label for="inputState">{{ "JobRole" | translate }}</label>
            <select id="inputState" class="form-control">
              <option selected value="''">
                {{ "All" | translate }}
              </option>
              <option
                *ngFor="let JobRole of JobRoleData"
                value="{{ JobRole.Id }}"
                readonly

              >
                {{ lang === "ar-EG" ? JobRole.Name : JobRole.Name }}
              </option>
            </select>
          </div>
  
          <div class="col-md-6" *ngIf="visable == 'فرع محدد'">
            <label for="inputState">{{ "Branches" | translate }}</label>
            <select id="inputState" class="form-control">
              <option selected value="''">
                {{ "All" | translate }}
              </option>
              <option
                *ngFor="let Branches of BranchesData"
                value="{{ Branches.Id }}"
                readonly

              >
                {{ lang === "ar-EG" ? Branches.Name : Branches.Name }}
              </option>
            </select>
          </div>
        </div>
  
        <div class="row">
          <div class="col-md-6">
            <label for="inputState">{{ "Create Invoice" | translate }}</label>
            <select
              id="inputState"
              class="form-control"
              [(ngModel)]="visable2"
              readonly

            >
              <option selected value="'">
                {{ "All" | translate }}
              </option>
              <option *ngFor="let item of itemsData" value="{{ item.Name }}">
                {{ lang === "ar-EG" ? item.Name : item.Name }}
                
              </option>
            </select>
          </div>
          <div class="col-md-6" *ngIf="visable2 == 'موظف محدد'">
            <label for="inputState">{{ "Employee" | translate }}</label>
            <select id="inputState" class="form-control">
              <option selected value="''">
                {{ "All" | translate }}
              </option>
              <option
                *ngFor="let Employee of EmployeesData"
                value="{{ Employee.Id }}"
                readonly

              >
                {{ lang === "ar-EG" ? Employee.Name : Employee.Name }}
              </option>
            </select>
          </div>
  
          <div class="col-md-6" *ngIf="visable2 == 'دور وظيفي محدد'">
            <label for="inputState">{{ "JobRole" | translate }}</label>
            <select id="inputState" class="form-control">
              <option selected value="''">
                {{ "All" | translate }}
              </option>
              <option
                *ngFor="let JobRole of JobRoleData"
                value="{{ JobRole.Id }}"
                readonly

              >
                {{ lang === "ar-EG" ? JobRole.Name : JobRole.Name }}
              </option>
            </select>
          </div>
  
          <div class="col-md-6" *ngIf="visable2 == 'فرع محدد'">
            <label for="inputState">{{ "Branches" | translate }}</label>
            <select id="inputState" class="form-control">
              <option selected value="''">
                {{ "All" | translate }}
              </option>
              <option
                *ngFor="let Branches of BranchesData"
                value="{{ Branches.Id }}"
                readonly

              >
                {{ lang === "ar-EG" ? Branches.Name : Branches.Name }}
              </option>
            </select>
          </div>
        </div>
  
        <div class="row">
          <div class="col-md-6">
            <label for="inputState">{{ "Edit Stores" | translate }}</label>
            <select
              id="inputState"
              class="form-control"
              [(ngModel)]="visable3"
              readonly

            >
              <option selected value="">
                {{ "All" | translate }}
              </option>
              <option *ngFor="let item of itemsData" value="{{ item.Name }}">
                {{ lang === "ar-EG" ? item.Name : item.Name }}
              </option>
            </select>
          </div>
          <div class="col-md-6" *ngIf="visable3 == 'موظف محدد'">
            <label for="inputState">{{ "Employee" | translate }}</label>
            <select id="inputState" class="form-control">
              <option selected value="''">
                {{ "All" | translate }}
              </option>
              <option
                *ngFor="let Employee of EmployeesData"
                value="{{ Employee.Id }}"
                readonly

              >
                {{ lang === "ar-EG" ? Employee.Name : Employee.Name }}
              </option>
            </select>
          </div>
  
          <div class="col-md-6" *ngIf="visable3 == 'دور وظيفي محدد'">
            <label for="inputState">{{ "JobRole" | translate }}</label>
            <select id="inputState" class="form-control">
              <option selected value="''">
                {{ "All" | translate }}
              </option>
              <option
                *ngFor="let JobRole of JobRoleData"
                value="{{ JobRole.Id }}"
                readonly

              >
                {{ lang === "ar-EG" ? JobRole.Name : JobRole.Name }}
              </option>
            </select>
          </div>
  
          <div class="col-md-6" *ngIf="visable3 == 'فرع محدد'">
            <label for="inputState">{{ "Branches" | translate }}</label>
            <select id="inputState" class="form-control">
              <option selected value="''">
                {{ "All" | translate }}
              </option>
              <option
                *ngFor="let Branches of BranchesData"
                value="{{ Branches.Id }}"
                readonly

              >
                {{ lang === "ar-EG" ? Branches.Name : Branches.Name }}
              </option>
            </select>
          </div>
        </div>
      </div>
   
      </div>
    </div>
  </div> -->
  