// import { StoreItemsModel } from "./StoreItemsModel";


export class BrandModel{
    Id?: number;
    Name?:string;
    Image?:string;
   
    constructor(item?:BrandModel){
        item = item? item:{};
        this.Id = item.Id? item.Id:0;
        this.Name = item.Name? item.Name:'';
        this.Image = item.Image? item.Image:'';     

    }

}