export class BankCodeModel{
    Id?:number;
    NameAr?:string;
    NameEn?:string;
    BankAddress?:string;
    BankAccountIBAN?:string;
    SwiftCode?:string;

    AccountNameId?:number;
    Terms?:string;
    constructor(obj?:BankCodeModel){
        obj = obj? obj:{};
        this.Id = obj.Id? obj.Id:0;
        this.NameAr = obj.NameAr? obj.NameAr:'';
        this.NameEn = obj.NameEn? obj.NameEn:'';
        this.BankAccountIBAN = obj.BankAccountIBAN? obj.BankAccountIBAN:'';
        this.BankAddress = obj.BankAddress? obj.BankAddress:'';
        this.AccountNameId = obj.AccountNameId? obj.AccountNameId:0;
        this.Terms = obj.Terms? obj.Terms:'';
        this.SwiftCode = obj.SwiftCode? obj.SwiftCode:'';
    }
}
