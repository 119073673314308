import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {EmployeesManagementModel} from '../EmployeesManagement/EmployeesManagementModel';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

   
  constructor(private http:HttpClient ) { }

  
  baseUrl = environment.baseApiUrl;
  token = localStorage.getItem("token");
  httpOptions = {
   headers: new HttpHeaders({
     // 'Content-Type': 'application/json; charset=utf-8',
     // 'Authorization': "Bearer " + token,
      "Authorization": "Bearer " + this.token,
      "Content-Type": "application/json"
   })
 };

 getAll(): Observable<any> {
   
  return this.http
    .get<any>(`${this.baseUrl}Users/GetAll`,this.httpOptions)
    .pipe(catchError((error: any) => Observable.throw(error => error)));
}
getById(Id:number): Observable<any> {

  return this.http
    .get<any>(`${this.baseUrl}Users/GetById/${Id}`,this.httpOptions)
    .pipe(catchError((error: any) => Observable.throw(error => error)));
}
insert(payload: EmployeesManagementModel): Observable<any> {
  
  return this.http
    .post<any>(`${this.baseUrl}Users/Insert`, payload,this.httpOptions)
    .pipe(catchError((error: any) => Observable.throw(error => error)));
}

GetLastCode(): Observable<any> {
   
  return this.http
    .get<any>(`${this.baseUrl}Users/GetLastCode`,this.httpOptions)
    .pipe(catchError((error: any) => Observable.throw(error => error)));
}

update(payload: EmployeesManagementModel): Observable<any> {
  return this.http
    .post<any>(`${this.baseUrl}Users/Update`, payload,this.httpOptions)
    .pipe(catchError((error: any) => Observable.throw(error => error)));
}

delete(Id:number): Observable<any> {
  return this.http
    .get<any>(`${this.baseUrl}Users/Delete/${Id}`,this.httpOptions)
    .pipe(catchError((error: any) => Observable.throw(error => error)));
}

  deleteList(payload: EmployeesManagementModel []): Observable<any> {
    return this.http
      .post<any>(`${this.baseUrl}Users/DeleteList`,JSON.stringify(payload),this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error => error)));
  }
}
