import { Component, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { TableEngine } from 'src/app/invoices/TableEngine';

import { SidePopupComponent } from 'src/app/views/invoice/side-popup/side-popup.component';
import { SalariesPaymentModel } from './SalariesPaymentModel';
import { EmployeesManagementModel } from 'src/app/HR/Employees/EmployeesManagement/EmployeesManagementModel';

import { SalariesPaymentService } from './salaries-payment.service';
import { SalariesManagerService } from '../salaries-manager.service';
import { SalariesManagerModel } from '../add-salaries-manager/SalariesManagerModel';
import { SalariesManagerPayment } from './SalariesManagerPayment';
import { TreasuryAndBankAccountsService } from 'src/app/views/app/aio/acounting_components/treasury-and-bank-accounts/treasury-and-bank-accounts.service';
import { TreasuryAndBankAccountsModel } from 'src/app/views/app/aio/acounting_components/treasury-and-bank-accounts/model/treasury-and-bank-accounts-model';



@Component({
    // moduleId: module.id,
    selector: 'add-salaries-payment',
    templateUrl: 'add-salaries-payment.component.html',
    styleUrls: ['add-salaries-payment.component.scss']
})
export class AddSalariesPaymentComponent {

    Id = 0;

    constructor(
        
      public router: Router,
      private fb: FormBuilder,
      private modelService: SalariesPaymentService,
      private SalariesManagerService: SalariesManagerService,
      private TreasuryAndBankAccountsService: TreasuryAndBankAccountsService,
      
      private notifications: NotificationsService,
      
      private translate: TranslateService,
      private route: ActivatedRoute
    ) {
      this.Id = +route.snapshot.paramMap.get("Id");
    }
    showItemIndex = 0;
    tableEngine = new TableEngine(this.translate, this.notifications);
    currentScreen: any = "Table";
    translationName: string = "Item";
    sortableKeys = [];
    searchableKeys = ["Name", "Address"];
    tableView: any = true;
  
    SalariesPaymentModel:SalariesPaymentModel[]=[];
    isLastOpen = false;
  
  
    modelObject: SalariesPaymentModel = new SalariesPaymentModel();
    Form: FormGroup;
    declare lang: any;
    declare itemOrder: any;
    currentAction = "";
    savedPage = 0;
    currentPage = 1;
    deletionList: any[] = [];
    prevAction = "";
    addToListId = 0;
    ItemsBalanceHeaderId = 0;
    ItemBalanceId = 0;
    currentFormMode: string = "";
    type = 0;
    visable = "";
    visable2 = "";
    visable3 = "";

    Code=0;

  AlertMessage :string="Alert me when the quantity reaches less than";
    ogData: SalariesPaymentModel[] = [];
    mainForm: FormGroup = new FormGroup({
        Id: new FormControl(),
        PaymentDate: new FormControl(),
        BankAccountId: new FormControl(),
        Notes: new FormControl(),
        PaymentOfApproved: new FormControl(),
        PaymentOfSelected: new FormControl(),
        SalariesManagerId: new FormControl(),
        
    });
  

    conpanyId:number;
    
    @ViewChild("sideModalRef", { static: true })
    sideModalRef: SidePopupComponent = null;
    @ViewChild("delModal", { static: true }) delModal: ModalDirective;
    @ViewChild("smallModal", { static: true }) smallModal: ModalDirective = null;
    formPresentationModal: any;
    ngOnInit(): void {
      // this.getAllCountryCode();
  
      this.lang = localStorage.getItem("theme_lang");
      this.itemOrder = {
        label: "Name",
        property: this.lang === "ar-EG" ? "Name" : "Name",
      };
      this.sortableKeys = [
        // { label: 'Date', property: 'Date' },
        { label: 'Name', property: this.lang === 'ar-EG' ? 'Name' : 'Name' },   
        { label: 'Nickname', property: this.lang === 'ar-EG' ? 'Nickname' : 'Nickname' },   
        { label: 'MobileNumber', property: this.lang === 'ar-EG' ? 'MobileNumber' : 'MobileNumber' }, 
            
      ];
  
      this.tableEngine.loadTableEngine(
        this.ogData,
        this.modelService,
        this.modelObject,
        this.searchableKeys,
        this.sortableKeys,
        this.mainForm,
        this.translationName,
        this.itemOrder,
        this.sideModalRef,
        this.delModal,
        this.smallModal,
        this.lang
      );
      this.loadServices();
  this.SalariesManagerService.getAll().subscribe(data=>{
    this.SalariesManagerList=data;
  });
this.TreasuryAndBankAccountsService.getAll().subscribe(data=>{
    this.BankAccountList=data;
})
this.modelService.getLastNumber()
.subscribe(code=>{
  this.modelObject.Code = code+1
  this.mainForm.controls['Code'].setValue(this.modelObject.Code);
})

     
          if(this.Id > 0)
          {   debugger;                            
                  this.modelService.getById(this.Id).subscribe(data=>{                        
                  this.modelObject = data;        
                   
               

                  this.mainForm = this.createForm(this.modelObject);
              });
          }
          else{
                  
             this.mainForm = this.createForm(this.modelObject);                              
          }
      this.mainForm = this.createForm(this.modelObject);
  
    }


 

    ReversePaymentOfApproved(){
        debugger;
        this.mainForm.controls['PaymentOfApproved'].setValue(this.modelObject.PaymentOfApproved=false);

            // this.mainForm.controls['GrammarChoose'].value==false;
    }
    ReversePaymentOfSelected(){
        debugger;
        this.mainForm.controls['PaymentOfSelected'].setValue(this.modelObject.PaymentOfSelected=false);

        // this.mainForm.controls['EmployeesChoose'].value==false;
}
    createForm(modelObject): FormGroup {
      debugger;
      return this.fb.group({
        Id: [modelObject.Id >= 0 ? modelObject.Id : 0],
        PaymentDate: [this.modelObject.PaymentDate != null ? new Date(this.modelObject.PaymentDate).toISOString().slice(0, 10):null, Validators.compose([Validators.required])],
        BankAccountId: [modelObject.BankAccountId],
        Notes: [modelObject.Notes],
        PaymentOfApproved: [modelObject.PaymentOfApproved],
        PaymentOfSelected: [modelObject.PaymentOfSelected],
        SalariesManagerId: [modelObject.SalariesManagerId],
        Amount: [modelObject.Amount],
        

        
      });
    }
  SelectedSalariceManager:SalariesManagerModel[]=[];
  SalariesManagerList:SalariesManagerModel[]=[];
  BankAccountList:TreasuryAndBankAccountsModel[]=[];
  
    onMaterialGroupChangeSalariesManager(event) {
        this.SelectedSalariceManager = event;
      
      }
     
    loadServices() {
      this.currentAction = "loading";
      this.tableEngine.loadData();
    }
    goBack(Id) {
      this.router.navigate(["app/HR/Info-Salaries-Manager/", this.modelObject.SalariesManagerId]);
    }
    Save() {
      debugger;
      this.currentAction = "loading";
      this.tableEngine.savedPage = this.tableEngine.currentPage;
      var SavedObj = new SalariesPaymentModel();
      SavedObj.Id = this.Id;
      SavedObj.BankAccountId = this.mainForm.getRawValue().BankAccountId;
      SavedObj.PaymentDate = new Date(this.mainForm.getRawValue().PaymentDate);
      SavedObj.PaymentOfApproved = this.mainForm.getRawValue().PaymentOfApproved;
      SavedObj.PaymentOfSelected = this.mainForm.getRawValue().PaymentOfSelected;
      SavedObj.Amount = this.mainForm.getRawValue().Amount;
      SavedObj.Code = this.mainForm.getRawValue().Code;

      this.SelectedSalariceManager.forEach(element => {
        var cat = new SalariesManagerPayment();
        cat.SalariesManagerId = element.Id;
        cat.Id = 0;
        cat.SalariesPaymentId= this.Id;
        this.modelObject.SalariesManagerList.push(cat);
      });
      SavedObj.SalariesManagerId =  this.modelObject.SalariesManagerId;
      if (this.Id == 0) {
        this.modelService.insert(SavedObj).subscribe((data) => {
          this.router.navigate(["app/HR/Info-Salaries-Manager/",this.modelObject.SalariesManagerId]);
        });
      } else {
        debugger;
        this.modelService.update(this.modelObject).subscribe((data) => {
          this.router.navigate(["app/HR/Info-Salaries-Manager/",this.modelObject.SalariesManagerId]);
        });
      }
    }
}
