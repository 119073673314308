import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AttendanceSessionModel } from './models/attendance-session.model';
import { SessionLogsModel } from './models/session-logs.model';
@Injectable({
  providedIn: 'root'
})
export class AttendanceSessionService {

 
  constructor(private http:HttpClient ) { }

  
  baseUrl = environment.baseApiUrl;
  token = localStorage.getItem("token");
  httpOptions = {
   headers: new HttpHeaders({
     // 'Content-Type': 'application/json; charset=utf-8',
     // 'Authorization': "Bearer " + token,
      "Authorization": "Bearer " + this.token,
      "Content-Type": "application/json"
   })
 };

 getAll(): Observable<any> {
   
  return this.http
    .get<any>(`${this.baseUrl}AttendanceSession/GetAll`,this.httpOptions)
    .pipe(catchError((error: any) => Observable.throw(error => error)));
}

getById(Id:number): Observable<any> {

  return this.http
    .get<any>(`${this.baseUrl}AttendanceSession/GetById/${Id}`,this.httpOptions)
    .pipe(catchError((error: any) => Observable.throw(error => error)));
}
OpenNewSession(): Observable<any> {
  return this.http
    .get<any>(`${this.baseUrl}AttendanceSession/OpenNewSession`,this.httpOptions)
    .pipe(catchError((error: any) => Observable.throw(error => error)));
}

update(payload:  AttendanceSessionModel): Observable<any> {
  return this.http
    .post<any>(`${this.baseUrl}AttendanceSession/Update`, payload,this.httpOptions)
    .pipe(catchError((error: any) => Observable.throw(error => error)));
}
CheckInByAdmin(payload:  AttendanceSessionModel): Observable<any> {
  return this.http
    .post<any>(`${this.baseUrl}AttendanceSession/CheckInByAdmin`, payload,this.httpOptions)
    .pipe(catchError((error: any) => Observable.throw(error => error)));
}
RecordEmployee(payload:SessionLogsModel): Observable<any> {
  return this.http
    .post<any>(`${this.baseUrl}AttendanceSession/RecordEmployee`, payload,this.httpOptions)
    .pipe(catchError((error: any) => Observable.throw(error => error)));
}
CloseSession(payload:AttendanceSessionModel): Observable<any> {
  return this.http
    .post<any>(`${this.baseUrl}AttendanceSession/CloseSession`, payload,this.httpOptions)
    .pipe(catchError((error: any) => Observable.throw(error => error)));
}

delete(Id:number): Observable<any> {
  return this.http
    .get<any>(`${this.baseUrl}AttendanceSession/Delete/${Id}`,this.httpOptions)
    .pipe(catchError((error: any) => Observable.throw(error => error)));
}

  deleteList(payload:  AttendanceSessionModel []): Observable<any> {
    return this.http
      .post<any>(`${this.baseUrl}AttendanceSession/DeleteList`,JSON.stringify(payload),this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error => error)));
  }
  UploadExcel(formData: FormData) {
    let headers = new HttpHeaders();

    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    const httpOptions = { headers: headers };

    return this.http.post(`${this.baseUrl}AttendanceSession/UploadExcel` , formData, httpOptions)
  }
}
