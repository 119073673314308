import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ConfigPurchasesModel } from "./ConfigPurchasesModel";
import { ItemModel } from 'src/app/invoices/items/ItemModel';
import { PaymentMethodModel } from '../../purchases/purchase-orders/PaymentMethodModel';

@Injectable({
  providedIn: 'root'
})
export class ConfigPurchasesService {
    constructor(private http: HttpClient) {
  
    }

    debugData: ConfigPurchasesModel [] = []
    
    debugMode: boolean = false;

    baseUrl = environment.baseApiUrl;
    // baseUrl = 'http://localhost:60289/';
     token = localStorage.getItem("token");
     httpOptions = {
      headers: new HttpHeaders({
        // 'Content-Type': 'application/json; charset=utf-8',
        // 'Authorization': "Bearer " + token,
         "Authorization": "Bearer " + this.token,
         "Content-Type": "application/json"
      })
    };
    getFirst(): Observable<any> {
      if(this.debugMode){
        return of(this.debugData)
      }
      return this.http
        .get<any>(`${this.baseUrl}ConfigPurchases/GetFirst`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    update(payload: ConfigPurchasesModel): Observable<any> {
      if(this.debugMode){
        this.debugData[this.debugData.findIndex(x=>x.Id==payload.Id)] = payload;
        return of(true)
      }
      return this.http
        .post<any>(`${this.baseUrl}ConfigPurchases/Update`, payload,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    checkPurchaseOrderCode(code:number): Observable<any> {
      // if(this.debugMode){
      //   return of(this.debugData.find(x=>x.Code==code)?false:true)
      // }
      return this.http
        .get<any>(`${this.baseUrl}ConfigPurchases/CheckPurchaseOrderCode/${code}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    checkRequestReceiptCode(code:number): Observable<any> {
      return this.http
        .get<any>(`${this.baseUrl}ConfigPurchases/CheckRequestReceiptCode/${code}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    checkPurchaseInvoiceCode(code:number): Observable<any> {
      return this.http
        .get<any>(`${this.baseUrl}ConfigPurchases/CheckPurchaseInvoiceCode/${code}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    checkReturnPurchaseCode(code:number): Observable<any> {
      return this.http
        .get<any>(`${this.baseUrl}ConfigPurchases/CheckReturnPurchaseCode/${code}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    checkQuotationRequestCode(code:number): Observable<any> {
      return this.http
        .get<any>(`${this.baseUrl}ConfigPurchases/CheckQuotationRequestCode/${code}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    checkPurchaseQuotationCode(code:number): Observable<any> {
      return this.http
        .get<any>(`${this.baseUrl}ConfigPurchases/CheckPurchaseQuotationCode/${code}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
}
