<div class="row">
    <div class="col-12">
        <div class="card-body">
            <a type="button" [routerLink]="[ '../../Dailyrestrictions', obj.Id ]" class="btn btn-primary default mb-1">{{'Edit' | translate}}</a>{{" "}}
            <button type="button" (click)="print()" class="btn btn-secondary default mb-1">{{'Print' | translate}}</button>{{" "}}
            <button type="button" (click)="exportTable()" class="btn btn-success default mb-1">{{'PDF' | translate}}</button>{{" "}}
            <button type="button"  class="btn btn-primary default mb-1" style="margin: 1px;">{{'Excel' | translate}}</button>{{" "}}
          
        </div>
      <div class="display-options-container">
        <div class="separator mb-4"></div>
      </div>
    </div>
  </div>

<div class="FormScreen" class="tableScreen" id="contentToConvert">

    <div id="divmiddle" style="display: block; margin-top: 5px;">
        <div style="width: 100%;">
            <div>
                <div class="card" style="min-width: 900px !important;">
                    <div class="card-header">
                        <h1 style="margin-top:10px">{{'Daily entry'|translate}}#{{obj.Code}}</h1>
                        <h6>{{'Date'|translate}}:{{obj.Date|date:'dd/MM/yyyy'}}</h6>
                        <h6>{{'Description'|translate}}:{{obj.Description}}</h6>
                    </div>
                    <div class="card-body">
                        <table class="table table-bordered" id="customers">
                            <thead>
                                <tr style="background-color: #f8f8f8;">
                                    <th scope="col" colspan="2">{{'Account'|translate}}</th>
                                    <th scope="col">{{'Description'|translate}}</th>
                                    <th scope="col">{{'Debit'|translate}}</th>
                                    <th scope="col">{{'Credit'|translate}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of obj.Footer">
                                    <th>{{item.AccountNumber}}</th>
                                    <th>{{lang==='ar-EG'?item.AccountNameAr:item.AccountNameEn}}</th>
                                    <td>{{item.Description}}</td>
                                    <td>{{item.Debit}}</td>
                                    <td>{{item.Credit}}</td>
                                </tr>
                            
                                <tr style="background-color: #f8f8f8;">
                                   
                                    <td colspan="3">{{'Total'|translate}}</td>
                                    <td>{{obj.Totaldebit}}</td>
                                    <td>{{obj.Totalcredit}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div id="divbottom" style="display: block; height: 47px;">

        <div id="divsearchright" style="float: right;  width: 33.33%; padding: 0px;">&nbsp;</div>


        <div id="divsearchleft" style="float: left; width: 33.33%; height: 100%;">&nbsp;</div>

    </div>
</div>

<div class="card">
        
    <div class="card-body">
        
        <label for="inputEmail4">{{ 'Documents' | translate }}</label>
        <table class="table">
           <tbody>
            <tr *ngFor="let file of files;let i = index">
                <td>#{{i+1}}</td>
                <td><a href="{{file.FileUrl}}" target="_blank" style="cursor: pointer;" download="">{{"Download"|translate}}</a></td>
             
            </tr>
           </tbody>
        </table>
    </div>

</div>