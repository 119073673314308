import { Component, Renderer2, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { NotificationsService, NotificationType } from "angular2-notifications";
import { ModalDirective } from "ngx-bootstrap/modal";
import { PageChangedEvent } from "ngx-bootstrap/pagination";
import { TableEngine } from "src/app/invoices/TableEngine";
import { CurrencyService } from "src/app/invoices/currencies/CurrencyService";
import { SidePopupComponent } from "src/app/views/invoice/side-popup/side-popup.component";
import { SalariesManagerService } from "../salaries-manager.service";
import { SalarySlipModel } from "./SalarySlipModel";
import { EmployeesManagementModel } from "src/app/HR/Employees/EmployeesManagement/EmployeesManagementModel";
import { EmployeesManagementService } from "src/app/HR/Employees/EmployeesManagement/employees-management.service";
import { SalarySlipService } from "./salary-slip.service";
import { CurrencyModel } from "src/app/invoices/currencies/CurrencyModel";
import { SalaryItemsService } from "./salary-items.service";
import { SalaryItemsModel } from "./SalaryItemsModel";
import { SalarySlipDueModel } from "./SalarySlipDueModel";
import { SalarySlipDeductionModel } from "./SalarySlipDeductionModel";
@Component({
  // moduleId: module.id
  selector: "add-salary-slip",
  templateUrl: "add-salary-slip.component.html",
  styleUrls: ["add-salary-slip.component.scss"],
})
export class AddSalarySlipComponent {
  Id = 0;

  constructor(
    public router: Router,
    private fb: FormBuilder,
    private formBuilder: FormBuilder,
    private modelService: SalarySlipService,
    private CurrencyService: CurrencyService,
    private EmployeesService: EmployeesManagementService,
    private salaryItemsService: SalaryItemsService,
    private notifications: NotificationsService,

    private translate: TranslateService,
    private route: ActivatedRoute
  ) {
    this.Id = +route.snapshot.paramMap.get("Id");
  }
  showItemIndex = 0;
  tableEngine = new TableEngine(this.translate, this.notifications);
  currentScreen: any = "Table";
  translationName: string = "Item";
  sortableKeys = [];
  searchableKeys = ["Name", "Address"];
  tableView: any = true;

  SalarySlipModel: SalarySlipModel[] = [];
  isLastOpen = false;

  modelObject: SalarySlipModel = new SalarySlipModel();
  Form: FormGroup;
  declare lang: any;
  declare itemOrder: any;
  currentAction = "";
  savedPage = 0;
  currentPage = 1;
  deletionList: any[] = [];
  prevAction = "";
  addToListId = 0;
  ItemsBalanceHeaderId = 0;
  ItemBalanceId = 0;
  currentFormMode: string = "";
  type = 0;
  visable = "";
  visable2 = "";
  visable3 = "";

  Code = 0;

  AlertMessage: string = "Alert me when the quantity reaches less than";
  ogData: SalarySlipModel[] = [];
  mainForm: FormGroup = new FormGroup({
    Id: new FormControl(),
    dateOfRegistration: new FormControl(),
    StartingDate: new FormControl(),
    EndDate: new FormControl(),
    EmployeeId: new FormControl(),
    Code: new FormControl(),
    CurrencyId: new FormControl(),
    TotalSalary: new FormControl(),
    TotalDiscount: new FormControl(),
    NetSalary: new FormControl(),
    Statuse: new FormControl(),
  });

  salaryItems: SalaryItemsModel[] = [];
  duesItems: SalaryItemsModel[] = [];
  deducationsItems: SalaryItemsModel[] = [];
  conpanyId: number;

  @ViewChild("sideModalRef", { static: true })
  sideModalRef: SidePopupComponent = null;
  @ViewChild("delModal", { static: true }) delModal: ModalDirective;
  @ViewChild("smallModal", { static: true }) smallModal: ModalDirective = null;
  formPresentationModal: any;
  ngOnInit(): void {
    // this.getAllCountryCode();

    this.lang = localStorage.getItem("theme_lang");
    this.itemOrder = {
      label: "Name",
      property: this.lang === "ar-EG" ? "Name" : "Name",
    };
    this.sortableKeys = [
      // { label: 'Date', property: 'Date' },
      { label: "Name", property: this.lang === "ar-EG" ? "Name" : "Name" },
      {
        label: "Nickname",
        property: this.lang === "ar-EG" ? "Nickname" : "Nickname",
      },
      {
        label: "MobileNumber",
        property: this.lang === "ar-EG" ? "MobileNumber" : "MobileNumber",
      },
    ];

    this.tableEngine.loadTableEngine(
      this.ogData,
      this.modelService,
      this.modelObject,
      this.searchableKeys,
      this.sortableKeys,
      this.mainForm,
      this.translationName,
      this.itemOrder,
      this.sideModalRef,
      this.delModal,
      this.smallModal,
      this.lang
    );
    this.loadServices();
    this.EmployeesService.getAll().subscribe((data) => {
      this.EmployeesList = data;
    });
    this.CurrencyService.getAll().subscribe((data) => {
      this.CurrencyList = data;
    });
    this.salaryItemsService.getAll().subscribe((result) => {
      debugger;
      this.salaryItems = result;
      this.salaryItems.forEach((element) => {
        if (element.SalaryItemTypeNameEn == "Deduction") {
          this.deducationsItems.push(element);
        } else if (element.SalaryItemTypeNameEn == "Due") {
          this.duesItems.push(element);
        }
      });
    });

    if (this.Id > 0) {
      debugger;
      this.modelService.getById(this.Id).subscribe((data) => {
        this.modelObject = data;
        this.executeEdit();
        this.mainForm = this.createForm(this.modelObject);
      });
    } else {
        this.executeAdd();
      this.mainForm = this.createForm(this.modelObject);
    }
    this.mainForm = this.createForm(this.modelObject);
  }



  TotalSalary(){
    debugger;
        this.modelObject.TotalSalary = 0;
        this.addedDuesList.forEach(element => {
          this.modelObject.TotalSalary += element.Amount;
        });
    
       this.mainForm.controls['TotalSalary'].setValue(this.modelObject.TotalSalary)
   this.NetSalary();

}
TotalDiscount(){
    debugger;
    this.modelObject.TotalDiscount = 0;
    this.addedDeductionsList.forEach(element => {
      this.modelObject.TotalDiscount += element.Amount;
    });

   this.mainForm.controls['TotalDiscount'].setValue(this.modelObject.TotalDiscount)
   this.NetSalary();
}
NetSalary(){
    debugger;
    var Sub=0;
    var TotalDiscount = this.mainForm.controls['TotalDiscount'].value;
    var TotalSalary = this.mainForm.controls['TotalSalary'].value;
    this.modelObject.TotalDiscount = TotalSalary - TotalDiscount;
        this.mainForm.controls['TotalDiscount'].setValue(this.modelObject.TotalDiscount)
}
  getMonthList(
    locales?: string | string[],
    format: "long" | "short" = "long"
  ): string[] {
    const year = new Date().getFullYear(); // 2020
    const monthList = [...Array(12).keys()]; // [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
    const formatter = new Intl.DateTimeFormat(locales, {
      month: format,
    });

    const getMonthName = (monthIndex: number) =>
      formatter.format(new Date(year, monthIndex));

    return monthList.map(getMonthName);
  }



  createForm(modelObject): FormGroup {
    debugger;
    return this.fb.group({
      Id: [modelObject.Id >= 0 ? modelObject.Id : 0],
      EmployeeId: [modelObject.Name, Validators.compose([Validators.required])],
      dateOfRegistration: [
        this.modelObject.dateOfRegistration != null
          ? new Date(this.modelObject.dateOfRegistration)
              .toISOString()
              .slice(0, 10)
          : null,
        Validators.compose([Validators.required]),
      ],
      StartingDate: [
        this.modelObject.StartingDate != null
          ? new Date(this.modelObject.StartingDate).toISOString().slice(0, 10)
          : null,
        Validators.compose([Validators.required]),
      ],
      EndDate: [
        this.modelObject.EndDate != null
          ? new Date(this.modelObject.EndDate).toISOString().slice(0, 10)
          : null,
        Validators.compose([Validators.required]),
      ],
      Code: [modelObject.Code],
      CurrencyId: [modelObject.CurrencyId],
      TotalSalary: [modelObject.TotalSalary],
      TotalDiscount: [modelObject.TotalDiscount],
      NetSalary: [modelObject.NetSalary],
      Statuse: [modelObject.Statuse],
    });
  }

  createDueForm(model): FormGroup {
    return this.formBuilder.group({
      Id: [model.Id],
      ContractId: [model.ContractId],
      SalaryItemId: [model.SalaryItemId, Validators.required],
      Amount: [model.Amount, Validators.required],
    });
  }
  addDeductions() {
    debugger;
    var detail = this.DeductionsForm.getRawValue();
    detail.ContractId = this.Id;
    detail.SalaryItemId = +detail.SalaryItemId;
    var itemIndex = this.deducationsItems.findIndex(
      (x) => x.Id == detail.SalaryItemId
    );
    if (itemIndex > -1) {
      detail.SalaryItemName = this.deducationsItems[itemIndex].ItemName;
    }
    this.addedDeductionsList.push(detail);
    this.salaryDeductionsModel = new SalarySlipDeductionModel();
    this.DeductionsForm = this.createDueForm(this.salaryDeductionsModel);
    this.TotalDiscount();
  }
  removeDeductions(obj) {
    var deletedIndex = this.addedDeductionsList.indexOf(obj);
    if (deletedIndex > -1) {
      this.addedDeductionsList.splice(deletedIndex, 1);
      this.TotalDiscount();
    }
  }
  addDue() {
    debugger;
    var detail = this.dueForm.getRawValue();
    detail.ContractId = this.Id;
    detail.SalaryItemId = +detail.SalaryItemId;
    var itemIndex = this.duesItems.findIndex(
      (x) => x.Id == detail.SalaryItemId
    );
    if (itemIndex > -1) {
      detail.SalaryItemName = this.duesItems[itemIndex].ItemName;
    }
    this.addedDuesList.push(detail);
    this.salaryDueModel = new SalarySlipDueModel();
    this.dueForm = this.createDueForm(this.salaryDueModel);
    this.TotalSalary();
  }
  removeDue(obj) {
    var deletedIndex = this.addedDuesList.indexOf(obj);
    if (deletedIndex > -1) {
      this.addedDuesList.splice(deletedIndex, 1);
    this.TotalSalary();

    }
  }
  addedDuesList: SalarySlipDueModel[] = [];
  salaryDueModel: SalarySlipDueModel = {};
  addedDeductionsList: SalarySlipDeductionModel[] = [];
  salaryDeductionsModel: SalarySlipDeductionModel = {};
  dueForm: FormGroup;
  DeductionsForm: FormGroup;
  SelectedEmployeesChoice: EmployeesManagementModel[] = [];
  SelectedEmployeesExcluded: EmployeesManagementModel[] = [];
  EmployeesList: EmployeesManagementModel[] = [];
  CurrencyList: CurrencyModel[] = [];

  currentTotal = 0;


  executeAdd(): void {
    debugger;
 
  this.modelObject = new SalarySlipModel();
//   this.mainForm = this.createForm();
  this.currentFormMode = 'Add'
  this.currentScreen = 'Add';
  this.modelService.getLastNumber()
  .subscribe(code=>{
    this.modelObject.Code = code+1
    this.mainForm.controls['Code'].setValue(this.modelObject.Code);
  })
  this.addedDeductionsList = [];
  this.addedDuesList=[];
  this.salaryDueModel = new SalarySlipDueModel();
  this.dueForm = this.createDueForm(this.salaryDueModel);
  this.salaryDeductionsModel = new SalarySlipDeductionModel();
  this.DeductionsForm = this.createDueForm(this.salaryDeductionsModel);
}
 
executeEdit(): void {
  
  
  this.addedDeductionsList = this.modelObject.SalarySlipDeductionList;
  this.addedDuesList=this.modelObject.SalarySlipDueList;
  this.salaryDueModel = new SalarySlipDueModel();
  this.dueForm = this.createDueForm(this.salaryDueModel);
  this.salaryDeductionsModel = new SalarySlipDeductionModel();
  this.DeductionsForm = this.createDueForm(this.salaryDeductionsModel);
}
  loadServices() {
    this.currentAction = "loading";
    this.tableEngine.loadData();
  }
  goBack() {
    this.router.navigate(["app/HR/List-Salaries-Slip"]);
  }
  Save() {
    debugger;
    this.currentAction = "loading";
    this.tableEngine.savedPage = this.tableEngine.currentPage;
    this.modelObject = new SalarySlipModel();
    this.modelObject.Id = 0;
    this.modelObject.Code = this.mainForm.getRawValue().Code;
    this.modelObject.CurrencyId = this.mainForm.getRawValue().CurrencyId;
    this.modelObject.EmployeeId = this.mainForm.getRawValue().EmployeeId;
    this.modelObject.NetSalary = this.mainForm.getRawValue().NetSalary;
    this.modelObject.Statuse = this.mainForm.getRawValue().Statuse;
    this.modelObject.TotalDiscount = this.mainForm.getRawValue().TotalDiscount;
    this.modelObject.TotalSalary = this.mainForm.getRawValue().TotalSalary;
    this.modelObject.SalarySlipDeductionList = this.addedDeductionsList;
    this.modelObject.SalarySlipDueList = this.addedDuesList;
    this.modelObject.StartingDate = new Date(
      this.mainForm.getRawValue().StartingDate
    );
    this.modelObject.dateOfRegistration = new Date(
      this.mainForm.getRawValue().dateOfRegistration
    );
    this.modelObject.EndDate = new Date(this.mainForm.getRawValue().EndDate);
    if (this.Id == 0) {
      this.modelService.insert(this.modelObject).subscribe((data) => {
        this.router.navigate(["app/HR/List-Salaries-Slip"]);
      });
    } else {
      debugger;
      this.modelService.update(this.modelObject).subscribe((data) => {
        this.router.navigate(["app/HR/List-Salaries-Slip"]);
      });
    }
  }
}
