export class DepreciationOfAssetsModel{
    Id?:number;
    AssetId?:number;
    Date?:Date|null;
    Amount?:number;
    Lang?:string;
    IsAuto?:boolean;
    IsPayed?:boolean;
    constructor(item ?: DepreciationOfAssetsModel){
        item  = item ? item : {};
        this.Id = item.Id ? item.Id : 0;
        this.Amount = item.Amount ? item.Amount : 0;
        this.AssetId = item.AssetId ? item.AssetId : 0;
        this.Amount = item.Amount ? item.Amount : 0;
        this.Lang = item.Lang ? item.Lang : "";
        this.IsAuto = item.IsAuto ? item.IsAuto : false;
        this.IsPayed = item.IsPayed ? item.IsPayed : true;
    }
}