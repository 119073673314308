
  <div class="row">
    <div class="col-12">
      <div class="card mb-4">
        <div class="card-body">
          <!-- <h5 class="mb-4">{{ 'From Date' | translate }}</h5> -->
          <div class="row">
            <div class="col-12 col-lg-6 mb-4">
              <label><span>{{ 'DateFrom' | translate }}</span></label>
              <input 
              type="text" onfocus="(this.type='date')" onblur="if(!this.value)this.type='text'"
              onchange="if(!this.value)this.type='text'" name="Date" class="form-control"
              [(ngModel)]="search.From"
              placeholder='{{"DateFrom" | translate}}'>
            </div>
            <div class="col-12 col-lg-6 mb-4">
              <label><span>{{ 'DateTo' | translate }}</span></label>
              <input 
              type="text" onfocus="(this.type='date')" onblur="if(!this.value)this.type='text'"
              onchange="if(!this.value)this.type='text'" name="Date" class="form-control"
              [(ngModel)]="search.To"
              placeholder='{{"DateTo" | translate}}'>
            </div>
            <div class="col-12 col-lg-6 mb-4 "  >
              <button type="button" (click)="Search()" class="btn btn-primary default mb-1" style="margin: 1px;">{{'Search' | translate}}</button>{{" "}}
              <button type="button" (click)="print()" class="btn btn-secondary default mb-1 " style="margin: 1px;">{{'Print' | translate}}</button>{{" "}}
              <button type="button" (click)="exportTable()" class="btn btn-success default mb-1" style="margin: 1px;">{{'PDF' | translate}}</button>{{" "}}
              <button type="button"  class="btn btn-primary default mb-1" style="margin: 1px;">{{'Excel' | translate}}</button>{{" "}}
            
            </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  <div id="contentToConvert">
  <div class="row" >
    <div class="col-12">
      <div class="card mb-4">
        <div class="card-body">
            <h3 class="float-center" style="text-align: center;">{{ 'Income List' | translate }}</h3>
            <h4 class="float-center" style="text-align: center;" *ngIf="search.From"> {{'From'|translate}} : {{  search.From| date:'dd/MM/yyyy' }}</h4>
            <h4 class="float-center" style="text-align: center;" *ngIf="search.To"> {{'To'|translate}} : {{  search.To| date:'dd/MM/yyyy' }}</h4>
            <h6 class="float-center" style="text-align: center;">{{ 'Future Core' | translate }}</h6>
           
        </div>
      </div>
    </div>
  </div>


<div class="card mb-12 tree-explorer-side col-md-12" *ngIf="data">
    <div class="card-body ">
    <div class="row">
        <table class="table">
            <thead>
                <th>{{'Name'|translate}}</th>
                <th>{{'Total'|translate}}</th>
            </thead>
          
        </table>
        <table class="table table-striped">
            <thead>
                <th>{{'Incomes'|translate}}</th>
                <th>{{'Value'|translate}}</th>
            </thead>
            <tbody>
                <tr *ngFor="let item of data.Incomes">
                    <td>{{lang==='ar-EG'?item.KeyAr:item.KeyEn}}</td>
                    <td>{{item.Value}}</td>
                </tr>
                <thead>
                  <th>{{'Total'|translate}}</th>
                  <th>{{totalIncomes}}</th>
              </thead>
            </tbody>
        </table>
    
    </div>
    <div class="row">
     
        <table class="table table-striped">
            <thead>
                <th>{{'Expenses'|translate}}</th>
                <th>{{'Value'|translate}}</th>

            </thead>
            <tbody>
                <tr *ngFor="let item of data.Expenses">
                    <td>{{lang==='ar-EG'?item.KeyAr:item.KeyEn}}</td>
                    <td>{{item.Value}}</td>
                </tr>
                <thead>
                  <th>{{'Total'|translate}}</th>
                  <th>{{totalOutComes}}</th>
              </thead>
            </tbody>
        </table>
    
    </div>
    <div class="row">
     
      <table class="table table-striped">
          <!-- <thead>
            <tr>
              <th>{{'Net Profit'|translate}}</th>
              <th>{{'Value'|translate}}</th>
            </tr>
          </thead> -->
          <tbody>
          
              <tr>
                <th>{{'Net Profit'|translate}}</th>
                <th>{{totalIncomes - totalOutComes}}</th>
            </tr>
          </tbody>
      </table>
  
  </div>
    </div>
  </div>
</div>
