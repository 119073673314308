import { saveAs } from 'file-saver';
import { SearchModel } from './../../models/search.model';
import { InvoiceReportService } from './../../services/invoice-report.service';
import { Component, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { InvoiceReportModel } from '../../models/invoice-report.model';
import { DataFilterer } from 'src/app/views/app/aio/shared/DataFilterer';
import { DataSorter } from 'src/app/views/app/aio/shared/DataSorter';
import { DataPaginator } from 'src/app/views/app/aio/shared/DataPaginator';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { environment } from 'src/environments/environment';
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';
@Component({
    selector: 'invoice-report',
    templateUrl: 'invoice-report.component.html',
    styleUrls: ['invoice-report.component.scss']
})
export class InvoiceReportComponent {
    constructor(private formBuilder: FormBuilder, private modelService: InvoiceReportService, private renderer: Renderer2,
        private notifications: NotificationsService, private translate: TranslateService) {
      }
      currentScreen: any = 'Table';
    
      translationName: string = 'Invoice'
    
      sortableKeys = [];
      searchableKeys = ['Id'];
      companyNameAr = localStorage.getItem("CompanyNameAr")
      companyNameEn = localStorage.getItem("CompanyNameEn")
      modelObject: SearchModel = new SearchModel();
      ogData: InvoiceReportModel [] = [];
      mainForm: FormGroup = new FormGroup({
        From: new FormControl(),
        To: new FormControl(),
      });
    
      ngOnInit(): void {
        console.log("model");
        console.log(this.modelObject);
        
        
        this.lang = localStorage.getItem('theme_lang');
        this.itemOrder = { label: 'Id', property: 'Id' };
        this.sortableKeys = [
          { label: 'Id', property: 'Id' },
        ];
        // this.loadData();
        // console.log(this.ogData);
      }
    
    
      lang: string;
      currentFormMode: string = '';
      currentAction: string = '';
    
    
      //ogData = [];
      filteredData = [...this.ogData];
      sortedData = [...this.filteredData];
      paginatedData = [...this.filteredData];
      lastId: number = this.ogData.length + 1;
    
      SelectAllBox: any = false;
    
      itemsPerPage = 10;
      itemOptionsPerPage = [5, 10, 20];
      selected = [];
      selectAllState = '';
      itemOrder: any;
      orderAsc: boolean = true;
    
      currentPage = 1;
      savedPage = 0;
    
    
      dataFilterer = new DataFilterer();
      dataSorter = new DataSorter();
      dataPaginator = new DataPaginator();
    
      loadData() {
        debugger;
        this.currentAction = 'loading';
        this.modelObject = this.mainForm.getRawValue();
        this.modelService.getAll(this.modelObject).subscribe(
          data => {
            console.log("data:");
            console.log(data);
            
            this.ogData = data;
            this.sortData();
          }
        )
        
      }
    
      sortData() {
        this.sortedData = this.dataSorter.sortData(this.itemOrder.property, this.ogData);
        this.filterData();
      }
    
      reverseSortData() {
        this.savedPage = this.currentPage;
        this.sortedData = this.sortedData.slice().reverse();
        this.filterData();
      }
    
      filterData() {
        debugger;
        this.filteredData = this.dataFilterer.filterData(this.SearchTerm, this.searchableKeys, this.sortedData)
        if (this.savedPage === 0) {
          this.paginateData(this.itemsPerPage, 1);
        }
        else {
          this.paginateData(this.itemsPerPage,
            Math.ceil((this.filteredData.length / this.itemsPerPage)) >= this.savedPage ?
              this.savedPage :
              Math.ceil((this.filteredData.length / this.itemsPerPage))
          );
        }
        this.savedPage = 0;
      }
    
      paginateData(newItemsPerPage: number, newCurrentPage: number) {
        debugger;
    
        this.itemsPerPage = newItemsPerPage;
        this.currentPage = newCurrentPage;
        this.paginatedData = this.filteredData.slice((newCurrentPage - 1) * (newItemsPerPage), (newItemsPerPage * newCurrentPage));
      }
    
      changeSort(newSort: any) {
        if (this.itemOrder.label === newSort.label) {
          this.orderAsc = !this.orderAsc;
          const oldPage = this.currentPage;
          this.reverseSortData()
          this.currentPage = oldPage;
        }
        else {
          this.orderAsc = true;
          this.itemOrder = newSort;
          this.sortData();
        }
      }
    
      getSort(label: any) {
        return this.sortableKeys.find(i => i.label === label);
      }
    
      changePage(event: PageChangedEvent): void {
        this.currentPage = event.page;
        this.paginateData(this.itemsPerPage, this.currentPage);
      }
    
      changeFilter() {
        this.deletionList = [];
        this.selected = [];
        this.selectAllState = '';
        this.filterData();
      }
    
      selectAll($event): void {
        if ($event.target.checked) {
          this.selected = [...this.filteredData];
        } else {
          this.selected = [];
        }
        this.setSelectAllState();
      }
    
      setSelectAllState(): void {
        if (this.selected.length >= this.filteredData.length) {
          this.selectAllState = 'checked';
        } else if (this.selected.length !== 0) {
          this.selectAllState = 'indeterminate';
        } else  {
          this.selectAllState = '';
        }
      }
    
      isSelected(p: InvoiceReportModel): boolean {
        return this.selected.findIndex(x => x.Id === p.Id) > -1;
      }
    
      onSelect(item: InvoiceReportModel): void {
        if (this.isSelected(item)) {
          this.selected = this.selected.filter(x => x.Id !== item.Id);
        } else {
          this.selected.push(item);
        }
        this.setSelectAllState();
      }
    
      findByCode(list: any, code: any): any {
        return list.find(x => x.Code === code)
      }
    
      @ViewChild('delModal') delModal: ModalDirective;
      showAddNewModalRef(): void {
        //IMPORTANT
        //REDIRECT TO ADD PAGE
      }
    
      showInfoModalRef(Id: any): void {
        //IMPORTANT
        //REDIRECT TO INFO PAGE
      }
    
      deletionList: InvoiceReportModel[] = [];
     
    
      SearchTerm: any = '';
    
    
      finalizeDeletion(){
        if (this.deletionList.length === 1) {
          this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyDeleted") + " " +
            this.translate.instant("One" + this.translationName));
        }
        else if (this.deletionList.length === 2) {
          this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyDeleted") + " " +
            this.translate.instant("Two" + this.translationName + "s"));
        }
        else if (this.deletionList.length > 2 && this.deletionList.length < 11) {
          this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyDeleted") + " " +
            this.deletionList.length + " " + this.translate.instant(this.translationName + "RowsPlur"));
        }
        else {
          this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyDeleted") + " " +
            this.deletionList.length + " " + this.translate.instant(this.translationName + "Rows"));
        }
    
        this.deletionList = [];
        this.selected = [];
    
        this.setSelectAllState();
        if (this.selectAllState === '') {
          this.SelectAllBox = false;
        }
        this.delModal.hide();
      }
    
      toastSuccess(toastHeader: string, toastBody: string): void {
        this.notifications.create(toastHeader, toastBody,
          NotificationType.Success, { timeOut: 3000, showProgressBar: true });
      }
      toastError(toastHeader: string, toastBody: string): void {
        this.notifications.create(toastHeader, toastBody,
          NotificationType.Error, { timeOut: 3000, showProgressBar: true });
      }
      deleteMultiTaxTypes() {
        this.loadData();
        this.delModal.hide();
      }
    
    //   loadAllData() {
    //     this.modelService.GetPurchases().subscribe(
    //       data => {
    //         this.ogData = data;
    //         this.loadSearch();
    //       }
    //     )
    //   }
    
      loadSearch() {
        const prevSearch = this.SearchTerm;
        this.SearchTerm = '';
        setTimeout(() => {
          this.SearchTerm = prevSearch;
        }, 1);
      }
      print(Id){
        debugger;
      this.modelService.printInvoice(Id)
      .subscribe(result =>{
        console.log(result);
        saveAs(`${environment.apiUrl}/Resources/Invoices/${result}`, 'report.pdf');
        // window.open(`${environment.apiUrl}/Resources/Invoices/${result}`, '_blank');
      //  window.location.href =result
      //   window.open(result, '_blank');
      //   window.open(result);
      })
      }
      openForExport = false;
      exportTable(){
        debugger;
        this.openForExport = true;
        var data = document.getElementById('contentToConvert');
        data.style.display = 'inline-block';
        html2canvas(data,{}).then(canvas => {
        var imgWidth = 208;
        var pageHeight = 295;
        var imgHeight = canvas.height * imgWidth / canvas.width;
        var heightLeft = imgHeight;
        const contentDataURL = canvas.toDataURL('image/png')
        let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
        var position = 0;
        pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
        pdf.save('new-file.pdf'); // Generated PDF
        data.style.display = 'none';
        
      });
      }
}


