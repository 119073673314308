export class ItemsStoreBellModel{
    Id?: number;
    ItemId?:number;
    StoreBillId?:number;
    SalePrice?:number;
    Quantity?:number;
    AdjustedQuantity?:number;
    Total?:number;
    Name?:string;

 
    constructor(item?:ItemsStoreBellModel){
        item = item? item:{};
        this.Id = item.Id? item.Id:0;
        this.ItemId = item.ItemId? item.ItemId:0;
        this.StoreBillId = item.StoreBillId? item.StoreBillId:0;     
        this.SalePrice = item.SalePrice? item.SalePrice:0;     
        this.Quantity = item.Quantity? item.Quantity:0;     
        this.AdjustedQuantity = item.AdjustedQuantity? item.AdjustedQuantity:0;     
        this.Total = item.Total? item.Total:0;     

    }

}