import { Component, OnInit } from '@angular/core';
import { EstimatedinventoryValueDataModel } from '../../models/estimatedinventory-value-data.model';
import { EstimatedinventoryValueSearchModel } from '../../models/estimatedinventory-value-search.model';
import { EstimatedinventoryValueService } from '../../estimatedinventory-value.service';
import { CategoryServiceService } from 'src/app/Invintory/warehouses/category-service.service';
import { BrandServiceService } from 'src/app/Invintory/warehouses/brand-service.service';
import { WarehouseService } from 'src/app/Invintory/warehouses/warehouse-service.service';
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';


@Component({
    selector: 'estimatedinventory-value',
    templateUrl: 'estimatedinventory-value.component.html',
    styleUrls: ['estimatedinventory-value.component.scss']
})
export class EstimatedinventoryValueComponent implements OnInit{
    declare lang: any;
    today:Date=new Date();
    list:EstimatedinventoryValueDataModel[]=[];
    liabilities:EstimatedinventoryValueDataModel[]=[];
    PropertyRights:EstimatedinventoryValueDataModel[]=[];
    employees=[];
    nodeList:EstimatedinventoryValueDataModel[]=[];
    categories=[];
    brnads=[];
    warehouses=[];
    constructor(private EstimatedinventoryValueService:EstimatedinventoryValueService,
      private categoryService:CategoryServiceService,
      private brandService:BrandServiceService,
      private warehouseService:WarehouseService
      ){
      this.lang = localStorage.getItem('theme_lang');
    }
    TotalAssets = 0;
    Totalliabilities = 0;
    TotalPropertyRights = 0;
    data:EstimatedinventoryValueDataModel[]=[];
    assets:EstimatedinventoryValueDataModel[]=[];
    search:EstimatedinventoryValueSearchModel={};
    ngOnInit(): void {
        this.EstimatedinventoryValueService.search(this.search)
        .subscribe(data => {
          this.data = data;
          this.assets = data;
          this.categoryService.getAll()
          .subscribe(categories=>{
            this.categories = categories;
          })
          this.brandService.getAll()
          .subscribe(brands => {
            this.brnads = brands;
          })
          this.warehouseService.getAll()
          .subscribe(warehouses=>{
            this.warehouses = warehouses;
          })
        })
    
    }
    Search(){
      this.EstimatedinventoryValueService.search(this.search)
        .subscribe(data => {
          this.data = data;
        })
    }
    exportTable(){
      var data = document.getElementById('contentToConvert');
      data.style.display = 'inline-block';
      html2canvas(data,{}).then(canvas => {
      var imgWidth = 208;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save('new-file.pdf'); // Generated PDF
      data.style.display = 'block';

    //   data.style.display = 'none';
      
    });
    }
    print(){
        let printContents, popupWin;
        printContents = document.getElementById('contentToConvert').innerHTML;
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.open();
        window.print();
       
      }
}
