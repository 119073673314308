<!--component html goes here -->
<div class="md-float-material form-material" >

    <div style="text-align: center;">
        <button class="btn btn-light" (click)="goBack()"
            style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Back"  | translate}}</button>
        <button
            id="smallModalSubmitButton" class="btn btn-secondary" (click)="Save()"
            style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Add"  | translate}}</button>
        
    </div>
    <!-- <div class="card" style="margin-bottom: 30px;">
        <div class="form-row">
            <div class="form-group col-md-4">
              <div style="margin: 20px;">
                <br>
                <h5>{{'Profile Image'|translate}}</h5>
                <br>
                <upload-membership-profile [(url)]=picurl [editmode]=editEnabled></upload-membership-profile>
           </div>
            </div>
            <div class="form-group col-md-4">
                <div style="margin: 20px;">
                    <br>
                    <h5>{{'National Id'|translate}}</h5>
                    <br>
                    <upload-nationalid-image [(url)]=NationalIdPicurl [editmode]=NationalIdEditEnabled></upload-nationalid-image>
               </div>
            </div>
            <div class="form-group col-md-4">
                <div style="margin: 20px;">
                    <br>
                    <h5>{{'Graduation document'|translate}}</h5>
                    <br>
                    <upload-graduation-document [(url)]=graduationPicurl [editmode]=graduationEditEnabled></upload-graduation-document>
               </div>
            </div>
       
        </div>
     
    
    </div> -->
    <form *ngIf="Form" [formGroup]="Form">
        <div class="form-group">
            <br>
            <h3 class="center" style="text-align: center;">{{ 'Basic Data' | translate }}</h3>
            <br>
      
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label >{{'ArabicName'| translate}}</label>
                    <input formControlName="ArabicName" type="text" class="form-control">
                </div>
                <div class="form-group col-md-6">
                    <label for="inputPassword4">{{'English Name'| translate}}</label>
                    <input formControlName="EnglishName" type="text" id="inputPassword4" class="form-control" >
                </div>
            </div>
 
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label for="inputPassword4">{{'Members'| translate}}</label>
                    <select
                       id="inputState"
                       class="form-control"
                       formControlName="MemberId"
                     >
                      <option  [value]="'MemberId'">
                       {{ "Mohamed Sayed" | translate }}
                      </option>
                      <option  [value]="'Single'">
                        {{ "Mohamed Nouh" | translate }}
                       </option>
                       <option  [value]="'Single'">
                        {{ "Ahmed Abd-elazim" | translate }}
                       </option>
                    </select>
          
                     </div>
                     <div class="form-group col-md-3">
                        <button
                        id="smallModalSubmitButton" class="btn btn-primary" (click)="Save()"
                        style="font-weight: bold; ; text-align: center; margin-top: 10%;">{{"Add"  | translate}}</button>
                    
                    </div>
            </div>
            <div class="card" style="margin-bottom: 30px;">
                <div class="form-row">
                    <table class=" table">
                        <thead>
                           <th>{{'Member Name'|translate}}</th> 
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of famly">
                                <td>{{item.Name}}</td>
                            </tr>
                        </tbody>
                    </table>
               
                </div>
            </div>
                <div class="form-row">
                         <div class="form-group col-md-12">
                            <label >{{'Nots'| translate}}</label>
                            <textarea formControlName="Nots" type="text" class="form-control"></textarea>
                        </div>
                    </div>
            </div>
            <!-- <div class="form-group">
                <br >
                <h3 style="background-color: darkgrey;" class="center" style="text-align: center;">{{ 'Procedures' | translate }}</h3>
                <br>
                <div class="form-row">
                    <div class="form-group col-md-4" style="margin-top: 30px; text-align: center;">
                        <mat-radio-group formControlName="IsBlocked" class="custom-control custom-radio">
                            <mat-radio-button  [value]="true">{{'Blocked'|translate}} </mat-radio-button>
                            <mat-radio-button  [value]="false">{{'Activate'|translate}}</mat-radio-button>
                     </mat-radio-group>
                    </div>
                    <div class="form-group col-md-4">
                       
                    </div>
                </div>
                <div class="form-row" *ngIf="Form.controls['IsBlocked'].value == true">
                    <div class="form-group col-md-12">
                        <label for="inputPassword4">{{'Cause'| translate}}</label>
                        <textarea formControlName="Cause" type="text"  class="form-control" ></textarea>
                    </div>
                </div>
            
                </div> -->
   
       </form>

<br>

<div style="text-align: center;">
    <button class="btn btn-light" (click)="goBack()"
        style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Back"  | translate}}</button>
    <button
        id="smallModalSubmitButton" class="btn btn-secondary" (click)="Save()"
        style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Add"  | translate}}</button>
    
</div>
</div>


