export class DataFilterer{
    constructor(){
        //console.log('data presenter loaded');

    }
    OnInit(){
        //console.log('data presenter used');
    }
    filterData(searchTerm:any, searchKeys:any[], ogData:any[]){
        let objectKeys : any [] = [];
        let result : any [] = [];
        for (let index = 0; index < searchKeys.length; index++) {
            objectKeys.push(searchKeys[index] as keyof typeof ogData[0]);
            // console.log(objectKeys[index]);
            
        }
        //console.log(objectKeys);
        for (let dataIndex = 0; dataIndex < ogData.length; dataIndex++) {
            
            for (let keyIndex = 0; keyIndex < objectKeys.length; keyIndex++) {
                
                if (result.includes(ogData[dataIndex]))
                {
                    //console.log(ogData[dataIndex].NameAr +  " is a duplicate. Ignored.");
                    break;
                }
                else
                {
                    // console.log("TEST1");
                    // console.log(ogData[dataIndex]);
                    // console.log(objectKeys[keyIndex]);
                    // console.log(ogData[dataIndex][objectKeys[keyIndex]]);
                    // console.log(ogData[dataIndex][objectKeys[keyIndex]].toString().toLowerCase());
                    if (ogData[dataIndex][objectKeys[keyIndex]] && ogData[dataIndex][objectKeys[keyIndex]]?.toString().toLowerCase().includes(searchTerm.toString().toLowerCase()))
                    {
                        result.push(ogData[dataIndex])
                    }
                }
            }
        }
        //console.log(result);
        return result;
    }

    filterByDateRange(From:Date, To:Date, dateKeyName:string, ogData:any[]){
        let result : any [] = [...ogData];
        let key = dateKeyName as keyof typeof ogData[0]
        let fromDate = new Date(From);
        let toDate = new Date(To);

        //console.log("from",fromDate);
       // console.log("to",toDate.getTime());
        //console.log(ogData[0][key]);

            
        
        result = result.filter((object: any) => {
            
            return (new Date(object[key])).getTime() >= fromDate.getTime() &&
                   (new Date(object[key])).getTime() <= toDate.getTime();
        });
        return result;
    }
}