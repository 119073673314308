
export class PurchaseOrderItemModel {
    Id?: number;
    Code?: number;
    PurchaseOrderId ?: number;    //PurchaseOrderId
    PurchaseOrderCode ?: number;
    ItemId?: number;     //ItemId
    ItemNameAr ?: string;
    ItemNameEn ?: string;
    UnitId?: number;
    UnitNameAr ?: string;
    UnitNameEn ?: string;
    Quantity?: number;
    UnitCost?: number;
    TotalPrice?: number;     //TotalValue

    constructor(obj?: PurchaseOrderItemModel) {
        obj = obj ? obj : {};
        this.Id = obj.Id ? obj.Id : 0;
        this.Code = obj.Code ? obj.Code : 0;
        this.PurchaseOrderId  = obj.PurchaseOrderId  ? obj.PurchaseOrderId  : 0;
        this.PurchaseOrderCode  = obj.PurchaseOrderCode  ? obj.PurchaseOrderCode  : 0;
        this.ItemId  = obj.ItemId  ? obj.ItemId  : null;
        this.ItemNameAr  = obj.ItemNameAr  ? obj.ItemNameAr  : "";
        this.ItemNameEn  = obj.ItemNameEn  ? obj.ItemNameEn  : "";
        this.UnitId   = obj.UnitId   ? obj.UnitId   : null;
        this.UnitNameAr  = obj.UnitNameAr  ? obj.UnitNameAr  : "";
        this.UnitNameEn  = obj.UnitNameEn  ? obj.UnitNameEn  : "";
        this.Quantity   = obj.Quantity   ? obj.Quantity   : null;
        this.UnitCost = obj.UnitCost ? obj.UnitCost : null;
        this.TotalPrice  = obj.TotalPrice  ? obj.TotalPrice  : 0;
    }

}