<!--component html goes here -->
<!--component html goes here -->
<div class="row mb-12">
    <div class="col-md-12 col-lg-12 col-12 mb-12">
      <div class="card"></div>
    </div>
  </div>
  <div class="row" *ngIf="mainForm">
    <div class="col-12">
      <div class="card mb-4">
        <div class="card-body">
          <h2>
           
            <Strong style="font-size: 22px; margin-left:10px ;"> {{modelObject.Name}}</Strong>
            <span style="margin-left:10px ;">{{modelObject.SkuNumber}}#</span>
            <span class="badge badge-pill badge-info mb-1 " style="background-color: #ffc107;" *ngIf="modelObject.Alert>currentTotal&&modelObject.Alert!=0">{{'LowStock' | translate}}</span>
            <span class="badge badge-pill badge-danger mb-1 " *ngIf="currentTotal==0">{{'Notavailable' | translate}}</span>
            <span class="badge badge-pill badge-info mb-1" *ngIf="modelObject.Alert<=currentTotal&&modelObject.Alert!=0"> {{'available' | translate}}</span>
             
             <!-- <strong style="margin-left:10px" >  
              </strong> -->
        
          <div class="top-right-button-container text-zero float-right">
            <button
              class="top-right-button btn btn-primary btn-lg mr-1"
              (click)="goBack()"
            >
              {{ "Back" | translate }}
            </button>
            <button
              type="button"
              (click)=" showModal(Id, 'Edit')"
              [disabled]="mainForm.invalid"
              class="top-right-button btn btn-primary btn-lg mr-1"
            >
              {{ "Edit" | translate }}
            </button>
          </div>
        </h2>
        </div>
      </div>
    </div>
  </div>
  <div></div>
  <div class="card"*ngIf="modelObject" >
    <tabset class="card-tabs">
    <tab heading="{{'Information' | translate}}" class="card-body" >    
        <div class="modal-body">
                <div class="row"  >
                  <div class="value_highlight col-md-2 ">
                    <h5 for="inputState" style="color: #0189DE;font-size: 14px;">{{ "StockQuantity" | translate }}</h5>
                  <label  type="text"  style=" font-size: x-large;" name="Quantity">{{currentTotal}}</label>
                  <div >
                    <table>
                      <thead>
                        <tr  *ngFor="let Warhouse of modelObject.WarhouseItem">
                          <label > {{ Warhouse.WarehouseName }} : {{ Warhouse.Quantity }} </label> 
                        </tr>
                      </thead>
                    </table>
                    
                  </div>
         
                </div>
                <div class="vl"></div>
                <div class="value_highlight col-md-2 ">
                  <h5 for="inputState" style="color: #0189DE;font-size: 14px;">{{ "TotalPiecesSold" | translate }}</h5>
                  <label  type="text"  style=" font-size: x-large;" name="TotalQuantitySoled">{{modelObject.TotalQuantitySoled}}</label>
         
                </div>
                <div class="vl"></div>

                <div class="value_highlight col-md-2 ">
                  <h5 for="inputState" style="color: #0189DE;font-size: 14px;">{{ "The last 28 days" | translate }}</h5>
                  <label  type="text"  style=" font-size: x-large;" name="Last28DayesQuantitySoled">{{modelObject.Last28DayesQuantitySoled}}</label>
         
                </div>
                <div class="vl"></div>

                <div class="value_highlight col-md-2 ">
                  <h5 for="inputState" style="color: #0189DE;font-size: 14px;">{{ "The last 7 days" | translate }}</h5>
                  <label  type="text"  style=" font-size: x-large;" name="Last7DayesQuantitySoled">{{modelObject.Last7DayesQuantitySoled}}</label>
         
                </div>
                <div class="vl"></div>

                <div class="value_highlight col-md-3 ">
                  <h5 for="inputState" style="color: #0189DE; font-size: 14px;">{{ "AverageCostPrice" | translate }}
                  <span  tooltip="{{ 'AverageCostPriceAlert' | translate }}" placement="auto" style="color: crimson; font-size: 8px;"  >
                    <img src="assets\img\question-ar.png">
                  </span>
                </h5>
                  <label  type="text"  style=" font-size: x-large;" name="AverageUnitPrice">{{modelObject.AverageUnitPrice}}</label>
         
                </div>
              </div>
          <div class="row">
             
          </div>
          <div class="row">
            <button
            type="button"
            (click)="StockAdjustment()"
            class="button"
            
          >        
            {{ "Adding a process to the inventory" | translate }}
          </button>
         
          </div>

            
        </div>
      <hr class="solid">
        <h3 class="head-bar theme-color-a"><span class="details-info">التفاصيل</span></h3>
        <div class="row">
          <div class="value_highlight col-md-2 ">

          </div>
          <div  *ngIf="tableView" class="FormScreen" class="value_highlight col-md-4">
            <table class="table table-borderless">
              <thead >                
                <tr >
                  <td>{{'Product Code:'|translate}}</td>
                  <td>{{modelObject.SkuNumber}}
                 </td>
                </tr>

                <tr >
                  <td>{{'SalePrice:'|translate}}</td>
                  <td>{{modelObject.SalePrice}}
                 </td>
                </tr>

                <tr >
                  <td>{{'ProfitPercentage:'|translate}}</td>
                  <td>{{modelObject.ProfitPercentage}}%
                 </td>
                </tr>
              </thead>
              
            </table>
          </div>
          <div  *ngIf="tableView" class="FormScreen" class="value_highlight col-md-6">
            <table class="table table-borderless">
              <thead>                
                <tr >
                  <td>{{'PurchasingPrice:'|translate}}</td>
                  <td>{{modelObject.PurchasingPrice}}
                 </td>
                </tr>
                 <tr >
                  <td>{{'Category:'|translate}}</td>
                  <td>
                      <tr *ngFor="let Category of modelObject.ItemCategory">
                        <label >{{ Category.CategoryName }}</label>
                      </tr>
                  </td>                  
                  </tr>
                  <tr  >
                    <td>{{'Alert:'|translate}}</td>     
                    <td>                         
                      {{modelObject.Alert}}                         
                    
                  </td>
                  </tr>               
                

              </thead>
              
            </table>
          </div>

          <div class="value_highlight col-md-3 ">
          </div>
        </div>
      
    </tab>
    <tab heading="{{'StockMovement' | translate}}" class="card-body">
      <div >
 
        <div id="divmiddle" style="display: block; margin-top: 5px">
          <div style="width: 100%">
            <div>
              <div class="card" style="min-width: 900px !important">
                <div class="card-body">
                  <table class="table table-hover" style="margin: unset !important" >
                    <thead class="no-border-top">
                    
                      <tr style="font-size: large ; color: #0189DE;"  >
                        <td>{{'DateOperation'|translate}}</td>
                        <td>
                          {{'TimeOperation'|translate}}
                        </td>
                        <td>{{'Movement'|translate}}</td>
                        <td></td>
                        <td></td>
                        <td>{{'InventoryAfterQuantity'|translate}}</td>
                      </tr>
               
         
                  
                    </thead>
                    <tbody>
                    <tr  *ngFor="let obj of StockAdjustmentList" style="font-size: 14px; font-weight: bold;">

                      <td >
                        <div>
                          <span >
                            ({{obj.MoveNumber}}#)
                            {{obj.Date}}
                            
                          </span>                          
                        </div>
                        <div>
                          <a *ngIf="obj.QuantityEditType==1">{{'InboundRequisition'|translate}}</a>
                          <a *ngIf="obj.QuantityEditType==2">{{'OutboundRequisition'|translate}}</a>
                        </div>
                        <div>
                        <label style="font-size: small;"> {{obj.WarehouseName}}</label>

                        </div>
                       
                        
                      </td>
                      <td>{{obj.Time}}</td>
                      <td >
                        <div>
                        <span  style="color: #27c24c;"  *ngIf="obj.QuantityEditType==1">{{'+' | translate}}</span>
                        <span  style="color:darkred; " *ngIf="obj.QuantityEditType==2">{{'-' | translate}}</span>
                        {{
                         obj.Quantity
                        }}  
                      </div> 
                        <div>
                          <a style="font-size: small;"> {{obj.UnitPrice}} ج م </a>
                          
                        </div>                   
                     </td>
                      <td></td>
                      <td></td>
                      <td >
                        <div>
                          {{obj.InventoryAfterQuantity}}
                        </div>
                       
                       
                      </td>
                    </tr>

                    </tbody>
                  </table>
                  <!-- <div
                    id="divsearchmiddle"
                    style="display: inline-block; width: 100%; height: 100%"
                  >
                    <div style="text-align: center">
                 
                      <div
                        class="centercenter"
                        style="
                          display: inline-block;
                          width: 70%;
                          height: 100%;
                          text-align: center;
                        "
                      >
                        <bdo dir="ltr">
                          <pagination
                            class="pagination-sm2"
                            style="
                              padding: 0px, 0px, 0px, 0px;
                              margin: 0px, 0px, 0px, 0px;
                              display: inline-block;
                            "
                            [(ngModel)]="currentPage"
                            [boundaryLinks]="true"
                            [totalItems]="tableEngine.filteredData.length"
                            [itemsPerPage]="tableEngine.itemsPerPage"
                            [customFirstTemplate]="firstTemplate"
                            [customLastTemplate]="lastTemplate"
                            [customPreviousTemplate]="prevTemplate"
                            [customNextTemplate]="nextTemplate"
                            [maxSize]="5"
                            (pageChanged)="tableEngine.changePage($event)"
                          >
                          </pagination>
                        </bdo>
                      </div>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </tab>
      <tab heading="{{'TimeLine' | translate}}" class="card-body">
     
      </tab>
  
  </tabset>
</div>
 <td></td>
  <td></td>
  <td></td>  <td></td>
  <td></td>
  <td></td>
 