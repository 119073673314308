<div class="row">
    <div class="col-12">
        <div class="card-body">

            <div class="btn-group btn-group-responsive btn-group-sm" role="group" aria-label="item-actions-group">
                <a  [routerLink]="[ '../../create-checks-received', obj.Id ]" class="btn btn-light"><i class="mdi mdi-pencil-outline mr-1"></i>تعديل</a>
                  <a  class="btn btn-danger" (click)="showDelModal(obj.Id)"  >
                    <i class="mdi mdi-trash-can mr-1"></i>
                    <span class="">{{'Delete'|translate}}</span>
                 </a>
                 <button  class="btn btn-success" [disabled]="obj.IsCollection || obj.IsRejected " (click)="showcollectionModal()">
                        <i class="mdi mdi-album text-success mr-1"></i>
                        {{'Collection'|translate}}
                </button>
                 <button  class="btn btn-secondary" [disabled]="obj.IsCollection || obj.IsRejected|| obj.IsDeposit" (click)="showdepositModal()">
                        <i class="mdi mdi-album text-orange mr-1"></i>
                        {{'Deposit'|translate}}
                 </button>
                 <button  class="btn btn-light" [disabled]="obj.IsCollection || obj.IsRejected|| obj.IsDeposit" (click)="showrejectModal()">
                        <i class="mdi mdi-album text-danger mr-1"></i>
                        {{'Reject'|translate}}

                </button>
        </div>


          </div>

      <div class="display-options-container">
        <div class="separator mb-4"></div>
      </div>
    </div>
  </div>

  <div class="row mb-12">
    <div class="col-md-12 col-lg-12 col-12 mb-12">
      <div class="card">
        <tabset class="card-tabs" [justified]="true">
          <tab heading="{{'Details' | translate}}" class="card-body">
            <div class="px-3 pt-3">
                <div class="panel">
                    <div class="panel-head">
                        <h3>{{"Check Information"|translate}}</h3>
                    </div>
                </div>
                <div class="px-3 pt-3">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="mb-4">
                                <p class="text-muted mb-1">{{"Amount"|translate}}:</p>
                                <p class="text-big-number">{{obj.Amount}}</p>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="mb-4">
                                <p class="text-muted mb-1">{{"Description"|translate}}:</p>
                                <p class="pre">{{obj.Description}}</p>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="mb-4">
                                <p class="text-muted mb-1">{{'DueDate'|translate}} :</p>
                                <p>{{obj.DueDate|date:'dd/MM/yyyy'}}</p>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="mb-4">
                                <p class="text-muted mb-1">{{'ReleaseDate'|translate}} :</p>
                                <p>{{obj.ReleaseDate|date:'dd/MM/yyyy'}}</p>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="mb-4">
                                <p class="text-muted mb-1">{{"The Name Is On The Check"|translate}}:</p>
                                <p class="pre">{{obj.TheNameIsOnTheCheck}}</p>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="mb-4">
                                <p class="text-muted mb-1">{{'The Collection Account'|translate}} :</p>
                                <p>{{gerAccountNameById(obj.TheCollectionAccount)}}</p>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="mb-4">
                                <p class="text-muted mb-1">{{'Check Number'|translate}} :</p>
                                <p>{{obj.CheckNumber}}</p>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="mb-4">
                                <p class="text-muted mb-1">{{'Account Received'|translate}} :</p>
                                <p>{{gerAccountNameById(obj.AccountReceivedId)}}</p>
                            </div>
                        </div>
                        <div class="col-md-6" *ngIf="obj.IdEndorsement">
                            <div class="mb-4">
                                <p class="text-muted mb-1">{{'Endorsement'|translate}} :</p>
                                <!-- <p>{{obj.IdEndorsement}}</p> -->
                            </div>
                        </div>
                        <div class="col-md-6" *ngIf="obj.IdEndorsement">
                            <div class="mb-4">
                                <p class="text-muted mb-1">{{'Endorsement Name'|translate}} :</p>
                                <p>{{obj.EndorsementName}}</p>
                            </div>
                        </div>

                         </div>
                </div>
<div class="card" *ngIf="obj.FileUrl && obj.FileUrl!=''">
    <div class="form-group  Card_Padding">
        <br>
        <table class="table" >
            <tbody>
             <tr >
                 <td></td>
                 <td><a href="{{obj.FileUrl}}" target="_blank" style="cursor: pointer;" download="">{{"Download File"|translate}}</a></td>
                 <td>
                     <!-- <a href="javascript:" (click)="clear()"><i class="simple-icon-trash"></i></a> -->
                 </td>
             </tr>
            </tbody>
         </table>
    </div>

</div>

                
                
                
            </div>
          </tab>
          <!-- <tab heading="{{'Contractors' | translate}}" class="card-body">
           
               </tab> -->
  
        </tabset>
      </div>
    </div>
  </div>

  <div bsModal #delModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">
    <div class="modal-dialog modal-sm">
        <div class="modal-content"  >
            <button type="button" class="close pull-right" aria-label="Close" (click)="delModal.hide()"
                style="margin-right: 20px; margin-left: 20px; margin-top: 10px; width: 20px; display: inline;">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-header" style="display: inline; padding: 0px 0px 5px 0px;">
                <h4 id="dialog-child-name" class="modal-title pull-left"  style="text-align: center;">
                    {{"ConfirmDel" |
                    translate}}</h4>
            </div>
            <div class="modal-body">
         
                <div class="form-group form-primary" style="text-align: center;" >
                    <label type="text">
                        <h1 style="font-weight: bolder; text-align: center;">{{"Delete" | translate}}</h1>
                    </label>
                    <span class="form-bar"></span>
                </div>
                
                <div style="margin-left: 5px; text-align: center; margin-top: 30px;">
                    <button class="btn btn-danger" id="smallModalDeleteButton" (click)="deleteObject()"
                        
                        style="font-weight: bold; width: 100%; text-align: center;">{{"Delete" | translate}}</button>
                    <button class="btn btn-light" (click)="delModal.hide()"
                        style="font-weight: bold; width: 100%; text-align: center; margin-top: 10px;">{{"Close" |
                        translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div bsModal #collection="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">
    <div class="modal-dialog modal-sm">
        <div class="modal-content"  >
            <button type="button" class="close pull-right" aria-label="Close" (click)="collection.hide()"
                style="margin-right: 20px; margin-left: 20px; margin-top: 10px; width: 20px; display: inline;">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-header" style="display: inline; padding: 0px 0px 5px 0px;">
                <h4 id="dialog-child-name"  class="modal-title pull-left"  style="text-align: center;">
                    {{"collection" |
                    translate}}</h4>
            </div>
            <div class="modal-body">
                <Form [formGroup]="collectionForm" *ngIf="collectionForm">
                    <div class="row">
                        <div class="col-md-12">
                            <label for="inputEmail4">{{ 'Collection Date' | translate }}</label>
                            <input formControlName="CollectionDate" type="Date" id="inputEmail4" class="form-control">
                        </div>
                        <div class="col-md-12" *ngIf="obj.IsDeposit">
                            <label for="inputEmail4">{{ 'Deposit Fees' | translate }}</label>
                            <input formControlName="DepositFees" type="number" id="inputEmail4" class="form-control">
                        </div>
                        <div class="col-md-12">
                            <label for="inputEmail4">{{ 'Description' | translate }}</label>
                            <textarea formControlName="CollectionDescription" type="text" id="inputEmail4" class="form-control">
                            </textarea>
                            </div>
                    </div>
                </Form>
           
                
                <div style="margin-left: 5px; text-align: center; margin-top: 30px;">
                    <button class="btn btn-success" id="smallModalDeleteButton" (click)="Collection()"
                        style="font-weight: bold; width: 100%; text-align: center;">{{"Collection" | translate}}</button>
                    <button class="btn btn-light" (click)="collection.hide()"
                        style="font-weight: bold; width: 100%; text-align: center; margin-top: 10px;">{{"Close" |
                        translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div bsModal #deposit="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">
    <div class="modal-dialog modal-sm">
        <div class="modal-content"  >
            <button type="button" class="close pull-right" aria-label="Close" (click)="deposit.hide()"
                style="margin-right: 20px; margin-left: 20px; margin-top: 10px; width: 20px; display: inline;">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-header" style="display: inline; padding: 0px 0px 5px 0px;">
                <h4 id="dialog-child-name"  class="modal-title pull-left"  style="text-align: center;">
                    {{"deposit" |
                    translate}}</h4>
            </div>
            <div class="modal-body">
                <Form [formGroup]="depositForm" *ngIf="depositForm">
                    <div class="row">
                        <div class="col-md-12">
                            <label for="inputEmail4">{{ 'Deposit Date' | translate }}</label>
                            <input formControlName="DepositDate" type="Date" id="inputEmail4" class="form-control">
                        </div>
                    
                        <div class="col-md-12">
                            <label for="inputEmail4">{{ 'Account' | translate }}</label>
                            <ng-select   formControlName="DepositAccountId"    [hideSelected]="true">
                              <ng-option selected desabled [value]="0">{{ "Please Choose"|translate}}</ng-option>
                              <ng-option *ngFor="let car of AccountsNameModel" [value]="car.Id">{{ lang==='ar-EG'? car.NameAr:car.NameEn}}</ng-option>
                          </ng-select>
                        </div>
                        <div class="col-md-12">
                            <label for="inputEmail4">{{ 'Description' | translate }}</label>
                            <textarea formControlName="DepositDescription" type="text" id="inputEmail4" class="form-control">
                            </textarea>
                            </div>
                    </div>
                </Form>
           
                
                <div style="margin-left: 5px; text-align: center; margin-top: 30px;">
                    <button class="btn btn-secondary" id="smallModalDeleteButton" (click)="Deposit()"
                        style="font-weight: bold; width: 100%; text-align: center;">{{"Deposit" | translate}}</button>
                    <button class="btn btn-light" (click)="deposit.hide()"
                        style="font-weight: bold; width: 100%; text-align: center; margin-top: 10px;">{{"Close" |
                        translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div bsModal #reject="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">
    <div class="modal-dialog modal-sm">
        <div class="modal-content"  >
            <button type="button" class="close pull-right" aria-label="Close" (click)="reject.hide()"
                style="margin-right: 20px; margin-left: 20px; margin-top: 10px; width: 20px; display: inline;">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-header" style="display: inline; padding: 0px 0px 5px 0px;">
                <h4 id="dialog-child-name"  class="modal-title pull-left"  style="text-align: center;">
                    {{"Reject" |
                    translate}}</h4>
            </div>
            <div class="modal-body">
                <Form [formGroup]="rejectForm" *ngIf="rejectForm">
                    <div class="row">
                        <div class="col-md-12">
                            <label for="inputEmail4">{{ 'Reject Date' | translate }}</label>
                            <input formControlName="RejectedDate" type="Date" id="inputEmail4" class="form-control">
                        </div>
                        <div class="col-md-12">
                            <label for="inputEmail4">{{ 'Description' | translate }}</label>
                            <textarea formControlName="RejectedDescription" type="text" id="inputEmail4" class="form-control">
                            </textarea>
                            </div>
                    </div>
                </Form>
           
                
                <div style="margin-left: 5px; text-align: center; margin-top: 30px;">
                    <button class="btn btn-success" id="smallModalDeleteButton" (click)="Reject()"
                        style="font-weight: bold; width: 100%; text-align: center;">{{"Reject" | translate}}</button>
                    <button class="btn btn-light" (click)="reject.hide()"
                        style="font-weight: bold; width: 100%; text-align: center; margin-top: 10px;">{{"Close" |
                        translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>