<!--component html goes here -->
<div class="row mb-12">
  <div class="col-md-12 col-lg-12 col-12 mb-12">
    <div class="card"></div>
  </div>
</div>
<div class="row" *ngIf="mainForm">
  <div class="col-12">
    <div class="card mb-4">
      <div class="card-body">
        <h5 class="float-left">{{ "Create Product" | translate }}</h5>
        <div class="top-right-button-container text-zero float-right">
          <button
            class="top-right-button btn btn-primary btn-lg mr-1"
            (click)="goBack()"
          >
            {{ "Back" | translate }}
          </button>
          <button
            type="button"
            (click)="Save()"
            [disabled]="mainForm.invalid"
            class="top-right-button btn btn-primary btn-lg mr-1"
          >
            {{ "Save" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div></div>
<div class="row">
    <div class="col-md-6">
  <!-- <div class="col-md-12 col-lg-12 col-12 mb-12"> -->
    <div class="card">
      <tabset class="card-tabs" [justified]="true">
        <tab heading="{{ 'Product Details' | translate }}" class="card-body">
          <!-- <h6 class="mb-4">{{ "Basic Data" | translate }}</h6> -->
          <div class="modal-body">
            <form
              class="md-float-material form-material"
              [formGroup]="mainForm"
            >
              <div class="row">
                <div class="col-md-6">
                    <label for="inputState">{{ "SkuNumber" | translate }}</label>
                    <input
                      class="form-control"
                      formControlName="SkuNumber"
                      rows="2"
                      placeholder="{{ 'SkuNumber' | translate }}"
                    />
                   
                  </div>
                  
                <div class="col-md-6">
                  <label for="inputState">{{ "Name" | translate }}</label>
                  <input
                    class="form-control"
                    formControlName="Name"
                    rows="2"
                    placeholder="{{ 'Name' | translate }}"
                  />
                  <!-- <div
                    class="messages text-danger"
                    *ngIf="
                      (mainForm.get('Name').hasError('required') ||
                        mainForm.get('Name').invalid) &&
                      (mainForm.get('Name').dirty ||
                        mainForm.get('Name').touched)
                    "
                  >
                    {{ "NameRequired" | translate }}
                  </div> -->
                </div>
              

                <div class="col-md-12">
                  <label for="inputState">{{
                    "Description" | translate
                  }}</label>
               
                  <textarea formControlName="Description" id="" cols="8" rows="3"
                    class="form-control"  placeholder="{{ 'Description' | translate }}"></textarea>
                  <!-- <div
                    class="messages text-danger"
                    *ngIf="
                      (mainForm.get('Description').hasError('required') ||
                        mainForm.get('Description').invalid) &&
                      (mainForm.get('Description').dirty ||
                        mainForm.get('Description').touched)
                    "
                  >
                    {{ "DescriptionRequired" | translate }}
                  </div> -->
                </div>


                <div class="col-md-12">
                  <label for="inputState">{{ "Barcode" | translate }}</label>
                  <!-- <barcode type="code128b">Hello</barcode> -->
                  <input
                    class="form-control"
                    formControlName="Barcode"
                    rows="2"
                    placeholder="{{ 'Barcode' | translate }}"
                  />
                  
                </div>


              <div class="col-md-6">
                <label for="inputState">{{
                  "Category" | translate
                }}</label>
                <ng-select [items]="CategoryList"
                bindLabel="Name"
                bindValue="Id"
                [multiple]="true"
                groupBy="selectedAllGroup"
                [selectableGroup]="true"
                placeholder="Select Category"
                (change)="onMaterialGroupChangeCategory($event)">
                >

                <ng-template 
                 ng-optgroup-tmp let-item="item" 
                 let-item$="item$" let-index="index">
                 <input id="item-{{index}}" type="checkbox"
                 [ngModel] ="item$.selected"/> Select All
                </ng-template>
                </ng-select>
              
              </div>


              <div class="col-md-6">
                <label for="inputState">{{
                  "Brand" | translate
                }}</label>
                <ng-select [items]="BrandList"
                bindLabel="Name"
                bindValue="Id"
                [multiple]="true"
                groupBy="selectedAllGroup"
                [selectableGroup]="true"
                placeholder="Select Brand"
                (change)="onMaterialGroupChangeBrand($event)">
                >

                <ng-template 
                 ng-optgroup-tmp let-item="item" 
                 let-item$="item$" let-index="index">
                 <input id="item-{{index}}" type="checkbox"
                 [ngModel] ="item$.selected"/> Select All
                </ng-template>
                </ng-select>

              </div>

              <div class="col-md-12">
                <div
                class="itemCheck mb-0 custom-checkbox custom-control"
                style="display: inline-block"
              >
                <input
                  type="checkbox"
                  class="custom-control-input"
                  [id]="'valueCheck'"
                  formControlName="AvailableOnline"
                />
                <label class="custom-control-label" [for]="'valueCheck'"
                  >{{ "AvailableOnline" | translate }}
                </label>
              </div>
              </div>

               <div class="col-md-12">
                  <div
                    class="itemCheck mb-0 custom-checkbox custom-control"
                    style="display: inline-block" >
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      [id]="'value'"
                      formControlName="FeaturedProduct"
                    />
                    <label class="custom-control-label" [for]="'value'"
                      >{{ "FeaturedProduct" | translate }}
                    </label>
                          </div>
               </div>

              </div>
              <div class="row"></div>
             
            </form>
          </div>
        </tab>
      </tabset>
    </div>
</div>
<div class="col-md-6">

    <div class="card" >
        <tabset class="card-tabs" [justified]="true">
            <tab heading="{{ 'Pricing' | translate }}" class="card-body"> 
        <div class="modal-body">
        <form class="md-float-material form-material" [formGroup]="mainForm">
          <div class="row">
            <div class="col-md-6">
              <label for="inputState">{{
                "PurchasingPrice" | translate
              }}</label>
              <input
                class="form-control"
                formControlName="PurchasingPrice"
                rows="2"
                placeholder="{{ 'PurchasingPrice' | translate }}"
              />
             
            </div>
            <div class="col-md-6">
              <label for="inputState">{{ "SalePrice" | translate }}</label>
              <input
                class="form-control"
                formControlName="SalePrice"
                rows="2"
                placeholder="{{ 'SalePrice' | translate }}"
                (change)="calcProfitPrice()"
              />
          
            </div>
          </div>
          
           <div class="row">
              <div class="col-md-6">
                <label for="inputState">{{ "FirstTaxId" | translate }}</label>
                <select
                  id="inputState"
                  class="form-control"
                  formControlName="FirstTaxId"

                >
                  <option selected value="0">
                    {{ "SelectFirstTax" | translate }}
                  </option>
                  <option
                    *ngFor="let tax of FirstTaxesList"
                    value="{{ tax.Id }}"
                  >
                    {{ lang === "ar-EG" ? tax.NameAr : tax.NameEn }}
                  </option>
                </select>
              </div>
  
              <div class="col-md-6">
                <label for="inputState">{{ "SecondTaxId" | translate }}</label>
                <select
                  id="inputState"
                  class="form-control"
                  formControlName="SecondTaxId"
                >
                  <option selected value="0">
                    {{ "SelectSecondTax" | translate }}
                  </option>
                  <option
                    *ngFor="let tax of SecondTaxesList"
                    value="{{ tax.Id }}"
                  >
                    {{ lang === "ar-EG" ? tax.NameAr : tax.NameEn }}
                  </option>
                </select>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <label for="inputState">{{
                  "LowestSellingPrice" | translate
                }}</label>
                <input
                  class="form-control"
                  formControlName="LowestSellingPrice"
                  rows="2"
                  placeholder="{{ 'LowestSellingPrice' | translate }}"
                />
                <div
                  class="messages text-danger"
                  *ngIf="
                    (mainForm.get('LowestSellingPrice').hasError('required') ||
                      mainForm.get('LowestSellingPrice').invalid) &&
                    (mainForm.get('LowestSellingPrice').dirty ||
                      mainForm.get('LowestSellingPrice').touched)
                  "
                >
                  {{ "LowestSellingPriceRequired" | translate }}
                </div>
              </div>
            <div class="col-md-4">
              <label for="inputState">{{ "Discount" | translate }}</label>
              <input
                class="form-control"
                formControlName="Discount"
                rows="2"
                placeholder="{{ 'Discount' | translate }}"
              />
              <div
                class="messages text-danger"
                *ngIf="
                  (mainForm.get('Discount').hasError('required') ||
                    mainForm.get('Discount').invalid) &&
                  (mainForm.get('Discount').dirty ||
                    mainForm.get('Discount').touched)
                "
              >
                {{ "DiscountRequired" | translate }}
              </div>
            </div>

            <div class="col-md-2">
              <label for="inputState">{{ "DiscountType" | translate }}</label>
              <select
                id="inputState"
                class="form-control"
                formControlName="DiscountType"
              >
              <option selected value="1">
                {{ "%" | translate }}                  
              </option>
                <option selected value="2">
                  {{ "$" | translate }}                  
                </option>
              
              
              </select>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <label for="inputState">{{
                "ProfitPercentage" | translate
              }}</label>
              <input
                class="form-control"
                formControlName="ProfitPercentage"
                rows="2"
                placeholder="{{ 'ProfitPercentage' | translate }}"
                (change)="calcSalePrice()"
              />
              <div
                class="messages text-danger"
                *ngIf="
                  (mainForm.get('ProfitPercentage').hasError('required') ||
                    mainForm.get('ProfitPercentage').invalid) &&
                  (mainForm.get('ProfitPercentage').dirty ||
                    mainForm.get('ProfitPercentage').touched)
                "
              >
                {{ "ProfitPercentageRequired" | translate }}
              </div>
            </div>

            <div class="col-md-6">
              <label for="inputState">{{ "UnitType" | translate }}</label>
              <select
                id="inputState"
                class="form-control"
                formControlName="UnitId"
              >
                <option selected value="0">
                  {{ "UnitType" | translate }}
                </option>
                <option
                  *ngFor="let Unit of UnitList"
                  value="{{ Unit.Id }}"
                >
                  {{ lang === "ar-EG" ? Unit.NameAr : Unit.NameEn }}
                </option>
              </select>
            </div>
          </div>
<td></td>
<td></td>
<td></td>
          <div class="row">
            <!-- <div class="col-md-6">
            </div> -->
            <div class="col-md-12">  
                <div style="text-align:left">
                  <button class="button" (click)="AddonClick()">
                    {{ "AddPriceList" | translate }}
                  </button>
                </div>
                <div>
                  <table class="table">
                        
                    <tbody>
                     
                      <tr *ngFor="let Item of ItemsPriceList2; let i = index">
                  
                        <td style="vertical-align: middle">
                            <select
                            id="inputState"
                            class="form-control"
                            [(ngModel)]="Item.PriceListId"
                            [ngModelOptions]="{standalone: true}"
                          >
                            <option selected value="0">
                              {{ "PriceList" | translate }}
                            </option>
                            <option
                              *ngFor="let Itsm of PriceList"
                              value="{{ Itsm.Id }}"
                            >
                              {{ lang === "ar-EG" ? Itsm.NameAr : Itsm.NameEn }}
                            </option>
                          </select>
                        </td>
                     
                        <td style="text-align: center; vertical-align: middle">
                          <i
                            class="simple-icon-plus"
                            [ngClass]="
                              !PriceList.invalid
                                ? 'simple-icon-plus'
                                : 'simple-icon-plus inactive-icon'
                            "
                            (click)="
                              !(
                                PriceList.invalid ||
                                tableEngine.currentAction === 'loading'
                              )
                                ? AddonClick()
                                : ''
                            "
                            style="
                              font-size: 20px;
                              font-weight: bolder;
                              cursor: pointer;
                              color: #17a2b8;
                            "
                          ></i>
                        </td>
                        <td style="text-align: center; vertical-align: middle">
                            <i
                              class="simple-icon-close"
                              (click)="removeItemsFooter(Item.Id)"
                              style="
                                font-size: 20px;
                                font-weight: bolder;
                                cursor: pointer;
                                color: #dc3545;
                              "
                            ></i>
                          </td>
                      </tr>
                      <tr
                        *ngIf="
                          tableEngine.currentFormMode === 'Add' ||
                          tableEngine.currentFormMode === 'Edit'
                        "
                        type="text"
                      ></tr>
                    </tbody>
                   
                  </table>
                </div>
            </div>
            </div>
        </form>
      </div>
    </tab>
</tabset>
    </div>
</div>
</div>

<!-- <div  class="row">
 
    <div class="col-md-12">
        <div class="card">
          <tabset class="card-tabs" [justified]="true">
            <tab heading="{{ 'PriceList' | translate }}" class="card-body">
              <table class="table">
            
                <tbody>
                 
                  <tr *ngFor="let Item of ItemsPriceList2; let i = index">
              
                    <td style="vertical-align: middle">
                        <select
                        id="inputState"
                        class="form-control"
                        [(ngModel)]="Item.PriceListId"
                        [ngModelOptions]="{standalone: true}"
                      >
                        <option selected value="0">
                          {{ "PriceList" | translate }}
                        </option>
                        <option
                          *ngFor="let Itsm of PriceList"
                          value="{{ Itsm.Id }}"
                        >
                          {{ lang === "ar-EG" ? Itsm.NameAr : Itsm.NameEn }}
                        </option>
                      </select>
                    </td>
                 
                    <td style="text-align: center; vertical-align: middle">
                      <i
                        class="simple-icon-plus"
                        [ngClass]="
                          !PriceList.invalid
                            ? 'simple-icon-plus'
                            : 'simple-icon-plus inactive-icon'
                        "
                        (click)="
                          !(
                            PriceList.invalid ||
                            tableEngine.currentAction === 'loading'
                          )
                            ? AddonClick()
                            : ''
                        "
                        style="
                          font-size: 20px;
                          font-weight: bolder;
                          cursor: pointer;
                          color: #17a2b8;
                        "
                      ></i>
                    </td>
                    <td style="text-align: center; vertical-align: middle">
                        <i
                          class="simple-icon-close"
                          (click)="removeItemsFooter(Item.Id)"
                          style="
                            font-size: 20px;
                            font-weight: bolder;
                            cursor: pointer;
                            color: #dc3545;
                          "
                        ></i>
                      </td>
                  </tr>
                  <tr
                    *ngIf="
                      tableEngine.currentFormMode === 'Add' ||
                      tableEngine.currentFormMode === 'Edit'
                    "
                    type="text"
                  ></tr>
                </tbody>
               
              </table>
            </tab>
          </tabset>
              </div>
          </div>
    </div> -->
    

<td></td>
<td></td>
<td></td>
<div class="row mb-12">
  <div class="col-md-12 col-lg-12 col-12 mb-12">
    <div class="card">
        <tabset class="card-tabs" [justified]="true">
            <tab heading="{{ 'WarehouseManage' | translate }}" class="card-body">
              <div class="modal-body">
                <div class="row">
            <div  class="col-md-12">
              <table class="table">
                 <tbody>                    
                  <tr *ngFor="let Item of WarehouseList2; let i = index">

                  <td style="vertical-align: middle">
                  <label for="inputState">{{ "EditStores" | translate }}</label>

                    <select
                    id="inputState"
                    class="form-control"
                    [(ngModel)]="Item.WarehouseDataId"
                    [ngModelOptions]="{standalone: true}"
                  >
                    <option selected value="0">
                      {{ "EditStores" | translate }}
                    </option>
                    <option
                      *ngFor="let Itsm of WarehouseList"
                      value="{{ Itsm.Id }}"
                    >
                      {{ Itsm.Name }}
                    </option>
                  </select>
                  </td>
                  <td>
                  <label for="inputState">{{ "Quantity" | translate }}</label>
                  <input
                  [(ngModel)]="Item.Quantity"
                  type="number"
                  class="form-control"
                />
                  <!-- <input
                    class="form-control"
                    formControlName="Quantity"
                    rows="2"
                    placeholder="{{ 'Quantity' | translate }}"
                  /> -->
                  <!-- <div
                    class="messages text-danger"
                    *ngIf="
                      (mainForm.get('Quantity').hasError('required') ||
                        mainForm.get('Quantity').invalid) &&
                      (mainForm.get('Quantity').dirty ||
                        mainForm.get('Quantity').touched)
                    "
                  >
                    {{ "QuantityRequired" | translate }}
                  </div> -->
                  </td>  
                  <!-- <td>
                    <label for="inputState">{{ AlertMessage | translate }}</label>
                    <input
                    [(ngModel)]="Item.Alert"
                    type="number"
                    class="form-control"
                  />
                  </td> -->
                  <td style="text-align: center; vertical-align: middle">
                    <i
                      class="simple-icon-close"
                      (click)="removeItemsFooter(Item.Id)"
                      style="
                        font-size: 20px;
                        font-weight: bolder;
                        cursor: pointer;
                        color: #dc3545;
                      "
                    ></i>
                  </td>
                  <td style="text-align: center; vertical-align: middle">
                    <i
                      class="simple-icon-plus"
                      [ngClass]="
                        !WarehouseList2.invalid
                          ? 'simple-icon-plus'
                          : 'simple-icon-plus inactive-icon'
                      "
                      (click)="
                        !(
                          WarehouseList2.invalid ||
                          tableEngine.currentAction === 'loading'
                        )
                          ? AddonWarehouseOnClick()
                          : ''
                      "
                      style="
                        font-size: 20px;
                        font-weight: bolder;
                        cursor: pointer;
                        color: #17a2b8;
                      "
                    ></i>
                  </td>
                 </tr>
                 <tr
                  *ngIf="
                    tableEngine.currentFormMode === 'Add' ||
                    tableEngine.currentFormMode === 'Edit'
                  "
                  type="text"
                ></tr>
                        
                 </tbody>
              </table>
             
             
            </div> 
                
          </div>          
          <div class="row">
            <div  class="col-md-6"> 
              <div class="modal-body">
                <form
                  class="md-float-material form-material"
                  [formGroup]="mainForm"
                >
                <tr >
                  <td>
                    <label for="inputState">{{AlertMessage| translate }}</label>
                    <input
                      class="form-control"
                      formControlName="Alert"
                      rows="2"
                    />
                    <div
                      class="messages text-danger"
                      *ngIf="
                        (mainForm.get('Alert').hasError('required') ||
                          mainForm.get('Alert').invalid) &&
                        (mainForm.get('Alert').dirty ||
                          mainForm.get('Alert').touched)
                      "
                    >
                      {{ "QuantityRequired" | translate }}
                    </div>
                  </td>
                </tr>   
              </form></div>   
            </div>  
          </div>
        
      </div>
    </tab>
</tabset>
    </div>
  </div>
</div>
<!-- </tab> -->
<td></td>
<td></td>
<td></td>
<div class="row mb-12">
  <div class="col-md-12 col-lg-12 col-12 mb-12">
    <div class="card">
    <tabset class="card-tabs" [justified]="true">
        <tab heading="{{ 'More' | translate }}" class="card-body">
      <div class="modal-body">
        <form class="md-float-material form-material" [formGroup]="mainForm">
        <div class="row">
        <div class="col-md-6">
            <label for="inputState">{{ "Notes" | translate }}</label>
            <input
              class="form-control"
              formControlName="Notes"
              rows="2"
              placeholder="{{ 'Notes' | translate }}"
            />
            <div
              class="messages text-danger"
              *ngIf="
                (mainForm.get('Notes').hasError('required') ||
                  mainForm.get('Notes').invalid) &&
                (mainForm.get('Notes').dirty ||
                  mainForm.get('Notes').touched)
              "
            >
              {{ "NotesRequired" | translate }}
            </div>
          </div>

          <div class="col-md-6">
            <label for="inputState">{{ "Tags" | translate }}</label>
            <input
              class="form-control"
              formControlName="Tags"
              rows="2"
              placeholder="{{ 'Tags' | translate }}"
            />
            <div
              class="messages text-danger"
              *ngIf="
                (mainForm.get('Tags').hasError('required') ||
                  mainForm.get('Tags').invalid) &&
                (mainForm.get('Tags').dirty || mainForm.get('Tags').touched)
              "
            >
              {{ "TagsRequired" | translate }}
            </div>
          </div>

          <div 
          class="col-md-6 itemCheck mb-0 custom-checkbox custom-control"
          style="display: inline-block"
        >
          <input
            type="checkbox"
            class="custom-control-input"
            [id]="'valueCheck2'"
            formControlName="IsDisable"
          />
          <label class="custom-control-label" [for]="'valueCheck2'"
            >{{ "IsDisable" | translate }}
          </label>
        </div>
        </div>
     
    </form>
    
    </div>
</tab>
</tabset>
</div>

</div>
