import { CashierSessionModel } from "../cashier-sessions/CashierSessionModel";

export class CashierStaffModel {
    Id?: number;
    Code?:number; //new
    StaffId?:number;
    NameAr?: string;
    NameEn?: string;
    Email?:string;
    Role?:string;
    BranchId?: number;
    BranchNameAr?: string;
    BranchNameEn?: string;
    LastRegisterId?:number;
    LastRegisterCode?:number;
    LastRegisterBranchNameAr?:string; //new
    LastRegisterBranchNameEn?:string; //new
    Sessions?: CashierSessionModel []; //new

    constructor(obj?: CashierStaffModel) {
        obj = obj ? obj : {};
        this.Id = obj.Id ? obj.Id : 0;
        this.Code = obj.Code ? obj.Code : 0;
        this.StaffId = obj.StaffId ? obj.StaffId : 0;
        this.NameAr = obj.NameAr ? obj.NameAr : '';
        this.NameEn = obj.NameEn ? obj.NameEn : '';
        this.Email = obj.Email ? obj.Email : '';
        this.Role = obj.Role ? obj.Role : '';
        this.BranchId = obj.BranchId ? obj.BranchId : 0;
        this.BranchNameAr = obj.BranchNameAr ? obj.BranchNameAr : '';
        this.BranchNameEn = obj.BranchNameEn ? obj.BranchNameEn : '';
        this.LastRegisterId = obj.LastRegisterId ? obj.LastRegisterId : 0;
        this.LastRegisterCode = obj.LastRegisterCode ? obj.LastRegisterCode : 0;
        this.LastRegisterBranchNameAr = obj.LastRegisterBranchNameAr ? obj.LastRegisterBranchNameAr : '';
        this.LastRegisterBranchNameEn = obj.LastRegisterBranchNameEn ? obj.LastRegisterBranchNameEn : '';
        this.Sessions = obj.Sessions ? obj.Sessions : [];
    }

}