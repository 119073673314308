import { Component, HostBinding, Renderer2, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';

import { TableEngine } from '../../../invoices/TableEngine'
import { ModalDirective } from 'ngx-bootstrap/modal';
import { SidePopupComponent } from '../../app/aio/shared/side-popup/side-popup.component';
import { Observable, of, Subscription } from 'rxjs';

import { NgSelectComponent } from '@ng-select/ng-select';

import { DatePipe, formatDate } from '@angular/common';


import { ConfigPoSService } from "./ConfigPoSService";
import { ConfigPoSModel } from "./ConfigPoSModel";
import { ClientService } from '../../../invoices/clients/ClientService';
import { PaymentMethodService } from '../../purchases/purchase-orders/PaymentMethodService';
import { ItemService } from '../../../invoices/items/ItemService';
import { ClientModel } from '../../../invoices/clients/ClientModel';
import { Router } from '@angular/router';
import { ListPageHeaderComponent } from 'src/app/containers/pages/list-page-header/list-page-header.component';

@Component({
  selector: 'app-config-pos-general',
  templateUrl: './config-pos-general.component.html',
  styleUrls: ['./config-pos-general.component.scss']
})
export class ConfigPosGeneralComponent {
  // ////////////////////////////////////////////////////////////////////////////
  // //  VARYING :
  // //  Modify per similar component.
  // ////////////////////////////////////////////////////////////////////////////

  //Enable debugMode to make API modules return static data from within the service class
  debugMode: boolean = false;

  constructor(private formBuilder: FormBuilder, private modelService: ConfigPoSService, private renderer: Renderer2,
    private notifications: NotificationsService, private translate: TranslateService,
    private datePipe: DatePipe, private clientService: ClientService, private paymentMethodService: PaymentMethodService,
    private itemService: ItemService, private _router: Router
  ) {
  }

  listHeader = new ListPageHeaderComponent();
  translationName: string = 'ConfigPoS'

  modelObject: ConfigPoSModel = new ConfigPoSModel();

  declare lang: any;
  ngOnInit(): void {
    if (this.debugMode) {
      this.enableDebugOnAllServices();
    }
    this.lang = localStorage.getItem('theme_lang');
    this.loadServices();
  }

  currentAction = '';

  clientList = [];
  paymentMethodList = [];
  itemList = [];
  loadServices() {
    this.currentAction = 'loading';
    

    this.clientService.getAll().subscribe(
      clientData => {
        this.clientList = clientData;

        this.itemService.getAll().subscribe(
          itemData => {
            this.itemList = itemData;
            
            this.paymentMethodService.getAll().subscribe(
              paymentMethodData => {
                this.paymentMethodList = paymentMethodData;
                this.loadModelObject();
              }
            )
          }
        )
      }
    )
  }

  loadModelObject(){
    this.currentAction = 'loading';
    this.modelService.getFirst().subscribe(
      config => {
        this.modelObject = config;
        this.mainForm = this.createForm(this.modelObject, this.formBuilder);
        this.currentAction = '';
        
        this.toggleInput(this.mainForm.getRawValue().EnableAutoCodesForCashiers, this.mainForm.get('StartingCodeCashiers'));
        this.toggleInput(this.mainForm.getRawValue().EnableAutoCodesForCashRegisiters, this.mainForm.get('StartingCodeCashRegisiters'));
        this.toggleInput(this.mainForm.getRawValue().EnableAutoCodesForWorkShifts, this.mainForm.get('StartingCodeWorkShifts'));
      }
    )
  }

  enableDebugOnAllServices() {
    //this.modelService.debugMode = true;
    // this.clientService.debugMode = true;
    // this.itemService.debugMode = true;
    // this.paymentMethodService.debugMode = true;
  }

  mainForm: FormGroup = new FormGroup({
    Id: new FormControl(),
    DefaultClientId: new FormControl(),
    //DefaultTemplateId: new FormControl(),
    DefaultPaymentMethodId: new FormControl(),
    //EnableDiscountLimit: new FormControl(),
    DiscountLimit: new FormControl(),
    EnableAutoCodesForCashRegisiters: new FormControl(),
    StartingCodeCashRegisiters: new FormControl(),
    EnableAutoCodesForCashiers: new FormControl(),
    StartingCodeCashiers: new FormControl(),
    EnableAutoCodesForWorkShifts: new FormControl(),
    StartingCodeWorkShifts: new FormControl(),
    StartingCodeOrders: new FormControl(),
    StartingCodeSessions: new FormControl(),
    //ActivePaymentMethods: new FormControl(),
    //ActiveItems: new FormControl(),
    //EnableDigitButtons: new FormControl(),
    //EnableItemImages: new FormControl(),
    //EnableAccountingPerInvoice: new FormControl(),
    //EnableAddingItemsViaSerial: new FormControl(),
    //EnableDelayedPayment: new FormControl(),
    //EnableAutoSettlement: new FormControl(),
    //DefaultEarningsAccountId: new FormControl(),
    //DefaultLossesAccountId: new FormControl(),
  });

  createForm(modelObject, formBuilder): FormGroup {
    
    return formBuilder.group({
      DefaultClientId: [modelObject.DefaultClientId, Validators.compose([Validators.required])],
      //DefaultTemplateId: [modelObject.DefaultTemplateId, Validators.compose([Validators.required])],
      DefaultPaymentMethodId: [modelObject.DefaultPaymentMethodId, Validators.compose([Validators.required])],
      //EnableDiscountLimit: [modelObject.EnableDiscountLimit, Validators.compose([Validators.required])],
      DiscountLimit: [modelObject.DiscountLimit, Validators.compose([Validators.required])],
      EnableAutoCodesForCashRegisiters: [modelObject.EnableAutoCodesForCashRegisiters, Validators.compose([Validators.required])],
      StartingCodeCashRegisiters: [modelObject.StartingCodeCashRegisiters, Validators.compose([Validators.required])],
      EnableAutoCodesForCashiers: [modelObject.EnableAutoCodesForCashiers, Validators.compose([Validators.required])],
      StartingCodeCashiers: [modelObject.StartingCodeCashiers, Validators.compose([Validators.required])],
      EnableAutoCodesForWorkShifts: [modelObject.EnableAutoCodesForWorkShifts, Validators.compose([Validators.required])],
      StartingCodeWorkShifts: [modelObject.StartingCodeWorkShifts, Validators.compose([Validators.required])],
      StartingCodeOrders: [modelObject.StartingCodeOrders, Validators.compose([Validators.required])],
      StartingCodeSessions: [modelObject.StartingCodeSessions, Validators.compose([Validators.required])],
      //ActivePaymentMethods: [modelObject.ActivePaymentMethods, Validators.compose([Validators.required])],
      //ActiveItems: [modelObject.ActiveItems, Validators.compose([Validators.required])],
      //EnableDigitButtons: [modelObject.EnableDigitButtons, Validators.compose([Validators.required])],
      //EnableItemImages: [modelObject.EnableItemImages, Validators.compose([Validators.required])],
      //EnableAccountingPerInvoice: [modelObject.EnableAccountingPerInvoice, Validators.compose([Validators.required])],
      //EnableAddingItemsViaSerial: [modelObject.EnableAddingItemsViaSerial, Validators.compose([Validators.required])],
      //EnableDelayedPayment: [modelObject.EnableDelayedPayment, Validators.compose([Validators.required])],
      //EnableAutoSettlement: [modelObject.EnableAutoSettlement, Validators.compose([Validators.required])],
      //DefaultEarningsAccountId: [modelObject.DefaultEarningsAccountId],
      //DefaultLossesAccountId: [modelObject.DefaultLossesAccountId],
    });
  }

  editObject() {
    this.currentAction = 'loading';
    let tempObj = new ConfigPoSModel();
    tempObj.Id =this.modelObject.Id;
    tempObj.Code = this.modelObject.Code;
    tempObj.DefaultClientId = this.mainForm.getRawValue().DefaultClientId;
    tempObj.DefaultPaymentMethodId = this.mainForm.getRawValue().DefaultPaymentMethodId;
    tempObj.DiscountLimit = this.mainForm.getRawValue().DiscountLimit;
    tempObj.EnableAutoCodesForCashRegisiters = this.mainForm.getRawValue().EnableAutoCodesForCashRegisiters;
    tempObj.StartingCodeCashRegisiters = this.mainForm.getRawValue().StartingCodeCashRegisiters;
    tempObj.EnableAutoCodesForCashiers = this.mainForm.getRawValue().EnableAutoCodesForCashiers;
    tempObj.StartingCodeCashiers = this.mainForm.getRawValue().StartingCodeCashiers;
    tempObj.EnableAutoCodesForWorkShifts = this.mainForm.getRawValue().EnableAutoCodesForWorkShifts;
    tempObj.StartingCodeWorkShifts = this.mainForm.getRawValue().StartingCodeWorkShifts;
    tempObj.StartingCodeOrders = this.mainForm.getRawValue().StartingCodeOrders;
    tempObj.StartingCodeSessions = this.mainForm.getRawValue().StartingCodeSessions;
    this.executeEditObject(tempObj);
    console.log("saved object", tempObj);
  }

  executeEditObject(Object) {
    this.modelService.update(Object).subscribe(result => {
      if (result === true) {
        this.currentAction = '';
        this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyEdited"));
        this.goBack();
      }
      else {
        this.toastError(this.translate.instant("Error"), this.translate.instant("FailedEdit"));
        this.currentAction = '';
      }
    })
  }

  toggleInput(state:any, input:any){
    if(state == false){
      input.disable();
    }
    else{
      input.enable();
    }
    console.log(state);
    console.log(input);
  }

  setDiscountLimit(){
    let value = this.mainForm.getRawValue().DiscountLimit > 100 ? 100 : this.mainForm.getRawValue().DiscountLimit;
    this.mainForm.controls.DiscountLimit.setValue(value);
  }

  toastSuccess(toastHeader: string, toastBody: string): void {
    this.notifications.create(toastHeader, toastBody,
      NotificationType.Success, { timeOut: 3000, showProgressBar: true });
  }
  toastError(toastHeader: string, toastBody: string): void {
    this.notifications.create(toastHeader, toastBody,
      NotificationType.Error, { timeOut: 3000, showProgressBar: true });
  }

  goBack(){
    this.modelObject = {};
    this.currentAction = "";
    this._router.navigate(['app/aio/Points-Of-Sale/Config']); 
  }
}
