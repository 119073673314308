import { Component } from '@angular/core';

@Component({
    selector: 'create-holiday',
    templateUrl: 'create-holiday.component.html',
    styleUrls: ['create-holiday.component.scss']
})
export class CreateHolidayComponent {

}
