

export class ThirdPartyModel{
    Id?: number;
    ArabicName                       ?: string;
    EnglishName                      ?: string;
    Phone                            ?: string;
    Email                            ?: string;
    Address                          ?: string;
    Cause                          ?: string;
    
    IsBlocked                        ?: boolean;
    UserId                           ?: string;
    CreateBy                         ?: string;
    CreateDate                       ?: Date|null;
    ModifiedBy                       ?: string;
    ModifiedDate                     ?: Date|null;
    IsDeleted                        ?: boolean;
    DeletedBy                        ?: string;
    DeletedDate                      ?: Date|null;
    constructor(item ?: ThirdPartyModel){
        item = item ? item : {};
        this.Id = item.Id ? item.Id : 0;
      
       
        this.ArabicName                        = item.ArabicName                        ? item.ArabicName                        : '';
        this.EnglishName                       = item.EnglishName                       ? item.EnglishName                       : '';
        this.Phone                             = item.Phone                             ? item.Phone                             : '';
        
        this.Cause                             = item.Cause                             ? item.Cause                             : '';
        this.Email                             = item.Email                             ? item.Email                             : '';
        this.Address                           = item.Address                           ? item.Address                           : '';
        this.IsBlocked                         = item.IsBlocked                         ? item.IsBlocked                         : false;
        this.UserId                            = item.UserId                            ? item.UserId                            : '';
        this.CreateBy                          = item.CreateBy                          ? item.CreateBy                          : '';
        this.CreateDate                        = item.CreateDate                        ? item.CreateDate                        : null;
        this.ModifiedBy                        = item.ModifiedBy                        ? item.ModifiedBy                        : '';
        this.ModifiedDate                      = item.ModifiedDate                      ? item.ModifiedDate                      : null;
        this.IsDeleted                         = item.IsDeleted                         ? item.IsDeleted                         : false;
        this.DeletedBy                         = item.DeletedBy                         ? item.DeletedBy                         : '';
        this.DeletedDate                       = item.DeletedDate                       ? item.DeletedDate                       : null;
    }
}