<div class="pagecontent">

    <div id="linkButtons" style="display:block;">
   
        <div class="glide__slide ng-star-inserted glide__slide--active d-inline-block"
        style="width: 210px; margin: 5px;">
        <div class="icon-row-item" style="width: inherit; cursor: pointer;">
            <div class="card">
                <div class="card-body text-center" routerLink="List-Department">
                    <!-- <i class="simple-icon-wallet"></i> -->
                    <img src="assets/icons/Departments.png" width="40px;" height="40px;">
                    <h3>{{"Department" | translate}}</h3>
                </div>
            </div>
        </div>
    </div>
    
    <div class="glide__slide ng-star-inserted glide__slide--active d-inline-block"
    style="width: 210px; margin: 5px;">
    <div class="icon-row-item" style="width: inherit; cursor: pointer;">
        <div class="card">
            <div class="card-body text-center" routerLink="List-JobLevels">
                <!-- <i class="simple-icon-wallet"></i> -->
                <img src="assets/icons/JobLevels.png" width="40px;" height="40px;">
                <h3>{{"JobLevels" | translate}}</h3>
            </div>
        </div>
    </div>
</div>
<div class="glide__slide ng-star-inserted glide__slide--active d-inline-block"
style="width: 210px; margin: 5px;">
<div class="icon-row-item" style="width: inherit; cursor: pointer;">
    <div class="card">
        <div class="card-body text-center" routerLink="List-JobTypes">
            <!-- <i class="simple-icon-wallet"></i> -->
            <img src="assets/icons/JobTypes.png" width="40px;" height="40px;">
            <h3>{{"JobTypes" | translate}}</h3>
        </div>
    </div>
</div>
</div>
<div class="glide__slide ng-star-inserted glide__slide--active d-inline-block"
style="width: 210px; margin: 5px;">
<div class="icon-row-item" style="width: inherit; cursor: pointer;">
    <div class="card">
        <div class="card-body text-center" routerLink="List-JobTitles">
            <!-- <i class="simple-icon-wallet"></i> -->
            <img src="assets/icons/JobTitles.jpg" width="40px;" height="40px;">
            <h3>{{"JobTitles" | translate}}</h3>
        </div>
    </div>
</div>
</div>
<div class="glide__slide ng-star-inserted glide__slide--active d-inline-block"
style="width: 210px; margin: 5px;">
<div class="icon-row-item" style="width: inherit; cursor: pointer;">
    <div class="card">
        <div class="card-body text-center" routerLink="List-Employees">
            <!-- <i class="simple-icon-wallet"></i> -->
            <img src="assets/icons/Employees.png" width="40px;" height="40px;">
            <h3>{{"Employees" | translate}}</h3>
        </div>
    </div>
</div>
</div>
<div class="glide__slide ng-star-inserted glide__slide--active d-inline-block"
style="width: 210px; margin: 5px;">
<div class="icon-row-item" style="width: inherit; cursor: pointer;">
    <div class="card">
        <div class="card-body text-center" routerLink="all-sessions">
            <!-- <i class="simple-icon-wallet"></i> -->
            <img src="assets/icons/AttendanceRecord.jpg" width="40px;" height="40px;">
            <h3>{{"Attendance Record" | translate}}</h3>
        </div>
    </div>
</div>
</div>
<div class="glide__slide ng-star-inserted glide__slide--active d-inline-block"
style="width: 210px; margin: 5px;">
<div class="icon-row-item" style="width: inherit; cursor: pointer;">
    <div class="card">
        <div class="card-body text-center" routerLink="vacations-types">
            <!-- <i class="simple-icon-wallet"></i> -->
            <img src="assets/icons/VacationsTypes.png" width="40px;" height="40px;">
            <h3>{{"Vacations Types" | translate}}</h3>
        </div>
    </div>
</div>
</div>
<div class="glide__slide ng-star-inserted glide__slide--active d-inline-block"
style="width: 210px; margin: 5px;">
<div class="icon-row-item" style="width: inherit; cursor: pointer;">
    <div class="card">
        <div class="card-body text-center" routerLink="vacations">
            <!-- <i class="simple-icon-wallet"></i> -->
            <img src="assets/icons/Vacations.jpg" width="40px;" height="40px;">
            <h3>{{"Vacations" | translate}}</h3>
        </div>
    </div>
</div>
</div>
<div class="glide__slide ng-star-inserted glide__slide--active d-inline-block"
style="width: 210px; margin: 5px;">
<div class="icon-row-item" style="width: inherit; cursor: pointer;">
    <div class="card">
        <div class="card-body text-center" routerLink="holidays">
            <!-- <i class="simple-icon-wallet"></i> -->
            <img src="assets/icons/Holidays.png" width="40px;" height="40px;">
            <h3>{{"Holidays" | translate}}</h3>
        </div>
    </div>
</div>
</div>
<div class="glide__slide ng-star-inserted glide__slide--active d-inline-block"
style="width: 210px; margin: 5px;">
<div class="icon-row-item" style="width: inherit; cursor: pointer;">
    <div class="card">
        <div class="card-body text-center" routerLink="shifts">
            <!-- <i class="simple-icon-wallet"></i> -->
            <img src="assets/icons/shifts.png" width="40px;" height="40px;">
            <h3>{{"Shifts" | translate}}</h3>
        </div>
    </div>
</div>
</div>
<div class="glide__slide ng-star-inserted glide__slide--active d-inline-block"
style="width: 210px; margin: 5px;">
<div class="icon-row-item" style="width: inherit; cursor: pointer;">
    <div class="card">
        <div class="card-body text-center" routerLink="salary-items">
            <!-- <i class="simple-icon-wallet"></i> -->
            <img src="assets/icons/SalaryItems.jpg" width="40px;" height="40px;">
            <h3>{{"Salary Items" | translate}}</h3>
        </div>
    </div>
</div>
</div>

<div class="glide__slide ng-star-inserted glide__slide--active d-inline-block"
style="width: 210px; margin: 5px;">
<div class="icon-row-item" style="width: inherit; cursor: pointer;">
    <div class="card">
        <div class="card-body text-center" routerLink="contracts">
            <!-- <i class="simple-icon-wallet"></i> -->
            <img src="assets/icons/contract.png" width="40px;" height="40px;">
            <h3>{{"Contracts" | translate}}</h3>
        </div>
    </div>
</div>
</div>

<div class="glide__slide ng-star-inserted glide__slide--active d-inline-block"
style="width: 210px; margin: 5px;">
<div class="icon-row-item" style="width: inherit; cursor: pointer;">
    <div class="card">
        <div class="card-body text-center" routerLink="predecessors">
            <!-- <i class="simple-icon-wallet"></i> -->
            <img src="assets/icons/diagram-predecessor.png" width="40px;" height="40px;">
            <h3>{{"Predecessors" | translate}}</h3>
        </div>
    </div>
</div>
</div>
    </div>
</div>