import { Component, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { TableEngine } from 'src/app/invoices/TableEngine';

import { SidePopupComponent } from 'src/app/views/invoice/side-popup/side-popup.component';
import{SalariesManagerService} from '../salaries-manager.service';
import { SalariesManagerModel } from './SalariesManagerModel';
import { EmployeesManagementModel } from 'src/app/HR/Employees/EmployeesManagement/EmployeesManagementModel';
import { BranchesModel } from 'src/app/Invintory/models/BranchesModel';
import { BranchModel } from 'src/app/invoices/branches/BranchModel';
import { DepartmentModel } from 'src/app/HR/Organization_Structure/Department_components/DepartmentModel';
import { JobTitlesModel } from 'src/app/HR/Organization_Structure/JobTitels_Componentes/JobTitlesModel';
import { EmployeesManagementService } from 'src/app/HR/Employees/EmployeesManagement/employees-management.service';
import { JobTitlesService } from 'src/app/HR/Organization_Structure/JobTitels_Componentes/job-titles.service';
import { DepartmentService } from 'src/app/HR/Organization_Structure/Department_components/department.service';
import { BranchService } from 'src/app/invoices/branches/BranchService';
import {EmployeesChoiceModel} from '../add-salaries-manager/EmployeesChoiceModel';
import { EmployeesExcludedModel } from './EmployeesExcludedModel';
@Component({
    // moduleId: module.id,
    selector: 'add-salaries-manager',
    templateUrl: 'add-salaries-manager.component.html',
    styleUrls: ['add-salaries-manager.component.scss']
})
export class AddSalariesManagerComponent {

    Id = 0;

    constructor(
        
      public router: Router,
      private fb: FormBuilder,
      private modelService: SalariesManagerService,
      private EmployeesService: EmployeesManagementService,
      private JobTitleService: JobTitlesService,
      private DepartmentService: DepartmentService,
      private BranchesService: BranchService,
      private notifications: NotificationsService,
      
      private translate: TranslateService,
      private route: ActivatedRoute
    ) {
      this.Id = +route.snapshot.paramMap.get("Id");
    }
    showItemIndex = 0;
    tableEngine = new TableEngine(this.translate, this.notifications);
    currentScreen: any = "Table";
    translationName: string = "Item";
    sortableKeys = [];
    searchableKeys = ["Name", "Address"];
    tableView: any = true;
  
    SalariesManagerModel:SalariesManagerModel[]=[];
    isLastOpen = false;
  
  
    modelObject: SalariesManagerModel = new SalariesManagerModel();
    Form: FormGroup;
    declare lang: any;
    declare itemOrder: any;
    currentAction = "";
    savedPage = 0;
    currentPage = 1;
    deletionList: any[] = [];
    prevAction = "";
    addToListId = 0;
    ItemsBalanceHeaderId = 0;
    ItemBalanceId = 0;
    currentFormMode: string = "";
    type = 0;
    visable = "";
    visable2 = "";
    visable3 = "";

    Code=0;

  AlertMessage :string="Alert me when the quantity reaches less than";
    ogData: SalariesManagerModel[] = [];
    mainForm: FormGroup = new FormGroup({
        Id: new FormControl(),
        Name: new FormControl(),
        dateOfRegistration: new FormControl(),
        StartingDate: new FormControl(),
        EndDate: new FormControl(),
        CheckAttendance: new FormControl(),
        GrammarChoose: new FormControl(),
        EmployeesChoose: new FormControl(),
        BranchId: new FormControl(),
        DepartmentId: new FormControl(),
        JobTitletId: new FormControl(),
        ArrestCycleId: new FormControl(),
        AcceptAll: new FormControl(),
        IsPayment: new FormControl(),
        Code: new FormControl(),
        
    });
  

    conpanyId:number;
    
    @ViewChild("sideModalRef", { static: true })
    sideModalRef: SidePopupComponent = null;
    @ViewChild("delModal", { static: true }) delModal: ModalDirective;
    @ViewChild("smallModal", { static: true }) smallModal: ModalDirective = null;
    formPresentationModal: any;
    ngOnInit(): void {
      // this.getAllCountryCode();
  
      this.lang = localStorage.getItem("theme_lang");
      this.itemOrder = {
        label: "Name",
        property: this.lang === "ar-EG" ? "Name" : "Name",
      };
      this.sortableKeys = [
        // { label: 'Date', property: 'Date' },
        { label: 'Name', property: this.lang === 'ar-EG' ? 'Name' : 'Name' },   
        { label: 'Nickname', property: this.lang === 'ar-EG' ? 'Nickname' : 'Nickname' },   
        { label: 'MobileNumber', property: this.lang === 'ar-EG' ? 'MobileNumber' : 'MobileNumber' }, 
            
      ];
  
      this.tableEngine.loadTableEngine(
        this.ogData,
        this.modelService,
        this.modelObject,
        this.searchableKeys,
        this.sortableKeys,
        this.mainForm,
        this.translationName,
        this.itemOrder,
        this.sideModalRef,
        this.delModal,
        this.smallModal,
        this.lang
      );
      this.loadServices();
  this.EmployeesService.getAll().subscribe(data=>{
    this.EmployeesList=data;
  });
  this.BranchesService.getAll().subscribe(data=>{
    this.BranchesList=data;
  }); 
  this.DepartmentService.getAll().subscribe(data=>{
    this.DepartmentList=data;
  }); 
  this.JobTitleService.getAll().subscribe(data=>{
    this.JobTitletList=data;
  });
  this.modelService.getLastNumber()
.subscribe(code=>{
  this.modelObject.Code = code+1
  this.mainForm.controls['Code'].setValue(this.modelObject.Code);
})
     
          if(this.Id > 0)
          {   debugger;                            
                  this.modelService.getById(this.Id).subscribe(data=>{                        
                  this.modelObject = data;        
                   
                this.SelectedEmployeesChoice=data.EmployeesChoiceList;
                this.SelectedEmployeesExcluded=data.EmployeesExcluded;

                  this.mainForm = this.createForm(this.modelObject);
              });
          }
          else{
                  
             this.mainForm = this.createForm(this.modelObject);                              
          }
      this.mainForm = this.createForm(this.modelObject);
  
    }


  getMonthList(
      locales?: string | string[],
      format: "long" | "short" = "long"
    ): string[] {
      const year = new Date().getFullYear(); // 2020
      const monthList = [...Array(12).keys()]; // [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
      const formatter = new Intl.DateTimeFormat(locales, {
        month: format
      });
    
      const getMonthName = (monthIndex: number) =>
        formatter.format(new Date(year, monthIndex));
    
      return monthList.map(getMonthName);
    }

    ReverseGrammarChoose(){
        debugger;
        this.mainForm.controls['GrammarChoose'].setValue(this.modelObject.GrammarChoose=false);

            // this.mainForm.controls['GrammarChoose'].value==false;
    }
    ReverseEmployeesChoose(){
        debugger;
        this.mainForm.controls['EmployeesChoose'].setValue(this.modelObject.EmployeesChoose=false);

        // this.mainForm.controls['EmployeesChoose'].value==false;
}
    createForm(modelObject): FormGroup {
      debugger;
      return this.fb.group({
        Id: [modelObject.Id >= 0 ? modelObject.Id : 0],
        Name: [modelObject.Name, Validators.compose([Validators.required])],
        dateOfRegistration: [this.modelObject.dateOfRegistration != null ? new Date(this.modelObject.dateOfRegistration).toISOString().slice(0, 10):null, Validators.compose([Validators.required])],
        StartingDate: [this.modelObject.StartingDate != null ? new Date(this.modelObject.StartingDate).toISOString().slice(0, 10):null,Validators.compose([Validators.required])],
        EndDate: [this.modelObject.EndDate != null ? new Date(this.modelObject.EndDate).toISOString().slice(0, 10):null,Validators.compose([Validators.required])],
        CheckAttendance: [modelObject.CheckAttendance],
        GrammarChoose: [modelObject.GrammarChoose],
        EmployeesChoose: [modelObject.EmployeesChoose],
        BranchId: [modelObject.BranchId],
        DepartmentId: [modelObject.DepartmentId],
        JobTitletId: [modelObject.JobTitletId],
        ArrestCycleId: [modelObject.ArrestCycleId],
        AcceptAll: [modelObject.AcceptAll],
        IsPayment: [modelObject.IsPayment],

        
        
      });
    }
  SelectedEmployeesChoice:EmployeesManagementModel[]=[];
  SelectedEmployeesExcluded:EmployeesManagementModel[]=[];
  EmployeesList:EmployeesManagementModel[]=[];
  BranchesList:BranchModel[]=[];
  DepartmentList:DepartmentModel[]=[];
  JobTitletList:JobTitlesModel[]=[];
  
    onMaterialGroupChangeEmployeesChoise(event) {
        this.SelectedEmployeesChoice = event;
      
      }
      onMaterialGroupEmployeesExcluded(event) {
        this.SelectedEmployeesExcluded = event;
      
      }
    loadServices() {
      this.currentAction = "loading";
      this.tableEngine.loadData();
    }
    goBack() {
      this.router.navigate(["app/HR/List-Salaries-Manager"]);
    }
    Save() {
      debugger;
      this.currentAction = "loading";
      this.tableEngine.savedPage = this.tableEngine.currentPage;
      this.modelObject = new SalariesManagerModel();
      this.modelObject.Id = 0;
      this.modelObject.Name = this.mainForm.getRawValue().Name;
    //   this.modelObject.ArrestCycleId = this.mainForm.getRawValue().ArrestCycleId;
      this.modelObject.ArrestCycleId = 0;
      this.modelObject.BranchId = this.mainForm.getRawValue().BranchId;
      this.modelObject.CheckAttendance = this.mainForm.getRawValue().CheckAttendance;
      this.modelObject.DepartmentId = this.mainForm.getRawValue().DepartmentId;
      this.modelObject.EmployeesChoose = this.mainForm.getRawValue().EmployeesChoose;
      this.modelObject.GrammarChoose = this.mainForm.getRawValue().GrammarChoose;
      this.modelObject.AcceptAll = this.mainForm.getRawValue().AcceptAll;
      this.modelObject.IsPayment = this.mainForm.getRawValue().IsPayment;
      this.modelObject.Code = this.mainForm.getRawValue().Code;
      this.modelObject.JobTitletId = this.mainForm.getRawValue().JobTitletId;
      this.modelObject.StartingDate =new Date(this.mainForm.getRawValue().StartingDate);
      this.modelObject.dateOfRegistration = new Date(this.mainForm.getRawValue().dateOfRegistration);
      this.modelObject.EndDate = new Date(this.mainForm.getRawValue().EndDate);
      this.SelectedEmployeesChoice.forEach(element => {
        var cat = new EmployeesChoiceModel();
        cat.EmployeeId = element.Id;
        cat.Id = 0;
        cat.SalariesManagerId= this.Id;
        this.modelObject.EmployeesChoice.push(cat);
      });
      
      this.SelectedEmployeesExcluded.forEach(element => {
        var cat = new EmployeesExcludedModel();
        cat.EmployeeId = element.Id;
        cat.Id = 0;
        cat.SalariesManagerId= this.Id;
        this.modelObject.EmployeesExcluded.push(cat);
      });
      if (this.Id == 0) {
        this.modelService.insert(this.modelObject).subscribe((data) => {
          this.router.navigate(["app/HR/List-Salaries-Manager"]);
        });
      } else {
        debugger;
        this.modelService.update(this.modelObject).subscribe((data) => {
          this.router.navigate(["app/HR/List-Salaries-Manager"]);
        });
      }
    }

}
