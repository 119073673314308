<div style="text-align: center;" *ngIf="Form">
    <a class="btn btn-light" 
    routerLink="../../expenses"
        style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Back" |
        translate}}</a>
    <button 
        *ngIf="Form"
         id="smallModalSubmitButton"
        class="btn btn-secondary" 
        (click)="Save()"
        [disabled]="Form.invalid || TotalCreditor != TotalDebtor"
        style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{buttonName |
        translate}}</button>
        <button 
        *ngIf="Form"
        [disabled]="Form.invalid || TotalCreditor != TotalDebtor"
        id="smallModalSubmitButton"
        class="btn btn-secondary" (click)="addAndNew()"
        style="font-weight: bold; width: 250px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{buttonName+"AndNew" |
        translate}}</button>
  </div>
<Form [formGroup]="Form" *ngIf="Form ">
    <div class="card">
        <div class="form-group  Card_Padding">
                    <br>
    
       <div class="row">
        <div class="col-md-10">
        <h3>{{ buttonName + ' Expenses' | translate }}</h3>
    
        </div>
    
        <!-- <button type="button" class="btn btn-primary"(click)="onClick()">{{ 'Save' | translate }}</button>{{' '}} -->
    
       </div>
       <div class="row">
        <div class="col-md-4">
            <label for="inputEmail4">{{ 'Amount' | translate }}</label>
            <!-- <input formControlName="Amount" type="number" id="inputEmail4" class="form-control"> -->
            <div class="input-group mb-3" >
                          
                <input type="number" min="0" name="Amount" (change)="chickAmount()" class="form-control" [ngModelOptions]="{standalone: true}"
                formControlName="Amount" style=" margin: 0px;"
                placeholder='{{"Amount" | translate}}'
                [readonly]="Form.get('IsMulti').value"
                >
                <ng-select appearance="outline" 
                name="CurrencyId" class="input-group-append" 
                formControlName="CurrencyId"
                placeholder='{{"Currency" | translate}}' #EmployeeId
                labelForId="txtycd" 
                *ngIf="curennciesLoaded"
                >
                <ng-option [value]="0">{{"Currency" | translate}}</ng-option>
                <ng-option *ngFor="let item of curenncies" [value]="item.Id">{{lang==='ar-EG'?item.Code:item.Code}}</ng-option>
            </ng-select>    
            </div>
        </div>
        <!-- <div class="col-md-2">
            <label for="inputEmail4">{{ 'Currency' | translate }}</label>
            <ng-select  *ngIf="lang==='ar-EG'"  formControlName="CurrencyId" bindValue="Id"[items]="curenncies"   [hideSelected]="true"  bindLabel="NameAr">
            </ng-select>
            <ng-select *ngIf="lang!=='ar-EG'"  formControlName="CurrencyId" bindValue="Id"[items]="curenncies"  [hideSelected]="true"  bindLabel="NameEn">
          </ng-select>        </div> -->
        <div class="col-md-4">
            <label for="inputEmail4">{{ 'Description' | translate }}</label>
            <textarea formControlName="Description" type="text" id="inputEmail4" class="form-control"></textarea>
        </div>
        <div class="col-md-4">
            <label for="inputEmail4">{{ 'Documents' | translate }}</label>
            <upload-image [(url)]=picurl (urlChange)="setFileToList()" [editmode]=editEnabled></upload-image>
            <table class="table">
               <tbody>
                <tr *ngFor="let file of files;let i = index">
                    <td>#{{i+1}}</td>
                    <td><a href="{{file.FileUrl}}" target="_blank" style="cursor: pointer;" download="">{{"Download"|translate}}</a></td>
                    <td>
                        <a href="javascript:" (click)="deleteFile(file)"><i class="simple-icon-trash"></i></a>
                    </td>
                </tr>
               </tbody>
            </table>
        </div>
    </div>
            <br>
            <div class="row">
                <div class="col-md-4">
                    <label for="inputEmail4">{{ 'Code' | translate }}</label>
                    <input formControlName="Code" type="number" id="inputEmail4" class="form-control" readonly>
                </div>
             
                <div class="col-md-4">
                    <label for="inputEmail4">{{ 'Date' | translate }}</label>
                    <input formControlName="Date" type="Date" id="inputEmail4" class="form-control">
                </div>
                <div class="col-md-4">
                    <label for="inputEmail4">{{ 'Invoice' | translate }}</label>
                  <ng-select   formControlName="PurchaseInvoiceId"  (change)="GetPayedAmount()"  [hideSelected]="true">
                    <ng-option selected desabled [value]="0">{{ "Invoice"|translate}}</ng-option>
                    <ng-option *ngFor="let car of invoices" [value]="car.Id">{{car.Code}}#</ng-option>
                </ng-select>
                </div>
            </div>
        <br />
        <br>
        <div class="row">
            <div class="col-md-4">
                <label for="inputEmail4">{{ 'The Recipient' | translate }}</label>
              <ng-select   formControlName="TheRecipient"    [hideSelected]="true">
                <ng-option selected desabled [value]="0">{{ "Please Choose"|translate}}</ng-option>
                <ng-option *ngFor="let car of employees" [value]="car.Id">{{ lang==='ar-EG'? car.Name:car.Name}}</ng-option>

            </ng-select>
            </div>
            <div class="col-md-4">
                <label for="inputEmail4">{{ 'Category' | translate }}</label>
                <ng-select *ngIf="categoriesLoaded"  formControlName="CategoryId"    [hideSelected]="true">
                    <ng-option selected desabled [value]="0">{{ "Please Choose"|translate}}</ng-option>
                    <ng-option *ngFor="let car of categories; " [value]="car.Id">{{ lang==='ar-EG'? car.NameAr:car.NameEn}}</ng-option>
                </ng-select>
                <mat-progress-bar *ngIf="!categoriesLoaded" mode="query"></mat-progress-bar>

            </div>
            <div class="col-md-4">
                <label for="inputEmail4">{{ 'Treasury' | translate }}</label>
                <ng-select *ngIf="TreasuriesLoaded"  formControlName="Treasury"  [hideSelected]="true">
                    <ng-option selected desabled [value]="0">{{ "Please Choose"|translate}}</ng-option>
                    <ng-option *ngFor="let car of Treasuries; " [value]="car.Id">{{ car.Name}}</ng-option>
                </ng-select>
        
            </div>
        </div>
        <div class="row">
            <div class="col-md-4" *ngIf="!Form.get('IsMulti').value">
                <label for="inputEmail4">{{ 'Account' | translate }}</label>
                <a *ngIf="lang==='ar-EG'"(click)="setIsMulti(true)" style="float: left;font: bold;cursor: pointer;text-decoration: underline">{{ 'Multi' | translate }}</a>
                <a *ngIf="lang!='ar-EG'" (click)="setIsMulti(true)" style="float: right;font: bold;cursor: pointer;text-decoration: underline">{{ 'Multi' | translate }}</a>
                <ng-select  *ngIf="AccountsLoaded" formControlName="AccountId"    [hideSelected]="true">
                    <ng-option selected desabled [value]="0">{{ "Please Choose"|translate}}</ng-option>
                    <ng-option *ngFor="let car of AccountsNameModel; " [value]="car.Id">{{ lang==='ar-EG'? car.NameAr:car.NameEn}}</ng-option>
                </ng-select>
             
            </div>
            <div class="col-md-4" *ngIf="!Form.get('IsMulti').value">
                <label for="inputEmail4">{{ 'Tax' | translate }}</label>
                <ng-select *ngIf="taxesLoaded"  formControlName="TaxId"    [hideSelected]="true">
                    <ng-option selected desabled [value]="0">{{ "Please Choose"|translate}}</ng-option>
                    <ng-option *ngFor="let car of taxes; " [value]="car.Id">{{ lang==='ar-EG'? car.NameAr:car.NameEn}}</ng-option>
                </ng-select>
       
            </div>
            <div class="col-md-4">
                <input type="checkbox" 
                formControlName="IsFrequent"
                style="margin-top: 30px;"
                > 
                {{'Frequent'|translate}}
                <br />
                <div *ngIf="Form.get('IsFrequent').value">
                    <div class="col-md-12">
                        <label for="inputEmail4">{{ 'Frequent Type' | translate }}</label>
                        <select formControlName="FrequentType" class="custom-select">
                            <option [value]="'Weekly'">{{'Weekly'|translate}}</option>
                            <option [value]="'2 Week'">{{'2 Week'|translate}}</option>
                            <option [value]="'4 Weeks'">{{'4 Weeks'|translate}}</option>
                            <option [value]="'Monthly'">{{'Monthly'|translate}}</option>
                            <option [value]="'2 Months'">{{'2 Months'|translate}}</option>
                            <option [value]="'3 Months'">{{'3 Months'|translate}}</option>
                            <option [value]="'6 Months'">{{'6 Months'|translate}}</option>
                            <option [value]="'Yearly'">{{'Yearly'|translate}}</option>
                            <option [value]="'2 Year'">{{'2 Year'|translate}}</option>
                        </select>
                    </div>
                    <div class="col-md-12">
                        <label for="inputEmail4">{{ 'Expiry Date' | translate }}</label>
                        <input formControlName="ExpiryDate" type="Date" id="inputEmail4" class="form-control">
                    </div>
                </div>
                <div *ngIf="!Form.get('IsMulti').value">
                <input  type="checkbox" 
                formControlName="WithCostCenters"
                style="margin-top: 10px;"
                > 
                {{'Set cost centers'|translate}}
            </div>
            </div>
        </div>
    <br />
        </div>
    
    </div>
</Form>
<br />
<br />

<div class="card" *ngIf="Form">
    <div *ngIf="Form.get('IsMulti').value">
    <div class="row card-header" style="text-align: center; margin-top: 10px;">
        <div class="col-md-10" style="text-align: center;">
            <th>{{"Accounts"|translate}}</th>
        </div>
        <div class="col-md-2 right" style="text-align: right;">
            <a (click)="setIsMulti(false)" class="right" style="float: left;font: bold;cursor: pointer;text-decoration: underline">{{"Individually"|translate}}</a>
        </div>
       </div>
    <div class="card-body">
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">{{'Account'| translate}}</th>
                    <th scope="col">{{'Description'| translate}}</th>
                    <th scope="col">{{'Amount'| translate}}</th>
                    <th scope="col">{{'Delete'| translate}}</th>
                </tr>
            </thead>
            <tbody>
                <tr  *ngFor="let Item of SelectedAccount; let i=index;">
                    <th style="width: 10%;" scope="row">{{i+1}}</th>
                    <td style="width: 30%;">
                        <ng-select  *ngIf="lang==='ar-EG'"  [(ngModel)]="Item.AccountId" bindValue="Id"[items]="AccountsNameModel" (change)="splitAccount($event)"  [hideSelected]="true"  bindLabel="NameAr">
                        </ng-select>
                        <ng-select *ngIf="lang!=='ar-EG'"  [(ngModel)]="Item.AccountId" bindValue="Id"[items]="AccountsNameModel" (change)="splitAccount($event)"  [hideSelected]="true"  bindLabel="NameEn">
                      </ng-select>
                    </td>
                    <td style="width: 30%;">
                        <input [(ngModel)]="Item.Description" type="text" class="form-control">
                    </td>
                    <td style="width: 20%;"><input [(ngModel)]="Item.Amount" (change)="changeAccountAmount()" type="number" class="form-control"></td>
                    <td style="width: 10%;">
                        <a href="javascript:" tooltip="{{'Delete'|translate}}" (click)="deleteSelectedAccount(Item)"><i class="simple-icon-trash"></i></a>
                    </td>
                </tr>
              
            </tbody>
        </table>

        <div class="col-12 row">
            <div class=" col-md-11">
            </div>
        <button type="button" class="btn btn-primary"(click)="AddonClick()">{{ '+ Add' | translate }}</button>{{' '}}

          </div>
        <br>
    </div>
</div>
</div>
<div class="card" style="margin-top: 20px;" *ngIf="Form">
     
    
    <div class="card-body" *ngIf="!Form.get('IsMulti').value&&Form.get('WithCostCenters').value">
        <div class="row card-header" style="text-align: center;">
            <div class="col-md-10" style="text-align: center;">
                <th>{{"Cost Centers"|translate}}</th>
            </div>
            <!-- <button type="button" class="btn btn-primary"(click)="onClick()">{{ 'Save' | translate }}</button>{{' '}} -->
           </div>
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">{{'Cost Center'| translate}}</th>
                    <th scope="col">{{'Percent'| translate}}</th>
                    <th scope="col">{{'Amount'| translate}}</th>
                    <th scope="col">{{'Delete'| translate}}</th>
 
                </tr>
            </thead>
            <tbody>
                <tr  *ngFor="let cost of SelectedCostCenters; let i=index;">
                    <th style="width: 10%;" scope="row">{{i + 1}}</th>
                    <td style="width: 30%;">
                        <ng-select  *ngIf="lang==='ar-EG' && centersLoaded"  [(ngModel)]="cost.CostCenterId" bindValue="Id"[items]="centers"   [hideSelected]="true"  bindLabel="NameAr">
                        </ng-select>
                        <ng-select *ngIf="lang!=='ar-EG' && centersLoaded"  [(ngModel)]="cost.CostCenterId" bindValue="Id"[items]="centers"  [hideSelected]="true"  bindLabel="NameEn">
                      </ng-select>
                    </td>
            
                    <td style="width: 20%;"><input [(ngModel)]="cost.Percent" (change)="changePercent(cost,$event)"type="number" class="form-control"></td>
                    <td style="width: 20%;"><input [(ngModel)]="cost.Amount" (change)="changeAmount(cost,$event)"  type="number" class="form-control "></td>
                    <td style="width: 20%;">
                        <a href="javascript:" tooltip="{{'Delete'|translate}}" (click)="deleteCostCenter(cost)"><i class="simple-icon-trash"></i></a>
                    </td>
                </tr>
            </tbody>
        </table>
        
        <div class="col-12 row">
            <div class=" col-md-11">
            </div>
        <button type="button" class="btn btn-primary"(click)="AddCostCenter()">{{ '+ Add' | translate }}</button>{{' '}}

          </div>
    </div>
</div>