import { CostCenterReportSearchModel } from './../../models/cost-center-report-search.model';
import { CostCenterReportDataModel } from './../../models/cost-center-ceport-data.mdel';
import { Component, OnInit } from '@angular/core';
import { AccountesNameModel } from 'src/app/Invintory/models/AccountesNameModel';
import { CostCenterReportService } from '../../cost-center-report.service';
import { BranchService } from 'src/app/invoices/branches/BranchService';
import { AccountsNameService } from '../../../../account-name/AccountNameService';
import { BranchModel } from '../../../../test-component/BranchModel';
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';
import { CostCenterService } from '../../../../cost-center/services/cost-center.service';
import { CostCenterModel } from '../../../../cost-center/models/cost-center.model';
@Component({
    selector: 'cost-center-report',
    templateUrl: 'cost-center-report.component.html',
    styleUrls: ['cost-center-report.component.scss']
})
export class CostCenterReportComponent implements OnInit{

      declare lang: any;
      data:CostCenterReportDataModel[];
      search:CostCenterReportSearchModel={};
      accounts:AccountesNameModel[]=[];
      constructor(private CostCenterReportService:CostCenterReportService,
          private branchService:BranchService,
          private accountService:AccountsNameService,
          private costCenterService:CostCenterService
          ){
        this.lang = localStorage.getItem('theme_lang');
      }
      TotalAssets = 0;
      Totalliabilities = 0;
      TotalPropertyRights = 0;
      branches:BranchModel[]=[];
      today=new Date();
      mainCenters:CostCenterModel[]=[];
      subCenters:CostCenterModel[]=[];
      BackUpmainCenters:CostCenterModel[]=[];
      BackUpsubCenters:CostCenterModel[]=[];
      ngOnInit(): void {
        debugger;
          this.CostCenterReportService.Search(this.search)
          .subscribe(data => {
            this.data =data;
            console.log('data',this.data)
          })
          this.branchService.getAll()
          .subscribe(branches=>{
            this.branches = branches;
          })
        this.accountService.GetAllSubAccountes(0)
          .subscribe(data => {
              this.accounts = data;
          })
          this.costCenterService.getAll(0)
          .subscribe(data=>{
            console.log('Centers',data)
            data.forEach(element => {
                if(element.CenterType == "Main"){
                    this.mainCenters.push(element);
                }
                else{
                    this.subCenters.push(element);
                }
             
            });
            this.BackUpmainCenters = [...this.mainCenters];
            this.BackUpsubCenters = [...this.subCenters];
          })
      }
      Search(){
          this.CostCenterReportService.Search(this.search)
          .subscribe(data => {
            this.data =data;
         
          })
      }
      exportTable(){
        var data = document.getElementById('contentToConvert');
        data.style.display = 'inline-block';
        html2canvas(data,{}).then(canvas => {
        var imgWidth = 208;
        var pageHeight = 295;
        var imgHeight = canvas.height * imgWidth / canvas.width;
        var heightLeft = imgHeight;
        const contentDataURL = canvas.toDataURL('image/png')
        let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
        var position = 0;
        pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
        pdf.save('new-file.pdf'); // Generated PDF
        data.style.display = 'block';
  
      //   data.style.display = 'none';
        
      });
      }
      print(){
          let printContents, popupWin;
          printContents = document.getElementById('contentToConvert').innerHTML;
          popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
          popupWin.document.open();
          window.print();
         
        }
        changeMainCostCenter(){
            var i = this.mainCenters.findIndex(x=>x.Id == this.search.MainCostCenterId);
            if(i > -1){
                this.subCenters = this.BackUpsubCenters.filter(x=>x.ParentId == this.mainCenters[i].Id);
            }
        }
        changeSubCostCenter(){
            
        }
  }
