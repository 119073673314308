
export class EmployeesExcludedModel{
    Id?: number;
    EmployeeId?: number;
    SalariesManagerId?: number;
    EmployeesName?:string;
   
   
   
    constructor(item?:EmployeesExcludedModel){
        item = item? item:{};
        this.Id = item.Id? item.Id:0;
        this.EmployeeId = item.EmployeeId? item.EmployeeId:0;
        this.SalariesManagerId = item.SalariesManagerId? item.SalariesManagerId:0;
        this.EmployeesName = item.EmployeesName? item.EmployeesName:'0';
  
 
      

    }

}