

  <div class="row">
    <div class="col-12">
      <div class="card mb-4">
        <div class="card-body">
          <!-- <h5 class="mb-4">{{ 'From Date' | translate }}</h5> -->
          <div class="row">
            <div class="col-12 col-lg-6 mb-4">
              <label><span>{{ 'DateFrom' | translate }}</span></label>
              <input 
              type="text" onfocus="(this.type='date')" onblur="if(!this.value)this.type='text'"
              onchange="if(!this.value)this.type='text'" name="Date" class="form-control"
              [(ngModel)]="search.FromDate"
              placeholder='{{"DateFrom" | translate}}'>
            </div>
            <div class="col-12 col-lg-6 mb-4">
              <label><span>{{ 'DateTo' | translate }}</span></label>
              <input 
              type="text" onfocus="(this.type='date')" onblur="if(!this.value)this.type='text'"
              onchange="if(!this.value)this.type='text'" name="Date" class="form-control"
              [(ngModel)]="search.ToDate"
              placeholder='{{"DateTo" | translate}}'>
            </div>
            <div class="col-12 col-lg-6 mb-4 "  >
              <button type="button" (click)="Search()" class="btn btn-primary default mb-1" style="margin: 1px;">{{'Search' | translate}}</button>{{" "}}
              <button type="button" (click)="print()" class="btn btn-secondary default mb-1 " style="margin: 1px;">{{'Print' | translate}}</button>{{" "}}
              <button type="button" (click)="exportTable()" class="btn btn-success default mb-1" style="margin: 1px;">{{'PDF' | translate}}</button>{{" "}}
              <button type="button"  class="btn btn-primary default mb-1" style="margin: 1px;">{{'Excel' | translate}}</button>{{" "}}
            
            </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  <div id="contentToConvert">
  <div class="row" >
    <div class="col-12">
      <div class="card mb-4">
        <div class="card-body">
            <h3 class="float-center" style="text-align: center;">{{ 'Balance Sheet' | translate }}</h3>
            
            <h4 class="float-center" style="text-align: center;" *ngIf="search.FromDate">{{'From Date'|translate}}{{  search.FromDate| date:'dd/MM/yyyy' }}</h4>
            <h4 class="float-center" style="text-align: center;" *ngIf="search.ToDate">{{'To Date'|translate}}{{  search.ToDate| date:'dd/MM/yyyy' }}</h4>
            <h4 class="float-center" style="text-align: center;" *ngIf="!search.ToDate">{{'To Date'|translate}}{{  today| date:'dd/MM/yyyy' }}</h4>
            <h6 class="float-center" style="text-align: center;">{{ 'Future Core' | translate }}</h6>
           
        </div>
      </div>
    </div>
  </div>
  
  <div class="card mb-5 tree-explorer-side col-md-5" *ngIf="data">
    <div class="card-body ">
    <div class="row">
        <table class="table" *ngIf="data.Assets && data.Assets.length>0">
            <thead>
                <th>{{'Account'|translate}}</th>
                <th>{{'Total'|translate}}</th>
            </thead>
            <tbody>
                <tr style="background-color:#cfcaca;">
                    <th>{{'Assets'|translate}}</th>
                    <th>{{TotalAssets}}</th>
                </tr>
                <tr *ngFor="let item of data.Assets">
                    <!-- <tr *ngFor="let child of item.Children">
                        <td >{{child.NameAr}}</td>
                        <td>{{child.Value}}</td>
                    </tr> -->
                    <td >{{lang==='ar-EG'?item.KeyAr:item.KeyEn}}</td>
                    <td>{{item.Value}}</td>
                </tr>
            </tbody>
      
        </table>
    </div>

    </div>
  </div>
  <div class="card mb-5 tree-explorer-side col-md-5" *ngIf="data">
    <div class="card-body ">
    <div class="row">
        <table class="table" *ngIf="data.CapitalAndPropertyRights && data.CapitalAndPropertyRights.length>0">
            <thead>
                <th>{{'Account'|translate}}</th>
                <th>{{'Total'|translate}}</th>
            </thead>
            <tbody>
                <tr style="background-color:#cfcaca;">
                    <th>{{'liabilities'|translate}}</th>
                    <th>{{Totalliabilities}}</th>
                </tr>
                <tr *ngFor="let item of data.Liabilities">
             
                    <td >{{lang==='ar-EG'?item.KeyAr:item.KeyEn}}</td>
                    <td>{{item.Value}}</td>
                </tr>
                <tr style="background-color:#cfcaca;">
                    <th>{{'PropertyRights'|translate}}</th>
                    <th>{{TotalPropertyRights}}</th>
                </tr>
                <tr *ngFor="let item of data.CapitalAndPropertyRights">
                    <!-- <tr *ngFor="let child of item.Children">
                        <td >{{child.NameAr}}</td>
                        <td>{{child.Value}}</td>
                    </tr> -->
                    <td >{{lang==='ar-EG'?item.KeyAr:item.KeyEn}}</td>
                    <td>{{item.Value}}</td>
                </tr>
            </tbody>
      
        </table>
    </div>

    </div>
  </div>
  </div>