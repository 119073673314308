
        <h1 style="display: block;">
            {{"CurrentCompany" | translate}}
        </h1>
        <div class="collapse dont-collapse-sm" style="display: block;" id="displayOptions"
            [collapse]="displayOptionsCollapsed">
            <div class="d-block d-md-inline-block" style="margin-left: 10px; margin-right: 10px;">
                <div class="float-md-left mr-1 mb-1" dropdown>
                    <span dropdown class="d-inline-block position-relative">
                        <button id="button-basic" dropdownToggle type="button"
                            class="btn btn-outline-dark btn-xs dropdown-toggle" aria-controls="dropdown-basic"
                            style="width: 120px; text-align: left;">
                            {{lang==='ar-EG'?currentCompany.NameAr:currentCompany.NameEn}} <span class="caret"></span>
                        </button>
                        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu"
                            aria-labelledby="button-basic">
                            <li role="menuitem" *ngFor="let item of companyApiList"><a
                                    [ngClass]="item === currentCompany ? 'dropdown-item active' : 'dropdown-item'"
                                    (click)="changeCompany(item)">{{lang==='ar-EG'?item.NameAr:item.NameEn| translate}}</a></li>
                        </ul>
                    </span>
                </div>
            </div>
        </div>