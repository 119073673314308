
export class QuotationRequestItemModel {
    Id?: number;
    Code?: number;
    QuotationRequestId ?: number;
    QuotationRequestCode ?: number;
    ItemId?: number;
    ItemNameAr?: string;
    ItemNameEn?: string;
    UnitId?: number;
    UnitNameAr?: string;
    UnitNameEn?: string;
    Quantity?: number;
    constructor(obj?: QuotationRequestItemModel) {
        obj = obj ? obj : {};
        this.Id = obj.Id ? obj.Id : 0;
        this.Code = obj.Code ? obj.Code : 0;
        this.QuotationRequestId  = obj.QuotationRequestId  ? obj.QuotationRequestId  : 0;
        this.QuotationRequestCode  = obj.QuotationRequestCode  ? obj.QuotationRequestCode  : 0;
        this.ItemId  = obj.ItemId  ? obj.ItemId  : null;
        this.ItemNameAr  = obj.ItemNameAr  ? obj.ItemNameAr  : '';
        this.ItemNameEn  = obj.ItemNameEn  ? obj.ItemNameEn  : '';
        this.UnitId   = obj.UnitId   ? obj.UnitId   : null;
        this.UnitNameAr  = obj.UnitNameAr  ? obj.UnitNameAr  : '';
        this.UnitNameEn  = obj.UnitNameEn  ? obj.UnitNameEn  : '';
        this.Quantity   = obj.Quantity   ? obj.Quantity   : null;
    }

}