export class ContractingBusinessExtractDetailsModel{
    Id?: number;
    ProjectContractorsItemsId   ?: number;
    Previous                    ?: number;
    Current                     ?: number;
    PreviousAndCurrentTotal     ?: number;
    Total                       ?: number;
    Price                       ?: number;
    TheTotalValue               ?: number;
    ExcutionPercentage          ?: number;
    ContractingBusinessExtractId?: number;
    itemNameEn?:string;
    itemNameAr?:string;
    constructor(item ?: ContractingBusinessExtractDetailsModel){
        item = item ? item :{};
        this.Id = item.Id ? item.Id : 0;
        this.ProjectContractorsItemsId    = item.ProjectContractorsItemsId    ? item.ProjectContractorsItemsId    : 0;
        this.Previous                     = item.Previous                     ? item.Previous                     : 0;
        this.Current                      = item.Current                      ? item.Current                      : 0;
        this.PreviousAndCurrentTotal      = item.PreviousAndCurrentTotal      ? item.PreviousAndCurrentTotal      : 0;
        this.Total                        = item.Total                        ? item.Total                        : 0;
        this.Price                        = item.Price                        ? item.Price                        : 0;
        this.TheTotalValue                = item.TheTotalValue                ? item.TheTotalValue                : 0;
        this.ExcutionPercentage           = item.ExcutionPercentage           ? item.ExcutionPercentage           : 0;
        this.ContractingBusinessExtractId = item.ContractingBusinessExtractId ? item.ContractingBusinessExtractId : 0;
        this.itemNameEn = item.itemNameEn ? item.itemNameEn : '';
        this.itemNameAr = item.itemNameAr ? item.itemNameAr : '';
    }
}