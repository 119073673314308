export class DepositTreasuryAndBankRolesBranchesModel{
    Id?: number;
    TreasuryAndBankAccountId?: number;
    BranchId?: number;
    constructor(item ?: DepositTreasuryAndBankRolesBranchesModel){
        item =item ? item : {};
        this.Id = item .Id ? item.Id : 0;
        this.BranchId = item.BranchId? item.BranchId: 0;
        this.TreasuryAndBankAccountId = item.TreasuryAndBankAccountId ? item.TreasuryAndBankAccountId : 0;
    }
}