

<div class="row">
    <div class="col-12">
      <div class="card mb-4">
        <div class="card-body">
          <!-- <h5 class="mb-4">{{ 'From Date' | translate }}</h5> -->
          <div class="row">
          
            <div class="col-12 col-lg-4 mb-4">
              <label><span>{{ 'DateFrom' | translate }}</span></label>
              <input 
              type="text" onfocus="(this.type='date')" onblur="if(!this.value)this.type='text'"
              onchange="if(!this.value)this.type='text'" name="Date" class="form-control"
              [(ngModel)]="search.From"
              placeholder='{{"DateFrom" | translate}}'>
            </div>
            <div class="col-12 col-lg-4 mb-4">
              <label><span>{{ 'DateTo' | translate }}</span></label>
              <input 
              type="text" onfocus="(this.type='date')" onblur="if(!this.value)this.type='text'"
              onchange="if(!this.value)this.type='text'" name="Date" class="form-control"
              [(ngModel)]="search.To"
              placeholder='{{"DateTo" | translate}}'>
            </div>
            <div class="col-12 col-lg-4 mb-4">
              <label><span>{{ 'Client' | translate }}</span></label>
         
              <ng-select placeholder='{{"Client" | translate}}' *ngIf="lang==='ar-EG'" [items]="clients" bindLabel="NameAr" bindValue="Id" [(ngModel)]="search.ClientId">
              </ng-select>
              <ng-select placeholder='{{"Client" | translate}}' *ngIf="lang!='ar-EG'" [items]="clients" bindLabel="NameEn" bindValue="Id" [(ngModel)]="search.ClientId">
              </ng-select>
            </div>
         
         
            <div class="col-12 col-lg-6 mb-4 "  >
              <button type="button" (click)="Search()" class="btn btn-primary default mb-1" style="margin: 1px;">{{'Search' | translate}}</button>{{" "}}
              <button type="button" (click)="print()" class="btn btn-secondary default mb-1 " style="margin: 1px;">{{'Print' | translate}}</button>{{" "}}
              <button type="button" (click)="exportTable()" class="btn btn-success default mb-1" style="margin: 1px;">{{'PDF' | translate}}</button>{{" "}}
            </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  <div id="contentToConvert">
    <div class="row" >
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-body">
              <h3 class="float-center" style="text-align: center;">{{ 'Debt Reconstruction Report' | translate }}</h3>
              <h4 class="float-center" style="text-align: center;">{{  today| date:'dd/MM/yyyy' }}</h4>
              <h6 class="float-center" style="text-align: center;">{{ 'Future Core' | translate }}</h6>
             
          </div>
        </div>
      </div>
    </div>
  
  
  <div class="card mb-12 tree-explorer-side col-md-12" *ngIf="data">
      <div class="card-body ">
      <div class="row">
          <table class="table table-striped">
              <thead>
            
                <tr>
                    <th>{{ 'Client' | translate }} </th>
                    <th>{{ 'Current' | translate }} </th>
                    <th>{{ 'Days0_30' | translate }}</th>
                    <th>{{ 'Days31_60' | translate }}</th>
                    <th>{{ 'Days61_90' | translate }} </th>
                    <th>{{ 'Days91_120' | translate }} </th>
                    <th>{{ 'DaysMoreTan120' | translate }} </th>
                    <th>{{ 'Total' | translate }} </th>
                    
                </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let item of data.Details">
                    <td>{{lang==='ar-EG'?item.ClentNameAr:item.ClentNameEn}}</td>
                      <td>{{item.Current}}</td>
                      <td>{{item.Days0_30}}</td>
                      <td>{{item.Days31_60}}</td>
                      <td>{{item.Days61_90}}</td>
                      <td>{{item.Days91_120}}</td>
                      <td>{{item.DaysMoreTan120}}</td>
                      <th style="font: bold;">{{item.Total}}</th>
                      
                  </tr>
                  <tr >
                    <th style="font: bold;">{{ 'Total' | translate }} </th>
                    <th style="font: bold;">{{data.Current}}</th>
                    <th style="font: bold;">{{data.Days0_30}}</th>
                    <th style="font: bold;">{{data.Days31_60}}</th>
                    <th style="font: bold;">{{data.Days61_90}}</th>
                    <th style="font: bold;">{{data.Days91_120}}</th>
                    <th style="font: bold;">{{data.DaysMoreTan120}}</th>
                    <th style="font: bold;">{{data.Total}}</th>
                    
                </tr>
              </tbody>
          </table>
      
      </div>
  
      </div>
    </div>
  </div>