
export class AccountBalanceModel{
    Id?:number;
    Date?:Date;
    BalanceNameId?:number;
    Totaldebit?:number;
    TotalCredit?:number;
    Totalbalance?:number;
   
    accountBalanceFooterList?:AccountBalanceFooterModel[];
    IsPosted?:boolean;
    
    constructor(obj?:AccountBalanceModel){
        obj = obj? obj:{};
        this.Id = obj.Id? obj.Id:0;
        this.Date = obj.Date? obj.Date:new Date();
        this.BalanceNameId = obj.BalanceNameId? obj.BalanceNameId:0;
        this.Totaldebit = obj.Totaldebit? obj.Totaldebit:0;
        this.TotalCredit = obj.TotalCredit? obj.TotalCredit:0;
        this.Totalbalance = obj.Totalbalance? obj.Totalbalance:0;
       
        this.accountBalanceFooterList = obj.accountBalanceFooterList? obj.accountBalanceFooterList:[];
        this.IsPosted = obj.IsPosted? obj.IsPosted:false;

    }
}
export class AccountBalanceFooterModel{
    Id?:number;
    Accountsnameid?:number;
    Debit?:number;
    Credit?:number;
   
    
    constructor(obj?:AccountBalanceFooterModel){
        obj = obj? obj:{};
        this.Id = obj.Id? obj.Id:0;
        this.Accountsnameid = obj.Accountsnameid? obj.Accountsnameid:0;
        this.Debit = obj.Debit? obj.Debit:0;
        this.Credit = obj.Credit? obj.Credit:0;
    }
}

export class SelectedAccountBalanceFooterModel{
    Id?:number;
    NameAr?:string;
    NameEn?:string;
    Debit?:number;
    Credit?:number;

    constructor(obj?:SelectedAccountBalanceFooterModel){
        obj = obj? obj:{};
        this.Id = obj.Id? obj.Id:0;
        this.NameAr = obj.NameAr? obj.NameAr:'';
        this.NameEn = obj.NameEn? obj.NameEn:'';
        this.Debit = obj.Debit? obj.Debit:0;
        this.Credit = obj.Credit? obj.Credit:0;
    }
}