import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CurrencyModel } from 'src/app/invoices/currencies/CurrencyModel';
import { CurrencyService } from 'src/app/invoices/currencies/CurrencyService';
import { AccountNameModel } from '../account-name/AccountNameModel';
import { AccountsNameService } from '../account-name/AccountNameService';
import { BasicModel } from '../account-type/helper/model';
import { AccountsTypeService } from '../account-type/helper/serves';
import { BankCodeModel } from '../bank-code/bank_code_model';
import { BankCodeService } from '../bank-code/serves';
import { DailyrestrictionsModel } from './DailyrestrictionsModel';
import { DailyrestrictionsService } from './DailyrestrictionsService';
import { DailyrestrictionsFooterModel } from './DailyrestrictionsModel';
import { SelectedDailyrestrictionsFooterModel } from './DailyrestrictionsModel';
@Component({
    // moduleId: module.id,
    selector: 'general-account',
    templateUrl: 'general-account.component.html',
    styleUrls: ['general-account.component.scss']
})
export class GeneralAccountComponent  {

    // accountsTypeModel: BasicModel[] = [];
    AccountsNameModel: AccountNameModel[] = [];
    HeaderAccountNameList: AccountNameModel[] = [];
    FooterAccountNameList: AccountNameModel[] = [];
    BankCodeList: BankCodeModel[] = [];
    CurrencyList: CurrencyModel[] = [];
    
    DailyrestrictionsModel: DailyrestrictionsModel=new DailyrestrictionsModel();
    DailyrestrictionsFooterModel: DailyrestrictionsFooterModel[] = [];
    SelectedDailyrestrictionsFooterModel: SelectedDailyrestrictionsFooterModel[] = [];
    
    // SaveIsValid=false;
    HeaderFormGroup:FormGroup;
    FooterFormGroup:FormGroup;
    TotalCreditor:number=0;
    TotalDebtor:number=0;
    Total:number=0;
    constructor( private BankCodeService: BankCodeService, private DailyrestrictionsService: DailyrestrictionsService,private CurrencyService: CurrencyService, private accountsNameService: AccountsNameService,){
        this.HeaderFormGroup = new FormGroup({
            HeaderAccountNameDropdwon: new FormControl(null, Validators.required,),
            HeaderBankCodeDropdwon: new FormControl(null, Validators.required,),
            HeaderCurrencyDropdwon: new FormControl(null, Validators.required,),
            HeaderDescription: new FormControl(null, Validators.required,),
            
            startDate: new FormControl(null, Validators.required),
          });
        //   this.FooterFormGroup = new FormGroup({
        //     DebtorControll: new FormControl(0,),
        //     CreditorControll: new FormControl(0,),

        //   });
       
        // this.accountsTypeService.getAll().subscribe(
        //     data=>{
        //         this.accountsTypeModel=data;
        //         console.log("accountsTypeModel",this.accountsTypeModel); 
        //     }
        // );
        this.BankCodeService.getAll().subscribe(
            data=>{
                this.BankCodeList=data;
            }
        );
        this.CurrencyService.getAll().subscribe(
            data=>{
               this. CurrencyList=data;
            }
        );
        this.accountsNameService.getAll(0).subscribe(
            data=>{
                this.AccountsNameModel=data;
                this.HeaderAccountNameList=data;
                this.FooterAccountNameList=data;
                // this.SelectedDailyrestrictionsFooterModel =data;
                // console.log("AccountsNameList",this.accountsTypeModel); 
            }
        );
    }
    // createMainFormForm():FormGroup{
    //     return   this.MainFormGroup = new FormGroup({
    //         BalanceNameDropdwon: new FormControl(null, Validators.required,),
    //         startDate: new FormControl(null, Validators.required),
    //         DebtorControll: new FormControl(0,),
    //         CreditorControll: new FormControl(0,),

    //       });
    //   }
    // onChangeHeaderAccountNameList(value){
    //     this.FooterAccountNameList=[];
    //    this.HeaderAccountNameList.forEach(
    //     item=>{
    //       if ( item.Parentcode == value ){
    //        this.FooterAccountNameList.push(item)
    //     }
    //     }
    //    )
        
    // }
    changeDebtor(Item,$event){
        var ItemValue = +$event.target.value;
        console.log("addItem",ItemValue);
        console.log("ItemId",Item["Id"]);
         let indexselected = this.SelectedDailyrestrictionsFooterModel.findIndex(i => i.Id == Item["Id"]);
        console.log("AccountNameId",this.SelectedDailyrestrictionsFooterModel[indexselected].AccountNameId);

        this.SelectedDailyrestrictionsFooterModel[indexselected].Debit=ItemValue;
        this.TotalCreditor=0;
        this.TotalDebtor=0; 
        this.SelectedDailyrestrictionsFooterModel[indexselected].Credit=0;

        this.SelectedDailyrestrictionsFooterModel.forEach(
            item=>{
                this.TotalCreditor+=item.Credit;
                this.TotalDebtor+=item.Debit; 
            }
        )
       
        console.log("Item",Item);
                
    }
    changeCredit(Item,$event){
        var ItemValue =+ $event.target.value;
        console.log("addItem",ItemValue);
        console.log("ItemId",Item["Id"]);
        let indexselected = this.SelectedDailyrestrictionsFooterModel.findIndex(i => i.Id == Item["Id"]);
        this.SelectedDailyrestrictionsFooterModel[indexselected].Credit=ItemValue;
        this.TotalCreditor=0;
        this.TotalDebtor=0; 
        this.SelectedDailyrestrictionsFooterModel[indexselected].Debit=0;
        this.SelectedDailyrestrictionsFooterModel.forEach(
            item=>{
            if(item.Credit>0){
             this.TotalCreditor+=item.Credit;
                
            }else{
                this.TotalDebtor+=item.Debit; 
            }
            }
        )

        console.log(".Credit",this.TotalCreditor);
        
      var  subAccountBalanceFooterModel = new DailyrestrictionsFooterModel();

        console.log("Item",Item);
                
    }


    // OnAddSelectedItemFunc($event){}
    // OnRemoveSelectedItemFunc($event){
    //     var Item = $event.value;
    //     console.log(" OnRemoveSelected",Item);
    //     // let indexselected = this.SelectedDailyrestrictionsFooterModel.findIndex(i => i.Id == Item);
    //     var Credit = $event.value.Credit;
    //     var Debit = $event.value.Debit;
    //     if(Credit>0){
    //         this.TotalCreditor-=Credit;
    //     }else{
    //         this.TotalDebtor-=Debit;
    //     }
    // }

    //save 
    onClick(){
        this.Total = this.TotalCreditor - this.TotalDebtor;
        if(this.Total==0){
        console.log("secound condition completed total = 0");
        if(this.HeaderFormGroup.valid){
        console.log("theard condition completed Header Form Group Completed");
        // this.DailyrestrictionsModel.accountBalanceFooterList =
        console.log("this.DailyrestrictionsModel.accountBalanceFooterModel ",this.SelectedDailyrestrictionsFooterModel );
      

        this.DailyrestrictionsModel.Description=this.HeaderFormGroup.getRawValue().HeaderDescription;
        this.DailyrestrictionsModel.CurrencyId=this.HeaderFormGroup.getRawValue().HeaderCurrencyDropdwon;
        this.DailyrestrictionsModel.BankCodeId=this.HeaderFormGroup.getRawValue().HeaderBankCodeDropdwon;
        this.DailyrestrictionsModel.AccountNameId =this.HeaderFormGroup.getRawValue().HeaderAccountNameDropdwon;
        this.DailyrestrictionsModel.Date=this.HeaderFormGroup.getRawValue().startDate;
        this.DailyrestrictionsModel.Totalcredit=this.TotalCreditor;
        this.DailyrestrictionsModel.Totaldebit=this.TotalDebtor;
        this.DailyrestrictionsModel.Totalbalance=this.Total;
        this.SelectedDailyrestrictionsFooterModel.forEach(
            Item=>{
                var  subAccountBalanceFooterModel = new DailyrestrictionsFooterModel();
                  subAccountBalanceFooterModel.Id =Item.Id ;
                  subAccountBalanceFooterModel.AccountNameId=Item.AccountNameId;
                  subAccountBalanceFooterModel.Credit =Item.Credit ;
                  subAccountBalanceFooterModel.Debit =Item.Debit ;
                this.DailyrestrictionsModel.dailyrestrictionsFooterList.push(subAccountBalanceFooterModel)
            }
        );
        this.DailyrestrictionsService.insert(this.DailyrestrictionsModel).subscribe(
            data=>{
                if(data){
                    console.log("done");
                    
                }
            }
            )
        console.log("this.DailyrestrictionsModel.accountBalanceFooterModel ",this.DailyrestrictionsModel);
        
        }else{
            console.log("theard condition field Header Form Group Completed");

        }

        }else{
            console.log("secound condition field total = 0");

        } 
    } 
    AddonClick(){

        var selectedDailyrestrictionsFooterModel =new SelectedDailyrestrictionsFooterModel();
        selectedDailyrestrictionsFooterModel.Id = this.SelectedDailyrestrictionsFooterModel.length+1 ;
        selectedDailyrestrictionsFooterModel.Debit = 0 ;
        selectedDailyrestrictionsFooterModel.Credit = 0 ;

        
        this.SelectedDailyrestrictionsFooterModel.push(selectedDailyrestrictionsFooterModel);
    }

  

}
