import { TrialBalanceBalancesDataModel } from './../../models/trial-balance-balances-data.model';
import { Component, OnInit } from '@angular/core';
import { TrialBalanceBalancesService } from '../../trial-balance-balances.service';
import { TrialBalanceBalancesSearchModel } from '../../models/trial-balance-balances-search.model';
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';
import { FlatTreeControl, NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener, MatTreeNestedDataSource } from '@angular/material/tree';
interface AccountNameNode {
    Id?: number;
    Children?: AccountNameNode[];
    Level?: number;
    Parentcode?:number;
    Name?:string;
    Type?:number;
    Debit?:number;
    Credit?:number;
  }
@Component({
    selector: 'trial-balance-balances',
    templateUrl: 'trial-balance-balances.component.html',
    styleUrls: ['trial-balance-balances.component.scss']
})
export class TrialBalanceBalancesComponent implements OnInit{
    declare lang: any;
    today:Date=new Date();
    list:TrialBalanceBalancesDataModel[]=[];
    liabilities:TrialBalanceBalancesDataModel[]=[];
    PropertyRights:TrialBalanceBalancesDataModel[]=[];
    employees=[];
    nodeList:AccountNameNode[]=[];
    constructor(private TrialBalanceBalancesService:TrialBalanceBalancesService){
      this.lang = localStorage.getItem('theme_lang');
    // this.tabledataSource.data = TREE_DATA;

    }
    TotalAssets = 0;
    Totalliabilities = 0;
    TotalPropertyRights = 0;
    data:TrialBalanceBalancesDataModel[]=[];
    assets:TrialBalanceBalancesDataModel[]=[];
    search:TrialBalanceBalancesSearchModel={};
    ngOnInit(): void {
        this.TrialBalanceBalancesService.search(this.search)
        .subscribe(data => {
          this.data = data;
          console.log('this.data',this.data)
          this.assets = data;
          this.groupedByData = this.groupByKey(this.data, 'Levelcode')
          this.nodeList = [];

            this.getDataNodes2();
        })
    
    }
    Search(){
      this.TrialBalanceBalancesService.search(this.search)
        .subscribe(data => {
          this.data = data;
        })
    }
    exportTable(){
      var data = document.getElementById('contentToConvert');
      data.style.display = 'inline-block';
      html2canvas(data,{}).then(canvas => {
      var imgWidth = 208;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save('new-file.pdf'); // Generated PDF
      data.style.display = 'block';

    //   data.style.display = 'none';
      
    });
    }
    print(){
        let printContents, popupWin;
        printContents = document.getElementById('contentToConvert').innerHTML;
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.open();
        window.print();
       
      }
      groupedByData=[];

      getDataNodes2(){
        for (let index = 1; index <= Object.keys(this.groupedByData).length; index++) {
          debugger;
            const element = this.groupedByData[index];
          if(index == 1){
            element.forEach(value => {
              this.nodeList.push({
                Id : value.Id,
                Level : value.Levelcode,
                Name : this.lang =='ar-EG' ? value.NameAr : value.NameEn,
                Parentcode : value.Parentcode,
                Children : [],
                Credit : value.Credit,
                Debit : value.Debit
              });
            });
          }
        } 
        this.drawTreeFromTop(this.nodeList);
  
        this.dataSource.data = [];
        this.dataSource.data = this.nodeList;
    
      }
      
groupByKey(data, key) {
    debugger;
    return data.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };
  nodeCount = 0;
  count = 0;

 drawTreeFromTop(currentlevelList: AccountNameNode []){
   debugger;
   console.log("data count: ", this.data.length);
   console.log("node count: ", this.nodeCount);
   console.log("current nodes: ", this.nodeList);
   
   if(this.nodeCount >= this.data.length){
     console.log("we did it, men. we found the deepest nodes.");
     return currentlevelList;
   }
   else{
     console.log("we're not yet done... descending one level deeper into this madness");
     if(this.levelLimit > this.count){
       let nextLevelList: AccountNameNode[] = [];
       if(currentlevelList.length > 0){
         currentlevelList.forEach(node => {
           this.data.filter(x=>x.Parentcode == node.Id).forEach(childNode => {
             let tempNode: AccountNameNode = {};
             tempNode.Id = childNode.Id;
             tempNode.Children = [];
             tempNode.Level = node.Level + 1;
             tempNode.Name = this.lang =='ar-EG' ? childNode.NameAr : childNode.NameEn,
             tempNode.Parentcode = childNode.Parentcode,
             tempNode.Type = childNode.AccountType,
             tempNode.Credit = childNode.Credit,
             tempNode.Debit = childNode.Debit,
             node.Children.push(tempNode)
             this.nodeCount++;
             nextLevelList.push(tempNode);
           });
         });
       }
       this.count ++;
       return this.drawTreeFromTop(nextLevelList);
     }
     else
   {
     return;
   }

   }
 }
 levelLimit = 6;
 treeControl = new NestedTreeControl<AccountNameNode>(node => node.Children);
//  dataSource = new MatTreeNestedDataSource<AccountNameNode>();
 //limiting depth level to 4 + base (total of 5 nested nodes)
 hasChild = (_: number, node: AccountNameNode) => !!node.Children && node.Children.length > 0 && node.Level+1<this.levelLimit;
 // formView = '';


 displayedColumns: string[] = ['name', 'Credit','Debit'];
  
  private transformer = (node: FoodNode, Level: number) => {
    return {
      expandable: !!node.Children && node.Children.length > 0,
      Id: node.Id,
      Name: node.Name,
      Parentcode: node.Parentcode,
      Type: node.Type,
      Level: Level,
      Credit:node.Credit,
      Debit:node.Debit

    };
  }

  tabletreeControl = new FlatTreeControl<ExampleFlatNode>(
      node => node.Level, node => node.expandable);

  treeFlattener = new MatTreeFlattener(
      this.transformer, node => node.Level, 
      node => node.expandable, node => node.Children);

  dataSource = new MatTreeFlatDataSource(this.tabletreeControl, this.treeFlattener);

 

  tablehasChild = (_: number, node: ExampleFlatNode) => node.expandable;


}
interface FoodNode {
  Id?: number;
  Level?: number;
  Parentcode?:number;
  Name?:string;
  Type?:number;
  Debit?:number;
  Credit?:number;
  Children?: AccountNameNode[];
}


interface ExampleFlatNode {
  expandable: boolean;
  Id?: number;
  Level?: number;
  Parentcode?:number;
  Name?:string;
  Type?:number;
  Debit?:number;
  Credit?:number;
}
