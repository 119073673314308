import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CouncilAndMembersAffairsModel } from '../../models/council-and-members-affairs.model';

@Component({
    // moduleId: module.id,
    selector: 'add-council-affairs',
    templateUrl: 'add-council-affairs.component.html',
    styleUrls: ['add-council-affairs.component.scss']
})
export class AddCouncilAffairsComponent implements OnInit {
    showCard: boolean = false;
    ItemHolderList: any[] = [];
    Form: FormGroup;
    lang: string;
  
    constructor(
      public router:Router, 
      public fb:FormBuilder, 
      ) {
    
   
    }
 
    ngOnInit() {
        this.Form = this.createForm(new CouncilAndMembersAffairsModel());        
    }

  
    Save() {
        this.router.navigate(['app/council-affairs/board-of-directors'])

    }
 
  goBack(){
    this.router.navigate(['app/council-affairs/board-of-directors'])
  }

  
  createForm(modelObject:CouncilAndMembersAffairsModel): FormGroup {
    debugger;
    return this.fb.group({
     Id:[modelObject.Id],
     ArabicName                       :[modelObject.ArabicName                       ],
     FirstArabicName                  :[modelObject.FirstArabicName                  ],
     SecondArabicName                 :[modelObject.SecondArabicName                 ],
     LastArabicName                   :[modelObject.LastArabicName                   ],
     EnglishName                      :[modelObject.EnglishName                      ],
     Phone                            :[modelObject.Phone                            ],
     Email                            :[modelObject.Email                            ],
     Gender                           :[modelObject.Gender                           ],
     IsEgyptian                       :[modelObject.IsEgyptian                       ],
     NationalID                       :[modelObject.NationalID                       ],
     PassportNumber                   :[modelObject.PassportNumber                   ],
     BranchNameEn                     :[modelObject.BranchNameEn                     ],
     BranchNameAr                     :[modelObject.BranchNameAr                     ],
     CountryEn                        :[modelObject.CountryEn                        ],
     CountryAr                        :[modelObject.CountryAr                        ],
     NationaltyID                     :[modelObject.NationaltyID                     ],
     City                             :[modelObject.City                             ],
     GovernorateId                    :[modelObject.GovernorateId                    ],
     EmergencyContactNumber           :[modelObject.EmergencyContactNumber           ],
     BirthDate                        :[modelObject.BirthDate                        ],
     ImageURL                         :[modelObject.ImageURL                         ],
     PWS                              :[modelObject.PWS                              ],
     IsActive                         :[modelObject.IsActive                         ],
     Address                          :[modelObject.Address                          ],
     TeamId                           :[modelObject.TeamId                           ],
     BranchId                         :[modelObject.BranchId                         ],
     RoleId                           :[modelObject.RoleId                           ],
     IsBlocked                        :[modelObject.IsBlocked                        ],
     IsStudent                        :[modelObject.IsStudent                        ],
     FieldsStudyOrWorkId              :[modelObject.FieldsStudyOrWorkId              ],
     SubFieldsStudyOrWorkId           :[modelObject.SubFieldsStudyOrWorkId           ],
     CollegeId                        :[modelObject.CollegeId                        ],
     SchoolId                         :[modelObject.SchoolId                         ],
     AcademicYear                     :[modelObject.AcademicYear                     ],
     VolunteerItherFiled              :[modelObject.VolunteerItherFiled              ],
     CurrentPosition                  :[modelObject.CurrentPosition                  ],
     Employer                         :[modelObject.Employer                         ],
     UserId                           :[modelObject.UserId                           ],
     IsHaveExpereanceInVolunteerFildes:[modelObject.IsHaveExpereanceInVolunteerFildes],
     HowDidYouKnowAboutUs             :[modelObject.HowDidYouKnowAboutUs             ],
     IsMembershipExpered              :[modelObject.IsMembershipExpered              ],
     IsDisability:[modelObject.IsDisability],
     DisabilityType:[modelObject.DisabilityType],
     CreateBy                         :[modelObject.CreateBy                         ],
     CreateDate                       :[modelObject.CreateDate                       ],
     ModifiedBy                       :[modelObject.ModifiedBy                       ],
     ModifiedDate                     :[modelObject.ModifiedDate                     ],
     IsDeleted                        :[modelObject.IsDeleted                        ],
     DeletedBy                        :[modelObject.DeletedBy                        ],
     DeletedDate                      :[modelObject.DeletedDate                      ],
     GovernorateNameEn                :[modelObject.GovernorateNameEn                ],
     GovernorateNameAr                :[modelObject.GovernorateNameAr                ],
     CityNameEn                       :[modelObject.CityNameEn                       ],
     CityNameAr                       :[modelObject.CityNameAr                       ],
     SectorNameAr                     :[modelObject.SectorNameAr                     ],
     SectorNameEn                     :[modelObject.SectorNameEn                     ],
     FieldNameAr                      :[modelObject.FieldNameAr                      ],
     FieldNameEn                      :[modelObject.FieldNameEn                      ],
     HasWork                          :[modelObject.HasWork                          ],
     PositionId                          :[modelObject.PositionId                          ],

     StudentType                      :[modelObject.StudentType                      ],
     CollegeNameAr                    :[modelObject.CollegeNameAr                    ],
     CollegeNameEn                    :[modelObject.CollegeNameEn                    ],
     SchoolNameAr                     :[modelObject.SchoolNameAr                     ],
     SchoolNameEn                     :[modelObject.SchoolNameEn                     ],
     VolunteerNumber                  :[modelObject.VolunteerNumber                  ],
     UniversityNameAr                 :[modelObject.UniversityNameAr                 ],
     UniversityNameEn                 :[modelObject.UniversityNameEn                 ],
     InstituteNameAr                  :[modelObject.InstituteNameAr                  ],
     InstituteNameEn                  :[modelObject.InstituteNameEn                  ],
     EventsCount                      :[modelObject.EventsCount                      ],
     ActivitiesCount                  :[modelObject.ActivitiesCount                  ],
     NumberOfActivityPoints           :[modelObject.NumberOfActivityPoints           ],
     NumberOfActivityHours            :[modelObject.NumberOfActivityHours            ],
     EndMembershipDate                :[modelObject.EndMembershipDate                ],
     MembershipIsIsEnded              :[modelObject.MembershipIsIsEnded              ],
     Latitude                         :[modelObject.Latitude                         ],
     Langitude                        :[modelObject.Langitude                        ],
     Zoom                             :[modelObject.Zoom                             ],
     EndMembershipRequest             :[modelObject.EndMembershipRequest             ],
     TransferMembershipRequest        :[modelObject.TransferMembershipRequest        ],
     RenewMembershipRequest           :[modelObject.RenewMembershipRequest           ],
     HasOtherExperinceField           :[modelObject.HasOtherExperinceField           ],
     OtherExperinceField              :[modelObject.OtherExperinceField              ],
     OtherNationalty                  :[modelObject.OtherNationalty                  ],
     IsAccepted                       :[modelObject.IsAccepted                       ],
     IsObsolete                       :[modelObject.IsObsolete                       ],
     IsRejected                       :[modelObject.IsRejected                       ],
     PrefierdInterview                :[modelObject.PrefierdInterview                ],
     SocialStatus:[modelObject.SocialStatus],
     GraduationYear:[modelObject.GraduationYear],
     EducationalQualification:[modelObject.EducationalQualification],
    });
  }
  public editEnabled = true;
  public picurl: string ='';

  clear(){
    this.picurl = '';
 }
 public NationalIdEditEnabled = true;
 public NationalIdPicurl: string ='';

 clearNationalId(){
   this.NationalIdPicurl = '';
}
public graduationEditEnabled = true;
public graduationPicurl: string ='';

cleargraduation(){
  this.graduationPicurl = '';
}

  }
  