export class LockerBasicModel{
        Id?: number;
        AccountNameId?: number;
        ManagerId?: number;
        NameAr?: string;
        NameEn?: string;
        Description?: string;
   
        constructor(item?: LockerBasicModel) {
            item = item ? item : {};
            this.Id = item.Id ? item.Id : 0;
            this.AccountNameId = item.AccountNameId ? item.AccountNameId : 0;
            this.ManagerId = item.ManagerId ? item.ManagerId : 0;
            this.NameAr = item.NameAr ? item.NameAr : '';
            this.NameEn = item.NameEn ? item.NameEn : '';
            this.Description = item.Description ? item.Description : '';
        }
    }