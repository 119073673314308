import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AccountNameModel } from '../account-name/AccountNameModel';
import { AccountsNameService } from '../account-name/AccountNameService';
import { BasicModel } from '../account-type/helper/model';
import { AccountsTypeService } from '../account-type/helper/serves';
import { AccountBalanceModel } from './AccountBalanceModel';
import { AccountsBalancService } from './AccountBalanceService';
import { AccountBalanceFooterModel } from './AccountBalanceModel';
import { SelectedAccountBalanceFooterModel } from './AccountBalanceModel';
import data from '../expense-definition/products';

@Component({
    // moduleId: module.id,
    selector: 'account-balance',
    templateUrl: 'account-balance.component.html',
    styleUrls: ['account-balance.component.scss']
})
export class AccountBalanceComponent {

    accountsTypeModel: BasicModel[] = [];
    AccountsNameModel: AccountNameModel[] = [];
    // AccountsNameListDropDownSelected: AccountNameModel[] = [];
    AccountBalanceModel: AccountBalanceModel=new AccountBalanceModel();
    AccountBalanceFooterModel: AccountBalanceFooterModel[] = [];
    SelectedAccountBalanceFooterModel: SelectedAccountBalanceFooterModel[] = [];
    
    // SaveIsValid=false;
    HeaderFormGroup:FormGroup;
    FooterFormGroup:FormGroup;
    TotalCreditor:number=0;
    TotalDebtor:number=0;
    Total:number=0;
    constructor(private AccountsBalancService:AccountsBalancService, private accountsTypeService: AccountsTypeService,private accountsNameService: AccountsNameService,){
        this.HeaderFormGroup = new FormGroup({
            BalanceNameDropdwon: new FormControl(null, Validators.required,),
            startDate: new FormControl(null, Validators.required),
          });
        //   this.FooterFormGroup = new FormGroup({
        //     DebtorControll: new FormControl(0,),
        //     CreditorControll: new FormControl(0,),

        //   });
       
        this.accountsTypeService.getAll().subscribe(
            data=>{
                this.accountsTypeModel=data;
                console.log("accountsTypeModel",this.accountsTypeModel); 
            }
        );
        this.accountsNameService.getAll(0).subscribe(
            data=>{
                this.AccountsNameModel=data;
                this.SelectedAccountBalanceFooterModel =data;
                console.log("AccountsNameList",this.accountsTypeModel); 
            }
        );
    }
    // createMainFormForm():FormGroup{
    //     return   this.MainFormGroup = new FormGroup({
    //         BalanceNameDropdwon: new FormControl(null, Validators.required,),
    //         startDate: new FormControl(null, Validators.required),
    //         DebtorControll: new FormControl(0,),
    //         CreditorControll: new FormControl(0,),

    //       });
    //   }

    changeDebtor(Item,$event){
        var ItemValue = +$event.target.value;
        console.log("changeDebtor",);
        console.log("addItem",ItemValue);
        console.log("ItemId",Item["Id"]);
         let indexselected = this.SelectedAccountBalanceFooterModel.findIndex(i => i.Id == Item["Id"]);
        this.SelectedAccountBalanceFooterModel[indexselected].Debit=ItemValue;
        this.TotalCreditor=0;
        this.TotalDebtor=0; 
        this.SelectedAccountBalanceFooterModel[indexselected].Credit=0;
        this.SelectedAccountBalanceFooterModel.forEach(
            item=>{
                this.TotalCreditor+=item.Credit;
                this.TotalDebtor+=item.Debit; 
            }
        )
       
        console.log("Item",Item);
                
    }
    changeCredit(Item,$event){
        var ItemValue =+ $event.target.value;
        console.log("addItem",ItemValue);
        console.log("ItemId",Item["Id"]);
        let indexselected = this.SelectedAccountBalanceFooterModel.findIndex(i => i.Id == Item["Id"]);
        this.SelectedAccountBalanceFooterModel[indexselected].Credit=ItemValue;
        this.TotalCreditor=0;
        this.TotalDebtor=0; 
        this.SelectedAccountBalanceFooterModel[indexselected].Debit=0;
        this.SelectedAccountBalanceFooterModel.forEach(
            item=>{
            if(item.Credit>0){
             this.TotalCreditor+=item.Credit;
                
            }else{
                
                this.TotalDebtor+=item.Debit; 
            }
            }
        )

        console.log(".Credit",this.TotalCreditor);
        
    //   var  subAccountBalanceFooterModel = new AccountBalanceFooterModel();

        console.log("Item",Item);
                
    }
    // OnAddSelectedItemFunc($event){}
    // OnRemoveSelectedItemFunc($event){
    //     var Item = $event.value;
    //     console.log(" OnRemoveSelected",Item);
    //     // let indexselected = this.SelectedAccountBalanceFooterModel.findIndex(i => i.Id == Item);
    //     var Credit = $event.value.Credit;
    //     var Debit = $event.value.Debit;
    //     if(Credit>0){
    //         this.TotalCreditor-=Credit;
    //     }else{
    //         this.TotalDebtor-=Debit;
    //     }
    // }

    //save 
    onClick(){
        this.Total = this.TotalCreditor - this.TotalDebtor;
        if(this.Total==0){
        console.log("secound condition completed total = 0");
        if(this.HeaderFormGroup.valid){
        console.log("theard condition completed Header Form Group Completed");
        // this.AccountBalanceModel.accountBalanceFooterList =
        console.log("this.AccountBalanceModel.accountBalanceFooterModel ",this.SelectedAccountBalanceFooterModel );
        
        this.AccountBalanceModel.BalanceNameId =this.HeaderFormGroup.getRawValue().BalanceNameDropdwon;
        this.AccountBalanceModel.Date=this.HeaderFormGroup.getRawValue().startDate;
        this.AccountBalanceModel.TotalCredit=this.TotalCreditor;
        this.AccountBalanceModel.Totaldebit=this.TotalDebtor;
        this.AccountBalanceModel.Totalbalance=this.Total;
        this.SelectedAccountBalanceFooterModel.forEach(
            Item=>{
                var  subAccountBalanceFooterModel = new AccountBalanceFooterModel();
                  subAccountBalanceFooterModel.Id =Item.Id ;
                  subAccountBalanceFooterModel.Credit =Item.Credit ;
                  subAccountBalanceFooterModel.Debit =Item.Debit ;
                this.AccountBalanceModel.accountBalanceFooterList.push(subAccountBalanceFooterModel)
            }
        );
        console.log("this.AccountBalanceModel.accountBalanceFooterModel ",this.AccountBalanceModel);
        this.AccountsBalancService.insert(this.AccountBalanceModel).subscribe(
            data=>{
                if(data){
                    console.log("Done");
                }
            }
            )
        
        }else{
            console.log("theard condition field Header Form Group Completed");

        }

        }else{
            console.log("secound condition field total = 0");

        } 
    } 


  

}
