import { Component, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationType, NotificationsService } from 'angular2-notifications';
import { DataFilterer } from 'src/app/views/app/aio/shared/DataFilterer';
import { DataSorter } from 'src/app/views/app/aio/shared/DataSorter';
import { DataPaginator } from 'src/app/views/app/aio/shared/DataPaginator';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { ModalDirective } from 'ngx-bootstrap/modal';
import {TypesOfTopicsModel} from '../../models/types-of-topics.model';
@Component({
    // moduleId: module.id,
    selector: 'types-of-topics',
    templateUrl: 'types-of-topics.component.html',
    styleUrls: ['types-of-topics.component.scss']
})
export class TypesOfTopicsComponent {
    constructor(private formBuilder: FormBuilder,  private renderer: Renderer2,
        private notifications: NotificationsService, private translate: TranslateService,
        private router : Router) {
      }
      currentScreen: any = 'Table';
    
      translationName: string = 'Types Of Topics'
    
      sortableKeys = [];
      searchableKeys = ['Id'];
      
      ogData: TypesOfTopicsModel [] = [ 
        {Id:1, ArabicName:'موضوعات إدارية'  , EnglishName:'Administrative topics'  }, 
        {Id:2, ArabicName:'موضوعات إدارية 2'   , EnglishName:'Administrative topics 2' }, 
      ];

    
      ngOnInit(): void {
        this.lang = localStorage.getItem('theme_lang');
        this.itemOrder = { label: 'Id', property: 'Id' };
        this.sortableKeys = [
          { label: 'Id', property: 'Id' },
          { label: 'ArabicName', property: 'ArabicName' },
          { label: 'EnglishName', property: 'EnglishName' },
          { label: 'Phone', property: 'Phone' },
          { label: 'Email', property: 'Email' },
        ];
        this.loadData();
        
        console.log(this.ogData);
      }
    
      //Deletion methods:
      //Customize if needed.
      //Pass the target object as paraMetre if the target API can only delete singular objects and not lists.
      deleteObject() {
        this.savedPage = this.currentPage;
        //prevent multiclicks. re enable after API response
        this.currentAction = 'loading';
        this.executeDeleteObject();
      }
    
      //Same here...
      executeDeleteObject(){
        // this.modelService.deleteList(this.deletionList).subscribe(result => {
        //   if (result === true) {
        //     this.loadData();
        //     this.currentAction = '';
        //     this.finalizeDeletion();
        //   }
        //   else {
        //     this.toastError(this.translate.instant("Error"), this.translate.instant("FailedDelete"));
        //     this.currentAction = '';
        //   }
        // })
      }
      ////////////////////////////////////////////////////////////////////////////
      //
      //
      //
      //
      //
      ////////////////////////////////////////////////////////////////////////////
      //  EXCLUSIVE :
      //  Specifically for this component.
      ///////////////////////////////
      
    
      ////////////////////////////////////////////////////////////////////////////
      //
      //
      //
      //
      //
      ////////////////////////////////////////////////////////////////////////////
      //  CONSTANT :
      //  Do not change.
      ///////////////////////////////
      lang: string;
      currentFormMode: string = '';
      currentAction: string = '';
    
    
      //ogData = [];
      filteredData = [...this.ogData];
      sortedData = [...this.filteredData];
      paginatedData = [...this.filteredData];
      lastId: number = this.ogData.length + 1;
    
      SelectAllBox: any = false;
    
      itemsPerPage = 10;
      itemOptionsPerPage = [5, 10, 20];
      selected = [];
      selectAllState = '';
      itemOrder: any;
      orderAsc: boolean = true;
    
      currentPage = 1;
      savedPage = 0;
    
    
      dataFilterer = new DataFilterer();
      dataSorter = new DataSorter();
      dataPaginator = new DataPaginator();
    
      loadData() {
        debugger;
        this.currentAction = '';
        this.sortData();

        // this.modelService.getAll().subscribe(
        //   data => {
        //     this.currentAction = '';
            
        //     this.ogData = data;
        //     this.sortData();
        //   }
        // )
        
      }
    
      sortData() {
        this.sortedData = this.dataSorter.sortData(this.itemOrder.property, this.ogData);
        this.filterData();
      }
    
      reverseSortData() {
        this.savedPage = this.currentPage;
        this.sortedData = this.sortedData.slice().reverse();
        this.filterData();
      }
    
      filterData() {
        this.filteredData = this.dataFilterer.filterData(this.SearchTerm, this.searchableKeys, this.sortedData)
        if (this.savedPage === 0) {
          this.paginateData(this.itemsPerPage, 1);
        }
        else {
          this.paginateData(this.itemsPerPage,
            Math.ceil((this.filteredData.length / this.itemsPerPage)) >= this.savedPage ?
              this.savedPage :
              Math.ceil((this.filteredData.length / this.itemsPerPage))
          );
        }
        this.savedPage = 0;
      }
    
      paginateData(newItemsPerPage: number, newCurrentPage: number) {
        this.itemsPerPage = newItemsPerPage;
        this.currentPage = newCurrentPage;
        this.paginatedData = this.filteredData.slice((newCurrentPage - 1) * (newItemsPerPage), (newItemsPerPage * newCurrentPage));
      }
    
      changeSort(newSort: any) {
        if (this.itemOrder.label === newSort.label) {
          this.orderAsc = !this.orderAsc;
          const oldPage = this.currentPage;
          this.reverseSortData()
          this.currentPage = oldPage;
        }
        else {
          this.orderAsc = true;
          this.itemOrder = newSort;
          this.sortData();
        }
      }
    
      getSort(label: any) {
        return this.sortableKeys.find(i => i.label === label);
      }
    
      changePage(event: PageChangedEvent): void {
        this.currentPage = event.page;
        this.paginateData(this.itemsPerPage, this.currentPage);
      }
    
      changeFilter() {
        this.deletionList = [];
        this.selected = [];
        this.selectAllState = '';
        this.filterData();
      }
    
      selectAll($event): void {
        if ($event.target.checked) {
          this.selected = [...this.filteredData];
        } else {
          this.selected = [];
        }
        this.setSelectAllState();
      }
    
      setSelectAllState(): void {
        if (this.selected.length >= this.filteredData.length) {
          this.selectAllState = 'checked';
        } else if (this.selected.length !== 0) {
          this.selectAllState = 'indeterminate';
        } else  {
          this.selectAllState = '';
        }
      }
    
      isSelected(p: TypesOfTopicsModel): boolean {
        return this.selected.findIndex(x => x.Id === p.Id) > -1;
      }
    
      onSelect(item: TypesOfTopicsModel): void {
        if (this.isSelected(item)) {
          this.selected = this.selected.filter(x => x.Id !== item.Id);
        } else {
          this.selected.push(item);
        }
        this.setSelectAllState();
      }
    
      findByCode(list: any, code: any): any {
        return list.find(x => x.Code === code)
      }
    
      @ViewChild('delModal') delModal: ModalDirective;
    //   showAddNewModalRef(): void {
    //    this.router.navigate(['app/council-affairs/create-council-affairs',0])
    //   }
    
      showInfoModalRef(Id: any): void {
        //IMPORTANT
        //REDIRECT TO INFO PAGE
      }
      modelObject:TypesOfTopicsModel={};
      deletionList: TypesOfTopicsModel[] = [];
      showDelModal(Id: any): void {
        if (Id >= 0) {
          this.currentFormMode = 'Del'
          this.modelObject = this.ogData.find(i => i.Id === Id);
          this.deletionList = [this.ogData.find(i => i.Id === Id)];
          this.delModal.show();
        }
        else if (Id === 'delMulti') {
          this.deletionList = [...this.selected]
          this.delModal.show();
        }
      }
    
      SearchTerm: any = '';
    
    
      finalizeDeletion(){
        if (this.deletionList.length === 1) {
          this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyDeleted") + " " +
            this.translate.instant("One" + this.translationName));
        }
        else if (this.deletionList.length === 2) {
          this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyDeleted") + " " +
            this.translate.instant("Two" + this.translationName + "s"));
        }
        else if (this.deletionList.length > 2 && this.deletionList.length < 11) {
          this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyDeleted") + " " +
            this.deletionList.length + " " + this.translate.instant(this.translationName + "RowsPlur"));
        }
        else {
          this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyDeleted") + " " +
            this.deletionList.length + " " + this.translate.instant(this.translationName + "Rows"));
        }
    
        this.deletionList = [];
        this.selected = [];
    
        this.setSelectAllState();
        if (this.selectAllState === '') {
          this.SelectAllBox = false;
        }
        this.delModal.hide();
      }
    
      toastSuccess(toastHeader: string, toastBody: string): void {
        this.notifications.create(toastHeader, toastBody,
          NotificationType.Success, { timeOut: 3000, showProgressBar: true });
      }
      toastError(toastHeader: string, toastBody: string): void {
        this.notifications.create(toastHeader, toastBody,
          NotificationType.Error, { timeOut: 3000, showProgressBar: true });
      }
      deleteMultiTaxTypes() {
        this.loadData();
        this.delModal.hide();
      }
    
      loadAllData() {
        // this.modelService.getAll().subscribe(
        //   data => {
        //     this.ogData = data;
        //     this.loadSearch();
        //   }
        // )
      }
    
      loadSearch() {
        const prevSearch = this.SearchTerm;
        this.SearchTerm = '';
        setTimeout(() => {
          this.SearchTerm = prevSearch;
        }, 1);
      }
    //   modelObject: PositionsOfCouncilModel = new PositionsOfCouncilModel();

    //   ogData: PositionsOfCouncilModel[] = [];
      mainForm: FormGroup = new FormGroup({
        Id: new FormControl(),
        NameEn: new FormControl(),
        NameAr: new FormControl(),
      });
      addObject() {
        this.currentAction = 'loading';
    
        this.modelObject = new TypesOfTopicsModel();
        this.modelObject.Id = 0;
        this.modelObject.ArabicName = this.mainForm.getRawValue().ArabicName;
        this.modelObject.EnglishName = this.mainForm.getRawValue().EnglishName;
    
        // this.executeAddObject();
      }

      declare sideModalRef: any;
      createForm(modelObject, formBuilder): FormGroup {
    
    
        return formBuilder.group({
          Id: [modelObject.Id >= 0 ? modelObject.Id : 0],
          ArabicName: [modelObject.ArabicName, Validators.compose([Validators.required, Validators.minLength(2)])],
          EnglishName: [modelObject.EnglishName, Validators.compose([Validators.required, Validators.pattern(/^[\u0621-\u064A]+([\s]*[\u0621-\u064A]+)*$/)])],
        });
      }
      
      showModal(Id, action) {
        if (action === 'Add') {
          this.showAddNewModalRef(this.createForm, this.formBuilder)
        }
      }
    
      showAddNewModalRef( func , formBuilder ): void {  //pass "createform()" here
        this.currentFormMode = 'Add'
        this.modelObject = {};
        this.mainForm = func;
        this.mainForm = func(this.modelObject, formBuilder);
        if(this.sideModalRef === undefined){
          if(this.smallModal != undefined){
            this.smallModal.show();
          }
        }
        else{
          this.sideModalRef.show();
        }
      }
   
      @ViewChild('smallModal') smallModal: ModalDirective;
}

