export class CashFlowsDataModel{
    CashFlowFromOperations?:CashFlowsDetailsModel[];
    CashFlowFromInvestments?:CashFlowsDetailsModel[];
    CashFlowFromFinancing?:CashFlowsDetailsModel[];
    constructor(item ?: CashFlowsDataModel){
        item = item ? item : {};
        this.CashFlowFromFinancing  = item.CashFlowFromFinancing?item.CashFlowFromFinancing:[];
        this.CashFlowFromInvestments = item.CashFlowFromInvestments?item.CashFlowFromInvestments:[];
        this.CashFlowFromOperations = item.CashFlowFromOperations?item.CashFlowFromOperations:[];
    }
}
export class CashFlowsDetailsModel{
    KeyAr: string;
    KeyEn: string;
    Value: number | null;
}