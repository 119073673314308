<app-side-popup #sideModalRef>
    <div class="modal-content"
        style="width: 90%; text-align: center; margin-top: 20px; margin-left: auto; margin-right: auto;">
        <div style="align-content: center; display: inline-block;">
            <div class="modal-header" style="display: block; padding: 0px 0px 5px 0px; text-align: center; ">
                <h1 id="dialog-child-name" class="modal-title">
                    {{tableEngine.currentFormMode+translationName | translate}}</h1>
            </div>
            <div class="modal-body">
                <form class="md-float-material form-material" [formGroup]="mainForm">

                    <div class="form-group form-primary">
                        <label class="float-label push righ">{{"WorkItemCode" | translate}}</label>
                        <input *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                            type="text" name="Code" class="form-control" formControlName="Code"
                            placeholder='{{"WorkItemCode" | translate}}'>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="Code">{{modelObject.Code}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('Code').hasError('required') || mainForm.get('Code').invalid) && ( mainForm.get('Code').dirty ||  mainForm.get('Code').touched)">
                            {{"WorkItemCodeRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>
                    
                    <!-- <div class="form-group form-primary">
                        <label class="float-label push righ">{{"WorkItemType" | translate}}</label>
                        <div *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'">
                            <ng-select appearance="outline"
                                name="WorkItemType" formControlName="WorkItemMain"
                                placeholder='{{"WorkItemType" | translate}}' #WorkItemTypeSelect labelForId="txtycd"
                                [searchable]="false">
                                <ng-option [value]="true">{{"WorkItemMain" | translate}}</ng-option>
                                <ng-option [value]="false">{{"WorkItemSub" | translate}}</ng-option>
                            </ng-select>
                        </div>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="ParentId">{{lang==='ar-EG'?(modelObject.ParentId>0?modelObject.ParentAr:"-"):(modelObject.ParentId>0?modelObject.ParentEn:"-")}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('ParentId').hasError('required') || mainForm.get('ParentId').invalid) && ( mainForm.get('ParentId').dirty ||  mainForm.get('ParentId').touched)">
                            {{"WorkItemCountryRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div> -->

                    
                    <!-- <div class="form-group form-primary" *ngIf="mainForm.get('WorkItemMain').value==false"> -->
                    <div class="form-group form-primary">
                        <label class="float-label push righ">{{"WorkItemParent" | translate}}</label>
                        <div *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'">
                            <ng-select appearance="outline"
                                name="ParentId" formControlName="ParentId"
                                placeholder='{{"WorkItemParent" | translate}}' #WorkItemParentSelect labelForId="txtycd"
                                [searchable]="true">
                                <ng-option *ngFor="let parent of parentList"
                                    [value]="parent.Id">
                                    {{lang==='ar-EG'?parent.NameAr:parent.NameEn}}</ng-option>
                            </ng-select>
                        </div>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="ParentId">{{lang==='ar-EG'?(modelObject.ParentId>0?modelObject.ParentAr:"-"):(modelObject.ParentId>0?modelObject.ParentEn:"-")}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('ParentId').hasError('required') || mainForm.get('ParentId').invalid) && ( mainForm.get('ParentId').dirty ||  mainForm.get('ParentId').touched)">
                            {{"WorkItemParentRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>

                    <div class="form-group form-primary">
                        <label class="float-label push righ">{{"WorkItemNameAr" | translate}}</label>
                        <input *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'" type="text" name="NameAr"
                            class="form-control" formControlName="NameAr"
                            placeholder='{{"WorkItemNameAr" | translate}}'>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="NameAr">{{modelObject.NameAr}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('NameAr').hasError('required') || mainForm.get('NameAr').invalid) && ( mainForm.get('NameAr').dirty ||  mainForm.get('NameAr').touched)">
                            {{"WorkItemNameArRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>

                    <div class="form-group form-primary">
                        <label class="float-label push righ">{{"WorkItemNameEn" | translate}}</label>
                        <input *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'" type="text" name="NameEn"
                            class="form-control" formControlName="NameEn"
                            placeholder='{{"WorkItemNameEn" | translate}}'>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="NameEn">{{modelObject.NameEn}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('NameEn').hasError('required') || mainForm.get('NameEn').invalid) && ( mainForm.get('NameEn').dirty ||  mainForm.get('NameEn').touched)">
                            {{"WorkItemNameEnRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>
                    
                    <div class="form-group form-primary">
                        <label class="float-label push righ">{{"WorkItemUnit" | translate}}</label>
                        <div *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'">
                            <ng-select appearance="outline"
                                name="UnitId" formControlName="UnitId"
                                placeholder='{{"WorkItemUnit" | translate}}' #WorkItemUnitSelect labelForId="txtycd"
                                [searchable]="true">
                                <ng-option *ngFor="let codingUnit of codingUnitList"
                                    [value]="codingUnit.Id">
                                    {{lang==='ar-EG'?codingUnit.NameAr:codingUnit.NameEn}}</ng-option>
                            </ng-select>
                        </div>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="UnitId">{{lang==='ar-EG'?modelObject.UnitAr:modelObject.UnitEn}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('UnitId').hasError('required') || mainForm.get('UnitId').invalid) && ( mainForm.get('UnitId').dirty ||  mainForm.get('UnitId').touched)">
                            {{"WorkItemUnitRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>
                    

                    <div class="form-group form-primary">
                        <label class="float-label push righ">{{"WorkItemCost" | translate}}</label>
                        <input *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'" type="number" name="Cost"
                            class="form-control" formControlName="Cost"
                            placeholder='{{"WorkItemCost" | translate}}'>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="Cost">{{modelObject.Cost}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('Cost').hasError('required') || mainForm.get('Cost').invalid) && ( mainForm.get('Cost').dirty ||  mainForm.get('Cost').touched)">
                            {{"WorkItemCostRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>
                    


                    <div style="margin-left: 5px; text-align: center; margin-top: 30px;">
                        <button *ngIf="tableEngine.currentFormMode==='Add'"
                            [disabled]="mainForm.invalid || tableEngine.currentAction==='loading'" id="smallModalSubmitButton"
                            class="btn btn-secondary" (click)="addObject()"
                            style="font-weight: bold; width: 100%; text-align: center;">{{"Add" |
                            translate}}</button>
                        <!-- <button *ngIf="tableEngine.currentFormMode==='Add'"
                            [disabled]="mainForm.invalid || tableEngine.currentAction==='loading'" id="smallModalSubmitButton"
                            class="btn btn-secondary" (click)="addAndNew()"
                            style="font-weight: bold; width: 100%; text-align: center; margin-top: 10px;">{{"AddAndNew" |
                            translate}}</button> -->
                        <button *ngIf="tableEngine.currentFormMode==='Edit'"
                            [disabled]="mainForm.invalid || tableEngine.currentAction==='loading'" id="smallModalSubmitButton"
                            class="btn btn-secondary" (click)="editObject()"
                            style="font-weight: bold; width: 100%; text-align: center;">{{"Edit"
                            | translate}}</button>
                        <button class="btn btn-light" (click)="sideModalRef.modalRef.hide()"
                            style="font-weight: bold; width: 100%; text-align: center; margin-top: 10px;">{{"Close" |
                            translate}}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</app-side-popup>
<div bsModal #smallModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <button type="button" class="close pull-right" aria-label="Close" (click)="smallModal.hide()"
                style="margin-right: 20px; margin-top: 10px; width: 20px; display: inline;">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-header" style="display: block; padding: 0px 0px 5px 0px; text-align: center; ">
                <h1 id="dialog-child-name" class="modal-title">
                    {{tableEngine.currentFormMode+translationName | translate}}</h1>
            </div>
            <div class="modal-body">
                <form class="md-float-material form-material" [formGroup]="mainForm">
                    
                    
                </form>
            </div>
        </div>
    </div>
</div>

<div bsModal #delModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">
    <div class="modal-dialog modal-sm">
        <div class="modal-content"  >
            <button type="button" class="close pull-right" aria-label="Close" (click)="delModal.hide()"
                style="margin-right: 20px; margin-left: 20px; margin-top: 10px; width: 20px; display: inline;">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-header" style="display: inline; padding: 0px 0px 5px 0px;">
                <h4 id="dialog-child-name" class="modal-title pull-left"  style="text-align: center;">
                    {{"ConfirmDel"+translationName |
                    translate}}</h4>
            </div>
            <div class="modal-body">
                <div class="form-group form-primary" *ngIf="tableEngine.deletionList.length < tableEngine.ogData.length 
                || tableEngine.deletionList.length === 1" 
                style="text-align: center;">
                    <div *ngFor="let item of tableEngine.deletionList;" style="text-align: center;">
                        <label type="text">- {{lang==='ar-EG'?item.NameAr:item.NameEn}}</label>
                        <span class="form-bar"></span>
                    </div>
                </div>
                <div class="form-group form-primary" style="text-align: center;" *ngIf="tableEngine.deletionList.length > 1 && 
                    tableEngine.deletionList.length === tableEngine.ogData.length">
                    <label type="text">
                        <h1 style="font-weight: bolder; text-align: center;">{{translationName+"DeleteAll" | translate}}</h1>
                    </label>
                    <span class="form-bar"></span>
                </div>

                <div style="margin-left: 5px; text-align: center; margin-top: 30px;">
                    <button class="btn btn-danger" id="smallModalDeleteButton" (click)="deleteObject()"
                        [disabled]="tableEngine.currentAction==='loading'"
                        style="font-weight: bold; width: 100%; text-align: center;">{{"Delete" | translate}}</button>
                    <button class="btn btn-light" (click)="delModal.hide()"
                        style="font-weight: bold; width: 100%; text-align: center; margin-top: 10px;">{{"Close" |
                        translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div  *ngIf="currentScreen === 'Form'" class="FormScreen">
    <a (click)="currentScreen='Table'">back</a>
</div>

<div *ngIf="currentScreen === 'Table'" class="FormScreen" class="tableScreen">
    <div id="divtop" style="display: block;height: 47px; width: 100%;">
        <div id="divsearchmiddle" style="display: inline-block; width: 70%;
        height: inherit;  text-align: center; padding: 0px; margin: 0px;">
            <div class="search" style="display: inline-block; margin-left: 50px; margin-right: 50px;">
                <div class="d-inline-block">
                    <div class="search-sm d-inline-block mr-1 mb-1 align-top" style="width: 600px;">
                        <input type="search" [(ngModel)]="tableEngine.SearchTerm" (keyup)="tableEngine.changeFilter()" (change)="tableEngine.changeFilter()"
                            class="form-control input-sm full-data-search" placeholder="{{translationName+'Search' | translate}}">
                    </div>
                </div>
            </div>
        </div>
    
        <div id="divsearchleft" style="display: inline-block; width: 30%; height: inherit;">
            <div style="display: block; height: 30%; ">
                &nbsp;
            </div>
            <div class="search" style="display: block; height: 70%; text-align: center;">
                <button class="btn btn-primary d-inline-block"
                    style="font-weight: bold; text-align: center; padding: 0px; width: 150px; height: 30px;"
                    (click)="showModal(null,'Add')" [disabled]="tableEngine.currentAction==='loading'">{{"Add"+translationName |
                    translate}}</button>
    
            </div>
        </div>
    
    </div>
    
        
    <div id="divmiddle" style="display: block; margin-top: 5px;">
    
        <div style="width: 100%;">
            <div>
                <table class="table">
                    <thead class="thead-light">
                        <th style="text-align: center; padding:5px;">
                            <div class=" text-zero" style="display: inline-block;">
                                <div class="top-right-button-container2 btn-group" dropdown>
                                    <div class="btn btn-primary pl-4 pr-0 check-button">
                                        <label class="custom-control custom-checkbox mb-0 d-inline-block"
                                            style="width: 10px;">
                                            <input type="checkbox" class="custom-control-input"
                                                [checked]="tableEngine.selectAllState==='checked'"
                                                [indeterminate]="tableEngine.selectAllState === 'indeterminate'"
                                                (change)="tableEngine.selectAll($event)">
                                            <span class="custom-control-label">&nbsp;</span>
                                        </label>
                                    </div>
                                    <button id="button-split" type="button" dropdownToggle style="width: 10px;"
                                        class="btn btn-primary dropdown-toggle dropdown-toggle-split"
                                        aria-controls="dropdown-split">
                                        <span class="caret"></span>
                                    </button>
                                    <ul id="dropdown-split" *dropdownMenu class="dropdown-menu dropdown-menu-right"
                                        role="menu" aria-labelledby="button-split">
                                        <li role="menuitem"><a class="dropdown-item">
                                                <button class="btn btn-danger d-inline-block"
                                                    style=" font-weight: bold; text-align: center; padding: 0px; width: 150px; height: 30px;"
                                                    (click)="tableEngine.showDelModal('delMulti')"
                                                    [disabled]="tableEngine.selected.length===0 || tableEngine.currentAction==='loading'">{{"DeleteSelected"
                                                    |
                                                    translate}}</button></a></li>
                                    </ul>
                                </div>
                            </div>
                        </th>
                        <th class="sorticon" (click)="prepChangeSort('WorkItemCode')" [ngClass]="(itemOrder.label==='WorkItemCode')?'sortkey sorticon':'sorticon'">
                            <div [ngClass]="{'iconsminds-up---down': (itemOrder.label!='WorkItemCode'), 
                            'iconsminds-down': ((itemOrder.label==='WorkItemCode') && tableEngine.orderAsc === true),
                            'iconsminds-up': ((itemOrder.label==='WorkItemCode') && tableEngine.orderAsc === false)}"></div></th>
                        <th class="sortOption" (click)="prepChangeSort('WorkItemCode')" 
                        [ngClass]="{'sortkey': (itemOrder.label==='WorkItemCode')}">
                                {{"WorkItemCode" | translate}}
                        </th>
                        <!-- <th class="sorticon" (click)="prepChangeSort('WorkItemParent')" [ngClass]="(itemOrder.label==='WorkItemParent')?'sortkey sorticon':'sorticon'">
                            <div [ngClass]="{'iconsminds-up---down': (itemOrder.label!='WorkItemParent'), 
                            'iconsminds-down': ((itemOrder.label==='WorkItemParent') && tableEngine.orderAsc === true),
                            'iconsminds-up': ((itemOrder.label==='WorkItemParent') && tableEngine.orderAsc === false)}"></div></th>
                        <th class="sortOption" (click)="prepChangeSort('WorkItemParent')" 
                        [ngClass]="{'sortkey': (itemOrder.label==='WorkItemParent')}">
                                {{"WorkItemParent" | translate}}
                        </th> -->
                        <th class="sorticon" (click)="prepChangeSort('WorkItemParent')" [ngClass]="(itemOrder.label==='WorkItemParent')?'sortkey sorticon':'sorticon'">
                            <div [ngClass]="{'iconsminds-up---down': (itemOrder.label!='WorkItemParent'), 
                            'iconsminds-down': ((itemOrder.label==='WorkItemParent') && tableEngine.orderAsc === true),
                            'iconsminds-up': ((itemOrder.label==='WorkItemParent') && tableEngine.orderAsc === false)}"></div></th>
                        <th class="sortOption" (click)="prepChangeSort('WorkItemParent')" 
                        [ngClass]="{'sortkey': (itemOrder.label==='WorkItemParent')}">
                                {{"WorkItemParent" | translate}}
                        </th>
                        <th class="sorticon" (click)="prepChangeSort('WorkItemSub')" [ngClass]="(itemOrder.label==='WorkItemSub')?'sortkey sorticon':'sorticon'">
                            <div [ngClass]="{'iconsminds-up---down': (itemOrder.label!='WorkItemSub'), 
                            'iconsminds-down': ((itemOrder.label==='WorkItemSub') && tableEngine.orderAsc === true),
                            'iconsminds-up': ((itemOrder.label==='WorkItemSub') && tableEngine.orderAsc === false)}"></div></th>
                        <th class="sortOption" (click)="prepChangeSort('WorkItemSub')" 
                        [ngClass]="{'sortkey': (itemOrder.label==='WorkItemSub')}">
                                {{"WorkItemSub" | translate}}
                        </th>
                        <th class="sorticon" (click)="prepChangeSort('WorkItemUnit')" [ngClass]="(itemOrder.label==='WorkItemUnit')?'sortkey sorticon':'sorticon'">
                            <div [ngClass]="{'iconsminds-up---down': (itemOrder.label!='WorkItemUnit'), 
                            'iconsminds-down': ((itemOrder.label==='WorkItemUnit') && tableEngine.orderAsc === true),
                            'iconsminds-up': ((itemOrder.label==='WorkItemUnit') && tableEngine.orderAsc === false)}"></div></th>
                        <th class="sortOption" (click)="prepChangeSort('WorkItemUnit')" 
                        [ngClass]="{'sortkey': (itemOrder.label==='WorkItemUnit')}">
                                {{"WorkItemUnit" | translate}}
                        </th>
                        <th class="sorticon" (click)="prepChangeSort('WorkItemCost')" [ngClass]="(itemOrder.label==='WorkItemCost')?'sortkey sorticon':'sorticon'">
                            <div [ngClass]="{'iconsminds-up---down': (itemOrder.label!='WorkItemCost'), 
                            'iconsminds-down': ((itemOrder.label==='WorkItemCost') && tableEngine.orderAsc === true),
                            'iconsminds-up': ((itemOrder.label==='WorkItemCost') && tableEngine.orderAsc === false)}"></div></th>
                        <th class="sortOption" (click)="prepChangeSort('WorkItemCost')" 
                        [ngClass]="{'sortkey': (itemOrder.label==='WorkItemCost')}">
                                {{"WorkItemCost" | translate}}
                        </th>
                        <th></th>
                        <th [colSpan]="2" style="text-align: center;">
                            <div id="displayOptions" style="
                            display: inline; margin-top: 10px; margin-left: 5px; margin-right: 5px;">
                                <span class="text-muted text-small" style=" width: 50px; height: 40px;">
                                    {{"ResultCount" | translate}}
                                </span>
                                <span dropdown class="d-inline-block position-relative">
                                    <button class="btn btn-outline-dark btn-xs dropdown-toggle" type="button"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" dropdownToggle>
                                        {{tableEngine.itemsPerPage}}
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-sm text-right"
                                        *dropdownMenu>
                                        <li role="menuitem" *ngFor="let item of tableEngine.itemOptionsPerPage"><a
                                                [ngClass]="item === tableEngine.itemsPerPage ? 'dropdown-item active' : 'dropdown-item'"
                                                (click)="tableEngine.paginateData(item, 1)">{{item}}</a></li>
                                    </div>
                                </span>
                            </div>
                        </th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let obj of tableEngine.paginatedData;  let i=index">
                            <td style="text-align: center;">
                                <div class="itemCheck mb-0 custom-checkbox custom-control" style="display: inline-block;">
                                    <input type="checkbox" class="custom-control-input" [id]="'customCheck'+i"
                                        [checked]="tableEngine.isSelected(obj)" (change)="tableEngine.onSelect(obj)">
                                    <label class="custom-control-label" [for]="'customCheck'+i"></label>
                                </div>
                            </td>
                            <td colspan="2">{{lang==='ar-EG'?obj.Code:obj.Code}}</td>
                            <td colspan="2">{{lang==='ar-EG'?(obj.ParentId>0?obj.ParentAr:obj.NameAr):(obj.ParentId>0?obj.ParentEn:obj.NameEn)}}</td>
                            <td colspan="2">{{lang==='ar-EG'?(obj.ParentId>0?obj.NameAr:"لا يوجد"):(obj.ParentId>0?obj.NameEn:"None")}}</td>
                            <td colspan="2">{{lang==='ar-EG'?obj.UnitAr:obj.UnitEn}}</td>
                            <td colspan="2">{{obj.Cost}}</td>
                            <td><button class="btn btn-info text-center" (click)="showModal(obj.Id, 'Info')"
                                    [disabled]="tableEngine.currentAction==='loading'" style="font-weight: bold; width: 100%;">{{"More"
                                    | translate}}</button></td>
                            <td><button class="btn btn-outline-warning text-center" (click)="showModal(obj.Id, 'Edit')"
                                    [disabled]="tableEngine.currentAction==='loading'" style="font-weight: bold; width: 100%;">{{"Edit"
                                    | translate}}</button></td>
                            <td><button class="btn btn-outline-danger text-center" (click)="tableEngine.showDelModal(obj.Id)"
                                    [disabled]="tableEngine.currentAction==='loading'" style="font-weight: bold; width: 100%">{{"Delete"
                                    | translate}}</button></td>
                        </tr>
                    </tbody>
                    <thead class="thead-light">
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </thead>
                </table>
            </div>
        </div>
    </div>
    
    <div id="divbottom" style="display: block; height: 47px;">
    
        <div id="divsearchright" style="float: right;  width: 33.33%; padding: 0px;">&nbsp;</div>
    
        <div id="divsearchmiddle" style="display: inline-block; width: 33.33%; height: 100%;">
    
            <div style="text-align: center;">
                <bdo dir="ltr">
                    <pagination class="pagination-sm2" style="padding: 0px, 0px, 0px, 0px; margin: 0px, 0px, 0px, 0px;
                    display: inline-block;" [(ngModel)]="currentPage" [boundaryLinks]="true"
                        [totalItems]="tableEngine.filteredData.length" [itemsPerPage]="tableEngine.itemsPerPage"
                        [customFirstTemplate]="firstTemplate" [customLastTemplate]="lastTemplate"
                        [customPreviousTemplate]="prevTemplate" [customNextTemplate]="nextTemplate" [maxSize]="5"
                        (pageChanged)="tableEngine.changePage($event)">
                    </pagination>
                </bdo>
            </div>
        </div>
    
        <div id="divsearchleft" style="float: left; width: 33.33%; height: 100%;">&nbsp;</div>
    
    </div>
</div>

<div>

</div>

<ng-template #nextTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i *ngIf="lang==='en-US'" class='simple-icon-arrow-right'></i>
    <i *ngIf="lang==='ar-EG'" class='simple-icon-arrow-left'></i>
</ng-template>

<ng-template #prevTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i *ngIf="lang==='en-US'" class='simple-icon-arrow-left'></i>
    <i *ngIf="lang==='ar-EG'" class='simple-icon-arrow-right'></i>
</ng-template>

<ng-template #lastTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i class='simple-icon-control-end'></i>
</ng-template>

<ng-template #firstTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i class='simple-icon-control-start'></i>
</ng-template>


