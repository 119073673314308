
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { MyPharmacyComponent } from './my-pharmacy/my-pharmacy.component';
import { MyPharmacyPackagesComponent } from './my-pharmacy-packages/components/my-pharmacy-packages/my-pharmacy-packages.component';
import { MyPharmacySubscriptionComponent } from './my-pharmacy-subscription/components/my-pharmacy-subscription/my-pharmacy-subscription.component';
import { CreateMyPharmacySubscriptionComponent } from './my-pharmacy-subscription/components/create-my-pharmacy-subscription/create-my-pharmacy-subscription.component';

const routes: Routes = [
    {
        path: '', component: MyPharmacyComponent,
        
    },
    {
        path: 'packages', component: MyPharmacyPackagesComponent,
        
    },
    {
        path: 'subscriptions', component: MyPharmacySubscriptionComponent,
        
    },
    {
        path: 'create-subscription/:Id', component: CreateMyPharmacySubscriptionComponent,
        
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class MyPharmacyRoutingModule { }