import { CostCenterModel } from './../cost-center/models/cost-center.model';
export class JournalFooterCostCenterModel{
    Id?:number;
    JournalFooterId?:number;
    Percent?:number;
    Amount?:number;
    CostCenterId?:number;
    centers?:CostCenterModel[];
    constructor(item?:JournalFooterCostCenterModel){
        item = item ? item:{};
        this.Id = item.Id?item.Id:0;
        this.JournalFooterId = item.JournalFooterId?item.JournalFooterId:0;
        this.Percent = item.Percent ? item.Percent : 0;
        this.Amount = item.Amount ? item.Amount:0;
        this.CostCenterId = item.CostCenterId ? item.CostCenterId:0;
        this.centers = item.centers ? item.centers:[];
    }
}