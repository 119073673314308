import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BranchesModel } from '../models/BranchesModel';

@Injectable({
  providedIn: 'root'
})
export class ValidityService {



  constructor(private http:HttpClient ) { }

  
  baseUrl = environment.baseApiUrl;
  token = localStorage.getItem("token");
  httpOptions = {
   headers: new HttpHeaders({
     // 'Content-Type': 'application/json; charset=utf-8',
     // 'Authorization': "Bearer " + token,
      "Authorization": "Bearer " + this.token,
      "Content-Type": "application/json"
   })
 };

 getAllBranches(): Observable<any> {
   
  return this.http
    .get<any>(`${this.baseUrl}Branches/GetAll`,this.httpOptions)
    .pipe(catchError((error: any) => Observable.throw(error => error)));
}

getBranchesById(Id:number): Observable<any> {

  return this.http
    .get<any>(`${this.baseUrl}Branches/GetById/${Id}`,this.httpOptions)
    .pipe(catchError((error: any) => Observable.throw(error => error)));
}


getAllEmployees(): Observable<any> {
   
  return this.http
    .get<any>(`${this.baseUrl}Employees/GetAll`,this.httpOptions)
    .pipe(catchError((error: any) => Observable.throw(error => error)));
}

getEmployeesById(Id:number): Observable<any> {

  return this.http
    .get<any>(`${this.baseUrl}Employees/GetById/${Id}`,this.httpOptions)
    .pipe(catchError((error: any) => Observable.throw(error => error)));
}




getAllJobRoles(): Observable<any> {
   
  return this.http
    .get<any>(`${this.baseUrl}JobRole/GetAll`,this.httpOptions)
    .pipe(catchError((error: any) => Observable.throw(error => error)));
}

getJobRoleById(Id:number): Observable<any> {

  return this.http
    .get<any>(`${this.baseUrl}JobRole/GetById/${Id}`,this.httpOptions)
    .pipe(catchError((error: any) => Observable.throw(error => error)));
}

// insert(payload: BranchesModel): Observable<any> {
  
//   return this.http
//     .post<any>(`${this.baseUrl}warehouse/Insert`, payload,this.httpOptions)
//     .pipe(catchError((error: any) => Observable.throw(error => error)));
// }

// update(payload: BranchesModel): Observable<any> {
//   return this.http
//     .post<any>(`${this.baseUrl}warehouse/Update`, payload,this.httpOptions)
//     .pipe(catchError((error: any) => Observable.throw(error => error)));
// }

// delete(Id:number): Observable<any> {
//   return this.http
//     .get<any>(`${this.baseUrl}warehouse/Delete/${Id}`,this.httpOptions)
//     .pipe(catchError((error: any) => Observable.throw(error => error)));
// }

//   deleteList(payload: BranchesModel []): Observable<any> {
//     console.log(JSON.stringify(payload))
//     return this.http
//       .post<any>(`${this.baseUrl}warehouse/DeleteList`,JSON.stringify(payload),this.httpOptions)
//       .pipe(catchError((error: any) => Observable.throw(error => error)));
//   }
}
