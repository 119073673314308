import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CityModel } from '../model/citymodel';

@Injectable({
  providedIn: 'root'
})
export class CityServes {
    CompanyId:number =+ localStorage.getItem("CompanyId");
    baseUrl = environment.baseApiUrl;
   token = localStorage.getItem("token");
   httpOptions = {
    headers: new HttpHeaders({
      // 'Content-Type': 'application/json; charset=utf-8',
      // 'Authorization': "Bearer " + token,
       "Authorization": "Bearer " + this.token,
       "Content-Type": "application/json"
    })
  };
  constructor(private http: HttpClient) {

  }
  getAll(): Observable<any> {
 
    return this.http
      .get<any>(`${this.baseUrl}City/GetAll/${this.CompanyId}`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error => error)));
  }


 
  postData(payload: CityModel): Observable<any> {
    
    return this.http
      .post<any>(`${this.baseUrl}City/Insert/${this.CompanyId}`, payload,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error => error)));
  }

 getById(id:number): Observable<any> {
 
    // debugger;
    return this.http
      .get<any>(`${this.baseUrl}City/GetById/${id}`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error => error)));
  }
  insert(payload: CityModel): Observable<any> {
    
    debugger;
    return this.http
      .post<any>(`${this.baseUrl}City/Insert/${this.CompanyId}`, payload,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error => error)));
  }

  update(payload: CityModel): Observable<any> {
    debugger;
    return this.http
      .post<any>(`${this.baseUrl}City/Update`, payload,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error => error)));
  }

  delete(id: number): Observable<any> {
    return this.http
      .get<any>(`${this.baseUrl}City/Delete/${id}`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error => error)));
  }
  deleteList(payload: CityModel []): Observable<any> {
    return this.http
      .post<any>(`${this.baseUrl}City/DeleteList`,JSON.stringify(payload),this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error => error)));
  }
}
