import { Component, OnInit } from '@angular/core';
import { ChecksReceivedModel } from '../../models/checks-received.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CurrencyModel } from 'src/app/invoices/currencies/CurrencyModel';
import { AccountNameModel } from '../../../../account-name/AccountNameModel';
import { CurrencyService } from 'src/app/invoices/currencies/CurrencyService';
import { AccountsNameService } from '../../../../account-name/AccountNameService';
import { ActivatedRoute, Router } from '@angular/router';
import {ChecksReceivedService  } from '../../checks-received.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationType, NotificationsService } from 'angular2-notifications';
@Component({
    selector: 'create-checks-received',
    templateUrl: 'create-checks-received.component.html',
    styleUrls: ['create-checks-received.component.scss']
})
export class CreateChecksReceivedComponent implements OnInit{
    ChecksReceived:ChecksReceivedModel={};
    declare lang: any;
    Id=0;
    Form:FormGroup;
    buttonName='Receive';
    
    curenncies:CurrencyModel[]=[];
    users:any[]=[];
    public editEnabled = true;
   public picurl: string ='';
   AccountsNameModel: AccountNameModel[] = [];
   PackUpAccounts: AccountNameModel[] = [];
   Treasuries:any[]=[];
    constructor(
        private CurrencyService: CurrencyService, 
        private accountsNameService: AccountsNameService,
       private route: ActivatedRoute,
       public router:Router,
       private ChecksReceivedService:ChecksReceivedService,
       public fb:FormBuilder,
       private translate: TranslateService,
        private notifications: NotificationsService
        ){
     this.lang = localStorage.getItem('theme_lang');
     this.Id = + route.snapshot.paramMap.get('Id');
       
   }
   centersLoaded= false;
   TreasuriesLoaded= false;
   taxesLoaded= false;
   AccountsLoaded= false;
   categoriesLoaded= false;
   curennciesLoaded= false;
    ngOnInit(): void {
     
        this.accountsNameService.GetAllSubAccountes(0).subscribe(
            data=>{
                this.AccountsNameModel=data;
                this.PackUpAccounts = data.map(item=>Object.assign({}, item))
                this.AccountsLoaded = true;

            }
        );
       
        this.CurrencyService.getAll()
        .subscribe(data => {
            this.curenncies = data;

            this.curenncies.forEach(element => {
                if(element.IsMain == true){
                    this.Form.controls['CurrencyId'].setValue(element.Id);
                }
            });
            this.curennciesLoaded = true;
        })
  
        if(this.Id == 0){
            this.buttonName = "Receive"
            this.picurl = '';

            this.ChecksReceived = new ChecksReceivedModel();
            this.Form = this.createForm();
            this.curenncies.forEach(element => {
                if(element.IsMain == true){
                    this.Form.controls['CurrencyId'].setValue(element.Id);
                }
            });
        }
        else{
            this.ChecksReceivedService.getById(this.Id)
            .subscribe(data=>{
                this.buttonName = "Edit"
                
                this.ChecksReceived =data;
                this.DueDateString= new Date(this.ChecksReceived.DueDate).toISOString().slice(0, 10);
                this.ReleaseDateString= new Date(this.ChecksReceived.ReleaseDate).toISOString().slice(0, 10);
                this.picurl = this.ChecksReceived.FileUrl;
                this.Form = this.createForm();
            })
        }
        
    }
    DueDateString = '';
    ReleaseDateString = '';
    createForm():FormGroup{
        return this.fb.group({
            Id                    :[this.ChecksReceived.Id                   ],
            Amount                :[this.ChecksReceived.Amount               ,Validators.required],
            CurrencyId            :[this.ChecksReceived.CurrencyId           ,Validators.required],
            Description           :[this.ChecksReceived.Description          ],
            ReleaseDate           :[this.ChecksReceived.ReleaseDate          ,Validators.required],
            DueDate               :[this.ChecksReceived.DueDate             ,Validators.required],
            CheckNumber           :[this.ChecksReceived.CheckNumber          ,Validators.required],
            AccountReceivedId     :[this.ChecksReceived.AccountReceivedId    ,Validators.required],
            TheCollectionAccount  :[this.ChecksReceived.TheCollectionAccount ,Validators.required],
            TheNameIsOnTheCheck   :[this.ChecksReceived.TheNameIsOnTheCheck  ,Validators.required],
            IdEndorsement         :[this.ChecksReceived.IdEndorsement        ],
            EndorsementName       :[this.ChecksReceived.EndorsementName      ],
            FileUrl               :[this.ChecksReceived.FileUrl              ],
            IsCollection          :[this.ChecksReceived.IsCollection         ],
            CollectionDate        :[this.ChecksReceived.CollectionDate       ],
            CollectionDescription :[this.ChecksReceived.CollectionDescription],
            CollectionBy          :[this.ChecksReceived.CollectionBy         ],
            IsDeposit             :[this.ChecksReceived.IsDeposit            ],
            DepositDate           :[this.ChecksReceived.DepositDate          ],
            DepositDescription    :[this.ChecksReceived.DepositDescription   ],
            DepositAccountId      :[this.ChecksReceived.DepositAccountId     ],
            DepositBy             :[this.ChecksReceived.DepositBy            ],
            IsRejected            :[this.ChecksReceived.IsRejected           ],
            RejectedDate          :[this.ChecksReceived.RejectedDate         ],
            RejectedDescription   :[this.ChecksReceived.RejectedDescription  ],
            RejectedBy            :[this.ChecksReceived.RejectedBy           ],

        })
    }

  
    Save(){
        debugger;
        this.ChecksReceived = this.Form.getRawValue();
        this.ChecksReceived.Id = this.Id;
        this.ChecksReceived.FileUrl = this.picurl ;
        this.ChecksReceived.DueDate = new Date(this.ChecksReceived.DueDate);
        this.ChecksReceived.ReleaseDate = new Date(this.ChecksReceived.ReleaseDate);
        console.log(this.ChecksReceived);
        if(this.Id > 0 ){
            this.ChecksReceivedService.update(this.ChecksReceived)
            .subscribe(result => {
                if(result == true){
                    this.router.navigate(['app/accounts/checks-received']);
                    this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyEdited"));
    
                }
                else{
                 this.toastError(this.translate.instant("Error"), this.translate.instant("FailedEdit"));

                }
            })
        }
        else{
            this.ChecksReceivedService.insert(this.ChecksReceived)
            .subscribe(result => {
                if(result == true){
                    this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyAdd"));
                    this.router.navigate(['app/accounts/checks-received']);
                }
                else{
                 this.toastError(this.translate.instant("Error"), this.translate.instant("FailedAdd"));
                }
            })
        }
   
    }
    addAndNew(){
        debugger;
        this.ChecksReceived = this.Form.getRawValue();
        this.ChecksReceived.Id = this.Id;
        this.ChecksReceived.FileUrl = this.picurl ;
 
        if(this.Id > 0 ){
            this.ChecksReceivedService.update(this.ChecksReceived)
            .subscribe(result => {
                if(result == true){
                    this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyEdited"));
                    this.ChecksReceived = new ChecksReceivedModel();
                   this.picurl = '';
                }
                else{
                 this.toastError(this.translate.instant("Error"), this.translate.instant("FailedEdit"));
                }
            })
        }
        else{
            this.ChecksReceivedService.insert(this.ChecksReceived)
            .subscribe(result => {
                if(result == true){
                    this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyAdd"));
                    this.ChecksReceived = new ChecksReceivedModel();
                    this.picurl = '';
                }
                else{
                    this.toastError(this.translate.instant("Error"), this.translate.instant("FailedAdd"));
                }
            })
        }
   
    }
    clear(){
        this.picurl = '';
     }
    toastSuccess(toastHeader: string, toastBody: string): void {
        this.notifications.create(toastHeader, toastBody,
          NotificationType.Success, { timeOut: 3000, showProgressBar: true });
      }
      toastError(toastHeader: string, toastBody: string): void {
        this.notifications.create(toastHeader, toastBody,
          NotificationType.Error, { timeOut: 3000, showProgressBar: true });
      }
}

