import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { BoardsOfDirectorsModel } from '../../models/boards-of-directors.model';

@Component({
    // moduleId: module.id,
    selector: 'add-board-of-director',
    templateUrl: 'add-board-of-director.component.html',
    styleUrls: ['add-board-of-director.component.scss']
})
export class AddBoardOfDirectorComponent implements OnInit {
    showCard: boolean = false;
    ItemHolderList: any[] = [];
    Form: FormGroup;
    lang: string;
  
    constructor(
      public router:Router, 
      public fb:FormBuilder, 
      ) {
    
   
    }
 
    ngOnInit() {
        this.Form = this.createForm(new BoardsOfDirectorsModel());        
    }

  
    Save() {
        this.router.navigate(['app/council-affairs/board-of-directors'])

    }
 
  goBack(){
    this.router.navigate(['app/council-affairs/board-of-directors'])
  }
  famly=[
    {Name:'Mohamed Sayed'},
    {Name:'Mohamed Nouh'},
    {Name:'Ahmed Abd-elazim'},
]
  
  createForm(modelObject:BoardsOfDirectorsModel): FormGroup {
    debugger;
    return this.fb.group({
     Id:[modelObject.Id],
     ArabicName                       :[modelObject.ArabicName                       ],
     EnglishName                      :[modelObject.EnglishName                      ],
     Nots                            :[modelObject.Nots                            ],
     File                            :[modelObject.File                            ],
     MemberId                            :[modelObject.MemberId                            ],
     IsBlocked                        :[modelObject.IsBlocked                        ],
     UserId                           :[modelObject.UserId                           ],
     CreateBy                         :[modelObject.CreateBy                         ],
     CreateDate                       :[modelObject.CreateDate                       ],
     ModifiedBy                       :[modelObject.ModifiedBy                       ],
     ModifiedDate                     :[modelObject.ModifiedDate                     ],
     IsDeleted                        :[modelObject.IsDeleted                        ],
     DeletedBy                        :[modelObject.DeletedBy                        ],
     DeletedDate                      :[modelObject.DeletedDate                      ],
    });
  }
  public editEnabled = true;
  public picurl: string ='';

  clear(){
    this.picurl = '';
 }
 public NationalIdEditEnabled = true;
 public NationalIdPicurl: string ='';

 clearNationalId(){
   this.NationalIdPicurl = '';
}
public graduationEditEnabled = true;
public graduationPicurl: string ='';

cleargraduation(){
  this.graduationPicurl = '';
}

  }
  
