import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {CountryCodeModel} from '../CountryCodeModel';

@Injectable({
  providedIn: 'root'
})
export class CountryCodeService {

  constructor(private http:HttpClient ) { }

  
  baseUrl = environment.baseApiUrl;
  token = localStorage.getItem("token");
  httpOptions = {
   headers: new HttpHeaders({
     // 'Content-Type': 'application/json; charset=utf-8',
     // 'Authorization': "Bearer " + token,
      "Authorization": "Bearer " + this.token,
      "Content-Type": "application/json"
   })
 };

 getAll(): Observable<any> {
   
  return this.http
    .get<any>(`${this.baseUrl}CountryCode/GetAll`,this.httpOptions)
    .pipe(catchError((error: any) => Observable.throw(error => error)));
}
getById(id:number): Observable<any> {

  return this.http
    .get<any>(`${this.baseUrl}CountryCode/GetById/${id}`,this.httpOptions)
    .pipe(catchError((error: any) => Observable.throw(error => error)));
}
insert(payload: CountryCodeModel): Observable<any> {
  
  return this.http
    .post<any>(`${this.baseUrl}CountryCode/Insert`, payload,this.httpOptions)
    .pipe(catchError((error: any) => Observable.throw(error => error)));
}

update(payload: CountryCodeModel): Observable<any> {
  return this.http
    .put<any>(`${this.baseUrl}CountryCode/Update`, payload,this.httpOptions)
    .pipe(catchError((error: any) => Observable.throw(error => error)));
}

delete(payload: CountryCodeModel): Observable<any> {
  return this.http
    .get<any>(`${this.baseUrl}CountryCode/Delete/${payload.Id}`,this.httpOptions)
    .pipe(catchError((error: any) => Observable.throw(error => error)));
}

  deleteList(payload: CountryCodeModel []): Observable<any> {
    return this.http
      .put<any>(`${this.baseUrl}CountryCode/DeleteList`,JSON.stringify(payload),this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error => error)));
  }
}
