import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent  {

  myDate:string = new Date().toString();
  constructor(private datePipe: DatePipe){
      this.myDate = this.datePipe.transform(this.myDate, 'y');
  }
}
