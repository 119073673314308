<!--component html goes here -->
<!-- 
<div bsModal #smallModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <button type="button" class="close pull-right" aria-label="Close" (click)="smallModal.hide()"
                style="margin-right: 20px; margin-top: 10px; width: 20px; display: inline;">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-header" style="display: block; padding: 0px 0px 5px 0px; text-align: center; ">
                <h1 id="dialog-child-name" class="modal-title">
                    {{currentFormMode+translationName | translate}}</h1>
            </div>
            <div class="modal-body">
                <form class="md-float-material form-material" [formGroup]="mainForm">
                    <div class="form-group form-primary">
                        <label class="float-label push righ">{{"ExpensCategoryNameAr" | translate}}</label>
                        <input *ngIf="currentFormMode==='Add'||currentFormMode==='Edit'" type="text" name="NameAr"
                            class="form-control" formControlName="NameAr"
                            placeholder='{{"ExpensCategoryNameAr" | translate}}'>
                        <label *ngIf="currentFormMode==='Info'" class="form-control" type="text"
                            name="NameAr">{{modelObject.NameAr}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('NameAr').hasError('required') || mainForm.get('NameAr').invalid) && ( mainForm.get('NameAr').dirty ||  mainForm.get('NameAr').touched)">
                            {{"ExpensCategoryNameArRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>

                    <div class="form-group form-primary">
                        <label class="float-label push righ">{{"ExpensCategoryNameEn" | translate}}</label>
                        <input *ngIf="currentFormMode==='Add'||currentFormMode==='Edit'" type="text" name="NameEn"
                            class="form-control" formControlName="NameEn"
                            placeholder='{{"ExpensCategoryNameEn" | translate}}'>
                        <label *ngIf="currentFormMode==='Info'" class="form-control" type="text"
                            name="NameEn">{{modelObject.NameEn}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('NameEn').hasError('required') || mainForm.get('NameEn').invalid) && ( mainForm.get('NameEn').dirty ||  mainForm.get('NameEn').touched)">
                            {{"ExpensCategoryNameEnRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>

                    <div style="margin-left: 5px; text-align: center; margin-top: 30px;">
                        <button *ngIf="currentFormMode==='Add'"
                            [disabled]="mainForm.invalid || currentAction==='loading'" id="smallModalSubmitButton"
                            class="btn btn-secondary" (click)="addObject()"
                            style="font-weight: bold; width: 100%; text-align: center;">{{"Add" |
                            translate}}</button>
                        <button *ngIf="currentFormMode==='Edit'"
                            [disabled]="mainForm.invalid || currentAction==='loading'" id="smallModalSubmitButton"
                            class="btn btn-secondary" (click)="editObject()"
                            style="font-weight: bold; width: 100%; text-align: center;">{{"Edit"
                            | translate}}</button>
                        <button class="btn btn-light" (click)="smallModal.hide()"
                            style="font-weight: bold; width: 100%; text-align: center; margin-top: 10px;">{{"Close" |
                            translate}}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div> -->

<div bsModal #delModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">
    <div class="modal-dialog modal-sm">
        <div class="modal-content"  >
            <button type="button" class="close pull-right" aria-label="Close" (click)="delModal.hide()"
                style="margin-right: 20px; margin-left: 20px; margin-top: 10px; width: 20px; display: inline;">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-header" style="display: inline; padding: 0px 0px 5px 0px;">
                <h4 id="dialog-child-name" class="modal-title pull-left"  style="text-align: center;">
                    {{"ConfirmDel"+translationName |
                    translate}}</h4>
            </div>
            <div class="modal-body">
                <div class="form-group form-primary" *ngIf="deletionList.length < ogData.length" style="text-align: center;">
                    <div *ngFor="let item of deletionList;" style="text-align: center;">
                        <label type="text">- {{lang==='ar-EG'?item.NameAr:item.NameEn}}</label>
                        <span class="form-bar"></span>
                    </div>
                </div>
                <div class="form-group form-primary" style="text-align: center;" *ngIf="deletionList.length === ogData.length">
                    <label type="text">
                        <h1 style="font-weight: bolder; text-align: center;">{{translationName+"DeleteAll" | translate}}</h1>
                    </label>
                    <span class="form-bar"></span>
                </div>

                <div style="margin-left: 5px; text-align: center; margin-top: 30px;">
                    <button class="btn btn-danger" id="smallModalDeleteButton" (click)="deleteObject()"
                        [disabled]="currentAction==='loading'"
                        style="font-weight: bold; width: 100%; text-align: center;">{{"Delete" | translate}}</button>
                    <button class="btn btn-light" (click)="delModal.hide()"
                        style="font-weight: bold; width: 100%; text-align: center; margin-top: 10px;">{{"Close" |
                        translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="FormScreen" class="tableScreen">
    <!-- <div id="divtop" style="display: block;height: 47px; width: 100%;">
        <div id="divsearchmiddle" style="display: inline-block; width: 70%;
        height: inherit;  text-align: center; padding: 0px; margin: 0px;">
            <div class="search" style="display: inline-block; margin-left: 50px; margin-right: 50px;">
                <div class="d-inline-block">
                    <div class="search-sm d-inline-block mr-1 mb-1 align-top" style="width: 600px;">
                        <input class="form-control" type="file" #fileInput />
                    </div>
                </div>
            </div>
        </div>
    
        <div id="divsearchleft" style="display: inline-block; width: 30%; height: inherit;">
            <div style="display: block; height: 30%; ">
                &nbsp;
            </div>
            <div class="search" style="display: block; height: 70%; text-align: center;">
                <button style="font-weight: bold; text-align: center; padding: 0px; width: 150px; height: 30px;" class="btn btn-primary" (click)="uploadFile();">{{'Upload'|translate}}</button>
        
    
            </div>
        </div>
   
    </div> -->
    <div id="divtop" style="display: block;height: 47px; width: 100%;">
        <div id="divsearchmiddle" style="display: inline-block; width: 70%;
        height: inherit;  text-align: center; padding: 0px; margin: 0px;">
            <div class="search" style="display: inline-block; margin-left: 50px; margin-right: 50px;">
                <div class="d-inline-block">
                    <div class="search-sm d-inline-block mr-1 mb-1 align-top" style="width: 600px;">
                        <input type="search" [(ngModel)]="SearchTerm" (keyup)="changeFilter()" (change)="changeFilter()"
                            class="form-control input-sm full-data-search" placeholder="{{translationName+'Search' | translate}}">
                    </div>
                </div>
            </div>
        </div>
    
        <div id="divsearchleft" style="display: inline-block; width: 30%; height: inherit;">
            <div style="display: block; height: 30%; ">
                &nbsp;
            </div>
            <div class="search" style="display: block; height: 70%; text-align: center;">
                <a class="btn btn-primary d-inline-block" 
                 [routerLink]="['../Create-Suppliers/Add/0']"
                    style="font-weight: bold; text-align: center; padding: 0px; width: 150px; height: 30px;"
                    >{{"Add"+translationName |translate}}</a>
            </div>
        </div>
    
    </div>
  
        
    <div id="divmiddle" style="display: block; margin-top: 5px;">
    
        <div style="width: 100%;">
            <div>
                <table class="table">
                    <thead class="thead-light">
                        <th style="text-align: center; padding:5px;">
                            <div class=" text-zero" style="display: inline-block;">
                                <div class="top-right-button-container2 btn-group" dropdown>
                                    <div class="btn btn-primary pl-4 pr-0 check-button">
                                        <label class="custom-control custom-checkbox mb-0 d-inline-block"
                                            style="width: 10px;">
                                            <input type="checkbox" class="custom-control-input"
                                                [checked]="selectAllState==='checked'"
                                                [indeterminate]="selectAllState === 'indeterminate'"
                                                (change)="selectAll($event)">
                                            <span class="custom-control-label">&nbsp;</span>
                                        </label>
                                    </div>
                                    <button id="button-split" type="button" dropdownToggle style="width: 10px;"
                                        class="btn btn-primary dropdown-toggle dropdown-toggle-split"
                                        aria-controls="dropdown-split">
                                        <span class="caret"></span>
                                    </button>
                                    <ul id="dropdown-split" *dropdownMenu class="dropdown-menu dropdown-menu-right"
                                        role="menu" aria-labelledby="button-split">
                                        <li role="menuitem"><a class="dropdown-item">
                                                <button class="btn btn-danger d-inline-block"
                                                    style=" font-weight: bold; text-align: center; padding: 0px; width: 150px; height: 30px;"
                                                    (click)="showDelModal('delMulti')"
                                                    [disabled]="selected.length===0 || currentAction==='loading'">{{"DeleteSelected"
                                                    |
                                                    translate}}</button></a></li>
                                    </ul>
                                </div>
                            </div>
                        </th>
                        <th class="sorticon" (click)="changeSort(getSort('TaxTypesName'))" [ngClass]="(itemOrder.label==='TaxTypesName')?'sortkey sorticon':'sorticon'">
                            <div [ngClass]="{'iconsminds-up---down': (itemOrder.label!='TaxTypesName'), 
                            'iconsminds-down': ((itemOrder.label==='TaxTypesName') && orderAsc === true),
                            'iconsminds-up': ((itemOrder.label==='TaxTypesName') && orderAsc === false)}"></div></th>
                        <th class="sortOption" (click)="changeSort(getSort('TaxTypesName'))" 
                        [ngClass]="{'sortkey': (itemOrder.label==='TaxTypesName')}">
                                {{"Name" | translate}}
                        </th>
                 
                        <th class="sorticon" (click)="changeSort(getSort('TaxSubType'))" [ngClass]="(itemOrder.label==='TaxSubType')?'sortkey sorticon':'sorticon'">
                            <div [ngClass]="{'iconsminds-up---down': (itemOrder.label!='TaxSubType'), 
                            'iconsminds-down': ((itemOrder.label==='TaxSubType') && orderAsc === true),
                            'iconsminds-up': ((itemOrder.label==='TaxSubType') && orderAsc === false)}"></div></th>
                        <th class="sortOption" (click)="changeSort(getSort('TaxSubType'))" 
                        [ngClass]="{'sortkey': (itemOrder.label==='TaxSubType')}">
                                {{"Phone" | translate}}
                        </th>
                        <th class="sorticon" (click)="changeSort(getSort('TaxTypesPercentage'))" [ngClass]="(itemOrder.label==='TaxTypesPercentage')?'sortkey sorticon':'sorticon'">
                            <div [ngClass]="{'iconsminds-up---down': (itemOrder.label!='TaxTypesPercentage'), 
                            'iconsminds-down': ((itemOrder.label==='TaxTypesPercentage') && orderAsc === true),
                            'iconsminds-up': ((itemOrder.label==='TaxTypesPercentage') && orderAsc === false)}"></div></th>
                        <!-- <th class="sortOption" (click)="changeSort(getSort('TaxTypesPercentage'))" 
                        [ngClass]="{'sortkey': (itemOrder.label==='TaxTypesPercentage')}">
                                {{"CountryId" | translate}}
                        </th> -->
                       
                        <th></th>
                        <th [colSpan]="2" style="text-align: center;">
                            <div id="displayOptions" style="
                            display: inline; margin-top: 10px; margin-left: 5px; margin-right: 5px;">
                                <span class="text-muted text-small" style=" width: 50px; height: 40px;">
                                    {{"ResultCount" | translate}}
                                </span>
                                <span dropdown class="d-inline-block position-relative">
                                    <button class="btn btn-outline-dark btn-xs dropdown-toggle" type="button"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" dropdownToggle>
                                        {{itemsPerPage}}
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-sm text-right"
                                        *dropdownMenu>
                                        <li role="menuitem" *ngFor="let item of itemOptionsPerPage"><a
                                                [ngClass]="item === itemsPerPage ? 'dropdown-item active' : 'dropdown-item'"
                                                (click)="paginateData(item, 1)">{{item}}</a></li>
                                    </div>
                                </span>
                            </div>
                        </th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let obj of paginatedData;  let i=index">
                            <td style="text-align: center;">
                                <div class="itemCheck mb-0 custom-checkbox custom-control" style="display: inline-block;">
                                    <input type="checkbox" class="custom-control-input" [id]="'customCheck'+i"
                                        [checked]="isSelected(obj)" (change)="onSelect(obj)">
                                    <label class="custom-control-label" [for]="'customCheck'+i"></label>
                                </div>
                            </td>
                            
                            <td colspan="2">{{lang==='ar-EG'?obj.NameAr:obj.NameEn}}</td>
                            <td colspan="2">{{obj.Phone}}</td>
                            <!-- <td colspan="2">{{obj.CitiyName}}</td> -->


                            <td><button class="btn btn-info text-center" [routerLink]="['../Create-Suppliers/Show/',obj.Id]" 
                                    [disabled]="currentAction==='loading'" style="font-weight: bold; width: 100%;">{{"More"
                                    | translate}}</button></td>
                            <td><button class="btn btn-outline-warning text-center"  [routerLink]="['../Create-Suppliers/Edit/',obj.Id]"
                                    [disabled]="currentAction==='loading'" style="font-weight: bold; width: 100%;">{{"Edit"
                                    | translate}}</button></td>
                            <td><button class="btn btn-outline-danger text-center" (click)="showDelModal(obj.Id)"
                                    [disabled]="currentAction==='loading'" style="font-weight: bold; width: 100%">{{"Delete"
                                    | translate}}</button></td>
                        </tr>
                    </tbody>
                    <thead class="thead-light">
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </thead>
                </table>
            </div>
        </div>
    </div>
    
    <div id="divbottom" style="display: block; height: 47px;">
    
        <div id="divsearchright" style="float: right;  width: 33.33%; padding: 0px;">&nbsp;</div>
    
        <div id="divsearchmiddle" style="display: inline-block; width: 33.33%; height: 100%;">
    
            <div style="text-align: center;">
                <bdo dir="ltr">
                    <pagination class="pagination-sm2" style="padding: 0px, 0px, 0px, 0px; margin: 0px, 0px, 0px, 0px;
                    display: inline-block;" [(ngModel)]="currentPage" [boundaryLinks]="true"
                        [totalItems]="filteredData.length" [itemsPerPage]="itemsPerPage"
                        [customFirstTemplate]="firstTemplate" [customLastTemplate]="lastTemplate"
                        [customPreviousTemplate]="prevTemplate" [customNextTemplate]="nextTemplate" [maxSize]="5"
                        (pageChanged)="changePage($event)">
                    </pagination>
                </bdo>
            </div>
        </div>
    
        <div id="divsearchleft" style="float: left; width: 33.33%; height: 100%;">&nbsp;</div>
    
    </div>
</div>

<div>

</div>

<ng-template #nextTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i *ngIf="lang==='en-US'" class='simple-icon-arrow-right'></i>
    <i *ngIf="lang==='ar-EG'" class='simple-icon-arrow-left'></i>
</ng-template>

<ng-template #prevTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i *ngIf="lang==='en-US'" class='simple-icon-arrow-left'></i>
    <i *ngIf="lang==='ar-EG'" class='simple-icon-arrow-right'></i>
</ng-template>

<ng-template #lastTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i class='simple-icon-control-end'></i>
</ng-template>

<ng-template #firstTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i class='simple-icon-control-start'></i>
</ng-template>


<simple-notifications></simple-notifications>