export class InventoryItemsModel{
    Id?: number;
    ItemId?:number;
    InventoryId?:number;
    UnitId?:number;
    QuantityNumberInStock?:number;
    QuantityNumberInProgram?:number;
    SalePrice?:number;
    ItemName?:string;

 
    constructor(item?:InventoryItemsModel){
        item = item? item:{};
        this.Id = item.Id? item.Id:0;
        this.ItemId = item.ItemId? item.ItemId:0;
        this.InventoryId = item.InventoryId? item.InventoryId:0;     
        this.UnitId = item.UnitId? item.UnitId:0;     
        this.QuantityNumberInStock = item.QuantityNumberInStock? item.QuantityNumberInStock:0;     
        this.QuantityNumberInProgram = item.QuantityNumberInProgram? item.QuantityNumberInProgram:0;     
        this.SalePrice = item.SalePrice? item.SalePrice:0;     
        this.ItemName = item.ItemName? item.ItemName:'';     

    }

}