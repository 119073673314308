import { BudgetAccountsModel } from "./budget-accounts.model";

export class BudgetModel{
    Id ?: number;
    BudgetAgainst                                    ?: number;
    MonthlyDistributionId                              ?: number;
    CostCenterNameAr                                 ?: string;
    CostCenterNameEn                                 ?: string;
    CostCenterId                                     ?: number;
    FiscalYearId                                     ?: number;
    ApplicableOnMaterialRequest                      ?: boolean;
    ApplicableOnBookingActualExpenses                ?: boolean;
    ApplicableOnPurchaseOrder                        ?: boolean;
    ActionIfAnnualBudgetExceededOnMR                 ?: string;
    ActionIfAnnualBudgetExceededOnPO                 ?: string;
    ActionIfAccumulatedMonthlyBudgetExceededOnMR     ?: string;
    ActionIfAccumulatedMonthlyBudgetExceededOnPO     ?: string;
    ActionIfAnnualBudgetExceededOnActual             ?: string;
    ActionIfAccumulatedMonthlyBudgetExceededOnActual ?: string;
    TotalAmount?:number;
    BudgetAccounts                                   ?:BudgetAccountsModel[];
    constructor(item ?: BudgetModel){
        item = item ? item : {};
        this.Id = item.Id ? item.Id : 0; 
        this.BudgetAgainst = item.BudgetAgainst ? item.BudgetAgainst : 0; 
        this.CostCenterId = item.CostCenterId ? item.CostCenterId : 0; 
        this.FiscalYearId = item.FiscalYearId ? item.FiscalYearId : 0; 
        this.TotalAmount = item.TotalAmount ? item.TotalAmount : 0; 
        this.MonthlyDistributionId = item.MonthlyDistributionId ? item.MonthlyDistributionId : 0; 
        this.ApplicableOnMaterialRequest = item.ApplicableOnMaterialRequest ? item.ApplicableOnMaterialRequest : false; 
        this.ApplicableOnBookingActualExpenses = item.ApplicableOnBookingActualExpenses ? item.ApplicableOnBookingActualExpenses : false; 
        this.ApplicableOnPurchaseOrder = item.ApplicableOnPurchaseOrder ? item.ApplicableOnPurchaseOrder : false; 
        this.ActionIfAnnualBudgetExceededOnMR = item.ActionIfAnnualBudgetExceededOnMR ? item.ActionIfAnnualBudgetExceededOnMR : ''; 
        this.ActionIfAnnualBudgetExceededOnPO = item.ActionIfAnnualBudgetExceededOnPO ? item.ActionIfAnnualBudgetExceededOnPO : ''; 
        this.ActionIfAccumulatedMonthlyBudgetExceededOnMR = item.ActionIfAccumulatedMonthlyBudgetExceededOnMR ? item.ActionIfAccumulatedMonthlyBudgetExceededOnMR : ''; 
        this.ActionIfAnnualBudgetExceededOnActual = item.ActionIfAnnualBudgetExceededOnActual ? item.ActionIfAnnualBudgetExceededOnActual : ''; 
        this.ActionIfAccumulatedMonthlyBudgetExceededOnPO = item.ActionIfAccumulatedMonthlyBudgetExceededOnPO ? item.ActionIfAccumulatedMonthlyBudgetExceededOnPO : ''; 
        this.ActionIfAccumulatedMonthlyBudgetExceededOnActual = item.ActionIfAccumulatedMonthlyBudgetExceededOnActual ? item.ActionIfAccumulatedMonthlyBudgetExceededOnActual : ''; 
        this.BudgetAccounts = item.BudgetAccounts ? item.BudgetAccounts : []; 
    
    }
}