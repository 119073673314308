export class ProductTypeModel{
    Id?:number;
    NameAr?:string;
    NameEn?:string;
    constructor(obj?:ProductTypeModel){
        obj = obj? obj:{};
        this.Id = obj.Id? obj.Id:0;
        this.NameAr = obj.NameAr? obj.NameAr:'';
        this.NameEn = obj.NameEn? obj.NameEn:'';
    }
}