export class ActionsModel{
    Id?: number;
    Name?:string;


 
    constructor(item?:ActionsModel){
        item = item? item:{};
        this.Id = item.Id? item.Id:0;
        this.Name = item.Name? item.Name:'';
    }

}