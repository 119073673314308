
export class AccountNameModel{
    Id?:number;
    NameAr?:string;
    NameEn?:string;
    CompanyDataId?:number;
    AccountNumber?:number;
    Parentcode?:number;
    Levelcode?:number;
    AccounExpensesTypeId?:number;
    AccountFicationId?:number;
    AccountFicationType?:number;
    AccountMenuId?:number;
    AccountType?:number;
    AccountNature?:number;
    IsNotMenu?:boolean;
    ParentName?:string;
    Debit?:number;
    Credit?:number;
    IsCurrencyEvaluation   ?:boolean;
    ItIsLinkedToAcostCenter?:boolean;
    constructor(obj?:AccountNameModel){
        obj = obj? obj:{};
        this.Id = obj.Id? obj.Id:0;
        this.NameAr = obj.NameAr? obj.NameAr:'';
        this.NameEn = obj.NameEn? obj.NameEn:'';
        this.ParentName = obj.ParentName? obj.ParentName:'';
        this.CompanyDataId = obj.CompanyDataId? obj.CompanyDataId:0;
        this.AccountNumber = obj.AccountNumber? obj.AccountNumber:0;
        this.Parentcode = obj.Parentcode? obj.Parentcode:0;
        this.Levelcode = obj.Levelcode? obj.Levelcode:0;
        this.AccounExpensesTypeId = obj.AccounExpensesTypeId? obj.AccounExpensesTypeId:0;
        this.AccountFicationId = obj.AccountFicationId? obj.AccountFicationId:0;
        this.AccountFicationType = obj.AccountFicationType? obj.AccountFicationType:0;
        this.AccountMenuId = obj.AccountMenuId? obj.AccountMenuId:0;
        this.AccountType = obj.AccountType? obj.AccountType:0;
        this.AccountNature = obj.AccountNature? obj.AccountNature:0;
        this.Debit = obj.Debit? obj.Debit:0;
        this.Credit = obj.Credit? obj.Credit:0;
        this.IsNotMenu = obj.IsNotMenu? obj.IsNotMenu:false;
        this.IsCurrencyEvaluation    = obj.IsCurrencyEvaluation   ? obj.IsCurrencyEvaluation   :false;
        this.ItIsLinkedToAcostCenter = obj.ItIsLinkedToAcostCenter? obj.ItIsLinkedToAcostCenter:false;

    }
}