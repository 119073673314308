import { Component, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { WarehouseModel } from 'src/app/Invintory/models/WarehouseModel';
import { TableEngine } from 'src/app/invoices/TableEngine';
import { WarehouseService } from '../../warehouse-service.service';
import{ValidityService} from 'src/app/Invintory/warehouses/validity.service';
import { WarehouseValidityModel } from 'src/app/Invintory/models/WarehouseValidityModel';
import { WarehouseEmployeesModel } from 'src/app/Invintory/models/WarehousEmployees';
import { WarehouseBrancheModel } from 'src/app/Invintory/models/WarehousBranches';
import { WarehouseJobRoleModel } from 'src/app/Invintory/models/WarehousJobRoles';
import { SidePopupComponent } from 'src/app/views/invoice/side-popup/side-popup.component';
import { ModalDirective } from 'ngx-bootstrap/modal';
@Component({
    // moduleId: module.id,
    selector: 'info-warehouse',
    templateUrl: 'info-warehouse.component.html',
    styleUrls: ['info-warehouse.component.scss']
})
export class InfoWarehouseComponent {
    Id=0;

    constructor(public router:Router, 
           private fb:FormBuilder,
           private modelService: WarehouseService,
           private ValidityService: ValidityService,
        //    private StoreItemsService: StoreItemsService,        
           private notifications: NotificationsService, private translate: TranslateService,
           private route: ActivatedRoute
           ){
               this.Id = + route.snapshot.paramMap.get('Id');            
           }
   
           tableEngine = new TableEngine(this.translate, this.notifications);
           currentScreen: any = 'Table';
           translationName: string = 'Item'
           sortableKeys = [];
           searchableKeys = ['Name', 'Address'];
           tableView: any = true;
           
           // StoreItemsModel:StoreItemsModel[]=[];  
            
           itemsData:WarehouseValidityModel[]=[];
           EmployeesData:WarehouseEmployeesModel[]=[];
           BranchesData:WarehouseBrancheModel[]=[];
           JobRoleData:WarehouseJobRoleModel[]=[];
           modelObject: WarehouseModel = new WarehouseModel();
           // ItemsModel:ItemsModel[]=[]
           Form:FormGroup;
           declare lang: any;
           declare itemOrder: any;
           currentAction = '';
           savedPage = 0;
           currentPage = 1;
           deletionList: any[] = [];
           prevAction = '';    
           addToListId = 0;
           ItemsBalanceHeaderId=0;
           ItemBalanceId=0;
   currentFormMode: string = '';
   
   
   ogData: WarehouseModel[] = [];
    mainForm: FormGroup = new FormGroup({
        Id: new FormControl(),
        Name: new FormControl(),
        Address: new FormControl(),
        Mobil: new FormControl(),
        Phone:new FormControl(),
        Fax:new FormControl(),
        Description:new FormControl(),
        EmployeeId:new FormControl(),
        BranchId:new FormControl(),
        JobRoleId:new FormControl(),
        IsPrime:new FormControl(),
               
           });
   
   @ViewChild('sideModalRef', { static: true }) sideModalRef: SidePopupComponent = null;
   @ViewChild('delModal', { static: true }) delModal: ModalDirective;
   @ViewChild('smallModal', { static: true }) smallModal: ModalDirective = null;
   formPresentationModal: any;
   ngOnInit(): void {
       // this.getAllCountryCode();    
        
       this.lang = localStorage.getItem('theme_lang');
       this.itemOrder = { label: 'Name', property: this.lang === 'ar-EG' ? 'Name' : 'Name' };      
       this.sortableKeys = [         
           // { label: 'Date', property: 'Date' },        
           { label: 'Name', property: this.lang === 'ar-EG' ? 'Name' : 'Name' },   
           { label: 'Address', property: this.lang === 'ar-EG' ? 'Address' : 'Address' },   
           { label: 'Mobil', property: this.lang === 'ar-EG' ? 'Mobil' : 'Mobil' }, 
           { label: 'Phone', property: this.lang === 'ar-EG' ? 'Phone' : 'Phone' }, 
           { label: 'Fax', property: this.lang === 'ar-EG' ? 'Fax' : 'Fax' }, 
           { label: 'IsPrime', property: 'IsPrime' },
       ];
   
       this.tableEngine.loadTableEngine(this.ogData, this.modelService, this.modelObject,
           this.searchableKeys, this.sortableKeys, this.mainForm, this.translationName, this.itemOrder,
           this.sideModalRef, this.delModal, this.smallModal, this.lang
       )
       //this.tableEngine.modelObject = {}
       // console.log("KEEEEEEEEEEEEMO");
       
       // console.log(this.tableEngine.modelObject);
       // console.log(this.tableEngine.modelObject.constructor.name);
       
       this.loadServices();
       this.modelService.getAllWarehouseValidity()            
               .subscribe(data=>
                   {
                       this.itemsData=data
                       console.log('WarehouseValidity',this.itemsData)
                   });   
                   this.mainForm = this.createForm(this.modelObject);
                   this.ValidityService.getAllBranches().subscribe(data=>{
                    this.BranchesData=data
                   })
                   this.ValidityService.getAllEmployees().subscribe(data=>{
                    this.EmployeesData=data
                   })
                   this.ValidityService.getAllJobRoles().subscribe(data=>{
                    this.JobRoleData=data
                   })
   } 
   
           createForm(modelObject):FormGroup{
               debugger;
               return this.fb.group({
                   // Id:[this.ItemBalance.Id],
                   Id: [modelObject.Id >= 0 ? modelObject.Id : 0],   
                   Name: [modelObject.Name, Validators.compose([Validators.required, Validators.minLength(2)])],
                   Address: [modelObject.Address, Validators.compose([Validators.required])],
                   Mobil:[modelObject.Mobil, Validators.required],
                   Phone:[modelObject.Phone, Validators.required],
                   Fax:[modelObject.Fax, Validators.required],
                   Description:[modelObject.Description, Validators.required],                  
                   IsPrime: [modelObject.IsPrime==null?true:modelObject.IsPrime],
                               
               }) 
           }
   
           // ListStoreItems:StoreItemsModel[]=[];  
        //    PriceList:WarehouseModel[]=[];  
           WarehouseValidityList:WarehouseValidityModel[]=[];
           loadServices(){
               // debugger;
               this.currentAction = 'loading';
               this.tableEngine.loadData();         
             
              
           }
        //    addToList(){     
        //        debugger; 
        //        var i = this.WarehouseValidityList.findIndex(x=>x.Id == this.addToListId);
        //        if(i >= 0){
        //            var item = this.WarehouseValidityList[i];
                   
        //            this.WarehouseValidityList.push(item);            
        //            this.WarehouseValidityList.splice(i,1);
        //            this.addToListId = 0;
        //        }
        //    }
           getItemsById(Id:any):any{
               if(Id>0)
               {
               return this.itemsData.find(i=>i.Id===Id)
               }
               else
               {
               return new WarehouseValidityModel();
               }
               }
               type = 0;
               visable = '';
               visable2 = '';
               visable3 = '';
            //    setValidity(){
            //     if(this.visable == "موظف محدد"){
            //         this.type = 2;
            //     }
            //   else if (this.visable=="دور وظيفي محدد")
            //   {
            //     this.type=3;
            //   }
            //        }
                   AddEmployeesOnClick(){
   
                       var EmployeesList= new WarehouseEmployeesModel();
                       EmployeesList.Id = 0, 
                       EmployeesList.EmployeeId = 0,   
                       EmployeesList.WarehouseId =0, 

                       this.EmployeesData.push(EmployeesList);
                       EmployeesList={};
                       
                   }
                   AddBranchesOnClick(){
   
                    var BrancheList= new WarehouseBrancheModel();
                    BrancheList.Id = 0, 
                    BrancheList.BranchId =0, 
                    BrancheList.WarehouseId =0, 
                                          
                    this.BranchesData.push(BrancheList);
                    BrancheList={};
                    
                }
                AddJobRoleOnClick(){
   
                    var JobRoleList= new WarehouseJobRoleModel();
                    JobRoleList.Id = 0, 
                    JobRoleList.JobRoleId =0,                                           
                    JobRoleList.WarehouseId =0,                                           
                    this.BranchesData.push(JobRoleList);
                    JobRoleList={};
                    
                }
              
                    //  addItems(){
   
                    //  }
   
                     goBack(){
                       this.router.navigate(['app/aio/Inventory/List-All-Warehouse']);
                     }
                   Save(){
          
                       debugger;
                       this.currentAction = 'loading'; 
                       this.tableEngine.savedPage = this.tableEngine.currentPage;
                       this.modelObject = new WarehouseModel();
                       this.modelObject.Name=this.mainForm.getRawValue().Name;
                       this.modelObject.Address=this.mainForm.getRawValue().Address;
                       this.modelObject.IsPrime=this.mainForm.getRawValue().IsPrime;
                       this.modelObject.Mobil=this.mainForm.getRawValue().Mobil;
                       this.modelObject.Phone=this.mainForm.getRawValue().Phone;
                       this.modelObject.Description=this.mainForm.getRawValue().Description;
                       this.modelObject.Fax=this.mainForm.getRawValue().Fax;
                       this.modelObject.Branches=this.BranchesData;
                       this.modelObject.Employees=this.EmployeesData;
                       this.modelObject.JobRoles=this.JobRoleData;
                       console.log(this.modelObject);                    
                       this.modelObject.Id=0;
                       console.log(this.modelObject);
                   
                       if(this.Id == 0){
                           this.modelService.insert(this.modelObject)
                           .subscribe(data => {
                             this.router.navigate(['app/aio/Inventory/List-All-Warehouse']);
                           })
                         }
                         else{
                           debugger;
                           this.modelService.update(this.modelObject)
                           .subscribe(data => {
                           
                             this.router.navigate(['app/aio/Inventory/List-All-Warehouse']);
                           })
                         }
                       }
        
}
