import { Component, OnInit } from '@angular/core';
import { MembershipModel } from '../../models/membership.model';
import { ActivatedRoute } from '@angular/router';
import { MembershipService } from '../../membership.service';
@Component({
    selector: 'view-membership',
    templateUrl: 'view-membership.component.html',
    styleUrls: ['view-membership.component.scss']
})
export class ViewMembershipComponent implements OnInit{
    membership:MembershipModel = new MembershipModel()
    Id = 0;

constructor(  private MembershipService:MembershipService,
    private route: ActivatedRoute){
  this.Id = +route.snapshot.paramMap.get("Id");
        
    }
    ngOnInit(): void {
        this.MembershipService.getById(this.Id)
        .subscribe(result=>{
          this.membership = result;
        })


    }
}
