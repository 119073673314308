import { PaymentPlanComponent } from './payment-plan/components/payment-plan/payment-plan.component';
import { TrialBalanceBalancesComponent } from './accounting-reports/trial-balance-balances/components/trial-balance-balances/trial-balance-balances.component';
import { AssetsReportComponent } from './accounting-reports/assets-report/components/assets-report/assets-report.component';
import { UploadJournalFileComponent } from './dailyrestrictions/upload-journal-file/upload-journal-file.component';
import { ComponentsCarouselModule } from './../../../../components/carousel/components.carousel.module';
import { SharedModule } from './../../../../shared/shared.module';
import { PagesContainersModule } from './../../../../containers/pages/pages.containers.module';
import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireAuthGuardModule } from "@angular/fire/auth-guard";
import { TabsModule } from "ngx-bootstrap/tabs";
import { HeadroomModule } from "@ctrl/ngx-headroom";
import { ScrollToModule } from "@nicky-lenaers/ngx-scroll-to";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { TranslateModule } from "@ngx-translate/core";
import { ArchwizardModule } from "angular-archwizard";
import { FormsModule, FormsModule as FormsModuleAngular, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from "ngx-bootstrap/modal";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { PopoverModule } from "ngx-bootstrap/popover";
import { AccountingRoutingModule } from './accounting.routing'
import { LayoutContainersModule } from 'src/app/containers/layout/layout.containers.module';
import { MatTabsModule } from '@angular/material/tabs';
import { HttpClientModule } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { NgSelectModule } from '@ng-select/ng-select';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { SortablejsModule } from 'ngx-sortablejs';
import { PaginationConfig, PaginationModule } from 'ngx-bootstrap/pagination';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { PaidChecksComponent } from './checks/paid-checks/components/paid-checks/paid-checks.component';
import { ViewPaidChecksComponent } from './checks/paid-checks/components/view-paid-checks/view-paid-checks.component';
import { CreatePaidChecksComponent } from './checks/paid-checks/components/create-paid-checks/create-paid-checks.component';
import { UploadPaidChecksFileComponent } from './checks/paid-checks/components/upload-paid-checks-file/upload-paid-checks-file.component';
import { CheckBooksComponent } from './checks/check-book/components/check-books/check-books.component';
import { ChecksReceivedComponent } from './checks/checks-received/components/checks-received/checks-received.component';
import { ViewChecksRecievedComponent } from './checks/checks-received/components/view-checks-recieved/view-checks-recieved.component';
import { CreateChecksReceivedComponent } from './checks/checks-received/components/create-checks-received/create-checks-received.component';
import { UploadChecksRecievedFileComponent } from './checks/checks-received/components/upload-checks-recieved-file/upload-checks-recieved-file.component';
import { UploadExpensesFileComponent } from './Expenses/components/upload-expenses-file/upload-expenses-file.component';
import { TreasuryAndBankAccountsComponent } from './treasury-and-bank-accounts/components/treasury-and-bank-accounts/treasury-and-bank-accounts.component';
import { BalanceSheetComponent } from './accounting-reports/balance-sheet/components/balance-sheet/balance-sheet.component';
import { CreatGovernorateComponent } from './creat-governorate/creat-governorate.component';
import { LockerDataComponent } from './locker-data/locker-data.component';
import { AddPaymentModeComponent } from './add-payment-mode/add-payment-mode.component';
import { JournalEntriesTypeComponent } from './journal-entries-type/journal-entries-type.component';
import { DailyrestrictionsComponent } from './dailyrestrictions/dailyrestrictions.component';
import { AccountingManualComponent } from './account_manual_section/accounting-manual/accounting-manual.component';
import { AcountingBaseDataComponent } from './acounting-base-data/acounting-base-data.component';
import { AccountPowerComponent } from './account_manual_section/account-power/account-power.component';
import { ProjectInfoComponent } from './project-info/project-info.component';
import { ExpensesComponent } from './Expenses/components/expenses/expenses.component';
import { CreateExpensesComponent } from './Expenses/components/create-expenses/create-expenses.component';
import { ExpenseDefinitionComponent } from './expense-definition/expense-definition.component';
import { ExpensessTypeComponent } from './expensess-type/expensess-type.component';
import { AccountFicationsComponent} from './account-fications/account-fications.component';
import { AccountNaturesComponent } from './account-natures/account-natures.component';
import { AccountMenuComponent } from './account-menu/account-menu.component';
import { AccountFicationCostTypeComponent } from './account-fication-cost-type/account-fication-cost-type.component';
import { CheckTypesComponent } from './check-types/check-types.component';
import { CheckStatusesComponent } from './check-statuses/check-statuses.component';
import { GenerateAccountTypeComponent } from './generate-account-type/generate-account-type.component';
import { AccountTypeComponent } from './account-type/account-type.component';
import { BankCodeComponent } from './bank-code/bank-code.component';
import { CreatCountryComponent } from './creat-country/creat-country.component';
import { CityCodeComponent } from './city-code/city-code.component';
import { AccountingDashboardComponent } from './accounting-dashboard/accounting-dashboard.component';
import { AccountBalanceComponent } from './account-balance/account-balance.component';
import { CostCenterComponent } from './cost-center/cost-center.component';
import { AccountNameComponent } from './account-name/account-name.component';
import { AccountNameActionsComponent } from './account-name/account-name-actions/account-name-actions.component';
import { GeneralAccountComponent } from './general-account/general-account.component';
import { AccountsComponent } from './accounts/accounts.component';
import { ExpenseCodesComponent } from './expense-codes/expense-codes.component';
import { RevenueCodesComponent } from './revenue-codes/revenue-codes.component';
import { AssignCostCentersComponent } from './assign-cost-centers/assign-cost-centers.component';
import { SelectCostCenterComponent } from './select-cost-center/select-cost-center.component';
import { AllDailyrestrictionsComponent } from './all-dailyrestrictions/all-dailyrestrictions.component';
import { ViewDailyRestrictionComponent } from './view-daily-restriction/view-daily-restriction.component';
import { CatchReceiptCategoryComponent } from './catch-receipt-category/catch-receipt-category.component';
import { CatchReceiptComponent } from './catch-receipt/catch-receipt.component';
import { CreateCatchReceiptComponent } from './catch-receipt/create-catch-receipt/create-catch-receipt.component';
import { UploadImageComponent } from './catch-receipt/upload-image/upload-image.component';
import { AllAssetsComponent } from './assets/components/all-assets/all-assets.component';
import { UploadAssetsFileComponent } from './assets/components/upload-assets-file/upload-assets-file.component';
import { IconsComponent } from './icons/icons.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FileUploadModule } from 'ng2-file-upload';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { RatingModule } from 'ngx-bootstrap/rating';
import { ComponentsPlayerModule } from 'src/app/components/player/components.player.module';
import { LightboxModule } from 'ngx-lightbox';
import { RouterModule } from '@angular/router';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { BootstrapModule } from 'src/app/components/bootstrap/bootstrap.module';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { IncomeListComponent } from './accounting-reports/income-list/components/income-list/income-list.component';
import { DailyRestrictionsReportComponent } from './accounting-reports/daily-restrictions-report/components/daily-restrictions-report/daily-restrictions-report.component'
import { CostCenterReportComponent } from './accounting-reports/cost-center-report/components/cost-center-report/cost-center-report.component';
import {LedgerAccountComponent  } from './accounting-reports/ledger-account/components/ledger-account/ledger-account.component';
import {TaxReportComponent  } from './accounting-reports/tax-report/components/tax-report/tax-report.component';
import {CdkTableModule} from '@angular/cdk/table';
import {CdkTreeModule} from '@angular/cdk/tree';
import { MatTableModule } from '@angular/material/table';
import { TaxReturnReportComponent } from './accounting-reports/tax-return-report/components/tax-return-report/tax-return-report.component';
import { CashFlowsComponent } from './accounting-reports/cash-flows/cash-flows.component';
import { AllBudgetComponent } from './budget/components/all-budget/all-budget.component';
import { CreateBudgetComponent } from './budget/components/create-budget/create-budget.component';
import { AllMonthlyDistributionComponent } from './budget/monthly-distribution/components/all-monthly-distribution/all-monthly-distribution.component';
import { CreateMonthlyDistributionComponent } from './budget/monthly-distribution/components/create-monthly-distribution/create-monthly-distribution.component';
import { BudgetReportComponent } from './budget/budget-report/components/budget-report/budget-report.component';
import { ViewAssetComponent } from './assets/components/view-asset/view-asset.component';
import { FiscalYearComponent } from './fiscal-years/components/fiscal-year/fiscal-year.component';

@NgModule({
  declarations: [ 
    IncomeListComponent,
    ViewAssetComponent,
    AllBudgetComponent,
    BudgetReportComponent,
    AllMonthlyDistributionComponent,
    CreateMonthlyDistributionComponent,
    CreateBudgetComponent,
    AccountingDashboardComponent,
    AccountingManualComponent,
    AcountingBaseDataComponent,
    AccountPowerComponent,
    ExpenseDefinitionComponent,
    ProjectInfoComponent,
    ExpensessTypeComponent,
    AccountFicationsComponent,
    AccountNaturesComponent,
    AccountMenuComponent,
    CheckTypesComponent,
    AccountFicationCostTypeComponent,
    CheckStatusesComponent,
    GenerateAccountTypeComponent,
    AccountTypeComponent,
    BankCodeComponent,
    CreatCountryComponent,
    CityCodeComponent,
    // AccountingDashboardComponent,
    AccountBalanceComponent,
    CostCenterComponent,
    CatchReceiptCategoryComponent,
    CatchReceiptComponent,
    CreateCatchReceiptComponent,
    CreatGovernorateComponent,
    DailyrestrictionsComponent,
    JournalEntriesTypeComponent,
    AddPaymentModeComponent,
    AccountNameComponent,
    AccountNameActionsComponent,
    LockerDataComponent,
    GeneralAccountComponent,
    AccountsComponent,
    BalanceSheetComponent,
    ExpenseCodesComponent,
    RevenueCodesComponent,
    AllAssetsComponent,
    IconsComponent,
    AllDailyrestrictionsComponent,
    AssignCostCentersComponent,
    SelectCostCenterComponent,
    ViewDailyRestrictionComponent,
    UploadImageComponent,
    TreasuryAndBankAccountsComponent,
    ExpensesComponent,
    CreateExpensesComponent,
    UploadExpensesFileComponent,
    ChecksReceivedComponent,
    CreateChecksReceivedComponent,
    UploadChecksRecievedFileComponent,
    ViewChecksRecievedComponent,
    CheckBooksComponent,
    UploadPaidChecksFileComponent,
    CreatePaidChecksComponent,
    ViewPaidChecksComponent,
    PaidChecksComponent,
    DailyRestrictionsReportComponent,
    CostCenterReportComponent,
    UploadJournalFileComponent,
    UploadAssetsFileComponent,
    AssetsReportComponent,
    TrialBalanceBalancesComponent,
    PaymentPlanComponent,
    LedgerAccountComponent,
    TaxReportComponent,
    TaxReturnReportComponent,
    CashFlowsComponent,
    FiscalYearComponent
  ],
  imports: [
    CommonModule,
    AccountingRoutingModule,
    LayoutContainersModule,
    CdkTableModule,
    CdkTreeModule,
    MatTableModule,
    MatTabsModule,
    TranslateModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    FormsModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    ArchwizardModule,
    SharedModule,
    NgSelectModule,
    FormsModuleAngular,
    AngularFireAuthModule,
    AngularFireAuthGuardModule,
    ComponentsCarouselModule,
    TabsModule.forRoot(),
    HeadroomModule,
    ScrollToModule.forRoot(),
    NgxDatatableModule,
    CollapseModule,
    PagesContainersModule,
    PopoverModule.forRoot(),
    TooltipModule.forRoot(),
    BsDropdownModule,
    SortablejsModule,
    PaginationModule,
    SimpleNotificationsModule.forRoot(),




    /////////////////////
    LayoutContainersModule,
    MatTooltipModule,
    FileUploadModule,
    AccordionModule,
    MatTreeModule,
    MatIconModule,
    MatButtonModule   ,
MatCheckboxModule ,
MatFormFieldModule,
MatInputModule    ,
MatProgressBarModule,

RatingModule.forRoot(),
ComponentsPlayerModule,
LightboxModule,
RouterModule,
PerfectScrollbarModule,
BootstrapModule,

TimepickerModule.forRoot(),

  ],
  entryComponents:[UploadAssetsFileComponent,UploadJournalFileComponent,AssignCostCentersComponent,UploadImageComponent],
  exports:[UploadAssetsFileComponent,UploadJournalFileComponent,UploadPaidChecksFileComponent,UploadImageComponent,UploadExpensesFileComponent,UploadChecksRecievedFileComponent],
  bootstrap: [AccountingDashboardComponent],
  providers: [DatePipe,PaginationConfig],
})
export class AccountingModule {}

