
export class DailyrestrictionsModel{
    Id?:number;
    Date?:Date;
    Description?:String;
    AccountNameId?:number;
    BankCodeId?:number;
    CurrencyId?:number;
    Totaldebit?:number;
    Totalcredit?:number;
    Totalbalance?:number;
    
    dailyrestrictionsFooterList?:DailyrestrictionsFooterModel[];
    IsPosted?:boolean;
    
    constructor(obj?:DailyrestrictionsModel){
        obj = obj? obj:{};
        this.Id = obj.Id? obj.Id:0;
        this.Date = obj.Date? obj.Date:new Date();
        this.Description = obj.Description? obj.Description:'';
        this.AccountNameId = obj.AccountNameId? obj.AccountNameId:0;
        this.BankCodeId = obj.BankCodeId? obj.BankCodeId:0;
        this.CurrencyId = obj.CurrencyId? obj.CurrencyId:0;
        this.Totaldebit = obj.Totaldebit? obj.Totaldebit:0;
        this.Totalcredit = obj.Totalcredit? obj.Totalcredit:0;
        this.Totalbalance = obj.Totalbalance? obj.Totalbalance:0;
       
        this.dailyrestrictionsFooterList = obj.dailyrestrictionsFooterList? obj.dailyrestrictionsFooterList:[];
        this.IsPosted = obj.IsPosted? obj.IsPosted:false;

    }
}

export class DailyrestrictionsFooterModel{
    Id?:number;
    AccountNameId?:number;
    // Subledger1?:number;
    // Subledger2?:number;
    // Subledger3?:number;
    // Subledger4?:number;
    // Subledger5?:number;
    Debit?:number;
    Credit?:number;
    constructor(obj?:DailyrestrictionsFooterModel){
        obj = obj? obj:{};
        this.Id = obj.Id? obj.Id:0;
        this.AccountNameId = obj.AccountNameId? obj.AccountNameId:0;
        // this.Subledger1 = obj.Subledger1? obj.Subledger1:0;
        // this.Subledger2 = obj.Subledger2? obj.Subledger2:0;
        // this.Subledger3 = obj.Subledger3? obj.Subledger3:0;
        // this.Subledger4 = obj.Subledger4? obj.Subledger4:0;
        // this.Subledger5 = obj.Subledger5? obj.Subledger5:0;
        this.Debit = obj.Debit? obj.Debit:0;
        this.Credit = obj.Credit? obj.Credit:0;
    }
}

export class SelectedDailyrestrictionsFooterModel{
    Id?:number;
    NameAr?:string;
    NameEn?:string;
    AccountNameId?:number;

    // Subledger1?:number;
    // Subledger2?:number;
    // Subledger3?:number;
    // Subledger4?:number;
    // Subledger5?:number;
    Debit?:number;
    Credit?:number;

    constructor(obj?:SelectedDailyrestrictionsFooterModel){
        obj = obj? obj:{};
        this.Id = obj.Id? obj.Id:0;
        this.AccountNameId = obj.AccountNameId? obj.AccountNameId:0;

        // this.Subledger1 = obj.Subledger1? obj.Subledger1:0;
        // this.Subledger2 = obj.Subledger2? obj.Subledger2:0;
        // this.Subledger3 = obj.Subledger3? obj.Subledger3:0;
        // this.Subledger4 = obj.Subledger4? obj.Subledger4:0;
        // this.Subledger5 = obj.Subledger5? obj.Subledger5:0;
        this.NameAr = obj.NameAr? obj.NameAr:'';
        this.NameEn = obj.NameEn? obj.NameEn:'';
        this.Debit = obj.Debit? obj.Debit:0;
        this.Credit = obj.Credit? obj.Credit:0;
    }
}