

<div class="row">
    <div class="col-12">
      <div class="card mb-4">
        <div class="card-body">
          <!-- <h5 class="mb-4">{{ 'From Date' | translate }}</h5> -->
          <div class="row">
            <div class="col-12 col-lg-6 mb-4">
              <label><span>{{ 'Country' | translate }}</span></label>
         
              <ng-select placeholder='{{"Country" | translate}}' *ngIf="lang==='ar-EG'" [items]="countries" bindLabel="NameAr" bindValue="Id" [(ngModel)]="search.CountryId">
              </ng-select>
              <ng-select placeholder='{{"Country" | translate}}' *ngIf="lang!='ar-EG'" [items]="countries" bindLabel="NameEn" bindValue="Id" [(ngModel)]="search.CountryId">
              </ng-select>
            </div>
            <div class="col-12 col-lg-6 mb-4">
                <label><span>{{ 'Type' | translate }}</span></label>
           
                <ng-select placeholder='{{"Type" | translate}}'  [items]="supplierTypes" bindLabel="Name" bindValue="Id" [(ngModel)]="search.SupplierType">
                </ng-select>
              
              </div>
         
            <div class="col-12 col-lg-6 mb-4 "  >
              <button type="button" (click)="Search()" class="btn btn-primary default mb-1" style="margin: 1px;">{{'Search' | translate}}</button>{{" "}}
              <button type="button" (click)="print()" class="btn btn-secondary default mb-1 " style="margin: 1px;">{{'Print' | translate}}</button>{{" "}}
              <button type="button" (click)="exportTable()" class="btn btn-success default mb-1" style="margin: 1px;">{{'PDF' | translate}}</button>{{" "}}
            </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  <div id="contentToConvert">
    <div class="row" >
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-body">
              <h3 class="float-center" style="text-align: center;">{{ 'Suppliers Directory' | translate }}</h3>
              <h4 class="float-center" style="text-align: center;">{{  today| date:'dd/MM/yyyy' }}</h4>
              <h6 class="float-center" style="text-align: center;">{{ 'Future Core' | translate }}</h6>
             
          </div>
        </div>
      </div>
    </div>
  
  
  <div class="card mb-12 tree-explorer-side col-md-12" *ngIf="data">
      <div class="card-body ">
      <div class="row">
          <table class="table table-striped">
              <thead>
                <tr>
                    <th>{{ 'Code' | translate }} </th>
                    <th>{{ 'Name' | translate }} </th>
                    <th>{{ 'Address' | translate }}</th>
                    <th>{{ 'Phone' | translate }}</th>
                    <th>{{ 'Postal Code' | translate }} </th>
                </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let item of data">
                      <td>{{item.Code}}</td>
                      <td>{{lang==='ar-EG' ? item.NameAr : item.NameEn}}</td>
                      <td>{{item.Address}}</td>
                      <td>{{item.Phone}}</td>
                      <td>{{item.PostalCode}}</td>
                      
                  </tr>
              </tbody>
          </table>
      
      </div>
  
      </div>
    </div>
  </div>