import { ExpensesAccountsModel } from "./expenses-accounts.model";
import { ExpensesCostCenterModel } from "./expenses-cost-centers.model";
import { ExpensesFilesModel } from "./expenses-files.model";

export class ExpensesModel{
  Id             ?: number;
  Amount         ?: number;
  CurrencyId     ?: number;
  Code           ?: number;
  AccountId ?:number;
  Treasury  ?:number;
  Date           ?: Date;
  TheRecipient   ?: number;
  CategoryId     ?: number;
  TaxId          ?: number;
  TaxValue       ?: number;
  IsMulti        ?: boolean;
  WithCostCenters?: boolean;
  IsFrequent     ?: boolean;
  FrequentType   ?: string;
  ExpiryDate     ?: Date;
  Files?: ExpensesFilesModel[];
  Description    ?: string;
  Accounts       ?: ExpensesAccountsModel[];
  CostCenters    ?: ExpensesCostCenterModel[];
  TreasuryType ?: number; //1 for Treasury and 2 for banck account
  PurchaseInvoiceId?:number;
  constructor(item ?: ExpensesModel){
    item = item ? item : {};
    this.Id              = item.Id              ? item.Id              : 0;
    this.Amount          = item.Amount          ? item.Amount          : 0;
    this.PurchaseInvoiceId = item.PurchaseInvoiceId ? item.PurchaseInvoiceId : 0;
    this.CurrencyId      = item.CurrencyId      ? item.CurrencyId      : 0;
    this.Code            = item.Code            ? item.Code            : 0;
    this.AccountId       = item.AccountId       ? item.AccountId       : 0;
    this.Treasury        = item.Treasury        ? item.Treasury        : 0;
    this.Date            = item.Date            ? item.Date            : new Date();
    this.TheRecipient    = item.TheRecipient    ? item.TheRecipient    : 0;
    this.CategoryId      = item.CategoryId      ? item.CategoryId      : 0;
    this.TaxId           = item.TaxId           ? item.TaxId           : 0;
    this.TaxValue        = item.TaxValue        ? item.TaxValue        : 0;
    this.TreasuryType        = item.TreasuryType        ? item.TreasuryType        : 0;
    this.IsMulti         = item.IsMulti         ? item.IsMulti         : false;
    this.WithCostCenters = item.WithCostCenters ? item.WithCostCenters : false;
    this.IsFrequent      = item.IsFrequent      ? item.IsFrequent      : false;
    this.FrequentType    = item.FrequentType    ? item.FrequentType    : '';
    this.ExpiryDate      = item.ExpiryDate      ? item.ExpiryDate      : new Date();
    this.Files         = item.Files         ? item.Files         : [];
    this.Description     = item.Description     ? item.Description     : '';
    this.Accounts        = item.Accounts        ? item.Accounts        : [];
    this.CostCenters     = item.CostCenters     ? item.CostCenters     : [];
  }
}