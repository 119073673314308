import { Component, OnInit } from '@angular/core';
import { MembershipModel } from '../../models/membership.model';
import { Lightbox } from 'ngx-lightbox';
import { ActivatedRoute } from '@angular/router';
import { MembershipService } from '../../membership.service';
@Component({
    selector: 'membership-profile',
    templateUrl: 'membership-profile.component.html',
    styleUrls: ['membership-profile.component.scss']
})
export class MembershipProfileComponent implements OnInit{
    membership:MembershipModel = new MembershipModel()
    Id = 0;

constructor(private lightbox:Lightbox,
  private MembershipService:MembershipService,
  private route: ActivatedRoute
){
  this.Id = +route.snapshot.paramMap.get("Id");

}
    ngOnInit(): void {
      this.MembershipService.getById(this.Id)
      .subscribe(result=>{
        this.membership = result;
      })
        // this.membership.AcademicYear='',
        // this.membership.ActivitiesCount=10,
        // this.membership.Address="cairo",
        // this.membership.ArabicName="محمد سيد",
        // this.membership.BirthDate=new Date("01/01/1996");
        // this.membership.CurrentPosition="Software Engineer";
        // this.membership.Email = "info@futurecore.net";
        // this.membership.EnglishName="Mohamed Sayed";
        // this.membership.Employer = "FutureCore";
        // this.membership.Gender="Male";
        // this.membership.Id = 1;
        // this.membership.NationalID="29601012418518";
        // this.membership.Phone = "01098680787";
        // this.membership.VolunteerNumber="NSR 0462 002346";
        // this.membership.GraduationYear="2019";
        // this.membership.EducationalQualification="Bachelor of Computer Science";

    }
    
  openLightbox(src: string): void {
    this.lightbox.open([{ src, thumb: '' }], 0, { centerVertically: true, positionFromTop: 0, disableScrolling: true, wrapAround: true });
  }
}
