import { CatchReceiptCategoryService } from './services/catch-receipt-category.service';
import { Component, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { TableEngine } from '../../shared/TableEngine';
import { CatchReceiptCategoryModel } from './models/catch-receipt-category.model';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { SidePopupComponent } from '../../shared/side-popup/side-popup.component';

@Component({
    selector: 'catch-receipt-category',
    templateUrl: 'catch-receipt-category.component.html',
    styleUrls: ['catch-receipt-category.component.scss']
})
export class CatchReceiptCategoryComponent {

    constructor(private formBuilder: FormBuilder, public modelService: CatchReceiptCategoryService, private renderer: Renderer2,
        private notifications: NotificationsService, private translate: TranslateService
    
      ) {
      }
    
      tableEngine = new TableEngine(this.translate, this.notifications);
      currentScreen: any = 'Table';
    
      translationName: string = 'Catch Receipt Categories'
    
      sortableKeys = [];
      searchableKeys = ['NameEn', 'NameAr'];
    
      modelObject: CatchReceiptCategoryModel = new CatchReceiptCategoryModel();
    
      ogData: CatchReceiptCategoryModel[] = [];
      mainForm: FormGroup = new FormGroup({
        Id: new FormControl(),
        NameEn: new FormControl(),
        NameAr: new FormControl(),
      });
    
    
      declare lang: any;
      declare itemOrder: any;
      currentAction = '';
      savedPage = 0;
      currentPage = 1;
      deletionList: any[] = [];
    
      @ViewChild('sideModalRef', { static: true }) sideModalRef: SidePopupComponent = null;
      @ViewChild('delModal', { static: true }) delModal: ModalDirective;
      @ViewChild('smallModal', { static: true }) smallModal: ModalDirective;
      ngOnInit(): void {
        this.lang = localStorage.getItem('theme_lang');
        this.itemOrder = { label: 'Name', property: this.lang === 'ar-EG' ? 'NameAr' : 'NameEn' };
        this.sortableKeys = [
          { label: 'Name', property: this.lang === 'ar-EG' ? 'NameAr' : 'NameEn' },
        ];
    
        this.tableEngine.loadTableEngine(this.ogData, this.modelService, this.modelObject,
          this.searchableKeys, this.sortableKeys, this.mainForm, this.translationName, this.itemOrder,
          this.sideModalRef, this.delModal, this.smallModal, this.lang
        )
        this.loadServices();
      }
    
      showModal(Id, action) {
        if (action === 'Add') {
          this.tableEngine.showAddNewModalRef(this.createForm, this.formBuilder)
        }
        else if (action === 'Edit') {
    
          this.tableEngine.showEditModalRef(Id, this.createForm, this.formBuilder)
          console.log("this model object:" + this.modelObject);
          console.log(this.modelObject);
          console.log("table engine model object:" + this.tableEngine.modelObject);
          console.log(this.tableEngine.modelObject);
    
        }
        else if (action === 'Info') {
          this.tableEngine.showInfoModalRef(Id, this.createForm, this.formBuilder)
        }
        this.modelObject = this.tableEngine.modelObject;
        this.mainForm = this.tableEngine.mainForm;
      }
    
      createForm(modelObject, formBuilder): FormGroup {
        //console.log("from craeteform");
    
        //console.log("modelObject",modelObject.NameEn);
    
        return formBuilder.group({
          Id: [modelObject.Id >= 0 ? modelObject.Id : 0],
          NameEn: [modelObject.NameEn, Validators.compose([Validators.required, Validators.minLength(2)])],
          NameAr: [modelObject.NameAr, Validators.compose([Validators.required, Validators.pattern(/^[\u0621-\u064A]+([\s]*[\u0621-\u064A]+)*$/)])],
          // PostalCode: [modelObject.PostalCode, Validators.compose([Validators.required, Validators.pattern(/^/d{5}$/)])],
        });
      }
    
      addObject() {
        this.currentAction = 'loading';
    
        this.modelObject = new CatchReceiptCategoryModel();
        this.modelObject.Id = 0;
        this.modelObject.NameEn = this.mainForm.getRawValue().NameEn;
        this.modelObject.NameAr = this.mainForm.getRawValue().NameAr;
        // this.modelObject.PostalCode = this.mainForm.getRawValue().PostalCode;
    
        this.executeAddObject();
      }
    
      executeAddObject() {
        console.log(this.modelObject);
    
        this.modelService.insert(this.modelObject).subscribe(result => {
          if (result === true) {
            this.tableEngine.loadData();
            this.currentAction = '';
            this.tableEngine.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyAdded"));
            if (this.sideModalRef === undefined) {
    
              this.tableEngine.smallModal.hide();
    
            } else {
    
              this.tableEngine.sideModalRef.modalRef.hide();
            }
          }
          else {
            this.tableEngine.toastError(this.translate.instant("Error"), this.translate.instant("FailedAdd"));
            this.currentAction = '';
          }
        })
      }
    
      editObject() {
        this.currentAction = 'loading';
        this.tableEngine.savedPage = this.tableEngine.currentPage;
    
        let tempObj = new CatchReceiptCategoryModel({
          Id: this.modelObject.Id,
          NameEn: this.mainForm.getRawValue().NameEn,
          NameAr: this.mainForm.getRawValue().NameAr,
          // PostalCode : this.mainForm.getRawValue().PostalCode
        });
    
        if (JSON.stringify(tempObj) != JSON.stringify(this.modelObject)) {
          this.executeEditObject(tempObj);
        }
        else {if (this.sideModalRef === undefined) {
    
          this.tableEngine.smallModal.hide();
    
        } else {
    
          this.tableEngine.sideModalRef.modalRef.hide();
        }
        }
      }
    
      executeEditObject(Object) {
        console.log("ObjectObject", Object);
    
        this.modelService.update(Object).subscribe(result => {
          if (result === true) {
            this.tableEngine.loadData();
            this.currentAction = '';
            this.tableEngine.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyEdited"));
            if (this.sideModalRef === undefined) {
    
              this.tableEngine.smallModal.hide();
    
            } else {
    
              this.tableEngine.sideModalRef.modalRef.hide();
            }
          }
          else {
            this.tableEngine.toastError(this.translate.instant("Error"), this.translate.instant("FailedEdit"));
            this.currentAction = '';
          }
        })
      }
    
      //Deletion methods:
      //Customize if needed.
      //Pass the target object as parameter if the target API can only delete singular objects and not lists.
      deleteObject() {
        this.savedPage = this.currentPage;
        //prevent multiclicks. re enable after API response
        this.currentAction = 'loading';
        this.executeDeleteObject();
      }
    
      //Same here...
      executeDeleteObject() {
        this.modelService.deleteList(this.tableEngine.deletionList).subscribe(result => {
          if (result === true) {
            this.tableEngine.loadData();
            this.currentAction = '';
            this.tableEngine.finalizeDeletion();
          }
          else {
            this.tableEngine.toastError(this.translate.instant("Error"), this.translate.instant("FailedDelete"));
            this.currentAction = '';
          }
        })
      }
    
    
      prepChangeSort(sortKey) {
        this.tableEngine.itemOrder = this.itemOrder;
        this.tableEngine.changeSort(this.tableEngine.getSort(sortKey))
      }
      ////////////////////////////////////////////////////////////////////////////
      //
      //
      //
      //
      //
      ////////////////////////////////////////////////////////////////////////////
      //  EXCLUSIVE :
      //  Specifically for this component.
      ///////////////////////////////
      //Get any additional data here
      loadServices() {
        this.currentAction = 'loading';
        this.tableEngine.loadData();
      }
    
      ////////////////////////////////////////////////////////////////////////////
      //
      //
      //
      //
      //
      ////////////////////////////////////////////////////////////////////////////
      //  CONSTANT :
      //  Do not change.
      ///////////////////////////////
      prepSort(sortKey) {
        this.tableEngine.changeSort(this.tableEngine.getSort('Name'));
        this.itemOrder = this.tableEngine.itemOrder;
      }
    }
    
