import { Component } from '@angular/core';
@Component({
    // moduleId: module.id,
    selector: 'accounting-manual',
    templateUrl: 'accounting-manual.component.html',
     styleUrls: ['accounting-manual.component.scss']
})
export class AccountingManualComponent {

}
