import { Component, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { NotificationType, NotificationsService } from "angular2-notifications";
import { ModalDirective } from "ngx-bootstrap/modal";
import { TableEngine } from "src/app/invoices/TableEngine";
import { SidePopupComponent } from "src/app/views/invoice/side-popup/side-popup.component";
import { NotesAndAttachmentModel } from "../NotesAndAttachmentModel";
import { NotesAndAttachmentService } from "../notes-and-attachment.service";
import { ActionsModel } from "../ActionsModel";
import { ActionStatusModel } from "../ActionStatusModel";
import { ActionService } from "../action.service";
import { ActionStatusService } from "../action-status.service";
import { PageChangedEvent } from "ngx-bootstrap/pagination";
import { of } from "rxjs";
import { DataFilterer } from "src/app/views/app/aio/shared/DataFilterer";
import { DataSorter } from "src/app/views/app/aio/shared/DataSorter";
import { DataPaginator } from "src/app/views/invoice/shared/DataPaginator";
import { SlicePipe } from "@angular/common";

@Component({
  // moduleId: module.id,
  selector: "notes-and-attachment",
  templateUrl: "notes-and-attachment.component.html",
  styleUrls: ["notes-and-attachment.component.scss"],
})
export class NotesAndAttachmentComponent {
  Id = 0;
  itemOrder: { label: string; property: string };

  constructor(
    public router: Router,
    private fb: FormBuilder,
    private formBuilder: FormBuilder,
    private modelService: NotesAndAttachmentService,
    private ActionsService: ActionService,
    private ActionStatusService: ActionStatusService,

    private notifications: NotificationsService,
    private translate: TranslateService,
    private route: ActivatedRoute
  ) {
    this.Id = +route.snapshot.paramMap.get("Id");
  }

  tableEngine = new TableEngine(this.translate, this.notifications);
  currentScreen: any = "Table";
  translationName: string = "Item";
  sortableKeys = [];
  searchableKeys = ["Name", "Address"];
  tableView: any = true;

  // StoreItemsModel:StoreItemsModel[]=[];
  actionStatusObject: ActionStatusModel = new ActionStatusModel();
  actionStatusOgData: ActionStatusModel[] = [];
  ActionStatusForm: FormGroup = new FormGroup({
    Id: new FormControl(),
    Name: new FormControl(),
  });

  ActionsObject: ActionsModel = new ActionsModel();
  ActionsOgData: ActionsModel[] = [{Id:0,Name:''}];
  ActionForm: FormGroup = new FormGroup({
    Id: new FormControl(),
    Name: new FormControl(),
  });

  modelObject: NotesAndAttachmentModel = new NotesAndAttachmentModel();
  // ItemsModel:ItemsModel[]=[]
  Form: FormGroup;
  declare lang: any;
  //  declare itemOrder: any;
  currentAction = "";
  savedPage = 0;
  currentPage = 1;
  deletionList: any[] = [];
  prevAction = "";
  addToListId = 0;
  ItemsBalanceHeaderId = 0;
  ItemBalanceId = 0;
  currentFormMode: string = "";

  ogData: NotesAndAttachmentModel[] = [];
  mainForm: FormGroup = new FormGroup({
    Id: new FormControl(),
    Date: new FormControl(),
    Time: new FormControl(),
    ActionId: new FormControl(),
    ActionStatusId: new FormControl(),
    Notes: new FormControl(),
    ActionStatusName: new FormControl(),
    ActionName: new FormControl(),
  });

  @ViewChild("sideModalRef", { static: true })
  sideModalRef: SidePopupComponent = null;
  @ViewChild("delModal", { static: true }) delModal: ModalDirective;
  @ViewChild("smallModal", { static: true }) smallModal: ModalDirective = null;
  @ViewChild("smallModalActions", { static: true }) smallModalActions: ModalDirective = null;
  formPresentationModal: any;
  ngOnInit(): void {
    // this.getAllCountryCode();

    this.lang = localStorage.getItem("theme_lang");
    this.itemOrder = {
      label: "Name",
      property: this.lang === "ar-EG" ? "Name" : "Name",
    };
    this.sortableKeys = [
      // { label: 'Date', property: 'Date' },
      { label: "Name", property: this.lang === "ar-EG" ? "Name" : "Name" },
    ];
    this.loadActionsStatuseData();
    this.loadActionsData();
    this.tableEngine.loadTableEngine(
      this.ogData,
      this.modelService,
      this.modelObject,
      this.searchableKeys,
      this.sortableKeys,
      this.mainForm,
      this.translationName,
      this.itemOrder,
      this.sideModalRef,
      this.delModal,
      this.smallModal,
      this.lang
    );

    this.ActionStatusService.getAll().subscribe((data) => {
      if(data.length>0){
        this.ActionStatusList = data;
      }
    });
    this.ActionsService.getAll().subscribe((data) => {
      if(data.length>0){
        this.ActionList = data;
      }
    });
    this.loadServices();
  }

  createActionsForm(): FormGroup {
    return this.formBuilder.group({
      Id: [this.ActionsObject.Id >= 0 ? this.ActionsObject.Id : 0],
      Name: [
        this.ActionsObject.Name,
        Validators.compose([Validators.required, Validators.minLength(2)]),
      ],
    });
  }

  createActionStatusForm(): FormGroup {
    return this.formBuilder.group({
      Id: [this.actionStatusObject.Id >= 0 ? this.actionStatusObject.Id : 0],
      Name: [
        this.actionStatusObject.Name,
        Validators.compose([Validators.required]),
      ],
    });
  }

  createForm(modelObject): FormGroup {
    debugger;
    return this.fb.group({
      Id: [modelObject.Id >= 0 ? modelObject.Id : 0],
      Date: [modelObject.Date],
      Time: [modelObject.Time],
      ActionId: [modelObject.ActionId],
      ActionStatusId: [modelObject.ActionStatusId],
      Notes: [modelObject.Notes],
      ActionName: [modelObject.ActionName],
      ActionStatusName: [modelObject.ActionStatusName],

    });
  }

  loadServices() {
    this.currentAction = "loading";
    this.tableEngine.loadData();
  }
  loadActionsStatuseData() {
    debugger;
    this.currentAction = "loading";
    this.ActionStatusService.getAll().subscribe((data) => {
      this.actionStatusOgData = data;
      this.currentAction = "";
    });
  }
  
  loadSearch() {
    const prevSearch = this.SearchTerm;
    this.SearchTerm = "";
    setTimeout(() => {
      this.SearchTerm = prevSearch;
    }, 1);
  }
  ActionList: ActionsModel[] = [{Id:0,Name:''}];
  ActionStatusList: ActionStatusModel[] = [{Id:0,Name:''}];

  type = 0;
  visable = "";
  visable2 = "";
  visable3 = "";

  goBack(Id) {
    debugger;
    this.router.navigate(["app/HR/Info-Employee/",  this.modelObject.EmployeeId]);
  }
  Save() {
    debugger;
    this.currentAction = "loading";
    this.tableEngine.savedPage = this.tableEngine.currentPage;
    var SavedObj = new NotesAndAttachmentModel();
    SavedObj.Date = new Date(this.mainForm.getRawValue().Date);
    SavedObj.Time = new Date(this.mainForm.getRawValue().Time);
    SavedObj.Notes = this.mainForm.getRawValue().Notes;
    SavedObj.ActionId = +this.mainForm.getRawValue().ActionId;
    SavedObj.ActionStatusId =+this.mainForm.getRawValue().ActionStatusId;
    SavedObj.Id =this.Id;
    SavedObj.EmployeeId = this.modelObject.EmployeeId;
    if (this.Id == 0) {
      this.modelService.insert(SavedObj).subscribe((data) => {
        this.router.navigate(["app/HR/Info-Employee/", this.modelObject.EmployeeId]);
      });
    } else {
      debugger;
      this.modelService.update(SavedObj).subscribe((data) => {
        this.router.navigate(["app/HR/Info-Employee/", this.modelObject.EmployeeId]);
      });
    }
  }



  //////Add Action Statuse
  SaveActionStatuse() {
    this.ActionStatusService.UpdateList(this.ActionStatusList).subscribe((result) => {
      if (result === true) {
        this.currentAction = "";
        this.smallModal.hide();
      } else {
        this.currentAction = "";
      }
    });
    this.smallModal.hide();
  }

  deleteActionStatuse(Id) {
    debugger;
    this.savedPage = this.currentPage;
    this.currentAction = "loading";
    this.ActionStatusService.delete(Id).subscribe((result) => {
  
      if (result === true) {
        this.loadActionStatusData();
        var i = this.ActionStatusList.findIndex(x=>x.Id == Id);
        if(i>-1){
          this.ActionStatusList.splice(i,1);
        }
        this.currentAction = "";
      }
       else {
    this.currentAction = "loading";
      }
    });
    
  }
  SearchTerm: any = "";
  filteredData = [...this.ogData];
  sortedData = [...this.filteredData];
  paginatedData = [...this.filteredData];
  lastId: number = this.ogData.length + 1;
  SelectAllBox: any = false;
  itemsPerPage = 10;
  itemOptionsPerPage = [5, 10, 20];
  selected = [];
  selectAllState = "";
  orderAsc: boolean = true;
  dataFilterer = new DataFilterer();
  dataSorter = new DataSorter();
  dataPaginator = new DataPaginator();

  loadActionStatusData() {
    debugger;
    this.currentAction = "loading";
    this.ActionStatusService.getAll().subscribe((data) => {
      this.actionStatusOgData = data;
      // this.sortData();
      // this.loadSearch();
      this.currentAction = "";
    });
  }
  AddonClick(obj) {
    debugger;
    this.actionStatusObject = new ActionStatusModel();
    (this.actionStatusObject.Id = 0),
    this.actionStatusObject.Name=obj.Name;
      this.ActionStatusList.push(this.actionStatusObject);
      this.ActionsObject = {};

  }

  showAddNewActionStatuseModalRef(): void {
    debugger;
    this.currentFormMode = "Add";
    this.actionStatusObject = new ActionStatusModel();
    this.ActionStatusForm = this.createActionStatusForm();
    // this.sideModalRef.show();
    this.smallModal.show();
  }


  ////Add Actions
  showAddNewActionModalRef(): void {
    debugger;
    this.currentFormMode = "Add";
    this.ActionsObject = new ActionsModel();
    this.ActionForm = this.createActionsForm();
    this.smallModalActions.show();
  }

  loadActionsData() {
    debugger;
    this.currentAction = "loading";
    this.ActionsService.getAll().subscribe((data) => {
      this.ActionsOgData = data;
      this.currentAction = "";
    });
  }
  SaveActions() {
    this.ActionsService.UpdateList(this.ActionList).subscribe((result) => {
      if (result === true) {
       
        this.smallModalActions.hide();
      } 
      else {
        this.currentAction = "";
      }
    });
this.smallModalActions.hide();
  }

  deleteActions(Id) {
    debugger;
    this.savedPage = this.currentPage;
    this.currentAction = "loading";
    this.ActionsService.delete(Id).subscribe((result) => {
  
      if (result === true) {
        this.loadActionStatusData();
        var i = this.ActionList.findIndex(x=>x.Id == Id);
        if(i>-1){
          this.ActionList.splice(i,1);
        }
        this.currentAction = "";
      }
      else {
    this.currentAction = "loading";
      }
    });
    
    this.currentAction = "loading";
  }
  loadActionData() {
    debugger;
    this.currentAction = "loading";
    this.ActionsService.getAll().subscribe((data) => {
      this.ActionsOgData = data;
      this.currentAction = "";
    });
  }

  AddActionsClick(obj2) {
    debugger;
    this.ActionsObject = new ActionStatusModel();
    (this.ActionsObject.Id = 0),
    this.actionStatusObject.Name=obj2.Name;
      this.ActionList.push(this.ActionsObject);
      this.ActionsObject = {};

  }
}
