import { Component } from '@angular/core';

@Component({
    selector: 'hr-dashboard',
    templateUrl: 'hr-dashboard.component.html',
    styleUrls: ['hr-dashboard.component.scss']
})
export class HrDashboardComponent {

}
