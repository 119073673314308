import { CheckBookService } from './../../check-book.service';
import { Component, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { CheckBookModel } from '../../models/check-book.model';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { TreasuryAndBankAccountsService } from '../../../../treasury-and-bank-accounts/treasury-and-bank-accounts.service';
import { CurrencyService } from 'src/app/invoices/currencies/CurrencyService';
import { TreasuryAndBankAccountsModel } from '../../../../treasury-and-bank-accounts/model/treasury-and-bank-accounts-model';
import { CurrencyModel } from 'src/app/invoices/currencies/CurrencyModel';
import { ListPageHeaderComponent } from 'src/app/containers/pages/list-page-header/list-page-header.component';
import { TableEngine } from 'src/app/views/app/aio/shared/TableEngine';
import { SidePopupComponent } from 'src/app/views/app/aio/shared/side-popup/side-popup.component';
@Component({
    selector: 'check-books',
    templateUrl: 'check-books.component.html',
    styleUrls: ['check-books.component.scss']
})
export class CheckBooksComponent {
    ////////////////////////////////////////////////////////////////////////////
    //  VARYING :
    //  Modify per similar component.
    ////////////////////////////////////////////////////////////////////////////
    
    constructor(private formBuilder: FormBuilder, private modelService: CheckBookService, private renderer: Renderer2,
      private notifications: NotificationsService, private translate: TranslateService,
      private TreasuryAndBankAccountsService:TreasuryAndBankAccountsService,
      private currencyService:CurrencyService
     
    ) {
    }
    listHeader = new ListPageHeaderComponent();
    @ViewChild('search') search: any;
    tableEngine = new TableEngine(this.translate, this.notifications);
    currentScreen: any = 'Table';
    tableView: any = true;
    banckAccounts:TreasuryAndBankAccountsModel[]=[];
    currences:CurrencyModel[]=[];
    
  
    translationName: string = 'CheckBook'
  
    sortableKeys = [];
    searchableKeys = ['CheckBookNumber', 'TheFirstSerialNumber', 'TheLastSerialNumber'];
  
    modelObject: CheckBookModel = new CheckBookModel();
  
    ogData: CheckBookModel[] = [];
    mainForm: FormGroup = new FormGroup({
      Id: new FormControl(),
      BankId              : new FormControl(),
      CheckBookNumber     : new FormControl(),
      TheFirstSerialNumber: new FormControl(),
      TheLastSerialNumber : new FormControl(),
      CurrencyId          : new FormControl(),
      IsActive            : new FormControl(),
      Description         : new FormControl(),
   
    });
  
  
    declare lang: any;
    declare itemOrder: any;
    currentAction = '';
    savedPage = 0;
    currentPage = 1;
    deletionList: any[] = [];
  
    @ViewChild('sideModalRef', { static: true }) sideModalRef: SidePopupComponent;
    @ViewChild('delModal', { static: true }) delModal: ModalDirective;
    @ViewChild('smallModal', { static: true }) smallModal: ModalDirective = null;
    formPresentationModal: any;
    ngOnInit(): void {
      this.lang = localStorage.getItem('theme_lang');
      this.itemOrder = { label: 'CheckBookName', property: this.lang === 'ar-EG' ? 'NameAr' : 'NameEn' };
      this.sortableKeys = [
        { label: 'CheckBookNumber', property: 'CheckBookNumber' },
        { label: 'TheFirstSerialNumber', property: 'TheFirstSerialNumber' },
        { label: 'TheLastSerialNumber', property: 'TheLastSerialNumber' },
  
      ];
      this.loadServices();
  
      this.tableEngine.loadTableEngine(this.ogData, this.modelService, this.modelObject,
        this.searchableKeys, this.sortableKeys, this.mainForm, this.translationName, this.itemOrder,
        this.sideModalRef, this.delModal, this.smallModal, this.lang
      )
    }
  
    showModal(Id, action) {
      
      if (action === 'Add') {
        //mainForm.get
        this.tableEngine.showAddNewModalRef(this.createForm, this.formBuilder)
      }
      else if (action === 'Edit') {
        this.tableEngine.showEditModalRef(Id, this.createForm, this.formBuilder)
      }
      else if (action === 'Info') {
        this.tableEngine.showInfoModalRef(Id, this.createForm, this.formBuilder)
      }
      //this.currentScreen = "Form";
      this.tableView = false;
      this.modelObject = this.tableEngine.modelObject;
      this.mainForm = this.tableEngine.mainForm;
    }
  
    createForm(modelObject, formBuilder): FormGroup {
      //console.log("from craeteform");
  
      //console.log(modelObject);
  
      return formBuilder.group({
        Id: [modelObject.Id >= 0 ? modelObject.Id : 0],
        BankId              : [modelObject.BankId              ,Validators.required],
        CheckBookNumber     : [modelObject.CheckBookNumber     ,Validators.required],
        TheFirstSerialNumber: [modelObject.TheFirstSerialNumber,Validators.required],
        TheLastSerialNumber : [modelObject.TheLastSerialNumber ,Validators.required],
        CurrencyId          : [modelObject.CurrencyId          ,Validators.required],
        IsActive            : [modelObject.IsActive            ],
        Description         : [modelObject.Description         ],
      });
    }
  
    addObject() {
      this.currentAction = 'loading';
  
      this.modelObject = new CheckBookModel();
      this.modelObject.Id = 0;
      this.modelObject = this.mainForm.getRawValue();
      
      this.executeAddObject();
    }
  
    executeAddObject() {
      console.log(this.modelObject);
  
      this.modelService.insert(this.modelObject).subscribe(result => {
        if (result === true) {
          this.tableEngine.loadData();
          this.currentAction = '';
          this.tableEngine.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyAdded"));
          if(this.sideModalRef === undefined){
            if(this.smallModal != undefined){
              if(this.prevAction!='AddThenNew'){
                this.tableEngine.smallModal.hide();
              }
            }
            else{
              //this.tableView = this.prevAction==='AddThenNew'?false:true;
            }
          }
          else{
            if(this.prevAction!='AddThenNew'){
              this.tableEngine.sideModalRef.modalRef.hide();
            }
          }
        }
        else {
          this.tableEngine.toastError(this.translate.instant("Error"), this.translate.instant("FailedAdd"));
          this.currentAction = '';
        }
      })
    }
  
    editObject() {
      this.currentAction = 'loading';
      this.tableEngine.savedPage = this.tableEngine.currentPage;
  
      let tempObj = new CheckBookModel();
      tempObj = this.mainForm.getRawValue();
      tempObj.Id= this.modelObject.Id;
      console.log(tempObj);
      
  
      if (JSON.stringify(tempObj) != JSON.stringify(this.modelObject)) {
        this.executeEditObject(tempObj);
      }
      else {
        if(this.sideModalRef === undefined){
          if(this.smallModal != undefined){
            this.tableEngine.smallModal.hide();
          }
        }
        else{
          this.tableEngine.sideModalRef.modalRef.hide();
        }
      }
    }
  
    executeEditObject(Object) {
      this.modelService.update(Object).subscribe(result => {
        if (result === true) {
          this.tableEngine.loadData();
          this.currentAction = '';
          this.tableEngine.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyEdited"));
  
          if(this.sideModalRef === undefined){
            if(this.smallModal != undefined){
              this.tableEngine.smallModal.hide();
            }
          }
          else{
            this.tableEngine.sideModalRef.modalRef.hide();
          }
        }
        else {
          this.tableEngine.toastError(this.translate.instant("Error"), this.translate.instant("FailedEdit"));
          this.currentAction = '';
        }
      })
    }
  
    //Deletion methods:
    //Customize if needed.
    //Pass the target object as parameter if the target API can only delete singular objects and not lists.
    deleteObject() {
      this.savedPage = this.currentPage;
      //prevent multiclicks. re enable after API response
      this.currentAction = 'loading';
      this.executeDeleteObject();
    }
  
    //Same here...
    executeDeleteObject() {
      this.modelService.deleteList(this.tableEngine.deletionList).subscribe(result => {
        if (result === true) {
          this.tableEngine.loadData();
          this.currentAction = '';
          this.tableEngine.finalizeDeletion();
        }
        else {
          this.tableEngine.toastError(this.translate.instant("Error"), this.translate.instant("FailedDelete"));
          this.currentAction = '';
        }
      })
    }
    ////////////////////////////////////////////////////////////////////////////
    //
    //
    //
    //
    //
    ////////////////////////////////////////////////////////////////////////////
    //  EXCLUSIVE :
    //  Specifically for this component.
    ///////////////////////////////
    //Get any additional data here
    prevAction = '';
    addAndNew(){
      this.prevAction = 'AddThenNew';
      this.addObject();
      
      this.tableEngine.sideModalRef.modalRef.hide();
      this.createForm(this.tableEngine.modelObject, this.formBuilder);
      this.tableEngine.showAddNewModalRef(this.createForm, this.formBuilder)
    }
  
    loadServices() {
      this.currentAction = 'loading';
      this.TreasuryAndBankAccountsService.GetAllBankAccounts()
      .subscribe(result=>{
        this.banckAccounts = result;
        this.currencyService.getAll()
        .subscribe(result=>{
          this.currences = result;
          this.tableEngine.loadData();
  
        })
      })
   
    //   this.tableEngine.loadData();

    }
  
  
  
    //CheckBookMain = false;
  
    ////////////////////////////////////////////////////////////////////////////
    //
    //
    //
    //
    //
    ////////////////////////////////////////////////////////////////////////////
    //  CONSTANT :
    //  Do not change.
    ///////////////////////////////
    prepChangeSort(sortKey){
      this.tableEngine.changeSort(this.tableEngine.getSort(sortKey));
      this.itemOrder = this.tableEngine.itemOrder;
  
      //console.log("sort in component");
      //console.log(this.itemOrder);
      
      //console.log("sort in table engine");
      //console.log(this.tableEngine.itemOrder);
    }
    getBanckNameById(Id){
       var item = this.banckAccounts.find(x=>x.Id == Id);
       if(item != null){
        return item.Name;
       }
       else
       {
        return '';
       }
    }
    getCurrencyNameById(Id){
        var item = this.currences.find(x=>x.Id == Id);
        if(item != null){
         return this.lang=='ar-EG' ?item.NameAr:item.NameEn;
        }
        else
        {
         return '';
        }
     }
     SearchFrom: Date = null ;
     SearchTo: Date= null;
   
     clearSearch() {
       this.SearchFrom= null ;
       this.SearchTo = null;
     }
   
  }
  