import { OperationTypeModel } from "./OperationTypeModel";


export class StockAdjustmentModel{
    Id?: number;
    QuantityEditType?:number;
    OperationTypeId?:number;
    WarehouseId?:number;
    SubAccountId?:number;
    InventoryBeforeQuantity?:number;
    InventoryAfterQuantity?:number;
    ItemId?:number;
    Quantity?:number;
    UnitPrice?:number;
    MoveNumber?:number;
    Notes?:string;
    
    WarehouseName?:string;
    Time?:Date;
    Date?:Date;
    
    OperationType?:OperationTypeModel[];

    constructor(item?:StockAdjustmentModel){
        item = item? item:{};
        this.Id = item.Id? item.Id:0;
        this.QuantityEditType = item.QuantityEditType? item.QuantityEditType:1;
        this.OperationTypeId = item.OperationTypeId? item.OperationTypeId:0;          
        this.MoveNumber = item.MoveNumber? item.MoveNumber:0;          
        this.InventoryBeforeQuantity = item.InventoryBeforeQuantity? item.InventoryBeforeQuantity:0;          
        this.InventoryAfterQuantity = item.InventoryAfterQuantity? item.InventoryAfterQuantity:0;          
        this.SubAccountId = item.SubAccountId? item.SubAccountId:0;          
        this.WarehouseId = item.WarehouseId? item.WarehouseId:0;          
        this.ItemId = item.ItemId? item.ItemId:0;          
        this.Quantity = item.Quantity? item.Quantity:0;          
        this.UnitPrice = item.UnitPrice? item.UnitPrice:0;          
        this.Notes = item.Notes? item.Notes:'';          
        this.WarehouseName = item.WarehouseName? item.WarehouseName:'';          
        this.Time = item.Time? item.Time:new Date();          
        this.Date = item.Date? item.Date:new Date();          
        this.OperationType = item.OperationType? item.OperationType:[];
        
    }

}