import { Component, OnInit } from '@angular/core';
import { BudgetModel } from '../../models/budget.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BudgetAccountsModel } from '../../models/budget-accounts.model';
import { LockerBasicModel } from '../../../locker-data/helper/model';
import { AccountNameModel } from '../../../account-name/AccountNameModel';
import { CostCenterModel } from '../../../cost-center/models/cost-center.model';
import { CurrencyService } from 'src/app/invoices/currencies/CurrencyService';
import { AccountsNameService } from '../../../account-name/AccountNameService';
import { CostCenterService } from '../../../cost-center/services/cost-center.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BudgetService } from '../../budget.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationType, NotificationsService } from 'angular2-notifications';
import { CurrencyModel } from 'src/app/invoices/currencies/CurrencyModel';
import { MonthlyDistributionModel } from '../../monthly-distribution/models/monthly-distribution.model';
import { MonthlyDistributionService } from '../../monthly-distribution/monthly-distribution.service';
import { AccountsCostCentersService } from '../../../assign-cost-centers/services/account-cost-center.service';
import { AccountsCostCentersModel } from '../../../assign-cost-centers/models/account-cost-center.model';

@Component({
    selector: 'create-budget',
    templateUrl: 'create-budget.component.html',
    styleUrls: ['create-budget.component.scss']
})
export class CreateBudgetComponent implements OnInit{
    Budget:BudgetModel={};
    declare lang: any;
    Id=0;
    Form:FormGroup;
    buttonName='Add';
    users:any[]=[];
    SelectedAccount:BudgetAccountsModel[]=[{AccountId:0,Amount:0,BudgetId:this.Id,Id:0}];
    LockersData:LockerBasicModel[]=[];
    public editEnabled = true;
   public picurl: string ='';
   AccountsNameModel: CostCenterModel[] = [];
   PackUpAccounts: CostCenterModel[] = [];
   centers:CostCenterModel[]=[];
    constructor(
        private CurrencyService: CurrencyService, 
        private accountsNameService: AccountsNameService,
        private costCenterService:CostCenterService,
       private route: ActivatedRoute,
       public router:Router,
       private BudgetService:BudgetService,
       public fb:FormBuilder,
       private translate: TranslateService,
        private notifications: NotificationsService,
        private monthlyDistributionService:MonthlyDistributionService,
        private AccountsCostCentersService:AccountsCostCentersService
        ){
     this.lang = localStorage.getItem('theme_lang');
     this.Id = + route.snapshot.paramMap.get('Id');
       
   }
   centersLoaded= false;
   TreasuriesLoaded= false;
   taxesLoaded= false;
   AccountsLoaded= false;
   categoriesLoaded= false;
   curennciesLoaded= false;
   curenncies:CurrencyModel[]=[]
   accounts : AccountsCostCentersModel[]=[];
   MonthlyDistributions:MonthlyDistributionModel[]=[];
   totalAmount=0;
   ChangeAmount(Amount){
    var total = this.Form.controls['TotalAmount'].value;
    if(total == null || total == undefined){
        total = 0;
    }
    total += Amount;
   this.Form.controls['TotalAmount'].setValue(total);

   }
   changeCostCenter(){
    var costId=this.Form.controls['CostCenterId'].value;
    if(costId > 0){
    this.SelectedAccount=[{AccountId:0,Amount:0,BudgetId:this.Id,Id:0}];
        this.AccountsCostCentersService.GetByCostCenterId(costId)
        .subscribe(result=>{
            this.accounts = result;
            this.PackUpAccounts = result.map(item=>Object.assign({}, item))

        })
    }
   }
    ngOnInit(): void {
      this.monthlyDistributionService.getAll()
      .subscribe(result=>{
        this.MonthlyDistributions = result;
      })
        this.accountsNameService.GetAllSubAccountes(0).subscribe(
            data=>{
                this.AccountsNameModel=data;
                this.PackUpAccounts = data.map(item=>Object.assign({}, item))
                this.AccountsLoaded = true;

            }
        );
      
        this.CurrencyService.getAll()
        .subscribe(data => {
            this.curenncies = data;
            this.curennciesLoaded = true;
        })
        this.costCenterService.getAll(0)
        .subscribe(cosetCenters=>{
            this.centers = cosetCenters;
            this.centersLoaded = true;
        })
        if(this.Id == 0){
            this.Budget = new BudgetModel();
            this.Form = this.createForm();
       
        }
        else{
            this.BudgetService.getById(this.Id)
            .subscribe(data=>{
                this.buttonName = "Edit"
                this.Budget =data;
                this.SelectedAccount    =this.Budget.BudgetAccounts    ;
                
                this.Form = this.createForm();
            })
        }
        
    }
    createForm():FormGroup{
        return this.fb.group({
            Id:[this.Budget.Id],
            BudgetAgainst                                    :[this.Budget.BudgetAgainst                                    ],
            MonthlyDistributionId                              :[this.Budget.MonthlyDistributionId                              ],
            CostCenterNameAr                                 :[this.Budget.CostCenterNameAr                                 ],
            CostCenterNameEn                                 :[this.Budget.CostCenterNameEn                                 ],
            CostCenterId                                     :[this.Budget.CostCenterId                                     ],
            FiscalYearId                                     :[this.Budget.FiscalYearId                                     ],
            ApplicableOnMaterialRequest                      :[this.Budget.ApplicableOnMaterialRequest                      ],
            ApplicableOnBookingActualExpenses                :[this.Budget.ApplicableOnBookingActualExpenses                ],
            ApplicableOnPurchaseOrder                        :[this.Budget.ApplicableOnPurchaseOrder                        ],
            ActionIfAnnualBudgetExceededOnMR                 :[this.Budget.ActionIfAnnualBudgetExceededOnMR                 ],
            ActionIfAnnualBudgetExceededOnPO                 :[this.Budget.ActionIfAnnualBudgetExceededOnPO                 ],
            ActionIfAccumulatedMonthlyBudgetExceededOnMR     :[this.Budget.ActionIfAccumulatedMonthlyBudgetExceededOnMR     ],
            ActionIfAccumulatedMonthlyBudgetExceededOnPO     :[this.Budget.ActionIfAccumulatedMonthlyBudgetExceededOnPO     ],
            ActionIfAnnualBudgetExceededOnActual             :[this.Budget.ActionIfAnnualBudgetExceededOnActual             ],
            ActionIfAccumulatedMonthlyBudgetExceededOnActual :[this.Budget.ActionIfAccumulatedMonthlyBudgetExceededOnActual ],
            BudgetAccounts                                   :[this.Budget.BudgetAccounts                                   ],
            TotalAmount:[this.Budget.TotalAmount]
        })
    }
   
    splitAccount($event){
        debugger;
        var i = this.accounts.findIndex(x=>x.AccountId == $event.AccountId);
        if(i > -1){
            this.accounts.splice(i,1);
        }
    }
    deleteSelectedAccount(item){
        debugger;
        var i = this.SelectedAccount.indexOf(item);
        if(i > -1){
            var total = this.Form.controls['TotalAmount'].value;
            if(total == null || total == undefined){
                total = 0;
            }
            total -= item.Amount;
           this.Form.controls['TotalAmount'].setValue(total);
            var x= this.PackUpAccounts.findIndex(x=>x.Id == this.SelectedAccount[i].AccountId);
            if(x > -1){

                this.accounts.push(this.PackUpAccounts[x]);
            }
            this.SelectedAccount.splice(i,1);
        }
    }
    AddonClick(){
        debugger;
        var selectedDailyrestrictionsFooterModel =new BudgetAccountsModel();
        selectedDailyrestrictionsFooterModel.Id = this.SelectedAccount.length+1 ;
        selectedDailyrestrictionsFooterModel.Amount = 0 ;
        selectedDailyrestrictionsFooterModel.AccountId = 0 ;
        selectedDailyrestrictionsFooterModel.BudgetId = this.Id ;
        this.SelectedAccount.push(selectedDailyrestrictionsFooterModel);
    }
 
    Save(){
        debugger;
        this.Budget = this.Form.getRawValue();
        this.Budget.Id = this.Id;
        this.Budget.BudgetAccounts = this.SelectedAccount;
        if(this.Id > 0 ){
            this.BudgetService.update(this.Budget)
            .subscribe(result => {
                if(result == true){
                    this.router.navigate(['app/accounts/budgets']);
                    this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyEdited"));
    
                }
                else{
                 this.toastError(this.translate.instant("Error"), this.translate.instant("FailedEdit"));

                }
            })
        }
        else{
            this.BudgetService.insert(this.Budget)
            .subscribe(result => {
                if(result == true){
                    this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyAdd"));
                    this.router.navigate(['app/accounts/budgets']);
    
                }
                else{
                 this.toastError(this.translate.instant("Error"), this.translate.instant("FailedAdd"));

                }
            })
        }
   
    }
    addAndNew(){
        debugger;
        this.Budget = this.Form.getRawValue();
        this.Budget.Id = this.Id;
        this.Budget.BudgetAccounts = this.SelectedAccount;
        if(this.Id > 0 ){
            this.BudgetService.update(this.Budget)
            .subscribe(result => {
                if(result == true){
                    this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyEdited"));
                    this.Budget = new BudgetModel();
                   this.picurl = '';
                   this.SelectedAccount = [];
                }
                else{
                 this.toastError(this.translate.instant("Error"), this.translate.instant("FailedEdit"));
                    
                }
            })
        }
        else{
            this.BudgetService.insert(this.Budget)
            .subscribe(result => {
                if(result == true){
                    this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyAdd"));
                    this.Budget = new BudgetModel();
                    this.picurl = '';
                    this.SelectedAccount = [];

                }
                else{
                    this.toastError(this.translate.instant("Error"), this.translate.instant("FailedAdd"));

                }
            })
        }
   
    }

    toastSuccess(toastHeader: string, toastBody: string): void {
        this.notifications.create(toastHeader, toastBody,
          NotificationType.Success, { timeOut: 3000, showProgressBar: true });
      }
      toastError(toastHeader: string, toastBody: string): void {
        this.notifications.create(toastHeader, toastBody,
          NotificationType.Error, { timeOut: 3000, showProgressBar: true });
      }
}

