export class QuotationItemTaxModel {
    Id?: number;
    QuotationItemId?: number;
    TaxId?: number;
    TaxNameAr?: string;
    TaxNameEn?: string;
    TaxIsRate?: boolean;
    TaxValue?: number; 

    constructor(obj?: QuotationItemTaxModel) {
        obj = obj ? obj : {};
        this.Id = obj.Id ? obj.Id : 0;
        this.QuotationItemId = obj.QuotationItemId ? obj.QuotationItemId : 0;
        this.TaxId = obj.TaxId ? obj.TaxId : 0;
        this.TaxNameAr = obj.TaxNameAr ? obj.TaxNameAr : '';
        this.TaxNameEn = obj.TaxNameEn ? obj.TaxNameEn : '';
        this.TaxIsRate = obj.TaxIsRate ? obj.TaxIsRate : true;
        this.TaxValue = obj.TaxValue ? obj.TaxValue : 0;
    }

}