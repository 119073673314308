import { SalariesManagerPayment } from "./SalariesManagerPayment";

export class SalariesPaymentModel{
    Id?: number;
    PaymentDate?: Date;
    BankAccountId?: number;
    SalariesManagerId?: number;
    Notes?:string;
    PaymentOfApproved?:boolean;
    PaymentOfSelected?:boolean;
    Amount?: number;
    Code?: number;

    
    SalariesManagerList?:SalariesManagerPayment[];
   
    constructor(item?:SalariesPaymentModel){
        item = item? item:{};
        this.Id = item.Id? item.Id:0;
        this.PaymentDate = item.PaymentDate? item.PaymentDate:new Date();
        this.BankAccountId = item.BankAccountId? item.BankAccountId:0;
        this.SalariesManagerId = item.SalariesManagerId? item.SalariesManagerId:0;
        this.Amount = item.Amount? item.Amount:0;
        this.Code = item.Code? item.Code:0;
        
        this.Notes = item.Notes? item.Notes:'';
        this.PaymentOfApproved = item.PaymentOfApproved? item.PaymentOfApproved:true;
        this.PaymentOfSelected = item.PaymentOfSelected? item.PaymentOfSelected:false;
  
 
      

    }

}