<simple-notifications></simple-notifications>
<form [formGroup]="AccountNameActionsFormGroup" *ngIf="formLoaded" class="card">
<div class="form-group  Card_Padding">
    <br>
    <br>
    <div class="row">
        <div class=" col-md-4">
            <label for="inputState">{{ "Account Expenses" | translate }}</label>
            <select formControlName="AccountExpenses" class="form-control">
                <option *ngFor="let item of accountExpensesTypeList" [value]="item.Id">{{item.NameEn}}</option>
            </select>
        </div>
        <div class=" col-md-4">
            <label for="inputState">{{ 'Account Fication' | translate }}</label>
            <select formControlName="AccountFication"  id="inputState" class="form-control">
                <option *ngFor="let item of accountFicationList" [value]="item.Id">{{item.NameEn}}</option>

            </select>
        </div>
        <div class=" col-md-4">
            <label for="inputState">{{ 'Account Fication cost' | translate }}</label>
            <select formControlName="AccountFicationcost" id="inputState" class="form-control">
                <option *ngFor="let item of accountFicationCostTypeList" [value]="item.Id">{{item.NameEn}}</option>
            </select>
        </div>
    </div>

    <br>

    <div class="row">
        <div class=" col-md-4">
            <label for="inputState">{{ "Account Type" | translate }}</label>
            <select formControlName="AccountType" class="form-control">
                <option *ngFor="let item of accountsTypeList" [value]="item.Id">{{item.NameEn}}</option>
            </select>
        </div>
        <div class=" col-md-4">
            <label for="inputState">{{ 'Account Nature' | translate }}</label>
            <select formControlName="AccountNature" id="inputState" class="form-control">
                <option *ngFor="let item of AccountNatureList" [value]="item.Id">{{item.NameEn}}</option>

            </select>
        </div>
        <div class=" col-md-4">
            <label for="inputState">{{ 'Account Menu' | translate }}</label>
            <select  formControlName="AccountMenu"  id="inputState" class="form-control">
                <option *ngFor="let item of accountMenuList" [value]="item.Id">{{item.NameEn}}</option>
            </select>
        </div>
    </div>

    <br>
    <div class=" row">
        <div class=" col-md-8">
            <label for="inputState">{{ "Main Account" | translate }}</label>
            <select  formControlName="MainAccount"  class="form-control">
                <option *ngFor="let item of AccountsNameList" [value]="item.Id">{{item.NameEn}}</option>
            </select>
        </div>
        <div>
            <br>
            <br>
            <div class="custom-control custom-checkbox ">
                <input  type="checkbox"  id="customCheckThis"
                    class="custom-control-input">
                    <label for="customCheckThis"  class="custom-control-label">Is Main
                    Account</label></div>
        </div>
    </div>



    <br>

    <div class="form-row">
        <div class="form-group col-md-6">
            <label>{{ 'NameAr' | translate }}</label>
            <input formControlName="NameArControl" type="text" class="form-control">
        </div>
        <div class="form-group col-md-6">
            <label >{{ 'NameEn' | translate }}</label>
            <input formControlName="NameEnControl" type="text"  class="form-control">
        </div>

    </div>
    <div class="form-row">
        <div class="form-group col-md-6">
            <label for="inputState">{{ 'Account Number' | translate }}</label>
            <input formControlName="AccountNumber" type="number" class="form-control">
        </div>
        <div class="form-group col-md-6">
            <label for="inputState">{{ 'Level Code' | translate }}</label>
            <input formControlName="LevelCode"  type="number"  class="form-control">
        </div>

    </div>
    <div class="custom-control custom-checkbox ">
        <input formControlName="NotMenu" type="checkbox"  id="customCheck"
            class="custom-control-input">
            <label for="customCheck"  class="custom-control-label">Not Menu</label></div>
            <div class="custom-control custom-checkbox ">
                <input  type="checkbox"  id="CheckThis"
                    class="custom-control-input">
                    <label for="CheckThis"  class="custom-control-label">Is Not Active</label></div> 
    <div *ngIf="modelState != 'Show'" class="text-center">
        <button type="button" class="btn btn-primary"(click)="onClick()">{{ titleButton | translate }}</button>{{' '}}

    </div>
</div>
</form>

