export class ClientBusinessExtractDetailsModel{
    Id?: number;
    Statement              ?: string;
    Previous               ?: number;
    Current                ?: number;
    PreviousAndCurrentTotal?: number;
    Total                  ?: number;
    Price                  ?: number;
    TheTotalValue          ?: number;
    ClientBusinessExtractId?: number;
    ProjectWorkItemId?: number;
    ExcutionPercentage?:number;
    itemNameAr?:string;
    itemNameEn?:string;
    constructor(item?:ClientBusinessExtractDetailsModel){
        item= item ? item : {};
        this.Id = item.Id ? item.Id : 0;
        this.Statement               = item.Statement               ? item.Statement               : '';
        this.itemNameAr               = item.itemNameAr               ? item.itemNameAr               : '';
        this.itemNameEn               = item.itemNameEn               ? item.itemNameEn               : '';
        this.Previous                = item.Previous                ? item.Previous                : 0;
        this.Current                 = item.Current                 ? item.Current                 : 0;
        this.PreviousAndCurrentTotal = item.PreviousAndCurrentTotal ? item.PreviousAndCurrentTotal : 0;
        this.Total                   = item.Total                   ? item.Total                   : 0;
        this.Price                   = item.Price                   ? item.Price                   : 0;
        this.TheTotalValue           = item.TheTotalValue           ? item.TheTotalValue           : 0;
        this.ClientBusinessExtractId = item.ClientBusinessExtractId ? item.ClientBusinessExtractId : 0;
        this.ExcutionPercentage = item.ExcutionPercentage ? item.ExcutionPercentage : 0;
        this.ProjectWorkItemId = item.ProjectWorkItemId ? item.ProjectWorkItemId : 0;
    }
}