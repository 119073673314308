import { Component, HostBinding, Renderer2, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';

import { TableEngine } from 'src/app/invoices/TableEngine'
import { ModalDirective } from 'ngx-bootstrap/modal';
import { SidePopupComponent } from 'src/app/views/app/aio/shared/side-popup/side-popup.component';
import { Observable, of, Subscription } from 'rxjs';

import { NgSelectComponent } from '@ng-select/ng-select';

import { ReturnPurchaseModel } from "./ReturnPurchaseModel";
import { ReturnPurchaseService } from "./ReturnPurchaseService";
import { ReturnPurchaseItemModel } from "./ReturnPurchaseItemModel";
import { CodingUnitModel } from "src/app/invoices/coding-units/CodingUnitModel";
import { CodingUnitService } from 'src/app/invoices/coding-units/CodingUnitService';
import { StoreDataService } from "src/app/views/purchases/purchase-orders/StoreDataService";
import { SupplierModel } from "src/app/views/purchases/purchase-orders/SupplierModel";
import { SupplierService } from "src/app/views/purchases/purchase-orders/SupplierService";
import { formatDate } from '@angular/common';
import { StoreDataModel } from '../purchase-orders/StoreDataModel';
import { PurchaseInvoiceService } from '../invoice-purchase/PurchaseInvoiceService';
import { PurchaseInvoiceModel } from '../invoice-purchase/PurchaseInvoiceModel';
import { PaymentMethodModel } from '../invoice-purchase/PaymentMethodModel';
import { PaymentMethodService } from '../invoice-purchase/PaymentMethodService';
import { ConfigPurchasesService } from '../config-purchases/ConfigPurchasesService';
import { ConfigPurchasesModel } from '../config-purchases/ConfigPurchasesModel';
import { StoreItemsService } from 'src/app/Invintory/store-items-service.service';



@Component({
  selector: 'app-return-purchases',
  templateUrl: './return-purchases.component.html',
  styleUrls: ['./return-purchases.component.scss']
})
export class ReturnPurchasesComponent {
  ////////////////////////////////////////////////////////////////////////////
  //  VARYING :
  //  Modify per similar component.
  ////////////////////////////////////////////////////////////////////////////

  //Enable debugMode to make API modules return static data from within the service class
  debugMode: boolean = false;

  constructor(private formBuilder: FormBuilder, private modelService: ReturnPurchaseService, private renderer: Renderer2,
    private notifications: NotificationsService, private translate: TranslateService,
    private itemService: StoreItemsService, private paymentMethodService: PaymentMethodService,
    private codingUnitService: CodingUnitService,
    private supplierService: SupplierService, private purchaseInvoiceService: PurchaseInvoiceService,
    private configPurchasesService: ConfigPurchasesService
  ) {
  }

  tableEngine = new TableEngine(this.translate, this.notifications);
  currentScreen: any = 'Table';
  tableView: any = true;


  translationName: string = 'ReturnPurchase'

  sortableKeys = [];
  searchableKeys = ['Date'];

  modelObject: ReturnPurchaseModel = new ReturnPurchaseModel();

  ogData: ReturnPurchaseModel[] = [];

  Footers?: ReturnPurchaseItemModel[];
  mainForm: FormGroup = new FormGroup({
    //Id: new FormControl(),
    PurchaseInvoiceId: new FormControl(),
    //PaymentMethodId: new FormControl(),
  });


  declare lang: any;
  declare itemOrder: any;
  currentAction = '';
  savedPage = 0;
  currentPage = 1;
  deletionList: any[] = [];

  @ViewChild('sideModalRef', { static: true }) sideModalRef: SidePopupComponent = null;
  @ViewChild('delModal', { static: true }) delModal: ModalDirective;
  @ViewChild('smallModal', { static: true }) smallModal: ModalDirective = null;
  formPresentationModal: any;
  ngOnInit(): void {
    if (this.debugMode) {
      this.enableDebugOnAllServices();
    }

    this.lang = localStorage.getItem('theme_lang');
    this.itemOrder = { label: 'ReturnPurchaseCode', property: 'Code' };
    this.sortableKeys = [
      { label: 'ReturnPurchaseDate', property: 'Date' },
      { label: 'ReturnPurchaseStoreName', property: this.lang === 'ar-EG' ? 'StoreNameAr':'StoreNameEn' },
      { label: 'ReturnPurchasePurchaseInvoiceHeader', property: 'RequestReceiptCode' },
      { label: 'ReturnPurchaseTotalValue', property: 'TotalItemGrossPrices' },
      { label: 'ReturnPurchaseSupplier', property: this.lang === 'ar-EG' ? 'SupplierNameAr':'SupplierNameEn' },
      { label: 'ReturnPurchaseCode', property: 'Code' },
      { label: 'ReturnPurchaseIsPosted', property: 'IsPosted' },
    ];


    this.tableEngine.loadTableEngine(this.ogData, this.modelService, this.modelObject,
      this.searchableKeys, this.sortableKeys, this.mainForm, this.translationName, this.itemOrder,
      this.sideModalRef, this.delModal, this.smallModal, this.lang
    )
    this.loadServices();
  }


  enableDebugOnAllServices() {
    //this.modelService.debugMode = true;
    // this.itemService.debugMode = true;
    //this.codingUnitService.debugMode = true;
    //this.storeDataService.debugMode = true;
    //this.supplierService.debugMode = true;
    //this.purchaseInvoiceService.debugMode = true;
    //this.paymentMethodService.debugMode = true;
  }


  lastCode = 0;
  showModal(Id, action) {
    console.log("entered showModal");

    if (action === 'Add') {
      this.addedItemList = [];
      if(this.configPurchases.EnableAutoReturnRequestCodes==true){
        this.lastCode = this.configPurchases.StartingReturnRequestCode;
        for (let index = 0; index < this.tableEngine.ogData.length; index++) {
          if(this.tableEngine.ogData.findIndex(x=>x.Code == this.lastCode) >= 0)
          { this.lastCode++; console.log(this.lastCode);
          } 
          else { break; }
        }
      }else{
        //this.lastCode = this.tableEngine.ogData.length>0?this.tableEngine.ogData[this.tableEngine.ogData.length - 1].Code + 1:0;
        this.lastCode = 0;
      }
      this.modelObject.Code = this.lastCode;
      this.checkCode();
      this.tableEngine.showAddNewModalRef(this.createForm, this.formBuilder)
    }
    else if (action === 'Edit') {
      this.modelService.getByIdWithItems(Id).subscribe(
        detailedObject => {
          console.log("detailed object", detailedObject);
          
          let targetIndex = this.tableEngine.ogData.findIndex(i => i.Id === Id);
          this.tableEngine.ogData[targetIndex] = detailedObject;
          this.tableEngine.showEditModalRef(Id, this.createForm, this.formBuilder)
          
          this.lastCode = this.tableEngine.modelObject.Code
          
          this.modelObject = this.tableEngine.modelObject;
          this.mainForm = this.tableEngine.mainForm;
          this.tableView = false;

          this.addedItemList = [];
          this.addedItemList = [...this.modelObject.Items];

          this.calculateTotalItemValue();
        }
      )
    }
    else if (action === 'Info') {
      console.log("entered info");
      this.modelService.getByIdWithItems(Id).subscribe(
        detailedObject => {
          console.log("detailed object", detailedObject);
          
          let targetIndex = this.tableEngine.ogData.findIndex(i => i.Id === Id);
          this.tableEngine.ogData[targetIndex] = detailedObject;
          this.tableEngine.showInfoModalRef(Id, this.createForm, this.formBuilder)
          
          this.lastCode = this.tableEngine.modelObject.Code
          
          this.modelObject = this.tableEngine.modelObject;
          this.mainForm = this.tableEngine.mainForm;
          this.tableView = false;

          this.addedItemList = [];
          this.addedItemList = [...this.modelObject.Items];

          this.calculateTotalItemValue();
        } 
      )
    }
    this.tableView = false;
    this.modelObject = this.tableEngine.modelObject;
    this.mainForm = this.tableEngine.mainForm;
    console.log((action + " object"), this.modelObject);

    this.addedItemList.forEach(element => {
      element.MaxQuantity = element.Quantity;
      console.log(element.MaxQuantity);
      
    });

    this.calculateTotalItemValue();

    console.log("modelObject after entering edit", this.modelObject);
  }

  createForm(modelObject, formBuilder): FormGroup {
    return formBuilder.group({
      Id: [modelObject.Id >= 0 ? modelObject.Id : 0],
      PurchaseInvoiceId: [modelObject.PurchaseInvoiceId, Validators.compose([Validators.required])],
      //PaymentMethodId: [modelObject.PaymentMethodId, Validators.compose([Validators.required])],
    });
  }

  addObject() {
    this.currentAction = 'loading';
    
    let tempList: ReturnPurchaseItemModel[] = [];
    let tempItem: ReturnPurchaseItemModel;
    let debugId = 0;
    for (let index = 0; index < this.addedItemList.length; index++) {
      tempItem = new ReturnPurchaseItemModel();
      tempItem = this.addedItemList[index];
      tempItem.Id = this.debugMode ? debugId : 0;
      tempItem.ReturnPurchaseId = 0;
      tempList.push(tempItem)

      debugId++;
    }
    
    //let lastId = this.tableEngine.ogData.length>0?this.tableEngine.ogData[this.tableEngine.ogData.length - 1].Id:0;
    //
    this.modelObject = new PurchaseInvoiceModel();
    
    //this.modelObject.Id = this.debugMode ? lastId + 1 : 0;
    this.modelObject.Id = 0;
    this.modelObject.PurchaseInvoiceId = this.purchaseInvoiceHeader.Id;
    this.modelObject.PurchaseInvoiceCode = this.purchaseInvoiceHeader.Code;

    this.modelObject.PaymentMethodId = this.purchaseInvoiceHeader.PaymentMethodId;
    this.modelObject.PaymentMethodNameAr = this.purchaseInvoiceHeader.PaymentMethodNameAr;
    this.modelObject.PaymentMethodNameEn = this.purchaseInvoiceHeader.PaymentMethodNameEn;
    
    this.modelObject.Code = this.lastCode;
    this.modelObject.SupplierId = this.purchaseInvoiceHeader.SupplierId;
    this.modelObject.SupplierNameAr = this.purchaseInvoiceHeader.SupplierNameAr;
    this.modelObject.SupplierNameEn = this.purchaseInvoiceHeader.SupplierNameEn;
    //this.modelObject.StoreDataId = this.purchaseInvoiceHeader.StoreDataId;
    //this.modelObject.StoreNameAr = this.purchaseInvoiceHeader.StoreNameAr;
    //this.modelObject.StoreNameEn = this.purchaseInvoiceHeader.StoreNameEn;
    this.modelObject.BranchId = this.purchaseInvoiceHeader.BranchId;
    this.modelObject.BranchNameAr = this.purchaseInvoiceHeader.BranchNameAr;
    this.modelObject.BranchNameEn = this.purchaseInvoiceHeader.BranchNameEn;

    let date = new Date(this.purchaseInvoiceHeader.Date);
    this.modelObject.Date = date;
    this.modelObject.Month = date.getMonth();
    this.modelObject.Year = date.getFullYear();

    this.modelObject.Description = this.purchaseInvoiceHeader.Description;
    this.modelObject.IsPosted = this.purchaseInvoiceHeader.IsPosted;
    this.modelObject.Items = tempList;

    this.modelObject.TotalItemGrossPrices = this.calculateTotalItemValue();
    this.modelObject.TotalItemDiscounts = this.currentDiscounts;
    this.modelObject.TotalItemNetPrices = this.currentAfterDiscounts;
    this.modelObject.TotalItemPrices = this.currentBaseValues;
    this.modelObject.TotalItemTaxes = this.currentTaxes;


    console.log(this.modelObject);
    this.executeAddObject();
  }

  executeAddObject() {
    this.modelService.insertWithItems(this.modelObject).subscribe(result => {
      if (result === true || result > 0) {
        this.tableEngine.loadData();
        this.currentAction = '';
        this.tableEngine.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyAdded"));
        if (this.sideModalRef === undefined) {
          if (this.smallModal != undefined) {
            this.tableEngine.smallModal.hide();
          }
          else {
            this.tableView = this.prevAction === 'AddThenNew' ? false : true;

            this.prevAction = '';
          }
        }
        else {
          this.tableEngine.sideModalRef.modalRef.hide();
        }

        if(this.prevAction != 'AddThenNew'){
          this.goBack();
        }
      }
      else {
        this.tableEngine.toastError(this.translate.instant("Error"), this.translate.instant("FailedAdd"));
        this.currentAction = '';
      }
    })
  }

  editObject() {
    this.currentAction = 'loading';
    this.tableEngine.savedPage = this.tableEngine.currentPage;

    let tempList: ReturnPurchaseItemModel[] = [];
    let tempItem: ReturnPurchaseItemModel;
    let debugId = 1;
    for (let index = 0; index < this.addedItemList.length; index++) {
      tempItem = new ReturnPurchaseItemModel();
      tempItem = this.addedItemList[index]
      //tempItem.Id = this.debugMode ? debugId : 0;
      tempItem.Id = 0;

      tempItem.ReturnPurchaseId = this.modelObject.Id;
      tempList.push(tempItem)

      debugId++;
    }
    
    //this.calculateTotalItemValue();

    let tempObj = JSON.parse(JSON.stringify(this.modelObject));
    console.log("Original Object", this.modelObject);
    console.log("Temp Object", tempObj);
    
    let date = new Date(this.modelObject.Date);
    tempObj.Date = date;


    //let paymentMehod = this.getPaymentMethodById(this.mainForm.getRawValue().PaymentMethodId);
    tempObj.PaymentMethodId = this.modelObject.PaymentMethodId;
    tempObj.PaymentMethodNameAr = this.modelObject.PaymentMethodNameAr;
    tempObj.PaymentMethodNameEn = this.modelObject.PaymentMethodNameEn;

    tempObj.Items = tempList;

    tempObj.Id = this.modelObject.Id;

    tempObj.Code = this.lastCode;
    tempObj.SupplierId = this.modelObject.SupplierId;
    //tempObj.StoreDataId = this.modelObject.StoreDataId;
    tempObj.BranchId = this.modelObject.BranchId;;

    tempObj.Description = this.modelObject.Description;
    tempObj.IsPosted = this.modelObject.IsPosted;
    
    tempObj.TotalValue = this.calculateTotalItemValue();
    tempObj.TotalItemDiscounts = this.currentDiscounts;
    tempObj.TotalItemNetPrices = this.currentAfterDiscounts;
    tempObj.TotalItemPrices = this.currentBaseValues;
    tempObj.TotalItemTaxes = this.currentTaxes;

    console.log("editedObject", tempObj);
    this.executeEditObject(tempObj);
  }

  executeEditObject(Object) {


    this.modelService.updateWithItems(Object).subscribe(result => {
      if (result === true) {
        this.tableEngine.loadData();
        this.currentAction = '';
        this.tableEngine.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyEdited"));

        if (this.sideModalRef === undefined) {
          if (this.smallModal != undefined) {
            this.tableEngine.smallModal.hide();
          }
          else {
            this.tableView = true;
          }
        }
        else {
          this.tableEngine.sideModalRef.modalRef.hide();
        }
        
        this.goBack();

      }
      else {
        this.tableEngine.toastError(this.translate.instant("Error"), this.translate.instant("FailedEdit"));
        this.currentAction = '';
      }
    })
  }

  //Deletion methods:
  //Customize if needed.
  //Pass the target object as parameter if the target API can only delete singular objects and not lists.
  deleteObject() {
    this.savedPage = this.currentPage;
    //prevent multiclicks. re enable after API response
    this.currentAction = 'loading';
    this.executeDeleteObject();
  }

  //Same here...
  executeDeleteObject() {
    this.modelService.deleteList(this.tableEngine.deletionList).subscribe(result => {
      if (result === true) {
        this.tableEngine.loadData();
        this.currentAction = '';
        this.tableEngine.finalizeDeletion();
      }
      else {
        this.tableEngine.toastError(this.translate.instant("Error"), this.translate.instant("FailedDelete"));
        this.currentAction = '';
      }
    })
  }
  ////////////////////////////////////////////////////////////////////////////
  //
  //
  //
  //
  //
  ////////////////////////////////////////////////////////////////////////////
  //  EXCLUSIVE :
  //  Specifically for this component.
  ///////////////////////////////
  //Get any additional data here
  SearchFrom: Date = null;
  SearchTo: Date = null;

  clearSearch() {
    this.SearchFrom = null;
    this.SearchTo = null;
  }

  prevAction = '';
  addAndNew() {
    this.prevAction = 'AddThenNew';
    this.addObject();
    this.showModal(null, 'Add');
  }

  configPurchases = new ConfigPurchasesModel();
  loadServices() {
    this.currentAction = 'loading';

    this.supplierService.getAll().subscribe(
      supplierData => {
        this.supplierList = supplierData;

        this.codingUnitService.getAll().subscribe(
          codingUnitData => {
            this.codingUnitList = codingUnitData;

            this.itemService.getAll().subscribe(
              itemData => {
                this.itemList = itemData;

                //this.storeDataService.getAll().subscribe(
                  //storeData => {
                    //this.storeDataList = storeData;
                    this.purchaseInvoiceService.getAll().subscribe(
                      purchaseInvoiceData => {
                        this.purchaseInvoiceList = purchaseInvoiceData;

                        this.paymentMethodService.getAll().subscribe(
                          paymentMethodData => {
                            this.paymentMethodList = paymentMethodData;
    
                            this.configPurchasesService.getFirst().subscribe(
                              configPurchasesData => {
                                this.configPurchases = configPurchasesData;
            
                                this.tableEngine.loadData();
                              }
                            )
                          }
                        )
                      }
                    )
                 // }
              //  )
              }
            )
          }
        )
      }
    )


  }
  
  addedItemList: ReturnPurchaseItemModel[] = [];
  codingUnitList: CodingUnitModel[] = [];
  itemList: any[] = [];
  //storeDataList: StoreDataModel[] = [];
  supplierList: SupplierModel[] = [];
  purchaseInvoiceList: PurchaseInvoiceModel[] = [];
  paymentMethodList: PaymentMethodModel[] = [];

  getItemById(Id: any): any {
    return this.itemList.find(i => i.Id === Id);
  }
  getUnityById(Id: any): any {
    return this.codingUnitList.find(i => i.Id === Id);
  }
  // getStoreDataById(Id: any): any {
  //   return this.storeDataList.find(i => i.StoreId === Id);
  // }
  getSupplierById(Id: any): any {
    return this.supplierList.find(i => i.Id === Id);
  }
  getPurchaseInvoiceById(Id: any): any {
    return this.purchaseInvoiceList.find(i => i.Id === Id);
  }
  getPaymentMethodById(Id: any): any {
    return this.paymentMethodList.find(i => i.Id === Id);
  }

  removeReturnPurchaseItem(Id: any): any {

    let index = this.addedItemList.findIndex(x => x.Id === Id);
    this.addedItemList.splice(index, 1);

    this.calculateTotalItemValue();
  }

  currentBaseValues = 0;
  currentDiscounts = 0;
  currentAfterDiscounts = 0;
  currentTaxes = 0;
  currentTotal = 0;

  badValue = false;
  calculateTotalItemValue() {
    this.badValue = false;

    console.log("calculating");

    let basevalues = 0;
    let discounts = 0;
    let afterDiscounts = 0;
    let taxes = 0;
    let total = 0;
    this.addedItemList.forEach(item => {
      if(item.Quantity > item.MaxQuantity){
        item.Quantity = item.MaxQuantity;
      }
      
      item.TotalPrice = item.Quantity * item.UnitCost;
      basevalues += item.TotalPrice;
      
      item.NetPrice = item.TotalPrice - item.DiscountValue;
      discounts += item.DiscountValue;
      afterDiscounts += item.NetPrice;
      
      item.GrossPrice = item.NetPrice + item.TaxValue;
      taxes += item.TaxValue;

      total += item.GrossPrice;
      if(item.DiscountValue == null || item.DiscountValue < 0 
        || item.TaxValue == null || item.TaxValue < 0
        || item.Quantity == null || item.Quantity <= 0
        || item.GrossPrice < 0)
      {
        this.badValue = true;
      }

      //console.log(item.Quantity);
      //console.log(item.DiscountValue);
      //console.log(item.TaxValue);
      
    });
    this.currentBaseValues = basevalues;
    this.currentDiscounts = discounts;
    this.currentAfterDiscounts = afterDiscounts;
    this.currentTaxes = taxes;
    this.currentTotal = total;
    //console.log(this.currentTotal);

    return total;
  }
  goBack(){
    console.log("back");
    
    this.modelObject = {};
    //this.createForm(this.modelObject, this.formBuilder);
    this.addedItemList = [];
    this.currentTotal = 0;
    this.purchaseInvoiceHeader = {};
    this.currentAction = "";
    this.tableView = true;

    console.log(this.modelObject);
    console.log(this.lastCode);
  }
  purchaseInvoiceHeader: PurchaseInvoiceModel = new PurchaseInvoiceModel();
  changePurchaseInvoiceHeader(){
    this.addedItemList = [];

    this.purchaseInvoiceHeader = this.getPurchaseInvoiceById(this.mainForm.getRawValue().PurchaseInvoiceId?this.mainForm.getRawValue().PurchaseInvoiceId:this.modelObject.PurchaseInvoiceId);
    
    this.purchaseInvoiceService.getByIdWithItems(this.purchaseInvoiceHeader.Id).subscribe(
      detailedObject => { 
        this.purchaseInvoiceHeader = {...detailedObject};
        this.modelObject = JSON.parse(JSON.stringify(detailedObject));
        this.addedItemList = [];
        this.purchaseInvoiceHeader.Items.forEach(element => {
          element.MaxQuantity = element.Quantity;
          console.log(element.MaxQuantity);
          let tempItem = new ReturnPurchaseItemModel({
            Id: element.Id,
            ItemId:  element.ItemId,
            ItemNameAr:  element.ItemNameAr,
            ItemNameEn:  element.ItemNameEn,
            UnitId:  element.UnitId,
            UnitNameAr:  element.UnitNameAr,
            UnitNameEn:  element.UnitNameEn,
            Quantity:  element.Quantity,
            UnitCost:  element.UnitCost,
            MaxQuantity: element.Quantity,
            TaxValue : element.TaxValue,
            TaxId:element.TaxId
          })
          this.addedItemList.push(tempItem);
        });
        console.log(this.modelObject);
        console.log(this.addedItemList);
        this.calculateTotalItemValue();
      }
    );
  }

  

  //lastCode = 0;
  codeWasChecked = false;
  checkCode() : boolean{
    if(!(this.lastCode > 0)){
      this.codeWasChecked = false;
      console.log("bad code", this.lastCode);
      
      return this.codeWasChecked;
    }
    this.configPurchasesService.checkReturnPurchaseCode(this.lastCode).subscribe(
        result => {
          this.codeWasChecked = result;
        }
      );
      console.log("code availability:",this.codeWasChecked);
      
    return this.codeWasChecked;
  }
  ////////////////////////////////////////////////////////////////////////////
  //
  //
  //
  //
  //
  ////////////////////////////////////////////////////////////////////////////
  //  CONSTANT :
  //  Do not change.
  ///////////////////////////////

  prepChangeSort(sortKey) {
    this.SearchFrom = null;
    this.SearchTo = null;
    
    this.tableEngine.changeSort(this.tableEngine.getSort(sortKey));
    this.itemOrder = this.tableEngine.itemOrder;
  }

}
