import { DailyrestrictionsModel } from './../dailyrestrictions/DailyrestrictionsModel';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DailyrestrictionsService } from '../dailyrestrictions/DailyrestrictionsService';
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';
import { JournalFileModel } from '../dailyrestrictions/journal-file.model';
@Component({
    selector: 'view-daily-restriction',
    templateUrl: 'view-daily-restriction.component.html',
    styleUrls: ['view-daily-restriction.component.scss']
})
export class ViewDailyRestrictionComponent implements OnInit {
    declare lang: any;
    Id = 0;
    buttonName='';
    obj : DailyrestrictionsModel=new DailyrestrictionsModel();
    files:JournalFileModel[]=[];
    constructor( 
         private DailyrestrictionsService: DailyrestrictionsService,
        private route: ActivatedRoute,
        public router:Router
         ){
      this.lang = localStorage.getItem('theme_lang');
      this.Id = + route.snapshot.paramMap.get('Id');
        
    }
    ngOnInit(): void {
       this.DailyrestrictionsService.getById(this.Id)
       .subscribe(result => {
        this.obj = result;
        this.files = this.obj.Files;
       })
    }
    openForExport = false;
    exportTable(){
      debugger;
      this.openForExport = true;
      var data = document.getElementById('contentToConvert');
      data.style.display = 'inline-block';
      html2canvas(data,{}).then(canvas => {
      var imgWidth = 208;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save('new-file.pdf'); // Generated PDF
      data.style.display = 'block';

    //   data.style.display = 'none';
      
    });
    }
    print(){
        let printContents, popupWin;
        printContents = document.getElementById('contentToConvert').innerHTML;
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.open();
        window.print();
        // popupWin.document.write(`
        //   <html>
        //     <head>
        //       <style>
        //       display :inline-block;
        //       #table{
        //         width:100%
        //       }
        //       #customers {
        //         font-family: Arial, Helvetica, sans-serif;
        //         border-collapse: collapse;
        //         width: 100%;
        //       }
        //       </style>
        //     </head>
        // <body onload="window.print();window.close()">${printContents}</body>
        //   </html>`
        // );
        // popupWin.document.close();

    //     debugger;
    //     this.openForExport = true;
    //     var data = document.getElementById('contentToConvert');
    //     data.style.display = 'inline-block';
    //     html2canvas(data,{}).then(canvas => {
    //     var imgWidth = 208;
    //     var pageHeight = 295;
    //     var imgHeight = canvas.height * imgWidth / canvas.width;
    //     var heightLeft = imgHeight;
    //     const contentDataURL = canvas.toDataURL('image/png')
    //     let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
    //     var position = 0;
    //     pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
    //     pdf.printHeaderRow();
    //     // pdf.save('new-file.pdf'); 
    //     data.style.display = 'block';
  
    //   //   data.style.display = 'none';
        
    //   });
      }
}
