<!--component html goes here -->
<!--component html goes here -->
<app-list-page-header
  *ngIf="tableView"
  [itemOptionsPerPage]="[5, 10, 20]"
  [itemsPerPage]="tableEngine.itemsPerPage"
  [selectAllState]="tableEngine.selectAllState"
  (addNewItem)="showModal(Id, 'Add')"
  [tableEngine]="this.tableEngine"
  (selectAllChange)="tableEngine.selectAll($event)"
  (changeOrderBy)="prepChangeSort($event)"
  (searchKeyUp)="
    tableEngine.filterDataByDateRange(this.SearchFrom, this.SearchTo, 'Date')
  "
  [dateSearch]="false"
>
</app-list-page-header>
<!-- <div bsModal #smallModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">
  <div class="modal-dialog modal-sm">
      <div class="modal-content"
      style="width: 90%; text-align: center; margin-top: 20px; margin-left: auto; margin-right: auto;">
      <div style="align-content: center; display: inline-block;">
          <div class="modal-header" style="display: block; padding: 0px 0px 5px 0px; text-align: center; ">
              <h1 id="dialog-child-name" class="modal-title">
                  {{tableEngine.currentFormMode+translationName | translate}}</h1>
          </div>
          <div class="modal-body">
              <form class="md-float-material form-material" [formGroup]="mainForm">
                  <div class="form-group form-primary">
                      <label class="float-label push righ">{{"NameAr" | translate}}</label>
                      <input *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'" type="text" name="NameAr"
                          class="form-control" formControlName="NameAr"
                          placeholder='{{"NameAr" | translate}}'>
                      <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                          name="NameAr">{{modelObject.NameAr}}</label>
                      <div class="messages text-danger"
                          *ngIf=" (mainForm.get('NameAr').hasError('required') || mainForm.get('NameAr').invalid) && ( mainForm.get('NameAr').dirty ||  mainForm.get('NameAr').touched)">
                          {{"NameArRequired" | translate}}</div>
                      <span class="form-bar"></span>
                  </div>

                  <div class="form-group form-primary">
                      <label class="float-label push righ">{{"NameEn" | translate}}</label>
                      <input *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'" type="text" name="NameEn"
                          class="form-control" formControlName="NameEn"
                          placeholder='{{"NameEn" | translate}}'>
                      <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                          name="NameEn">{{modelObject.NameEn}}</label>
                      <div class="messages text-danger"
                          *ngIf=" (mainForm.get('NameEn').hasError('required') || mainForm.get('NameEn').invalid) && ( mainForm.get('NameEn').dirty ||  mainForm.get('NameEn').touched)">
                          {{"NameEnRequired" | translate}}</div>
                      <span class="form-bar"></span>
                  </div>
                  <div class="itemCheck mb-0 custom-checkbox custom-control"
                                                            style="display: inline-block;">
                                                            <input type="checkbox" class="custom-control-input"
                                                                [id]="'valueCheck'" 
                                                                formControlName="IsActive">
                                                            <label class="custom-control-label"
                                                                [for]="'valueCheck'">Active</label>
                  </div>
                
                  <div style="margin-left: 5px; text-align: center; margin-top: 30px;">
                      <button *ngIf="tableEngine.currentFormMode==='Add'"
                          [disabled]="mainForm.invalid || tableEngine.currentAction==='loading'" id="smallModalSubmitButton"
                          class="btn btn-secondary" (click)="addObject()"
                          style="font-weight: bold; width: 100%; text-align: center;">{{"Add" |
                          translate}}</button>
                      <button *ngIf="tableEngine.currentFormMode==='Edit'"
                          [disabled]="mainForm.invalid || tableEngine.currentAction==='loading'" id="smallModalSubmitButton"
                          class="btn btn-secondary" (click)="editObject()" 
                          style="font-weight: bold; width: 100%; text-align: center;">{{"Edit"
                          | translate}}</button>
                      <button class="btn btn-light" (click)="smallModal.hide()"
                          style="font-weight: bold; width: 100%; text-align: center; margin-top: 10px;">{{"Close" |
                          translate}}</button>
                  </div>
              </form>
          </div>
      </div>
  </div>
  </div>
</div> -->
<div bsModal #delModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">
  <div class="modal-dialog modal-sm">
      <div class="modal-content" style="border: 3px solid red;">
          <button type="button" class="close pull-right" aria-label="Close" (click)="delModal.hide()"
              style="margin-right: 20px; margin-left: 20px; margin-top: 10px; width: 20px; display: inline;">
              <span aria-hidden="true">&times;</span>
          </button>
          <div class="modal-header" style="display: inline; padding: 0px 0px 5px 0px;">
              <h4 id="dialog-child-name" class="modal-title pull-left" style="text-align: center; color: red;">
                  {{"ConfirmDel"+translationName |
                  translate}}</h4> 
          </div>
          <div class="modal-body">
              <div class="form-group form-primary" *ngIf="deletionList.length < ogData.length || deletionList.length === 1" style="text-align: center;">
                  <div style="text-align: center;">
                      <label *ngIf="deletionList.length > 10" type="text">{{deletionList.length}} {{"WarehouseRows" | translate}}</label>
                      <label *ngIf="deletionList.length > 2 && deletionList.length < 11" type="text">{{deletionList.length}} {{"WarehouseRowsPlur" | translate}}</label>
                      <label *ngIf="deletionList.length === 2" type="text">{{"TwoWarehouse" | translate}}</label>
                      <label *ngIf="deletionList.length === 1" type="text">{{"OneWarehouse" | translate}}</label>
                      <span class="form-bar"></span>
                  </div>
              </div>
              <div class="form-group form-primary" style="text-align: center;" *ngIf="deletionList.length > 1 && 
                  deletionList.length === ogData.length">
                  <label type="text">
                      <h1 style="font-weight: bolder; text-align: center;">{{translationName+"DeleteAll" | translate}}</h1>
                  </label>
                  <span class="form-bar"></span>
              </div>
              
              <div style="margin-left: 5px; text-align: center; margin-top: 30px;">
                  <button class="btn btn-danger" id="smallModalDeleteButton" (click)="deleteObject()"
                     
                      style="font-weight: bold; width: 100%; text-align: center;">{{"Delete" | translate}}</button>
                  <button class="btn btn-light" (click)="delModal.hide()"
                      style="font-weight: bold; width: 100%; text-align: center; margin-top: 10px;">{{"Close" |
                      translate}}</button>
              </div>
          </div>
      </div>
  </div>
</div>
<div *ngIf="tableView" class="FormScreen" class="tableScreen">
    
        <!-- <div >
            <button class="btn btn-primary d-inline-block"
                style="font-weight: bold; text-align: center; padding: 0px; width: 150px; height: 30px;"
                  routerLink="../Add-Price-List/0" >{{"Add"|
                translate}}</button>

        </div> -->
  <!-- <div id="divtop" style="display: block;height: 47px; width: 100%;">
        <div id="divsearchmiddle" style="display: inline-block; width: 70%;
        height: inherit;  text-align: center; padding: 0px; margin: 0px;">
            <div class="search" style="display: inline-block; margin-left: 50px; margin-right: 50px;">
                <div class="d-inline-block">
                    <div class="search-sm d-inline-block mr-1 mb-1 align-top" style="width: 600px;">
                        <input type="search" [(ngModel)]="SearchTerm" (keyup)="changeFilter()" (change)="changeFilter()"
                            class="form-control input-sm full-data-search" placeholder="{{translationName+'Search' | translate}}">
                    </div>
                </div>
            </div>
        </div>
    
        <div id="divsearchleft" style="display: inline-block; width: 30%; height: inherit;">
            <div style="display: block; height: 30%; ">
                &nbsp;
            </div>
            <div class="search" style="display: block; height: 70%; text-align: center;">
                <button class="btn btn-primary d-inline-block"
                    style="font-weight: bold; text-align: center; padding: 0px; width: 150px; height: 30px;"
                     routerLink="../AddItemBalance" >{{"Add"+translationName |
                    translate}}</button>
    
            </div>
        </div>
    
    </div> -->

  <div id="divmiddle" style="display: block; margin-top: 5px">
    <div style="width: 100%">
      <div>
        <div class="card" style="min-width: 900px !important">
          <div class="card-body">
            <table class="table table-hover" style="margin: unset !important">
              <thead class="no-border-top">
                <th style="text-align: center; padding: 5px">
                  <div class="text-zero" style="display: inline-block">
                    <div class="top-right-button-container2 btn-group" dropdown>
                      <div class="btn btn-primary pl-4 pr-0 check-button">
                        <label
                          class="custom-control custom-checkbox mb-0 d-inline-block"
                          style="width: 10px"
                        >
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            [checked]="tableEngine.selectAllState === 'checked'"
                            [indeterminate]="
                            tableEngine.selectAllState === 'indeterminate'
                            "
                            (change)="tableEngine.selectAll($event)"
                          />
                          <span class="custom-control-label">&nbsp;</span>
                        </label>
                      </div>
                      <button
                        id="button-split"
                        type="button"
                        dropdownToggle
                        style="width: 10px"
                        [ngClass]="
                          tableEngine.selected.length == 0
                            ? 'btn btn-outline-primary dropdown-toggle dropdown-toggle-split'
                            : 'btn btn-primary dropdown-toggle dropdown-toggle-split'
                        "
                        aria-controls="dropdown-split"
                      >
                        <span class="caret"></span>
                      </button>
                      <ul
                        id="dropdown-split"
                        *dropdownMenu
                        class="dropdown-menu dropdown-menu-right"
                        role="menu"
                        aria-labelledby="button-split"
                      >
                        <li role="menuitem">
                          <a class="dropdown-item">
                            <button
                              class="btn btn-danger d-inline-block"
                              style="
                                font-weight: bold;
                                text-align: center;
                                padding: 0px;
                                width: 150px;
                                height: 30px;
                              "
                              (click)="tableEngine.showDelModal('delMulti')"
                              [disabled]="
                                tableEngine.selected.length === 0 ||
                                tableEngine.currentAction === 'loading'
                              "
                            >
                              {{ "DeleteSelected" | translate }}
                            </button></a
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </th>

                <th 
                  class="sorticon"
                  (click)="prepChangeSort('Name')"
                  [ngClass]="
                    itemOrder.label === 'Name'
                      ? 'sortkey sorticon'
                      : 'sorticon'
                  "
                >
                  <div
                    [ngClass]="{
                      'iconsminds-up---down':
                        itemOrder.label != 'Name',
                      'iconsminds-down':
                        itemOrder.label === 'Name' &&
                        tableEngine.orderAsc === true,
                      'iconsminds-up':
                        itemOrder.label === 'Name' &&
                        tableEngine.orderAsc === false
                    }"
                  ></div>
                </th>
                <th 
                  class="sortOption"
                  (click)="prepChangeSort('Name')"
                  [ngClass]="{ sortkey: itemOrder.label === 'Name' }"
                >
                  {{ "Name" | translate }}
                </th>

               
              <!-- is active -->

                <th
                  class="sorticon"
                  (click)="prepChangeSort('FeaturedProduct')"
                  [ngClass]="
                    itemOrder.label === 'FeaturedProduct'
                      ? 'sortkey sorticon'
                      : 'sorticon'
                  "
                >
                  <div
                    [ngClass]="{
                      'iconsminds-up---down': itemOrder.label != 'FeaturedProduct',
                      'iconsminds-down':
                        itemOrder.label === 'FeaturedProduct' &&
                        tableEngine.orderAsc === true,
                      'iconsminds-up':
                        itemOrder.label === 'FeaturedProduct' &&
                        tableEngine.orderAsc === false
                    }"
                  ></div>
                </th>
                <th
                  class="sortOption"
                  (click)="prepChangeSort('FeaturedProduct')"
                  [ngClass]="{ sortkey: itemOrder.label === 'FeaturedProduct' }"
                >
                  {{ "FeaturedProduct" | translate }}
                </th>


                
                <th
                  [colSpan]="5"
                  style="
                    text-align: center;
                    padding: unset !important;
                    vertical-align: middle;
                  "
                >
                  <div
                    id="displayOptions"
                    style="
                      display: inline;
                      margin-top: 10px;
                      margin-left: 5px;
                      margin-right: 5px;
                    "
                  >
                    <span
                      class="text-muted text-small"
                      style="width: 50px; height: 40px"
                    >
                      {{ "ResultCount" | translate }}
                    </span>
                    <span dropdown class="d-inline-block position-relative">
                      <button
                        class="btn btn-outline-dark btn-xs dropdown-toggle"
                        type="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        dropdownToggle
                      >
                        {{ tableEngine.itemsPerPage }}
                      </button>
                      <div
                        class="dropdown-menu dropdown-menu-right dropdown-menu-sm text-right"
                        *dropdownMenu
                      >
                        <li
                          role="menuitem"
                          *ngFor="let item of tableEngine.itemOptionsPerPage"
                        >
                          <a
                            [ngClass]="
                              item === tableEngine.itemsPerPage
                                ? 'dropdown-item active'
                                : 'dropdown-item'
                            "
                            (click)="tableEngine.paginateData(item, 1)"
                            >{{ item }}</a
                          >
                        </li>
                      </div>
                    </span>
                  </div>
                </th>
               
              </thead>
              <tbody>
                <tr
                  *ngFor="let obj of tableEngine.paginatedData; let i = index"
                >
                  <td style="text-align: center; padding: 5px">
                    <div
                      class="itemCheck mb-0 custom-checkbox custom-control"
                      style="display: inline-block"
                    >
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        [id]="'customCheck' + i"
                        [checked]="tableEngine.isSelected(obj)"
                        (change)="tableEngine.onSelect(obj)"
                      />
                      <label
                        class="custom-control-label"
                        [for]="'customCheck' + i"
                      ></label>
                    </div>
                  </td>
                
                  <td colspan="2">
                    {{
                      lang === "ar-EG" ? obj.Name : obj.Name
                    }}
                  </td>
                  <!-- <td colspan="2">{{obj.Name}}</td> -->
                  <!-- <td colspan="2">{{obj.Quantity}}</td> -->
                  <td colspan="2">
                    <span class="badge badge-pill badge-success mb-1" *ngIf="obj.FeaturedProduct">{{'Featured' | translate}}</span>
                    <span class="badge badge-pill badge-info mb-1" *ngIf="!obj.FeaturedProduct"> {{'NotFeatured' | translate}}</span>
                  </td>
                  <td colspan="2">
                    <span class="badge badge-pill badge-success mb-1" *ngIf="obj.IsDisable">{{'Disable' | translate}}</span>
                    <span class="badge badge-pill badge-info mb-1" *ngIf="!obj.IsDisable"> {{'NotDisable' | translate}}</span>
                  </td>
              
                  <td style="text-align: center">
                    <i
                      class="simple-icon-info"
                      (click)="showModal(obj.Id, 'Info')"                      
                      tooltip="{{ 'Info' | translate }}"
                      placement="auto"
                      style="
                        font-size: 20px;
                        font-weight: bolder;
                        cursor: pointer;
                        vertical-align: middle;
                        color: #17a2b8;
                      "
                      
                     
                    ></i>
                  </td>
                  <td style="text-align: center">
                    <i
                      class="simple-icon-pencil"
                      (click)="showModal(obj.Id, 'Edit')"
                      tooltip="{{ 'Edit' | translate }}"
                      placement="auto"
                      style="
                        font-size: 20px;
                        font-weight: bolder;
                        cursor: pointer;
                        vertical-align: middle;
                        color: #ffc107;
                      "
                     
                     
                    ></i>
                  </td>
                  <td style="text-align: center">
                    <i
                      class="simple-icon-close"
                      (click)="tableEngine.showDelModal(obj.Id)"
                      tooltip="{{ 'Delete' | translate }}"
                      placement="auto"
                      style="
                        font-size: 20px;
                        font-weight: bolder;
                        cursor: pointer;
                        vertical-align: middle;
                        color: #dc3545;
                      "
                    ></i>
                  </td>
                  <!-- <td>   <button class="btn btn-outline-warning text-center" (click)="showInfoModalRef(obj.Id)"
                                            [disabled]="currentAction==='loading'"
                                            style="font-weight: bold; width: 100%;">{{"Edit"
                                            | translate}}</button></td>
                            <td><button class="btn btn-outline-danger text-center" (click)="showDelModal(obj.Id)"
                                    [disabled]="currentAction==='loading'" style="font-weight: bold; width: 100%">{{"Delete"
                                    | translate}}</button></td> -->
                </tr>
              </tbody>
            </table>
            <div
              id="divsearchmiddle"
              style="display: inline-block; width: 100%; height: 100%"
            >
              <div style="text-align: center">
                <!-- <div class="centerside" style="display: inline-block; width: 15%; height: 100%; ">
                            &nbsp;

                        </div> -->
                <div
                  class="centercenter"
                  style="
                    display: inline-block;
                    width: 70%;
                    height: 100%;
                    text-align: center;
                  "
                >
                  <bdo dir="ltr">
                    <pagination
                      class="pagination-sm2"
                      style="
                        padding: 0px, 0px, 0px, 0px;
                        margin: 0px, 0px, 0px, 0px;
                        display: inline-block;
                      "
                      [(ngModel)]="currentPage"
                      [boundaryLinks]="true"
                      [totalItems]="tableEngine.filteredData.length"
                      [itemsPerPage]="tableEngine.itemsPerPage"
                      [customFirstTemplate]="firstTemplate"
                      [customLastTemplate]="lastTemplate"
                      [customPreviousTemplate]="prevTemplate"
                      [customNextTemplate]="nextTemplate"
                      [maxSize]="5"
                      (pageChanged)="tableEngine.changePage($event)"
                    >
                    </pagination>
                  </bdo>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div></div>

<ng-template
  #nextTemplate
  let-disabled="disabled"
  let-currentPage="currentPage"
>
  <i *ngIf="lang === 'en-US'" class="simple-icon-arrow-right"></i>
  <i *ngIf="lang === 'ar-EG'" class="simple-icon-arrow-left"></i>
</ng-template>

<ng-template
  #prevTemplate
  let-disabled="disabled"
  let-currentPage="currentPage"
>
  <i *ngIf="lang === 'en-US'" class="simple-icon-arrow-left"></i>
  <i *ngIf="lang === 'ar-EG'" class="simple-icon-arrow-right"></i>
</ng-template>

<ng-template
  #lastTemplate
  let-disabled="disabled"
  let-currentPage="currentPage"
>
  <i class="simple-icon-control-end"></i>
</ng-template>

<ng-template
  #firstTemplate
  let-disabled="disabled"
  let-currentPage="currentPage"
>
  <i class="simple-icon-control-start"></i>
</ng-template>

<simple-notifications></simple-notifications>
