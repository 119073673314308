import { Component, OnInit } from '@angular/core';
import { BudgetReportService } from '../../budget-report.service';
import { CostCenterService } from '../../../../cost-center/services/cost-center.service';
import { CostCenterModel } from '../../../../cost-center/models/cost-center.model';
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';

@Component({
    selector: 'budget-report',
    templateUrl: 'budget-report.component.html',
    styleUrls: ['budget-report.component.scss']
})
export class BudgetReportComponent implements OnInit{

    declare lang: any;
    data:any[]=[];
    constructor(private BudgetReportService:BudgetReportService,

        private costCenterService:CostCenterService
        ){
      this.lang = localStorage.getItem('theme_lang');
    }
    TotalAssets = 0;
    Totalliabilities = 0;
    TotalPropertyRights = 0;
    today=new Date();
    mainCenters:CostCenterModel[]=[];
    subCenters:CostCenterModel[]=[];
    BackUpmainCenters:CostCenterModel[]=[];
    BackUpsubCenters:CostCenterModel[]=[];
    ngOnInit(): void {
      debugger;
        this.costCenterService.getAll(0)
        .subscribe(data=>{
          console.log('Centers',data)
          this.subCenters = data;
        //   data.forEach(element => {
        //       if(element.CenterType == "Main"){
        //           this.mainCenters.push(element);
        //       }
        //       else{
        //           this.subCenters.push(element);
        //       }
           
        //   });
          this.BackUpmainCenters = [...this.mainCenters];
          this.BackUpsubCenters = [...this.subCenters];
        })
    }
    costCenterId=0;
    Search(){
        this.BudgetReportService.Search(this.costCenterId)
        .subscribe(data => {
          this.data =data;
       console.log('this.data',this.data)
        })
    }
    exportTable(){
      var data = document.getElementById('contentToConvert');
      data.style.display = 'inline-block';
      html2canvas(data,{}).then(canvas => {
      var imgWidth = 208;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save('new-file.pdf'); // Generated PDF
      data.style.display = 'block';

    //   data.style.display = 'none';
      
    });
    }
    print(){
        let printContents, popupWin;
        printContents = document.getElementById('contentToConvert').innerHTML;
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.open();
        window.print();
       
      }
      MainCostCenterId = 0;
      changeMainCostCenter(){
          var i = this.mainCenters.findIndex(x=>x.Id == this.MainCostCenterId);
          if(i > -1){
              this.subCenters = this.BackUpsubCenters.filter(x=>x.ParentId == this.mainCenters[i].Id);
          }
      }
      changeSubCostCenter(){
          
      }
}
