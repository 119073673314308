import { Component, OnInit } from '@angular/core';
import { CashFlowsDataModel, CashFlowsDetailsModel } from './models/cash-flows.model';
import { CashFlowsSearchModel } from './models/cash-flows-search.model';
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';
@Component({
    selector: 'cash-flows',
    templateUrl: 'cash-flows.component.html',
    styleUrls: ['cash-flows.component.scss']
})
export class CashFlowsComponent implements OnInit{
    declare lang: any;
    data:CashFlowsDataModel;
    search:CashFlowsSearchModel={};
    constructor(){
      this.lang = localStorage.getItem('theme_lang');
    }
    TotalAssets = 0;
    Totalliabilities = 0;
    TotalPropertyRights = 0;
    today=new Date();
    totalCashFlowFromOperations=0
    totalCashFlowFromInvestments=0
    totalCashFlowFromFinancing=0;
    type="indirect";
    ngOnInit(): void {
        this.changeType()
    }
    Search(){
        
    }
    changeType(){
        this.totalCashFlowFromOperations = 0;
        this.totalCashFlowFromInvestments = 0;
        this.totalCashFlowFromFinancing = 0;
        if(this.type == "indirect"){
          
    ///////////////////
    this.data=new CashFlowsDataModel();
    var details = new CashFlowsDetailsModel();
    details.KeyAr = "صافي الدخل";
    details.KeyEn = "net income";
    details.Value = 70000;
    this.totalCashFlowFromOperations += details.Value;
    this.data.CashFlowFromOperations.push(details);

    var DepreciationExpense = new CashFlowsDetailsModel();
    DepreciationExpense.KeyAr = "مصروفات الإهلاك";
    DepreciationExpense.KeyEn = "Depreciation expense";
    DepreciationExpense.Value = 30000;
    this.totalCashFlowFromOperations += DepreciationExpense.Value;
    this.data.CashFlowFromOperations.push(DepreciationExpense);


    var IncreaseInAccountsPayable = new CashFlowsDetailsModel();
    IncreaseInAccountsPayable.KeyAr = "التغيرات في الحسابات الدائنة";
    IncreaseInAccountsPayable.KeyEn = "Changes in accounts payable";
    IncreaseInAccountsPayable.Value = 8000;
    this.totalCashFlowFromOperations += IncreaseInAccountsPayable.Value;
    this.data.CashFlowFromOperations.push(IncreaseInAccountsPayable);

    var IncreaseInCustomerAccountsReceivable = new CashFlowsDetailsModel();
    IncreaseInCustomerAccountsReceivable.KeyAr = "التغيرات في الحسابات المدينة";
    IncreaseInCustomerAccountsReceivable.KeyEn = "Changes in accounts receivable";
    IncreaseInCustomerAccountsReceivable.Value = 6000;
    this.totalCashFlowFromOperations += IncreaseInCustomerAccountsReceivable.Value;
    this.data.CashFlowFromOperations.push(IncreaseInCustomerAccountsReceivable);

  

    var IncreaseInInventory = new CashFlowsDetailsModel();
    IncreaseInInventory.KeyAr = "التغيرات في المخزون ";
    IncreaseInInventory.KeyEn = "Changes in inventory";
    IncreaseInInventory.Value = -5500;
    this.totalCashFlowFromOperations += IncreaseInInventory.Value;
    this.data.CashFlowFromOperations.push(IncreaseInInventory);

    var IncreaseInInventory = new CashFlowsDetailsModel();
    IncreaseInInventory.KeyAr = "التغيرات في المصروفات المستحقة";
    IncreaseInInventory.KeyEn = "Changes in accrued expenses";
    IncreaseInInventory.Value = -17000;
    this.totalCashFlowFromOperations += IncreaseInInventory.Value;
    this.data.CashFlowFromOperations.push(IncreaseInInventory);

    var IncreaseInInventory = new CashFlowsDetailsModel();
    IncreaseInInventory.KeyAr = "التغيرات في النقدية المدفوعة لضريبة الدخل";
    IncreaseInInventory.KeyEn = "Changes in cash paid for income tax";
    IncreaseInInventory.Value = -7000;
    this.totalCashFlowFromOperations += IncreaseInInventory.Value;
    this.data.CashFlowFromOperations.push(IncreaseInInventory);

    var IncreaseInInventory = new CashFlowsDetailsModel();
    IncreaseInInventory.KeyAr = "التغيرات في النقدية المدفوعة لمصروفات الفوائد ";
    IncreaseInInventory.KeyEn = "Changes in cash paid for interest expense";
    IncreaseInInventory.Value = -18000;
    this.totalCashFlowFromOperations += IncreaseInInventory.Value;
    this.data.CashFlowFromOperations.push(IncreaseInInventory);


    //////////////////////////////////////////////////////
            var CashReceivedFromTheSaleOfAssets = new CashFlowsDetailsModel();
            CashReceivedFromTheSaleOfAssets.KeyAr = "المتحصلات النقدية من بيع استثمارات طويلة الأجل";
            CashReceivedFromTheSaleOfAssets.KeyEn = "Cash proceeds from the sale of long-term investments";
            CashReceivedFromTheSaleOfAssets.Value = 50000;
            this.totalCashFlowFromInvestments += CashReceivedFromTheSaleOfAssets.Value;
            this.data.CashFlowFromInvestments.push(CashReceivedFromTheSaleOfAssets);
    
            
            var CashSpentToPurchaseAssets = new CashFlowsDetailsModel();
            CashSpentToPurchaseAssets.KeyAr = "مصاريف شراء آلات ومعدات وأصول ثابتة أخرى";
            CashSpentToPurchaseAssets.KeyEn = "Expenses for purchasing machinery, equipment and other fixed assets";
            CashSpentToPurchaseAssets.Value = 20000;
            this.totalCashFlowFromInvestments += CashSpentToPurchaseAssets.Value;
            this.data.CashFlowFromInvestments.push(CashSpentToPurchaseAssets);
    //////////////////////////////////////////////////////
            var IncreaseInCommonStock = new CashFlowsDetailsModel();
            IncreaseInCommonStock.KeyAr = "الزيادة في القروض طويلة الأجل رأس المال";
            IncreaseInCommonStock.KeyEn = "Increase in long-term loan capital";
            IncreaseInCommonStock.Value = 40000;
            this.totalCashFlowFromFinancing += IncreaseInCommonStock.Value;
            this.data.CashFlowFromFinancing.push(IncreaseInCommonStock);
    
    
    
            var IncreaseInCommonStock = new CashFlowsDetailsModel();
            IncreaseInCommonStock.KeyAr = "سداد أقساط قروض وسندات";
            IncreaseInCommonStock.KeyEn = "Payment of installments of loans and bonds";
            IncreaseInCommonStock.Value = -12000;
            this.totalCashFlowFromFinancing += IncreaseInCommonStock.Value;
            this.data.CashFlowFromFinancing.push(IncreaseInCommonStock);
    
            var IncreaseInCommonStock = new CashFlowsDetailsModel();
            IncreaseInCommonStock.KeyAr = "توزيعات أرباح";
            IncreaseInCommonStock.KeyEn = "Dividend";
            IncreaseInCommonStock.Value = -9500;
            this.totalCashFlowFromFinancing += IncreaseInCommonStock.Value;
            this.data.CashFlowFromFinancing.push(IncreaseInCommonStock);
        }
        else{
            this.data=new CashFlowsDataModel();
            var details = new CashFlowsDetailsModel();
            details.KeyAr = "النقدية الواردة من العملاء ";
            details.KeyEn = "Cash received from customers";
            details.Value = 113200;
            this.totalCashFlowFromOperations += details.Value;
            this.data.CashFlowFromOperations.push(details);
    
            var DepreciationExpense = new CashFlowsDetailsModel();
            DepreciationExpense.KeyAr = "النقدية المدفوعة للموردين";
            DepreciationExpense.KeyEn = "Cash paid to suppliers";
            DepreciationExpense.Value = -29000 ;
            this.totalCashFlowFromOperations += DepreciationExpense.Value;
            this.data.CashFlowFromOperations.push(DepreciationExpense);
    
    
            var IncreaseInAccountsPayable = new CashFlowsDetailsModel();
            IncreaseInAccountsPayable.KeyAr = "النقدية المدفوعة للمصروفات التشغيلية";
            IncreaseInAccountsPayable.KeyEn = "Cash paid for operating expenses";
            IncreaseInAccountsPayable.Value = -7500;
            this.totalCashFlowFromOperations += IncreaseInAccountsPayable.Value;
            this.data.CashFlowFromOperations.push(IncreaseInAccountsPayable);
    
            var IncreaseInCustomerAccountsReceivable = new CashFlowsDetailsModel();
            IncreaseInCustomerAccountsReceivable.KeyAr = "النقدية المدفوعة لضريبة الدخل";
            IncreaseInCustomerAccountsReceivable.KeyEn = "Cash paid for income tax";
            IncreaseInCustomerAccountsReceivable.Value = -4000;
            this.totalCashFlowFromOperations += IncreaseInCustomerAccountsReceivable.Value;
            this.data.CashFlowFromOperations.push(IncreaseInCustomerAccountsReceivable);
    
          
    
            var IncreaseInInventory = new CashFlowsDetailsModel();
            IncreaseInInventory.KeyAr = "التغيرات في النقدية المدفوعة لمصروفات الفوائد ";
            IncreaseInInventory.KeyEn = "Changes in cash paid for interest expense";
            IncreaseInInventory.Value = -6200;
            this.totalCashFlowFromOperations += IncreaseInInventory.Value;
            this.data.CashFlowFromOperations.push(IncreaseInInventory);  
    //////////////////////////////////////////////////////
            var CashReceivedFromTheSaleOfAssets = new CashFlowsDetailsModel();
            CashReceivedFromTheSaleOfAssets.KeyAr = "المتحصلات النقدية من بيع استثمارات طويلة الأجل";
            CashReceivedFromTheSaleOfAssets.KeyEn = "Cash proceeds from the sale of long-term investments";
            CashReceivedFromTheSaleOfAssets.Value = 50000;
            this.totalCashFlowFromInvestments += CashReceivedFromTheSaleOfAssets.Value;
            this.data.CashFlowFromInvestments.push(CashReceivedFromTheSaleOfAssets);
    
            
            var CashSpentToPurchaseAssets = new CashFlowsDetailsModel();
            CashSpentToPurchaseAssets.KeyAr = "مصاريف شراء آلات ومعدات وأصول ثابتة أخرى";
            CashSpentToPurchaseAssets.KeyEn = "Expenses for purchasing machinery, equipment and other fixed assets";
            CashSpentToPurchaseAssets.Value = 20000;
            this.totalCashFlowFromInvestments += CashSpentToPurchaseAssets.Value;
            this.data.CashFlowFromInvestments.push(CashSpentToPurchaseAssets);
    //////////////////////////////////////////////////////
            var IncreaseInCommonStock = new CashFlowsDetailsModel();
            IncreaseInCommonStock.KeyAr = "الزيادة في القروض طويلة الأجل رأس المال";
            IncreaseInCommonStock.KeyEn = "Increase in long-term loan capital";
            IncreaseInCommonStock.Value = 40000;
            this.totalCashFlowFromFinancing += IncreaseInCommonStock.Value;
            this.data.CashFlowFromFinancing.push(IncreaseInCommonStock);
    
    
    
            var IncreaseInCommonStock = new CashFlowsDetailsModel();
            IncreaseInCommonStock.KeyAr = "سداد أقساط قروض وسندات";
            IncreaseInCommonStock.KeyEn = "Payment of installments of loans and bonds";
            IncreaseInCommonStock.Value = -12000;
            this.totalCashFlowFromFinancing += IncreaseInCommonStock.Value;
            this.data.CashFlowFromFinancing.push(IncreaseInCommonStock);
    
            var IncreaseInCommonStock = new CashFlowsDetailsModel();
            IncreaseInCommonStock.KeyAr = "توزيعات أرباح";
            IncreaseInCommonStock.KeyEn = "Dividend";
            IncreaseInCommonStock.Value = -9500;
            this.totalCashFlowFromFinancing += IncreaseInCommonStock.Value;
            this.data.CashFlowFromFinancing.push(IncreaseInCommonStock);
        }
    }
    exportTable(){
        var data = document.getElementById('contentToConvert');
        data.style.display = 'inline-block';
        html2canvas(data,{}).then(canvas => {
        var imgWidth = 208;
        var pageHeight = 295;
        var imgHeight = canvas.height * imgWidth / canvas.width;
        var heightLeft = imgHeight;
        const contentDataURL = canvas.toDataURL('image/png')
        let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
        var position = 0;
        pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
        pdf.save('new-file.pdf'); // Generated PDF
        data.style.display = 'block';
  
      //   data.style.display = 'none';
        
      });
      }
      print(){
          let printContents, popupWin;
          printContents = document.getElementById('contentToConvert').innerHTML;
          popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
          popupWin.document.open();
          window.print();
         
        }
}
