

export class BoardsOfDirectorsModel{
    Id?: number;
    ArabicName                       ?: string;
    EnglishName                      ?: string;
    Nots                          ?: string;
    MemberId                          ?: number;
    File                          ?: string;
    
    IsBlocked                        ?: boolean;
    UserId                           ?: string;
    CreateBy                         ?: string;
    CreateDate                       ?: Date|null;
    ModifiedBy                       ?: string;
    ModifiedDate                     ?: Date|null;
    IsDeleted                        ?: boolean;
    DeletedBy                        ?: string;
    DeletedDate                      ?: Date|null;
    constructor(item ?: BoardsOfDirectorsModel){
        item = item ? item : {};
        this.Id = item.Id ? item.Id : 0;
      
       
        this.ArabicName                        = item.ArabicName                        ? item.ArabicName                        : '';
        this.EnglishName                       = item.EnglishName                       ? item.EnglishName                       : '';
        this.Nots                       = item.Nots                       ? item.Nots                       : '';
        this.File                       = item.File                       ? item.File                       : '';
        this.MemberId                             = item.MemberId                             ? item.MemberId                             : 0;
        this.IsBlocked                         = item.IsBlocked                         ? item.IsBlocked                         : false;
        this.UserId                            = item.UserId                            ? item.UserId                            : '';
        this.CreateBy                          = item.CreateBy                          ? item.CreateBy                          : '';
        this.CreateDate                        = item.CreateDate                        ? item.CreateDate                        : null;
        this.ModifiedBy                        = item.ModifiedBy                        ? item.ModifiedBy                        : '';
        this.ModifiedDate                      = item.ModifiedDate                      ? item.ModifiedDate                      : null;
        this.IsDeleted                         = item.IsDeleted                         ? item.IsDeleted                         : false;
        this.DeletedBy                         = item.DeletedBy                         ? item.DeletedBy                         : '';
        this.DeletedDate                       = item.DeletedDate                       ? item.DeletedDate                       : null;
    }
}