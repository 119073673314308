import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ReturnPurchaseModel } from "./ReturnPurchaseModel";

@Injectable({
  providedIn: 'root'
})
export class ReturnPurchaseService {
  constructor(private http: HttpClient) {

  }

  debugData: ReturnPurchaseModel [] = [];
  // debugData: ReturnPurchaseModel [] = [
  //   {
  //     Id:1, InvoicePurchasesHeaderId:1, SuppliersDataId:1, Code:1, CompanyDataId:0, BranchesDataId:0, BarcodeInvoice: "", PaymentTypeId:1,
  //     Year:2021, Month:1, Date:new Date(2021, 0, 13), Description:"desc1\ntest1\n1", TotalItemsValue:5, TotalItemsDiscounts:1, TotalItemsAfterDisc:1,
  //     TotalItemsTaxs: 1, NetItemsValue: 1,
  //     IsPosted:false,
  //     Footers: [
  //       {Id:1, ReturnPurchasesHeaderId:1, ItemsHeaderId:1, ItemsUnitId:1, Quantity:1, UnitPrice:1, ItemValue:1, DiscountTypeId:1, 
  //         DiscountValue: 1, ItemValAfterDiscount: 1, TaxableTypeId: 1, TaxValue: 1, ItemNetValue:1},
  //       {Id:2, ReturnPurchasesHeaderId:1, ItemsHeaderId:2, ItemsUnitId:2, Quantity:2, UnitPrice:2, ItemValue:2, DiscountTypeId:2, 
  //         DiscountValue: 2, ItemValAfterDiscount: 2, TaxableTypeId: 2, TaxValue: 2, ItemNetValue:2},
  //     ]
  //   },
  //   {
  //     Id:2, InvoicePurchasesHeaderId:2, SuppliersDataId:2, Code:2, CompanyDataId:0, BranchesDataId:0, BarcodeInvoice: "", PaymentTypeId:2,
  //     Year:2022, Month:2, Date:new Date(2022, 1, 13), Description:"desc2\ntest2\n2", TotalItemsValue:5, TotalItemsDiscounts:2, TotalItemsAfterDisc:2,
  //     TotalItemsTaxs: 2, NetItemsValue: 2,
  //     IsPosted:true,
  //     Footers: [
  //       {Id: 3, ReturnPurchasesHeaderId: 2, ItemsHeaderId: 2, ItemsUnitId: 2, Quantity: 3, UnitPrice: 3, ItemValue: 3, DiscountTypeId: 3, 
  //         DiscountValue: 3, ItemValAfterDiscount: 3, TaxableTypeId: 3, TaxValue: 3, ItemNetValue: 3},
  //       {Id: 4, ReturnPurchasesHeaderId: 2, ItemsHeaderId: 1, ItemsUnitId: 1, Quantity: 4, UnitPrice: 4, ItemValue: 4, DiscountTypeId: 4, 
  //         DiscountValue: 4, ItemValAfterDiscount: 4, TaxableTypeId: 4, TaxValue: 4, ItemNetValue: 4},
  //     ]
  //   },
  // ];
  debugMode: boolean = false;

  baseUrl = environment.baseApiUrl;
  // baseUrl = 'http://localhost:60289/';
   token = localStorage.getItem("token");
   httpOptions = {
    headers: new HttpHeaders({
      // 'Content-Type': 'application/json; charset=utf-8',
      // 'Authorization': "Bearer " + token,
       "Authorization": "Bearer " + this.token,
       "Content-Type": "application/json"
    })
  };
  getAll(): Observable<any> {
    if(this.debugMode){
      return of(this.debugData)
    }
    return this.http
      .get<any>(`${this.baseUrl}ReturnPurchase/GetAll`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error => error)));
  }
  getAllWithItems(): Observable<any> {
    if(this.debugMode){
      return of(this.debugData)
    }
    return this.http
      .get<any>(`${this.baseUrl}ReturnPurchase/GetAllWithItems`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error => error)));
  }
  getById(id:number): Observable<any> {
    if(this.debugMode){
      return of(this.debugData.find(x=>x.Id==id))
    }
    return this.http
      .get<any>(`${this.baseUrl}ReturnPurchase/GetById/${id}`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error => error)));
  }
  getByIdWithItems(id:number): Observable<any> {
    if(this.debugMode){
      return of(this.debugData.find(x=>x.Id==id))
    }
    return this.http
      .get<any>(`${this.baseUrl}ReturnPurchase/GetByIdWithItems/${id}`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error => error)));
  }
  insert(payload: ReturnPurchaseModel): Observable<any> {
    if(this.debugMode){
      this.debugData.push(payload);
      return of(true)
    }
    return this.http
      .post<any>(`${this.baseUrl}ReturnPurchase/Insert`, payload,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error => error)));
  }
  insertWithItems(payload: ReturnPurchaseModel): Observable<any> {
    if(this.debugMode){
      this.debugData.push(payload);
      return of(true)
    }
    return this.http
      .post<any>(`${this.baseUrl}ReturnPurchase/InsertWithItems`, payload,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error => error)));
  }

  update(payload: ReturnPurchaseModel): Observable<any> {
    if(this.debugMode){
      this.debugData[this.debugData.findIndex(x=>x.Id==payload.Id)] = payload;
      return of(true)
    }
    return this.http
      .post<any>(`${this.baseUrl}ReturnPurchase/Update`, payload,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error => error)));
  }
  updateWithItems(payload: ReturnPurchaseModel): Observable<any> {
    if(this.debugMode){
      this.debugData[this.debugData.findIndex(x=>x.Id==payload.Id)] = payload;
      return of(true)
    }
    return this.http
      .post<any>(`${this.baseUrl}ReturnPurchase/UpdateWithItems`, payload,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error => error)));
  }

  delete(payload: ReturnPurchaseModel): Observable<any> {
    if(this.debugMode){
      this.debugData.splice(this.debugData.findIndex(x=>x.Id==payload.Id),1);
      return of(true)
    }
    return this.http
      .get<any>(`${this.baseUrl}ReturnPurchase/Delete/${payload.Id}`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error => error)));
  }

  deleteList(payload: ReturnPurchaseModel []): Observable<any> {
    if(this.debugMode){
      payload.forEach(element => {
        this.debugData.splice(this.debugData.findIndex(x=>x.Id==element.Id),1);
      });
      return of(true)
    }
    return this.http
      .post<any>(`${this.baseUrl}ReturnPurchase/DeleteList`,JSON.stringify(payload),this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error => error)));
  }

}
