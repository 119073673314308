<table class="table">
    <thead>
            <tr>
                <th style="width: 25%;">{{'Cost Center'|translate}}</th>
                <th style="width: 25%;">{{'Percentage'|translate}}{{' '|translate}}%</th>
                <th style="width: 25%;">{{'Auto'|translate}}</th>
                <th style="width: 25%;">{{'Delete'|translate}}</th>
            </tr>
    </thead>
    <tbody>
        <tr *ngFor="let item of selectedCenters" >
            <td style="width: 25%;" *ngIf="!item.IsDeleted">
                <ng-select  *ngIf="lang==='ar-EG'"  [(ngModel)]="item.CostCenterId" bindValue="Id"[items]="centers" (change)="splitCostCenter($event)"  [hideSelected]="true"  bindLabel="NameAr">
                </ng-select>
                <ng-select *ngIf="lang!=='ar-EG'"  [(ngModel)]="item.CostCenterId" bindValue="Id"[items]="centers" (change)="splitCostCenter($event)"  [hideSelected]="true"  bindLabel="NameEn">
              </ng-select>
            </td>
            <td style="width: 25%;" *ngIf="!item.IsDeleted">
                
                <input  min="0" max="100" (change)="chickValue(item)" [(ngModel)]="item.Percentage" type="number" class="form-control"></td>

            <td style="width: 25%;" *ngIf="!item.IsDeleted">
                <label class="custom-control custom-checkbox mb-0 d-inline-block"
                style="width: 10px;">
                <input type="checkbox" [(ngModel)]="item.Auto" class="custom-control-input"
                    [checked]="item.Auto">
                <span class="custom-control-label">&nbsp;</span>
            </label>
            </td>
            <td style="width: 25%;" *ngIf="!item.IsDeleted">
                <a href="javascript:" style="margin-top: 10px;" (click)="deleteSelectedCost(item)"><i  style="width: 10px;" class="simple-icon-trash"></i></a>
            </td>
        </tr>
    </tbody>
</table>
<!-- <div class="row">
    <div class="col-12 mb-4 data-table-rows">
      <ngx-datatable [rows]="rows" [columns]="columns" >
  
        <ngx-datatable-column name="Cost Center" [flexGrow]="2">
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            <ng-select  *ngIf="lang==='ar-EG'"  [(ngModel)]="row.CostCenterId" bindValue="Id"[items]="centers" (change)="splitCostCenter($event)"  [hideSelected]="true"  bindLabel="NameAr">
            </ng-select>
            <ng-select *ngIf="lang!=='ar-EG'"  [(ngModel)]="row.CostCenterId" bindValue="Id"[items]="centers" (change)="splitCostCenter($event)"  [hideSelected]="true"  bindLabel="NameEn">
          </ng-select>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Percentage" [flexGrow]="2">
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            <input [(ngModel)]="row.Percentage" type="number" class="form-control">
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Auto" [flexGrow]="2">
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            <label class="custom-control custom-checkbox mb-0 d-inline-block"
            style="width: 10px;">
            <input type="checkbox" [(ngModel)]="row.Auto" class="custom-control-input"
                [checked]="row.Auto">
            <span class="custom-control-label">&nbsp;</span>
        </label>
          </ng-template>
        </ngx-datatable-column>

      </ngx-datatable>
    </div>
  </div> -->

<div class="col-12 row">
    <div class=" col-md-11">
    </div>
<button type="button" class="btn btn-primary"(click)="AddNewCost()">{{ '+ Add' | translate }}</button>{{' '}}

  </div>