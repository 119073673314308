import { ContractorWorkItemModel } from './contractor-work-item.model';
export class ContractorModel{
    
    Id?: number;
    Code              ?: string;
    NameAr            ?: string;
    NameEn            ?: string;
    IdNumber          ?: string;
    TaxCard           ?: string;
    CommercialRegister?: string;
    Nationality       ?: string;
    Phone             ?: string;
    BankAccount       ?: string;
    Description       ?: string;
    CountryId         ?: number;
    CountryAr         ?: string;
    CountryEn         ?: string;
    CityId            ?: number;
    CityAr            ?: string;
    CityEn            ?: string;
    Governorate       ?: string;
    Street            ?: string;
    Landmark          ?: string;
    BuildingNumber    ?: string;
    Floor             ?: string;
    Room              ?: string;
    ZipCode           ?: string;
    ContractorWorkItems?:ContractorWorkItemModel [];
    SelectedContractorWorkItems?:ContractorWorkItemModel [];
    constructor(item ?: ContractorModel){
        item = item ? item : {};
        this.Id = item.Id ? item.Id : 0;
        this.Code               = item.Code               ? item.Code               : '';
        this.NameAr             = item.NameAr             ? item.NameAr             : '';
        this.NameEn             = item.NameEn             ? item.NameEn             : '';
        this.IdNumber           = item.IdNumber           ? item.IdNumber           : '';
        this.TaxCard            = item.TaxCard            ? item.TaxCard            : '';
        this.CommercialRegister = item.CommercialRegister ? item.CommercialRegister : '';
        this.Nationality        = item.Nationality        ? item.Nationality        : '';
        this.Phone              = item.Phone              ? item.Phone              : '';
        this.BankAccount        = item.BankAccount        ? item.BankAccount        : '';
        this.Description        = item.Description        ? item.Description        : '';
        this.CountryId          = item.CountryId          ? item.CountryId          : 0;
        this.CountryAr          = item.CountryAr          ? item.CountryAr          : '';
        this.CountryEn          = item.CountryEn          ? item.CountryEn          : '';
        this.CityId             = item.CityId             ? item.CityId             : 0;
        this.CityAr             = item.CityAr             ? item.CityAr             : '';
        this.CityEn             = item.CityEn             ? item.CityEn             : '';
        this.Governorate        = item.Governorate        ? item.Governorate        : '';
        this.Street             = item.Street             ? item.Street             : '';
        this.Landmark           = item.Landmark           ? item.Landmark           : '';
        this.BuildingNumber     = item.BuildingNumber     ? item.BuildingNumber     : '';
        this.Floor              = item.Floor              ? item.Floor              : '';
        this.Room               = item.Room               ? item.Room               : '';
        this.ZipCode            = item.ZipCode            ? item.ZipCode            : '';
        this.ContractorWorkItems = item.ContractorWorkItems ? item.ContractorWorkItems:[];
        this.SelectedContractorWorkItems = item.SelectedContractorWorkItems ? item.SelectedContractorWorkItems:[];
        
    }
}