import { Component, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { ListPageHeaderComponent } from 'src/app/containers/pages/list-page-header/list-page-header.component';
import { MonthlyDistributionModel } from '../../models/monthly-distribution.model';
import { SidePopupComponent } from 'src/app/views/invoice/side-popup/side-popup.component';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { TableEngine } from 'src/app/views/app/aio/shared/TableEngine';
import { MonthlyDistributionService } from '../../monthly-distribution.service';

@Component({
    selector: 'all-monthly-distribution',
    templateUrl: 'all-monthly-distribution.component.html',
    styleUrls: ['all-monthly-distribution.component.scss']
})
export class AllMonthlyDistributionComponent {
    debugMode: boolean = false;
  
    constructor(private formBuilder: FormBuilder, private modelService: MonthlyDistributionService, private renderer: Renderer2,
      private notifications: NotificationsService, private translate: TranslateService,
       private datePipe: DatePipe,
       private router : Router
    ) {
    }
    listHeader = new ListPageHeaderComponent();
    @ViewChild('search') search: any;
    tableEngine = new TableEngine(this.translate, this.notifications);
    currentScreen: any = 'Table';
    tableView: any = true;
  
    translationName: string = 'MonthlyDistribution'
  
    sortableKeys = [];
    searchableKeys = ['Id'];
  
    modelObject: MonthlyDistributionModel = new MonthlyDistributionModel();
  
    ogData: MonthlyDistributionModel[] = [];
    mainForm: FormGroup = new FormGroup({
    });
  
    declare lang: any;
    declare itemOrder: any;
    currentAction = '';
    savedPage = 0;
    currentPage = 1;
    deletionList: any[] = [];
    branches:any[]=[];
    employees:any[]=[];
    roles:any[]=[];
    @ViewChild('sideModalRef', { static: true }) sideModalRef: SidePopupComponent = null;
    @ViewChild('delModal', { static: true }) delModal: ModalDirective=null;
    @ViewChild('smallModal', { static: true }) smallModal: ModalDirective =null;
    formPresentationModal: any;
    ngOnInit(): void {
   
      if (this.debugMode) {
        this.enableDebugOnAllServices();
      }
  
      this.lang = localStorage.getItem('theme_lang');
      this.itemOrder = { label: 'Id', property: 'Id' };
      this.sortableKeys = [
        { label: 'Id', property: 'Id' },
        { label: 'Name', property: 'Name' },
   
      ];
      this.tableEngine.loadTableEngine(this.ogData, this.modelService, this.modelObject,
        this.searchableKeys, this.sortableKeys, this.mainForm, this.translationName, this.itemOrder,
        this.sideModalRef, this.delModal, this.smallModal, this.lang
      )
  
      this.loadServices();
    }
  
  
    enableDebugOnAllServices() {
      //this.modelService.debugMode = true;
      // this.itemService.debugMode = true;
      //this.codingUnitService.debugMode = true;
      //this.storeDataService.debugMode = true;
      //this.supplierService.debugMode = true;
    }
  
    lastCode = 0;
    showModal(Id, action) {
      debugger;
      if (action === 'Add') {
        this.router.navigate(['app/accounts/create-monthly-distribution/0'])

      }
      else if (action === 'Edit') {
        this.router.navigate(['app/accounts/create-monthly-distribution',Id])
      }
      else if (action === 'Info') {
        this.router.navigate(['app/accounts/create-monthly-distribution',Id])
      }
      this.tableView = false;
      this.modelObject = this.tableEngine.modelObject;
      this.mainForm = this.tableEngine.mainForm;
      console.log((action + " object"), this.modelObject);
  
  
      console.log("modelObject after entering edit", this.modelObject);
    }

    currentDate='';
    
    //Deletion methods:
    //Customize if needed.
    //Pass the target object as parameter if the target API can only delete singular objects and not lists.
    deleteObject() {
      this.savedPage = this.currentPage;
      //prevent multiclicks. re enable after API response
      this.currentAction = 'loading';
      this.executeDeleteObject();
    }
  
    //Same here...
    executeDeleteObject() {
      this.modelService.deleteList(this.tableEngine.deletionList).subscribe(result => {
        if (result === true || result > 0) {
          this.tableEngine.loadData();
          this.currentAction = '';
          this.tableEngine.finalizeDeletion();
        }
        else {
          this.tableEngine.toastError(this.translate.instant("Error"), this.translate.instant("FailedDelete"));
          this.currentAction = '';
        }
      })
    }
  
    
    //lastCode = 0;
    codeWasChecked = false;
    checkCode() : boolean{
      if(!(this.lastCode > 0)){
        this.codeWasChecked = false;
        console.log("bad code", this.lastCode);
        
        return this.codeWasChecked;
      }
      return this.codeWasChecked;
    }
    ////////////////////////////////////////////////////////////////////////////
    //
    //
    //
    //
    //
    ////////////////////////////////////////////////////////////////////////////
    //  EXCLUSIVE :
    //  Specifically for this component.
    ///////////////////////////////
    //Get any additional data here
    SearchFrom: Date = null ;
    SearchTo: Date= null;
  
    clearSearch() {
      this.SearchFrom= null ;
      this.SearchTo = null;
    }
  
    prevAction = '';
  
  
  
    loadServices() {
      this.currentAction = 'loading';
      this.tableEngine.loadData();

  
  
    }
  
    newMonthlyDistributionItemInternalId = 0;
  
    currentTotal = 0;
 
    ////////////////////////////////////////////////////////////////////////////
    //
    //
    //
    //
    //
    ////////////////////////////////////////////////////////////////////////////
    //  CONSTANT :
    //  Do not change.
    ///////////////////////////////
    prepChangeSort(sortKey) {
      this.SearchFrom ;
      this.SearchTo ;
      
      
      this.tableEngine.changeSort(this.tableEngine.getSort(sortKey));
      this.itemOrder = this.tableEngine.itemOrder;
    }
  
    getFormattedDate(dateString: any){
      let splitDate: number[] = dateString.toString().split("/");
  
      return new Date(splitDate[2], splitDate[1]-1, splitDate[0])
    }
    ///////////////////////////////////////////////////////
    
    displayMode = 'list';
    changeDisplayMode(mode): void {
      this.displayMode = mode;
    }
    itemsPerPageChange(perPage: number): void {
      this.tableEngine.paginateData(perPage, 1)
    }
    selectedCurrency = '';
  
    SearchKey = ''
    AddEnabled: boolean = true;
    dateSearch: boolean = true;
    searchDateRange(): void {
        this.tableEngine.filterDataByDateRange(this.SearchFrom,this.SearchTo,this.SearchKey==''?'Date':this.SearchKey);
        console.log("datefrom header",this.SearchFrom);
      }
      searchUsingKeys(): void {
        this.tableEngine.filterData();
      }
      
      deleteSelected(): void {
        this.tableEngine.showDelModal('delMulti');
      }
}
