import { QuotationRequestItemModel } from "./QuotationRequestItemModel";

        
export class QuotationRequestModel {
    Id?: number;
    Code?: number;
    SupplierId?: number;
    SupplierNameAr?: string;
    SupplierNameEn?: string;
    CompanyDataId?: number;
    //BranchId?: number;
    //BranchNameAr?: string;
    //BranchNameEn?: string;
    //StoreDataId?: number;
    //StoreNameAr?: string;
    //StoreNameEn?: string;
    Year?: number;
    Month?: number;
    Date?: Date;
    DueDate?: Date;
    Description?: string;
    TotalValue?: number;
    //IsPosted?: boolean;
    Items?: QuotationRequestItemModel [];
    constructor(obj?: QuotationRequestModel) {
        obj = obj ? obj : {};
        this.Id = obj.Id ? obj.Id : 0;
        this.Code = obj.Code ? obj.Code : 0;
        this.SupplierId = obj.SupplierId ? obj.SupplierId : 0;
        this.SupplierNameAr = obj.SupplierNameAr ? obj.SupplierNameAr : '';
        this.SupplierNameEn = obj.SupplierNameEn ? obj.SupplierNameEn : '';
        this.CompanyDataId = obj.CompanyDataId ? obj.CompanyDataId : 0;
        //this.BranchId = obj.BranchId ? obj.BranchId : 0;
        //this.BranchNameAr = obj.BranchNameAr ? obj.BranchNameAr : '';
        //this.BranchNameEn = obj.BranchNameEn ? obj.BranchNameEn : '';
        //this.StoreDataId = obj.StoreDataId ? obj.StoreDataId : 0;
        //this.StoreNameAr = obj.StoreNameAr ? obj.StoreNameAr : '';
        //this.StoreNameEn = obj.StoreNameEn ? obj.StoreNameEn : '';
        this.Year = obj.Year ? obj.Year : 0;
        this.Month = obj.Month ? obj.Month : 0;
        this.Date = obj.Date ? obj.Date : new Date();
        this.DueDate = obj.DueDate ? obj.DueDate : new Date();
        this.Description = obj.Description ? obj.Description : '';
        this.TotalValue = obj.TotalValue ? obj.TotalValue : 0;
        //this.IsPosted = obj.IsPosted ? obj.IsPosted : false;
        this.Items = obj.Items ? obj.Items : [];
    }

}