import { SidePopupComponent } from './../../../views/invoice/side-popup/side-popup.component';
import { Component, Renderer2, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { NotificationsService } from "angular2-notifications";
import { ModalDirective } from "ngx-bootstrap/modal";
import { PageChangedEvent } from "ngx-bootstrap/pagination";
import { TableEngine } from "../../../invoices/TableEngine";
import { ItemsModel } from "../item-balance/shared/Models/ItemsModel";
import { StoreDataService } from "../store-data/store-data.service";
import { StoreDataModel } from "../store-data/StoreDataModel";
import { AdditionAdjustmentService } from "./addition-adjustment.service";
import { AdditionAdjustmentModel } from "./AdditionAdjustmentModel";
import { DataFilterer } from 'src/app/views/app/aio/shared/DataFilterer';
import { DataSorter } from 'src/app/views/app/aio/shared/DataSorter';
import { DataPaginator } from 'src/app/views/app/aio/shared/DataPaginator';

@Component({
  // moduleId: module.id,
  selector: "addition-adjustment",
  templateUrl: "addition-adjustment.component.html",
  styleUrls: ["addition-adjustment.component.scss"],
})
export class AdditionAdjustmentComponent {
  constructor(
    private formBuilder: FormBuilder,
    private modelService: AdditionAdjustmentService,
    private renderer: Renderer2,
    private notifications: NotificationsService,
    private translate: TranslateService,
    private StoreDataService: StoreDataService
  ) {}

  tableEngine = new TableEngine(this.translate, this.notifications);
  currentScreen: any = "Table";

  translationName: string = "";

  sortableKeys = [];
  searchableKeys = ["StoreDataId","ItemsHeaderId"];

  modelObject: AdditionAdjustmentModel = new AdditionAdjustmentModel();

  ogData: AdditionAdjustmentModel[] = [];
  mainForm: FormGroup = new FormGroup({
    Id: new FormControl(),
    CompanyDataId: new FormControl(),
    BranchesDataId: new FormControl(),
    StoreDataId: new FormControl(),
    ItemsHeaderId: new FormControl(),
    UnitTypeId: new FormControl(),
    UnitPrice: new FormControl(),
    Quantity: new FormControl(),
    TotalValue: new FormControl(),
  });

  declare lang: any;
  declare itemOrder: any;
  currentAction = "";
  savedPage = 0;
  currentPage = 1;
  deletionList: any[] = [];

  @ViewChild("sideModalRef", { static: true }) sideModalRef: SidePopupComponent;
  @ViewChild("delModal", { static: true }) delModal: ModalDirective;
  @ViewChild("smallModal", { static: true }) smallModal: ModalDirective = null;
  ngOnInit(): void {
    this.lang = localStorage.getItem("theme_lang");
    // this.itemOrder = { label: "StoreDataId", property: "Id" };
     this.itemOrder = { label: 'StoreDataId', property:this.lang === 'ar-EG' ? 'NameAr' : 'NameEn'  };
     this.itemOrder = { label: 'ItemsHeaderId', property:this.lang === 'ar-EG' ? 'NameAr' : 'NameEn'  };
    this.sortableKeys = [
      //   { label: 'BranchName', property: this.lang === 'ar-EG' ? 'NameAr' : 'NameEn' },
      {
        label: "StoreDataId",
        property: this.lang === "ar-EG" ? "NameAr" : "NameEn",
      },
      {
        label: "ItemsHeaderId",
        property: this.lang === "ar-EG" ? "NameAr" : "NameEn",
      },
      { label: "UnitPrice", property: "UnitPrice" },
      { label: "Quantity", property: "Quantity" },
      { label: "TotalValue", property: "TotalValue" },
      // { label: "ItemsHeader", property: "ItemsHeaderId" },
    ];
    this.loadServices();
    // this.tableEngine.loadData();
    // this.currentAction = '';
    this.tableEngine.loadTableEngine(
      this.ogData,
      this.modelService,
      this.modelObject,
      this.searchableKeys,
      this.sortableKeys,
      this.mainForm,
      this.translationName,
      this.itemOrder,
      this.sideModalRef,
      this.delModal,
      this.smallModal,
      this.lang
    );
    this.getTotal();
  }

  showModal(Id, action) {
    debugger;
    // this.tableEngine.showAddNewModalRef(this.createForm, this.formBuilder)
    var i = this.ogData.findIndex(x=>x.Id == Id)
    if(i > -1){
    this.tableEngine.modelObject = this.ogData[i];
      
    }
    this.modelObject =  this.tableEngine.modelObject;
    this.mainForm = this.tableEngine.mainForm;
    if (action === "Add") {
      this.tableEngine.showAddNewModalRef(this.createForm, this.formBuilder);
    } else if (action === "Edit") {
      this.tableEngine.showEditModalRef(Id, this.createForm, this.formBuilder);
    } else if (action === "Info") {
      this.tableEngine.showInfoModalRef(Id, this.createForm, this.formBuilder);
    }

  }
  createForm(modelObject, formBuilder): FormGroup {
    return formBuilder.group({
      Id: [modelObject.Id >= 0 ? modelObject.Id : 0],
      StoreDataId: [
        modelObject.StoreDataId,
        Validators.compose([Validators.required]),
      ],
      ItemsHeaderId: [
        modelObject.ItemsHeaderId,
        Validators.compose([Validators.required]),
      ],
      UnitPrice: [modelObject.UnitPrice],
      Quantity: [modelObject.Quantity],
      TotalValue: [modelObject.TotalValue],
    });
  }
  addObject() {
    debugger;
    this.currentAction = "loading";

    this.modelObject = new AdditionAdjustmentModel();
    this.modelObject.Id = 0;
    this.modelObject.CompanyDataId = 0;
    this.modelObject.BranchesDataId = 0;
    this.modelObject.ItemsHeaderId = +this.mainForm.getRawValue().ItemsHeaderId;
    this.modelObject.Quantity = this.mainForm.getRawValue().Quantity;
    this.modelObject.StoreDataId = +this.mainForm.getRawValue().StoreDataId;
    this.modelObject.TotalValue = this.mainForm.getRawValue().TotalValue;
    this.modelObject.UnitPrice = this.mainForm.getRawValue().UnitPrice;
    this.modelObject.UnitTypeId = this.mainForm.getRawValue().UnitTypeId;
    if(this.modelObject.UnitTypeId == null || this.modelObject.UnitTypeId == undefined){
      this.modelObject.UnitTypeId = 0;
    }
    this.executeAddObject();
  }

  executeAddObject() {
    this.modelService.insert(this.modelObject).subscribe((result) => {
      if (result === true) {
        this.tableEngine.loadData();
          this.loadServices();

        this.currentAction = "";
        this.tableEngine.toastSuccess(
          this.translate.instant("Success"),
          this.translate.instant("SuccessfullyAdded")
        );
        this.tableEngine.sideModalRef.modalRef.hide();
      } else {
        this.tableEngine.toastError(
          this.translate.instant("Error"),
          this.translate.instant("FailedAdd")
        );
        this.currentAction = "";
      }
    });
  }

  editObject() {
    this.currentAction = "loading";
    this.tableEngine.savedPage = this.tableEngine.currentPage;

    let tempObj = new AdditionAdjustmentModel({
      Id: this.modelObject.Id,
      StoreDataId: this.mainForm.getRawValue().StoreDataId,
      Quantity: this.mainForm.getRawValue().Quantity,
      TotalValue: this.mainForm.getRawValue().TotalValue,
      UnitPrice: this.mainForm.getRawValue().UnitPrice,
      ItemsHeaderId: this.mainForm.getRawValue().ItemsHeaderId,
    });

    if (JSON.stringify(tempObj) != JSON.stringify(this.modelObject)) {
      this.executeEditObject(tempObj);
    } else {
      this.tableEngine.sideModalRef.modalRef.hide();
    }
  }

  executeEditObject(Object) {
    this.modelService.update(Object).subscribe((result) => {
      if (result === true) {
        this.tableEngine.loadData();
    this.loadServices();

        this.currentAction = "";
        this.tableEngine.toastSuccess(
          this.translate.instant("Success"),
          this.translate.instant("SuccessfullyEdited")
        );

        this.tableEngine.sideModalRef.modalRef.hide();
      } else {
        this.tableEngine.toastError(
          this.translate.instant("Error"),
          this.translate.instant("FailedEdit")
        );
        this.currentAction = "";
      }
    });
  }

  deleteObject() {
    this.savedPage = this.currentPage;
    //prevent multiclicks. re enable after API response
    this.currentAction = "loading";
    this.executeDeleteObject();
  }

  //Same here...
  executeDeleteObject() {
    this.modelService
      .deleteList(this.tableEngine.deletionList)
      .subscribe((result) => {
        if (result === true) {
          this.tableEngine.loadData();
          this.currentAction = "";
          this.tableEngine.finalizeDeletion();
        } else {
          this.tableEngine.toastError(
            this.translate.instant("Error"),
            this.translate.instant("FailedDelete")
          );
          this.currentAction = "";
        }
      });
  }

  StoreDataList: StoreDataModel[] = [];
  // storeService=StoreDataService;
  loadServices() {
    //debugger;
    this.currentAction = "loading";
    this.StoreDataService.getAll().subscribe((Data) => {
      this.StoreDataList = Data;
      // this.tableEngine.loadData();
    });
    this.modelService.getAllItems().subscribe((data) => {
      this.ItemsList = data;
    });
    this.modelService.getAll().subscribe((Data) => {
      this.ogData = Data;
      this.tableEngine.ogData = Data;
      this.sortData();
    });
  }
  ItemsList: ItemsModel[] = [];
  getStoreDataById(Id: any): any {
    //debugger;

    if (Id > 0) {
      return this.StoreDataList.find((i) => i.Id === Id);
    } else {
      return new StoreDataModel();
    }
  }
  // TValue = 0;
  getItemsById(Id: any): any {
    //debugger;
    if (Id > 0) {
      return this.ItemsList.find((i) => i.Id === Id);
    } else {
      return new ItemsModel();
    }
  }

  getPrice() {
    //debugger;
    var i = this.ItemsList.findIndex(
      (x) => x.Id == this.mainForm.controls["ItemsHeaderId"].value
    );
    if (i > -1) {
      var item = this.ItemsList[i];
      this.mainForm.controls["UnitPrice"].setValue(item.Cost);
      this.mainForm.controls["UnitTypeId"].setValue(item.CodingUnitsId);
    }
  }
  Total: AdditionAdjustmentModel = {};

  getTotal() {
    //debugger;
    this.Total = this.mainForm.getRawValue();
    this.Total.TotalValue = +(this.Total.Quantity * this.Total.UnitPrice);
    this.mainForm.controls["TotalValue"].setValue(this.Total.TotalValue);
  }
  // TValue=0;
  // AddList:AdditionAdjustmentModel[]=[];
  // calculateTotalItemValue() {
  //   //debugger;
  //   let total = 0;
  //   this.ogData.forEach((item) => {
  //     total += item.Quantity * item.UnitPrice;
  //   });
  //   this.TValue = total;
  //   return total;
  // }

  filteredData = [...this.ogData];
  sortedData = [...this.filteredData];
  paginatedData = [...this.filteredData];
  lastId: number = this.ogData.length + 1;

  SelectAllBox: any = false;

  itemsPerPage = 10;
  itemOptionsPerPage = [5, 10, 20];
  selected = [];
  selectAllState = "";
  orderAsc: boolean = true;

  // itemOrder: any;
  // orderAsc: boolean = true;

  // currentPage = 1;
  // savedPage = 0;

  dataFilterer = new DataFilterer();
  dataSorter = new DataSorter();
  dataPaginator = new DataPaginator();
  SearchTerm: any = "";

  sortData() {
    //debugger;
    this.sortedData = this.dataSorter.sortData(
      this.itemOrder.property,
      this.ogData
    );
    this.filterData();
  }

  reverseSortData() {
    this.savedPage = this.currentPage;
    this.sortedData = this.sortedData.slice().reverse();
    this.filterData();
  }

  filterData() {
    //debugger;

    this.filteredData = this.dataFilterer.filterData(
      this.SearchTerm,
      this.searchableKeys,
      this.sortedData
    );
    if (this.savedPage === 0) {
      this.paginateData(this.itemsPerPage, 1);
    } else {
      this.paginateData(
        this.itemsPerPage,
        Math.ceil(this.filteredData.length / this.itemsPerPage) >=
          this.savedPage
          ? this.savedPage
          : Math.ceil(this.filteredData.length / this.itemsPerPage)
      );
    }
    this.savedPage = 0;
  }

  paginateData(newItemsPerPage: number, newCurrentPage: number) {
    //debugger;

    this.itemsPerPage = newItemsPerPage;
    this.currentPage = newCurrentPage;
    this.paginatedData = this.filteredData.slice(
      (newCurrentPage - 1) * newItemsPerPage,
      newItemsPerPage * newCurrentPage
    );
  }

  changeSort(newSort: any) {
    if (this.itemOrder.label === newSort.label) {
      this.orderAsc = !this.orderAsc;
      const oldPage = this.currentPage;
      this.reverseSortData();
      this.currentPage = oldPage;
    } else {
      this.orderAsc = true;
      this.itemOrder = newSort;
      this.sortData();
    }
  }

  getSort(label: any) {
    return this.sortableKeys.find((i) => i.label === label);
  }

  changePage(event: PageChangedEvent): void {
    this.currentPage = event.page;
    this.paginateData(this.itemsPerPage, this.currentPage);
  }

  changeFilter() {
    this.deletionList = [];
    this.selected = [];
    this.selectAllState = "";
    this.filterData();
  }

  selectAll($event): void {
    if ($event.target.checked) {
      this.selected = [...this.filteredData];
    } else {
      this.selected = [];
    }
    this.setSelectAllState();
  }

  setSelectAllState(): void {
    if (this.selected.length >= this.filteredData.length) {
      this.selectAllState = "checked";
    } else if (this.selected.length !== 0) {
      this.selectAllState = "indeterminate";
    } else {
      this.selectAllState = "";
    }
  }

  isSelected(p: AdditionAdjustmentModel): boolean {
    return this.selected.findIndex((x) => x.Id === p.Id) > -1;
  }

  onSelect(item: AdditionAdjustmentModel): void {
    if (this.isSelected(item)) {
      this.selected = this.selected.filter((x) => x.Id !== item.Id);
    } else {
      this.selected.push(item);
    }
    this.setSelectAllState();
  }

  findByCode(list: any, code: any): any {
    return list.find((x) => x.Code === code);
  }
}
