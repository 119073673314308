export class DataPaginator{
    constructor(){
        //console.log('data presenter loaded');

    }
    OnInit(){
        //console.log('data presenter used');
    }
    paginateData(currentPage:any, itemsperPage:any, ogData:any[]):any[]{
        let result: any[] = [...ogData];
        return result.slice((currentPage-1)*(itemsperPage), (itemsperPage*currentPage));
    }
}