import { Component } from '@angular/core';

@Component({
    selector: 'my-pharmacy',
    templateUrl: 'my-pharmacy.component.html',
    styleUrls: ['my-pharmacy.component.scss']
})
export class MyPharmacyComponent {

}
