import { InventoryItemsModel } from "./InventoryItemsModel";

export class InventoryManagementModel{
    Id?: number;
    InventoryNumber?:number;
    WarehouseId?:number;
    Notes?:string;
    Time?:string;
    WarehouseName?:string;
    Date?:Date;
    
    IsSettlement?:boolean;
    InventoryItems?:InventoryItemsModel[]
    tooltip?:string;
    constructor(item?:InventoryManagementModel){
        item = item? item:{};
        this.Id = item.Id? item.Id:0;
        this.InventoryNumber = item.InventoryNumber? item.InventoryNumber:0;
        this.WarehouseId = item.WarehouseId? item.WarehouseId:0;          
        this.Notes = item.Notes? item.Notes:'';          
        this.IsSettlement = item.IsSettlement? item.IsSettlement:true;          
   
        this.WarehouseName = item.WarehouseName? item.WarehouseName:'';          
        this.Time = item.Time? item.Time:'';          
        this.Date = item.Date? item.Date:new Date();          
        this.InventoryItems = item.InventoryItems? item.InventoryItems:[];
        this.tooltip = item.tooltip?item.tooltip:'';
    }

}