import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { RequestReceiptModel } from "./RequestReceiptModel";

@Injectable({
  providedIn: 'root'
})
export class RequestReceiptService {
  constructor(private http: HttpClient) {

  }

  debugData: RequestReceiptModel [] = [];
  // debugData: RequestReceiptModel [] = [
  //   {
  //     Id:1, PurchaseOrderHeaderId:1, SupplierId:1, Code:1, CompanyDataId:0, BranchesDataId:0, StoreDataId:2, 
  //     Year:2021, Month:1, Date:new Date(2021, 0, 13), Description:"desc1\ntest1\n1", TotalValue:5,
  //     IsPosted:false,
  //     Footers: [
  //       {Id:1, RequestReceiptHeaderId:1, ItemsHeaderId:1, UnitTypeId:1, Quantity:1, Price:1, Value:1},
  //       {Id:2, RequestReceiptHeaderId:1, ItemsHeaderId:2, UnitTypeId:2, Quantity:2, Price:2, Value:4},
  //     ]
  //   },
  //   {
  //     Id:2, PurchaseOrderHeaderId:2, SupplierId:2, Code:2, CompanyDataId:0, BranchesDataId:0, StoreDataId:3, 
  //     Year:2022, Month:2, Date:new Date(2022, 1, 13), Description:"desc2\ntest2\n2", TotalValue:25,
  //     IsPosted:true,
  //     Footers: [
  //       {Id:3, RequestReceiptHeaderId:2, ItemsHeaderId:1, UnitTypeId:2, Quantity:3, Price:3, Value:9},
  //       {Id:4, RequestReceiptHeaderId:2, ItemsHeaderId:2, UnitTypeId:1, Quantity:4, Price:4, Value:16},
  //     ]
  //   }
  // ];
  debugMode: boolean = false;

  baseUrl = environment.baseApiUrl;
  // baseUrl = 'http://localhost:60289/';
   token = localStorage.getItem("token");
   httpOptions = {
    headers: new HttpHeaders({
      // 'Content-Type': 'application/json; charset=utf-8',
      // 'Authorization': "Bearer " + token,
       "Authorization": "Bearer " + this.token,
       "Content-Type": "application/json"
    })
  };
  getAll(): Observable<any> {
    if(this.debugMode){
      return of(this.debugData)
    }
    return this.http
      .get<any>(`${this.baseUrl}RequestReceipt/GetAll`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error => error)));
  }
  getAllWithItems(): Observable<any> {
    if(this.debugMode){
      return of(this.debugData)
    }
    return this.http
      .get<any>(`${this.baseUrl}RequestReceipt/GetAllWithItems`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error => error)));
  }
  getById(id:number): Observable<any> {
    if(this.debugMode){
      return of(this.debugData.find(x=>x.Id==id))
    }
    return this.http
      .get<any>(`${this.baseUrl}RequestReceipt/GetById/${id}`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error => error)));
  }
  getByIdWithItems(id:number): Observable<any> {
    if(this.debugMode){
      return of(this.debugData.find(x=>x.Id==id))
    }
    return this.http
      .get<any>(`${this.baseUrl}RequestReceipt/GetByIdWithItems/${id}`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error => error)));
  }
  insert(payload: RequestReceiptModel): Observable<any> {
    if(this.debugMode){
      this.debugData.push(payload);
      return of(true)
    }
    return this.http
      .post<any>(`${this.baseUrl}RequestReceipt/Insert`, payload,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error => error)));
  }
  insertWithItems(payload: RequestReceiptModel): Observable<any> {
    if(this.debugMode){
      this.debugData.push(payload);
      return of(true)
    }
    return this.http
      .post<any>(`${this.baseUrl}RequestReceipt/InsertWithItems`, payload,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error => error)));
  }

  update(payload: RequestReceiptModel): Observable<any> {
    if(this.debugMode){
      this.debugData[this.debugData.findIndex(x=>x.Id==payload.Id)] = payload;
      return of(true)
    }
    return this.http
      .post<any>(`${this.baseUrl}RequestReceipt/Update`, payload,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error => error)));
  }
  updateWithItems(payload: RequestReceiptModel): Observable<any> {
    if(this.debugMode){
      this.debugData[this.debugData.findIndex(x=>x.Id==payload.Id)] = payload;
      return of(true)
    }
    return this.http
      .post<any>(`${this.baseUrl}RequestReceipt/UpdateWithItems`, payload,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error => error)));
  }

  delete(payload: RequestReceiptModel): Observable<any> {
    if(this.debugMode){
      this.debugData.splice(this.debugData.findIndex(x=>x.Id==payload.Id),1);
      return of(true)
    }
    return this.http
      .get<any>(`${this.baseUrl}RequestReceipt/Delete/${payload.Id}`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error => error)));
  }

  deleteList(payload: RequestReceiptModel []): Observable<any> {
    if(this.debugMode){
      payload.forEach(element => {
        this.debugData.splice(this.debugData.findIndex(x=>x.Id==element.Id),1);
      });
      return of(true)
    }
    return this.http
      .post<any>(`${this.baseUrl}RequestReceipt/DeleteList`,JSON.stringify(payload),this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error => error)));
  }

}
