import { ListPageHeaderComponent } from 'src/app/containers/pages/list-page-header/list-page-header.component';
import { Component, HostBinding, Renderer2, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';

import { TableEngine } from '../../../invoices/TableEngine'
import { ModalDirective } from 'ngx-bootstrap/modal';
import { SidePopupComponent } from '../../app/aio/shared/side-popup/side-popup.component';
import { Observable, of, Subscription } from 'rxjs';

import { NgSelectComponent } from '@ng-select/ng-select';

import { DatePipe, formatDate } from '@angular/common';


import { CashierSessionModel } from "./CashierSessionModel";
import { CashierSessionService } from "./CashierSessionService";

import { CashierOrderModel } from "../cashier-orders/CashierOrderModel";
import { BranchModel } from '../../../invoices/branches/BranchModel';
import { CashRegisterModel } from '../cashier-machines/CashRegisterModel';
import { StaffModel } from '../../../invoices/staff/StaffModel';
import { CashierStaffModel } from '../cashier-staff/CashierStaffModel';
import { ItemModel } from "../../../invoices/items/ItemModel";
import { CodingUnitModel } from "../../../invoices/coding-units/CodingUnitModel";
import { ClientModel } from "../../../invoices/clients/ClientModel";




@Component({
  selector: 'app-cashier-sessions',
  templateUrl: './cashier-sessions.component.html',
  styleUrls: ['./cashier-sessions.component.scss']
})
export class RestaurantsCashierSessionsComponent {
  // ////////////////////////////////////////////////////////////////////////////
  // //  VARYING :
  // //  Modify per similar component.
  // ////////////////////////////////////////////////////////////////////////////

  //Enable debugMode to make API modules return static data from within the service class
  debugMode: boolean = false;

  constructor(private formBuilder: FormBuilder, private modelService: CashierSessionService, private renderer: Renderer2,
    private notifications: NotificationsService, private translate: TranslateService,
    private datePipe: DatePipe,
  ) {
  }


  listHeader = new ListPageHeaderComponent();

  tableEngine = new TableEngine(this.translate, this.notifications);
  currentScreen: any = 'Table';
  tableView: any = true;

  translationName: string = 'CashierSession'

  sortableKeys = [];
  searchableKeys = ['StartTime'];

  modelObject: CashierSessionModel = new CashierSessionModel();

  ogData: CashierSessionModel[] = [];
  
  mainForm: FormGroup = new FormGroup({
    Id: new FormControl(),
    // StaffId: new FormControl(),
    // BranchId: new FormControl(),
  });


  declare lang: any;
  declare itemOrder: any;
  currentAction = '';
  savedPage = 0;
  currentPage = 1;
  deletionList: any[] = [];

  @ViewChild('sideModalRef', { static: true }) sideModalRef: SidePopupComponent = null;
  @ViewChild('delModal', { static: true }) delModal: ModalDirective;
  @ViewChild('smallModal', { static: true }) smallModal: ModalDirective = null;
  @ViewChild('orderItemsModal', { static: true }) orderItemsModal: ModalDirective;
  
  formPresentationModal: any;
  ngOnInit(): void {
    if (this.debugMode) {
      this.enableDebugOnAllServices();
    }

    this.lang = localStorage.getItem('theme_lang');
    this.itemOrder = { label: 'CashierSessionName', property: this.lang === 'ar-EG' ? 'NameAr' : 'NameEn' };
    this.sortableKeys = [
      { label: 'CashierSessionCode', property: 'Code' },
      { label: 'CashierSessionCashierName', property: this.lang === 'ar-EG' ? 'CashierNameAr' : 'CashierNameEn' },
      { label: 'CashierSessionBranchName', property: this.lang === 'ar-EG' ? 'BranchNameAr' : 'BranchNameEn' },
      { label: 'CashierSessionCashRegisterCode', property: 'CashRegisterCode' },
      { label: 'CashierSessionStartTime', property: 'StartTime' },
      { label: 'CashierSessionEndTime', property: 'EndTime' },
      { label: 'CashierSessionOrderCount', property: 'OrderCount' },
      { label: 'CashierSessionRefundCount', property: 'RefundCount' },
      { label: 'CashierSessionSessionTotal', property: 'SessionTotal' },
    ];


    this.tableEngine.loadTableEngine(this.ogData, this.modelService, this.modelObject,
      this.searchableKeys, this.sortableKeys, this.mainForm, this.translationName, this.itemOrder,
      this.sideModalRef, this.delModal, this.smallModal, this.lang
    )

    this.loadServices();
  }


  enableDebugOnAllServices() {
    //this.modelService.debugMode = true;
  }

  lastCode = 0;
  showModal(Id, action) {
    // if (action === 'Add') {
    //   this.lastCode = this.tableEngine.ogData.length>0?this.tableEngine.ogData[this.tableEngine.ogData.length - 1].Code:0;
    //   this.tableEngine.showAddNewModalRef(this.createForm, this.formBuilder);
    // }
    // else if (action === 'Edit') {
    //   this.tableEngine.showEditModalRef(Id, this.createForm, this.formBuilder);
    // }
    //else if (action === 'Info') {
    if (action === 'Info') {
      this.modelService.getByIdWithOrders(Id).subscribe(
        detailedObject => {
          let targetIndex = this.tableEngine.ogData.findIndex(i => i.Id === Id);
          console.log(detailedObject);
          console.log(this.tableEngine.ogData.find(i => i.Id === Id));
          this.tableEngine.ogData[targetIndex] = detailedObject;
          console.log(this.tableEngine.ogData.find(i => i.Id === Id));
          
          this.tableEngine.showInfoModalRef(Id, this.createForm, this.formBuilder);
          
          this.tableView = false;
          this.modelObject = this.tableEngine.modelObject;
          this.mainForm = this.tableEngine.mainForm;
          console.log((action + " object"), this.modelObject);
      
          
          console.log("modelObject after entering edit", this.modelObject);
        }
      )
    }
  }
  
  createForm(modelObject, formBuilder): FormGroup {
    
    return formBuilder.group({
    });
  }

  ////////////////////////////////////////////////////////////////////////////
  //
  //
  //
  //
  //
  ////////////////////////////////////////////////////////////////////////////
  //  EXCLUSIVE :
  //  Specifically for this component.
  ///////////////////////////////
  //Get any additional data here
  SearchFrom: Date = null;
  SearchTo: Date = null;

  clearSearch() {
    this.SearchFrom = null;
    this.SearchTo = null;
  }

  prevAction = '';

  loadServices() {
    this.currentAction = 'loading';

    this.tableEngine.loadData();


  }

  viewedOrder: CashierOrderModel = new CashierOrderModel();
  showOrderItemsModal(order: CashierOrderModel){
    this.viewedOrder = order;
    this.orderItemsModal.show();
  }

  goBack(){
    console.log("back");
    
    this.modelObject = {};
    this.currentAction = "";
    this.tableView = true;

    
    console.log(this.modelObject);
    console.log(this.lastCode);
  }

  ////////////////////////////////////////////////////////////////////////////
  //
  //
  //
  //
  //
  ////////////////////////////////////////////////////////////////////////////
  //  CONSTANT :
  //  Do not change.
  ///////////////////////////////
  prepChangeSort(sortKey) {
    this.SearchFrom = null;
    this.SearchTo = null;
    
    
    this.tableEngine.changeSort(this.tableEngine.getSort(sortKey));
    this.itemOrder = this.tableEngine.itemOrder;
  }

  getFormattedDate(dateString: any){
    let splitDate: number[] = dateString.toString().split("/");

    return new Date(splitDate[2], splitDate[1]-1, splitDate[0])
  }
  // ///////////////////////////////////////////////////////
  
  displayMode = 'list';
  changeDisplayMode(mode): void {
    this.displayMode = mode;
  }
  itemsPerPageChange(perPage: number): void {
    this.tableEngine.paginateData(perPage, 1)
  }
}
