
export class SalariesManagerPayment{
    Id?: number;
    SalariesPaymentId?: number;
    SalariesManagerId?: number;
   
   
   
    constructor(item?:SalariesManagerPayment){
        item = item? item:{};
        this.Id = item.Id? item.Id:0;
        this.SalariesPaymentId = item.SalariesPaymentId? item.SalariesPaymentId:0;
        this.SalariesManagerId = item.SalariesManagerId? item.SalariesManagerId:0;
  
 
      

    }

}