export class ChecksReceivedModel{
  Id                   ?: number;
  Amount               ?: number;
  CurrencyId           ?: number;
  Description          ?: string;
  ReleaseDate          ?: Date;
  DueDate              ?: Date;
  CheckNumber          ?: string;
  AccountReceivedId    ?: number;
  TheCollectionAccount ?: number;
  TheNameIsOnTheCheck  ?: string;
  IdEndorsement        ?: boolean;
  EndorsementName      ?: string;
  FileUrl              ?: string;
  IsCollection         ?: boolean;
  CollectionDate       ?: Date;
  CollectionDescription?: string;
  CollectionBy         ?: string;
  IsDeposit            ?: boolean;
  DepositDate          ?: Date;
  DepositDescription   ?: string;
  DepositAccountId     ?: number;
  DepositBy            ?: string;
  IsRejected           ?: boolean;
  RejectedDate         ?: Date;
  RejectedDescription  ?: string;
  RejectedBy           ?: string;
  DepositFees?:number;
constructor(item?:ChecksReceivedModel){
    item = item ?item : {};
    this.Id                    = item.Id                    ? item.Id                    : 0;
    this.Amount                = item.Amount                ? item.Amount                : 0;
    this.CurrencyId            = item.CurrencyId            ? item.CurrencyId            : 0;
    this.DepositFees            = item.DepositFees            ? item.DepositFees            : 0;
    this.Description           = item.Description           ? item.Description           : '';
    this.ReleaseDate           = item.ReleaseDate           ? item.ReleaseDate           : new Date();
    this.DueDate               = item.DueDate               ? item.DueDate               : new Date();
    this.CheckNumber           = item.CheckNumber           ? item.CheckNumber           : '';
    this.AccountReceivedId     = item.AccountReceivedId     ? item.AccountReceivedId     : 0;
    this.TheCollectionAccount  = item.TheCollectionAccount  ? item.TheCollectionAccount  : 0;
    this.TheNameIsOnTheCheck   = item.TheNameIsOnTheCheck   ? item.TheNameIsOnTheCheck   : '';
    this.IdEndorsement         = item.IdEndorsement         ? item.IdEndorsement         : false;
    this.EndorsementName       = item.EndorsementName       ? item.EndorsementName       : '';
    this.FileUrl               = item.FileUrl               ? item.FileUrl               : '';
    this.IsCollection          = item.IsCollection          ? item.IsCollection          : false;
    this.CollectionDate        = item.CollectionDate        ? item.CollectionDate        : new Date();
    this.CollectionDescription = item.CollectionDescription ? item.CollectionDescription : '';
    this.CollectionBy          = item.CollectionBy          ? item.CollectionBy          : '';
    this.IsDeposit             = item.IsDeposit             ? item.IsDeposit             : false;
    this.DepositDate           = item.DepositDate           ? item.DepositDate           : new Date();
    this.DepositDescription    = item.DepositDescription    ? item.DepositDescription    : '';
    this.DepositAccountId      = item.DepositAccountId      ? item.DepositAccountId      : 0;
    this.DepositBy             = item.DepositBy             ? item.DepositBy             : '';
    this.IsRejected            = item.IsRejected            ? item.IsRejected            : false;
    this.RejectedDate          = item.RejectedDate          ? item.RejectedDate          : new Date();
    this.RejectedDescription   = item.RejectedDescription   ? item.RejectedDescription   : '';
    this.RejectedBy            = item.RejectedBy            ? item.RejectedBy            : '';
}

}