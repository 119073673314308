import { PurchaseQuotationFileModel } from "./PurchaseQuotationFileModel";
import { PurchaseQuotationItemModel } from "./PurchaseQuotationItemModel";
import { PurchaseQuotationServiceModel } from "./PurchaseQuotationServiceModel";

export class PurchaseQuotationDetailsModel {
    Id?: number;
    Code?: number;
    SupplierId?: number;
    SupplierNameAr?: string;
    SupplierNameEn?: string;
    CompanyDataId?: number;
    //BranchId?: number;
    //BranchNameAr?: string;
    //BranchNameEn?: string;
    //StoreDataId?: number;
    //StoreNameAr?: string;
    //StoreNameEn?: string;
    Year?: number;
    Month?: number;
    Date?: Date;
    ExpirationDate?: Date;
    DiscountValue?: number;
    DiscountIsRate?: boolean;
    ShippingFees?: number;
    ShippingAddress1?: string;
    ShippingAddress2?: string;
    Description?: string;
    Items?: PurchaseQuotationItemModel[];
    TotalItemPrices?: number;    //base prices, without taxes or discounts
    TotalItemDiscounts?: number;
    TotalItemNetPrices?: number;
    TotalItemTaxes?: number;
    TotalItemGrossPrices?: number;  //final prices
    Services?: PurchaseQuotationServiceModel[];
    TotalServices?: number;
    TotalPurchaseQuotationValue?: number;
    Files?: PurchaseQuotationFileModel[];
    constructor(obj?: PurchaseQuotationDetailsModel) {
        obj = obj ? obj : {};
        this.Id = obj.Id ? obj.Id : 0;
        this.Code = obj.Code ? obj.Code : 0;
        this.SupplierId = obj.SupplierId ? obj.SupplierId : 0;
        this.SupplierNameAr = obj.SupplierNameAr ? obj.SupplierNameAr : '';
        this.SupplierNameEn = obj.SupplierNameEn ? obj.SupplierNameEn : '';
        this.CompanyDataId = obj.CompanyDataId ? obj.CompanyDataId : 0;
        // this.BranchId = obj.BranchId ? obj.BranchId : 0;
        // this.BranchNameAr = obj.BranchNameAr ? obj.BranchNameAr : '';
        // this.BranchNameEn = obj.BranchNameEn ? obj.BranchNameEn : '';
        // this.StoreDataId = obj.StoreDataId ? obj.StoreDataId : 0;
        // this.StoreNameAr = obj.StoreNameAr ? obj.StoreNameAr : '';
        // this.StoreNameEn = obj.StoreNameEn ? obj.StoreNameEn : '';
        this.Year = obj.Year ? obj.Year : 0;
        this.Month = obj.Month ? obj.Month : 0;
        this.Date = obj.Date ? obj.Date : new Date();
        this.ExpirationDate = obj.ExpirationDate ? obj.Date : new Date();
        this.DiscountValue = obj.DiscountValue ? obj.DiscountValue : 0;
        this.DiscountIsRate = obj.DiscountIsRate ? obj.DiscountIsRate : true;
        this.ShippingFees = obj.ShippingFees ? obj.ShippingFees : 0;
        this.ShippingAddress1 = obj.ShippingAddress1 ? obj.ShippingAddress1 : '';
        this.ShippingAddress2 = obj.ShippingAddress2 ? obj.ShippingAddress2 : '';
        this.Description = obj.Description ? obj.Description : '';
        this.Items = obj.Items ? obj.Items : [];
        this.TotalItemPrices = obj.TotalItemPrices ? obj.TotalItemPrices : 0;
        this.TotalItemDiscounts = obj.TotalItemDiscounts ? obj.TotalItemDiscounts : 0;
        this.TotalItemNetPrices = obj.TotalItemNetPrices ? obj.TotalItemNetPrices : 0;
        this.TotalItemTaxes = obj.TotalItemTaxes ? obj.TotalItemTaxes : 0;
        this.TotalItemGrossPrices = obj.TotalItemGrossPrices ? obj.TotalItemGrossPrices : 0;
        this.Services = obj.Services ? obj.Services : [];
        this.TotalServices = obj.TotalServices ? obj.TotalServices : 0;
        this.TotalPurchaseQuotationValue = obj.TotalPurchaseQuotationValue ? obj.TotalPurchaseQuotationValue : 0;
        this.Files = obj.Files ? obj.Files : [];
    }

}