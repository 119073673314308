<div class="pagecontent">
    <div style="display: block;">
        <app-company-api></app-company-api>
    </div>
    <div id="linkButtons" style="display:block;">
        <div class="glide__slide ng-star-inserted glide__slide--active d-inline-block"
            style="width: 200px; margin: 5px;">
            <div class="icon-row-item" style="width: inherit; cursor: pointer;">
                <div class="card">
                    <div class="card-body text-center" routerLink="Clients/Currencies"><i class="simple-icon-wallet"></i>
                        <h3>{{"Currencies" | translate}}</h3>
                    </div>
                </div>
            </div>
        </div>
        <div class="glide__slide ng-star-inserted glide__slide--active d-inline-block"
            style="width: 200px; margin: 5px;">
            <div class="icon-row-item" style="width: inherit; cursor: pointer;">
                <div class="card">
                    <div class="card-body text-center" routerLink="Clients/All-Clients"><i class="simple-icon-people"></i>
                        <h3>{{"Clients" | translate}}</h3>
                    </div>
                </div>
            </div>
        </div>
        <div class="glide__slide ng-star-inserted glide__slide--active d-inline-block"
            style="width: 200px; margin: 5px;">
            <div class="icon-row-item" style="width: inherit; cursor: pointer;">
                <div class="card">
                    <div class="card-body text-center" routerLink="Clients/Branches"><i class="simple-icon-organization"></i>
                        <h3>{{"Branches" | translate}}</h3>
                    </div>
                </div>
            </div>
        </div>
        <div class="glide__slide ng-star-inserted glide__slide--active d-inline-block"
            style="width: 200px; margin: 5px;">
            <div class="icon-row-item" style="width: inherit; cursor: pointer;">
                <div class="card">
                    <div class="card-body text-center" routerLink="MainData/Items"><i class="iconsminds-box-close"></i>
                        <h3>{{"Items" | translate}}</h3>
                    </div>
                </div>
            </div>
        </div>
        <div class="glide__slide ng-star-inserted glide__slide--active d-inline-block"
            style="width: 200px; margin: 5px;">
            <div class="icon-row-item" style="width: inherit; cursor: pointer;">
                <div class="card">
                    <div class="card-body text-center" routerLink="Reports/Invoices"><i class="iconsminds-receipt-4   "></i>
                        <h3>{{"Invoices" | translate}}</h3>
                    </div>
                </div>
            </div>
        </div>
        <div class="glide__slide ng-star-inserted glide__slide--active d-inline-block"
            style="width: 200px; margin: 5px;">
            <div class="icon-row-item" style="width: inherit; cursor: pointer;">
                <div class="card">
                    <div class="card-body text-center" routerLink="Reports/Create-Invoice"><i class="iconsminds-add"></i>
                        <h3>{{"CreateInvoice" | translate}}</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>