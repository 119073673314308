import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { InventoryManagementService } from '../inventory-management.service';
import {WarehouseService}from '../../warehouses/warehouse-service.service';
import { ProductesService}from '../../ProductesAndServices/productes.service';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { TableEngine } from 'src/app/views/app/aio/shared/TableEngine';
import { InventoryManagementModel } from '../../models/InventoryManagementModel';
import { SidePopupComponent } from 'src/app/views/app/aio/shared/side-popup/side-popup.component';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { InventoryItemsModel } from '../../models/InventoryItemsModel';
import { StoreItemsModel } from '../../models/StoreItemsModel';
import { WarehouseModel } from '../../models/WarehouseModel';

@Component({
    // moduleId: module.id,
    selector: 'add-inventory-management',
    templateUrl: 'add-inventory-management.component.html',
    styleUrls: ['add-inventory-management.component.scss']
})
export class AddInventoryManagementComponent {

    Id :number = 0;

    constructor(
      public router: Router,
      private fb: FormBuilder,
      private modelService:InventoryManagementService,
      private WarehouseService: WarehouseService,
     private ProductesService:ProductesService,
      private notifications: NotificationsService,
      private translate: TranslateService,
      private route: ActivatedRoute
    ) {
      this.Id = +route.snapshot.paramMap.get("Id");
    }
  
    tableEngine = new TableEngine(this.translate, this.notifications);
    currentScreen: any = "Table";
    translationName: string = "BellType";
    sortableKeys = [];
    searchableKeys = ["BellType", "Date"];
    tableView: any = true;
  
    // StoreItemsModel:StoreItemsModel[]=[];
  
   
    modelObject: InventoryManagementModel = new InventoryManagementModel();
    Form: FormGroup;
    declare lang: any;
    declare itemOrder: any;
    currentAction = "";
    savedPage = 0;
    currentPage = 1;
    deletionList: any[] = [];
    prevAction = "";
    addToListId = 0;
    ItemsBalanceHeaderId = 0;
    ItemBalanceId = 0;
    currentFormMode: string = "";
    type = 0;
    visable = "";
    visable2 = "";
    visable3 = "";
    ogData: InventoryManagementModel[] = [];
    mainForm: FormGroup = new FormGroup({
        Id: new FormControl(),
        InventoryNumber: new FormControl(),
        WarehouseId: new FormControl(),
        Notes: new FormControl(),
        Time: new FormControl(),
        Date: new FormControl(),
        IsSettlement: new FormControl(),
      
    });
    @ViewChild("sideModalRef", { static: true })
    sideModalRef: SidePopupComponent = null;
    @ViewChild("delModal", { static: true }) delModal: ModalDirective;
    @ViewChild("smallModal", { static: true }) smallModal: ModalDirective = null;
    formPresentationModal: any;
    ngOnInit(): void {
      // this.getAllCountryCode();
  
      this.lang = localStorage.getItem("theme_lang");
      this.itemOrder = {
        label: "Date",
        property: this.lang === "ar-EG" ? "Date" : "Date",
      };
     
      this.tableEngine.loadTableEngine(
        this.ogData,
        this.modelService,
        this.modelObject,
        this.searchableKeys,
        this.sortableKeys,
        this.mainForm,
        this.translationName,
        this.itemOrder,
        this.sideModalRef,
        this.delModal,
        this.smallModal,
        this.lang
      );
     
  
      this.loadServices();
      this.modelService.getAll().subscribe((data) => {
        this.modelObject = data;
      });
     
      this.modelService.getLastNumber().subscribe((data) => {
        debugger;
          this.modelObject.InventoryNumber = data;
          this.mainForm.controls['InventoryNumber'].setValue(this.modelObject.InventoryNumber+1);
        });
       
      this.WarehouseService.getAll().subscribe((data) => {
        this.WarehouseList = data;
      });
  this.ProductesService.getAll().subscribe(data=>{
    this.ProducteList=data;
  })
          if(this.Id > 0)
          {   debugger;                            
                  this.modelService.getById(this.Id).subscribe(data=>{                        
                  this.modelObject = data;                 
                  this.InventoryItemsList=data.InventoryItems;
                  this.InventoryItemsList=this.modelObject.InventoryItems;
                  this.mainForm = this.createForm(this.modelObject);
  
              });
             
  
          }
          else{
                  
             this.mainForm = this.createForm(this.modelObject);                              
  
  
          }
      // this.mainForm = this.createForm(this.modelObject);
  
    }

  createForm(modelObject): FormGroup {
    debugger;
    return this.fb.group({
      // Id:[this.ItemBalance.Id],
      Id: [modelObject.Id >= 0 ? modelObject.Id : 0],
      InventoryNumber: [modelObject.InventoryNumber,Validators.compose([Validators.required])],      
      WarehouseId: [modelObject.WarehouseId,Validators.compose([Validators.required])],
      Time: [modelObject.Time],
      Date: [modelObject.Date],
      IsSettlement: [modelObject.IsSettlement],
      Notes: [modelObject.Notes],
    
    });
  }
  WarehouseList: WarehouseModel[] = [];
  ProducteList:StoreItemsModel[]=[];
  InventoryItemsList:InventoryItemsModel[]=[];

  loadServices() {
    // debugger;
    this.currentAction = "loading";
    this.tableEngine.loadData();
  }

  getItemsById(Id:any):any{
    if(Id>0)
    {
    return this.ProducteList.find(i=>i.Id===Id)
    }
    else
    {
    return new StoreItemsModel();
    }
    }
  setItem(bell){
    debugger;
    var i = this.ProducteList.findIndex(x=>x.Id == bell.ItemId);
    if(i>-1){
      bell.SalePrice = this.ProducteList[i].SalePrice;
      bell.Quantity = this.ProducteList[i].Quantity;
    }
    // this.calculateTotalItemValue();
    }
    currentTotal = 0;

    calculateQuantity() {
      let total = 0;
      this.InventoryItemsList.forEach(item => {
        total += item.QuantityNumberInStock*item.QuantityNumberInProgram;
      });
      this.currentTotal = total;
      return total;
    }


  addproductes() {
    debugger;
    var i = this.ProducteList.findIndex((x) => x.Id == this.addToListId);
    if (i >= 0) {
      var item = this.ProducteList[i];

      this.ProducteList.push(item);
      this.ProducteList.splice(i, 1);
      this.addToListId = 0;

    }
 }
  AddonClick(){
    var InventoryList= new InventoryItemsModel();
    InventoryList.Id = 0, 
    InventoryList.InventoryId =this.Id, 
    InventoryList.ItemId = 0, 
    InventoryList.UnitId=0,
    // ItemStoreList.IsDeleted= false, 
    this.InventoryItemsList.push(InventoryList);
    // this.mainForm.controls['StoreBillId'].setValue(0);
    InventoryList={};    
  }

  removeItemsFooter(Id: any): any {
    debugger;
    let index = this.InventoryItemsList.findIndex(x => x.ItemId === Id);
    this.InventoryItemsList.splice(index, 1);      
    this.calculateQuantity();           
    }
  
   
    goBack() {
        this.router.navigate(["app/aio/Inventory/List-All-InventoryManagement"]);
      }
      goInventory(){
    this.router.navigate(['app/aio/Inventory/InventorySheet/',this.Id])   
        
      }
      Save() {
        debugger;
        this.currentAction = "loading";
        this.tableEngine.savedPage = this.tableEngine.currentPage;
        this.modelObject = new InventoryManagementModel();
        this.modelObject.Id = this.Id;
        this.modelObject.InventoryNumber = this.mainForm.getRawValue().InventoryNumber;
        this.modelObject.Date = new Date(this.mainForm.getRawValue().Date);
        this.modelObject.Notes = this.mainForm.getRawValue().Notes;
        this.modelObject.Time =
          this.mainForm.getRawValue().Time;
        this.modelObject.IsSettlement =false;
        this.modelObject.WarehouseId =+
          this.mainForm.getRawValue().WarehouseId;
        this.modelObject.InventoryItems = this.InventoryItemsList;
    
        if (this.Id == 0) {
        this.modelObject.Id = 0;
          this.modelService.insert(this.modelObject).subscribe((data) => {
            this.router.navigate(['app/aio/Inventory/InventorySheet/',data]);

          });
        } else {
          debugger;
          this.modelService.update(this.modelObject).subscribe((data) => {
            this.router.navigate(['app/aio/Inventory/InventorySheet/',this.Id]);
          });
        }

      }

}
