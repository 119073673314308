<!--component html goes here -->
<!--component html goes here -->
<!--component html goes here -->
<!--component html goes here -->
<div class="row mb-12">
    <div class="col-md-12 col-lg-12 col-12 mb-12">
      <div class="card"></div>
    </div>
  </div>
  <div class="row" *ngIf="mainForm">
    <div class="col-12">
      <div class="card mb-4">
        <div class="card-body">
          <h2>
           
            <Strong style="font-size: 22px; margin-left:10px ;"> {{modelObject.Name}}</Strong>
            <span class="badge badge-pill badge-success  " style= "margin-left:10px; font-size: 14px"  *ngIf="modelObject.Status==true">{{'Active' | translate}}</span>
            <span class="badge badge-pill badge-danger mb-1 " style= "margin-left:10px; font-size: 14px;"   *ngIf="modelObject.Status==false">{{'Inactive' | translate}}</span>
        
          <div class="top-right-button-container text-zero float-right">
            <button
              class="top-right-button btn btn-primary btn-lg mr-1"
              (click)="goBack()"
            >
              {{ "Back" | translate }}
            </button>
           
          </div>
        </h2>
        </div>
      </div>
    </div>
  </div>
  <div></div>
  <div class="card"*ngIf="modelObject" >
    <tabset class="card-tabs">
    <tab heading="{{'Information' | translate}}" class="card-body" >    
        <div >
            <div id="divmiddle" style="display: block; margin-top: 5px">
                <div style="width: 100%">
                    <div class="card" style="min-width: 900px !important">
                        <div class="card-body">
            <div class="row"  >
                <div class="value_highlight col-md-3 ">
                  <h5 for="inputState" style="color: #0189DE;font-size: 14px;">{{ "JoblevelName" | translate }}</h5>
                <label  type="text" >{{modelObject.Name}}</label>            
              </div>
         
            <div class="vl"></div>

            <div class="value_highlight col-md-4 ">
                <h5 for="inputState" style="color: #0189DE;font-size: 14px;">{{ "JoblevelNameDescription" | translate }}</h5>
              <label  type="text" >{{modelObject.Description}}</label>            
            </div>
        </div>
        </div>
        </div>
        </div>
            </div>
          </div>
          
      
    </tab>
    <tab heading="{{'AppointedEmployees' | translate}}" class="card-body">
      <div >
 
        <div id="divmiddle" style="display: block; margin-top: 5px">
          <div style="width: 100%">
            <div>
              <div class="card" style="min-width: 900px !important">
                <div class="card-body">
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </tab>
      <tab heading="{{'RecordActivities' | translate}}" class="card-body">
     
      </tab>
  
  </tabset>
</div>
 <td></td>
  <td></td>
  <td></td>  <td></td>
  <td></td>
  <td></td>
 