import { Component, TemplateRef,  ViewChild } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-side-popup',
  templateUrl: './side-popup.component.html',
  styleUrls: ['./side-popup.component.scss']
})
export class SidePopupComponent  {
  modalRef: BsModalRef;
  lang:string;
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'modal-left'
  };


  @ViewChild('template', { static: true }) template: TemplateRef<any>;

  constructor(private modalService: BsModalService) {
    this.lang = localStorage.getItem('theme_lang');
    this.config = {
      backdrop: true,
      ignoreBackdropClick: true,
      class: this.lang==='ar-EG'?'modal-left':'modal-right'
    };
  }


  show(): void {
    this.modalRef = this.modalService.show(this.template, this.config);
  }

}
