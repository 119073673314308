<div class="form-group" *ngIf="!tableView">
    <div class="modal-body">
        <form class="md-float-material form-material" [formGroup]="mainForm">

            <div style="text-align: center;">
                <button class="btn btn-light" (click)="tableView=true"
                    style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Back" |
                    translate}}</button>
                <button *ngIf="tableEngine.currentFormMode==='Add'"
                    [disabled]="mainForm.invalid || tableEngine.currentAction==='loading'" id="smallModalSubmitButton"
                    class="btn btn-secondary" (click)="addObject()"
                    style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Add" |
                    translate}}</button>
                <button *ngIf="tableEngine.currentFormMode==='Add'"
                    [disabled]="mainForm.invalid || tableEngine.currentAction==='loading'" id="smallModalSubmitButton"
                    class="btn btn-secondary" (click)="addAndNew()"
                    style="font-weight: bold; width: 250px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"AddAndNew" |
                    translate}}</button>
                <button *ngIf="tableEngine.currentFormMode==='Edit'"
                    [disabled]="mainForm.invalid || tableEngine.currentAction==='loading'" id="smallModalSubmitButton"
                    class="btn btn-secondary" (click)="editObject()"
                    style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Edit"
                    | translate}}</button>
            </div>
            <div class="form-group" style="margin-top: 10px;">
                <div class="row">
                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"ContractorNameAr" | translate}}</label>
                        <input *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                            type="text" name="NameAr" class="form-control" formControlName="NameAr"
                            placeholder='{{"ContractorNameAr" | translate}}'>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="NameAr">{{modelObject.NameAr}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('NameAr').hasError('required') || mainForm.get('NameAr').invalid) && ( mainForm.get('NameAr').dirty ||  mainForm.get('NameAr').touched)">
                            {{"ContractorNameArRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>
                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"ContractorNameEn" | translate}}</label>
                        <input *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                            type="text" name="NameEn" class="form-control" formControlName="NameEn"
                            placeholder='{{"ContractorNameEn" | translate}}'>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="NameEn">{{modelObject.NameEn}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('NameEn').hasError('required') || mainForm.get('NameEn').invalid) && ( mainForm.get('NameEn').dirty ||  mainForm.get('NameEn').touched)">
                            {{"ContractorNameEnRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>
                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"ContractorCode" | translate}}</label>
                        <input *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                            type="text" name="Code" class="form-control" formControlName="Code"
                            placeholder='{{"ContractorCode" | translate}}'>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="Code">{{modelObject.Code}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('Code').hasError('required') || mainForm.get('Code').invalid) && ( mainForm.get('Code').dirty ||  mainForm.get('Code').touched)">
                            {{"ContractorCodeRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>
                </div>

                <br>

                <div class="row">
                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"ContractorPhone" | translate}}</label>
                        <input *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'" type="text"
                            name="Phone" class="form-control" formControlName="Phone"
                            placeholder='{{"ContractorPhone" | translate}}'>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="Phone">{{modelObject.Phone}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('Phone').hasError('required') || mainForm.get('Phone').invalid) && ( mainForm.get('Phone').dirty ||  mainForm.get('Phone').touched)">
                            {{"ContractorPhoneRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>
                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"ContractorNationality" | translate}}</label>
                        <input *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'" type="text"
                            name="Nationality" class="form-control" formControlName="Nationality"
                            placeholder='{{"ContractorNationality" | translate}}'>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="Nationality">{{modelObject.Nationality}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('Nationality').hasError('required') || mainForm.get('Nationality').invalid) && ( mainForm.get('Nationality').dirty ||  mainForm.get('Nationality').touched)">
                            {{"ContractorNationalityRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>
                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"ContractorBankAccount" | translate}}</label>
                        <input *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'" type="text"
                            name="BankAccount" class="form-control" formControlName="BankAccount"
                            placeholder='{{"ContractorBankAccount" | translate}}'>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="BankAccount">{{modelObject.BankAccount}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('BankAccount').hasError('required') || mainForm.get('BankAccount').invalid) && ( mainForm.get('BankAccount').dirty ||  mainForm.get('BankAccount').touched)">
                            {{"ContractorBankAccountRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>
                </div>

                <br>
                
                <div class="row">
                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"ContractorIdNumber" | translate}}</label>
                        <input *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'" type="text"
                            name="IdNumber" class="form-control" formControlName="IdNumber"
                            placeholder='{{"ContractorIdNumber" | translate}}'>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="IdNumber">{{modelObject.IdNumber}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('IdNumber').hasError('required') || mainForm.get('IdNumber').invalid) && ( mainForm.get('IdNumber').dirty ||  mainForm.get('IdNumber').touched)">
                            {{"ContractorIdNumberRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>
                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"ContractorCommercialRegister" | translate}}</label>
                        <input *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'" type="text"
                            name="CommercialRegister" class="form-control" formControlName="CommercialRegister"
                            placeholder='{{"ContractorCommercialRegister" | translate}}'>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="CommercialRegister">{{modelObject.CommercialRegister}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('CommercialRegister').hasError('required') || mainForm.get('CommercialRegister').invalid) && ( mainForm.get('CommercialRegister').dirty ||  mainForm.get('CommercialRegister').touched)">
                            {{"ContractorCommercialRegisterRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>
                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"ContractorTaxCard" | translate}}</label>
                        <input *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'" type="text"
                            name="TaxCard" class="form-control" formControlName="TaxCard"
                            placeholder='{{"ContractorTaxCard" | translate}}'>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="TaxCard">{{modelObject.TaxCard}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('TaxCard').hasError('required') || mainForm.get('TaxCard').invalid) && ( mainForm.get('TaxCard').dirty ||  mainForm.get('TaxCard').touched)">
                            {{"ContractorTaxCardRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>
                </div>

                <br>
                
                <div class="row">
                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"ContractorCountry" | translate}}</label>
                        <div *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'">
                            <ng-select appearance="outline" name="CountryId" formControlName="CountryId"
                                placeholder='{{"ContractorCountry" | translate}}' #ContractorCountrySelect labelForId="txtycd"
                                [searchable]="true">
                                <ng-option *ngFor="let country of countryList" [value]="country.Id">
                                    {{lang==='ar-EG'?country.NameAr:country.NameEn}}</ng-option>
                            </ng-select>
                        </div>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="CountryId">{{lang==='ar-EG'?getCountryById(modelObject.CountryId).NameAr:getCountryById(modelObject.CountryId).NameEn}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('CountryId').hasError('required') || mainForm.get('CountryId').invalid) && ( mainForm.get('CountryId').dirty ||  mainForm.get('CountryId').touched)">
                            {{"ContractorCountryRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>
                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"ContractorCity" | translate}}</label>
                        <div *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'">
                            <ng-select appearance="outline" name="CityId" formControlName="CityId"
                                placeholder='{{"ContractorCity" | translate}}' #ContractorCitySelect labelForId="txtycd"
                                [searchable]="true">
                                <ng-option *ngFor="let city of cityList" [value]="city.Id">
                                    {{lang==='ar-EG'?city.NameAr:city.NameEn}}</ng-option>
                            </ng-select>
                        </div>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="CityId">{{lang==='ar-EG'?getCityById(modelObject.CityId).NameAr:getCityById(modelObject.CityId).NameEn}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('CityId').hasError('required') || mainForm.get('CityId').invalid) && ( mainForm.get('CityId').dirty ||  mainForm.get('CityId').touched)">
                            {{"ContractorCityRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>
                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"ContractorGovernorate" | translate}}</label>
                        <input *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'" type="text"
                            name="Governorate" class="form-control" formControlName="Governorate"
                            placeholder='{{"ContractorGovernorate" | translate}}'>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="Governorate">{{modelObject.Governorate}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('Governorate').hasError('required') || mainForm.get('Governorate').invalid) && ( mainForm.get('Governorate').dirty ||  mainForm.get('Governorate').touched)">
                            {{"ContractorGovernorateRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>
                </div>
            </div>

            <br>
            
            <div class="row">
                <div class=" col-md-4">
                    <label class="float-label push righ">{{"ContractorZipCode" | translate}}</label>
                    <input *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'" type="text"
                        name="ZipCode" class="form-control" formControlName="ZipCode"
                        placeholder='{{"ContractorZipCode" | translate}}'>
                    <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                        name="ZipCode">{{modelObject.ZipCode}}</label>
                    <div class="messages text-danger"
                        *ngIf=" (mainForm.get('ZipCode').hasError('required') || mainForm.get('ZipCode').invalid) && ( mainForm.get('ZipCode').dirty ||  mainForm.get('ZipCode').touched)">
                        {{"ContractorZipCodeRequired" | translate}}</div>
                    <span class="form-bar"></span>
                </div>
            </div>

            <br>
            
            <div class="row">
                <div class=" col-md-4">
                    <label class="float-label push righ">{{"ContractorStreet" | translate}}</label>
                    <input *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'" type="text"
                        name="Street" class="form-control" formControlName="Street"
                        placeholder='{{"ContractorStreet" | translate}}'>
                    <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                        name="Street">{{modelObject.Street}}</label>
                    <div class="messages text-danger"
                        *ngIf=" (mainForm.get('Street').hasError('required') || mainForm.get('Street').invalid) && ( mainForm.get('Street').dirty ||  mainForm.get('Street').touched)">
                        {{"ContractorStreetRequired" | translate}}</div>
                    <span class="form-bar"></span>
                </div>
                <div class=" col-md-4">
                    <label class="float-label push righ">{{"ContractorBuildingNumber" | translate}}</label>
                    <input *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'" type="text"
                        name="BuildingNumber" class="form-control" formControlName="BuildingNumber"
                        placeholder='{{"ContractorBuildingNumber" | translate}}'>
                    <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                        name="BuildingNumber">{{modelObject.BuildingNumber}}</label>
                    <div class="messages text-danger"
                        *ngIf=" (mainForm.get('BuildingNumber').hasError('required') || mainForm.get('BuildingNumber').invalid) && ( mainForm.get('BuildingNumber').dirty ||  mainForm.get('BuildingNumber').touched)">
                        {{"ContractorBuildingNumberRequired" | translate}}</div>
                    <span class="form-bar"></span>
                </div>
                <div class=" col-md-4">
                    <label class="float-label push righ">{{"ContractorLandmark" | translate}}</label>
                    <input *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'" type="text"
                        name="Landmark" class="form-control" formControlName="Landmark"
                        placeholder='{{"ContractorLandmark" | translate}}'>
                    <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                        name="Landmark">{{modelObject.Landmark}}</label>
                    <span class="form-bar"></span>
                </div>
            </div>

            <br>
            
            <div class="row">
                <div class=" col-md-4">
                    <label class="float-label push righ">{{"ContractorFloor" | translate}}</label>
                    <input *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'" type="text"
                        name="Floor" class="form-control" formControlName="Floor"
                        placeholder='{{"ContractorFloor" | translate}}'>
                    <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                        name="Floor">{{modelObject.Floor}}</label>
                    <div class="messages text-danger"
                        *ngIf=" (mainForm.get('Floor').hasError('required') || mainForm.get('Floor').invalid) && ( mainForm.get('Floor').dirty ||  mainForm.get('Floor').touched)">
                        {{"ContractorFloorRequired" | translate}}</div>
                    <span class="form-bar"></span>
                </div>
                <div class=" col-md-4">
                    <label class="float-label push righ">{{"ContractorRoom" | translate}}</label>
                    <input *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'" type="text"
                        name="Room" class="form-control" formControlName="Room"
                        placeholder='{{"ContractorRoom" | translate}}'>
                    <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                        name="Room">{{modelObject.Room}}</label>
                    <div class="messages text-danger"
                        *ngIf=" (mainForm.get('Room').hasError('required') || mainForm.get('Room').invalid) && ( mainForm.get('Room').dirty ||  mainForm.get('Room').touched)">
                        {{"ContractorRoomRequired" | translate}}</div>
                    <span class="form-bar"></span>
                </div>
            </div>
            
            <br>
            
            <div class="row" style="height: 160px;">
                <div class=" col-md-4">
                    <label class="float-label push righ">{{"ContractorDescription" | translate}}</label>
                    <textarea *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'" type="text"
                        name="Description" class="form-control" formControlName="Description"
                        style="width: 600px; height: 150px;"
                        placeholder='{{"ContractorDescription" | translate}}'></textarea>
                    <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                        style="white-space:pre-wrap; word-wrap:break-word; width: 600px; height: 150px; overflow: scroll;"
                        name="Description">{{modelObject.Description}}</label>
                    <span class="form-bar"></span>
                </div>
            </div>

            <br>

            <!-- <div id="divmiddle" style="display: block; margin-top: 5px;"> -->
                <div>
                <div style="width: 100%; margin-top: 15px;">
                    <div>
                        <table class="table">
                            <thead class="thead-light">
                                <th></th>
                                <th>{{"WorkItemParent" | translate}}</th>
                                <th>{{"WorkItemSub" | translate}}</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let obj of addedItemList;  let i=index">
                                    <td style="text-align: center; max-width: 80px;">
                                        <button *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'" type="text"
                                        class="btn btn-danger text-center" (click)="removeContractorWorkItem(obj.Id)"
                                        [disabled]="tableEngine.currentAction==='loading'"
                                        style="font-weight: bold; width: 80px;">{{"Remove"
                                        | translate}}</button>
                                    </td>
                                    <td>
                                        {{lang==='ar-EG'?obj.ParentAr:obj.ParentEn}}
                                    </td>
                                    <td>{{lang==='ar-EG'?obj.NameAr:obj.NameEn}}</td>
                                </tr>
                                <tr *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'" type="text">
                                    <td style="text-align: center; max-width: 80px;">
                                        <button class="btn btn-info text-center" (click)="addContractorWorkItem()"
                                        [disabled]="newWorkItemId===null||newWorkItemParentId===null||tableEngine.currentAction==='loading'"
                                        style="font-weight: bold; width: 80px;">{{"Add"
                                        | translate}}</button>
                                    </td>
                                    <td>
                                        <div>
                                            <ng-select appearance="outline"
                                                name="ParentId" [(ngModel)]="newWorkItemParentId"
                                                [ngModelOptions]="{standalone: true}"
                                                placeholder='{{"WorkItemParent" | translate}}' #WorkItemParentSelect labelForId="txtycd2"
                                                [searchable]="true" (change)="changeParent()">
                                                <ng-option *ngFor="let workItem of workItemList"
                                                    [value]="workItem.Id">
                                                    {{lang==='ar-EG'?workItem.NameAr:workItem.NameEn}}</ng-option>
                                            </ng-select>
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <ng-select appearance="outline" #subItemSelect
                                                name="Id" [(ngModel)]="newWorkItemId"
                                                [ngModelOptions]="{standalone: true}"
                                                placeholder='{{"WorkItemSub" | translate}}' labelForId="txtycd3"
                                                [searchable]="true">
                                                <ng-option *ngFor="let subItem of subItemList"
                                                    [value]="subItem.Id">
                                                    {{lang==='ar-EG'?subItem.NameAr:subItem.NameEn}}</ng-option>
                                            </ng-select>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <thead class="thead-light">
                                <th></th>
                                <th></th>
                                <th></th>
                            </thead>
                        </table>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<div bsModal #delModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">
    <div class="modal-dialog modal-sm">
        <div class="modal-content"  >
            <button type="button" class="close pull-right" aria-label="Close" (click)="delModal.hide()"
                style="margin-right: 20px; margin-left: 20px; margin-top: 10px; width: 20px; display: inline;">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-header" style="display: inline; padding: 0px 0px 5px 0px;">
                <h4 id="dialog-child-name" class="modal-title pull-left" style="text-align: center;">
                    {{"ConfirmDel"+translationName |
                    translate}}</h4>
            </div>
            <div class="modal-body">
                <div class="form-group form-primary" *ngIf="tableEngine.deletionList.length < tableEngine.ogData.length 
                || tableEngine.deletionList.length === 1" style="text-align: center;">
                    <div *ngFor="let item of tableEngine.deletionList;" style="text-align: center;">
                        <label type="text">- {{lang==='ar-EG'?item.NameAr:item.NameEn}}</label>
                        <span class="form-bar"></span>
                    </div>
                </div>
                <div class="form-group form-primary" style="text-align: center;" *ngIf="tableEngine.deletionList.length > 1 && 
                    tableEngine.deletionList.length === tableEngine.ogData.length">
                    <label type="text">
                        <h1 style="font-weight: bolder; text-align: center;">{{translationName+"DeleteAll" | translate}}
                        </h1>
                    </label>
                    <span class="form-bar"></span>
                </div>

                <div style="margin-left: 5px; text-align: center; margin-top: 30px;">
                    <button class="btn btn-danger" id="smallModalDeleteButton" (click)="deleteObject()"
                        [disabled]="tableEngine.currentAction==='loading'"
                        style="font-weight: bold; width: 100%; text-align: center;">{{"Delete" | translate}}</button>
                    <button class="btn btn-light" (click)="delModal.hide()"
                        style="font-weight: bold; width: 100%; text-align: center; margin-top: 10px;">{{"Close" |
                        translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="tableView" class="FormScreen" class="tableScreen">
    <div id="divtop" style="display: block;height: 47px; width: 100%;">
        <div id="divsearchmiddle" style="display: inline-block; width: 70%;
        height: inherit;  text-align: center; padding: 0px; margin: 0px;">
            <div class="search" style="display: inline-block; margin-left: 50px; margin-right: 50px;">
                <div class="d-inline-block">
                    <div class="search-sm d-inline-block mr-1 mb-1 align-top" style="width: 600px;">
                        <input type="search" [(ngModel)]="tableEngine.SearchTerm" (keyup)="tableEngine.changeFilter()"
                            (change)="tableEngine.changeFilter()" class="form-control input-sm full-data-search"
                            placeholder="{{translationName+'Search' | translate}}">
                    </div>
                </div>
            </div>
        </div>

        <div id="divsearchleft" style="display: inline-block; width: 30%; height: inherit;">
            <div style="display: block; height: 30%; ">
                &nbsp;
            </div>
            <div class="search" style="display: block; height: 70%; text-align: center;">
                <button class="btn btn-primary d-inline-block"
                    style="font-weight: bold; text-align: center; padding: 0px; width: 150px; height: 30px;"
                    (click)="showModal(null,'Add')"
                    [disabled]="tableEngine.currentAction==='loading'">{{"Add"+translationName |
                    translate}}</button>

            </div>
        </div>

    </div>


    <div id="divmiddle" style="display: block; margin-top: 5px;">

        <div style="width: 100%;">
            <div>
                <table class="table">
                    <thead class="thead-light">
                        <th style="text-align: center; padding:5px;">
                            <div class=" text-zero" style="display: inline-block;">
                                <div class="top-right-button-container2 btn-group" dropdown>
                                    <div class="btn btn-primary pl-4 pr-0 check-button">
                                        <label class="custom-control custom-checkbox mb-0 d-inline-block"
                                            style="width: 10px;">
                                            <input type="checkbox" class="custom-control-input"
                                                [checked]="tableEngine.selectAllState==='checked'"
                                                [indeterminate]="tableEngine.selectAllState === 'indeterminate'"
                                                (change)="tableEngine.selectAll($event)">
                                            <span class="custom-control-label">&nbsp;</span>
                                        </label>
                                    </div>
                                    <button id="button-split" type="button" dropdownToggle style="width: 10px;"
                                        class="btn btn-primary dropdown-toggle dropdown-toggle-split"
                                        aria-controls="dropdown-split">
                                        <span class="caret"></span>
                                    </button>
                                    <ul id="dropdown-split" *dropdownMenu class="dropdown-menu dropdown-menu-right"
                                        role="menu" aria-labelledby="button-split">
                                        <li role="menuitem"><a class="dropdown-item">
                                                <button class="btn btn-danger d-inline-block"
                                                    style=" font-weight: bold; text-align: center; padding: 0px; width: 150px; height: 30px;"
                                                    (click)="tableEngine.showDelModal('delMulti')"
                                                    [disabled]="tableEngine.selected.length===0 || tableEngine.currentAction==='loading'">{{"DeleteSelected"
                                                    |
                                                    translate}}</button></a></li>
                                    </ul>
                                </div>
                            </div>
                        </th>

                        <th class="sorticon" (click)="prepChangeSort('ContractorName')"
                            [ngClass]="(itemOrder.label==='ContractorName')?'sortkey sorticon':'sorticon'">
                            <div
                                [ngClass]="{'iconsminds-up---down': (itemOrder.label!='ContractorName'), 
                            'iconsminds-down': ((itemOrder.label==='ContractorName') && tableEngine.orderAsc === true),
                            'iconsminds-up': ((itemOrder.label==='ContractorName') && tableEngine.orderAsc === false)}">
                            </div>
                        </th>
                        <th class="sortOption" (click)="prepChangeSort('ContractorName')"
                            [ngClass]="{'sortkey': (itemOrder.label==='ContractorName')}">
                            {{"ContractorName" | translate}}
                        </th>

                        <th class="sorticon" (click)="prepChangeSort('ContractorPhone')"
                            [ngClass]="(itemOrder.label==='ContractorPhone')?'sortkey sorticon':'sorticon'">
                            <div
                                [ngClass]="{'iconsminds-up---down': (itemOrder.label!='ContractorPhone'), 
                            'iconsminds-down': ((itemOrder.label==='ContractorPhone') && tableEngine.orderAsc === true),
                            'iconsminds-up': ((itemOrder.label==='ContractorPhone') && tableEngine.orderAsc === false)}">
                            </div>
                        </th>
                        <th class="sortOption" (click)="prepChangeSort('ContractorPhone')"
                            [ngClass]="{'sortkey': (itemOrder.label==='ContractorPhone')}">
                            {{"ContractorPhone" | translate}}
                        </th>

                        <th class="sorticon" (click)="prepChangeSort('ContractorIdNumber')"
                            [ngClass]="(itemOrder.label==='ContractorIdNumber')?'sortkey sorticon':'sorticon'">
                            <div
                                [ngClass]="{'iconsminds-up---down': (itemOrder.label!='ContractorIdNumber'), 
                            'iconsminds-down': ((itemOrder.label==='ContractorIdNumber') && tableEngine.orderAsc === true),
                            'iconsminds-up': ((itemOrder.label==='ContractorIdNumber') && tableEngine.orderAsc === false)}">
                            </div>
                        </th>
                        <th class="sortOption" (click)="prepChangeSort('ContractorIdNumber')"
                            [ngClass]="{'sortkey': (itemOrder.label==='ContractorIdNumber')}">
                            {{"ContractorIdNumber" | translate}}
                        </th>


                        <th class="sorticon" (click)="prepChangeSort('ContractorCountry')"
                            [ngClass]="(itemOrder.label==='ContractorCountry')?'sortkey sorticon':'sorticon'">
                            <div
                                [ngClass]="{'iconsminds-up---down': (itemOrder.label!='ContractorCountry'), 
                            'iconsminds-down': ((itemOrder.label==='ContractorCountry') && tableEngine.orderAsc === true),
                            'iconsminds-up': ((itemOrder.label==='ContractorCountry') && tableEngine.orderAsc === false)}">
                            </div>
                        </th>
                        <th class="sortOption" (click)="prepChangeSort('ContractorCountry')"
                            [ngClass]="{'sortkey': (itemOrder.label==='ContractorCountry')}">
                            {{"ContractorCountry" | translate}}
                        </th>

                        <th class="sorticon" (click)="prepChangeSort('ContractorDescription')"
                            [ngClass]="(itemOrder.label==='ContractorDescription')?'sortkey sorticon':'sorticon'">
                            <div
                                [ngClass]="{'iconsminds-up---down': (itemOrder.label!='ContractorDescription'), 
                            'iconsminds-down': ((itemOrder.label==='ContractorDescription') && tableEngine.orderAsc === true),
                            'iconsminds-up': ((itemOrder.label==='ContractorDescription') && tableEngine.orderAsc === false)}">
                            </div>
                        </th>
                        <th class="sortOption" (click)="prepChangeSort('ContractorDescription')"
                            [ngClass]="{'sortkey': (itemOrder.label==='ContractorDescription')}">
                            {{"ContractorDescription" | translate}}
                        </th>

                        <th></th>
                        <th [colSpan]="2" style="text-align: center;">
                            <div id="displayOptions" style="
                            display: inline; margin-top: 10px; margin-left: 5px; margin-right: 5px;">
                                <span class="text-muted text-small" style=" width: 50px; height: 40px;">
                                    {{"ResultCount" | translate}}
                                </span>
                                <span dropdown class="d-inline-block position-relative">
                                    <button class="btn btn-outline-dark btn-xs dropdown-toggle" type="button"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                        dropdownToggle>
                                        {{tableEngine.itemsPerPage}}
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-sm text-right"
                                        *dropdownMenu>
                                        <li role="menuitem" *ngFor="let item of tableEngine.itemOptionsPerPage"><a
                                                [ngClass]="item === tableEngine.itemsPerPage ? 'dropdown-item active' : 'dropdown-item'"
                                                (click)="tableEngine.paginateData(item, 1)">{{item}}</a></li>
                                    </div>
                                </span>
                            </div>
                        </th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let obj of tableEngine.paginatedData;  let i=index">
                            <td style="text-align: center;">
                                <div class="itemCheck mb-0 custom-checkbox custom-control"
                                    style="display: inline-block;">
                                    <input type="checkbox" class="custom-control-input" [id]="'customCheck'+i"
                                        [checked]="tableEngine.isSelected(obj)" (change)="tableEngine.onSelect(obj)">
                                    <label class="custom-control-label" [for]="'customCheck'+i"></label>
                                </div>
                            </td>
                            <td colspan="2">{{lang==='ar-EG'?obj.NameAr:obj.NameEn}}</td>
                            <td colspan="2">{{obj.Phone}}</td>
                            <td colspan="2">{{obj.IdNumber}}</td>
                            <td colspan="2">
                                {{lang==='ar-EG'?getCountryById(obj.CountryId).NameAr:getCountryById(obj.CountryId).NameEn}}
                            </td>
                            <td colspan="2">{{obj.Description}}</td>
                            <td><button class="btn btn-info text-center" (click)="showModal(obj.Id, 'Info')"
                                    [disabled]="tableEngine.currentAction==='loading'"
                                    style="font-weight: bold; width: 100%;">{{"More"
                                    | translate}}</button></td>
                            <td><button class="btn btn-outline-warning text-center" (click)="showModal(obj.Id, 'Edit')"
                                    [disabled]="tableEngine.currentAction==='loading'"
                                    style="font-weight: bold; width: 100%;">{{"Edit"
                                    | translate}}</button></td>
                            <td><button class="btn btn-outline-danger text-center"
                                    (click)="tableEngine.showDelModal(obj.Id)"
                                    [disabled]="tableEngine.currentAction==='loading'"
                                    style="font-weight: bold; width: 100%">{{"Delete"
                                    | translate}}</button></td>
                        </tr>
                    </tbody>
                    <thead class="thead-light">
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </thead>
                </table>
            </div>
        </div>
    </div>

    <div id="divbottom" style="display: block; height: 47px;">

        <div id="divsearchright" style="float: right;  width: 33.33%; padding: 0px;">&nbsp;</div>

        <div id="divsearchmiddle" style="display: inline-block; width: 33.33%; height: 100%;">

            <div style="text-align: center;">
                <bdo dir="ltr">
                    <pagination class="pagination-sm2" style="padding: 0px, 0px, 0px, 0px; margin: 0px, 0px, 0px, 0px;
                    display: inline-block;" [(ngModel)]="currentPage" [boundaryLinks]="true"
                        [totalItems]="tableEngine.filteredData.length" [itemsPerPage]="tableEngine.itemsPerPage"
                        [customFirstTemplate]="firstTemplate" [customLastTemplate]="lastTemplate"
                        [customPreviousTemplate]="prevTemplate" [customNextTemplate]="nextTemplate" [maxSize]="5"
                        (pageChanged)="tableEngine.changePage($event)">
                    </pagination>
                </bdo>
            </div>
        </div>

        <div id="divsearchleft" style="float: left; width: 33.33%; height: 100%;">&nbsp;</div>

    </div>
</div>

<div>

</div>

<ng-template #nextTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i *ngIf="lang==='en-US'" class='simple-icon-arrow-right'></i>
    <i *ngIf="lang==='ar-EG'" class='simple-icon-arrow-left'></i>
</ng-template>

<ng-template #prevTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i *ngIf="lang==='en-US'" class='simple-icon-arrow-left'></i>
    <i *ngIf="lang==='ar-EG'" class='simple-icon-arrow-right'></i>
</ng-template>

<ng-template #lastTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i class='simple-icon-control-end'></i>
</ng-template>

<ng-template #firstTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i class='simple-icon-control-start'></i>
</ng-template>


