// import { StoreItemsModel } from "./StoreItemsModel";


export class BrandItemsModel{
    Id?: number;
    ItemId?:number;
    BrandId?:number;


    constructor(item?:BrandItemsModel){
        item = item? item:{};
        this.Id = item.Id? item.Id:0;
        this.ItemId = item.ItemId? item.ItemId:0;
        this.BrandId = item.BrandId? item.BrandId:0;     

    }

}