import { ItemModel } from "../items/ItemModel";

export class InvoiceModel{
    Id?:number;
    InvoiceNumber?:string;
    TaxActivityCode?:string;
    Type?:string;
    SalesOrder?:string;
    PurchaseOrder?:string;
    BranchId?:number;
    ReleaseDate?:Date;
    PurchaseDate?:Date;
    Status?:string;
    TotalPrice?:number;
    ClientId?:number;
    uuid?:string;
    Items?:ItemModel[];
    totalAmount?:number;
    constructor(obj?:InvoiceModel){
        obj = obj? obj:{};
        this.Id = obj.Id? obj.Id:0;
        this.totalAmount = obj.totalAmount? obj.totalAmount:0;
        this.InvoiceNumber = obj.InvoiceNumber? obj.InvoiceNumber:'';
        this.TaxActivityCode = obj.TaxActivityCode? obj.TaxActivityCode:'';
        this.SalesOrder = obj.SalesOrder? obj.SalesOrder:'';
        this.PurchaseOrder = obj.PurchaseOrder? obj.PurchaseOrder:'';
        this.Type = obj.Type? obj.Type:'';
        this.uuid = obj.uuid? obj.uuid:'';
        this.ReleaseDate = obj.ReleaseDate? obj.ReleaseDate:new Date();
        this.PurchaseDate = obj.PurchaseDate? obj.PurchaseDate:new Date();
        this.BranchId = obj.BranchId? obj.BranchId:0;
        this.Status = obj.Status?obj.Status :'';
        this.TotalPrice = obj.TotalPrice? obj.TotalPrice:0;
        this.ClientId = obj.ClientId? obj.ClientId:0;
        this.Items = obj.Items? obj.Items:[];
    }
}