

<div class="row">
    <div class="col-12">
      <div class="card mb-4">
        <div class="card-body">
          <!-- <h5 class="mb-4">{{ 'From Date' | translate }}</h5> -->
          <div class="row">
            <div class="col-12 col-lg-4 mb-4">
                <label><span>{{ 'DateFrom' | translate }}</span></label>
                <input 
                type="text" onfocus="(this.type='date')" onblur="if(!this.value)this.type='text'"
                onchange="if(!this.value)this.type='text'" name="Date" class="form-control"
                [(ngModel)]="search.From"
                placeholder='{{"DateFrom" | translate}}'>
              </div>
              <div class="col-12 col-lg-4 mb-4">
                <label><span>{{ 'DateTo' | translate }}</span></label>
                <input 
                type="text" onfocus="(this.type='date')" onblur="if(!this.value)this.type='text'"
                onchange="if(!this.value)this.type='text'" name="Date" class="form-control"
                [(ngModel)]="search.To"
                placeholder='{{"DateTo" | translate}}'>
              </div>
         
              <div class="col-12 col-lg-4 mb-4">
                <label><span>{{ 'Warehouse' | translate }}</span></label>
           
                <ng-select placeholder='{{"Warehouse" | translate}}' [items]="warehouses" bindLabel="Name" bindValue="Id" [(ngModel)]="search.WarehouseId">
                </ng-select>
              
              </div>
         
            <div class="col-12 col-lg-6 mb-4 "  >
              <button type="button" (click)="Search()" class="btn btn-primary default mb-1" style="margin: 1px;">{{'Search' | translate}}</button>{{" "}}
              <button type="button" (click)="print()" class="btn btn-secondary default mb-1 " style="margin: 1px;">{{'Print' | translate}}</button>{{" "}}
              <button type="button" (click)="exportTable()" class="btn btn-success default mb-1" style="margin: 1px;">{{'PDF' | translate}}</button>{{" "}}
            </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  <div id="contentToConvert">
    <div class="row" >
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-body">
              <h3 class="float-center" style="text-align: center;">{{ 'Detailed Of Inventory Operations' | translate }}</h3>
              <h4 class="float-center" style="text-align: center;">{{  today| date:'dd/MM/yyyy' }}</h4>
              <h6 class="float-center" style="text-align: center;">{{ 'Future Core' | translate }}</h6>
             
          </div>
        </div>
      </div>
    </div>
  
  
  <div class="card mb-12 tree-explorer-side col-md-12" *ngIf="data">
      <div class="card-body ">
      <div class="row">
          <table class="table table-bordered" >
      
              <thead>
              
                <tr class="active">
                                    <th>{{ 'Date' | translate }} </th>
                                    <th> {{ 'Type' | translate }}</th>
                                    <th> {{ 'Product Name' | translate }}</th>
                                    <th>{{ 'Incoming' | translate }} </th>
                                    <th>{{ 'The outgoing' | translate }}</th>
                            </tr>
                </thead>
              <tbody>
                  <tr *ngFor="let item of data">
                    <td>{{item.Date|date:'dd/MM/yyyy'}}</td>
                      <td>{{item.Type | translate}}</td>
                      <td>{{item.ProductName}}</td>
                      <td>{{item.Income}}</td>
                      <td>{{item.Outcome}}</td>
                 
                  </tr>
               
              </tbody>
          </table>
      
      </div>
  
      </div>
    </div>
  </div>