import { AccountsCostCentersModel } from './models/account-cost-center.model';
import { CostCenterModel } from './../cost-center/models/cost-center.model';
import { CostCenterService } from './../cost-center/services/cost-center.service';
import { AccountBalanceFooterModel } from './../account-balance/AccountBalanceModel';
import { Component, Input, OnInit } from '@angular/core';
import { AccountsCostCentersService } from './services/account-cost-center.service';
import { ActivatedRoute } from '@angular/router';
@Component({
    selector: 'assign-cost-centers',
    templateUrl: 'assign-cost-centers.component.html',
    styleUrls: ['assign-cost-centers.component.scss']
})
export class AssignCostCentersComponent implements OnInit{

    accountCostCentes:AccountBalanceFooterModel[];
    @Input()
    AccountId:number = 0;
    centers:CostCenterModel[]=[];
    selectedCenters:AccountsCostCentersModel[]=[];
  
    columns = [
      { prop: 'CostCenterId', name: 'Cost Center' },
      { prop: 'Percentage', name: 'Percentage' },
      { prop: 'Auto', name: 'Auto' }
    ];
    rows:any;
    temp:any;
    constructor(
        private service:AccountsCostCentersService,
        public route:ActivatedRoute,
        private costCenterService:CostCenterService
        ){
            // this.AccountId = + route.snapshot.paramMap.get('AccountId');
        }
    ngOnInit(): void {
        console.log('this.AccountId',this.AccountId)
        if(this.AccountId > 0){
            this.service.GetByAccountId(this.AccountId)
            .subscribe(data => {
                this.selectedCenters = data;
                if(this.selectedCenters.length==0){
                    this.selectedCenters.push( {AccountId:this.AccountId,Auto:false,CostCenterId:0,Id:0,Percentage:0,IsDeleted:false})
                }
                this.rows = this.selectedCenters.map(({ CostCenterId, Percentage, Auto, Id, IsDeleted }) => ({ CostCenterId, Percentage, Auto, Id, IsDeleted }));
                this.temp = [...this.rows];
            })
        }
        this.costCenterService.GetAllSub(0)
        .subscribe(data => {
            this.centers = data;
        })
    
    }
    splitCostCenter($event){
        debugger;
        var i = this.centers.findIndex(x=>x.Id == $event.Id);
        if(i > -1){
            console.log('selected',this.selectedCenters);
            this.centers.splice(i,1);
        }
    }
    AddNewCost(){
        var cost=new AccountsCostCentersModel();
        cost.AccountId = this.AccountId;
        cost.Auto = false;
        cost.CostCenterId = 0;
        cost.Id = 0;
        cost.IsDeleted = false;
        cost.Percentage = 0;
        this.selectedCenters.push(cost);
    }
    deleteSelectedCost(item){
        var i = this.selectedCenters.findIndex(x=>x.CostCenterId == item.CostCenterId && x.Auto == item.Auto && x.Percentage == item.Percentage && x.Id == item.Id);
        if(i>-1){
            this.selectedCenters[i].IsDeleted = true;
        }

    }
    saveCostCenters():boolean{
        debugger;
        var r = true;
            this.service.update(this.selectedCenters)
            .subscribe(result=>{
                r = result;
            })
            return r;
    }
    chickValue(item){
        debugger;
        var total = 0;
        this.selectedCenters.forEach(element => {
            total += element.Percentage;
        });
        total -=item.Percentage;
        var i = this.selectedCenters.findIndex(x=>x.CostCenterId == item.CostCenterId && x.Auto == item.Auto && x.Percentage == item.Percentage && x.Id == item.Id)
        if((total + item.Percentage) > 100){
            if(i > -1){
                this.selectedCenters[i].Percentage = 100 - total;
            }
        }
        if(item.Percentage < 0){
            if(i > -1){
                this.selectedCenters[i].Percentage = 0;
            }
        }
    }
}
