import { QuotationFileModel } from "./QuotationFileModel";
import { QuotationItemModel } from "./QuotationItemModel";
import { QuotationServiceModel } from "./QuotationServiceModel";

export class QuotationDetailsModel {
    Id?: number;
    Code?: number;
    Date?: Date;
    ClientId?: number;
    ClientNameAr?: string;
    ClientNameEn?: string;
    DiscountValue?: number;
    DiscountIsRate?: boolean;
    ShippingFees?: number;
    ShippingAddress1?: string;
    ShippingAddress2?: string;
    Description?: string;
    Items?: QuotationItemModel[];
    TotalItemPrices?: number;    //base prices, without taxes or discounts
    TotalItemDiscounts?: number;
    TotalItemNetPrices?: number;
    TotalItemTaxes?: number;
    TotalItemGrossPrices?: number;  //final prices
    Services?: QuotationServiceModel[];
    TotalServices?: number;
    TotalQuotationValue?: number;
    Files?: QuotationFileModel[];

    constructor(obj?: QuotationDetailsModel) {
        obj = obj ? obj : {};
        this.Id = obj.Id ? obj.Id : 0;
        this.Code = obj.Code ? obj.Code : 0;
        this.Date = obj.Date ? obj.Date : new Date();
        this.ClientId = obj.ClientId ? obj.ClientId : 0;
        this.ClientNameAr = obj.ClientNameAr ? obj.ClientNameAr : '';
        this.ClientNameEn = obj.ClientNameEn ? obj.ClientNameEn : '';
        this.DiscountValue = obj.DiscountValue ? obj.DiscountValue : 0;
        this.DiscountIsRate = obj.DiscountIsRate ? obj.DiscountIsRate : true;
        this.ShippingFees = obj.ShippingFees ? obj.ShippingFees : 0;
        this.ShippingAddress1 = obj.ShippingAddress1 ? obj.ShippingAddress1 : '';
        this.ShippingAddress2 = obj.ShippingAddress2 ? obj.ShippingAddress2 : '';
        this.Description = obj.Description ? obj.Description : '';
        this.Items = obj.Items ? obj.Items : [];
        this.TotalItemPrices = obj.TotalItemPrices ? obj.TotalItemPrices : 0;
        this.TotalItemDiscounts = obj.TotalItemDiscounts ? obj.TotalItemDiscounts : 0;
        this.TotalItemNetPrices = obj.TotalItemNetPrices ? obj.TotalItemNetPrices : 0;
        this.TotalItemTaxes = obj.TotalItemTaxes ? obj.TotalItemTaxes : 0;
        this.TotalItemGrossPrices = obj.TotalItemGrossPrices ? obj.TotalItemGrossPrices : 0;
        this.Services = obj.Services ? obj.Services : [];
        this.TotalServices = obj.TotalServices ? obj.TotalServices : 0;
        this.TotalQuotationValue = obj.TotalQuotationValue ? obj.TotalQuotationValue : 0;
        this.Files = obj.Files ? obj.Files : [];
    }

}