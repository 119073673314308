import { ClientModel } from 'src/app/invoices/clients/ClientModel';
import { ClientInvoicesDataModel } from './../../models/client-invoices-data.model';
import { Component, OnInit } from '@angular/core';
import { ClientService } from 'src/app/invoices/clients/ClientService';
import { ClientInvoicesService } from '../../client-invoices.service';
import { ClientInvoicesSearchModel } from '../../models/client-invoices-search.model';
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';
@Component({
    selector: 'client-invoices',
    templateUrl: 'client-invoices.component.html',
    styleUrls: ['client-invoices.component.scss']
})
export class ClientInvoicesComponent implements OnInit{
    declare lang: any;
    today:Date=new Date();
    list:ClientInvoicesDataModel[]=[];
    liabilities:ClientInvoicesDataModel[]=[];
    PropertyRights:ClientInvoicesDataModel[]=[];
    employees=[];
    nodeList:ClientInvoicesDataModel[]=[];
    clients : ClientModel[]=[];
    constructor(private ClientInvoicesService:ClientInvoicesService,private clientService:ClientService){
      this.lang = localStorage.getItem('theme_lang');
    }
    TotalAssets = 0;
    Totalliabilities = 0;
    TotalPropertyRights = 0;
    data:ClientInvoicesDataModel[]=[];
    assets:ClientInvoicesDataModel[]=[];
    search:ClientInvoicesSearchModel={};
    ngOnInit(): void {
        this.ClientInvoicesService.search(this.search)
        .subscribe(data => {
          this.data = data;
          this.assets = data;
          this.clientService.getAll()
          .subscribe(result =>{
            this.clients = result;
          })
        })
    
    }
    Search(){
      this.ClientInvoicesService.search(this.search)
        .subscribe(data => {
          this.data = data;
        })
    }
    exportTable(){
      var data = document.getElementById('contentToConvert');
      data.style.display = 'inline-block';
      html2canvas(data,{}).then(canvas => {
      var imgWidth = 208;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save('new-file.pdf'); // Generated PDF
      data.style.display = 'block';

    //   data.style.display = 'none';
      
    });
    }
    print(){
        let printContents, popupWin;
        printContents = document.getElementById('contentToConvert').innerHTML;
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.open();
        window.print();
       
      }
}
