import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AllAssetsComponent } from './assets/components/all-assets/all-assets.component';
import { ViewDailyRestrictionComponent } from './view-daily-restriction/view-daily-restriction.component';
import { ProjectInfoComponent } from './project-info/project-info.component';
import { AllBudgetComponent } from './budget/components/all-budget/all-budget.component';

import { AccountPowerComponent } from './account_manual_section/account-power/account-power.component';
import { AccountingManualComponent } from './account_manual_section/accounting-manual/accounting-manual.component';
import { AcountingBaseDataComponent } from './acounting-base-data/acounting-base-data.component';
import { ExpenseDefinitionComponent } from './expense-definition/expense-definition.component';
import { ExpensessTypeComponent } from './expensess-type/expensess-type.component';
import { AccountFicationsComponent} from './account-fications/account-fications.component';
import { AccountMenuComponent} from './account-menu/account-menu.component';
import { AccountFicationCostTypeComponent } from './account-fication-cost-type/account-fication-cost-type.component';
import { CheckTypesComponent } from './check-types/check-types.component';
import { CheckStatusesComponent } from './check-statuses/check-statuses.component';
import { GenerateAccountTypeComponent } from './generate-account-type/generate-account-type.component';
import { AccountTypeComponent } from './account-type/account-type.component';
import { BankCodeComponent } from './bank-code/bank-code.component';
import { CreatCountryComponent } from './creat-country/creat-country.component';
import { CreatGovernorateComponent } from './creat-governorate/creat-governorate.component';
import { CityCodeComponent } from './city-code/city-code.component';
import { AccountingDashboardComponent } from './accounting-dashboard/accounting-dashboard.component';
import { IconsComponent } from './icons/icons.component';

import { AccountBalanceComponent } from './account-balance/account-balance.component';
import {CostCenterComponent} from 'src/app//views/app/aio/acounting_components/cost-center/cost-center.component';
import { DailyrestrictionsComponent } from './dailyrestrictions/dailyrestrictions.component';
import { JournalEntriesTypeComponent } from './journal-entries-type/journal-entries-type.component';
import { AddPaymentModeComponent } from './add-payment-mode/add-payment-mode.component';
import { AccountNameComponent } from './account-name/account-name.component';
import { AccountNameActionsComponent } from './account-name/account-name-actions/account-name-actions.component';
import { LockerDataComponent } from './locker-data/locker-data.component';
import { AccountNaturesComponent } from './account-natures/account-natures.component';
import { GeneralAccountComponent } from './general-account/general-account.component';
import { AccountsComponent } from './accounts/accounts.component';
import { BalanceSheetComponent } from './accounting-reports/balance-sheet/components/balance-sheet/balance-sheet.component';
import { ExpenseCodesComponent } from './expense-codes/expense-codes.component';
import { RevenueCodesComponent } from './revenue-codes/revenue-codes.component';
import { AssignCostCentersComponent } from './assign-cost-centers/assign-cost-centers.component';
import { AllDailyrestrictionsComponent } from './all-dailyrestrictions/all-dailyrestrictions.component';
import { CatchReceiptCategoryComponent } from './catch-receipt-category/catch-receipt-category.component';
import { CreateCatchReceiptComponent } from './catch-receipt/create-catch-receipt/create-catch-receipt.component';
import { TreasuryAndBankAccountsComponent } from './treasury-and-bank-accounts/components/treasury-and-bank-accounts/treasury-and-bank-accounts.component';
import { CatchReceiptComponent } from './catch-receipt/catch-receipt.component';
import { CreateExpensesComponent } from './Expenses/components/create-expenses/create-expenses.component';
import { ExpensesComponent } from './Expenses/components/expenses/expenses.component';
import { ChecksReceivedComponent } from './checks/checks-received/components/checks-received/checks-received.component';
import { CreateChecksReceivedComponent } from './checks/checks-received/components/create-checks-received/create-checks-received.component';
import { ViewChecksRecievedComponent } from './checks/checks-received/components/view-checks-recieved/view-checks-recieved.component';
import { CheckBooksComponent } from './checks/check-book/components/check-books/check-books.component';
import { PaidChecksComponent } from './checks/paid-checks/components/paid-checks/paid-checks.component';
import { CreatePaidChecksComponent } from './checks/paid-checks/components/create-paid-checks/create-paid-checks.component';
import { ViewPaidChecksComponent } from './checks/paid-checks/components/view-paid-checks/view-paid-checks.component';

import { IncomeListComponent } from './accounting-reports/income-list/components/income-list/income-list.component';
import { DailyRestrictionsReportComponent } from './accounting-reports/daily-restrictions-report/components/daily-restrictions-report/daily-restrictions-report.component'
import { CostCenterReportComponent } from './accounting-reports/cost-center-report/components/cost-center-report/cost-center-report.component';
import { AssetsReportComponent } from './accounting-reports/assets-report/components/assets-report/assets-report.component';
import { TrialBalanceBalancesComponent } from './accounting-reports/trial-balance-balances/components/trial-balance-balances/trial-balance-balances.component';
import { PaymentPlanComponent } from './payment-plan/components/payment-plan/payment-plan.component';
import {LedgerAccountComponent  } from './accounting-reports/ledger-account/components/ledger-account/ledger-account.component';
import {TaxReportComponent  } from './accounting-reports/tax-report/components/tax-report/tax-report.component';
import { TaxReturnReportComponent } from './accounting-reports/tax-return-report/components/tax-return-report/tax-return-report.component';
import { CashFlowsComponent } from './accounting-reports/cash-flows/cash-flows.component';
import { CreateBudgetComponent } from './budget/components/create-budget/create-budget.component';
import { AllMonthlyDistributionComponent } from './budget/monthly-distribution/components/all-monthly-distribution/all-monthly-distribution.component';
import { CreateMonthlyDistributionComponent } from './budget/monthly-distribution/components/create-monthly-distribution/create-monthly-distribution.component';
import { BudgetReportComponent } from './budget/budget-report/components/budget-report/budget-report.component';
import { ViewAssetComponent } from './assets/components/view-asset/view-asset.component';
import { FiscalYearComponent } from './fiscal-years/components/fiscal-year/fiscal-year.component';

const routes: Routes = [
  {
      path: '', component: AccountingDashboardComponent,
  },
   { path: 'Finance', component: AccountingDashboardComponent },
   { path: 'AccountingManualComponent', component: AccountingManualComponent },
        { path: 'AcountingBaseDataComponent', component: AcountingBaseDataComponent},
        { path: 'AccountPowerComponent', component: AccountPowerComponent },
        { path: 'ExpenseDefinitionComponent', component: ExpenseDefinitionComponent },
        { path: 'ProjectInfoComponent', component: ProjectInfoComponent },
        { path: 'ExpensessType', component: ExpensessTypeComponent },
        { path: 'AccountFications', component: AccountFicationsComponent },
        { path: 'AccountMenu', component: AccountMenuComponent },
        { path: 'AccountFicationCostType', component: AccountFicationCostTypeComponent },
        { path: 'CheckTypes', component: CheckTypesComponent },
        { path: 'CheckStatuses', component: CheckStatusesComponent },
        { path: 'GenerateAccountType', component: GenerateAccountTypeComponent },
        { path: 'AccountType', component: AccountTypeComponent },
        { path: 'BankCode', component: BankCodeComponent },
        { path: 'CreatCountry', component: CreatCountryComponent },
        { path: 'CreatGovernorate', component: CreatGovernorateComponent },
        { path: 'CityCode', component: CityCodeComponent },
        { path: 'AccountBalance', component: AccountBalanceComponent },
        { path: 'CostCenter', component: CostCenterComponent },
        { path: 'Dailyrestrictions/:Id', component: DailyrestrictionsComponent },
        { path: 'JournalEntriesType', component: JournalEntriesTypeComponent },
        { path: 'AddPaymentMode', component: AddPaymentModeComponent },
        { path: 'AccountName', component: AccountNameComponent },
        { path: 'AccountName/AccountNameActions/:state/:id', component: AccountNameActionsComponent },
        { path: 'LockerData', component: LockerDataComponent },
        { path: 'AccountNatures', component: AccountNaturesComponent },
        { path: 'GeneralAccount', component: GeneralAccountComponent },
        { path: 'Accounts', component: AccountsComponent },
        { path: 'BalanceSheet', component: BalanceSheetComponent },
        { path: 'Expenses', component: ExpenseCodesComponent },
        { path: 'Revenues', component: RevenueCodesComponent },
        { path: 'assign-cost-centers/:AccountId', component: AssignCostCentersComponent },
        { path: 'all-daily', component: AllDailyrestrictionsComponent },
        { path: 'view-daily-restriction/:Id', component: ViewDailyRestrictionComponent },
        { path: 'create-catch-receipt/:Id', component: CreateCatchReceiptComponent },
        { path: 'catch-receipt-categories', component: CatchReceiptCategoryComponent },
        { path: 'assets', component: AllAssetsComponent },
        { path: 'catch-receipt',component:CatchReceiptComponent},
        
        { path: 'treasury-and-bank-accounts', component: TreasuryAndBankAccountsComponent },
        // { path: 'accounts', component: AccountingDashboardComponent },
        { path: 'icons', component: IconsComponent },
        { path: 'expenses', component: ExpensesComponent },
        { path: 'create-expenses/:Id', component: CreateExpensesComponent },
        { path: 'checks-received', component: ChecksReceivedComponent },
        { path: 'create-checks-received/:Id', component: CreateChecksReceivedComponent },
        { path: 'view-checks-received/:Id', component: ViewChecksRecievedComponent },
        { path: 'paid-checks', component: PaidChecksComponent },
        { path: 'create-paid-checks/:Id', component: CreatePaidChecksComponent },
        { path: 'view-paid-checks/:Id', component: ViewPaidChecksComponent },
        { path: 'check-book', component: CheckBooksComponent },
        { path: 'income-list', component: IncomeListComponent },
        { path: 'daily-restrictions-report', component: DailyRestrictionsReportComponent },
        { path: 'cost-center-report', component: CostCenterReportComponent },
        { path: 'assets-report', component: AssetsReportComponent },
        { path: 'trial-balance-balances', component: TrialBalanceBalancesComponent },
        { path: 'payment-plan', component: PaymentPlanComponent},
        { path: 'ledger-account', component: LedgerAccountComponent},
        { path: 'tax-report', component: TaxReportComponent},
        { path: 'tax-return-report', component: TaxReturnReportComponent},
        { path: 'budgets', component: AllBudgetComponent},
        { path: 'budget-report', component: BudgetReportComponent},
        { path: 'create-budget/:Id', component: CreateBudgetComponent},
        { path: 'monthly-distribution', component: AllMonthlyDistributionComponent},
        { path: 'create-monthly-distribution/:Id', component: CreateMonthlyDistributionComponent},
        { path: 'cash-flows', component: CashFlowsComponent } ,
        { path: 'view-asset/:Id', component: ViewAssetComponent } ,
        { path: 'fiscal-years', component: FiscalYearComponent }
        
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AccountingRoutingModule {}
