import { ReturnPurchaseItemModel } from "./ReturnPurchaseItemModel";

export class ReturnPurchaseModel {
      Id?: number;
      Code?: number;
      PurchaseInvoiceId?: number;
      PurchaseInvoiceCode?: number;
      SupplierId?: number;
      SupplierNameAr?: string;
      SupplierNameEn?: string;
      PaymentMethodId?: number;
      PaymentMethodNameAr?: string;
      PaymentMethodNameEn?: string;
      CompanyDataId?: number;
      BranchId?: number;
      BranchNameAr?: string;
      BranchNameEn?: string;
      //StoreDataId?: number;
      //StoreNameAr?: string;
      //StoreNameEn?: string;
      Barcode?: string;
      Year?: number;
      Month?: number;
      Date?: Date;
      Description?: string;
      TotalItemPrices?: number;
      TotalItemDiscounts?: number;
      TotalItemNetPrices?: number;
      TotalItemTaxes?: number;
      TotalItemGrossPrices?: number;
      IsPosted?: boolean;
      Items?: ReturnPurchaseItemModel [];
      constructor(obj?: ReturnPurchaseModel) {
          obj = obj ? obj : {};
          this.Id = obj.Id ? obj.Id : 0;
          this.Code = obj.Code ? obj.Code : 0;
          this.PurchaseInvoiceId = obj.PurchaseInvoiceId ? obj.PurchaseInvoiceId : 0;
          this.PurchaseInvoiceCode = obj.PurchaseInvoiceCode ? obj.PurchaseInvoiceCode : 0;
          this.SupplierId = obj.SupplierId ? obj.SupplierId : 0;
          this.SupplierNameAr = obj.SupplierNameAr ? obj.SupplierNameAr : '';
          this.SupplierNameEn = obj.SupplierNameEn ? obj.SupplierNameEn : '';
          this.PaymentMethodId = obj.PaymentMethodId ? obj.PaymentMethodId : 0;
          this.PaymentMethodNameAr = obj.PaymentMethodNameAr ? obj.PaymentMethodNameAr : '';
          this.PaymentMethodNameEn = obj.PaymentMethodNameEn ? obj.PaymentMethodNameEn : '';
          this.CompanyDataId = obj.CompanyDataId ? obj.CompanyDataId : 0;
          this.BranchId = obj.BranchId ? obj.BranchId : 0;
          this.BranchNameAr = obj.BranchNameAr ? obj.BranchNameAr : '';
          this.BranchNameEn = obj.BranchNameEn ? obj.BranchNameEn : '';
          //this.StoreDataId = obj.StoreDataId ? obj.StoreDataId : 0;
          //this.StoreNameAr = obj.StoreNameAr ? obj.StoreNameAr : '';
          //this.StoreNameEn = obj.StoreNameEn ? obj.StoreNameEn : '';
          this.Barcode = obj.Barcode ? obj.Barcode : '';
          this.Year = obj.Year ? obj.Year : 0;
          this.Month = obj.Month ? obj.Month : 0;
          this.Date = obj.Date ? obj.Date : new Date();
          this.Description = obj.Description ? obj.Description : '';
          this.TotalItemPrices = obj.TotalItemPrices ? obj.TotalItemPrices : 0;
          this.TotalItemDiscounts = obj.TotalItemDiscounts ? obj.TotalItemDiscounts : 0;
          this.TotalItemNetPrices = obj.TotalItemNetPrices ? obj.TotalItemNetPrices : 0;
          this.TotalItemTaxes = obj.TotalItemTaxes ? obj.TotalItemTaxes : 0;
          this.TotalItemGrossPrices = obj.TotalItemGrossPrices ? obj.TotalItemGrossPrices : 0;
          this.IsPosted = obj.IsPosted ? obj.IsPosted : false;
          this.Items = obj.Items ? obj.Items : [];
      }
  
  }
  