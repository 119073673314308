<div class="row">
  <div class="col-12">
      <div class="card-body">
          <!-- <a type="button" [routerLink]="[ '../../Dailyrestrictions', obj.Id ]" class="btn btn-primary default mb-1">{{'Edit' | translate}}</a>{{" "}} -->
          <button type="button" (click)="print()" class="btn btn-secondary default mb-1">{{'Print' | translate}}</button>{{" "}}
          <button type="button" (click)="exportTable()" class="btn btn-success default mb-1">{{'PDF' | translate}}</button>{{" "}}
        </div>
    <div class="display-options-container">
      <div class="separator mb-4"></div>
    </div>
  </div>
</div>

<div class="row invoice-angular" id="contentToConvert">
    <div class="col-12 mb-5">
      <div class="card mb-5 invoice-contents">
        <div class="card-body d-flex flex-column justify-content-between">
          <div class="d-flex flex-column">
            <div class="d-flex flex-row justify-content-between pt-2 pb-2">
              <div class="d-flex align-self-center">
                <img src="assets/logos/Logo.png" />
              </div>
              <div class="d-flex w-30 text-right align-self-center">
                <p class="text-small text-semi-muted mb-0">119 مصر حلوان الزراعي - الدور الثالث - المعادي - مصر
                    <br />+2 022525495
                </p>
              </div>
            </div>
            <div class="border-bottom pt-4 mb-5"></div>
  
            <div class="d-flex flex-row justify-content-between mb-5">
              <div class="d-flex flex-column w-70 mr-2 p-4 text-semi-muted bg-semi-muted">
                <p class="mb-0">{{'Invoice To : '|translate}}</p>
                <p class="mb-0">{{lang==='ar-EG'?invoice.ClientNameAr:invoice.ClientNameEn}}</p>
              </div>
              <div class="d-flex w-30 flex-column text-right p-4 text-semi-muted bg-semi-muted">
                <p class="mb-0">{{'Invoice Number'|translate}} : # {{invoice.InvoiceNumber}}</p>
                <p class="mb-0">{{invoice.ReleaseDate|date:'dd/MM/yyyy'}}</p>
              </div>
            </div>
            <table class="table table-borderless">
              <thead>
                <tr>
                  <th class="text-muted text-extra-small mb-2">{{'Item'|translate}}</th>
                  <th class="text-muted text-extra-small mb-2">{{'Quantity'|translate}}</th>
                  <th class="text-muted text-extra-small mb-2">{{'Price'|translate}}</th>
                  <th class="text-muted text-extra-small mb-2">{{'Discount'|translate}}</th>
                  <th class="text-right text-muted text-extra-small mb-2">{{'Total'|translate}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of invoice.Items">
                  <td>{{lang==='ar-EG'?item.NameAr:item.NameEn}}</td>
                  <td>{{item.Quantity| number}}</td>
                  <td >{{item.Price| number}}</td>
                  <td >{{item.DiscountAmount| number}}</td>
                  <td class="text-right">{{item.NetTotal| number}}</td>
                </tr>
         
              </tbody>
            </table>
          </div>
          <div class="d-flex flex-column">
            <div class="border-bottom pt-3 mb-5"></div>
            <table class="table table-borderless d-flex justify-content-end">
              <tbody>
                <tr>
                  <td class="text-semi-muted">{{'Subtotal'|translate}} :</td>
                  <td class="text-right">{{invoice.netAmount| number}}</td>
                </tr>
                <tr>
                  <td class="text-semi-muted">{{'Taxes'|translate}} :</td>
                  <td class="text-right">{{invoice.totalAmount - (invoice.totalDiscountAmount + invoice.netAmount)| number}}</td>
                </tr>
                <tr>
                  <td class="text-semi-muted">{{'Discount'|translate}} :</td>
                  <td class="text-right">{{invoice.totalDiscountAmount| number}}</td>
                </tr>
                <tr class="font-weight-bold">
                  <td class="text-semi-muted">{{'Total'|translate}} :</td>
                  <td class="text-right">{{invoice.totalAmount| number}}</td>
                </tr>
              </tbody>
            </table>
            <div class="border-bottom pt-3 mb-5"></div>
            <!-- <p class="text-muted text-small text-center">Invoice was created on a computer and is valid without the
              signature and seal. </p> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  