<app-list-page-header *ngIf="tableView" [itemOptionsPerPage]="[5,10,20]" [itemsPerPage]="tableEngine.itemsPerPage"
    [selectAllState]="tableEngine.selectAllState" (addNewItem)="showModal(null,'Add')" [tableEngine]="this.tableEngine"
    (selectAllChange)="tableEngine.selectAll($event)" (changeOrderBy)="prepChangeSort($event)"
    (searchKeyUp)="tableEngine.filterDataByDateRange(this.SearchFrom,this.SearchTo,'Date')" [dateSearch]=true>
</app-list-page-header>

<div class="form-group" *ngIf="!tableView">
    <div class="modal-body">
        <form class="md-float-material form-material" [formGroup]="mainForm">

            <div style="text-align: center;">
                <button class="btn btn-light" (click)="goBack()"
                    style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Back"
                    |
                    translate}}</button>
                <button *ngIf="tableEngine.currentFormMode==='Add'"
                    
                    id="smallModalSubmitButton" class="btn btn-secondary" (click)="addObject()"
                    style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Add"
                    |
                    translate}}</button>
                <button *ngIf="tableEngine.currentFormMode==='Add'" class="btn btn-secondary" (click)="addAndNew()"
                    
                    style="font-weight: bold; width: 250px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"AddAndNew"
                    |
                    translate}}</button>
                <button *ngIf="tableEngine.currentFormMode==='Edit'"
                    [disabled]="mainForm.invalid || tableEngine.currentAction==='loading' || addedItemList.length===0"
                    id="smallModalSubmitButton" class="btn btn-secondary" (click)="editObject()"
                    style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Edit"
                    | translate}}</button>
            </div>
            <div class="form-group" style="margin-top: 10px;">

                <div class="row">

                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"QuotationCode" | translate}}</label>
                        <label class="form-control" type="text" style="border: unset; width: 120px;"
                            name="Code">{{modelObject.Code>0?modelObject.Code:lastCode+1}}</label>
                    </div>

                </div>

                <br>

                <div class="row">
                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"QuotationClientId" | translate}}</label>
                        <div *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'">
                            <ng-select appearance="outline" name="ClientId" formControlName="ClientId"
                                placeholder='{{"QuotationClientId" | translate}}' #QuotationStoreDataSelect
                                labelForId="txtycd" [searchable]="true">
                                <ng-option *ngFor="let client of clientList" [value]="client.Id">
                                    {{lang==='ar-EG'?client.NameAr:client.NameEn}}</ng-option>
                            </ng-select>
                        </div>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="ClientId">{{lang==='ar-EG'?getClientById(modelObject.ClientId).NameAr:getClientById(modelObject.ClientId).NameEn}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('ClientId').hasError('required') || mainForm.get('ClientId').invalid) && ( mainForm.get('ClientId').dirty ||  mainForm.get('ClientId').touched)">
                            {{"QuotationClientIdRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>

                    <br>

                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"QuotationDate" | translate}}</label>

                        <input *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                            [type]="modelObject.Id>0?'date':'text'" onfocus="(this.type='date')"
                            onblur="if(!this.value)this.type='text'" onchange="if(!this.value)this.type='text'"
                            name="Date" class="form-control" formControlName="Date"
                            placeholder='{{"QuotationDate" | translate}}'>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="Date">{{modelObject.Date | date:"dd/MM/yyyy"}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('Date').hasError('required') || mainForm.get('Date').invalid) && ( mainForm.get('Date').dirty ||  mainForm.get('Date').touched)">
                            {{"QuotationDateRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>

                </div>


                <br>

                <div class="row">
                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"QuotationShippingAddress1" | translate}}</label>
                        <input *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                            type="text" name="ShippingAddress1" class="form-control" formControlName="ShippingAddress1"
                            style=" margin: 0px;" placeholder='{{"QuotationShippingAddress1" | translate}}'>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('ShippingAddress1').hasError('required') || mainForm.get('ShippingAddress1').invalid) && ( mainForm.get('ShippingAddress1').dirty || mainForm.get('ShippingAddress1').touched)">
                            {{"QuotationShippingAddress1Required" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>

                    <br>

                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"QuotationShippingAddress2" | translate}}</label>
                        <input *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                            type="text" name="ShippingAddress2" class="form-control" formControlName="ShippingAddress2"
                            style=" margin: 0px;" placeholder='{{"QuotationShippingAddress2" | translate}}'>
                        <span class="form-bar"></span>
                    </div>

                </div>

                <br>

                <div class="row" style="height: 160px;">
                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"QuotationDescription" | translate}}</label>
                        <textarea *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                            type="text" name="Description" class="form-control" formControlName="Description"
                            style="width: 319%; height: 150px;"
                            placeholder='{{"QuotationDescription" | translate}}'></textarea>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            style="white-space:pre-wrap; word-wrap:break-word; width: 319%; height: 150px; overflow: auto;"
                            name="Description">{{modelObject.Description}}</label>
                        <span class="form-bar"></span>
                    </div>
                </div>

                <br>
                <br>

                <label class="float-label push righ" style="margin-top: 15px;">{{"QuotationItems" | translate}}</label>
                <div class="card" style="min-width: 900px !important;">
                    <div class="card-body">
                        <div>
                            <div style="width: 100%;">
                                <div>
                                    <table class="table">
                                        <thead class="thead-light">
                                            <th></th>
                                            <th>{{"QuotationItemName" | translate}}</th>
                                            <th>{{"QuotationItemUnit" | translate}}</th>
                                            <!-- <th>{{"QuotationItemDescription" | translate}}</th> -->
                                            <th>{{"QuotationItemUnitCost" | translate}}</th>
                                            <th>{{"QuotationItemQuantity" | translate}}</th>
                                            <th>{{"QuotationItemTotalPrice" | translate}}</th>
                                            <th>{{"QuotationItemDiscountValue" | translate}}</th>
                                            <th>{{"QuotationItemNetPrice" | translate}}</th>
                                            <th>{{"QuotationItemTotalTaxValues" | translate}}</th>
                                            <th>{{"QuotationItemGrossPrice" | translate}}</th>
                                        </thead>
                                        <tbody>

                                            <tr *ngFor="let obj of addedItemList;  let i=index">
                                                <td style="text-align: center; vertical-align: middle;">
                                                    <i *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                                                        class="simple-icon-close" (click)="removeQuotationItem(obj.Id)"
                                                        style="font-size: 20px; font-weight: bolder; cursor: pointer; 
                                        color: #DC3545;"></i>
                                                </td>
                                                <td style="vertical-align: middle;">
                                                    {{obj.ItemId>0?(lang==='ar-EG'?getItemById(obj.ItemId).Name:getItemById(obj.ItemId).Name):"None"|translate}}
                                                </td>
                                                <td style="vertical-align: middle;">
                                                    {{obj.UnitId>0?(lang==='ar-EG'?getUnityById(obj.UnitId).NameAr:getUnityById(obj.UnitId).NameEn):"None"|translate}}
                                                </td>
                                                <!-- <td style="vertical-align: middle;">
                                            {{obj.Description}}
                                        </td> -->
                                                <td style="vertical-align: middle;">
                                                    {{obj.UnitCost.toFixed(2)}}
                                                </td>
                                                <td style="vertical-align: middle;">
                                                    {{obj.Quantity.toFixed(2)}}
                                                </td>
                                                <td style="vertical-align: middle;">
                                                    {{obj.TotalPrice.toFixed(2)}}
                                                </td>
                                                <td style="vertical-align: middle;">
                                                    {{obj.DiscountValue.toFixed(2)}}{{obj.DiscountIsRate?'%':''}}
                                                </td>
                                                <td style="vertical-align: middle;">
                                                    {{obj.NetPrice.toFixed(2)}}
                                                </td>
                                                <td style="vertical-align: middle;">
                                                    {{obj.TotalTaxValues.toFixed(2)}}
                                                </td>
                                                <td style="vertical-align: middle;">
                                                    {{obj.GrossPrice.toFixed(2)}}
                                                </td>
                                            </tr>
                                            <tr *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                                                type="text" [formGroup]=quotationItemForm>
                                                <td style="text-align: center; vertical-align: middle;">
                                                    <i class="simple-icon-plus"
                                                        [ngClass]="!(quotationItemForm.invalid)?'simple-icon-plus':'simple-icon-plus inactive-icon'"
                                                        (click)="addQuotationItem()"
                                                        style="font-size: 20px; font-weight: bolder; cursor: pointer; color: #17A2B8;"></i>
                                                </td>
                                                <td colspan="9">
                                                    <div class="card" style="min-width: 900px !important;">
                                                        <div class="card-body">
                                                            <div class="form-group" style="margin-top: 10px;">

                                                                <div class="row">

                                                                    <div class=" col-md-4">
                                                                        <label
                                                                            class="float-label push righ">{{"QuotationItemId"
                                                                            | translate}}</label>
                                                                        <div
                                                                            *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'">
                                                                            <ng-select appearance="outline"
                                                                                name="ItemId" formControlName="ItemId"
                                                                                placeholder='{{"QuotationItemId" | translate}}'
                                                                                #QuotationItemIdSelect
                                                                                labelForId="txtycd2" [searchable]="true"
                                                                                (change)="changeItem()"
                                                                                (keyup)="changeItem()">
                                                                                <ng-option *ngFor="let item of itemList"
                                                                                    [value]="item.Id">
                                                                                    {{lang==='ar-EG'?item.Name:item.Name}}</ng-option>
                                                                            </ng-select>
                                                                        </div>
                                                                        <div class="messages text-danger"
                                                                            *ngIf=" (quotationItemForm.get('ItemId').hasError('required') || quotationItemForm.get('ItemId').invalid) && ( quotationItemForm.get('ItemId').dirty ||  quotationItemForm.get('ItemId').touched)">
                                                                            {{"QuotationItemIdRequired" | translate}}
                                                                        </div>
                                                                        <span class="form-bar"></span>
                                                                    </div>

                                                                    <br>

                                                                    <div class=" col-md-4">
                                                                        <label
                                                                            class="float-label push righ">{{"QuotationItemUnitId"
                                                                            | translate}}</label>
                                                                        <label class="form-control" type="text"
                                                                            style="border: unset; " name="UnitId">
                                                                            {{newQuotationItem.UnitId>0?(lang==='ar-EG'?getUnityById(newQuotationItem.UnitId).NameAr:getUnityById(newQuotationItem.UnitId).NameEn):"None"|translate}}
                                                                        </label>
                                                                    </div>

                                                                </div>

                                                                <br>

                                                                <div class="row">

                                                                    <div class=" col-md-4">
                                                                        <label
                                                                            class="float-label push righ">{{"QuotationItemUnitCost"
                                                                            | translate}}</label>
                                                                        <div
                                                                            *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'">
                                                                            <input
                                                                                *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                                                                                type="number" min="0" name="UnitCost"
                                                                                class="form-control"
                                                                                formControlName="UnitCost"
                                                                                style=" margin: 0px;"
                                                                                (change)="calculateItemValues()"
                                                                                (keyup)="calculateItemValues()"
                                                                                placeholder='{{"QuotationItemUnitCost" | translate}}'>
                                                                            <div class="messages text-danger"
                                                                                *ngIf=" (quotationItemForm.get('UnitCost').hasError('required') || quotationItemForm.get('UnitCost').invalid) && ( quotationItemForm.get('UnitCost').dirty || quotationItemForm.get('UnitCost').touched)">
                                                                                {{"QuotationItemUnitCostRequired" |
                                                                                translate}}</div>
                                                                            <span class="form-bar"></span>
                                                                        </div>
                                                                    </div>

                                                                    <br>

                                                                    <div class=" col-md-4">
                                                                        <label
                                                                            class="float-label push righ">{{"QuotationItemQuantity"
                                                                            | translate}}</label>
                                                                        <input
                                                                            *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                                                                            type="number" min="0" name="Quantity"
                                                                            class="form-control"
                                                                            formControlName="Quantity"
                                                                            style="margin: 0px;"
                                                                            (change)="calculateItemValues()"
                                                                            (keyup)="calculateItemValues()"
                                                                            placeholder='{{"QuotationItemQuantity" | translate}}'>

                                                                        <div class="messages text-danger"
                                                                            *ngIf=" (quotationItemForm.get('Quantity').hasError('required') || quotationItemForm.get('Quantity').invalid) && ( quotationItemForm.get('Quantity').dirty || quotationItemForm.get('Quantity').touched)">
                                                                            {{"QuotationItemQuantityRequired" |
                                                                            translate}}</div>
                                                                        <span class="form-bar"></span>
                                                                    </div>

                                                                    <br>

                                                                    <div class=" col-md-4">
                                                                        <label
                                                                            class="float-label push righ">{{"QuotationItemTotalPrice"
                                                                            | translate}}</label>
                                                                        <label class="form-control" type="text"
                                                                            style="border: unset; " name="UnitId">
                                                                            {{newQuotationItem.TotalPrice>0?newQuotationItem.TotalPrice.toFixed(2):0}}
                                                                        </label>
                                                                    </div>

                                                                </div>

                                                                <br>

                                                                <div class="row">

                                                                    <div class=" col-md-4">
                                                                        <label
                                                                            class="float-label push righ">{{"QuotationItemDiscountValue"
                                                                            | translate}}</label>
                                                                        <div
                                                                            *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'">
                                                                            <input
                                                                                *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                                                                                type="number" min="0"
                                                                                [max]="quotationItemForm.getRawValue().DiscountIsRate?100:2147483647"
                                                                                (change)="calculateItemValues()"
                                                                                (keyup)="calculateItemValues()"
                                                                                name="DiscountValue"
                                                                                class="form-control"
                                                                                formControlName="DiscountValue"
                                                                                style=" margin: 0px;"
                                                                                placeholder='{{"QuotationItemDiscountValue" | translate}}'>
                                                                            <div class="itemCheck mb-0 custom-checkbox custom-control"
                                                                                style="display: inline-block;">
                                                                                <input type="checkbox"
                                                                                    class="custom-control-input"
                                                                                    [id]="'valueCheck'"
                                                                                    (change)="calculateItemValues()"
                                                                                    (keyup)="calculateItemValues()"
                                                                                    formControlName="DiscountIsRate">
                                                                                <label class="custom-control-label"
                                                                                    [for]="'valueCheck'"></label>
                                                                            </div>
                                                                            {{'ValueIsRate' | translate}}
                                                                            <div class="messages text-danger"
                                                                                *ngIf=" (quotationItemForm.get('DiscountValue').hasError('required') || quotationItemForm.get('DiscountValue').invalid) && ( quotationItemForm.get('DiscountValue').dirty || quotationItemForm.get('DiscountValue').touched)">
                                                                                {{"QuotationItemDiscountValueRequired" |
                                                                                translate}}</div>
                                                                            <span class="form-bar"></span>
                                                                        </div>
                                                                    </div>

                                                                    <br>

                                                                    <div class=" col-md-4">
                                                                        &nbsp;
                                                                    </div>

                                                                    <br>

                                                                    <div class=" col-md-4">
                                                                        <label
                                                                            class="float-label push righ">{{"QuotationItemNetPrice"
                                                                            | translate}}</label>
                                                                        <label class="form-control" type="text"
                                                                            style="border: unset; " name="UnitId">
                                                                            {{newQuotationItem.NetPrice>0?newQuotationItem.NetPrice.toFixed(2):0}}
                                                                        </label>
                                                                    </div>

                                                                </div>

                                                                <br>

                                                                <div class="card" style="min-width: 600px !important;">
                                                                    <div class="card-body">
                                                                        <div class="row">
                                                                            <div style="display: inline-block;">
                                                                                {{"QuotationItemTaxes" | translate}}
                                                                            </div>
                                                                            <table class="table">
                                                                                <thead class="thead-light">
                                                                                    <th></th>
                                                                                    <th>{{"QuotationItemTaxName" |
                                                                                        translate}}</th>
                                                                                    <th>{{"QuotationItemTaxValue" |
                                                                                        translate}}</th>
                                                                                    <th>{{"QuotationItemAppliedTaxValue"
                                                                                        | translate}}</th>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr
                                                                                        *ngFor="let tax of newQuotationItem.Taxes;  let i=index">
                                                                                        <td
                                                                                            style="text-align: center; vertical-align: middle;">
                                                                                            <i *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                                                                                                class="simple-icon-close"
                                                                                                (click)="removeQuotationItemTax(tax.Id)"
                                                                                                style="font-size: 20px; font-weight: bolder; 
                                                                                    cursor: pointer; color: #DC3545;"></i>
                                                                                        </td>
                                                                                        <td>
                                                                                            <div
                                                                                                style="margin-top: 8px; margin-bottom: 8px;">
                                                                                                {{lang==='ar-EG'?tax.TaxNameAr:tax.TaxNameEn}}
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <div
                                                                                                style="margin-top: 8px; margin-bottom: 8px;">
                                                                                                {{(tax.TaxValue>0?tax.TaxValue.toFixed(2):0)}}{{tax.TaxIsRate?'%':''}}
                                                                                            </div>
                                                                                        </td>
                                                                                        <td
                                                                                            style="vertical-align: middle;">
                                                                                            <div
                                                                                                style="margin-top: 8px; margin-bottom: 8px;">
                                                                                                {{newQuotationItem.NetPrice>0&&tax.TaxValue>0?
                                                                                                (tax.TaxIsRate?
                                                                                                ((newQuotationItem.NetPrice
                                                                                                * tax.TaxValue /
                                                                                                100).toFixed(2))
                                                                                                :tax.TaxValue.toFixed(2))
                                                                                                :0}}
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                                                                                        type="text"
                                                                                        [formGroup]=quotationItemTaxForm>

                                                                                        <td
                                                                                            style="text-align: center; vertical-align: middle;">
                                                                                            <i class="simple-icon-plus"
                                                                                                [ngClass]="!(quotationItemTaxForm.invalid)?'simple-icon-plus':'simple-icon-plus inactive-icon'"
                                                                                                (click)="addQuotationItemTax()"
                                                                                                style="font-size: 20px; font-weight: bolder; cursor: pointer; color: #17A2B8;"></i>
                                                                                        </td>
                                                                                        <td>
                                                                                            <div>
                                                                                                <ng-select
                                                                                                    appearance="outline"
                                                                                                    name="TaxId"
                                                                                                    formControlName="TaxId"
                                                                                                    placeholder='{{"QuotationItemTaxId" | translate}}'
                                                                                                    #QuotationItemNameSelect
                                                                                                    labelForId="txtycd2"
                                                                                                    [searchable]="true"
                                                                                                    (change)="changeTax()">
                                                                                                    <ng-option
                                                                                                        *ngFor="let tax of taxTypeList"
                                                                                                        [value]="tax.Id">
                                                                                                        {{lang==='ar-EG'?tax.NameAr:tax.NameEn}}</ng-option>
                                                                                                </ng-select>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <div
                                                                                                style="margin-top: 8px; margin-bottom: 8px;">
                                                                                                {{(newQuotationItemTax.TaxId>0?newQuotationItemTax.TaxValue.toFixed(2):0)}}{{newQuotationItemTax.TaxIsRate?'%':''}}
                                                                                            </div>
                                                                                        </td>
                                                                                        <td
                                                                                            style="vertical-align: middle;">
                                                                                            <div
                                                                                                style="margin-top: 8px; margin-bottom: 8px;">
                                                                                                {{newQuotationItem.NetPrice>0&&newQuotationItemTax.TaxValue>0?
                                                                                                (newQuotationItemTax.TaxIsRate?
                                                                                                (newQuotationItem.NetPrice
                                                                                                *
                                                                                                newQuotationItemTax.TaxValue
                                                                                                / 100).toFixed(2)
                                                                                                :newQuotationItemTax.TaxValue)
                                                                                                :0}}
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <br>

                                                                <div class="row">
                                                                    <div class=" col-md-4">
                                                                        &nbsp;
                                                                    </div>

                                                                    <br>

                                                                    <div class=" col-md-4">
                                                                        &nbsp;
                                                                    </div>

                                                                    <br>

                                                                    <div class=" col-md-4">
                                                                        <div style="display: inline-block;">
                                                                            {{"QuotationItemGrossPrice" | translate}}
                                                                        </div>
                                                                        <div
                                                                            style="margin-top: 8px; margin-bottom: 8px;">
                                                                            {{newQuotationItem.GrossPrice>0?newQuotationItem.GrossPrice.toFixed(2):0}}
                                                                        </div>
                                                                        <!-- <label
                                                                    style="display: inline-block; margin-left: 8px; margin-right: 8px; min-width: 150px; border: unset; font-size: 25px;"
                                                                    class="form-control" type="text" name="TotalValue">{{currentTotalQuotationValue>0?currentTotalQuotationValue:0}}</label> -->
                                                                    </div>
                                                                </div>

                                                                <br>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <br>

                    <div class="row">
                        <div class=" col-md-4">
                            &nbsp;
                        </div>

                        <br>

                        <div class=" col-md-4">
                            &nbsp;
                        </div>

                        <br>
                        <div class=" col-md-4">
                            <div style="display: block;">
                                {{"QuotationTotalGrossPrices" | translate}}
                            </div>
                            <label
                                style="display: block; margin-left: 8px; margin-right: 8px; width: 85% !important; font-size: 25px;"
                                class="form-control" type="number" name="totalItemGrossPrices">
                                {{currentTotalItemGrossPrices>0?this.currentTotalItemGrossPrices.toFixed(2):0}}
                            </label>
                        </div>
                    </div>
                </div>


                <br>

                <label class="float-label push righ" style="margin-top: 15px;">{{"QuotationDiscount" |
                    translate}}</label>
                <div class="card" style="min-width: 900px !important;">
                    <div class="card-body">
                        <div class="row">

                            <div class=" col-md-4">
                                <label class="float-label push righ">{{"QuotationDiscountValue" | translate}}</label>
                                <div *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'">
                                    <input
                                        *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                                        type="number" min="0"
                                        [max]="mainForm.getRawValue().DiscountIsRate?100:2147483647"
                                        (change)="calculateTotalQuotationValue()"
                                        (keyup)="calculateTotalQuotationValue()" name="DiscountValue"
                                        class="form-control" formControlName="DiscountValue" style=" margin: 0px;"
                                        placeholder='{{"QuotationDiscountValue" | translate}}'>
                                    <div class="itemCheck mb-0 custom-checkbox custom-control"
                                        style="display: inline-block;">
                                        <input type="checkbox" class="custom-control-input"
                                            [id]="'quotationDiscountCheck'" (change)="calculateTotalQuotationValue()"
                                            (keyup)="calculateTotalQuotationValue()" formControlName="DiscountIsRate">
                                        <label class="custom-control-label" [for]="'quotationDiscountCheck'"></label>
                                    </div>
                                    {{'ValueIsRate' | translate}}
                                    <div class="messages text-danger"
                                        *ngIf=" (mainForm.get('DiscountValue').hasError('required') || mainForm.get('DiscountValue').invalid) && ( mainForm.get('DiscountValue').dirty || mainForm.get('DiscountValue').touched)">
                                        {{"QuotationDiscountValueRequired" | translate}}</div>
                                    <span class="form-bar"></span>
                                </div>
                            </div>


                            <br>

                            <div class=" col-md-4">
                                <label class="float-label push righ">{{"QuotationAppliedDiscountValue" |
                                    translate}}</label>
                                <label class="form-control" type="text" name="UnitId">
                                    {{
                                    this.mainForm.getRawValue().DiscountValue>0&&currentTotalItemGrossPrices>0?
                                    (this.mainForm.getRawValue().DiscountIsRate?
                                    (this.mainForm.getRawValue().DiscountValue*currentTotalItemGrossPrices/100).toFixed(2)
                                    :this.mainForm.getRawValue().DiscountValue)
                                    :0
                                    }}
                                </label>
                            </div>


                        </div>


                        <div class="row">
                            <div class=" col-md-4">
                                &nbsp;
                            </div>

                            <br>

                            <div class=" col-md-4">
                                &nbsp;
                            </div>

                            <br>
                            <div class=" col-md-4">
                                <div style="display: inline-block;">
                                    {{"QuotationTotalAfterDiscount" | translate}}
                                </div>
                                <label
                                    style="display: block; margin-left: 8px; margin-right: 8px; width: 85% !important; font-size: 25px;"
                                    class="form-control" type="number" name="totalAfterQuotationDiscount">
                                    {{this.currentTotalAfterQuotationDiscount>0?this.currentTotalAfterQuotationDiscount.toFixed(2):0}}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>



                <br>

                <label class="float-label push righ" style="margin-top: 15px;">{{"QuotationShippingFees" |
                    translate}}</label>
                <div class="card" style="min-width: 900px !important;">
                    <div class="card-body">
                        <div class="row">



                            <div class=" col-md-4">
                                <label class="float-label push righ">{{"QuotationShippingFeesValue" |
                                    translate}}</label>
                                <div *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'">
                                    <input
                                        *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                                        type="number" min="0" name="ShippingFees" class="form-control"
                                        formControlName="ShippingFees" style=" margin: 0px;"
                                        (change)="calculateTotalQuotationValue()"
                                        (keyup)="calculateTotalQuotationValue()"
                                        placeholder='{{"QuotationShippingFees" | translate}}'>
                                    <div class="messages text-danger"
                                        *ngIf=" (mainForm.get('ShippingFees').hasError('required') || mainForm.get('ShippingFees').invalid) && ( mainForm.get('ShippingFees').dirty || mainForm.get('ShippingFees').touched)">
                                        {{"QuotationShippingFeesRequired" | translate}}</div>
                                    <span class="form-bar"></span>
                                </div>
                            </div>

                        </div>

                        <div class="row">
                            <div class=" col-md-4">
                                &nbsp;
                            </div>

                            <br>

                            <div class=" col-md-4">
                                &nbsp;
                            </div>

                            <br>
                            <div class=" col-md-4">
                                <div style="display: inline-block;">
                                    {{"QuotationTotalAfterShippingFees" | translate}}
                                </div>
                                <label
                                    style="display: block; margin-left: 8px; margin-right: 8px; width: 85% !important; font-size: 25px;"
                                    class="form-control" type="number" name="totalAfterShippingFees">
                                    {{this.currentTotalAfterShippingFees>0?this.currentTotalAfterShippingFees.toFixed(2):0}}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>


                <br>

                <!-- <div id="divmiddle" style="display: block; margin-top: 5px;"> -->

                <label class="float-label push righ" style="margin-top: 15px;">{{"QuotationServices" |
                    translate}}</label>
                <div style="text-align: center;">
                    <div style="width: 100%; display: inline-block;">
                        <div>
                            <div class="card" style="min-width: 900px !important;">
                                <div class="card-body">
                                    <table class="table">
                                        <thead class="no-border-top">
                                            <th></th>
                                            <th>{{"QuotationServiceName" | translate}}</th>
                                            <th>{{"QuotationServiceValue" | translate}}</th>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let obj of addedServiceList;  let i=index">
                                                <td style="text-align: center; vertical-align: middle;">
                                                    <i *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                                                        class="simple-icon-close"
                                                        (click)="removeQuotationService(obj.Id)" style="font-size: 20px; font-weight: bolder; cursor: pointer; 
                                        color: #DC3545;"></i>
                                                </td>
                                                <td style="vertical-align: middle;">
                                                    {{lang==='ar-EG'?obj.NameAr:obj.NameEn}}
                                                </td>
                                                <td style="vertical-align: middle;">
                                                    {{obj.Value.toFixed(2)}}
                                                </td>
                                            </tr>
                                            <tr *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                                                type="text" [formGroup]=quotationServiceForm>
                                                <td style="text-align: center; vertical-align: middle;">
                                                    <i class="simple-icon-plus"
                                                        [ngClass]="!(quotationServiceForm.invalid)?'simple-icon-plus':'simple-icon-plus inactive-icon'"
                                                        (click)="!(quotationServiceForm.invalid||tableEngine.currentAction==='loading')?addQuotationService():''"
                                                        style="font-size: 20px; font-weight: bolder; cursor: pointer; color: #17A2B8;"></i>
                                                </td>
                                                <td>
                                                    <input
                                                        *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                                                        type="text" name="NameAr" class="form-control"
                                                        formControlName="NameAr" style=" margin: 0px;"
                                                        placeholder='{{"QuotationServiceNameAr" | translate}}'>
                                                    <div class="messages text-danger"
                                                        *ngIf=" (quotationServiceForm.get('NameAr').hasError('required') || quotationServiceForm.get('NameAr').invalid) && ( quotationServiceForm.get('NameAr').dirty || quotationServiceForm.get('NameAr').touched)">
                                                        {{"QuotationServiceNameArRequired" | translate}}</div>
                                                </td>
                                                <td>
                                                    <input
                                                        *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                                                        type="number" min="0" name="Value" class="form-control"
                                                        formControlName="Value" style=" margin: 0px;"
                                                        placeholder='{{"QuotationServiceValue" | translate}}'>
                                                    <div class="messages text-danger"
                                                        *ngIf=" (quotationServiceForm.get('Value').hasError('required') || quotationServiceForm.get('Value').invalid) && ( quotationServiceForm.get('Value').dirty || quotationServiceForm.get('Value').touched)">
                                                        {{"QuotationValueRequired" | translate}}</div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <br>


                <div class="row">
                    <div class=" col-md-4">
                        &nbsp;
                    </div>

                    <br>

                    <div class=" col-md-4">
                        &nbsp;
                    </div>

                    <br>
                    <div class=" col-md-4">
                        <div style="display: inline-block;">
                            {{"QuotationTotalValue" | translate}}
                        </div>
                        <label
                            style="display: block; margin-left: 8px; margin-right: 8px; width: 85% !important; font-size: 25px;"
                            class="form-control" type="number" name="quotationTotalValue">
                            {{this.currentTotalQuotationValue>0?this.currentTotalQuotationValue.toFixed(2):0}}
                        </label>
                    </div>
                </div>
            </div>

            <div style="text-align: center;">
                <button class="btn btn-light" (click)="goBack()"
                    style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Back"
                    |
                    translate}}</button>
                <button *ngIf="tableEngine.currentFormMode==='Add'"
                    [disabled]="mainForm.invalid || tableEngine.currentAction==='loading' || addedItemList.length===0"
                    id="smallModalSubmitButton" class="btn btn-secondary" (click)="addObject()"
                    style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Add"
                    |
                    translate}}</button>
                <button *ngIf="tableEngine.currentFormMode==='Add'" class="btn btn-secondary" (click)="addAndNew()"
                    [disabled]="mainForm.invalid || tableEngine.currentAction==='loading' || addedItemList.length===0"
                    style="font-weight: bold; width: 250px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"AddAndNew"
                    |
                    translate}}</button>
                <button *ngIf="tableEngine.currentFormMode==='Edit'"
                    [disabled]="mainForm.invalid || tableEngine.currentAction==='loading' || addedItemList.length===0"
                    id="smallModalSubmitButton" class="btn btn-secondary" (click)="editObject()"
                    style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Edit"
                    | translate}}</button>
            </div>
        </form>
    </div>
</div>

<div bsModal #delModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <!-- <button type="button" class="close pull-right" aria-label="Close" (click)="delModal.hide()"
                style="margin-right: 20px; margin-left: 20px; margin-top: 10px; width: 20px; display: inline;">
            <span aria-hidden="true">&nbsp;</span>
            </button> -->
            <span aria-hidden="true">&nbsp;</span>
            <div class="modal-header" style="display: inline; padding: 0px 0px 5px 0px;">
                <h4 id="dialog-child-name" class="modal-title pull-left" style="text-align: center; color: #DC3545;">
                    {{"ConfirmDel"+translationName |
                    translate}}</h4>
            </div>
            <div class="modal-body">
                <div class="form-group form-primary" *ngIf="tableEngine.deletionList.length < tableEngine.ogData.length 
                || tableEngine.deletionList.length === 1" style="text-align: center;">
                    <div *ngFor="let item of tableEngine.deletionList;" style="text-align: center;">
                        <label type="text">- {{translationName|translate}} {{"Number" | translate}}
                            {{lang==='ar-EG'?item.Code:item.Code}}</label>
                        <span class="form-bar"></span>
                    </div>
                </div>
                <div class="form-group form-primary" style="text-align: center;" *ngIf="tableEngine.deletionList.length > 1 && 
                    tableEngine.deletionList.length === tableEngine.ogData.length">
                    <label type="text">
                        <h1 style="font-weight: bolder; text-align: center;">{{translationName+"DeleteAll" | translate}}
                        </h1>
                    </label>
                    <span class="form-bar"></span>
                </div>

                <div style="margin-left: 5px; text-align: center; margin-top: 30px;">
                    <button class="btn btn-danger" id="smallModalDeleteButton" (click)="deleteObject()"
                        [disabled]="tableEngine.currentAction==='loading'"
                        style="font-weight: bold; width: 100%; text-align: center;">{{"Delete" | translate}}</button>
                    <button class="btn btn-light" (click)="delModal.hide()"
                        style="font-weight: bold; width: 100%; text-align: center; margin-top: 10px;">{{"Close" |
                        translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <context-menu #basicMenu>
    <ng-template contextMenuItem let-item>
        <i class="simple-icon-docs mr-1"></i> <span>Copy</span>
    </ng-template>
    <ng-template contextMenuItem let-item>
        <i class="simple-icon-drawer mr-1"></i> <span>Move to archive</span>
    </ng-template>
    <ng-template contextMenuItem let-item (execute)="tableEngine.showDelModal('delMulti')">
        <i class="simple-icon-trash mr-1"></i><span>Delete</span>
    </ng-template>
</context-menu> -->

<div *ngIf="tableView" class="FormScreen" class="tableScreen">

    <div id="divmiddle" style="display: block; margin-top: 5px;">

        <div style="width: 100%;">
            <div>
                <div class="card" style="min-width: 900px !important;">
                    <div class="card-body">
                        <table class="table table-hover" style="margin: unset !important;">
                            <thead class="no-border-top">
                                <th style="text-align: center; padding:5px;">
                                    <div class=" text-zero" style="display: inline-block;">
                                        <div class="top-right-button-container2 btn-group" dropdown>
                                            <div class="btn btn-primary pl-4 pr-0 check-button">
                                                <label class="custom-control custom-checkbox mb-0 d-inline-block"
                                                    style="width: 10px;">
                                                    <input type="checkbox" class="custom-control-input"
                                                        [checked]="tableEngine.selectAllState==='checked'"
                                                        [indeterminate]="tableEngine.selectAllState === 'indeterminate'"
                                                        (change)="tableEngine.selectAll($event)">
                                                    <span class="custom-control-label">&nbsp;</span>
                                                </label>
                                            </div>
                                            <button id="button-split" type="button" dropdownToggle style="width: 10px;"
                                                [ngClass]="(tableEngine.selected.length == 0)?
                                                'btn btn-outline-primary dropdown-toggle dropdown-toggle-split':
                                                'btn btn-primary dropdown-toggle dropdown-toggle-split'"
                                                aria-controls="dropdown-split">
                                                <span class="caret"></span>
                                            </button>
                                            <ul id="dropdown-split" *dropdownMenu
                                                class="dropdown-menu dropdown-menu-right" role="menu"
                                                aria-labelledby="button-split">
                                                <li role="menuitem"><a class="dropdown-item">
                                                        <button class="btn btn-danger d-inline-block"
                                                            style=" font-weight: bold; text-align: center; padding: 0px; width: 150px; height: 30px;"
                                                            (click)="tableEngine.showDelModal('delMulti')"
                                                            [disabled]="tableEngine.selected.length===0 || tableEngine.currentAction==='loading'">{{"DeleteSelected"
                                                            |
                                                            translate}}</button></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </th>

                                <th class="sorticon" (click)="prepChangeSort('QuotationCode')"
                                    [ngClass]="(itemOrder.label==='QuotationCode')?'sortkey sorticon':'sorticon'">
                                    <div
                                        [ngClass]="{'iconsminds-up---down': (itemOrder.label!='QuotationCode'), 
                                    'iconsminds-down': ((itemOrder.label==='QuotationCode') && tableEngine.orderAsc === true),
                                    'iconsminds-up': ((itemOrder.label==='QuotationCode') && tableEngine.orderAsc === false)}">
                                    </div>
                                </th>
                                <th class="sortOption" (click)="prepChangeSort('QuotationCode')"
                                    [ngClass]="{'sortkey': (itemOrder.label==='QuotationCode')}">
                                    {{"QuotationCode" | translate}}
                                </th>


                                <th class="sorticon" (click)="prepChangeSort('QuotationDate')"
                                    [ngClass]="(itemOrder.label==='QuotationDate')?'sortkey sorticon':'sorticon'">
                                    <div
                                        [ngClass]="{'iconsminds-up---down': (itemOrder.label!='QuotationDate'), 
                                    'iconsminds-down': ((itemOrder.label==='QuotationDate') && tableEngine.orderAsc === true),
                                    'iconsminds-up': ((itemOrder.label==='QuotationDate') && tableEngine.orderAsc === false)}">
                                    </div>
                                </th>
                                <th class="sortOption" (click)="prepChangeSort('QuotationDate')"
                                    [ngClass]="{'sortkey': (itemOrder.label==='QuotationDate')}">
                                    {{"QuotationDate" | translate}}
                                </th>



                                <th class="sorticon" (click)="prepChangeSort('QuotationClientName')"
                                    [ngClass]="(itemOrder.label==='QuotationClientName')?'sortkey sorticon':'sorticon'">
                                    <div
                                        [ngClass]="{'iconsminds-up---down': (itemOrder.label!='QuotationClientName'), 
                                    'iconsminds-down': ((itemOrder.label==='QuotationClientName') && tableEngine.orderAsc === true),
                                    'iconsminds-up': ((itemOrder.label==='QuotationClientName') && tableEngine.orderAsc === false)}">
                                    </div>
                                </th>
                                <th class="sortOption" (click)="prepChangeSort('QuotationClientName')"
                                    [ngClass]="{'sortkey': (itemOrder.label==='QuotationClientName')}">
                                    {{"QuotationClientName" | translate}}
                                </th>

                                <th class="sorticon" (click)="prepChangeSort('QuotationShippingAddress')"
                                    [ngClass]="(itemOrder.label==='QuotationShippingAddress')?'sortkey sorticon':'sorticon'">
                                    <div
                                        [ngClass]="{'iconsminds-up---down': (itemOrder.label!='QuotationShippingAddress'), 
                                    'iconsminds-down': ((itemOrder.label==='QuotationShippingAddress') && tableEngine.orderAsc === true),
                                    'iconsminds-up': ((itemOrder.label==='QuotationShippingAddress') && tableEngine.orderAsc === false)}">
                                    </div>
                                </th>
                                <th class="sortOption" (click)="prepChangeSort('QuotationShippingAddress')"
                                    [ngClass]="{'sortkey': (itemOrder.label==='QuotationShippingAddress')}">
                                    {{"QuotationShippingAddress" | translate}}
                                </th>


                                <th class="sorticon" (click)="prepChangeSort('QuotationTotalQuotationValue')"
                                    [ngClass]="(itemOrder.label==='QuotationTotalQuotationValue')?'sortkey sorticon':'sorticon'">
                                    <div
                                        [ngClass]="{'iconsminds-up---down': (itemOrder.label!='QuotationTotalQuotationValue'), 
                                    'iconsminds-down': ((itemOrder.label==='QuotationTotalQuotationValue') && tableEngine.orderAsc === true),
                                    'iconsminds-up': ((itemOrder.label==='QuotationTotalQuotationValue') && tableEngine.orderAsc === false)}">
                                    </div>
                                </th>
                                <th class="sortOption" (click)="prepChangeSort('QuotationTotalQuotationValue')"
                                    [ngClass]="{'sortkey': (itemOrder.label==='QuotationTotalQuotationValue')}">
                                    {{"QuotationTotalQuotationValue" | translate}}
                                </th>

                                <th [colSpan]="3"
                                    style="text-align: center; padding: unset !important; vertical-align: middle;">

                                    <div id="displayOptions" style="
                                        display: inline; margin-top: 10px; margin-left: 5px; margin-right: 5px;">
                                        <span class="text-muted text-small" style=" width: 50px; height: 40px;">
                                            {{"ResultCount" | translate}}
                                        </span>
                                        <span dropdown class="d-inline-block position-relative">
                                            <button class="btn btn-outline-dark btn-xs dropdown-toggle" type="button"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                                dropdownToggle>
                                                {{tableEngine.itemsPerPage}}
                                            </button>
                                            <div class="dropdown-menu dropdown-menu-right dropdown-menu-sm text-right"
                                                *dropdownMenu>
                                                <li role="menuitem" *ngFor="let item of tableEngine.itemOptionsPerPage">
                                                    <a [ngClass]="item === tableEngine.itemsPerPage ? 'dropdown-item active' : 'dropdown-item'"
                                                        (click)="tableEngine.paginateData(item, 1)">{{item}}</a>
                                                </li>
                                            </div>
                                        </span>
                                    </div>

                                </th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let obj of tableEngine.paginatedData;  let i=index">
                                    <td style="text-align: center; padding:5px;">
                                        <div class="itemCheck mb-0 custom-checkbox custom-control"
                                            style="display: inline-block;">
                                            <input type="checkbox" class="custom-control-input" [id]="'customCheck'+i"
                                                [checked]="tableEngine.isSelected(obj)"
                                                (change)="tableEngine.onSelect(obj)">
                                            <label class="custom-control-label" [for]="'customCheck'+i"></label>
                                        </div>
                                    </td>
                                    <td colspan="2">{{obj.Code}}</td>
                                    <td colspan="2">{{obj.Date | date:"dd/MM/yyyy"}}</td>
                                    <td colspan="2">
                                        {{lang==='ar-EG'?getClientById(obj.ClientId).NameAr:getClientById(obj.ClientId).NameEn}}
                                    </td>
                                    <td colspan="2">{{obj.ShippingAddress1}}</td>
                                    <!-- <td colspan="2">{{obj.ShippingAddress2}}</td> -->
                                    <!-- <td colspan="2" style="white-space:initial; word-wrap:break-word;  max-width: 250px;">
                                        {{obj.Description}}
                                    </td> -->
                                    <td colspan="2">{{obj.TotalQuotationValue.toFixed(2)}}</td>
                                    <td style="text-align: center;">
                                        <i class="simple-icon-info" (click)="showModal(obj.Id, 'Info')"
                                            tooltip="{{'Info'|translate}}" placement="auto" style="font-size: 20px; font-weight: bolder; cursor: pointer; 
                                                vertical-align: middle; color: #17A2B8;"></i>
                                    </td>
                                    <td style="text-align: center;">
                                        <i class="simple-icon-pencil" (click)="showModal(obj.Id, 'Edit')"
                                            tooltip="{{'Edit'|translate}}" placement="auto" style="font-size: 20px; font-weight: bolder; cursor: pointer; 
                                                vertical-align: middle; color: #FFC107;"></i>
                                    </td>
                                    <td style="text-align: center;">
                                        <i class="simple-icon-close" (click)="tableEngine.showDelModal(obj.Id)"
                                            tooltip="{{'Delete'|translate}}" placement="auto" style="font-size: 20px; font-weight: bolder; cursor: pointer; 
                                                vertical-align: middle; color: #DC3545;"></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div id="divsearchmiddle" style="display: inline-block; width: 100%; height: 100%;">
                            <div style="text-align: center;">
                                <!-- <div class="centerside" style="display: inline-block; width: 15%; height: 100%; ">
                                    &nbsp;

                                </div> -->
                                <div class="centercenter"
                                    style="display: inline-block; width: 70%; height: 100%;  text-align: center;">

                                    <bdo dir="ltr">
                                        <pagination class="pagination-sm2" style="padding: 0px, 0px, 0px, 0px; margin: 0px, 0px, 0px, 0px;
                                        display: inline-block;" [(ngModel)]="currentPage" [boundaryLinks]="true"
                                            [totalItems]="tableEngine.filteredData.length"
                                            [itemsPerPage]="tableEngine.itemsPerPage"
                                            [customFirstTemplate]="firstTemplate" [customLastTemplate]="lastTemplate"
                                            [customPreviousTemplate]="prevTemplate" [customNextTemplate]="nextTemplate"
                                            [maxSize]="5" (pageChanged)="tableEngine.changePage($event)">
                                        </pagination>
                                    </bdo>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

            </div>
        </div>
    </div>

    <div id="divbottom" style="display: block; height: 47px;">

        <div id="divsearchright" style="float: right;  width: 33.33%; padding: 0px;">&nbsp;</div>


        <div id="divsearchleft" style="float: left; width: 33.33%; height: 100%;">&nbsp;</div>

    </div>
</div>

<div>

</div>

<ng-template #nextTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i *ngIf="lang==='en-US'" class='simple-icon-arrow-right'></i>
    <i *ngIf="lang==='ar-EG'" class='simple-icon-arrow-left'></i>
</ng-template>

<ng-template #prevTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i *ngIf="lang==='en-US'" class='simple-icon-arrow-left'></i>
    <i *ngIf="lang==='ar-EG'" class='simple-icon-arrow-right'></i>
</ng-template>

<ng-template #lastTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i class='simple-icon-control-end'></i>
</ng-template>

<ng-template #firstTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i class='simple-icon-control-start'></i>
</ng-template>


<simple-notifications></simple-notifications>