import { ClientBusinessExtractDetailsModel } from './client-business-extract-details.model';
export class ClientBusinessExtractModel{
    Id?: number;
    Code             ?: number;
    Date             ?: Date;
    Deductions       ?: number;
    BusinessInsurance?: number;
    Total            ?: number;
    ClientId         ?: number;
    ProjectId        ?: number;
    Details          ?:ClientBusinessExtractDetailsModel[];
    constructor(item ?: ClientBusinessExtractModel){
        item = item ? item : {};
        this.Id = item.Id ? item.Id : 0;
        this.Code              = item.Code              ? item.Code              : 0;
        this.Date              = item.Date              ? item.Date              : new Date();
        this.Deductions        = item.Deductions        ? item.Deductions        : 0;
        this.BusinessInsurance = item.BusinessInsurance ? item.BusinessInsurance : 0;
        this.Total             = item.Total             ? item.Total             : 0;
        this.ClientId          = item.ClientId          ? item.ClientId          : 0;
        this.ProjectId         = item.ProjectId         ? item.ProjectId         : 0;
        this.Details           = item.Details           ? item.Details           : [];
    }
}