// import { StoreItemsModel } from "./StoreItemsModel";


export class CategoryModel{
    Id?: number;
    Name?:string;
    Image?:string;
    CategoryId?: number;

    constructor(item?:CategoryModel){
        item = item? item:{};
        this.Id = item.Id? item.Id:0;
        this.Name = item.Name? item.Name:'';
        this.Image = item.Image? item.Image:'';     
        this.CategoryId = item.CategoryId? item.CategoryId:0;

    }

}