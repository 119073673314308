import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SupplierTypeModel } from '../model/supplierTypeModel'
import { SuppliersModel } from '../model/suppliersModel'
import { CountryServes } from '../serves/countryServes'

import { CityModel } from '../model/citymodel'
import { CountryModel } from '../model/countrymodel'
import { CityServes } from '../serves/cityServes'
import { GetSupplier } from '../serves/suppliers_serves'
import { GetExpensesCategory } from '../serves/expensesCategory'
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DatePipe } from '@angular/common';


@Component({
    // moduleId: module.id,
    selector: 'add-suppliers',
    templateUrl: 'add-suppliers.component.html',
    styleUrls: ['add-suppliers.component.scss']
})
export class AddSuppliersComponent {
    supplierTypeModel:  SupplierTypeModel[] = [{Id:"b",NameAr:"شركة",NameEn:"Bussines"},{Id:"p",NameAr:"فرد",NameEn:"Person"},{Id:"f",NameAr:"أجنبي",NameEn:"Foreign"}];
    CityList: CityModel[] = [];
    CountryList: CountryModel[] = [];
    SuppliersModel: SuppliersModel;
    ExpensesForm: FormGroup;
    itemId:number;
    modelState:string;
    SuppliersList: SuppliersModel[] = [];
    SuppliersItem: SuppliersModel;
    titleButton: String = "";
   
    constructor(private router:Router, private activatedRoute: ActivatedRoute, public notifications: NotificationsService, private translate: TranslateService, cityServes: CityServes, expensesCategoryServes: GetExpensesCategory, countryServes: CountryServes, private GetSupplier: GetSupplier) {
        this.activatedRoute.params.subscribe((params: Params) => {
            this.modelState = params['state'];
            this.itemId = +params['id'];
        });      
        cityServes.getAll().subscribe(
            item => {
                this.CityList = item;
            }
        );
  
        countryServes.getAll().subscribe(
            item => {
                this.CountryList = item;
            }
        );
    }
    ngOnInit() {
        if (this.modelState == "Add") {
            this.titleButton = "wizard.save";
        } else {
            this.titleButton = "update";
        }
        if (this.modelState != "Add") {
            this.GetSupplier.getAll().subscribe(
                data => {
                    this.SuppliersList = data;
                    var index = this.SuppliersList.findIndex(index => index.Id == +this.itemId);
                    if (index > -1) {
                        this.SuppliersItem = this.SuppliersList[+index];
                        this.LoadCase();
                    }
                }
            )
        } else {
            this.LoadCase();
        }
    }
    LoadCase() {
        switch (this.modelState) {
            case 'Edit': {
                this.EditForm();
                break;
            }
            case 'Show': {
                this.ShowForm();
                break;
            }
            default: {
                this.AddForm();
                break;
            }
        }
    }
    AddForm() {
        this.ExpensesForm = new FormGroup({
            NameArInputForm: new FormControl(null, Validators.required,),
            NameEnInputForm: new FormControl(null, Validators.required,),
            AddressInputForm: new FormControl(null, Validators.required,),
            PhoneInputForm: new FormControl(null, Validators.required,),
            PostalCodeInputForm: new FormControl(null, Validators.required,),
            CountryIdDropdwon: new FormControl(null),
            CitiyNameDropdwon: new FormControl(null),
            SupplierTypeDropdwon: new FormControl(null, Validators.required,),
        })
    }

    EditForm() {

        this.ExpensesForm = new FormGroup({
            NameArInputForm: new FormControl(this.SuppliersItem.NameAr, Validators.required,),
            NameEnInputForm: new FormControl(this.SuppliersItem.NameEn, Validators.required,),
            AddressInputForm: new FormControl(this.SuppliersItem.Address, Validators.required,),
            PhoneInputForm: new FormControl(this.SuppliersItem.Phone, Validators.required,),
            PostalCodeInputForm: new FormControl(this.SuppliersItem.PostalCode, Validators.required,),
            CountryIdDropdwon: new FormControl(this.SuppliersItem.CountryId, Validators.required,),
            CitiyNameDropdwon: new FormControl(this.SuppliersItem.CityId, Validators.required,),
            SupplierTypeDropdwon: new FormControl(this.SuppliersItem.SupplierType, Validators.required,),
        })
    }

    ShowForm() {

        this.ExpensesForm = new FormGroup({
            NameArInputForm: new FormControl({ value: this.SuppliersItem.NameAr, disabled: true }),
            NameEnInputForm: new FormControl({ value: this.SuppliersItem.NameEn, disabled: true }),
            AddressInputForm: new FormControl({ value: this.SuppliersItem.Address, disabled: true }),
            PhoneInputForm: new FormControl({ value: this.SuppliersItem.Phone, disabled: true }),
            PostalCodeInputForm: new FormControl({ value: this.SuppliersItem.PostalCode, disabled: true }),
            CountryIdDropdwon: new FormControl({ value: this.SuppliersItem.CountryId, disabled: true }),
            CitiyNameDropdwon: new FormControl({ value: this.SuppliersItem.CityId, disabled: true }),
            SupplierTypeDropdwon: new FormControl({ value: this.SuppliersItem.SupplierType, disabled: true }),
        })
    }

    onClick() {
        if (this.modelState == "Add") {
            this.Save();
        } else {
            this.Update();
        }
    }

    Save() {
        // this.onWarning();
        debugger;
        var expensesForm = this.ExpensesForm.getRawValue();
        var suppliModel = new SuppliersModel;
        suppliModel.Id = 0;
        suppliModel.NameAr = expensesForm.NameArInputForm;
        suppliModel.NameEn = expensesForm.NameEnInputForm;
        suppliModel.Address = expensesForm.AddressInputForm;
        suppliModel.Phone = expensesForm.PhoneInputForm;
        suppliModel.PostalCode = expensesForm.PostalCodeInputForm;
        suppliModel.CountryId = expensesForm.CountryIdDropdwon;
       
        
        suppliModel.CityId = expensesForm.CitiyNameDropdwon;
        console.log( "suppliModel.CitiyName",suppliModel.CitiyName);
        suppliModel.SupplierType = expensesForm.SupplierTypeDropdwon;
        console.log("suppliModel",suppliModel);
        
        this.GetSupplier.insert(suppliModel).subscribe(
            result => {
                if (result) {
                    this.onSuccess();
                    this.router.navigate(['../app/aio/Purchases/Vendors'])

                } else {
                    this.onError();

                }
            }
        )

    }

    Update() {
        // this.onWarning();
        var expensesForm = this.ExpensesForm.getRawValue();
        var suppliModel = new SuppliersModel;
        suppliModel.Id = +this.itemId;
        suppliModel.NameAr = expensesForm.NameArInputForm;
        suppliModel.NameEn = expensesForm.NameEnInputForm;
        suppliModel.Address = expensesForm.AddressInputForm;
        suppliModel.Phone = expensesForm.PhoneInputForm;
        suppliModel.PostalCode = expensesForm.PostalCodeInputForm;
        suppliModel.CountryId = expensesForm.CountryIdDropdwon;
        suppliModel.CityId = expensesForm.CitiyNameDropdwon;
        suppliModel.SupplierType = expensesForm.SupplierTypeDropdwon;
        console.log("suppliModel",suppliModel);

        this.GetSupplier.update(suppliModel).subscribe(
            result => {
                if (result) {
                    console.log("Succsess");
                    this.onSuccess();
                    this.router.navigate(['../app/aio/Purchases/Vendors'])


                } else {
                    this.onError();

                }
            }
            
        )

    }

    onWarning(): void {

        this.notifications.create(this.translate.instant('Wait'),
            this.translate.instant('Action processing '),
            NotificationType.Warn, { timeOut: 3000, showProgressBar: true });
    }

    onError(): void {
        this.notifications.create(this.translate.instant('Error'),
            this.translate.instant('Something wrong'),
            NotificationType.Error, { timeOut: 3000, showProgressBar: true });
    }

    onSuccess(): void {

        this.notifications.create(this.translate.instant('Success'),
            this.translate.instant('Action Success'),
            NotificationType.Success, { timeOut: 3000, showProgressBar: true });
    }


}
