import { ActivatedRoute, Router } from '@angular/router';
import { ClientBusinessExtractDetailsModel } from './../../models/client-business-extract-details.model';
import { ClientService } from './../../../../invoices/clients/ClientService';
import { ProjectModel } from './../../../../views/invoice/suppliers/model/projectModel';
import { ClientModel } from './../../../../views/invoice/clients/ClientModel';
import { ClientBusinessExtractModel } from './../../models/client-business-extract.model';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ProjectService } from '../../../projects/projects.service';
import { SidePopupComponent } from 'src/app/views/app/aio/shared/side-popup/side-popup.component';
import { WorkItemModel } from 'src/app/Contracting/projects/models/work-item.model';
import { ClientBusinessExtractService } from '../../client-business-extract.service';
@Component({
    selector: 'create-client-business-extract',
    templateUrl: 'create-client-business-extract.component.html',
    styleUrls: ['create-client-business-extract.component.scss']
})
export class CreateClientBusinessExtractComponent implements OnInit{
    @ViewChild('sideModalRef', { static: true }) sideModalRef: SidePopupComponent;
    translationName: string = 'Client Business Extract Details';
  currentFormMode: string = 'Add ';

    Form:FormGroup;
    ClientBusinessExtract:ClientBusinessExtractModel = {};
    clients:ClientModel[]=[];
    projects:ProjectModel[]=[];
    workItems:WorkItemModel[]=[];
    Id = 0;
    constructor(public router:Router, 
      private fb:FormBuilder,
      private clientService:ClientService,
      private projectService:ProjectService,
      private contractorBusinessExtractService:ClientBusinessExtractService,
      private route: ActivatedRoute){
        this.Id = + route.snapshot.paramMap.get('Id');
      }
    createForm():FormGroup{
        return this.fb.group({
            Id:[this.ClientBusinessExtract.Id],
            BusinessInsurance:[this.ClientBusinessExtract.BusinessInsurance],
            ClientId:[this.ClientBusinessExtract.ClientId],
            Code:[this.ClientBusinessExtract.Code],
            Date:[this.ClientBusinessExtract.Date],
            Deductions:[this.ClientBusinessExtract.Deductions],
            ProjectId:[this.ClientBusinessExtract.ProjectId],
            Total:[this.ClientBusinessExtract.Total],
            Details:[this.ClientBusinessExtract.Details],
        })
    }
    detail:ClientBusinessExtractDetailsModel = {};
    DetailsForm:FormGroup;
    createDetailsForm():FormGroup{
        return this.fb.group({
            Id:[this.detail.Id],
            ClientBusinessExtractId:[this.detail.ClientBusinessExtractId],
            Current:[this.detail.Current],
            ExcutionPercentage:[this.detail.ExcutionPercentage],
            Previous:[this.detail.Previous],
            PreviousAndCurrentTotal:[this.detail.PreviousAndCurrentTotal],
            Price:[this.detail.Price],
            TheTotalValue:[this.detail.TheTotalValue],
            ProjectWorkItemId:[this.detail.ProjectWorkItemId],
            Total:[this.detail.Total],
        })
    }
    getContractors(){
      this.projectService.GetWorkItemsWithProjectId(this.Form.get('ProjectId')?.value)
      .subscribe(workItems=>{
          this.workItems = workItems;
      })
    }
    // contractor:ContractorModel=new ContractorModel();
    // selectContractor(){
    //   var i = this.Contractors.findIndex(x=>x.Id == this.Form.get('ContractorId').value);
    //   if(i > -1){

    //       this.contractor = this.Contractors[i];
    //   }
    // }
    GetPreviousDetails(){
      this.contractorBusinessExtractService.GetPreviousDetails(this.Form.get('ProjectId').value,this.Form.get('ClientId').value,this.DetailsForm.get('ProjectWorkItemId').value)
      .subscribe(data => {
          var itemWorkIndex = this.workItems.findIndex(x=>x.Id == this.DetailsForm.get('ProjectWorkItemId').value);
          var price = 0;
          if(itemWorkIndex > -1){
              price = this.workItems[itemWorkIndex].Cost;
          }
          this.detail = data;
          this.detail.Price = price;
          this.DetailsForm = this.createDetailsForm();
      })
    }
    ngOnInit(): void {
        debugger;
        if(this.Id > 0){
          this.contractorBusinessExtractService.getById(this.Id)
          .subscribe(data => {
            this.ClientBusinessExtract = data;
            this.chooseClient();
            this.clientService.getAll()
            .subscribe(clients => {
                this.clients = clients;
                this.Form = this.createForm();
            })
          })
        }
        else{
          this.contractorBusinessExtractService.GetLastCode()
          .subscribe(data => {
              this.clientService.getAll()
              .subscribe(clients => {
                  this.clients = clients;
                  this.ClientBusinessExtract = new ClientBusinessExtractModel();
                  if(data > 0){
                      this.ClientBusinessExtract.Code = data++;
                  }
                  else{
                    this.ClientBusinessExtract.Code = 1;
                  }
                  this.Form = this.createForm();
              })
          })
        }
   
 
     
    }
    chooseClient(){
        this.projectService.getAll()
        .subscribe(data => {
            this.projects = data;
        })
    }
    AddDetail(){
        this.detail = new ClientBusinessExtractDetailsModel();
        this.ClientBusinessExtract = this.Form.getRawValue();
        if(!this.ClientBusinessExtract.Details){
            this.ClientBusinessExtract.Details = [];
        }
        this.Form = this.createForm();
        this.DetailsForm = this.createDetailsForm();
        this.sideModalRef.show();
    }
    addObject(){
        this.detail = this.DetailsForm.getRawValue();
        var i = this.workItems.findIndex(x=>x.Id == this.detail.ProjectWorkItemId);
        if(i>-1){
          this.detail.itemNameAr = this.workItems[i].NameAr;
          this.detail.itemNameEn = this.workItems[i].NameEn;
        }
        this.ClientBusinessExtract = this.Form.getRawValue();
        this.ClientBusinessExtract.Details?.push(this.detail);
        this.ClientBusinessExtract.Total += this.detail.Total;
        this.Form = this.createForm();
        this.sideModalRef.modalRef.hide();
    }
    changeCurrent(){
      this.detail = this.DetailsForm.getRawValue();
      this.detail.PreviousAndCurrentTotal = +this.detail.Previous + +this.detail.Current;
      this.detail.TheTotalValue = +(this.detail.PreviousAndCurrentTotal * this.detail.Price);
      this.detail.Total = this.detail.TheTotalValue;
      // this.ClientBusinessExtract = this.Form.getRawValue();
      // this.ClientBusinessExtract.Total += this.detail.Total;
      // this.Form = this.createForm();
      this.DetailsForm = this.createDetailsForm();
    }
    ExcutionPercentage(){
      this.detail = this.DetailsForm.getRawValue();
      var discount = this.detail.ExcutionPercentage/100;
      var discountValue = this.detail.Total*discount;
      this.detail.Total -= discountValue;
      // this.ClientBusinessExtract = this.Form.getRawValue();
      // this.ClientBusinessExtract.Total -= discountValue;
      // this.Form = this.createForm();
      this.DetailsForm = this.createDetailsForm();
    }
    Save(){
      debugger
      
      this.ClientBusinessExtract = this.Form.getRawValue();
      if(this.Id == 0){
        this.contractorBusinessExtractService.insert(this.ClientBusinessExtract)
        .subscribe(data => {
          this.router.navigate(['app/aio/Construction/client-business-extracts']);
        })
      }
      else{
        this.contractorBusinessExtractService.update(this.ClientBusinessExtract)
        .subscribe(data => {
          this.router.navigate(['app/aio/Construction/client-business-extracts']);
        })
      }
   
    }
    calcBusinssInsurance(){
      debugger;
      this.ClientBusinessExtract = this.Form.getRawValue();
      var descount = this.ClientBusinessExtract.BusinessInsurance/100;
      var decountValue = this.ClientBusinessExtract.Total*descount;
      this.ClientBusinessExtract.Total -= decountValue;
              this.Form = this.createForm();
    }
    calcDeductions(){
      this.ClientBusinessExtract = this.Form.getRawValue();
      var descount = this.ClientBusinessExtract.Deductions/100;
      var decountValue = this.ClientBusinessExtract.Total*descount;
      this.ClientBusinessExtract.Total -= decountValue;
      this.Form = this.createForm();
    }

}
