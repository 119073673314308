import { Component, OnInit } from '@angular/core';
import { ExpensesModel } from '../../models/expenses.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BasicModel } from '../../../expensess-type/helper/model';
import { CurrencyModel } from 'src/app/invoices/currencies/CurrencyModel';
import { ExpensesAccountsModel } from '../../models/expenses-accounts.model';
import { ExpensesCostCenterModel } from '../../models/expenses-cost-centers.model';
import { LockerBasicModel } from '../../../locker-data/helper/model';
import { AccountNameModel } from '../../../account-name/AccountNameModel';
import { CostCenterModel } from '../../../cost-center/models/cost-center.model';
import { BankCodeModel } from '../../../bank-code/bank_code_model';
import { TaxTypesModel } from 'src/app/invoices/tax-types/TaxTypesModel';
import { AccountsNameService } from '../../../account-name/AccountNameService';
import { CostCenterService } from '../../../cost-center/services/cost-center.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountExpensesTypeService } from '../../../expensess-type/helper/serves';
import { TreasuryAndBankAccountsService } from '../../../treasury-and-bank-accounts/treasury-and-bank-accounts.service';
import { TaxTypesService } from 'src/app/invoices/tax-types/TaxTypesService';
import { TranslateService } from '@ngx-translate/core';
import { NotificationType, NotificationsService } from 'angular2-notifications';
import { CurrencyService } from 'src/app/invoices/currencies/CurrencyService';
import { LockersDataService } from '../../../locker-data/helper/serves';
import { BankCodeService } from '../../../bank-code/serves';
import { ExpensesService } from '../../services/expenses.service';
import { ExpensesFilesModel } from '../../models/expenses-files.model';
import { EmployeesManagementService } from 'src/app/HR/Employees/EmployeesManagement/employees-management.service';
import { EmployeesChoiceModel } from 'src/app/HR/Salaries/add-salaries-manager/EmployeesChoiceModel';
import { PurchaseInvoiceService } from 'src/app/views/purchases/invoice-purchase/PurchaseInvoiceService';
import { PurchaseInvoiceModel } from 'src/app/views/purchases/invoice-purchase/PurchaseInvoiceModel';

@Component({
    selector: 'create-expenses',
    templateUrl: 'create-expenses.component.html',
    styleUrls: ['create-expenses.component.scss']
})
export class CreateExpensesComponent implements OnInit{
    expenses:ExpensesModel={};
    declare lang: any;
    Id=0;
    Form:FormGroup;
    buttonName='Add';
    categories:BasicModel[]=[];
    curenncies:CurrencyModel[]=[];
    users:any[]=[];
    SelectedAccount:ExpensesAccountsModel[]=[{AccountId:0,Amount:0,ExpensesId:this.Id,Description:'',Id:0}];
    SelectedCostCenters:ExpensesCostCenterModel[]=[{CostCenterId:0,Amount:0,ExpensesId:this.Id,Percent:0,Id:0}];
    LockersData:LockerBasicModel[]=[];
    public editEnabled = true;
   public picurl: string ='';
   AccountsNameModel: AccountNameModel[] = [];
   PackUpAccounts: AccountNameModel[] = [];
   centers:CostCenterModel[]=[];
   Banks:BankCodeModel[]=[];
   Treasuries:any[]=[];
   taxes:TaxTypesModel[]=[]
   employees:EmployeesChoiceModel[]=[];
   invoices:PurchaseInvoiceModel[]=[];
    constructor(
        private CurrencyService: CurrencyService, 
        private accountsNameService: AccountsNameService,
        private costCenterService:CostCenterService,
       private route: ActivatedRoute,
       public router:Router,
       private ExpensesService:ExpensesService,
       public fb:FormBuilder,
       private ExpensesCategoryService:AccountExpensesTypeService,
       private LockersDataService:LockersDataService,
       private BankCodeService:BankCodeService,
       private treasuryService:TreasuryAndBankAccountsService,
       private taxService:TaxTypesService,
       private translate: TranslateService,
        private notifications: NotificationsService,
        private employeeService:EmployeesManagementService,
        private PurchaseInvoiceService:PurchaseInvoiceService
        ){
     this.lang = localStorage.getItem('theme_lang');
     this.Id = + route.snapshot.paramMap.get('Id');
       
   }
   centersLoaded= false;
   TreasuriesLoaded= false;
   taxesLoaded= false;
   AccountsLoaded= false;
   categoriesLoaded= false;
   curennciesLoaded= false;
    ngOnInit(): void {
        this.PurchaseInvoiceService.GetAllNotPayed()
        .subscribe(result=>{
            this.invoices = result
        })
        this.employeeService.getAll()
        .subscribe(result=>{
            this.employees = result;
        })
        this.treasuryService.getAll()
        .subscribe(data=>{

            this.Treasuries = data;
            this.TreasuriesLoaded = true;
        })
        this.taxService.getAll()
        .subscribe(data => {
            this.taxes = data;
            this.taxesLoaded = true;
       

        })
        this.accountsNameService.GetAllSubAccountes(0).subscribe(
            data=>{
                this.AccountsNameModel=data;
                this.PackUpAccounts = data.map(item=>Object.assign({}, item))
                this.AccountsLoaded = true;

            }
        );
        this.ExpensesCategoryService.getAll()
        .subscribe(cstegorie=>{
            this.categories = cstegorie
            console.log('this.categories ',this.categories )
            this.categoriesLoaded = true;

        })
        this.CurrencyService.getAll()
        .subscribe(data => {
            debugger;
            this.curenncies = data;
        
            this.curennciesLoaded = true;
   
        })
        this.costCenterService.getAll(0)
        .subscribe(cosetCenters=>{
            this.centers = cosetCenters;
            this.centersLoaded = true;
        })
        if(this.Id == 0){
            this.ExpensesService.GetLastCode()
            .subscribe(code=>{
                this.expenses = new ExpensesModel();
                this.expenses.Code = code + 1;
                this.Form = this.createForm();
                this.curenncies.forEach(element => {
                    if(element.IsMain == true){
                        this.Form.controls['CurrencyId'].setValue(element.Id);
                    }
                });
            })
       
        }
        else{
            this.ExpensesService.getById(this.Id)
            .subscribe(data=>{
                this.buttonName = "Edit"
                this.expenses =data;
                this.SelectedAccount    =this.expenses.Accounts    ;
                this.SelectedCostCenters=this.expenses.CostCenters ;
                this.files              =this.expenses.Files       ;
                this.Form = this.createForm();
            })
        }
        
    }
    createForm():FormGroup{
        return this.fb.group({
            Id              :[this.expenses.Id              ],
            Amount          :[this.expenses.Amount          ],
            CurrencyId      :[this.expenses.CurrencyId      ],
            Code            :[this.expenses.Code            ],
            Date            :[this.expenses.Date            ],
            TheRecipient    :[this.expenses.TheRecipient    ],
            CategoryId      :[this.expenses.CategoryId      ],
            TaxId           :[this.expenses.TaxId           ],
            TaxValue        :[this.expenses.TaxValue        ],
            IsMulti         :[this.expenses.IsMulti         ],
            WithCostCenters :[this.expenses.WithCostCenters ],
            IsFrequent      :[this.expenses.IsFrequent      ],
            FrequentType    :[this.expenses.FrequentType    ],
            ExpiryDate      :[this.expenses.ExpiryDate      ],
            Files         :[this.expenses.Files         ],
            Description     :[this.expenses.Description     ],
            Accounts        :[this.expenses.Accounts        ],
            CostCenters     :[this.expenses.CostCenters     ],
            AccountId      :[this.expenses.AccountId ],
            Treasury       :[this.expenses.Treasury  ],
            TreasuryType       :[this.expenses.TreasuryType  ],
            PurchaseInvoiceId       :[this.expenses.PurchaseInvoiceId  ],
        })
    }
    setIsMulti(IsMulti){
        this.Form.controls['IsMulti'].setValue(IsMulti);
        if(this.Form.controls['IsMulti'].value==false){
            this.SelectedAccount=[{AccountId:0,Amount:0,ExpensesId:this.Id,Description:'',Id:0}];
        }
        else if(this.Form.controls['IsMulti'].value==true){
            this.Form.controls['AccountId'].setValue(0);
            this.SelectedCostCenters=[{CostCenterId:0,Amount:0,ExpensesId:this.Id,Percent:0,Id:0}];
            this.Form.controls['TaxId'].setValue(0);
            this.Form.controls['TaxValue'].setValue(0);

        }
    }
    splitAccount($event){
        debugger;
        var i = this.AccountsNameModel.findIndex(x=>x.Id == $event.Id);
        if(i > -1){
            this.AccountsNameModel.splice(i,1);
        }
    }
    deleteSelectedAccount(item){
        debugger;
        var i = this.SelectedAccount.indexOf(item);
        if(i > -1){
         
            var x= this.PackUpAccounts.findIndex(x=>x.Id == this.SelectedAccount[i].AccountId);
            if(x > -1){

                this.AccountsNameModel.push(this.PackUpAccounts[x]);
            }
            this.SelectedAccount.splice(i,1);
            this.changeAccountAmount();
        }
    }
    AddonClick(){
        debugger;
        var selectedDailyrestrictionsFooterModel =new ExpensesAccountsModel();
        selectedDailyrestrictionsFooterModel.Id = this.SelectedAccount.length+1 ;
        selectedDailyrestrictionsFooterModel.Amount = 0 ;
        selectedDailyrestrictionsFooterModel.AccountId = 0 ;
        selectedDailyrestrictionsFooterModel.ExpensesId = this.Id ;
        selectedDailyrestrictionsFooterModel.Description = "" ;
        this.SelectedAccount.push(selectedDailyrestrictionsFooterModel);
    }
    AddCostCenter(){
        this.SelectedCostCenters.push(new ExpensesCostCenterModel());
    }
    deleteCostCenter(cost){
        var i = this.SelectedCostCenters.indexOf(cost);
        if(i > -1){
            this.SelectedCostCenters.splice(i,1);
        }
       
    }
    changePercent(cost,$event){
        debugger;
        var amount = this.Form.controls['Amount'].value;
        cost.Percent = + $event.target.value;
        var totalPercent = 0;
        this.SelectedCostCenters.forEach(element => {
            totalPercent += element.Percent;
        });
        totalPercent = totalPercent - cost.Percent;
        if(cost.Percent <= 100 && cost.Percent > 0 && totalPercent < 100){
            if((cost.Percent+totalPercent) > 100){
                cost.Percent = 100 - totalPercent ;
                if(cost.Percent < 0){
                    cost.Percent *= -1
                }
            }
            cost.Amount = (amount * cost.Percent) / 100;

        }
        else if(totalPercent >= 100){
            cost.Percent = 0;
            cost.Amount = (amount * cost.Percent) / 100;

        }
        else if(cost.Percent > 100){
            cost.Percent = 100;
            cost.Amount = (amount * cost.Percent) / 100;

        }
    }
    changeAmount(cost,$event){
        debugger;
        var amount = this.Form.controls['Amount'].value;
        cost.Amount = + $event.target.value;
        var totalPercent = 0;
        this.SelectedCostCenters.forEach(element => {
            totalPercent += element.Percent;
        });
        cost.Percent =(cost.Amount * 100)/ amount

        if(cost.Percent <= 100 && cost.Percent > 0&& totalPercent < 100){
            if((cost.Percent+totalPercent) > 100){
                cost.Percent = 100 - totalPercent ;
                if(cost.Percent < 0){
                    cost.Percent *= -1
                }

            }
            cost.Amount = (amount * cost.Percent) / 100;

        }
        else if(totalPercent >= 100){
            cost.Percent = 0;
            cost.Amount = (amount * cost.Percent) / 100;

        }
        else if(cost.Percent > 100){
            cost.Percent = 100;
            cost.Amount = (amount * cost.Percent) / 100;

        }
    }
    changeAccountAmount(){
        var total = 0;
        this.SelectedAccount.forEach(element => {
            total += element.Amount;
        });
        var amount = this.Form.controls['Amount'].setValue(total);
    }
    Save(){
        debugger;
        this.expenses = this.Form.getRawValue();
        this.expenses.Id = this.Id;
        this.expenses.Accounts = this.SelectedAccount;
        this.expenses.CostCenters = this.SelectedCostCenters;
        this.expenses.Files = this.files;
        if(this.Id > 0 ){
            this.ExpensesService.update(this.expenses)
            .subscribe(result => {
                if(result == true){
                    this.router.navigate(['app/accounts/expenses']);
                    this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyEdited"));
    
                }
                else{
                 this.toastError(this.translate.instant("Error"), this.translate.instant("FailedEdit"));

                }
            })
        }
        else{
            this.ExpensesService.insert(this.expenses)
            .subscribe(result => {
                if(result == true){
                    this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyAdd"));
                    this.router.navigate(['app/accounts/expenses']);
    
                }
                else{
                 this.toastError(this.translate.instant("Error"), this.translate.instant("FailedAdd"));

                }
            })
        }
   
    }
    addAndNew(){
        debugger;
        this.expenses = this.Form.getRawValue();
        this.expenses.Id = this.Id;
        this.expenses.Accounts = this.SelectedAccount;
        this.expenses.CostCenters = this.SelectedCostCenters;
        this.expenses.Files = this.files;
        if(this.Id > 0 ){
            this.ExpensesService.update(this.expenses)
            .subscribe(result => {
                if(result == true){
                    this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyEdited"));
                    this.expenses = new ExpensesModel();
                   this.picurl = '';
                   this.SelectedAccount = [];
                   this.SelectedCostCenters = [];
                    this.files = [];
                }
                else{
                 this.toastError(this.translate.instant("Error"), this.translate.instant("FailedEdit"));
                    
                }
            })
        }
        else{
            this.ExpensesService.insert(this.expenses)
            .subscribe(result => {
                if(result == true){
                    this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyAdd"));
                    this.expenses = new ExpensesModel();
                    this.picurl = '';
                    this.SelectedAccount = [];
                    this.SelectedCostCenters = [];
                    this.files = [];

                }
                else{
                    this.toastError(this.translate.instant("Error"), this.translate.instant("FailedAdd"));

                }
            })
        }
   
    }
    files:ExpensesFilesModel[]=[];
    setFileToList(){
        var file = new ExpensesFilesModel();
        file.ExpensesId = this.Id ;
        file.FileUrl = this.picurl;
        file.Id = 0;
        this.files.push(file);
    }
    deleteFile(file){
        var i = this.files.indexOf(file);
        if(i>-1){
            this.files.splice(i,1);
        }
    }
    maxAmount = 0;
    GetPayedAmount(){
        var InvoiceId = this.Form.controls['PurchaseInvoiceId'].value;
        this.ExpensesService.GetPayedAmount(InvoiceId)
        .subscribe(result=>{
            console.log('PayedAmount',result);
            var invoiceIndex = this.invoices.findIndex(x=>x.Id == InvoiceId);
            if(invoiceIndex > -1){
                this.maxAmount = this.invoices[invoiceIndex].TotalItemGrossPrices - result;
                this.Form.controls['Amount'].setValue(this.maxAmount);

            }
        })
    }
    
    chickAmount(){
        var amount = this.Form.controls['Amount'].value;
        if(amount > this.maxAmount && this.maxAmount > 0){
            this.Form.controls['Amount'].setValue(this.maxAmount);
        }
    }
    toastSuccess(toastHeader: string, toastBody: string): void {
        this.notifications.create(toastHeader, toastBody,
          NotificationType.Success, { timeOut: 3000, showProgressBar: true });
      }
      toastError(toastHeader: string, toastBody: string): void {
        this.notifications.create(toastHeader, toastBody,
          NotificationType.Error, { timeOut: 3000, showProgressBar: true });
      }
}

