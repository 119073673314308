<app-side-popup #sideModalRef>
    <div class="modal-content"
        style="width: 90%; text-align: center; margin-top: 20px; margin-left: auto; margin-right: auto;">
        <div style="align-content: center; display: inline-block;">
            <div class="modal-header" style="display: block; padding: 0px 0px 5px 0px; text-align: center; ">
                <h1 id="dialog-child-name" class="modal-title">
                    {{tableEngine.currentFormMode+translationName | translate}}</h1>
            </div>
            <div class="modal-body">
                <form class="md-float-material form-material" [formGroup]="mainForm">

                    <div class="form-group form-primary">
                        <label class="float-label push righ">{{"Check Book Number" | translate}}</label>
                        <input *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                            type="text" name="Code" class="form-control" formControlName="CheckBookNumber"
                            placeholder='{{"Check Book Number" | translate}}'>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="Code">{{modelObject.CheckBookNumber}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('CheckBookNumber').hasError('required') || mainForm.get('CheckBookNumber').invalid) && ( mainForm.get('CheckBookNumber').dirty ||  mainForm.get('CheckBookNumber').touched)">
                            {{"Check Book Number Required" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>
                    
                    <!-- <div class="form-group form-primary" *ngIf="mainForm.get('CheckBookMain').value==false"> -->
                    <div class="form-group form-primary">
                        <label class="float-label push righ">{{"Bank" | translate}}</label>
                        <div *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'">
                            <ng-select appearance="outline"
                                name="BankId" formControlName="BankId"
                                placeholder='{{"Bank" | translate}}' #CheckBookNumberSelect labelForId="txtycd"
                                [searchable]="true">
                                <ng-option *ngFor="let parent of banckAccounts"
                                    [value]="parent.Id">
                                    {{parent.Name}}</ng-option>
                            </ng-select>
                        </div>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="BankId">{{getBanckNameById(modelObject.BankId)}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('BankId').hasError('required') || mainForm.get('BankId').invalid) && ( mainForm.get('BankId').dirty ||  mainForm.get('BankId').touched)">
                            {{"Bank Required" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>

                    <div class="form-group form-primary">
                        <label class="float-label push righ">{{"The First Serial Number" | translate}}</label>
                        <input *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'" type="text" name="TheFirstSerialNumber"
                            class="form-control" formControlName="TheFirstSerialNumber"
                            placeholder='{{"The First Serial Number" | translate}}'>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="TheFirstSerialNumber">{{modelObject.TheFirstSerialNumber}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('TheFirstSerialNumber').hasError('required') || mainForm.get('TheFirstSerialNumber').invalid) && ( mainForm.get('TheFirstSerialNumber').dirty ||  mainForm.get('TheFirstSerialNumber').touched)">
                            {{"The First Serial Number Required" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>

                    <div class="form-group form-primary">
                        <label class="float-label push righ">{{"TheLastSerialNumber" | translate}}</label>
                        <input *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'" type="text" name="TheLastSerialNumber"
                            class="form-control" formControlName="TheLastSerialNumber"
                            placeholder='{{"TheL ast Serial Number" | translate}}'>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="TheLastSerialNumber">{{modelObject.TheLastSerialNumber}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('TheLastSerialNumber').hasError('required') || mainForm.get('TheLastSerialNumber').invalid) && ( mainForm.get('TheLastSerialNumber').dirty ||  mainForm.get('TheLastSerialNumber').touched)">
                            {{"The Last Serial Number Required" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>
                    
                    <div class="form-group form-primary">
                        <label class="float-label push righ">{{"Currency" | translate}}</label>
                        <div *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'">
                            <ng-select appearance="outline"
                                name="CurrencyId" formControlName="CurrencyId"
                                placeholder='{{"Currency" | translate}}' #TheLastSerialNumberSelect labelForId="txtycd"
                                [searchable]="true">
                                <ng-option *ngFor="let codingUnit of currences"
                                    [value]="codingUnit.Id">
                                    {{lang==='ar-EG'?codingUnit.NameAr:codingUnit.NameEn}}</ng-option>
                            </ng-select>
                        </div>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="CurrencyId">{{getCurrencyNameById(modelObject.CurrencyId)}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('CurrencyId').hasError('required') || mainForm.get('CurrencyId').invalid) && ( mainForm.get('CurrencyId').dirty ||  mainForm.get('CurrencyId').touched)">
                            {{"Currency Required" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>
                    

                    <div class="form-group form-primary">
                        <label class="float-label push righ">{{"Description" | translate}}</label>
                        <textarea *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'" type="text" name="Description"
                            class="form-control" formControlName="Description"
                            placeholder='{{"Description" | translate}}'></textarea>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="Description">{{modelObject.Description}}</label>
               
                        <span class="form-bar"></span>
                    </div>
                    


                    <div style="margin-left: 5px; text-align: center; margin-top: 30px;">
                        <button *ngIf="tableEngine.currentFormMode==='Add'"
                            [disabled]="mainForm.invalid || tableEngine.currentAction==='loading'" id="smallModalSubmitButton"
                            class="btn btn-secondary" (click)="addObject()"
                            style="font-weight: bold; width: 100%; text-align: center;">{{"Add" |
                            translate}}</button>
                        <!-- <button *ngIf="tableEngine.currentFormMode==='Add'"
                            [disabled]="mainForm.invalid || tableEngine.currentAction==='loading'" id="smallModalSubmitButton"
                            class="btn btn-secondary" (click)="addAndNew()"
                            style="font-weight: bold; width: 100%; text-align: center; margin-top: 10px;">{{"AddAndNew" |
                            translate}}</button> -->
                        <button *ngIf="tableEngine.currentFormMode==='Edit'"
                            [disabled]="mainForm.invalid || tableEngine.currentAction==='loading'" id="smallModalSubmitButton"
                            class="btn btn-secondary" (click)="editObject()"
                            style="font-weight: bold; width: 100%; text-align: center;">{{"Edit"
                            | translate}}</button>
                        <button class="btn btn-light" (click)="sideModalRef.modalRef.hide()"
                            style="font-weight: bold; width: 100%; text-align: center; margin-top: 10px;">{{"Close" |
                            translate}}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</app-side-popup>
<div bsModal #smallModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <button type="button" class="close pull-right" aria-label="Close" (click)="smallModal.hide()"
                style="margin-right: 20px; margin-top: 10px; width: 20px; display: inline;">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-header" style="display: block; padding: 0px 0px 5px 0px; text-align: center; ">
                <h1 id="dialog-child-name" class="modal-title">
                    {{tableEngine.currentFormMode+translationName | translate}}</h1>
            </div>
            <div class="modal-body">
                <form class="md-float-material form-material" [formGroup]="mainForm">
                    
                    
                </form>
            </div>
        </div>
    </div>
</div>

<div bsModal #delModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">
    <div class="modal-dialog modal-sm">
        <div class="modal-content"  >
            <button type="button" class="close pull-right" aria-label="Close" (click)="delModal.hide()"
                style="margin-right: 20px; margin-left: 20px; margin-top: 10px; width: 20px; display: inline;">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-header" style="display: inline; padding: 0px 0px 5px 0px;">
                <h4 id="dialog-child-name" class="modal-title pull-left"  style="text-align: center;">
                    {{"ConfirmDel"+translationName |
                    translate}}</h4>
            </div>
            <div class="modal-body">
                <div class="form-group form-primary" *ngIf="tableEngine.deletionList.length < tableEngine.ogData.length 
                || tableEngine.deletionList.length === 1" 
                style="text-align: center;">
                    <div *ngFor="let item of tableEngine.deletionList;" style="text-align: center;">
                        <label type="text">- {{lang==='ar-EG'?item.CheckBookNumber:item.CheckBookNumber}}</label>
                        <span class="form-bar"></span>
                    </div>
                </div>
                <div class="form-group form-primary" style="text-align: center;" *ngIf="tableEngine.deletionList.length > 1 && 
                    tableEngine.deletionList.length === tableEngine.ogData.length">
                    <label type="text">
                        <h1 style="font-weight: bolder; text-align: center;">{{translationName+"DeleteAll" | translate}}</h1>
                    </label>
                    <span class="form-bar"></span>
                </div>

                <div style="margin-left: 5px; text-align: center; margin-top: 30px;">
                    <button class="btn btn-danger" id="smallModalDeleteButton" (click)="deleteObject()"
                        [disabled]="tableEngine.currentAction==='loading'"
                        style="font-weight: bold; width: 100%; text-align: center;">{{"Delete" | translate}}</button>
                    <button class="btn btn-light" (click)="delModal.hide()"
                        style="font-weight: bold; width: 100%; text-align: center; margin-top: 10px;">{{"Close" |
                        translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div  *ngIf="currentScreen === 'Form'" class="FormScreen">
    <a (click)="currentScreen='Table'">back</a>
</div>

<div *ngIf="currentScreen === 'Table'" class="FormScreen" class="tableScreen">
    <!-- <div id="divtop" style="display: block;height: 47px; width: 100%;">
        <div id="divsearchmiddle" style="display: inline-block; width: 70%;
        height: inherit;  text-align: center; padding: 0px; margin: 0px;">
            <div class="search" style="display: inline-block; margin-left: 50px; margin-right: 50px;">
                <div class="d-inline-block">
                    <div class="search-sm d-inline-block mr-1 mb-1 align-top" style="width: 600px;">
                        <input type="search" [(ngModel)]="tableEngine.SearchTerm" (keyup)="tableEngine.changeFilter()" (change)="tableEngine.changeFilter()"
                            class="form-control input-sm full-data-search" placeholder="{{translationName+'Search' | translate}}">
                    </div>
                </div>
            </div>
        </div>
    
        <div id="divsearchleft" style="display: inline-block; width: 30%; height: inherit;">
            <div style="display: block; height: 30%; ">
                &nbsp;
            </div>
            <div class="search" style="display: block; height: 70%; text-align: center;">
                <button class="btn btn-primary d-inline-block"
                    style="font-weight: bold; text-align: center; padding: 0px; width: 150px; height: 30px;"
                    (click)="showModal(null,'Add')" [disabled]="tableEngine.currentAction==='loading'">{{"Add"+translationName |
                    translate}}</button>
    
            </div>
        </div>
    
    </div> -->
    <app-list-page-header *ngIf="currentScreen === 'Table'"[itemOptionsPerPage]="[5,10,20]"
    [itemsPerPage]="tableEngine.itemsPerPage" [selectAllState]="tableEngine.selectAllState" (addNewItem)="showModal(null,'Add')"
    [tableEngine]="this.tableEngine" (selectAllChange)="tableEngine.selectAll($event)" (changeOrderBy)="prepChangeSort($event)"
    (searchKeyUp)="tableEngine.filterDataByDateRange(this.SearchFrom,this.SearchTo,'Date')" [dateSearch]=true>
</app-list-page-header>
        
    <div id="divmiddle" style="display: block; margin-top: 5px;">
    
        <div style="width: 100%;">
            <div>
                <table class="table">
                    <thead class="thead-light">
                        <th style="text-align: center; padding:5px;">
                            <div class=" text-zero" style="display: inline-block;">
                                <div class="top-right-button-container2 btn-group" dropdown>
                                    <div class="btn btn-primary pl-4 pr-0 check-button">
                                        <label class="custom-control custom-checkbox mb-0 d-inline-block"
                                            style="width: 10px;">
                                            <input type="checkbox" class="custom-control-input"
                                                [checked]="tableEngine.selectAllState==='checked'"
                                                [indeterminate]="tableEngine.selectAllState === 'indeterminate'"
                                                (change)="tableEngine.selectAll($event)">
                                            <span class="custom-control-label">&nbsp;</span>
                                        </label>
                                    </div>
                                    <button id="button-split" type="button" dropdownToggle style="width: 10px;"
                                        class="btn btn-primary dropdown-toggle dropdown-toggle-split"
                                        aria-controls="dropdown-split">
                                        <span class="caret"></span>
                                    </button>
                                    <ul id="dropdown-split" *dropdownMenu class="dropdown-menu dropdown-menu-right"
                                        role="menu" aria-labelledby="button-split">
                                        <li role="menuitem"><a class="dropdown-item">
                                                <button class="btn btn-danger d-inline-block"
                                                    style=" font-weight: bold; text-align: center; padding: 0px; width: 150px; height: 30px;"
                                                    (click)="tableEngine.showDelModal('delMulti')"
                                                    [disabled]="tableEngine.selected.length===0 || tableEngine.currentAction==='loading'">{{"DeleteSelected"
                                                    |
                                                    translate}}</button></a></li>
                                    </ul>
                                </div>
                            </div>
                        </th>
                   
                   
                        <th class="sorticon" (click)="prepChangeSort('CheckBookNumber')" [ngClass]="(itemOrder.label==='CheckBookNumber')?'sortkey sorticon':'sorticon'">
                            <div [ngClass]="{'iconsminds-up---down': (itemOrder.label!='CheckBookNumber'), 
                            'iconsminds-down': ((itemOrder.label==='CheckBookNumber') && tableEngine.orderAsc === true),
                            'iconsminds-up': ((itemOrder.label==='CheckBookNumber') && tableEngine.orderAsc === false)}"></div></th>
                        <th class="sortOption" (click)="prepChangeSort('CheckBookNumber')" 
                        [ngClass]="{'sortkey': (itemOrder.label==='CheckBookNumber')}">
                                {{"CheckBookNumber" | translate}}
                        </th>
                        <th class="sorticon" (click)="prepChangeSort('TheFirstSerialNumber')" [ngClass]="(itemOrder.label==='TheFirstSerialNumber')?'sortkey sorticon':'sorticon'">
                            <div [ngClass]="{'iconsminds-up---down': (itemOrder.label!='TheFirstSerialNumber'), 
                            'iconsminds-down': ((itemOrder.label==='TheFirstSerialNumber') && tableEngine.orderAsc === true),
                            'iconsminds-up': ((itemOrder.label==='TheFirstSerialNumber') && tableEngine.orderAsc === false)}"></div></th>
                        <th class="sortOption" (click)="prepChangeSort('TheFirstSerialNumber')" 
                        [ngClass]="{'sortkey': (itemOrder.label==='TheFirstSerialNumber')}">
                                {{"TheFirstSerialNumber" | translate}}
                        </th>
                        <th class="sorticon" (click)="prepChangeSort('TheLastSerialNumber')" [ngClass]="(itemOrder.label==='TheLastSerialNumber')?'sortkey sorticon':'sorticon'">
                            <div [ngClass]="{'iconsminds-up---down': (itemOrder.label!='TheLastSerialNumber'), 
                            'iconsminds-down': ((itemOrder.label==='TheLastSerialNumber') && tableEngine.orderAsc === true),
                            'iconsminds-up': ((itemOrder.label==='TheLastSerialNumber') && tableEngine.orderAsc === false)}"></div></th>
                        <th class="sortOption" (click)="prepChangeSort('TheLastSerialNumber')" 
                        [ngClass]="{'sortkey': (itemOrder.label==='TheLastSerialNumber')}">
                                {{"TheLastSerialNumber" | translate}}
                        </th>
                     
                        <th></th>
                        <th></th>
                        <th></th>
                        <th [colSpan]="2" style="text-align: center;">
                            <div id="displayOptions" style="
                            display: inline; margin-top: 10px; margin-left: 5px; margin-right: 5px;">
                                <span class="text-muted text-small" style=" width: 50px; height: 40px;">
                                    {{"ResultCount" | translate}}
                                </span>
                                <span dropdown class="d-inline-block position-relative">
                                    <button class="btn btn-outline-dark btn-xs dropdown-toggle" type="button"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" dropdownToggle>
                                        {{tableEngine.itemsPerPage}}
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-sm text-right"
                                        *dropdownMenu>
                                        <li role="menuitem" *ngFor="let item of tableEngine.itemOptionsPerPage"><a
                                                [ngClass]="item === tableEngine.itemsPerPage ? 'dropdown-item active' : 'dropdown-item'"
                                                (click)="tableEngine.paginateData(item, 1)">{{item}}</a></li>
                                    </div>
                                </span>
                            </div>
                        </th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let obj of tableEngine.paginatedData;  let i=index">
                            <td style="text-align: center;">
                                <div class="itemCheck mb-0 custom-checkbox custom-control" style="display: inline-block;">
                                    <input type="checkbox" class="custom-control-input" [id]="'customCheck'+i"
                                        [checked]="tableEngine.isSelected(obj)" (change)="tableEngine.onSelect(obj)">
                                    <label class="custom-control-label" [for]="'customCheck'+i"></label>
                                </div>
                            </td>
                            <td colspan="2">{{obj.CheckBookNumber}}</td>
                            <td colspan="2">{{obj.TheFirstSerialNumber}}</td>
                            <td colspan="2">{{obj.TheLastSerialNumber}}</td>
                            <td colspan="2">{{obj.Cost}}</td>
                            <td style="text-align: center;">
                                <i class="simple-icon-info" (click)="showModal(obj.Id, 'Info')"
                                    tooltip="{{'Info'|translate}}" placement="auto" style="font-size: 20px; font-weight: bolder; cursor: pointer; 
                                        vertical-align: middle; color: #17A2B8;"></i>
                            </td>
                            <td style="text-align: center;">
                                <i class="simple-icon-pencil" (click)="showModal(obj.Id, 'Edit')"
                                    tooltip="{{'Edit'|translate}}" placement="auto" style="font-size: 20px; font-weight: bolder; cursor: pointer; 
                                        vertical-align: middle; color: #FFC107;"></i>
                            </td>
                            <td style="text-align: center;">
                                <i class="simple-icon-close" (click)="tableEngine.showDelModal(obj.Id)"
                                    tooltip="{{'Delete'|translate}}" placement="auto" style="font-size: 20px; font-weight: bolder; cursor: pointer; 
                                        vertical-align: middle; color: #DC3545;"></i>
                            </td>
                        </tr>
                    </tbody>
                    <thead class="thead-light">
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
              
                        <th></th>
                        <th></th>
                        <th></th>
                    </thead>
                </table>
            </div>
        </div>
    </div>
    
    <div id="divbottom" style="display: block; height: 47px;">
    
        <div id="divsearchright" style="float: right;  width: 33.33%; padding: 0px;">&nbsp;</div>
    
        <div id="divsearchmiddle" style="display: inline-block; width: 33.33%; height: 100%;">
    
            <div style="text-align: center;">
                <bdo dir="ltr">
                    <pagination class="pagination-sm2" style="padding: 0px, 0px, 0px, 0px; margin: 0px, 0px, 0px, 0px;
                    display: inline-block;" [(ngModel)]="currentPage" [boundaryLinks]="true"
                        [totalItems]="tableEngine.filteredData.length" [itemsPerPage]="tableEngine.itemsPerPage"
                        [customFirstTemplate]="firstTemplate" [customLastTemplate]="lastTemplate"
                        [customPreviousTemplate]="prevTemplate" [customNextTemplate]="nextTemplate" [maxSize]="5"
                        (pageChanged)="tableEngine.changePage($event)">
                    </pagination>
                </bdo>
            </div>
        </div>
    
        <div id="divsearchleft" style="float: left; width: 33.33%; height: 100%;">&nbsp;</div>
    
    </div>
</div>

<div>

</div>

<ng-template #nextTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i *ngIf="lang==='en-US'" class='simple-icon-arrow-right'></i>
    <i *ngIf="lang==='ar-EG'" class='simple-icon-arrow-left'></i>
</ng-template>

<ng-template #prevTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i *ngIf="lang==='en-US'" class='simple-icon-arrow-left'></i>
    <i *ngIf="lang==='ar-EG'" class='simple-icon-arrow-right'></i>
</ng-template>

<ng-template #lastTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i class='simple-icon-control-end'></i>
</ng-template>

<ng-template #firstTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i class='simple-icon-control-start'></i>
</ng-template>


