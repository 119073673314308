import { JournalFooterCostCenterModel } from "src/app/views/app/aio/acounting_components/dailyrestrictions/journal-footer-cost-center.model";
import { ItemPostModel } from "./Item_post_model";

export class PostInvoiceModel{
    Id ?: number;
    InvoiceNumber ?: string;
    TaxActivityCode ?: string;
    Type ?: string;
    SalesOrder ?: string;
    BranchId ?: number;
    PurchaseOrder ?: string;
    ClientId ?: number;
    TotalPrice ?: number;
    ReleaseDate ?: Date;
    PurchaseDate ?: Date;
    SupplierId     ?: number;
    Status         ?: string;
    Approach       ?: string;
    Packaging      ?: string;
    DateValidity   ?: string;
    ExportPort     ?: string;
    CountryOfOrigin?: string;
    GrossWeight    ?: string;
    NetWeight      ?: string;
    Terms          ?: string;
    BankName       ?: string;
    BankAddress    ?: string;
    BankAccountNo  ?: string;
    BankAccountIBAN?: string;
    SwiftCode      ?: string;
    Currency      ?: string;
    Lang      ?: string;
    extraDiscountAmount     ?:number;
    netAmount               ?:number;
    totalAmount             ?:number;
    totalDiscountAmount     ?:number;
    totalItemsDiscountAmount?:number;
    totalSalesAmount        ?:number;
    PaymentPlanId        ?:number;
    CostCenters?:JournalFooterCostCenterModel[];
    items ?: ItemPostModel[];
    constructor(item ?: PostInvoiceModel){
        item = item ? item : {};
        this.Id = item.Id ? item.Id : 0;
        this.InvoiceNumber = item.InvoiceNumber ? item.InvoiceNumber : '';
        this.Lang = item.Lang ? item.Lang : '';
        this.TaxActivityCode = item.TaxActivityCode ? item.TaxActivityCode : '';
        this.Type = item.Type ? item.Type : '';
        this.PurchaseOrder = item.PurchaseOrder ? item.PurchaseOrder : '';
        this.BranchId = item.BranchId ? item.BranchId : 0;
        this.ClientId = item.ClientId ? item.ClientId : 0;
        this.TotalPrice = item.TotalPrice ? item.TotalPrice : 0;
        this.PaymentPlanId = item.PaymentPlanId ? item.PaymentPlanId : 0;
        this.ReleaseDate = item.ReleaseDate ? item.ReleaseDate : null;
        this.PurchaseDate = item.PurchaseDate ? item.PurchaseDate : null;
        this.items = item.items ? item.items : [];
        this.SupplierId      = item.SupplierId      ? item.SupplierId      : 0;
        this.extraDiscountAmount      = item.extraDiscountAmount     ? item.extraDiscountAmount     : 0;
        this.netAmount                = item.netAmount               ? item.netAmount               : 0;
        this.totalAmount              = item.totalAmount             ? item.totalAmount             : 0;
        this.totalDiscountAmount      = item.totalDiscountAmount     ? item.totalDiscountAmount     : 0;
        this.totalItemsDiscountAmount = item.totalItemsDiscountAmount? item.totalItemsDiscountAmount: 0;
        this.totalSalesAmount         = item.totalSalesAmount        ? item.totalSalesAmount        : 0;
        this.Status          = item.Status          ? item.Status          : '';
        this.Approach        = item.Approach        ? item.Approach        : '';
        this.Packaging       = item.Packaging       ? item.Packaging       : '';
        this.DateValidity    = item.DateValidity    ? item.DateValidity    : '';
        this.ExportPort      = item.ExportPort      ? item.ExportPort      : '';
        this.CountryOfOrigin = item.CountryOfOrigin ? item.CountryOfOrigin : '';
        this.GrossWeight     = item.GrossWeight     ? item.GrossWeight     : '';
        this.NetWeight       = item.NetWeight       ? item.NetWeight       : '';
        this.Terms           = item.Terms           ? item.Terms           : '';
        this.BankName        = item.BankName        ? item.BankName        : '';
        this.BankAddress     = item.BankAddress     ? item.BankAddress     : '';
        this.BankAccountNo   = item.BankAccountNo   ? item.BankAccountNo   : '';
        this.BankAccountIBAN = item.BankAccountIBAN ? item.BankAccountIBAN : '';
        this.SwiftCode       = item.SwiftCode       ? item.SwiftCode       : '';
        this.Currency       = item.Currency       ? item.Currency       : '';
        this.CostCenters = item.CostCenters ? item.CostCenters : [];
    }
}