
<div *ngIf="currentScreen === 'Table'" class="FormScreen" class="tableScreen">
    <div id="divtop" style="display: block;height: 47px; width: 100%;">
        <!-- <div id="divsearchmiddle" style="display: inline-block; width: 70%; -->
       <form *ngIf="mainForm"  [formGroup]="mainForm">
       <div id="divsearchmiddle" style="display: inline-block; width: 100%;
        height: inherit;  text-align: center; padding: 0px; margin: 0px;">
            <div class="search" style="display: inline-block; ">
                <div class="d-inline-block">
                    <!-- <div class="search-sm d-inline-block mr-1 mb-1 align-top" style="width: 600px;"> -->
                    <div class="search-sm d-inline-block mr-1 mb-1 align-top" >
                        <label class="form-group has-float-label">
                            <input name="From" type="date" formControlName="From"  class="form-control ng-dirty ng-valid ng-touched" ><span>{{'From'|translate}}</span><!--bindings={
                            "ng-reflect-ng-if": "false"
                          }--></label>
                        <!-- <input type="date" formControlName="From" 
                            class="form-control input-sm full-data-search" placeholder="{{'From' | translate}}"> -->
                    </div>
                  
                </div>
                <div class="d-inline-block">
                    <!-- <div class="search-sm d-inline-block mr-1 mb-1 align-top" style="width: 600px;"> -->
                
                    <div class="search-sm d-inline-block mr-1 mb-1 align-top" >
                        <label class="form-group has-float-label">
                            <input  name="To" type="date" formControlName="To"  class="form-control ng-dirty ng-valid ng-touched" ><span>{{'To'|translate}}</span><!--bindings={
                            "ng-reflect-ng-if": "false"
                          }--></label>
                        <!-- <input type="date" formControlName="To" 
                            class="form-control input-sm full-data-search" placeholder="{{'To' | translate}}"> -->
                    </div>
             
                </div>
                <div class="d-inline-block">
                    <!-- <div class="search-sm d-inline-block mr-1 mb-1 align-top" style="width: 600px;"> -->
                
             
                    <div class="search-sm d-inline-block mr-1 mb-1 align-top" >
                        <button class="btn btn-primary d-inline-block"
                        style="font-weight: bold; text-align: center; padding: 0px; width: 150px; height: 30px;"
                        (click)="loadData()" >{{"Search" |
                        translate}}</button>
                    </div>
                </div>
                <div class="d-inline-block">
                    <!-- <div class="search-sm d-inline-block mr-1 mb-1 align-top" style="width: 600px;"> -->
                
             
                    <div class="search-sm d-inline-block mr-1 mb-1 align-top" *ngIf="paginatedData && paginatedData.length>0 ">
                        <button class="btn btn-success d-inline-block"
                        style="font-weight: bold; text-align: center; padding: 0px; width: 150px; height: 30px;"
                         (click)="exportTable()"
                                            style="font-weight: bold; width: 100%">{{"Download"
                                            | translate}}</button>
                    </div>
                </div>
       
                <!-- <div id="divsearchleft" style="display: inline-block; width: 30%; height: inherit;">
                    <div style="display: block; height: 30%; ">
                        &nbsp;
                    </div>
                    <div class="search" style="display: block; height: 70%; text-align: center;">
                        <button class="btn btn-primary d-inline-block"
                            style="font-weight: bold; text-align: center; padding: 0px; width: 150px; height: 30px;"
                            (click)="showAddNewModalRef()" [disabled]="currentAction==='loading'">{{"Search" |
                            translate}}</button>
            
                    </div>
                </div> -->
            </div>
        </div>
    
       
    </form>
    </div>
    
        
    <div id="divmiddle" style="display: block; margin-top: 5px;">
    
        <div style="width: 100%;">
            <div>
                <table class="table">
                    <thead class="thead-light">
                       
                        <th class="sorticon" (click)="changeSort(getSort('InvoiceNumber'))" [ngClass]="(itemOrder.label==='InvoiceNumber')?'sortkey sorticon':'sorticon'">
                            <div [ngClass]="{'iconsminds-up---down': (itemOrder.label!='InvoiceNumber'), 
                            'iconsminds-down': ((itemOrder.label==='InvoiceNumber') && orderAsc === true),
                            'iconsminds-up': ((itemOrder.label==='InvoiceNumber') && orderAsc === false)}"></div></th>
                        <th class="sortOption" (click)="changeSort(getSort('InvoiceNumber'))" 
                        [ngClass]="{'sortkey': (itemOrder.label==='InvoiceNumber')}">
                                {{"SeriesNumber" | translate}}
                        </th>
                        
                        <th class="sorticon" (click)="changeSort(getSort('InvoiceTaxActivityCode'))" [ngClass]="(itemOrder.label==='InvoiceTaxActivityCode')?'sortkey sorticon':'sorticon'">
                            <div [ngClass]="{'iconsminds-up---down': (itemOrder.label!='InvoiceTaxActivityCode'), 
                            'iconsminds-down': ((itemOrder.label==='InvoiceTaxActivityCode') && orderAsc === true),
                            'iconsminds-up': ((itemOrder.label==='InvoiceTaxActivityCode') && orderAsc === false)}"></div></th>
                        <th class="sortOption" (click)="changeSort(getSort('InvoiceTaxActivityCode'))" 
                        [ngClass]="{'sortkey': (itemOrder.label==='InvoiceTaxActivityCode')}">
                                {{"issuerName" | translate}}
                        </th>
                        <th class="sorticon" (click)="changeSort(getSort('InvoiceTaxActivityCode'))" [ngClass]="(itemOrder.label==='InvoiceTaxActivityCode')?'sortkey sorticon':'sorticon'">
                            <div [ngClass]="{'iconsminds-up---down': (itemOrder.label!='InvoiceTaxActivityCode'), 
                            'iconsminds-down': ((itemOrder.label==='InvoiceTaxActivityCode') && orderAsc === true),
                            'iconsminds-up': ((itemOrder.label==='InvoiceTaxActivityCode') && orderAsc === false)}"></div></th>
                        <th class="sortOption" (click)="changeSort(getSort('InvoiceTaxActivityCode'))" 
                        [ngClass]="{'sortkey': (itemOrder.label==='InvoiceTaxActivityCode')}">
                                {{"InvoiceNumber" | translate}}
                        </th>
                        <th class="sorticon" (click)="changeSort(getSort('InvoiceTaxActivityCode'))" [ngClass]="(itemOrder.label==='InvoiceTaxActivityCode')?'sortkey sorticon':'sorticon'">
                            <div [ngClass]="{'iconsminds-up---down': (itemOrder.label!='InvoiceTaxActivityCode'), 
                            'iconsminds-down': ((itemOrder.label==='InvoiceTaxActivityCode') && orderAsc === true),
                            'iconsminds-up': ((itemOrder.label==='InvoiceTaxActivityCode') && orderAsc === false)}"></div></th>
                        <th class="sortOption" (click)="changeSort(getSort('InvoiceTaxActivityCode'))" 
                        [ngClass]="{'sortkey': (itemOrder.label==='InvoiceTaxActivityCode')}">
                                {{"Total" | translate}}
                        </th>
                        
                        <th class="sorticon" (click)="changeSort(getSort('InvoiceType'))" [ngClass]="(itemOrder.label==='InvoiceType')?'sortkey sorticon':'sorticon'">
                            <div [ngClass]="{'iconsminds-up---down': (itemOrder.label!='InvoiceType'), 
                            'iconsminds-down': ((itemOrder.label==='InvoiceType') && orderAsc === true),
                            'iconsminds-up': ((itemOrder.label==='InvoiceType') && orderAsc === false)}"></div></th>
                        <th class="sortOption" (click)="changeSort(getSort('InvoiceType'))" 
                        [ngClass]="{'sortkey': (itemOrder.label==='InvoiceType')}">
                                {{"Total Taxes" | translate}}
                        </th>
                        
                        <th [colSpan]="2" style="text-align: center;">
                            <div id="displayOptions" style="
                            display: inline; margin-top: 10px; margin-left: 5px; margin-right: 5px;">
                                <span class="text-muted text-small" style=" width: 50px; height: 40px;">
                                    {{"ResultCount" | translate}}
                                </span>
                                <span dropdown class="d-inline-block position-relative">
                                    <button class="btn btn-outline-dark btn-xs dropdown-toggle" type="button"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" dropdownToggle>
                                        {{itemsPerPage}}
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-sm text-right"
                                        *dropdownMenu>
                                        <li role="menuitem" *ngFor="let item of itemOptionsPerPage"><a
                                                [ngClass]="item === itemsPerPage ? 'dropdown-item active' : 'dropdown-item'"
                                                (click)="paginateData(item, 1)">{{item}}</a></li>
                                    </div>
                                </span>
                            </div>
                        </th>
                        <th></th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let obj of paginatedData;  let i=index">
                        
                            <td colspan="2">{{obj.InvoiceNumber}}</td>
                            <td colspan="2">{{lang==='ar-EG'?obj.ClientNameAr:obj.ClientNameEn}}</td>
                            <td colspan="2">{{obj.uuid}}</td>
                            <td colspan="2">{{obj.Total}}</td>
                            <td colspan="2">{{obj.TotalTaxes}}</td>
                            <td><button class="btn btn-success text-center" (click)="print(obj.uuid)"
                                    style="font-weight: bold; width: 100%">{{"Download"
                                    | translate}}</button></td> 
                            <!-- <td><button class="btn btn-outline-danger text-center" (click)="showDelModal(obj.Id)"
                                    [disabled]="currentAction==='loading'" style="font-weight: bold; width: 100%">{{"Delete"
                                    | translate}}</button></td> -->
                        </tr>
                    </tbody>
                    <thead class="thead-light">
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </thead>
                </table>
            </div>
        </div>
    </div>
    
    <div id="divbottom" style="display: block; height: 47px;">
    
        <div id="divsearchright" style="float: right;  width: 33.33%; padding: 0px;">&nbsp;</div>
    
        <div id="divsearchmiddle" style="display: inline-block; width: 33.33%; height: 100%;">
    
            <div style="text-align: center;">
                <bdo dir="ltr">
                    <pagination class="pagination-sm2" style="padding: 0px, 0px, 0px, 0px; margin: 0px, 0px, 0px, 0px;
                    display: inline-block;" [(ngModel)]="currentPage" [boundaryLinks]="true"
                        [totalItems]="filteredData.length" [itemsPerPage]="itemsPerPage"
                        [customFirstTemplate]="firstTemplate" [customLastTemplate]="lastTemplate"
                        [customPreviousTemplate]="prevTemplate" [customNextTemplate]="nextTemplate" [maxSize]="5"
                        (pageChanged)="changePage($event)">
                    </pagination>
                </bdo>
            </div>
        </div>
    
        <div id="divsearchleft" style="float: left; width: 33.33%; height: 100%;">&nbsp;</div>
    
    </div>
</div>

<div>

</div>

<div id="contentToConvert" style="display: none;" *ngIf="paginatedData && paginatedData.length>0 ">
    <div class="card">
        <div class="card-body">
            <h5 class="card-title">{{lang==='ar-EG'?companyNameAr:companyNameEn}}</h5>
            <div class="row center">
                <table class="table table-hover">
                    <thead>
                        <tr>
                        
                            <th scope="col" style="text-align: center;">{{"Purchases Report" | translate}}</th>
                        
                        </tr>
                    </thead>
                 
                </table>
            </div>
            <table class="table table-hover">
                <thead>
                    <tr>
                    
                        <th scope="col">{{'SeriesNumber'|translate}}</th>
                        <th scope="col">{{'issuerName'|translate}}</th>
                        <th scope="col">{{'InvoiceNumber'|translate}}</th>
                        <th scope="col">{{'Total'|translate}}</th>
                        <th scope="col">{{'Total Taxes'|translate}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of paginatedData">
                        <td >{{item.InvoiceNumber}}</td>
                        <td >{{lang==='ar-EG'?item.ClientNameAr:item.ClientNameEn}}</td>
                        <td >{{item.uuid}}</td>
                        <td >{{item.Total}}</td>
                        <td >{{item.TotalTaxes}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
   
</div>
<ng-template #nextTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i *ngIf="lang==='en-US'" class='simple-icon-arrow-right'></i>
    <i *ngIf="lang==='ar-EG'" class='simple-icon-arrow-left'></i>
</ng-template>

<ng-template #prevTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i *ngIf="lang==='en-US'" class='simple-icon-arrow-left'></i>
    <i *ngIf="lang==='ar-EG'" class='simple-icon-arrow-right'></i>
</ng-template>

<ng-template #lastTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i class='simple-icon-control-end'></i>
</ng-template>

<ng-template #firstTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i class='simple-icon-control-start'></i>
</ng-template>


<simple-notifications></simple-notifications>