import { SidePopupComponent } from './../../../../views/invoice/side-popup/side-popup.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ContractingBusinessExtractModel } from '../../models/contractor-business-extract.model';
import { ProjectService } from '../../../projects/projects.service';
import { ProjectModel } from '../../../projects/models/project.model';
import { ContractorModel } from '../../../projects/models/contractor.model';
import { ContractingBusinessExtractDetailsModel } from '../../models/contractor-business-extract-details.model';
import { ContractingBusinessExtractService } from '../../contractor-business-extract.service';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
    selector: 'create-contractor-business-extract',
    templateUrl: 'create-contractor-business-extract.component.html',
    styleUrls: ['create-contractor-business-extract.component.scss']
})
export class CreateContractorBusinessExtractComponent implements OnInit{
    @ViewChild('sideModalRef', { static: true }) sideModalRef: SidePopupComponent;
      translationName: string = 'Client Business Extract Details';
    currentFormMode: string = 'Add ';
  
      Form:FormGroup;
      ContractingBusinessExtract:ContractingBusinessExtractModel = {};
      Contractors:ContractorModel[]=[];
      projects:ProjectModel[]=[];
      Id=0;
      constructor(public router:Router, 
        private fb:FormBuilder,
        private projectService:ProjectService,
        private contractorBusinessExtractService:ContractingBusinessExtractService,
        private route: ActivatedRoute){
          this.Id = + route.snapshot.paramMap.get('Id');
        }
      createForm():FormGroup{
          return this.fb.group({
              Id:[this.ContractingBusinessExtract.Id],
              BusinessInsurance:[this.ContractingBusinessExtract.BusinessInsurance],
              ContractorId:[this.ContractingBusinessExtract.ContractorId],
              Code:[this.ContractingBusinessExtract.Code],
              Date:[this.ContractingBusinessExtract.Date],
              Deductions:[this.ContractingBusinessExtract.Deductions],
              ProjectId:[this.ContractingBusinessExtract.ProjectId],
              Total:[this.ContractingBusinessExtract.Total],
              Details:[this.ContractingBusinessExtract.Details],
          })
      }
      detail:ContractingBusinessExtractDetailsModel = {};
      DetailsForm:FormGroup;
      createDetailsForm():FormGroup{
          return this.fb.group({
              Id:[this.detail.Id],
              ContractingBusinessExtractId:[this.detail.ContractingBusinessExtractId],
              Current:[this.detail.Current],
              ExcutionPercentage:[this.detail.ExcutionPercentage],
              Previous:[this.detail.Previous],
              PreviousAndCurrentTotal:[this.detail.PreviousAndCurrentTotal],
              Price:[this.detail.Price],
              TheTotalValue:[this.detail.TheTotalValue],
              ProjectContractorsItemsId:[this.detail.ProjectContractorsItemsId],
              Total:[this.detail.Total],
          })
      }
      getContractors(){
        this.projectService.GetContractorsWithProjectId(this.Form.get('ProjectId')?.value)
        .subscribe(Contractors=>{
            this.Contractors = Contractors;
        
        })
      }
      contractor:ContractorModel=new ContractorModel();
      selectContractor(){
        var i = this.Contractors.findIndex(x=>x.Id == this.Form.get('ContractorId').value);
        if(i > -1){

            this.contractor = this.Contractors[i];
        }
      }
      GetPreviousDetails(){
        this.contractorBusinessExtractService.GetPreviousDetails(this.Form.get('ProjectId').value,this.contractor.Id,this.DetailsForm.get('ProjectContractorsItemsId').value)
        .subscribe(data => {
            var itemWorkIndex = this.contractor.SelectedContractorWorkItems.findIndex(x=>x.Id == this.DetailsForm.get('ProjectContractorsItemsId').value);
            var price = 0;
            if(itemWorkIndex > -1){
                price = this.contractor.SelectedContractorWorkItems[itemWorkIndex].Cost;
            }
            this.detail = data;
            this.detail.Price = price;
            this.DetailsForm = this.createDetailsForm();
        })
      }
      ngOnInit(): void {
        if(this.Id > 0){
          this.contractorBusinessExtractService.getById(this.Id)
          .subscribe(data => {
              this.projectService.getAll()
              .subscribe(projects => {
                  this.projects = projects;
                  this.ContractingBusinessExtract = data;
                  this.Form = this.createForm();
                  this.getContractors();
              })
          })
        }
        else{
          this.contractorBusinessExtractService.GetLastCode()
          .subscribe(data => {
              this.projectService.getAll()
              .subscribe(projects => {
                  this.projects = projects;
                  this.ContractingBusinessExtract = new ContractingBusinessExtractModel();
                  this.ContractingBusinessExtract.Code = data++;
                  this.Form = this.createForm();
              })
          })
        }
     
   
       
      }
      AddDetail(){
          this.detail = new ContractingBusinessExtractDetailsModel();
          this.ContractingBusinessExtract = this.Form.getRawValue();
          if(!this.ContractingBusinessExtract.Details){
              this.ContractingBusinessExtract.Details = [];
          }
          this.Form = this.createForm();
          this.DetailsForm = this.createDetailsForm();
          this.sideModalRef.show();
      }
      addObject(){
          this.detail = this.DetailsForm.getRawValue();
          var i = this.contractor.SelectedContractorWorkItems.findIndex(x=>x.Id == this.detail.ProjectContractorsItemsId);
          if(i>-1){
            this.detail.itemNameAr = this.contractor.SelectedContractorWorkItems[i].NameAr;
            this.detail.itemNameEn = this.contractor.SelectedContractorWorkItems[i].NameEn;
          }
          this.ContractingBusinessExtract = this.Form.getRawValue();
          this.ContractingBusinessExtract.Details?.push(this.detail);
          this.ContractingBusinessExtract.Total += this.detail.Total;
          this.Form = this.createForm();
          this.sideModalRef.modalRef.hide();
      }
      changeCurrent(){
        this.detail = this.DetailsForm.getRawValue();
        this.detail.PreviousAndCurrentTotal = +this.detail.Previous + +this.detail.Current;
        this.detail.TheTotalValue = +(this.detail.PreviousAndCurrentTotal * this.detail.Price);
        this.detail.Total = this.detail.TheTotalValue;
        // this.ContractingBusinessExtract = this.Form.getRawValue();
        // this.ContractingBusinessExtract.Total += this.detail.Total;
        // this.Form = this.createForm();
        this.DetailsForm = this.createDetailsForm();
      }
      ExcutionPercentage(){
        this.detail = this.DetailsForm.getRawValue();
        var discount = this.detail.ExcutionPercentage/100;
        var discountValue = this.detail.Total*discount;
        this.detail.Total -= discountValue;
        // this.ContractingBusinessExtract = this.Form.getRawValue();
        // this.ContractingBusinessExtract.Total -= discountValue;
        // this.Form = this.createForm();
        this.DetailsForm = this.createDetailsForm();
      }
      Save(){
        debugger
        this.ContractingBusinessExtract = this.Form.getRawValue();

        this.contractorBusinessExtractService.insert(this.ContractingBusinessExtract)
        .subscribe(data => {
            this.router.navigate(['app/aio/Construction/contractors-business-extracts']);
        })
      }
      calcBusinssInsurance(){
        debugger;
        this.ContractingBusinessExtract = this.Form.getRawValue();
        var descount = this.ContractingBusinessExtract.BusinessInsurance/100;
        var decountValue = this.ContractingBusinessExtract.Total*descount;
        this.ContractingBusinessExtract.Total -= decountValue;
                this.Form = this.createForm();
      }
      calcDeductions(){
        this.ContractingBusinessExtract = this.Form.getRawValue();
        var descount = this.ContractingBusinessExtract.Deductions/100;
        var decountValue = this.ContractingBusinessExtract.Total*descount;
        this.ContractingBusinessExtract.Total -= decountValue;
        this.Form = this.createForm();
      }
  }
  