<div class="row">
    <div class="col-8">
      <div class="half-body" style="display: inline-block; width: 50%;">
        <!-- <app-heading></app-heading>
        <app-breadcrumb></app-breadcrumb> -->
        <div class="search-sm-nosearch d-inline-block mr-1 mb-1 align-top" *ngIf="!dateSearch">
          <div class="search" style="display: inline-block; margin-left: 50px; margin-right: 50px;">
            <div class="d-inline-block">
                <div class="search-sm d-inline-block mr-1 mb-1 align-top" style="width: 600px;">
                    <input type="search" [(ngModel)]="tableEngine.SearchTerm" (keyup)="searchUsingKeys()" (change)="searchUsingKeys()"
                        class="form-control input-sm full-data-search" placeholder="{{'Search' | translate}}">
                </div>
            </div>
        </div>
        </div>
        <div class="d-inline-block mr-1 mb-1 align-top" style="width: 1000px;" *ngIf="dateSearch">
          {{"PurchaseOrderShowResultsFrom"|translate}}
          <div class="search-sm-nosearch d-inline-block mr-1 mb-1 align-top">
              <input style="display: inline-block; width: 150px; margin-left: 5px;  margin-right: 5px;"
                  type="text" onfocus="(this.type='date')" onblur="if(!this.value)this.type='text'"
                  onchange="if(!this.value)this.type='text'" name="Date" class="form-control"
                  [(ngModel)]="SearchFrom"
                  (change)="searchDateRange()"
                  placeholder='{{"DateFrom" | translate}}'>
          </div>
          {{"PurchaseOrderShowResultsTo"|translate}}
          <div class="search-sm-nosearch d-inline-block mr-1 mb-1 align-top">
              <input style="display: inline-block; width: 150px; margin-left: 5px;  margin-right: 5px;"
                  type="text" onfocus="(this.type='date')" onblur="if(!this.value)this.type='text'"
                  onchange="if(!this.value)this.type='text'" name="Date" class="form-control"
                  [(ngModel)]="SearchTo"
                  (change)="searchDateRange()"
                  placeholder='{{"DateTo" | translate}}'>
          </div>
      </div>
      </div>
    </div>
    <div class="col-4">
      <div class="half-body" style=" width: 50%;">
        <div class="half-body-filler" style="display: inline-block; width: 60%;">
          &nbsp;
        </div>
        <div class="half-body-content" style=" width: 40%; display: inline-block; text-align: center;">
          <div *ngIf="AddEnabled" style="display: block; height: 40%; width: 100%; text-align: center; margin-bottom: 5px;">
            <button type="button" (click)="onAddNewItem()" 
            class=" btn btn-primary btn-lg mr-1">{{ 'Add' | translate}}
         
          </button>
          </div>
          <div id="displayOptions" 
          style="padding: unset !important; display: block; height: 40%; width: 100%; text-align: center;">
            <!-- <div class="top-right-button-container2 btn-group" dropdown style="padding: unset !important; margin: unset !important;">
              <button [disabled]="tableEngine.selected.length===0 || tableEngine.currentAction==='loading'"
              id="button-split" type="button" dropdownToggle
              style="margin: unset !important; width: 100px; font-weight: bold; color: white; border: unset !important;"
                  class="btn btn-primary dropdown-toggle "
                  aria-controls="dropdown-split">
                  <span class="caret"></span>
                  {{'MoreActions' | translate}}
              </button>
              <ul id="dropdown-split" *dropdownMenu class="dropdown-menu dropdown-menu-right" 
                  role="menu" aria-labelledby="button-split">
                  <li role="menuitem"><a class="dropdown-item">
                          <button class="btn btn-danger d-inline-block" (click)="deleteSelected()" 
                          [disabled]="tableEngine.selected.length===0 || tableEngine.currentAction==='loading'"
                          style="color: white; font-weight: bold; text-align: center; padding: 0px; width: 150px; height: 30px;">
                          {{"DeleteSelected"|translate}}</button></a></li>
              </ul>
          </div> -->
            <!-- <span class="text-muted text-small" style=" width: 50px; height: 40px;">
              {{"ResultCount" | translate}}
            </span> -->
            <!-- <span dropdown class="d-inline-block">
              <button class="btn btn-primary btn-xs dropdown-toggle" type="button" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false" dropdownToggle>
                {{'MoreActions' | translate}}
              </button>
              <div class="dropdown-menu dropdown-menu-right dropdown-menu-sm text-right" *dropdownMenu>
                <li role="menuitem" *ngFor="let item of itemOptionsPerPage"><a
                    [ngClass]="item === itemsPerPage ? 'dropdown-item active' : 'dropdown-item'"
                    >{{item}}</a></li>
              </div>
            </span> -->
          </div>
        </div>
      </div>
      
    </div>
    <div class="display-options-container">
      <div class="separator mb-4"></div>
    </div>
</div>