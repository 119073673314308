
export class StoreDataModel{    
  Id?: number;
  CompanyDataId?: number;
  BranchesDataId?: number;
  NameAr?: string;
  NameEn?: string;
  CountryCodeId?: number;
  GovernorateCodeId?: number;
  CityCodeId?: number;
  StoreAddress?: string;
  StoreMobil?: string;
  StorePhone?: string;
  StoreFax?: string;
  StoreWhatsapp?: string;
  StoreEmail?: string;
  StoreManager?: number;
  Description?: string;


  constructor(obj?:StoreDataModel){
    obj =obj? obj:{};
    this.Id =obj?.Id? obj.Id:0;
    this.CompanyDataId =obj?.CompanyDataId? obj.CompanyDataId:0;
    this.BranchesDataId=obj?.BranchesDataId? obj?.BranchesDataId:0;
    this.NameAr = obj.NameAr? obj.NameAr:'';
    this.NameEn = obj.NameEn? obj.NameEn:'';
    this.CountryCodeId = obj.CountryCodeId? obj.CountryCodeId:0;
    this.GovernorateCodeId = obj.GovernorateCodeId? obj.GovernorateCodeId:0;
    this.CityCodeId = obj.CityCodeId? obj.CityCodeId:0;
    this.StoreAddress = obj.StoreAddress? obj.StoreAddress:'';
    this.StoreMobil = obj.StoreMobil? obj.StoreMobil:'';
    this.StorePhone = obj.StorePhone? obj.StorePhone:'';
    this.StoreFax = obj.StoreFax? obj.StoreFax:'';
    this.StoreWhatsapp = obj.StoreWhatsapp? obj.StoreWhatsapp:'';
    this.StoreEmail = obj.StoreEmail? obj.StoreEmail:'';
    this.StoreManager = obj.StoreManager? obj.StoreManager:0;
    this.Description = obj.Description? obj.Description:'';
}
}