
export class RequestReceiptItemModel {
    Id?: number;
    Code?: number;
    RequestReceiptId?: number;
    RequestReceiptCode?: number;
    ItemId?: number;
    ItemNameAr?: string;
    ItemNameEn?: string;
    UnitId?: number;
    UnitNameAr?: string;
    UnitNameEn?: string;
    Quantity?: number;
    UnitCost?: number;
    TotalPrice?: number;
    MaxQuantity?: number;
    constructor(obj?: RequestReceiptItemModel) {
        obj = obj ? obj : {};
        this.Id = obj.Id ? obj.Id : 0;
        this.Code = obj.Code ? obj.Code : 0;
        this.RequestReceiptId  = obj.RequestReceiptId  ? obj.RequestReceiptId  : 0;
        this.RequestReceiptCode  = obj.RequestReceiptCode  ? obj.RequestReceiptCode  : 0;
        this.ItemId  = obj.ItemId  ? obj.ItemId  : null;
        this.ItemNameAr  = obj.ItemNameAr  ? obj.ItemNameAr  : '';
        this.ItemNameEn  = obj.ItemNameEn  ? obj.ItemNameEn  : '';
        this.UnitId   = obj.UnitId   ? obj.UnitId   : null;
        this.UnitNameAr  = obj.UnitNameAr  ? obj.UnitNameAr  : '';
        this.UnitNameEn  = obj.UnitNameEn  ? obj.UnitNameEn  : '';
        this.Quantity   = obj.Quantity   ? obj.Quantity   : null;
        this.UnitCost = obj.UnitCost ? obj.UnitCost : null;
        this.TotalPrice  = obj.TotalPrice  ? obj.TotalPrice  : 0;
        this.MaxQuantity   = obj.MaxQuantity   ? obj.MaxQuantity   : null;
    }

}