

<div class="row">
    <div class="col-12">
      <div class="card mb-4">
        <div class="card-body">
          <!-- <h5 class="mb-4">{{ 'From Date' | translate }}</h5> -->
          <div class="row">
          
            <div class="col-12 col-lg-4 mb-4">
              <label><span>{{ 'DateFrom' | translate }}</span></label>
              <input 
              type="text" onfocus="(this.type='date')" onblur="if(!this.value)this.type='text'"
              onchange="if(!this.value)this.type='text'" name="Date" class="form-control"
              [(ngModel)]="search.From"
              placeholder='{{"DateFrom" | translate}}'>
            </div>
            <div class="col-12 col-lg-4 mb-4">
              <label><span>{{ 'DateTo' | translate }}</span></label>
              <input 
              type="text" onfocus="(this.type='date')" onblur="if(!this.value)this.type='text'"
              onchange="if(!this.value)this.type='text'" name="Date" class="form-control"
              [(ngModel)]="search.To"
              placeholder='{{"DateTo" | translate}}'>
            </div>
            <div class="col-12 col-lg-4 mb-4">
              <label><span>{{ 'Client' | translate }}</span></label>
         
              <ng-select placeholder='{{"Client" | translate}}' *ngIf="lang==='ar-EG'" [items]="clients" bindLabel="NameAr" bindValue="Id" [(ngModel)]="search.ClientId">
              </ng-select>
              <ng-select placeholder='{{"Client" | translate}}' *ngIf="lang!='ar-EG'" [items]="clients" bindLabel="NameEn" bindValue="Id" [(ngModel)]="search.ClientId">
              </ng-select>
            </div>
         
         
            <div class="col-12 col-lg-6 mb-4 "  >
              <button type="button" (click)="Search()" class="btn btn-primary default mb-1" style="margin: 1px;">{{'Search' | translate}}</button>{{" "}}
              <button type="button" (click)="print()" class="btn btn-secondary default mb-1 " style="margin: 1px;">{{'Print' | translate}}</button>{{" "}}
              <button type="button" (click)="exportTable()" class="btn btn-success default mb-1" style="margin: 1px;">{{'PDF' | translate}}</button>{{" "}}
            </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  <div id="contentToConvert">
    <div class="row" >
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-body">
              <h3 class="float-center" style="text-align: center;">{{ 'Daily Invoices Report' | translate }}</h3>
              <h4 class="float-center" style="text-align: center;">{{  today| date:'dd/MM/yyyy' }}</h4>
              <h6 class="float-center" style="text-align: center;">{{ 'Future Core' | translate }}</h6>
             
          </div>
        </div>
      </div>
    </div>
  
  
  <div class="card mb-12 tree-explorer-side col-md-12" *ngIf="data">
      <div class="card-body ">
      <div class="row">
          <table class="table table-striped" *ngFor="let supplier of data">
              <thead>
                <tr>
                  <th colspan="5">{{supplier.Date | date:'dd/MM/yyyy'}}</th>
                </tr>
                <tr>
                    <th>{{ 'Code' | translate }} </th>
                    <th>{{ 'Client' | translate }} </th>
                    <th>{{ 'Is Payed' | translate }}</th>
                    <th>{{ 'Is Not Payed' | translate }}</th>
                    <th>{{ 'Total' | translate }} </th>
                </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let item of supplier.Details">
                      <td><a [routerLink]="[ '../view-invoice', item.Id ]"> {{item.Code}}#</a></td>
                      <td>{{lang==='ar-EG'?item.ClientNameAr:item.ClientNameEn}}</td>
                      <td>{{item.Payed}}</td>
                      <td>{{item.NotPayed}}</td>
                      <th style="font: bold;">{{item.Total}}</th>
                      
                  </tr>
                  <tr >
                    <th colspan="2">{{ 'Total' | translate }} </th>
                    <th style="font: bold;">{{supplier.Payed}}</th>
                    <th style="font: bold;">{{supplier.NotPayed}}</th>
                    <th style="font: bold;">{{supplier.Total}}</th>
                    
                </tr>
              </tbody>
          </table>
      
      </div>
  
      </div>
    </div>
  </div>