<div class="form-group">
    <div class="modal-body">
        <form class="md-float-material form-material" [formGroup]="mainForm">

            <div style="text-align: center;">
                <button class="btn btn-light" (click)="goBack()"
                    style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Back"  | translate}}</button>
                <button [disabled]="mainForm.invalid || currentAction==='loading'"
                    id="smallModalSubmitButton" class="btn btn-secondary" (click)="editObject()"
                    style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Edit" | translate}}</button>
            </div>
            <div class="form-group" style="margin-top: 10px;">

                <!-- <div class="row">

                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"ConfigPurchasesAllowedDiscounts" | translate}}</label>
                        <ng-select appearance="outline" name="AllowedDiscounts" formControlName="AllowedDiscounts"
                                placeholder='{{"ConfigPurchasesAllowedDiscounts" | translate}}' 
                                #AllowedDiscountsSelect labelForId="txtycd" [searchable]="true">
                                <ng-option [value]="'Both'">{{'ConfigPurchasesAllowAllDiscounts' | translate}}</ng-option>
                                <ng-option [value]="'PerItem'">{{'ConfigPurchasesAllowItemDiscounts' | translate}}</ng-option>
                                <ng-option [value]="'Total'">{{'ConfigPurchasesAllowTotalDiscounts' | translate}}</ng-option>
                        </ng-select>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('AllowedDiscounts').hasError('required') || mainForm.get('AllowedDiscounts').invalid) && ( mainForm.get('AllowedDiscounts').dirty ||  mainForm.get('AllowedDiscounts').touched)">
                            {{"ConfigPurchasesAllowedDiscountsRequired" | translate}}</div>
                    </div>
                    
                </div>

                <br> -->

                <div class="row">
                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"ConfigPurchasesEnableAutoPurchaseOrderCodes" | translate}}</label>
                        <div class="itemCheck mb-0 custom-checkbox custom-control">
                            <input type="checkbox" [id]="'ConfigPurchasesEnableAutoPurchaseOrderCodes'" 
                            class="custom-control-input" formControlName="EnableAutoPurchaseOrderCodes">
                            <label class="custom-control-label" [for]="'ConfigPurchasesEnableAutoPurchaseOrderCodes'"></label>
                        </div>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('EnableAutoPurchaseOrderCodes').hasError('required') || mainForm.get('EnableAutoPurchaseOrderCodes').invalid) && ( mainForm.get('EnableAutoPurchaseOrderCodes').dirty ||  mainForm.get('EnableAutoPurchaseOrderCodes').touched)">
                            {{"ConfigPurchasesEnableAutoPurchaseOrderCodesRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>

                    <br>

                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"ConfigPurchasesEnableAutoRequestReceiptCodes" | translate}}</label>
                        <div class="itemCheck mb-0 custom-checkbox custom-control">
                            <input type="checkbox" [id]="'ConfigPurchasesEnableAutoRequestReceiptCodes'" 
                            class="custom-control-input" formControlName="EnableAutoRequestReceiptCodes">
                            <label class="custom-control-label" [for]="'ConfigPurchasesEnableAutoRequestReceiptCodes'"></label>
                        </div>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('EnableAutoRequestReceiptCodes').hasError('required') || mainForm.get('EnableAutoRequestReceiptCodes').invalid) && ( mainForm.get('EnableAutoRequestReceiptCodes').dirty ||  mainForm.get('EnableAutoRequestReceiptCodes').touched)">
                            {{"ConfigPurchasesEnableAutoRequestReceiptCodesRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>

                    <br>

                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"ConfigPurchasesEnableAutoPurchaseInvoiceCodes" | translate}}</label>
                        <div class="itemCheck mb-0 custom-checkbox custom-control">
                            <input type="checkbox" [id]="'ConfigPurchasesEnableAutoPurchaseInvoiceCodes'" 
                            class="custom-control-input" formControlName="EnableAutoPurchaseInvoiceCodes">
                            <label class="custom-control-label" [for]="'ConfigPurchasesEnableAutoPurchaseInvoiceCodes'"></label>
                        </div>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('EnableAutoPurchaseInvoiceCodes').hasError('required') || mainForm.get('EnableAutoPurchaseInvoiceCodes').invalid) && ( mainForm.get('EnableAutoPurchaseInvoiceCodes').dirty ||  mainForm.get('EnableAutoPurchaseInvoiceCodes').touched)">
                            {{"ConfigPurchasesEnableAutoPurchaseInvoiceCodesRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>
                    
                </div>

                <br>

                <div class="row">
                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"ConfigPurchasesStartingPurchaseOrderCode" | translate}}</label>
                        <div>
                            <input type="number" min="0" name="StartingPurchaseOrderCode" class="form-control" 
                            formControlName="StartingPurchaseOrderCode" style=" margin: 0px;"
                            placeholder='{{"ConfigPurchasesStartingPurchaseOrderCode" | translate}}'>
                        </div>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('StartingPurchaseOrderCode').hasError('required') || mainForm.get('StartingPurchaseOrderCode').invalid) && ( mainForm.get('StartingPurchaseOrderCode').dirty ||  mainForm.get('StartingPurchaseOrderCode').touched)">
                            {{"ConfigPurchasesStartingPurchaseOrderCodeRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>

                    <br>

                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"ConfigPurchasesStartingRequestReceiptCode" | translate}}</label>
                        <div>
                            <input type="number" min="0" name="StartingRequestReceiptCode" class="form-control" 
                            formControlName="StartingRequestReceiptCode" style=" margin: 0px;" 
                            placeholder='{{"ConfigPurchasesStartingRequestReceiptCode" | translate}}'>
                        </div>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('StartingRequestReceiptCode').hasError('required') || mainForm.get('StartingRequestReceiptCode').invalid) && ( mainForm.get('StartingRequestReceiptCode').dirty ||  mainForm.get('StartingRequestReceiptCode').touched)">
                            {{"ConfigPurchasesStartingRequestReceiptCodeRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>

                    <br>

                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"ConfigPurchasesStartingPurchaseInvoiceCode" | translate}}</label>
                        <div>
                            <input type="number" min="0" name="StartingPurchaseInvoiceCode" class="form-control" 
                            formControlName="StartingPurchaseInvoiceCode" style=" margin: 0px;" 
                            placeholder='{{"ConfigPurchasesStartingPurchaseInvoiceCode" | translate}}'>
                        </div>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('StartingPurchaseInvoiceCode').hasError('required') || mainForm.get('StartingPurchaseInvoiceCode').invalid) && ( mainForm.get('StartingPurchaseInvoiceCode').dirty ||  mainForm.get('StartingPurchaseInvoiceCode').touched)">
                            {{"ConfigPurchasesStartingPurchaseInvoiceCodeRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>
                </div>

                <br>

                <div class="row">
                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"ConfigPurchasesEnableAutoReturnRequestCodes" | translate}}</label>
                        <div class="itemCheck mb-0 custom-checkbox custom-control">
                            <input type="checkbox" [id]="'ConfigPurchasesEnableAutoReturnRequestCodes'" 
                            class="custom-control-input" formControlName="EnableAutoReturnRequestCodes">
                            <label class="custom-control-label" [for]="'ConfigPurchasesEnableAutoReturnRequestCodes'"></label>
                        </div>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('EnableAutoReturnRequestCodes').hasError('required') || mainForm.get('EnableAutoReturnRequestCodes').invalid) && ( mainForm.get('EnableAutoReturnRequestCodes').dirty ||  mainForm.get('EnableAutoReturnRequestCodes').touched)">
                            {{"ConfigPurchasesEnableAutoReturnRequestCodesRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>

                    <br>

                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"ConfigPurchasesEnableAutoPurchaseQuotationCodes" | translate}}</label>
                        <div class="itemCheck mb-0 custom-checkbox custom-control">
                            <input type="checkbox" [id]="'ConfigPurchasesEnableAutoPurchaseQuotationCodes'" 
                            class="custom-control-input" formControlName="EnableAutoPurchaseQuotationCodes">
                            <label class="custom-control-label" [for]="'ConfigPurchasesEnableAutoPurchaseQuotationCodes'"></label>
                        </div>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('EnableAutoPurchaseQuotationCodes').hasError('required') || mainForm.get('EnableAutoPurchaseQuotationCodes').invalid) && ( mainForm.get('EnableAutoPurchaseQuotationCodes').dirty ||  mainForm.get('EnableAutoPurchaseQuotationCodes').touched)">
                            {{"ConfigPurchasesEnableAutoPurchaseQuotationCodesRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>

                    <br>

                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"ConfigPurchasesEnableAutoQuotationRequestCodes" | translate}}</label>
                        <div class="itemCheck mb-0 custom-checkbox custom-control">
                            <input type="checkbox" [id]="'ConfigPurchasesEnableAutoQuotationRequestCodes'" 
                            class="custom-control-input" formControlName="EnableAutoQuotationRequestCodes">
                            <label class="custom-control-label" [for]="'ConfigPurchasesEnableAutoQuotationRequestCodes'"></label>
                        </div>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('EnableAutoQuotationRequestCodes').hasError('required') || mainForm.get('EnableAutoQuotationRequestCodes').invalid) && ( mainForm.get('EnableAutoQuotationRequestCodes').dirty ||  mainForm.get('EnableAutoQuotationRequestCodes').touched)">
                            {{"ConfigPurchasesEnableAutoQuotationRequestCodesRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>
                    
                </div>

                <br>

                <div class="row">
                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"ConfigPurchasesStartingReturnRequestCode" | translate}}</label>
                        <div>
                            <input type="number" min="0" name="StartingReturnRequestCode" class="form-control" 
                            formControlName="StartingReturnRequestCode" style=" margin: 0px;" 
                            placeholder='{{"ConfigPurchasesStartingReturnRequestCode" | translate}}'>
                        </div>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('StartingReturnRequestCode').hasError('required') || mainForm.get('StartingReturnRequestCode').invalid) && ( mainForm.get('StartingReturnRequestCode').dirty ||  mainForm.get('StartingReturnRequestCode').touched)">
                            {{"ConfigPurchasesStartingReturnRequestCodeRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>

                    <br>

                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"ConfigPurchasesStartingPurchaseQuotationCode" | translate}}</label>
                        <div>
                            <input type="number" min="0" name="StartingPurchaseQuotationCode" class="form-control" 
                            formControlName="StartingPurchaseQuotationCode" style=" margin: 0px;" 
                            placeholder='{{"ConfigPurchasesStartingPurchaseQuotationCode" | translate}}'>
                        </div>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('StartingPurchaseQuotationCode').hasError('required') || mainForm.get('StartingPurchaseQuotationCode').invalid) && ( mainForm.get('StartingPurchaseQuotationCode').dirty ||  mainForm.get('StartingPurchaseQuotationCode').touched)">
                            {{"ConfigPurchasesStartingPurchaseQuotationCodeRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>

                    <br>

                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"ConfigPurchasesStartingQuotationRequestCode" | translate}}</label>
                        <div>
                            <input type="number" min="0" name="StartingQuotationRequestCode" class="form-control" 
                            formControlName="StartingQuotationRequestCode" style=" margin: 0px;" 
                            placeholder='{{"ConfigPurchasesStartingQuotationRequestCode" | translate}}'>
                        </div>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('StartingQuotationRequestCode').hasError('required') || mainForm.get('StartingQuotationRequestCode').invalid) && ( mainForm.get('StartingQuotationRequestCode').dirty ||  mainForm.get('StartingQuotationRequestCode').touched)">
                            {{"ConfigPurchasesStartingQuotationRequestCodeRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>
                </div>
            </div>

        </form>
    </div>
</div>