
export class SuppliersModel{
    Id?: number;
    NameEn?: string;
    NameAr?: string;
    Phone?: string;
    Address?: string;
    SupplierType?: string;
    CountryId?: number;
    CityId?: number;
    CitiyName?: string;
    PostalCode?: string;
    constructor(item?: SuppliersModel) {
        item = item ? item : {};
        this.Id = item.Id ? item.Id : 0;
        this.NameEn = item.NameEn ? item.NameEn : '';
        this.NameAr = item.NameAr ? item.NameAr : '';
        this.Phone = item.Phone ? item.Phone : '';
        this.Address = item.Address ? item.Address : '';
        this.SupplierType = item.SupplierType ? item.SupplierType : '';
        this.CountryId = item.CountryId ? item.CountryId : 0;
        this.CityId = item.CityId ? item.CityId : null;
        this.CitiyName = item.CitiyName ? item.CitiyName : '';
        this.PostalCode = item.PostalCode ? item.PostalCode : '';
    }
}