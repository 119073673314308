import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { StoreBillModel } from '../models/StoreBillModel';

@Injectable({
  providedIn: 'root'
})
export class StoreBillService {

 

  constructor(private http:HttpClient ) { }

  
  baseUrl = environment.baseApiUrl;
  token = localStorage.getItem("token");
  httpOptions = {
   headers: new HttpHeaders({
     // 'Content-Type': 'application/json; charset=utf-8',
     // 'Authorization': "Bearer " + token,
      "Authorization": "Bearer " + this.token,
      "Content-Type": "application/json"
   })
 };

 getAll(): Observable<any> {
   
  return this.http
    .get<any>(`${this.baseUrl}AddStoreBill/GetAll`,this.httpOptions)
    .pipe(catchError((error: any) => Observable.throw(error => error)));
}
GetAllSubAccountes(Id:number): Observable<any> {
   
  return this.http
    .get<any>(`${this.baseUrl}AccountsName/GetAll/${Id}`,this.httpOptions)
    .pipe(catchError((error: any) => Observable.throw(error => error)));
}

getLastNumber(): Observable<any> {
   
  return this.http
    .get<any>(`${this.baseUrl}AddStoreBill/GetLastNumber`,this.httpOptions)
    .pipe(catchError((error: any) => Observable.throw(error => error)));
}
getLastMoveNumber(): Observable<any> {
   
  return this.http
    .get<any>(`${this.baseUrl}AddStoreBill/GetLastMoveNumber`,this.httpOptions)
    .pipe(catchError((error: any) => Observable.throw(error => error)));
}



getById(Id:number): Observable<any> {

  return this.http
    .get<any>(`${this.baseUrl}AddStoreBill/GetById/${Id}`,this.httpOptions)
    .pipe(catchError((error: any) => Observable.throw(error => error)));
}
insert(payload: StoreBillModel): Observable<any> {
  
  return this.http
    .post<any>(`${this.baseUrl}AddStoreBill/Insert`, payload,this.httpOptions)
    .pipe(catchError((error: any) => Observable.throw(error => error)));
}

update(payload: StoreBillModel): Observable<any> {
  return this.http
    .post<any>(`${this.baseUrl}AddStoreBill/Update`, payload,this.httpOptions)
    .pipe(catchError((error: any) => Observable.throw(error => error)));
}

delete(Id:number): Observable<any> {
  return this.http
    .get<any>(`${this.baseUrl}AddStoreBill/Delete/${Id}`,this.httpOptions)
    .pipe(catchError((error: any) => Observable.throw(error => error)));
}
IsApproval(Id:number): Observable<any> {
  return this.http
    .get<any>(`${this.baseUrl}AddStoreBill/IsApproval/${Id}`,this.httpOptions)
    .pipe(catchError((error: any) => Observable.throw(error => error)));
}
  deleteList(payload: StoreBillModel []): Observable<any> {
    console.log(JSON.stringify(payload))
    return this.http
      .post<any>(`${this.baseUrl}AddStoreBill/DeleteList`,JSON.stringify(payload),this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error => error)));
  }
}
