export class SessionLogsModel{
    Id?: number;
    AttendanceSessionId       ?: number;
    AttendanceSession         ?: string;
    EmployeName               ?: string;
    EmployeCode               ?: number;
    EmployeeId                ?: number;
    CheckIn                   ?: Date|null;
    CheckOut                  ?: Date|null;
    Nots                      ?: string;
    IsAttenance               ?: boolean;
    IsInVacation              ?: boolean;
    AttendanceSourceName      ?: string;
    CheckInAttendanceSourceId ?: number;
    CheckOutAttendanceSourceId?: number;
    Status                    ?: boolean;
    constructor(item ?: SessionLogsModel){
        item = item ? item : {};
        this.Id = item.Id ? item.Id : 0;
        this.AttendanceSessionId        = item.AttendanceSessionId        ? item.AttendanceSessionId        : 0;
        this.AttendanceSession          = item.AttendanceSession          ? item.AttendanceSession          : '';
        this.EmployeName                = item.EmployeName                ? item.EmployeName                : '';
        this.EmployeCode                = item.EmployeCode                ? item.EmployeCode                : 0;
        this.EmployeeId                 = item.EmployeeId                 ? item.EmployeeId                 : 0;
        this.CheckIn                    = item.CheckIn                    ? item.CheckIn                    : null;
        this.CheckOut                   = item.CheckOut                   ? item.CheckOut                   : null;
        this.Nots                       = item.Nots                       ? item.Nots                       : '';
        this.IsAttenance                = item.IsAttenance                ? item.IsAttenance                : false;
        this.IsInVacation               = item.IsInVacation               ? item.IsInVacation               : false;
        this.AttendanceSourceName       = item.AttendanceSourceName       ? item.AttendanceSourceName       : '';
        this.CheckInAttendanceSourceId  = item.CheckInAttendanceSourceId  ? item.CheckInAttendanceSourceId  : 0;
        this.CheckOutAttendanceSourceId = item.CheckOutAttendanceSourceId ? item.CheckOutAttendanceSourceId : 0;
        this.Status                     = item.Status                     ? item.Status                     : false;
    }
}