import { IncomeListSearchModel } from './models/income-list-search.model';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IncomeListDataModel } from './models/income-list-data.model';



@Injectable({
  providedIn: 'root'
})
export class IncomeListService {
    constructor(private http: HttpClient) {
  
    }

    baseUrl = environment.baseApiUrl;
     token = localStorage.getItem("token");
     httpOptions = {
      headers: new HttpHeaders({
        // 'Content-Type': 'application/json; charset=utf-8',
        // 'Authorization': "Bearer " + token,
         "Authorization": "Bearer " + this.token,
         "Content-Type": "application/json"
      })
    };
    Search(search:IncomeListSearchModel): Observable<IncomeListDataModel> {
      return this.http
        .post<IncomeListDataModel>(`${this.baseUrl}IncomeList/Search`,search,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
}
  