export class QuotationFileModel {
    Id?: number;
    QuotationId?: number;
    FileUrl?: string;

    constructor(obj?: QuotationFileModel) {
        obj = obj ? obj : {};
        this.Id = obj.Id ? obj.Id : 0;
        this.QuotationId = obj.QuotationId ? obj.QuotationId : 0;
        this.FileUrl = obj.FileUrl ? obj.FileUrl : '';
    }

}