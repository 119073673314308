export class MyPharmacyPackageModel{
    Id?:number;
    Name?:string;
    Type?:string;
    Status?:string;
    Price?:number;
    PeriodValue?:number;
    PeriodType?:string;
    Description?:string;
    constructor(item ?: MyPharmacyPackageModel){
        item = item ? item : {};
        this.Id = item.Id ? item.Id : 0;
        this.Name = item.Name ? item.Name : '';
        this.Status = item.Status ? item.Status : '';
        this.Type = item.Type ? item.Type : '';
        this.Description = item.Description ? item.Description : '';
        this.PeriodValue = item.PeriodValue ? item.PeriodValue : 0;
        this.PeriodType = item.PeriodType ? item.PeriodType : '';
        this.Price = item.Price ? item.Price : 0;
    }
}