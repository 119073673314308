import { HolidayDetailsModel } from "./holidays-details.model";

export class HolidaysModel{
    Id?: number;
    Name?: string;
    Details?:HolidayDetailsModel[];
    constructor(item ?: HolidaysModel){
        item = item ? item : {};
        this.Id = item.Id ? item.Id : 0;
        this.Name = item.Name ? item.Name: '';
        this.Details = item.Details ? item.Details : [];
    }
}