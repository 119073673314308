
<div bsModal #cashierLoginModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-header" style="margin-top: 10px; display: block; padding: 0px 0px 5px 0px; text-align: center; ">
                <h1 id="dialog-child-name" class="modal-title">
                    {{"CashierLogin" | translate}}</h1>
            </div>
            <div class="modal-body">
                <div class="form-group form-primary">
                <label class="float-label push righ"style="font-size: 15px;">{{"CashierStaffSelect" | translate}}</label>
                <ng-select appendTo="body" appearance="outline" name="cashierStaffId" [(ngModel)]="cashierStaffId" style="font-size: 15px !important;"
                      placeholder='{{"CashierStaffSelect" | translate}}'
                      labelForId="txtycd" [searchable]="true">
                      <ng-option *ngFor="let cashierStaff of cashierStaffList" [value]="cashierStaff.Id" style="font-size: 15px !important;">
                          {{lang==='ar-EG'?cashierStaff.NameAr:cashierStaff.NameEn}}
                      </ng-option>
                  </ng-select>
                </div>

                <div class="form-group form-primary">
                <label class="float-label push righ"style="font-size: 15px;">{{"CashRegisterSelect" | translate}}</label>
                <ng-select appendTo="body" appearance="outline" name="CashRegisterId" [(ngModel)]="cashRegisterId" 
                style="font-size: 15px !important; "
                      placeholder='{{"CashRegisterSelect" | translate}}'
                      labelForId="txtycd" [searchable]="true">
                      <ng-option *ngFor="let cashRegister of cashRegisterList" [value]="cashRegister.Id" style="font-size: 15px !important;">
                        {{lang==='ar-EG'?cashRegister.BranchNameAr:cashRegister.BranchNameEn}} - {{cashRegister.Code}}
                      </ng-option>
                  </ng-select>
                </div>
                
                <div class="form-group form-primary">
                <label class="float-label push righ"style="font-size: 15px;">{{"CashierStartingCash" | translate}}</label>
                <input type="number" min="0" name="StartCash" class="form-control" [(ngModel)]="startingCash" style=" margin: 0px;"
                placeholder='{{"CashierStartCash" | translate}}'>
                <div *ngIf="!(startingCash >= 0)" class="messages text-danger">
                    {{"CashierStartCashRequired" | translate}}</div>
                <span class="form-bar"></span>
                </div>

                <div class="form-group form-primary">
                <label class="float-label push righ"style="font-size: 15px;">{{"CashierWorkShift" | translate}}</label>
                <ng-select appendTo="body" appearance="outline" name="workShiftId" [(ngModel)]="cashierWorkShiftId" style="font-size: 15px !important;"
                      placeholder='{{"CashierWorkShiftSelect" | translate}}'
                      labelForId="txtycd" [searchable]="true">
                      <ng-option *ngFor="let cashierWorkShift of cashierWorkShiftList" [value]="cashierWorkShift.Id" style="font-size: 15px !important;">
                          {{cashierWorkShift.Name}}
                      </ng-option>
                  </ng-select>
                </div>

                <div class="form-group form-primary">
                <label class="float-label push righ"style="font-size: 15px;">{{"CashierStartingNote" | translate}}</label>
                <textarea type="text" name="Description" class="form-control" 
                  style="width: 100%; height: 150px;" [(ngModel)]="startingNote"
                  placeholder='{{"CashierStartingNote" | translate}}'></textarea>
                <span class="form-bar"></span>
                </div>

                <button class="btn btn-primary" (click)="LogIntoSession()" 
                [disabled]="!((cashierStaffId>0) && (cashRegisterId>0) && (startingCash>=0) && (cashierWorkShiftId>0))"
                style="font-weight: bold; width: 100%; text-align: center; margin-top: 10px;">{{"CashierLogin" |
                translate}}</button>
                <button class="btn btn-light" (click)="cashierLoginModal.hide()"
                style="font-weight: bold; width: 100%; text-align: center; margin-top: 10px;">{{"Close" |
                translate}}</button>
            </div>
        </div>
    </div>
</div>
<div class="pagecontent">
    <div id="redirectCards" style="display: block;">
        <div class="glide__slide ng-star-inserted glide__slide--active d-inline-block redirect-card">
            <div class="icon-row-item">
                <div class="card"
                [ngClass]="currentAction=='loading'?'card inactive-loading':'card'">
                    <!-- <div class="card-body text-center" routerLink="Cashier-Screen"><i class="simple-icon-calculator iconfont-30"></i> -->
                    <div class="card-body text-center" 
                    (click)="currentAction=='loading'?null:startSession()"><i class="simple-icon-calculator iconfont-30"></i>
                        <a>{{"CashierScreen" | translate}}</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="glide__slide ng-star-inserted glide__slide--active d-inline-block redirect-card">
            <div class="icon-row-item">
                <div class="card">
                    <div class="card-body text-center" routerLink="Cashiers"><i class="iconsminds-business-man-woman iconfont-30"></i>
                        <a>{{"Cashiers" | translate}}</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="glide__slide ng-star-inserted glide__slide--active d-inline-block redirect-card">
            <div class="icon-row-item">
                <div class="card">
                    <div class="card-body text-center" routerLink="Orders"><i class="iconsminds-checkout iconfont-30"></i>
                        <a>{{"CashierOrders" | translate}}</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="glide__slide ng-star-inserted glide__slide--active d-inline-block redirect-card">
            <div class="icon-row-item">
                <div class="card">
                    <div class="card-body text-center" routerLink="Sessions"><i class="iconsminds-over-time-2 iconfont-30"></i>
                        <a>{{"CashierSessions" | translate}}</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="glide__slide ng-star-inserted glide__slide--active d-inline-block redirect-card">
            <div class="icon-row-item">
                <div class="card">
                    <div class="card-body text-center" routerLink="Config"><i class="simple-icon-settings iconfont-30"></i>
                        <a>{{"ConfigPoS" | translate}}</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="glide__slide ng-star-inserted glide__slide--active d-inline-block redirect-card">
            <div class="icon-row-item">
                <div class="card">
                    <div class="card-body text-center" routerLink="categories">
                        <i class="simple-icon-organization iconfont-30"></i>
                        <a>{{"Categories" | translate}}</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="glide__slide ng-star-inserted glide__slide--active d-inline-block redirect-card">
            <div class="icon-row-item">
                <div class="card">
                    <div class="card-body text-center" routerLink="items"><i class="iconsminds-box-close iconfont-30"></i>
                        <!-- <img src="assets/img/1710414.png" width="40px;" height="40px;"> -->
                        
                        <a>{{"Items" | translate}}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> 

<simple-notifications></simple-notifications>