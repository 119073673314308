import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { InvoiceModel } from './InvoiceModel';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {
    constructor(private http: HttpClient) {
  
    }
    CompanyId:number =+ localStorage.getItem("CompanyId");

    baseUrl = environment.baseApiUrl;
     token = "eyJhbGciOiJSUzI1NiIsImtpZCI6Ijk2RjNBNjU2OEFEQzY0MzZDNjVBNDg1MUQ5REM0NTlFQTlCM0I1NTQiLCJ0eXAiOiJhdCtqd3QiLCJ4NXQiOiJsdk9tVm9yY1pEYkdXa2hSMmR4Rm5xbXp0VlEifQ.eyJuYmYiOjE2NzIwMDQzNjUsImV4cCI6MTY3MjAwNzk2NSwiaXNzIjoiaHR0cHM6Ly9pZC5ldGEuZ292LmVnIiwiYXVkIjoiSW52b2ljaW5nQVBJIiwiY2xpZW50X2lkIjoiZDE5ZTA1MGUtMjc3Yi00MWJjLWJmMzctZDVlNWM0ZTUwYTNlIiwiSXNUYXhSZXByZXMiOiIxIiwiSXNJbnRlcm1lZGlhcnkiOiIwIiwiSW50ZXJtZWRJZCI6IjAiLCJJbnRlcm1lZFJJTiI6IiIsIkludGVybWVkRW5mb3JjZWQiOiIyIiwibmFtZSI6IjYxODc2NDM1NjpkMTllMDUwZS0yNzdiLTQxYmMtYmYzNy1kNWU1YzRlNTBhM2UiLCJTU0lkIjoiZWVkYzM3MjYtMTFjYy01ZGFlLTJmNTMtNzgyZjRhOWVmMWMyIiwicHJlZmVycmVkX3VzZXJuYW1lIjoiQUlPIEVSUCIsIlRheElkIjoiMTM0NTYwIiwiVGF4UmluIjoiNjE4NzY0MzU2IiwiUHJvZklkIjoiMzIxOTA4IiwiSXNUYXhBZG1pbiI6IjAiLCJJc1N5c3RlbSI6IjEiLCJOYXRJZCI6IiIsIlRheFByb2ZUYWdzIjoiQjJCIiwic2NvcGUiOlsiSW52b2ljaW5nQVBJIl19.ASQy5kfS8LfZu4fTHvqoUp9USc1zAOsYfnJFPk7lw3D-Q9er0CQueldEaEQParGaC-gM92fBJdt2D3bIMKWI9S2ib_pOV8Mwk37cbn22nP2hzxHmdI1k0qrv2WsKgo6Z9vlSjHuSil-vzPA3lxZ_Q9oGlARifQAEp4KhAePpLBtSbXdR-6FIG-xDwvBpzs31GiZntPLIx8_3HW3_4ldjI0BTIOBTROL2DVkSfGO8ajV1Rt4KJ8lHJPWg1ynWOf38TerTkii8XM6qD9NjECN2HB3OfgL7dGtuERF-cpdAYCwU-83DMCMFnBb4Kek60mVYdxsCtyHDmwFQc0BrWprG7g";
     httpOptions = {
      headers: new HttpHeaders({
        // 'Content-Type': 'application/json; charset=utf-8',
        // 'Authorization': "Bearer " + token,
         "Authorization": "Bearer " + this.token,
         "Content-Type": "application/json"
      })
    };
    getAll(): Observable<any> {
   
      return this.http
        .get<any>(`${this.baseUrl}Invoice/GetAll/${this.CompanyId}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    GetAllNotPayed(): Observable<any> {
   
      return this.http
        .get<any>(`${this.baseUrl}Invoice/GetAllNotPayed/${this.CompanyId}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    
    GetPurchases(): Observable<any> {
   
      return this.http
        .get<any>(`${this.baseUrl}InvoicePurshases/GetAll/${this.CompanyId}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    
    getById(id:number): Observable<any> {
   
      return this.http
        .get<any>(`${this.baseUrl}Invoice/GetById/${id}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    print(uuid:string,token:string): Observable<any> {
     var httpOptions = {
        headers: new HttpHeaders({
          // 'Content-Type': 'application/json; charset=utf-8',
          // 'Authorization': "Bearer " + token,
           "Authorization": "Bearer " + token,
          //  "Content-Type": "application/json"
        })
      };
      return this.http
        .get<any>(`https://api.invoicing.eta.gov.eg/api/v1/documents/${uuid}/pdf`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    Send(id:number, TokenPin: string): Observable<any> {
      return this.http
        .get<any>(`${this.baseUrl}Invoice/Send/${id}/${TokenPin}/${this.CompanyId}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    insert(payload: InvoiceModel): Observable<any> {
      return this.http
        .post<any>(`${this.baseUrl}Invoice/Insert/${this.CompanyId}`, payload,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    update(payload: InvoiceModel): Observable<any> {
      return this.http
        .post<any>(`${this.baseUrl}Invoice/Update`, payload,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    delete(payload: InvoiceModel): Observable<any> {
      return this.http
        .get<any>(`${this.baseUrl}Invoice/Delete/${payload.Id}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
    deleteList(payload: InvoiceModel []): Observable<any> {
      return this.http
        .post<any>(`${this.baseUrl}Invoice/DeleteList`,JSON.stringify(payload),this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    loginToTaxes():Observable<string>{
      return this.http
      .get<string>(`${this.baseUrl}Login/Login/${this.CompanyId}`)
      .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    printFromBackEnd(Id:number): Observable<any> {
   
      return this.http
        .get<any>(`${this.baseUrl}Invoice/print/${Id}/${this.CompanyId}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    printInvoicePurshases(Id:string): Observable<any> {
   
      return this.http
        .get<any>(`${this.baseUrl}InvoicePurshases/print/${Id}/${this.CompanyId}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    UploadExcel(formData: FormData) {
      let headers = new HttpHeaders();
  
      headers.append('Content-Type', 'multipart/form-data');
      headers.append('Accept', 'application/json');
  
      const httpOptions = { headers: headers };
  
      return this.http.post(`${this.baseUrl}Invoice/UploadExcel/${this.CompanyId}` , formData, httpOptions)
    }
}
