<!--component html goes here -->


<div class="row">
    <div class="col-12">
      <div class="card mb-4">
        <div class="card-body">
          <!-- <h5 class="mb-4">{{ 'From Date' | translate }}</h5> -->
          <div class="row">
            <div class="col-12 col-lg-6 mb-4">
              <label><span>{{ 'DateFrom' | translate }}</span></label>
              <input 
              type="text" onfocus="(this.type='date')" onblur="if(!this.value)this.type='text'"
              onchange="if(!this.value)this.type='text'" name="Date" class="form-control"
              [(ngModel)]="search.FromDate"
              placeholder='{{"DateFrom" | translate}}'>
            </div>
            <div class="col-12 col-lg-6 mb-4">
              <label><span>{{ 'DateTo' | translate }}</span></label>
              <input 
              type="text" onfocus="(this.type='date')" onblur="if(!this.value)this.type='text'"
              onchange="if(!this.value)this.type='text'" name="Date" class="form-control"
              [(ngModel)]="search.ToDate"
              placeholder='{{"DateTo" | translate}}'>
            </div>
            <div class="col-12 col-lg-6 mb-4">
              <label><span>{{ 'Branch' | translate }}</span></label>
         
              <ng-select placeholder='{{"Branch" | translate}}' *ngIf="lang==='ar-EG'" [items]="branches" bindLabel="NameAr" bindValue="Id" [(ngModel)]="search.BranchId">
              </ng-select>
              <ng-select placeholder='{{"Branch" | translate}}' *ngIf="lang!='ar-EG'" [items]="branches" bindLabel="NameEn" bindValue="Id" [(ngModel)]="search.BranchId">
              </ng-select>
            </div>
         
            <div class="col-12 col-lg-6 mb-4">
              <label><span>{{ 'Tax' | translate }}</span></label>
              <ng-select placeholder='{{"Tax" | translate}}'  *ngIf="lang==='ar-EG'" [items]="taxes" bindLabel="NameAr" bindValue="Id" [(ngModel)]="search.TaxId">
              </ng-select>
              <ng-select placeholder='{{"Tax" | translate}}'  *ngIf="lang!='ar-EG'" [items]="taxes" bindLabel="NameEn" bindValue="Id" [(ngModel)]="search.TaxId">
              </ng-select>
            </div>
          
            <div class="col-12 col-lg-6 mb-4 "  >
         </div>
            <div class="col-12 col-lg-6 mb-4 "  >
              <button type="button" (click)="Search()" class="btn btn-primary default mb-1" style="margin: 1px;">{{'Search' | translate}}</button>{{" "}}
              <button type="button" (click)="print()" class="btn btn-secondary default mb-1 " style="margin: 1px;">{{'Print' | translate}}</button>{{" "}}
              <button type="button" (click)="exportTable()" class="btn btn-success default mb-1" style="margin: 1px;">{{'PDF' | translate}}</button>{{" "}}
              <button type="button"  class="btn btn-primary default mb-1" style="margin: 1px;">{{'Excel' | translate}}</button>{{" "}}
           
            </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  <div id="contentToConvert">
    <div class="row" >
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-body">
              <h3 class="float-center" style="text-align: center;">{{ 'Taxes Report' | translate }}</h3>
              <h4 class="float-center" style="text-align: center;">{{  today| date:'dd/MM/yyyy' }}</h4>
              <h6 class="float-center" style="text-align: center;">{{ 'Future Core' | translate }}</h6>
             
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="card " *ngIf="data">
        <div class="card-body ">
            <table class="table table-bordered">
                <thead>
                        <tr>
                            <th >{{'Code'|translate}}</th>
                            <th >{{'Financier'|translate}}</th>
                            <th >{{'Commercial Record'|translate}}</th>
                            <th >{{'Tax card'|translate}}</th>
                            <th >{{'Date'|translate}}</th>
                            <th >{{'Item'|translate}}</th>
                            <th >{{'Description'|translate}}</th>
                            <th >{{'Taxable amount'|translate}}</th>
                            <th >{{'Taxes'|translate}}</th>

                        </tr>
                </thead>
             </table>
        </div>
    </div>
    <br /> -->
  <div class="card mb-12 tree-explorer-side col-md-12" *ngIf="data">
      <div class="card-body ">
      <div class="row" *ngFor="let cost of data">
          <table class="table table-striped"  *ngIf="cost.SalesInvoices&&cost.SalesInvoices.length>0">
            <thead>
                <tr >
                    <th colspan="9" style="font-size: large;">{{lang==='ar-EG'?cost.TaxNameAr:cost.TaxNameEn}}</th>
             
                </tr>
                <tr >
                  <th colspan="9" style="font-size: large;">{{"Sales Invoices"|translate}}</th>
           
              </tr>
             
            </thead>
    
            <table class="table table-striped">
              <thead>
                <tr>
                    <th >{{'Code'|translate}}</th>
                    <th >{{'Financier'|translate}}</th>
                    <th >{{'Commercial Record'|translate}}</th>
                    <th >{{'Tax card'|translate}}</th>
                    <th >{{'Date'|translate}}</th>
                    <th >{{'Item'|translate}}</th>
                    <th >{{'Description'|translate}}</th>
                    <th >{{'Taxable amount'|translate}}</th>
                    <th >{{'Taxes'|translate}}</th>

                </tr>
        </thead>
            <tbody>
                <tr  *ngFor="let item of cost.SalesInvoices">
                    <td >{{item.Code}}#</td>
                    <td >{{item.Financier}}</td>
                    <td >{{item.CommercialRecord}}</td>
                    <td >{{item.TaxRecord}}</td>
                    <td >{{item.Date|date:'dd/MM/yyyy'}}</td>
                    <td >{{item.ItemName}}</td>
                    <td >{{item.Description}}</td>
                    <td>{{item.TheTaxableAmount}}</td>
                    <td>{{item.TaxesValue}}</td>
                </tr>
                <!-- <tr style="font: bold;font-size:medium">
                  <td colspan="6" style="font: bold;">
                    {{'Total'|translate}}
                  </td>
                  <td style="font: bold;">{{cost.TotalCreditTransfer}}</td>
                  <td style="font: bold;">{{cost.TotalDebitTransfer}}</td>
                  <td style="font: bold;">{{cost.TotalCreditBalance > cost.TotalDebitBalance ? cost.TotalCreditBalance - cost.TotalDebitBalance : 0}}</td>
                  <td style="font: bold;">{{cost.TotalDebitBalance > cost.TotalCreditBalance ? cost.TotalDebitBalance - cost.TotalCreditBalance : 0}}</td>
                </tr> -->
            </tbody>
          </table>
        </table>
        
        <table class="table table-striped"  *ngIf="cost.SalesReturns&&cost.SalesReturns.length>0">
          <thead>
           
              <tr >
                <th colspan="9" style="font-size: large;">{{"Sales Returns"|translate}}</th>
         
            </tr>
           
          </thead>
  
          <table class="table table-striped">
            <thead>
              <tr>
                  <th >{{'Code'|translate}}</th>
                  <th >{{'Financier'|translate}}</th>
                  <th >{{'Commercial Record'|translate}}</th>
                  <th >{{'Tax card'|translate}}</th>
                  <th >{{'Date'|translate}}</th>
                  <th >{{'Item'|translate}}</th>
                  <th >{{'Description'|translate}}</th>
                  <th >{{'Taxable amount'|translate}}</th>
                  <th >{{'Taxes'|translate}}</th>

              </tr>
      </thead>
          <tbody>
              <tr  *ngFor="let item of cost.SalesReturns">
                  <td >{{item.Code}}#</td>
                  <td >{{item.Financier}}</td>
                  <td >{{item.CommercialRecord}}</td>
                  <td >{{item.TaxRecord}}</td>
                  <td >{{item.Date|date:'dd/MM/yyyy'}}</td>
                  <td >{{item.ItemName}}</td>
                  <td >{{item.Description}}</td>
                  <td>{{item.TheTaxableAmount}}</td>
                  <td>{{item.TaxesValue}}</td>
              </tr>
              <!-- <tr style="font: bold;font-size:medium">
                <td colspan="6" style="font: bold;">
                  {{'Total'|translate}}
                </td>
                <td style="font: bold;">{{cost.TotalCreditTransfer}}</td>
                <td style="font: bold;">{{cost.TotalDebitTransfer}}</td>
                <td style="font: bold;">{{cost.TotalCreditBalance > cost.TotalDebitBalance ? cost.TotalCreditBalance - cost.TotalDebitBalance : 0}}</td>
                <td style="font: bold;">{{cost.TotalDebitBalance > cost.TotalCreditBalance ? cost.TotalDebitBalance - cost.TotalCreditBalance : 0}}</td>
              </tr> -->
          </tbody>
        </table>
      </table>

        <hr />
        <table class="table table-striped"  *ngIf="cost.PurchaseInvoices&&cost.PurchaseInvoices.length>0">
          <thead>
         
              <tr >
                <th colspan="9" style="font-size: large;">{{"Purchases Invoices"|translate}}</th>
         
            </tr>
           
          </thead>
  
          <table class="table table-striped">
            <thead>
              <tr>
                  <th >{{'Code'|translate}}</th>
                  <th >{{'Financier'|translate}}</th>
                  <th >{{'Commercial Record'|translate}}</th>
                  <th >{{'Tax card'|translate}}</th>
                  <th >{{'Date'|translate}}</th>
                  <th >{{'Item'|translate}}</th>
                  <th >{{'Description'|translate}}</th>
                  <th >{{'Taxable amount'|translate}}</th>
                  <th >{{'Taxes'|translate}}</th>

              </tr>
      </thead>
          <tbody>
              <tr  *ngFor="let item of cost.PurchaseInvoices">
                  <td >{{item.Code}}#</td>
                  <td >{{item.Financier}}</td>
                  <td >{{item.CommercialRecord}}</td>
                  <td >{{item.TaxRecord}}</td>
                  <td >{{item.Date|date:'dd/MM/yyyy'}}</td>
                  <td >{{item.ItemName}}</td>
                  <td >{{item.Description}}</td>
                  <td>{{item.TheTaxableAmount}}</td>
                  <td>{{item.TaxesValue}}</td>
              </tr>
              <!-- <tr style="font: bold;font-size:medium">
                <td colspan="6" style="font: bold;">
                  {{'Total'|translate}}
                </td>
                <td style="font: bold;">{{cost.TotalCreditTransfer}}</td>
                <td style="font: bold;">{{cost.TotalDebitTransfer}}</td>
                <td style="font: bold;">{{cost.TotalCreditBalance > cost.TotalDebitBalance ? cost.TotalCreditBalance - cost.TotalDebitBalance : 0}}</td>
                <td style="font: bold;">{{cost.TotalDebitBalance > cost.TotalCreditBalance ? cost.TotalDebitBalance - cost.TotalCreditBalance : 0}}</td>
              </tr> -->
          </tbody>
        </table>
      </table>
      <table class="table table-striped"  *ngIf="cost.PurchaseReturns&&cost.PurchaseReturns.length>0">
        <thead>
       
            <tr >
              <th colspan="9" style="font-size: large;">{{"Purchases Returns"|translate}}</th>
       
          </tr>
         
        </thead>

        <table class="table table-striped">
          <thead>
            <tr>
                <th >{{'Code'|translate}}</th>
                <th >{{'Financier'|translate}}</th>
                <th >{{'Commercial Record'|translate}}</th>
                <th >{{'Tax card'|translate}}</th>
                <th >{{'Date'|translate}}</th>
                <th >{{'Item'|translate}}</th>
                <th >{{'Description'|translate}}</th>
                <th >{{'Taxable amount'|translate}}</th>
                <th >{{'Taxes'|translate}}</th>

            </tr>
    </thead>
        <tbody>
            <tr  *ngFor="let item of cost.PurchaseReturns">
                <td >{{item.Code}}#</td>
                <td >{{item.Financier}}</td>
                <td >{{item.CommercialRecord}}</td>
                <td >{{item.TaxRecord}}</td>
                <td >{{item.Date|date:'dd/MM/yyyy'}}</td>
                <td >{{item.ItemName}}</td>
                <td >{{item.Description}}</td>
                <td>{{item.TheTaxableAmount}}</td>
                <td>{{item.TaxesValue}}</td>
            </tr>
            <!-- <tr style="font: bold;font-size:medium">
              <td colspan="6" style="font: bold;">
                {{'Total'|translate}}
              </td>
              <td style="font: bold;">{{cost.TotalCreditTransfer}}</td>
              <td style="font: bold;">{{cost.TotalDebitTransfer}}</td>
              <td style="font: bold;">{{cost.TotalCreditBalance > cost.TotalDebitBalance ? cost.TotalCreditBalance - cost.TotalDebitBalance : 0}}</td>
              <td style="font: bold;">{{cost.TotalDebitBalance > cost.TotalCreditBalance ? cost.TotalDebitBalance - cost.TotalCreditBalance : 0}}</td>
            </tr> -->
        </tbody>
      </table>
    </table>
    <table class="table table-striped">
      <thead>
     
          <tr >
            <th colspan="8" style="font-size: large;">{{"Total"|translate}}</th>
            <th  style="font-size: large;">{{cost.TotlaTheTaxableAmount}}</th>
     
        </tr>
       
      </thead>

  </table>
      </div>
  
      </div>
    </div>
  </div>