
export class SalarySlipDueModel{
    Id?: number;
    SalaryItemsId?: number;
    SalarySlipId?: number;
    Amount?: number;
    SalaryItemName?: string;
    
    
  
   
    constructor(item?:SalarySlipDueModel){
        item = item? item:{};
        this.Id = item.Id? item.Id:0;
        this.SalaryItemsId = item.SalaryItemsId? item.SalaryItemsId:0;
        this.SalarySlipId = item.SalarySlipId? item.SalarySlipId:0;
        this.Amount = item.Amount? item.Amount:0;
        this.SalaryItemName = item.SalaryItemName? item.SalaryItemName:'';
  
 
      

    }

}