import { EmployeesChoiceModel } from "./EmployeesChoiceModel";
import {EmployeesExcludedModel} from './EmployeesExcludedModel'

export class SalariesManagerModel{
    Id?: number;
    dateOfRegistration?:Date;
    StartingDate?:Date;
    EndDate?:Date;
    Name?:string;
    CheckAttendance?:boolean;
    GrammarChoose?:boolean;
    EmployeesChoose?:boolean;
    BranchId?: number;
    DepartmentId?: number;
    JobTitletId?: number;
    Code?: number;
    ArrestCycleId?: number;
    EmployeesChoice?:EmployeesChoiceModel[];
    EmployeesExcluded?:EmployeesExcludedModel[];
    

    AcceptAll?:boolean;
    IsPayment?:boolean;
    
    
   
   
    constructor(item?:SalariesManagerModel){
        item = item? item:{};
        this.Id = item.Id? item.Id:0;
        this.Name = item.Name? item.Name:'';
        this.CheckAttendance = item.CheckAttendance? item.CheckAttendance:true;
        this.GrammarChoose = item.GrammarChoose? item.GrammarChoose:true;
        this.EmployeesChoose = item.EmployeesChoose? item.EmployeesChoose:false;
        this.BranchId = item.BranchId? item.BranchId:0;
        this.DepartmentId = item.DepartmentId? item.DepartmentId:0;
        this.JobTitletId = item.JobTitletId? item.JobTitletId:0;
        this.ArrestCycleId = item.ArrestCycleId? item.ArrestCycleId:0;
        this.dateOfRegistration = item.dateOfRegistration? item.dateOfRegistration:new Date();
        this.StartingDate = item.StartingDate? item.StartingDate:new Date();
        this.EndDate = item.EndDate? item.EndDate:new Date();
        this.Code = item.Code? item.Code:0;
        
        this.EmployeesChoice = item.EmployeesChoice? item.EmployeesChoice:[];
        this.EmployeesExcluded = item.EmployeesExcluded? item.EmployeesExcluded:[];
        this.AcceptAll = item.AcceptAll? item.AcceptAll:false;
        this.IsPayment = item.IsPayment? item.IsPayment:false;
      

    }

}