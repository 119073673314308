import { Component, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { TableEngine } from 'src/app/invoices/TableEngine';

import { SidePopupComponent } from 'src/app/views/invoice/side-popup/side-popup.component';
import { EmployeesManagementModel } from '../EmployeesManagementModel';
import { EmployeesManagementService } from '../employees-management.service';
import { CountryModel } from 'src/app/invoices/suppliers/model/countrymodel';
import { CountryService } from '../country.service';
import { JobTitlesModel } from 'src/app/HR/Organization_Structure/JobTitels_Componentes/JobTitlesModel';
import { JobLevelsModel } from 'src/app/HR/Organization_Structure/JobLevelsComponentes/JobLevelsModel';
import { JobTypesModel } from 'src/app/HR/Organization_Structure/JobTypesComponentes/JobTypesModel';
import { JobLevelsService } from 'src/app/HR/Organization_Structure/JobLevelsComponentes/job-levels.service';
import { JobTitlesService } from 'src/app/HR/Organization_Structure/JobTitels_Componentes/job-titles.service';
import { JobTypesService } from 'src/app/HR/Organization_Structure/JobTypesComponentes/job-types.service';
import { DepartmentModel } from 'src/app/HR/Organization_Structure/Department_components/DepartmentModel';
import { DepartmentService } from 'src/app/HR/Organization_Structure/Department_components/department.service';
import { BranchService } from 'src/app/invoices/branches/BranchService';
import { BranchModel } from 'src/app/invoices/branches/BranchModel';

@Component({
    // moduleId: module.id,
    selector: 'add-employees-management',
    templateUrl: 'add-employees-management.component.html',
    styleUrls: ['add-employees-management.component.scss']
})
export class AddEmployeesManagementComponent {
    Id = 0;

    constructor(
        
      public router: Router,
      private fb: FormBuilder,
      private modelService: EmployeesManagementService,
      private notifications: NotificationsService,
      private CountryService:CountryService,
      private JobLevelsService:JobLevelsService,
      private JobTitlesService:JobTitlesService,
      private JobTypesService:JobTypesService,
      private BranchesService:BranchService,
      private DepartmentService:DepartmentService,
      
      private translate: TranslateService,
      private route: ActivatedRoute
    ) {
      this.Id = +route.snapshot.paramMap.get("Id");
    }
    showItemIndex = 0;
    tableEngine = new TableEngine(this.translate, this.notifications);
    currentScreen: any = "Table";
    translationName: string = "Item";
    sortableKeys = [];
    searchableKeys = ["Name", "Address"];
    tableView: any = true;
  
    EmployeesManagementModel:EmployeesManagementModel[]=[];
    isLastOpen = false;
  
  
    modelObject: EmployeesManagementModel = new EmployeesManagementModel();
    Form: FormGroup;
    declare lang: any;
    declare itemOrder: any;
    currentAction = "";
    savedPage = 0;
    currentPage = 1;
    deletionList: any[] = [];
    prevAction = "";
    addToListId = 0;
    ItemsBalanceHeaderId = 0;
    ItemBalanceId = 0;
    currentFormMode: string = "";
    type = 0;
    visable = "";
    visable2 = "";
    visable3 = "";

    Code=0;

  AlertMessage :string="Alert me when the quantity reaches less than";
    ogData: EmployeesManagementModel[] = [];
    mainForm: FormGroup = new FormGroup({
        Id: new FormControl(),
        Name: new FormControl(),
        Nickname: new FormControl(),
        MiddleName: new FormControl(),
        EmployeeType: new FormControl(),
        EMail: new FormControl(),
        BirthDate: new FormControl(),
        Type: new FormControl(),
        CountryId: new FormControl(),
        MobileNumber: new FormControl(),
        GroundPhoneNumber: new FormControl(),
        PersonalEmail: new FormControl(),
        FirstAddress: new FormControl(),
        SecondAddress: new FormControl(),
        City: new FormControl(),
        Governorate: new FormControl(),
        PostalCode: new FormControl(),
        FirstPermanentAddress: new FormControl(),
        SecondPermanentAddress: new FormControl(),
        PermanentCity: new FormControl(),
        PermanentGovernorate: new FormControl(),
        PermanentPostalCode: new FormControl(),
        DepartmentId: new FormControl(),
        JobLevelId: new FormControl(),
        JoiningDate: new FormControl(),
        JobTitelId: new FormControl(),
        BranchId: new FormControl(),
        Month: new FormControl(),
        Day: new FormControl(),
        AttendanceShiftId: new FormControl(),
        VacationPolicyId: new FormControl(),
        AttendanceDeterminantsId: new FormControl(),
        Status: new FormControl(),
        IsAccessToTheSystem: new FormControl(),
        Notes: new FormControl(),
        JobTypeId: new FormControl(),

        Code: new FormControl(),
        LanguageId: new FormControl(),
        JobRoleId: new FormControl(),
        SendLoginDataByMail: new FormControl(),
        IsDefaultFiscalDate: new FormControl(),
        
        
    });
  


    
    @ViewChild("sideModalRef", { static: true })
    sideModalRef: SidePopupComponent = null;
    @ViewChild("delModal", { static: true }) delModal: ModalDirective;
    @ViewChild("smallModal", { static: true }) smallModal: ModalDirective = null;
    formPresentationModal: any;
    ngOnInit(): void {
      // this.getAllCountryCode();
  
      this.lang = localStorage.getItem("theme_lang");
      this.itemOrder = {
        label: "Name",
        property: this.lang === "ar-EG" ? "Name" : "Name",
      };
      this.sortableKeys = [
        // { label: 'Date', property: 'Date' },
        { label: 'Name', property: this.lang === 'ar-EG' ? 'Name' : 'Name' },   
        { label: 'Nickname', property: this.lang === 'ar-EG' ? 'Nickname' : 'Nickname' },   
        { label: 'MobileNumber', property: this.lang === 'ar-EG' ? 'MobileNumber' : 'MobileNumber' }, 
            
      ];
  
      this.tableEngine.loadTableEngine(
        this.ogData,
        this.modelService,
        this.modelObject,
        this.searchableKeys,
        this.sortableKeys,
        this.mainForm,
        this.translationName,
        this.itemOrder,
        this.sideModalRef,
        this.delModal,
        this.smallModal,
        this.lang
      );
    
      this.loadServices();
      this.modelService.GetLastCode().subscribe(data=>{
        this.Code=data;
      });
       this.CountryService.getAll().subscribe(data=>{
        this.CountryList=data;
       });
       this.JobLevelsService.getAll().subscribe(data=>{
        this.JobLevelList=data;
       });
       this.JobTitlesService.getAll().subscribe(data=>{
        this.JobTitelList=data;
       });
       this.JobTypesService.getAll().subscribe(data=>{
        this.JobTypeList=data;
       });
       this.BranchesService.getAll().subscribe(data=>{
        this.BranchList=data;
       });
       this.DepartmentService.getAll().subscribe(data=>{
        this.DepartmentList=data;
       });
       this.modelObject.EmployeeType="موظف";
          if(this.Id > 0)
          {   debugger;                            
                  this.modelService.getById(this.Id).subscribe(data=>{                        
                  this.modelObject = data;                 
                  this.mainForm = this.createForm(this.modelObject);
              });
          }
          else{
                  
             this.mainForm = this.createForm(this.modelObject);                              
          }
      this.mainForm = this.createForm(this.modelObject);
  
    }


  getMonthList(
      locales?: string | string[],
      format: "long" | "short" = "long"
    ): string[] {
      const year = new Date().getFullYear(); // 2020
      const monthList = [...Array(12).keys()]; // [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
      const formatter = new Intl.DateTimeFormat(locales, {
        month: format
      });
    
      const getMonthName = (monthIndex: number) =>
        formatter.format(new Date(year, monthIndex));
    
      return monthList.map(getMonthName);
    }



    createForm(modelObject): FormGroup {
      debugger;
      return this.fb.group({
        Id: [modelObject.Id >= 0 ? modelObject.Id : 0],
        Name: [modelObject.Name, Validators.compose([Validators.required])],
        MiddleName: [ modelObject.MiddleName],
        Nickname: [modelObject.Nickname],
        EmployeeType: [modelObject.EmployeeType],
        EMail: [modelObject.EMail],
        BirthDate: [modelObject.BirthDate, Validators.compose([Validators.required])],
        Type: [modelObject.Type],
        CountryId: [modelObject.CountryId, Validators.compose([Validators.required])],
        MobileNumber: [modelObject.MobileNumber],
        GroundPhoneNumber: [modelObject.GroundPhoneNumber],
        PersonalEmail: [modelObject.PersonalEmail],
        FirstAddress: [modelObject.FirstAddress],
        SecondAddress: [modelObject.SecondAddress],
        City: [modelObject.City],
        Governorate: [modelObject.Governorate],
        PostalCode: [modelObject.PostalCode],
        FirstPermanentAddress: [modelObject.FirstPermanentAddress],
        SecondPermanentAddress: [modelObject.SecondPermanentAddress],
        PermanentCity: [modelObject.PermanentCity],
        PermanentGovernorate: [modelObject.PermanentGovernorate],
        PermanentPostalCode: [modelObject.PermanentPostalCode],
        DepartmentId: [modelObject.DepartmentId],
        JoiningDate: [modelObject.JoiningDate, Validators.compose([Validators.required])],
        JobTitelId: [modelObject.JobTitelId],
        BranchId: [modelObject.BranchId, Validators.compose([Validators.required])],
        Month: [modelObject.Month],
        Day: [modelObject.Day],
        AttendanceShiftId: [modelObject.AttendanceShiftId],
        VacationPolicyId: [modelObject.VacationPolicyId],
        AttendanceDeterminantsId: [modelObject.AttendanceDeterminantsId],
        Status: [modelObject.Status, Validators.compose([Validators.required])],
        IsAccessToTheSystem: [modelObject.IsAccessToTheSystem],
        Notes: [modelObject.Notes],
        JobLevelId: [modelObject.JobLevelId],
        JobTypeId: [modelObject.JobTypeId],

        SendLoginDataByMail: [modelObject.SendLoginDataByMail],
        LanguageId: [modelObject.LanguageId],
        JobRoleId: [modelObject.JobRoleId],
        IsDefaultFiscalDate: [modelObject.IsDefaultFiscalDate],
        
       
      });
    }

    CountryList:CountryModel[]=[];
    JobTitelList:JobTitlesModel[]=[];
    JobLevelList:JobLevelsModel[]=[];
    JobTypeList:JobTypesModel[]=[];
    BranchList:BranchModel[]=[];
    DepartmentList:DepartmentModel[]=[];
    loadServices() {
      this.currentAction = "loading";
      this.tableEngine.loadData();
    }
    goBack() {
      this.router.navigate(["app/HR/List-Employees"]);
    }
    Save() {
      debugger;
      this.currentAction = "loading";
      this.tableEngine.savedPage = this.tableEngine.currentPage;
      this.modelObject = new EmployeesManagementModel();
      this.modelObject.Id = 0;
      this.modelObject.Name = this.mainForm.getRawValue().Name;
      this.modelObject.Nickname = this.mainForm.getRawValue().Nickname;
      this.modelObject.MiddleName = this.mainForm.getRawValue().MiddleName;
      this.modelObject.EmployeeType = this.mainForm.getRawValue().EmployeeType;
      this.modelObject.EMail = this.mainForm.getRawValue().EMail;
      this.modelObject.BirthDate = this.mainForm.getRawValue().BirthDate;
      this.modelObject.Type = this.mainForm.getRawValue().Type;
      this.modelObject.CountryId = this.mainForm.getRawValue().CountryId;
      this.modelObject.MobileNumber = this.mainForm.getRawValue().MobileNumber;
      this.modelObject.GroundPhoneNumber = this.mainForm.getRawValue().GroundPhoneNumber;
      this.modelObject.PersonalEmail = this.mainForm.getRawValue().PersonalEmail;
      this.modelObject.FirstAddress = this.mainForm.getRawValue().FirstAddress;
      this.modelObject.SecondAddress = this.mainForm.getRawValue().SecondAddress;
      this.modelObject.City = this.mainForm.getRawValue().City;
      this.modelObject.Governorate = this.mainForm.getRawValue().Governorate;
      this.modelObject.PostalCode = this.mainForm.getRawValue().PostalCode;
      this.modelObject.FirstPermanentAddress = this.mainForm.getRawValue().FirstPermanentAddress;
      this.modelObject.SecondPermanentAddress = this.mainForm.getRawValue().SecondPermanentAddress;
      this.modelObject.PermanentCity = this.mainForm.getRawValue().PermanentCity;
      this.modelObject.PermanentGovernorate = this.mainForm.getRawValue().PermanentGovernorate;
      this.modelObject.PermanentPostalCode = this.mainForm.getRawValue().PermanentPostalCode;
      this.modelObject.DepartmentId = +this.mainForm.getRawValue().DepartmentId;
      this.modelObject.JobLevelId = +this.mainForm.getRawValue().JobLevelId;
      this.modelObject.JoiningDate = this.mainForm.getRawValue().JoiningDate;
      this.modelObject.JobTitelId = +this.mainForm.getRawValue().JobTitelId;
      this.modelObject.BranchId = +this.mainForm.getRawValue().BranchId;
      // this.modelObject.Month = this.mainForm.getRawValue().Month;
      // this.modelObject.Day = this.mainForm.getRawValue().Day;
      this.modelObject.SendLoginDataByMail = this.mainForm.getRawValue().SendLoginDataByMail;
      this.modelObject.LanguageId = this.mainForm.getRawValue().LanguageId;
      this.modelObject.JobRoleId = this.mainForm.getRawValue().JobRoleId;
      this.modelObject.Code=this.Code+1;

     
      // not comleted
      this.modelObject.AttendanceShiftId = 0;
      this.modelObject.VacationPolicyId = 0;
      this.modelObject.AttendanceDeterminantsId = 0;
      //

      this.modelObject.Status = this.mainForm.getRawValue().Status;
      this.modelObject.IsAccessToTheSystem =true;
      this.modelObject.JobTypeId = this.mainForm.getRawValue().JobTypeId;
      if(this.modelObject.IsDefaultFiscalDate==true){
        this.modelObject.Month="August";
        this.modelObject.Day="1";
      }
      if(this.modelObject.IsDefaultFiscalDate==false)
      {
        this.modelObject.Month = this.mainForm.getRawValue().Month;
        this.modelObject.Day = this.mainForm.getRawValue().Day;
      }
      this.modelObject.IsDefaultFiscalDate = this.mainForm.getRawValue().IsDefaultFiscalDate;
      if (this.Id == 0) {
        this.modelService.insert(this.modelObject).subscribe((data) => {
          this.router.navigate(["app/HR/List-Employees"]);
        });
      } else {
        debugger;
        this.modelService.update(this.modelObject).subscribe((data) => {
          this.router.navigate(["app/HR/List-Employees"]);
        });
      }
    }
}
