import { Router } from '@angular/router';
import { Component, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NotificationType, NotificationsService } from 'angular2-notifications';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { SidePopupComponent } from '../../shared/side-popup/side-popup.component';
import { TableEngine } from '../../shared/TableEngine';
import { DailyrestrictionsModel } from '../dailyrestrictions/DailyrestrictionsModel';
import { DailyrestrictionsService } from '../dailyrestrictions/DailyrestrictionsService';
import { FiscalYearService } from '../fiscal-years/fiscal-year.service';
@Component({
    selector: 'all-dailyrestrictions',
    templateUrl: 'all-dailyrestrictions.component.html',
    styleUrls: ['all-dailyrestrictions.component.scss']
})
export class AllDailyrestrictionsComponent {
    ////////////////////////////////////////////////////////////////////////////
    //  VARYING :
    //  Modify per similar component.
    ////////////////////////////////////////////////////////////////////////////
    dailyRestrictions: DailyrestrictionsModel[] = [];
  
    constructor(
      private FiscalYearService:FiscalYearService,
      private dailyRestrictionsService: DailyrestrictionsService,private formBuilder: FormBuilder, public modelService: DailyrestrictionsService, private renderer: Renderer2,
      private notifications: NotificationsService, private translate: TranslateService,public router : Router
  
    ) {
    }
    SearchFrom: Date = null;
    SearchTo: Date = null;
  
    clearSearch() {
      this.SearchFrom = null;
      this.SearchTo = null;
    }
    tableEngine = new TableEngine(this.translate, this.notifications);
    currentScreen: any = 'Table';
  
    translationName: string = ' Daily Restrictions'
  
    sortableKeys = [];
    searchableKeys = ['Id', 'Code'];
  
    modelObject: DailyrestrictionsModel = new DailyrestrictionsModel();
  
    ogData: DailyrestrictionsModel[] = [];
    mainForm: FormGroup = new FormGroup({
      Id: new FormControl(),
      NameEn: new FormControl(),
      NameAr: new FormControl(),
      BankAddress: new FormControl(),
      BankAccountIBAN: new FormControl(),
      SwiftCode: new FormControl(),
      Terms: new FormControl(),
      AccountNameId: new FormControl(),
      
      
    });
  
  
    declare lang: any;
    declare itemOrder: any;
    currentAction = '';
    savedPage = 0;
    currentPage = 1;
    deletionList: any[] = [];
  
    @ViewChild('sideModalRef', { static: true }) sideModalRef: SidePopupComponent ;
    @ViewChild('delModal', { static: true }) delModal: ModalDirective;
    @ViewChild('smallModal', { static: true }) smallModal: ModalDirective= null;
    ngOnInit(): void {
      this.lang = localStorage.getItem('theme_lang');
      this.itemOrder = { label: 'Id', property: 'Id'};
      this.sortableKeys = [
        { label: 'Id', property: 'Id' },
      ];
  
      this.tableEngine.loadTableEngine(this.ogData, this.modelService, this.modelObject,
        this.searchableKeys, this.sortableKeys, this.mainForm, this.translationName, this.itemOrder,
        this.sideModalRef, this.delModal, this.smallModal, this.lang
      )
      this.loadServices();
    }
  
    showModal(Id, action) {
      if (action === 'Add') {
       this.router.navigate(['app/accounts/Dailyrestrictions',0])
      }
      else if (action === 'Edit') {
  
        this.router.navigate(['app/accounts/Dailyrestrictions',Id])

  
      }
      else if (action === 'Info') {
        this.router.navigate(['app/accounts/view-daily-restriction',Id])
      }
      this.modelObject = this.tableEngine.modelObject;
      this.mainForm = this.tableEngine.mainForm;
    }
  
    createForm(modelObject, formBuilder): FormGroup {
      //console.log("from craeteform");
  
      //console.log("modelObject",modelObject.NameEn);
  
      return formBuilder.group({
        Id: [modelObject.Id >= 0 ? modelObject.Id : 0],
        
        Terms: [modelObject.Terms, Validators.compose([Validators.required, Validators.minLength(2)])],
        SwiftCode: [modelObject.SwiftCode, Validators.compose([Validators.required, Validators.minLength(2)])],
        BankAccountIBAN: [modelObject.BankAccountIBAN, Validators.compose([Validators.required, Validators.minLength(2)])],
        BankAddress: [modelObject.BankAddress, Validators.compose([Validators.required, Validators.minLength(2)])],
        NameEn: [modelObject.NameEn, Validators.compose([Validators.required, Validators.minLength(2)])],
        NameAr: [modelObject.NameAr, Validators.compose([Validators.required, Validators.pattern(/^[\u0621-\u064A]+([\s]*[\u0621-\u064A]+)*$/)])],
        AccountNameId: [modelObject.AccountNameId, Validators.compose([Validators.required,])],
      });
    }
  
    addObject() {
      this.currentAction = 'loading';
  
      this.modelObject = new DailyrestrictionsModel();

      this.modelObject.AccountNameId = this.mainForm.getRawValue().AccountNameId;
      
    }
  
    
  
   
  
    //Deletion methods:
    //Customize if needed.
    //Pass the target object as parameter if the target API can only delete singular objects and not lists.
    deleteObject() {
      this.savedPage = this.currentPage;
      //prevent multiclicks. re enable after API response
      this.currentAction = 'loading';
      this.executeDeleteObject();
    }
  
    //Same here...
    executeDeleteObject() {
      this.modelService.deleteList(this.tableEngine.deletionList).subscribe(result => {
        if (result === true) {
          this.tableEngine.loadData();
          this.currentAction = '';
          this.tableEngine.finalizeDeletion();
          this.toastSuccess(this.translate.instant("Success"), this.translate.instant("Delete Done Successfully"));
        }
        else {
          this.toastError(this.translate.instant("Error"), this.translate.instant("FailedDelete"));
          this.currentAction = '';
        }
      })
    }
  
  
    prepChangeSort(sortKey) {
      this.tableEngine.itemOrder = this.itemOrder;
      this.tableEngine.changeSort(this.tableEngine.getSort(sortKey))
    }
    ////////////////////////////////////////////////////////////////////////////
    //
    //
    //
    //
    //
    ////////////////////////////////////////////////////////////////////////////
    //  EXCLUSIVE :
    //  Specifically for this component.
    ///////////////////////////////
    //Get any additional data here
    loadServices() {
      this.currentAction = 'loading';
      this.modelService.getAll().subscribe(
        data=>{
          this.dailyRestrictions=data;
          this.tableEngine.loadDataAndReverse();
  
        }
      );
    }

    ////////////////////////////////////////////////////////////////////////////
    //
    //
    //
    //
    //
    ////////////////////////////////////////////////////////////////////////////
    //  CONSTANT :
    //  Do not change.
    ///////////////////////////////
    prepSort(sortKey) {
      this.tableEngine.changeSort(this.tableEngine.getSort('Name'));
      this.itemOrder = this.tableEngine.itemOrder;
    }
    toastSuccess(toastHeader: string, toastBody: string): void {
      this.notifications.create(toastHeader, toastBody,
        NotificationType.Success, { timeOut: 3000, showProgressBar: true });
    }
    toastError(toastHeader: string, toastBody: string): void {
      debugger;
      this.notifications.create(toastHeader, toastBody,
        NotificationType.Error, { timeOut: 3000, showProgressBar: true });
    }
    onInfo(toastHeader: string, toastBody: string): void {
      debugger;
      this.notifications.create(toastHeader, toastBody,
        NotificationType.Info, { timeOut: 3000, showProgressBar: true });
    }
  }
  