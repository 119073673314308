
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AllCouncilAffairsComponent } from './components/all-council-affairs/all-council-affairs.component';
import { AddCouncilAffairsComponent } from './components/add-council-affairs/add-council-affairs.component';
import { AllPositionsOfCouncilMembersComponent } from './components/all-positions-of-council-members/all-positions-of-council-members.component';
import {CouncilMemberComponent} from './components/council-member/council-member.component';
import { TypesOfTopicsComponent } from './components/types-of-topics/types-of-topics.component';
import { AllThirdPartyComponent } from './components/all-third-party/all-third-party.component';
import { AddThirdPartyComponent } from './components/add-third-party/add-third-party.component';
import { AllBoardsOfDirectorsComponent } from './components/all-boards-of-directors/all-boards-of-directors.component';
import { AddBoardOfDirectorComponent } from './components/add-board-of-director/add-board-of-director.component';
const routes: Routes = [
    {
        path: '', component: CouncilMemberComponent,
        
    },
    {
        path: 'board-of-directors', component: AllCouncilAffairsComponent,
        
    },
    
    {
        path: 'positions-of-council-members', component: AllPositionsOfCouncilMembersComponent,
        
    },
    {
        path:'create-council-affairs/:Id',
        component:AddCouncilAffairsComponent
    },
    {
        path:'types-of-topics',
        component:TypesOfTopicsComponent
    },
    {
        path:'third-party',
        component:AllThirdPartyComponent
    },
    {
        path:'create-third-party/:Id',
        component:AddThirdPartyComponent
    },
    {
        path:'boards-of-directors',
        component:AllBoardsOfDirectorsComponent
    },
    {
        path:'board-of-director/:Id',
        component:AddBoardOfDirectorComponent
    },
    
    
    // {
    //     path:'view-membership/:Id',
    //     component:ViewMembershipComponent
    // }
   
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class CouncilAndMembersAffairsRoutingModule { }