export class DepositTreasuryAndBankRolesFunctionalRolesModel{
    Id?: number;
    TreasuryAndBankAccountId?: number;
    RoleId?: number;
    constructor(item?:DepositTreasuryAndBankRolesFunctionalRolesModel){
        item = item ? item : {};
        this.Id = item.Id ? item.Id : 0;
        this.TreasuryAndBankAccountId = item.TreasuryAndBankAccountId ? item.TreasuryAndBankAccountId : 0;
        this.RoleId = item.RoleId ? item.RoleId : 0;
    }
}