    <div class="row mb-12">
        <div class="col-md-12 col-lg-12 col-12 mb-12">
          <div class="card">
            
           
            </div>
        </div>
    </div>
    <div class="row" *ngIf="Form">
        <div class="col-12">
          <div class="card mb-4">
            <div class="card-body">
                <h5 class="float-left">{{ 'Create Project' | translate }}</h5>
                <div class="top-right-button-container text-zero float-right">
                    <button type="button" (click)="Save()" [disabled]="Form.invalid" class="top-right-button btn btn-primary  btn-lg mr-1">{{'Save'|translate}}</button>
                 </div>
            </div>
          </div>
        </div>
      </div>
<div class="row mb-12">
  <div class="col-md-12 col-lg-12 col-12 mb-12">
    <div class="card">
      <tabset class="card-tabs" [justified]="true">
        <tab heading="{{'Basic Data' | translate}}" class="card-body">
            <form  *ngIf="Form" [formGroup]="Form">
                <div class="form-group">
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label>{{ 'Code' | translate }}</label>
                            <input formControlName="Code" type="number" class="form-control">
                        </div>
                        <div class="form-group col-md-6">
                            <label for="inputPassword4">{{ 'Project Amount' | translate }}</label>
                            <input  formControlName="ProjectAmount" type="number" id="inputPassword4" class="form-control">
                        </div>
                      
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label >{{'NameAr'| translate}}</label>
                            <input formControlName="NameAr" type="text" class="form-control">
                        </div>
                        <div class="form-group col-md-6">
                            <label for="inputPassword4">{{'Name En'| translate}}</label>
                            <input formControlName="NameEn" type="text" id="inputPassword4" class="form-control" >
                        </div>
                   
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label for="inputPassword4">{{'StartDate'| translate}}</label>
                            <input formControlName="StartDate" type="date" id="inputPassword4" class="form-control" >
                        </div>
                        <div class="form-group col-md-6">
                            <label for="inputPassword4">{{'EndDate'| translate}}</label>
                            <input formControlName="EndDate" type="date" id="inputPassword4" class="form-control" >
                        </div>
                    </div>
                <div class="row">
                    <div class=" col-md-6">
                        <label for="inputState">{{ 'Client' | translate }}</label>
                        <select  formControlName="ClientId"  id="inputState" class="form-control">
                            <option disabled selected value="0">{{'Client' | translate}}</option>
                            <option *ngFor="let item of clients" [value]="item.Id">{{lang==='ar-EG'?item.NameAr:item.NameEn}}</option>
                        </select>
                    </div>
                </div>
                </div>
               </form>
        </tab>
        <tab heading="{{'Contractors' | translate}}" class="card-body">
          <h6 class="mb-4">{{'Contractors' | translate}}</h6>
          <div class="row">
            <div class=" col-md-12">
                <label for="inputState">{{ 'Contractors' | translate }}</label>
                <select  [(ngModel)]="selectedContractorId" (change)="selectContractor()"  id="inputState" class="form-control">
                    <option disabled selected value="0">{{'Contractors' | translate}}</option>
                    <option *ngFor="let item of Contractors" [value]="item.Id">{{lang==='ar-EG'?item.NameAr:item.NameEn}}</option>
                </select>
                <accordion class="detail-tabs"  [closeOthers]="true" [isAnimated]="true">
                    <accordion-group heading="{{lang==='ar-EG'?Contractor.NameAr:Contractor.NameEn}}" [isOpen]="i === 0 ? true : false" *ngFor="let Contractor of SelectedContractors; let i = index;">
                        <label for="inputState">{{ 'Business Items' | translate }}</label>
                        <select  [(ngModel)]="selectedContractorBusinessItemId" (change)="selectContractorWorkItem(Contractor)" id="inputState" class="form-control">
                            <option disabled selected value="0">{{'Business Item' | translate}}</option>
                            <option *ngFor="let item of Contractor.ContractorWorkItems" [value]="item.Id">{{lang==='ar-EG'?item.NameAr:item.NameEn}}</option>
                        </select>
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>{{'Parent Name'|translate}}</th>
                                    <th>{{'Name'|translate}}</th>
                                    <th>{{'Unit'|translate}}</th>
                                    <th>{{'Assay'|translate}}</th>
                                    <th>{{'Cost'|translate}}</th>
                                    <th>{{'Total'|translate}}</th>
                                    <th>{{'Delete'|translate}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of Contractor.SelectedContractorWorkItems">
                                    <td>{{item.Code}}</td>
                                    <td>{{ParentId>0?lang==='ar-EG'?item.ParentAr:item.ParentEn:lang==='ar-EG'?item.NameAr:item.NameEn}}</td>
                                    <td>{{lang==='ar-EG'?item.NameAr:item.NameEn}}</td>
                                    <td>{{lang==='ar-EG'?item.UnitAr:item.UnitEn}}</td>
                                    <td> <input type="number" placeholder="Assay" [(ngModel)]="item.Assay"/></td>
                                    <td> <input type="number" placeholder="Cost" [(ngModel)]="item.Cost"/></td>
                                    <td>{{item.Assay * item.Cost}}</td>
                                    <td>
                                        <a href="javascript:" (click)="deleteContractorWorkItem(Contractor,item)"><i class="simple-icon-trash"></i></a>
                                    </td>
                                </tr>
                            </tbody>
                           </table>
                    </accordion-group>
                  </accordion>
            </div>
      
        </div>
             </tab>
        <tab heading="{{'Business Items' | translate}}" class="card-body">
            <h6 class="mb-4">{{'Business Items' | translate}}</h6>
            <div class=" col-md-12">
                <label for="inputState">{{ 'Business Items' | translate }}</label>
                <select  [(ngModel)]="selectedBusinessItemId" (change)="selectWorkItem()" id="inputState" class="form-control">
                    <option disabled selected value="0">{{'Business Item' | translate}}</option>
                    <option *ngFor="let item of workItems" [value]="item.Id">{{lang==='ar-EG'?item.NameAr:item.NameEn}}</option>
                </select>
            </div>
            <div class=" col-md-12" *ngIf="selectedWorkItems">
               <table class="table">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>{{'Parent Name'|translate}}</th>
                        <th>{{'Name'|translate}}</th>
                        <th>{{'Unit'|translate}}</th>
                        <th>{{'Assay'|translate}}</th>
                        <th>{{'Cost'|translate}}</th>
                        <th>{{'Total'|translate}}</th>
                        <th>{{'Delete'|translate}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of selectedWorkItems">
                        <td>{{item.Code}}</td>
                        <td>{{ParentId>0?lang==='ar-EG'?item.ParentAr:item.ParentEn:lang==='ar-EG'?item.NameAr:item.NameEn}}</td>
                        <td>{{lang==='ar-EG'?item.NameAr:item.NameEn}}</td>
                        <td>{{lang==='ar-EG'?item.UnitAr:item.UnitEn}}</td>
                        <td> <input type="number" placeholder="Assay" [(ngModel)]="item.Assay"/></td>
                        <td>{{item.Cost}}</td>
                        <td>{{item.Assay * item.Cost}}</td>
                        <td>
                            <a href="javascript:" (click)="delectWorkItem(item)"><i class="simple-icon-trash"></i></a>
                        </td>
                    </tr>
                </tbody>
               </table>
            </div>
          </tab>
      </tabset>
    </div>
  </div>
</div>
