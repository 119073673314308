export class DataSorter {
    constructor() {
        //console.log('data presenter loaded');

    }
    OnInit() {
        //console.log('data presenter used');
    }
    sortData(sortKey: any, ogData: any[]):any[]{
        //console.log(sortKey);
        
        let result: any[] = [...ogData];
        let objKey = sortKey as keyof typeof ogData[0]
         result = result.sort((n1, n2) => {
             if (n1[sortKey] > n2[sortKey]) {
                 return 1;
             }
             if (n1[sortKey] < n2[sortKey]) {
                 return -1;
            }
             return 0;
         });
        //console.log("sorting by: " + sortKey);
        //console.log(result.map(item => item.Id));
        
        //console.log(sortKey);
        
        //console.log(result.map(x=>x[sortKey]));
        
        return result;
    }
}