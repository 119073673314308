<div style="text-align: center;" *ngIf="Form">
    <a class="btn btn-light" 
    routerLink="../../paid-checks"
        style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Back" |
        translate}}</a>
    <button 
        *ngIf="Form"
         id="smallModalSubmitButton"
        class="btn btn-secondary" 
        (click)="Save()"
        [disabled]="Form.invalid "
        style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{buttonName |
        translate}}</button>
        <button 
        *ngIf="Form"
        [disabled]="Form.invalid "
        id="smallModalSubmitButton"
        class="btn btn-secondary" (click)="addAndNew()"
        style="font-weight: bold; width: 250px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{buttonName+"AndNew" |
        translate}}</button>
  </div>
<Form [formGroup]="Form" *ngIf="Form ">
    <div class="card">
        <div class="form-group  Card_Padding">
                    <br>
    
       <div class="row">
        <div class="col-md-10">
        <h3>{{  'Issuing a check' | translate }}</h3>
    
        </div>
    
    
       </div>
       <div class="row">
        <div class="col-md-6">
            <label for="inputEmail4">{{ 'Amount' | translate }}</label>
            <!-- <input formControlName="Amount" type="number" id="inputEmail4" class="form-control"> -->
            <div class="input-group mb-3" >
                          
                <input type="number" min="0" name="Amount" class="form-control" [ngModelOptions]="{standalone: true}"
                formControlName="Amount" style=" margin: 0px;"
                placeholder='{{"Amount" | translate}}'

                >
                <ng-select appearance="outline" 
                name="CurrencyId" class="input-group-append" 
                formControlName="CurrencyId"
                placeholder='{{"Currency" | translate}}' #EmployeeId
                labelForId="txtycd" 
                *ngIf="curennciesLoaded"
                >
                <ng-option [value]="0">{{"Currency" | translate}}</ng-option>
                <ng-option *ngFor="let item of curenncies" [value]="item.Id">{{lang==='ar-EG'?item.Code:item.Code}}</ng-option>
            </ng-select>    
            </div>
            <div class="messages text-danger"
            *ngIf=" (Form.get('Amount').hasError('required') || Form.get('Amount').invalid) && ( Form.get('Amount').dirty ||  Form.get('Amount').touched)">
            {{"Amount required" | translate}}</div>
            <div class="messages text-danger"
            *ngIf=" (Form.get('CurrencyId').hasError('required') || Form.get('CurrencyId').invalid) && ( Form.get('CurrencyId').dirty ||  Form.get('CurrencyId').touched)">
            {{"Currency required" | translate}}</div>
        </div>
      
        <div class="col-md-6">
            <label for="inputEmail4">{{ 'Description' | translate }}</label>
            <textarea formControlName="Description" type="text" id="inputEmail4" class="form-control"></textarea>
        </div>
 
    </div>
            <br>
            <div class="row">
                <div class="col-md-6">
                    <label for="inputEmail4">{{ 'Release Date' | translate }}</label>
                    <input formControlName="ReleaseDate" [(ngModel)]="ReleaseDateString" type="Date" id="inputEmail4" class="form-control">
                    <div class="messages text-danger"
                    *ngIf=" (Form.get('ReleaseDate').hasError('required') || Form.get('ReleaseDate').invalid) && ( Form.get('ReleaseDate').dirty ||  Form.get('ReleaseDate').touched)">
                    {{"Release Date required" | translate}}</div>
                </div>
                <div class="col-md-6">
                    <label for="inputEmail4">{{ 'Due Date' | translate }}</label>
                    <input formControlName="DueDate" [(ngModel)]="DueDateString" type="Date" id="inputEmail4" class="form-control">
                    <div class="messages text-danger"
                    *ngIf=" (Form.get('DueDate').hasError('required') || Form.get('DueDate').invalid) && ( Form.get('DueDate').dirty ||  Form.get('DueDate').touched)">
                    {{"Due Date required" | translate}}</div>
                </div>
            
            </div>
        <br />
        <div class="row">
            <div class="col-md-6">
                <label for="inputEmail4">{{ 'Banck Name' | translate }}</label>
                <ng-select   formControlName="BanckId" (change)="changeBanck()"   [hideSelected]="true">
                  <ng-option selected desabled [value]="0">{{ "Please Choose"|translate}}</ng-option>
                  <ng-option *ngFor="let car of banckAccounts" [value]="car.Id">{{ car.Name}}</ng-option>
              </ng-select>
              <div class="messages text-danger"
              *ngIf=" (Form.get('BanckId').hasError('required') || Form.get('BanckId').invalid) && ( Form.get('BanckId').dirty ||  Form.get('BanckId').touched)">
              {{"Banck required" | translate}}</div>
            </div>
            <div class="col-md-6">
                <label for="inputEmail4">{{ 'Check Book Number' | translate }}</label>
                <ng-select   formControlName="CheckBookId" (change)="changeCheckBook()"   [hideSelected]="true">
                  <ng-option selected desabled [value]="0">{{ "Please Choose"|translate}}</ng-option>
                  <ng-option *ngFor="let car of checks" [value]="car.Id">{{ car.CheckBookNumber}}</ng-option>
              </ng-select>
              <div class="messages text-danger"
              *ngIf=" (Form.get('CheckBookId').hasError('required') || Form.get('CheckBookId').invalid) && ( Form.get('CheckBookId').dirty ||  Form.get('CheckBookId').touched)">
              {{"Check Book required" | translate}}</div>
            </div>
        
        </div>
        <br>
        <div class="row">
            <div class="col-md-6">
                <label for="inputEmail4">{{ 'Check Number' | translate }}</label>
                <input formControlName="CheckNumber" type="text" id="inputEmail4" class="form-control">
                <div class="messages text-danger"
                *ngIf=" (Form.get('CheckNumber').hasError('required') || Form.get('CheckNumber').invalid) && ( Form.get('CheckNumber').dirty ||  Form.get('CheckNumber').touched)">
                {{"Check Number required" | translate}}</div>
            </div>
            <div class="col-md-6">
                <label for="inputEmail4">{{ 'Received Account' | translate }}</label>
                <ng-select   formControlName="AccountReceivedId"    [hideSelected]="true">
                  <ng-option selected desabled [value]="0">{{ "Please Choose"|translate}}</ng-option>
                  <ng-option *ngFor="let car of AccountsNameModel" [value]="car.Id">{{ lang==='ar-EG'? car.NameAr:car.NameEn}}</ng-option>
              </ng-select>
              <div class="messages text-danger"
              *ngIf=" (Form.get('AccountReceivedId').hasError('required') || Form.get('AccountReceivedId').invalid) && ( Form.get('AccountReceivedId').dirty ||  Form.get('AccountReceivedId').touched)">
              {{"Account Received required" | translate}}</div>
            </div>
        
        </div>

    <br />
    <div class="row">
        <div class="col-md-6">
            <label for="inputEmail4">{{ 'The Name Is On The Check' | translate }}</label>
            <input formControlName="TheNameIsOnTheCheck" type="text" id="inputEmail4" class="form-control">
            <div class="messages text-danger"
            *ngIf=" (Form.get('TheNameIsOnTheCheck').hasError('required') || Form.get('TheNameIsOnTheCheck').invalid) && ( Form.get('TheNameIsOnTheCheck').dirty ||  Form.get('TheNameIsOnTheCheck').touched)">
            {{"The Name Is On The Check required" | translate}}</div>
        </div>
        <div class="col-md-6">
        </div>
    </div>

<br />
        </div>
    
    </div>
</Form>
<br />
<div class="card">
    <div class="form-group  Card_Padding">
        <br>
        <upload-paid-checks-file *ngIf="!picurl || picurl == ''" [(url)]=picurl [editmode]=editEnabled></upload-paid-checks-file>
        <table class="table" *ngIf="picurl && picurl!=''">
            <tbody>
             <tr >
                 <td></td>
                 <td><a href="{{picurl}}" target="_blank" style="cursor: pointer;" download="">{{"Download"|translate}}</a></td>
                 <td>
                     <a href="javascript:" (click)="clear()"><i class="simple-icon-trash"></i></a>
                 </td>
             </tr>
            </tbody>
         </table>
    </div>

</div>
