export class ContractFilesModel{
    Id?: number;
    ContractId?: number;
    FileUrl?: string;
    constructor(item ?: ContractFilesModel){
        item = item ? item : {};
        this.Id = item.Id ? item.Id : 0;
        this.ContractId = item.ContractId ? item.ContractId : 0;
        this.FileUrl = item.FileUrl ? item.FileUrl : '';
    }
}