<app-side-popup #sideModalRef>
  <div
    class="modal-content"
    style="
      width: 90%;
      text-align: center;
      margin-top: 20px;
      margin-left: auto;
      margin-right: auto;
    "
  >
    <div style="align-content: center; display: inline-block">
      <div
        class="modal-header"
        style="display: block; padding: 0px 0px 5px 0px; text-align: center"
      >
        <h1 id="dialog-child-name" class="modal-title">
          {{ tableEngine.currentFormMode + translationName | translate }}
        </h1>
      </div>
      <div class="modal-body">
        <form class="md-float-material form-material" [formGroup]="mainForm">
          <!-- <div class="form-group form-primary">
                        <label class="float-label push righ">{{"StoreDataId" | translate}}</label>
                        <input *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'" type="text" name="StoreDataId"
                            class="form-control" formControlName="StoreDataId"
                            placeholder='{{"StoreDataId" | translate}}'>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="StoreDataId">{{modelObject.StoreDataId}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('StoreDataId').hasError('required') || mainForm.get('StoreDataId').invalid) && ( mainForm.get('StoreDataId').dirty ||  mainForm.get('StoreDataId').touched)">
                            {{"StoreDataIdRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div> -->

          <div class="form-group form-primary">
            <label for="inputState">{{ "StoreData" | translate }}</label>
            <select
              formControlName="StoreDataId"
              id="inputState"
              class="form-control"
            >
              <option selected disabled value="0">
                {{ "StoreData" | translate }}
              </option>
              <option *ngFor="let item of StoreDataList" [value]="item.Id">
                {{ lang === "ar-EG" ? item.NameAr : item.NameEn }}
              </option>
            </select>
            <div
              class="messages text-danger"
              *ngIf="
                (mainForm.get('StoreDataId').hasError('required') ||
                  mainForm.get('StoreDataId').invalid) &&
                (mainForm.get('StoreDataId').dirty ||
                  mainForm.get('StoreDataId').touched)
              "
            >
              {{ "StoreNameRequired" | translate }}
            </div>
          </div>

          <div class="form-group form-primary">
            <label for="inputState">{{ "Items" | translate }}</label>
            <select
              formControlName="ItemsHeaderId"
              (change)="getPrice()"
              id="inputState"
              class="form-control"
            >
              <option selected disabled value="0">
                {{ "Items" | translate }}
              </option>
              <option *ngFor="let item of ItemsList" [value]="item.Id">
                {{ lang === "ar-EG" ? item.NameAr : item.NameEn }}
              </option>
            </select>
            <div
              class="messages text-danger"
              *ngIf="
                (mainForm.get('ItemsHeaderId').hasError('required') ||
                  mainForm.get('ItemsHeaderId').invalid) &&
                (mainForm.get('ItemsHeaderId').dirty ||
                  mainForm.get('ItemsHeaderId').touched)
              "
            >
              {{ "ItemsRequired" | translate }}
            </div>
          </div>

          <!-- <div class="form-group form-primary">
                        <label class="float-label push righ">{{"BranchCountry" | translate}}</label>
                        <div *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'">
                            <ng-select appearance="outline"
                                name="CountryId" formControlName="CountryId"
                                placeholder='{{"BranchCountry" | translate}}' #BranchCountrySelect labelForId="txtycd"
                                [searchable]="true">
                                <ng-option *ngFor="let country of countryList"
                                    [value]="country.Id">
                                    {{lang==='ar-EG'?country.NameAr:country.NameEn}}</ng-option>
                            </ng-select>
                        </div>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="CountryId">{{lang==='ar-EG'?getCountryById(modelObject.CountryId).NameAr:getCountryById(modelObject.CountryId).NameEn}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('CountryId').hasError('required') || mainForm.get('CountryId').invalid) && ( mainForm.get('CountryId').dirty ||  mainForm.get('CountryId').touched)">
                            {{"BranchCountryRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div> -->

          <div class="form-group form-primary">
            <label class="float-label push righ">{{
              "ItemPrice" | translate
            }}</label>
            <input
              *ngIf="
                tableEngine.currentFormMode === 'Add' ||
                tableEngine.currentFormMode === 'Edit'
              "
              type="text"
              name="UnitPrice"
              class="form-control"
              formControlName="UnitPrice"
              placeholder="{{ 'ItemPrice' | translate }}"
              readonly
            />
            <label
              *ngIf="tableEngine.currentFormMode === 'Info'"
              class="form-control"
              type="text"
              name="UnitPrice"
              >{{ modelObject.UnitPrice }}</label
            >
            <div
              class="messages text-danger"
              *ngIf="
                (mainForm.get('UnitPrice').hasError('required') ||
                  mainForm.get('UnitPrice').invalid) &&
                (mainForm.get('UnitPrice').dirty ||
                  mainForm.get('UnitPrice').touched)
              "
            >
              {{ "UnitPriceRequired" | translate }}
            </div>
            <span class="form-bar"></span>
          </div>

          <div class="form-group form-primary">
            <label class="float-label push righ">{{
              "Quantity" | translate
            }}</label>
            <input
              *ngIf="
                tableEngine.currentFormMode === 'Add' ||
                tableEngine.currentFormMode === 'Edit'
              "
              type="number"
              name="Quantity"
              class="form-control"
              formControlName="Quantity"
              placeholder="{{ 'Quantity' | translate }}"
              (change)="getTotal()"
            />
            <label
              *ngIf="tableEngine.currentFormMode === 'Info'"
              class="form-control"
              type="text"
              name="Quantity"
              >{{ modelObject.Quantity }}</label
            >
            <div
              class="messages text-danger"
              *ngIf="
                (mainForm.get('Quantity').hasError('required') ||
                  mainForm.get('Quantity').invalid) &&
                (mainForm.get('Quantity').dirty ||
                  mainForm.get('Quantity').touched)
              "
            >
              {{ "QuantityRequired" | translate }}
            </div>
            <span class="form-bar"></span>
          </div>
          <!-- <button class="btn btn-info text-center" (click)="">=</button> -->

          <div class="form-group form-primary">
            <label class="float-label push righ">{{
              "TotalValue" | translate
            }}</label>
            <input
              *ngIf="
                tableEngine.currentFormMode === 'Add' ||
                tableEngine.currentFormMode === 'Edit'
              "
              type="text"
              name="TotalValue"
              class="form-control"
              formControlName="TotalValue"
              placeholder="{{ 'TotalValue' | translate }}"
              readonly
            />
            <label
              *ngIf="tableEngine.currentFormMode === 'Info'"
              class="form-control"
              type="text"
              name="TotalValue"
              >{{ modelObject.TotalValue }}
            </label>
            <div
              class="messages text-danger"
              *ngIf="
                (mainForm.get('TotalValue').hasError('required') ||
                  mainForm.get('TotalValue').invalid) &&
                (mainForm.get('TotalValue').dirty ||
                  mainForm.get('TotalValue').touched)
              "
            >
              {{ "TotalValueRequired" | translate }}
            </div>
            <span class="form-bar"></span>
          </div>

          <div style="margin-left: 5px; text-align: center; margin-top: 30px">
            <button
              *ngIf="tableEngine.currentFormMode === 'Add'"
              [disabled]="
                mainForm.invalid || tableEngine.currentAction === 'loading'
              "
              id="smallModalSubmitButton"
              class="btn btn-secondary"
              (click)="addObject()"
              style="font-weight: bold; width: 100%; text-align: center"
            >
              {{ "Add" | translate }}
            </button>
            <button
              *ngIf="tableEngine.currentFormMode === 'Edit'"
              [disabled]="
                mainForm.invalid || tableEngine.currentAction === 'loading'
              "
              id="smallModalSubmitButton"
              class="btn btn-secondary"
              (click)="editObject()"
              style="font-weight: bold; width: 100%; text-align: center"
            >
              {{ "Edit" | translate }}
            </button>
            <button
              class="btn btn-light"
              (click)="sideModalRef.modalRef.hide()"
              style="
                font-weight: bold;
                width: 100%;
                text-align: center;
                margin-top: 10px;
              "
            >
              {{ "Close" | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</app-side-popup>

<div
  bsModal
  #delModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-sm">
    <div class="modal-content" style="border: 3px solid red">
      <button
        type="button"
        class="close pull-right"
        aria-label="Close"
        (click)="delModal.hide()"
        style="
          margin-right: 20px;
          margin-left: 20px;
          margin-top: 10px;
          width: 20px;
          display: inline;
        "
      >
        <span aria-hidden="true">&times;</span>
      </button>
      <div
        class="modal-header"
        style="display: inline; padding: 0px 0px 5px 0px"
      >
        <h4
          id="dialog-child-name"
          class="modal-title pull-left"
          style="text-align: center; color: red"
        >
          {{ "ConfirmDel" + translationName | translate }}
        </h4>
      </div>
      <div class="modal-body">
        <div
          class="form-group form-primary"
          *ngIf="
            tableEngine.deletionList.length < tableEngine.ogData.length ||
            tableEngine.deletionList.length === 1
          "
          style="text-align: center"
        >
          <div
            *ngFor="let item of tableEngine.deletionList"
            style="text-align: center"
          >
            <label type="text"
              >- {{ lang === "ar-EG" ? item.NameAr : item.NameEn }}</label
            >
            <span class="form-bar"></span>
          </div>
        </div>
        <div
          class="form-group form-primary"
          style="text-align: center"
          *ngIf="
            tableEngine.deletionList.length > 1 &&
            tableEngine.deletionList.length === tableEngine.ogData.length
          "
        >
          <label type="text">
            <h1 style="font-weight: bolder; text-align: center">
              {{ translationName + "DeleteAll" | translate }}
            </h1>
          </label>
          <span class="form-bar"></span>
        </div>

        <div style="margin-left: 5px; text-align: center; margin-top: 30px">
          <button
            class="btn btn-danger"
            id="smallModalDeleteButton"
            (click)="deleteObject()"
            [disabled]="tableEngine.currentAction === 'loading'"
            style="font-weight: bold; width: 100%; text-align: center"
          >
            {{ "Delete" | translate }}
          </button>
          <button
            class="btn btn-light"
            (click)="delModal.hide()"
            style="
              font-weight: bold;
              width: 100%;
              text-align: center;
              margin-top: 10px;
            "
          >
            {{ "Close" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="currentScreen === 'Form'" class="FormScreen">
  <a (click)="currentScreen = 'Table'">back</a>
</div>

<div *ngIf="currentScreen === 'Table'" class="FormScreen" class="tableScreen">
  <div id="divtop" style="display: block; height: 47px; width: 100%">
    <div
      id="divsearchmiddle"
      style="
        display: inline-block;
        width: 70%;
        height: inherit;
        text-align: center;
        padding: 0px;
        margin: 0px;
      "
    >
      <div
        class="search"
        style="display: inline-block; margin-left: 50px; margin-right: 50px"
      >
        <div class="d-inline-block">
          <div
            class="search-sm d-inline-block mr-1 mb-1 align-top"
            style="width: 600px"
          >
            <input
              type="search"
              [(ngModel)]="tableEngine.SearchTerm"
              (keyup)="tableEngine.changeFilter()"
              (change)="tableEngine.changeFilter()"
              class="form-control input-sm full-data-search"
              placeholder="{{ translationName + 'Search' | translate }}"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      id="divsearchleft"
      style="display: inline-block; width: 30%; height: inherit"
    >
      <div style="display: block; height: 30%">&nbsp;</div>
      <div
        class="search"
        style="display: block; height: 70%; text-align: center"
      >
        <button
          class="btn btn-primary d-inline-block"
          style="
            font-weight: bold;
            text-align: center;
            padding: 0px;
            width: 150px;
            height: 30px;
          "
          (click)="showModal(null, 'Add')"
          [disabled]="tableEngine.currentAction === 'loading'"
        >
          {{ "Add" + translationName | translate }}
        </button>
      </div>
    </div>
  </div>

  <div id="divmiddle" style="display: block; margin-top: 5px">
    <div style="width: 100%">
      <div>
        <div class="card" style="min-width: 900px !important">
          <div class="card-body">
            <table class="table table-hover" style="margin: unset !important">
              <thead class="no-border-top">
                <th style="text-align: center; padding: 5px">
                  <div class="text-zero" style="display: inline-block">
                    <div class="top-right-button-container2 btn-group" dropdown>
                      <div class="btn btn-primary pl-4 pr-0 check-button">
                        <label
                          class="custom-control custom-checkbox mb-0 d-inline-block"
                          style="width: 10px"
                        >
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            [checked]="tableEngine.selectAllState === 'checked'"
                            [indeterminate]="
                              tableEngine.selectAllState === 'indeterminate'
                            "
                            (change)="selectAll($event)"
                          />
                          <span class="custom-control-label">&nbsp;</span>
                        </label>
                      </div>
                      <button
                        id="button-split"
                        type="button"
                        dropdownToggle
                        style="width: 10px"
                        class="btn btn-primary dropdown-toggle dropdown-toggle-split"
                        aria-controls="dropdown-split"
                      >
                        <span class="caret"></span>
                      </button>
                      <ul
                        id="dropdown-split"
                        *dropdownMenu
                        class="dropdown-menu dropdown-menu-right"
                        role="menu"
                        aria-labelledby="button-split"
                      >
                        <li role="menuitem">
                          <a class="dropdown-item">
                            <button
                              class="btn btn-danger d-inline-block"
                              style="
                                font-weight: bold;
                                text-align: center;
                                padding: 0px;
                                width: 150px;
                                height: 30px;
                              "
                              (click)="tableEngine.showDelModal('delMulti')"
                              [disabled]="
                                tableEngine.selected.length === 0 ||
                                tableEngine.currentAction === 'loading'
                              "
                            >
                              {{ "DeleteSelected" | translate }}
                            </button></a
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </th>
                <th
                  class="sorticon"
                  (click)="changeSort(tableEngine.getSort('StoreDataId'))"
                  [ngClass]="
                    itemOrder.label === 'StoreDataId'
                      ? 'sortkey sorticon'
                      : 'sorticon'
                  "
                >
                  <div
                    [ngClass]="{
                      'iconsminds-up---down': itemOrder.label != 'StoreDataId',
                      'iconsminds-down':
                        itemOrder.label === 'StoreDataId' &&
                        tableEngine.orderAsc === true,
                      'iconsminds-up':
                        itemOrder.label === 'StoreDataId' &&
                        tableEngine.orderAsc === false
                    }"
                  ></div>
                </th>
                <th
                  class="sortOption"
                  (click)="changeSort(tableEngine.getSort('StoreDataId'))"
                  [ngClass]="{ sortkey: itemOrder.label === 'StoreDataId' }"
                >
                  {{ "StoreData" | translate }}
                </th>
                <th
                  class="sorticon"
                  (click)="changeSort(tableEngine.getSort('ItemsHeaderId'))"
                  [ngClass]="
                    itemOrder.label === 'ItemsHeaderId'
                      ? 'sortkey sorticon'
                      : 'sorticon'
                  "
                >
                  <div
                    [ngClass]="{
                      'iconsminds-up---down':
                        itemOrder.label != 'ItemsHeaderId',
                      'iconsminds-down':
                        itemOrder.label === 'ItemsHeaderId' &&
                        tableEngine.orderAsc === true,
                      'iconsminds-up':
                        itemOrder.label === 'ItemsHeaderId' &&
                        tableEngine.orderAsc === false
                    }"
                  ></div>
                </th>
                <th
                  class="sortOption"
                  (click)="changeSort(tableEngine.getSort('ItemsHeaderId'))"
                  [ngClass]="{ sortkey: itemOrder.label === 'ItemsHeaderId' }"
                >
                  {{ "Items" | translate }}
                </th>
                <th
                  class="sorticon"
                  (click)="changeSort(tableEngine.getSort('UnitPrice'))"
                  [ngClass]="
                    itemOrder.label === 'UnitPrice'
                      ? 'sortkey sorticon'
                      : 'sorticon'
                  "
                >
                  <div
                    [ngClass]="{
                      'iconsminds-up---down': itemOrder.label != 'UnitPrice',
                      'iconsminds-down':
                        itemOrder.label === 'UnitPrice' &&
                        tableEngine.orderAsc === true,
                      'iconsminds-up':
                        itemOrder.label === 'UnitPrice' &&
                        tableEngine.orderAsc === false
                    }"
                  ></div>
                </th>
                <th
                  class="sortOption"
                  (click)="changeSort(tableEngine.getSort('UnitPrice'))"
                  [ngClass]="{ sortkey: itemOrder.label === 'UnitPrice' }"
                >
                  {{ "UnitPrice" | translate }}
                </th>
                <th
                  class="sorticon"
                  (click)="changeSort(tableEngine.getSort('Quantity'))"
                  [ngClass]="
                    itemOrder.label === 'Quantity'
                      ? 'sortkey sorticon'
                      : 'sorticon'
                  "
                >
                  <div
                    [ngClass]="{
                      'iconsminds-up---down': itemOrder.label != 'Quantity',
                      'iconsminds-down':
                        itemOrder.label === 'Quantity' &&
                        tableEngine.orderAsc === true,
                      'iconsminds-up':
                        itemOrder.label === 'Quantity' &&
                        tableEngine.orderAsc === false
                    }"
                  ></div>
                </th>
                <th
                  class="sortOption"
                  (click)="changeSort(tableEngine.getSort('Quantity'))"
                  [ngClass]="{ sortkey: itemOrder.label === 'Quantity' }"
                >
                  {{ "Quantity" | translate }}
                </th>
                <th
                  class="sorticon"
                  (click)="changeSort(tableEngine.getSort('TotalValue'))"
                  [ngClass]="
                    itemOrder.label === 'TotalValue'
                      ? 'sortkey sorticon'
                      : 'sorticon'
                  "
                >
                  <div
                    [ngClass]="{
                      'iconsminds-up---down': itemOrder.label != 'TotalValue',
                      'iconsminds-down':
                        itemOrder.label === 'TotalValue' &&
                        tableEngine.orderAsc === true,
                      'iconsminds-up':
                        itemOrder.label === 'TotalValue' &&
                        tableEngine.orderAsc === false
                    }"
                  ></div>
                </th>
                <th
                  class="sortOption"
                  (click)="changeSort(tableEngine.getSort('TotalValue'))"
                  [ngClass]="{ sortkey: itemOrder.label === 'TotalValue' }"
                >
                  {{ "TotalValue" | translate }}
                </th>
                <!-- <th class="sorticon" (click)="tableEngine.changeSort(tableEngine.getSort('BranchNotableMark'))" [ngClass]="(itemOrder.label==='BranchNotableMark')?'sortkey sorticon':'sorticon'">
                            <div [ngClass]="{'iconsminds-up---down': (itemOrder.label!='BranchNotableMark'), 
                            'iconsminds-down': ((itemOrder.label==='BranchNotableMark') && tableEngine.orderAsc === true),
                            'iconsminds-up': ((itemOrder.label==='BranchNotableMark') && tableEngine.orderAsc === false)}"></div></th>
                        <th class="sortOption" (click)="tableEngine.changeSort(tableEngine.getSort('BranchNotableMark'))" 
                        [ngClass]="{'sortkey': (itemOrder.label==='BranchNotableMark')}">
                                {{"BranchNotableMark" | translate}}
                        </th> -->
                <!-- <th class="sorticon" (click)="tableEngine.changeSort(tableEngine.getSort('BranchPostalCode'))" [ngClass]="(itemOrder.label==='BranchPostalCode')?'sortkey sorticon':'sorticon'">
                            <div [ngClass]="{'iconsminds-up---down': (itemOrder.label!='BranchPostalCode'), 
                            'iconsminds-down': ((itemOrder.label==='BranchPostalCode') && tableEngine.orderAsc === true),
                            'iconsminds-up': ((itemOrder.label==='BranchPostalCode') && tableEngine.orderAsc === false)}"></div></th>
                        <th class="sortOption" (click)="tableEngine.changeSort(tableEngine.getSort('BranchPostalCode'))" 
                        [ngClass]="{'sortkey': (itemOrder.label==='BranchPostalCode')}">
                                {{"BranchPostalCode" | translate}}
                        </th> -->
                <th></th>
                <th [colSpan]="2" style="text-align: center">
                  <div
                    id="displayOptions"
                    style="
                      display: inline;
                      margin-top: 10px;
                      margin-left: 5px;
                      margin-right: 5px;
                    "
                  >
                    <span
                      class="text-muted text-small"
                      style="width: 50px; height: 40px"
                    >
                      {{ "ResultCount" | translate }}
                    </span>
                    <span dropdown class="d-inline-block position-relative">
                      <button
                        class="btn btn-outline-dark btn-xs dropdown-toggle"
                        type="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        dropdownToggle
                      >
                        {{ tableEngine.itemsPerPage }}
                      </button>
                      <div
                        class="dropdown-menu dropdown-menu-right dropdown-menu-sm text-right"
                        *dropdownMenu
                      >
                        <li
                          role="menuitem"
                          *ngFor="let item of tableEngine.itemOptionsPerPage"
                        >
                          <a
                            [ngClass]="
                              item === tableEngine.itemsPerPage
                                ? 'dropdown-item active'
                                : 'dropdown-item'
                            "
                            (click)="tableEngine.paginateData(item, 1)"
                            >{{ item }}</a
                          >
                        </li>
                      </div>
                    </span>
                  </div>
                </th>
              </thead>
              <tbody>
                <tr *ngFor="let obj of paginatedData">
                  <td style="text-align: center">
                    <div
                      class="itemCheck mb-0 custom-checkbox custom-control"
                      style="display: inline-block"
                    >
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        [id]="'customCheck' + i"
                        [checked]="isSelected(obj)"
                        (change)="onSelect(obj)"
                      />
                      <label
                        class="custom-control-label"
                        [for]="'customCheck' + i"
                      ></label>
                    </div>
                  </td>
                  <!-- <td colspan="2">{{lang==='ar-EG'?obj.NameAr:obj.NameEn}}</td> -->
                  <td colspan="2">
                    {{
                      lang === "ar-EG"
                        ? getStoreDataById(obj.StoreDataId).NameAr
                        : getStoreDataById(obj.StoreDataId).NameEn
                    }}
                  </td>
                  <td colspan="2">
                    {{
                      lang === "ar-EG"
                        ? getItemsById(obj.ItemsHeaderId).NameAr
                        : getItemsById(obj.ItemsHeaderId).NameEn
                    }}
                  </td>

                  <td colspan="2">{{ obj.UnitPrice }}</td>
                  <td colspan="2">{{ obj.Quantity }}</td>
                  <td colspan="2">{{ obj.TotalValue }}</td>
                  <!-- <td colspan="2">{{obj.NotableMark}}</td> -->
                  <!-- <td colspan="2">{{obj.PostalCode}}</td> -->
                  <td style="text-align: center">
                    <i
                      class="simple-icon-info"
                      (click)="showModal(obj.Id, 'Info')"
                      tooltip="{{ 'Info' | translate }}"
                      placement="auto"
                      style="
                        font-size: 20px;
                        font-weight: bolder;
                        cursor: pointer;
                        vertical-align: middle;
                        color: #17a2b8;
                      "
                    ></i>
                  </td>
                  <td style="text-align: center">
                    <i
                      class="simple-icon-pencil"
                      (click)="showModal(obj.Id, 'Edit')"
                      tooltip="{{ 'Edit' | translate }}"
                      placement="auto"
                      style="
                        font-size: 20px;
                        font-weight: bolder;
                        cursor: pointer;
                        vertical-align: middle;
                        color: #ffc107;
                      "
                    ></i>
                  </td>
                  <td style="text-align: center">
                    <i
                      class="simple-icon-close"
                      (click)="tableEngine.showDelModal(obj.Id)"
                      tooltip="{{ 'Delete' | translate }}"
                      placement="auto"
                      style="
                        font-size: 20px;
                        font-weight: bolder;
                        cursor: pointer;
                        vertical-align: middle;
                        color: #dc3545;
                      "
                    ></i>
                  </td>
                  <!-- <td><button class="btn btn-info text-center" (click)="showModal(obj.Id, 'Info')"
                                    [disabled]="tableEngine.currentAction==='loading'" style="font-weight: bold; width: 100%;">{{"More"
                                    | translate}}</button></td>
                            <td><button class="btn btn-outline-warning text-center" (click)="showModal(obj.Id, 'Edit')"
                                    [disabled]="tableEngine.currentAction==='loading'" style="font-weight: bold; width: 100%;">{{"Edit"
                                    | translate}}</button></td>
                            <td><button class="btn btn-outline-danger text-center" (click)="showDelModal(obj.Id)"
                                    [disabled]="tableEngine.currentAction==='loading'" style="font-weight: bold; width: 100%">{{"Delete"
                                    | translate}}</button></td> -->
                </tr>
              </tbody>
            </table>
            <div id="divsearchmiddle" style="display: inline-block; width: 100%; height: 100%;">
                <div style="text-align: center;">
                    <!-- <div class="centerside" style="display: inline-block; width: 15%; height: 100%; ">
                        &nbsp;

                    </div> -->
                    <div class="centercenter"
                        style="display: inline-block; width: 70%; height: 100%;  text-align: center;">

                        <bdo dir="ltr">
                            <pagination class="pagination-sm2" style="padding: 0px, 0px, 0px, 0px; margin: 0px, 0px, 0px, 0px;
                            display: inline-block;" [(ngModel)]="currentPage" [boundaryLinks]="true"
                                [totalItems]="tableEngine.filteredData.length"
                                [itemsPerPage]="tableEngine.itemsPerPage"
                                [customFirstTemplate]="firstTemplate" [customLastTemplate]="lastTemplate"
                                [customPreviousTemplate]="prevTemplate" [customNextTemplate]="nextTemplate"
                                [maxSize]="5" (pageChanged)="tableEngine.changePage($event)">
                            </pagination>
                        </bdo>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="divbottom" style="display: block; height: 47px">
    <div id="divsearchright" style="float: right; width: 33.33%; padding: 0px">
      &nbsp;
    </div>

    <!-- <div
      id="divsearchmiddle"
      style="display: inline-block; width: 33.33%; height: 100%"
    >
      <div style="text-align: center">
        <bdo dir="ltr">
          <pagination
            class="pagination-sm2"
            style="
              padding: 0px, 0px, 0px, 0px;
              margin: 0px, 0px, 0px, 0px;
              display: inline-block;
            "
            [(ngModel)]="currentPage"
            [boundaryLinks]="true"
            [totalItems]="tableEngine.filteredData.length"
            [itemsPerPage]="tableEngine.itemsPerPage"
            [customFirstTemplate]="firstTemplate"
            [customLastTemplate]="lastTemplate"
            [customPreviousTemplate]="prevTemplate"
            [customNextTemplate]="nextTemplate"
            [maxSize]="5"
            (pageChanged)="tableEngine.changePage($event)"
          >
          </pagination>
        </bdo>
      </div>
    </div> -->
    <div id="divbottom" style="display: block; height: 47px;">

        <div id="divsearchright" style="float: right;  width: 33.33%; padding: 0px;">&nbsp;</div>


        <div id="divsearchleft" style="float: left; width: 33.33%; height: 100%;">&nbsp;</div>

  </div>
</div>

<div></div>

<ng-template
  #nextTemplate
  let-disabled="disabled"
  let-currentPage="currentPage"
>
  <i *ngIf="lang === 'en-US'" class="simple-icon-arrow-right"></i>
  <i *ngIf="lang === 'ar-EG'" class="simple-icon-arrow-left"></i>
</ng-template>

<ng-template
  #prevTemplate
  let-disabled="disabled"
  let-currentPage="currentPage"
>
  <i *ngIf="lang === 'en-US'" class="simple-icon-arrow-left"></i>
  <i *ngIf="lang === 'ar-EG'" class="simple-icon-arrow-right"></i>
</ng-template>

<ng-template
  #lastTemplate
  let-disabled="disabled"
  let-currentPage="currentPage"
>
  <i class="simple-icon-control-end"></i>
</ng-template>

<ng-template
  #firstTemplate
  let-disabled="disabled"
  let-currentPage="currentPage"
>
  <i class="simple-icon-control-start"></i>
</ng-template>

