

import { UserRole } from '../app/shared/auth.roles';

export const environment = {
  production: false,

  SCARF_ANALYTICS : false,
  adminRoot: '/app',
  // apiUrl: 'http://92.205.19.224:8082',
  // apiUrl: 'https://localhost:5001',
  apiUrl: 'http://apis.aio.systems',
  
  defaultMenuType: 'menu-default',
  subHiddenBreakpoint: 1440,
  menuHiddenBreakpoint: 768,
  themeColorStorageKey: 'aio-themecolor',
  isMultiColorActive: true,
  //K
  ///////////////////////////////////////////////////////
  basePath: '/app/aio',
  invoicesPath: '/app/invoices',
  accountPath: '/app/accounts',
  membershipsPath: '/app/memberships',
  healthCarePath: '/app/health-care',
  myPharmacyPath: '/app/my-pharmacy',
  myCouncilAndMembers:'/app/council-affairs',
  hrPath: '/app/HR',
  InventoryPath: '/app/inventory',
  // AccountingPath: '/app/invoices',
  // baseApiUrl: 'http://92.205.19.224:8082/api/',
  // baseApiUrl: 'https://localhost:5001/api/',
  baseApiUrl: 'http://apis.aio.systems/api/',
  companyApi: 'defaultapi',
  /*
  Color Options:
  'light.blueyale', 'light.blueolympic', 'light.bluenavy', 'light.greenmoss',
  'light.greenlime', 'light.yellowgranola', 'light.greysteel', 'light.orangecarrot',
  'light.redruby', 'light.purplemonster'

  'dark.blueyale', 'dark.blueolympic', 'dark.bluenavy', 'dark.greenmoss',
  'dark.greenlime', 'dark.yellowgranola', 'dark.greysteel', 'dark.orangecarrot',
  'dark.redruby', 'dark.purplemonster'
  */
  defaultColor: 'light.blueyale',
  isDarkSwitchActive: true,
  defaultDirection: 'ltr',
  themeRadiusStorageKey: 'aio-themeradius',
  isAuthGuardActive: true,
  defaultRole: UserRole.Admin,
  firebase: {
    apiKey: 'AIzaSyCqoNLB_jTw4nncO12qR-eDH9gAeWiZVaw',
    authDomain: 'aio-angular-login.firebaseapp.com',
    databaseURL: 'https://aio-angular-login.firebaseio.com',
    projectId: 'aio-angular-login',
    storageBucket: 'aio-angular-login.appspot.com',
    messagingSenderId: '16217062888',
    appId: '1:16217062888:web:6b08232ca0c9662fedb85d',
    measurementId: 'G-8ETT79WRRN'
  }
};
