export class PaidChecksModel{
  Id                   ?: number;
  Amount               ?: number;
  CurrencyId           ?: number;
  Description          ?: string;
  ReleaseDate          ?: Date;
  DueDate              ?: Date;
  CheckNumber          ?: string;
  AccountReceivedId    ?: number;
  CheckBookId          ?: number;
  BanckId?:number;
  TheNameIsOnTheCheck  ?: string;
  FileUrl              ?: string;
  IsCollection         ?: boolean;
  CollectionDate       ?: Date;
  CollectionDescription?: string;
  CollectionBy         ?: string;
  IsRejected           ?: boolean;
  RejectedDate         ?: Date;
  RejectedDescription  ?: string;
  RejectedBy           ?: string;
  constructor(item?:PaidChecksModel){
    item =item ? item : {};
    this.Id                    = item.Id                    ? item.Id                    : 0;
    this.Amount                = item.Amount                ? item.Amount                : 0;
    this.CurrencyId            = item.CurrencyId            ? item.CurrencyId            : 0;
    this.BanckId            = item.BanckId            ? item.BanckId            : 0;
    this.Description           = item.Description           ? item.Description           : '';
    this.ReleaseDate           = item.ReleaseDate           ? item.ReleaseDate           : new Date();
    this.DueDate               = item.DueDate               ? item.DueDate               : new Date();
    this.CheckNumber           = item.CheckNumber           ? item.CheckNumber           : '';
    this.AccountReceivedId     = item.AccountReceivedId     ? item.AccountReceivedId     : 0;
    this.CheckBookId           = item.CheckBookId           ? item.CheckBookId           : 0;
    this.TheNameIsOnTheCheck   = item.TheNameIsOnTheCheck   ? item.TheNameIsOnTheCheck   : '';
    this.FileUrl               = item.FileUrl               ? item.FileUrl               : '';
    this.IsCollection          = item.IsCollection          ? item.IsCollection          : false;
    this.CollectionDate        = item.CollectionDate        ? item.CollectionDate        : new Date();
    this.CollectionDescription = item.CollectionDescription ? item.CollectionDescription : '';
    this.CollectionBy          = item.CollectionBy          ? item.CollectionBy          : '';
    this.IsRejected            = item.IsRejected            ? item.IsRejected            : false;
    this.RejectedDate          = item.RejectedDate          ? item.RejectedDate          : new Date();
    this.RejectedDescription   = item.RejectedDescription   ? item.RejectedDescription   : '';
    this.RejectedBy            = item.RejectedBy            ? item.RejectedBy            : '';
  }
}