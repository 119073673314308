export class ItemModel{
    Id?:number;
    NameAr?:string;
    NameEn?:string;

    Code ?:string;

    TaxApiCode ?:string;
    Cost?:number;
    Stock?:number;
    Total?:number;
    
    CodingType ?:string;
    Description ?:string;
    ProductTypeAr ?:string;
    ProductTypeEn ?:string;
    ProductTypeId ?:number;
    CodingUnitId ?: number;
    MeasurCode?:string;
    IsSendToTaxes?:boolean;
    constructor(obj?:ItemModel){
        obj = obj? obj:{};
        this.Id = obj.Id? obj.Id:0;
        this.NameAr = obj.NameAr? obj.NameAr:'';
        this.NameEn = obj.NameEn? obj.NameEn:'';
        this.Code = obj.Code? obj.Code:'';
        this.TaxApiCode = obj.TaxApiCode? obj.TaxApiCode:'';
        this.Cost = obj.Cost? obj.Cost:0;
        this.Stock = obj.Stock? obj.Stock:0;
        this.Total = obj.Total? obj.Total:0;
        this.MeasurCode = obj.MeasurCode? obj.MeasurCode:'';
        this.Description = obj.Description? obj.Description:'';
        this.ProductTypeAr = obj.ProductTypeAr? obj.ProductTypeAr:'';
        this.ProductTypeEn = obj.ProductTypeEn? obj.ProductTypeEn:'';
        this.ProductTypeEn = obj.ProductTypeEn? obj.ProductTypeEn:'';
        this.ProductTypeId = obj.ProductTypeId? obj.ProductTypeId:0;
        this.CodingUnitId = obj.CodingUnitId? obj.CodingUnitId:0;
        this.IsSendToTaxes = obj.IsSendToTaxes? obj.IsSendToTaxes:false;
    }
}