export class RestaurantMenuItemModel {
    Id?: number;
    Code?: number;
    Name?: string;
    CategoryId?: number;
    CategoryName?: string;
    Cost?: number;
    ImageUrl?: string;
    constructor(obj?: RestaurantMenuItemModel) {
        obj = obj ? obj : {};
        this.Id = obj.Id ? obj.Id : 0;
        this.Code = obj.Code ? obj.Code : 0;
        this.Name = obj.Name ? obj.Name : '';
        this.CategoryId = obj.CategoryId ? obj.CategoryId : 0;
        this.CategoryName = obj.CategoryName ? obj.CategoryName : '';
        this.Cost = obj.Cost ? obj.Cost : 0;
        this.ImageUrl = obj.ImageUrl ? obj.ImageUrl : '';
    }
}