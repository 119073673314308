import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PurchaseQuotationDetailsModel } from "./PurchaseQuotationDetailsModel";

@Injectable({
  providedIn: 'root'
})
export class PurchaseQuotationService {
    constructor(private http: HttpClient) {
  
    }
    debugData: PurchaseQuotationDetailsModel [] = [];
    // debugData: PurchaseQuotationDetailsModel [] = [
    //   {
    //     Id:1, Code:1001, Date:new Date(2021, 0, 13), ExpirationDate:new Date(2021, 5, 13),
    //     SupplierId:1, SupplierNameAr:'احمد', SupplierNameEn:'Ahmad', ShippingAddress1: "address11", ShippingAddress2:"adderss12",
    //     Description:"desc1\nquotation1\n1",
    //     Items: [
    //       { Id:1, PurchaseQuotationId: 1, ItemId:1, ItemNameAr: "منتج 1", ItemNameEn: "Item 1", 
    //       UnitId:1, UnitNameAr:"وحدة 1", UnitNameEn:"Unit 1", Description:"item\ndescription\n1", 
    //       UnitCost:1000, Quantity:1, TotalPrice:1000, DiscountValue:1, DiscountIsRate:false, NetPrice:999,
    //       Taxes:[
    //         { Id:1, PurchaseQuotationItemId: 1, TaxId: 1, TaxNameAr: "ضريبة 1", TaxNameEn: "Tax 1", TaxIsRate:true, TaxValue:5 },
    //         { Id:2, PurchaseQuotationItemId: 1, TaxId: 2, TaxNameAr: "ضريبة 2", TaxNameEn: "Tax 2", TaxIsRate:false, TaxValue:200 },
    //       ],
    //       TotalTaxValues:249.95, GrossPrice: 1248.95 },

    //       { Id:2, PurchaseQuotationId: 1, ItemId:2, ItemNameAr: "منتج 2", ItemNameEn: "Item 2", 
    //       UnitId:2, UnitNameAr:"وحدة 2", UnitNameEn:"Unit 2", Description:"item\ndescription\n2", 
    //       UnitCost:1000, Quantity:2, TotalPrice:2000, DiscountValue:2, DiscountIsRate:true, NetPrice:1960,
    //       Taxes:[
    //         { Id:3, PurchaseQuotationItemId: 2, TaxId: 2, TaxNameAr: "ضريبة 2", TaxNameEn: "Tax 2", TaxIsRate:false, TaxValue:200 },
    //         { Id:4, PurchaseQuotationItemId: 2, TaxId: 3, TaxNameAr: "ضريبة 3", TaxNameEn: "Tax 3", TaxIsRate:true, TaxValue:10 },
    //       ],
    //       TotalTaxValues:396, GrossPrice: 2356 },
    //     ],
    //     TotalItemPrices: 3000, TotalItemDiscounts: 41, TotalItemNetPrices: 2959, TotalItemTaxes: 645.95,
    //     TotalItemGrossPrices: 3604.95,
    //     Services: [
    //       { Id: 1, PurchaseQuotationId: 1, NameAr: "خدمة تحويل فودافون كاش", NameEn:"Vodafone Cash fee", Value: 5, IsRate: false },
    //       { Id: 2, PurchaseQuotationId: 1, NameAr: "كارتة طريق الرحاب", NameEn:"Al-Rihab road toll", Value: 200, IsRate: false },
    //     ],
    //     TotalServices: 205, DiscountValue:100, DiscountIsRate: false, ShippingFees: 1000,
    //     TotalPurchaseQuotationValue: 4109.95,
    //     Files: [
    //       {Id: 1, PurchaseQuotationId: 1, FileUrl: "ID-Card.pdf"},
    //       {Id: 2, PurchaseQuotationId: 1, FileUrl: "Vodafone-Cash-Transfer-Image.jpg"},
    //     ],
    //   },

    //   {
    //     Id:2, Code:1002, Date:new Date(2022, 1, 13), ExpirationDate:new Date(2021, 6, 13),
    //     SupplierId:2, SupplierNameAr:'فواز', SupplierNameEn:'Fawaz', ShippingAddress1: "address21", ShippingAddress2:"adderss22",
    //     Description:"desc2\nquotation2\n2",
    //     Items: [
    //       { Id:3, PurchaseQuotationId: 2, ItemId:2, ItemNameAr: "منتج 2", ItemNameEn: "Item 2", 
    //       UnitId:2, UnitNameAr:"وحدة 2", UnitNameEn:"Unit 2", Description:"item\ndescription\n3", 
    //       UnitCost:1000, Quantity:3, TotalPrice:3000, DiscountValue:3, DiscountIsRate:true, NetPrice:2910,
    //       Taxes:[
    //         { Id:3, PurchaseQuotationItemId: 3, TaxId: 3, TaxNameAr: "ضريبة 3", TaxNameEn: "Tax 3", TaxIsRate:true, TaxValue:10 },
    //         { Id:4, PurchaseQuotationItemId: 3, TaxId: 1, TaxNameAr: "ضريبة 1", TaxNameEn: "Tax 1", TaxIsRate:true, TaxValue:5 },
    //       ],
    //       TotalTaxValues:436.5, GrossPrice: 3346.5 },

    //       { Id:4, PurchaseQuotationId: 2, ItemId:1, ItemNameAr: "منتج 1", ItemNameEn: "Item 1", 
    //       UnitId:1, UnitNameAr:"وحدة 1", UnitNameEn:"Unit 1", Description:"item\ndescription\n4", 
    //       UnitCost:1000, Quantity:4, TotalPrice:4000, DiscountValue:4, DiscountIsRate:false, NetPrice:3996,
    //       Taxes:[
    //         { Id:5, PurchaseQuotationItemId: 4, TaxId: 1, TaxNameAr: "ضريبة 1", TaxNameEn: "Tax 1", TaxIsRate:true, TaxValue:5 },
    //         { Id:6, PurchaseQuotationItemId: 4, TaxId: 2, TaxNameAr: "ضريبة 2", TaxNameEn: "Tax 2", TaxIsRate:false, TaxValue:200 },
    //       ],
    //       TotalTaxValues:399.8, GrossPrice: 4395.8 },
    //     ],
    //     TotalItemPrices: 7000, TotalItemDiscounts: 94, TotalItemNetPrices: 6906, TotalItemTaxes: 836.3,
    //     TotalItemGrossPrices: 7742.3,
    //     Services: [
    //       { Id: 3, PurchaseQuotationId: 2, NameAr: "غرامة تأخر استلام", NameEn:"Waiting fee", Value: 100, IsRate: false },
    //     ],
    //     TotalServices: 100, DiscountValue:200, DiscountIsRate: false, ShippingFees: 2000,
    //     TotalPurchaseQuotationValue: 9642.3,
    //     Files: [
    //       {Id: 3, PurchaseQuotationId: 2, FileUrl: "Contract1.pdf"},
    //       {Id: 4, PurchaseQuotationId: 2, FileUrl: "Contract2.pdf"},
    //     ],
    //   },
      
    // ];
    debugMode: boolean = false;

    baseUrl = environment.baseApiUrl;
    // baseUrl = 'http://localhost:60289/';
     token = localStorage.getItem("token");
     httpOptions = {
      headers: new HttpHeaders({
        // 'Content-Type': 'application/json; charset=utf-8',
        // 'Authorization': "Bearer " + token,
         "Authorization": "Bearer " + this.token,
         "Content-Type": "application/json"
      })
    };
    getAll(): Observable<any> {
      if(this.debugMode){
        return of(this.debugData)
      }
      return this.http
        .get<any>(`${this.baseUrl}PurchaseQuotation/GetAll`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    getAllWithItems(): Observable<any> {
      if(this.debugMode){
        return of(this.debugData)
      }
      return this.http
        .get<any>(`${this.baseUrl}PurchaseQuotation/GetAllWithItems`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    getById(id:number): Observable<any> {
      if(this.debugMode){
        return of(this.debugData.find(x=>x.Id==id))
      }
      return this.http
        .get<any>(`${this.baseUrl}PurchaseQuotation/GetById/${id}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    getByIdWithItems(id:number): Observable<any> {
      if(this.debugMode){
        return of(this.debugData.find(x=>x.Id==id))
      }
      return this.http
        .get<any>(`${this.baseUrl}PurchaseQuotation/GetByIdWithItems/${id}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    insert(payload: PurchaseQuotationDetailsModel): Observable<any> {
      if(this.debugMode){
        this.debugData.push(payload);
        return of(true)
      }
      return this.http
        .post<any>(`${this.baseUrl}PurchaseQuotation/Insert`, payload,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    insertWithItems(payload: PurchaseQuotationDetailsModel): Observable<any> {
      if(this.debugMode){
        this.debugData.push(payload);
        return of(true)
      }
      return this.http
        .post<any>(`${this.baseUrl}PurchaseQuotation/InsertWithItems`, payload,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
    update(payload: PurchaseQuotationDetailsModel): Observable<any> {
      if(this.debugMode){
        this.debugData[this.debugData.findIndex(x=>x.Id==payload.Id)] = payload;
        return of(true)
      }
      return this.http
        .post<any>(`${this.baseUrl}PurchaseQuotation/Update`, payload,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    updateWithItems(payload: PurchaseQuotationDetailsModel): Observable<any> {
      if(this.debugMode){
        this.debugData[this.debugData.findIndex(x=>x.Id==payload.Id)] = payload;
        return of(true)
      }
      return this.http
        .post<any>(`${this.baseUrl}PurchaseQuotation/UpdateWithItems`, payload,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
    delete(payload: PurchaseQuotationDetailsModel): Observable<any> {
      if(this.debugMode){
        this.debugData.splice(this.debugData.findIndex(x=>x.Id==payload.Id),1);
        return of(true)
      }
      return this.http
        .get<any>(`${this.baseUrl}PurchaseQuotation/Delete/${payload.Id}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
    deleteList(payload: PurchaseQuotationDetailsModel []): Observable<any> {
      if(this.debugMode){
        payload.forEach(element => {
          this.debugData.splice(this.debugData.findIndex(x=>x.Id==element.Id),1);
        });
        return of(true)
      }
      return this.http
        .post<any>(`${this.baseUrl}PurchaseQuotation/DeleteList`,JSON.stringify(payload),this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
}
