<div class="row mb-12">
    <div class="col-md-12 col-lg-12 col-12 mb-12">
      <div class="card"></div>
    </div>
  </div>
  <div class="row" *ngIf="mainForm">
    <div class="col-12">
      <div class="card mb-4">
        <div class="card-body">
          <h3>
           
            <Strong style="font-size: 22px; margin-left:10px ;"> {{modelObject.Name}}</Strong>
            <span  style= "margin-left:10px; font-size: 14px" >{{modelObject.Code}}#</span>
            <!-- <span  style= "margin-left:10px; font-size: 14px" >{{modelObject.AcceptAll}}#</span> -->
            <span class="badge badge-pill badge-pill  " style= "margin-left:10px; font-size: 14px ; background-color: chartreuse;"  *ngIf="modelObject.AcceptAll==true">{{'ISAccept' | translate}}</span>
            <span class="badge badge-pill btn-danger  " style= "margin-left:10px; font-size: 14px"  *ngIf="modelObject.AcceptAll==false">{{'HasBeenCreated' | translate}}</span>
          <div class="top-right-button-container text-zero float-right">
            
            <button class="top-right-button btn btn-light btn-lg mr-1" style="background-color: #07ace4; color: white;" (click)="AcceptAll()"  *ngIf="modelObject.AcceptAll==false">{{ "AcceptedAll" | translate }}</button>
            <button class="top-right-button btn badge-success btn-lg mr-1" (click)="Payment(modelObject.Id)"  *ngIf="modelObject.AcceptAll==true">{{ "Payment" | translate }}</button>
        
            <button
              class="top-right-button btn btn-primary btn-lg mr-1"
              (click)="goBack()"
            >
              {{ "Back" | translate }}
            </button>
            </div>
        </h3>
        </div>
      </div>
    </div>
  </div>


  <div class="row" *ngIf="mainForm">
    <div class="col-12">
  <td></td>
  <td></td>
  <div class="card"*ngIf="modelObject" >
    <tabset class="card-tabs">
    <tab heading="{{'Information' | translate}}" class="card-body" >    
        
        <div >
            <div class="container">
                <!-- <h3>panelHeading</h3> -->
                <div class="panel" style="padding: 5px;">
                  <div class="panel-head" style="background-color:#f6f9fc;">
                    <h3>{{'Detales' | translate}}</h3></div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="mb-4">
                          <p class="text-muted mb-1"> {{ "dateOfRegistration:" | translate }}</p>
                          <p style="font-weight: bold;"> {{modelObject.dateOfRegistration | date:'mediumDate'}}</p>      
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="mb-4">
                          <p class="text-muted mb-1"> {{ "StartingDate:" | translate }}</p>
                          <p style="font-weight: bold;"> {{modelObject.StartingDate | date:'mediumDate'}}</p>      
                        </div>
                      </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                          <div class="mb-4">
                            <p class="text-muted mb-1"> {{ "EndDate:" | translate }}</p>
                            <p style="font-weight: bold;"> {{modelObject.EndDate | date:'mediumDate'}}</p>      
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="mb-4">
                            <p class="text-muted mb-1"> {{ "CheckAttendance:" | translate }}</p>
                            <p style="font-weight: bold;" *ngIf="modelObject.CheckAttendance==true">{{ "True" | translate }}</p>      
                            <p style="font-weight: bold;" *ngIf="modelObject.CheckAttendance==false">{{ "False" | translate }}</p>      
                          </div>
                        </div>
                      </div>
                </div>
              </div>

              <div class="container">
                <!-- <h3>panelHeading</h3> -->
                <div class="panel">
                  <div class="panel-head" style="background-color:#f6f9fc;">
                    <h3>{{'Employees' | translate}}</h3></div>
                  <div class="row" style="padding: 5px;" >
                    <div class="col-md-6" >                 
                        <table class="table table-borderless">
                            <thead>                
                                <tr   *ngIf="modelObject.GrammarChoose==true">
                                  <!-- <td>{{'Alert:'|translate}}</td>      -->
                                  <td>                         
                                    <tr *ngFor="let EmployeesExcluded of modelObject.EmployeesExcluded">  
                                      <label style="font-weight: bold;" > {{ EmployeesExcluded.EmployeesName }}</label>
                                  </tr>
                                </td>
                                </tr>               
                              
              
                            </thead>
                            
                          </table>
                   </div>                

                  
                 </div>
                 <div class="row" style="padding: 5px;" >
                    <div class="col-md-6" >                 
                        <table class="table table-borderless">
                            <thead>                
                                <tr   *ngIf="modelObject.EmployeesChoose==true">
                                  <!-- <td>{{'Alert:'|translate}}</td>      -->
                                  <td>                         
                                    <tr *ngFor="let EmployeesChoose of modelObject.EmployeesChoiceList">  
                                      <label > {{ EmployeesChoose.EmployeesName }} : </label>
                                  </tr>
                                </td>
                                </tr>               
                              
              
                            </thead>
                            
                          </table>
                   </div>                

                  
                 </div>
            

                


                </div>
              </div>
           
          </div>
          
      
    </tab>
    <tab heading="{{'SalarySlips' | translate}}" class="card-body">
      <div >
 
        <div id="divmiddle" style="display: block; margin-top: 5px">
          <div style="width: 100%">
            <div>
              <div class="card" style="min-width: 900px !important">
                <div class="card-body">
                  <table class="table table-hover" style="margin: unset !important" >
                    <thead class="no-border-top">
                    
                      <tr style="font-size: large ; color: #0189DE;"  >
                        <td>{{'SalaryPathID'|translate}}</td>
                        
                        <td>{{'dateOfRegistration'|translate}}</td>
                        <td>{{'StartingDate'|translate}}</td>
                        <td>{{'EndDate'|translate}}</td>
                        <td></td>
                        <td></td>
                      </tr>
               
         
                  
                    </thead>
                    <tbody>
                    <tr  *ngFor="let obj of SalarySlipList" style="font-size: 18px;">

                      <td>
                        {{obj.Code}}

                      </td>
                      <td >
                        {{obj.dateOfRegistration | date:'mediumDate'}}
                      </td>
                      <td >
                        {{obj.StartingDate | date:'mediumDate'}}
                      </td>
                       <td >
                         {{obj.EndDate | date:'mediumDate'}}
                      </td>
                                 
                      <!-- <td style="text-align: center">
                        <i
                          class="simple-icon-pencil"
                          (click)="EditPayment(obj.Id)"
                          tooltip="{{ 'Edit' | translate }}"
                          placement="auto"
                          style="
                            font-size: 20px;
                            font-weight: bolder;
                            cursor: pointer;
                            vertical-align: middle;
                            color: #ffc107;
                          "
                         
                         
                        ></i>
                      </td> -->
                      <td style="text-align: center">
                        <i
                          class="simple-icon-close"
                          (click)="DeletePayment(obj.Id)"
                          tooltip="{{ 'Delete' | translate }}"
                          placement="auto"
                          style="
                            font-size: 20px;
                            font-weight: bolder;
                            cursor: pointer;
                            vertical-align: middle;
                            color: #dc3545;
                          "
                        ></i>
                      </td>
                    
                    </tr>

                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </tab>
      <tab heading="{{'Payments' | translate}}" class="card-body">
        <div >
   
          <div id="divmiddle" style="display: block; margin-top: 5px">
            <div style="width: 100%">
              <div>
                <div class="card" style="min-width: 900px !important">
                  <div class="card-body">
                    <table class="table table-hover" style="margin: unset !important" >
                        <thead class="no-border-top">
                        
                          <tr style="font-size: large ; color: #0189DE;"  >
                            <td>{{'SalaryPathID'|translate}}</td>
                            <td>
                            </td>
                            <td>{{'ExpenseNumber'|translate}}</td>
                            <td>{{'PaymentDate'|translate}}</td>
                            <td>{{'Amount'|translate}}</td>
                            <td></td>
                            <td></td>
                          </tr>
                   
             
                      
                        </thead>
                        <tbody>
                        <tr  *ngFor="let obj of SalariesPaymentList" style="font-size: 18px;">
    
                          <td>
                          </td>
                          <td>
                          </td>
                          <td></td>
                          <td >
                            {{obj.PaymentDate| date:'fullDate'}}
                          </td>     
                          <td>
                            {{obj.Amount}}
                          </td>  
                                     
                          <!-- <td style="text-align: center">
                            <i
                              class="simple-icon-pencil"
                              (click)="EditPayment(obj.Id)"
                              tooltip="{{ 'Edit' | translate }}"
                              placement="auto"
                              style="
                                font-size: 20px;
                                font-weight: bolder;
                                cursor: pointer;
                                vertical-align: middle;
                                color: #ffc107;
                              "
                             
                             
                            ></i>
                          </td> -->
                          <td style="text-align: center">
                            <i
                              class="simple-icon-close"
                              (click)="DeletePayment(obj.Id)"
                              tooltip="{{ 'Delete' | translate }}"
                              placement="auto"
                              style="
                                font-size: 20px;
                                font-weight: bolder;
                                cursor: pointer;
                                vertical-align: middle;
                                color: #dc3545;
                              "
                            ></i>
                          </td>
                        
                        </tr>
    
                        </tbody>
                      </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </tab>
        <tab heading="{{'TimLine' | translate}}" class="card-body">
            <div >
       
              <div id="divmiddle" style="display: block; margin-top: 5px">
                <div style="width: 100%">
                  <div>
                    <div class="card" style="min-width: 900px !important">
                      <div class="card-body">
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </tab>
  </tabset>
</div>
<div bsModal #delModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">
  <div class="modal-dialog modal-sm">
      <div class="modal-content" style="border: 3px solid red;">
          <button type="button" class="close pull-right" aria-label="Close" (click)="delModal.hide()"
              style="margin-right: 20px; margin-left: 20px; margin-top: 10px; width: 20px; display: inline;">
              <span aria-hidden="true">&times;</span>
          </button>
          <div class="modal-header" style="display: inline; padding: 0px 0px 5px 0px;">
              <h4 id="dialog-child-name" class="modal-title pull-left" style="text-align: center; color: red;">
                  {{"ConfirmDel"+translationName |
                  translate}}</h4>
          </div>
          <div class="modal-body">
              <!-- <div class="form-group form-primary" *ngIf="deletionList.length < NotesObject.length || deletionList.length === 1" style="text-align: center;">
                  <div *ngFor="let item of deletionList;" style="text-align: center;">
                      <label type="text">- {{item.Name}}</label>
                      <span class="form-bar"></span>
                  </div>
              </div> -->
              <div class="form-group form-primary" style="text-align: center;" *ngIf="deletionList.length > 1 && 
                  deletionList.length === NotesObject.length">
                  <label type="text">
                      <h1 style="font-weight: bolder; text-align: center;">{{translationName+"DeleteAll" | translate}}</h1>
                  </label>
                  <span class="form-bar"></span>
              </div>

              <div  *ngFor="let item of deletionList;" style="margin-left: 5px; text-align: center; margin-top: 30px;">
                  <button class="btn btn-danger" id="sideModalRefDeleteButton" (click)="deleteNotes(item.Id)"
                      [disabled]="currentAction==='loading'"
                      style="font-weight: bold; width: 100%; text-align: center;">{{"Delete" | translate}}</button>
                  <button class="btn btn-light" (click)="delModal.hide()"
                      style="font-weight: bold; width: 100%; text-align: center; margin-top: 10px;">{{"Close" |
                      translate}}</button>
              </div>
          </div>
      </div>
  </div>
</div>
 <td></td>
  <td></td>
  <td></td>  <td></td>
  <td></td>
  <td></td>
 