import { FooterModel } from "./FooterModel";
import { ItemsModel } from "./ItemsModel";

export class ItemBalanceModel{
    Id?: number;
    CompanyDataId?:number;
    BranchesDataId?: number; 
    Year?: number; 
    Month?: number; 
    Date?:Date;
    BalanceNameId?: number; 
    BalanceNameAr?:string;
    BalanceNameEn?: string;
    StoreDataId?: number; 
    StoreAr?: string;
    StoreEn?: string;
    TotalValue?: number; 
    Description?: string; 
    IsPosted?: Boolean;     
    Footer?:FooterModel[];
    // Items?:ItemsModel[];


    constructor(item?:ItemBalanceModel){
        item = item? item:{};
        this.Id = item.Id? item.Id:0;
        this.CompanyDataId = item.CompanyDataId? item.CompanyDataId:0;
        this.BranchesDataId = item.BranchesDataId? item.BranchesDataId:0;
        this.Year = item.Year? item.Year:0;
        this.Month = item.Month? item.Month:0;
        this.Date = item.Date? item.Date:this.Date;
        this.BalanceNameId = item.BalanceNameId? item.BalanceNameId:0;
        this.BalanceNameAr=item.BalanceNameAr ? item.BalanceNameAr:'';
        this.BalanceNameEn=item.BalanceNameEn ? item.BalanceNameEn:'';
        this.StoreDataId = item.StoreDataId? item.StoreDataId:0;
        this.StoreAr=item.StoreAr ? item.StoreAr:'';
        this.StoreEn=item.StoreEn ? item.StoreEn:'';

        this.TotalValue = item.TotalValue? item.TotalValue:0;
       
        this.Description=item.Description ? item.Description:'';
        this.IsPosted=item.IsPosted ? item.IsPosted:false;    
        this.Footer = item.Footer ? item.Footer : [];
        // this.Items=item.Items? item.Items:[];
    
    }

}