import { ItemsPriceListModel } from "./ItemsPriceListModel";


export class PriceListModel{
    Id?: number;
    NameAr?:string;
    NameEn?: string;  
    IsActive?: boolean;
    Items?:ItemsPriceListModel[];
    constructor(item?:PriceListModel){
        item = item? item:{};
        this.Id = item.Id? item.Id:0;
        this.NameAr = item.NameAr? item.NameAr:'';
        this.NameEn = item.NameEn? item.NameEn:'';          
        this.IsActive = item.IsActive? item.IsActive:true;
        this.Items = item.Items? item.Items:[];

    }

}