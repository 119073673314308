import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { StoreDataModel } from "src/app/views/purchases/purchase-orders/StoreDataModel";

@Injectable({
  providedIn: 'root'
})
export class StoreDataService {
    constructor(private http: HttpClient) {
  
    }

    debugData: StoreDataModel [] = [];
    // debugData: StoreDataModel [] = [
    //   {
    //     Id: 1, CompanyDataId: 0, BranchesDataId:0, NameAr:"مخزن 1", NameEn:"Store 1", CountryCodeId:1, GovernorateCodeId:1,
    //     CityCodeId:1, StoreAddress:"Store 1 Address", StoreMobil:"02011111111", StorePhone:"0211111111", StoreFax:"FAX111111",
    //     StoreWhatsapp:"WA02011111111", StoreEmail:"store1@gmail.com", StoreManager:1, Description:"Description\nTest\nStore 1"
    //   },
    //   {
    //     Id: 2, CompanyDataId: 0, BranchesDataId:0, NameAr:"مخزن 2", NameEn:"Store 2", CountryCodeId:1, GovernorateCodeId:1,
    //     CityCodeId:1, StoreAddress:"Store 2 Address", StoreMobil:"02022222222", StorePhone:"0222222222", StoreFax:"FAX222222",
    //     StoreWhatsapp:"WA02022222222", StoreEmail:"store2@gmail.com", StoreManager:1, Description:"Description\nTest\nStore 2"
    //   },
    //   {
    //     Id: 3, CompanyDataId: 0, BranchesDataId:0, NameAr:"مخزن 3", NameEn:"Store 3", CountryCodeId:1, GovernorateCodeId:1,
    //     CityCodeId:1, StoreAddress:"Store 3 Address", StoreMobil:"03033333333", StorePhone:"0333333333", StoreFax:"FAX333333",
    //     StoreWhatsapp:"WA03033333333", StoreEmail:"store3@gmail.com", StoreManager:1, Description:"Description\nTest\nStore 3"
    //   },
    //   {
    //     Id: 4, CompanyDataId: 0, BranchesDataId:0, NameAr:"مخزن 4", NameEn:"Store 4", CountryCodeId:1, GovernorateCodeId:1,
    //     CityCodeId:1, StoreAddress:"Store 4 Address", StoreMobil:"04044444444", StorePhone:"0444444444", StoreFax:"FAX444444",
    //     StoreWhatsapp:"WA04044444444", StoreEmail:"store4@gmail.com", StoreManager:1, Description:"Description\nTest\nStore 4"
    //   },
    //   {
    //     Id: 5, CompanyDataId: 0, BranchesDataId:0, NameAr:"مخزن 5", NameEn:"Store 5", CountryCodeId:1, GovernorateCodeId:1,
    //     CityCodeId:1, StoreAddress:"Store 5 Address", StoreMobil:"05055555555", StorePhone:"0555555555", StoreFax:"FAX555555",
    //     StoreWhatsapp:"WA05055555555", StoreEmail:"store5@gmail.com", StoreManager:1, Description:"Description\nTest\nStore 5"
    //   },
    //   {
    //     Id: 6, CompanyDataId: 0, BranchesDataId:0, NameAr:"مخزن 6", NameEn:"Store 6", CountryCodeId:1, GovernorateCodeId:1,
    //     CityCodeId:1, StoreAddress:"Store 6 Address", StoreMobil:"06066666666", StorePhone:"0666666666", StoreFax:"FAX666666",
    //     StoreWhatsapp:"WA06066666666", StoreEmail:"store6@gmail.com", StoreManager:1, Description:"Description\nTest\nStore 6"
    //   },
    //   {
    //     Id: 7, CompanyDataId: 0, BranchesDataId:0, NameAr:"مخزن 7", NameEn:"Store 7", CountryCodeId:1, GovernorateCodeId:1,
    //     CityCodeId:1, StoreAddress:"Store 7 Address", StoreMobil:"07077777777", StorePhone:"0777777777", StoreFax:"FAX777777",
    //     StoreWhatsapp:"WA07077777777", StoreEmail:"store7@gmail.com", StoreManager:1, Description:"Description\nTest\nStore 7"
    //   },
    //   {
    //     Id: 8, CompanyDataId: 0, BranchesDataId:0, NameAr:"مخزن 8", NameEn:"Store 8", CountryCodeId:1, GovernorateCodeId:1,
    //     CityCodeId:1, StoreAddress:"Store 8 Address", StoreMobil:"08088888888", StorePhone:"0888888888", StoreFax:"FAX888888",
    //     StoreWhatsapp:"WA08088888888", StoreEmail:"store8@gmail.com", StoreManager:1, Description:"Description\nTest\nStore 8"
    //   },
    //   {
    //     Id: 9, CompanyDataId: 0, BranchesDataId:0, NameAr:"مخزن 9", NameEn:"Store 9", CountryCodeId:1, GovernorateCodeId:1,
    //     CityCodeId:1, StoreAddress:"Store 9 Address", StoreMobil:"09099999999", StorePhone:"0999999999", StoreFax:"FAX999999",
    //     StoreWhatsapp:"WA09099999999", StoreEmail:"store9@gmail.com", StoreManager:1, Description:"Description\nTest\nStore 9"
    //   }
    // ]
    debugMode: boolean = false;

    baseUrl = environment.baseApiUrl;
    // baseUrl = 'http://localhost:60289/';
     token = localStorage.getItem("token");
     httpOptions = {
      headers: new HttpHeaders({
        // 'Content-Type': 'application/json; charset=utf-8',
        // 'Authorization': "Bearer " + token,
         "Authorization": "Bearer " + this.token,
         "Content-Type": "application/json"
      })
    };
    getAll(): Observable<any> {
      if(this.debugMode){
        return of(this.debugData)
      }
      return this.http
        .get<any>(`${this.baseUrl}warehouse/GetAll`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    getById(id:number): Observable<any> {
      if(this.debugMode){
        return of(this.debugData.find(x=>x.Id==id))
      }
      return this.http
        .get<any>(`${this.baseUrl}warehouse/GetById/${id}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    insert(payload: StoreDataModel): Observable<any> {
      if(this.debugMode){
        this.debugData.push(payload);
        return of(true)
      }
      return this.http
        .post<any>(`${this.baseUrl}warehouse/Insert`, payload,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
    update(payload: StoreDataModel): Observable<any> {
      if(this.debugMode){
        this.debugData[this.debugData.findIndex(x=>x.Id==payload.Id)] = payload;
        return of(true)
      }
      return this.http
        .post<any>(`${this.baseUrl}warehouse/Update`, payload,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
    delete(payload: StoreDataModel): Observable<any> {
      if(this.debugMode){
        this.debugData.splice(this.debugData.findIndex(x=>x.Id==payload.Id),1);
        return of(true)
      }
      return this.http
        .get<any>(`${this.baseUrl}warehouse/Delete/${payload.Id}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
    deleteList(payload: StoreDataModel []): Observable<any> {
      if(this.debugMode){
        payload.forEach(element => {
          this.debugData.splice(this.debugData.findIndex(x=>x.Id==element.Id),1);
        });
        return of(true)
      }
      return this.http
        .post<any>(`${this.baseUrl}warehouse/DeleteList`,JSON.stringify(payload),this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
}
