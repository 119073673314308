import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { FileUploader } from 'ng2-file-upload';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'upload-image',
    templateUrl: 'upload-image.component.html',
    styleUrls: ['upload-image.component.scss']
})
export class UploadImageComponent implements OnInit {
    public NationalIDImage: FileUploader;
    public hasDragOver = false;
  
    @Input()
    public editmode = false;
  
    @Input()
    public url = '';
  
    @Output()
    public urlChange = new EventEmitter();
    declare lang: any;
  
    constructor(private translate: TranslateService, private notifications: NotificationsService) {
        this.lang = localStorage.getItem('theme_lang');

      this.NationalIDImage = new FileUploader({
        url: environment.baseApiUrl+'CatchReceipt/UploadFile',
        disableMultipart: false,
        autoUpload: true
      });
  
      this.NationalIDImage.response.subscribe(res => {
        // Upload returns a JSON with the image ID
        if(res != null && res != "" && res != undefined){
        this.url = environment.apiUrl+ '/Resources/CatchReceiptFiles/' + JSON.parse(res);
        this.urlChange.emit(this.url);
        }
        else{
            this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessUpload"))
        }
       },error =>{
        this.toastError(this.translate.instant("Error"), this.translate.instant("FailedUpload"))
       });
    }
  
    public fileOver(e: any): void {
       
      this.hasDragOver = e;
    }
  
    ngOnInit() {
       
    }
    toastSuccess(toastHeader: string, toastBody: string): void {
        this.notifications.create(toastHeader, toastBody,
          NotificationType.Success, { timeOut: 3000, showProgressBar: true });
      }
      toastError(toastHeader: string, toastBody: string): void {
        this.notifications.create(toastHeader, toastBody,
          NotificationType.Error, { timeOut: 3000, showProgressBar: true });
      }
  }
  