import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tableFilter'
})
export class TableFilterPipe implements PipeTransform {

  transform(list: any[], value: string): any {
    return value ? list.filter(item => item.Name.toLowerCase().includes(value.toLowerCase()) || item.ArName.toLowerCase().includes(value.toLowerCase()) ) : list;
  }

}
