

<div class="form-group" *ngIf="currentScreen !== 'Table' ">
    <div class="modal-body">
        <form class="md-float-material form-material" *ngIf="mainForm" [formGroup]="mainForm">

            <div style="text-align: center;">
                <button class="btn btn-light" (click)="currentScreen = 'Table'"
                    style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Back" |
                    translate}}</button>
                <button *ngIf="currentFormMode==='Add'"
                    [disabled]="mainForm.invalid || currentAction==='loading'" id="smallModalSubmitButton"
                    class="btn btn-secondary" (click)="addObject()"
                    style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Add" |
                    translate}}</button>
                <button *ngIf="currentFormMode==='Add'"
                    [disabled]="mainForm.invalid || currentAction==='loading'" id="smallModalSubmitButton"
                    class="btn btn-secondary" (click)="addAndNew()"
                    style="font-weight: bold; width: 250px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"AddAndNew" |
                    translate}}</button>
                <button *ngIf="currentFormMode==='Edit'"
                    [disabled]="mainForm.invalid || currentAction==='loading'" id="smallModalSubmitButton"
                    class="btn btn-secondary" (click)="editObject()"
                    style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Edit"
                    | translate}}</button>
            </div>
            <div class="form-group" style="margin-top: 10px;">
                <div class="row">
                    <div class=" col-md-6">
                        <label class="float-label push righ">{{"Code" | translate}}</label>
                        <input *ngIf="currentFormMode==='Add'||currentFormMode==='Edit'"
                            type="text" name="ContractCode" class="form-control" formControlName="ContractCode"
                            placeholder='{{"Code" | translate}}'>
                        <label *ngIf="currentFormMode==='Info'" class="form-control" type="text"
                            name="ContractCode">{{modelObject.ContractCode}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('ContractCode').hasError('required') || mainForm.get('ContractCode').invalid) && ( mainForm.get('ContractCode').dirty ||  mainForm.get('ContractCode').touched)">
                            {{"Code Required" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>
                    <div class=" col-md-6">
                        <div class="form-group form-primary">
                            <label class="float-label push righ">{{"Employee" | translate}}</label>
                            <select  formControlName="EmployeeId"  id="inputState" class="form-control" *ngIf="currentFormMode==='Add'||currentFormMode==='Edit'">
                                <option disabled selected [value]="0">{{'Employee' | translate}}</option>
                                <option *ngFor="let item of employees" [value]="item.Id">{{item.Name}}</option>
                            </select> 
                            <label *ngIf="currentFormMode==='Info'" class="form-control" type="text"
                                name="EmployeeId">{{ getEmployeeName(modelObject.EmployeeId)}}</label>
                            <div class="messages text-danger"
                                *ngIf=" (mainForm.get('EmployeeId').hasError('required') || mainForm.get('EmployeeId').invalid) && ( mainForm.get('EmployeeId').dirty ||  mainForm.get('EmployeeId').touched)">
                                {{"Employee Required" | translate}}</div>
                            <span class="form-bar"></span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class=" col-md-6">
                        <div class="form-group form-primary">
                            <label class="float-label push righ">{{"Job Level" | translate}}</label>
                            <select  formControlName="JobLevelId"  id="inputState" class="form-control" *ngIf="currentFormMode==='Add'||currentFormMode==='Edit'">
                                <option disabled selected [value]="0">{{'Job Level' | translate}}</option>
                                <option *ngFor="let item of JobLevels" [value]="item.Id">{{item.Name}}</option>
                            </select> 
                            <label *ngIf="currentFormMode==='Info'" class="form-control" type="text"
                                name="JobLevelId">{{getJobLevelName(modelObject.JobLevelId)}}</label>
                            <div class="messages text-danger"
                                *ngIf=" (mainForm.get('JobLevelId').hasError('required') || mainForm.get('JobLevelId').invalid) && ( mainForm.get('JobLevelId').dirty ||  mainForm.get('JobLevelId').touched)">
                                {{"Job Level Required" | translate}}</div>
                            <span class="form-bar"></span>
                        </div>
                    </div>
                    <div class=" col-md-6">
                        <div class="form-group form-primary">
                            <label class="float-label push righ">{{"Job Title" | translate}}</label>
                            <select  formControlName="JobTitleId"  id="inputState" class="form-control" *ngIf="currentFormMode==='Add'||currentFormMode==='Edit'">
                                <option disabled selected [value]="0">{{'Job Title' | translate}}</option>
                                <option *ngFor="let item of JobTitles" [value]="item.Id">{{item.Name}}</option>
                            </select> 
                            <label *ngIf="currentFormMode==='Info'" class="form-control" type="text"
                                name="JobTitleId">{{getJobTitleName(modelObject.JobTitleId)}}</label>
                            <div class="messages text-danger"
                                *ngIf=" (mainForm.get('JobTitleId').hasError('required') || mainForm.get('JobTitleId').invalid) && ( mainForm.get('JobTitleId').dirty ||  mainForm.get('JobTitleId').touched)">
                                {{"Job Title Required" | translate}}</div>
                            <span class="form-bar"></span>
                        </div>
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class=" col-md-6">
                        <div class="form-group form-primary">
                            <label class="float-label push righ">{{"Start Date" | translate}}</label>
                            <input *ngIf="currentFormMode==='Add'||currentFormMode==='Edit'"
                            type="date" name="StartDate" class="form-control" formControlName="StartDate"
                            placeholder='{{"Start Date" | translate}}'
                            >
                        <label *ngIf="currentFormMode==='Info'" class="form-control" type="date"
                            name="StartDate">{{modelObject.StartDate | date:'dd/MM/yyyy'}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('StartDate').hasError('required') || mainForm.get('StartDate').invalid) && ( mainForm.get('StartDate').dirty ||  mainForm.get('StartDate').touched)">
                            {{"Start Date Required" | translate}}</div>
                        <span class="form-bar"></span>
                        </div>
                    </div>
                    <div class=" col-md-6" *ngIf="currentFormMode==='Add'||currentFormMode==='Edit'">
                        <div class="form-group form-primary">
                            <label class="float-label push righ">{{"End Type" | translate}}</label>
                            <div class="row">
                                <div class=" col-md-6">
                                    <div class="custom-control custom-radio" >
                                        <input class="custom-control-input" type="radio" [value]="1" name="radio" formControlName="endType" id="reactiveRadios1">
                                        <label class="custom-control-label" for="reactiveRadios1">
                                            {{'Duration'|translate}}
                                        </label>
                                      
                                      </div>
                                </div>
                                <div class=" col-md-6">
                                    <div class="custom-control custom-radio" style="display: inline;">
                                        <input class="custom-control-input" type="radio" [value]="2" name="radio" formControlName="endType" id="reactiveRadios2">
                                        <label class="custom-control-label" for="reactiveRadios2">
                                          {{'Date'|translate}}
                                        </label>
                                      </div>
                                </div>

                            </div>
                       
                          
                        </div>
                    </div>
                </div>
                <div class="row">
                        <div class=" col-md-3" *ngIf="mainForm.get('endType').value == '1'">
                            <div class="form-group form-primary">
                                <label class="float-label push righ">{{"Duration" | translate}}</label>
                                <input [disabled]="mainForm.get('endType').value == '1'" *ngIf="currentFormMode==='Add'||currentFormMode==='Edit'"
                                type="number" name="Duration" class="form-control" formControlName="Duration"
                                placeholder='{{"Duration" | translate}}'>
                            <label *ngIf="currentFormMode==='Info'" class="form-control" type="date"
                                name="Duration">{{modelObject.Duration}}</label>
    
                            <span class="form-bar"></span>
                            </div>
                        </div>
                        <div class=" col-md-3" *ngIf="mainForm.get('endType').value == '1'">
                            <div class="form-group form-primary">
                                <label class="float-label push righ">{{''|translate}}</label>
                                <select  formControlName="DurationType"  id="inputState" class="form-control" *ngIf="currentFormMode==='Add'||currentFormMode==='Edit'">
                                    <option  [value]="1">{{'Year' | translate}} </option>
                                    <option  [value]="2">{{'Month' | translate}}</option>
                                </select> 
                            <label *ngIf="currentFormMode==='Info'" class="form-control" type="date"
                                name="StartDate">
                                <span *ngIf="modelObject.DurationType == '1'"> {{'Year' | translate}} </span>
                                <span *ngIf="modelObject.DurationType == '2'"> {{'Month' | translate}}</span>
                            </label>
                   
                            <span class="form-bar"></span>
                            </div>
                        </div>
                    <div class=" col-md-6" >
                        <div class="form-group form-primary" >
                            <div class="form-group form-primary" *ngIf="mainForm.get('endType').value == '2'">
                                <label class="float-label push righ">{{"End Date" | translate}}</label>
                                <input  *ngIf="currentFormMode==='Add'||currentFormMode==='Edit'"
                                type="date" name="EndDate" class="form-control" formControlName="EndDate"
                                placeholder='{{"End Date" | translate}}'>
                            <label *ngIf="currentFormMode==='Info'" class="form-control" type="date"
                                name="StartDate">{{modelObject.EndDate| date:'dd/MM/yyyy'}}</label>
                            <span class="form-bar"></span>
                            </div>
                          
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class=" col-md-6">
                        <div class="form-group form-primary">
                            <label class="float-label push righ">{{"Joining Date" | translate}}</label>
                            <input *ngIf="currentFormMode==='Add'||currentFormMode==='Edit'"
                            type="date" name="JoiningDate" class="form-control" formControlName="JoiningDate"
                            placeholder='{{"Joining Date" | translate}}'>
                        <label *ngIf="currentFormMode==='Info'" class="form-control" type="date"
                            name="JoiningDate">{{modelObject.JoiningDate| date:'dd/MM/yyyy'}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('JoiningDate').hasError('required') || mainForm.get('JoiningDate').invalid) && ( mainForm.get('JoiningDate').dirty ||  mainForm.get('JoiningDate').touched)">
                            {{"Joining Date Required" | translate}}</div>
                        <span class="form-bar"></span>
                        </div>
                    </div>
                    <div class=" col-md-6">
                        <div class="form-group form-primary">
                            <label class="float-label push righ">{{"Test End Date" | translate}}</label>
                            <input *ngIf="currentFormMode==='Add'||currentFormMode==='Edit'"
                            type="date" name="TestEndDate" class="form-control" formControlName="TestEndDate"
                            placeholder='{{"Test End Date" | translate}}'>
                        <label *ngIf="currentFormMode==='Info'" class="form-control" type="date"
                            name="TestEndDate">{{modelObject.TestEndDate| date:'dd/MM/yyyy'}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('TestEndDate').hasError('required') || mainForm.get('TestEndDate').invalid) && ( mainForm.get('TestEndDate').dirty ||  mainForm.get('TestEndDate').touched)">
                            {{"Test End Date Required" | translate}}</div>
                        <span class="form-bar"></span>
                        </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class=" col-md-6">
                            <div class="form-group form-primary">
                                <label class="float-label push righ">{{"The Date Of Signing The Contract" | translate}}</label>
                                <input *ngIf="currentFormMode==='Add'||currentFormMode==='Edit'"
                                type="date" name="TheDateOfSigningTheContract" class="form-control" formControlName="TheDateOfSigningTheContract"
                                placeholder='{{"The Date Of Signing The Contract" | translate}}'>
                            <label *ngIf="currentFormMode==='Info'" class="form-control" type="date"
                                name="TheDateOfSigningTheContract">{{modelObject.TheDateOfSigningTheContract| date:'dd/MM/yyyy'}}</label>
                            <div class="messages text-danger"
                                *ngIf=" (mainForm.get('TheDateOfSigningTheContract').hasError('required') || mainForm.get('TheDateOfSigningTheContract').invalid) && ( mainForm.get('TheDateOfSigningTheContract').dirty ||  mainForm.get('TheDateOfSigningTheContract').touched)">
                                {{"The Date Of Signing The Contract Required" | translate}}</div>
                            <span class="form-bar"></span>
                            </div>
                        </div>
                        <div class=" col-md-6">
                      
                        </div>
                </div>
                <hr />
                <table class="table">
                    <thead class="thead-light">
                        <th>{{'Salary data'|translate}}</th>
                    </thead>
                </table>

                <div class="row">
                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"Basic Salary" | translate}}</label>
                        <input *ngIf="currentFormMode==='Add'||currentFormMode==='Edit'"
                            type="text" name="BasicSalary" class="form-control" formControlName="BasicSalary"
                            placeholder='{{"Basic Salary" | translate}}'>
                        <label *ngIf="currentFormMode==='Info'" class="form-control" type="text"
                            name="BasicSalary">{{modelObject.BasicSalary}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('BasicSalary').hasError('required') || mainForm.get('BasicSalary').invalid) && ( mainForm.get('BasicSalary').dirty ||  mainForm.get('BasicSalary').touched)">
                            {{"Basic Salary Required" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>
                    <div class=" col-md-4">
                        <div class="form-group form-primary">
                            <label class="float-label push righ">{{"Currency" | translate}}</label>
                            <select  formControlName="CurrencyId"  id="inputState" [searchable]="true" class="form-control" *ngIf="(currentFormMode==='Add'||currentFormMode==='Edit') && lang == 'ar-EG'">
                                <option disabled selected [value]="0">{{'Currency' | translate}}</option>
                                <option *ngFor="let item of currencies" [value]="item.Id">{{item.NameAr}}</option>
                            </select> 
                            <select  formControlName="CurrencyId"  id="inputState" class="form-control" *ngIf="(currentFormMode==='Add'||currentFormMode==='Edit')  && lang != 'ar-EG'">
                                <option disabled selected [value]="0">{{'Currency' | translate}}</option>
                                <option *ngFor="let item of currencies" value="item.Id">{{item.NameEn}}</option>
                            </select> 
                            <label *ngIf="currentFormMode==='Info'" class="form-control" type="text"
                                name="EmployeeId">{{modelObject.CurrencyId}}</label>
                            <div class="messages text-danger"
                                *ngIf=" (mainForm.get('CurrencyId').hasError('required') || mainForm.get('CurrencyId').invalid) && ( mainForm.get('CurrencyId').dirty ||  mainForm.get('CurrencyId').touched)">
                                {{"Currency Required" | translate}}</div>
                            <span class="form-bar"></span>
                        </div>
                    </div>
                    <div class=" col-md-4">
                        <div class="form-group form-primary">
                            <label class="float-label push righ">{{"ArrestCycle" | translate}}</label>
                            <select  formControlName="ArrestCycle"  id="inputState" class="form-control" *ngIf="currentFormMode==='Add'||currentFormMode==='Edit'">
                                <option disabled selected [value]="''">{{'ArrestCycle' | translate}}</option>
                                <option value="Weekly">{{'Weekly' | translate}}</option>
                                <option value="Weekly">{{'Monthly' | translate}}</option>
                                <option value="Quarterly">{{'Quarterly' | translate}}</option>
                                <option value="annual">{{'annual' | translate}}</option>
                                <option value="midterm">{{'midterm' | translate}}</option>
                                <option value="fortnightly">{{'fortnightly' | translate}}</option>
                            </select> 
                            <label *ngIf="currentFormMode==='Info'" class="form-control" type="text"
                                name="EmployeeId">{{modelObject.EmployeeName}}</label>
                            <div class="messages text-danger"
                                *ngIf=" (mainForm.get('EmployeeId').hasError('required') || mainForm.get('EmployeeId').invalid) && ( mainForm.get('EmployeeId').dirty ||  mainForm.get('EmployeeId').touched)">
                                {{"EmployeeRequired" | translate}}</div>
                            <span class="form-bar"></span>
                        </div>
                    </div>
                </div>
                <div style="width: 100%; margin-top: 15px;">
                    <form [formGroup]="dueForm">
                        <table class="table">
                            <thead class="thead-light">
                                <th>{{'Salary Due'|translate}}</th>
                                <th>{{"Salary Item Name" | translate}}</th>
                                <th>{{"Amount" | translate}}</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let obj of addedDuesList;  let i=index">
                                    <td style="text-align: center; max-width: 80px;">
                                        <button *ngIf="currentFormMode==='Add'||currentFormMode==='Edit'" type="text"
                                        class="btn btn-danger text-center" (click)="removeDue(obj)"
                                        [disabled]="currentAction==='loading'"
                                        style="font-weight: bold; width: 80px;">{{"Remove"
                                        | translate}}</button>
                                    </td>
                                    <td>
                                        {{obj.SalaryItemName}}
                                    </td>
                                    <td>{{obj.Amount}}</td>
                                </tr>
                                <tr *ngIf="currentFormMode==='Add'||currentFormMode==='Edit'" type="text">
                                    <td style="text-align: center; max-width: 80px;">
                                        <button class="btn btn-info text-center" (click)="addDue()"
                                        [disabled]="dueForm.invalid || currentAction==='loading'"
                                        style="font-weight: bold; width: 80px;">{{"Add"
                                        | translate}}</button>
                                    </td>
                                    <td>
                                        <div>
                                            <div class="form-group form-primary">
                                                <!-- <label class="float-label push righ">{{"Salary Item" | translate}}</label> -->
                                                <select  formControlName="SalaryItemId"  id="inputState" class="form-control" >
                                                    <option disabled selected [value]="0">{{'Salary Item' | translate}}</option>
                                                    <option *ngFor="let item of duesItems" [value]="item.Id">{{item.ItemName}}</option>
                                                </select> 
                                             
                                                <span class="form-bar"></span>
                                            </div>
                                   
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <div class="form-group form-primary">
                                                <!-- <label class="float-label push righ">{{"Amount" | translate}}</label> -->
                                                <input *ngIf="currentFormMode==='Add'||currentFormMode==='Edit'" type="number" name="Date"
                                                class="form-control" formControlName="Amount"
                                                placeholder='{{"Amount" | translate}}'>
                                            <label *ngIf="currentFormMode==='Info'" class="form-control" type="text"
                                                name="Date">{{modelObject.Amount}}</label>
                                          
                                                <span class="form-bar"></span>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <thead class="thead-light">
                                <th></th>
                                <th></th>
                                <th></th>
                            </thead>
                        </table>
                    </form>
                </div>
                <div style="width: 100%; margin-top: 15px;">
                    <form [formGroup]="DeductionsForm">
                        <table class="table">
                            <thead class="thead-light">
                                <th>{{'Salary Deductions'|translate}}</th>
                                <th>{{"Salary Item Name" | translate}}</th>
                                <th>{{"Amount" | translate}}</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let obj of addedDeductionsList;  let i=index">
                                    <td style="text-align: center; max-width: 80px;">
                                        <button *ngIf="currentFormMode==='Add'||currentFormMode==='Edit'" type="text"
                                        class="btn btn-danger text-center" (click)="removeDeductions(obj)"
                                        [disabled]="currentAction==='loading'"
                                        style="font-weight: bold; width: 80px;">{{"Remove"
                                        | translate}}</button>
                                    </td>
                                    <td>
                                        {{obj.SalaryItemName}}
                                    </td>
                                    <td>{{obj.Amount}}</td>
                                </tr>
                                <tr *ngIf="currentFormMode==='Add'||currentFormMode==='Edit'" type="text">
                                    <td style="text-align: center; max-width: 80px;">
                                        <button class="btn btn-info text-center" (click)="addDeductions()"
                                        [disabled]="DeductionsForm.invalid || currentAction==='loading'"
                                        style="font-weight: bold; width: 80px;">{{"Add"
                                        | translate}}</button>
                                    </td>
                                    <td>
                                        <div>
                                            <div class="form-group form-primary">
                                                <!-- <label class="float-label push righ">{{"Salary Item" | translate}}</label> -->
                                                <select  formControlName="SalaryItemId"  id="inputState" class="form-control" >
                                                    <option disabled selected [value]="0">{{'Salary Item' | translate}}</option>
                                                    <option *ngFor="let item of deducationsItems" [value]="item.Id">{{item.ItemName}}</option>
                                                </select> 
                                             
                                                <span class="form-bar"></span>
                                            </div>
                                   
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <div class="form-group form-primary">
                                                <!-- <label class="float-label push righ">{{"Amount" | translate}}</label> -->
                                                <input *ngIf="currentFormMode==='Add'||currentFormMode==='Edit'" type="number" name="Date"
                                                class="form-control" formControlName="Amount"
                                                placeholder='{{"Amount" | translate}}'>
                                            <label *ngIf="currentFormMode==='Info'" class="form-control" type="text"
                                                name="Date">{{modelObject.Amount}}</label>
                                          
                                                <span class="form-bar"></span>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <thead class="thead-light">
                                <th></th>
                                <th></th>
                                <th></th>
                            </thead>
                        </table>
                    </form>
                </div>

            </div>
        </form>
         
        <div class="col-md-12">
            <label for="inputEmail4">{{ 'Documents' | translate }}</label>
            <upload-contract-file *ngIf="currentFormMode==='Add'||currentFormMode==='Edit'" [(url)]=picurl (urlChange)="setFileToList()" [editmode]=editEnabled></upload-contract-file>
            <table class="table">
               <tbody>
                <tr *ngFor="let file of files;let i = index">
                    <td>#{{i+1}}</td>
                    <td><a href="{{file.FileUrl}}" target="_blank" style="cursor: pointer;" download="">{{"Download"|translate}}</a></td>
                    <td>
                        <a href="javascript:" (click)="deleteFile(file)"><i class="simple-icon-trash"></i></a>
                    </td>
                </tr>
               </tbody>
            </table>
        </div>
    </div>
</div>
<div bsModal #delModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">
    <div class="modal-dialog modal-sm">
        <div class="modal-content"  >
            <button type="button" class="close pull-right" aria-label="Close" (click)="delModal.hide()"
                style="margin-right: 20px; margin-left: 20px; margin-top: 10px; width: 20px; display: inline;">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-header" style="display: inline; padding: 0px 0px 5px 0px;">
                <h4 id="dialog-child-name" class="modal-title pull-left"  style="text-align: center;">
                    {{"ConfirmDel"+translationName |
                    translate}}</h4>
            </div>
            <div class="modal-body">
                <div class="form-group form-primary" *ngIf="deletionList.length < ogData.length || deletionList.length === 1" style="text-align: center;">
                    <div *ngFor="let item of deletionList;" style="text-align: center;">
                        <label type="text">- {{ContractCode}}</label>
                        <span class="form-bar"></span>
                    </div>
                </div>
                <div class="form-group form-primary" style="text-align: center;" *ngIf="deletionList.length > 1 && 
                    deletionList.length === ogData.length">
                    <label type="text">
                        <h1 style="font-weight: bolder; text-align: center;">{{translationName+"DeleteAll" | translate}}</h1>
                    </label>
                    <span class="form-bar"></span>
                </div>

                <div style="margin-left: 5px; text-align: center; margin-top: 30px;">
                    <button class="btn btn-danger" id="smallModalDeleteButton" (click)="deleteObject()"
                        [disabled]="currentAction==='loading'"
                        style="font-weight: bold; width: 100%; text-align: center;">{{"Delete" | translate}}</button>
                    <button class="btn btn-light" (click)="delModal.hide()"
                        style="font-weight: bold; width: 100%; text-align: center; margin-top: 10px;">{{"Close" |
                        translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="currentScreen === 'Table'" class="FormScreen" class="tableScreen">
    <div id="divtop" style="display: block;height: 47px; width: 100%;">
        <div id="divsearchmiddle" style="display: inline-block; width: 70%;
        height: inherit;  text-align: center; padding: 0px; margin: 0px;">
            <div class="search" style="display: inline-block; margin-left: 50px; margin-right: 50px;">
                <div class="d-inline-block">
                    <div class="search-sm d-inline-block mr-1 mb-1 align-top" style="width: 600px;">
                        <input type="search" [(ngModel)]="SearchTerm" (keyup)="changeFilter()" (change)="changeFilter()"
                            class="form-control input-sm full-data-search" placeholder="{{translationName+' Search' | translate}}">
                    </div>
                </div>
            </div>
        </div>
    
        <div id="divsearchleft" style="display: inline-block; width: 30%; height: inherit;">
            <div style="display: block; height: 30%; ">
                &nbsp;
            </div>
            <div class="search" style="display: block; height: 70%; text-align: center;">
                <button class="btn btn-primary d-inline-block"
                    style="font-weight: bold; text-align: center; padding: 0px; width: 150px; height: 30px;"
                    (click)="showAddNewModalRef()" [disabled]="currentAction==='loading'">{{"Add"+translationName |
                    translate}}</button>
    
            </div>
        </div>
    
    </div>
    
        
    <div id="divmiddle" style="display: block; margin-top: 5px;">
    
        <div style="width: 100%;">
            <div>
                <table class="table">
                    <thead class="thead-light">
                        <th style="text-align: center; padding:5px;">
                            <div class=" text-zero" style="display: inline-block;">
                                <div class="top-right-button-container2 btn-group" dropdown>
                                    <div class="btn btn-primary pl-4 pr-0 check-button">
                                        <label class="custom-control custom-checkbox mb-0 d-inline-block"
                                            style="width: 10px;">
                                            <input type="checkbox" class="custom-control-input"
                                                [checked]="selectAllState==='checked'"
                                                [indeterminate]="selectAllState === 'indeterminate'"
                                                (change)="selectAll($event)">
                                            <span class="custom-control-label">&nbsp;</span>
                                        </label>
                                    </div>
                                    <button id="button-split" type="button" dropdownToggle style="width: 10px;"
                                        class="btn btn-primary dropdown-toggle dropdown-toggle-split"
                                        aria-controls="dropdown-split">
                                        <span class="caret"></span>
                                    </button>
                                    <ul id="dropdown-split" *dropdownMenu class="dropdown-menu dropdown-menu-right"
                                        role="menu" aria-labelledby="button-split">
                                        <li role="menuitem"><a class="dropdown-item">
                                                <button class="btn btn-danger d-inline-block"
                                                    style=" font-weight: bold; text-align: center; padding: 0px; width: 150px; height: 30px;"
                                                    (click)="showDelModal('delMulti')"
                                                    [disabled]="selected.length===0 || currentAction==='loading'">{{"DeleteSelected"
                                                    |
                                                    translate}}</button></a></li>
                                    </ul>
                                </div>
                            </div>
                        </th>
                        <th class="sorticon" (click)="changeSort(getSort('ContractCode'))" [ngClass]="(itemOrder.label==='ContractCode')?'sortkey sorticon':'sorticon'">
                            <div [ngClass]="{'iconsminds-up---down': (itemOrder.label!='ContractCode'), 
                            'iconsminds-down': ((itemOrder.label==='ContractCode') && orderAsc === true),
                            'iconsminds-up': ((itemOrder.label==='ContractCode') && orderAsc === false)}"></div></th>
                        <th class="sortOption" (click)="changeSort(getSort('ContractCode'))" 
                        [ngClass]="{'sortkey': (itemOrder.label==='ContractCode')}">
                                {{"Code" | translate}}
                        </th>
                        <th class="sorticon" (click)="changeSort(getSort('Employee'))" [ngClass]="(itemOrder.label==='Employee')?'sortkey sorticon':'sorticon'">
                            <div [ngClass]="{'iconsminds-up---down': (itemOrder.label!='Employee'), 
                            'iconsminds-down': ((itemOrder.label==='Employee') && orderAsc === true),
                            'iconsminds-up': ((itemOrder.label==='Employee') && orderAsc === false)}"></div></th>
                        <th class="sortOption" (click)="changeSort(getSort('Employee'))" 
                        [ngClass]="{'sortkey': (itemOrder.label==='Employee')}">
                                {{"Employee" | translate}}
                        </th>
                        <th class="sorticon" (click)="changeSort(getSort('StartDate'))" [ngClass]="(itemOrder.label==='StartDate')?'sortkey sorticon':'sorticon'">
                            <div [ngClass]="{'iconsminds-up---down': (itemOrder.label!='StartDate'), 
                            'iconsminds-down': ((itemOrder.label==='StartDate') && orderAsc === true),
                            'iconsminds-up': ((itemOrder.label==='StartDate') && orderAsc === false)}"></div></th>
                        <th class="sortOption" (click)="changeSort(getSort('StartDate'))" 
                        [ngClass]="{'sortkey': (itemOrder.label==='StartDate')}">
                                {{"Start Date" | translate}}
                        </th>
                  
                        <th class="sorticon" (click)="changeSort(getSort('BasicSalary'))" [ngClass]="(itemOrder.label==='BasicSalary')?'sortkey sorticon':'sorticon'">
                            <div [ngClass]="{'iconsminds-up---down': (itemOrder.label!='BasicSalary'), 
                            'iconsminds-down': ((itemOrder.label==='BasicSalary') && orderAsc === true),
                            'iconsminds-up': ((itemOrder.label==='BasicSalary') && orderAsc === false)}"></div></th>
                        <th class="sortOption" (click)="changeSort(getSort('BasicSalary'))" 
                        [ngClass]="{'sortkey': (itemOrder.label==='BasicSalary')}">
                                {{"Basic Salary" | translate}}
                        </th>
                        <th class="sorticon" (click)="changeSort(getSort('Status'))" [ngClass]="(itemOrder.label==='Status')?'sortkey sorticon':'sorticon'">
                            <div [ngClass]="{'iconsminds-up---down': (itemOrder.label!='Status'), 
                            'iconsminds-down': ((itemOrder.label==='Status') && orderAsc === true),
                            'iconsminds-up': ((itemOrder.label==='Status') && orderAsc === false)}"></div></th>
                        <th class="sortOption" (click)="changeSort(getSort('Status'))" 
                        [ngClass]="{'sortkey': (itemOrder.label==='Status')}">
                                {{"Status" | translate}}
                        </th>
                        
                        <th [colSpan]="2" style="text-align: center;">
                            <div id="displayOptions" style="
                            display: inline; margin-top: 10px; margin-left: 5px; margin-right: 5px;">
                                <span class="text-muted text-small" style=" width: 50px; height: 40px;">
                                    {{"ResultCount" | translate}}
                                </span>
                                <span dropdown class="d-inline-block position-relative">
                                    <button class="btn btn-outline-dark btn-xs dropdown-toggle" type="button"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" dropdownToggle>
                                        {{itemsPerPage}}
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-sm text-right"
                                        *dropdownMenu>
                                        <li role="menuitem" *ngFor="let item of itemOptionsPerPage"><a
                                                [ngClass]="item === itemsPerPage ? 'dropdown-item active' : 'dropdown-item'"
                                                (click)="paginateData(item, 1)">{{item}}</a></li>
                                    </div>
                                </span>
                            </div>
                        </th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let obj of paginatedData;  let i=index">
                            <td style="text-align: center;">
                                <div class="itemCheck mb-0 custom-checkbox custom-control" style="display: inline-block;">
                                    <input type="checkbox" class="custom-control-input" [id]="'customCheck'+i"
                                        [checked]="isSelected(obj)" (change)="onSelect(obj)">
                                    <label class="custom-control-label" [for]="'customCheck'+i"></label>
                                </div>
                            </td>
                            <td colspan="2">{{obj.ContractCode}}</td>
                            <td colspan="2">{{obj.EmployeeName}}</td>
                            <td colspan="2">{{obj.StartDate|date:'dd/MM/yyyy'}}</td>
                            <!-- <td colspan="2">{{obj.EndDate|date:'dd/MM/yyyy'}}</td> -->
                            <td colspan="2">{{obj.BasicSalary}}</td>
                            <td colspan="2">{{obj.Status | translate}}</td>
                            <td style="text-align: center">
                                <i
                                  class="simple-icon-info"
                                  (click)="showInfoModalRef(obj.Id)"                      
                                  tooltip="{{ 'Info' | translate }}"
                                  placement="auto"
                                  style="
                                    font-size: 20px;
                                    font-weight: bolder;
                                    cursor: pointer;
                                    vertical-align: middle;
                                    color: #17a2b8;
                                    margin: 10px;
                                  "
                                  
                                 
                                ></i>
                                <i
                                class="simple-icon-pencil"
                                (click)="showEditModalRef(obj.Id)"
                                tooltip="{{ 'Edit' | translate }}"
                                placement="auto"
                                style="
                                  font-size: 20px;
                                  font-weight: bolder;
                                  cursor: pointer;
                                  vertical-align: middle;
                                  color: #ffc107;
                                  margin: 10px;
                                "
                               
                               
                              ></i>
                              <i
                              class="simple-icon-close"
                              (click)="showDelModal(obj.Id)"
                              tooltip="{{ 'Delete' | translate }}"
                              placement="auto"
                              style="
                                font-size: 20px;
                                font-weight: bolder;
                                cursor: pointer;
                                vertical-align: middle;
                                color: #dc3545;
                                margin: 10px;
                              "
                            ></i>
                              </td>
                        </tr>
                    </tbody>
                    <thead class="thead-light">
                
                   
                     
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </thead>
                </table>
            </div>
        </div>
    </div>
    
    <div id="divbottom" style="display: block; height: 47px;">
    
        <div id="divsearchright" style="float: right;  width: 33.33%; padding: 0px;">&nbsp;</div>
    
        <div id="divsearchmiddle" style="display: inline-block; width: 33.33%; height: 100%;">
    
            <div style="text-align: center;">
                <bdo dir="ltr">
                    <pagination class="pagination-sm2" style="padding: 0px, 0px, 0px, 0px; margin: 0px, 0px, 0px, 0px;
                    display: inline-block;" [(ngModel)]="currentPage" [boundaryLinks]="true"
                        [totalItems]="filteredData.length" [itemsPerPage]="itemsPerPage"
                        [customFirstTemplate]="firstTemplate" [customLastTemplate]="lastTemplate"
                        [customPreviousTemplate]="prevTemplate" [customNextTemplate]="nextTemplate" [maxSize]="5"
                        (pageChanged)="changePage($event)">
                    </pagination>
                </bdo>
            </div>
        </div>
    
        <div id="divsearchleft" style="float: left; width: 33.33%; height: 100%;">&nbsp;</div>
    
    </div>
</div>

<div>

</div>

<ng-template #nextTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i *ngIf="lang==='en-US'" class='simple-icon-arrow-right'></i>
    <i *ngIf="lang==='ar-EG'" class='simple-icon-arrow-left'></i>
</ng-template>

<ng-template #prevTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i *ngIf="lang==='en-US'" class='simple-icon-arrow-left'></i>
    <i *ngIf="lang==='ar-EG'" class='simple-icon-arrow-right'></i>
</ng-template>

<ng-template #lastTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i class='simple-icon-control-end'></i>
</ng-template>

<ng-template #firstTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i class='simple-icon-control-start'></i>
</ng-template>


