<!--component html goes here -->
<!--component html goes here -->
<simple-notifications></simple-notifications>
<form [formGroup]="ExpensesForm" *ngIf="ExpensesForm" class="card ">
    <div class="form-group Card_Padding">
        <br>
        <h3>{{ 'Suppliers' | translate }}</h3>
        <br>
        <div class="row">
            <div class=" col-md-4">
            
                <label for="inputState">{{ 'CountryId' | translate }}</label>
                <select formControlName="CountryIdDropdwon" id="inputState" class="form-control">
                    <option *ngFor="let item of CountryList " [value]="item.Id">{{item.NameEn}}</option>
                </select>
            </div>
            <!-- <div class=" col-md-4">
                <label for="inputState">{{ 'CityName' | translate }}</label>
                <select formControlName="CitiyNameDropdwon" id="inputState" class="form-control">
                    <option *ngFor="let item of CityList" [value]="item.Id">{{item.NameEn}}</option>

                </select>
            </div> -->
            <div class=" col-md-4">
                <label for="inputState">{{ 'SupplierType' | translate }}</label>
                <select formControlName="SupplierTypeDropdwon" id="inputState" class="form-control">
                    <option *ngFor="let item of supplierTypeModel" [value]="item.Id">{{item.NameEn}}</option>

                </select>
            </div>
        </div>
        <br>
        <div class="form-row">
            <div class="form-group col-md-6">
                <label>{{ 'NameAr' | translate }}</label>
                <input formControlName="NameArInputForm" type="text" class="form-control">
            </div>
            <div class="form-group col-md-6">
                <label>{{ 'NameEn' | translate }}</label>
                <input formControlName="NameEnInputForm" type="text" class="form-control">
            </div>
        </div>
        <div class="form-group ">
            <label>{{'Address'| translate}}</label>
            <input formControlName="AddressInputForm" type="text" class="form-control">
        </div>
        <div class="form-row">
            <div class="form-group col-md-6">
                <label>{{'Phone'| translate}}</label>
                <input formControlName="PhoneInputForm" type="text" class="form-control">
            </div>
            <div class="form-group col-md-6">
                <label>{{'PostalCode'| translate}}</label>
                <input formControlName="PostalCodeInputForm" type="text" class="form-control">
            </div>

        </div>
     
        <br>
        <div *ngIf="modelState != 'Show'" class="text-center">
            <button type="button" class="btn btn-primary" (click)="onClick()" [disabled]="ExpensesForm.invalid">{{ titleButton | translate }}</button>{{' '}}

        </div>
    </div>
    <!-- [disabled]="InvoiceForm.invalid" -->
    <!-- <div class="text-center">
        <button type="button" class="btn btn-primary" awPreviousStep disabled>{{ 'wizard.prev' | translate
            }}</button>{{" "}}
        <button type="button"class="btn btn-primary" awNextStep>{{ 'wizard.next' | translate }}</button>
    </div> -->
</form>
