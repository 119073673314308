<app-side-popup #sideModalRef>
    <div class="modal-content"
        style="width: 90%; text-align: center; margin-top: 20px; margin-left: auto; margin-right: auto;">
        <div style="align-content: center; display: inline-block;">
            <div class="modal-header" style="display: block; padding: 0px 0px 5px 0px; text-align: center; ">
                <h1 id="dialog-child-name" class="modal-title">
                    {{tableEngine.currentFormMode+translationName | translate}}</h1>
            </div>
            <div class="modal-body">
                <form class="md-float-material form-material" [formGroup]="mainForm">
                    <div class="form-group form-primary">
                        <label class="float-label push righ">{{"BranchNameAr" | translate}}</label>
                        <input *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                            type="text" name="NameAr" class="form-control" formControlName="NameAr"
                            placeholder='{{"BranchNameAr" | translate}}'>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="NameAr">{{modelObject.NameAr}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('NameAr').hasError('required') || mainForm.get('NameAr').invalid) && ( mainForm.get('NameAr').dirty ||  mainForm.get('NameAr').touched)">
                            {{"BranchNameArRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>

                    <div class="form-group form-primary">
                        <label class="float-label push righ">{{"BranchNameEn" | translate}}</label>
                        <input *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                            type="text" name="NameEn" class="form-control" formControlName="NameEn"
                            placeholder='{{"BranchNameEn" | translate}}'>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="NameEn">{{modelObject.NameEn}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('NameEn').hasError('required') || mainForm.get('NameEn').invalid) && ( mainForm.get('NameEn').dirty ||  mainForm.get('NameEn').touched)">
                            {{"BranchNameEnRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>


                    <div class="form-group form-primary">
                        <label class="float-label push righ">{{"BranchCountry" | translate}}</label>
                        <div *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'">
                            <ng-select appearance="outline" name="CountryId" formControlName="CountryId"
                                placeholder='{{"BranchCountry" | translate}}' #BranchCountrySelect labelForId="txtycd"
                                [searchable]="true">
                                <ng-option *ngFor="let country of countryList" [value]="country.Id">
                                    {{lang==='ar-EG'?country.NameAr:country.NameEn}}</ng-option>
                            </ng-select>
                        </div>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="CountryId">{{lang==='ar-EG'?getCountryById(modelObject.CountryId).NameAr:getCountryById(modelObject.CountryId).NameEn}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('CountryId').hasError('required') || mainForm.get('CountryId').invalid) && ( mainForm.get('CountryId').dirty ||  mainForm.get('CountryId').touched)">
                            {{"BranchCountryRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>

                    <div class="form-group form-primary">
                        <label class="float-label push righ">{{"BranchGovernorate" | translate}}</label>
                        <input *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                            type="text" name="Governorate" class="form-control" formControlName="Governorate"
                            placeholder='{{"BranchGovernorate" | translate}}'>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="Governorate">{{modelObject.Governorate}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('Governorate').hasError('required') || mainForm.get('Governorate').invalid) && ( mainForm.get('Governorate').dirty ||  mainForm.get('Governorate').touched)">
                            {{"BranchGovernorateRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>
                    <div class="form-group form-primary">
                        <label class="float-label push righ">{{"City" | translate}}</label>
                        <input *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                            type="text" name="Governorate" class="form-control" formControlName="CityName"
                            placeholder='{{"CityName" | translate}}'>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="CityName">{{modelObject.CityName}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('CityName').hasError('required') || mainForm.get('CityName').invalid) && ( mainForm.get('CityName').dirty ||  mainForm.get('CityName').touched)">
                            {{"CityNameRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>
                    <div class="form-group form-primary">
                        <label class="float-label push righ">{{"BranchStreet" | translate}}</label>
                        <input *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                            type="text" name="Street" class="form-control" formControlName="Street"
                            placeholder='{{"BranchStreet" | translate}}'>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="Street">{{modelObject.Street}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('Street').hasError('required') || mainForm.get('Street').invalid) && ( mainForm.get('Street').dirty ||  mainForm.get('Street').touched)">
                            {{"BranchStreetRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>

                    <div class="form-group form-primary">
                        <label class="float-label push righ">{{"BranchBuildingNo" | translate}}</label>
                        <input *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                            type="text" name="BuildingNo" class="form-control" formControlName="BuildingNo"
                            placeholder='{{"BranchBuildingNo" | translate}}'>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="BuildingNo">{{modelObject.BuildingNo}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('BuildingNo').hasError('required') || mainForm.get('BuildingNo').invalid) && ( mainForm.get('BuildingNo').dirty ||  mainForm.get('BuildingNo').touched)">
                            {{"BranchBuildingNoRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>

                    <div class="form-group form-primary">
                        <label class="float-label push righ">{{"BranchNotableMark" | translate}}</label>
                        <input *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                            type="text" name="NotableMark" class="form-control" formControlName="NotableMark"
                            placeholder='{{"BranchNotableMark" | translate}}'>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="NotableMark">{{modelObject.NotableMark}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('NotableMark').hasError('required') || mainForm.get('NotableMark').invalid) && ( mainForm.get('NotableMark').dirty ||  mainForm.get('NotableMark').touched)">
                            {{"BranchNotableMarkRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>

                    <!-- <div class="form-group form-primary">
                        <label class="float-label push righ">{{"BranchPostalCode" | translate}}</label>
                        <input *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'" type="text" name="PostalCode"
                            class="form-control" formControlName="PostalCode"
                            placeholder='{{"BranchPostalCode" | translate}}'>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="PostalCode">{{modelObject.PostalCode}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('PostalCode').hasError('required') || mainForm.get('PostalCode').invalid) && ( mainForm.get('PostalCode').dirty ||  mainForm.get('PostalCode').touched)">
                            {{"BranchPostalCodeRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div> -->



                    <div style="margin-left: 5px; text-align: center; margin-top: 30px;">
                        <button *ngIf="tableEngine.currentFormMode==='Add'"
                            [disabled]="mainForm.invalid || tableEngine.currentAction==='loading'"
                            id="smallModalSubmitButton" class="btn btn-secondary" (click)="addObject()"
                            style="font-weight: bold; width: 100%; text-align: center;">{{"Add" |
                            translate}}</button>
                        <button *ngIf="tableEngine.currentFormMode==='Edit'"
                            [disabled]="mainForm.invalid || tableEngine.currentAction==='loading'"
                            id="smallModalSubmitButton" class="btn btn-secondary" (click)="editObject()"
                            style="font-weight: bold; width: 100%; text-align: center;">{{"Edit"
                            | translate}}</button>
                        <button class="btn btn-light" (click)="sideModalRef.modalRef.hide()"
                            style="font-weight: bold; width: 100%; text-align: center; margin-top: 10px;">{{"Close" |
                            translate}}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</app-side-popup>


<div bsModal #delModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">
    <div class="modal-dialog modal-sm">
        <div class="modal-content"  >
            <button type="button" class="close pull-right" aria-label="Close" (click)="delModal.hide()"
                style="margin-right: 20px; margin-left: 20px; margin-top: 10px; width: 20px; display: inline;">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-header" style="display: inline; padding: 0px 0px 5px 0px;">
                <h4 id="dialog-child-name" class="modal-title pull-left"  style="text-align: center;">
                    {{"ConfirmDel"+translationName |
                    translate}}</h4>
            </div>
            <div class="modal-body">
                <div class="form-group form-primary" *ngIf="tableEngine.deletionList.length < tableEngine.ogData.length 
                || tableEngine.deletionList.length === 1" style="text-align: center;">
                    <div *ngFor="let item of tableEngine.deletionList;" style="text-align: center;">
                        <label type="text">- {{lang==='ar-EG'?item.NameAr:item.NameEn}}</label>
                        <span class="form-bar"></span>
                    </div>
                </div>
                <div class="form-group form-primary" style="text-align: center;" *ngIf="tableEngine.deletionList.length > 1 && 
                    tableEngine.deletionList.length === tableEngine.ogData.length">
                    <label type="text">
                        <h1 style="font-weight: bolder; text-align: center;">{{translationName+"DeleteAll" | translate}}
                        </h1>
                    </label>
                    <span class="form-bar"></span>
                </div>

                <div style="margin-left: 5px; text-align: center; margin-top: 30px;">
                    <button class="btn btn-danger" id="smallModalDeleteButton" (click)="deleteObject()"
                        [disabled]="tableEngine.currentAction==='loading'"
                        style="font-weight: bold; width: 100%; text-align: center;">{{"Delete" | translate}}</button>
                    <button class="btn btn-light" (click)="delModal.hide()"
                        style="font-weight: bold; width: 100%; text-align: center; margin-top: 10px;">{{"Close" |
                        translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="currentScreen === 'Form'" class="FormScreen">
    <a (click)="currentScreen='Table'">back</a>
</div>

<div *ngIf="currentScreen === 'Table'" class="FormScreen" class="tableScreen">
    <div id="divtop" style="display: block;height: 47px; width: 100%;">
        <div id="divsearchmiddle" style="display: inline-block; width: 70%;
        height: inherit;  text-align: center; padding: 0px; margin: 0px;">
            <div class="search" style="display: inline-block; margin-left: 50px; margin-right: 50px;">
                <div class="d-inline-block">
                    <div class="search-sm d-inline-block mr-1 mb-1 align-top" style="width: 600px;">
                        <input type="search" [(ngModel)]="tableEngine.SearchTerm" (keyup)="tableEngine.changeFilter()"
                            (change)="tableEngine.changeFilter()" class="form-control input-sm full-data-search"
                            placeholder="{{translationName+'Search' | translate}}">
                    </div>
                </div>
            </div>
        </div>

        <div id="divsearchleft" style="display: inline-block; width: 30%; height: inherit;">
            <div style="display: block; height: 30%; ">
                &nbsp;
            </div>
            <div class="search" style="display: block; height: 70%; text-align: center;">
                <button class="btn btn-primary d-inline-block"
                    style="font-weight: bold; text-align: center; padding: 0px; width: 150px; height: 30px;"
                    (click)="showModal(null,'Add')"
                    [disabled]="tableEngine.currentAction==='loading'">{{"Add"+translationName |
                    translate}}</button>

            </div>
        </div>

    </div>


    <div id="divmiddle" style="display: block; margin-top: 5px;">

        <div style="width: 100%;">
            <div>
                <table class="table">
                    <thead class="thead-light">
                        <th style="text-align: center; padding:5px;">
                            <div class=" text-zero" style="display: inline-block;">
                                <div class="top-right-button-container2 btn-group" dropdown>
                                    <div class="btn btn-primary pl-4 pr-0 check-button">
                                        <label class="custom-control custom-checkbox mb-0 d-inline-block"
                                            style="width: 10px;">
                                            <input type="checkbox" class="custom-control-input"
                                                [checked]="tableEngine.selectAllState==='checked'"
                                                [indeterminate]="tableEngine.selectAllState === 'indeterminate'"
                                                (change)="tableEngine.selectAll($event)">
                                            <span class="custom-control-label">&nbsp;</span>
                                        </label>
                                    </div>
                                    <button id="button-split" type="button" dropdownToggle style="width: 10px;"
                                        class="btn btn-primary dropdown-toggle dropdown-toggle-split"
                                        aria-controls="dropdown-split">
                                        <span class="caret"></span>
                                    </button>
                                    <ul id="dropdown-split" *dropdownMenu class="dropdown-menu dropdown-menu-right"
                                        role="menu" aria-labelledby="button-split">
                                        <li role="menuitem"><a class="dropdown-item">
                                                <button class="btn btn-danger d-inline-block"
                                                    style=" font-weight: bold; text-align: center; padding: 0px; width: 150px; height: 30px;"
                                                    (click)="tableEngine.showDelModal('delMulti')"
                                                    [disabled]="tableEngine.selected.length===0 || tableEngine.currentAction==='loading'">{{"DeleteSelected"
                                                    |
                                                    translate}}</button></a></li>
                                    </ul>
                                </div>
                            </div>
                        </th>
                        <th class="sorticon" (click)="tableEngine.changeSort(tableEngine.getSort('BranchName'))"
                            [ngClass]="(itemOrder.label==='BranchName')?'sortkey sorticon':'sorticon'">
                            <div [ngClass]="{'iconsminds-up---down': (itemOrder.label!='BranchName'), 
                            'iconsminds-down': ((itemOrder.label==='BranchName') && tableEngine.orderAsc === true),
                            'iconsminds-up': ((itemOrder.label==='BranchName') && tableEngine.orderAsc === false)}">
                            </div>
                        </th>
                        <th class="sortOption" (click)="tableEngine.changeSort(tableEngine.getSort('BranchName'))"
                            [ngClass]="{'sortkey': (itemOrder.label==='BranchName')}">
                            {{"BranchName" | translate}}
                        </th>
                        <!-- <th class="sorticon" (click)="tableEngine.changeSort(tableEngine.getSort('BranchCountry'))" [ngClass]="(itemOrder.label==='BranchCountry')?'sortkey sorticon':'sorticon'">
                            <div [ngClass]="{'iconsminds-up---down': (itemOrder.label!='BranchCountry'), 
                            'iconsminds-down': ((itemOrder.label==='BranchCountry') && tableEngine.orderAsc === true),
                            'iconsminds-up': ((itemOrder.label==='BranchCountry') && tableEngine.orderAsc === false)}"></div></th>
                        <th class="sortOption" (click)="tableEngine.changeSort(tableEngine.getSort('BranchCountry'))" 
                        [ngClass]="{'sortkey': (itemOrder.label==='BranchCountry')}">
                                {{"BranchCountry" | translate}}
                        </th> -->
                        <th class="sorticon" (click)="tableEngine.changeSort(tableEngine.getSort('BranchGovernorate'))"
                            [ngClass]="(itemOrder.label==='BranchGovernorate')?'sortkey sorticon':'sorticon'">
                            <div
                                [ngClass]="{'iconsminds-up---down': (itemOrder.label!='BranchGovernorate'), 
                            'iconsminds-down': ((itemOrder.label==='BranchGovernorate') && tableEngine.orderAsc === true),
                            'iconsminds-up': ((itemOrder.label==='BranchGovernorate') && tableEngine.orderAsc === false)}">
                            </div>
                        </th>
                        <th class="sortOption"
                            (click)="tableEngine.changeSort(tableEngine.getSort('BranchGovernorate'))"
                            [ngClass]="{'sortkey': (itemOrder.label==='BranchGovernorate')}">
                            {{"BranchGovernorate" | translate}}
                        </th>
                        <th class="sorticon" (click)="tableEngine.changeSort(tableEngine.getSort('BranchBuildingNo'))"
                            [ngClass]="(itemOrder.label==='BranchBuildingNo')?'sortkey sorticon':'sorticon'">
                            <div
                                [ngClass]="{'iconsminds-up---down': (itemOrder.label!='BranchBuildingNo'), 
                            'iconsminds-down': ((itemOrder.label==='BranchBuildingNo') && tableEngine.orderAsc === true),
                            'iconsminds-up': ((itemOrder.label==='BranchBuildingNo') && tableEngine.orderAsc === false)}">
                            </div>
                        </th>
                        <th class="sortOption" (click)="tableEngine.changeSort(tableEngine.getSort('BranchBuildingNo'))"
                            [ngClass]="{'sortkey': (itemOrder.label==='BranchBuildingNo')}">
                            {{"BranchBuildingNo" | translate}}
                        </th>
                        <th class="sorticon" (click)="tableEngine.changeSort(tableEngine.getSort('BranchStreet'))"
                            [ngClass]="(itemOrder.label==='BranchStreet')?'sortkey sorticon':'sorticon'">
                            <div [ngClass]="{'iconsminds-up---down': (itemOrder.label!='BranchStreet'), 
                            'iconsminds-down': ((itemOrder.label==='BranchStreet') && tableEngine.orderAsc === true),
                            'iconsminds-up': ((itemOrder.label==='BranchStreet') && tableEngine.orderAsc === false)}">
                            </div>
                        </th>
                        <th class="sortOption" (click)="tableEngine.changeSort(tableEngine.getSort('BranchStreet'))"
                            [ngClass]="{'sortkey': (itemOrder.label==='BranchStreet')}">
                            {{"BranchStreet" | translate}}
                        </th>
                        <th class="sorticon" (click)="tableEngine.changeSort(tableEngine.getSort('BranchNotableMark'))"
                            [ngClass]="(itemOrder.label==='BranchNotableMark')?'sortkey sorticon':'sorticon'">
                            <div
                                [ngClass]="{'iconsminds-up---down': (itemOrder.label!='BranchNotableMark'), 
                            'iconsminds-down': ((itemOrder.label==='BranchNotableMark') && tableEngine.orderAsc === true),
                            'iconsminds-up': ((itemOrder.label==='BranchNotableMark') && tableEngine.orderAsc === false)}">
                            </div>
                        </th>
                        <th class="sortOption"
                            (click)="tableEngine.changeSort(tableEngine.getSort('BranchNotableMark'))"
                            [ngClass]="{'sortkey': (itemOrder.label==='BranchNotableMark')}">
                            {{"BranchNotableMark" | translate}}
                        </th>
                        <!-- <th class="sorticon" (click)="tableEngine.changeSort(tableEngine.getSort('BranchPostalCode'))" [ngClass]="(itemOrder.label==='BranchPostalCode')?'sortkey sorticon':'sorticon'">
                            <div [ngClass]="{'iconsminds-up---down': (itemOrder.label!='BranchPostalCode'), 
                            'iconsminds-down': ((itemOrder.label==='BranchPostalCode') && tableEngine.orderAsc === true),
                            'iconsminds-up': ((itemOrder.label==='BranchPostalCode') && tableEngine.orderAsc === false)}"></div></th>
                        <th class="sortOption" (click)="tableEngine.changeSort(tableEngine.getSort('BranchPostalCode'))" 
                        [ngClass]="{'sortkey': (itemOrder.label==='BranchPostalCode')}">
                                {{"BranchPostalCode" | translate}}
                        </th> -->
                        <th></th>
                        <th [colSpan]="2" style="text-align: center;">
                            <div id="displayOptions" style="
                            display: inline; margin-top: 10px; margin-left: 5px; margin-right: 5px;">
                                <span class="text-muted text-small" style=" width: 50px; height: 40px;">
                                    {{"ResultCount" | translate}}
                                </span>
                                <span dropdown class="d-inline-block position-relative">
                                    <button class="btn btn-outline-dark btn-xs dropdown-toggle" type="button"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                        dropdownToggle>
                                        {{tableEngine.itemsPerPage}}
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-sm text-right"
                                        *dropdownMenu>
                                        <li role="menuitem" *ngFor="let item of tableEngine.itemOptionsPerPage"><a
                                                [ngClass]="item === tableEngine.itemsPerPage ? 'dropdown-item active' : 'dropdown-item'"
                                                (click)="tableEngine.paginateData(item, 1)">{{item}}</a></li>
                                    </div>
                                </span>
                            </div>
                        </th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let obj of tableEngine.paginatedData;  let i=index">
                            <td style="text-align: center;">
                                <div class="itemCheck mb-0 custom-checkbox custom-control"
                                    style="display: inline-block;">
                                    <input type="checkbox" class="custom-control-input" [id]="'customCheck'+i"
                                        [checked]="tableEngine.isSelected(obj)" (change)="tableEngine.onSelect(obj)">
                                    <label class="custom-control-label" [for]="'customCheck'+i"></label>
                                </div>
                            </td>
                            <td colspan="2">{{lang==='ar-EG'?obj.NameAr:obj.NameEn}}</td>
                            <!-- <td colspan="2">{{lang==='ar-EG'?getCountryById(obj.CountryId).NameAr:getCountryById(obj.CountryId).NameEn}}</td> -->
                            <td colspan="2">{{obj.Governorate}}</td>
                            <td colspan="2">{{obj.Street}}</td>
                            <td colspan="2">{{obj.BuildingNo}}</td>
                            <td colspan="2">{{obj.NotableMark}}</td>
                            <!-- <td colspan="2">{{obj.PostalCode}}</td> -->
                            <td><button class="btn btn-info text-center" (click)="showModal(obj.Id, 'Info')"
                                    [disabled]="tableEngine.currentAction==='loading'"
                                    style="font-weight: bold; width: 100%;">{{"More"
                                    | translate}}</button></td>
                            <td><button class="btn btn-outline-warning text-center" (click)="showModal(obj.Id, 'Edit')"
                                    [disabled]="tableEngine.currentAction==='loading'"
                                    style="font-weight: bold; width: 100%;">{{"Edit"
                                    | translate}}</button></td>
                            <td><button class="btn btn-outline-danger text-center"
                                    (click)="tableEngine.showDelModal(obj.Id)"
                                    [disabled]="tableEngine.currentAction==='loading'"
                                    style="font-weight: bold; width: 100%">{{"Delete"
                                    | translate}}</button></td>
                        </tr>
                    </tbody>
                    <thead class="thead-light">
                        <th></th>
                        <th></th>
                        <!-- <th></th>
                        <th></th> -->
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <!-- <th></th>
                        <th></th> -->
                    </thead>
                </table>
            </div>
        </div>
    </div>

    <div id="divbottom" style="display: block; height: 47px;">

        <div id="divsearchright" style="float: right;  width: 33.33%; padding: 0px;">&nbsp;</div>

        <div id="divsearchmiddle" style="display: inline-block; width: 33.33%; height: 100%;">

            <div style="text-align: center;">
                <bdo dir="ltr">
                    <pagination class="pagination-sm2" style="padding: 0px, 0px, 0px, 0px; margin: 0px, 0px, 0px, 0px;
                    display: inline-block;" [(ngModel)]="currentPage" [boundaryLinks]="true"
                        [totalItems]="tableEngine.filteredData.length" [itemsPerPage]="tableEngine.itemsPerPage"
                        [customFirstTemplate]="firstTemplate" [customLastTemplate]="lastTemplate"
                        [customPreviousTemplate]="prevTemplate" [customNextTemplate]="nextTemplate" [maxSize]="5"
                        (pageChanged)="tableEngine.changePage($event)">
                    </pagination>
                </bdo>
            </div>
        </div>

        <div id="divsearchleft" style="float: left; width: 33.33%; height: 100%;">&nbsp;</div>

    </div>
</div>

<div>

</div>

<ng-template #nextTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i *ngIf="lang==='en-US'" class='simple-icon-arrow-right'></i>
    <i *ngIf="lang==='ar-EG'" class='simple-icon-arrow-left'></i>
</ng-template>

<ng-template #prevTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i *ngIf="lang==='en-US'" class='simple-icon-arrow-left'></i>
    <i *ngIf="lang==='ar-EG'" class='simple-icon-arrow-right'></i>
</ng-template>

<ng-template #lastTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i class='simple-icon-control-end'></i>
</ng-template>

<ng-template #firstTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i class='simple-icon-control-start'></i>
</ng-template>


