export class AccountsCostCentersModel{
    Id?: number;
    AccountId?: number;
    CostCenterId?: number;
    Percentage?: number;
    Auto?: boolean;
    IsDeleted?: boolean;
    AccountNameAr?:string;
    AccountNameEn?:string;
    constructor(item ?: AccountsCostCentersModel){
        item = item ? item : {};
        this.Id = item.Id ? item.Id : 0;
        this.AccountId = item.AccountId ? item.AccountId : 0;
        this.CostCenterId = item.CostCenterId ? item.CostCenterId : 0;
        this.Percentage = item.Percentage ? item.Percentage : 0;
        this.Auto = item.Auto ? item.Auto : false;
        this.IsDeleted = item.IsDeleted ? item.IsDeleted : false;
        this.AccountNameAr = item.AccountNameAr ? item.AccountNameAr : '';
        this.AccountNameEn = item.AccountNameEn ? item.AccountNameEn : '';
    }
}