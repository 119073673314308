import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CashierSessionModel } from "./CashierSessionModel";

@Injectable({
  providedIn: 'root'
})
export class CashierSessionService {
    constructor(private http: HttpClient) {
  
    }

    currentSession = new CashierSessionModel();
    
    debugData: CashierSessionModel [] = []
    // debugData: CashierSessionModel [] = [
    //   {
    //     Id:1, Code:1001, StartingNote:"desc1\nOrder1\n1", StartingCash:1000, Active:false,
    //     CashierId:1, CashierNameAr:"احمد سعيد", CashierNameEn:"Ahmad Saeed", 
    //     StartTime: new Date(2021, 0, 13, 9, 0, 0), EndTime: new Date(2021, 0, 13, 17, 0, 0),
    //     CashRegisterId:1, CashRegisterCode:1001, 
    //     CashRegisterBranchId:1, CashRegisterBranchNameAr:"فرع القاهرة", CashRegisterBranchNameEn:"Cairo Branch",
        
    //     Orders: [{
    //         Id:1, Code:1001, Note:"desc1\nOrder1\n1", Status:'Complete',
    //         CashierNameAr:"احمد سعيد", CashierNameEn:"Ahmad Saeed", 
    //         ClientId:1, ClientNameAr:'احمد', ClientNameEn:'Ahmad', IsRefund:false,
    //         ModifiedDate: new Date(2021, 0, 13), SessionId:1, SessionCode:1001, CashRegisterId:1, CashRegisterCode:1001,
    //         CashRegisterBranchId:1, CashRegisterBranchNameAr:"فرع القاهرة", CashRegisterBranchNameEn:"Cairo Branch",
            
    //         Items: [
    //           { Id:1, CashierOrderId: 1, ItemId:1, ItemNameAr: "منتج 1", ItemNameEn: "Item 1", 
    //           UnitId:1, UnitNameAr:"وحدة 1", UnitNameEn:"Unit 1", Note:"item\ndescription\n1", 
    //           UnitCost:1000, Quantity:1, TotalPrice:1000, DiscountValue:1, DiscountIsRate:true, NetPrice:990,
    //           AddedValueTax:138.6, GrossPrice: 1128.6 },
    
    //           { Id:2, CashierOrderId: 1, ItemId:2, ItemNameAr: "منتج 2", ItemNameEn: "Item 2", 
    //           UnitId:2, UnitNameAr:"وحدة 2", UnitNameEn:"Unit 2", Note:"item\ndescription\n2", 
    //           UnitCost:1000, Quantity:2, TotalPrice:2000, DiscountValue:2, DiscountIsRate:true, NetPrice:1960,
    //           AddedValueTax:274.4, GrossPrice: 2234.4 },
    //         ],
    
    //         ItemTotalPrices:3000, ItemTotalDiscounts:50, ItemTotalNetPrices: 2950, 
    //         ItemTotalTaxes:413, ItemTotalGrossPrices: 3363, TotalValue:3363,
    
    //         PaymentMethodId:1, PaymentMethodAr: "كاش", PaymentMethodEn:"Cash",
    //         CounterCashBeforeCheckout:1000, CounterCashAfterCheckout: 4363,
    //         PaidAmount: 3400, ChangeGiven:37,
    //       }
    //     ],

    //     OrderCount:1, RefundCount:0, SessionTotal: 3363,
    //     LeaveCash:4363, LeaveNote:"good\nday\n1",
    //     WorkShiftId:1, WorkShiftNameAr:"الصباح", WorkShiftNameEn:"الصباح",
    //     WorkShiftFrom: new Date(2021, 0, 13, 9, 0, 0), WorkShiftTo: new Date(2021, 0, 13, 17, 0, 0),
    //   },
    //   {
    //     Id:2, Code:1002, StartingNote:"desc2\nOrder2\n2", StartingCash:2000, Active:false,
    //     CashierId:2, CashierNameAr:"محمد فيصل", CashierNameEn:"Mohamad Faysal", 
    //     StartTime: new Date(2022, 1, 13, 15, 0, 0), EndTime: new Date(2022, 1, 13, 23, 0, 0),
    //     CashRegisterId:2, CashRegisterCode:1002, 
    //     CashRegisterBranchId:2, CashRegisterBranchNameAr:"فرع السعودية", CashRegisterBranchNameEn:"Saudi Branch",
        
    //     Orders: [
    //         {
    //           Id:2, Code:1002, Note:"desc2\nOrder2\n2", Status:'In-Delivery',
    //           CashierId:2, CashierNameAr:"محمد فيصل", CashierNameEn:"Mohamad Faysal", 
    //           ClientId:2, ClientNameAr:'فواز', ClientNameEn:'Fawaz', IsRefund:false,
    //           ModifiedDate: new Date(2022, 1, 13), SessionId:2, SessionCode:1002, CashRegisterId:2, CashRegisterCode:1002,
    //           CashRegisterBranchId:2, CashRegisterBranchNameAr:"فرع السعودية", CashRegisterBranchNameEn:"Saudi Branch",
              
    //           Items: [
    //             { Id:3, CashierOrderId: 2, ItemId:2, ItemNameAr: "منتج 2", ItemNameEn: "Item 2", 
    //             UnitId:2, UnitNameAr:"وحدة 1", UnitNameEn:"Unit 1", Note:"item\ndescription\n3", 
    //             UnitCost:1000, Quantity:3, TotalPrice:3000, DiscountValue:3, DiscountIsRate:true, NetPrice:2910,
    //             AddedValueTax:407.4, GrossPrice: 3317.4 },
      
    //             { Id:4, CashierOrderId: 2, ItemId:1, ItemNameAr: "منتج 1", ItemNameEn: "Item 1", 
    //             UnitId:1, UnitNameAr:"وحدة 2", UnitNameEn:"Unit 2", Note:"item\ndescription\n4", 
    //             UnitCost:1000, Quantity:4, TotalPrice:4000, DiscountValue:4, DiscountIsRate:true, NetPrice:3840,
    //             AddedValueTax:537.6, GrossPrice: 4377.6 },
    //           ],
      
    //           ItemTotalPrices:7000, ItemTotalDiscounts:250, ItemTotalNetPrices: 6750, 
    //           ItemTotalTaxes:945, ItemTotalGrossPrices: 7695, 
    //           OrderDiscountValue:0, OrderDiscountIsRate:false, TotalValue:7695,
      
    //           PaymentMethodId:1, PaymentMethodAr: "كاش", PaymentMethodEn:"Cash",
    //           //CounterCashBeforeCheckout:2000, CounterCashAfterCheckout: 9695,
    //           CounterCashBeforeCheckout:2000, CounterCashAfterCheckout: 2000,
    //           PaidAmount: 7700, ChangeGiven:5
    //         },
    //     ],

    //     OrderCount:1, RefundCount:0, SessionTotal: 2000,
    //     LeaveCash:2000, LeaveNote:"good\nday\n2",
    //     WorkShiftId:2, WorkShiftNameAr:"المساء", WorkShiftNameEn:"المساء",
    //     WorkShiftFrom: new Date(2021, 0, 13, 17, 0, 0), WorkShiftTo: new Date(2021, 0, 14, 2, 0, 0)
    //   },
    // ];
    debugMode: boolean = false;

    baseUrl = environment.baseApiUrl;
    // baseUrl = 'http://localhost:60289/';
     token = localStorage.getItem("token");
     httpOptions = {
      headers: new HttpHeaders({
        // 'Content-Type': 'application/json; charset=utf-8',
        // 'Authorization': "Bearer " + token,
         "Authorization": "Bearer " + this.token,
         "Content-Type": "application/json"
      })
    };
    getAll(): Observable<any> {
      console.log(this.debugData);
      
      if(this.debugMode){
        return of(this.debugData)
      }
      return this.http
        .get<any>(`${this.baseUrl}CashierSession/GetAll`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    getAllWithOrders(): Observable<any> {
      console.log(this.debugData);
      
      if(this.debugMode){
        return of(this.debugData)
      }
      return this.http
        .get<any>(`${this.baseUrl}CashierSession/GetAllWithOrders`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    getById(id:number): Observable<any> {
      if(this.debugMode){
        return of(this.debugData.find(x=>x.Id==id))
      }
      return this.http
        .get<any>(`${this.baseUrl}CashierSession/GetById/${id}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    getByIdWithOrders(id:number): Observable<any> {
      if(this.debugMode){
        return of(this.debugData.find(x=>x.Id==id))
      }
      return this.http
        .get<any>(`${this.baseUrl}CashierSession/GetByIdWithOrders/${id}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    insert(payload: CashierSessionModel): Observable<any> {
      if(this.debugMode){
        this.debugData.push(payload);
        return of(true)
      }
      return this.http
        .post<any>(`${this.baseUrl}CashierSession/Insert`, payload,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    insertWithOrders(payload: CashierSessionModel): Observable<any> {
      if(this.debugMode){
        this.debugData.push(payload);
        return of(true)
      }
      return this.http
        .post<any>(`${this.baseUrl}CashierSession/InsertWithOrders`, payload,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    update(payload: CashierSessionModel): Observable<any> {
      if(this.debugMode){
        this.debugData[this.debugData.findIndex(x=>x.Id==payload.Id)] = payload;
        return of(true)
      }
      return this.http
        .post<any>(`${this.baseUrl}CashierSession/Update`, payload,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
    delete(payload: CashierSessionModel): Observable<any> {
      if(this.debugMode){
        this.debugData.splice(this.debugData.findIndex(x=>x.Id==payload.Id),1);
        return of(true)
      }
      return this.http
        .get<any>(`${this.baseUrl}CashierSession/Delete/${payload.Id}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
    deleteList(payload: CashierSessionModel []): Observable<any> {
      if(this.debugMode){
        payload.forEach(element => {
          this.debugData.splice(this.debugData.findIndex(x=>x.Id==element.Id),1);
        });
        return of(true)
      }
      return this.http
        .post<any>(`${this.baseUrl}CashierSession/DeleteList`,JSON.stringify(payload),this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
}
