<!--component html goes here -->
<!-- <app-list-page-header
  *ngIf="tableView"
  [itemOptionsPerPage]="[5, 10, 20]"
  [itemsPerPage]="tableEngine.itemsPerPage"
  [selectAllState]="tableEngine.selectAllState"
  (addNewItem)="showModal(Id, 'Add')"
  [tableEngine]="this.tableEngine"
  (selectAllChange)="tableEngine.selectAll($event)"
  (changeOrderBy)="prepChangeSort($event)"
  (searchKeyUp)="
    tableEngine.filterDataByDateRange(this.SearchFrom, this.SearchTo, 'Date')
  "
  [dateSearch]="false"
>

</app-list-page-header> -->

<!-- Example single danger button -->

<div class="row">
  <div class="col-12">
    <!-- <div > </div> -->
    <div class="half-body" style="display: inline-block; width: 50%;">
          <div class="search-sm-nosearch d-inline-block mr-1 mb-1 align-top">
            <div class="search" style="display: inline-block; margin-left: 50px; margin-right: 50px;">
              <div class="d-inline-block">
                  <div class="search-sm d-inline-block mr-1 mb-1 align-top" style="width: 600px;">
                      <input type="search" [(ngModel)]="tableEngine.SearchTerm" (keyup)="searchUsingKeys()" (change)="searchUsingKeys()"
                          class="form-control input-sm full-data-search" placeholder="{{'Search' | translate}}">
                  </div>
              </div>
          </div>
      </div>
    </div>
    <div class="half-body" style="display: inline-block; width: 50%;">
      <div class="half-body-filler" style="display: inline-block; width: 60%;">
        &nbsp;
      </div>
        
              <div class="top-right-button-container2 btn-group" dropdown style="padding: unset !important; margin: unset !important;">
                <button [disabled]="tableEngine.selected.length===0 || tableEngine.currentAction==='loading'"
                id="button-split" type="button" dropdownToggle
                style="width: 130px; font-size: 16px;"
                    class="btn btn-primary dropdown-toggle "
                    aria-controls="dropdown-split">
                    <span class="caret"></span>
                    {{'Add' | translate}}
                </button>
                <ul id="dropdown-split" *dropdownMenu class="dropdown-menu dropdown-menu-right" 
                    role="menu" aria-labelledby="button-split">
                    <li role="menuitem"><a class="dropdown-item">
                            <button class="btn btn-primary d-inline-block" (click)="GoToAddBill(modelObject.Id)"
                            style="color: white; font-weight: bold; text-align: center; padding: 0px; width: 150px; height: 30px;">
                            {{"InboundRequisition" | translate}}</button></a></li>
                            <li role="menuitem"><a class="dropdown-item">
                              <button class="btn btn-primary d-inline-block" (click)="GoToSubtractBill(modelObject.Id)"
                              style="color: white; font-weight: bold; text-align: center; padding: 0px; width: 150px; height: 30px;">
                              {{"OutboundRequisition" | translate}}</button></a></li>
                              <li role="menuitem"><a class="dropdown-item">
                                <button class="btn btn-primary d-inline-block" (click)="GoToTransfer(modelObject.Id)"
                                style="color: white; font-weight: bold; text-align: center; padding: 0px; width: 150px; height: 30px;">
                                {{"TransferManual" | translate}}</button></a></li>
                </ul>          
            </div>
          
     </div>
      <div class="display-options-container">
        <div class="separator mb-4"></div>
      </div>

  </div>
  </div>

<div bsModal #delModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">
    <div class="modal-dialog modal-sm">
        <div class="modal-content" style="border: 3px solid red;">
            <button type="button" class="close pull-right" aria-label="Close" (click)="delModal.hide()"
                style="margin-right: 20px; margin-left: 20px; margin-top: 10px; width: 20px; display: inline;">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-header" style="display: inline; padding: 0px 0px 5px 0px;">
                <h4 id="dialog-child-name" class="modal-title pull-left" style="text-align: center; color: red;">
                    {{"ConfirmDel"+translationName |
                    translate}}</h4> 
            </div>
            <div class="modal-body">
                <div class="form-group form-primary" *ngIf="deletionList.length < ogData.length || deletionList.length === 1" style="text-align: center;">
                    <div style="text-align: center;">
                        <label *ngIf="deletionList.length > 10" type="text">{{deletionList.length}} {{"PriceListRows" | translate}}</label>
                        <label *ngIf="deletionList.length > 2 && deletionList.length < 11" type="text">{{deletionList.length}} {{"PriceListRowsPlur" | translate}}</label>
                        <label *ngIf="deletionList.length === 2" type="text">{{"TwoPriceList" | translate}}</label>
                        <label *ngIf="deletionList.length === 1" type="text">{{"OnePriceList" | translate}}</label>
                        <span class="form-bar"></span>
                    </div>
                </div>
                <div class="form-group form-primary" style="text-align: center;" *ngIf="deletionList.length > 1 && 
                    deletionList.length === ogData.length">
                    <label type="text">
                        <h1 style="font-weight: bolder; text-align: center;">{{translationName+"DeleteAll" | translate}}</h1>
                    </label>
                    <span class="form-bar"></span>
                </div>
                
                <div style="margin-left: 5px; text-align: center; margin-top: 30px;">
                    <button class="btn btn-danger" id="smallModalDeleteButton" (click)="deleteObject()"
                       
                        style="font-weight: bold; width: 100%; text-align: center;">{{"Delete" | translate}}</button>
                    <button class="btn btn-light" (click)="delModal.hide()"
                        style="font-weight: bold; width: 100%; text-align: center; margin-top: 10px;">{{"Close" |
                        translate}}</button>
                </div>
            </div>
        </div>
    </div>
  </div>
  <div *ngIf="tableView" class="FormScreen" class="tableScreen"> 
    <div id="divmiddle" style="display: block; margin-top: 5px">
     <div style="width: 100%">
       <div>
         <div class="card" style="min-width: 900px !important">
           <div class="card-body">
             <table class="table table-hover" style="margin: unset !important">
               <thead class="no-border-top">
                 <th style="text-align: center; padding: 5px">
                   <div class="text-zero" style="display: inline-block">
                     <div class="top-right-button-container2 btn-group" dropdown>
                       <div class="btn btn-primary pl-4 pr-0 check-button">
                         <label
                           class="custom-control custom-checkbox mb-0 d-inline-block"
                           style="width: 10px"
                         >
                           <input
                             type="checkbox"
                             class="custom-control-input"
                             [checked]="tableEngine.selectAllState === 'checked'"
                             [indeterminate]="
                             tableEngine.selectAllState === 'indeterminate'
                             "
                             (change)="tableEngine.selectAll($event)"
                           />
                           <span class="custom-control-label">&nbsp;</span>
                         </label>
                       </div>
                       <button
                         id="button-split"
                         type="button"
                         dropdownToggle
                         style="width: 10px"
                         [ngClass]="
                           tableEngine.selected.length == 0
                             ? 'btn btn-outline-primary dropdown-toggle dropdown-toggle-split'
                             : 'btn btn-primary dropdown-toggle dropdown-toggle-split'
                         "
                         aria-controls="dropdown-split"
                       >
                         <span class="caret"></span>
                       </button>
                       <ul
                         id="dropdown-split"
                         *dropdownMenu
                         class="dropdown-menu dropdown-menu-right"
                         role="menu"
                         aria-labelledby="button-split"
                       >
                         <li role="menuitem">
                           <a class="dropdown-item">
                             <button
                               class="btn btn-danger d-inline-block"
                               style="
                                 font-weight: bold;
                                 text-align: center;
                                 padding: 0px;
                                 width: 150px;
                                 height: 30px;
                               "
                               (click)="tableEngine.showDelModal('delMulti')"
                               [disabled]="
                                 tableEngine.selected.length === 0 ||
                                 tableEngine.currentAction === 'loading'
                               "
                             >
                               {{ "DeleteSelected" | translate }}
                             </button></a
                           >
                         </li>
                       </ul>
                     </div>
                   </div>
                 </th>
 
                 <th 
                   class="sorticon"
                   (click)="prepChangeSort('Date')"
                   [ngClass]="
                     itemOrder.label === 'Date'
                       ? 'sortkey sorticon'
                       : 'sorticon'
                   "
                 >
                   <div
                     [ngClass]="{
                       'iconsminds-up---down':
                         itemOrder.label != 'Date',
                       'iconsminds-down':
                         itemOrder.label === 'Date' &&
                         tableEngine.orderAsc === true,
                       'iconsminds-up':
                         itemOrder.label === 'Date' &&
                         tableEngine.orderAsc === false
                     }"
                   >   {{ "Date" | translate }}</div>
                 </th>

                 <th 
                 class="sorticon"
                 (click)="prepChangeSort('BillType')"
                 [ngClass]="
                   itemOrder.label === 'BillType'
                     ? 'sortkey sorticon'
                     : 'sorticon'
                 "
               >
                 <div
                   [ngClass]="{
                     'iconsminds-up---down':
                       itemOrder.label != 'BillType',
                     'iconsminds-down':
                       itemOrder.label === 'BillType' &&
                       tableEngine.orderAsc === true,
                     'iconsminds-up':
                       itemOrder.label === 'BillType' &&
                       tableEngine.orderAsc === false
                   }"
                 >   {{ "BillType" | translate }}</div>
               </th>
               
                 <!-- <th 
                 class="sorticon"
                 (click)="prepChangeSort('Time')"
                 [ngClass]="
                   itemOrder.label === 'Date'
                     ? 'sortkey sorticon'
                     : 'sorticon'
                 "
               >
                 <div
                   [ngClass]="{
                     'iconsminds-up---down':
                       itemOrder.label != 'Time',
                     'iconsminds-down':
                       itemOrder.label === 'Time' &&
                       tableEngine.orderAsc === true,
                     'iconsminds-up':
                       itemOrder.label === 'Time' &&
                       tableEngine.orderAsc === false
                   }"
                 >   {{ "Time" | translate }}</div>
               </th> -->
                

               <!-- is active -->
 
                 <th
                   class="sorticon"
                   (click)="prepChangeSort('IsApproval')"
                   [ngClass]="
                     itemOrder.label === 'IsApproval'
                       ? 'sortkey sorticon'
                       : 'sorticon'
                   "
                 >
                   <div
                     [ngClass]="{
                       'iconsminds-up---down': itemOrder.label != 'IsApproval',
                       'iconsminds-down':
                         itemOrder.label === 'IsApproval' &&
                         tableEngine.orderAsc === true,
                       'iconsminds-up':
                         itemOrder.label === 'IsApproval' &&
                         tableEngine.orderAsc === false
                     }"
                   >{{ "Status" | translate }}</div>
                 </th>
                 <th>
                   
                 </th>
 
                 <th
                   [colSpan]="5"
                   style="
                     text-align: center;
                     padding: unset !important;
                     vertical-align: middle;
                   "
                 >
                   <div
                     id="displayOptions"
                     style="
                       display: inline;
                       margin-top: 10px;
                       margin-left: 5px;
                       margin-right: 5px;
                     "
                   >
                     <span
                       class="text-muted text-small"
                       style="width: 50px; height: 40px"
                     >
                       {{ "ResultCount" | translate }}
                     </span>
                     <span dropdown class="d-inline-block position-relative">
                       <button
                         class="btn btn-outline-dark btn-xs dropdown-toggle"
                         type="button"
                         data-toggle="dropdown"
                         aria-haspopup="true"
                         aria-expanded="false"
                         dropdownToggle
                       >
                         {{ tableEngine.itemsPerPage }}
                       </button>
                       <div
                         class="dropdown-menu dropdown-menu-right dropdown-menu-sm text-right"
                         *dropdownMenu
                       >
                         <li
                           role="menuitem"
                           *ngFor="let item of tableEngine.itemOptionsPerPage"
                         >
                           <a
                             [ngClass]="
                               item === tableEngine.itemsPerPage
                                 ? 'dropdown-item active'
                                 : 'dropdown-item'
                             "
                             (click)="tableEngine.paginateData(item, 1)"
                             >{{ item }}</a
                           >
                         </li>
                       </div>
                     </span>
                   </div>
                 </th>
              </thead>
               <tbody>
                 <tr
                   *ngFor="let obj of tableEngine.paginatedData; let i = index"
                 >
                   <td style="text-align: center; padding: 5px">
                     <div
                       class="itemCheck mb-0 custom-checkbox custom-control"
                       style="display: inline-block"
                     >
                       <input
                         type="checkbox"
                         class="custom-control-input"
                         [id]="'customCheck' + i"
                         [checked]="tableEngine.isSelected(obj)"
                         (change)="tableEngine.onSelect(obj)"
                       />
                       <label
                         class="custom-control-label"
                         [for]="'customCheck' + i"
                       ></label>
                     </div>
                   </td>
                 
                   <td >
                     {{
                       obj.Date | date:'dd/MM/yyyy'
                     }}
                     {{
                        obj.Time
                      }}
                   </td>
 
                   <td >
                    {{
                      obj.BillType
                    }}
                  </td>
                   <td colspan="2">
                     <span class="badge badge-pill badge-success mb-1" *ngIf="obj.IsApproval==true">{{'Approval' | translate}}</span>
                     <button class="badge badge-pill badge-success mb-1" style="background-color: #ffc107;"*ngIf="obj.IsApproval==false" (click)="IsApproval(obj)">{{'NotApproval' | translate}} </button>
                  
                    </td>
               
                   <td style="text-align: center">
                     <i
                       class="simple-icon-info"
                       (click)="showModal(obj.Id, 'Info',obj.BillType)"                      
                       tooltip="{{ 'Info' | translate }}"
                       placement="auto"
                       style="
                         font-size: 20px;
                         font-weight: bolder;
                         cursor: pointer;
                         vertical-align: middle;
                         color: #17a2b8;
                       "
                       
                      
                     ></i>
                   </td>
                   <td style="text-align: center">
                     <i
                       class="simple-icon-pencil"
                       (click)="showModal(obj.Id, 'Edit',obj.BillType)"
                       tooltip="{{ 'Edit' | translate }}"
                       placement="auto"
                       style="
                         font-size: 20px;
                         font-weight: bolder;
                         cursor: pointer;
                         vertical-align: middle;
                         color: #ffc107;
                       "
                      
                      
                     ></i>
                   </td>
                   <td style="text-align: center">
                     <i
                       class="simple-icon-close"
                       (click)="showDelModal(obj.Id)"
                       tooltip="{{ 'Delete' | translate }}"
                       placement="auto"
                       style="
                         font-size: 20px;
                         font-weight: bolder;
                         cursor: pointer;
                         vertical-align: middle;
                         color: #dc3545;
                       "
                     ></i>
                   </td>
                </tr>
               </tbody>
             </table>
             <div
               id="divsearchmiddle"
               style="display: inline-block; width: 100%; height: 100%"
             >
               <div style="text-align: center">
                      <div
                   class="centercenter"
                   style="
                     display: inline-block;
                     width: 70%;
                     height: 100%;
                     text-align: center;
                   "
                 >
                   <bdo dir="ltr">
                     <pagination
                       class="pagination-sm2"
                       style="
                         padding: 0px, 0px, 0px, 0px;
                         margin: 0px, 0px, 0px, 0px;
                         display: inline-block;
                       "
                       [(ngModel)]="currentPage"
                       [boundaryLinks]="true"
                       [totalItems]="tableEngine.filteredData.length"
                       [itemsPerPage]="tableEngine.itemsPerPage"
                       [customFirstTemplate]="firstTemplate"
                       [customLastTemplate]="lastTemplate"
                       [customPreviousTemplate]="prevTemplate"
                       [customNextTemplate]="nextTemplate"
                       [maxSize]="5"
                       (pageChanged)="tableEngine.changePage($event)"
                     >
                     </pagination>
                   </bdo>
                 </div>
               </div>
             </div>
           </div>
         </div>
       </div>
     </div>
   </div>
 </div>
 
<ng-template
#nextTemplate
let-disabled="disabled"
let-currentPage="currentPage"
>
<i *ngIf="lang === 'en-US'" class="simple-icon-arrow-right"></i>
<i *ngIf="lang === 'ar-EG'" class="simple-icon-arrow-left"></i>
</ng-template>

<ng-template
#prevTemplate
let-disabled="disabled"
let-currentPage="currentPage"
>
<i *ngIf="lang === 'en-US'" class="simple-icon-arrow-left"></i>
<i *ngIf="lang === 'ar-EG'" class="simple-icon-arrow-right"></i>
</ng-template>

<ng-template
#lastTemplate
let-disabled="disabled"
let-currentPage="currentPage"
>
<i class="simple-icon-control-end"></i>
</ng-template>

<ng-template
#firstTemplate
let-disabled="disabled"
let-currentPage="currentPage"
>
<i class="simple-icon-control-start"></i>
</ng-template>

<simple-notifications></simple-notifications>