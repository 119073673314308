import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { TableEngine } from 'src/app/invoices/TableEngine';
import { StoreItemsModel } from '../../models/StoreItemsModel';
import { PriceListService } from '../price-list/price-list.service';
import{StoreItemsService} from 'src/app/Invintory/store-items-service.service'
import { PriceListModel } from '../../models/PriceListModel';
import { SidePopupComponent } from 'src/app/views/invoice/side-popup/side-popup.component';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ItemsPriceListModel } from '../../models/ItemsPriceListModel';

@Component({
    // moduleId: module.id,
    selector: 'info-price-list',
    templateUrl: 'info-price-list.component.html',
    styleUrls: ['info-price-list.component.scss']
})
export class InfoPriceListComponent {
    Id=0;

    constructor(public router:Router, 
           private fb:FormBuilder,
           private modelService: PriceListService,
           private StoreItemsService: StoreItemsService,        
           private notifications: NotificationsService, private translate: TranslateService,
           private route: ActivatedRoute
           ){
               this.Id = + route.snapshot.paramMap.get('Id');            
           }
   
           tableEngine = new TableEngine(this.translate, this.notifications);
           currentScreen: any = 'Table';
           translationName: string = 'Item'
           sortableKeys = [];
           searchableKeys = ['BalanceNameEn', 'BalanceNameAr'];
           tableView: any = true;
           
           // StoreItemsModel:StoreItemsModel[]=[];  
            
           itemsData:StoreItemsModel[]=[];
           modelObject: PriceListModel = new PriceListModel();
           // ItemsModel:ItemsModel[]=[]
           Form:FormGroup;
           declare lang: any;
           declare itemOrder: any;
           currentAction = '';
           savedPage = 0;
           currentPage = 1;
           deletionList: any[] = [];
           prevAction = '';    
           addToListId = 0;
           ItemsBalanceHeaderId=0;
           ItemBalanceId=0;
   currentFormMode: string = '';
   
   
   ogData: PriceListModel[] = [];
    mainForm: FormGroup = new FormGroup({
     Id: new FormControl(),
     NameAr: new FormControl(),
     NameEn: new FormControl(),
     IsActive: new FormControl(),
               
           });
   
   @ViewChild('sideModalRef', { static: true }) sideModalRef: SidePopupComponent = null;
   @ViewChild('delModal', { static: true }) delModal: ModalDirective;
   @ViewChild('smallModal', { static: true }) smallModal: ModalDirective = null;
   formPresentationModal: any;
   ngOnInit(): void {
       // this.getAllCountryCode();    
        
       this.lang = localStorage.getItem('theme_lang');
       this.itemOrder = { label: 'PriceListName', property: this.lang === 'ar-EG' ? 'NameAr' : 'NameEn' };      
       this.sortableKeys = [         
           // { label: 'Date', property: 'Date' },        
           { label: 'PriceListName', property: this.lang === 'ar-EG' ? 'NameAr' : 'NameEn' },   
           { label: 'IsActive', property: 'IsActive' },
                
       ];
   
       this.tableEngine.loadTableEngine(this.ogData, this.modelService, this.modelObject,
           this.searchableKeys, this.sortableKeys, this.mainForm, this.translationName, this.itemOrder,
           this.sideModalRef, this.delModal, this.smallModal, this.lang
       )
       //this.tableEngine.modelObject = {}
       this.loadServices();
       this.StoreItemsService.getAll()            
               .subscribe(data=>
                   {
                       this.itemsData=data
                   });
   
   
                   if(this.Id > 0)
                   {   debugger;                            
                           this.modelService.getById(this.Id).subscribe(data=>{                        
                           this.modelObject = data;
                           this.ItemsPriceList=data.Items;
                           this.mainForm = this.createForm(this.modelObject);
   
                       });
                      
       
                   }
                   else{
                   this.mainForm = this.createForm(this.modelObject);                              
                   }
   } 
   
           createForm(modelObject):FormGroup{
               debugger;
               return this.fb.group({
                   // Id:[this.ItemBalance.Id],
                   Id: [modelObject.Id >= 0 ? modelObject.Id : 0],   
                   NameEn: [modelObject.NameEn, Validators.compose([Validators.required, Validators.minLength(2)])],
                   NameAr: [modelObject.NameAr, Validators.compose([Validators.required, Validators.pattern(/^[\u0621-\u064A\u0660-\u06690-9]+([\s]*[\u0621-\u064A\u0660-\u06690-9]+)*$/)])],
                   IsActive: [modelObject.IsActive==null?true:modelObject.IsActive],
                               
               }) 
           }
   
           // ListStoreItems:StoreItemsModel[]=[];  
           PriceList:PriceListModel[]=[];  
           ItemsPriceList:ItemsPriceListModel[]=[];
           loadServices(){
               // debugger;
               this.currentAction = 'loading';
               this.tableEngine.loadData();         
             
              
           }
           addToList(){     
               debugger; 
               var i = this.ItemsPriceList.findIndex(x=>x.Id == this.addToListId);
               if(i >= 0){
                   var item = this.ItemsPriceList[i];
                   
                   this.ItemsPriceList.push(item);            
                   this.ItemsPriceList.splice(i,1);
                   this.addToListId = 0;
               }
           }
           getItemsById(Id:any):any{
               if(Id>0)
               {
               return this.itemsData.find(i=>i.Id===Id)
               }
               else
               {
               return new StoreItemsModel();
               }
               }
               setItem(Item){
                   debugger;
                   var i = this.itemsData.findIndex(x=>x.Id == Item.ItemId);
                   if(i>-1){
                       Item.SalePrice = this.itemsData[i].SalePrice;
                   }
                   }
                   AddonClick(){
   
                       var ItemsModel= new ItemsPriceListModel();
                       ItemsModel.Id = 0, 
                       ItemsModel.ItemId = 0, 
                       ItemsModel.PriceListId = 0, 
                       ItemsModel.IsDeleted= false, 
                       // ItemsModel.Items=[]
                       this.ItemsPriceList.push(ItemsModel);
                       ItemsModel={};
                       
                   }
                   removeItemsFooter(Id: any): any {
   
                       let index = this.ItemsPriceList.findIndex(x => x.PriceListId === Id);
                       this.ItemsPriceList.splice(index, 1);
                                    
                     }
                     addItems(){
   
                     }
   
                     goBack(){
                       this.router.navigate(['app/aio/Inventory/List-All-PriceList']);
                     }
                //    Save(){
          
                //        debugger;
                //        this.currentAction = 'loading'; 
                //        this.tableEngine.savedPage = this.tableEngine.currentPage;
                //        this.modelObject = new PriceListModel();
                //        this.modelObject.NameAr=this.mainForm.getRawValue().NameAr;
                //        this.modelObject.NameEn=this.mainForm.getRawValue().NameEn;
                //        this.modelObject.IsActive=this.mainForm.getRawValue().IsActive;
                //       // this.modelObject.ItemsPriceList=this.mainForm.getRawValue().ItemsPriceList;
                //        this.modelObject.Id=0;
                //        this.modelObject.Items=this.ItemsPriceList;
                //       this.sideModalRef.modalRef.hide();
                   
                //        if(this.Id == 0){
                //            this.modelService.insert(this.modelObject)
                //            .subscribe(data => {
                //              this.router.navigate(['app/aio/Inventory/List-All-PriceList']);
                //            })
                //          }
                //          else{
                //            this.modelService.update(this.modelObject)
                //            .subscribe(data => {
                //              this.router.navigate(['app/aio/Inventory/List-All-PriceList']);
                //            })
                //          }
                //        }
                     
}
