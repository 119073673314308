import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from '../service.service';
import { PriceListService } from "src/app/Invintory/Price List/price-list/price-list.service";
import { BrandServiceService } from 'src/app/Invintory/warehouses/brand-service.service';
import { CategoryServiceService } from 'src/app/Invintory/warehouses/category-service.service';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { TableEngine } from 'src/app/invoices/TableEngine';
import { ServiceModel } from 'src/app/Invintory/models/ServiceModel';
import { WarehouseModel } from 'src/app/Invintory/models/WarehouseModel';
import { SidePopupComponent } from 'src/app/views/invoice/side-popup/side-popup.component';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { PriceListModel } from 'src/app/Invintory/models/PriceListModel';
import { CategoryModel } from 'src/app/Invintory/models/CategoryModel';
import { ItemsPriceListModel } from 'src/app/Invintory/models/ItemsPriceListModel';
import { CategoryItemsModel } from 'src/app/Invintory/models/CategoryItemsMosel';
import { ItemTaxModel } from 'src/app/Invintory/models/ItemTaxModel';
import { ServiceCategoryModel } from 'src/app/Invintory/models/ServiceCategoryModel';

@Component({
    // moduleId: module.id,
    selector: 'add-services',
    templateUrl: 'add-services.component.html',
    styleUrls: ['add-services.component.scss']
})
export class AddServicesComponent {
    Id = 0;

    constructor(
      public router: Router,
      private fb: FormBuilder,
      private modelService: ServiceService,
      private PriceListService: PriceListService,
     
      private CategorySevice: CategoryServiceService,
      private notifications: NotificationsService,
      private translate: TranslateService,
      private route: ActivatedRoute
    ) {
      this.Id = +route.snapshot.paramMap.get("Id");
    }
  
    tableEngine = new TableEngine(this.translate, this.notifications);
    currentScreen: any = "Table";
    translationName: string = "Item";
    sortableKeys = [];
    searchableKeys = ["Name", "Address"];
    tableView: any = true;
  
    StoreItemsModel:ServiceModel[]=[];
  
    // WarehouseData: WarehouseModel[] = [];
  
    modelObject: ServiceModel = new ServiceModel();
    Form: FormGroup;
    declare lang: any;
    declare itemOrder: any;
    currentAction = "";
    savedPage = 0;
    currentPage = 1;
    deletionList: any[] = [];
    prevAction = "";
    addToListId = 0;
    ItemsBalanceHeaderId = 0;
    ItemBalanceId = 0;
    currentFormMode: string = "";
    type = 0;
    visable = "";
    visable2 = "";
    visable3 = "";



    ogData: ServiceModel[] = [];
    mainForm: FormGroup = new FormGroup({
        Id: new FormControl(),
        Name: new FormControl(),
        ServiceCode:new FormControl(),
        Description:new FormControl(),
        PurchasingPrice:new FormControl(),
        SalePrice:new FormControl(),
        FirstTaxId:new FormControl(),
        SecondTaxId:new FormControl(),
        LowestSellingPrice:new FormControl(),
        Discount:new FormControl(),
        ProfitPercentage:new FormControl(),
        PriceListId:new FormControl(),
        UnitPrice:new FormControl(),
        Time:new FormControl(),
        Notes:new FormControl(),
        Tags:new FormControl(),
        IsDisable:new FormControl(),
        AvailableOnline:new FormControl(),
        FeaturedProduct:new FormControl(),
        
      
    });
  
    @ViewChild("sideModalRef", { static: true })
    sideModalRef: SidePopupComponent = null;
    @ViewChild("delModal", { static: true }) delModal: ModalDirective;
    @ViewChild("smallModal", { static: true }) smallModal: ModalDirective = null;
    formPresentationModal: any;
    ngOnInit(): void {
      // this.getAllCountryCode();
  
      this.lang = localStorage.getItem("theme_lang");
      this.itemOrder = {
        label: "Name",
        property: this.lang === "ar-EG" ? "Name" : "Name",
      };
      this.sortableKeys = [
        // { label: 'Date', property: 'Date' },
        { label: "Name", property: this.lang === "ar-EG" ? "Name" : "Name" },
        { label: "ServiceCode", property: "ServiceCode" },
        { label: "Description", property: "Description" },
        { label: "PurchasingPrice", property: "PurchasingPrice" },
        { label: "SalePrice", property: "SalePrice" },
        { label: "FirstTaxId", property: "FirstTaxId" },
        { label: "SecondTaxId", property: "SecondTaxId" },
        { label: "LowestSellingPrice", property: "LowestSellingPrice" },
        { label: "Discount", property: "Discount" },
        { label: "ProfitPercentage", property: "ProfitPercentage" },
        { label: "PriceListId", property: "PriceListId" },
        { label: "UnitPrice", property: "UnitPrice" },
        { label: "Time", property: "Time" },
        { label: "Notes", property: "Notes" },
        { label: "Tags", property: "Tags" },
        { label: "IsDisable", property: "IsDisable" },
        { label: "IsAlertDisable", property: "Alert" },
        { label: "FeaturedProduct", property: "FeaturedProduct" },
        { label: "AvailableOnline", property: "AvailableOnline" },
        
      ];
  
      this.tableEngine.loadTableEngine(
        this.ogData,
        this.modelService,
        this.modelObject,
        this.searchableKeys,
        this.sortableKeys,
        this.mainForm,
        this.translationName,
        this.itemOrder,
        this.sideModalRef,
        this.delModal,
        this.smallModal,
        this.lang
      );
      //this.tableEngine.modelObject = {}
      this.loadServices();
      this.modelService.getAll().subscribe((data) => {
        this.modelObject = data;
      });
      this.modelService.getAllTaxes().subscribe((data) => {
        this.FirstTaxesList = data;
      });
      this.modelService.getAllTaxes().subscribe((data) => {
          this.SecondTaxesList = data;
        });
      this.modelService.getLastNumber().subscribe((data) => {
          this.modelObject.ServiceCode = data;
          this.mainForm.controls['ServiceCode'].setValue(this.modelObject.ServiceCode+1);
        });
     
    
      this.PriceListService.getAll()            
      .subscribe(data=>
          {
              this.PriceList=data
          });
         
              
              this.CategorySevice.getAll()            
              .subscribe(data=>
                  {
                      this.CategoryList=data
                  });
  
                  // this.selectAllForDropdownItems(this.getCategoryById(this.Id));
  
          if(this.Id > 0)
          {   debugger;                            
                  this.modelService.getById(this.Id).subscribe(data=>{                        
                  this.modelObject = data;
                  this.ItemsPriceList=data.ServicePriceList;
                  this.CategoryList=data.ServiceCategory;
                  this.mainForm = this.createForm(this.modelObject);
  
              });
             
  
          }
          else{
                  
             this.mainForm = this.createForm(this.modelObject);                              
  
  
          }
      this.mainForm = this.createForm(this.modelObject);
  
    }
 
    createForm(modelObject): FormGroup {
        debugger;
        return this.fb.group({
          // Id:[this.ItemBalance.Id],
          Id: [modelObject.Id >= 0 ? modelObject.Id : 0],
          Name: [modelObject.Name, Validators.compose([Validators.required])],
          ServiceCode: [
            modelObject.ServiceCode,Validators.compose([Validators.required, Validators.minLength(2)])],
          PurchasingPrice: [
            modelObject.PurchasingPrice,
            Validators.compose([Validators.required]),
          ],
          SalePrice: [modelObject.SalePrice],
          FirstTaxId: [modelObject.FirstTaxId],
          SecondTaxId: [modelObject.SecondTaxId],
          LowestSellingPrice: [modelObject.LowestSellingPrice],
          Description: [modelObject.Description],
          Discount: [modelObject.Discount],
          ProfitPercentage: [modelObject.ProfitPercentage],
          PriceListId: [modelObject.PriceListId],
          UnitPrice:[modelObject.UnitPrice],
          Time: [modelObject.Time],
          Notes: [modelObject.Notes],
          Tags: [modelObject.Tags],
          WarehouseName: [modelObject.WarehouseName],
          AvailableOnline: [modelObject.AvailableOnline],
          FeaturedProduct: [modelObject.FeaturedProduct],
          IsDisable: [modelObject.IsDisable == null ? true : modelObject.IsDisable],
          
        });
      }
    
      FirstTaxesList: ItemTaxModel[] = [];
      SecondTaxesList: ItemTaxModel[] = [];
      
      PriceList: PriceListModel[] = [];
      CategoryList: CategoryModel[] = [];
      SelectedCategoryList: CategoryModel[] = [];
      loadServices() {
        // debugger;
        this.currentAction = "loading";
        this.tableEngine.loadData();
      }
      calcSalePrice(){
        // var i = this.StoreItemsModel.findIndex(x=>x.Id == Item);
        this.modelObject.PurchasingPrice = +this.mainForm.getRawValue().PurchasingPrice;
        this.modelObject.ProfitPercentage = +this.mainForm.getRawValue().ProfitPercentage;
    
    
            var pre=+(this.modelObject.ProfitPercentage/100)*this.modelObject.PurchasingPrice;
            this.modelObject.SalePrice = +this.modelObject.PurchasingPrice +pre;
            this.mainForm.controls['SalePrice'].setValue(this.modelObject.SalePrice)
        
      }
      calcProfitPrice(){
        this.modelObject.PurchasingPrice = +this.mainForm.getRawValue().PurchasingPrice;
        this.modelObject.SalePrice = +this.mainForm.getRawValue().SalePrice;
    
        // var pre=+(this.modelObject.SalePrice-this.modelObject.PurchasingPrice);
    
            var pre=this.modelObject.SalePrice-this.modelObject.PurchasingPrice;
            this.modelObject.ProfitPercentage = +((pre/this.modelObject.PurchasingPrice)*100);
            // this.modelObject.ProfitPercentage = +(pre/(this.modelObject.PurchasingPrice*100));
    
            this.mainForm.controls['ProfitPercentage'].setValue(this.modelObject.ProfitPercentage)
      }
      addPriceList() {
        debugger;
        var i = this.PriceList.findIndex((x) => x.Id == this.addToListId);
        if (i >= 0) {
          var item = this.PriceList[i];
    
          this.PriceList.push(item);
          this.PriceList.splice(i, 1);
          this.addToListId = 0;
        }
      }
      ItemsPriceList:ItemsPriceListModel[]=[];
      CategoryItemList: CategoryItemsModel[]=[{Id:0,CategoryId:0,ItemId:this.Id}];
      AddonClick(){
    
        var PriceList= new ItemsPriceListModel();
        PriceList.Id = 0, 
        PriceList.ItemId =this.Id, 
        PriceList.PriceListId = this.mainForm.controls['PriceListId'].value, 
        PriceList.IsDeleted= false, 
        // ItemsModel.Items=[]
        this.ItemsPriceList.push(PriceList);
        this.mainForm.controls['PriceListId'].setValue(0);
        PriceList={};
        
    }
    
    removeItemsFooter(Id: any): any {
    
        let index = this.ItemsPriceList.findIndex(x => x.PriceListId === Id);
        this.ItemsPriceList.splice(index, 1);
                     
      }

      
onMaterialGroupChange(event) {
    this.SelectedCategoryList = event;
  }
     goBack() {
    this.router.navigate(["app/aio/Inventory/List-All-Services"]);
  }
  Save() {
    debugger;
    this.currentAction = "loading";
    this.tableEngine.savedPage = this.tableEngine.currentPage;
    this.modelObject = new ServiceModel();
    this.modelObject.Id = 0;
    this.modelObject.Name = this.mainForm.getRawValue().Name;
    this.modelObject.ServiceCode = this.mainForm.getRawValue().ServiceCode;
    this.modelObject.IsDisable = this.mainForm.getRawValue().IsDisable;
    this.modelObject.Description = this.mainForm.getRawValue().Description;
    this.modelObject.Discount = this.mainForm.getRawValue().Discount;
    this.modelObject.Time = this.mainForm.getRawValue().Time;
    
    this.modelObject.LowestSellingPrice =
      this.mainForm.getRawValue().LowestSellingPrice;
    this.modelObject.ProfitPercentage =
      this.mainForm.getRawValue().ProfitPercentage;
    this.modelObject.PurchasingPrice =
      this.mainForm.getRawValue().PurchasingPrice;
    this.modelObject.SalePrice = this.mainForm.getRawValue().SalePrice;
    this.modelObject.Notes = this.mainForm.getRawValue().Notes;
    // this.modelObject.PriceListId = this.mainForm.getRawValue().PriceListId;
    this.modelObject.FirstTaxId = this.mainForm.getRawValue().FirstTaxId;
    this.modelObject.SecondTaxId = this.mainForm.getRawValue().SecondTaxId;
    this.modelObject.UnitPrice = this.mainForm.getRawValue().UnitPrice;
    this.modelObject.ServicePriceList = this.ItemsPriceList;
    this.SelectedCategoryList.forEach(element => {
      var cat = new ServiceCategoryModel();
      cat.CategoryId = element.Id;
      cat.Id = 0;
      cat.ServiceId = this.Id;
      this.modelObject.ServiceCategory.push(cat);
    });
    // this.modelObject.Category=this.SelectedCategoryList;
   
    this.modelObject.Tags = this.mainForm.getRawValue().Tags;
    this.modelObject.AvailableOnline = this.mainForm.getRawValue().AvailableOnline;
    this.modelObject.FeaturedProduct = this.mainForm.getRawValue().FeaturedProduct;
    if (this.Id == 0) {
      this.modelService.insert(this.modelObject).subscribe((data) => {
        this.router.navigate(["app/aio/Inventory/List-All-Services"]);
      });
    } else {
      debugger;
      this.modelService.update(this.modelObject).subscribe((data) => {
        this.router.navigate(["app/aio/Inventory/List-All-Services"]);
      });
    }
  }
    
}
