import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MembershipModel } from 'src/app/memberships/models/membership.model';

@Component({
    selector: 'create-doctor',
    templateUrl: 'create-doctor.component.html',
    styleUrls: ['create-doctor.component.scss']
})
export class CreateDoctorComponent implements OnInit {
    showCard: boolean = false;
    ItemHolderList: any[] = [];
    Form: FormGroup;
    lang: string;
    Id = 0;
    ogData: MembershipModel [] = [ 
        {Id:5,FieldNameAr:'محمود',SchoolNameAr:'احمد', ArabicName:'محمود احمد'  , EnglishName:'Mahmoud Ahmed', Phone:'01098340345', Email:"mahmoud@gmail.com"   }, 
        {Id:1,FieldNameAr:'عمرو',SchoolNameAr:'محمد', ArabicName:'عمرو محمد'   , EnglishName:'Amro Mohamed', Phone:'01098340342', Email:"Amro@gmail.com"        }, 
        {Id:2,FieldNameAr:'محمد',SchoolNameAr:'نوح', ArabicName:'محمد نوح'    , EnglishName:'Mohamed Nouh', Phone:'01098340341', Email:"Mohamed@gmail.com"      }, 
        {Id:3,FieldNameAr:'يوسف',SchoolNameAr:'احمد', ArabicName:'يوسف احمد'   , EnglishName:'Youssef Ahmed', Phone:'01098340345', Email:"Youssef@gmail.com"    }, 
        {Id:4,FieldNameAr:'ابراهيم',SchoolNameAr:'حسن', ArabicName:'ابراهيم حسن' , EnglishName:'Ibrahim Hassen', Phone:'01098340347', Email:"Ibrahim@gmail.com" }, 
    
      ];
    MembershipService: any;
    constructor(
      public router:Router, 
      public fb:FormBuilder, 
      private route: ActivatedRoute

      ) {
    
        this.Id = +route.snapshot.paramMap.get("Id");
   
    }
 
    ngOnInit() {
        if(this.Id > 0){
            var index = this.ogData.findIndex(x=>x.Id == this.Id);
            if(index > -1){
                var item = this.ogData[index];
        this.Form = this.createForm(item);   

            }
            else{
        this.Form = this.createForm(new MembershipModel());   

            }
        }
        else{
            this.Form = this.createForm(new MembershipModel());   
    
                }   
            
            }

  
    Save() {
      debugger;

      var membership = this.Form.getRawValue();
      membership.ImageURL = this.picurl;
      membership.NationalIDImage = this.NationalIdPicurl;
      membership.GraduationDocument = this.graduationPicurl;
      if(this.Id > 0){
        this.MembershipService.update(membership)
        .subscribe(result=>{
          if(result == true){
            this.router.navigate(['app/health-care/doctors'])
          }
        })
      }
      else{
        this.MembershipService.insert(membership)
        .subscribe(result=>{
          if(result == true){
            this.router.navigate(['app/health-care/doctors'])
          }
        })
      }


    }
 
  goBack(){
    this.router.navigate(['app/health-care/doctors'])
  }

  
  createForm(modelObject:MembershipModel): FormGroup {
    debugger;
    return this.fb.group({
     Id:[modelObject.Id],
     ArabicName                       :[modelObject.ArabicName                       ],
     FirstArabicName                  :[modelObject.FirstArabicName                  ],
     SecondArabicName                 :[modelObject.SecondArabicName                 ],
     LastArabicName                   :[modelObject.LastArabicName                   ],
     EnglishName                      :[modelObject.EnglishName                      ],
     Phone                            :[modelObject.Phone                            ],
     Email                            :[modelObject.Email                            ],
     Gender                           :[modelObject.Gender                           ],
     IsEgyptian                       :[modelObject.IsEgyptian                       ],
     NationalID                       :[modelObject.NationalID                       ],
     PassportNumber                   :[modelObject.PassportNumber                   ],
     BranchNameEn                     :[modelObject.BranchNameEn                     ],
     BranchNameAr                     :[modelObject.BranchNameAr                     ],
     CountryEn                        :[modelObject.CountryEn                        ],
     CountryAr                        :[modelObject.CountryAr                        ],
     NationaltyID                     :[modelObject.NationaltyID                     ],
     City                             :[modelObject.City                             ],
     GovernorateId                    :[modelObject.GovernorateId                    ],
     EmergencyContactNumber           :[modelObject.EmergencyContactNumber           ],
     BirthDate                        :[modelObject.BirthDate                        ],
     ImageURL                         :[modelObject.ImageURL                         ],
     PWS                              :[modelObject.PWS                              ],
     IsActive                         :[modelObject.IsActive                         ],
     Address                          :[modelObject.Address                          ],
     TeamId                           :[modelObject.TeamId                           ],
     BranchId                         :[modelObject.BranchId                         ],
     RoleId                           :[modelObject.RoleId                           ],
     IsBlocked                        :[modelObject.IsBlocked                        ],
     IsStudent                        :[modelObject.IsStudent                        ],
     FieldsStudyOrWorkId              :[modelObject.FieldsStudyOrWorkId              ],
     SubFieldsStudyOrWorkId           :[modelObject.SubFieldsStudyOrWorkId           ],
     CollegeId                        :[modelObject.CollegeId                        ],
     SchoolId                         :[modelObject.SchoolId                         ],
     AcademicYear                     :[modelObject.AcademicYear                     ],
     VolunteerItherFiled              :[modelObject.VolunteerItherFiled              ],
     CurrentPosition                  :[modelObject.CurrentPosition                  ],
     Employer                         :[modelObject.Employer                         ],
     UserId                           :[modelObject.UserId                           ],
     IsHaveExpereanceInVolunteerFildes:[modelObject.IsHaveExpereanceInVolunteerFildes],
     HowDidYouKnowAboutUs             :[modelObject.HowDidYouKnowAboutUs             ],
     IsMembershipExpered              :[modelObject.IsMembershipExpered              ],
     IsDisability:[modelObject.IsDisability],
     DisabilityType:[modelObject.DisabilityType],
     CreateBy                         :[modelObject.CreateBy                         ],
     CreateDate                       :[modelObject.CreateDate                       ],
     ModifiedBy                       :[modelObject.ModifiedBy                       ],
     ModifiedDate                     :[modelObject.ModifiedDate                     ],
     IsDeleted                        :[modelObject.IsDeleted                        ],
     DeletedBy                        :[modelObject.DeletedBy                        ],
     DeletedDate                      :[modelObject.DeletedDate                      ],
     GovernorateNameEn                :[modelObject.GovernorateNameEn                ],
     GovernorateNameAr                :[modelObject.GovernorateNameAr                ],
     CityNameEn                       :[modelObject.CityNameEn                       ],
     CityNameAr                       :[modelObject.CityNameAr                       ],
     SectorNameAr                     :[modelObject.SectorNameAr                     ],
     SectorNameEn                     :[modelObject.SectorNameEn                     ],
     FieldNameAr                      :[modelObject.FieldNameAr                      ],
     FieldNameEn                      :[modelObject.FieldNameEn                      ],
     HasWork                          :[modelObject.HasWork                          ],
     StudentType                      :[modelObject.StudentType                      ],
     CollegeNameAr                    :[modelObject.CollegeNameAr                    ],
     CollegeNameEn                    :[modelObject.CollegeNameEn                    ],
     SchoolNameAr                     :[modelObject.SchoolNameAr                     ],
     SchoolNameEn                     :[modelObject.SchoolNameEn                     ],
     VolunteerNumber                  :[modelObject.VolunteerNumber                  ],
     UniversityNameAr                 :[modelObject.UniversityNameAr                 ],
     UniversityNameEn                 :[modelObject.UniversityNameEn                 ],
     InstituteNameAr                  :[modelObject.InstituteNameAr                  ],
     InstituteNameEn                  :[modelObject.InstituteNameEn                  ],
     EventsCount                      :[modelObject.EventsCount                      ],
     ActivitiesCount                  :[modelObject.ActivitiesCount                  ],
     NumberOfActivityPoints           :[modelObject.NumberOfActivityPoints           ],
     NumberOfActivityHours            :[modelObject.NumberOfActivityHours            ],
     EndMembershipDate                :[modelObject.EndMembershipDate                ],
     MembershipIsIsEnded              :[modelObject.MembershipIsIsEnded              ],
     Latitude                         :[modelObject.Latitude                         ],
     Langitude                        :[modelObject.Langitude                        ],
     Zoom                             :[modelObject.Zoom                             ],
     EndMembershipRequest             :[modelObject.EndMembershipRequest             ],
     TransferMembershipRequest        :[modelObject.TransferMembershipRequest        ],
     RenewMembershipRequest           :[modelObject.RenewMembershipRequest           ],
     HasOtherExperinceField           :[modelObject.HasOtherExperinceField           ],
     OtherExperinceField              :[modelObject.OtherExperinceField              ],
     OtherNationalty                  :[modelObject.OtherNationalty                  ],
     IsAccepted                       :[modelObject.IsAccepted                       ],
     IsObsolete                       :[modelObject.IsObsolete                       ],
     IsRejected                       :[modelObject.IsRejected                       ],
     PrefierdInterview                :[modelObject.PrefierdInterview                ],
     SocialStatus:[modelObject.SocialStatus],
     GraduationYear:[modelObject.GraduationYear],
     EducationalQualification:[modelObject.EducationalQualification],
    });
  }
  public editEnabled = true;
  public picurl: string ='';

  clear(){
    this.picurl = '';
 }
 public NationalIdEditEnabled = true;
 public NationalIdPicurl: string ='';

 clearNationalId(){
   this.NationalIdPicurl = '';
}
public graduationEditEnabled = true;
public graduationPicurl: string ='';

cleargraduation(){
  this.graduationPicurl = '';
}

  }
  