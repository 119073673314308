import { Component } from '@angular/core';

@Component({
    // moduleId: module.id,
    selector: 'acounting-base-data',
    templateUrl: 'acounting-base-data.component.html',
    styleUrls: ['acounting-base-data.component.scss']
    
})
export class AcountingBaseDataComponent {

}
