<div style="text-align: center;" *ngIf="Form">
    <a class="btn btn-light" 
    routerLink="../../monthly-distribution"
        style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Back" |
        translate}}</a>
    <button 
        *ngIf="Form"
         id="smallModalSubmitButton"
        class="btn btn-secondary" 
        (click)="Save()"
        [disabled]="Form.invalid || TotalCreditor != TotalDebtor"
        style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{buttonName |
        translate}}</button>
        <button 
        *ngIf="Form"
        [disabled]="Form.invalid || TotalCreditor != TotalDebtor"
        id="smallModalSubmitButton"
        class="btn btn-secondary" (click)="addAndNew()"
        style="font-weight: bold; width: 250px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{buttonName+"AndNew" |
        translate}}</button>
  </div>
<Form [formGroup]="Form" *ngIf="Form ">
    <div class="card">
        <div class="form-group  Card_Padding">
                    <br>
    
       <div class="row">
        <div class="col-md-10">
        <h3>{{ buttonName + ' Monthly Distribution' | translate }}</h3>
    
        </div>
    
        <!-- <button type="button" class="btn btn-primary"(click)="onClick()">{{ 'Save' | translate }}</button>{{' '}} -->
    
       </div>
       <div class="row">
        <div class="col-md-6">
            <label for="inputEmail4">{{ 'Name' | translate }}</label>
            <input formControlName="Name" type="text" id="inputEmail4" class="form-control">
        </div>
    
    </div>
 
    <br />
        </div>
    
    </div>
</Form>
<br />
<br />

<div class="card" *ngIf="Form">
    <div >
    <div class="row card-header" style="text-align: center; margin-top: 10px;">
        <div class="col-md-10" style="text-align: center;">
            <th>{{"Monthes"|translate}}</th>
        </div>
     
       </div>
    <div class="card-body">
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">{{'Month'| translate}}</th>
                    <th scope="col">{{'Percentage'| translate}}</th>
                </tr>
            </thead>
            <tbody>
                <tr  *ngFor="let Item of monthes; let i=index;">
                    <th style="width: 10%;" scope="row">{{i+1}}</th>
                    <td style="width: 30%;">
                      {{lang==='ar-EG' ? Item.MonthlyNameAr : Item.MonthlyNameEn}}
                    </td>
                    <td style="width: 20%;"><input [(ngModel)]="Item.Percentage"  type="number" class="form-control"></td>
              
                </tr>
              
            </tbody>
        </table>
<!-- 
        <div class="col-12 row">
            <div class=" col-md-11">
            </div>
        <button type="button" class="btn btn-primary"(click)="AddonClick()">{{ '+ Add' | translate }}</button>{{' '}}

          </div> -->
        <br>
    </div>
</div>
</div>
