import { ContractorModel } from "./contractor.model";
import { WorkItemModel } from "./work-item.model";

export class ProjectModel{
    Id ?: number;
    Code ?: number;
    ProjectAmount ?: number;
    NameAr ?: string;
    NameEn ?: string;
    ClientId ?: number;
    StartDate ?: Date;
    EndDate ?: Date;
    Description ?: string;
    Contractors ?: ContractorModel[];
    WorkItems ?: WorkItemModel[];
    constructor(item ?: ProjectModel){
        item = item ? item : {};
        this.Id = item.Id ? item.Id : 0;
        this.Code = item.Code ? item.Code : 0;
        this.ProjectAmount = item.ProjectAmount ? item.ProjectAmount : 0;
        this.ClientId = item.ClientId ? item.ClientId : 0;
        this.NameAr = item.NameAr ? item.NameAr : '';
        this.NameEn = item.NameEn ? item.NameEn : '';
        this.Description = item.Description ? item.Description : '';
        this.StartDate = item.StartDate ? item.StartDate : new Date();
        this.EndDate = item.EndDate ? item.EndDate : new Date();
        this.Contractors = item.Contractors ? item.Contractors : [];
        this.WorkItems = item.WorkItems ? item.WorkItems : [];
    }
}