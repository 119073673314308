<app-list-page-header *ngIf="tableView" [itemOptionsPerPage]="[5,10,20]" [itemsPerPage]="tableEngine.itemsPerPage"
    [selectAllState]="tableEngine.selectAllState" (addNewItem)="showModal(null,'Add')" [tableEngine]="this.tableEngine"
    (selectAllChange)="tableEngine.selectAll($event)" (changeOrderBy)="prepChangeSort($event)"
    [SearchKey]="''"
    (searchKeyUp)="tableEngine.filterData()" [dateSearch]=false>
</app-list-page-header>



<div class="form-group" *ngIf="!tableView">
    <div class="modal-body">
        <form class="md-float-material form-material" [formGroup]="mainForm">

            <div style="text-align: center;">
                <button class="btn btn-light" (click)="goBack()"
                    style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Back"  | translate}}</button>
                <button *ngIf="tableEngine.currentFormMode==='Add'"
                    [disabled]="mainForm.invalid || tableEngine.currentAction==='loading' || !codeWasChecked"
                    id="smallModalSubmitButton" class="btn btn-secondary" (click)="addObject()"
                    style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Add"  | translate}}</button>
                <button *ngIf="tableEngine.currentFormMode==='Add'" class="btn btn-secondary" (click)="addAndNew()"
                    [disabled]="mainForm.invalid || tableEngine.currentAction==='loading' || !codeWasChecked"
                    style="font-weight: bold; width: 250px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"AddAndNew"  | translate}}</button>
                <button *ngIf="tableEngine.currentFormMode==='Edit'"
                    [disabled]="mainForm.invalid || tableEngine.currentAction==='loading' || (!codeWasChecked && lastCode != modelObject.Code)"
                    id="smallModalSubmitButton" class="btn btn-secondary" (click)="editObject()"
                    style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Edit"
                    | translate}}</button>
            </div>
            <div class="form-group" style="margin-top: 10px;">

                <div class="row">

                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"CashierStaffCode" | translate}}</label>
                        <div *ngIf="(tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit') 
                                        && !configPoS.EnableAutoCodesForWorkShifts">
                            <input type="number" min="0" name="Code" class="form-control" [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="lastCode" style=" margin: 0px;" (change)="checkCode()" (keyup)="checkCode()"
                            placeholder='{{"CashierStaffCode" | translate}}'>
                        </div>
                        <div *ngIf="((tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit') 
                                        && configPoS.EnableAutoCodesForWorkShifts)">
                        <label class="form-control" type="text" style="border: unset; width: 120px;"
                            name="Code">{{lastCode}}</label>
                        </div>
                        <div *ngIf="tableEngine.currentFormMode == 'Info'">
                        <label class="form-control" type="text" style="border: unset; width: 120px;"
                            name="Code">{{modelObject.Code}}</label>
                        </div>
                        <div class="messages text-danger"
                            *ngIf="!(lastCode > 0)">
                            {{"CashierStaffCodeRequired" | translate}}</div>
                        <div class="messages text-danger"
                            *ngIf="!(codeWasChecked) && (tableEngine.currentFormMode != 'Info' && lastCode != modelObject.Code)">
                            {{"CasherStaffCodeAlreadyTaken" | translate}}</div>
                    </div>

                </div>

                <div class="row">
                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"CashierStaffStaffId" | translate}}</label>
                        <div *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'">
                            <ng-select appearance="outline" name="StaffId" formControlName="StaffId"
                                placeholder='{{"CashierStaffStaffId" | translate}}' 
                                #CashierStaffStoreDataSelect labelForId="txtycd" [searchable]="true">
                                <ng-option *ngFor="let staff of staffList" [value]="staff.Id">
                                    {{lang==='ar-EG'?staff.NameAr:staff.NameEn}}</ng-option>
                            </ng-select>
                        </div>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="StaffId">{{modelObject.StaffId>0?(lang==='ar-EG'?getStaffById(modelObject.StaffId).NameAr:getStaffById(modelObject.StaffId).NameEn):"None" | translate}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('StaffId').hasError('required') || mainForm.get('StaffId').invalid) && ( mainForm.get('StaffId').dirty ||  mainForm.get('StaffId').touched)">
                            {{"CashierStaffStaffIdRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>

                    <br>

                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"CashierStaffRole" | translate}}</label>
                        <label class="form-control" type="text" style="border: unset;" name="Role">
                            {{mainForm.getRawValue().StaffId>0?getStaffById(mainForm.getRawValue().StaffId).Role:'None'|translate}}
                        </label>
                    </div>

                    <br>

                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"CashierStaffEmail" | translate}}</label>
                        <label class="form-control" type="text" style="border: unset;" name="Email">
                            {{mainForm.getRawValue().StaffId>0?getStaffById(mainForm.getRawValue().StaffId).Email:'None'|translate}}
                        </label>
                    </div>

                </div>

                <br>

                <div class="row">
                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"CashierStaffBranchId" | translate}}</label>
                        <div *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'">
                            <ng-select appearance="outline" name="BranchId" formControlName="BranchId"
                                placeholder='{{"CashierStaffBranchId" | translate}}'
                                #CashierStaffStoreDataSelect labelForId="txtycd" [searchable]="true">
                                <ng-option *ngFor="let branch of branchList" [value]="branch.Id">
                                    {{branch.Id>0?(lang==='ar-EG'?branch.NameAr:branch.NameEn):"None" | translate}}</ng-option>
                            </ng-select>
                        </div>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="BranchId">{{modelObject.BranchId>0?(lang==='ar-EG'?getBranchById(modelObject.BranchId).NameAr:getBranchById(modelObject.BranchId).NameEn):"None" | translate}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('BranchId').hasError('required') || mainForm.get('BranchId').invalid) && ( mainForm.get('BranchId').dirty ||  mainForm.get('BranchId').touched)">
                            {{"CashierStaffBranchIdRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>
                </div>
            </div>

        </form>
    </div>
</div>

<div bsModal #delModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <!-- <button type="button" class="close pull-right" aria-label="Close" (click)="delModal.hide()"
                style="margin-right: 20px; margin-left: 20px; margin-top: 10px; width: 20px; display: inline;">
            <span aria-hidden="true">&nbsp;</span>
            </button> -->
            <span aria-hidden="true">&nbsp;</span>
            <div class="modal-header" style="display: inline; padding: 0px 0px 5px 0px;">
                <h4 id="dialog-child-name" class="modal-title pull-left" style="text-align: center; color: #DC3545;">
                    {{"ConfirmDel"+translationName |
                    translate}}</h4>
            </div>
            <div class="modal-body">
                <div class="form-group form-primary" *ngIf="tableEngine.deletionList.length < tableEngine.ogData.length 
                || tableEngine.deletionList.length === 1" style="text-align: center;">
                    <div *ngFor="let item of tableEngine.deletionList;" style="text-align: center;">
                        <!-- <label type="text">- {{translationName|translate}} {{"Number" | translate}}
                            {{lang==='ar-EG'?item.Code:item.Code}}</label> -->
                        <label type="text">- {{lang==='ar-EG'?item.NameAr:item.NameEn}}</label>
                        <span class="form-bar"></span>
                    </div>
                </div>
                <div class="form-group form-primary" style="text-align: center;" *ngIf="tableEngine.deletionList.length > 1 && 
                    tableEngine.deletionList.length === tableEngine.ogData.length">
                    <label type="text">
                        <h1 style="font-weight: bolder; text-align: center;">{{translationName+"DeleteAll" | translate}}
                        </h1>
                    </label>
                    <span class="form-bar"></span>
                </div>

                <div style="margin-left: 5px; text-align: center; margin-top: 30px;">
                    <button class="btn btn-danger" id="smallModalDeleteButton" (click)="deleteObject()"
                        [disabled]="tableEngine.currentAction==='loading'"
                        style="font-weight: bold; width: 100%; text-align: center;">{{"Delete" | translate}}</button>
                    <button class="btn btn-light" (click)="delModal.hide()"
                        style="font-weight: bold; width: 100%; text-align: center; margin-top: 10px;">{{"Close" |
                        translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <context-menu #basicMenu>
    <ng-template contextMenuItem let-item>
        <i class="simple-icon-docs mr-1"></i> <span>Copy</span>
    </ng-template>
    <ng-template contextMenuItem let-item>
        <i class="simple-icon-drawer mr-1"></i> <span>Move to archive</span>
    </ng-template>
    <ng-template contextMenuItem let-item (execute)="tableEngine.showDelModal('delMulti')">
        <i class="simple-icon-trash mr-1"></i><span>Delete</span>
    </ng-template>
</context-menu> -->

<div *ngIf="tableView" class="FormScreen" class="tableScreen">

    <div id="divmiddle" style="display: block; margin-top: 5px;">

        <div style="width: 100%;">
            <div>
                <div class="card" style="min-width: 900px !important;">
                    <div class="card-body">
                        <table class="table table-hover" style="margin: unset !important;">
                            <thead class="no-border-top">
                                <th style="text-align: center; padding:5px;">
                                    <div class=" text-zero" style="display: inline-block;">
                                        <div class="top-right-button-container2 btn-group" dropdown>
                                            <div class="btn btn-primary pl-4 pr-0 check-button">
                                                <label class="custom-control custom-checkbox mb-0 d-inline-block"
                                                    style="width: 10px;">
                                                    <input type="checkbox" class="custom-control-input"
                                                        [checked]="tableEngine.selectAllState==='checked'"
                                                        [indeterminate]="tableEngine.selectAllState === 'indeterminate'"
                                                        (change)="tableEngine.selectAll($event)">
                                                    <span class="custom-control-label">&nbsp;</span>
                                                </label>
                                            </div>
                                            <button id="button-split" type="button" dropdownToggle style="width: 10px;"
                                                [ngClass]="(tableEngine.selected.length == 0)?
                                                'btn btn-outline-primary dropdown-toggle dropdown-toggle-split':
                                                'btn btn-primary dropdown-toggle dropdown-toggle-split'"
                                                aria-controls="dropdown-split">
                                                <span class="caret"></span>
                                            </button>
                                            <ul id="dropdown-split" *dropdownMenu
                                                class="dropdown-menu dropdown-menu-right" role="menu"
                                                aria-labelledby="button-split">
                                                <li role="menuitem"><a class="dropdown-item">
                                                        <button class="btn btn-danger d-inline-block"
                                                            style=" font-weight: bold; text-align: center; padding: 0px; width: 150px; height: 30px;"
                                                            (click)="tableEngine.showDelModal('delMulti')"
                                                            [disabled]="tableEngine.selected.length===0 || tableEngine.currentAction==='loading'">{{"DeleteSelected"
                                                            |
                                                            translate}}</button></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </th>

                                <th class="sorticon" (click)="prepChangeSort('CashierStaffCode')"
                                    [ngClass]="(itemOrder.label==='CashierStaffCode')?'sortkey sorticon':'sorticon'">
                                    <div
                                        [ngClass]="{'iconsminds-up---down': (itemOrder.label!='CashierStaffCode'), 
                                    'iconsminds-down': ((itemOrder.label==='CashierStaffCode') && tableEngine.orderAsc === true),
                                    'iconsminds-up': ((itemOrder.label==='CashierStaffCode') && tableEngine.orderAsc === false)}">
                                    </div>
                                </th>
                                <th class="sortOption" (click)="prepChangeSort('CashierStaffCode')"
                                    [ngClass]="{'sortkey': (itemOrder.label==='CashierStaffCode')}">
                                    {{"CashierStaffCode" | translate}}
                                </th>

                                <th class="sorticon" (click)="prepChangeSort('CashierStaffName')"
                                    [ngClass]="(itemOrder.label==='CashierStaffName')?'sortkey sorticon':'sorticon'">
                                    <div
                                        [ngClass]="{'iconsminds-up---down': (itemOrder.label!='CashierStaffName'), 
                                    'iconsminds-down': ((itemOrder.label==='CashierStaffName') && tableEngine.orderAsc === true),
                                    'iconsminds-up': ((itemOrder.label==='CashierStaffName') && tableEngine.orderAsc === false)}">
                                    </div>
                                </th>
                                <th class="sortOption" (click)="prepChangeSort('CashierStaffName')"
                                    [ngClass]="{'sortkey': (itemOrder.label==='CashierStaffName')}">
                                    {{"CashierStaffName" | translate}}
                                </th>

                                <th class="sorticon" (click)="prepChangeSort('CashierStaffBranch')"
                                    [ngClass]="(itemOrder.label==='CashierStaffBranch')?'sortkey sorticon':'sorticon'">
                                    <div
                                        [ngClass]="{'iconsminds-up---down': (itemOrder.label!='CashierStaffBranch'), 
                                    'iconsminds-down': ((itemOrder.label==='CashierStaffBranch') && tableEngine.orderAsc === true),
                                    'iconsminds-up': ((itemOrder.label==='CashierStaffBranch') && tableEngine.orderAsc === false)}">
                                    </div>
                                </th>
                                <th class="sortOption" (click)="prepChangeSort('CashierStaffBranch')"
                                    [ngClass]="{'sortkey': (itemOrder.label==='CashierStaffBranch')}">
                                    {{"CashierStaffBranch" | translate}}
                                </th>

                                <th class="sorticon" (click)="prepChangeSort('CashierStaffRole')"
                                    [ngClass]="(itemOrder.label==='CashierStaffRole')?'sortkey sorticon':'sorticon'">
                                    <div
                                        [ngClass]="{'iconsminds-up---down': (itemOrder.label!='CashierStaffRole'), 
                                    'iconsminds-down': ((itemOrder.label==='CashierStaffRole') && tableEngine.orderAsc === true),
                                    'iconsminds-up': ((itemOrder.label==='CashierStaffRole') && tableEngine.orderAsc === false)}">
                                    </div>
                                </th>
                                <th class="sortOption" (click)="prepChangeSort('CashierStaffRole')"
                                    [ngClass]="{'sortkey': (itemOrder.label==='CashierStaffRole')}">
                                    {{"CashierStaffRole" | translate}}
                                </th>

                                <th class="sorticon" (click)="prepChangeSort('CashierStaffEmail')"
                                    [ngClass]="(itemOrder.label==='CashierStaffEmail')?'sortkey sorticon':'sorticon'">
                                    <div
                                        [ngClass]="{'iconsminds-up---down': (itemOrder.label!='CashierStaffEmail'), 
                                    'iconsminds-down': ((itemOrder.label==='CashierStaffEmail') && tableEngine.orderAsc === true),
                                    'iconsminds-up': ((itemOrder.label==='CashierStaffEmail') && tableEngine.orderAsc === false)}">
                                    </div>
                                </th>
                                <th class="sortOption" (click)="prepChangeSort('CashierStaffEmail')"
                                    [ngClass]="{'sortkey': (itemOrder.label==='CashierStaffEmail')}">
                                    {{"CashierStaffEmail" | translate}}
                                </th>

                                <th class="sorticon" (click)="prepChangeSort('CashierStaffLastRegister')"
                                    [ngClass]="(itemOrder.label==='CashierStaffLastRegister')?'sortkey sorticon':'sorticon'">
                                    <div
                                        [ngClass]="{'iconsminds-up---down': (itemOrder.label!='CashierStaffLastRegister'), 
                                    'iconsminds-down': ((itemOrder.label==='CashierStaffLastRegister') && tableEngine.orderAsc === true),
                                    'iconsminds-up': ((itemOrder.label==='CashierStaffLastRegister') && tableEngine.orderAsc === false)}">
                                    </div>
                                </th>
                                <th class="sortOption" (click)="prepChangeSort('CashierStaffLastRegister')"
                                    [ngClass]="{'sortkey': (itemOrder.label==='CashierStaffLastRegister')}">
                                    {{"CashierStaffLastRegister" | translate}}
                                </th>

                                <th [colSpan]="3"
                                    style="text-align: center; padding: unset !important; vertical-align: middle;">

                                    <div id="displayOptions" style="
                                        display: inline; margin-top: 10px; margin-left: 5px; margin-right: 5px;">
                                        <span class="text-muted text-small" style=" width: 50px; height: 40px;">
                                            {{"ResultCount" | translate}}
                                        </span>
                                        <span dropdown class="d-inline-block position-relative">
                                            <button class="btn btn-outline-dark btn-xs dropdown-toggle" type="button"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                                dropdownToggle>
                                                {{tableEngine.itemsPerPage}}
                                            </button>
                                            <div class="dropdown-menu dropdown-menu-right dropdown-menu-sm text-right"
                                                *dropdownMenu>
                                                <li role="menuitem" *ngFor="let item of tableEngine.itemOptionsPerPage">
                                                    <a [ngClass]="item === tableEngine.itemsPerPage ? 'dropdown-item active' : 'dropdown-item'"
                                                        (click)="tableEngine.paginateData(item, 1)">{{item}}</a>
                                                </li>
                                            </div>
                                        </span>
                                    </div>

                                </th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let obj of tableEngine.paginatedData;  let i=index">
                                    <td style="text-align: center; padding:5px;">
                                        <div class="itemCheck mb-0 custom-checkbox custom-control"
                                            style="display: inline-block;">
                                            <input type="checkbox" class="custom-control-input" [id]="'customCheck'+i"
                                                [checked]="tableEngine.isSelected(obj)"
                                                (change)="tableEngine.onSelect(obj)">
                                            <label class="custom-control-label" [for]="'customCheck'+i"></label>
                                        </div>
                                    </td>
                                    <td colspan="2">{{obj.Code}}</td>
                                    <td colspan="2">{{lang==='ar-EG'?obj.NameAr:obj.NameEn}}</td>
                                    <td colspan="2">{{lang==='ar-EG'?obj.BranchNameAr:obj.BranchNameEn}}</td>
                                    <td colspan="2">{{obj.Role}}</td>
                                    <td colspan="2">{{obj.Email}}</td>
                                    <td colspan="2">{{obj.LastRegisterCode>0?obj.LastRegisterCode:'None'|translate}}</td>
                                    <td style="text-align: center;">
                                        <i class="simple-icon-info" (click)="showModal(obj.Id, 'Info')"
                                            tooltip="{{'Info'|translate}}" placement="auto" style="font-size: 20px; font-weight: bolder; cursor: pointer; 
                                                vertical-align: middle; color: #17A2B8;"></i>
                                    </td>
                                    <td style="text-align: center;">
                                        <i class="simple-icon-pencil" (click)="showModal(obj.Id, 'Edit')"
                                            tooltip="{{'Edit'|translate}}" placement="auto" style="font-size: 20px; font-weight: bolder; cursor: pointer; 
                                                vertical-align: middle; color: #FFC107;"></i>
                                    </td>
                                    <td style="text-align: center;">
                                        <i class="simple-icon-close" (click)="tableEngine.showDelModal(obj.Id)"
                                            tooltip="{{'Delete'|translate}}" placement="auto" style="font-size: 20px; font-weight: bolder; cursor: pointer; 
                                                vertical-align: middle; color: #DC3545;"></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div id="divsearchmiddle" style="display: inline-block; width: 100%; height: 100%;">
                            <div style="text-align: center;">
                                <!-- <div class="centerside" style="display: inline-block; width: 15%; height: 100%; ">
                                    &nbsp;

                                </div> -->
                                <div class="centercenter"
                                    style="display: inline-block; width: 70%; height: 100%;  text-align: center;">

                                    <bdo dir="ltr">
                                        <pagination class="pagination-sm2" style="padding: 0px, 0px, 0px, 0px; margin: 0px, 0px, 0px, 0px;
                                        display: inline-block;" [(ngModel)]="currentPage" [boundaryLinks]="true"
                                            [totalItems]="tableEngine.filteredData.length"
                                            [itemsPerPage]="tableEngine.itemsPerPage"
                                            [customFirstTemplate]="firstTemplate" [customLastTemplate]="lastTemplate"
                                            [customPreviousTemplate]="prevTemplate" [customNextTemplate]="nextTemplate"
                                            [maxSize]="5" (pageChanged)="tableEngine.changePage($event)">
                                        </pagination>
                                    </bdo>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

            </div>
        </div>
    </div>

    <div id="divbottom" style="display: block; height: 47px;">

        <div id="divsearchright" style="float: right;  width: 33.33%; padding: 0px;">&nbsp;</div>


        <div id="divsearchleft" style="float: left; width: 33.33%; height: 100%;">&nbsp;</div>

    </div>
</div>

<div>

</div>

<ng-template #nextTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i *ngIf="lang==='en-US'" class='simple-icon-arrow-right'></i>
    <i *ngIf="lang==='ar-EG'" class='simple-icon-arrow-left'></i>
</ng-template>

<ng-template #prevTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i *ngIf="lang==='en-US'" class='simple-icon-arrow-left'></i>
    <i *ngIf="lang==='ar-EG'" class='simple-icon-arrow-right'></i>
</ng-template>

<ng-template #lastTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i class='simple-icon-control-end'></i>
</ng-template>

<ng-template #firstTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i class='simple-icon-control-start'></i>
</ng-template>


<simple-notifications></simple-notifications>