export class AdditionAdjustmentModel {
  Id?: number;
  CompanyDataId?: number;
  BranchesDataId?: number;
  StoreDataId?: number;
  ItemsHeaderId?: number;
  UnitTypeId?: number;
  UnitPrice?: number;
  Quantity?: number;
  TotalValue?: number;

  constructor(obj?: AdditionAdjustmentModel) {
    obj = obj ? obj : {};
    this.Id = obj?.Id ? obj.Id : 0;
    this.CompanyDataId = obj?.CompanyDataId ? obj.CompanyDataId : 0;
    this.BranchesDataId = obj?.BranchesDataId ? obj?.BranchesDataId : 0;
    this.StoreDataId = obj?.StoreDataId ? obj?.StoreDataId : 0;
    this.ItemsHeaderId = obj?.ItemsHeaderId ? obj?.ItemsHeaderId : 0;
    this.UnitTypeId = obj?.UnitTypeId ? obj?.UnitTypeId : 0;
    this.UnitPrice = obj?.UnitPrice ? obj?.UnitPrice : 0;
    this.Quantity = obj?.Quantity ? obj?.Quantity : 0;
    this.TotalValue = obj?.TotalValue ? obj?.TotalValue : 0;
  
  }
}
