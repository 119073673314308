import { ModalDirective } from 'ngx-bootstrap/modal';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { DataPaginator } from './../../../../views/invoice/shared/DataPaginator';
import { DataSorter } from './../../../../views/invoice/shared/DataSorter';
import { DataFilterer } from './../../../../views/invoice/shared/DataFilterer';
import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { ContractingBusinessExtractService } from '../../contractor-business-extract.service';
import { ContractingBusinessExtractModel } from '../../models/contractor-business-extract.model';

@Component({
    selector: 'all-contractor-business-extract',
    templateUrl: 'all-contractor-business-extract.component.html',
    styleUrls: ['all-contractor-business-extract.component.scss']
})
export class AllContractorBusinessExtractComponent implements OnInit {
    constructor(private formBuilder: FormBuilder, private modelService: ContractingBusinessExtractService, private renderer: Renderer2,
        private notifications: NotificationsService, private translate: TranslateService,private router : Router) {
      }
      currentScreen: any = 'Table';
    
      translationName: string = 'ContractingBusinessExtract'
     
      sortableKeys : any[] = [];
      searchableKeys = ['Code'];
      
      modelObject: any = {};
      ogData: ContractingBusinessExtractModel [] = [];
      mainForm: FormGroup = new FormGroup({
        Id: new FormControl(),
        NameEn: new FormControl(),
        Deductions: new FormControl(),
      });
      public lang;
      ngOnInit(): void {
      //  this.modelService.getAll()
      //  .subscribe(data => {
      //   this.ogData = data;
      //  })
        this.lang = localStorage.getItem('theme_lang');
        this.itemOrder = { label: 'Code', property: 'Code' };
        this.sortableKeys = [
          { label: 'Code', property: 'Code' },
          { label: 'Date', property: 'Date' },
          { label: 'Deductions', property: 'Deductions' },
          { label: 'BusinessInsurance', property: 'BusinessInsurance' },
          { label: 'Total', property: 'Total' }
        ];
        this.loadData();
      }
    
      //Deletion methods:
      //Customize if needed.
      //Pass the target object as paraMetre if the target API can only delete singular objects and not lists.
      deleteObject() {
        this.savedPage = this.currentPage;
        //prevent multiclicks. re enable after API response
        this.currentAction = 'loading';
        this.executeDeleteObject();
      }
    
      //Same here...
      executeDeleteObject(){
        this.modelService.deleteList(this.deletionList).subscribe(result => {
          if (result === true) {
            this.loadData();
            this.currentAction = '';
            this.finalizeDeletion();
          }
          else {
            this.toastError(this.translate.instant("Error"), this.translate.instant("FailedDelete"));
            this.currentAction = '';
          }
        })
      }
      ////////////////////////////////////////////////////////////////////////////
      //
      //
      //
      //
      //
      ////////////////////////////////////////////////////////////////////////////
      //  EXCLUSIVE :
      //  Specifically for this component.
      ///////////////////////////////
      
    
      ////////////////////////////////////////////////////////////////////////////
      //
      //
      //
      //
      //
      ////////////////////////////////////////////////////////////////////////////
      //  CONSTANT :
      //  Do not change.
      ///////////////////////////////
      currentFormMode: string = '';
      currentAction: string = '';
    
    
      //ogData = [];
      filteredData = [...this.ogData];
      sortedData = [...this.filteredData];
      paginatedData = [...this.filteredData];
      lastId: number = this.ogData.length + 1;
    
      SelectAllBox: any = false;
    
      itemsPerPage = 10;
      itemOptionsPerPage = [5, 10, 20];
      selected : any[] = [];
      selectAllState = '';
      itemOrder: any;
      orderAsc: boolean = true;
    
      currentPage = 1;
      savedPage = 0;
    
    
      dataFilterer = new DataFilterer();
      dataSorter = new DataSorter();
      dataPaginator = new DataPaginator();
    
      loadData() {
        this.currentAction = 'loading';
        this.modelService.getAll().subscribe(
          data => {
            this.ogData = data;
            this.sortData();
            this.loadSearch();
            this.currentAction = '';
          }
        )
        
      }
    
      sortData() {
        this.sortedData = this.dataSorter.sortData(this.itemOrder.property, this.ogData);
        this.filterData();
      }
    
      reverseSortData() {
        this.savedPage = this.currentPage;
        this.sortedData = this.sortedData.slice().reverse();
        this.filterData();
      }
    
      filterData() {
        debugger;
        this.filteredData = this.dataFilterer.filterData(this.SearchTerm, this.searchableKeys, this.sortedData)
        if (this.savedPage === 0) {
          this.paginateData(this.itemsPerPage, 1);
        }
        else {
          this.paginateData(this.itemsPerPage,
            Math.ceil((this.filteredData.length / this.itemsPerPage)) >= this.savedPage ?
              this.savedPage :
              Math.ceil((this.filteredData.length / this.itemsPerPage))
          );
        }
        this.savedPage = 0;
      }
    
      paginateData(newItemsPerPage: number, newCurrentPage: number) {
        this.itemsPerPage = newItemsPerPage;
        this.currentPage = newCurrentPage;
        this.paginatedData = this.filteredData.slice((newCurrentPage - 1) * (newItemsPerPage), (newItemsPerPage * newCurrentPage));
      }
    
      changeSort(newSort: any) {
        if (this.itemOrder.label === newSort.label) {
          this.orderAsc = !this.orderAsc;
          const oldPage = this.currentPage;
          this.reverseSortData()
          this.currentPage = oldPage;
        }
        else {
          this.orderAsc = true;
          this.itemOrder = newSort;
          this.sortData();
        }
      }
    
      getSort(label: any) {
        return this.sortableKeys.find(i => i.label === label);
      }
    
      changePage(event: PageChangedEvent): void {
        this.currentPage = event.page;
        this.paginateData(this.itemsPerPage, this.currentPage);
      }
    
      changeFilter() {
        this.deletionList = [];
        this.selected = [];
        this.selectAllState = '';
        this.filterData();
      }
    
      selectAll($event): void {
        if ($event.target.checked) {
          this.selected = [...this.filteredData];
        } else {
          this.selected = [];
        }
        this.setSelectAllState();
      }
    
      setSelectAllState(): void {
        if (this.selected.length >= this.filteredData.length) {
          this.selectAllState = 'checked';
        } else if (this.selected.length !== 0) {
          this.selectAllState = 'indeterminate';
        } else  {
          this.selectAllState = '';
        }
      }
    
      isSelected(p: ContractingBusinessExtractModel): boolean {
        return this.selected.findIndex(x => x.Id === p.Id) > -1;
      }
    
      onSelect(item: ContractingBusinessExtractModel): void {
        if (this.isSelected(item)) {
          this.selected = this.selected.filter(x => x.Id !== item.Id);
        } else {
          this.selected.push(item);
        }
        this.setSelectAllState();
      }
    
      findByCode(list: any, code: any): any {
        return list.find(x => x.Code === code)
      }
    
      @ViewChild('delModal') delModal: ModalDirective;
      showAddNewModalRef(): void {
       this.router.navigate(['app/aio/Construction/create-contractor-business-extracts/0'])
      }
    
      showInfoModalRef(Id: any): void {
        this.router.navigate(['app/aio/Construction/create-contractor-business-extracts',Id])
      }
    
      deletionList: any[] = [];
      showDelModal(Id: any): void {
        if (Id >= 0) {
          this.currentFormMode = 'Del'
          this.modelObject = this.ogData.find(i => i.Id === Id);
          this.deletionList = [this.ogData.find(i => i.Id === Id)];
          this.delModal.show();
        }
        else if (Id === 'delMulti') {
          this.deletionList = [...this.selected]
          this.delModal.show();
        }
      }
    
      SearchTerm: any = '';
    
    
      finalizeDeletion(){
        if (this.deletionList.length === 1) {
          this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyDeleted") + " " +
            this.translate.instant("One" + this.translationName));
        }
        else if (this.deletionList.length === 2) {
          this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyDeleted") + " " +
            this.translate.instant("Two" + this.translationName + "s"));
        }
        else if (this.deletionList.length > 2 && this.deletionList.length < 11) {
          this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyDeleted") + " " +
            this.deletionList.length + " " + this.translate.instant(this.translationName + "RowsPlur"));
        }
        else {
          this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyDeleted") + " " +
            this.deletionList.length + " " + this.translate.instant(this.translationName + "Rows"));
        }
    
        this.deletionList = [];
        this.selected = [];
    
        this.setSelectAllState();
        if (this.selectAllState === '') {
          this.SelectAllBox = false;
        }
        this.delModal.hide();
      }
    
      toastSuccess(toastHeader: string, toastBody: string): void {
        this.notifications.create(toastHeader, toastBody,
          NotificationType.Success, { timeOut: 3000, showProgressBar: true });
      }
      toastError(toastHeader: string, toastBody: string): void {
        this.notifications.create(toastHeader, toastBody,
          NotificationType.Error, { timeOut: 3000, showProgressBar: true });
      }
      deleteMultiTaxTypes() {
        this.loadData();
        this.delModal.hide();
      }
    
      loadAllData() {
        this.modelService.getAll().subscribe(
          data => {
            this.ogData = data;
            this.loadSearch();
          }
        )
      }
    
      loadSearch() {
        const prevSearch = this.SearchTerm;
        this.SearchTerm = '';
        setTimeout(() => {
          this.SearchTerm = prevSearch;
        }, 1);
      }
}
