<div class="row">
  <div class="col-12">
    <div class="card mb-4">
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-lg-6 mb-4">
            <label><span>{{ 'DateFrom' | translate }}</span></label>
            <input 
            type="text" onfocus="(this.type='date')" onblur="if(!this.value)this.type='text'"
            onchange="if(!this.value)this.type='text'" name="Date" class="form-control"
            [(ngModel)]="search.From"
            placeholder='{{"DateFrom" | translate}}'>
          </div>
          <div class="col-12 col-lg-6 mb-4">
            <label><span>{{ 'DateTo' | translate }}</span></label>
            <input 
            type="text" onfocus="(this.type='date')" onblur="if(!this.value)this.type='text'"
            onchange="if(!this.value)this.type='text'" name="Date" class="form-control"
            [(ngModel)]="search.To"
            placeholder='{{"DateTo" | translate}}'>
          </div>
          <div class="col-12 col-lg-6 mb-4">
            <label><span>{{ 'Branch' | translate }}</span></label>
       
            <ng-select placeholder='{{"Branch" | translate}}' *ngIf="lang==='ar-EG'" [items]="branches" bindLabel="NameAr" bindValue="Id" [(ngModel)]="search.BranchId">
            </ng-select>
            <ng-select placeholder='{{"Branch" | translate}}' *ngIf="lang!='ar-EG'" [items]="branches" bindLabel="NameEn" bindValue="Id" [(ngModel)]="search.BranchId">
            </ng-select>
          </div>
       
          <div class="col-12 col-lg-6 mb-4">
            <label><span>{{ 'Tax' | translate }}</span></label>
            <ng-select placeholder='{{"Tax" | translate}}'  *ngIf="lang==='ar-EG'" [items]="taxes" bindLabel="NameAr" bindValue="Id" [(ngModel)]="search.TaxId">
            </ng-select>
            <ng-select placeholder='{{"Tax" | translate}}'  *ngIf="lang!='ar-EG'" [items]="taxes" bindLabel="NameEn" bindValue="Id" [(ngModel)]="search.TaxId">
            </ng-select>
          </div>
        
          <div class="col-12 col-lg-6 mb-4 "  >
       </div>
          <div class="col-12 col-lg-6 mb-4 "  >
            <button type="button" (click)="Search()" class="btn btn-primary default mb-1" style="margin: 1px;">{{'Search' | translate}}</button>{{" "}}
            <button type="button" (click)="print()" class="btn btn-secondary default mb-1 " style="margin: 1px;">{{'Print' | translate}}</button>{{" "}}
            <button type="button" (click)="exportTable()" class="btn btn-success default mb-1" style="margin: 1px;">{{'PDF' | translate}}</button>{{" "}}
            <button type="button"  class="btn btn-primary default mb-1" style="margin: 1px;">{{'Excel' | translate}}</button>{{" "}}
        
          </div>
      </div>
    </div>
  </div>
</div>
</div>
<div id="contentToConvert">
  <div class="row" >
    <div class="col-12">
      <div class="card mb-4">
        <div class="card-body">
            <h3 class="float-center" style="text-align: center;">{{ 'Taxes Return Report' | translate }}</h3>
            <h4 class="float-center" style="text-align: center;">{{  today| date:'dd/MM/yyyy' }}</h4>
            <h6 class="float-center" style="text-align: center;">{{ 'Future Core' | translate }}</h6>
           
        </div>
      </div>
    </div>
  </div>
  
<div class="card mb-12 tree-explorer-side col-md-12" *ngIf="data && data.length > 0">
    <div class="card-body ">
    <div class="row" *ngFor="let cost of data">
        <table class="table table-striped"  >
          <thead>
              <tr >
                  <th colspan="5" style="font-size: large;">{{lang==='ar-EG'?cost.NameAr:cost.NameEn}}</th>
           
              </tr>
          
           
          </thead>
  
          <table class="table table-striped">
            <thead>
              <tr>
                  <th colspan="2">{{'Tax'|translate}}</th>
                  <th >{{lang==='ar-EG'?cost.NameAr:cost.NameEn}}</th>
                  <th >{{'Adjustments'|translate}}</th>
                  <th class="text-right">{{'Tax Due'|translate}}</th>
              </tr>
      </thead>
          <tbody>
              <tr  *ngFor="let item of cost.Details">
                  <td colspan="2">{{lang==='ar-EG'?item.TaxNameAr:item.TaxNameEn}}</td>
                  <td >{{item.Amount}}</td>
                  <td >{{item.AmountAdjustments}}</td>
                  <td class="text-right">{{item.TaxDue}}</td>
        
              </tr>
              <tr style="font: bold;font-size:medium">
                <td colspan="2" style="font: bold;">
                  {{'Total'|translate}}
                </td>
                <td style="font: bold;">{{cost.Amount}}</td>
                <td style="font: bold;">{{cost.AmountAdjustments}}</td>
                <td style="font: bold;"class="text-right">{{cost.TaxDue}}</td>
              </tr>
          </tbody>
        </table>
      </table>
   

      <hr />
     
    </div>

    </div>

    
    <table class="table table-striped">

    <tbody>
        <tr style="font: bold;font-size:medium">
          <td colspan="2" style="font: bold;">
            {{'Net'|translate}}
          </td>
          <td style="font: bold;" class="text-right">{{totalTaxDue}}</td>
        </tr>
    </tbody>
  </table>
  </div>
</div>