import { Component, Input, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { StoreBillService } from '../Add-store-bill.service';
import { NotificationType, NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableEngine } from 'src/app/views/app/aio/shared/TableEngine';
import { StoreBillModel } from '../../models/StoreBillModel';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { DataFilterer } from 'src/app/views/app/aio/shared/DataFilterer';
import { DataSorter } from 'src/app/views/app/aio/shared/DataSorter';
import { DataPaginator } from 'src/app/views/app/aio/shared/DataPaginator';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { SidePopupComponent } from 'src/app/views/app/aio/shared/side-popup/side-popup.component';

@Component({
    // moduleId: module.id,
    selector: 'list-store-bell',
    templateUrl: 'list-store-bell.component.html',
    styleUrls: ['list-store-bell.component.scss']
})
export class ListStoreBellComponent {

    Id=0;
    constructor(private formBuilder: FormBuilder, private modelService: StoreBillService, private renderer: Renderer2,
        private notifications: NotificationsService, private translate: TranslateService, private router : Router,
        private route: ActivatedRoute
        ) {   this.Id = + route.snapshot.paramMap.get('Id');
        }

tableEngine = new TableEngine(this.translate, this.notifications);
currentScreen: any = 'Table';
tableView: any = true;
// PriceList : PriceListModel[]=[];
@Input() dateSearch: boolean = true;


translationName: string = ' PruductList' 
sortableKeys = [];
searchableKeys = ['Date'];
modelObject: StoreBillModel = new StoreBillModel();

debugMode = true;

ogData: StoreBillModel[] = [];
mainForm: FormGroup = new FormGroup({
    Id: new FormControl(),
    BillType: new FormControl(),
    SkuNumber: new FormControl(),
    WarehouseId: new FormControl(),
    SubAccountId: new FormControl(),
    Notes: new FormControl(),
    Time: new FormControl(),
    Date: new FormControl(),
    IsApproval: new FormControl(),
    SecondWarehouseId: new FormControl(),
  
    
  });


declare lang: any;
declare itemOrder: any;
currentAction = '';
currentFormMode: string = '';
savedPage = 0;
currentPage = 1; 
deletionList: any[] = [];

@ViewChild('sideModalRef', { static: true }) sideModalRef: SidePopupComponent = null;
@ViewChild('delModal', { static: true }) delModal: ModalDirective;
@ViewChild('smallModal', { static: true }) smallModal: ModalDirective = null;
formPresentationModal: any;
ngOnInit(): void {
    // this.getAllCountryCode();    
     
    this.lang = localStorage.getItem('theme_lang');
    this.itemOrder = { label: 'Date', property: 'Date' };      
    this.sortableKeys = [         
        { label: 'Time', property: 'Time' },        
        { label: 'SkuNumber', property: 'SkuNumber' },  
        { label: 'Date', property: 'Date' },
             
    ];

    this.tableEngine.loadTableEngine(this.ogData, this.modelService, this.modelObject,
        this.searchableKeys, this.sortableKeys, this.mainForm, this.translationName, this.itemOrder,
        this.sideModalRef, this.delModal, this.smallModal, this.lang
    )
    //this.tableEngine.modelObject = {}
    console.log("KEEEEEEEEEEEEMO");
    
    console.log(this.tableEngine.modelObject);
    console.log(this.tableEngine.modelObject.constructor.name);
    
    this.loadServices();
} 
IsApproval(item){
    debugger;
    this.modelService.IsApproval(item.Id).subscribe(result => {
    if(item.IsApproval==false)
    {   
        item.IsApproval=true;
    }
})
}
showInfoModalRef(Id: any): void {
    this.router.navigate(['app/aio/Inventory/Info-Price-List',Id])
  }
  GoToTransfer(Id: any): void {
    this.router.navigate(['app/aio/Inventory/Transfer-Bell',Id])
  } 
  GoToSubtractBill(Id: any): void {
    this.router.navigate(['app/aio/Inventory/Subtract-Bell',Id])
  }
  GoToAddBill(Id: any): void {
    this.router.navigate(['app/aio/Inventory/Add-Bell',Id])
  }
  showDelModal(Id: any): void {
    debugger;
    if (Id >= 0) {
      this.currentFormMode = 'Del'
      this.modelObject = this.ogData.find(i => i.Id === Id);
      this.deletionList = [this.ogData.find(i => i.Id === Id)];
      this.delModal.show();
    }
    else if (Id === 'delMulti') {
      this.deletionList = [...this.selected]
      this.delModal.show();
    }
   
  }
  
  showModal(Id, action,BillType) {
  
    if (action === 'Add') {          
     debugger;
      // this.tableEngine.showAddNewModalRef(this.createForm, this.formBuilder)
      this.router.navigate(['app/aio/Inventory/Add-Bell/',0])   
    }
    else if (action === 'Edit') {
      debugger;
      if(BillType=='Inbound Requisition'||BillType=='اذن اضافة'){
        this.router.navigate(['app/aio/Inventory/Add-Bell/',Id])   
      }
      if(BillType=='Outbound Requisition'||BillType=='اذن صرف'){
        this.router.navigate(['app/aio/Inventory/Subtract-Bell/',Id])   
      }
      if(BillType=='Transfer Manual'||BillType=='تحويل يدوي'){
        this.router.navigate(['app/aio/Inventory/Transfer-Bell/',Id])   
      }
    }
    else if (action === 'Info') {
     debugger;
     if(BillType=='Inbound Requisition'||BillType=='اذن اضافة'){
      this.router.navigate(['app/aio/Inventory/Info-Bell/',Id])

    }
    if(BillType=='Outbound Requisition'||BillType=='اذن صرف'){
      this.router.navigate(['app/aio/Inventory/Info-Sub-Bell/',Id])   
    }
    if(BillType=='Transfer Manual'||BillType=='تحويل يدوي'){
      this.router.navigate(['app/aio/Inventory/Info-Transfer-Bell/',Id])   
    }
   
    }
    //this.mainForm.get('IsActive').setValue(true);
    console.log("component object", this.modelObject);
    console.log("TE object", this.tableEngine.modelObject);
     this.modelObject = this.tableEngine.modelObject;
     this.mainForm = this.tableEngine.mainForm;
  
  }
  
deleteObject() {
    this.savedPage = this.currentPage;
    //prevent multiclicks. re enable after API response
    this.currentAction = 'loading';
    this.executeDeleteObject();
  }
  
  //Same here...
  executeDeleteObject(){
    debugger;
    this.modelService.deleteList(this.tableEngine.deletionList).subscribe(result => {
      if (result === true) {
        this.tableEngine.loadData();
        this.currentAction = '';
        this.tableEngine.delModal.hide();
        // this.finalizeDeletion();
        this.tableEngine.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyDeleted"));
        if (this.sideModalRef === undefined) {
  
          this.tableEngine.smallModal.hide();
  
        } else {
  
          this.tableEngine.sideModalRef.modalRef.hide();
        }
      }
      else {
        this.toastError(this.translate.instant("Error"), this.translate.instant("FailedDelete"));
        this.currentAction = '';
      }
    })
  }
  
 
  createForm(modelObject): FormGroup {
    debugger;
    return this.formBuilder.group({
      // Id:[this.ItemBalance.Id],
      Id: [modelObject.Id >= 0 ? modelObject.Id : 0],
      BillType: [modelObject.BellType],
      SkuNumber: [modelObject.SkuNumber],
      
      WarehouseId: [modelObject.WarehouseId],
      SubAccountId: [modelObject.SubAccountId],
      Time: [modelObject.Time],
      Date: [modelObject.Date],
      IsApproval: [modelObject.IsApproval],
      Notes: [modelObject.Notes],
      SecondWarehouseId: [modelObject.SecondWarehouseId],
    
      
    });
  }
  addObject() {
    // debugger;
    console.log(this.mainForm.getRawValue().IsActive);
    console.log("before",this.modelObject);
    this.currentAction = 'loading';
    this.modelObject = new StoreBillModel();
    this.modelObject.Id = 0;
    this.modelObject.BillType = this.mainForm.getRawValue().BillType;
    this.modelObject.Date = this.mainForm.getRawValue().Date;
    this.modelObject.Notes = this.mainForm.getRawValue().Notes;
    this.modelObject.SkuNumber = this.mainForm.getRawValue().SkuNumber;
    this.modelObject.SubAccountId = this.mainForm.getRawValue().SubAccountId;
    this.modelObject.Time = this.mainForm.getRawValue().Time;
    this.modelObject.IsApproval = this.mainForm.getRawValue().IsApproval;
    this.modelObject.WarehouseId = this.mainForm.getRawValue().WarehouseId;
    console.log("after",this.modelObject);
  
    this.executeAddObject();
  }
  executeAddObject() {
    console.log(this.modelObject);
  
    this.modelService.insert(this.modelObject).subscribe(result => {
      if (result === true) {
        this.tableEngine.loadData();
        this.currentAction = '';
        this.tableEngine.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyAdded"));
        if (this.sideModalRef === undefined) {
  
          this.tableEngine.smallModal.hide();
  
        } else {
  
          this.tableEngine.sideModalRef.modalRef.hide();
        }
      }
      else {
        this.tableEngine.toastError(this.translate.instant("Error"), this.translate.instant("FailedAdd"));
        this.currentAction = '';
      }
    })
  }
  
  editObject() {
    debugger;
    this.currentAction = 'loading';
    this.tableEngine.savedPage = this.tableEngine.currentPage;
  
    let tempObj = new StoreBillModel({
      Id: this.modelObject.Id,
      SkuNumber: this.mainForm.getRawValue().SkuNumber,
      Date: this.mainForm.getRawValue().Date,
      Time:this.mainForm.getRawValue().Time,
      IsApproval:this.mainForm.getRawValue().IsApproval,
      SubAccountId:this.mainForm.getRawValue().SubAccountId,
      WarehouseId:this.mainForm.getRawValue().WarehouseId,
      Notes:this.mainForm.getRawValue().Notes,
      BillType:this.mainForm.getRawValue().BillType,
      SecondWarehouseId:this.mainForm.getRawValue().SecondWarehouseId,
      
    });
  
    if (JSON.stringify(tempObj) != JSON.stringify(this.modelObject)) {
      this.executeEditObject(tempObj);
    }
    else {if (this.sideModalRef === undefined) {
  
      this.tableEngine.smallModal.hide();
      
  
    } else {
  
      this.tableEngine.sideModalRef.modalRef.hide();
    }
    }
  }
  
  executeEditObject(Object) {
    console.log("ObjectObject", Object);
  
    this.modelService.update(Object).subscribe(result => {
      if (result === true) {
        this.tableEngine.loadData();
        this.currentAction = '';
        this.tableEngine.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyEdited"));
        if (this.sideModalRef === undefined) {
  
          this.tableEngine.smallModal.hide();
  
        } else {
  
          this.tableEngine.sideModalRef.modalRef.hide();
        }
      }
      else {
        this.tableEngine.toastError(this.translate.instant("Error"), this.translate.instant("FailedEdit"));
        this.currentAction = '';
      }
    })
  }
  toastError(toastHeader: string, toastBody: string): void {
    this.notifications.create(toastHeader, toastBody,
      NotificationType.Error, { timeOut: 3000, showProgressBar: true });
  }
  
  loadServices(){
      // debugger;
      this.currentAction = 'loading';
      this.tableEngine.loadData();         
    
     
  }
  searchUsingKeys(): void {
    this.tableEngine.filterData();
  }
  filteredData = [...this.ogData];
  sortedData = [...this.filteredData];
  paginatedData = [...this.filteredData];
  itemsPerPage = 10;
  itemOptionsPerPage = [5, 10, 20];
  
  selected : any[] = [];
  selectAllState = '';
  SearchFrom: Date = null;
  SearchTo: Date = null;
        dataFilterer = new DataFilterer();
        dataSorter = new DataSorter();
        dataPaginator = new DataPaginator();
        SearchTerm: any = '';
  
  clearSearch() {
    this.SearchFrom = null;
    this.SearchTo = null;
  }
  changeFilter() {
    this.deletionList = [];
    this.selected = [];
    this.selectAllState = '';
    this.filterData();
  }
  filterData() {
    this.filteredData = this.dataFilterer.filterData(this.SearchTerm, this.searchableKeys, this.sortedData)
    if (this.savedPage === 0) {
      this.paginateData(this.itemsPerPage, 1);
    }
    else {
      this.paginateData(this.itemsPerPage,
        Math.ceil((this.filteredData.length / this.itemsPerPage)) >= this.savedPage ?
          this.savedPage :
          Math.ceil((this.filteredData.length / this.itemsPerPage))
      );
    }
    this.savedPage = 0;
  }
  changePage(event: PageChangedEvent): void {
      this.currentPage = event.page;
      this.paginateData(this.itemsPerPage, this.currentPage);
    }
    paginateData(newItemsPerPage: number, newCurrentPage: number) {
      this.itemsPerPage = newItemsPerPage;
      this.currentPage = newCurrentPage;
      this.paginatedData = this.filteredData.slice((newCurrentPage - 1) * (newItemsPerPage), (newItemsPerPage * newCurrentPage));
      console.log('paginatedData',this.paginatedData)
  }
    isSelected(p: StoreBillModel): boolean {
      return this.selected.findIndex(x => x.Id === p.Id) > -1;
    }
  
    onSelect(item: StoreBillModel): void {
      if (this.isSelected(item)) {
        this.selected = this.selected.filter(x => x.Id !== item.Id);
      } else {
        this.selected.push(item);
      }
      this.setSelectAllState();
    }
  
    selectAll($event): void {
      if ($event.target.checked) {
        this.selected = [...this.filteredData];
      } else {
        this.selected = [];
      }
      this.setSelectAllState();
    }
  
    setSelectAllState(): void {
      if (this.selected.length >= this.filteredData.length) {
        this.selectAllState = 'checked';
      } else if (this.selected.length !== 0) {
        this.selectAllState = 'indeterminate';
      } else  {
        this.selectAllState = '';
      }
    }
  
  prepChangeSort(sortKey) {
    this.SearchFrom = null;
    this.SearchTo = null;
    
    
    this.tableEngine.changeSort(this.tableEngine.getSort(sortKey));
    this.itemOrder = this.tableEngine.itemOrder;
  }
  
  getFormattedDate(dateString: any){
    let splitDate: number[] = dateString.toString().split("/");
  
    return new Date(splitDate[2], splitDate[1]-1, splitDate[0])
  }
  ///////////////////////////////////////////////////////
  
  displayMode = 'list';
  changeDisplayMode(mode): void {
    this.displayMode = mode;
  }
  itemsPerPageChange(perPage: number): void {
    this.tableEngine.paginateData(perPage, 1)
  }
}
