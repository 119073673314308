import { InstallmentsOfPredecessorModel } from "./installments-of-predecessor.model";

export class PredecessorModel{
    Id?: number;
    Code                      ?: number;
    EmployeeName              ?: string;
    EmployeeId                ?: number;
    SubmissionDate            ?: Date | null;
    Amount                    ?: number;
    InstallmentAmount         ?: number;
    CurrencyId                ?: number;
    InstallmentNumber         ?: number;
    ReimbursementRate         ?: number;
    StartDateOfTheInstallments?: Date | null;
    TreasuryId                ?: number;
    Description               ?: string;
    PaymentFromSalarySlip     ?: boolean;
    Installments              ?: InstallmentsOfPredecessorModel[];
    constructor(item ?: PredecessorModel){
        item = item ? item : {};
        this.Id = item.Id ? item.Id : 0;
        this.Code                       = item.Code                       ? item.Code                       : 0;
        this.EmployeeName               = item.EmployeeName               ? item.EmployeeName               : '';
        this.EmployeeId                 = item.EmployeeId                 ? item.EmployeeId                 : 0;
        this.SubmissionDate             = item.SubmissionDate             ? item.SubmissionDate             : null;
        this.Amount                     = item.Amount                     ? item.Amount                     : 0;
        this.InstallmentAmount          = item.InstallmentAmount          ? item.InstallmentAmount          : 0;
        this.CurrencyId                 = item.CurrencyId                 ? item.CurrencyId                 : 0;
        this.InstallmentNumber          = item.InstallmentNumber          ? item.InstallmentNumber          : 0;
        this.ReimbursementRate          = item.ReimbursementRate          ? item.ReimbursementRate          : 0;
        this.StartDateOfTheInstallments = item.StartDateOfTheInstallments ? item.StartDateOfTheInstallments : null;
        this.TreasuryId                 = item.TreasuryId                 ? item.TreasuryId                 : 0;
        this.Description                = item.Description                ? item.Description                : '';
        this.PaymentFromSalarySlip      = item.PaymentFromSalarySlip      ? item.PaymentFromSalarySlip      : false;
        this.Installments               = item.Installments               ? item.Installments               : [];
        
    }
}