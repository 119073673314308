export class ConfigPurchasesModel {
    Id?: number;
    Code?: number;
    EnableAutoPurchaseOrderCodes?: boolean;
    EnableAutoRequestReceiptCodes?: boolean;
    EnableAutoPurchaseInvoiceCodes?: boolean;
    EnableAutoReturnRequestCodes?: boolean;
    EnableAutoPurchaseQuotationCodes?: boolean;
    EnableAutoQuotationRequestCodes?: boolean;
    StartingPurchaseOrderCode?: number;
    StartingRequestReceiptCode?: number;
    StartingPurchaseInvoiceCode?: number;
    StartingReturnRequestCode?: number;
    StartingPurchaseQuotationCode?: number;
    StartingQuotationRequestCode?: number;
    AllowedDiscounts?: string;
    UpdateItemValuesAfterInvoiceCreation?: boolean;
    AutoPaymentIfSupplierHasCredit?: boolean;
    PurchaseInvoicesPaidForByDefault?: boolean;
    PurchaseInvoicesRecievedByDefault?: boolean;
    PurchaseInvoicesManualStatusEnabled?: boolean;
    constructor(obj?: ConfigPurchasesModel) {
        obj = obj ? obj : {};
        this.Id = obj.Id ? obj.Id : 0;
        this.Code = obj.Code ? obj.Code : 0;
        this.EnableAutoPurchaseOrderCodes = obj.EnableAutoPurchaseOrderCodes ? obj.EnableAutoPurchaseOrderCodes : false;
        this.EnableAutoRequestReceiptCodes = obj.EnableAutoRequestReceiptCodes ? obj.EnableAutoRequestReceiptCodes : false;
        this.EnableAutoPurchaseInvoiceCodes = obj.EnableAutoPurchaseInvoiceCodes ? obj.EnableAutoPurchaseInvoiceCodes : false;
        this.EnableAutoReturnRequestCodes = obj.EnableAutoReturnRequestCodes ? obj.EnableAutoReturnRequestCodes : false;
        this.EnableAutoPurchaseQuotationCodes = obj.EnableAutoPurchaseQuotationCodes ? obj.EnableAutoPurchaseQuotationCodes : false;
        this.EnableAutoQuotationRequestCodes = obj.EnableAutoQuotationRequestCodes ? obj.EnableAutoQuotationRequestCodes : false;
        this.StartingPurchaseOrderCode = obj.StartingPurchaseOrderCode ? obj.StartingPurchaseOrderCode : 0;
        this.StartingRequestReceiptCode = obj.StartingRequestReceiptCode ? obj.StartingRequestReceiptCode : 0;
        this.StartingPurchaseInvoiceCode = obj.StartingPurchaseInvoiceCode ? obj.StartingPurchaseInvoiceCode : 0;
        this.StartingReturnRequestCode = obj.StartingReturnRequestCode ? obj.StartingReturnRequestCode : 0;
        this.StartingPurchaseQuotationCode = obj.StartingPurchaseQuotationCode ? obj.StartingPurchaseQuotationCode : 0;
        this.StartingQuotationRequestCode = obj.StartingQuotationRequestCode ? obj.StartingQuotationRequestCode : 0;
        this.AllowedDiscounts = obj.AllowedDiscounts ? obj.AllowedDiscounts : '';
        this.UpdateItemValuesAfterInvoiceCreation = obj.UpdateItemValuesAfterInvoiceCreation ? obj.UpdateItemValuesAfterInvoiceCreation : false;
        this.AutoPaymentIfSupplierHasCredit = obj.AutoPaymentIfSupplierHasCredit ? obj.AutoPaymentIfSupplierHasCredit : false;
        this.PurchaseInvoicesPaidForByDefault = obj.PurchaseInvoicesPaidForByDefault ? obj.PurchaseInvoicesPaidForByDefault : false;
        this.PurchaseInvoicesRecievedByDefault = obj.PurchaseInvoicesRecievedByDefault ? obj.PurchaseInvoicesRecievedByDefault : false;
        this.PurchaseInvoicesManualStatusEnabled = obj.PurchaseInvoicesManualStatusEnabled ? obj.PurchaseInvoicesManualStatusEnabled : false;
    }

}