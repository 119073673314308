import { LedgerAccountDataModel } from './../../models/ledger-account-data.model';
import { Component, OnInit } from '@angular/core';
import { LedgerAccountSearchModel } from './../../models/ledger-account-search.model';
import { AccountesNameModel } from 'src/app/Invintory/models/AccountesNameModel';
import { LedgerAccountService } from '../../ledger-account.service';
import { BranchService } from 'src/app/invoices/branches/BranchService';
import { AccountsNameService } from '../../../../account-name/AccountNameService';
import { BranchModel } from '../../../../test-component/BranchModel';
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';

@Component({
    selector: 'ledger-account',
    templateUrl: 'ledger-account.component.html',
    styleUrls: ['ledger-account.component.scss']
})
export class LedgerAccountComponent implements OnInit{

    declare lang: any;
    data:LedgerAccountDataModel[];
    search:LedgerAccountSearchModel={};
    accounts:AccountesNameModel[]=[];
    constructor(private LedgerAccountService:LedgerAccountService,
        private branchService:BranchService,
        private accountService:AccountsNameService,
        ){
      this.lang = localStorage.getItem('theme_lang');
    }
    TotalAssets = 0;
    Totalliabilities = 0;
    TotalPropertyRights = 0;
    branches:BranchModel[]=[];
    today=new Date();
    ngOnInit(): void {
      debugger;
        this.LedgerAccountService.search(this.search)
        .subscribe(data => {
          this.data =data;
       
          console.log('data',this.data)
        })
        this.branchService.getAll()
        .subscribe(branches=>{
          this.branches = branches;
        })
      this.accountService.GetAllSubAccountes(0)
        .subscribe(data => {
            this.accounts = data;
        })
    
    }
    Search(){
        this.LedgerAccountService.search(this.search)
        .subscribe(data => {
          this.data =data;
       
        })
    }
    exportTable(){
      var data = document.getElementById('contentToConvert');
      data.style.display = 'inline-block';
      html2canvas(data,{}).then(canvas => {
      var imgWidth = 208;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save('new-file.pdf'); // Generated PDF
      data.style.display = 'block';

    //   data.style.display = 'none';
      
    });
    }
    print(){
        let printContents, popupWin;
        printContents = document.getElementById('contentToConvert').innerHTML;
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.open();
        window.print();
       
      }
  
      changeSubCostCenter(){
          
      }
}
