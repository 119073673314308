import { environment } from 'src/environments/environment';
import { InvoiceReportModel } from './../models/invoice-report.model';
import { SearchModel } from './../models/search.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InvoiceReportService {
    constructor(private http: HttpClient) {
  
    }
    CompanyId:number =+ localStorage.getItem("CompanyId");

    baseUrl = environment.baseApiUrl;
     token = localStorage.getItem("token");
     httpOptions = {
      headers: new HttpHeaders({
        // 'Content-Type': 'application/json; charset=utf-8',
        // 'Authorization': "Bearer " + token,
         "Authorization": "Bearer " + this.token,
         "Content-Type": "application/json"
      })
    };
    getAll(search:SearchModel): Observable<InvoiceReportModel[]> {
   
      return this.http
        .post<InvoiceReportModel[]>(`${this.baseUrl}InvoiceReport/GetInvoiceReport/${this.CompanyId}`,search,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    GetPurshasesForReport(search:SearchModel): Observable<InvoiceReportModel[]> {
   
      return this.http
        .post<InvoiceReportModel[]>(`${this.baseUrl}InvoiceReport/GetPurshasesForReport/${this.CompanyId}`,search,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    printInvoice(Id:string): Observable<any> {
   
      return this.http
        .get<any>(`${this.baseUrl}InvoicePurshases/print/${Id}/${this.CompanyId}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    
}
