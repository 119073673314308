import { CreditTreasuryAndBankRolesBranchesModel } from './credit-treasury-and-bank-roles-branches-model';
import { CreditTreasuryAndBankRolesEmployeesModel } from './credit-treasury-and-bank-roles-employees';
import { CreditTreasuryAndBankRolesFunctionalRolesModel } from './credit-treasury-and-bank-roles-functional-roles-model';
import { DepositTreasuryAndBankRolesBranchesModel } from './deposit-treasury-and-bank-roles-branches-model';
import { DepositTreasuryAndBankRolesEmployeesModel } from './deposit-treasury-and-bank-roles-employees-model';
import { DepositTreasuryAndBankRolesFunctionalRolesModel } from './deposit-treasury-and-bank-roles-functional-roles-model';
export class TreasuryAndBankAccountsModel{
    Id?: number;
    Type                                          ?: number;
    Status                                        ?: boolean;
    Name                                          ?: string;
    Description                                   ?: string;
    AccountName                                   ?: string;
    AccountNumber                                 ?: string;
    CurrencyId                                    ?: number;
    DepositRolesType                              ?: number;
    CreditRolesType                               ?: number;
    DepositTreasuryAndBankRolesBranchesList       ?:DepositTreasuryAndBankRolesBranchesModel[];
    DepositTreasuryAndBankRolesEmployeesList      ?:DepositTreasuryAndBankRolesEmployeesModel[];
    DepositTreasuryAndBankRolesFunctionalRolesList?:DepositTreasuryAndBankRolesFunctionalRolesModel[];
    CreditTreasuryAndBankRolesBranchesList        ?:CreditTreasuryAndBankRolesBranchesModel[];
    CreditTreasuryAndBankRolesEmployeesList       ?:CreditTreasuryAndBankRolesEmployeesModel[];
    CreditTreasuryAndBankRolesFunctionalRolesList ?:CreditTreasuryAndBankRolesFunctionalRolesModel[];
    SelectedDepositTreasuryAndBankRolesBranchesList       ?:any[];
    SelectedDepositTreasuryAndBankRolesEmployeesList      ?:any[];
    SelectedDepositTreasuryAndBankRolesFunctionalRolesList?:any[];
    SelectedCreditTreasuryAndBankRolesBranchesList        ?:any[];
    SelectedCreditTreasuryAndBankRolesEmployeesList       ?:any[];
    SelectedCreditTreasuryAndBankRolesFunctionalRolesList ?:any[];
    constructor(item ?: TreasuryAndBankAccountsModel){
        item = item ? item : {};
        this.Id = item.Id ? item.Id : 0;
        this.Type = item.Type ? item.Type : 0;
        this.Status = item.Status ? item.Status : true;
        this.Name = item.Name ? item.Name : '';
        this.Description = item.Description?item.Description:'';
        this.AccountName = item.AccountName?item.AccountName:'';
        this.AccountNumber = item.AccountNumber?item.AccountNumber:'';
        this.CurrencyId = item.CurrencyId ? item.CurrencyId : 0;
        this.DepositRolesType = item.DepositRolesType?item.DepositRolesType:0;
        this.CreditRolesType = item.CreditRolesType ? item.CreditRolesType : 0;
        this.DepositTreasuryAndBankRolesBranchesList = item.DepositTreasuryAndBankRolesBranchesList?item.DepositTreasuryAndBankRolesBranchesList:[];
        this.DepositTreasuryAndBankRolesEmployeesList = item.DepositTreasuryAndBankRolesEmployeesList?item.DepositTreasuryAndBankRolesEmployeesList:[];
        this.DepositTreasuryAndBankRolesFunctionalRolesList = item.DepositTreasuryAndBankRolesFunctionalRolesList ? item.DepositTreasuryAndBankRolesFunctionalRolesList : [];
        this.CreditTreasuryAndBankRolesBranchesList = item.CreditTreasuryAndBankRolesBranchesList?item.CreditTreasuryAndBankRolesBranchesList:[];
        this.CreditTreasuryAndBankRolesEmployeesList = item.CreditTreasuryAndBankRolesEmployeesList?item.CreditTreasuryAndBankRolesEmployeesList:[];
        this.CreditTreasuryAndBankRolesFunctionalRolesList = item.CreditTreasuryAndBankRolesFunctionalRolesList ? item.CreditTreasuryAndBankRolesFunctionalRolesList : [];
        this.SelectedDepositTreasuryAndBankRolesBranchesList        = item.SelectedDepositTreasuryAndBankRolesBranchesList        ? item.SelectedDepositTreasuryAndBankRolesBranchesList        : [];
        this.SelectedDepositTreasuryAndBankRolesEmployeesList       = item.SelectedDepositTreasuryAndBankRolesEmployeesList       ? item.SelectedDepositTreasuryAndBankRolesEmployeesList       : [];
        this.SelectedDepositTreasuryAndBankRolesFunctionalRolesList = item.SelectedDepositTreasuryAndBankRolesFunctionalRolesList ? item.SelectedDepositTreasuryAndBankRolesFunctionalRolesList : [];
        this.SelectedCreditTreasuryAndBankRolesBranchesList         = item.SelectedCreditTreasuryAndBankRolesBranchesList         ? item.SelectedCreditTreasuryAndBankRolesBranchesList         : [];
        this.SelectedCreditTreasuryAndBankRolesEmployeesList        = item.SelectedCreditTreasuryAndBankRolesEmployeesList        ? item.SelectedCreditTreasuryAndBankRolesEmployeesList        : [];
        this.SelectedCreditTreasuryAndBankRolesFunctionalRolesList  = item.SelectedCreditTreasuryAndBankRolesFunctionalRolesList  ? item.SelectedCreditTreasuryAndBankRolesFunctionalRolesList  : [];
        
    }
}