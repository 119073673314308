export class HolidayDetailsModel{
    Id?: number;
    Name?: string;
    HolidayId?: number;
    Date?: Date | null;
    constructor(item?:HolidayDetailsModel){
        item = item?item:{};
        this.Id = item.Id ? item.Id : 0;
        this.Name = item.Name?item.Name:'';
        this.HolidayId = item.HolidayId ? item.HolidayId : 0;
        this.Date = item.Date ? item.Date : null;
    }
}