export class AssetsFileModel{
    Id ?: number;
    AssetId ?: number;
    FileUrl ?: string;
    constructor(item ?: AssetsFileModel){
        item = item ? item : {};
        this.Id = item.Id ? item.Id : 0;
        this.AssetId = item.AssetId ? item.AssetId : 0;
        this.FileUrl = item.FileUrl ? item.FileUrl : '';
    }
}