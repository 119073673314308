export class ItemsModel{
  Id?: Number;
  Code?: String;
  NameAr?: String
  NameEn?: String;
  TaxApiCode?: String;
  Cost?:Number;
  Stock?: Number;
  Total?: Number;
  CodingType?: String;
  Description?: String;
  ProductTypeAr?: String;
  ProductTypeEn?: String;
  ProductTypeId?: Number;
  MeasurCode?: String;
  CodingUnitsId?: Number;
    
    constructor(item?:ItemsModel){
        item = item? item:{};
        this.Id = item.Id? item.Id:0;
        this.Code = item.Code? item.Code:'';
        this.NameAr = item.NameAr? item.NameAr:'';
        this.NameEn = item.NameEn? item.NameEn:'';
        this.TaxApiCode = item.TaxApiCode? item.TaxApiCode:'';
        this.Cost = item.Cost? item.Cost:0;
        this.Stock = item.Stock? item.Stock:0;
        this.Total = item.Total? item.Total:0;
        this.CodingType = item.CodingType? item.CodingType:'';
        this.Description = item.Description? item.Description:'';
        this.ProductTypeAr = item.ProductTypeAr? item.ProductTypeAr:'';
        this.ProductTypeEn = item.ProductTypeEn? item.ProductTypeEn:'';
        this.ProductTypeId = item.ProductTypeId? item.ProductTypeId:0;
        this.MeasurCode = item.MeasurCode? item.MeasurCode:'';
        this.CodingUnitsId = item.CodingUnitsId? item.CodingUnitsId:0;
     
        
    
    }

}