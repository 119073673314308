import { ContractFilesModel } from "./contract-files.model";
import { SalaryDeductionsModel } from "./salary-deductions.model";
import { SalaryDuesModel } from "./salary-dues.model";

export class ContractModel{
  Id ?: number;
  ContractName                ?: string;
  ContractCode                ?: number;
  EmployeeName                ?: string;
  EmployeeId                  ?: number;
  Description                 ?: string;
  JobTitleName                ?: string;
  JobTitleId                  ?: number;
  JobLevelName                ?: string;
  JobLevelId                  ?: number;
  StartDate                   ?:Date | null;
  EndDate                     ?:Date | null;
  JoiningDate                 ?:Date | null;
  TestEndDate                 ?:Date | null;
  TheDateOfSigningTheContract ?:Date | null;
  Duration                    ?: number;
  DurationType                ?: number;
  CurrencyId                  ?: number;
  ArrestCycle                 ?: string;
  SalaryDues                  ?: SalaryDuesModel[];
  SalaryDeductions            ?: SalaryDeductionsModel[];
  ContractFiles               ?: ContractFilesModel[];
  BasicSalary?:number;
  Status?:string;
  constructor(item ?: ContractModel)
  {
    item = item ? item : {};
    this.Id = item.Id ? item.Id : 0;
    this.BasicSalary = item.BasicSalary ? item.BasicSalary : 0;
    this.Status = item.Status  ? item.Status: 'Active';
    this.ContractName                 = item.ContractName                 ? item.ContractName                 : '';
    this.ContractCode                 = item.ContractCode                 ? item.ContractCode                 : 0;
    this.EmployeeName                 = item.EmployeeName                 ? item.EmployeeName                 : '';
    this.EmployeeId                   = item.EmployeeId                   ? item.EmployeeId                   : 0;
    this.Description                  = item.Description                  ? item.Description                  : '';
    this.JobTitleName                 = item.JobTitleName                 ? item.JobTitleName                 : '';
    this.JobTitleId                   = item.JobTitleId                   ? item.JobTitleId                   : 0;
    this.JobLevelName                 = item.JobLevelName                 ? item.JobLevelName                 : '';
    this.JobLevelId                   = item.JobLevelId                   ? item.JobLevelId                   : 0;
    this.StartDate                    = item.StartDate                    ? item.StartDate                    : null;
    this.EndDate                      = item.EndDate                      ? item.EndDate                      : null;
    this.JoiningDate                  = item.JoiningDate                  ? item.JoiningDate                  : null;
    this.TestEndDate                  = item.TestEndDate                  ? item.TestEndDate                  : null;
    this.TheDateOfSigningTheContract  = item.TheDateOfSigningTheContract  ? item.TheDateOfSigningTheContract  : new Date();
    this.Duration                     = item.Duration                     ? item.Duration                     : 0;
    this.DurationType                 = item.DurationType                 ? item.DurationType                 : 1;
    this.CurrencyId                   = item.CurrencyId                   ? item.CurrencyId                   : 0;
    this.ArrestCycle                  = item.ArrestCycle                  ? item.ArrestCycle                  : '';
    this.SalaryDues                   = item.SalaryDues                   ? item.SalaryDues                   : [];
    this.SalaryDeductions             = item.SalaryDeductions             ? item.SalaryDeductions             : [];
    this.ContractFiles                = item.ContractFiles                ? item.ContractFiles                : [];
  }


}

