
export class InvoceExpensesModel{
    Id?: number;
    SupplierId?: number;
    CategoryId?: number;
    ProjectId?: number;
    Quantity?: number;
    UnitPrice?: number;
    TotalPrice?: number;
    Date?:Date;
    CostOfMerchandise?:boolean;
    NameAr?: string;
    NameEn?: string;
    SupplierName?: string;
    ExpensesCategoryName?: string;
    ProjectName?: string;
    DescriptionEn?: string;
    DescriptionAr?: string;
    constructor(item?: InvoceExpensesModel) {
        item = item ? item : {};
        this.Id = item.Id ? item.Id : 0;
        this.SupplierId = item.SupplierId ? item.SupplierId : 0;
        this.CategoryId = item.CategoryId ? item.CategoryId : 0;
        this.ProjectId = item.ProjectId ? item.ProjectId : 0;
        this.Quantity = item.Quantity ? item.Quantity : 0;
        this.UnitPrice = item.UnitPrice ? item.UnitPrice : 0;
        this.TotalPrice = item.TotalPrice ? item.TotalPrice : 0;
        this.CostOfMerchandise=item.CostOfMerchandise?item.CostOfMerchandise:true;
        this.Date = item.Date ? item.Date : new Date();
        this.NameAr = item.NameAr ? item.NameAr : '';
        this.NameEn = item.NameEn ? item.NameEn : '';
        this.SupplierName = item.SupplierName ? item.SupplierName : '';
        this.ExpensesCategoryName = item.ExpensesCategoryName ? item.ExpensesCategoryName : '';
        this.ProjectName = item.ProjectName ? item.ProjectName : '';
        this.DescriptionEn = item.DescriptionEn ? item.DescriptionEn : '';
        this.DescriptionAr = item.DescriptionAr ? item.DescriptionAr : '';
    }
}
