import { Component, OnInit, ViewChild } from '@angular/core';
import { AssetsModel } from '../../models/assets.model';
import { AssetsService } from '../../assets.service';
import { ActivatedRoute, Router } from '@angular/router';
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DepreciationOfAssetsModel } from '../../models/depreciation-of-assets.model'
import { NotificationType, NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'view-asset',
    templateUrl: 'view-asset.component.html',
    styleUrls: ['view-asset.component.scss']
})
export class ViewAssetComponent implements OnInit {
    declare lang: any;
    Id = 0;
    buttonName='';
    obj : AssetsModel=new AssetsModel();
    constructor( 
         private service: AssetsService,
        private route: ActivatedRoute,
        public router:Router,
        private fb:FormBuilder,
        private notifications: NotificationsService,
        private translate: TranslateService,
         ){
      this.lang = localStorage.getItem('theme_lang');
      this.Id = + route.snapshot.paramMap.get('Id');
        
    }
    ngOnInit(): void {
       this.service.getById(this.Id)
       .subscribe(result => {
        this.obj = result;
       })
    }
    openForExport = false;
    exportTable(){
      debugger;
      this.openForExport = true;
      var data = document.getElementById('contentToConvert');
      data.style.display = 'inline-block';
      html2canvas(data,{}).then(canvas => {
      var imgWidth = 208;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save('new-file.pdf'); // Generated PDF
      data.style.display = 'block';

    //   data.style.display = 'none';
      
    });
    }
    print(){
        let printContents, popupWin;
        printContents = document.getElementById('contentToConvert').innerHTML;
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.open();
        window.print();
       
      }
      DepreciationModel:DepreciationOfAssetsModel = new DepreciationOfAssetsModel();
      DepreciationForm:FormGroup;
      createDepreciationForm():FormGroup{
        return this.fb.group({
            Id:[this.DepreciationModel.Id],
            Amount:[this.DepreciationModel.Amount],
            AssetId:[this.DepreciationModel.AssetId],
            Date:[this.DepreciationModel.Date],
            IsAuto:[this.DepreciationModel.IsAuto],
            IsPayed:[this.DepreciationModel.IsPayed],
        })
      }
    @ViewChild('Depreciation') Depreciation: ModalDirective;
      showDepreciationModal(): void {
        this.DepreciationModel = new DepreciationOfAssetsModel();
        this.DepreciationForm = this.createDepreciationForm();
        this.Depreciation.show();
      }   
      SaveDepreciation(){
        this.DepreciationModel = this.DepreciationForm.getRawValue();
        this.DepreciationModel.AssetId = this.Id;
        this.DepreciationModel.Lang = this.lang;
        this.DepreciationModel.IsAuto = false;
        this.DepreciationModel.IsPayed = true;
        this.service.DepreciationOfAssets(this.DepreciationModel)
        .subscribe(data=>{
            if(data > 0){
                this.Depreciation.hide()
                this.DepreciationModel.Id = data;
                this.obj.DepreciationList.push(this.DepreciationModel);
                  this.toastSuccess(this.translate.instant("Success"), this.translate.instant("Success Added"));
              }
              else{
              this.toastError(this.translate.instant("Error"), this.translate.instant("Failed"));
                  
              }
        })
      }
      toastSuccess(toastHeader: string, toastBody: string): void {
        this.notifications.create(toastHeader, toastBody,
          NotificationType.Success, { timeOut: 3000, showProgressBar: true });
      }
      toastError(toastHeader: string, toastBody: string): void {
        this.notifications.create(toastHeader, toastBody,
          NotificationType.Error, { timeOut: 3000, showProgressBar: true });
      }
}
