<!--component html goes here -->
<div class="form-group">
    <div class="form-group row">
      <label for="inputEmail3" class="col-sm-2 col-form-label">{{'Beneficiary Network Name'|translate}}</label>
      <div class="col-sm-5"><input type="email" id="inputEmail3" class="form-control" >
      </div>
    </div>
    <div class="form-group row">
      <label for="inputState" class="col-sm-2 col-form-label">Organization</label>
      <select id="inputState" class="form-control col-sm-4 ">
        <option selected="">Choose...</option>
        <option>...</option>
      </select>
    </div>
    <div class="form-group row">
      <label for="inputState" class="col-sm-2 col-form-label">Link to cost center</label>
      <select id="inputState" class="form-control col-sm-4 ">
        <option selected="">Choose...</option>
        <option>...</option>
      </select>
    </div>
    <div class="card">
      <label for="inputState" class="col-sm-2 col-form-label"><h3>Account Permissions</h3></label>

      <div class="form-group row">

        <div class="col-sm-8">
          <div class="col-sm-8"><div class="form-check"><input type="checkbox" id="gridCheck1" class="form-check-input"><label for="gridCheck1" class="form-check-label"> protected </label></div></div>
          <div class="col-sm-8"><div class="form-check"><input type="checkbox" id="gridCheck1" class="form-check-input"><label for="gridCheck1" class="form-check-label"> prohibition of use </label></div></div>
          <div class="col-sm-8"><div class="form-check"><input type="checkbox" id="gridCheck1" class="form-check-input"><label for="gridCheck1" class="form-check-label"> Appear in the budget</label></div></div>
          <div class="col-sm-8"><div class="form-check"><input type="checkbox" id="gridCheck1" class="form-check-input"><label for="gridCheck1" class="form-check-label"> Prevent direct restrictions in the daily restrictions screen </label></div></div>
        </div>
        <div class=" col-sm-4 ">
          <button type="button" class="btn btn-outline-warning mb-1">Add an assistant professor account </button>
  
      </div>
   
    </div>
    </div>