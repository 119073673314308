export class ShiftDetailsModel{
    Id?: number;
    ShiftName         ?: string;
    ShiftId           ?: number;
    WeekDay           ?: string;
    DayId             ?: number;
    StartTime         ?: string;
    EndTime           ?: string;
    AttendaceTimeFrom ?: string;
    AttendaceTimeTo   ?: string;
    BeginningTimeFrom ?: string;
    BeginningTimeTo   ?: string;
    AllowableDelayTime?: number;
    constructor(item ?: ShiftDetailsModel){
        item = item ? item : {};
        this.Id = item.Id ? item.Id : 0;
        this.ShiftName          = item.ShiftName          ? item.ShiftName          : '';
        this.ShiftId            = item.ShiftId            ? item.ShiftId            : 0;
        this.WeekDay            = item.WeekDay            ? item.WeekDay            : '';
        this.DayId              = item.DayId              ? item.DayId              : 0;
        this.StartTime          = item.StartTime          ? item.StartTime          : null;
        this.EndTime            = item.EndTime            ? item.EndTime            : null;
        this.AttendaceTimeFrom  = item.AttendaceTimeFrom  ? item.AttendaceTimeFrom  : null;
        this.AttendaceTimeTo    = item.AttendaceTimeTo    ? item.AttendaceTimeTo    : null;
        this.BeginningTimeFrom  = item.BeginningTimeFrom  ? item.BeginningTimeFrom  : null;
        this.BeginningTimeTo    = item.BeginningTimeTo    ? item.BeginningTimeTo    : null;
        this.AllowableDelayTime = item.AllowableDelayTime ? item.AllowableDelayTime : 0;
    }
}