import { environment } from 'src/environments/environment';
import { ItemFilterPipe } from './../../pipes/item-filter.pipe';
import { InvoiceService } from './InvoiceService';
import { Component, HostBinding, Renderer2, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { of, throwError } from 'rxjs';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { DataFilterer } from 'src/app/views/app/aio/shared/DataFilterer';
import { DataSorter } from 'src/app/views/app/aio/shared/DataSorter';
import { DataPaginator } from 'src/app/views/app/aio/shared/DataPaginator';
import { InvoiceModel } from './InvoiceModel';
import { Router } from '@angular/router';
import { saveAs } from 'file-saver';



@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent {
  //IMPORTANT: ADD AND EDIT METHODS REMOVED
  //IMPORTANT: ADD AND EDIT METHODS REMOVED
  //IMPORTANT: ADD AND EDIT METHODS REMOVED
  //IMPORTANT: ADD AND EDIT METHODS REMOVED
  //IMPORTANT: ADD AND EDIT METHODS REMOVED
  //CTRL + F "IMPORTANT"
  //CTRL + F "IMPORTANT"
  //CTRL + F "IMPORTANT"
  //CTRL + F "IMPORTANT"
  //CTRL + F "IMPORTANT"
  
  ////////////////////////////////////////////////////////////////////////////
  //  VARYING :
  //  Modify per similar component.
  ////////////////////////////////////////////////////////////////////////////
  constructor(private formBuilder: FormBuilder, private modelService: InvoiceService, private renderer: Renderer2,
    private notifications: NotificationsService, private translate: TranslateService,private router : Router) {
  }
  currentScreen: any = 'Table';

  translationName: string = 'Invoice'

  sortableKeys = [];
  searchableKeys = ['InvoiceNumber'];
  
  modelObject: InvoiceModel = new InvoiceModel();
  ogData: InvoiceModel [] = [ 
    // {Id:5, InvoiceNumber:'123453', TaxActivityCode:'6789', Type:'Type1', ReleaseDate:new Date("4/12/2022"), PurchaseDate:new Date("4/1/2023"), Status:'Valid', TotalPrice:1000}, 
    // {Id:1, InvoiceNumber:'543121', TaxActivityCode:'1111', Type:'Type2', ReleaseDate:new Date("11/12/2022"), PurchaseDate:new Date("3/1/2023"), Status:'Invalid', TotalPrice:200}, 
    // {Id:2, InvoiceNumber:'821232', TaxActivityCode:'2345', Type:'Type3', ReleaseDate:new Date("10/12/2022"), PurchaseDate:new Date("1/1/2023"), Status:'ReadyToSend', TotalPrice:50}, 
    // {Id:3, InvoiceNumber:'551122', TaxActivityCode:'7654', Type:'Type4', ReleaseDate:new Date("9/12/2022"), PurchaseDate:new Date("2/1/2023"), Status:'In progress', TotalPrice:5050}, 
    // {Id:4, InvoiceNumber:'990085', TaxActivityCode:'9120', Type:'Type5', ReleaseDate:new Date("8/12/2022"), PurchaseDate:new Date("9/1/2023"), Status:'Valid', TotalPrice:990}, 

  ];
  mainForm: FormGroup = new FormGroup({
    Id: new FormControl(),
    NameEn: new FormControl(),
    NameAr: new FormControl(),
  });

  ngOnInit(): void {
    console.log("model");
    console.log(this.modelObject);
    
    
    this.lang = localStorage.getItem('theme_lang');
    this.itemOrder = { label: 'InvoiceNumber', property: 'InvoiceNumber' };
    this.sortableKeys = [
      { label: 'InvoiceNumber', property: 'InvoiceNumber' },
      { label: 'InvoiceTaxActivityCode', property: 'TaxActivityCode' },
      { label: 'InvoiceType', property: 'Type' },
      { label: 'InvoiceReleaseDate', property: 'ReleaseDate' },
      { label: 'InvoicePurchaseDate', property: 'PurchaseDate' },
      { label: 'InvoiceStatus', property: 'Status' },
      { label: 'InvoiceTotalPrice', property: 'TotalPrice' },
    ];
    this.loadData();
    console.log(this.ogData);
  }

  //Deletion methods:
  //Customize if needed.
  //Pass the target object as paraMetre if the target API can only delete singular objects and not lists.
  deleteObject() {
    this.savedPage = this.currentPage;
    //prevent multiclicks. re enable after API response
    this.currentAction = 'loading';
    this.executeDeleteObject();
  }

  //Same here...
  executeDeleteObject(){
    this.modelService.deleteList(this.deletionList).subscribe(result => {
      if (result === true) {
        this.loadData();
        this.currentAction = '';
        this.finalizeDeletion();
      }
      else {
        this.toastError(this.translate.instant("Error"), this.translate.instant("FailedDelete"));
        this.currentAction = '';
      }
    })
  }
  ////////////////////////////////////////////////////////////////////////////
  //
  //
  //
  //
  //
  ////////////////////////////////////////////////////////////////////////////
  //  EXCLUSIVE :
  //  Specifically for this component.
  ///////////////////////////////
  

  ////////////////////////////////////////////////////////////////////////////
  //
  //
  //
  //
  //
  ////////////////////////////////////////////////////////////////////////////
  //  CONSTANT :
  //  Do not change.
  ///////////////////////////////
  lang: string;
  currentFormMode: string = '';
  currentAction: string = '';


  //ogData = [];
  filteredData = [...this.ogData];
  sortedData = [...this.filteredData];
  paginatedData = [...this.filteredData];
  lastId: number = this.ogData.length + 1;

  SelectAllBox: any = false;

  itemsPerPage = 10;
  itemOptionsPerPage = [5, 10, 20];
  selected = [];
  selectAllState = '';
  itemOrder: any;
  orderAsc: boolean = true;

  currentPage = 1;
  savedPage = 0;


  dataFilterer = new DataFilterer();
  dataSorter = new DataSorter();
  dataPaginator = new DataPaginator();

  loadData() {
    debugger;
    this.currentAction = 'loading';
    this.modelService.getAll().subscribe(
      data => {
        this.currentAction = '';
        
        this.ogData = data;
        this.sortData();
      }
    )
    
  }

  sortData() {
    this.sortedData = this.dataSorter.sortData(this.itemOrder.property, this.ogData);
    this.filterData();
  }

  reverseSortData() {
    this.savedPage = this.currentPage;
    this.sortedData = this.sortedData.slice().reverse();
    this.filterData();
  }

  filterData() {
    this.filteredData = this.dataFilterer.filterData(this.SearchTerm, this.searchableKeys, this.sortedData)
    if (this.savedPage === 0) {
      this.paginateData(this.itemsPerPage, 1);
    }
    else {
      this.paginateData(this.itemsPerPage,
        Math.ceil((this.filteredData.length / this.itemsPerPage)) >= this.savedPage ?
          this.savedPage :
          Math.ceil((this.filteredData.length / this.itemsPerPage))
      );
    }
    this.savedPage = 0;
  }

  paginateData(newItemsPerPage: number, newCurrentPage: number) {
    this.itemsPerPage = newItemsPerPage;
    this.currentPage = newCurrentPage;
    this.paginatedData = this.filteredData.slice((newCurrentPage - 1) * (newItemsPerPage), (newItemsPerPage * newCurrentPage));
  }

  changeSort(newSort: any) {
    if (this.itemOrder.label === newSort.label) {
      this.orderAsc = !this.orderAsc;
      const oldPage = this.currentPage;
      this.reverseSortData()
      this.currentPage = oldPage;
    }
    else {
      this.orderAsc = true;
      this.itemOrder = newSort;
      this.sortData();
    }
  }

  getSort(label: any) {
    return this.sortableKeys.find(i => i.label === label);
  }

  changePage(event: PageChangedEvent): void {
    this.currentPage = event.page;
    this.paginateData(this.itemsPerPage, this.currentPage);
  }

  changeFilter() {
    this.deletionList = [];
    this.selected = [];
    this.selectAllState = '';
    this.filterData();
  }

  selectAll($event): void {
    if ($event.target.checked) {
      this.selected = [...this.filteredData];
    } else {
      this.selected = [];
    }
    this.setSelectAllState();
  }

  setSelectAllState(): void {
    if (this.selected.length >= this.filteredData.length) {
      this.selectAllState = 'checked';
    } else if (this.selected.length !== 0) {
      this.selectAllState = 'indeterminate';
    } else  {
      this.selectAllState = '';
    }
  }

  isSelected(p: InvoiceModel): boolean {
    return this.selected.findIndex(x => x.Id === p.Id) > -1;
  }

  onSelect(item: InvoiceModel): void {
    if (this.isSelected(item)) {
      this.selected = this.selected.filter(x => x.Id !== item.Id);
    } else {
      this.selected.push(item);
    }
    this.setSelectAllState();
  }

  findByCode(list: any, code: any): any {
    return list.find(x => x.Code === code)
  }

  @ViewChild('delModal') delModal: ModalDirective;
  showAddNewModalRef(): void {
   this.router.navigate(['app/invoices/Reports/Create-Invoice'])
  }

  showInfoModalRef(Id: any): void {
    //IMPORTANT
    //REDIRECT TO INFO PAGE
  }

  deletionList: InvoiceModel[] = [];
  showDelModal(Id: any): void {
    if (Id >= 0) {
      this.currentFormMode = 'Del'
      this.modelObject = this.ogData.find(i => i.Id === Id);
      this.deletionList = [this.ogData.find(i => i.Id === Id)];
      this.delModal.show();
    }
    else if (Id === 'delMulti') {
      this.deletionList = [...this.selected]
      this.delModal.show();
    }
  }

  SearchTerm: any = '';


  finalizeDeletion(){
    if (this.deletionList.length === 1) {
      this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyDeleted") + " " +
        this.translate.instant("One" + this.translationName));
    }
    else if (this.deletionList.length === 2) {
      this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyDeleted") + " " +
        this.translate.instant("Two" + this.translationName + "s"));
    }
    else if (this.deletionList.length > 2 && this.deletionList.length < 11) {
      this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyDeleted") + " " +
        this.deletionList.length + " " + this.translate.instant(this.translationName + "RowsPlur"));
    }
    else {
      this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyDeleted") + " " +
        this.deletionList.length + " " + this.translate.instant(this.translationName + "Rows"));
    }

    this.deletionList = [];
    this.selected = [];

    this.setSelectAllState();
    if (this.selectAllState === '') {
      this.SelectAllBox = false;
    }
    this.delModal.hide();
  }

  toastSuccess(toastHeader: string, toastBody: string): void {
    this.notifications.create(toastHeader, toastBody,
      NotificationType.Success, { timeOut: 3000, showProgressBar: true });
  }
  toastError(toastHeader: string, toastBody: string): void {
    this.notifications.create(toastHeader, toastBody,
      NotificationType.Error, { timeOut: 3000, showProgressBar: true });
  }
  deleteMultiTaxTypes() {
    this.loadData();
    this.delModal.hide();
  }

  loadAllData() {
    this.modelService.getAll().subscribe(
      data => {
        this.ogData = data;
        this.loadSearch();
      }
    )
  }

  loadSearch() {
    const prevSearch = this.SearchTerm;
    this.SearchTerm = '';
    setTimeout(() => {
      this.SearchTerm = prevSearch;
    }, 1);
  }
  Send(){
    this.modelService.Send(this.SendId,this.TokenPin)
    .subscribe(result=>{
      if(result == true){
    this.toastSuccess(this.translate.instant("Success"), this.translate.instant("The invoice has been sent successfully"));
        var i = this.ogData.findIndex(x=>x.Id == this.SendId);
        if(i > -1){
          this.ogData[i].Status = 'In progress';
        }
      }
    })
  }
  pdfSrc = '';
  TokenPin = '';
  SendId = 0;
  print(Id){
    debugger;
    // var mediaType = 'application/pdf';
    // this.modelService.loginToTaxes()
    // .subscribe(token => {
    //   var i = this.paginatedData.findIndex(c=>c.Id == Id);
    //   this.modelService.print(this.paginatedData[i].uuid,token)
    //   .subscribe(   (response) => {
    //     var blob = new Blob([response], { type: mediaType });
    //     saveAs(blob, 'report.pdf');
    // },
    // e => { throwError(e); })
    // })
  this.modelService.printFromBackEnd(Id)
  .subscribe(result =>{
    console.log(result);
    this.pdfSrc = result;
    saveAs(`${environment.apiUrl}/Resources/Invoices/${result}`, 'report.pdf');
    // window.open(`${environment.apiUrl}/Resources/Invoices/${result}`, '_blank');
  //  window.location.href =result
  //   window.open(result, '_blank');
  //   window.open(result);
  })
  }
  @ViewChild('smallModal') smallModal: ModalDirective;

  showPINModalRef(Id): void {
    // this.currentFormMode = 'Add'
    this.SendId = Id;
    this.smallModal.show();
  }
  private selectedFile: File;
  uploadFile(event) {
    let formData = new FormData();
    this.selectedFile = event.target.files[0];
    formData.append('upload', this.selectedFile)

    this.modelService.UploadExcel(formData).subscribe(result => {
    
    });
   

  }
}


