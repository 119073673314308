import { Component } from '@angular/core';

@Component({
    // moduleId: module.id,
    selector: 'account-power',
    templateUrl: 'account-power.component.html',
    styleUrls: ['account-power.component.scss']
})
export class AccountPowerComponent {

}
