import { MonthlyDistributionPercentagesModel } from "./monthly-distribution-percentages.model";

export class MonthlyDistributionModel{
    Id?:number;
    Name ?: string;
    FiscalYear ?: number;
    MonthlyDistributionPercentages ?: MonthlyDistributionPercentagesModel[];
    constructor(item ?: MonthlyDistributionModel){
        item = item ? item : {};
        this.FiscalYear = item.FiscalYear ? item.FiscalYear : 0;
        this.Name = item.Name ? item.Name : '';
        this.Id = item.Id ? item.Id : 0;
        this.MonthlyDistributionPercentages = item.MonthlyDistributionPercentages ? item.MonthlyDistributionPercentages : [];
    }
}