import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BranchItemModel } from '../model/get_branch.model';

@Injectable({
  providedIn: 'root'
})
export class GetClient {
  baseUrl = environment.baseApiUrl;
  CompanyId:number =+ localStorage.getItem("CompanyId");

   token = localStorage.getItem("token");
   httpOptions = {
    headers: new HttpHeaders({
      // 'Content-Type': 'application/json; charset=utf-8',
      // 'Authorization': "Bearer " + token,
       "Authorization": "Bearer " + this.token,
       "Content-Type": "application/json"
    })
  };
  constructor(private http: HttpClient) {

  }

  getAll(): Observable<any> {
 
    return this.http
      .get<any>(`${this.baseUrl}Client/GetAll/${this.CompanyId}`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error => error)));
  }

  getById(id:number): Observable<any> {
 
    // debugger;
    return this.http
      .get<any>(`${this.baseUrl}Item/GetById/${id}`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error => error)));
  }
  createProject(payload: BranchItemModel): Observable<any> {
    
    debugger;
    return this.http
      .post<any>(`${this.baseUrl}Alboum/Insert/${this.CompanyId}`, payload,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error => error)));
  }

  put(payload: BranchItemModel): Observable<any> {
    debugger;
    return this.http
      .post<any>(`${this.baseUrl}Alboum/Update`, payload,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error => error)));
  }

  delete(id: number): Observable<any> {
    return this.http
      .get<any>(`${this.baseUrl}Alboum/Delete/${id}`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error => error)));
  }
}
