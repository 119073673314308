<!--component html goes here -->
<!--component html goes here -->
<div class="row" *ngIf="mainForm">
    <div class="col-12">
      <div class="card mb-4">
        <div class="card-body">
          <h5 class="float-left">{{ "StorePermission" | translate }}</h5>
          <div class="top-right-button-container text-zero float-right">
            <button
              class="top-right-button btn btn-primary btn-lg mr-1"
              (click)="goBack()"
            >
              {{ "Back" | translate }}
            </button>
            <button
              type="button"
              (click)="Save()"
              [disabled]="mainForm.invalid"
              class="top-right-button btn btn-primary btn-lg mr-1"
            >
              {{ "Save" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
    
  </div>
  <div class="modal-body">
    <form
      class="md-float-material form-material"
      [formGroup]="mainForm"
    >
  <!-- <div style="font-size: large;" class="alert" *ngIf="mainForm.get('BillType').value !=1&&mainForm.get('BillType').value !=2&&mainForm.get('BillType').value !=3">
    <span class="closebtn" onclick="this.parentElement.style.display='none';">&times;</span> 
    <strong > {{ 'Warning' | translate }}!</strong> {{ MessagChoice | translate }}
  </div> -->
</form>
</div>
  <div class="row">
    
    <div class="col-md-6">
        <!-- <div class="col-md-12 col-lg-12 col-12 mb-12"> -->
          <div class="card">
            <tabset class="card-tabs" [justified]="true">
              <tab heading="{{ 'Product' | translate }}" class="card-body">
                <!-- <h6 class="mb-4">{{ "Basic Data" | translate }}</h6> -->
                <div class="modal-body">
                  <form
                    class="md-float-material form-material"
                    [formGroup]="mainForm"
                  >
                    <div class="row">
                      <div class="col-md-6">
                          <label for="inputState">{{ "SkuNumber" | translate }}</label>
                          <input
                            class="form-control"
                            formControlName="SkuNumber"
                            rows="2"
                            placeholder="{{ 'SkuNumber' | translate }}"
                          />
                          <div
                            class="messages text-danger"
                            *ngIf="
                              (mainForm.get('SkuNumber').hasError('required') ||
                                mainForm.get('SkuNumber').invalid) &&
                              (mainForm.get('SkuNumber').dirty ||
                                mainForm.get('SkuNumber').touched)
                            "
                          >
                            {{ "SkuNumberRequired" | translate }}
                          </div>
                        </div> 
                    
                         <div class="col-md-6">
                          <label for="inputState">{{ "BillType" | translate }}</label>
                          <input
                            class="form-control"
                            formControlName="BillType"
                            rows="2"
                            placeholder="{{ 'BillType' | translate }}"
                            
                            readonly
                          /> 
                          <div
                            class="messages text-danger"
                            *ngIf="
                              (mainForm.get('BillType').hasError('required') ||
                                mainForm.get('BillType').invalid) &&
                              (mainForm.get('BillType').dirty ||
                                mainForm.get('BillType').touched)
                            "
                          >
                            {{ "BillTypeRequired" | translate }}
                          </div>
                        </div>
                           <!-- <div class="col-md-6">
                            <label for="inputState">{{ "BillType" | translate }}</label>
                            <select
                                   id="inputState"
                                   class="form-control"
                                   formControlName="BillType"  
                                           
                                 >
                                   <option selected value="1"
                                   >                                
                                     {{ "AddBill" | translate }}
                                   </option>
                                   <option value="2">
                                   {{ "SubBell" | translate }}
       
                                </option>
                                <option value="3">
                                  {{ "Transformation" | translate }}
      
                               </option>
                             
                          </select> 
                          <div
                          class="messages text-danger"
                          *ngIf="
                            (mainForm.get('BillType').hasError('required') ||
                              mainForm.get('BillType').invalid) &&
                            (mainForm.get('BillType').dirty ||
                              mainForm.get('BillType').touched)
                          "
                        >
                          {{ "BillTypeRequired" | translate }}
                        </div> 
                          </div> -->


                          <div class="col-md-6">
                            <label for="inputState">{{ "Date" | translate }}</label>
                            <!-- <barcode type="code128b">Hello</barcode> -->
                            <input
                              class="form-control"
                              formControlName="Date"
                              rows="2"
                              placeholder="{{ 'Date' | translate }}"
                              type="date" />
                            <div
                              class="messages text-danger"
                              *ngIf="
                                (mainForm.get('Date').hasError('required') ||
                                  mainForm.get('Date').invalid) &&
                                (mainForm.get('Date').dirty ||
                                  mainForm.get('Date').touched)
                              "
                            >
                              {{ "DateRequired" | translate }}
                            </div>
                          </div>
                          
      
      
                      <div class="col-md-4">
                        <label for="inputState">{{ "Time" | translate }}</label>
                        <!-- <barcode type="code128b">Hello</barcode> -->
                        <input
                          class="form-control"
                          formControlName="Time"
                          rows="2"
                          placeholder="{{ 'Time' | translate }}"
                          type="time"
                        />
                        <div
                          class="messages text-danger"
                          *ngIf="
                            (mainForm.get('Time').hasError('required') ||
                              mainForm.get('Time').invalid) &&
                            (mainForm.get('Time').dirty ||
                              mainForm.get('Time').touched)
                          "
                        >
                          {{ "NameRequired" | translate }}
                        </div>
                      </div>
      
      
                    
                    
      
                    </div>
                    <div class="row"></div>
                   
                  </form>
                </div>
              </tab>
            </tabset>
          </div>
   </div>
   <div class="col-md-6">
    <div class="card">
      <tabset class="card-tabs" [justified]="true">
        <tab heading="{{ 'Warehouse' | translate }}" class="card-body">
          <div class="modal-body">
            <form
                    class="md-float-material form-material"
                    [formGroup]="mainForm"
                  >
                  <div class="row">
                    <div class="col-md-12">
                      <label for="inputState">{{ "from" | translate }}</label>
                      <select
                         id="inputState"
                         class="form-control"
                         formControlName="WarehouseId"
                         (change)="setQuantityByWarehouse()"
                       >
                        <option selected value="0">
                         {{ "Warehouse" | translate }}
                        </option>
                        <option
                          *ngFor="let warehouse of WarehouseList"
                          value="{{ warehouse.Id }}"
                          >
                          {{warehouse.Name }}
                       </option>
                      </select>
                    </div>

                    <div class="col-md-12" >
                      <label for="inputState">{{ "To" | translate }}</label>
                      <select
                         id="inputState"
                         class="form-control"
                         formControlName="SecondWarehouseId"
                       >
                        <option selected value="0">
                         {{ "Warehouse" | translate }}
                        </option>
                        <option
                          *ngFor="let warehouse of WarehouseList"
                          value="{{ warehouse.Id }}"
                          >
                          {{warehouse.Name }}
                       </option>
                      </select>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <label for="inputState">{{ "SubAccount" | translate }}</label>
                      <select
                         id="inputState"
                         class="form-control"
                         formControlName="SubAccountId"
                       >
                        <option selected value="0">
                         {{ "from" | translate }}
                        </option>
                        <option
                          *ngFor="let Account of AccountesList"
                          value="{{ Account.Id }}"
                          >
                          {{ lang === "ar-EG" ? Account.NameAr : Account.NameEn }}
                       </option>
                      </select>
                    </div>
                  </div>
            </form>
          </div>
        </tab>
      </tabset>
    </div>
   </div>
  </div>
  <td></td>
  <td></td>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <tabset class="card-tabs" [justified]="true">
          <tab heading="{{ 'Items' | translate }}" class="card-body">
           
                  <table  class="tableView" >
                  <thead class="no-border-top">
                  <!-- {{'Please choose the type of permission stored first'|translate}} -->
                  <tr class="thead-light"  *ngIf="ItemsStoreBell.length > 0">
                    <td>{{'Item'|translate}}</td>
                    <td>
                      {{'UnitPrice'|translate}}
                    </td>
                    <td>{{'Quantity'|translate}}</td>                 
                    <td>{{'InventoryBeforeQuantity'|translate}}</td>
                    <td>{{'InventoryAfterQuantity'|translate}}</td>
                    <td>{{'Total'|translate}}</td>
                  </tr>
           
     
              
                </thead>
                <tbody>
                  
                  <tr *ngFor="let bell of ItemsStoreBell; let i = index">
                  <td style="vertical-align: middle">
                    <select
                    id="inputState"
                    class="form-control"
                    [(ngModel)]="bell.ItemId"
                    [ngModelOptions]="{standalone: true}"
                    (change)="setItem(bell)"
                    (change)="setQiantity(bell.ItemId)"
                  >
                    <option selected value="0">
                      {{ "Items" | translate }}
                    </option>
                    <option
                      *ngFor="let producte of ProducteList"
                      value="{{ producte.Id }}"                    
                    >
                      {{producte.Name }}
                    </option>
                  </select>
                </td>
               
                <td style="vertical-align: middle">
                  <input
                    [(ngModel)]="bell.SalePrice"
                    type="number"
                    class="form-control"
                  />
                </td>
                
                <td >                 
                  <input class="form-control" type="number" (change)="calculateTotalItemValue()&&( bell.Quantity=selectedWarehouse.Quantity+bell.AdjustedQuantity)" [(ngModel)]="bell.AdjustedQuantity">               
                </td>
                <td style="vertical-align: middle">
                  <input
                  [(ngModel)]="selectedWarehouse.Quantity"
                    type="number"
                    class="form-control"
                    readonly
                  />
                </td>
                <td >     
                  <!-- <input  class="form-control" type="number" [(ngModel)]="selectedWarehouse.Quantity+bell.AdjustedQuantity " readonly>     -->
                  <!-- <input  class="form-control" type="number" [(ngModel)]="selectedWarehouse.Quantity-bell.AdjustedQuantity " readonly>     -->
                  <input  class="form-control" type="number" [(ngModel)]=" bell.Quantity" readonly>    
                </td>
                <td > 
                  <input class="form-control" type="number" [(ngModel)]=" bell.SalePrice*bell.AdjustedQuantity"readonly>                
                  <!-- <input class="form-control" type="number" [(ngModel)]="bell.Total">                -->
                </td>
               

                <td colspan="2" style="text-align: center; vertical-align: middle" >
                  <i 
                  
                    class="simple-icon-close"
                    (click)="removeItemsFooter(bell.Id)"
                    style="
                      font-size: 20px;
                      font-weight: bolder;
                      cursor: pointer;
                      color: #dc3545;
                    "
                  ></i>
                </td>
              </tr>
              <tr *ngIf="ItemsStoreBell">
                <td>
                  <button class="top-right-button btn btn-primary btn-lg mr-1"  (click)="
                  !(
                    ProducteList.invalid ||
                    tableEngine.currentAction === 'loading'
                  )
                    ? AddonClick()
                    : ''
                ">
                {{ "Add" | translate }}
                   
                  </button>
                </td>
              
                <td></td>
                <td></td>
                <td></td>
                <td></td>
               <td>
                <label>{{"Total" | translate}}</label>
                <input class="form-control" type="number" [(ngModel)]="currentTotal" readonly>
               </td>
              
              </tr>
                </tbody>
              </table>
           
          </tab>
        </tabset>
      </div>
    </div>
  </div>