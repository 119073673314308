
export class WarehouseEmployeesModel{
    Id?: number;
    EmployeeId?:number;
    WarehouseId?: number;  
 
    constructor(item?:WarehouseEmployeesModel){
        item = item? item:{};
        this.Id = item.Id? item.Id:0;
        this.EmployeeId = item.EmployeeId? item.EmployeeId:0;
        this.WarehouseId = item.WarehouseId? item.WarehouseId:0;
       
    
    }

}
