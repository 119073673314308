import { Component, OnInit, ViewChild } from '@angular/core';
import { AttendanceSessionModel } from '../../models/attendance-session.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AttendanceSessionService } from '../../attendance-session.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { EmployeesManagementModel } from 'src/app/HR/Employees/EmployeesManagement/EmployeesManagementModel';
import { EmployeesManagementService } from 'src/app/HR/Employees/EmployeesManagement/employees-management.service';
import { SessionLogsModel } from '../../models/session-logs.model';
@Component({
    selector: 'view-session',
    templateUrl: 'view-session.component.html',
    styleUrls: ['view-session.component.scss']
})
export class ViewSessionComponent implements OnInit {
    Form:FormGroup;
    AttendanceSession:AttendanceSessionModel={};
    openGrid = false;
    Id=0;
    declare lang: any;
    EmployeeId = 0;
    employees:EmployeesManagementModel[]=[];
    columnDefs = [{ field: "Code" },{ field: "SessionTime" }, { field: "CloseTime" }, { field: "NumberOfRecords" }];
    constructor(public router:Router, 
        private fb:FormBuilder,
        private AttendanceSessionService:AttendanceSessionService,
        private route: ActivatedRoute,
        private employeeService:EmployeesManagementService
        ){
            this.Id = + route.snapshot.paramMap.get('Id');
        this.lang = localStorage.getItem('theme_lang');

        }
        OpenRecord(): void {

            this.Record.show();
          }
        @ViewChild('Record') Record: ModalDirective;

    rowData:any[]=[];
    ngOnInit(): void {
        if(this.Id > 0)
        {
            this.lang = localStorage.getItem('theme_lang');
            this.AttendanceSessionService.getById(this.Id)
            .subscribe(data => {
                this.AttendanceSession = data;
            })
       
      
        }
    this.employeeService.getAll()
    .subscribe(employees=>{
        this.employees = employees;
    })
    }
     SessionLogsModel:SessionLogsModel={};

    SaveRecord(){
        debugger;
        this.SessionLogsModel.AttendanceSessionId = this.Id;
        this.SessionLogsModel.CheckIn = new Date();
        this.SessionLogsModel.EmployeeId = this.EmployeeId;
        this.SessionLogsModel.IsAttenance = true;
        this.SessionLogsModel.Status = true;
        this.AttendanceSessionService.RecordEmployee(this.SessionLogsModel)
        .subscribe(result => {
            if(result >0){
                this.SessionLogsModel.Id = result;
                this.AttendanceSession.SessionLogs.push(this.SessionLogsModel);
                this.Record.hide();
                this.EmployeeId = 0;
                this.SessionLogsModel = new SessionLogsModel();
            }
        })
    }
    count = 0;
    SelectEmployee(){
        debugger;
        if(this.EmployeeId > 0){
            var i = this.AttendanceSession.SessionLogs.findIndex(x=>x.EmployeeId == this.EmployeeId && x.Status == true);
            if(i>-1){
                var CheckInDate = new Date(this.AttendanceSession.SessionLogs[i].CheckIn);
               var days = CheckInDate.getDay();
               var hours = CheckInDate.getHours();
               var Minutes = CheckInDate.getMinutes();
               var Seconds = CheckInDate.getSeconds();
               var now = new Date();
               var nowDay = now.getDay();
               var nowhours = now.getHours();
               var nowMinutes = now.getMinutes();
               var nowSeconds = now.getSeconds();
                this.count += (nowDay - days)*86400;
                this.count += (nowhours - hours)*3600;
                this.count += (nowMinutes - Minutes)*60;
                this.count += (nowSeconds - Seconds);
            // this.count = this.diff_minutes(now,CheckInDate)
                // var today = new Date();
                // var Christmas = new Date(today.getFullYear() + "-12-25");
                // var diffMs = (Christmas - today); // milliseconds between now & Christmas
                // var diffDays = Math.floor(diffMs / 86400000); // days
                // var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
                // var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
            }
            else{
                this.count = 0;
            }
        }
    }
 diff_minutes(dt2, dt1) 
 {

  var diff =(dt2.getTime() - dt1.getTime()) / 1000;
  diff /= 60;
  return Math.abs(Math.round(diff));
  
 }
  completed() {
  }
  CloseSession(){
    this.AttendanceSessionService.CloseSession(this.AttendanceSession)
    .subscribe(data=>{
        this.router.navigate(['app/HR/all-sessions'])
    })
  }
}
