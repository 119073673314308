
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AllMembershipsComponent } from './components/all-memberships/all-memberships.component';
import { CreateMembershipComponent } from './components/create-membership/create-membership.component';
import { ViewMembershipComponent } from './components/view-membership/view-membership.component';

const routes: Routes = [
    {
        path: '', component: AllMembershipsComponent,
        
    },{
        path:'create-membership/:Id',
        component:CreateMembershipComponent
    },{
        path:'view-membership/:Id',
        component:ViewMembershipComponent
    }
   
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class MembershipRoutingModule { }