import { Component, OnInit, ViewChild } from '@angular/core';
import { ChecksReceivedModel } from '../../models/checks-received.model';
import { ChecksReceivedService } from '../../checks-received.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NotificationType, NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AccountsNameService } from '../../../../account-name/AccountNameService';
import { AccountNameModel } from '../../../../account-name/AccountNameModel';
@Component({
    selector: 'view-checks-recieved',
    templateUrl: 'view-checks-recieved.component.html',
    styleUrls: ['view-checks-recieved.component.scss']
})
export class ViewChecksRecievedComponent implements OnInit {
    declare lang: any;
    Id = 0;
    buttonName='';
    obj : ChecksReceivedModel=new ChecksReceivedModel();
    constructor( 
         private ChecksReceivedService: ChecksReceivedService,
        private route: ActivatedRoute,
        public router:Router,
        private notifications: NotificationsService, private translate: TranslateService,
        public fb:FormBuilder,
        private accountsNameService: AccountsNameService,
        
         ){
      this.lang = localStorage.getItem('theme_lang');
      this.Id = + route.snapshot.paramMap.get('Id');
        
    }
    AccountsNameModel: AccountNameModel[] = [];
    PackUpAccounts: AccountNameModel[] = [];
    ngOnInit(): void {
        this.accountsNameService.GetAllSubAccountes(0).subscribe(
            data=>{
                this.AccountsNameModel=data;
                this.PackUpAccounts = data.map(item=>Object.assign({}, item))

            }
        );
       this.ChecksReceivedService.getById(this.Id)
       .subscribe(result => {
        this.obj = result;
        this.collectionForm = this.createcollectionForm();
        this.depositForm = this.createdepositForm();
        this.rejectForm = this.createrejectForm();
       })
    }
    currentFormMode = ''
    @ViewChild('delModal') delModal: ModalDirective;
    @ViewChild('collection') collection: ModalDirective;
    @ViewChild('deposit') deposit: ModalDirective;
    @ViewChild('reject') reject: ModalDirective;

    collectionForm:FormGroup;
    createcollectionForm():FormGroup{
        return this.fb.group({
            CollectionDate:[this.obj.CollectionDate],
            CollectionDescription:[this.obj.CollectionDescription],
            DepositFees:[this.obj.DepositFees]

        })
    }
    depositForm:FormGroup;
    createdepositForm():FormGroup{
        return this.fb.group({
            DepositDate:[this.obj.DepositDate],
            DepositDescription:[this.obj.DepositDescription],
            DepositAccountId:[this.obj.DepositAccountId],
        })
    }
    rejectForm:FormGroup;
    createrejectForm():FormGroup{
        return this.fb.group({
            RejectedDate:[this.obj.RejectedDate],
            RejectedDescription:[this.obj.RejectedDescription],
        })
    }
    showdepositModal(): void {
        this.deposit.show();
      }
    showrejectModal(): void {
        this.reject.show();
      }
      showcollectionModal(): void {
        this.collection.show();
      }
      Collection(){
        
        this.obj.CollectionDate = this.collectionForm.getRawValue().CollectionDate;
        this.obj.CollectionDescription = this.collectionForm.getRawValue().CollectionDescription;
        this.obj.DepositFees = this.collectionForm.getRawValue().DepositFees;
        this.ChecksReceivedService.Collection(this.obj)
        .subscribe(data=>{
            if(data == true){
              this.collection.hide()
              this.obj.IsCollection = true;
              
                this.toastSuccess(this.translate.instant("Success"), this.translate.instant("Success Collection"));
            }
            else{
            this.toastError(this.translate.instant("Error"), this.translate.instant("FailedCollection"));
                
            }
        })
      }

      Deposit(){
        this.obj.DepositDate = this.depositForm.getRawValue().DepositDate;
        this.obj.DepositAccountId = this.depositForm.getRawValue().DepositAccountId;
        this.obj.DepositDescription = this.depositForm.getRawValue().DepositDescription;
        this.ChecksReceivedService.Deposit(this.obj)
        .subscribe(data=>{
            if(data == true){
              this.deposit.hide();
              this.obj.IsDeposit = true;

                this.toastSuccess(this.translate.instant("Success"), this.translate.instant("Success Collection"));
            }
            else{
            this.toastError(this.translate.instant("Error"), this.translate.instant("FailedCollection"));
                
            }
        })
      }
      Reject(){
        this.obj.RejectedDate = this.rejectForm.getRawValue().RejectedDate;
        this.obj.RejectedDescription = this.rejectForm.getRawValue().RejectedDescription;
        this.ChecksReceivedService.Reject(this.obj)
        .subscribe(data=>{
            if(data == true){
              this.reject.hide();
                this.obj.IsRejected = true;
                this.toastSuccess(this.translate.instant("Success"), this.translate.instant("Success Collection"));
            }
            else{
            this.toastError(this.translate.instant("Error"), this.translate.instant("FailedCollection"));
                
            }
        })
      }
    showDelModal(Id: any): void {
        if (Id >= 0) {
          this.currentFormMode = 'Del'
          this.delModal.show();
        }
        else if (Id === 'delMulti') {
          this.delModal.show();
        }
      }
      deleteObject() {
        this.executeDeleteObject();
      }
    
      //Same here...
      executeDeleteObject(){
        this.ChecksReceivedService.delete(this.obj).subscribe(result => {
          if (result === true) {
            this.router.navigate(['app/accounts/checks-received']);
          }
          else {
            this.toastError(this.translate.instant("Error"), this.translate.instant("FailedDelete"));
          }
        })
      }
    

      toastSuccess(toastHeader: string, toastBody: string): void {
        this.notifications.create(toastHeader, toastBody,
          NotificationType.Success, { timeOut: 3000, showProgressBar: true });
      }
      toastError(toastHeader: string, toastBody: string): void {
        this.notifications.create(toastHeader, toastBody,
          NotificationType.Error, { timeOut: 3000, showProgressBar: true });
      }
      gerAccountNameById(Id):string{
        var account = this.AccountsNameModel.find(x=>x.Id == Id);
        if(account != null){
          return this.lang=='ar-EG'? account.NameAr:account.NameEn;
        }
        return '';

      }

}
