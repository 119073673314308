<!--component html goes here -->
<!--component html goes here -->
<!--component html goes here -->
<div class="row mb-12">
  <div class="col-md-12 col-lg-12 col-12 mb-12">
    <div class="card"></div>
  </div>
</div>
<div class="row" *ngIf="mainForm">
  <div class="col-12">
    <div class="card mb-4">
      <div class="card-body">
        <h5 class="float-left">{{ "SalariesManager" | translate }}</h5>
        <div class="top-right-button-container text-zero float-right">
          <button
            class="top-right-button btn btn-primary btn-lg mr-1"
            (click)="goBack()"
          >
            {{ "Back" | translate }}
          </button>
          <button
            type="button"
            (click)="Save()"
            [disabled]="mainForm.invalid"
            class="top-right-button btn btn-primary btn-lg mr-1"
          >
            {{ "Save" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div></div>
<div class="row">
  <div class="col-md-12">
    <!-- <div class="col-md-12 col-lg-12 col-12 mb-12"> -->
    <div class="card">
      <tabset class="card-tabs" [justified]="true">
        <tab
          style="font-weight: bold"
          heading="{{ 'Salaries' | translate }}"
          class="card-body"
        >
          <!-- <h6 class="mb-4">{{ "Basic Data" | translate }}</h6> -->
          <div class="modal-body">
            <form
              class="md-float-material form-material"
              [formGroup]="mainForm"
            >
              <div class="row">
                <div class="col-md-6">
                  <label for="inputState">{{ "Name" | translate }}</label>
                  <span class="required">*</span>
                  <input
                    class="form-control"
                    formControlName="Name"
                    rows="2"
                    placeholder="{{ 'Name' | translate }}"
                  />
                  <div
                    class="messages text-danger"
                    *ngIf="
                      (mainForm.get('Name').hasError('required') ||
                        mainForm.get('Name').invalid) &&
                      (mainForm.get('Name').dirty ||
                        mainForm.get('Name').touched)
                    "
                  >
                    {{ "FirstNameRequired" | translate }}
                  </div>
                </div>
                <div class="col-md-6">
                  <label for="inputState">{{
                    "dateOfRegistration" | translate
                  }}</label>
                  <span class="required">*</span>
                  <!-- <barcode type="code128b">Hello</barcode> -->
                  <input
                    class="form-control"
                    formControlName="dateOfRegistration"
                    rows="2"
                    placeholder="{{ 'dateOfRegistration' | translate }}"
                    type="date"
                  />
                  <div
                    class="messages text-danger"
                    *ngIf="
                      (mainForm
                        .get('dateOfRegistration')
                        .hasError('required') ||
                        mainForm.get('dateOfRegistration').invalid) &&
                      (mainForm.get('dateOfRegistration').dirty ||
                        mainForm.get('dateOfRegistration').touched)
                    "
                  >
                    {{ "DateRequired" | translate }}
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <label for="inputState">{{
                    "StartingDate" | translate
                  }}</label>
                  <span class="required">*</span>
                  <!-- <barcode type="code128b">Hello</barcode> -->
                  <input
                    class="form-control"
                    formControlName="StartingDate"
                    rows="2"
                    placeholder="{{ 'StartingDate' | translate }}"
                    type="date"
                  />
                  <div
                    class="messages text-danger"
                    *ngIf="
                      (mainForm.get('StartingDate').hasError('required') ||
                        mainForm.get('StartingDate').invalid) &&
                      (mainForm.get('StartingDate').dirty ||
                        mainForm.get('StartingDate').touched)
                    "
                  >
                    {{ "DateRequired" | translate }}
                  </div>
                </div>

                <div class="col-md-6">
                  <label for="inputState">{{ "EndDate" | translate }}</label>
                  <span class="required">*</span>
                  <!-- <barcode type="code128b">Hello</barcode> -->
                  <input
                    class="form-control"
                    formControlName="EndDate"
                    rows="2"
                    placeholder="{{ 'EndDate' | translate }}"
                    type="date"
                  />
                  <div
                    class="messages text-danger"
                    *ngIf="
                      (mainForm.get('EndDate').hasError('required') ||
                        mainForm.get('EndDate').invalid) &&
                      (mainForm.get('EndDate').dirty ||
                        mainForm.get('EndDate').touched)
                    "
                  >
                    {{ "DateRequired" | translate }}
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <!-- <label>{{ "CheckAttendance" | translate }}</label> -->

                  <div
                    class="itemCheck mb-0 custom-checkbox custom-control"
                    style="display: inline-block"
                  >
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      [id]="'valueCheck'"
                      formControlName="CheckAttendance"
                    />
                    <label class="custom-control-label" [for]="'valueCheck'"
                      >{{ "CheckAttendance" | translate }}
                    </label>
                  </div>
                </div>
              </div>

              <div class="card" style="margin-top: 30px">
              <div class="row">
                <div class="col-md-6">
                    <div >
                        <!-- <label>{{ "GrammarChoose" | translate }}</label> -->
                        <div
                          class="itemCheck mb-0 custom-checkbox custom-control"
                          style="display: inline-block"
                        >
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            [id]="'valueCheck2'"
                            formControlName="GrammarChoose"
                          (change)="ReverseEmployeesChoose()"

                          />
                          <label
                            class="custom-control-label"
                            [for]="'valueCheck2'"
                            >{{ "GrammarChoose" | translate }}
                          </label>
                        </div>
                    </div>
                    <div [ngClass]=" {'disables' : mainForm.controls['GrammarChoose'].value == false}" >
                       <div class="form-group  input-error-target" >
                    <label for="inputState">{{ "Branch" | translate }} </label>
                    <select
                      id="inputState"
                      class="form-control"
                      formControlName="BranchId"
                    >
                      <option selected value="1">
                        {{ "ChoiceBranch" | translate }}
                      </option>
                      <option
                        *ngFor="let Brach of BranchesList"
                        value="{{ Brach.Id }}"
                      >
                        {{ lang === "ar-EG" ? Brach.NameAr : Brach.NameEn }}
                      </option>
                    </select>
                       </div>
                       <div class="form-group  input-error-target">
                      <label for="inputState"
                        >{{ "Department" | translate }}
                      </label>
                      <select
                        id="inputState"
                        class="form-control"
                        formControlName="DepartmentId"
                      >
                        <option selected value="1">
                          {{ "ChoiceDepartment" | translate }}
                        </option>
                        <option
                          *ngFor="let Department of DepartmentList"
                          value="{{ Department.Id }}"
                        >
                          {{ Department.Name }}
                        </option>
                      </select>
                       </div>
                       <div class="form-group  input-error-target" >
                      <label for="inputState"
                        >{{ "JobTitle" | translate }}
                      </label>
                      <select
                        id="inputState"
                        class="form-control"
                        formControlName="JobTitletId"
                      >
                        <option selected value="1">
                          {{ "ChoiceJobTitlet" | translate }}
                        </option>
                        <option
                          *ngFor="let JobTitlet of JobTitletList"
                          value="{{ JobTitlet.Id }}"
                        >
                          {{ JobTitlet.Name }}
                        </option>
                      </select>
                       </div>
                       <div class="form-group  input-error-target" >
                    <!-- <div class="col-md-6">
                        <label for="inputState">{{ "ArrestCycle" | translate }} </label>
                        <select
                          id="inputState"
                          class="form-control"
                          formControlName="ArrestCycleId"
                        >
                          <option selected value="1">
                            {{ "-" | translate }}
                          </option>
                          <option
                            *ngFor="let ArrestCycle of ArrestCycleList"
                            value="{{ ArrestCycle.Id }}"
                          >
                            {{ ArrestCycle.NameEn }}
                          </option>
                        </select>
                      </div> -->
                       </div>
                       <div class="form-group  input-error-target">
                        <label for="inputState">{{"ExcludedEmployees" | translate
                        }}</label>
                        <ng-select
                          [items]="EmployeesList"
                          bindLabel="Name"
                          bindValue="Id"
                          [multiple]="true"
                          groupBy="selectedAllGroup"
                          [selectableGroup]="true"
                          placeholder="Select Employees"
                          (change)="onMaterialGroupEmployeesExcluded($event)"
                          rows="2"
                        >
                          <ng-template
                            ng-optgroup-tmp
                            let-item="item"
                            let-item$="item$"
                            let-index="index"
                          >
                            <input
                              id="item-{{ index }}"
                              type="checkbox"
                              [ngModel]="item$.selected"
                            />
                            Select All
                          </ng-template>
                        </ng-select>
                    </div>
                     </div>
                </div>
                <div class="col-md-6">
                    <div
                        class="itemCheck mb-0 custom-checkbox custom-control"
                        style="display: inline-block"
                      >
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          [id]="'valueCheck3'"
                          formControlName="EmployeesChoose"
                          (change)="ReverseGrammarChoose()"
                        />
                        <label
                          class="custom-control-label"
                          [for]="'valueCheck3'"
                          >{{ "EmployeesChoose" | translate }}
                        </label>
                    </div>
                    <div [ngClass]=" {'disables' : mainForm.controls['EmployeesChoose'].value == false}" >
                        <div class="form-group  input-error-target">
                            <label for="inputState">{{"Employees" | translate
                            }}</label>
                            <ng-select
                              [items]="EmployeesList"
                              bindLabel="Name"
                              bindValue="Id"
                              [multiple]="true"
                              groupBy="selectedAllGroup"
                              [selectableGroup]="true"
                              placeholder="Select Employees"
                              (change)="onMaterialGroupChangeEmployeesChoise($event)"
                              
                            >
                              <ng-template
                                ng-optgroup-tmp
                                let-item="item"
                                let-item$="item$"
                                let-index="index"
                              >
                                <input
                                  id="item-{{ index }}"
                                  type="checkbox"
                                  [ngModel]="item$.selected"
                                />
                                Select All
                              </ng-template>
                            </ng-select>
                        </div>
                    </div>
                </div>
                
              </div>
              </div>
            </form>
          </div>
        </tab>
      </tabset>
    </div>
  </div>
</div>
