import { TaxTypesModel } from 'src/app/views/tax-types/TaxTypesModel';
import { CostCenterModel } from '../cost-center/models/cost-center.model';
import { JournalFooterCostCenterModel } from './journal-footer-cost-center.model';
import { JournalFileModel } from './journal-file.model';

export class DailyrestrictionsModel{
    Id?:number;
    Date?:Date;
    Description?:String;
    AccountNameId?:number;
    BankCodeId?:number;
    CurrencyId?:number;
    Totaldebit?:number;
    Totalcredit?:number;
    Totalbalance?:number;
    Code ?: number;
    Footer?:DailyrestrictionsFooterModel[];
    IsPosted?:boolean;
    NotebookReference?: number;
    MovementNumber   ?: number;
    FiscalYearId?:number;
    PeriodicEntry?: boolean;
    PeriodicType?: string;
    Files ?: JournalFileModel[];
    constructor(obj?:DailyrestrictionsModel){
        obj = obj? obj:{};
        this.Id = obj.Id? obj.Id:0;
        this.Code = obj.Code? obj.Code:0;
        this.FiscalYearId = obj.FiscalYearId? obj.FiscalYearId:0;
        this.Date = obj.Date? obj.Date:new Date();
        this.Description = obj.Description? obj.Description:'';
        this.PeriodicType = obj.PeriodicType? obj.PeriodicType:'';

        this.AccountNameId = obj.AccountNameId? obj.AccountNameId:0;
        this.NotebookReference = obj.NotebookReference? obj.NotebookReference:0;
        this.MovementNumber    = obj.MovementNumber   ? obj.MovementNumber   :0;
        this.BankCodeId = obj.BankCodeId? obj.BankCodeId:0;
        this.CurrencyId = obj.CurrencyId? obj.CurrencyId:0;
        this.Totaldebit = obj.Totaldebit? obj.Totaldebit:0;
        this.Totalcredit = obj.Totalcredit? obj.Totalcredit:0;
        this.Totalbalance = obj.Totalbalance? obj.Totalbalance:0;
       
        this.Footer = obj.Footer? obj.Footer:[];
        this.IsPosted = obj.IsPosted? obj.IsPosted:false;
        this.PeriodicEntry = obj.PeriodicEntry? obj.PeriodicEntry:false;
        this.Files = obj.Files ? obj.Files : [];
    }
}

export class DailyrestrictionsFooterModel{
    Id?:number;
    AccountNameId?:number;
    // Subledger1?:number;
    // Subledger2?:number;
    // Subledger3?:number;
    // Subledger4?:number;
    // Subledger5?:number;
    Description ?:string;
    AccountNumber?:number;
    Debit?:number;
    Credit?:number;
    CostCenters?:JournalFooterCostCenterModel[];
    centers?:CostCenterModel[];
    AccountNameAr?:string;
    AccountNameEn?:string;
    taxes?:TaxTypesModel[];
    constructor(obj?:DailyrestrictionsFooterModel){
        obj = obj? obj:{};
        this.Id = obj.Id? obj.Id:0;
        this.AccountNameId = obj.AccountNameId? obj.AccountNameId:0;
        this.AccountNumber = obj.AccountNumber? obj.AccountNumber:0;
        // this.Subledger1 = obj.Subledger1? obj.Subledger1:0;
        // this.Subledger2 = obj.Subledger2? obj.Subledger2:0;
        // this.Subledger3 = obj.Subledger3? obj.Subledger3:0;
        // this.Subledger4 = obj.Subledger4? obj.Subledger4:0;
        // this.Subledger5 = obj.Subledger5? obj.Subledger5:0;
        this.Debit = obj.Debit? obj.Debit:0;
        this.Credit = obj.Credit? obj.Credit:0;
        this.CostCenters = obj.CostCenters? obj.CostCenters:[];
        this.centers = obj.centers? obj.centers:[];
        this.taxes = obj.taxes? obj.taxes:[];
        this.AccountNameAr = obj.AccountNameAr? obj.AccountNameAr:'';
        this.AccountNameEn = obj.AccountNameEn? obj.AccountNameEn:'';
        this.Description = obj.Description? obj.Description:'';
    }
}

export class SelectedDailyrestrictionsFooterModel{
    Id?:number;
    NameAr?:string;
    NameEn?:string;
    AccountNameId?:number;
    addCostCenters?:boolean;
    // Subledger1?:number;
    // Subledger2?:number;
    // Subledger3?:number;
    // Subledger4?:number;
    // Subledger5?:number;
    Debit?:number;
    Credit?:number;
    CostCenterId1?:number;
    CostCenterId2?:number;
    CostCenters?:JournalFooterCostCenterModel[];
    centers?:CostCenterModel[];
    Description?:string;
    taxes?:TaxTypesModel[];
    Files?:JournalFileModel[];
    AccountNature?:number;
    constructor(obj?:SelectedDailyrestrictionsFooterModel){
        obj = obj? obj:{};
        this.Id = obj.Id? obj.Id:0;
        this.AccountNameId = obj.AccountNameId? obj.AccountNameId:0;
        this.CostCenterId1 = obj.CostCenterId1? obj.CostCenterId1:0;
        this.CostCenterId2 = obj.CostCenterId2? obj.CostCenterId2:0;
        this.AccountNature = obj.AccountNature? obj.AccountNature:0;
        this.addCostCenters = obj.addCostCenters? obj.addCostCenters:false;
        this.CostCenters = obj.CostCenters? obj.CostCenters:[];
        this.centers = obj.centers? obj.centers:[];
        this.Description = obj.Description? obj.Description:'';
        this.taxes = obj.taxes? obj.taxes:[];

        // this.Subledger1 = obj.Subledger1? obj.Subledger1:0;
        // this.Subledger2 = obj.Subledger2? obj.Subledger2:0;
        // this.Subledger3 = obj.Subledger3? obj.Subledger3:0;
        // this.Subledger4 = obj.Subledger4? obj.Subledger4:0;
        // this.Subledger5 = obj.Subledger5? obj.Subledger5:0;
        this.NameAr = obj.NameAr? obj.NameAr:'';
        this.NameEn = obj.NameEn? obj.NameEn:'';
        this.Debit = obj.Debit? obj.Debit:0;
        this.Credit = obj.Credit? obj.Credit:0;
        this.Files = obj.Files ? obj.Files : [];
    }
}