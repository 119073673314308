<!--component html goes here -->
<!--component html goes here -->
<!--component html goes here -->
<!--component html goes here -->
<div class="row mb-12">
    <div class="col-md-12 col-lg-12 col-12 mb-12">
      <div class="card"></div>
    </div>
  </div>
  <div class="row" *ngIf="mainForm">
    <div class="col-12">
      <div class="card mb-4">
        <div class="card-body">
          <h5 class="float-left">{{ "SalariesManager" | translate }}</h5>
          <div class="top-right-button-container text-zero float-right">
            <button
              class="top-right-button btn btn-primary btn-lg mr-1"
              (click)="goBack()"
            >
              {{ "Back" | translate }}
            </button>
            <button
              type="button"
              (click)="Save()"
              [disabled]="mainForm.invalid"
              class="top-right-button btn btn-primary btn-lg mr-1"
            >
              {{ "Save" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div></div>
  <div class="row">
    <div class="col-md-12">
      <!-- <div class="col-md-12 col-lg-12 col-12 mb-12"> -->
      <div class="card">
        <tabset class="card-tabs" [justified]="true">
          <tab
            style="font-weight: bold"
            heading="{{ 'Salaries' | translate }}"
            class="card-body"
          >
            <!-- <h6 class="mb-4">{{ "Basic Data" | translate }}</h6> -->
            <div class="modal-body">
              <form
                class="md-float-material form-material"
                [formGroup]="mainForm"
              >
                <div class="row">
                  <div class="col-md-6">
                   
                        <label for="inputState">{{ "BankAccount/treasury" | translate }} </label>
                        <select
                          id="inputState"
                          class="form-control"
                          formControlName="BankAccountId"
                        >
                          <option selected value="1" >
                            {{ "ChoiseBanckAccount" | translate }}
                          </option>
                          <option
                            *ngFor="let Bank of BankAccountList"
                            value="{{ Bank.Id }}"
                          >
                            {{ Bank.Name}}
                          </option>
                        </select>
                  </div>
                  <div class="col-md-6">
                    <label for="inputState">{{
                      "PaymentDate" | translate
                    }}</label>
                    <span class="required">*</span>
                    <!-- <barcode type="code128b">Hello</barcode> -->
                    <input
                      class="form-control"
                      formControlName="PaymentDate"
                      rows="2"
                      placeholder="{{ 'PaymentDate' | translate }}"
                      type="date"
                    />
                    <div
                      class="messages text-danger"
                      *ngIf="
                        (mainForm
                          .get('PaymentDate')
                          .hasError('required') ||
                          mainForm.get('PaymentDate').invalid) &&
                        (mainForm.get('PaymentDate').dirty ||
                          mainForm.get('PaymentDate').touched)
                      "
                    >
                      {{ "DateRequired" | translate }}
                    </div>
                  </div>
                </div>
               <div class="row">
                <div class="col-md-12">
                    <label for="inputState">{{
                      "Notes" | translate
                    }}</label>
                 
                    <textarea formControlName="Notes" rows="3"
                      class="form-control"  placeholder="{{ 'Notes' | translate }}"></textarea>
                   
                  </div>
               </div>
             
  
                <div class="card" style="margin-top: 30px">
                <div class="row">
                  <div class="col-md-6">
                      <div >
                          <!-- <label>{{ "GrammarChoose" | translate }}</label> -->
                          <div
                            class="itemCheck mb-0 custom-checkbox custom-control"
                            style="display: inline-block"
                          >
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              [id]="'valueCheck2'"
                              formControlName="PaymentOfApproved"
                            (change)="ReversePaymentOfSelected()"
  
                            />
                            <label
                              class="custom-control-label"
                              [for]="'valueCheck2'"
                              >{{ "PaymentOfApproved" | translate }}
                            </label>
                          </div>
                      </div>
                    
                  </div>
                  <div class="col-md-6">
                      <div
                          class="itemCheck mb-0 custom-checkbox custom-control"
                          style="display: inline-block"
                        >
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            [id]="'valueCheck3'"
                            formControlName="PaymentOfSelected"
                            (change)="ReversePaymentOfApproved()"
                          />
                          <label
                            class="custom-control-label"
                            [for]="'valueCheck3'"
                            >{{ "PaymentOfSelected" | translate }}
                          </label>
                      </div>
                      <div [ngClass]=" {'disables' : mainForm.controls['PaymentOfSelected'].value == false}" >
                          <div class="form-group  input-error-target">
                              <label for="inputState">{{"Employees" | translate
                              }}</label>
                              <ng-select
                                [items]="SalariesManagerList"
                                bindLabel="Name"
                                bindValue="Id"
                                [multiple]="true"
                                groupBy="selectedAllGroup"
                                [selectableGroup]="true"
                                placeholder="Select Employees"
                                (change)="onMaterialGroupChangeSalariesManager($event)"
                                
                              >
                                <ng-template
                                  ng-optgroup-tmp
                                  let-item="item"
                                  let-item$="item$"
                                  let-index="index"
                                >
                                  <input
                                    id="item-{{ index }}"
                                    type="checkbox"
                                    [ngModel]="item$.selected"
                                  />
                                  Select All
                                </ng-template>
                              </ng-select>
                          </div>
                      </div>
                  </div>
                  
                </div>
                </div>
              </form>
            </div>
          </tab>
        </tabset>
      </div>
    </div>
  </div>
  