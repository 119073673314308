import { SessionLogsModel } from "./session-logs.model";

export class AttendanceSessionModel{
    Id?: number;
    Code                       ?: number;
    SessionTime                ?: Date|null;
    CloseTime                ?: Date|null;
    NumberOfRecords            ?: number;
    AttendanceSourceId  ?: number;
    AttendanceSourceNameAr?: string;
    AttendanceSourceNameEn?: string;
    IsOpen                     ?: boolean;
    SessionLogs                ?:SessionLogsModel[];
    constructor(item ?: AttendanceSessionModel){
        item = item ? item : {};
        this.Id = item.Id ? item.Id : 0;
        this.Code                        = item.Code                        ? item.Code                        : 0;
        this.SessionTime                 = item.SessionTime                 ? item.SessionTime                 : null;
        this.CloseTime                 = item.CloseTime                 ? item.CloseTime                 : null;
        this.NumberOfRecords             = item.NumberOfRecords             ? item.NumberOfRecords             : 0;
        this.AttendanceSourceId   = item.AttendanceSourceId   ? item.AttendanceSourceId   : 0;
        this.AttendanceSourceNameAr = item.AttendanceSourceNameAr ? item.AttendanceSourceNameAr : '';
        this.AttendanceSourceNameEn = item.AttendanceSourceNameEn ? item.AttendanceSourceNameEn : '';
        this.IsOpen                      = item.IsOpen                      ? item.IsOpen                      : false;
        this.SessionLogs                 = item.SessionLogs                 ? item.SessionLogs                 : [];
    }
}