// import { StoreItemsModel } from "./StoreItemsModel";


export class AdjustmentOperationModel{
    Id?: number;
    StockAdjustmentId?:number;
    OperationTypeId?:number;


    constructor(item?:AdjustmentOperationModel){
        item = item? item:{};
        this.Id = item.Id? item.Id:0;
        this.StockAdjustmentId = item.StockAdjustmentId? item.StockAdjustmentId:0;
        this.OperationTypeId = item.OperationTypeId? item.OperationTypeId:0;     

    }

}