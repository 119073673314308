import { Component, OnInit } from '@angular/core';
import { PurchaseInvoiceService } from '../invoice-purchase/PurchaseInvoiceService';
import { ActivatedRoute, Router } from '@angular/router';
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';

@Component({
    selector: 'view-purchase-invoice',
    templateUrl: 'view-purchase-invoice.component.html',
    styleUrls: ['view-purchase-invoice.component.scss']
})
export class ViewPurchaseInvoiceComponent implements OnInit {
    declare lang: any;
    Id = 0;
    constructor( 
        private invoiceService: PurchaseInvoiceService,
       private route: ActivatedRoute,
       public router:Router
        ){
     this.lang = localStorage.getItem('theme_lang');
     this.Id = + route.snapshot.paramMap.get('Id');
       
   }
   invoice:any={};
    ngOnInit(): void {
        this.invoiceService.getByIdWithItems(this.Id)
        .subscribe(result =>{
            this.invoice = result;
            console.log('invoice',result);
        })
    }

    openForExport = false;
    exportTable(){
      debugger;
      this.openForExport = true;
      var data = document.getElementById('contentToConvert');
      data.style.display = 'inline-block';
      html2canvas(data,{}).then(canvas => {
      var imgWidth = 208;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save('new-file.pdf'); // Generated PDF
      data.style.display = 'block';

    //   data.style.display = 'none';
      
    });
    }
    print(){
        let printContents, popupWin;
        printContents = document.getElementById('contentToConvert').innerHTML;
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.open();
        window.print();
       
      }
}
