import { SuppliersDirectoryDataModel } from './../../models/suppliers-directory-data.model';
import { Component, OnInit } from '@angular/core';
import { SuppliersDirectoryService } from '../../suppliers-directory.service';
import { SuppliersDirectorySearchModel } from '../../models/suppliers-directory-search.model';
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';
import { CountryServes } from 'src/app/invoices/suppliers/serves/countryServes';
import { CountryModel } from 'src/app/invoices/suppliers/model/countrymodel';

@Component({
    selector: 'suppliers-directory',
    templateUrl: 'suppliers-directory.component.html',
    styleUrls: ['suppliers-directory.component.scss']
})
export class SuppliersDirectoryComponent implements OnInit{
    declare lang: any;
    today:Date=new Date();
    list:SuppliersDirectoryDataModel[]=[];
    liabilities:SuppliersDirectoryDataModel[]=[];
    PropertyRights:SuppliersDirectoryDataModel[]=[];
    employees=[];
    nodeList:SuppliersDirectoryDataModel[]=[];
    countries:CountryModel[]=[];
    constructor(private SuppliersDirectoryService:SuppliersDirectoryService,private countryService:CountryServes){
      this.lang = localStorage.getItem('theme_lang');
    }
    TotalAssets = 0;
    Totalliabilities = 0;
    TotalPropertyRights = 0;
    data:SuppliersDirectoryDataModel[]=[];
    assets:SuppliersDirectoryDataModel[]=[];
    search:SuppliersDirectorySearchModel={};
    supplierTypes:[
        {Id:'0',Name:'All'},
        {Id:'1',Name:'Bussiness'},
        {Id:'2',Name:'Person'},
        {Id:'3',Name:'Foreign'},

]
    ngOnInit(): void {
        this.countryService.getAll()
        .subscribe(result => {
            this.countries = result;
        })
        this.SuppliersDirectoryService.search(this.search)
        .subscribe(data => {
          this.data = data;
          this.assets = data;
        })
    
    }
    Search(){
      this.SuppliersDirectoryService.search(this.search)
        .subscribe(data => {
          this.data = data;
        })
    }
    exportTable(){
      var data = document.getElementById('contentToConvert');
      data.style.display = 'inline-block';
      html2canvas(data,{}).then(canvas => {
      var imgWidth = 208;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save('new-file.pdf'); // Generated PDF
      data.style.display = 'block';

    //   data.style.display = 'none';
      
    });
    }
    print(){
        let printContents, popupWin;
        printContents = document.getElementById('contentToConvert').innerHTML;
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.open();
        window.print();
       
      }
}
