<img alt="Profile" src="{{membership.ImageURL}}" (click)="openLightbox('/assets/img/profiles/1.jpg')"
  class="img-thumbnail card-img social-profile-img c-pointer">
<div class="card mb-4">
  <div class="card-body">
    <div class="text-center pt-4">
      <p class="list-item-heading pt-2">{{membership.EnglishName}}</p>
    </div>
    <!-- <p class="mb-3">
      I’m a web developer. I spend my whole day, practically every day,
      experimenting with HTML, CSS, and JavaScript; dabbling with Python and
      Ruby; and inhaling a wide variety of potentially useless information
      through a few hundred RSS feeds. I build websites that delight and
      inform. I do it well.
    </p> -->

    <!-- <p class="text-muted text-small mb-2">{{'pages.location' | translate}}</p>
    <p class="mb-3">Nairobi, Kenya</p>

    <p class="text-muted text-small mb-2">{{'pages.responsibilities' | translate}}</p> -->
    <p class="mb-3">
      <a href="#">
        <span class="badge badge-pill badge-outline-theme-2 mb-1">{{'Edit'|translate}}</span>
      </a>{{" "}}
      <a href="#">
        <span class="badge badge-pill badge-outline-theme-2 mb-1">{{'Renewal Membership'|translate}}</span>
      </a>{{" "}}
      <a href="#">
        <span class="badge badge-pill badge-outline-theme-2 mb-1">{{'End Membership'|translate}}</span>
      </a>{{" "}}
   
    </p>

    <p class="text-muted text-small mb-2">{{'Contact' | translate}}</p>
    <div class="social-icons">
      <ul class="list-unstyled list-inline">
        <li class="list-inline-item">
          <a href="javascript:;"><i class="simple-icon-social-facebook"></i></a>
        </li>
        <li class="list-inline-item">
          <a href="javascript:;"><i class="simple-icon-social-twitter"></i></a>
        </li>
        <li class="list-inline-item">
          <a href="javascript:;"><i class="simple-icon-social-instagram"></i></a>
        </li>
      </ul>
    </div>
  </div>
</div>
