<div class="md-float-material form-material" >

    <div style="text-align: center;">
        <button class="btn btn-light" (click)="goBack()"
            style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Back"  | translate}}</button>
        <button
            id="smallModalSubmitButton" class="btn btn-secondary" (click)="Save()"
            style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Save"  | translate}}</button>
        
    </div>
    <div class="card" style="margin-bottom: 30px;">
        <div class="form-row">
            <div class="form-group col-md-4">
              <div style="margin: 20px;">
                <br>
                <h5>{{'Profile Image'|translate}}</h5>
                <br>
                <upload-doctor-profile-image [(url)]=picurl [editmode]=editEnabled></upload-doctor-profile-image>
           </div>
            </div>
            <div class="form-group col-md-4">
                <div style="margin: 20px;">
                    <br>
                    <h5>{{'National Id'|translate}}</h5>
                    <br>
                    <upload-nationalid-image-for-doctor [(url)]=NationalIdPicurl [editmode]=NationalIdEditEnabled></upload-nationalid-image-for-doctor>
               </div>
            </div>
            <div class="form-group col-md-4">
                <div style="margin: 20px;">
                    <br>
                    <h5>{{'Graduation document'|translate}}</h5>
                    <br>
                    <upload-graduation-document-for-doctor [(url)]=graduationPicurl [editmode]=graduationEditEnabled></upload-graduation-document-for-doctor>
               </div>
            </div>
       
        </div>
     
    
    </div>
    <form *ngIf="Form" [formGroup]="Form">
        <div class="form-group">
            <br>
            <h3 class="center" style="text-align: center;">{{ 'Basic Data' | translate }}</h3>
            <br>
      
            <div class="form-row">
                <div class="form-group col-md-4">
                    <label >{{'First Arabic Name'| translate}}</label>
                    <input formControlName="FirstArabicName" type="text" class="form-control">
                </div>
                <div class="form-group col-md-4">
                    <label for="inputPassword4">{{'Middle Arabic Name'| translate}}</label>
                    <input formControlName="SecondArabicName" type="text" id="inputPassword4" class="form-control" >
                </div>
                <div class="form-group col-md-4">
                    <label for="inputPassword4">{{'Last Arabic Name'| translate}}</label>
                    <input formControlName="LastArabicName" type="text" id="inputPassword4" class="form-control" >
                </div>
            </div>
 
            <div class="form-row">
                <div class="form-group col-md-4">
                    <label for="inputPassword4">{{'English Name'| translate}}</label>
                    <input formControlName="EnglishName" type="text" id="inputPassword4" class="form-control" >
                </div>
                <div class="form-group col-md-4">
                    <label >{{'Email'| translate}}</label>
                    <input formControlName="Email" type="text" class="form-control">
                </div>
                <div class="form-group col-md-4">
                    <label for="inputPassword4">{{'Phone'| translate}}</label>
                    <input formControlName="Phone" type="text" id="inputPassword4" class="form-control" >
                </div>
              
            </div>
            
            <div class="form-row">
                <div class="form-group col-md-4">
                    <label for="inputPassword4">{{'Type'| translate}}</label>
                    <select
                       id="inputState"
                       class="form-control"
                       formControlName="Gender"
                     >
                      <option  [value]="'Male'">
                       {{ "Male" | translate }}
                      </option>
                      <option  [value]="'Female'">
                        {{ "Female" | translate }}
                       </option>
                      
                    </select>
          
                     </div>
                     <div class="form-group col-md-4">
                        <label for="inputPassword4">{{'Social Status'| translate}}</label>
                        <select
                           id="inputState"
                           class="form-control"
                           formControlName="SocialStatus"
                         >
                          <option  [value]="'Married'">
                           {{ "Married" | translate }}
                          </option>
                          <option  [value]="'Single'">
                            {{ "Single" | translate }}
                           </option>
                          
                        </select>
              
                         </div>
                     <div class="form-group col-md-4">
                        <label >{{'National ID'| translate}}</label>
                        <input formControlName="NationalID" type="text" class="form-control">
                    </div>
                 
                </div>
          
                <div class="form-row">
           
                         <div class="form-group col-md-12">
                            <label >{{'Address'| translate}}</label>
                            <textarea formControlName="Address" type="text" class="form-control"></textarea>
                        </div>
                     
                    </div>
            </div>
            <div class="form-group">
                <br>
                <h3 class="center" style="text-align: center;">{{ 'Education And Work' | translate }}</h3>
                <br>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label for="inputPassword4">{{'Educational Qualification'| translate}}</label>
                        <input formControlName="EducationalQualification" type="text" id="inputPassword4" class="form-control" >
                    </div>
                    <div class="form-group col-md-6">
                        <label >{{'Graduation Year'| translate}}</label>
                        <input formControlName="GraduationYear" type="text" class="form-control">
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-4">

                    </div>
               
                    <div class="form-group col-md-4">
                       
                    </div>
                </div>
  
            
                </div>
   
       </form>

<br>

<div style="text-align: center;">
    <button class="btn btn-light" (click)="goBack()"
        style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Back"  | translate}}</button>
    <button
        id="smallModalSubmitButton" class="btn btn-secondary" (click)="Save()"
        style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Save"  | translate}}</button>
    
</div>
</div>


