<!--component html goes here -->
<div class="row" *ngIf="mainForm">
    <div class="col-12">
      <div class="card mb-4">
        <div class="card-body">
          <h5 class="float-left">{{ "AddAdjustment" | translate }}</h5>
          <div class="top-right-button-container text-zero float-right">
            <button
              class="top-right-button btn btn-primary btn-lg mr-1"
              (click)="goBack(Id)"
            >
              {{ "Back" | translate }}
            </button>
            <button
              type="button"
              (click)="Save(mainForm.get('QuantityEditType').value)"
              [disabled]="mainForm.invalid"
              class="top-right-button btn btn-primary btn-lg mr-1"
            >
              {{ "Save" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div></div>
  <div class="row">
      <div class="col-md-12">
    <!-- <div class="col-md-12 col-lg-12 col-12 mb-12"> -->
      <div class="card">
        <tabset class="card-tabs" [justified]="true">
          <tab heading="{{ 'Adjustment' | translate }}" class="card-body">
            <!-- <h6 class="mb-4">{{ "Basic Data" | translate }}</h6> -->
            <div class="modal-body">
              <form
                class="md-float-material form-material"
                [formGroup]="mainForm"
              >
                <div class="row">
                    <div class="col-md-4">                    
                     <label for="inputState">{{ "Quantity" | translate }}</label>                    
                      <div class="input-group ">
                        <div class="input-group-append">
                            <select
                            id="inputState"
                            class="form-control"
                            formControlName="QuantityEditType"  
                            (change)="QuantityAfter()"        
                          >
                            <option selected value="1"
                            >                                
                              {{ "OperationType1" | translate }}
                            </option>
                            <option value="2">
                            {{ "OperationType2" | translate }}

                         </option>
                          </select>                     
                        </div>
                        <input (change)="QuantityAfter()" type="text" class="form-control" placeholder="Quantity" formControlName="Quantity"
                        >
                        
                      </div>
                    </div>
                    <div  class="col-md-4" *ngIf="ProducteList && modelObject" >
                        <label for="inputState"
                        >{{ "SalePrice" | translate }}
                        <span  tooltip="{{ 'EditSalePrice' | translate }}" placement="auto" style="color: crimson;" >
                            <img src="assets\img\question-ar.png">
                        </span>
                      
                    </label>
                            <div>
                            <input  type="number" class="form-control" [(ngModel)]="ProducteList.SalePrice" [ngModelOptions]="{standalone: true}">
                             </div>
                    </div>
                  <div class="col-md-2">
                    <label for="inputState">{{ "ModifiedDate" | translate }}</label>
                    <input
                      class="form-control"
                      formControlName="Date"
                      type="date"
                      placeholder="{{ 'ModifiedDate' | translate }}"
                    />
                
                  </div>
                

                  <div class="col-md-2">
                    <label for="inputState">{{ "CreateDateTime" | translate }}</label>
                    <input
                      class="form-control"
                      formControlName="Time"
                      type="time"
                      placeholder="{{ 'CreateDateTime' | translate }}"
                    />
                
                  </div>
                  
             
                </div>
                <div class="row">               
                    <div class="col-md-4">
                      <label for="inputState">{{ "Warehouse" | translate }}</label>
                      <select
                        id="inputState"
                        class="form-control"
                        formControlName="WarehouseId"
                        (change)="QuantityBefour()"
                        (change)="QuantityAfter()"
                      >
                        <option selected value="0">
                          {{ "ChoiceWarehouse" | translate }}
                        </option>
                        <option
                          *ngFor="let Warehouse of WarehouseList"
                          value="{{ Warehouse.Id }}"
                        >
                          {{ Warehouse.Name }}

                        </option>
                      </select>
                  </div>

                  <div class="ms-sel-ctn col-md-4">
                    <label for="inputState">{{
                      "OperationType" | translate
                    }}</label>
                    <ng-select [items]="OperationList"
                    bindLabel="Name"
                    bindValue="Id"
                    [multiple]="true"
                    groupBy="selectedAllGroup"
                    [selectableGroup]="true"
                    placeholder="اختر العملية"
                    (change)="onMaterialGroupChange($event)"
                    [addTagText]="">
                    
    
                    <ng-template 
                     ng-optgroup-tmp let-item="item" 
                     let-item$="item$" let-index="index">
                     <input id="item-{{index}}" type="checkbox"
                     [ngModel] ="item$.selected"/> Select All
                    </ng-template>
                    </ng-select>
    
                  </div>


                  <div class="col-md-4">
                    <label for="inputState">{{ "SubAccount" | translate }}</label>
                    <select
                      id="inputState"
                      class="form-control"
                      formControlName="SubAccountId"
                      
    
                    >
                      <option selected value="0">
                        {{ "SubAccount" | translate }}
                      </option>
                      <option
                        *ngFor="let Account of AccountesList"
                        value="{{ Account.Id }}"
                        
                      >
                        {{ lang === "ar-EG" ? Account.NameAr : Account.NameEn }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row">
                    <div  class="col-md-4">
                      <label for="inputState">{{
                        "Notes" | translate
                          }}</label>                 
                       <textarea formControlName="Notes" id="" cols="8" rows="3"
                       class="form-control"  placeholder="{{ 'Notes' | translate }}"></textarea>
                     
                    </div>
                    <div  class="col-md-4">

                    </div>
                    <div  class="col-md-2"  >
                        <label for="inputState">{{ "InventoryBeforeQuantity" | translate }}</label>
                            <div>
                            <input  type="number" class="form-control" formControlName="InventoryBeforeQuantity" [(ngModel)]="selectedWarehouse.Quantity"  readonly >
                             </div>
                    </div>
                    <div  class="col-md-2" *ngIf="modelObject" >
                        <label for="inputState">{{ "InventoryAfterQuantity" | translate }}</label>
                            <div>
                            <input  type="number" class="form-control" [(ngModel)]="modelObject.InventoryAfterQuantity" [ngModelOptions]="{standalone: true}" readonly>
                             </div>
                    </div>
                </div>
              </form>
              <form >
                
             
              </form>
            </div>
      
          </tab>
        </tabset>
      </div>
  </div>
  
  <!-- <div class="col-md-6">
  
      <div class="card" >
          <tabset class="card-tabs" [justified]="true">
              <tab heading="{{ 'Pricing' | translate }}" class="card-body"> 
          <div class="modal-body">
          <form class="md-float-material form-material" [formGroup]="mainForm">
            <div class="row">
              <div class="col-md-6">
                <label for="inputState">{{
                  "PurchasingPrice" | translate
                }}</label>
                <input
                  class="form-control"
                  formControlName="PurchasingPrice"
                  rows="2"
                  placeholder="{{ 'PurchasingPrice' | translate }}"
                />
                <div
                  class="messages text-danger"
                  *ngIf="
                    (mainForm.get('PurchasingPrice').hasError('required') ||
                      mainForm.get('PurchasingPrice').invalid) &&
                    (mainForm.get('PurchasingPrice').dirty ||
                      mainForm.get('PurchasingPrice').touched)
                  "
                >
                  {{ "PurchasingPriceRequired" | translate }}
                </div>
              </div>
              <div class="col-md-6">
                <label for="inputState">{{ "SalePrice" | translate }}</label>
                <input
                  class="form-control"
                  formControlName="SalePrice"
                  rows="2"
                  placeholder="{{ 'SalePrice' | translate }}"
                  (change)="calcProfitPrice()"
                />
                <div
                  class="messages text-danger"
                  *ngIf="
                    (mainForm.get('SalePrice').hasError('required') ||
                      mainForm.get('SalePrice').invalid) &&
                    (mainForm.get('SalePrice').dirty ||
                      mainForm.get('SalePrice').touched)
                  "
                >
                  {{ "SalePriceRequired" | translate }}
                </div>
              </div>
  
            
            
                <div class="col-md-6">
                  <label for="inputState">{{ "FirstTaxId" | translate }}</label>
                  <select
                    id="inputState"
                    class="form-control"
                    formControlName="FirstTaxId"
  
                  >
                    <option selected value="0">
                      {{ "FirstTaxId" | translate }}
                    </option>
                    <option
                      *ngFor="let tax of FirstTaxesList"
                      value="{{ tax.Id }}"
                    >
                      {{ lang === "ar-EG" ? tax.NameAr : tax.NameEn }}
                    </option>
                  </select>
                </div>
    
                <div class="col-md-6">
                  <label for="inputState">{{ "SecondTaxId" | translate }}</label>
                  <select
                    id="inputState"
                    class="form-control"
                    formControlName="SecondTaxId"
                  >
                    <option selected value="0">
                      {{ "SecondTaxId" | translate }}
                    </option>
                    <option
                      *ngFor="let tax of SecondTaxesList"
                      value="{{ tax.Id }}"
                    >
                      {{ lang === "ar-EG" ? tax.NameAr : tax.NameEn }}
                    </option>
                  </select>
                </div>
  
  
                <div class="col-md-6">
                  <label for="inputState">{{
                    "LowestSellingPrice" | translate
                  }}</label>
                  <input
                    class="form-control"
                    formControlName="LowestSellingPrice"
                    rows="2"
                    placeholder="{{ 'LowestSellingPrice' | translate }}"
                  />
                  <div
                    class="messages text-danger"
                    *ngIf="
                      (mainForm.get('LowestSellingPrice').hasError('required') ||
                        mainForm.get('LowestSellingPrice').invalid) &&
                      (mainForm.get('LowestSellingPrice').dirty ||
                        mainForm.get('LowestSellingPrice').touched)
                    "
                  >
                    {{ "LowestSellingPriceRequired" | translate }}
                  </div>
                </div>
              <div class="col-md-6">
                <label for="inputState">{{ "Discount" | translate }}</label>
                <input
                  class="form-control"
                  formControlName="Discount"
                  rows="2"
                  placeholder="{{ 'Discount' | translate }}"
                />
                <div
                  class="messages text-danger"
                  *ngIf="
                    (mainForm.get('Discount').hasError('required') ||
                      mainForm.get('Discount').invalid) &&
                    (mainForm.get('Discount').dirty ||
                      mainForm.get('Discount').touched)
                  "
                >
                  {{ "DiscountRequired" | translate }}
                </div>
              </div>
  
              <div class="col-md-6">
                <label for="inputState">{{
                  "ProfitPercentage" | translate
                }}</label>
                <input
                  class="form-control"
                  formControlName="ProfitPercentage"
                  rows="2"
                  placeholder="{{ 'ProfitPercentage' | translate }}"
                  (change)="calcSalePrice()"
                />
                <div
                  class="messages text-danger"
                  *ngIf="
                    (mainForm.get('ProfitPercentage').hasError('required') ||
                      mainForm.get('ProfitPercentage').invalid) &&
                    (mainForm.get('ProfitPercentage').dirty ||
                      mainForm.get('ProfitPercentage').touched)
                  "
                >
                  {{ "ProfitPercentageRequired" | translate }}
                </div>
              </div>
  
            </div>
  
            <div class="row"></div>
          </form>
        </div>
      </tab>
  </tabset>
      </div>
  </div> -->
  </div>