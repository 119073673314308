export class GovernorateCodeModel{
    GovernorateId?:Number;
    CountryCodeId?: Number;
    GovernorateNameL1?: string;
    GovernorateNameL2?: string;
    IsPosted?: Boolean;
   

    constructor(obj?:GovernorateCodeModel){
        obj=obj?obj:{};
        this.GovernorateId=obj?.GovernorateId? obj.GovernorateId:0;
        this.CountryCodeId=obj?.CountryCodeId? obj.CountryCodeId:0;
        this.GovernorateNameL1=obj?.GovernorateNameL1? obj.GovernorateNameL1:'';
        this.GovernorateNameL2=obj?.GovernorateNameL2? obj.GovernorateNameL2:'';
        this.IsPosted=obj?.IsPosted? obj.IsPosted:false;
        
    }
}