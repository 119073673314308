import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { TableEngine } from 'src/app/views/app/aio/shared/TableEngine';
import { StoreBillModel } from '../../models/StoreBillModel';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { WarehouseModel } from '../../models/WarehouseModel';
import { StoreItemsModel } from '../../models/StoreItemsModel';
import { ItemsStoreBellModel } from '../../models/ItemsStoreBillModel';
import { AccountesNameModel } from '../../models/AccountesNameModel';
import { TransfareBillService } from '../transfare-bill.service';
import { SidePopupComponent } from 'src/app/views/app/aio/shared/side-popup/side-popup.component';
@Component({
    // moduleId: module.id,
    selector: 'info-transfer-bill',
    templateUrl: 'info-transfer-bill.component.html',
    styleUrls: ['info-transfer-bill.component.scss']
})
export class InfoTransferBillComponent {
    Id = 0;
    constructor(
      public router: Router,
      private fb: FormBuilder,
      private modelService: TransfareBillService,
      private notifications: NotificationsService,
      private translate: TranslateService,
      private route: ActivatedRoute
    ) {
      this.Id = +route.snapshot.paramMap.get("Id");
    }
  
    tableEngine = new TableEngine(this.translate, this.notifications);
    currentScreen: any = "Table";
    translationName: string = "BellType";
    sortableKeys = [];
    searchableKeys = ["BellType", "Date"];
    tableView: any = true;
  
    // StoreItemsModel:StoreItemsModel[]=[];
  
   
    modelObject: StoreBillModel = new StoreBillModel();
    Form: FormGroup;
    declare lang: any;
    declare itemOrder: any;
    currentAction = "";
    savedPage = 0;
    currentPage = 1;
    deletionList: any[] = [];
    prevAction = "";
    addToListId = 0;
    ItemsBalanceHeaderId = 0;
    ItemBalanceId = 0;
    currentFormMode: string = "";
    type = 0;
    visable = "";
    visable2 = "";
    visable3 = "";
    ogData: StoreBillModel[] = [];
    mainForm: FormGroup = new FormGroup({
      Id: new FormControl(),
      BillType: new FormControl(),
      SkuNumber: new FormControl(),
      WarehouseId: new FormControl(),
      SubAccountId: new FormControl(),
      Notes: new FormControl(),
      Time: new FormControl(),
      Date: new FormControl(),
      IsApproval: new FormControl(),
      SecondWarehouseId: new FormControl(),
      SecondWarehouseName: new FormControl(),
      SubAccountName: new FormControl(),
      
      
    });
    @ViewChild("sideModalRef", { static: true })
    sideModalRef: SidePopupComponent = null;
    @ViewChild("delModal", { static: true }) delModal: ModalDirective;
    @ViewChild("smallModal", { static: true }) smallModal: ModalDirective = null;
    formPresentationModal: any;
    ngOnInit(): void {
      // this.getAllCountryCode();
  
      this.lang = localStorage.getItem("theme_lang");
      this.itemOrder = {
        label: "Date",
        property: this.lang === "ar-EG" ? "Date" : "Date",
      };
     
      this.tableEngine.loadTableEngine(
        this.ogData,
        this.modelService,
        this.modelObject,
        this.searchableKeys,
        this.sortableKeys,
        this.mainForm,
        this.translationName,
        this.itemOrder,
        this.sideModalRef,
        this.delModal,
        this.smallModal,
        this.lang
      );
     
      this.loadServices();
      // this.modelService.getById(this.Id).subscribe((data) => {
      //   this.modelObject = data;
      //   console.log("BillInfo", this.modelObject);
      // });
     
  
          if(this.Id > 0)
          {   debugger;                            
                  this.modelService.getById(this.Id).subscribe(data=>{                        
                  this.modelObject = data;                 
                  this.ItemsStoreBell=data.ItemsStoreBillList;
                  this.ItemsStoreBell=this.modelObject.ItemsStoreBillList;
                  this.calculateTotalItemValue();
                  this.mainForm = this.createForm(this.modelObject);
  
              });
             
  
          }
          else{
                  
             this.mainForm = this.createForm(this.modelObject);                              
  
  
          }
      // this.mainForm = this.createForm(this.modelObject);
  
    }


    createForm(modelObject): FormGroup {
        debugger;
        return this.fb.group({
          // Id:[this.ItemBalance.Id],
          Id: [modelObject.Id >= 0 ? modelObject.Id : 0],
          BillType: [modelObject.BillType],
          SkuNumber: [modelObject.SkuNumber],
          WarehouseName: [modelObject.WarehouseName],
          SubAccountName: [modelObject.SubAccountName],
          
          WarehouseId: [modelObject.WarehouseId],
          SubAccountId: [modelObject.SubAccountId],
          Time: [modelObject.Time],
          Date: [modelObject.Date],
          IsApproval: [modelObject.IsApproval],
          Notes: [modelObject.Notes],
          SecondWarehouseId:[modelObject.SecondWarehouseId],
          SecondWarehouseName:[modelObject.SecondWarehouseName],
          
          
        });
      }
      WarehouseList: WarehouseModel[] = [];
      ProducteList:StoreItemsModel[]=[];
      ItemsStoreBell:ItemsStoreBellModel[]=[];
      AccountesList: AccountesNameModel[] = [];
    
      loadServices() {
        // debugger;
        this.currentAction = "loading";
        this.tableEngine.loadData();
      }
    
      getItemsById(Id:any):any{
        if(Id>0)
        {
        return this.ProducteList.find(i=>i.Id===Id)
        }
        else
        {
        return new StoreItemsModel();
        }
        }
      setItem(bell){
        debugger;
        var i = this.ProducteList.findIndex(x=>x.Id == bell.ItemId);
        if(i>-1){
          bell.SalePrice = this.ProducteList[i].SalePrice;
          bell.Quantity = this.ProducteList[i].Quantity;
        }
        // this.calculateTotalItemValue();
        }
        currentTotal = 0;
    
        calculateTotalItemValue() {
          let total = 0;
          this.ItemsStoreBell.forEach(item => {
            total += item.AdjustedQuantity*item.SalePrice;
          });
          this.currentTotal = total;
          return total;
        }
    
    
      addproductes() {
        debugger;
        var i = this.ProducteList.findIndex((x) => x.Id == this.addToListId);
        if (i >= 0) {
          var item = this.ProducteList[i];
    
          this.ProducteList.push(item);
          this.ProducteList.splice(i, 1);
          this.addToListId = 0;
    
        }
     }
      AddonClick(){
        var ItemStoreList= new ItemsStoreBellModel();
        ItemStoreList.Id = 0, 
        ItemStoreList.StoreBillId =this.Id, 
        ItemStoreList.ItemId = 0, 
        // ItemStoreList.IsDeleted= false, 
        this.ItemsStoreBell.push(ItemStoreList);
        // this.mainForm.controls['StoreBillId'].setValue(0);
        ItemStoreList={};    
      }
    
      removeItemsFooter(Id: any): any {
        debugger;
        let index = this.ItemsStoreBell.findIndex(x => x.ItemId === Id);
        this.ItemsStoreBell.splice(index, 1);                 
        }
      
       
        goBack() {
            this.router.navigate(["app/aio/Inventory/List-All-Bells"]);
          }
          Save() {
            debugger;
            this.currentAction = "loading";
            this.tableEngine.savedPage = this.tableEngine.currentPage;
            this.modelObject = new StoreBillModel();
            this.modelObject.Id = 0;
            this.modelObject.BillType = this.mainForm.getRawValue().BillType;
            this.modelObject.SkuNumber = this.mainForm.getRawValue().SkuNumber;
            this.modelObject.Date = new Date(this.mainForm.getRawValue().Date);
            this.modelObject.Notes = this.mainForm.getRawValue().Notes;
            this.modelObject.SubAccountId = this.mainForm.getRawValue().SubAccountId;     
            
            this.modelObject.Time =
              this.mainForm.getRawValue().Time;
            this.modelObject.IsApproval =true;
            this.modelObject.WarehouseId =
              this.mainForm.getRawValue().WarehouseId;
          
            this.modelObject.ItemsStoreBillList = this.ItemsStoreBell;
            console.log(this.modelObject);
            console.log(this.modelObject);
        
            if (this.Id == 0) {
              this.modelService.insert(this.modelObject).subscribe((data) => {
                this.router.navigate(["app/aio/Inventory/List-All-Bells"]);
              });
            } else {
              debugger;
              this.modelService.update(this.modelObject).subscribe((data) => {
                this.router.navigate(["app/aio/Inventory/List-All-Bells"]);
              });
            }
          }

}
