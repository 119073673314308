import { Component, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { NotificationsService } from "angular2-notifications";
import { ModalDirective } from "ngx-bootstrap/modal";
import { TableEngine } from "src/app/invoices/TableEngine";
import { SidePopupComponent } from "src/app/views/invoice/side-popup/side-popup.component";
import {JobTypesModel} from '../JobTypesModel';
import{JobTypesService} from '../job-types.service';

@Component({
    // moduleId: module.id,
    selector: 'info-job-types',
    templateUrl: 'info-job-types.component.html',
    styleUrls: ['info-job-types.component.scss']
})
export class InfoJobTypesComponent {
    Id = 0;

    constructor(
      public router: Router,
      private fb: FormBuilder,
      private modelService : JobTypesService ,
      private notifications: NotificationsService,
      private translate: TranslateService,
      private route: ActivatedRoute
    ) {
      this.Id = +route.snapshot.paramMap.get("Id");
    }
  
    tableEngine = new TableEngine(this.translate, this.notifications);
    currentScreen: any = "Table";
    translationName: string = "Item";
    sortableKeys = [];
    searchableKeys = ["Name", "Address"];
    tableView: any = true;
  
    JobTypesModel:JobTypesModel[]=[];
  
    // WarehouseData: WarehouseModel[] = [];
  
    modelObject: JobTypesModel = new JobTypesModel();
    Form: FormGroup;
    declare lang: any;
    declare itemOrder: any;
    currentAction = "";
    savedPage = 0;
    currentPage = 1;
    deletionList: any[] = [];
    prevAction = "";
    addToListId = 0;
    ItemsBalanceHeaderId = 0;
    ItemBalanceId = 0;
    currentFormMode: string = "";
  
    ogData: JobTypesModel[] = [];
    mainForm: FormGroup = new FormGroup({
        Id: new FormControl(),
        Name: new FormControl(),
        ManagerId: new FormControl(),
        limitation: new FormControl(),
        Description: new FormControl(),
        Status: new FormControl(),
      
    });
  
    @ViewChild("sideModalRef", { static: true })
    sideModalRef: SidePopupComponent = null;
    @ViewChild("delModal", { static: true }) delModal: ModalDirective;
    @ViewChild("smallModal", { static: true }) smallModal: ModalDirective = null;
    formPresentationModal: any;
    ngOnInit(): void {
      // this.getAllCountryCode();
  
      this.lang = localStorage.getItem("theme_lang");
      this.itemOrder = {
        label: "Name",
        property: this.lang === "ar-EG" ? "Name" : "Name",
      };
      this.sortableKeys = [
        // { label: 'Date', property: 'Date' },
        { label: 'Name',property:  this.lang === 'ar-EG' ? 'Name' : 'Name'},
        { label: 'limitation', property: 'limitation' },
        
      ];
  
      this.tableEngine.loadTableEngine(
        this.ogData,
        this.modelService,
        this.modelObject,
        this.searchableKeys,
        this.sortableKeys,
        this.mainForm,
        this.translationName,
        this.itemOrder,
        this.sideModalRef,
        this.delModal,
        this.smallModal,
        this.lang
      );

      // this.mainForm = this.createForm(this.modelObject);
  
      // this.loadServices();
      // this.modelService.getById(this.Id).subscribe((data) => {
      //   this.modelObject = data;
      // });     
     
          if(this.Id > 0)
          {   debugger;                            
                  this.modelService.getById(this.Id).subscribe(data=>{                        
                  this.modelObject = data;
                  this.mainForm = this.createForm(this.modelObject);
              });
                 this.mainForm = this.createForm(this.modelObject);
          }
          else{
             this.mainForm = this.createForm(this.modelObject);       
              }
    }
  

    createForm(modelObject): FormGroup {
      debugger;
      return this.fb.group({
        Id: [this.modelObject.Id >= 0 ? this.modelObject.Id : 0],
        Name: [this.modelObject.Name, Validators.compose([Validators.required, Validators.minLength(2)])],
        Description: [this.modelObject.Description],
        Status: [this.modelObject.Status, Validators.compose([Validators.required])],
        });
    }
    loadServices() {
      // debugger;
      this.currentAction = "loading";
      this.tableEngine.loadData();
    }
  
 
    type = 0;
    visable = "";
    visable2 = "";
    visable3 = "";
  
  
    goBack() {
      this.router.navigate(["app/HR/List-JobTypes"]);
    }
}
