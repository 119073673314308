import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CashierOrderItemModel } from "./CashierOrderItemModel";

@Injectable({
  providedIn: 'root'
})
export class CashierOrderItemService {
    constructor(private http: HttpClient) {
  
    }
    
    debugData: CashierOrderItemModel [] = [
      
          { Id:1, Code:1001, CashierOrderId: 1, CashierOrderCode:1001, ItemId:1, ItemNameAr: "منتج 1", ItemNameEn: "Item 1", 
          UnitId:1, UnitNameAr:"وحدة 1", UnitNameEn:"Unit 1", Note:"item\ndescription\n1", 
          UnitCost:1000, Quantity:1, TotalPrice:1000, DiscountValue:1, DiscountIsRate:true, NetPrice:990,
          AddedValueTax:138.6, GrossPrice: 1128.6 },

          { Id:2, Code:1002, CashierOrderId: 1, CashierOrderCode:1001, ItemId:2, ItemNameAr: "منتج 2", ItemNameEn: "Item 2", 
          UnitId:2, UnitNameAr:"وحدة 2", UnitNameEn:"Unit 2", Note:"item\ndescription\n2", 
          UnitCost:1000, Quantity:2, TotalPrice:2000, DiscountValue:2, DiscountIsRate:true, NetPrice:1960,
          AddedValueTax:274.4, GrossPrice: 2234.4 },

          { Id:3, Code:1003, CashierOrderId: 2, CashierOrderCode:1002, ItemId:2, ItemNameAr: "منتج 2", ItemNameEn: "Item 2", 
          UnitId:2, UnitNameAr:"وحدة 1", UnitNameEn:"Unit 1", Note:"item\ndescription\n3", 
          UnitCost:1000, Quantity:3, TotalPrice:3000, DiscountValue:3, DiscountIsRate:true, NetPrice:2910,
          AddedValueTax:407.4, GrossPrice: 3317.4 },

          { Id:4, Code:1004, CashierOrderId: 2, CashierOrderCode:1002, ItemId:1, ItemNameAr: "منتج 1", ItemNameEn: "Item 1", 
          UnitId:1, UnitNameAr:"وحدة 2", UnitNameEn:"Unit 2", Note:"item\ndescription\n4", 
          UnitCost:1000, Quantity:4, TotalPrice:4000, DiscountValue:4, DiscountIsRate:true, NetPrice:3840,
          AddedValueTax:537.6, GrossPrice: 4377.6 },
    ];
    debugMode: boolean = false;

    baseUrl = environment.baseApiUrl;
    // baseUrl = 'http://localhost:60289/';
     token = localStorage.getItem("token");
     httpOptions = {
      headers: new HttpHeaders({
        // 'Content-Type': 'application/json; charset=utf-8',
        // 'Authorization': "Bearer " + token,
         "Authorization": "Bearer " + this.token,
         "Content-Type": "application/json"
      })
    };
    getAll(): Observable<any> {
      if(this.debugMode){
        return of(this.debugData)
      }
      return this.http
        .get<any>(`${this.baseUrl}CashierOrderItem/GetAll`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }

    getById(id:number): Observable<any> {
      if(this.debugMode){
        return of(this.debugData.find(x=>x.Id==id))
      }
      return this.http
        .get<any>(`${this.baseUrl}CashierOrderItem/GetById/${id}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }

    insert(payload: CashierOrderItemModel): Observable<any> {
      if(this.debugMode){
        this.debugData.push(payload);
        return of(true)
      }
      return this.http
        .post<any>(`${this.baseUrl}CashierOrderItem/Insert`, payload,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
    update(payload: CashierOrderItemModel): Observable<any> {
      if(this.debugMode){
        this.debugData[this.debugData.findIndex(x=>x.Id==payload.Id)] = payload;
        return of(true)
      }
      return this.http
        .post<any>(`${this.baseUrl}CashierOrderItem/Update`, payload,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
    delete(payload: CashierOrderItemModel): Observable<any> {
      if(this.debugMode){
        this.debugData.splice(this.debugData.findIndex(x=>x.Id==payload.Id),1);
        return of(true)
      }
      return this.http
        .get<any>(`${this.baseUrl}CashierOrderItem/Delete/${payload.Id}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
    deleteList(payload: CashierOrderItemModel []): Observable<any> {
      if(this.debugMode){
        payload.forEach(element => {
          this.debugData.splice(this.debugData.findIndex(x=>x.Id==element.Id),1);
        });
        return of(true)
      }
      return this.http
        .post<any>(`${this.baseUrl}CashierOrderItem/DeleteList`,JSON.stringify(payload),this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
}
