import { Component, HostBinding, Renderer2, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';

import { ModalDirective } from 'ngx-bootstrap/modal';
import { SidePopupComponent } from '../../app/aio/shared/side-popup/side-popup.component';
import { Observable, of, Subscription } from 'rxjs';

import { NgSelectComponent } from '@ng-select/ng-select';

import { DatePipe, formatDate } from '@angular/common';


import { RestaurantMenuItemService } from "./RestaurantMenuItemService";
import { RestaurantMenuItemModel } from "./RestaurantMenuItemModel";
import { ConfigPoSService } from '../../restaurants/config-pos-general/ConfigPoSService';
import { ConfigPoSModel } from '../../restaurants/config-pos-general/ConfigPoSModel';
import { RestaurantCategoryService } from '../restaurant-category/RestaurantCategoryService';
import { RestaurantCategoryModel } from '../restaurant-category/RestaurantCategoryModel';
import { ListPageHeaderComponent } from 'src/app/containers/pages/list-page-header/list-page-header.component';
import { TableEngine } from '../../app/aio/shared/TableEngine';

@Component({
  selector: 'app-restaurant-menu-item',
  templateUrl: './restaurant-menu-item.component.html',
  styleUrls: ['./restaurant-menu-item.component.scss']
})
export class RestaurantMenuItemComponent {
  // ////////////////////////////////////////////////////////////////////////////
  // //  VARYING :
  // //  Modify per similar component.
  // ////////////////////////////////////////////////////////////////////////////

  //Enable debugMode to make API modules return static data from within the service class
  debugMode: boolean = true;

  constructor(private formBuilder: FormBuilder, private modelService: RestaurantMenuItemService, private renderer: Renderer2,
    private notifications: NotificationsService, private translate: TranslateService,
    private datePipe: DatePipe, private configPoSService: ConfigPoSService, private restaurantCategoryService: RestaurantCategoryService
  ) {
  }


  listHeader = new ListPageHeaderComponent();

  tableEngine = new TableEngine(this.translate, this.notifications);
  currentScreen: any = 'Table';
  tableView: any = true;

  translationName: string = 'RestaurantMenuItem'

  sortableKeys = [];
  searchableKeys = ['Name'];

  modelObject: RestaurantMenuItemModel = new RestaurantMenuItemModel();

  ogData: RestaurantMenuItemModel[] = [];
  
  mainForm: FormGroup = new FormGroup({
    Id: new FormControl(),
    Name: new FormControl(),
    Cost: new FormControl(),
    CategoryId: new FormControl(),
  });


  declare lang: any;
  declare itemOrder: any;
  currentAction = '';
  savedPage = 0;
  currentPage = 1;
  deletionList: any[] = [];
  public editEnabled = true;
  public picurl: string ='';

  @ViewChild('sideModalRef', { static: true }) sideModalRef: SidePopupComponent = null;
  @ViewChild('delModal', { static: true }) delModal: ModalDirective;
  @ViewChild('smallModal', { static: true }) smallModal: ModalDirective = null;
  formPresentationModal: any;
  ngOnInit(): void {
    if (this.debugMode) {
      this.enableDebugOnAllServices();
    }
    this.lang = localStorage.getItem('theme_lang');
    this.itemOrder = { label: 'RestaurantMenuItemCode', property: 'Code' };
    this.sortableKeys = [
      { label: 'RestaurantMenuItemCode', property: 'Code' },
      { label: 'RestaurantMenuItemName', property: 'Name' },
      { label: 'RestaurantMenuItemCost', property: 'Cost' },
      { label: 'RestaurantMenuItemCategoryName', property: 'CategoryName' },
    ];
    this.tableEngine.loadTableEngine(this.ogData, this.modelService, this.modelObject,
      this.searchableKeys, this.sortableKeys, this.mainForm, this.translationName, this.itemOrder,
      this.sideModalRef, this.delModal, this.smallModal, this.lang
    )

    this.loadServices();
  }
  clear(){
    this.picurl = '';
 }
  enableDebugOnAllServices() {
    //this.modelService.debugMode = true;
  }

  lastCode = 0;
  codeWasChecked = false;
  checkCode() : boolean{
    if(!(this.lastCode > 0)){
      this.codeWasChecked = false;
      console.log("bad code", this.lastCode);
      
      return this.codeWasChecked;
    }
    this.configPoSService.checkMenuItemCode(this.lastCode).subscribe(
        result => {
          this.codeWasChecked = result;
        }
      );
      console.log("code availability:",this.codeWasChecked);
    return this.codeWasChecked;
  }

  showModal(Id, action) {
    if (action === 'Add') {
      //this.lastCode = this.tableEngine.ogData.length>0?this.tableEngine.ogData[this.tableEngine.ogData.length - 1].Code:0;
      this.lastCode = this.configPoS.StartingCodeWorkShifts;
      for (let index = 0; index < this.tableEngine.ogData.length; index++) {
        if(this.tableEngine.ogData.findIndex(x=>x.Code == this.lastCode) >= 0)
        { this.lastCode++ } 
        else { break; }
      }
      this.checkCode();
      this.tableEngine.showAddNewModalRef(this.createForm, this.formBuilder);
    }
    else if (action === 'Edit') {
     this.picurl = this.modelObject.ImageUrl;

      this.tableEngine.showEditModalRef(Id, this.createForm, this.formBuilder);
      this.lastCode = this.tableEngine.modelObject.Code
    }
    else if (action === 'Info') {
      this.tableEngine.showInfoModalRef(Id, this.createForm, this.formBuilder);
     this.picurl = this.modelObject.ImageUrl;
     this.lastCode = this.tableEngine.modelObject.Code
    }
    this.tableView = false;
    this.modelObject = this.tableEngine.modelObject;
    this.picurl = this.modelObject.ImageUrl;
    this.mainForm = this.tableEngine.mainForm;
    console.log((action + " object"), this.modelObject);
    console.log("modelObject after entering edit", this.modelObject);
  }
  currentDate='';
  currentExpirationDate='';
  createForm(modelObject, formBuilder): FormGroup {
    return formBuilder.group({
      Name: [modelObject.Name, Validators.compose([Validators.required])],
      CategoryId: [modelObject.CategoryId, Validators.compose([Validators.required])],
      Cost: [modelObject.Cost, Validators.compose([Validators.required])],
    });
  }

  addObject() {
    debugger;
    this.currentAction = 'loading';
    let lastId = this.tableEngine.ogData.length>0?this.tableEngine.ogData[this.tableEngine.ogData.length - 1].Id:0;
    this.modelObject = new RestaurantMenuItemModel();
    this.modelObject.Id = this.debugMode ? lastId + 1 : 0;
    this.modelObject.Code = this.lastCode;
    this.modelObject.Name = this.mainForm.getRawValue().Name;
    this.modelObject.CategoryId = this.mainForm.getRawValue().CategoryId;
    this.modelObject.Cost = this.mainForm.getRawValue().Cost;
    this.modelObject.ImageUrl = this.picurl;
    this.executeAddObject();
  }

  executeAddObject() {
    this.modelService.insert(this.modelObject).subscribe(result => {
      if (result === true || result > 0) {
        this.tableEngine.loadData();
        this.currentAction = '';
        this.tableEngine.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyAdded"));
        if (this.sideModalRef === undefined) {
          if (this.smallModal != undefined) {
            this.tableEngine.smallModal.hide();
          }
          else {
            this.tableView = this.prevAction === 'AddThenNew' ? false : true;
            
            this.prevAction = '';
          }
        }
        else {
          this.tableEngine.sideModalRef.modalRef.hide();
        }
        
        if(this.prevAction != 'AddThenNew'){
          this.goBack();
        }
      }
      else {
        console.log(result);
        
        this.tableEngine.toastError(this.translate.instant("Error"), this.translate.instant("FailedAdd"));
        this.currentAction = '';
      }
    })
  }

  editObject() {
    this.currentAction = 'loading';
    this.tableEngine.savedPage = this.tableEngine.currentPage;
    let tempObj = new RestaurantMenuItemModel();
    tempObj.Id =this.modelObject.Id;
    tempObj.Code = this.lastCode;
    tempObj.Name = this.mainForm.getRawValue().Name;
    tempObj.CategoryId = this.mainForm.getRawValue().CategoryId;
    tempObj.Cost = this.mainForm.getRawValue().Cost;
    tempObj.ImageUrl = this.picurl;
    console.log("saved object", tempObj);
    this.executeEditObject(tempObj);
  }

  executeEditObject(Object) {
    console.log("applying edit");
    
    this.modelService.update(Object).subscribe(result => {
      if (result === true) {
        this.tableEngine.loadData();
        this.currentAction = '';
        this.tableEngine.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyEdited"));

        if (this.sideModalRef === undefined) {
          if (this.smallModal != undefined) {
            this.tableEngine.smallModal.hide();
          }
          else {
            this.tableView = true;
          }
        }
        else {
          this.tableEngine.sideModalRef.modalRef.hide();
        }
        
        this.goBack();
        
      }
      else {
        this.tableEngine.toastError(this.translate.instant("Error"), this.translate.instant("FailedEdit"));
        this.currentAction = '';
      }
    })
  }

  //Deletion methods:
  //Customize if needed.
  //Pass the target object as parameter if the target API can only delete singular objects and not lists.
  deleteObject() {
    this.savedPage = this.currentPage;
    //prevent multiclicks. re enable after API response
    this.currentAction = 'loading';
    this.executeDeleteObject();
  }

  //Same here...
  executeDeleteObject() {
    this.modelService.deleteList(this.tableEngine.deletionList).subscribe(result => {
      if (result === true) {
        this.tableEngine.loadData();
        this.currentAction = '';
        this.tableEngine.finalizeDeletion();
      }
      else {
        this.tableEngine.toastError(this.translate.instant("Error"), this.translate.instant("FailedDelete"));
        this.currentAction = '';
      }
    })
  }
  ////////////////////////////////////////////////////////////////////////////
  //
  //
  //
  //
  //
  ////////////////////////////////////////////////////////////////////////////
  //  EXCLUSIVE :
  //  Specifically for this component.
  ///////////////////////////////
  //Get any additional data here
  SearchFrom: Date = null;
  SearchTo: Date = null;

  clearSearch() {
    this.SearchFrom = null;
    this.SearchTo = null;
  }

  prevAction = '';
  addAndNew() {
    this.prevAction = 'AddThenNew';
    this.addObject();
    this.showModal(null, 'Add');
  }

  configPoS = new ConfigPoSModel();
  restaurantCategoryList: RestaurantCategoryModel[] = [];
  loadServices() {
    this.currentAction = 'loading';
    this.configPoSService.getFirst().subscribe(
      configPoSData => {
        this.configPoS = configPoSData;
        this.restaurantCategoryService.getAll().subscribe(
          restaurantCategoryData => {
            this.restaurantCategoryList = restaurantCategoryData;
            this.tableEngine.loadData();
          }
        )
      }
    )
  }

  goBack(){
    console.log("back");
    
    this.modelObject = {};
    this.currentAction = "";
    this.tableView = true;

    
    console.log(this.modelObject);
    console.log(this.lastCode);
  }

  ////////////////////////////////////////////////////////////////////////////
  //
  //
  //
  //
  //
  ////////////////////////////////////////////////////////////////////////////
  //  CONSTANT :
  //  Do not change.
  ///////////////////////////////
  prepChangeSort(sortKey) {
    this.SearchFrom = null;
    this.SearchTo = null;
    
    
    this.tableEngine.changeSort(this.tableEngine.getSort(sortKey));
    this.itemOrder = this.tableEngine.itemOrder;
  }

  getFormattedDate(dateString: any){
    let splitDate: number[] = dateString.toString().split("/");

    return new Date(splitDate[2], splitDate[1]-1, splitDate[0])
  }
  // ///////////////////////////////////////////////////////
  
  displayMode = 'list';
  changeDisplayMode(mode): void {
    this.displayMode = mode;
  }
  itemsPerPageChange(perPage: number): void {
    this.tableEngine.paginateData(perPage, 1)
  }
}
