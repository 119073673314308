import { TaxModel } from './taxModel';

export class ItemPostModel{
    Id                  ?: number;
    Price               ?: number;
    Quantity            ?: number;
    Discount            ?: number;
    InvoiceId           ?: number;
    ItemId              ?: number;
    Name                ?:string;
    NameAr              ?: string;
    NameEn              ?: string;
    SsalesTotal         ?: number;
    Total               ?: number;
    ValueDifference     ?: number;
    TotalTaxableFees    ?: number;
    NetTotal            ?: number;
    ItemsDiscount       ?: number;
    CurrencySold        ?: string;
    AmountEGP           ?: number;
    AmountSold          ?: number;
    CurrencyExchangeRate?: number;
    DiscountRate        ?: number;
    DiscountAmount      ?: number;
    Rate                ?: number;
    TotalTax?:number;
    Taxes?:TaxModel[];
    constructor(item ?: ItemPostModel){
        item = item ? item : {};
        this.Id                  = item.Id                  ? item.Id                  : 0;
        this.Price               = item.Price               ? item.Price               : 0;
        this.Quantity            = item.Quantity            ? item.Quantity            : 0;
        this.Discount            = item.Discount            ? item.Discount            : 0;
        this.InvoiceId           = item.InvoiceId           ? item.InvoiceId           : 0;
        this.ItemId              = item.ItemId              ? item.ItemId              : 0;
        this.TotalTax              = item.TotalTax              ? item.TotalTax              : 0;
        this.Name                = item.Name                ? item.Name                : '';
        this.NameAr              = item.NameAr              ? item.NameAr              : '';
        this.NameEn              = item.NameEn              ? item.NameEn              : '';
        this.SsalesTotal         = item.SsalesTotal         ? item.SsalesTotal         : 0;
        this.Total               = item.Total               ? item.Total               : 0;
        this.ValueDifference     = item.ValueDifference     ? item.ValueDifference     : 0;
        this.TotalTaxableFees    = item.TotalTaxableFees    ? item.TotalTaxableFees    : 0;
        this.NetTotal            = item.NetTotal            ? item.NetTotal            : 0;
        this.ItemsDiscount       = item.ItemsDiscount       ? item.ItemsDiscount       : 0;
        this.CurrencySold        = item.CurrencySold        ? item.CurrencySold        : '';
        this.AmountEGP           = item.AmountEGP           ? item.AmountEGP           : 0;
        this.AmountSold          = item.AmountSold          ? item.AmountSold          : 0;
        this.CurrencyExchangeRate= item.CurrencyExchangeRate? item.CurrencyExchangeRate: 0;
        this.DiscountRate        = item.DiscountRate        ? item.DiscountRate        : 0;
        this.DiscountAmount      = item.DiscountAmount      ? item.DiscountAmount      : 0;
        this.Rate                = item.Rate                ? item.Rate                : 0;
        this.Taxes               = item.Taxes               ? item.Taxes               : [];
    }
}