import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { QuotationRequestModel } from "./QuotationRequestModel";

@Injectable({
  providedIn: 'root'
})
export class QuotationRequestService {
    constructor(private http: HttpClient) {
  
    }

    debugData: QuotationRequestModel [] = [];
    // debugData: QuotationRequestModel [] = [
    //   {
    //     Id:1, SupplierId:1, Code:1, CompanyDataId:0, BranchesDataId:0, StoreDataId:2, 
    //     Year:2021, Month:1, Date:new Date(2021, 0, 13), DueDate:new Date(2021, 5, 13), Description:"desc1\ntest1\n1", TotalValue:5,
    //     //IsPosted:false,
    //     Footers: [
    //       {Id:1, QuotationRequestHeaderId:1, ItemsHeaderId:1, UnitTypeId:1, Quantity:1},
    //       {Id:2, QuotationRequestHeaderId:1, ItemsHeaderId:2, UnitTypeId:2, Quantity:2},
    //     ]
    //   },
    //   {
    //     Id:2, SupplierId:2, Code:2, CompanyDataId:0, BranchesDataId:0, StoreDataId:3, 
    //     Year:2022, Month:2, Date:new Date(2022, 1, 13), DueDate:new Date(2021, 6, 13), Description:"desc2\ntest2\n2", TotalValue:25,
    //     //IsPosted:true,
    //     Footers: [
    //       {Id:3, QuotationRequestHeaderId:2, ItemsHeaderId:1, UnitTypeId:2, Quantity:3},
    //       {Id:4, QuotationRequestHeaderId:2, ItemsHeaderId:2, UnitTypeId:1, Quantity:4},
    //     ]
    //   },
    // ];
    debugMode: boolean = false;

    baseUrl = environment.baseApiUrl;
    // baseUrl = 'http://localhost:60289/';
     token = localStorage.getItem("token");
     httpOptions = {
      headers: new HttpHeaders({
        // 'Content-Type': 'application/json; charset=utf-8',
        // 'Authorization': "Bearer " + token,
         "Authorization": "Bearer " + this.token,
         "Content-Type": "application/json"
      })
    };
    getAll(): Observable<any> {
      if(this.debugMode){
        return of(this.debugData)
      }
      return this.http
        .get<any>(`${this.baseUrl}QuotationRequest/GetAll`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    getAllWithItems(): Observable<any> {
      if(this.debugMode){
        return of(this.debugData)
      }
      return this.http
        .get<any>(`${this.baseUrl}QuotationRequest/GetAllWithItems`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    getById(id:number): Observable<any> {
      if(this.debugMode){
        return of(this.debugData.find(x=>x.Id==id))
      }
      return this.http
        .get<any>(`${this.baseUrl}QuotationRequest/GetById/${id}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    getByIdWithItems(id:number): Observable<any> {
      if(this.debugMode){
        return of(this.debugData.find(x=>x.Id==id))
      }
      return this.http
        .get<any>(`${this.baseUrl}QuotationRequest/GetByIdWithItems/${id}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    insert(payload: QuotationRequestModel): Observable<any> {
      if(this.debugMode){
        this.debugData.push(payload);
        return of(true)
      }
      return this.http
        .post<any>(`${this.baseUrl}QuotationRequest/Insert`, payload,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    insertWithItems(payload: QuotationRequestModel): Observable<any> {
      if(this.debugMode){
        this.debugData.push(payload);
        return of(true)
      }
      return this.http
        .post<any>(`${this.baseUrl}QuotationRequest/InsertWithItems`, payload,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
    update(payload: QuotationRequestModel): Observable<any> {
      if(this.debugMode){
        this.debugData[this.debugData.findIndex(x=>x.Id==payload.Id)] = payload;
        return of(true)
      }
      return this.http
        .post<any>(`${this.baseUrl}QuotationRequest/Update`, payload,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    updateWithItems(payload: QuotationRequestModel): Observable<any> {
      if(this.debugMode){
        this.debugData[this.debugData.findIndex(x=>x.Id==payload.Id)] = payload;
        return of(true)
      }
      return this.http
        .post<any>(`${this.baseUrl}QuotationRequest/UpdateWithItems`, payload,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
    delete(payload: QuotationRequestModel): Observable<any> {
      if(this.debugMode){
        this.debugData.splice(this.debugData.findIndex(x=>x.Id==payload.Id),1);
        return of(true)
      }
      return this.http
        .get<any>(`${this.baseUrl}QuotationRequest/Delete/${payload.Id}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
    deleteList(payload: QuotationRequestModel []): Observable<any> {
      if(this.debugMode){
        payload.forEach(element => {
          this.debugData.splice(this.debugData.findIndex(x=>x.Id==element.Id),1);
        });
        return of(true)
      }
      return this.http
        .post<any>(`${this.baseUrl}QuotationRequest/DeleteList`,JSON.stringify(payload),this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
}
