import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {JobLevelsModel} from '../JobLevelsComponentes/JobLevelsModel';
@Injectable({
  providedIn: 'root'
})
export class JobLevelsService {


  constructor(private http:HttpClient ) { }

  
  baseUrl = environment.baseApiUrl;
  token = localStorage.getItem("token");
  httpOptions = {
   headers: new HttpHeaders({
     // 'Content-Type': 'application/json; charset=utf-8',
     // 'Authorization': "Bearer " + token,
      "Authorization": "Bearer " + this.token,
      "Content-Type": "application/json"
   })
 };

 getAll(): Observable<any> {
   
  return this.http
    .get<any>(`${this.baseUrl}JobLevels/GetAll`,this.httpOptions)
    .pipe(catchError((error: any) => Observable.throw(error => error)));
}
getById(Id:number): Observable<any> {

  return this.http
    .get<any>(`${this.baseUrl}JobLevels/GetById/${Id}`,this.httpOptions)
    .pipe(catchError((error: any) => Observable.throw(error => error)));
}
insert(payload: JobLevelsModel): Observable<any> {
  
  return this.http
    .post<any>(`${this.baseUrl}JobLevels/Insert`, payload,this.httpOptions)
    .pipe(catchError((error: any) => Observable.throw(error => error)));
}

update(payload: JobLevelsModel): Observable<any> {
  return this.http
    .post<any>(`${this.baseUrl}JobLevels/Update`, payload,this.httpOptions)
    .pipe(catchError((error: any) => Observable.throw(error => error)));
}

delete(Id:number): Observable<any> {
  return this.http
    .get<any>(`${this.baseUrl}JobLevels/Delete/${Id}`,this.httpOptions)
    .pipe(catchError((error: any) => Observable.throw(error => error)));
}

  deleteList(payload: JobLevelsModel []): Observable<any> {
    return this.http
      .post<any>(`${this.baseUrl}JobLevels/DeleteList`,JSON.stringify(payload),this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error => error)));
  }}
