import { AddSalarySlipComponent } from './Salaries/add-salary-slip/add-salary-slip.component';
import { AddSalariesManagerComponent } from './Salaries/add-salaries-manager/add-salaries-manager.component';
import { UploadContractFileComponent } from './Salaries/contract/components/upload-contract-file/upload-contract-file.component';
import { SalaryItemsComponent } from './Salaries/salary-items/components/salary-items/salary-items.component';
import { HrDashboardComponent } from './hr-dashboard/hr-dashboard.component';

import { NgxCountdownComponent } from './Attendance/attendance-session/components/ngx-countdown/ngx-countdown.component';
import { VacationsTypesComponent } from './Attendance/Vacations/VacationsTypes/components/vacations-types/vacations-types.component';
import { VacationsComponent } from './Attendance/Vacations/Vacation/components/vacations/vacations.component';
import { ShiftsComponent } from './Attendance/shifts/components/shifts/shifts.component';
import { HolidaysComponent } from './Attendance/holidays/components/holidays/holidays.component';
import { CreateHolidayComponent } from './Attendance/holidays/components/create-holiday/create-holiday.component';
import { NotesAndAttachmentComponent } from './Employees/EmployeesManagement/Actions-and-Notes/notes-and-attachment/notes-and-attachment.component';
import { AddActionsComponent } from './Employees/EmployeesManagement/Actions-and-Notes/add-actions/add-actions.component';
import { AddEmployeesManagementComponent } from './Employees/EmployeesManagement/add-employees-management/add-employees-management.component';
import { ListJobTypesComponent } from './Organization_Structure/JobTypesComponentes/list-job-types/list-job-types.component';
import { InfoJobLevelsComponent } from './Organization_Structure/JobLevelsComponentes/info-job-levels/info-job-levels.component';
import { InfoDepartmentComponent } from './Organization_Structure/Department_components/info-department/info-department.component';
import { ListDepartmentComponent } from './Organization_Structure/Department_components/list-department/list-department.component';
import { ListJobLevelsComponent } from './Organization_Structure/JobLevelsComponentes/list-job-levels/list-job-levels.component';
import { InfoJobTypesComponent } from './Organization_Structure/JobTypesComponentes/info-job-types/info-job-types.component';
import { InfoJobTitlesComponent } from './Organization_Structure/JobTitels_Componentes/info-job-titles/info-job-titles.component';
import { ListJobTitlesComponent } from './Organization_Structure/JobTitels_Componentes/list-job-titles/list-job-titles.component';
import { InfoEmployeesManagementComponent } from './Employees/EmployeesManagement/info-employees-management/info-employees-management.component';
import { ListEmployeesManagementComponent } from './Employees/EmployeesManagement/list-employees-management/list-employees-management.component';
import { ActionStatusComponent } from './Employees/EmployeesManagement/Actions-and-Notes/action-status/action-status.component';
import { AddUsersComponent } from './Employees/Users/add-users/add-users.component';
import { InfoUsersComponent } from './Employees/Users/info-users/info-users.component';
import { AllSessionsComponent } from './Attendance/attendance-session/components/all-sessions/all-sessions.component';
import { ViewSessionComponent } from './Attendance/attendance-session/components/view-session/view-session.component';


import { SortablejsModule } from 'ngx-sortablejs';
import { SearchPipePipe } from './../pipes/search-pipe.pipe';
import { PagesContainersModule } from './../containers/pages/pages.containers.module';
import { ComponentsCarouselModule } from './../components/carousel/components.carousel.module';
import { SharedModule } from './../shared/shared.module';
import { environment } from './../../environments/environment';
import { LayoutContainersModule } from './../containers/layout/layout.containers.module';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ModalModule } from 'ngx-bootstrap/modal';
import { ArchwizardModule } from 'angular-archwizard';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireAuthGuardModule } from '@angular/fire/auth-guard';
import { FormsModule as FormsModuleAngular } from '@angular/forms';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { HeadroomModule } from '@ctrl/ngx-headroom';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgSelectModule } from '@ng-select/ng-select';
import { HRRoutingModule } from './HR.routing';
import { SidePopupComponent } from '../views/app/aio/shared/side-popup/side-popup.component';
import { PaginationConfig, PaginationModule } from 'ngx-bootstrap/pagination';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { SimpleNotificationsModule } from 'angular2-notifications';
import {MatTabsModule} from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FileUploadModule } from 'ng2-file-upload';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { RatingModule } from 'ngx-bootstrap/rating';
import { ComponentsPlayerModule } from '../components/player/components.player.module';
import { LightboxModule } from 'ngx-lightbox';
import { RouterModule } from '@angular/router';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { BootstrapModule } from '../components/bootstrap/bootstrap.module';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { ContractsComponent } from './Salaries/contract/components/contracts/contracts.component';
import { PredecessorComponent } from './Predecessor/components/predecessor/predecessor.component';
import {  AddSalariesPaymentComponent } from './Salaries/add-salaries-payment/add-salaries-payment.component';
import { InfoSalariesManagerComponent } from './Salaries/info-salaries-manager/info-salaries-manager.component';
import { ListSalariesManagerComponent } from './Salaries/list-salaries-manager/list-salaries-manager.component';
import { ListSalarySlipComponent } from './Salaries/list-salary-slip/list-salary-slip.component';
@NgModule({
  imports: [
    CommonModule,
    HRRoutingModule,
    LayoutContainersModule,
    MatTabsModule,
    TranslateModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    FormsModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    ArchwizardModule,
    SharedModule,
    NgSelectModule,
    FormsModuleAngular,
    AngularFireAuthModule,
    AngularFireAuthGuardModule,
    ComponentsCarouselModule,
    TabsModule.forRoot(),
    HeadroomModule,
    ScrollToModule.forRoot(),
    NgxDatatableModule,
    CollapseModule,
    PagesContainersModule,
    PopoverModule.forRoot(),
    TooltipModule.forRoot(),
    BsDropdownModule,
    SortablejsModule,
    PaginationModule,
    SimpleNotificationsModule.forRoot(),




    /////////////////////
    SimpleNotificationsModule,
    LayoutContainersModule,
    MatTooltipModule,
    FileUploadModule,
    AccordionModule,
    MatTreeModule,
    MatIconModule,
    MatButtonModule   ,
MatCheckboxModule ,
MatFormFieldModule,
MatInputModule    ,
MatProgressBarModule,

RatingModule.forRoot(),
ComponentsPlayerModule,
LightboxModule,
RouterModule,
PerfectScrollbarModule,
BootstrapModule,

TimepickerModule.forRoot(),
  ],
  declarations: [
    HrDashboardComponent,
    InfoDepartmentComponent,
    ListDepartmentComponent,
    InfoJobLevelsComponent,
    ListJobLevelsComponent,
    InfoJobTypesComponent,
    ListJobTypesComponent,
    InfoJobTitlesComponent,
    ListJobTitlesComponent,
    AddEmployeesManagementComponent,
    
    AddActionsComponent,
    NotesAndAttachmentComponent,
    InfoEmployeesManagementComponent,
    ListEmployeesManagementComponent,
    ActionStatusComponent,
    AddUsersComponent,
    InfoUsersComponent,
    AllSessionsComponent,
    ViewSessionComponent,
    NgxCountdownComponent,
    VacationsTypesComponent,
    VacationsComponent,
    HolidaysComponent,
    CreateHolidayComponent,
    ShiftsComponent,
    SalaryItemsComponent,
    ContractsComponent,
    UploadContractFileComponent,
    PredecessorComponent,
    AddSalariesManagerComponent,
ListSalariesManagerComponent,
InfoSalariesManagerComponent,
AddSalariesPaymentComponent,
AddSalarySlipComponent,
ListSalarySlipComponent
  ],
  providers: [DatePipe,PaginationConfig],
  exports:[NgxCountdownComponent,UploadContractFileComponent],
//   bootstrap: [InvoiceComponent]
})
export class HRModule { }
