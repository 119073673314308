import { TaxModel } from "./taxModel";

export class ItemModel{
    
    id ?: number;
    price              ?: number;
    quantity           ?: number;
    discount           ?: number;
    totalPrice         ?: number;
    totalAfterDiscount ?: number;
    TotalPriceAfterTax?: number;
    TotalTax?: number;
    name?:string;
    taxs?:TaxModel[];
    constructor(item ?: ItemModel){
        item = item ? item : {};
        this.id = item.id ? item.id : 0;
        this.price               = item.price               ? item.price               : 0;
        this.quantity            = item.quantity            ? item.quantity            : 0;
        this.discount            = item.discount            ? item.discount            : 0;
        this.totalPrice          = item.totalPrice          ? item.totalPrice          : 0;
        this.TotalTax          = item.TotalTax          ? item.TotalTax          : 0;
        this.totalAfterDiscount  = item.totalAfterDiscount  ? item.totalAfterDiscount  : 0;
        this.TotalPriceAfterTax = item.TotalPriceAfterTax ? item.TotalPriceAfterTax : 0;
        this.name = item.name ? item.name : '';
        this.taxs = item.taxs ? item.taxs : [];
    }
}