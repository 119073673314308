<app-list-page-header *ngIf="tableView"[itemOptionsPerPage]="[5,10,20]"
    [itemsPerPage]="tableEngine.itemsPerPage" [selectAllState]="tableEngine.selectAllState" (addNewItem)="showModal(null,'Add')"
    [tableEngine]="this.tableEngine" (selectAllChange)="tableEngine.selectAll($event)" (changeOrderBy)="prepChangeSort($event)"
    (searchKeyUp)="tableEngine.filterDataByDateRange(this.SearchFrom,this.SearchTo,'Date')" [dateSearch]=true>
</app-list-page-header>

<div class="form-group" *ngIf="!tableView">
    <div class="modal-body">
        <form class="md-float-material form-material" [formGroup]="mainForm">

            <div style="text-align: center;">
                <button class="btn btn-light" (click)="goBack()"
                    style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Back"  | translate}}</button>
                <button *ngIf="tableEngine.currentFormMode==='Add'"
                    [disabled]="mainForm.invalid || tableEngine.currentAction==='loading' || addedItemList.length===0 || !codeWasChecked"
                    id="smallModalSubmitButton" class="btn btn-secondary" (click)="addObject()"
                    style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Add"  | translate}}</button>
                <button *ngIf="tableEngine.currentFormMode==='Add'" class="btn btn-secondary" (click)="addAndNew()"
                    [disabled]="mainForm.invalid || tableEngine.currentAction==='loading' || addedItemList.length===0 || !codeWasChecked"
                    style="font-weight: bold; width: 250px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"AddAndNew"  | translate}}</button>
                <button *ngIf="tableEngine.currentFormMode==='Edit'"
                    [disabled]="mainForm.invalid || tableEngine.currentAction==='loading' || addedItemList.length===0 || (!codeWasChecked && lastCode != modelObject.Code)"
                    id="smallModalSubmitButton" class="btn btn-secondary" (click)="editObject()"
                    style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Edit" | translate}}</button>
            </div>
            <div class="form-group" style="margin-top: 10px;">

                <div class="row">

                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"QuotationRequestCode" | translate}}</label>
                        <div *ngIf="(tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit') 
                                        && !configPurchases.EnableAutoQuotationRequestCodes">
                            <input type="number" min="0" name="Code" class="form-control" [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="lastCode" style=" margin: 0px;" (change)="checkCode()" (keyup)="checkCode()"
                            placeholder='{{"QuotationRequestCode" | translate}}'>
                        </div>
                        <div *ngIf="((tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit') 
                                        && configPurchases.EnableAutoQuotationRequestCodes)">
                        <label class="form-control" type="text" style="border: unset; width: 120px;"
                            name="Code">{{lastCode}}</label>
                        </div>
                        <div *ngIf="tableEngine.currentFormMode == 'Info'">
                        <label class="form-control" type="text" style="border: unset; width: 120px;"
                            name="Code">{{modelObject.Code}}</label>
                        </div>
                        <div class="messages text-danger"
                            *ngIf="!(lastCode > 0)">
                            {{"QuotationRequestCodeRequired" | translate}}</div>
                        <div class="messages text-danger"
                            *ngIf="!(codeWasChecked) && (tableEngine.currentFormMode != 'Info' && lastCode != modelObject.Code)">
                            {{"QuotationRequestCodeAlreadyTaken" | translate}}</div>
                    </div>

                </div>

                <br>

                <div class="row">



                    <!-- <div class=" col-md-4">
                        <label class="float-label push righ">{{"QuotationRequestStoreData" | translate}}</label>
                        <div *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'">
                            <ng-select appearance="outline" name="StoreDataId" formControlName="StoreDataId"
                                placeholder='{{"QuotationRequestStoreData" | translate}}' #QuotationRequestStoreDataSelect
                                labelForId="txtycd" [searchable]="true">
                                <ng-option *ngFor="let store of storeDataList" [value]="store.Id">
                                    {{lang==='ar-EG'?store.NameAr:store.NameEn}}</ng-option>
                            </ng-select>
                        </div>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="StoreDataId">{{lang==='ar-EG'?modelObject.StoreNameAr:modelObject.StoreNameEn}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('StoreDataId').hasError('required') || mainForm.get('StoreDataId').invalid) && ( mainForm.get('StoreDataId').dirty ||  mainForm.get('StoreDataId').touched)">
                            {{"QuotationRequestStoreDataRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>

                    <br> -->

                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"QuotationRequestSupplier" | translate}}</label>
                        <div *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'">
                            <ng-select appearance="outline" name="SupplierId" formControlName="SupplierId"
                                placeholder='{{"QuotationRequestSupplier" | translate}}' #QuotationRequestSupplierSelect
                                labelForId="txtycd" [searchable]="true">
                                <ng-option *ngFor="let supplier of supplierList" [value]="supplier.Id">
                                    {{lang==='ar-EG'?supplier.NameAr:supplier.NameEn}}</ng-option>
                            </ng-select>
                        </div>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="SupplierId">{{lang==='ar-EG'?modelObject.SupplierNameAr:modelObject.SupplierNameEn}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('SupplierId').hasError('required') || mainForm.get('SupplierId').invalid) && ( mainForm.get('SupplierId').dirty ||  mainForm.get('SupplierId').touched)">
                            {{"QuotationRequestSupplierRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>

                    <br>

                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"QuotationRequestDate" | translate}}</label>

                        <input *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                            [type]="modelObject.Id>0?'date':'text'" onfocus="(this.type='date')"
                            onblur="if(!this.value)this.type='text'" onchange="if(!this.value)this.type='text'"
                            name="Date" class="form-control" formControlName="Date"
                            placeholder='{{"QuotationRequestDate" | translate}}'>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="Date">{{modelObject.Date | date:"dd/MM/yyyy"}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('Date').hasError('required') || mainForm.get('Date').invalid) && ( mainForm.get('Date').dirty ||  mainForm.get('Date').touched)">
                            {{"QuotationRequestDateRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>

                    <br>

                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"QuotationRequestDueDate" | translate}}</label>

                        <input *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                            [type]="modelObject.Id>0?'date':'text'" onfocus="(this.type='date')"
                            onblur="if(!this.value)this.type='text'" onchange="if(!this.value)this.type='text'"
                            name="DueDate" class="form-control" formControlName="DueDate"
                            placeholder='{{"QuotationRequestDueDate" | translate}}'>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="DueDate">{{modelObject.DueDate | date:"dd/MM/yyyy"}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('DueDate').hasError('required') || mainForm.get('DueDate').invalid) && ( mainForm.get('DueDate').dirty ||  mainForm.get('DueDate').touched)">
                            {{"QuotationRequestDueDateRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>



                </div>

                <!-- <br>

                <div class="row">
                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"QuotationRequestIsPosted" | translate}}</label>
                        <div *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'">
                            <ng-select appearance="outline" name="IsPosted" formControlName="IsPosted"
                                placeholder='{{"QuotationRequestIsPosted" | translate}}' #QuotationRequestIsPostedSelect
                                labelForId="txtycd">
                                <ng-option [value]="false">{{"QuotationRequestIsPostedFalse" | translate}}</ng-option>
                                <ng-option [value]="true">{{"QuotationRequestIsPostedTrue" | translate}}</ng-option>
                            </ng-select>
                        </div>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="IsPosted">{{modelObject.IsPosted?"QuotationRequestIsPostedTrue":"QuotationRequestIsPostedFalse"
                            | translate}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('IsPosted').hasError('required') || mainForm.get('IsPosted').invalid) && ( mainForm.get('IsPosted').dirty ||  mainForm.get('IsPosted').touched)">
                            {{"QuotationRequestIsPostedRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>
                </div> -->

                <br>

                <div class="row" style="height: 160px;">
                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"QuotationRequestDescription" | translate}}</label>
                        <textarea *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                            type="text" name="Description" class="form-control" formControlName="Description"
                            style="width: 319%; height: 150px;"
                            placeholder='{{"QuotationRequestDescription" | translate}}'></textarea>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            style="white-space:pre-wrap; word-wrap:break-word; width: 319%; height: 150px; overflow: auto;"
                            name="Description">{{modelObject.Description}}</label>
                        <span class="form-bar"></span>
                    </div>
                </div>

                <br>

                <!-- <div id="divmiddle" style="display: block; margin-top: 5px;"> -->
                <div>
                    <div style="width: 100%; margin-top: 15px;">
                        <div>
                            <table class="table">
                                <thead class="thead-light">
                                    <th></th>
                                    <th>{{"QuotationRequestItemName" | translate}}</th>
                                    <th>{{"QuotationRequestItemUnit" | translate}}</th>
                                    <!-- <th>{{"QuotationRequestItemPrice" | translate}}</th> -->
                                    <th>{{"QuotationRequestItemQuantity" | translate}}</th>
                                    <!-- <th>{{"QuotationRequestItemValue" | translate}}</th> -->
                                </thead>
                                <tbody>
                                    
                                    <tr *ngFor="let obj of addedItemList;  let i=index">
                                        <td style="text-align: center; vertical-align: middle;">
                                            <i *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                                            class="simple-icon-close" (click)="removeQuotationRequestItem(obj.Id)"
                                                style="font-size: 20px; font-weight: bolder; cursor: pointer; 
                                        color: #DC3545;"></i>
                                        </td>
                                        <td style="vertical-align: middle;">
                                            {{obj.ItemId>0?(lang==='ar-EG'?obj.ItemNameAr:obj.ItemNameEn):"None"|translate}}
                                        </td>
                                        <td style="vertical-align: middle;">
                                            {{obj.UnitId>0?(lang==='ar-EG'?obj.UnitNameAr:obj.UnitNameEn):"None"|translate}}
                                        </td>
                                        <!-- <td style="vertical-align: middle;">
                                            {{obj.Price}}
                                        </td> -->
                                        <td style="vertical-align: middle;">
                                            {{obj.Quantity}}
                                        </td>
                                        <!-- <td style="vertical-align: middle;">
                                            {{obj.Value}}
                                        </td> -->
                                    </tr>
                                    <tr *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                                        type="text" [formGroup]=quotationRequestItemForm>

                                        <td style="text-align: center; vertical-align: middle;">
                                            <i class="simple-icon-plus"
                                                [ngClass]="!(quotationRequestItemForm.invalid)?'simple-icon-plus':'simple-icon-plus inactive-icon'"
                                                (click)="!(quotationRequestItemForm.invalid||tableEngine.currentAction==='loading')?addQuotationRequestItem():''"
                                                style="font-size: 20px; font-weight: bolder; cursor: pointer; color: #17A2B8;"></i>
                                        </td>
                                        <td>
                                            <div>
                                                <ng-select appearance="outline" name="ItemId"
                                                    formControlName="ItemId"
                                                    placeholder='{{"QuotationRequestItemName" | translate}}'
                                                    #QuotationRequestItemNameSelect labelForId="txtycd2"
                                                    [searchable]="true" (change)="changeItem()">
                                                    <ng-option *ngFor="let item of itemList" [value]="item.Id">
                                                        {{lang==='ar-EG'?item.Name:item.Name}}</ng-option>
                                                </ng-select>
                                            </div>
                                        </td>
                                        <td>
                                            <div style="margin-top: 8px; margin-bottom: 8px;">
                                                {{newQuotationRequestItem.UnitId>0?(lang==='ar-EG'?newQuotationRequestItem.UnitNameAr:newQuotationRequestItem.UnitNameEn):"None"|translate}}
                                            </div>
                                        </td>
                                        <!-- <td>
                                            <input
                                                *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                                                type="number" min="0" name="Price" class="form-control"
                                                formControlName="Price" style="width: 150px; margin: 0px;"
                                                placeholder='{{"QuotationRequestItemPrice" | translate}}'>
                                            <div class="messages text-danger"
                                                *ngIf=" (quotationRequestItemForm.get('Price').hasError('required') || quotationRequestItemForm.get('Price').invalid) && ( quotationRequestItemForm.get('Price').dirty || quotationRequestItemForm.get('Price').touched)">
                                                {{"QuotationRequestItemPriceRequired" | translate}}</div>
                                        </td> -->
                                        <td>
                                            <input
                                                *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                                                type="number" min="0" name="Quantity" class="form-control"
                                                formControlName="Quantity" style="width: 150px; margin: 0px;"
                                                placeholder='{{"QuotationRequestItemQuantity" | translate}}'>

                                            <div class="messages text-danger"
                                                *ngIf=" (quotationRequestItemForm.get('Quantity').hasError('required') || quotationRequestItemForm.get('Quantity').invalid) && ( quotationRequestItemForm.get('Quantity').dirty || quotationRequestItemForm.get('Quantity').touched)">
                                                {{"QuotationRequestItemQuantityRequired" | translate}}</div>
                                        </td>
                                        <!-- <td style="vertical-align: middle;">
                                            <div style="margin-top: 8px; margin-bottom: 8px;">
                                                {{quotationRequestItemForm.getRawValue().Price *
                                                quotationRequestItemForm.getRawValue().Quantity}}</div>

                                        </td> -->
                                    </tr>
                                </tbody>
                                <thead class="thead-light">
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <!-- <th></th>
                                    <th></th> -->
                                </thead>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- <div class="row">
                    <div class=" col-md-4">
                        <div style="display: inline-block;">
                            {{"QuotationRequestTotalValue" | translate}}
                        </div>
                        <label
                            style="display: inline-block; margin-left: 8px; margin-right: 8px; min-width: 150px; border: unset; font-size: 25px;"
                            class="form-control" type="text" name="TotalValue">{{currentTotal>0?currentTotal:0}}</label>
                    </div>
                </div> -->
            </div>
        </form>
    </div>
</div>

<div bsModal #delModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <!-- <button type="button" class="close pull-right" aria-label="Close" (click)="delModal.hide()"
                style="margin-right: 20px; margin-left: 20px; margin-top: 10px; width: 20px; display: inline;">
            <span aria-hidden="true">&nbsp;</span>
            </button> -->
            <span aria-hidden="true">&nbsp;</span>
            <div class="modal-header" style="display: inline; padding: 0px 0px 5px 0px;">
                <h4 id="dialog-child-name" class="modal-title pull-left" style="text-align: center; color: #DC3545;">
                    {{"ConfirmDel"+translationName |
                    translate}}</h4>
            </div>
            <div class="modal-body">
                <div class="form-group form-primary" *ngIf="tableEngine.deletionList.length < tableEngine.ogData.length 
                || tableEngine.deletionList.length === 1" style="text-align: center;">
                    <div *ngFor="let item of tableEngine.deletionList;" style="text-align: center;">
                        <label type="text">- {{translationName|translate}} {{"Number" | translate}}
                            {{lang==='ar-EG'?item.Code:item.Code}}</label>
                        <span class="form-bar"></span>
                    </div>
                    <!-- <div style="text-align: center;">
                        <label type="text">- {{(tableEngine.deletionList.length>2?tableEngine.deletionList.length:"")}}
                            {{(tableEngine.deletionList.length===1?"One"+translationName:
                            tableEngine.deletionList.length===2?"Two"+translationName:
                            tableEngine.deletionList.length>2 && tableEngine.deletionList.length
                            <11?translationName+"RowsPlur": translationName+"Rows") | translate}} </label>
                                <span class="form-bar"></span>
                    </div> -->
                </div>
                <div class="form-group form-primary" style="text-align: center;" *ngIf="tableEngine.deletionList.length > 1 && 
                    tableEngine.deletionList.length === tableEngine.ogData.length">
                    <label type="text">
                        <h1 style="font-weight: bolder; text-align: center;">{{translationName+"DeleteAll" | translate}}
                        </h1>
                    </label>
                    <span class="form-bar"></span>
                </div>

                <div style="margin-left: 5px; text-align: center; margin-top: 30px;">
                    <button class="btn btn-danger" id="smallModalDeleteButton" (click)="deleteObject()"
                        [disabled]="tableEngine.currentAction==='loading'"
                        style="font-weight: bold; width: 100%; text-align: center;">{{"Delete" | translate}}</button>
                    <button class="btn btn-light" (click)="delModal.hide()"
                        style="font-weight: bold; width: 100%; text-align: center; margin-top: 10px;">{{"Close" |
                        translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="tableView" class="FormScreen" class="tableScreen">
    
    <div id="divmiddle" style="display: block; margin-top: 5px;">

        <div style="width: 100%;">
            <div>
                <div class="card" style="min-width: 900px !important;">
                    <div class="card-body">
                        <table class="table table-hover" style="margin: unset !important;">
                            <thead class="no-border-top">
                                <th style="text-align: center; padding:5px;">
                                    <div class=" text-zero" style="display: inline-block;">
                                        <div class="top-right-button-container2 btn-group" dropdown>
                                            <div class="btn btn-primary pl-4 pr-0 check-button">
                                                <label class="custom-control custom-checkbox mb-0 d-inline-block"
                                                    style="width: 10px;">
                                                    <input type="checkbox" class="custom-control-input"
                                                        [checked]="tableEngine.selectAllState==='checked'"
                                                        [indeterminate]="tableEngine.selectAllState === 'indeterminate'"
                                                        (change)="tableEngine.selectAll($event)">
                                                        <span class="custom-control-label">&nbsp;</span>
                                                </label>
                                            </div>
                                            <button id="button-split" type="button" dropdownToggle style="width: 10px;"
                                                
                                                [ngClass]="(tableEngine.selected.length == 0)?
                                                'btn btn-outline-primary dropdown-toggle dropdown-toggle-split':
                                                'btn btn-primary dropdown-toggle dropdown-toggle-split'"
                                                aria-controls="dropdown-split">
                                                <span class="caret"></span>
                                            </button>
                                            <ul id="dropdown-split" *dropdownMenu
                                                class="dropdown-menu dropdown-menu-right" role="menu"
                                                aria-labelledby="button-split">
                                                <li role="menuitem"><a class="dropdown-item">
                                                        <button class="btn btn-danger d-inline-block"
                                                            style=" font-weight: bold; text-align: center; padding: 0px; width: 150px; height: 30px;"
                                                            (click)="tableEngine.showDelModal('delMulti')"
                                                            [disabled]="tableEngine.selected.length===0 || tableEngine.currentAction==='loading'">{{"DeleteSelected"
                                                            |
                                                            translate}}</button></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </th>

                                <th class="sorticon" (click)="prepChangeSort('QuotationRequestCode')"
                                    [ngClass]="(itemOrder.label==='QuotationRequestCode')?'sortkey sorticon':'sorticon'">
                                    <div
                                        [ngClass]="{'iconsminds-up---down': (itemOrder.label!='QuotationRequestCode'), 
                                    'iconsminds-down': ((itemOrder.label==='QuotationRequestCode') && tableEngine.orderAsc === true),
                                    'iconsminds-up': ((itemOrder.label==='QuotationRequestCode') && tableEngine.orderAsc === false)}">
                                    </div>
                                </th>
                                <th class="sortOption" (click)="prepChangeSort('QuotationRequestCode')"
                                    [ngClass]="{'sortkey': (itemOrder.label==='QuotationRequestCode')}">
                                    {{"QuotationRequestCode" | translate}}
                                </th>


                                <th class="sorticon" (click)="prepChangeSort('QuotationRequestSupplier')"
                                    [ngClass]="(itemOrder.label==='QuotationRequestSupplier')?'sortkey sorticon':'sorticon'">
                                    <div
                                        [ngClass]="{'iconsminds-up---down': (itemOrder.label!='QuotationRequestSupplier'), 
                                    'iconsminds-down': ((itemOrder.label==='QuotationRequestSupplier') && tableEngine.orderAsc === true),
                                    'iconsminds-up': ((itemOrder.label==='QuotationRequestSupplier') && tableEngine.orderAsc === false)}">
                                    </div>
                                </th>
                                <th class="sortOption" (click)="prepChangeSort('QuotationRequestSupplier')"
                                    [ngClass]="{'sortkey': (itemOrder.label==='QuotationRequestSupplier')}">
                                    {{"QuotationRequestSupplier" | translate}}
                                </th>


                                <th class="sorticon" (click)="prepChangeSort('QuotationRequestDate')"
                                    [ngClass]="(itemOrder.label==='QuotationRequestDate')?'sortkey sorticon':'sorticon'">
                                    <div
                                        [ngClass]="{'iconsminds-up---down': (itemOrder.label!='QuotationRequestDate'), 
                                    'iconsminds-down': ((itemOrder.label==='QuotationRequestDate') && tableEngine.orderAsc === true),
                                    'iconsminds-up': ((itemOrder.label==='QuotationRequestDate') && tableEngine.orderAsc === false)}">
                                    </div>
                                </th>
                                <th class="sortOption" (click)="prepChangeSort('QuotationRequestDate')"
                                    [ngClass]="{'sortkey': (itemOrder.label==='QuotationRequestDate')}">
                                    {{"QuotationRequestDate" | translate}}
                                </th>


                                <th class="sorticon" (click)="prepChangeSort('QuotationRequestDueDate')"
                                    [ngClass]="(itemOrder.label==='QuotationRequestDueDate')?'sortkey sorticon':'sorticon'">
                                    <div
                                        [ngClass]="{'iconsminds-up---down': (itemOrder.label!='QuotationRequestDueDate'), 
                                    'iconsminds-down': ((itemOrder.label==='QuotationRequestDueDate') && tableEngine.orderAsc === true),
                                    'iconsminds-up': ((itemOrder.label==='QuotationRequestDueDate') && tableEngine.orderAsc === false)}">
                                    </div>
                                </th>
                                <th class="sortOption" (click)="prepChangeSort('QuotationRequestDueDate')"
                                    [ngClass]="{'sortkey': (itemOrder.label==='QuotationRequestDueDate')}">
                                    {{"QuotationRequestDueDate" | translate}}
                                </th>


                                <!-- <th class="sorticon" (click)="prepChangeSort('QuotationRequestStoreName')"
                                    [ngClass]="(itemOrder.label==='QuotationRequestStoreName')?'sortkey sorticon':'sorticon'">
                                    <div
                                        [ngClass]="{'iconsminds-up---down': (itemOrder.label!='QuotationRequestStoreName'), 
                                    'iconsminds-down': ((itemOrder.label==='QuotationRequestStoreName') && tableEngine.orderAsc === true),
                                    'iconsminds-up': ((itemOrder.label==='QuotationRequestStoreName') && tableEngine.orderAsc === false)}">
                                    </div>
                                </th>
                                <th class="sortOption" (click)="prepChangeSort('QuotationRequestStoreName')"
                                    [ngClass]="{'sortkey': (itemOrder.label==='QuotationRequestStoreName')}">
                                    {{"QuotationRequestStoreName" | translate}}
                                </th> -->
<!-- 
                                <th class="sorticon" (click)="prepChangeSort('QuotationRequestTotalValue')"
                                    [ngClass]="(itemOrder.label==='QuotationRequestTotalValue')?'sortkey sorticon':'sorticon'">
                                    <div
                                        [ngClass]="{'iconsminds-up---down': (itemOrder.label!='QuotationRequestTotalValue'), 
                                    'iconsminds-down': ((itemOrder.label==='QuotationRequestTotalValue') && tableEngine.orderAsc === true),
                                    'iconsminds-up': ((itemOrder.label==='QuotationRequestTotalValue') && tableEngine.orderAsc === false)}">
                                    </div>
                                </th>
                                <th class="sortOption" (click)="prepChangeSort('QuotationRequestTotalValue')"
                                    [ngClass]="{'sortkey': (itemOrder.label==='QuotationRequestTotalValue')}">
                                    {{"QuotationRequestTotalValue" | translate}}
                                </th> -->
                                <!-- <th class="sorticon" (click)="prepChangeSort('QuotationRequestIsPosted')"
                                    [ngClass]="(itemOrder.label==='QuotationRequestIsPosted')?'sortkey sorticon':'sorticon'">
                                    <div
                                        [ngClass]="{'iconsminds-up---down': (itemOrder.label!='QuotationRequestIsPosted'), 
                                    'iconsminds-down': ((itemOrder.label==='QuotationRequestIsPosted') && tableEngine.orderAsc === true),
                                    'iconsminds-up': ((itemOrder.label==='QuotationRequestIsPosted') && tableEngine.orderAsc === false)}">
                                    </div>
                                </th>
                                <th class="sortOption" (click)="prepChangeSort('QuotationRequestIsPosted')"
                                    [ngClass]="{'sortkey': (itemOrder.label==='QuotationRequestIsPosted')}">
                                    {{"QuotationRequestIsPosted" | translate}}
                                </th> -->

                                <th [colSpan]="3"
                                    style="text-align: center; padding: unset !important; vertical-align: middle;">

                                    <div id="displayOptions" style="
                                        display: inline; margin-top: 10px; margin-left: 5px; margin-right: 5px;">
                                        <span class="text-muted text-small" style=" width: 50px; height: 40px;">
                                            {{"ResultCount" | translate}}
                                        </span>
                                        <span dropdown class="d-inline-block position-relative">
                                            <button class="btn btn-outline-dark btn-xs dropdown-toggle" type="button"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                                dropdownToggle>
                                                {{tableEngine.itemsPerPage}}
                                            </button>
                                            <div class="dropdown-menu dropdown-menu-right dropdown-menu-sm text-right"
                                                *dropdownMenu>
                                                <li role="menuitem" *ngFor="let item of tableEngine.itemOptionsPerPage">
                                                    <a [ngClass]="item === tableEngine.itemsPerPage ? 'dropdown-item active' : 'dropdown-item'"
                                                        (click)="tableEngine.paginateData(item, 1)">{{item}}</a></li>
                                            </div>
                                        </span>
                                    </div>

                                </th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let obj of tableEngine.paginatedData;  let i=index">
                                    <td style="text-align: center; padding:5px;">
                                        <div class="itemCheck mb-0 custom-checkbox custom-control"
                                            style="display: inline-block;">
                                            <input type="checkbox" class="custom-control-input" [id]="'customCheck'+i"
                                                [checked]="tableEngine.isSelected(obj)"
                                                (change)="tableEngine.onSelect(obj)">
                                            <label class="custom-control-label" [for]="'customCheck'+i"></label>
                                        </div>
                                    </td>
                                    <td colspan="2">{{obj.Code}}</td>
                                    <td colspan="2">
                                        {{lang==='ar-EG'?obj.SupplierNameAr:obj.SupplierNameEn}}
                                    </td>
                                    <td colspan="2">{{obj.Date | date:"dd/MM/yyyy"}}</td>
                                    <td colspan="2">{{obj.DueDate | date:"dd/MM/yyyy"}}</td>
                                    <!-- <td colspan="2">
                                        {{lang==='ar-EG'?obj.StoreNameAr:obj.StoreNameEn}}
                                    </td> -->
                                    <!-- <td colspan="2">{{obj.TotalValue}}</td> -->
                                    <!-- <td colspan="2" style="white-space:initial; word-wrap:break-word;  max-width: 250px;">
                                        {{obj.Description}}
                                    </td> -->
                                    <!-- <td colspan="2" style="text-align: center;">
                                        <span style="width: 90px; text-align: center; margin-top: 5px;"
                                            [ngClass]="obj.IsPosted?'badge badge-pill badge-success mb-1':'badge badge-pill badge-warning mb-1'">{{(obj.IsPosted?"QuotationRequestIsPostedTrue":"QuotationRequestIsPostedFalse")
                                            | translate}}</span>

                                    </td> -->
                                    <td style="text-align: center;">
                                        <i class="simple-icon-info" (click)="showModal(obj.Id, 'Info')"
                                            tooltip="{{'Info'|translate}}" placement="auto" style="font-size: 20px; font-weight: bolder; cursor: pointer; 
                                                vertical-align: middle; color: #17A2B8;"></i>
                                    </td>
                                    <td style="text-align: center;">
                                        <i class="simple-icon-pencil" (click)="showModal(obj.Id, 'Edit')"
                                            tooltip="{{'Edit'|translate}}" placement="auto" style="font-size: 20px; font-weight: bolder; cursor: pointer; 
                                                vertical-align: middle; color: #FFC107;"></i>
                                    </td>
                                    <td style="text-align: center;">
                                        <i class="simple-icon-close" (click)="tableEngine.showDelModal(obj.Id)"
                                            tooltip="{{'Delete'|translate}}" placement="auto" style="font-size: 20px; font-weight: bolder; cursor: pointer; 
                                                vertical-align: middle; color: #DC3545;"></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div id="divsearchmiddle" style="display: inline-block; width: 100%; height: 100%;">
                            <div style="text-align: center;">
                                <!-- <div class="centerside" style="display: inline-block; width: 15%; height: 100%; ">
                                    &nbsp;

                                </div> -->
                                <div class="centercenter"
                                    style="display: inline-block; width: 70%; height: 100%;  text-align: center;">

                                    <bdo dir="ltr">
                                        <pagination class="pagination-sm2" style="padding: 0px, 0px, 0px, 0px; margin: 0px, 0px, 0px, 0px;
                                        display: inline-block;" [(ngModel)]="currentPage" [boundaryLinks]="true"
                                            [totalItems]="tableEngine.filteredData.length"
                                            [itemsPerPage]="tableEngine.itemsPerPage"
                                            [customFirstTemplate]="firstTemplate" [customLastTemplate]="lastTemplate"
                                            [customPreviousTemplate]="prevTemplate" [customNextTemplate]="nextTemplate"
                                            [maxSize]="5" (pageChanged)="tableEngine.changePage($event)">
                                        </pagination>
                                    </bdo>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

            </div>
        </div>
    </div>

    <div id="divbottom" style="display: block; height: 47px;">

        <div id="divsearchright" style="float: right;  width: 33.33%; padding: 0px;">&nbsp;</div>


        <div id="divsearchleft" style="float: left; width: 33.33%; height: 100%;">&nbsp;</div>

    </div>
</div>

<div>

</div>

<ng-template #nextTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i *ngIf="lang==='en-US'" class='simple-icon-arrow-right'></i>
    <i *ngIf="lang==='ar-EG'" class='simple-icon-arrow-left'></i>
</ng-template>

<ng-template #prevTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i *ngIf="lang==='en-US'" class='simple-icon-arrow-left'></i>
    <i *ngIf="lang==='ar-EG'" class='simple-icon-arrow-right'></i>
</ng-template>

<ng-template #lastTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i class='simple-icon-control-end'></i>
</ng-template>

<ng-template #firstTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i class='simple-icon-control-start'></i>
</ng-template>


<simple-notifications></simple-notifications>