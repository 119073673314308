export class DepartmentModel{
    Id?: number;
    ManagerId?:number;
    Name?:string;
    limitation?:string;
    Description?:string;
    Status?:boolean;


 
    constructor(item?:DepartmentModel){
        item = item? item:{};
        this.Id = item.Id? item.Id:0;
        this.ManagerId = item.ManagerId? item.ManagerId:0;
        this.Name = item.Name? item.Name:'';
        this.limitation = item.limitation? item.limitation:'';
        this.Description = item.Description? item.Description:'';
        this.Status = item.Status? item.Status:true;

    }

}