import { ItemsStoreBellModel } from "./ItemsStoreBillModel";

export class StoreBillModel{
    Id?: number;
    SkuNumber?:number;
    BillType?:string;
    WarehouseName?:string;
    WarehouseId?:number;
    SecondWarehouseId?:number;
    MoveNumber?:number;
    SubAccountName?:string;
    SecondWarehouseName?:string;
    
    SubAccountId?:number;
    Notes?:string;
    Time?:Date;
    Date?:Date;
    IsApproval?:boolean;
    ItemsStoreBillList?:ItemsStoreBellModel[]

    constructor(item?:StoreBillModel){
        item = item? item:{};
        this.Id = item.Id? item.Id:0;
        this.SkuNumber = item.SkuNumber? item.SkuNumber:1;
        this.MoveNumber = item.MoveNumber? item.MoveNumber:1;
        
        this.BillType = item.BillType? item.BillType:'';          
        this.WarehouseName = item.WarehouseName? item.WarehouseName:'';          
        this.SecondWarehouseName = item.SecondWarehouseName? item.SecondWarehouseName:'';          
        this.SubAccountName = item.SubAccountName? item.SubAccountName:'';          
        this.WarehouseId = item.WarehouseId? item.WarehouseId:0;          
        this.Notes = item.Notes? item.Notes:'';          
        this.SubAccountId = item.SubAccountId? item.SubAccountId:0;          
        this.WarehouseId = item.WarehouseId? item.WarehouseId:0;          
        this.SecondWarehouseId = item.SecondWarehouseId? item.SecondWarehouseId:0;          
        this.IsApproval = item.IsApproval? item.IsApproval:true;          
        
        this.Notes = item.Notes? item.Notes:'';          
        this.Time = item.Time? item.Time:new Date();          
        this.Date = item.Date? item.Date:new Date();          
        this.ItemsStoreBillList = item.ItemsStoreBillList? item.ItemsStoreBillList:[];

    }

}