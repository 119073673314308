<!--component html goes here -->
<!--component html goes here -->
<div class="row" *ngIf="mainForm">
    <div class="col-12">
      <div class="card mb-4">
        <div class="card-body">
            <strong style="font-size: 22px; margin-left:10px ;" >{{modelObject.BillType}}</strong>
            <!-- <strong style="font-size: 22px; margin-left:10px ;" *ngIf="modelObject.BillType==2">{{'SubBill' | translate}}</strong> -->
            <strong style="font-size: 22px; margin-left:10px ;">{{modelObject.SkuNumber}}#</strong>
            <span class="badge badge-pill badge-success mb-1" *ngIf="modelObject.IsApproval">{{'Approval' | translate}}</span>
            <span class="badge badge-pill badge-info mb-1" style="background-color: #ffc107;" *ngIf="!modelObject.IsApproval"> {{'NotApproval' | translate}}</span>
         
          <div class="top-right-button-container text-zero float-right">
            <button
              class="top-right-button btn btn-primary btn-lg mr-1"
              (click)="goBack()"
            >
              {{ "Back" | translate }}
            </button>
            <button
              type="button"
              (click)=" showModal(Id, 'Edit')"
              [disabled]="mainForm.invalid"
              class="top-right-button btn btn-primary btn-lg mr-1"
            >
              {{ "Edit" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
</div>
<div class="card"*ngIf="modelObject" >
    <tabset class="card-tabs">
        <tab heading="{{'Information' | translate}}" class="card-body" >
            <div class="modal-body">
                <form>
                    <div  *ngIf="tableView" class="FormScreen" class="value_highlight col-md-12">
                        <table class="table table-borderless">
                          <thead>                
                            <tr style="background-color: #f6f9fc;">
                              <td>{{'BillType'|translate}}</td>
                              <td>{{'Date'|translate}}</td>
                              <td>{{'SkuNumber'|translate}}</td>
                              <td>{{'Ref'|translate}}</td>
                              <td>{{'Warehouse'|translate}}</td>
                            </tr>
            
                            <tr >
                                <td >
                                    <!-- <label  *ngIf="modelObject.BillType==1">{{'AdditionBill' | translate}}</label>
                                    <label  *ngIf="modelObject.BillType==2">{{'SubBill' | translate}}</label>                               
                                    <label  *ngIf="modelObject.BillType==3">{{'Transformation' | translate}}</label>                                -->
                                    <!-- <label ></label>                                -->
                                    {{modelObject.BillType}}
                                </td>
                             
                              <td >
                                {{modelObject.Date}}
                                
                              </td>
                              
                              <td >                 
                                {{modelObject.SkuNumber}}#
                   
                              </td>
                              <td >
                               <label  >{{modelObject.BillType}}</label>                               

                                <label   name="ToatlQuantitySoled">{{modelObject.SkuNumber}}#</label>
    
                                
                              </td>
                              <td>
                                {{modelObject.WarehouseName}}
                                <i style='font-size: 20px; '>&#8592;</i>
                                {{modelObject.SecondWarehouseName}}
                              </td>
                              
                            </tr>
                            <tr style="background-color: #f6f9fc;">
                                <td>{{'SubAccount'|translate}}</td>
                                  <td>{{'Notes'|translate}}</td>
                                  <td></td>
                                  <td></td>
                                  <td></td>

                            </tr>
                            <tr>
                                <td>
                                    {{modelObject.SubAccountName}}
                                  </td>
                                  <td>
                                    {{modelObject.Notes}}
                                  </td>
                            </tr>
                            <tr></tr>
                            <tr class="solid"></tr>
            
                            
                          </thead>
                          
                        </table>
                      </div>
                    <!-- <div class="row flex-row" >
                        <div class="value_highlight col-md-2 ">
                            <h5 for="inputState" style="color: #0189DE;font-size: 14px;">{{ "BillType" | translate }}</h5>
                            <label  *ngIf="modelObject.BillType==1">{{'AdditionBill' | translate}}</label>
                            <label  *ngIf="modelObject.BillType==2">{{'SubBill' | translate}}</label>                               
                        </div>

                        <div class="vl"></div>
                        <div class="value_highlight col-md-2 ">
                            <h5 for="inputState" style="color: #0189DE;font-size: 14px;">{{ "Date" | translate }}</h5>
                            <label  type="date"  name="TotalQuantitySoled">{{modelObject.Date}}</label>
                            <label  type="time"  style=" font-size: x-large;" name="TotalQuantitySoled">{{modelObject.Time}}</label>
                          </div>
                          <div class="vl"></div>
                          <div class="value_highlight col-md-2 ">
                            <h5 for="inputState" style="color: #0189DE;font-size: 14px;">{{ "SkuNumber" | translate }}</h5>
                            <label  type="date"  name="TotalQuantitySoled">{{modelObject.SkuNumber}}#</label>
                          </div>
                          <div class="vl"></div>
                          <div class="value_highlight col-md-2 ">
                            <h5 for="inputState" style="color: #0189DE;font-size: 14px;">{{ "Ref" | translate }}</h5>
                            <label  *ngIf="modelObject.BillType==1">{{'AdditionBill' | translate}}</label>
                            <label  *ngIf="modelObject.BillType==2">{{'SubBill' | translate}}</label>     
                            <label  type="date"  name="TotalQuantitySoled">{{modelObject.SkuNumber}}#</label>

                      </div>
                      <div class="vl"></div>
                      <div class="value_highlight col-md-3 ">
                        <h5 for="inputState" style="color: #0189DE;font-size: 14px;">{{ "Warehouse" | translate }}</h5>
                        <label  type="date"  name="TotalQuantitySoled">{{modelObject.WarehouseId}}</label>
                      </div>
                        <div class="value_highlight col-md-6 ">
                           
                            <div class="panel panel-default">
                               
                                <div class="panel-heading">{{'BillType' | translate}}</div>
                                <div class="panel-body">
                                    <div class="col-xs-4  p-0 b-r">
                                        <label style="font-size: 22px; margin-left:10px ;" *ngIf="modelObject.BillType==1">{{'AdditionBill' | translate}}</label>
                                        <label style="font-size: 22px; margin-left:10px ;" *ngIf="modelObject.BillType==2">{{'SubBill' | translate}}</label>
                                       

                                    </div>
                                    <div class="col-xs-4  p-0 b-r">

                                    </div>
                                    <div class="col-xs-4  p-0 b-r">

                                    </div>
                                </div>
                            </div>


                        </div>
                    </div> -->
                    <!-- <div class="row flex-row" >
                        <div class="value_highlight col-md-2 ">
                            <h5 for="inputState" style="color: #0189DE;font-size: 14px;">{{ "SubAccount" | translate }}</h5>
                            <label  type="date"  name="TotalQuantitySoled">{{modelObject.SubAccountId}}</label>
                        </div>
                    </div> -->
                </form>
                <tr></tr>
                <tr></tr>
                <tr></tr>
                <tr></tr>
               <form>
                <div  *ngIf="tableView" class="FormScreen" class="value_highlight col-md-12"> 
                    <h3 class="head-bar theme-color-a"><span class="details-info">{{'ProducteLIst' | translate}}</span></h3>
                    <table class="table table-borderless">
                      <thead>                
                        <tr style="background-color: #f6f9fc;">
                          <td>{{'Items'|translate}}</td>
                          <td>{{'UnitPrice'|translate}}</td>
                          <td>{{'Quantity'|translate}}</td>
                          <td></td>
                          <td></td>
                          <td>{{'Total'|translate}}</td>
                         
                        </tr>
        
                        <tr *ngFor="let bell of ItemsStoreBell; let i = index">
                            <td >                            
                            
                                {{ bell.Name }}
                              
                          </td>
                         
                          <td >
                            {{bell.SalePrice}}
                            
                          </td>
                          
                          <td  >                 
                            {{bell.AdjustedQuantity}}
               
                          </td>
                          <td></td>
                          <td></td>
                          <td >
                            {{bell.SalePrice*bell.AdjustedQuantity}}
                            
                          </td>
                        </tr>
                        <tr >
                            <td>
                                {{"Total" | translate}}
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>
                             <input class="form-control" type="number" [(ngModel)]="currentTotal" [ngModelOptions]="{standalone: true}" readonly>

                            </td>
                        </tr>
        
                        
                      </thead>
                      
                    </table>
                                 
                  <div class="value_highlight col-md-3 ">
                  </div>
                </div>
               </form>
            </div>
        </tab>  
    </tabset>
</div>