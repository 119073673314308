<!--component html goes here -->
<div class="row mb-12">
    <div class="col-md-12 col-lg-12 col-12 mb-12">
      <div class="card"></div>
    </div>
  </div>
  <div class="row" *ngIf="mainForm">
    <div class="col-12">
      <div class="card mb-4">
        <div class="card-body">
          <h5 class="float-left">{{ "NewAttachment" | translate }}</h5>
          <div class="top-right-button-container text-zero float-right">
            <button
              class="top-right-button btn btn-primary btn-lg mr-1"
              (click)="goBack()"
            >
              {{ "Back" | translate }}
            </button>
            <button
              type="button"
              (click)="Save()"
              [disabled]="mainForm.invalid"
              class="top-right-button btn btn-primary btn-lg mr-1"
            >
              {{ "Save" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mb-12">
    <div class="col-md-12 col-lg-12 col-12 mb-12">
      <div class="card">
        <tabset class="card-tabs" [justified]="true">
          <tab heading="{{ 'NotesAndAttachment' | translate }}" class="card-body">
            <!-- <h6 class="mb-4">{{ "Basic Data" | translate }}</h6> -->
            <div class="modal-body">
              <form
                class="md-float-material form-material"
                [formGroup]="mainForm"
              >
                <div class="row">
                  <div class="col-md-3">
                    <label for="inputState">{{ "Date" | translate }}</label>
                    <!-- <barcode type="code128b">Hello</barcode> -->
                    <input
                      class="form-control"
                      formControlName="Date"
                      rows="2"
                      placeholder="{{ 'Date' | translate }}"
                      type="date" />
                    <div
                      class="messages text-danger"
                      *ngIf="
                        (mainForm.get('Date').hasError('required') ||
                          mainForm.get('Date').invalid) &&
                        (mainForm.get('Date').dirty ||
                          mainForm.get('Date').touched)
                      "
                    >
                      {{ "DateRequired" | translate }}
                    </div>
                  </div>
                  <div class="col-md-2">
                    <label for="inputState">{{ "Time" | translate }}</label>
                    <!-- <barcode type="code128b">Hello</barcode> -->
                    <input
                      class="form-control"
                      formControlName="Time"
                      rows="2"
                      placeholder="{{ 'Time' | translate }}"
                      type="time"
                    />
                    <div
                      class="messages text-danger"
                      *ngIf="
                        (mainForm.get('Time').hasError('required') ||
                          mainForm.get('Time').invalid) &&
                        (mainForm.get('Time').dirty ||
                          mainForm.get('Time').touched)
                      "
                    >
                      {{ "NameRequired" | translate }}
                    </div>
                  </div>
                  
                  <div class="col-md-3" >
                    <label for="inputState">{{ "Action" | translate }}</label>

                    <div class="input-group mb-3">

                      <div class="input-group-prepend">
                        <span class="input-group-text" id="inputGroup-sizing-default"> <i
                          class="simple-icon-plus"
                          (click)="showAddNewActionModalRef()"
                          tooltip="{{ 'EditActions' | translate }}"
                          style="
                            font-size: 20px;
                            font-weight: bolder;
                            cursor: pointer;
                            color: #17a2b8;
                          "
                        ></i></span>
                      </div>
                      <select formControlName="ActionId"  class="form-control">
                        <option selected value="1">
                          {{ "-" | translate }}
                        </option>
                        <option
                          *ngFor="let Action of ActionList"
                          value="{{ Action.Id }}"
                        >
                          {{ Action.Name }}
                        </option>
                      </select>
                     </div>
                  
                  </div>
                  <div class="col-md-3" >
                    <label for="inputState">{{ "StatusEventTo" | translate }}</label>

                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="inputGroup-sizing-default"> <i
                          class="simple-icon-plus"
                          (click)="showAddNewActionStatuseModalRef()"
                          tooltip="{{ 'EditeTheListOfActionsStatuse' | translate }}"
                          style="
                            font-size: 20px;
                            font-weight: bolder;
                            cursor: pointer;
                            color: #17a2b8;
                          "
                        ></i></span>
                      </div>
                      <select formControlName="ActionStatusId"  class="form-control">
                        <option selected value="1">
                          {{ "-" | translate }}
                        </option>
                        <option
                          *ngFor="let ActionStatus of ActionStatusList"
                          value="{{ ActionStatus.Id }}"
                        >
                          {{ ActionStatus.Name }}
                        </option>
                      </select>
                     </div>
                  
                  </div>
                
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <label for="inputState">{{
                          "Notes" | translate
                        }}</label>
                        <textarea 
                          id="" cols="8" rows="3"
                          class="form-control"
                          formControlName="Notes"
                          placeholder="{{ 'Notes' | translate }}"
                        >
                      </textarea>
                      </div>

                </div>
              </form>
            </div>
          </tab>
        </tabset>
      </div>
    </div>
  </div>
  <div bsModal  #smallModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">
    <div >
        <div class="modal-dialog modal-sm">
            <div class="modal-content" style="width: 600px;">
                <button type="button" class="close pull-right" aria-label="Close" (click)="smallModal.hide()"
                    style="margin-right: 20px; margin-top: 10px; width: 20px; display: inline;">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div class="modal-header" style="display: block; padding: 0px 0px 5px 0px; text-align: center; ">
                    <h1 id="dialog-child-name" class="modal-title">
                        {{currentFormMode+"Statuse" | translate}}</h1>
                </div>
                <div *ngIf="currentScreen === 'Table'" class="FormScreen" class="tableScreen">
                  <div id="divmiddle" style="display: block; margin-top: 5px">
                      <div>
                          <div class="card" style="min-width: 500px !important">
                              <div  class="card-body">
                              <table class="table">
                                <tbody>
                                    <tr>
                                      <label for="inputState">{{
                                        "Name" | translate
                                      }}</label>
                                    </tr>
                                      <tr *ngFor="let obj of ActionStatusList;  let i=index">
                                          <td colspan="2" style="font-weight: bold;">
                                            <div class="col-md-12">
                                              <input
                                                class="form-control"
                                                rows="2"
                                                [(ngModel)]="obj.Name"
                                                [ngModelOptions]="{standalone: true}"
                                              />
                                            </div>
                                          
                                          <td style="text-align: center">
                                              <i
                                                class="simple-icon-close"
                                                (click)="deleteActionStatuse(obj.Id)"
                                                tooltip="{{ 'Delete' | translate }}"
                                                placement="auto"
                                                style="
                                                  font-size: 20px;
                                                  font-weight: bolder;
                                                  cursor: pointer;
                                                  vertical-align: middle;
                                                  color: #dc3545;
                                                "
                                              ></i>
                                          </td>
                                          <td style="text-align: center">
                                            <i
                                              class="simple-icon-plus"
                                              [ngClass]="
                                                !ActionStatusList.invalid
                                                  ? 'simple-icon-plus'
                                                  : 'simple-icon-plus inactive-icon'
                                              "
                                              (click)="
                                                !(
                                                  ActionStatusList.invalid ||
                                                  tableEngine.currentAction === 'loading'
                                                )
                                                  ? AddonClick(obj)
                                                  : ''
                                              "
                                              tooltip="{{ 'Add' | translate }}"
                                              style="
                                                font-size: 20px;
                                                font-weight: bolder;

                                                cursor: pointer;
                                                color: #17a2b8;
                                              "
                                            ></i>
                                          </td>
                                      </tr>
                                      
                                  </tbody>
                                
                              </table>
                             
                          </div>
                      </div>
                      </div>
                  </div>
                  
                
              </div>
                <div class="modal-body">
                    <form class="md-float-material form-material" [formGroup]="ActionStatusForm">
                        <div style="margin-left: 5px; text-align: center; margin-top: 30px;">
                            <button   class="btn btn-secondary" (click)="SaveActionStatuse()"
                                style="font-weight: bold; width: 100%; text-align: center;">{{"Save" |
                                translate}}</button>
                            <button *ngIf="currentFormMode==='Edit'"
                                [disabled]="ActionStatusForm.invalid || currentAction==='loading'" id="sideModalRefSubmitButton"
                                class="btn btn-secondary" (click)="editActionStatus()"
                                style="font-weight: bold; width: 100%; text-align: center;">{{"Edit"
                                | translate}}</button>
                            <button class="btn btn-light" (click)="smallModal.hide()"
                                style="font-weight: bold; width: 100%; text-align: center; margin-top: 10px;">{{"Close" |
                                translate}}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    
  </div>
  <div bsModal  #smallModalActions="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">
    
    <div >
        <div class="modal-dialog modal-sm">
            <div class="modal-content" style="width: 600px;">
                <button type="button" class="close pull-right" aria-label="Close" (click)="smallModalActions.hide()"
                    style="margin-right: 20px; margin-top: 10px; width: 20px; display: inline;">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div class="modal-header" style="display: block; padding: 0px 0px 5px 0px; text-align: center; ">
                    <h1 id="dialog-child-name" class="modal-title">
                        {{currentFormMode+"Attachment" | translate}}</h1>
                </div>
                <div *ngIf="currentScreen === 'Table'" class="FormScreen" class="tableScreen">
                  <div id="divmiddle" style="display: block; margin-top: 5px">
                      <div>
                          <div class="card" style="min-width: 500px !important">
                              <div  class="card-body">
                              <table class="table">
                                <tbody>
                                    <tr>
                                      <label for="inputState">{{
                                        "Name" | translate
                                      }}</label>
                                    </tr>
                                      <tr *ngFor="let obj2 of ActionList;  let i=index">
                                          <td colspan="2" style="font-weight: bold;">
                                            <div class="col-md-12">
                                              <input
                                                class="form-control"
                                                rows="2"
                                                [(ngModel)]="obj2.Name"
                                                [ngModelOptions]="{standalone: true}"
                                              />
                                            </div>
                                          
                                          <td style="text-align: center">
                                              <i 
                                                class="simple-icon-close"
                                                (click)="deleteActions(obj2.Id)"
                                                tooltip="{{ 'Delete' | translate }}"
                                                placement="auto"
                                                style="
                                                  font-size: 20px;
                                                  font-weight: bolder;
                                                  cursor: pointer;
                                                  vertical-align: middle;
                                                  color: #dc3545;
                                                "
                                              ></i>
                                          </td>
                                          <td style="text-align: center">
                                            <i
                                              class="simple-icon-plus"
                                              [ngClass]="
                                                !ActionStatusList.invalid
                                                  ? 'simple-icon-plus'
                                                  : 'simple-icon-plus inactive-icon'
                                              "
                                              (click)="
                                                !(
                                                  ActionStatusList.invalid ||
                                                  tableEngine.currentAction === 'loading'
                                                )
                                                  ? AddActionsClick(obj2)
                                                  : ''
                                              "
                                              tooltip="{{ 'Add' | translate }}"
                                              style="
                                                font-size: 20px;
                                                font-weight: bolder;

                                                cursor: pointer;
                                                color: #17a2b8;
                                              "
                                            ></i>
                                          </td>
                                      </tr>
                                      
                                  </tbody>
                                
                              </table>
                             
                          </div>
                      </div>
                      </div>
                  </div>
                  
                
              </div>
                <div class="modal-body">
                    <form class="md-float-material form-material" [formGroup]="ActionForm">
                        <div style="margin-left: 5px; text-align: center; margin-top: 30px;">
                            <button   class="btn btn-secondary" (click)="SaveActions()"
                                style="font-weight: bold; width: 100%; text-align: center;">{{"Save" |
                                translate}}</button>
                            <button *ngIf="currentFormMode==='Edit'"
                                [disabled]="ActionForm.invalid || currentAction==='loading'" id="sideModalRefSubmitButton"
                                class="btn btn-secondary" (click)="editActions()"
                                style="font-weight: bold; width: 100%; text-align: center;">{{"Edit"
                                | translate}}</button>
                            <button class="btn btn-light" (click)="smallModalActions.hide()"
                                style="font-weight: bold; width: 100%; text-align: center; margin-top: 10px;">{{"Close" |
                                translate}}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    
  </div>
    <div bsModal #delModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">
        <div class="modal-dialog modal-sm">
            <div class="modal-content" style="border: 3px solid red;">
                <button type="button" class="close pull-right" aria-label="Close" (click)="delModal.hide()"
                    style="margin-right: 20px; margin-left: 20px; margin-top: 10px; width: 20px; display: inline;">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div class="modal-header" style="display: inline; padding: 0px 0px 5px 0px;">
                    <h4 id="dialog-child-name" class="modal-title pull-left" style="text-align: center; color: red;">
                        {{"ConfirmDel"+translationName |
                        translate}}</h4>
                </div>
                <div class="modal-body">
                    <div class="form-group form-primary" *ngIf="deletionList.length < actionStatusOgData.length || deletionList.length === 1" style="text-align: center;">
                        <div *ngFor="let item of deletionList;" style="text-align: center;">
                            <label type="text">- {{item.Name}}</label>
                            <span class="form-bar"></span>
                        </div>
                    </div>
                    <div class="form-group form-primary" style="text-align: center;" *ngIf="deletionList.length > 1 && 
                        deletionList.length === actionStatusOgData.length">
                        <label type="text">
                            <h1 style="font-weight: bolder; text-align: center;">{{translationName+"DeleteAll" | translate}}</h1>
                        </label>
                        <span class="form-bar"></span>
                    </div>
    
                    <div  *ngFor="let item of deletionList;" style="margin-left: 5px; text-align: center; margin-top: 30px;">
                        <button class="btn btn-danger" id="sideModalRefDeleteButton" (click)="deleteActionStatuse(item.Id)"
                            [disabled]="currentAction==='loading'"
                            style="font-weight: bold; width: 100%; text-align: center;">{{"Delete" | translate}}</button>
                        <button class="btn btn-light" (click)="delModal.hide()"
                            style="font-weight: bold; width: 100%; text-align: center; margin-top: 10px;">{{"Close" |
                            translate}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  <td></td>
  <td></td>
  <td></td>
   