import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { QuotationModel } from "./QuotationModel";
import { QuotationDetailsModel } from './QuotationDetailsModel';

@Injectable({
  providedIn: 'root'
})
export class QuotationService {
    constructor(private http: HttpClient) {
  
    }

    debugData: QuotationDetailsModel [] = [
      {
        Id:1, Code:1001, Date:new Date(2021, 0, 13),
        ClientId:1, ClientNameAr:'احمد', ClientNameEn:'Ahmad', ShippingAddress1: "address11", ShippingAddress2:"adderss12",
        Description:"desc1\nquotation1\n1",
        Items: [
          { Id:1, QuotationId: 1, ItemId:1, ItemNameAr: "منتج 1", ItemNameEn: "Item 1", 
          UnitId:1, UnitNameAr:"وحدة 1", UnitNameEn:"Unit 1", Description:"item\ndescription\n1", 
          UnitCost:1000, Quantity:1, TotalPrice:1000, DiscountValue:1, DiscountIsRate:false, NetPrice:999,
          Taxes:[
            { Id:1, QuotationItemId: 1, TaxId: 1, TaxNameAr: "ضريبة 1", TaxNameEn: "Tax 1", TaxIsRate:true, TaxValue:5 },
            { Id:2, QuotationItemId: 1, TaxId: 2, TaxNameAr: "ضريبة 2", TaxNameEn: "Tax 2", TaxIsRate:false, TaxValue:200 },
          ],
          TotalTaxValues:249.95, GrossPrice: 1248.95 },

          { Id:2, QuotationId: 1, ItemId:2, ItemNameAr: "منتج 2", ItemNameEn: "Item 2", 
          UnitId:2, UnitNameAr:"وحدة 2", UnitNameEn:"Unit 2", Description:"item\ndescription\n2", 
          UnitCost:1000, Quantity:2, TotalPrice:2000, DiscountValue:2, DiscountIsRate:true, NetPrice:1960,
          Taxes:[
            { Id:3, QuotationItemId: 2, TaxId: 2, TaxNameAr: "ضريبة 2", TaxNameEn: "Tax 2", TaxIsRate:false, TaxValue:200 },
            { Id:4, QuotationItemId: 2, TaxId: 3, TaxNameAr: "ضريبة 3", TaxNameEn: "Tax 3", TaxIsRate:true, TaxValue:10 },
          ],
          TotalTaxValues:396, GrossPrice: 2356 },
        ],
        TotalItemPrices: 3000, TotalItemDiscounts: 41, TotalItemNetPrices: 2959, TotalItemTaxes: 645.95,
        TotalItemGrossPrices: 3604.95,
        Services: [
          { Id: 1, QuotationId: 1, NameAr: "خدمة تحويل فودافون كاش", NameEn:"Vodafone Cash fee", Value: 5, IsRate: false },
          { Id: 2, QuotationId: 1, NameAr: "كارتة طريق الرحاب", NameEn:"Al-Rihab road toll", Value: 200, IsRate: false },
        ],
        TotalServices: 205, DiscountValue:100, DiscountIsRate: false, ShippingFees: 1000,
        TotalQuotationValue: 4109.95,
        Files: [
          {Id: 1, QuotationId: 1, FileUrl: "ID-Card.pdf"},
          {Id: 2, QuotationId: 1, FileUrl: "Vodafone-Cash-Transfer-Image.jpg"},
        ],
      },

      {
        Id:2, Code:1002, Date:new Date(2022, 1, 13),
        ClientId:2, ClientNameAr:'فواز', ClientNameEn:'Fawaz', ShippingAddress1: "address21", ShippingAddress2:"adderss22",
        Description:"desc2\nquotation2\n2",
        Items: [
          { Id:3, QuotationId: 2, ItemId:2, ItemNameAr: "منتج 2", ItemNameEn: "Item 2", 
          UnitId:2, UnitNameAr:"وحدة 2", UnitNameEn:"Unit 2", Description:"item\ndescription\n3", 
          UnitCost:1000, Quantity:3, TotalPrice:3000, DiscountValue:3, DiscountIsRate:true, NetPrice:2910,
          Taxes:[
            { Id:3, QuotationItemId: 3, TaxId: 3, TaxNameAr: "ضريبة 3", TaxNameEn: "Tax 3", TaxIsRate:true, TaxValue:10 },
            { Id:4, QuotationItemId: 3, TaxId: 1, TaxNameAr: "ضريبة 1", TaxNameEn: "Tax 1", TaxIsRate:true, TaxValue:5 },
          ],
          TotalTaxValues:436.5, GrossPrice: 3346.5 },

          { Id:4, QuotationId: 2, ItemId:1, ItemNameAr: "منتج 1", ItemNameEn: "Item 1", 
          UnitId:1, UnitNameAr:"وحدة 1", UnitNameEn:"Unit 1", Description:"item\ndescription\n4", 
          UnitCost:1000, Quantity:4, TotalPrice:4000, DiscountValue:4, DiscountIsRate:false, NetPrice:3996,
          Taxes:[
            { Id:5, QuotationItemId: 4, TaxId: 1, TaxNameAr: "ضريبة 1", TaxNameEn: "Tax 1", TaxIsRate:true, TaxValue:5 },
            { Id:6, QuotationItemId: 4, TaxId: 2, TaxNameAr: "ضريبة 2", TaxNameEn: "Tax 2", TaxIsRate:false, TaxValue:200 },
          ],
          TotalTaxValues:399.8, GrossPrice: 4395.8 },
        ],
        TotalItemPrices: 7000, TotalItemDiscounts: 94, TotalItemNetPrices: 6906, TotalItemTaxes: 836.3,
        TotalItemGrossPrices: 7742.3,
        Services: [
          { Id: 3, QuotationId: 2, NameAr: "غرامة تأخر استلام", NameEn:"Waiting fee", Value: 100, IsRate: false },
        ],
        TotalServices: 100, DiscountValue:200, DiscountIsRate: false, ShippingFees: 2000,
        TotalQuotationValue: 9642.3,
        Files: [
          {Id: 3, QuotationId: 2, FileUrl: "Contract1.pdf"},
          {Id: 4, QuotationId: 2, FileUrl: "Contract2.pdf"},
        ],
      },
      
    ];
    debugMode: boolean = false;

    baseUrl = environment.baseApiUrl;
     token = localStorage.getItem("token");
     httpOptions = {
      headers: new HttpHeaders({
        // 'Content-Type': 'application/json; charset=utf-8',
        // 'Authorization': "Bearer " + token,
         "Authorization": "Bearer " + this.token,
         "Content-Type": "application/json"
      })
    };
    getAll(): Observable<any> {
      if(this.debugMode){
        return of(this.debugData)
      }
      return this.http
        .get<any>(`${this.baseUrl}Quotation/GetAll`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    getById(id:number): Observable<any> {
      if(this.debugMode){
        return of(this.debugData.find(x=>x.Id==id))
      }
      return this.http
        .get<any>(`${this.baseUrl}Quotation/GetById/${id}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    insert(payload: QuotationModel): Observable<any> {
      if(this.debugMode){
        this.debugData.push(payload);
        return of(true)
      }
      return this.http
        .post<any>(`${this.baseUrl}Quotation/Insert`, payload,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
    update(payload: QuotationModel): Observable<any> {
      if(this.debugMode){
        this.debugData[this.debugData.findIndex(x=>x.Id==payload.Id)] = payload;
        return of(true)
      }
      return this.http
        .post<any>(`${this.baseUrl}Quotation/Update`, payload,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
    delete(payload: QuotationModel): Observable<any> {
      if(this.debugMode){
        this.debugData.splice(this.debugData.findIndex(x=>x.Id==payload.Id),1);
        return of(true)
      }
      return this.http
        .get<any>(`${this.baseUrl}Quotation/Delete/${payload.Id}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
    deleteList(payload: QuotationModel []): Observable<any> {
      if(this.debugMode){
        payload.forEach(element => {
          this.debugData.splice(this.debugData.findIndex(x=>x.Id==element.Id),1);
        });
        return of(true)
      }
      return this.http
        .post<any>(`${this.baseUrl}Quotation/DeleteList`,JSON.stringify(payload),this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
}
