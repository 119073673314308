<!--component html goes here -->
<div class="row" *ngIf="mainItemForm">
    <div class="col-12">
      <div class="card mb-4">
        <div class="card-body">
          <!-- <h5 class="float-left">{{ "Create Product" | translate }}</h5> -->
          <div class="top-right-button-container text-zero float-right">
            <button
              class="top-right-button btn btn-primary btn-lg mr-1"
              (click)="goBack()"
            >
              {{ "Back" | translate }}
            </button>
            <button
              type="button"
              (click)="Save()"
              [disabled]="mainItemForm.invalid"
              class="top-right-button btn btn-primary btn-lg mr-1"
            >
              {{ "Save" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div bsModal #delModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">
    <div class="modal-dialog modal-sm">
        <div class="modal-content" style="border: 3px solid red;">
            <button type="button" class="close pull-right" aria-label="Close" (click)="delModal.hide()"
                style="margin-right: 20px; margin-left: 20px; margin-top: 10px; width: 20px; display: inline;">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-header" style="display: inline; padding: 0px 0px 5px 0px;">
                <h4 id="dialog-child-name" class="modal-title pull-left" style="text-align: center; color: red;">
                    {{"ConfirmDel"+translationName |
                    translate}}</h4> 
            </div>
            <div class="modal-body">
                <div class="form-group form-primary" *ngIf="deletionList.length < ogData.length || deletionList.length === 1" style="text-align: center;">
                    <div style="text-align: center;">
                        <label *ngIf="deletionList.length > 10" type="text">{{deletionList.length}} {{"WarehouseRows" | translate}}</label>
                        <label *ngIf="deletionList.length > 2 && deletionList.length < 11" type="text">{{deletionList.length}} {{"WarehouseRowsPlur" | translate}}</label>
                        <label *ngIf="deletionList.length === 2" type="text">{{"TwoWarehouse" | translate}}</label>
                        <label *ngIf="deletionList.length === 1" type="text">{{"OneWarehouse" | translate}}</label>
                        <span class="form-bar"></span>
                    </div>
                </div>
                <div class="form-group form-primary" style="text-align: center;" *ngIf="deletionList.length > 1 && 
                    deletionList.length === ogData.length">
                    <label type="text">
                        <h1 style="font-weight: bolder; text-align: center;">{{translationName+"DeleteAll" | translate}}</h1>
                    </label>
                    <span class="form-bar"></span>
                </div>
                
                <div style="margin-left: 5px; text-align: center; margin-top: 30px;">
                    <button class="btn btn-danger" id="smallModalDeleteButton" (click)="AddObject()"
                       
                        style="font-weight: bold; width: 100%; text-align: center;">{{"Delete" | translate}}</button>
                    <button class="btn btn-light" (click)="delModal.hide()"
                        style="font-weight: bold; width: 100%; text-align: center; margin-top: 10px;">{{"Close" |
                        translate}}</button>
                </div>
            </div>
        </div>
    </div>
  </div>
  <div bsModal #smallModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">
    <div class="modal-dialog modal-sm">
        <div class="modal-content" style="border: 3px solid red;">
            <button type="button" class="close pull-right" aria-label="Close" (click)="delModal.hide()"
                style="margin-right: 20px; margin-left: 20px; margin-top: 10px; width: 20px; display: inline;">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-header" style="display: inline; padding: 0px 0px 5px 0px;">
                <h4 id="dialog-child-name" class="modal-title pull-left" style="text-align: center; color: red;">
                    {{"AddItem"|translate}}</h4> 
            </div>
            <div class="modal-body">
        
                <form [formGroup]="mainItemForm" *ngIf="mainItemForm">
                <label for="inputState">{{ "Items" | translate }}</label>
                <select
                   id="inputState"
                   class="form-control"
                   formControlName="ItemId"
                   (change)="setItemQuentity()"
                 >
                  <option selected value="0">
                   {{ "from" | translate }}
                  </option>
                  <option
                    *ngFor="let Item of ProducteList"
                    value="{{ Item.Id }}"
                    >
                    {{Item.Name }}
                 </option>
                </select>
                <label for="inputState">{{ "NumberInProgram" | translate }}</label>
                 <input class="form-control" type="number"  formControlName="QuantityNumberInProgram" readonly> 
                  <label for="inputState">{{ "NumberInStock" | translate }}</label>
                  <input class="form-control" type="number"  formControlName="QuantityNumberInStock">             

                
                <div style="margin-left: 5px; text-align: center; margin-top: 30px;"> 
                  
                    <button class="btn btn-info " id="smallModalDeleteButton" (click)="addproductes()"                       
                        style="font-weight: bold; width: 100%; text-align: center;">{{"Add" | translate}}</button>
                    <button class="btn btn-light" (click)="smallModal.hide()"
                        style="font-weight: bold; width: 100%; text-align: center; margin-top: 10px;">{{"Close" |
                        translate}}</button>
                </div>
            </form>

            </div>
        </div>
    </div>
  </div>
  <div class="row">
  <div class="col-md-12">
    <div class="card">
     <tabset class="card-tabs" [justified]="true">
         <tab heading="{{ 'Productes' | translate }}" class="card-body">    
          <div class="btn-group">
            <button (click)="showAddNewModalRef()">{{ "Add Items" | translate }}</button>
            <button>{{ "Import" | translate }}</button>
            <button>{{ "Export" | translate }}</button>
          </div>
          <!-- <button class="btn btn-primary d-inline-block"
          style="font-weight: bold; text-align: center; padding: 0px; width: 150px; height: 30px;"
          (click)="showAddNewModalRef()">{{"AddItem"|translate}}</button> -->
          <div class="modal-body" *ngIf="tableView" class="FormScreen" class="tableScreen">
            <table class="table" >
              <thead  class="thead-light">
               <tr *ngIf="InventoryItemsList.length > 0">
                <th>{{'Item'|translate}}</th>            
                <th>{{'Unit'|translate}}</th>                 
                <th>{{'NumberInStock'|translate}}</th>
                <th>{{'NumberInProgram'|translate}}</th>
                <th>{{'decrease/excess'|translate}}</th>
                <th></th>
               </tr>
            </thead>
           <tbody>
           <tr *ngFor="let item of InventoryItemsList; let i = index">
             <td style="vertical-align: middle">
                 {{item.ItemName}}
             </td >
             <td style="vertical-align: middle"> 
                 {{item.UnitId}}
             </td>
             <td style="vertical-align: middle">
                 {{item.QuantityNumberInProgram}}
             </td>
             <td style="vertical-align: middle">
                 {{item.QuantityNumberInStock}}
             </td >
             <td style="vertical-align: middle">
                 {{item.QuantityNumberInStock-item.QuantityNumberInProgram}}
             </td>
             <td style="text-align: center; vertical-align: middle">
                <i
                  class="simple-icon-close"
                  (click)="removeItemsFooter(item.Id)"
                  style="
                    font-size: 20px;
                    font-weight: bolder;
                    cursor: pointer;
                    color: #dc3545;
                  "
                ></i>
              </td>
           </tr>
           </tbody>     
           </table>
         </div> 
        </tab>

         </tabset>
    
    </div>
</div>  
</div>