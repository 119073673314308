import { Component, HostBinding, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { of } from 'rxjs';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';


import { InvoceExpensesModel } from '../model/expensesItemModel'
import {PostExpensesItemServes} from '../serves/expenses_item_serves'
import {GetSupplier} from '../serves/suppliers_serves'
import { ActivatedRoute, Router } from '@angular/router';
import { SuppliersModel } from '../model/suppliersModel';
import { ProjectModel } from '../model/projectModel';
import { GetProject } from '../serves/project_serves';
import { GetExpensesCategory } from '../serves/expensesCategory';
import { CountryModel } from '../model/countrymodel';
import { CountryServes } from '../serves/countryServes';
import { DataFilterer } from 'src/app/views/app/aio/shared/DataFilterer';
import { DataSorter } from 'src/app/views/app/aio/shared/DataSorter';
import { DataPaginator } from 'src/app/views/app/aio/shared/DataPaginator';


@Component({
    // moduleId: module.id,
    selector: 'show-suppliers',
    templateUrl: 'show-suppliers.component.html',
    styleUrls: ['show-suppliers.component.scss']
})
export class ShowSuppliersComponent {
  ////////////////////////////////////////////////////////////////////////////
  //  VARYING :
  //  Modify per similar component.
  ////////////////////////////////////////////////////////////////////////////
  constructor( private  expensesCategoryServes: GetExpensesCategory,private countryServes: CountryServes, private router: Router, private GetSupplierServes: GetSupplier,private getProjectServes: GetProject ,private formBuilder: FormBuilder, private modelService: GetSupplier, private renderer: Renderer2,
    private notifications: NotificationsService, private translate: TranslateService) {
  }

  translationName: string = 'Supplier'

  sortableKeys = [];
  searchableKeys = ['NameEn', 'NameAr'];
  expensesTypeList: any[] = [];

  ogData: SuppliersModel [] = [];
  modelObject: SuppliersModel = new SuppliersModel({});
  mainForm: FormGroup = new FormGroup({
    Id: new FormControl(),
    NameEn: new FormControl(),
    NameAr: new FormControl(),
  });

  ngOnInit(): void {

    
  //   this.GetSupplierServes.getAll().subscribe(
  //     item => {
  //         this.expensesTypeList = item;
  //     }
  // );
    this.lang = localStorage.getItem('theme_lang');
    this.itemOrder = { label: 'Expenses', property: this.lang === 'ar-EG' ? 'NameAr' : 'NameEn' };
    this.sortableKeys = [
      { label: 'Expenses', property: this.lang === 'ar-EG' ? 'NameAr' : 'NameEn' },
    ];
    this.loadData();
    //this.dataSorter.sortData('NameEn', this.ogData);
  }

  // createForm(): FormGroup {
  //   return this.formBuilder.group({
  //     Id: [this.modelObject.Id >= 0 ? this.modelObject.Id : 0],
  //     NameEn: [this.modelObject.NameEn, Validators.compose([Validators.required, Validators.minLength(2)])],
  //     NameAr: [this.modelObject.NameAr, Validators.compose([Validators.required, Validators.pattern(/^[\u0621-\u064A]+([\s]*[\u0621-\u064A]+)*$/)])],
  //   });
  // }

  // addObject() {
  //   //prevent multiclick submits. enable again after API response.
  //   this.currentAction = 'loading';

  //   this.modelObject = new InvoceExpensesModel();
  //   this.modelObject.Id = 0;
  //   this.modelObject.NameEn = this.mainForm.getRawValue().NameEn;
  //   this.modelObject.NameAr = this.mainForm.getRawValue().NameAr;
  //   this.modelObject.Date = this.mainForm.getRawValue().Date;
  //   this.modelObject.TotalPrice = this.mainForm.getRawValue().TotalPrice;
  //   this.modelObject.CostOfMerchandise = this.mainForm.getRawValue().CostOfMerchandise;
  //   this.modelObject.SupplierName = this.mainForm.getRawValue().SupplierName;
  //   this.modelObject.ExpensesCategoryName = this.mainForm.getRawValue().ExpensesCategoryName;
  //   this.modelObject.ProjectName = this.mainForm.getRawValue().ProjectName;
  //   this.modelObject.TotalPrice = this.mainForm.getRawValue().TotalPrice;
  //   this.modelObject.CostOfMerchandise = this.mainForm.getRawValue().CostOfMerchandise;
  //   this.executeAddObject();
  // }

  // executeAddObject(){
  //   this.modelService.insert(this.modelObject).subscribe(result => {
  //     if (result === true) {
  //       this.loadData();
  //       this.currentAction = '';
  //       this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyAdded"));
  //       this.smallModal.hide();
  //     }
  //     else {
  //       this.toastError(this.translate.instant("Error"), this.translate.instant("FailedAdd"));
  //     }
  //   })
  // }

  // editObject() {
  //   this.savedPage = this.currentPage;

  //   let tempObj = new InvoceExpensesModel({
  //     Id: this.modelObject.Id,
  //     NameEn: this.mainForm.getRawValue().NameEn,
  //     NameAr: this.mainForm.getRawValue().NameAr,
  //   });
  //   this.executeEditObject(tempObj);
  // }

  // executeEditObject(Object)
  // {
  //   this.modelService.update(Object).subscribe(result => {
  //     if (result === true) {
  //       this.loadData();
  //       this.currentAction = '';
  //       this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyEdited"));

  //       this.smallModal.hide();
  //     }
  //     else {
  //       this.toastError(this.translate.instant("Error"), this.translate.instant("FailedEdit"));
  //     }
  //   })
  // }

  //Deletion methods:
  //Customize if needed.
  //Pass the target object as parameter if the target API can only delete singular objects and not lists.
  deleteObject() {
    this.savedPage = this.currentPage;
    //prevent multiclicks. re enable after API response
    this.currentAction = 'loading';
    this.executeDeleteObject();
  }

  //Same here...
  executeDeleteObject(){
    this.modelService.deleteList(this.deletionList).subscribe(result => {
      if (result === true) {
        this.loadData();
        this.currentAction = '';
        this.finalizeDeletion();
      }
      else {
        this.toastError(this.translate.instant("Error"), this.translate.instant("FailedDelete"));
      }
    })
  }
  ////////////////////////////////////////////////////////////////////////////
  //
  //
  //
  //
  //
  ////////////////////////////////////////////////////////////////////////////
  //  EXCLUSIVE :
  //  Specifically for this component.
  ///////////////////////////////
  CountryList: CountryModel[] = [];

  onClick(){
    this.router.navigate( ['Create-Suppliers', {state: 'Add', id: '0'}]);
}
findByCode(list: any, Id: any): any {
  return list.find(x => x.Id === Id)
}
  ////////////////////////////////////////////////////////////////////////////
  //
  //
  //
  //
  //
  ////////////////////////////////////////////////////////////////////////////
  //  CONSTANT :
  //  Do not change.
  ///////////////////////////////
  lang: string;
  currentFormMode: string = '';
  currentAction: string = '';

  filteredData = [...this.ogData];
  sortedData = [...this.filteredData];
  paginatedData = [...this.filteredData];
  lastId: number = this.ogData.length + 1;

  SelectAllBox: any = false;

  itemsPerPage = 10;
  itemOptionsPerPage = [5, 10, 20];
  selected = [];
  selectAllState = '';
  itemOrder: any;
  orderAsc: boolean = true;

  currentPage = 1;
  savedPage = 0;


  dataFilterer = new DataFilterer();
  dataSorter = new DataSorter();
  dataPaginator = new DataPaginator();

  loadData() {
    //this.sortData();
    
    this.currentAction = 'loading';
    this.countryServes.getAll().subscribe(
      item => {
          this.CountryList = item;
          console.log(this.CountryList);
          this.modelService.getAll().subscribe(
            data => {
              this.ogData = data;
              //////////////////////////////////////
              this.ogData.forEach(
                item=>{
                  item.CitiyName = this.lang==='ar-EG'?this.findByCode(this.CountryList,item.CountryId).NameAr:this.findByCode(this.CountryList,item.CountryId).NameEn
                  // item.ProjectName= this.lang==='ar-EG'?this.findByCode(this.projectList,item.ProjectId).NameAr:this.findByCode(this.projectList,item.ProjectId).NameEn
                  // item.ExpensesCategoryName= this.lang==='ar-EG'?this.findByCode(this.expensesTypeList,item.CategoryId).NameAr:this.findByCode(this.expensesTypeList,item.CategoryId).NameEn
                }
              )
              
      
              this.sortData();
              this.loadSearch();
              this.currentAction = '';
            }
          )
      }
  );
 
  }

  sortData() {
    this.sortedData = this.dataSorter.sortData(this.itemOrder.property, this.ogData);
    this.filterData();
  }

  reverseSortData() {
    this.savedPage = this.currentPage;
    this.sortedData = this.sortedData.slice().reverse();
    this.filterData();
  }

  filterData() {
    this.filteredData = this.dataFilterer.filterData(this.SearchTerm, this.searchableKeys, this.sortedData)
    if (this.savedPage === 0) {
      this.paginateData(this.itemsPerPage, 1);
    }
    else {
      this.paginateData(this.itemsPerPage,
        Math.ceil((this.filteredData.length / this.itemsPerPage)) >= this.savedPage ?
          this.savedPage :
          Math.ceil((this.filteredData.length / this.itemsPerPage))
      );
    }
    this.savedPage = 0;
  }

  paginateData(newItemsPerPage: number, newCurrentPage: number) {
    this.itemsPerPage = newItemsPerPage;
    this.currentPage = newCurrentPage;
    this.paginatedData = this.filteredData.slice((newCurrentPage - 1) * (newItemsPerPage), (newItemsPerPage * newCurrentPage));
  }

  changeSort(newSort: any) {
    if (this.itemOrder.label === newSort.label) {
      this.orderAsc = !this.orderAsc;
      const oldPage = this.currentPage;
      this.reverseSortData()
      this.currentPage = oldPage;
    }
    else {
      this.orderAsc = true;
      this.itemOrder = newSort;
      this.sortData();
    }
  }

  getSort(label: any) {
    return this.sortableKeys.find(i => i.label === label);
  }

  changePage(event: PageChangedEvent): void {
    this.currentPage = event.page;
    this.paginateData(this.itemsPerPage, this.currentPage);
  }

  changeFilter() {
    this.deletionList = [];
    this.selected = [];
    this.selectAllState = '';
    this.filterData();
  }

  selectAll($event): void {
    if ($event.target.checked) {
      this.selected = [...this.filteredData];
    } else {
      this.selected = [];
    }
    this.setSelectAllState();
  }

  setSelectAllState(): void {
    if (this.selected.length >= this.filteredData.length) {
      this.selectAllState = 'checked';
    } else if (this.selected.length !== 0) {
      this.selectAllState = 'indeterminate';
    } else  {
      this.selectAllState = '';
    }
  }

  isSelected(p: any): boolean {
    return this.selected.findIndex(x => x.Id === p.Id) > -1;
  }

  onSelect(item: any): void {
    if (this.isSelected(item)) {
      this.selected = this.selected.filter(x => x.Id !== item.Id);
    } else {
      this.selected.push(item);
    }
    this.setSelectAllState();
  }

  // @ViewChild('smallModal') smallModal: ModalDirective;
   @ViewChild('delModal') delModal: ModalDirective;
  // showAddNewModalRef(): void {
  //   this.currentFormMode = 'Add'
  //   this.modelObject = new InvoceExpensesModel();
  //   this.mainForm = this.createForm();
  //   this.smallModal.show();
  // }

  showEditModalRef(Id: any): void {
    this.currentFormMode = 'Edit'
    this.modelObject = this.ogData.find(i => i.Id === Id);
    of(this.modelObject).subscribe(data => {
      this.modelObject = data;
    })
    // this.mainForm = this.createForm();
    // this.smallModal.show();
  }

  // showInfoModalRef(Id: any): void {
  //   this.currentFormMode = 'Info'
  //   this.modelObject = this.ogData.find(i => i.Id === Id);
  //   of(this.modelObject).subscribe(data => {
  //     this.modelObject = data;
  //   })
  //   this.mainForm = this.createForm();
  //   this.smallModal.show();
  // }

  deletionList: InvoceExpensesModel[] = [];
  showDelModal(Id: any): void {
    if (Id >= 0) {
      this.currentFormMode = 'Del'
      this.modelObject = this.ogData.find(i => i.Id === Id);
      this.deletionList = [this.ogData.find(i => i.Id === Id)];
      this.delModal.show();
    }
    else if (Id === 'delMulti') {
      this.deletionList = [...this.selected]
      this.delModal.show();
    }
  }

  SearchTerm: any = '';


  finalizeDeletion(){
    if (this.deletionList.length === 1) {
      this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyDeleted") + " " +
        this.translate.instant("One" + this.translationName));
    }
    else if (this.deletionList.length === 2) {
      this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyDeleted") + " " +
        this.translate.instant("Two" + this.translationName + "s"));
    }
    else if (this.deletionList.length > 2 && this.deletionList.length < 11) {
      this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyDeleted") + " " +
        this.deletionList.length + " " + this.translate.instant(this.translationName + "RowsPlur"));
    }
    else {
      this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyDeleted") + " " +
        this.deletionList.length + " " + this.translate.instant(this.translationName + "Rows"));
    }

    this.deletionList = [];
    this.selected = [];

    this.setSelectAllState();
    if (this.selectAllState === '') {
      this.SelectAllBox = false;
    }
    this.delModal.hide();
  }

  toastSuccess(toastHeader: string, toastBody: string): void {
    this.notifications.create(toastHeader, toastBody,
      NotificationType.Success, { timeOut: 3000, showProgressBar: true });
  }
  toastError(toastHeader: string, toastBody: string): void {
    this.notifications.create(toastHeader, toastBody,
      NotificationType.Error, { timeOut: 3000, showProgressBar: true });
  }
  deleteMultiTaxTypes() {
    this.loadData();
    this.delModal.hide();
  }

  loadAllData() {
    this.modelService.getAll().subscribe(
      data => {
        this.ogData = data;
        this.loadSearch();
      }
    )
  }

  loadSearch() {
    const prevSearch = this.SearchTerm;
    this.SearchTerm = '';
    setTimeout(() => {
      this.SearchTerm = prevSearch;
    }, 1);
  }
  @ViewChild('fileInput') fileInput;
  uploadFile() {
    let formData = new FormData();
    formData.append('upload', this.fileInput.nativeElement.files[0])

    this.modelService.UploadExcel(formData).subscribe(result => {
    
    });
   

  }
}
