<!--component html goes here -->
<app-list-page-header
  *ngIf="tableView"
  [itemOptionsPerPage]="[5, 10, 20]"
  [itemsPerPage]="tableEngine.itemsPerPage"
  [selectAllState]="tableEngine.selectAllState"
  (addNewItem)="showModal(Id, 'Add')"
  [tableEngine]="this.tableEngine"
  (selectAllChange)="tableEngine.selectAll($event)"
  (changeOrderBy)="prepChangeSort($event)"
  (searchKeyUp)="
    tableEngine.filterDataByDateRange(this.SearchFrom, this.SearchTo, 'Date')
  "
  [dateSearch]="false"
>
</app-list-page-header>

<div bsModal #delModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">
  <div class="modal-dialog modal-sm">
      <div class="modal-content" style="border: 3px solid red;">
          <button type="button" class="close pull-right" aria-label="Close" (click)="delModal.hide()"
              style="margin-right: 20px; margin-left: 20px; margin-top: 10px; width: 20px; display: inline;">
              <span aria-hidden="true">&times;</span>
          </button>
          <div class="modal-header" style="display: inline; padding: 0px 0px 5px 0px;">
              <h4 id="dialog-child-name" class="modal-title pull-left" style="text-align: center; color: red;">
                  {{"ConfirmDel"+translationName |
                  translate}}</h4> 
          </div>
          <div class="modal-body">
              <div class="form-group form-primary" *ngIf="deletionList.length < ogData.length || deletionList.length === 1" style="text-align: center;">
                  <div style="text-align: center;">
                    
                      <label *ngIf="deletionList.length > 10" type="text">{{deletionList.length}} {{"WarehouseRows" | translate}}</label>
                      <label *ngIf="deletionList.length > 2 && deletionList.length < 11" type="text">{{deletionList.length}} {{"WarehouseRowsPlur" | translate}}</label>
                      <label *ngIf="deletionList.length === 2" type="text">{{"TwoWarehouse" | translate}}</label>
                      <label *ngIf="deletionList.length === 1" type="text">{{"OneWarehouse" | translate}}</label>
                      <span class="form-bar"></span>
                  </div>
              </div>
              <div class="form-group form-primary" style="text-align: center;" *ngIf="deletionList.length > 1 && 
                  deletionList.length === ogData.length">
                  <label type="text">
                      <h1 style="font-weight: bolder; text-align: center;">{{translationName+"DeleteAll" | translate}}</h1>
                  </label>
                  <span class="form-bar"></span>
              </div>
              
              <div style="margin-left: 5px; text-align: center; margin-top: 30px;">
                  <button class="btn btn-danger" id="smallModalDeleteButton" (click)="deleteObject()"
                     
                      style="font-weight: bold; width: 100%; text-align: center;">{{"Delete" | translate}}</button>
                  <button class="btn btn-light" (click)="delModal.hide()"
                      style="font-weight: bold; width: 100%; text-align: center; margin-top: 10px;">{{"Close" |
                      translate}}</button>
              </div>
          </div>
      </div>
  </div>
</div>
<div *ngIf="tableView" class="FormScreen" class="tableScreen">
 
  <div id="divmiddle" style="display: block; margin-top: 5px">
    <div style="width: 100%">
      <div>
        <div class="card" style="min-width: 900px !important">
          <div class="card-body">
            <table class="table table-hover" style="margin: unset !important">
              <thead class="no-border-top">
                <tr>
                   <th style="text-align: center; padding: 5px">
                  <div class="text-zero" style="display: inline-block">
                    <div class="top-right-button-container2 btn-group" dropdown>
                      <div class="btn btn-primary pl-4 pr-0 check-button">
                        <label
                          class="custom-control custom-checkbox mb-0 d-inline-block"
                          style="width: 10px"
                        >
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            [checked]="tableEngine.selectAllState === 'checked'"
                            [indeterminate]="
                            tableEngine.selectAllState === 'indeterminate'
                            "
                            (change)="tableEngine.selectAll($event)"
                          />
                          <span class="custom-control-label">&nbsp;</span>
                        </label>
                      </div>
                      <button
                        id="button-split"
                        type="button"
                        dropdownToggle
                        style="width: 10px"
                        [ngClass]="
                          tableEngine.selected.length == 0
                            ? 'btn btn-outline-primary dropdown-toggle dropdown-toggle-split'
                            : 'btn btn-primary dropdown-toggle dropdown-toggle-split'
                        "
                        aria-controls="dropdown-split"
                      >
                        <span class="caret"></span>
                      </button>
                      <ul
                        id="dropdown-split"
                        *dropdownMenu
                        class="dropdown-menu dropdown-menu-right"
                        role="menu"
                        aria-labelledby="button-split"
                      >
                        <li role="menuitem">
                          <a class="dropdown-item">
                            <button
                              class="btn btn-danger d-inline-block"
                              style="
                                font-weight: bold;
                                text-align: center;
                                padding: 0px;
                                width: 150px;
                                height: 30px;
                              "
                              (click)="tableEngine.showDelModal('delMulti')"
                              [disabled]="
                                tableEngine.selected.length === 0 ||
                                tableEngine.currentAction === 'loading'
                              "
                            >
                              {{ "DeleteSelected" | translate }}
                            </button></a
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                   </th>
                   <th colspan="2"
                  class="sorticon"
                  (click)="prepChangeSort('Name')"
                  [ngClass]="
                    itemOrder.label === 'Name'
                      ? 'sortkey sorticon'
                      : 'sorticon'
                  "
                >
                  <div
                    [ngClass]="{
                      'iconsminds-up---down':
                        itemOrder.label != 'Name',
                      'iconsminds-down':
                        itemOrder.label === 'Name' &&
                        tableEngine.orderAsc === true,
                      'iconsminds-up':
                        itemOrder.label === 'Name' &&
                        tableEngine.orderAsc === false
                    }"
                  >
                  {{ "Name" | translate }}
                </div>

                   </th>
                   <th colspan="2"
                  class="sorticon"
                  (click)="prepChangeSort('EmployeeType')"
                  [ngClass]="
                    itemOrder.label === 'EmployeeType'
                      ? 'sortkey sorticon'
                      : 'sorticon'
                  "
                >
                  <div
                    [ngClass]="{
                      'iconsminds-up---down': itemOrder.label != 'EmployeeType',
                      'iconsminds-down':
                        itemOrder.label === 'EmployeeType' &&
                        tableEngine.orderAsc === true,
                      'iconsminds-up':
                        itemOrder.label === 'EmployeeType' &&
                        tableEngine.orderAsc === false
                    }"
                  >
                  {{ "EmployeeType" | translate }}

                </div>
                   </th>
                   <th colspan="2"
                  class="sorticon"
                  (click)="prepChangeSort('JobTitelName')"
                  [ngClass]="
                    itemOrder.label === 'JobTitelName'
                      ? 'sortkey sorticon'
                      : 'sorticon'
                  "
                >
                  <div
                    [ngClass]="{
                      'iconsminds-up---down': itemOrder.label != 'JobTitelName',
                      'iconsminds-down':
                        itemOrder.label === 'JobTitelName' &&
                        tableEngine.orderAsc === true,
                      'iconsminds-up':
                        itemOrder.label === 'JobTitelName' &&
                        tableEngine.orderAsc === false
                    }"
                  >
                  {{ "JobTitelName" | translate }}

                </div>
                   </th>

                   <th colspan="2"
                class="sorticon"
                (click)="prepChangeSort('DepartmentName')"
                [ngClass]="
                  itemOrder.label === 'DepartmentName'
                    ? 'sortkey sorticon'
                    : 'sorticon'
                "
              >
                <div
                  [ngClass]="{
                    'iconsminds-up---down': itemOrder.label != 'DepartmentName',
                    'iconsminds-down':
                      itemOrder.label === 'DepartmentName' &&
                      tableEngine.orderAsc === true,
                    'iconsminds-up':
                      itemOrder.label === 'DepartmentName' &&
                      tableEngine.orderAsc === false
                  }"
                >
                {{ "DepartmentName" | translate }}

              </div>
                   </th>

                    <th colspan="2"
              class="sorticon"
              (click)="prepChangeSort('BranchName')"
              [ngClass]="
                itemOrder.label === 'BranchName'
                  ? 'sortkey sorticon'
                  : 'sorticon'
              "
            >
              <div
                [ngClass]="{
                  'iconsminds-up---down': itemOrder.label != 'BranchName',
                  'iconsminds-down':
                    itemOrder.label === 'BranchName' &&
                    tableEngine.orderAsc === true,
                  'iconsminds-up':
                    itemOrder.label === 'BranchName' &&
                    tableEngine.orderAsc === false
                }"
              >
              {{ "BranchName" | translate }}

            </div>
                   </th>
              

                   <th colspan="2"
            class="sorticon"
            (click)="prepChangeSort('Status')"
            [ngClass]="
              itemOrder.label === 'Status'
                ? 'sortkey sorticon'
                : 'sorticon'
            "
          >
            <div
              [ngClass]="{
                'iconsminds-up---down': itemOrder.label != 'Status',
                'iconsminds-down':
                  itemOrder.label === 'Status' &&
                  tableEngine.orderAsc === true,
                'iconsminds-up':
                  itemOrder.label === 'Status' &&
                  tableEngine.orderAsc === false
              }"
            >
            {{ "Status" | translate }}

          </div>
                   </th>
        
                   <th colspan="2"
                  
                  style="
                    text-align: center;
                    padding: unset !important;
                    vertical-align: middle;
                  "
                >
                  <div
                    id="displayOptions"
                    style="
                      display: inline;
                      margin-top: 10px;
                      margin-left: 5px;
                      margin-right: 5px;
                    "
                  >
                    <span
                      class="text-muted text-small"
                      style="width: 50px; height: 40px"
                    >
                      {{ "ResultCount" | translate }}
                    </span>
                    <span dropdown class="d-inline-block position-relative">
                      <button
                        class="btn btn-outline-dark btn-xs dropdown-toggle"
                        type="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        dropdownToggle
                      >
                        {{ tableEngine.itemsPerPage }}
                      </button>
                      <div
                        class="dropdown-menu dropdown-menu-right dropdown-menu-sm text-right"
                        *dropdownMenu
                      >
                        <li
                          role="menuitem"
                          *ngFor="let item of tableEngine.itemOptionsPerPage"
                        >
                          <a
                            [ngClass]="
                              item === tableEngine.itemsPerPage
                                ? 'dropdown-item active'
                                : 'dropdown-item'
                            "
                            (click)="tableEngine.paginateData(item, 1)"
                            >{{ item }}</a
                          >
                        </li>
                      </div>
                    </span>
                  </div>
                   </th>
              </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let obj of tableEngine.paginatedData; let i = index"
                >
                  <td style="text-align: center; padding: 5px">
                    <div
                      class="itemCheck mb-0 custom-checkbox custom-control"
                      style="display: inline-block"
                    >
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        [id]="'customCheck' + i"
                        [checked]="tableEngine.isSelected(obj)"
                        (change)="tableEngine.onSelect(obj)"
                      />
                      <label
                        class="custom-control-label"
                        [for]="'customCheck' + i"
                      ></label>
                    </div>
                  </td>
                
                  <td colspan="2">
                    <div>
                      {{obj.Name}}
                    </div>
                    <div>
                      {{obj.Code}}#
                    </div>
                  </td>
                  <td colspan="2">{{obj.EmployeeType}}</td>
                  <td colspan="2">{{obj.JobTitelName}}</td>
                  <td colspan="2">{{obj.DepartmentName}}</td>
                  <td colspan="2">{{obj.BranchName}}</td>
                  <td colspan="2">
                    <span class="badge badge-pill badge-info mb-1" *ngIf="obj.Status">{{'Active' | translate}}</span>
                    <span class="badge badge-pill badge-danger mb-1" *ngIf="!obj.Status"> {{'Inactive' | translate}}</span>
                  </td>
                 
                  
                  <td style="text-align: center">
                    <i
                      class="simple-icon-info"
                      (click)="showModal(obj.Id, 'Info')"                      
                      tooltip="{{ 'Info' | translate }}"
                      placement="auto"
                      style="
                        font-size: 20px;
                        font-weight: bolder;
                        cursor: pointer;
                        vertical-align: middle;
                        color: #17a2b8;
                      "
                      
                     
                    ></i>
                  </td>
                  <td style="text-align: center">
                    <i
                      class="simple-icon-pencil"
                      (click)="showModal(obj.Id, 'Edit')"
                      tooltip="{{ 'Edit' | translate }}"
                      placement="auto"
                      style="
                        font-size: 20px;
                        font-weight: bolder;
                        cursor: pointer;
                        vertical-align: middle;
                        color: #ffc107;
                      "
                     
                     
                    ></i>
                  </td>
                  <td style="text-align: center">
                    <i
                      class="simple-icon-close"
                      (click)="tableEngine.showDelModal(obj.Id)"
                      tooltip="{{ 'Delete' | translate }}"
                      placement="auto"
                      style="
                        font-size: 20px;
                        font-weight: bolder;
                        cursor: pointer;
                        vertical-align: middle;
                        color: #dc3545;
                      "
                    ></i>
                  </td>
                  <!-- <td>   <button class="btn btn-outline-warning text-center" (click)="showInfoModalRef(obj.Id)"
                                            [disabled]="currentAction==='loading'"
                                            style="font-weight: bold; width: 100%;">{{"Edit"
                                            | translate}}</button></td>
                            <td><button class="btn btn-outline-danger text-center" (click)="showDelModal(obj.Id)"
                                    [disabled]="currentAction==='loading'" style="font-weight: bold; width: 100%">{{"Delete"
                                    | translate}}</button></td> -->
                </tr>
              </tbody>
            </table>
            <div
              id="divsearchmiddle"
              style="display: inline-block; width: 100%; height: 100%"
            >
              <div style="text-align: center">
                <!-- <div class="centerside" style="display: inline-block; width: 15%; height: 100%; ">
                            &nbsp;

                        </div> -->
                <div
                  class="centercenter"
                  style="
                    display: inline-block;
                    width: 70%;
                    height: 100%;
                    text-align: center;
                  "
                >
                  <bdo dir="ltr">
                    <pagination
                      class="pagination-sm2"
                      style="
                        padding: 0px, 0px, 0px, 0px;
                        margin: 0px, 0px, 0px, 0px;
                        display: inline-block;
                      "
                      [(ngModel)]="currentPage"
                      [boundaryLinks]="true"
                      [totalItems]="tableEngine.filteredData.length"
                      [itemsPerPage]="tableEngine.itemsPerPage"
                      [customFirstTemplate]="firstTemplate"
                      [customLastTemplate]="lastTemplate"
                      [customPreviousTemplate]="prevTemplate"
                      [customNextTemplate]="nextTemplate"
                      [maxSize]="5"
                      (pageChanged)="tableEngine.changePage($event)"
                    >
                    </pagination>
                  </bdo>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div></div>

<ng-template
  #nextTemplate
  let-disabled="disabled"
  let-currentPage="currentPage"
>
  <i *ngIf="lang === 'en-US'" class="simple-icon-arrow-right"></i>
  <i *ngIf="lang === 'ar-EG'" class="simple-icon-arrow-left"></i>
</ng-template>

<ng-template
  #prevTemplate
  let-disabled="disabled"
  let-currentPage="currentPage"
>
  <i *ngIf="lang === 'en-US'" class="simple-icon-arrow-left"></i>
  <i *ngIf="lang === 'ar-EG'" class="simple-icon-arrow-right"></i>
</ng-template>

<ng-template
  #lastTemplate
  let-disabled="disabled"
  let-currentPage="currentPage"
>
  <i class="simple-icon-control-end"></i>
</ng-template>

<ng-template
  #firstTemplate
  let-disabled="disabled"
  let-currentPage="currentPage"
>
  <i class="simple-icon-control-start"></i>
</ng-template>

<simple-notifications></simple-notifications>
