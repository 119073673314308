
export class ContractorWorkItemModel {
    Id?: number;
    ContractorId?: number;
    ContractorAr?: string;
    ContractorEn?: string;
    WorkItemId?: number;
    NameAr?: string;
    NameEn?: string;
    ParentId?: number;
    ParentAr?: string;
    ParentEn?: string;
    Level?: number;


    constructor(obj?: ContractorWorkItemModel) {
        obj = obj ? obj : {};
        this.Id = obj.Id ? obj.Id : 0;
        this.ContractorId = obj.ContractorId ? obj.ContractorId : 0;
        this.ContractorAr = obj.ContractorAr ? obj.ContractorAr : '';
        this.ContractorEn = obj.ContractorEn ? obj.ContractorEn : '';
        this.WorkItemId = obj.WorkItemId ? obj.WorkItemId : 0;
        this.NameAr = obj.NameAr ? obj.NameAr : '';
        this.NameEn = obj.NameEn ? obj.NameEn : '';
        this.ParentId = obj.ParentId ? obj.ParentId : 0;
        this.ParentAr = obj.ParentAr ? obj.ParentAr : '';
        this.ParentEn = obj.ParentEn ? obj.ParentEn : '';
        this.Level = obj.Level ? obj.Level : 0;
    }

}