export class PurchaseQuotationServiceModel {
    Id?: number;
    Code?: number;
    PurchaseQuotationId?: number;
    PurchaseQuotationCode?: number;
    NameAr?: string;
    NameEn?: string;
    Value?: number;
    IsRate?: boolean;
    constructor(obj?: PurchaseQuotationServiceModel) {
        obj = obj ? obj : {};
        this.Id = obj.Id ? obj.Id : 0;
        this.Code = obj.Code ? obj.Code : 0;
        this.PurchaseQuotationId = obj.PurchaseQuotationId ? obj.PurchaseQuotationId : 0;
        this.PurchaseQuotationCode = obj.PurchaseQuotationCode ? obj.PurchaseQuotationCode : 0;
        this.NameAr = obj.NameAr ? obj.NameAr : '';
        this.NameEn = obj.NameEn ? obj.NameEn : '';
        this.Value = obj.Value ? obj.Value : 0;
        this.IsRate = obj.IsRate ? obj.IsRate : false;
    }

}