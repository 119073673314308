<!--component html goes here -->
<!--component html goes here -->
<div class="row mb-12">
    <div class="col-md-12 col-lg-12 col-12 mb-12">
      <div class="card"></div>
    </div>
  </div>
  <div class="row" *ngIf="mainForm">
    <div class="col-12">
      <div class="card mb-4">
        <div class="card-body">
          <h5 class="float-left">{{ "CreateEmployee" | translate }}</h5>
          <div class="top-right-button-container text-zero float-right">
            <button
              class="top-right-button btn btn-primary btn-lg mr-1"
              (click)="goBack()"
            >
              {{ "Back" | translate }}
            </button>
            <button
              type="button"
              (click)="Save()"
              [disabled]="mainForm.invalid"
              class="top-right-button btn btn-primary btn-lg mr-1"
            >
              {{ "Save" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div></div>
<div class="row">
    <div class="col-md-12">
  <!-- <div class="col-md-12 col-lg-12 col-12 mb-12"> -->
    <div class="card">
      <tabset class="card-tabs" [justified]="true">
        <tab style="font-weight: bold; " heading="{{ 'GeneralInformation' | translate }}" class="card-body">
          <!-- <h6 class="mb-4">{{ "Basic Data" | translate }}</h6> -->
          <div class="modal-body">
            <form
              class="md-float-material form-material"
              [formGroup]="mainForm"
            >
              <div class="row">
                <div class="col-md-4">
                    <label for="inputState">{{ "FirstName" | translate }}</label>
                    <span class="required">*</span>
                    <input
                      class="form-control"
                      formControlName="Name"
                      rows="2"
                      placeholder="{{ 'FirstName' | translate }}"
                    />
                    <div
                    class="messages text-danger"
                    *ngIf="
                      (mainForm.get('Name').hasError('required') ||
                        mainForm.get('Name').invalid) &&
                      (mainForm.get('Name').dirty ||
                        mainForm.get('Name').touched)
                    "
                  >
                    {{ "FirstNameRequired" | translate }}
                  </div>
                  </div>
                  

                  <div class="col-md-4">
                    <label for="inputState">{{ "Nickname" | translate }}</label>
                    <input
                      class="form-control"
                      formControlName="Nickname"
                      rows="2"
                      placeholder="{{ 'Nickname' | translate }}"
                    />
                   
                  </div>

                  <div class="col-md-4">
                    <label for="inputState">{{ "MiddleName" | translate }}</label>
                    <input
                      class="form-control"
                      formControlName="MiddleName"
                      rows="2"
                      placeholder="{{ 'MiddleName' | translate }}"
                    />
                   
                  </div>
               
              </div>
              <div class="row">
                <div class="col-md-6">
                    <!-- <label for="inputState">{{ "Image" | translate }}</label>
                    <img src="" alt=""> -->
                   
                  </div>
                 <div class="col-md-6">
                  <label for="inputState">{{
                    "Notes" | translate
                  }}</label>
               
                  <textarea formControlName="Notes" rows="3"
                    class="form-control"  placeholder="{{ 'Notes' | translate }}"></textarea>
                 
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                    <label for="inputState">{{ "EmployeeType" | translate }}</label>
                    <input
                      class="form-control"
                      formControlName="EmployeeType"
                      rows="2"
                      placeholder="{{ 'EmployeeType' | translate }}"
                      readonly
                    />
                   
                  </div>
                  <div class="col-md-6">
                    <label for="inputState">{{ "E-Mail" | translate }}</label>
                    <input
                      class="form-control"
                      formControlName="EMail"
                      rows="2"
                      placeholder="{{ 'E-Mail' | translate }}"
                      
                    />
                   
                  </div>
                  
              </div>
              <div class="row">
                <div class="col-12"></div> </div>
              <div class="row">
                <div class="col-md-6">
                   
                        <label for="inputState">{{ "Status" | translate }}
                          <span class="required">*</span>
                        </label>
                        <select
                           id="inputState"
                           class="form-control"
                           formControlName="Status"
                         >
                          <option  [value]="true">
                           {{ "Active" | translate }}
                          </option>
                          <option  [value]="false">
                            {{ "Inactive" | translate }}
                           </option>
                          
                        </select>
                        <div class="messages text-danger"
                        *ngIf=" (mainForm.get('Status').hasError('required') || mainForm.get('Status').invalid) && ( mainForm.get('Status').dirty ||  mainForm.get('Status').touched)">
                        {{"Statusrequired" | translate}}</div>
                </div>
                
              </div>
              <div class="row">
                <div class="col-md-6">
                    <div
                      class="itemCheck mb-0 custom-checkbox custom-control"
                      style="display: inline-block" >
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        [id]="'value'"
                        formControlName="IsAccessToTheSystem"
                      />
                      <label class="custom-control-label"   [for]="'value'"
                        >{{ "IsAccessToTheSystem" | translate }}
                        <span class="required">*</span>
                      </label>
                    </div>
                 </div>

                 <div class="col-md-6">
                    <div
                      class="itemCheck mb-0 custom-checkbox custom-control"
                      style="display: inline-block" >
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        [id]="'value'"
                        formControlName="SendLoginDataByMail"
                      />
                      <label class="custom-control-label"  [for]="'value'"
                        >{{ "SendLoginDataByMail" | translate }}
                        
                      </label>
                    </div>
                 </div>
              </div>


              <div class="row" *ngIf="mainForm.get('IsAccessToTheSystem').value==true">
                <div class="col-md-6">
                    <label for="inputState">{{ "Passowrd" | translate }}</label>
                    <span class="required">*</span>
                    <input
                      class="form-control"
                      formControlName="Name"
                      rows="2"
                      placeholder="{{ 'FirstName' | translate }}"
                    />
                    <div
                    class="messages text-danger"
                    *ngIf="
                      (mainForm.get('Name').hasError('required') ||
                        mainForm.get('Name').invalid) &&
                      (mainForm.get('Name').dirty ||
                        mainForm.get('Name').touched)
                    "
                  >
                    {{ "FirstNameRequired" | translate }}
                  </div>
                  </div>
                  <div class="col-md-6">
                    <label for="inputState">{{ "ConfirmPassowrd" | translate }}</label>
                    <span class="required">*</span>
                    <input
                      class="form-control"
                      formControlName="Name"
                      rows="2"
                      placeholder="{{ 'FirstName' | translate }}"
                    />
                    <div
                    class="messages text-danger"
                    *ngIf="
                      (mainForm.get('Name').hasError('required') ||
                        mainForm.get('Name').invalid) &&
                      (mainForm.get('Name').dirty ||
                        mainForm.get('Name').touched)
                    "
                  >
                    {{ "ConfirmPassowrdRequired" | translate }}
                  </div>
                  </div>

              </div>
             


              <div class="row" *ngIf="mainForm.get('IsAccessToTheSystem').value==true">
                <div class="col-md-6">                   
                    <label for="inputState">{{ "DisplayLanguage" | translate }}
                        <span class="required">*</span>
                    </label>
                    <select
                       id="inputState"
                       class="form-control"
                       formControlName="LanguageId"
                     >
                      <option  [value]="1">
                       {{ "Arabic" | translate }}
                      </option>
                      <option  [value]="2">
                        {{ "English" | translate }}
                       </option>
                      
                    </select>
                    <div class="messages text-danger"
                    *ngIf=" (mainForm.get('Type').hasError('LanguageId') || mainForm.get('LanguageId').invalid) && ( mainForm.get('LanguageId').dirty ||  mainForm.get('LanguageId').touched)">
                    {{"DisplayLanguageRequired" | translate}}</div>
                     </div>
                     <div class="col-md-6">                   
                        <label for="inputState">{{ "JobRole" | translate }}
                            <span class="required">*</span>
                        </label>
                        <select
                           id="inputState"
                           class="form-control"
                           formControlName="JobRoleId"
                         >
                          <option  [value]="1">
                           {{ "Manager" | translate }}
                          </option>
                          <option  [value]="2">
                            {{ "Staff" | translate }}
                           </option>
                          
                        </select>
                        <div class="messages text-danger"
                        *ngIf=" (mainForm.get('JobRoleId').hasError('JobRoleId') || mainForm.get('JobRoleId').invalid) && ( mainForm.get('JobRoleId').dirty ||  mainForm.get('JobRoleId').touched)">
                        {{"JobRoleRequired" | translate}}</div>
                         </div>
              </div>


            </form>
          </div>
        </tab>
      </tabset>
      <tabset class="card-tabs" [justified]="true">
        <tab  heading="{{ 'EmployeeInformation' | translate }}" class="card-body" >
          <!-- <h6 class="mb-4">{{ "Basic Data" | translate }}</h6> -->
          <div class="modal-body">
            <form
              class="md-float-material form-material"
              [formGroup]="mainForm"
            >
            <div class="col-12 mb-4">
               
              
                  <accordion> 
                    <accordion-group style="font-weight: bold; " heading=" &#8645; {{'PersonalInformation' | translate}}" [panelClass]="'border'" [isOpen]="true">
                        <div class="row">                        
                            <div class="col-md-6">
                              <label for="inputState">{{ "BirthDate" | translate }}</label>
                            <span class="required">*</span>
                              <!-- <barcode type="code128b">Hello</barcode> -->
                              <input
                                class="form-control"
                                formControlName="BirthDate"
                                rows="2"
                                placeholder="{{ 'BirthDate' | translate }}"
                                type="date" />
                              <div
                                class="messages text-danger"
                                *ngIf="
                                  (mainForm.get('BirthDate').hasError('required') ||
                                    mainForm.get('BirthDate').invalid) &&
                                  (mainForm.get('BirthDate').dirty ||
                                    mainForm.get('BirthDate').touched)
                                "
                              >
                                {{ "BirthDateRequired" | translate }}
                              </div>
                            </div>
                            <div class="col-md-6">                   
                              <label for="inputState">{{ "Type" | translate }}
                              </label>
                              <select
                                 id="inputState"
                                 class="form-control"
                                 formControlName="Type"
                               >
                                <option  [value]="true">
                                 {{ "male" | translate }}
                                </option>
                                <option  [value]="false">
                                  {{ "female" | translate }}
                                 </option>
                                
                              </select>
                              <div class="messages text-danger"
                              *ngIf=" (mainForm.get('Type').hasError('required') || mainForm.get('Type').invalid) && ( mainForm.get('Type').dirty ||  mainForm.get('Type').touched)">
                              {{"TypeRequired" | translate}}</div>
                               </div>
                          </div>
  
                          <div class="row">                        
                              
                              <div class="col-md-6">                   
                                <label for="inputState">{{ "Country" | translate }}
                                    <span class="required">*</span>
                                </label>
                                <select
                                   id="inputState"
                                   class="form-control"
                                   formControlName="Country"
                                 >
                                 <option selected value="0">
                                  {{ "Country" | translate }}
                                </option>
                                <option
                                  *ngFor="let Country of CountryList"
                                  value="{{ Country.Id }}"
                                >
                                  {{ lang === "ar-EG" ? Country.NameAr : Country.NameEn }}
                                </option>
                                  
                                </select>
                                <div class="messages text-danger"
                                *ngIf=" (mainForm.get('Country').hasError('required') || mainForm.get('Country').invalid) && ( mainForm.get('Country').dirty ||  mainForm.get('Country').touched)">
                                {{"TypeRequired" | translate}}</div>
                                 </div>
                            </div>

                    </accordion-group>
                    <accordion-group style="font-weight: bold; " heading="&#8645; {{'ContactInformation' | translate}} " [panelClass]="'border'" [isOpen]="true">
                        <div class="row">                        
                            <div class="col-md-6">
                              <label for="inputState">{{ "MobileNumber" | translate }}</label>
                              <!-- <barcode type="code128b">Hello</barcode> -->
                              <input
                                class="form-control"
                                formControlName="MobileNumber"
                                rows="2"
                                placeholder="{{ 'MobileNumber' | translate }}"
                                type="MobileNumber" />
                              <div
                                class="messages text-danger"
                                *ngIf="
                                  (mainForm.get('MobileNumber').hasError('required') ||
                                    mainForm.get('MobileNumber').invalid) &&
                                  (mainForm.get('MobileNumber').dirty ||
                                    mainForm.get('MobileNumber').touched)
                                "
                              >
                                {{ "MobileNumberRequired" | translate }}
                              </div>
                            </div>
                            

                            <div class="col-md-6">
                                <label for="inputState">{{ "GroundPhoneNumber" | translate }}</label>
                                <!-- <barcode type="code128b">Hello</barcode> -->
                                <input
                                  class="form-control"
                                  formControlName="GroundPhoneNumber"
                                  rows="2"
                                  placeholder="{{ 'GroundPhoneNumber' | translate }}"
                                  type="GroundPhoneNumber" />
                                <div
                                  class="messages text-danger"
                                  *ngIf="
                                    (mainForm.get('GroundPhoneNumber').hasError('required') ||
                                      mainForm.get('GroundPhoneNumber').invalid) &&
                                    (mainForm.get('GroundPhoneNumber').dirty ||
                                      mainForm.get('GroundPhoneNumber').touched)
                                  "
                                >
                                  {{ "GroundPhoneNumberRequired" | translate }}
                                </div>
                              </div>

                          </div>
                          <div class="row">                        
                            <div class="col-md-6">
                              <label for="inputState">{{ "PersonalEmail" | translate }}</label>
                              <!-- <barcode type="code128b">Hello</barcode> -->
                              <input
                                class="form-control"
                                formControlName="PersonalEmail"
                                rows="2"
                                placeholder="{{ 'PersonalEmail' | translate }}"
                                type="PersonalEmail" />
                              <div
                                class="messages text-danger"
                                *ngIf="
                                  (mainForm.get('PersonalEmail').hasError('required') ||
                                    mainForm.get('PersonalEmail').invalid) &&
                                  (mainForm.get('PersonalEmail').dirty ||
                                    mainForm.get('PersonalEmail').touched)
                                "
                              >
                                {{ "PersonalEmailrRequired" | translate }}
                              </div>
                            </div>                           
                      </div>
                   
                    </accordion-group>
                    <accordion-group style="font-weight: bold;  " heading="&#8645; {{'CurrentAddress' | translate}} " [panelClass]="'border'" [isOpen]="true">
                        <div class="row">                        
                            <div class="col-md-6">
                              <label for="inputState">{{ "FirstAddress" | translate }}</label>
                              <!-- <barcode type="code128b">Hello</barcode> -->
                              <input
                                class="form-control"
                                formControlName="FirstAddress"
                                rows="2"
                                placeholder="{{ 'FirstAddress' | translate }}"
                                type="FirstAddress" />
                              <div
                                class="messages text-danger"
                                *ngIf="
                                  (mainForm.get('FirstAddress').hasError('required') ||
                                    mainForm.get('FirstAddress').invalid) &&
                                  (mainForm.get('FirstAddress').dirty ||
                                    mainForm.get('FirstAddress').touched)
                                "
                              >
                                {{ "FirstAddressRequired" | translate }}
                              </div>
                            </div>
                            

                            <div class="col-md-6">
                                <label for="inputState">{{ "SecondAddress" | translate }}</label>
                                <!-- <barcode type="code128b">Hello</barcode> -->
                                <input
                                  class="form-control"
                                  formControlName="SecondAddress"
                                  rows="2"
                                  placeholder="{{ 'SecondAddress' | translate }}"
                                  type="SecondAddress" />
                                <div
                                  class="messages text-danger"
                                  *ngIf="
                                    (mainForm.get('SecondAddress').hasError('required') ||
                                      mainForm.get('SecondAddress').invalid) &&
                                    (mainForm.get('SecondAddress').dirty ||
                                      mainForm.get('SecondAddress').touched)
                                  "
                                >
                                  {{ "SecondAddressRequired" | translate }}
                                </div>
                              </div>

                          </div>
                          <div class="row">                        
                            <div class="col-md-6">
                              <label for="inputState">{{ "City" | translate }}</label>
                              <!-- <barcode type="code128b">Hello</barcode> -->
                              <input
                                class="form-control"
                                formControlName="City"
                                rows="2"
                                placeholder="{{ 'City' | translate }}"
                                type="City" />
                              <div
                                class="messages text-danger"
                                *ngIf="
                                  (mainForm.get('City').hasError('required') ||
                                    mainForm.get('City').invalid) &&
                                  (mainForm.get('City').dirty ||
                                    mainForm.get('City').touched)
                                "
                              >
                                {{ "CityRequired" | translate }}
                              </div>
                            </div>  
                            <div class="col-md-6">
                                <label for="inputState">{{ "Governorate" | translate }}</label>
                                <!-- <barcode type="code128b">Hello</barcode> -->
                                <input
                                  class="form-control"
                                  formControlName="Governorate"
                                  rows="2"
                                  placeholder="{{ 'Governorate' | translate }}"
                                  type="Governorate" />
                                <div
                                  class="messages text-danger"
                                  *ngIf="
                                    (mainForm.get('Governorate').hasError('required') ||
                                      mainForm.get('Governorate').invalid) &&
                                    (mainForm.get('Governorate').dirty ||
                                      mainForm.get('Governorate').touched)
                                  "
                                >
                                  {{ "GovernorateRequired" | translate }}
                                </div>
                              </div>                          
                      </div>
                      <div class="row">                        
                        <div class="col-md-6">
                          <label for="inputState">{{ "PostalCode" | translate }}</label>
                          <!-- <barcode type="code128b">Hello</barcode> -->
                          <input
                            class="form-control"
                            formControlName="PostalCode"
                            rows="2"
                            placeholder="{{ 'PostalCode' | translate }}"
                            type="PostalCode" />
                          <div
                            class="messages text-danger"
                            *ngIf="
                              (mainForm.get('PostalCode').hasError('required') ||
                                mainForm.get('PostalCode').invalid) &&
                              (mainForm.get('PostalCode').dirty ||
                                mainForm.get('PostalCode').touched)
                            "
                          >
                            {{ "PostalCodeRequired" | translate }}
                          </div>
                        </div>  
                                               
                  </div>
                     
                    </accordion-group>
                    <accordion-group style="font-weight: bold;  " heading="&#8645; {{'PermanentAddress' | translate}} " [panelClass]="'border'" [isOpen]="true">
                        <div class="row">                        
                            <div class="col-md-6">
                              <label for="inputState">{{ "FirstPermanentAddress" | translate }}</label>
                              <!-- <barcode type="code128b">Hello</barcode> -->
                              <input
                                class="form-control"
                                formControlName="FirstPermanentAddress"
                                rows="2"
                                placeholder="{{ 'FirstPermanentAddress' | translate }}"
                                type="FirstPermanentAddress" />
                              <div
                                class="messages text-danger"
                                *ngIf="
                                  (mainForm.get('FirstPermanentAddress').hasError('required') ||
                                    mainForm.get('FirstPermanentAddress').invalid) &&
                                  (mainForm.get('FirstPermanentAddress').dirty ||
                                    mainForm.get('FirstPermanentAddress').touched)
                                "
                              >
                                {{ "FirstPermanentAddressRequired" | translate }}
                              </div>
                            </div>
                            

                            <div class="col-md-6">
                                <label for="inputState">{{ "SecondPermanentAddress" | translate }}</label>
                                <!-- <barcode type="code128b">Hello</barcode> -->
                                <input
                                  class="form-control"
                                  formControlName="SecondPermanentAddress"
                                  rows="2"
                                  placeholder="{{ 'SecondPermanentAddress' | translate }}"
                                  type="SecondPermanentAddress" />
                                <div
                                  class="messages text-danger"
                                  *ngIf="
                                    (mainForm.get('SecondPermanentAddress').hasError('required') ||
                                      mainForm.get('SecondPermanentAddress').invalid) &&
                                    (mainForm.get('SecondPermanentAddress').dirty ||
                                      mainForm.get('SecondPermanentAddress').touched)
                                  "
                                >
                                  {{ "SecondPermanentAddressRequired" | translate }}
                                </div>
                              </div>

                          </div>
                          <div class="row">                        
                            <div class="col-md-6">
                              <label for="inputState">{{ "PermanentCity" | translate }}</label>
                              <!-- <barcode type="code128b">Hello</barcode> -->
                              <input
                                class="form-control"
                                formControlName="PermanentCity"
                                rows="2"
                                placeholder="{{ 'PermanentCity' | translate }}"
                                type="PermanentCity" />
                              <div
                                class="messages text-danger"
                                *ngIf="
                                  (mainForm.get('PermanentCity').hasError('required') ||
                                    mainForm.get('PermanentCity').invalid) &&
                                  (mainForm.get('PermanentCity').dirty ||
                                    mainForm.get('PermanentCity').touched)
                                "
                              >
                                {{ "PermanentCityRequired" | translate }}
                              </div>
                            </div>  
                            <div class="col-md-6">
                                <label for="inputState">{{ "PermanentGovernorate" | translate }}</label>
                                <!-- <barcode type="code128b">Hello</barcode> -->
                                <input
                                  class="form-control"
                                  formControlName="PermanentGovernorate"
                                  rows="2"
                                  placeholder="{{ 'PermanentGovernorate' | translate }}"
                                  type="PermanentGovernorate" />
                                <div
                                  class="messages text-danger"
                                  *ngIf="
                                    (mainForm.get('PermanentGovernorate').hasError('required') ||
                                      mainForm.get('PermanentGovernorate').invalid) &&
                                    (mainForm.get('PermanentGovernorate').dirty ||
                                      mainForm.get('PermanentGovernorate').touched)
                                  "
                                >
                                  {{ "PermanentGovernorateRequired" | translate }}
                                </div>
                              </div>                          
                      </div>
                      <div class="row">                        
                        <div class="col-md-6">
                          <label for="inputState">{{ "PermanentPostalCode" | translate }}</label>
                          <!-- <barcode type="code128b">Hello</barcode> -->
                          <input
                            class="form-control"
                            formControlName="PermanentPostalCode"
                            rows="2"
                            placeholder="{{ 'PermanentPostalCode' | translate }}"
                            type="PermanentPostalCode" />
                          <div
                            class="messages text-danger"
                            *ngIf="
                              (mainForm.get('PermanentPostalCode').hasError('required') ||
                                mainForm.get('PermanentPostalCode').invalid) &&
                              (mainForm.get('PermanentPostalCode').dirty ||
                                mainForm.get('PermanentPostalCode').touched)
                            "
                          >
                            {{ "PermanentPostalCodeRequired" | translate }}
                          </div>
                        </div>  
                                               
                  </div>
                 
                    </accordion-group>

                </accordion>
                <!-- <div class="card d-flex mb-3" >
                    <div class="d-flex flex-grow-1 min-width-zero" role="tab">
                        <a href="javascript:;" class="card-body btn-empty btn-link list-item-heading text-left text-one" (click)="showItemIndex=0">{{'PersonalInformation'|translate}}</a>
                    </div>
                    <div [collapse]="showItemIndex!==0" [isAnimated]="true">
                      <div class="card-body accordion-content pt-0" >
                        
                      </div>
                    </div>
                  </div> -->
              </div>
            </form>
          </div>
        </tab>
      </tabset>

      <tabset class="card-tabs" [justified]="true">
        <tab style="font-weight: bold;  " heading="{{ 'BusinessInformation' | translate }}" class="card-body">
          <!-- <h6 class="mb-4">{{ "Basic Data" | translate }}</h6> -->
          <div class="modal-body">
            <form
              class="md-float-material form-material"
              [formGroup]="mainForm"
            >
            <div class="col-12 mb-4">  
                <accordion>
                <accordion-group style="font-weight: bold;  " heading=" &#8645; {{'JobInformation' | translate}}" [panelClass]="'border'" [isOpen]="true">
                    <div class="row">   
                        <div class="col-md-6">                   
                            <label for="inputState">{{ "JobTitelId" | translate }}
                            </label>
                            <select
                               id="inputState"
                               class="form-control"
                               formControlName="JobTitelId"
                             >
                             <option selected value="0">
                              {{ "JobTitelId" | translate }}
                            </option>
                            <option
                              *ngFor="let JobTitel of JobTitelList"
                              value="{{ JobTitel.Id }}"
                            >
                              {{ JobTitel.Name }}
                            </option>
                              
                            </select>
                            <div class="messages text-danger"
                            *ngIf=" (mainForm.get('JobTitelId').hasError('required') || mainForm.get('JobTitelId').invalid) && ( mainForm.get('JobTitelId').dirty ||  mainForm.get('JobTitelId').touched)">
                            {{"JobTitelIdRequired" | translate}}</div>
                             </div>                     
                       
                        
                             <div class="col-md-6">                   
                                <label for="inputState">{{ "DepartmentId" | translate }}
                                </label>
                                <select
                                   id="inputState"
                                   class="form-control"
                                   formControlName="DepartmentId"
                                 >
                                 <option selected value="0">
                                  {{ "DepartmentId" | translate }}
                                </option>
                                <option
                                  *ngFor="let Department of DepartmentList"
                                  value="{{ Department.Id }}"
                                >
                                  {{ Department.Name }}
                                </option>
                                  
                                </select>
                                <div class="messages text-danger"
                                *ngIf=" (mainForm.get('DepartmentId').hasError('required') || mainForm.get('DepartmentId').invalid) && ( mainForm.get('DepartmentId').dirty ||  mainForm.get('DepartmentId').touched)">
                                {{"DepartmentIdRequired" | translate}}</div>
                                 </div> 
                        

                      </div>
                      <div class="row">   
                        <div class="col-md-6">                   
                            <label for="inputState">{{ "JobTypeId" | translate }}
                            </label>
                            <select
                               id="inputState"
                               class="form-control"
                               formControlName="JobTypeId"
                             >
                             <option selected value="0">
                              {{ "JobTypeId" | translate }}
                            </option>
                            <option
                              *ngFor="let JobType of JobTypeList"
                              value="{{ JobType.Id }}"
                            >
                              {{ JobType.Name }}
                            </option>
                              
                            </select>
                            <div class="messages text-danger"
                            *ngIf=" (mainForm.get('JobTypeId').hasError('required') || mainForm.get('JobTypeId').invalid) && ( mainForm.get('JobTypeId').dirty ||  mainForm.get('JobTypeId').touched)">
                            {{"JobTypeIdRequired" | translate}}</div>
                             </div>                     
                       
                        
                             <div class="col-md-6">                   
                                <label for="inputState">{{ "JobLevelId" | translate }}
                                </label>
                                <select
                                   id="inputState"
                                   class="form-control"
                                   formControlName="JobLevelId"
                                 >
                                 <option selected value="0">
                                  {{ "JobLevelId" | translate }}
                                </option>
                                <option
                                  *ngFor="let JobLevel of JobLevelList"
                                  value="{{ JobLevel.Id }}"
                                >
                                  {{ JobLevel.Name }}
                                </option>
                                  
                                  
                                </select>
                                <div class="messages text-danger"
                                *ngIf=" (mainForm.get('JobLevelId').hasError('required') || mainForm.get('JobLevelId').invalid) && ( mainForm.get('JobLevelId').dirty ||  mainForm.get('JobLevelId').touched)">
                                {{"JobLevelIdRequired" | translate}}</div>
                                 </div> 
                        

                      </div>
                     
                     <div class="row">                        
                    <div class="col-md-6">
                        <label for="inputState">{{ "JoiningDate" | translate }}</label>
                        <span class="required">*</span>
                        <input
                          class="form-control"
                          formControlName="JoiningDate"
                          rows="2"
                          placeholder="{{ 'JoiningDate' | translate }}"
                          type="date"/>
                        <div
                          class="messages text-danger"
                          *ngIf="
                            (mainForm.get('JoiningDate').hasError('required') ||
                              mainForm.get('JoiningDate').invalid) &&
                            (mainForm.get('JoiningDate').dirty ||
                              mainForm.get('JoiningDate').touched)
                          "
                        >
                          {{ "JoiningDateRequired" | translate }}
                        </div>
                      </div>
                      <div class="col-md-6">                   
                        <label for="inputState">{{ "Branch" | translate }}
                            <span class="required">*</span>
                        </label>
                        <select
                           id="inputState"
                           class="form-control"
                           formControlName="BranchId"
                         >
                         <option selected value="0">
                          {{ "BranchId" | translate }}
                        </option>
                        <option
                          *ngFor="let Branch of BranchList"
                          value="{{ Branch.Id }}"
                        >
                          {{ Branch.Name }}
                        </option>
                          
                        </select>
                        <div class="messages text-danger"
                        *ngIf=" (mainForm.get('BranchId').hasError('required') || mainForm.get('BranchId').invalid) && ( mainForm.get('BranchId').dirty ||  mainForm.get('BranchId').touched)">
                        {{"BranchRequired" | translate}}</div>
                         </div> 

                        
                                           
                     </div>
                    <div class="row">
                     <div class="col-md-6">                   
                    <label for="inputState">{{ "AttendanceShift" | translate }}
                    </label>
                    <!-- <select
                       id="inputState"
                       class="form-control"
                       formControlName="AttendanceShift"
                     >
                      <option  [value]="true">
                       {{ "AttendanceShift" | translate }}
                      </option>
                      <option  [value]="false">
                        {{ "AttendanceShift" | translate }}
                       </option>
                       
                    </select>
                    <div class="messages text-danger"
                    *ngIf=" (mainForm.get('AttendanceShiftId').hasError('required') || mainForm.get('AttendanceShiftId').invalid) && ( mainForm.get('AttendanceShiftId').dirty ||  mainForm.get('AttendanceShiftId').touched)">
                    {{"AttendanceShiftRequired" | translate}}</div> -->
                     </div> 
                     <div class="col-md-6">
                          <label>{{"TheBeginningOfTheFiscalYear"|translate}}</label>
                          <div >                   
                        <label for="inputState">{{ "Month" | translate }}
                            <span class="required">*</span>
                        </label>
                        <select
                           id="inputState"
                           class="form-control"
                           formControlName="Month"
                         >
                          <option  [value]="true">
                           {{ "Month" | translate }}
                          </option>
                          <option  [value]="false">
                            {{ "Month" | translate }}
                           </option>
                          
                        </select>
                        <div class="messages text-danger"
                        *ngIf=" (mainForm.get('Month').hasError('required') || mainForm.get('Month').invalid) && ( mainForm.get('Month').dirty ||  mainForm.get('Month').touched)">
                        {{"MonthRequired" | translate}}</div>
                             </div> 
                           
                             <div >                   
                            <label for="inputState">{{ "Day" | translate }}
                            </label>
                            <select
                               id="inputState"
                               class="form-control"
                               formControlName="Day"
                             >
                              <option  [value]="true">
                               {{ "Day" | translate }}
                              </option>
                              <option  [value]="false">
                                {{ "Day" | translate }}
                               </option>
                              
                            </select>
                            <div class="messages text-danger"
                            *ngIf=" (mainForm.get('Day').hasError('required') || mainForm.get('Day').invalid) && ( mainForm.get('Day').dirty ||  mainForm.get('Day').touched)">
                            {{"DayRequired" | translate}}</div>
                             </div> 
                     </div>

                     

                    
                    </div>
                   
           
                </accordion-group>

                <accordion-group style="font-weight: bold;  " heading=" &#8645; {{'AttendanceInformation' | translate}}" [panelClass]="'border'" [isOpen]="true">
              
                    <div class="row">   
                        <div class="col-md-6">                   
                            <label for="inputState">{{ "ReservationShift" | translate }}
                            </label>
                            <select
                               id="inputState"
                               class="form-control"
                               formControlName="AttendanceShiftId"
                             >
                              <option  [value]="true">
                               {{ "ReservationShift" | translate }}
                              </option>
                              <option  [value]="false">
                                {{ "ReservationShift" | translate }}
                               </option>
                              
                            </select>
                            <div class="messages text-danger"
                            *ngIf=" (mainForm.get('AttendanceShiftId').hasError('required') || mainForm.get('AttendanceShiftId').invalid) && ( mainForm.get('AttendanceShiftId').dirty ||  mainForm.get('AttendanceShiftId').touched)">
                            {{"ReservationShiftRequired" | translate}}</div>
                             </div>                     
                       
                        
                             <div class="col-md-6">                   
                                <label for="inputState">{{ "VacationPolicy" | translate }}
                                </label>
                                <select
                                   id="inputState"
                                   class="form-control"
                                   formControlName="VacationPolicyId"
                                 >
                                  <option  [value]="true">
                                   {{ "VacationPolicy" | translate }}
                                  </option>
                                  <option  [value]="false">
                                    {{ "VacationPolicy" | translate }}
                                   </option>
                                  
                                </select>
                                <div class="messages text-danger"
                                *ngIf=" (mainForm.get('VacationPolicyId').hasError('required') || mainForm.get('VacationPolicyId').invalid) && ( mainForm.get('VacationPolicyId').dirty ||  mainForm.get('VacationPolicyId').touched)">
                                {{"VacationPolicyRequired" | translate}}</div>
                                 </div> 
                        

                      </div>
                      <div class="row">   
                        <div class="col-md-6">                   
                            <label for="inputState">{{ "AttendanceDeterminants" | translate }}
                                <span class="required">*</span>
                            </label>
                            <select
                               id="inputState"
                               class="form-control"
                               formControlName="AttendanceDeterminantsId"
                             >
                              <option  [value]="true">
                               {{ "AttendanceDeterminants" | translate }}
                              </option>
                              <option  [value]="false">
                                {{ "AttendanceDeterminants" | translate }}
                               </option>
                              
                            </select>
                            <div class="messages text-danger"
                            *ngIf=" (mainForm.get('AttendanceDeterminantsId').hasError('required') || mainForm.get('AttendanceDeterminantsId').invalid) && ( mainForm.get('AttendanceDeterminantsId').dirty ||  mainForm.get('AttendanceDeterminantsId').touched)">
                            {{"AttendanceDeterminantsRequired" | translate}}</div>
                             </div>                     
                       
                        
                             <div class="col-md-6">                   
                                <label for="inputState">{{ "HolidayLists" | translate }}
                                </label>
                                <!-- <select
                                   id="inputState"
                                   class="form-control"
                                   formControlName="JobLevelId"
                                 >
                                  <option  [value]="true">
                                   {{ "HolidayLists" | translate }}
                                  </option>
                                  <option  [value]="false">
                                    {{ "HolidayLists" | translate }}
                                   </option>
                                  
                                </select> -->
                                <div class="messages text-danger"
                                *ngIf=" (mainForm.get('JobLevelId').hasError('required') || mainForm.get('JobLevelId').invalid) && ( mainForm.get('JobLevelId').dirty ||  mainForm.get('JobLevelId').touched)">
                                {{"HolidayListsRequired" | translate}}</div>
                                 </div> 
                        

                      </div>
                     
                </accordion-group>
            </accordion>
              </div>      
            </form>
          </div>
        </tab>
      </tabset>
    </div>
  </div>
</div>