import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {
  token = '';
  constructor( private router: Router) {
    debugger;
    // this.token = localStorage.getItem('token');
    // if(this.token == '' || this.token == undefined || this.token == null){
    //   this.router.navigate(['user']);
    // }
   }

  ngOnInit(): void {
    //get company name from Api

  }
}
