export class CatchReceiptFilesModel{
    Id?:number;
    CatchReceiptId?:number;
    FileUrl?:string;
    constructor(item ?: CatchReceiptFilesModel){
        item = item ? item : {};
        this.Id = item.Id ? item.Id : 0;
        this.CatchReceiptId = item.CatchReceiptId ? item.CatchReceiptId : 0;
        this.FileUrl = item.FileUrl ? item.FileUrl : '';
    }
}