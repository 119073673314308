<div class="row mb-12">
  <div class="col-md-12 col-lg-12 col-12 mb-12">
    <div class="card"></div>
  </div>
</div>
<div class="row" *ngIf="Form">
  <div class="col-12">
    <div class="card mb-4">
      <div class="card-body">
        <h5 class="float-left">{{ "Create Items" | translate }}</h5>
        <div class="top-right-button-container text-zero float-right">
          <button  class="top-right-button btn btn-primary btn-lg mr-1"
          (click)="goBack()" >
          {{ "Back" | translate }}
        </button>
          <button
            type="button"
            (click)="Save()"
            [disabled]="Form.invalid || FooterModel.length===0"
            class="top-right-button btn btn-primary btn-lg mr-1"
          >
            {{ "Save" | translate }}
          </button>
         
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row mb-12">
  <div class="col-md-12 col-lg-12 col-12 mb-12">
    <div class="card">
      <tabset class="card-tabs" [justified]="true">
        <tab heading="{{ 'Type' | translate }}" class="card-body">
          <!-- <h6 class="mb-4">{{ "Basic Data" | translate }}</h6> -->
          <form *ngIf="Form" [formGroup]="Form">
            <div class="form-group">
              <div class="row">
                <div class="col-md-6">
                  <label for="inputState">{{
                    "Type" | translate
                  }}</label>
                  <select
                    formControlName="BalanceNameId"
                    id="inputState"
                    class="form-control"
                  >
                    <option>
                      {{ "Type" | translate }}
                    </option>

                    <option
                      *ngFor="let item of BalanceDataList"
                      [value]="item.Id"
                    >
                      {{ lang === "ar-EG" ? item.NameAr : item.NameEn }}
                    </option>
                  </select>
                  <div class="messages text-danger"
                  *ngIf=" (Form.get('BalanceNameId').hasError('required') || Form.get('BalanceNameId').invalid) && ( Form.get('BalanceNameId').dirty ||  Form.get('BalanceNameId').touched)">
                  {{"BalanceNameRequired" | translate}}</div>
                </div>
                <div class="col-md-6">
                  <label for="inputState">{{ "StoreData" | translate }}</label>
                  <select
                    formControlName="StoreDataId"
                    id="inputState"
                    class="form-control"
                  >
                    <option>
                      {{ "StoreData" | translate }}
                    </option>
                    <option
                      *ngFor="let item of StoreDataList"
                      [value]="item.Id"
                    >
                      {{ lang === "ar-EG" ? item.NameAr : item.NameEn }}
                    </option>
                  </select>
                  <div class="messages text-danger"
                  *ngIf=" (Form.get('StoreDataId').hasError('required') || Form.get('StoreDataId').invalid) && ( Form.get('StoreDataId').dirty ||  Form.get('StoreDataId').touched)">
                  {{"StoreNameRequired" | translate}}</div>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-md-6">
                  <label for="inputPassword4">{{ "Date" | translate }}</label>
                  <input
                    formControlName="Date"
                    type="date"
                    id="inputPassword4"
                    class="form-control"
                  />
                  <div class="messages text-danger"
                  *ngIf=" (Form.get('Date').hasError('required') || Form.get('Date').invalid) && ( Form.get('Date').dirty ||  Form.get('Date').touched)">
                  {{"DateRequired" | translate}}</div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <label for="inputState">{{
                    "Description" | translate
                  }}</label>
                  <textarea
                    class="form-control"
                    formControlName="Description"
                    rows="2"
                  ></textarea>
                </div>
              </div>

              <!-- <div class="col-12 row">
                <div class="col-md-11"></div>
                <button
                  type="button"
                  class="btn btn-primary"
                  (click)="AddonClick()"
                >
                  {{ "+ Add" | translate }}</button
                >{{ " " }}
              </div> -->

              <br />
            </div>
          </form>
          <div>
          <table class="table">
            <th></th>
              <thead class="thead-light">
                <th>{{"Items" | translate}}</th>
                <th>{{"Cost" | translate}}</th>
                <th>{{"Quantity" | translate}}</th>
                <th>{{"ItemValue" | translate}}</th>
              </thead>
            <tbody>
              <tr *ngFor="let Item of FooterModel;  let i=index">
               
                <!-- <th scope="row">{{ Item.Id }}</th> -->
                <td style="vertical-align: middle;">
                  <select
                    [(ngModel)]="Item.ItemsHeaderId"
                    id="inputState"
                    class="form-control"
                    (change)="setItem(Item)"
                  >
                    <option disabled selected value="0">
                      {{ "Items" | translate }}
                    </option>
                    <option
                      *ngFor="let item of ItemsList"
                      value="{{ item.Id }}"
                    >
                      {{ lang === "ar-EG" ? item.NameAr : item.NameEn }}
                    </option>
                  </select>
                </td>
                <td style="vertical-align: middle;">
                  <input
                    [(ngModel)]="Item.ItemPrice"
                    type="number"
                    class="form-control"
                    readonly
                  />
                </td>
                <td style="vertical-align: middle;">
                  <input
                    [(ngModel)]="Item.ItemQuantity"
                    (change)="calculateTotalItemValue()"
                    type="number"
                    class="form-control"
                  />
                </td>
                <td style="vertical-align: middle;">
                  <input
                    [(ngModel)]="Item.ItemQuantity * Item.ItemPrice"
                    type="number"
                    class="form-control"
                    readonly
                  />
                </td>
                <td style="text-align: center; vertical-align: middle">
                  <i
                    class="simple-icon-close"
                   
                    (click)="removeItemsFooter(Item.Id)"
                    style="
                      font-size: 20px;
                      font-weight: bolder;
                      cursor: pointer;
                      color: #dc3545;
                    "
                  ></i>
                </td>
                <td style="text-align: center; vertical-align: middle;">
                  <i class="simple-icon-plus"
                      [ngClass]="!(FooterModel.invalid)?'simple-icon-plus':'simple-icon-plus inactive-icon'"
                      (click)="!(FooterModel.invalid||tableEngine.currentAction==='loading')?AddonClick():''"
                      style="font-size: 20px; font-weight: bolder; cursor: pointer; color: #17A2B8;"></i>
              </td>
              </tr>
              <tr *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
              type="text" [formGroup]=FooterModel>

              <!-- <td style="text-align: center; vertical-align: middle;">
                  <i class="simple-icon-plus"
                      [ngClass]="!(FooterModel.invalid)?'simple-icon-plus':'simple-icon-plus inactive-icon'"
                      (click)="!(FooterModel.invalid||tableEngine.currentAction==='loading')?AddonClick():''"
                      style="font-size: 20px; font-weight: bolder; cursor: pointer; color: #17A2B8;"></i>
              </td> -->
          </tr>
            </tbody>
          </table>
        </div>
        
          <table class="table">
            <tbody>
              <tr>
                <th scope="row">{{ "TotalValue" | translate }}</th>             
             
              <label
                  style="display: inline-block; margin-left: 8px; margin-right: 8px; min-width: 150px; border: unset; font-size: 25px;"
                  class="form-control" type="text" name="TotalValue">{{TValue>0?TValue:0}}</label>
          
                <!-- <td scope="row" width="25%">
                  <input
                    [(ngModel)]="TValue"
                    type="number"
                    class="form-control"
                    readonly
                  />
                </td> -->
              </tr>
            </tbody>
          </table>
        </tab>
      </tabset>
    </div>
  </div>
</div>
