import { WeekDaysModel } from './models/week-days.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ShiftModel } from './models/shift.model';

@Injectable({
  providedIn: 'root'
})
export class ShiftService {
    constructor(private http: HttpClient) {
  
    }
    baseUrl = environment.baseApiUrl;
     token = localStorage.getItem("token");
     httpOptions = {
      headers: new HttpHeaders({
        // 'Content-Type': 'application/json; charset=utf-8',
        // 'Authorization': "Bearer " + token,
         "Authorization": "Bearer " + this.token,
         "Content-Type": "application/json"
      })
    };
    getAll(): Observable<any> {
   
      return this.http
        .get<any>(`${this.baseUrl}Shift/GetAll `,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    getAllWeekDays(): Observable<WeekDaysModel[]> {
   
      return this.http
        .get<WeekDaysModel[]>(`${this.baseUrl}WeekDays/GetAll `,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    WeekDays
    getById(id:number): Observable<any> {
   
      return this.http
        .get<any>(`${this.baseUrl}Shift/GetById/${id}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    insert(payload: ShiftModel): Observable<any> {
      
      return this.http
        .post<any>(`${this.baseUrl}Shift/Insert `, payload,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
    update(payload: ShiftModel): Observable<any> {
      return this.http
        .post<any>(`${this.baseUrl}Shift/Update`, payload,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
    delete(payload: ShiftModel): Observable<any> {
      return this.http
        .get<any>(`${this.baseUrl}Shift/Delete/${payload.Id}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
    deleteList(payload: ShiftModel []): Observable<any> {
      return this.http
        .post<any>(`${this.baseUrl}Shift/DeleteList`,JSON.stringify(payload),this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
}
