

<div class="row">
    <div class="col-12">
      <div class="card mb-4">
        <div class="card-body">
          <!-- <h5 class="mb-4">{{ 'From Date' | translate }}</h5> -->
          <div class="row">
            <div class="col-12 col-lg-6 mb-4">
              <label><span>{{ 'DateFrom' | translate }}</span></label>
              <input 
              type="text" onfocus="(this.type='date')" onblur="if(!this.value)this.type='text'"
              onchange="if(!this.value)this.type='text'" name="Date" class="form-control"
              [(ngModel)]="search.From"
              placeholder='{{"DateFrom" | translate}}'>
            </div>
            <div class="col-12 col-lg-6 mb-4">
              <label><span>{{ 'DateTo' | translate }}</span></label>
              <input 
              type="text" onfocus="(this.type='date')" onblur="if(!this.value)this.type='text'"
              onchange="if(!this.value)this.type='text'" name="Date" class="form-control"
              [(ngModel)]="search.To"
              placeholder='{{"DateTo" | translate}}'>
            </div>
            <div class="col-12 col-lg-6 mb-4">
              <label><span>{{ 'Branch' | translate }}</span></label>
         
              <ng-select placeholder='{{"Branch" | translate}}' *ngIf="lang==='ar-EG'" [items]="branches" bindLabel="NameAr" bindValue="Id" [(ngModel)]="search.BranchId">
              </ng-select>
              <ng-select placeholder='{{"Branch" | translate}}' *ngIf="lang!='ar-EG'" [items]="branches" bindLabel="NameEn" bindValue="Id" [(ngModel)]="search.BranchId">
              </ng-select>
            </div>
         
            <!-- <div class="col-12 col-lg-6 mb-4">
              <label><span>{{ 'Account' | translate }}</span></label>
              <ng-select placeholder='{{"Account" | translate}}'  *ngIf="lang==='ar-EG'" [items]="accounts" bindLabel="NameAr" bindValue="Id" [(ngModel)]="search.AccountId">
              </ng-select>
              <ng-select placeholder='{{"Account" | translate}}'  *ngIf="lang!='ar-EG'" [items]="accounts" bindLabel="NameEn" bindValue="Id" [(ngModel)]="search.AccountId">
              </ng-select>
            </div> -->
          
            <div class="col-12 col-lg-6 mb-4 "  >
         </div>
            <div class="col-12 col-lg-6 mb-4 "  >
              <button type="button" (click)="Search()" class="btn btn-primary default mb-1" style="margin: 1px;">{{'Search' | translate}}</button>{{" "}}
              <button type="button" (click)="print()" class="btn btn-secondary default mb-1 " style="margin: 1px;">{{'Print' | translate}}</button>{{" "}}
              <button type="button" (click)="exportTable()" class="btn btn-success default mb-1" style="margin: 1px;">{{'PDF' | translate}}</button>{{" "}}
            </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  <div id="contentToConvert">
    <div class="row" >
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-body">
              <h3 class="float-center" style="text-align: center;">{{ 'Customer Account Statement' | translate }}</h3>
              <h4 class="float-center" style="text-align: center;">{{  today| date:'dd/MM/yyyy' }}</h4>
              <h6 class="float-center" style="text-align: center;">{{ 'Future Core' | translate }}</h6>
             
          </div>
        </div>
      </div>
    </div>
    <div class="card " *ngIf="data">
        <div class="card-body ">
            <table class="table table-bordered">
                <thead>
                        <tr>
                            <th rowspan="2" style="width: 8%;">{{'Journal Code'|translate}}</th>
                            <th rowspan="2">{{'Date'|translate}}</th>
                            <th rowspan="2"style="width: 25%;">{{'Description'|translate}}</th>
                            <th colspan="2">{{'The Operation'|translate}}</th>
                            <th colspan="2">{{'Balance'|translate}}</th>

                        </tr>
                        <tr>
                        
                            <th>{{'Credit'|translate}}</th>
                            <th>{{'Debit'|translate}}</th>
                            <th>{{'Credit'|translate}}</th>
                            <th>{{'Debit'|translate}}</th>
                        
                        </tr>
                </thead>
             </table>
        </div>
    </div>
    <br />
  <div class="card mb-12 tree-explorer-side col-md-12" *ngIf="data">
      <div class="card-body ">
      <div class="row" *ngFor="let cost of data">
          <table class="table table-bordered"  *ngIf="cost.Details&&cost.Details.length>0">
            <thead>
                <tr >
                    <th colspan="7" style="font-size: large;">{{lang==='ar-EG'?cost.AccountNameAr:cost.AccountNameEn}}</th>
             
                </tr>
              
            </thead>
    
            <table class="table table-bordered">
              <tr>
                <td colspan="5" style="font-size: small;">{{'Balance Before'|translate}}</td>
                <td style="font-size: small;">{{cost.CreditBalanceBefore}}</td>
                <td style="font-size: small;">{{cost.DebitBalanceBefore}}</td>
              </tr>
            <tbody>
                <tr  *ngFor="let item of cost.Details">
                    <td ><a [routerLink]="[ '../../../accounts/view-daily-restriction', item.JournalId ]"> {{item.JournalCode}}#</a></td>
                    <td >{{item.Date|date:'dd/MM/yyyy'}}</td>
                    <td style="width: 27%;">{{item.Description}}</td>
                    <td >{{item.CreditTransfer}}</td>
                    <td>{{item.DebitTransfer}}</td>
                    <td >{{item.CreditBalance>item.DebitBalance?item.CreditBalance - item.DebitBalance:0}}</td>
                    <td >{{item.DebitBalance>item.CreditBalance? item.DebitBalance - item.CreditBalance : 0}}</td>
                </tr>
                <tr style="font: bold;font-size:medium">
                  <td colspan="3" style="font: bold;">
                    {{'Total'|translate}}
                  </td>
                  <td style="font: bold;">{{cost.TotalCreditTransfer}}</td>
                  <td style="font: bold;">{{cost.TotalDebitTransfer}}</td>
                  <td style="font: bold;">{{cost.TotalCreditBalance > cost.TotalDebitBalance ? cost.TotalCreditBalance - cost.TotalDebitBalance : 0}}</td>
                  <td style="font: bold;">{{cost.TotalDebitBalance > cost.TotalCreditBalance ? cost.TotalDebitBalance - cost.TotalCreditBalance : 0}}</td>
                </tr>
            </tbody>
          </table>
        </table>
        <hr />
      </div>
  
      </div>
    </div>
  </div>