import { CompanyApiModel } from './CompanyApiModel';
import { CompanyApiService } from './CompanyApiService';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-company-api',
  templateUrl: './company-api.component.html',
  styleUrls: ['./company-api.component.scss']
})
export class CompanyApiComponent implements OnInit {

  lang: string = '';
  constructor(private service:CompanyApiService) {
    //service.getAll();
    this.lang = localStorage.getItem('theme_lang');
   }

  ngOnInit(): void {
    this.loadAllData();
  }

  
  currentCompany:CompanyApiModel = new  CompanyApiModel({Id: 0, NameAr: 'Default', NameEn: 'Default', ApisURL: 'defaultURL'});
  companyApiList:CompanyApiModel[] = [];
  companyApiList2: ['1','2','3'];
  displayOptionsCollapsed = false;


  //Use "environment.companyApi" anywhere else within the "invoices" component
  changeCompany(str:CompanyApiModel){
    console.log(str);
    this.currentCompany = str;
    // environment.companyApi = str.ApisURL;
    localStorage.removeItem('CompanyId');
    localStorage.setItem('CompanyId',str.Id.toString());
    localStorage.setItem('CompanyNameAr',str.NameAr.toString());
    localStorage.setItem('CompanyNameEn',str.NameEn.toString());
  }
  loadAllData() {
    this.service.getAll().subscribe(
      data => {
        this.companyApiList = data;
        var comany = localStorage.getItem("CompanyId");
        if(comany == null ||  comany == undefined){
          localStorage.setItem('CompanyId',this.companyApiList[0].Id.toString());
          localStorage.setItem('CompanyNameAr',this.companyApiList[0].NameAr.toString());
          localStorage.setItem('CompanyNameEn',this.companyApiList[0].NameEn.toString());
        }
        console.log(data);
      }
    )
  }
}
