import { ContractorWorkItemModel } from "./ContractorWorkItemModel";

export class ContractorModel{
    Id?:number;
    Code?:string;
    NameAr?:string;
    NameEn?:string;
    IdNumber?:string;
    TaxCard?:string;
    CommercialRegister?:string;
    Nationality?:string;
    Phone?:string;
    BankAccount?:string;
    Description?:string;
    CountryId?:number;
    CountryAr?:string;
    CountryEn?:string;
    CityId?:number;
    CityAr?:string;
    CityEn?:string;
    Governorate?:string;
    Street?:string;
    Landmark?:string;
    BuildingNumber?:string;
    Floor?:string;
    Room?:string;
    ZipCode?:string;
    ContractorWorkItems?:ContractorWorkItemModel[];
    
    constructor(obj?:ContractorModel){
        obj = obj? obj:{};
        this.Id = obj.Id? obj.Id:0;
        this.Code = obj.Code? obj.Code:'';
        this.NameAr = obj.NameAr? obj.NameAr:'';
        this.NameEn = obj.NameEn? obj.NameEn:'';
        this.IdNumber = obj.IdNumber? obj.IdNumber:'';
        this.TaxCard = obj.TaxCard? obj.TaxCard:'';
        this.CommercialRegister = obj.CommercialRegister? obj.CommercialRegister:'';
        this.Nationality = obj.Nationality? obj.Nationality:'';
        this.BankAccount = obj.BankAccount? obj.BankAccount:'';
        this.Phone = obj.Phone? obj.Phone:'';
        this.Description = obj.Description? obj.Description:'';
        this.CountryId = obj.Id? obj.CountryId:null;
        this.CountryAr = obj.NameAr? obj.CountryAr:'';
        this.CountryEn = obj.NameAr? obj.CountryEn:'';
        this.CityId = obj.CityId? obj.CityId:1;
        this.CityAr = obj.NameAr? obj.CityAr:'';
        this.CityEn = obj.NameAr? obj.CityEn:'';
        this.Governorate = obj.Governorate? obj.Governorate:'';
        this.Landmark = obj.Landmark? obj.Landmark:'';
        this.Street = obj.Street? obj.Street:'';
        this.BuildingNumber = obj.BuildingNumber? obj.BuildingNumber:'';
        this.Floor = obj.Floor? obj.Floor:'';
        this.Room = obj.Room? obj.Room:'';
        this.ZipCode = obj.ZipCode? obj.ZipCode:'';
        this.ContractorWorkItems = obj.ContractorWorkItems? obj.ContractorWorkItems:[];
    }

}