import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { FiscalYearModel } from './models/fiscal-year.model'

@Injectable({
  providedIn: 'root'
})
export class FiscalYearService {
  public openYear ;
    constructor(private http: HttpClient) {
      this.GetOpenFiscalYear()
      .subscribe(result=>{
        this.openYear = result;
      })
    }

    baseUrl = environment.baseApiUrl;
     token = localStorage.getItem("token");
     httpOptions = {
      headers: new HttpHeaders({
        // 'Content-Type': 'application/json; charset=utf-8',
        // 'Authorization': "Bearer " + token,
         "Authorization": "Bearer " + this.token,
         "Content-Type": "application/json"
      })
    };
    getAll(): Observable<any> {
   
      return this.http
        .get<any>(`${this.baseUrl}FiscalYear/GetAll`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    GetOpenFiscalYear(): Observable<any> {
   
      return this.http
        .get<any>(`${this.baseUrl}FiscalYear/GetOpenFiscalYear`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    
    getById(id:number): Observable<any> {
   
      return this.http
        .get<any>(`${this.baseUrl}FiscalYear/GetById/${id}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    GetLastCode(): Observable<number> {
      return this.http
        .get<number>(`${this.baseUrl}FiscalYear/GetLastCode`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    
    insert(payload: FiscalYearModel): Observable<any> {
      
      return this.http
        .post<any>(`${this.baseUrl}FiscalYear/Insert`, payload,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
    update(payload: FiscalYearModel): Observable<any> {
      return this.http
        .post<any>(`${this.baseUrl}FiscalYear/Update`, payload,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
    delete(payload: FiscalYearModel): Observable<any> {
        return this.http
        .post<any>(`${this.baseUrl}FiscalYear/Delete`, payload,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
    GetPayedAmount(InvoiceId:number): Observable<any> {
   
      return this.http
        .get<any>(`${this.baseUrl}FiscalYear/GetPayedAmount/${InvoiceId}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    deleteList(payload: FiscalYearModel []): Observable<any> {
      return this.http
        .post<any>(`${this.baseUrl}FiscalYear/DeleteList`,JSON.stringify(payload),this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
}
