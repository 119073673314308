
<div bsModal #sessionOrdersModal="bs-modal" style=" font-size: 12px;" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">
  <div class="modal-dialog modal-sm" style="max-width: 1000px !important;">
      <div class="modal-content" style=" text-align: center;">
          <div class="modal-header" style="font-size: 12px; margin-top: 10px; display: block; padding: 0px 0px 5px 0px; text-align: center; ">
              <h1 id="dialog-child-name" class="modal-title">
                  {{"CashierSessionOrders" | translate}}</h1>
          </div>
          <div class="modal-body" style="font-size: 12px; text-align: center;">
            <div style="padding: 5px;" style="font-size: 12px; text-align: center;">
              <table class="table" >
                <thead class="thead-light">
                    <th>{{"CashierOrderCode" | translate}}</th>
                    <th>{{"CashierOrderClientName" | translate}}</th>
                    <th>{{"CashierOrderDate" | translate}}</th>
                    <th>{{"CashierOrderItems" | translate}}</th>
                    <th>{{"CashierOrderIsRefund" | translate}}</th>
                    <th>{{"CashierOrderStatus" | translate}}</th>
                    <th>{{"CashierOrderItemTotalNetPrices" | translate}}</th>
                    <th>{{"CashierOrderItemTotalTaxes" | translate}}</th>
                    <th>{{"CashierOrderTotalValue" | translate}}</th>
                    <th>{{"CashierOrderPaymentMethod" | translate}}</th>
                    <th>{{"CashierOrderNote" | translate}}</th>
                </thead>
                <tbody>
                    <tr *ngIf="currentSession.Orders.length<1"><td colspan="11">{{"CashierSessionOrdersEmpty"|translate}}</td></tr>
                    <tr *ngFor="let obj of currentSession.Orders;  let i=index">
                        <td style="vertical-align: middle;">
                            {{obj.Id>0?(lang==='ar-EG'?obj.Code:obj.Code):"None"|translate}}
                        </td>
                        <td style="vertical-align: middle;">
                            {{obj.ClientId>0?(lang==='ar-EG'?obj.ClientNameAr:obj.ClientNameEn):"NoClient"|translate}}
                        </td>
                        <td style="vertical-align: middle;">
                            {{obj.ModifiedDate | date:"dd/MM/yyyy - HH:mm:ss"}}
                        </td>
                        <td style="vertical-align: middle;">
                            <button
                                    [disabled]="!(obj.Items.length>0)"
                                    id="showOrderItems" class="btn btn-secondary"
                                    (click)="showOrderItemsModal(obj)"
                                    style="font-weight: bold; width: 65px; height: 25px; font-size: 12px; text-align: center; padding: 2px;">
                                    {{"Show" | translate}}</button>
                        </td>
                        <td style="vertical-align: middle;">
                            {{obj.IsRefund?("CashierOrderIsRefundTrue"|translate):("CashierOrderIsRefundFalse"|translate)}}
                        </td>
                        <td style="vertical-align: middle;">
                            {{obj.Status | translate}}
                        </td>
                        <td style="vertical-align: middle;">
                            {{obj.ItemTotalNetPrices.toFixed(2)}}
                        </td>
                        <td style="vertical-align: middle;">
                            {{obj.ItemTotalTaxes.toFixed(2)}}
                        </td>
                        <td style="vertical-align: middle;">
                            {{obj.TotalValue.toFixed(2)}}
                        </td>
                        <td style="vertical-align: middle; white-space:pre-wrap; word-wrap:break-word;">
                            {{obj.PaymentMethodId>0?(lang==='ar-EG'?obj.PaymentMethodAr:obj.PaymentMethodEn):"None"|translate}}
                        </td>
                        <td style="vertical-align: middle; white-space:pre-wrap; word-wrap:break-word;">
                            {{obj.Note}}
                        </td>
                    </tr>
                </tbody>
            </table>
            </div>
            
            <button class="btn btn-light" (click)="sessionOrdersModal.hide()"
                style="font-weight: bold; width: 30%; text-align: center; margin-top: 10px;">{{"Close" |
                translate}}</button>
        </div>
    </div>
  </div>
</div>

<div bsModal #orderItemsModal="bs-modal" style=" font-size: 12px;" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">
  <div class="modal-dialog modal-sm" style="max-width: 1000px !important;">
      <div class="modal-content" style=" text-align: center;">
          <div class="modal-header" style="font-size: 12px; margin-top: 10px; display: block; padding: 0px 0px 5px 0px; text-align: center; ">
              <h1 id="dialog-child-name" class="modal-title">
                  {{"CashierOrderItems" | translate}}</h1>
          </div>
          <div class="modal-body" style="font-size: 12px; text-align: center;">
            <div style="padding: 5px;" style="font-size: 12px; text-align: center;">
              <table class="table">
                  <thead class="thead-light">
                      <th>{{"CashierOrderItemName" | translate}}</th>
                      <!-- <th>{{"CashierOrderItemUnit" | translate}}</th> -->
                      <th>{{"CashierOrderItemUnitCost" | translate}}</th>
                      <th>{{"CashierOrderItemQuantity" | translate}}</th>
                      <th>{{"CashierOrderItemTotalPrice" | translate}}</th>
                      <th>{{"CashierOrderItemDiscountValue" | translate}}</th>
                      <th>{{"CashierOrderItemNetPrice" | translate}}</th>
                      <th>{{"CashierOrderItemTotalTaxValues" | translate}}</th>
                      <th>{{"CashierOrderItemGrossPrice" | translate}}</th>
                      <th>{{"CashierOrderItemNote" | translate}}</th>
                  </thead>
                  <tbody>

                      <tr *ngFor="let obj of viewedOrder.Items;  let i=index">
                          <td style="vertical-align: middle;">
                              {{obj.ItemId>0?(lang==='ar-EG'?obj.ItemNameAr:obj.ItemNameEn):"None"|translate}}
                          </td>
                          <!-- <td style="vertical-align: middle;">
                              {{obj.UnitId>0?(lang==='ar-EG'?obj.UnitNameAr:obj.UnitNameEn):"None"|translate}}
                          </td> -->
                          <td style="vertical-align: middle;">
                              {{obj.UnitCost.toFixed(2)}}
                          </td>
                          <td style="vertical-align: middle;">
                              {{obj.Quantity.toFixed(2)}}
                          </td>
                          <td style="vertical-align: middle;">
                              {{obj.TotalPrice.toFixed(2)}}
                          </td>
                          <td style="vertical-align: middle;">
                              {{obj.DiscountValue.toFixed(2)}}{{obj.DiscountIsRate?'%':''}}
                          </td>
                          <td style="vertical-align: middle;">
                              {{obj.NetPrice.toFixed(2)}}
                          </td>
                          <td style="vertical-align: middle;">
                              {{obj.AddedValueTax.toFixed(2)}}
                          </td>
                          <td style="vertical-align: middle;">
                              {{obj.GrossPrice.toFixed(2)}}
                          </td>
                          <td style="vertical-align: middle; white-space:pre-wrap; word-wrap:break-word;">
                              {{obj.Note}}
                          </td>
                      </tr>
                  </tbody>
              </table>
            </div>
            
            <button class="btn btn-light" (click)="orderItemsModal.hide()"
                style="font-weight: bold; width: 30%; text-align: center; margin-top: 10px;">{{"Close" |
                translate}}</button>
        </div>
    </div>
  </div>
</div>

<div bsModal #infoModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">
  <div class="modal-dialog modal-sm">
      <div class="modal-content">
          <div class="modal-header" style="margin-top: 10px; display: block; padding: 0px 0px 5px 0px; text-align: center; ">
              <h1 id="dialog-child-name" class="modal-title">
                  {{"CashierOrderItemInfo" | translate}}</h1>
          </div>
          <div class="modal-body">
              <div class="form-group form-primary">
                  <label class="float-label push righ"style="font-size: 15px;">{{"CashierOrderItemName" | translate}}</label>
                  <label class="form-control"style="font-size: 15px;" type="text">
                    {{lang==='ar-EG'?currentItem.ItemNameAr:currentItem.ItemNameEn}}</label>
                  <span class="form-bar"></span>
              </div>
              <div class="form-group form-primary">
                  <label class="float-label push righ"style="font-size: 15px;">{{"CashierOrderItemUnitCost" | translate}}</label>
                  <label class="form-control"style="font-size: 15px;" type="text">
                    {{currentItem.UnitCost.toFixed(2)}}</label>
                  <span class="form-bar"></span>
              </div>
              <div class="form-group form-primary">
                  <label class="float-label push righ"style="font-size: 15px;">{{"CashierOrderItemQuantity" | translate}}</label>
                  <label class="form-control"style="font-size: 15px;" type="text">
                    {{currentItem.Quantity.toFixed(2)}}</label>
                  <span class="form-bar"></span>
              </div>
              <div class="form-group form-primary">
                  <label class="float-label push righ"style="font-size: 15px;">{{"CashierOrderItemTotalPrice" | translate}}</label>
                  <label class="form-control"style="font-size: 15px;" type="text">
                    {{currentItem.TotalPrice.toFixed(2)}}</label>
                  <span class="form-bar"></span>
              </div>
              <div class="form-group form-primary">
                  <label class="float-label push righ"style="font-size: 15px;">{{"CashierOrderItemDiscountValue" | translate}}</label>
                  <label class="form-control"style="font-size: 15px;" type="text">
                    {{currentItem.DiscountValue.toFixed(2)}}%</label>
                  <span class="form-bar"></span>
              </div>
              <div class="form-group form-primary">
                  <label class="float-label push righ"style="font-size: 15px;">{{"CashierOrderItemDiscountTotal" | translate}}</label>
                  <label class="form-control"style="font-size: 15px;" type="text">
                    {{(currentItem.TotalPrice-(currentItem.DiscountValue*currentItem.TotalPrice/100)).toFixed(2)}}</label>
                  <span class="form-bar"></span>
              </div>
              <div class="form-group form-primary">
                  <label class="float-label push righ"style="font-size: 15px;">{{"CashierOrderItemNetPrice" | translate}}</label>
                  <label class="form-control"style="font-size: 15px;" type="text">
                    {{currentItem.NetPrice.toFixed(2)}}</label>
                  <span class="form-bar"></span>
              </div>
              <div class="form-group form-primary">
                  <label class="float-label push righ"style="font-size: 15px;">{{"CashierOrderItemTaxValue" | translate}}</label>
                  <label class="form-control"style="font-size: 15px;" type="text">
                    {{14}}%</label>
                  <span class="form-bar"></span>
              </div>
              <div class="form-group form-primary">
                  <label class="float-label push righ"style="font-size: 15px;">{{"CashierOrderItemAddedValueTax" | translate}}</label>
                  <label class="form-control"style="font-size: 15px;" type="text">
                    {{currentItem.AddedValueTax.toFixed(2)}}</label>
                  <span class="form-bar"></span>
              </div>
              <div class="form-group form-primary">
                  <label class="float-label push righ"style="font-size: 15px;">{{"CashierOrderItemGrossPrice" | translate}}</label>
                  <label class="form-control"style="font-size: 15px;" type="text">
                    {{currentItem.GrossPrice.toFixed(2)}}</label>
                  <span class="form-bar"></span>
              </div>
              <div class="form-group form-primary">
                  <label class="float-label push righ"style="font-size: 15px;">{{"CashierOrderItemNote" | translate}}</label>
                  <label class="form-control" type="text" 
                    style="white-space:pre-wrap; word-wrap:break-word; width: 100%; height: 150px; overflow: auto;"
                    name="Note">{{currentItem.Note}}</label>
                  <span class="form-bar"></span>
              </div>
              <button class="btn btn-light" (click)="infoModal.hide()"
              style="font-weight: bold; width: 100%; text-align: center; margin-top: 10px;">{{"Close" |
              translate}}</button>
          </div>
          
      </div>
  </div>
</div>

<div bsModal #orderModal="bs-modal" class="modal fade" id="orderModal" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">
  <div class="modal-dialog modal-sm" style="max-width: 1000px !important;">
      <div class="modal-content">
          <div class="modal-header" style="margin-top: 10px; display: block; padding: 0px 0px 5px 0px; text-align: center; ">
              <h1 id="dialog-child-name" class="modal-title">
                  {{(currentOrder.IsRefund?"CashierRefundSummary":"CashierOrderSummary") | translate}}</h1>
          </div>
          <div class="modal-body" style="text-align: center;">
            
            <div class="form-group" style="margin-top: 10px; overflow: hidden;">

              <div class="row">

                  <div class=" col-md-4">
                    <label class="float-label push righ"style="font-size: 15px;">{{"CashierOrderTotalNetPrices" | translate}}</label>
                    <label class="form-control"style="font-size: 15px;" type="text">
                      {{currentOrder.ItemTotalNetPrices.toFixed(2)}}</label>
                    <span class="form-bar"></span>
                  </div>
                  <br>
                  <div class=" col-md-4">
                    <label class="float-label push righ"style="font-size: 15px;">{{"CashierOrderTotalTaxes" | translate}}</label>
                    <label class="form-control"style="font-size: 15px;" type="text">
                      {{currentOrder.ItemTotalTaxes.toFixed(2)}}</label>
                    <span class="form-bar"></span>
                  </div>
                  <br>
                  <div class=" col-md-4">
                    <label class="float-label push righ"style="font-size: 15px;">{{"CashierOrderTotalGrossPrices" | translate}}</label>
                    <label class="form-control"style="font-size: 15px;" type="text">
                      {{currentOrder.ItemTotalGrossPrices.toFixed(2)}}</label>
                    <span class="form-bar"></span>
                  </div>

              </div>

              <br>

              <div class="row" *ngIf="!(currentOrder.IsRefund)">

                  <div class=" col-md-4">
                    <label class="float-label push righ"style="font-size: 15px;">{{"CashierOrderPaidAmount" | translate}}</label>
                    
                    <input type="number" min="0" name="PaidAmount" class="form-control"style=" margin: 0px;"
                    [(ngModel)]="tempPaidAmount" (change)="checkValue(tempPaidAmount, 'CheckEndSession')"
                    placeholder='{{"CashierOrderPaidAmount" | translate}}'>
                    <span class="form-bar"></span>
                  </div>
                  <br>
                  <div class=" col-md-4">
                    <label class="float-label push righ"style="font-size: 15px;">{{"CashierOrderChangeGiven" | translate}}</label>
                    <!-- <input type="number" min="0" name="ChangeGiven" class="form-control"style=" margin: 0px;"
                    [(ngModel)]="tempChangeGiven" (change)="updateAmount()"
                    placeholder='{{"CashierOrderChangeGiven" | translate}}'> -->
                    <label class="form-control"style="font-size: 15px;" type="text">
                      {{(tempChangeGiven).toFixed(2)}}</label>
                    <span class="form-bar"></span>
                  </div>

              </div>

              <br>

              <div class="row">

                <div class=" col-md-4">
                  <label class="float-label push righ"style="font-size: 15px;">{{"CashierOrderPaymentMethod" | translate}}</label>
                  <ng-select appendTo="body" appearance="outline" name="PaymentMethodId" [(ngModel)]="currentOrder.PaymentMethodId" style="font-size: 15px !important;"
                        placeholder='{{"CashierPaymentMethodSelect" | translate}}'
                        labelForId="txtycd" [searchable]="true">
                        <ng-option *ngFor="let paymentMethod of paymentMethodList" [value]="paymentMethod.Id" style="font-size: 15px !important;">
                          {{lang==='ar-EG'?paymentMethod.NameAr:paymentMethod.NameEn}}
                        </ng-option>
                    </ng-select>
                </div>
                <br>
                <div class=" col-md-4">
                  <label class="float-label push righ"style="font-size: 15px;">{{"CashierOrderStatus" | translate}}</label>
                  <ng-select appendTo="body" appearance="outline" name="Status" [(ngModel)]="currentOrder.Status" style="font-size: 15px !important;"
                        placeholder='{{"CashierStatusSelect" | translate}}'
                        labelForId="txtycd" [searchable]="true">
                        <ng-option style="font-size: 15px !important;" [value]="'Complete'">
                          {{"CashierOrderComplete"|translate}}
                        </ng-option>
                        <ng-option style="font-size: 15px !important;" [value]="'In-Delivery'">
                          {{"CashierOrderDelivery"|translate}}
                        </ng-option>
                    </ng-select>
                </div>

              </div>

              <br>

              <div class="form-group form-primary" style=" overflow: hidden;">
                <label class="float-label push righ"style="font-size: 15px;">{{"CashierOrderNote" | translate}}</label>
                <textarea type="text" name="Note" class="form-control" 
                  style="width: 100%; height: 150px;" [(ngModel)]="tempOrderNote"
                  placeholder='{{"CashierOrderNote" | translate}}'></textarea>
                <span class="form-bar"></span>
              </div>

          </div>
          <div style="display: inline-block; width: 30%; ">
          <button class="btn btn-primary" (click)="addOrder()" 
          [disabled]="!(currentOrder.PaymentMethodId>0)|| (currentOrder.Status==null ||
           currentOrder.Status == '' || (!(tempPaidAmount>0) && !(currentOrder.IsRefund)) )"
            style="font-weight: bold;  display: block;width: 100%; text-align: center; margin-top: 10px;">{{"CashierOrderProceedConfirm" |
            translate}}</button>
            <button class="btn btn-light" (click)="orderModal.hide()"
            style="font-weight: bold; display: block; width: 100%; text-align: center; margin-top: 10px;">{{"Close" |
            translate}}</button>
          </div>
          
        </div>
      </div>
  </div>
</div>


<div bsModal #printModal="bs-modal" style=" font-size: 12px;" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">
  <div class="modal-dialog modal-sm" style="max-width: 1000px !important;">
      <div class="modal-content" style=" text-align: center;">
          <div class="modal-header" style="font-size: 12px; margin-top: 10px; display: block; padding: 0px 0px 5px 0px; text-align: center; ">
              <h1 id="dialog-child-name" class="modal-title">
                  {{"CashierOrderSummary" | translate}}</h1>
          </div>
          <div class="modal-body" style="font-size: 12px; text-align: center; ">
            <div id="contentToConvert" style="padding: 10px; height: 1000px !important; font-size: 12px; text-align: center; border: 1px solid black;">
              <div><a style="font-size: 18px">{{"CashierOrderCode" | translate}} # {{printOrder.Code}}</a></div>
              <div><a style="font-size: 18px">{{"CashierOrderDate" | translate}} : {{printOrder.ModifiedDate | date:"dd/MM/yyyy - hh:mm:ssa"}}</a></div>
              <table class="table">
                  <thead class="thead-light">
                      <th>{{"CashierOrderItemName" | translate}}</th>
                      <!-- <th>{{"CashierOrderItemUnit" | translate}}</th> -->
                      <th>{{"CashierOrderItemUnitCost" | translate}}</th>
                      <th>{{"CashierOrderItemQuantity" | translate}}</th>
                      <th>{{"CashierOrderItemTotalPrice" | translate}}</th>
                      <th>{{"CashierOrderItemDiscountValue" | translate}}</th>
                      <th>{{"CashierOrderItemNetPrice" | translate}}</th>
                      <th>{{"CashierOrderItemTotalTaxValues" | translate}}</th>
                      <th>{{"CashierOrderItemGrossPrice" | translate}}</th>
                      <th>{{"CashierOrderItemNote" | translate}}</th>
                  </thead>
                  <tbody>

                      <tr *ngFor="let obj of printOrder.Items;  let i=index">
                          <td style="vertical-align: middle;">
                              {{obj.ItemId>0?(lang==='ar-EG'?obj.ItemNameAr:obj.ItemNameEn):"None"|translate}}
                          </td>
                          <!-- <td style="vertical-align: middle;">
                              {{obj.UnitId>0?(lang==='ar-EG'?obj.UnitNameAr:obj.UnitNameEn):"None"|translate}}
                          </td> -->
                          <td style="vertical-align: middle;">
                              {{obj.UnitCost.toFixed(2)}}
                          </td>
                          <td style="vertical-align: middle;">
                              {{obj.Quantity.toFixed(2)}}
                          </td>
                          <td style="vertical-align: middle;">
                              {{obj.TotalPrice.toFixed(2)}}
                          </td>
                          <td style="vertical-align: middle;">
                              {{obj.DiscountValue.toFixed(2)}}{{obj.DiscountIsRate?'%':''}}
                          </td>
                          <td style="vertical-align: middle;">
                              {{obj.NetPrice.toFixed(2)}}
                          </td>
                          <td style="vertical-align: middle;">
                              {{obj.AddedValueTax.toFixed(2)}}
                          </td>
                          <td style="vertical-align: middle;">
                              {{obj.GrossPrice.toFixed(2)}}
                          </td>
                          <td style="vertical-align: middle; white-space:pre-wrap; word-wrap:break-word;">
                              {{obj.Note}}
                          </td>
                      </tr>
                  </tbody>
              </table>
              <div><a style="font-size: 25px; text-align: center; font-weight: 900;">{{"CashierOrderTotalValue" | translate}} : {{printOrder.TotalValue.toFixed(2)}}</a></div>
            </div>
            
            <button class="btn btn-primary" (click)="exportTable()"
            style="font-weight: bold; width: 30%; text-align: center; margin-top: 10px;">{{"Print" |
            translate}}</button>
            <button class="btn btn-light" (click)="printModal.hide()"
                style="font-weight: bold; width: 30%; text-align: center; margin-top: 10px;">{{"Close" |
                translate}}</button>
        </div>
    </div>
  </div>
</div>

<div bsModal #itemNoteModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">
  <div class="modal-dialog modal-sm">
      <div class="modal-content">
          <div class="modal-header" style="margin-top: 10px; display: block; padding: 0px 0px 5px 0px; text-align: center; ">
              <h1 id="dialog-child-name" class="modal-title">
                  {{"CashierOrderItemNote" | translate}}</h1>
          </div>
          <div class="modal-body">
              <div class="form-group form-primary">
                  <label class="float-label push righ"style="font-size: 15px;">{{"CashierOrderItemNote" | translate}}</label>
                  <textarea type="text" name="Note" class="form-control" 
                    style="width: 100%; height: 150px;" [(ngModel)]="tempNote"
                    placeholder='{{"CashierOrderItemNote" | translate}}'>{{currentItem.Note}}</textarea>
                  <span class="form-bar"></span>
              </div>
              <button class="btn btn-primary" (click)="setOrderItemNote()"
              style="font-weight: bold; width: 100%; text-align: center; margin-top: 10px;">{{"Save" |
              translate}}</button>
              <button class="btn btn-light" (click)="itemNoteModal.hide()"
              style="font-weight: bold; width: 100%; text-align: center; margin-top: 10px;">{{"Close" |
              translate}}</button>
          </div>
          
      </div>
  </div>
</div>


<div bsModal #endSessionModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">
  <div class="modal-dialog modal-sm" style="max-width: 400px !important;">
      <div class="modal-content">
          <div class="modal-header" style="margin-top: 10px; display: block; padding: 0px 0px 5px 0px; text-align: center; ">
              <h1 id="dialog-child-name" class="modal-title">
                  {{"CashierEndSession" | translate}}</h1>
          </div>
          <div class="modal-body" style="text-align: center;">
            
            <div class="form-group" style="margin-top: 5px; overflow: hidden;">
              <div class="form-group form-primary">
                <label class="float-label push righ"style=" font-size: 15px;">{{"CashierStartCash" | translate}}</label>
                <label class="form-control"style=" font-size: 15px;" type="text">
                  {{currentSession.StartingCash.toFixed(2)}}</label>
                <span class="form-bar"></span>
              </div>

              <br>
              <br>
              <label class="float-label push righ"style="margin-top: 10px; font-size: 15px;">{{"CashierSessionEndRegisterContents" | translate}}</label>
                   
              <br> 
              <div class="row" style="margin-top: 5px;">

                  <div class=" col-md-4">
                    <label class="float-label push righ"style="font-size: 15px; margin: 0px;">{{"CashierBill025" | translate}}</label>
                    <input type="number" min="0" name="Bill025" class="form-control"style=" margin: 0px;"
                    [(ngModel)]="tempSessionEndCash.Bill025" (change)="updateBills()"
                    placeholder='{{"CashierBill025" | translate}}'>
                    <span class="form-bar"></span>
                  </div>
                  <br>
                  <div class=" col-md-4">
                    <label class="float-label push righ"style="font-size: 15px; margin: 0px;">{{"CashierBill05" | translate}}</label>
                    <input type="number" min="0" name="Bill05" class="form-control"style=" margin: 0px;"
                    [(ngModel)]="tempSessionEndCash.Bill05" (change)="updateBills()"
                    placeholder='{{"CashierBill05" | translate}}'>
                    <span class="form-bar"></span>
                  </div>
                  <br>
                  <div class=" col-md-4">
                    <label class="float-label push righ"style="font-size: 15px; margin: 0px;">{{"CashierBill1" | translate}}</label>
                    <input type="number" min="0" name="Bill1" class="form-control"style=" margin: 0px;"
                    [(ngModel)]="tempSessionEndCash.Bill1" (change)="updateBills()"
                    placeholder='{{"CashierBill1" | translate}}'>
                    <span class="form-bar"></span>
                  </div>
              </div>
              <br> 
              <br> 
              <div class="row" style="margin-top: 5px;">

                  <div class=" col-md-4">
                    <label class="float-label push righ"style="font-size: 15px; margin: 0px;">{{"CashierBill5" | translate}}</label>
                    <input type="number" min="0" name="Bill5" class="form-control"style=" margin: 0px;"
                    [(ngModel)]="tempSessionEndCash.Bill5" (change)="updateBills()"
                    placeholder='{{"CashierBill5" | translate}}'>
                    <span class="form-bar"></span>
                  </div>
                  <br>
                  <div class=" col-md-4">
                    <label class="float-label push righ"style="font-size: 15px; margin: 0px;">{{"CashierBill10" | translate}}</label>
                    <input type="number" min="0" name="Bill025" class="form-control"style=" margin: 0px;"
                    [(ngModel)]="tempSessionEndCash.Bill10" (change)="updateBills()"
                    placeholder='{{"CashierBill10" | translate}}'>
                    <span class="form-bar"></span>
                  </div>
                  <br>
                  <div class=" col-md-4">
                    <label class="float-label push righ"style="font-size: 15px; margin: 0px;">{{"CashierBill20" | translate}}</label>
                    <input type="number" min="0" name="Bill20" class="form-control"style=" margin: 0px;"
                    [(ngModel)]="tempSessionEndCash.Bill20" (change)="updateBills()"
                    placeholder='{{"CashierBill20" | translate}}'>
                    <span class="form-bar"></span>
                  </div>
              </div>
              <br> 
              <br> 
              <div class="row" style="margin-top: 5px;">

                  <div class=" col-md-4">
                    <label class="float-label push righ"style="font-size: 15px; margin: 0px;">{{"CashierBill50" | translate}}</label>
                    <input type="number" min="0" name="Bill50" class="form-control"style=" margin: 0px;"
                    [(ngModel)]="tempSessionEndCash.Bill50" (change)="updateBills()"
                    placeholder='{{"CashierBill50" | translate}}'>
                    <span class="form-bar"></span>
                  </div>
                  <br>
                  <div class=" col-md-4">
                    <label class="float-label push righ"style="font-size: 15px; margin: 0px;">{{"CashierBill100" | translate}}</label>
                    <input type="number" min="0" name="Bill100" class="form-control"style=" margin: 0px;"
                    [(ngModel)]="tempSessionEndCash.Bill100" (change)="updateBills()"
                    placeholder='{{"CashierBill100" | translate}}'>
                    <span class="form-bar"></span>
                  </div>
                  <br>
                  <div class=" col-md-4">
                    <label class="float-label push righ"style="font-size: 15px; margin: 0px;">{{"CashierBill200" | translate}}</label>
                    <input type="number" min="0" name="Bill200" class="form-control"style=" margin: 0px;"
                    [(ngModel)]="tempSessionEndCash.Bill200" (change)="updateBills()"
                    placeholder='{{"CashierBill200" | translate}}'>
                    <span class="form-bar"></span>
                  </div>
              </div>

              <br>

              <div class="form-group form-primary" style="margin-top: 10px;">
                  <label class="float-label push righ"style="font-size: 15px;">{{"CashierLeaveCash" | translate}}</label>
                  <!-- <label class="form-control"style="font-size: 15px;" type="text">
                  {{currentSession.LeaveCash.toFixed(2)}}</label> -->
                  <label class="form-control"style="font-size: 15px;" type="text">
                    {{(tempSessionEndCash.BillTotal).toFixed(2)}}</label>
                  <span class="form-bar"></span>
              </div>
              <div class="form-group form-primary">
                  <label class="float-label push righ"style="font-size: 15px;">{{"CashierSessionEndNote" | translate}}</label>
                  <textarea type="text" name="EndNote" class="form-control" 
                    style="width: 100%; height: 150px;" [(ngModel)]="tempSessionEndNote"
                    placeholder='{{"CashierSessionEndNote" | translate}}'></textarea>
                  <span class="form-bar"></span>
              </div>
              <div class="form-group form-primary">
                <div *ngIf="this.currentSession.HasOrdersInDelivery" class="messages text-danger" style="text-align: center; font-weight: 900; font-size: 16px;">
                  {{"CashierSessionHasOrdersInDelivery" | translate}}</div>
                </div>
              <button class="btn btn-primary" (click)="endSession()"
              style="font-weight: bold; width: 100%; text-align: center; margin-top: 10px;">{{"CashierEndSessionConfirm" |
              translate}}</button>
              <button class="btn btn-light" (click)="closeEndSessionModal()"
              style="font-weight: bold; width: 100%; text-align: center; margin-top: 10px;">{{"Close" |
              translate}}</button>
          </div>
        </div>
          
      </div>
  </div>
</div>

<div bsModal #clientModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">
  <div class="modal-dialog modal-sm">
      <div class="modal-content">
          <div class="modal-header" style="margin-top: 10px; display: block; padding: 0px 0px 5px 0px; text-align: center; ">
              <h1 id="dialog-child-name" class="modal-title">
                  {{"CashierOrderClient" | translate}}</h1>
          </div>
          <div class="modal-body">
              <div class="form-group form-primary">
                  <label class="float-label push righ"style="font-size: 15px;">{{"CashierOrderClient" | translate}}</label>
                  <ng-select appendTo="body"  appearance="outline" name="ClientId" [(ngModel)]="tempClientId" style="font-size: 15px !important;"
                        placeholder='{{"CashierOrderClient" | translate}}'
                        labelForId="txtycd" [searchable]="true">
                        <ng-option *ngFor="let client of clientList" [value]="client.Id" style="font-size: 15px !important;">
                            {{lang==='ar-EG'?client.NameAr:client.NameEn}}
                        </ng-option>
                    </ng-select>
                  <span class="form-bar"></span>
              </div>
              <button class="btn btn-primary" (click)="setOrderClient()"
              style="font-weight: bold; width: 100%; text-align: center; margin-top: 10px;">{{"Save" |
              translate}}</button>
              <button class="btn btn-light" (click)="clientModal.hide()"
              style="font-weight: bold; width: 100%; text-align: center; margin-top: 10px;">{{"Close" |
              translate}}</button>
          </div>
          
      </div>
  </div>
</div>

<div bsModal #orderStatusModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">
  <div class="modal-dialog modal-sm">
      <div class="modal-content">
          <div class="modal-header" style="margin-top: 10px; display: block; padding: 0px 0px 5px 0px; text-align: center; ">
              <h1 id="dialog-child-name" class="modal-title">
                  {{"CashierOrderStatus" | translate}}</h1>
          </div>
          <div class="modal-body">
              <div class="form-group form-primary">
                  <!-- <label class="float-label push righ"style="font-size: 15px;">{{"CashierOrderStatus" | translate}}</label> -->
                  <ng-select appendTo="body"  appearance="outline" name="ClientId" 
                  [(ngModel)]="tempOrderStatus" style="font-size: 15px !important;"
                        placeholder='{{"CashierOrderStatus" | translate}}' (change)="setOrderStatus()"
                        labelForId="txtycd" [searchable]="true">
                        <ng-option style="font-size: 15px !important;" [value]="'Complete'">
                          {{"CashierOrderComplete"|translate}}
                        </ng-option>
                        <ng-option style="font-size: 15px !important;" [value]="'In-Delivery'">
                          {{"CashierOrderDelivery"|translate}}
                        </ng-option>
                        <ng-option style="font-size: 15px !important;" [value]="'Cancelled'">
                          {{"CashierOrderCancelled"|translate}}
                        </ng-option>
                    </ng-select>
                  <span class="form-bar"></span>
              </div>
              <button class="btn btn-primary" (click)="applyOrderStatusUpdate()"
              style="font-weight: bold; width: 100%; text-align: center; margin-top: 10px;">{{"Save" |
              translate}}</button>
              <button class="btn btn-light" (click)="orderStatusModal.hide()"
              style="font-weight: bold; width: 100%; text-align: center; margin-top: 10px;">{{"Close" |
              translate}}</button>
          </div>
          
      </div>
  </div>
</div>

<div bsModal #orderStatusReminderModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">
  <div class="modal-dialog modal-sm">
      <div class="modal-content">
          <div class="modal-header" style="margin-top: 10px; display: block; padding: 0px 0px 5px 0px; text-align: center; ">
              <h1 id="dialog-child-name" class="modal-title">
                  {{"CashierOrderStatusReminder" | translate}}</h1>
          </div>
          <div class="modal-body">
              <div class="form-group form-primary">
                  <label class="float-label push righ"style="font-size: 15px;">{{"CashierOrderStatusReminderText" | translate}}</label>
              </div>
              <button class="btn btn-primary" (click)="orderStatusReminderModal.hide()"
              style="font-weight: bold; width: 100%; text-align: center; margin-top: 10px;">{{"Ok" |
              translate}}</button>
          </div>
          
      </div>
  </div>
</div>

<div class="row" style="line-height: 2.4; width: 100%; height: 50px;">
  <div class="exit-row" style="vertical-align: middle; display: inline-block; width: 100%; height: 100%; ">
    <div class="exit-row-half" style="font-size: 20px; display: inline-block; width: 50%; height: 100%;">
      {{"CashierName" | translate}} : {{currentSession.CashierId>0?(lang==='ar-EG'?currentSession.CashierNameAr:currentSession.CashierNameEn):"CashierNameError"|translate}}
    </div>
    <div class="exit-row-half" style="line-height: 1; text-align: end; display: inline-block; width: 50%; height: 100% ;">
      <div class="exit-row-half" style="align-content: flex-end; line-height: 1; text-align: end; display: inline-block; width: 50%; height: 100% ;">
        <button *ngIf="tableView" class="btn btn-light"  (click)="showModal(null, 'ExitTableView')"
            style="vertical-align: middle; font-weight: bold; width: 150px; text-align: center; 
            margin-top: 8px; margin-left: 10px; margin-right: 10px;">{{"Back" | translate}}</button>
        <button *ngIf="!tableView" class="btn btn-primary"  (click)="showModal(null, 'SessionOrders')"
            style="vertical-align: middle; font-weight: bold; width: 150px; text-align: center; 
            margin-top: 8px; margin-left: 10px; margin-right: 10px;">{{"CashierSessionOrders" | translate}}</button>
      </div>
      <div class="exit-row-half" style="line-height: 1; text-align: end; display: inline-block; width: 50%; height: 100% ;">
        <button class="btn btn-light"  (click)="showModal(endSessionModal, '')"
            style="vertical-align: middle; font-weight: bold; width: 150px; text-align: center; 
            margin-top: 8px; margin-left: 10px; margin-right: 10px;">{{"CashierEndSession" | translate}}</button>
      </div>
    </div>
  </div>
</div>

<div class="row" style="line-height: 2.4; width: 100%; height: 50px;">
  <div class="exit-row" style="vertical-align: middle; display: inline-block; width: 100%; height: 100%; ">
    <div class="exit-row-half" style="font-size: 20px; display: inline-block; width: 100%; height: 100%;">
      <button *ngIf="!tableView" class="btn btn-primary default mb-1"  
      style="vertical-align: middle; font-weight: bold;  text-align: center; 
      margin-top: 8px; margin-left: 2px; margin-right: 2px;width: 125px;">{{"Dine In" | translate}}</button>

                      <button *ngIf="!tableView" class="btn btn-primary default mb-1"  
                      style="vertical-align: middle; font-weight: bold;  text-align: center; 
                      margin-top: 8px; margin-left: 2px; margin-right: 2px;width: 145px;">{{"Modifiers" | translate}}</button>
                              <button *ngIf="!tableView" class="btn btn-primary default mb-1" 
                              style="vertical-align: middle; font-weight: bold;  text-align: center; 
                              margin-top: 8px; margin-left: 2px; margin-right: 2px;width: 125px;">{{"Send Order" | translate}}</button>
       <button *ngIf="!tableView" class="btn btn-primary default mb-1" 
       style="vertical-align: middle; font-weight: bold; text-align: center; 
       margin-top: 8px; margin-left: 2px; margin-right: 2px;width: 141px;">{{"Get Order" | translate}}</button>
           <button *ngIf="!tableView" class="btn btn-secondary default mb-1"  
           style="vertical-align: middle; font-weight: bold;  text-align: center; 
           margin-top: 8px; margin-left: 2px; margin-right: 2px;width: 125px;">{{"Generate a Bill" | translate}}</button>
             <button *ngIf="!tableView" class="btn btn-success default mb-1" 
             style="vertical-align: middle; font-weight: bold;  text-align: center; 
             margin-top: 8px; margin-left: 2px; margin-right: 2px;width: 125px;">{{"Add Gratuity" | translate}}</button>
                        <button *ngIf="!tableView" class="btn btn-danger default mb-1"  
                        style="vertical-align: middle; font-weight: bold;  text-align: center; 
                        margin-top: 8px; margin-left: 2px; margin-right: 2px;width: 125px;">{{"Change Seat" | translate}}</button>
                                 
                                                                                                                                               
  
  
  </div>

  </div>
</div>

<div class="row" style="line-height: 2.4; width: 100%; height: 50px;">
  <div class="exit-row" style="vertical-align: middle; display: inline-block; width: 100%; height: 100%; ">
    <div class="exit-row-half" style="font-size: 20px; display: inline-block; width: 100%; height: 100%;">
      <button *ngIf="!tableView" class="btn btn-info default mb-1"  
      style="vertical-align: middle; font-weight: bold;  text-align: center; 
      margin-top: 8px; margin-left: 2px; margin-right: 2px;width: 125px;">{{"Change Server" | translate}}</button>
        <button *ngIf="!tableView" class="btn btn-info default mb-1"  
        style="vertical-align: middle; font-weight: bold;  text-align: center; 
        margin-top: 8px; margin-left: 2px; margin-right: 2px;width: 125px;">{{"Reservation" | translate}}</button>
      <button *ngIf="!tableView" class="btn btn-info default mb-1"  
      style="vertical-align: middle; font-weight: bold;  text-align: center; 
      margin-top: 8px; margin-left: 2px; margin-right: 2px;width: 125px;">{{"Quick Order" | translate}}</button>
                             
       <button *ngIf="!tableView" class="btn btn-primary default mb-1" 
       style="vertical-align: middle; font-weight: bold; text-align: center; 
       margin-top: 8px; margin-left: 2px; margin-right: 2px;width: 125px;">{{"Move Table" | translate}}</button>
           <button *ngIf="!tableView" class="btn btn-secondary default mb-1"  
           style="vertical-align: middle; font-weight: bold;  text-align: center; 
           margin-top: 8px; margin-left: 2px; margin-right: 2px;width: 125px;">{{"Link Table" | translate}}</button>
             <button *ngIf="!tableView" class="btn btn-success default mb-1" 
             style="vertical-align: middle; font-weight: bold;  text-align: center; 
             margin-top: 8px; margin-left: 2px; margin-right: 2px;width: 125px;">{{"Split Bill" | translate}}</button>
                        <button *ngIf="!tableView" class="btn btn-danger default mb-1"  
                        style="vertical-align: middle; font-weight: bold;  text-align: center; 
                        margin-top: 8px; margin-left: 2px; margin-right: 2px;width: 125px;">{{"Void Item" | translate}}</button>
                                   <button *ngIf="!tableView" class="btn btn-warning default mb-1" 
                                   style="vertical-align: middle; font-weight: bold;  text-align: center; 
                                   margin-top: 8px; margin-left: 2px; margin-right: 2px;width: 125px;">{{"Void Order" | translate}}</button>
                                                                                                                                   
  
  
  </div>

  </div>
</div>
<div class="row" style="line-height: 2.4; width: 100%; height: 50px;">
  <div class="exit-row" style="vertical-align: middle; display: inline-block; width: 100%; height: 100%; ">
    <div class="exit-row-half" style="font-size: 20px; display: inline-block; width: 100%; height: 100%;">

         <button *ngIf="!tableView" class="btn btn-warning default mb-1" 
         style="vertical-align: middle; font-weight: bold;  text-align: center; 
         margin-top: 8px; margin-left: 2px; margin-right: 2px;width: 125px;">{{"Change Course" | translate}}</button>
                        <button *ngIf="!tableView" class="btn btn-info default mb-1"  
                        style="vertical-align: middle; font-weight: bold;  text-align: center; 
                        margin-top: 8px; margin-left: 2px; margin-right: 2px;width: 125px;">{{"Fulfillment" | translate}}</button>
                          <button *ngIf="!tableView" class="btn btn-info default mb-1"  
                          style="vertical-align: middle; font-weight: bold;  text-align: center; 
                          margin-top: 8px; margin-left: 2px; margin-right: 2px;width: 125px;">{{"Delivery" | translate}}</button>
                        <button *ngIf="!tableView" class="btn btn-danger default mb-1"  
                        style="vertical-align: middle; font-weight: bold;  text-align: center; 
                        margin-top: 8px; margin-left: 2px; margin-right: 2px;width: 125px;">{{"Shutdown" | translate}}</button>
                                                                                                                          
  
  
  </div>

  </div>
</div>
<div class="row">
  <div *ngIf="tableView" class="FormScreen" style="min-width: 1300px; height: 700px;" class="tableScreen">
    
    <div id="divmiddle" style="display: block; margin-top: 5px;">
  
        <div style="width: 100%;">
            <div>
                <div class="card" style="min-width: 900px !important;">
                  <div style="display: block; margin-top: 15px; width: 100%;  font-size: 20px; font-weight: bold; text-align: center;">
                    {{"CashierSessionOrders"|translate}}</div>
                    <div class="card-body" id="printingTest" style="display: block; font-size: 12px;">
                      <table class="table" >
                        <thead class="thead-light">
                            <th>{{"CashierOrderCode" | translate}}</th>
                            <th>{{"CashierOrderClientName" | translate}}</th>
                            <th>{{"CashierOrderDate" | translate}}</th>
                            <th>{{"CashierOrderItems" | translate}}</th>
                            <th>{{"CashierOrderIsRefund" | translate}}</th>
                            <th colspan="2">{{"CashierOrderStatus" | translate}}</th>
                            <th>{{"CashierOrderItemTotalNetPrices" | translate}}</th>
                            <th>{{"CashierOrderItemTotalTaxes" | translate}}</th>
                            <th>{{"CashierOrderTotalValue" | translate}}</th>
                            <th>{{"CashierOrderPaymentMethod" | translate}}</th>
                            <th>{{"CashierOrderNote" | translate}}</th>
                        </thead>
                        <tbody>
                            <tr *ngIf="currentSession.Orders.length<1"><td colspan="11">{{"CashierSessionOrdersEmpty"|translate}}</td></tr>
                            <tr *ngFor="let obj of currentSession.Orders;  let i=index">
                                <td style="vertical-align: middle;">
                                    {{obj.Id>0?(lang==='ar-EG'?obj.Code:obj.Code):"None"|translate}}
                                </td>
                                <td style="vertical-align: middle;">
                                    {{obj.ClientId>0?(lang==='ar-EG'?obj.ClientNameAr:obj.ClientNameEn):"NoClient"|translate}}
                                </td>
                                <td style="vertical-align: middle;">
                                    {{obj.ModifiedDate | date:"dd/MM/yyyy - HH:mm:ss"}}
                                </td>
                                <td style="vertical-align: middle;">
                                    <button
                                            [disabled]="!(obj.Items.length>0)"
                                            id="showOrderItems" class="btn btn-secondary"
                                            (click)="showOrderItemsModal(obj)"
                                            style="font-weight: bold; width: 65px; height: 25px; font-size: 12px; text-align: center; padding: 2px;">
                                            {{"Show" | translate}}</button>
                                </td>
                                <td style="vertical-align: middle;">
                                    {{obj.IsRefund?("CashierOrderIsRefundTrue"|translate):("CashierOrderIsRefundFalse"|translate)}}
                                </td>
                                <td style="text-align: center; padding:0px !important; margin:0px !important;">
                                  <i class="simple-icon-pencil" (click)="showModal(orderStatusModal, obj)"
                                      tooltip="{{'Edit'|translate}}" placement="auto" style=" font-size: 14px; font-weight: bolder; cursor: pointer; 
                                          vertical-align: middle; color: #FFC107;"></i>
                                </td>
                                <td style="vertical-align: middle; margin:0px !important;  padding:0px !important">
                                    {{obj.Status | translate}}
                                </td>
                                <td style="vertical-align: middle;">
                                    {{obj.ItemTotalNetPrices.toFixed(2)}}
                                </td>
                                <td style="vertical-align: middle;">
                                    {{obj.ItemTotalTaxes.toFixed(2)}}
                                </td>
                                <td style="vertical-align: middle;">
                                    {{obj.TotalValue.toFixed(2)}}
                                </td>
                                <td style="vertical-align: middle; white-space:pre-wrap; word-wrap:break-word;">
                                    {{obj.PaymentMethodId>0?(lang==='ar-EG'?obj.PaymentMethodAr:obj.PaymentMethodEn):"None"|translate}}
                                </td>
                                <td style="vertical-align: middle; white-space:pre-wrap; word-wrap:break-word;">
                                    {{obj.Note}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
  
                        <div id="divsearchmiddle" style="display: inline-block; width: 100%; height: 100%;">
                            <div style="text-align: center;">
                                <!-- <div class="centerside" style="display: inline-block; width: 15%; height: 100%; ">
                                    &nbsp;
  
                                </div> -->
                                <div class="centercenter"
                                    style="display: inline-block; width: 70%; height: 100%;  text-align: center;">
  
                                </div>
                            </div>
                        </div>
  
  
                    </div>
                </div>
  
            </div>
        </div>
    </div>
  
    <div id="divbottom" style="display: block; height: 47px;">
  
        <div id="divsearchright" style="float: right;  width: 33.33%; padding: 0px;">&nbsp;</div>
  
  
        <div id="divsearchleft" style="float: left; width: 33.33%; height: 100%;">&nbsp;</div>
  
    </div>
  </div>
  <div *ngIf="!tableView" class="card" style="min-width: 1300px; height: 700px;">
  <div class="card-body" style="padding: 0px;">

    <div class="item-view">
      <perfect-scrollbar class="scroll" [config]="{suppressScrollX: true}"
        style=" margin: 0px !important; padding: 0px !important;">
        <div class="card" style="display: inline-block; width: 98%; height: 99%; background-color: white;">
          <div class="card-body">

            <div id="divtop">
              <div id="divsearchmiddle">
                <div class="search" style="width: 80%;  overflow: hidden; vertical-align: middle; display: inline-block; margin-left: 50px; margin-right: 50px;">
                  <div class="d-inline-block" style="width: 100%; overflow: hidden;">
                      <ng-select appearance="outline" name="CategoryId" [(ngModel)]="categoryId" (change)="changeCategory()"
                                [ngModelOptions]="{standalone: true}" style="margin-bottom: 7px;"  appendTo="body"
                                placeholder='{{"RestaurantMenuItemCategoryName" | translate}}' 
                                #RestaurantMenuItemCategorySelect labelForId="txtycd" [searchable]="true">
                                <ng-option style="text-align: center;" *ngFor="let category of activeCategories" [value]="category.Id">
                                    {{category.Name}}</ng-option>
                      </ng-select>
                    <!-- <div class="search-sm d-inline-block mr-1 mb-1 align-top" style="width: 100%; overflow: hidden;">
                      <input type="search" [(ngModel)]="tableEngine.SearchTerm" (keyup)="tableEngine.changeFilter()"
                      (change)="tableEngine.changeFilter()" class="form-control input-sm full-data-search" [placeholder]="'Search'|translate">
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          <table class="table table-hover" style="font-size: 12px;">
            <thead class="thead-light item-view-table">
              <th>{{"CashierItemImage" | translate}}</th>
              <th>{{"CashierItemName" | translate}}</th>
              <th>{{"CashierItemPrice" | translate}}</th>
              <th>{{"CashierItemGrossPrice" | translate}}</th>
            </thead>
            <tbody>
              <tr *ngFor="let obj of activeItems;  let i=index" (click)="addOrderItem(obj.Id)">
                <td>
                <img width="50px" height="50px" src="{{obj.ImageUrl}}">
                </td>
                <td>
                  {{lang==='ar-EG'?obj.Name:obj.Name}}
                </td>
                <td>
                  {{obj.Cost.toFixed(2)}}
                </td>
                <td>
                  {{(obj.Cost*0.14+obj.Cost).toFixed(2)}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
    </div>
    </perfect-scrollbar>

  </div>
  <div class="calc-view">
    <div class="order-panel">

      <perfect-scrollbar class="scroll" [config]="{suppressScrollX: true}"
        style="padding: 0px !important; margin: 0px !important;">
        <div class="row">
          <div *ngFor="let orderItem of currentOrder.Items;  let i=index" (click)="selectOrderItem(orderItem.Id)"
          class="col-12 list" style="background-color: #dddddd !important;">
            <div [ngClass]="orderItem.Id==currentItem.Id?'card d-flex flex-row mb-3 clickable selected':'card d-flex flex-row mb-3 clickable'"
            class="card d-flex flex-row mb-3 clickable" style="flex-direction: column !important; margin-bottom: 5px !important;">
              <div class="d-flex flex-grow-1 min-width-zero" style="display: block !important;">
                <div
                  class="card-body align-self-center d-flex flex-column flex-md-row justify-content-between min-width-zero align-items-md-center"
                  style=" padding: 5px; max-height: 100px;">
                  <div class="item-card-section-1" style="width: 50%;">
                    <a class="section-header">
                      {{lang==='ar-EG'?orderItem.ItemNameAr:orderItem.ItemNameEn}}
                    </a>
                    <a class="section-text-small">
                      {{"CashierOrderItemQuantity" | translate}} {{orderItem.Quantity}} - {{"CashierOrderItemPrice" | translate}} {{(orderItem.UnitCost - (orderItem.DiscountValue*orderItem.TotalPrice/100)).toFixed(2)}}
                    </a>
                    <a class="section-text-small" *ngIf="orderItem.DiscountValue>0">
                      {{"CashierOrderItemDiscount" | translate}} {{(orderItem.DiscountValue*orderItem.TotalPrice/100).toFixed(2)}} ({{orderItem.DiscountValue}}%)
                    </a>
                  </div>

                  <div class="item-card-section-2">
                    <a class="section-header" style="text-align: center;">
                      {{orderItem.AddedValueTax.toFixed(2)}}
                    </a>
                    <a class="section-text-small" style="text-align: center;">
                      {{"CashierOrderItemAddedValueTax" | translate}}
                    </a>
                  </div>

                  <div class="item-card-section-2">
                    <a class="section-total">
                      {{orderItem.GrossPrice.toFixed(2)}}
                    </a>
                  </div>
                </div>
              </div>
              <div class="order-item-note" *ngIf="orderItem.Note!=''">
                 {{"CashierOrderItemNote"|translate}}: {{orderItem.Note}}
              </div>
            </div>
          </div>

        </div>
      </perfect-scrollbar>

    </div>
    <div class="order-total-view">
      <div class="order-total-half">
        <a>{{"TotalCashierOrderValue" | translate}}</a>
      </div>
      <div class="order-total-half" style="font-size: 35px; text-align: end;">
        <a>{{this.currentOrder.TotalValue.toFixed(2)}}</a>
      </div>
    </div>
    <div class="calc-panel">
      <div class="dib calc-top-bar">
        <div class="dib calc-bar-tab clickable" (click)="showModal(infoModal, 'Info')">
          <i class="simple-icon-info tab-icon"></i>{{"CashierOrderItemInfo"|translate}}
        </div>
        <div class="dib calc-bar-tab clickable" (click)="showModal(orderModal, 'Refund')"
          style="border-left: 1px solid #F8F8F8; border-right: 1px solid #F8F8F8;">
          <i class="simple-icon-action-undo tab-icon"></i>{{"CashierOrderRefund"|translate}}
        </div>
        <div class="dib calc-bar-tab clickable" (click)="showModal(itemNoteModal, 'Note')">
          <i class="simple-icon-note  tab-icon"></i>{{"CashierOrderItemNote"|translate}}
        </div>
      </div>
      <div class="dib calc-button-section">
        <div class="dib submit-side">
          <div class="dib" style="width: 100%; height: 70%; ">
            <div class="client-header">{{"CashierOrderClientName"|translate}}</div>

            <div class="client-image">
              <i class="simple-icon-user" style="font-size: 50px;  vertical-align: middle;"></i>
            </div>

            <div class="client-footer clickable"  (click)="showModal(clientModal, '')">
              {{currentOrder.ClientId>0?(lang==='ar-EG'?currentOrder.ClientNameAr:currentOrder.ClientNameEn):"CashierChooseClient"|translate}}
            </div>
          </div>
          <div class="dib clickable-primary calc-btn-proceed" (click)="showModal(orderModal, 'Order')">
            {{"CashierOrderProceed"|translate}}
          </div>
        </div>

        <div class="dib button-side" style="width: 65%; height:100%;
              box-shadow: 0px 0px 0px 1px #F8F8F8 inset;">
          <bdo dir="ltr">
            <div class="calc-btn-row">
              <div [ngClass]="currentMode=='Qty'?'calc-btn clickable calc-btn-selected':'calc-btn clickable'"
              (click)="setMode('Qty')"
              class="calc-btn clickable">{{'CashierQty'|translate}}</div>
              <div (click)="modifyItem(currentMode, 7)"  class="calc-btn clickable">7</div>
              <div (click)="modifyItem(currentMode, 8)"  class="calc-btn clickable">8</div>
              <div (click)="modifyItem(currentMode, 9)"  class="calc-btn clickable">9</div>
            </div>
            <div class="calc-btn-row">
              <div [ngClass]="currentMode=='Price'?'calc-btn clickable calc-btn-selected':'calc-btn clickable'"
              (click)="setMode('Price')"
              class="calc-btn clickable">{{'CashierPrice'|translate}}</div>
              <div (click)="modifyItem(currentMode, 4)"  class="calc-btn clickable">4</div>
              <div (click)="modifyItem(currentMode, 5)"  class="calc-btn clickable">5</div>
              <div (click)="modifyItem(currentMode, 6)"  class="calc-btn clickable">6</div>
            </div>
            <div class="calc-btn-row">
              <div [ngClass]="currentMode=='Dsc'?'calc-btn clickable calc-btn-selected':'calc-btn clickable'"
              (click)="setMode('Dsc')"
              class="calc-btn clickable">{{'CashierDsc'|translate}}</div>
              <div (click)="modifyItem(currentMode, 1)" class="calc-btn clickable">1</div>
              <div (click)="modifyItem(currentMode, 2)"  class="calc-btn clickable">2</div>
              <div (click)="modifyItem(currentMode, 3)"  class="calc-btn clickable">3</div>
            </div>
            <div class="calc-btn-row">
              <div (click)="modifyItem(currentMode, 'Del')"  class="calc-btn clickable">حذف</div>
              <div (click)="modifyItem(currentMode, '+/-')"  class="calc-btn clickable">+/-</div>
              <div (click)="modifyItem(currentMode, 0)"  class="calc-btn clickable">0</div>
              <div (click)="modifyItem(currentMode, '.')"  class="calc-btn clickable">.</div>
            </div>
          </bdo>
        </div>
      </div>
      <!-- <div class="calc-top-bars" style="background-color: white ; height: 50px; align-content: center;
          vertical-align: middle; ">
          <div style="border: 5px solid teal; vertical-align: baseline; height: 25px;">
              <div class="dib calc-tab">info</div>
              <div class="dib calc-tab" style="border-left: 1px solid black; border-right: 1px solid black;">refund</div>
              <div class="dib calc-tab">note</div>
          </div>
          </div> -->
    </div>

  </div>
</div>
</div>




<simple-notifications></simple-notifications>