import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BudgetModel } from './models/budget.model';


@Injectable({
  providedIn: 'root'
})
export class BudgetService {
    constructor(private http: HttpClient) {
  
    }

    baseUrl = environment.baseApiUrl;
     token = localStorage.getItem("token");
     httpOptions = {
      headers: new HttpHeaders({
        // 'Content-Type': 'application/json; charset=utf-8',
        // 'Authorization': "Bearer " + token,
         "Authorization": "Bearer " + this.token,
         "Content-Type": "application/json"
      })
    };
    getAll(): Observable<any> {
   
      return this.http
        .get<any>(`${this.baseUrl}Budget/GetAll`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    getById(id:number): Observable<any> {
   
      return this.http
        .get<any>(`${this.baseUrl}Budget/GetById/${id}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    GetPayedAmount(InvoiceId:number): Observable<any> {
   
      return this.http
        .get<any>(`${this.baseUrl}Budget/GetPayedAmount/${InvoiceId}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    GetLastCode(): Observable<number> {
      return this.http
        .get<number>(`${this.baseUrl}Budget/GetLastCode`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    
    insert(payload: BudgetModel): Observable<any> {
      
      return this.http
        .post<any>(`${this.baseUrl}Budget/Insert`, payload,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
    update(payload: BudgetModel): Observable<any> {
      return this.http
        .post<any>(`${this.baseUrl}Budget/Update`, payload,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
    delete(payload: BudgetModel): Observable<any> {
        return this.http
        .post<any>(`${this.baseUrl}Budget/Delete`, payload,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
  
    deleteList(payload: BudgetModel []): Observable<any> {
      return this.http
        .post<any>(`${this.baseUrl}Budget/DeleteList`,JSON.stringify(payload),this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
}
