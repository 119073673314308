<!-- <app-list-page-header *ngIf="tableView"[itemOptionsPerPage]="[5,10,20]"
    [itemsPerPage]="tableEngine.itemsPerPage" [selectAllState]="tableEngine.selectAllState" (addNewItem)="showModal(null,'Add')"
    [tableEngine]="this.tableEngine" (selectAllChange)="tableEngine.selectAll($event)" (changeOrderBy)="prepChangeSort($event)"
    (searchKeyUp)="tableEngine.filterDataByDateRange(this.SearchFrom,this.SearchTo,'Date')" [dateSearch]=true>
</app-list-page-header> -->
<div class="row" *ngIf="tableView">
    <div class="col-12">
      <div class="half-body" style="display: inline-block; width: 50%;">
        <!-- <app-heading></app-heading>
        <app-breadcrumb></app-breadcrumb> -->
        <div class="search-sm-nosearch d-inline-block mr-1 mb-1 align-top" *ngIf="!dateSearch">
          <div class="search" style="display: inline-block; margin-left: 50px; margin-right: 50px;">
            <div class="d-inline-block">
                <div class="search-sm d-inline-block mr-1 mb-1 align-top" style="width: 600px;">
                    <input type="search" [(ngModel)]="tableEngine.SearchTerm" (keyup)="searchUsingKeys()" (change)="searchUsingKeys()"
                        class="form-control input-sm full-data-search" placeholder="{{'Search' | translate}}">
                </div>
            </div>
        </div>
        </div>
        <div class="d-inline-block mr-1 mb-1 align-top" style="width: 1000px;" *ngIf="dateSearch">
          {{"PurchaseOrderShowResultsFrom"|translate}}
          <div class="search-sm-nosearch d-inline-block mr-1 mb-1 align-top">
              <input style="display: inline-block; width: 150px; margin-left: 5px;  margin-right: 5px;"
                  type="text" onfocus="(this.type='date')" onblur="if(!this.value)this.type='text'"
                  onchange="if(!this.value)this.type='text'" name="Date" class="form-control"
                  [(ngModel)]="SearchFrom"
                  (change)="searchDateRange()"
                  placeholder='{{"DateFrom" | translate}}'>
          </div>
          {{"PurchaseOrderShowResultsTo"|translate}}
          <div class="search-sm-nosearch d-inline-block mr-1 mb-1 align-top">
              <input style="display: inline-block; width: 150px; margin-left: 5px;  margin-right: 5px;"
                  type="text" onfocus="(this.type='date')" onblur="if(!this.value)this.type='text'"
                  onchange="if(!this.value)this.type='text'" name="Date" class="form-control"
                  [(ngModel)]="SearchTo"
                  (change)="searchDateRange()"
                  placeholder='{{"DateTo" | translate}}'>
          </div>
      </div>
      </div>
      <div class="half-body" style="display: inline-block; width: 50%;">
        <div class="half-body-filler" style="display: inline-block; width: 40%;">
          &nbsp;
        </div>
        <div class="half-body-content" style=" width: 30%; display: inline-block; text-align: center;">
          <div *ngIf="AddEnabled" style="display: block; height: 40%; width: 100%; text-align: center; margin-bottom: 5px;">
            <button type="button" (click)="addAccountBanck(null,'Add',2)"  
            class=" btn btn-secondary btn-sm mr-1">{{ 'Add Banck Account' | translate}}</button>
          </div>
          <div id="displayOptions" 
          style="padding: unset !important; display: block; height: 40%; width: 100%; text-align: center;">
          </div>
        </div>
        <div class="half-body-content" style=" width: 30%; display: inline-block; text-align: center;">
            <div *ngIf="AddEnabled" style="display: block; height: 40%; width: 100%; text-align: center; margin-bottom: 5px;">
              <button type="button" (click)="addAccountBanck(null,'Add',1)" 
              class=" btn btn-primary btn-sm mr-1">{{ 'Add Treasury' | translate}}</button>
            </div>
            <div id="displayOptions" 
            style="padding: unset !important; display: block; height: 40%; width: 100%; text-align: center;">
            </div>
          </div>
      </div>
 
      <div class="display-options-container">
        <div class="separator mb-4"></div>
      </div>
    </div>
  </div>
<div class="form-group" *ngIf="!tableView && mainForm">
    <div class="modal-body">
        <form class="md-float-material form-material" [formGroup]="mainForm">

            <div style="text-align: center;">
                <button class="btn btn-light" (click)="goBack()"
                    style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Back"  | translate}}</button>
                <button *ngIf="tableEngine.currentFormMode==='Add'"
                    [disabled]="mainForm.invalid || tableEngine.currentAction==='loading' "
                    id="smallModalSubmitButton" class="btn btn-secondary" (click)="addObject()"
                    style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Add"  | translate}}</button>
                <button *ngIf="tableEngine.currentFormMode==='Add'" class="btn btn-secondary" (click)="addAndNew()"
                    [disabled]="mainForm.invalid || tableEngine.currentAction==='loading' "
                    style="font-weight: bold; width: 250px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"AddAndNew"  | translate}}</button>
                <button *ngIf="tableEngine.currentFormMode==='Edit'"
                    [disabled]="mainForm.invalid || tableEngine.currentAction==='loading' "
                    id="smallModalSubmitButton" class="btn btn-secondary" (click)="editObject()"
                    style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Edit" | translate}}</button>
            </div>
            <div class="form-group" style="margin-top: 10px;">
                <div class="row">
                    <div class=" col-md-6">
                        <label class="float-label push righ">{{"Type" | translate}}</label>
                        <!-- <div *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'">
                          
                            <input type="text"  name="Name" class="form-control" [ngModelOptions]="{standalone: true}"
                            formControlName="Type" style=" margin: 0px;"
                            placeholder='{{"Type" | translate}}'>
                        </div> -->
                        <label *ngIf="mainForm.get('Type').value == 2" class="form-control" type="text"
                        style="white-space:pre-wrap; word-wrap:break-word; overflow: auto;"
                        name="Description">{{"Account Banck Name" | translate}}</label>
                        <label *ngIf="mainForm.get('Type').value == 1" class="form-control" type="text"
                        style="white-space:pre-wrap; word-wrap:break-word; overflow: auto;"
                        name="Description">{{"Treasury"|translate}}</label>
                        <!-- <div class="messages text-danger"
                            *ngIf=" (mainForm.get('Name').hasError('required') || mainForm.get('Name').invalid) && ( mainForm.get('Name').dirty ||  mainForm.get('Name').touched)">
                            {{"Name Required" | translate}}</div> -->
                        <span class="form-bar"></span>
                    </div>
                    <div class=" col-md-6">
                        <label class="float-label push righ">{{"Name" | translate}}</label>
                        <div *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'">
                          
                            <input type="text"  name="Name" class="form-control" [ngModelOptions]="{standalone: true}"
                            formControlName="Name" style=" margin: 0px;"
                            placeholder='{{"Name" | translate}}'>
                        </div>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                        style="white-space:pre-wrap; word-wrap:break-word; overflow: auto;"
                        name="Description">{{modelObject.Name}}</label>
                        <!-- <div class="messages text-danger"
                            *ngIf=" (mainForm.get('Name').hasError('required') || mainForm.get('Name').invalid) && ( mainForm.get('Name').dirty ||  mainForm.get('Name').touched)">
                            {{"Name Required" | translate}}</div> -->
                        <span class="form-bar"></span>
                    </div>
                </div>

                <br>
                <div class="row" *ngIf="mainForm.get('Type').value == 2">
                    <div class=" col-md-6">
                        <label class="float-label push righ">{{"Account Name" | translate}}</label>
                        <div *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'">
                          
                            <input type="text"  name="AccountName" class="form-control" [ngModelOptions]="{standalone: true}"
                            formControlName="AccountName" style=" margin: 0px;"
                            placeholder='{{"AccountName" | translate}}'>
                        </div>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                        style="white-space:pre-wrap; word-wrap:break-word; overflow: auto;"
                        name="Description">{{modelObject.AccountName}}</label>
                        <!-- <div class="messages text-danger"
                            *ngIf=" (mainForm.get('Name').hasError('required') || mainForm.get('Name').invalid) && ( mainForm.get('Name').dirty ||  mainForm.get('Name').touched)">
                            {{"Name Required" | translate}}</div> -->
                        <span class="form-bar"></span>
                    </div>
                    <div class=" col-md-6">
                        <label class="float-label push righ">{{"Account Number" | translate}}</label>
                        <div *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'">
                          
                            <input type="text"  name="AccountNumber" class="form-control" [ngModelOptions]="{standalone: true}"
                            formControlName="AccountNumber" style=" margin: 0px;"
                            placeholder='{{"Account Number" | translate}}'>
                        </div>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                        style="white-space:pre-wrap; word-wrap:break-word; overflow: auto;"
                        name="Description">{{modelObject.AccountNumber}}</label>
                        <!-- <div class="messages text-danger"
                            *ngIf=" (mainForm.get('Name').hasError('required') || mainForm.get('Name').invalid) && ( mainForm.get('Name').dirty ||  mainForm.get('Name').touched)">
                            {{"Name Required" | translate}}</div> -->
                        <span class="form-bar"></span>
                    </div>
                </div>

                <br>
                <div class="row">
                    <div class=" col-md-6"  *ngIf="mainForm.get('Type').value == 2">
                        <label class="float-label push righ">{{"Currency" | translate}}</label>
                        <div *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'">
                            <ng-select appearance="outline" name="Currency" formControlName="CurrencyId"
                                placeholder='{{"Currency" | translate}}' #PurchaseOrderIsPostedSelect
                                labelForId="txtycd">
                                <ng-option selected desabled [value]="0">{{"Select Currency" | translate}}</ng-option>
                                <ng-option *ngFor="let item of currencies" [value]="item.Id">{{lang==='ar-EG'?item.NameAr:item.NameEn}}</ng-option>
                            </ng-select>
                        </div>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="IsPosted">{{getCurrency(modelObject.CurrencyId)
                            | translate}}</label>
                        <!-- <div class="messages text-danger"
                            *ngIf=" (mainForm.get('MainAccountId').hasError('required') || mainForm.get('MainAccountId').invalid) && ( mainForm.get('MainAccountId').dirty ||  mainForm.get('MainAccountId').touched)">
                            {{"Main Account Required" | translate}}</div> -->
                        <span class="form-bar"></span>
                    </div>
                    <div class=" col-md-6">
                        <label class="float-label push righ">{{"Status" | translate}}</label>
                       
                        <div *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'">
                            <!-- <mat-radio-group   aria-label="Select an option">
                                <mat-radio-button formControlName="Status" [value]="true">{{'Active'|translate}}</mat-radio-button>
                                <mat-radio-button formControlName="Status" [value]="false">{{'Not Active'|translate}}</mat-radio-button>
                              </mat-radio-group> -->
                              <div class="form-group" >
                                <!-- <label>{{ 'Status' | translate }}</label> -->
                                <div class="custom-control custom-radio">
                                  <div  style="width: 50%;display: inline;" [ngStyle]="{'margin-left':lang=='ar-EG' ? '50%' : '0%','margin-right':lang !='ar-EG' ? '50%' : '0%' }" >
                                    <input class="custom-control-input" type="radio"  [value]="true"  formControlName="Status" name="Status"  id="exampleRadios1"
                                     required>
                                  <label class="custom-control-label" for="exampleRadios1">
                                     {{'Active'|translate}}
                                  </label>
                                </div>
                                <div  style="width: 50%;display: inline">
                                    <input class="custom-control-input" type="radio" [value]="false" formControlName="Status" name="Status"  id="exampleRadios2"
                                    required>
                                 <label class="custom-control-label" for="exampleRadios2">
                                   {{'Not Active'|translate}}
                                 </label>
                                </div>
                                </div>
                                <!-- <div class="custom-control custom-radio" style="width: 50%;">
                                  <input class="custom-control-input" type="radio" [value]="false" formControlName="Status" name="Status"  id="exampleRadios2"
                                     required>
                                  <label class="custom-control-label" for="exampleRadios2">
                                    {{'Not Active'|translate}}
                                  </label>
                                </div> -->
                                <!-- <div *ngIf="!radio.valid && form.submitted" class="invalid-tooltip">Radio is required!</div> -->
                              </div>
                        </div> 
                    </div>
                </div>
                <br>
                <div class="row" >
                <div class=" col-md-6">
                    <label class="float-label push righ">{{"Description" | translate}}</label>
                    <textarea *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                        type="text" name="Description" class="form-control" formControlName="Description"
                        placeholder='{{"Description" | translate}}'></textarea>
                    <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                        style="white-space:pre-wrap; word-wrap:break-word; overflow: auto;"
                        name="Description">{{modelObject.Description}}</label>
                    <span class="form-bar"></span>
                </div>
                    <div class=" col-md-6">
                    
                    </div>
                </div>

                <br>
            </div>
            <!-- <div style="width: 100%; margin-top: 15px;">
                <div>
                    <table class="table">
                        <thead class="thead-light">
                            <th>{{"Roles"|translate}}</th>
                        </thead>
                    </table>
                    <table class="table">
                        <thead class="thead-light">
                            <th>{{"Deposit" | translate}}</th>
                            <th>{{"withdrawal" | translate}}</th>
                        </thead>
                        <tbody>
                            <tr>
                              
                                <td style="width: 50%;">
                                    <div class="row">
                                        <div [ngClass]="{ 'col-md-6':mainForm.get('DepositRolesType').value > 0,'col-md-12':mainForm.get('DepositRolesType').value == 0}">
                                            <div *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'">
                                                <ng-select appearance="outline" name="Currency" formControlName="DepositRolesType"
                                                    placeholder='{{"Deposit Roles Type" | translate}}' #DepositRolesType
                                                    labelForId="txtycd">
                                                    <ng-option  [value]="0">{{"All" | translate}}</ng-option>
                                                    <ng-option  [value]="1">{{"Specific Employee" | translate}}</ng-option>
                                                    <ng-option  [value]="2">{{"Specific Job Role" | translate}}</ng-option>
                                                    <ng-option  [value]="3">{{"Specific Branch" | translate}}</ng-option>
                                                </ng-select>
                                            </div>
                                            <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                                                name="IsPosted">{{getRolesType(modelObject.DepositRolesType)
                                                | translate}}</label>
                                    
                                            <span class="form-bar"></span>
                                        </div>
                                        <div class=" col-md-6"  *ngIf="mainForm.get('DepositRolesType').value > 0">
                                            <div *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'">
                                                <ng-select  *ngIf="mainForm.get('DepositRolesType').value ==1" appearance="outline" name="SelectedDepositTreasuryAndBankRolesEmployeesList" formControlName="SelectedDepositTreasuryAndBankRolesEmployeesList"
                                                    placeholder='{{"Plase Choose" | translate}}' #SelectedDepositTreasuryAndBankRolesEmployeesList
                                                    labelForId="txtycd" [multiple]="true">
                                                    <ng-option selected desabled [value]="0">{{"Plase Choose" | translate}}</ng-option>
                                                    <ng-option  *ngFor="let item of employees" [value]="item.Id">{{lang==='ar-EG'?item.NameAr:item.NameEn}}</ng-option>
                                                </ng-select>
                                                <ng-select  *ngIf="mainForm.get('DepositRolesType').value ==2" appearance="outline" name="SelectedDepositTreasuryAndBankRolesFunctionalRolesList" formControlName="SelectedDepositTreasuryAndBankRolesFunctionalRolesList"
                                                placeholder='{{"Plase Choose" | translate}}' #SelectedDepositTreasuryAndBankRolesFunctionalRolesList
                                                labelForId="txtycd" [multiple]="true">
                                                <ng-option selected desabled [value]="0">{{"Plase Choose" | translate}}</ng-option>
                                                <ng-option  *ngFor="let item of roles" [value]="item.Id">{{lang==='ar-EG'?item.NameAr:item.NameEn}}</ng-option>
                                            </ng-select>
                                            <ng-select  *ngIf="mainForm.get('DepositRolesType').value == 3" appearance="outline" name="SelectedDepositTreasuryAndBankRolesBranchesList" formControlName="SelectedDepositTreasuryAndBankRolesBranchesList"
                                            placeholder='{{"Plase Choose" | translate}}' #SelectedCreditTreasuryAndBankRolesBranchesList
                                            labelForId="txtycd" [multiple]="true">
                                            <ng-option selected desabled [value]="0">{{"Plase Choose" | translate}}</ng-option>
                                            <ng-option  *ngFor="let item of branches" [value]="item.Id">{{lang==='ar-EG'?item.NameAr:item.NameEn}}</ng-option>
                                        </ng-select>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </td>
                                <td style="width: 50%;">
                                    <div class="row">
                                        <div [ngClass]="{ 'col-md-6':mainForm.get('CreditRolesType').value > 0,'col-md-12':mainForm.get('CreditRolesType').value == 0}">
                                            <div *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'">
                                                <ng-select appearance="outline" name="Currency" formControlName="CreditRolesType"
                                                    placeholder='{{"Credit Roles Type" | translate}}' #CreditRolesType
                                                    labelForId="txtycd">
                                                    <ng-option [value]="0">{{"All" | translate}}</ng-option>
                                                    <ng-option [value]="1">{{"Specific Employee" | translate}}</ng-option>
                                                    <ng-option [value]="2">{{"Specific Job Role" | translate}}</ng-option>
                                                    <ng-option [value]="3">{{"Specific Branch" | translate}}</ng-option>
                                                </ng-select>
                                            </div>
                                            <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                                                name="IsPosted">{{getRolesType(modelObject.CreditRolesType)
                                                | translate}}</label>
                                 
                                            <span class="form-bar"></span>
                                        </div>
                                        <div class=" col-md-6"  *ngIf="mainForm.get('CreditRolesType').value > 0">
                                            <div *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'">
                                                <ng-select  *ngIf="mainForm.get('CreditRolesType').value ==1" appearance="outline" name="SelectedCreditTreasuryAndBankRolesEmployeesList" formControlName="SelectedCreditTreasuryAndBankRolesEmployeesList"
                                                    placeholder='{{"Plase Choose" | translate}}' #SelectedDepositTreasuryAndBankRolesEmployeesList
                                                    labelForId="txtycd" [multiple]="true">
                                                    <ng-option selected desabled [value]="0">{{"Plase Choose" | translate}}</ng-option>
                                                    <ng-option  *ngFor="let item of employees" [value]="item.Id">{{lang==='ar-EG'?item.NameAr:item.NameEn}}</ng-option>
                                                </ng-select>
                                                <ng-select  *ngIf="mainForm.get('CreditRolesType').value ==2" appearance="outline" name="SelectedCreditTreasuryAndBankRolesFunctionalRolesList" formControlName="SelectedCreditTreasuryAndBankRolesFunctionalRolesList"
                                                placeholder='{{"Plase Choose" | translate}}' #SelectedDepositTreasuryAndBankRolesFunctionalRolesList
                                                labelForId="txtycd" [multiple]="true">
                                                <ng-option selected desabled [value]="0">{{"Plase Choose" | translate}}</ng-option>
                                                <ng-option  *ngFor="let item of roles" [value]="item.Id">{{lang==='ar-EG'?item.NameAr:item.NameEn}}</ng-option>
                                            </ng-select>
                                            <ng-select  *ngIf="mainForm.get('CreditRolesType').value == 3" appearance="outline" name="SelectedCreditTreasuryAndBankRolesBranchesList" formControlName="SelectedCreditTreasuryAndBankRolesBranchesList"
                                            placeholder='{{"Plase Choose" | translate}}' #SelectedCreditTreasuryAndBankRolesBranchesList
                                            labelForId="txtycd" [multiple]="true">
                                            <ng-option selected desabled [value]="0">{{"Plase Choose" | translate}}</ng-option>
                                            <ng-option  *ngFor="let item of branches" [value]="item.Id">{{lang==='ar-EG'?item.NameAr:item.NameEn}}</ng-option>
                                        </ng-select>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </td>
                            </tr>
                 
                        </tbody>
                        <thead class="thead-light">
                            <th></th>
                            <th></th>
                        </thead>
                    </table>
                </div>
            </div> -->
        </form>
        
    </div>

</div>

<div bsModal #delModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <span aria-hidden="true">&nbsp;</span>
            <div class="modal-header" style="display: inline; padding: 0px 0px 5px 0px;">
                <h4 id="dialog-child-name" class="modal-title pull-left" style="text-align: center; color: #DC3545;">
                    {{"ConfirmDel"+translationName |
                    translate}}</h4>
            </div>
            <div class="modal-body">
                <div class="form-group form-primary" *ngIf="tableEngine.deletionList.length < tableEngine.ogData.length 
                || tableEngine.deletionList.length === 1" style="text-align: center;">
                    <div *ngFor="let item of tableEngine.deletionList;" style="text-align: center;">
                        <label type="text">- {{"Number" | translate}}
                            {{lang==='ar-EG'?item.Code:item.Code}}</label>
                        <span class="form-bar"></span>
                    </div>
                </div>
                <div class="form-group form-primary" style="text-align: center;" *ngIf="tableEngine.deletionList.length > 1 && 
                    tableEngine.deletionList.length === tableEngine.ogData.length">
                    <label type="text">
                        <h1 style="font-weight: bolder; text-align: center;">{{"DeleteAll" | translate}}
                        </h1>
                    </label>
                    <span class="form-bar"></span>
                </div>

                <div style="margin-left: 5px; text-align: center; margin-top: 30px;">
                    <button class="btn btn-danger" id="smallModalDeleteButton" (click)="deleteObject()"
                        [disabled]="tableEngine.currentAction==='loading'"
                        style="font-weight: bold; width: 100%; text-align: center;">{{"Delete" | translate}}</button>
                    <button class="btn btn-light" (click)="delModal.hide()"
                        style="font-weight: bold; width: 100%; text-align: center; margin-top: 10px;">{{"Close" |
                        translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="tableView" class="FormScreen" class="tableScreen">
    <div id="divmiddle" style="display: block; margin-top: 5px;">

        <div style="width: 100%;">
            <div>
                <div class="card" style="min-width: 900px !important;">
                    <div class="card-body">
                        <table class="table table-hover" style="margin: unset !important;">
                            <thead class="no-border-top">
                                <th style="text-align: center; padding:5px;">
                                    <div class=" text-zero" style="display: inline-block;">
                                        <div class="top-right-button-container2 btn-group" dropdown>
                                            <div class="btn btn-primary pl-4 pr-0 check-button">
                                                <label class="custom-control custom-checkbox mb-0 d-inline-block"
                                                    style="width: 10px;">
                                                    <input type="checkbox" class="custom-control-input"
                                                        [checked]="tableEngine.selectAllState==='checked'"
                                                        [indeterminate]="tableEngine.selectAllState === 'indeterminate'"
                                                        (change)="tableEngine.selectAll($event)">
                                                        <span class="custom-control-label">&nbsp;</span>
                                                </label>
                                            </div>
                                            <button id="button-split" type="button" dropdownToggle style="width: 10px;"
                                                
                                                [ngClass]="(tableEngine.selected.length == 0)?
                                                'btn btn-outline-primary dropdown-toggle dropdown-toggle-split':
                                                'btn btn-primary dropdown-toggle dropdown-toggle-split'"
                                                aria-controls="dropdown-split">
                                                <span class="caret"></span>
                                            </button>
                                            <ul id="dropdown-split" *dropdownMenu
                                                class="dropdown-menu dropdown-menu-right" role="menu"
                                                aria-labelledby="button-split">
                                                <li role="menuitem"><a class="dropdown-item">
                                                        <button class="btn btn-danger d-inline-block"
                                                            style=" font-weight: bold; text-align: center; padding: 0px; width: 150px; height: 30px;"
                                                            (click)="tableEngine.showDelModal('delMulti')"
                                                            [disabled]="tableEngine.selected.length===0 || tableEngine.currentAction==='loading'">{{"DeleteSelected"
                                                            |
                                                            translate}}</button></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </th>

                                <th class="sorticon" (click)="prepChangeSort('Name')"
                                    [ngClass]="(itemOrder.label==='Name')?'sortkey sorticon':'sorticon'">
                                    <div
                                        [ngClass]="{'iconsminds-up---down': (itemOrder.label!='Name'), 
                                    'iconsminds-down': ((itemOrder.label==='Name') && tableEngine.orderAsc === true),
                                    'iconsminds-up': ((itemOrder.label==='Name') && tableEngine.orderAsc === false)}">
                                    </div>
                                </th>
                                <th class="sortOption" (click)="prepChangeSort('Name')"
                                    [ngClass]="{'sortkey': (itemOrder.label==='Name')}">
                                    {{"Name" | translate}}
                                </th>


                                <th class="sorticon" (click)="prepChangeSort('Type')"
                                    [ngClass]="(itemOrder.label==='Type')?'sortkey sorticon':'sorticon'">
                                    <div
                                        [ngClass]="{'iconsminds-up---down': (itemOrder.label!='Type'), 
                                    'iconsminds-down': ((itemOrder.label==='Type') && tableEngine.orderAsc === true),
                                    'iconsminds-up': ((itemOrder.label==='Type') && tableEngine.orderAsc === false)}">
                                    </div>
                                </th>
                                <th class="sortOption" (click)="prepChangeSort('Type')"
                                [ngClass]="{'sortkey': (itemOrder.label==='Type')}">
                                {{"Type" | translate}}
                            </th>
                              
                               
                                <th [colSpan]="3"
                                    style="text-align: center; padding: unset !important; vertical-align: middle;">

                                    <div id="displayOptions" style="
                                        display: inline; margin-top: 10px; margin-left: 5px; margin-right: 5px;">
                                        <span class="text-muted text-small" style=" width: 50px; height: 40px;">
                                            {{"ResultCount" | translate}}
                                        </span>
                                        <span dropdown class="d-inline-block position-relative">
                                            <button class="btn btn-outline-dark btn-xs dropdown-toggle" type="button"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                                dropdownToggle>
                                                {{tableEngine.itemsPerPage}}
                                            </button>
                                            <div class="dropdown-menu dropdown-menu-right dropdown-menu-sm text-right"
                                                *dropdownMenu>
                                                <li role="menuitem" *ngFor="let item of tableEngine.itemOptionsPerPage">
                                                    <a [ngClass]="item === tableEngine.itemsPerPage ? 'dropdown-item active' : 'dropdown-item'"
                                                        (click)="tableEngine.paginateData(item, 1)">{{item}}</a></li>
                                            </div>
                                        </span>
                                    </div>

                                </th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let obj of tableEngine.paginatedData;  let i=index">
                                    <td style="text-align: center; padding:5px;">
                                        <div class="itemCheck mb-0 custom-checkbox custom-control"
                                            style="display: inline-block;">
                                            <input type="checkbox" class="custom-control-input" [id]="'customCheck'+i"
                                                [checked]="tableEngine.isSelected(obj)"
                                                (change)="tableEngine.onSelect(obj)">
                                            <label class="custom-control-label" [for]="'customCheck'+i"></label>
                                        </div>
                                    </td>
                                    <td colspan="2">{{obj.Name}}</td>
                                    <td colspan="2" *ngIf="obj.Type == 2">{{'Account Banck Name'|translate}}</td>
                                    <td colspan="2" *ngIf="obj.Type == 1">{{'Treasury'|translate}}</td>
                                    
                                    <td style="text-align: center;">
                                        <i class="simple-icon-info" (click)="showModal(obj.Id, 'Info')"
                                            tooltip="{{'Info'|translate}}" placement="auto" style="font-size: 20px; font-weight: bolder; cursor: pointer; 
                                                vertical-align: middle; color: #17A2B8;"></i>
                                    </td>
                                    <td style="text-align: center;">
                                        <i class="simple-icon-pencil" (click)="showModal(obj.Id, 'Edit')"
                                            tooltip="{{'Edit'|translate}}" placement="auto" style="font-size: 20px; font-weight: bolder; cursor: pointer; 
                                                vertical-align: middle; color: #FFC107;"></i>
                                    </td>
                                    <td style="text-align: center;">
                                        <i class="simple-icon-close" (click)="tableEngine.showDelModal(obj.Id)"
                                            tooltip="{{'Delete'|translate}}" placement="auto" style="font-size: 20px; font-weight: bolder; cursor: pointer; 
                                                vertical-align: middle; color: #DC3545;"></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div id="divsearchmiddle" style="display: inline-block; width: 100%; height: 100%;">
                            <div style="text-align: center;">
                                <!-- <div class="centerside" style="display: inline-block; width: 15%; height: 100%; ">
                                    &nbsp;

                                </div> -->
                                <div class="centercenter"
                                    style="display: inline-block; width: 70%; height: 100%;  text-align: center;">

                                    <bdo dir="ltr">
                                        <pagination class="pagination-sm2" style="padding: 0px, 0px, 0px, 0px; margin: 0px, 0px, 0px, 0px;
                                        display: inline-block;" [(ngModel)]="currentPage" [boundaryLinks]="true"
                                            [totalItems]="tableEngine.filteredData.length"
                                            [itemsPerPage]="tableEngine.itemsPerPage"
                                            [customFirstTemplate]="firstTemplate" [customLastTemplate]="lastTemplate"
                                            [customPreviousTemplate]="prevTemplate" [customNextTemplate]="nextTemplate"
                                            [maxSize]="5" (pageChanged)="tableEngine.changePage($event)">
                                        </pagination>
                                    </bdo>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

            </div>
        </div>
    </div>

    <div id="divbottom" style="display: block; height: 47px;">

        <div id="divsearchright" style="float: right;  width: 33.33%; padding: 0px;">&nbsp;</div>


        <div id="divsearchleft" style="float: left; width: 33.33%; height: 100%;">&nbsp;</div>

    </div>
</div>

<div>

</div>

<ng-template #nextTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i *ngIf="lang==='en-US'" class='simple-icon-arrow-right'></i>
    <i *ngIf="lang==='ar-EG'" class='simple-icon-arrow-left'></i>
</ng-template>

<ng-template #prevTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i *ngIf="lang==='en-US'" class='simple-icon-arrow-left'></i>
    <i *ngIf="lang==='ar-EG'" class='simple-icon-arrow-right'></i>
</ng-template>

<ng-template #lastTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i class='simple-icon-control-end'></i>
</ng-template>

<ng-template #firstTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i class='simple-icon-control-start'></i>
</ng-template>


<simple-notifications></simple-notifications>