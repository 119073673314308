import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { StaffModel } from './StaffModel';

@Injectable({
  providedIn: 'root'
})
export class StaffService {
    constructor(private http: HttpClient) {
  
    }

    CompanyId:number =+ localStorage.getItem("CompanyId");
    baseUrl = environment.baseApiUrl;
     token = localStorage.getItem("token");
     httpOptions = {
      headers: new HttpHeaders({
        // 'Content-Type': 'application/json; charset=utf-8',
        // 'Authorization': "Bearer " + token,
         "Authorization": "Bearer " + this.token,
         "Content-Type": "application/json"
      })
    };
    getAll(): Observable<any> {
   
      return this.http
        .get<any>(`${this.baseUrl}Staff/GetAll/${this.CompanyId}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    getById(id:number): Observable<any> {
   
      return this.http
        .get<any>(`${this.baseUrl}Staff/GetById/${id}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    insert(payload: StaffModel): Observable<any> {
      
      return this.http
        .post<any>(`${this.baseUrl}Staff/Insert/${this.CompanyId}`, payload,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
    update(payload: StaffModel): Observable<any> {
      return this.http
        .post<any>(`${this.baseUrl}Staff/Update`, payload,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
    delete(payload: StaffModel): Observable<any> {
      return this.http
        .get<any>(`${this.baseUrl}Staff/Delete/${payload.Id}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
    deleteList(payload: StaffModel []): Observable<any> {
      return this.http
        .post<any>(`${this.baseUrl}Staff/DeleteList`,JSON.stringify(payload),this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
}
