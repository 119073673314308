import { Component, Inject, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { AccountFicationCostTypeService } from '../../account-fication-cost-type/helper/serves';
import { AccountFicationService } from '../../account-fications/helper/serves';
import { AccountMenuService } from '../../account-menu/helper/serves';
import { AccountNatureService } from '../../account-natures/helper/serves';
import { AccountsTypeService } from '../../account-type/helper/serves';
import { BasicModel } from '../../expensess-type/helper/model';
import { AccountExpensesTypeService } from '../../expensess-type/helper/serves';
import { AccountNameModel } from '../AccountNameModel';
import { AccountsNameService } from '../AccountNameService';

@Component({
    // moduleId: module.id,
    selector: 'account-name-actions',
    templateUrl: 'account-name-actions.component.html',
    styleUrls: ['account-name-actions.component.scss']
})
export class AccountNameActionsComponent {
    accountFicationList: BasicModel[] = [];
    accountFicationCostTypeList: BasicModel[] = [];
    accountExpensesTypeList: BasicModel[] = [];
    accountsTypeList: BasicModel[] = [];
    accountMenuList: BasicModel[] = [];
    AccountNatureList: BasicModel[] = [];
    AccountsNameList: AccountNameModel[] = [];
    AccountsNameModel: AccountNameModel;
    itemId:number;
    modelState:string;
    titleButton: String = "";

    IsMainAccountcheck :boolean =true;
    formLoaded :boolean =false;

    AccountNameActionsFormGroup: FormGroup;

    constructor( private translate: TranslateService, public notifications: NotificationsService,private activatedRoute: ActivatedRoute,private accountsNameService: AccountsNameService, accountNatureService: AccountNatureService, accountMenuService: AccountMenuService,accountsTypeService: AccountsTypeService,accountFicationService: AccountFicationService,  accountFicationCostTypeService: AccountFicationCostTypeService, accountExpensesTypeService: AccountExpensesTypeService,){
        
        this.activatedRoute.params.subscribe((params: Params) => {
            this.modelState = params['state'];
            this.itemId = +params['id'];
        });
        
        
        accountFicationService.getAll().subscribe(
            item => {
                this.accountFicationList = item;
                console.log("accountFicationList",item);
                accountFicationCostTypeService.getAll().subscribe(
                    item => {
                        this.accountFicationCostTypeList = item;
                        console.log("accountFicationCostTypeList",item);
                        accountExpensesTypeService.getAll().subscribe(
                            item => {
                                this.accountExpensesTypeList = item;
                                console.log("accountExpensesTypeList",item);
                                accountsTypeService.getAll().subscribe(
                                    item => {
                                        this.accountsTypeList = item;
                                        console.log("accountsTypeService",item);
                                        
                                        accountMenuService.getAll().subscribe(
                                            item => {
                                                this.accountMenuList = item;
                                                console.log("accountMenuService",item);

                                                accountNatureService.getAll().subscribe(
                                                    item => {
                                                        this.AccountNatureList = item;
                                                        console.log("accountMenuService",item);
                                                        
                                                        accountsNameService.getAll(0).subscribe(
                                                            item => {
                                                                this.AccountsNameList = item;
                                                                console.log("AccountsNameList",item);
                                                                this.AccountsNameModel= this.AccountsNameList.find(item=>item.Id==this.itemId);
                                                                console.log(" this.AccountsNameModel", this.AccountsNameModel);
                                                               this.formLoaded =true;
                                                               this.LoadCase();
                                                            }
                                                        ); 
                                                    }
                                                );
                                            
                                                
                                            }
                                        );
                                
                                    }
                                );
                          
                            }
                        );
             
                    }
                );
        
            }
        );
  
    }



    ngOnInit() {
        if (this.modelState == "Add") {
            this.titleButton = "wizard.save";
        } else {
            this.titleButton = "update";
        }
        // if (this.modelState != "Add") {
        //     this.accountsNameService.getAll(0).subscribe(
        //         data => {
        //             this.AccountsNameList = data;
        //             var index = this.AccountsNameList.findIndex(index => index.Id == +this.itemId);
        //             if (index > -1) {
        //                 this.AccountsNameModel = this.AccountsNameList[+index];
        //                 this.LoadCase();
        //             }
        //         }
        //     )
        // } else {
        //     this.LoadCase();
        // }
    }
    LoadCase() {
        switch (this.modelState) {
            case 'Edit': {
                this.EditForm();
                break;
            }
            case 'Show': {
                this.ShowForm();
                break;
            }
            default: {
                this.AddForm();
                break;
            }
        }
    }
    AddForm() {
        this.AccountNameActionsFormGroup = new FormGroup({
            AccountExpenses: new FormControl(null, Validators.required,),
            AccountFication: new FormControl(null, Validators.required,),
            AccountFicationcost: new FormControl(null, Validators.required,),

            AccountType: new FormControl(null, Validators.required,),
            AccountNature: new FormControl(null, Validators.required,),
            AccountMenu: new FormControl(null, Validators.required,),

            MainAccount: new FormControl(null),
            IsMainAccount: new FormControl(false),

            NameArControl: new FormControl(null, Validators.required,),
            NameEnControl: new FormControl(null, Validators.required,),

            AccountNumber: new FormControl(null, Validators.required,),
            LevelCode: new FormControl(null, Validators.required,),

            NotMenu: new FormControl(false),
            IsNotActive: new FormControl(false),

        })
    }

    EditForm() {
        // const datepipe: DatePipe = new DatePipe('en-US')
        // let formattedDate = datepipe.transform(this.AccountsNameModel.Date, "yyyy-MM-dd");
        this.AccountNameActionsFormGroup = new FormGroup({
            AccountExpenses: new FormControl(this.AccountsNameModel.AccounExpensesTypeId, Validators.required,),
            AccountFication: new FormControl(this.AccountsNameModel.AccountFicationId, Validators.required,),
            AccountFicationcost: new FormControl(this.AccountsNameModel.AccountFicationType, Validators.required,),
            
            AccountType: new FormControl(this.AccountsNameModel.AccountType, Validators.required,),
            AccountNature: new FormControl(this.AccountsNameModel.AccountNature, Validators.required,),
            AccountMenu: new FormControl(this.AccountsNameModel.AccountMenuId, Validators.required,),

            MainAccount: new FormControl(this.AccountsNameModel.Parentcode, Validators.required,),
            // IsMainAccount: new FormControl(this.AccountsNameModel., ),


            NameArControl: new FormControl(this.AccountsNameModel.NameAr, Validators.required,),
            NameEnControl: new FormControl(this.AccountsNameModel.NameEn, Validators.required,),

            AccountNumber: new FormControl(this.AccountsNameModel.AccountNumber, Validators.required,),
            LevelCode: new FormControl(this.AccountsNameModel.Levelcode, Validators.required,),

            NotMenu: new FormControl(this.AccountsNameModel.IsNotMenu, ),
            // IsNotActive: new FormControl(this.AccountsNameModel.,),
        })
    }
    // ({ value: this.AccountsNameModel.NameAr, disabled: true }),
    ShowForm() {
        // const datepipe: DatePipe = new DatePipe('en-US')
        // let formattedDate = datepipe.transform(this.AccountsNameModel.Date, "yyyy-MM-dd");
        this.AccountNameActionsFormGroup = new FormGroup({
            AccountExpenses: new FormControl({ value:  this.AccountsNameModel.AccounExpensesTypeId, disabled: true }),
            AccountFication: new FormControl({ value:  this.AccountsNameModel.AccountFicationId,disabled: true }),
            AccountFicationcost: new FormControl({ value:  this.AccountsNameModel.AccountFicationType, disabled: true }),
            
            AccountType: new FormControl({ value:  this.AccountsNameModel.AccountType, disabled: true }),
            AccountNature: new FormControl({ value:  this.AccountsNameModel.AccountNature, disabled: true }),
            AccountMenu: new FormControl({ value:  this.AccountsNameModel.AccountMenuId, disabled: true }),

            MainAccount: new FormControl({ value:  this.AccountsNameModel.Parentcode,disabled: true }),
            // IsMainAccount: new FormControl(this.AccountsNameModel., ),


            NameArControl: new FormControl({ value:  this.AccountsNameModel.NameAr, disabled: true }),
            NameEnControl: new FormControl({ value:  this.AccountsNameModel.NameEn,disabled: true }),

            AccountNumber: new FormControl({ value:  this.AccountsNameModel.AccountNumber, disabled: true }),
            LevelCode: new FormControl({ value:  this.AccountsNameModel.Levelcode, disabled: true }),

            NotMenu: new FormControl({ value:  this.AccountsNameModel.IsNotMenu, disabled: true }),
            // IsNotActive: new FormControl(this.AccountsNameModel.,),
        })
    }

    onClick() {
        if (this.modelState == "Add") {
             this.Save();
        } else {
             this.Update();
        }
    }

    Save() {
        this.onWarning();
        var accountNameActionsFormGroup = this.AccountNameActionsFormGroup.getRawValue();
        var accountNameModel = new AccountNameModel;
        accountNameModel.Id = 0;
        accountNameModel.AccounExpensesTypeId = accountNameActionsFormGroup.AccountExpenses;
        accountNameModel.AccountFicationId = accountNameActionsFormGroup.AccountFication;
        accountNameModel.AccountFicationType = accountNameActionsFormGroup.AccountFicationcost;

        accountNameModel.AccountMenuId = accountNameActionsFormGroup.AccountMenu;
        accountNameModel.AccountType = accountNameActionsFormGroup.AccountType;
        accountNameModel.AccountNature = accountNameActionsFormGroup.AccountNature;

        accountNameModel.AccountNumber = accountNameActionsFormGroup.AccountNumber;
        accountNameModel.Parentcode = accountNameActionsFormGroup.MainAccount ??0;
        accountNameModel.NameAr = accountNameActionsFormGroup.NameArControl;
        accountNameModel.NameEn = accountNameActionsFormGroup.NameEnControl;

        accountNameModel.Levelcode = accountNameActionsFormGroup.LevelCode;
        accountNameModel.IsNotMenu = accountNameActionsFormGroup.NotMenu;

        this.accountsNameService.insert(accountNameModel).subscribe(
            result => {
                if (result) {
                    this.onSuccess();
                } else {
                    this.onError();
                }
            }
        )

    }

    Update() {
        this.onWarning();
        var accountNameActionsFormGroup = this.AccountNameActionsFormGroup.getRawValue();
        var accountNameModel = new AccountNameModel;
        accountNameModel.Id = this.itemId;
        accountNameModel.AccounExpensesTypeId = accountNameActionsFormGroup.AccountExpenses;
        accountNameModel.AccountFicationId = accountNameActionsFormGroup.AccountFication;
        accountNameModel.AccountFicationType = accountNameActionsFormGroup.AccountFicationcost;

        accountNameModel.AccountMenuId = accountNameActionsFormGroup.AccountMenu;
        accountNameModel.AccountType = accountNameActionsFormGroup.AccountType;
        accountNameModel.AccountNature = accountNameActionsFormGroup.AccountNature;

        accountNameModel.AccountNumber = accountNameActionsFormGroup.AccountNumber;
        accountNameModel.Parentcode = accountNameActionsFormGroup.MainAccount;
        accountNameModel.NameAr = accountNameActionsFormGroup.NameArControl;
        accountNameModel.NameEn = accountNameActionsFormGroup.NameEnControl;

        accountNameModel.Levelcode = accountNameActionsFormGroup.LevelCode;
        accountNameModel.IsNotMenu = accountNameActionsFormGroup.NotMenu;
        console.log(" this.accountNameModel", accountNameModel);

        this.accountsNameService.update(accountNameModel).subscribe(
            result => {
                if (result) {
                    console.log("Succsess");
                    this.onSuccess();
                    console.log(" Succsess.accountNameModel", accountNameModel);

                } else {
                    this.onError();
                }
            }
        )

    }

    onWarning(): void {

        this.notifications.create(this.translate.instant('Wait'),
            this.translate.instant('Action processing '),
            NotificationType.Warn, { timeOut: 3000, showProgressBar: true });
    }

    onError(): void {
        this.notifications.create(this.translate.instant('Error'),
            this.translate.instant('Something wrong'),
            NotificationType.Error, { timeOut: 3000, showProgressBar: true });
    }

    onSuccess(): void {

        this.notifications.create(this.translate.instant('Success'),
            this.translate.instant('Action Success'),
            NotificationType.Success, { timeOut: 3000, showProgressBar: true });
    }
   
   
  }