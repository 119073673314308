import { Component } from '@angular/core';

@Component({
    // moduleId: module.id,
    selector: 'council-member',
    templateUrl: 'council-member.component.html',
    styleUrls: ['council-member.component.scss']
})
export class CouncilMemberComponent {

}
