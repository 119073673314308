<div class="row listing-card-container">
  <div class="col-sm-6 col-lg-6 col-xl-4 col-12 mb-4" *ngFor="let item of data">
    <div class="card">
      <div class="position-relative">
        <a routerLink="#"><img class="card-img-top" [src]="item.img" alt="Card image cap"></a>
        <span class="badge badge-pill badge-theme-1 position-absolute badge-top-left">{{item.status}}</span>
      </div>
      <div class="card-body">
        <a routerLink="#">
          <h6 class="mb-3 listing-heading ellipsis">
            {{item.title}}
          </h6>
        </a>
        <footer>
          <p class="text-muted text-small mb-0 font-weight-light">{{item.date}}</p>
        </footer>
      </div>
    </div>
  </div>
</div>
