<div class="md-float-material form-material" >

    <div style="text-align: center;">
        <button class="btn btn-light" (click)="goBack()"
            style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Back"  | translate}}</button>
        <button
            id="smallModalSubmitButton" class="btn btn-secondary" (click)="Save()"
            style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Add"  | translate}}</button>
        
    </div>
 
    <form *ngIf="Form" [formGroup]="Form">
        <div class="form-group">
            <br>
            <h3 class="center" style="text-align: center;">{{ 'Basic Data' | translate }}</h3>
            <br>
      
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label >{{'Mmember Name'| translate}}</label>
                    <select
                    id="inputState"
                    class="form-control"
                    formControlName="MmemberName"
                  >
                   <option *ngFor="let item of members" [value]="item.Name">
                    {{item.Name }}
                   </option>
         
                   
                 </select>
                </div>
                <div class="form-group col-md-4">
                    <label >{{'Package'| translate}}</label>
                    <select
                    id="inputState"
                    class="form-control"
                    formControlName="PackagePrice"
                  >
                   <option *ngFor="let item of packages" [value]="item.Price">
                    {{item.Name }}
                   </option>
         
                   
                 </select>
                </div>
                <div class="form-group col-md-2">
                    <label >{{'Package Price'| translate}}</label>
                    <input formControlName="PackagePrice" type="text" class="form-control" readonly>
                </div>
            </div>
            <div class="form-row">
           
                <div class="form-group col-md-12">
                   <label >{{'Description'| translate}}</label>
                   <textarea formControlName="Description" type="text" class="form-control"></textarea>
               </div>
            
           </div>
            </div>
            
   
       </form>
       <form *ngIf="FamlyForm" [formGroup]="FamlyForm">
       
            <div class="form-group">
                <br>
                <h3 class="center" style="text-align: center;">{{ 'Family Info' | translate }}</h3>
                <br>
          
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label >{{'Name'| translate}}</label>
                        <input formControlName="Name" type="text" class="form-control">
                    </div>
                    <div class="form-group col-md-6">
                        <label >{{'Age'| translate}}</label>
                        <input formControlName="Age" type="number" class="form-control" >

                    </div>
                 
                </div>
            
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label >{{'Degree Of Kinship'| translate}}</label>
                        <select
                        id="inputState"
                        class="form-control"
                        formControlName="DegreeOfKinship"
                      >
                       <option  [value]="'Close to first class'">
                        {{ "Close to first class" | translate }}
                       </option>
                       <option  [value]="'Close to second degree'">
                         {{ "Close to second degree" | translate }}
                        </option>
                       
                     </select>                    </div>
                    <div class="form-group col-md-3">
                        <label >{{'Gender'| translate}}</label>
                        <select
                        id="inputState"
                        class="form-control"
                        formControlName="Gender"
                      >
                       <option  [value]="'Male'">
                        {{ "Male" | translate }}
                       </option>
                       <option  [value]="'Female'">
                         {{ "Female" | translate }}
                        </option>
                       
                     </select>
                    </div>
                    <div class="form-group col-md-3">
                        <button
                        id="smallModalSubmitButton" class="btn btn-primary" (click)="Save()"
                        style="font-weight: bold; ; text-align: center; margin-top: 10%;">{{"Add"  | translate}}</button>
                    
                    </div>
                </div>
                </div>
   
       </form>
       <div class="card" style="margin-bottom: 30px;">
        <div class="form-row">
            <table class=" table">
                <thead>
                   <th>{{'Name'|translate}}</th> 
                   <th>{{'Age'|translate}}</th> 
                   <th>{{'Degree Of Kinship'|translate}}</th> 
                   <th>{{'Gender'|translate}}</th> 
                </thead>
                <tbody>
                    <tr *ngFor="let item of famly">
                        <td>{{item.Name}}</td>
                        <td>{{item.Age}}</td>
                        <td>{{item.DegreeOfKinship | translate}}</td>
                        <td>{{item.Gender | translate}}</td>
                    </tr>
                </tbody>
            </table>
       
        </div>
     
    
    </div>
<br>

<div style="text-align: center;">
    <button class="btn btn-light" (click)="goBack()"
        style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Back"  | translate}}</button>
    <button
        id="smallModalSubmitButton" class="btn btn-secondary" (click)="Save()"
        style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Add"  | translate}}</button>
    
</div>
</div>


