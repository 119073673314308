

  <div class="row">
    <div class="col-12">
      <div class="card mb-4">
        <div class="card-body">
          <!-- <h5 class="mb-4">{{ 'From Date' | translate }}</h5> -->
          <div class="row">
            <div class="col-12 col-lg-6 mb-4">
              <label><span>{{ 'DateFrom' | translate }}</span></label>
              <input 
              type="text" onfocus="(this.type='date')" onblur="if(!this.value)this.type='text'"
              onchange="if(!this.value)this.type='text'" name="Date" class="form-control"
              [(ngModel)]="search.From"
              placeholder='{{"DateFrom" | translate}}'>
            </div>
            <div class="col-12 col-lg-6 mb-4">
              <label><span>{{ 'DateTo' | translate }}</span></label>
              <input 
              type="text" onfocus="(this.type='date')" onblur="if(!this.value)this.type='text'"
              onchange="if(!this.value)this.type='text'" name="Date" class="form-control"
              [(ngModel)]="search.To"
              placeholder='{{"DateTo" | translate}}'>
            </div>
            <!-- <div class="col-12 col-lg-6 mb-4">
              <label><span>{{ 'Type' | translate }}</span></label>
              <ng-select  style="display: inline-block; margin-left: 5px;  margin-right: 5px;" appearance="outline" name="Branch" [(ngModel)]="type"
              placeholder='{{"Type" | translate}}' #Branch
              labelForId="txtycd"
              (change)="changeType()"
              >
              <ng-option  [value]="'indirect'">{{"In the indirect way" | translate}}</ng-option>
              <ng-option  [value]="'In the direct way'">{{"In the direct way" | translate}}</ng-option>
          </ng-select>

            </div> -->
            
          <div class="col-12 col-lg-6 mb-4" >
            <label><span>{{ 'Type' | translate }}</span></label>
       
            <ng-select placeholder='{{"Type" | translate}}' (change)="changeType()"  [(ngModel)]="type">
              <ng-option  [value]="'indirect'">{{"In the indirect way" | translate}}</ng-option>
              <ng-option  [value]="'In the direct way'">{{"In the direct way" | translate}}</ng-option>
            </ng-select>
 
          </div>
            <div class="col-12 col-lg-6 mb-4 " style="margin-top:25px" >
              <button type="button" (click)="Search()" class="btn btn-primary default mb-1" style="margin: 1px;">{{'Search' | translate}}</button>{{" "}}
              <button type="button" (click)="print()" class="btn btn-secondary default mb-1 " style="margin: 1px;">{{'Print' | translate}}</button>{{" "}}
              <button type="button" (click)="exportTable()" class="btn btn-success default mb-1" style="margin: 1px;">{{'PDF' | translate}}</button>{{" "}}
              <button type="button"  class="btn btn-primary default mb-1" style="margin: 1px;">{{'Excel' | translate}}</button>{{" "}}
           
            </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  <div id="contentToConvert">
  <div class="row" >
    <div class="col-12">
      <div class="card mb-4">
        <div class="card-body">
            <h3 class="float-center" style="text-align: center;">{{ 'Cash Flows' | translate }}
               <span *ngIf="type == 'indirect'"> {{  'In the indirect way' |translate}} </span> 
                <span *ngIf="type != 'indirect'"> {{ 'In the direct way'|translate}} </span> 

               </h3>
            <h4 class="float-center" style="text-align: center;">{{  today| date:'dd/MM/yyyy' }}</h4>
            <h6 class="float-center" style="text-align: center;">{{ 'Future Core' | translate }}</h6>
           
        </div>
      </div>
    </div>
  </div>


<div class="card mb-12 tree-explorer-side col-md-12" *ngIf="data">
    <div class="card-body ">
    <div class="row">
        <table class="table">
            <thead>
                <th>{{'Name'|translate}}</th>
                <th>{{'Total'|translate}}</th>
            </thead>
          
        </table>
        <table class="table table-striped">
            <thead>
                <th>{{'Cash Flow From Operations'|translate}}</th>
                <th>{{'Value'|translate}}</th>
            </thead>
            <tbody>
                <tr *ngFor="let item of data.CashFlowFromOperations">
                    <td>{{lang==='ar-EG'?item.KeyAr:item.KeyEn}}</td>
                    <td>{{item.Value}}</td>
                </tr>
                <thead>
                  <th>{{'Total Cash Flow From Operations'|translate}}</th>
                  <th>{{totalCashFlowFromOperations}}</th>
              </thead>
            </tbody>
        </table>
    
    </div>
    <div class="row">
     
        <table class="table table-striped">
            <thead>
                <th>{{'Cash FlowFrom Investments'|translate}}</th>
                <th>{{'Value'|translate}}</th>

            </thead>
            <tbody>
                <tr *ngFor="let item of data.CashFlowFromInvestments">
                    <td>{{lang==='ar-EG'?item.KeyAr:item.KeyEn}}</td>
                    <td>{{item.Value}}</td>
                </tr>
                <thead>
                  <th>{{'Total Cash Flow From Investments'|translate}}</th>
                  <th>{{totalCashFlowFromInvestments}}</th>
              </thead>
            </tbody>
        </table>
    
    </div>
    <div class="row">
     
        <table class="table table-striped">
            <thead>
                <th>{{'Cash Flow From Financing'|translate}}</th>
                <th>{{'Value'|translate}}</th>

            </thead>
            <tbody>
                <tr *ngFor="let item of data.CashFlowFromFinancing">
                    <td>{{lang==='ar-EG'?item.KeyAr:item.KeyEn}}</td>
                    <td>{{item.Value}}</td>
                </tr>
                <thead>
                  <th>{{'Total Cash Flow From Financing'|translate}}</th>
                  <th>{{totalCashFlowFromFinancing}}</th>
              </thead>
            </tbody>
        </table>
    
    </div>
    </div>
  </div>
</div>
