export class ExpensesFilesModel{
    Id?:number;
    ExpensesId?:number;
    FileUrl?:string;
    constructor(item ?: ExpensesFilesModel){
        item = item ? item : {};
        this.Id = item.Id ? item.Id : 0;
        this.ExpensesId = item.ExpensesId ? item.ExpensesId : 0;
        this.FileUrl = item.FileUrl ? item.FileUrl : '';
    }
}