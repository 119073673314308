export class CheckBookModel{
  Id                  ?: number;
  BankId              ?: number;
  CheckBookNumber     ?: string;
  TheFirstSerialNumber?: string;
  TheLastSerialNumber ?: string;
  CurrencyId          ?: number;
  IsActive            ?: boolean;
  Description         ?: string;
  constructor(item ?: CheckBookModel){
    item = item ? item : {};
    this.Id = item.Id ? item.Id : 0;
    this.BankId               = item.BankId               ? item.BankId               : 0;
    this.CheckBookNumber      = item.CheckBookNumber      ? item.CheckBookNumber      : '';
    this.TheFirstSerialNumber = item.TheFirstSerialNumber ? item.TheFirstSerialNumber : '';
    this.TheLastSerialNumber  = item.TheLastSerialNumber  ? item.TheLastSerialNumber  : '';
    this.CurrencyId           = item.CurrencyId           ? item.CurrencyId           : 0;
    this.IsActive             = item.IsActive             ? item.IsActive             : false;
    this.Description          = item.Description          ? item.Description          : '';
  }
}