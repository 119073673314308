export class BranchModel{
    Id?:number;
    NameAr?:string;
    NameEn?:string;
    //Country?:string;
    Governorate?:string;
    Street?:string;
    BuildingNo?:number;
    // PostalCode?:string;
    NotableMark?:string;
    CountryId?:number;
    CityAr?:string;
    CityEn?:string;
    CountryAr?:string;
    CountryEn?:string;
    CityId?:number;
    Address?:string;
    CountryCode?:string;
    CityName?:string;

    constructor(obj?:BranchModel){
        obj = obj? obj:{};
        this.Id = obj.Id? obj.Id:0;
        this.NameAr = obj.NameAr? obj.NameAr:'';
        this.NameEn = obj.NameEn? obj.NameEn:'';
        this.CountryCode = obj.CountryCode? obj.CountryCode:'';
        this.CityName = obj.CityName? obj.CityName:'';
        //this.Country = obj.Country? obj.Country:'';
        this.Governorate = obj.Governorate? obj.Governorate:'';
        this.Street = obj.Street? obj.Street:'';
        this.BuildingNo = obj.BuildingNo? obj.BuildingNo:null;
        this.NotableMark = obj.NotableMark? obj.NotableMark:'';
        // this.PostalCode = obj.PostalCode? obj.PostalCode:'';
        this.CountryId = obj.Id? obj.CountryId:null;
        this.CityAr = obj.NameAr? obj.CityAr:'';
        this.CityEn = obj.NameAr? obj.CityEn:'';
        this.CountryAr = obj.NameAr? obj.CountryAr:'';
        this.CountryEn = obj.NameAr? obj.CountryEn:'';
        this.CityId = obj.CityId? obj.CityId:null;
        this.Address = obj.Address? obj.Address:'';
    }
}