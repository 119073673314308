import { CostCenterService } from './services/cost-center.service';
import { CostCenterModel } from './models/cost-center.model';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, OnInit } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { ProjectService } from 'src/app/Contracting/projects/projects.service';
import { ProjectModel } from 'src/app/Contracting/projects/models/project.model';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';

interface AccountNameNode {
    Id?: number;
    Children?: AccountNameNode[];
    Level?: number;
    Parentcode?:number;
    Name?:string;
  }
  
@Component({
    // moduleId: module.id,
    selector: 'cost-center',
    templateUrl: 'cost-center.component.html',
    styleUrls: ['cost-center.component.scss']
})
export class CostCenterComponent implements OnInit{
  declare lang: any;
   Form:FormGroup;
   projects:ProjectModel[]=[];
   AccountsNameList:AccountNameNode[]=[]
  centers:CostCenterModel[]=[
    {Acronym:'الفروع',CenterRating:'',CenterType:'',CostCenterNumber:1,Id:1,Level:1,NameAr:'الفروع',NameEn:'Branches',ParentId:0,Stop:false},
    {Acronym:'العيادات',CenterRating:'',CenterType:'',CostCenterNumber:1,Id:2,Level:1,NameAr:'العيادات',NameEn:'Clients',ParentId:0,Stop:false},
    {Acronym:'التسويات',CenterRating:'',CenterType:'',CostCenterNumber:1,Id:3,Level:1,NameAr:'التسويات',NameEn:'Settlements',ParentId:0,Stop:false},
    {Acronym:'العيادات 2',CenterRating:'',CenterType:'',CostCenterNumber:1,Id:4,Level:1,NameAr:'العيادات 2',NameEn:'Clients 2',ParentId:0,Stop:false},
    {Acronym:'عيادات فرع الدائري',CenterRating:'',CenterType:'',CostCenterNumber:5,Id:5,Level:1,NameAr:'عيادات فرع الدائري',NameEn:'Clients',ParentId:0,Stop:false},
    {Acronym:'فرع 1',CenterRating:'',CenterType:'',CostCenterNumber:1,Id:6,Level:2,NameAr:'فرع 1',NameEn:'branch 1',ParentId:1,ParentName:'الفروع', Stop:false},
    {Acronym:'فرع 2',CenterRating:'',CenterType:'',CostCenterNumber:1,Id:7,Level:2,NameAr:'فرع 2',NameEn:'branch 2',ParentId:1,ParentName:'الفروع', Stop:false},
    {Acronym:'فرع 3',CenterRating:'',CenterType:'',CostCenterNumber:1,Id:8,Level:2,NameAr:'فرع 3',NameEn:'branch 3',ParentId:1,ParentName:'الفروع', Stop:false},
  ]
   costCenter:CostCenterModel=new CostCenterModel();
   constructor(
    private translate: TranslateService, 
    public notifications: NotificationsService,
    private fb:FormBuilder,
    private projectService:ProjectService,
    private costCenterService:CostCenterService
    ){
    this.lang = localStorage.getItem('theme_lang');

   }
    ngOnInit(): void {
      this.costCenterService.getAll(0)
      .subscribe(data => {
        this.centers = data;
        if(this.centers && this.centers.length > 0){
          this.getDataNodes();

        }
        // this.Form = this.createForm();
      })
      this.costCenterService.GetLastCode()
      .subscribe(accountNumber=>{
        this.LastAccountNumber = accountNumber;
      })
      this.projectService.getAll()
      .subscribe(data => {
        this.projects = data;
      })

      
    }
    createForm():FormGroup{
      return this.fb.group({
        Id:[this.costCenter.Id],
        Acronym:[this.costCenter.Acronym],
        CenterRating:[this.costCenter.CenterRating],
        CenterType:[this.costCenter.CenterType],
        CostCenterNumber:[this.costCenter.CostCenterNumber],
        Level:[this.costCenter.Level],
        NameAr:[this.costCenter.NameAr],
        NameEn:[this.costCenter.NameEn],
        ParentId:[this.costCenter.ParentId],
        Stop:[this.costCenter.Stop],
        ParentName:[this.costCenter.ParentName],
        ProjectId : [this.costCenter.ProjectId]
      })
    }
    nodeList: AccountNameNode [] = [];
    openProjects = false;
    CenterRatingChanged(){
      debugger;
      if(this.Form.controls['CenterRating'].value == 'Project'){
        this.openProjects = true;
      }
      else{
        this.costCenter = this.Form.getRawValue();
        this.costCenter.ProjectId = 0;
       
        this.Form = this.createForm();
        this.openProjects = false;
      }
    }
    getDataNodes(){
        this.nodeCount = 0;
        this.nodeList = [];
        this.centers.forEach(element => {
          debugger;
          if(element.ParentId == 0){
            this.nodeList.push({
              Id : element.Id,
              Level : element.Level,
              Name : this.lang =='ar-EG' ? element.NameAr : element.NameEn,
              Parentcode : element.ParentId,
              Children : []
            });
          }
       else{
        this.nodeList.forEach(child => {
          if(child.Id == element.ParentId){
            child.Children.push({
              Id : element.Id,
              Level : element.Level,
              Name : this.lang =='ar-EG' ? element.NameAr : element.NameEn,
              Parentcode : element.ParentId,
              Children : []
            })
          }
          else{

            while(child.Children&&child.Children.length > 0){
              if(child.Id == element.ParentId){
                child.Children.push({
                  Id : element.Id,
                  Level : element.Level,
                  Name : this.lang =='ar-EG' ? element.NameAr : element.NameEn,
                  Parentcode : element.ParentId,
                  Children : []
                })
                break;
              }
              break;

            }
          }

          },
          );
       }
          this.nodeCount++;
        });
        
        console.log('this.nodeList',this.nodeList);
        
        this.drawTreeFromTop(this.nodeList);
    
        this.dataSource.data = null;
        this.dataSource.data = this.nodeList;
    
    
    
      }
      //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      ///////////////////////////////////////////////////////////
      // TREE CODE //
      ///////////////////////////////////////////////////////////
      nodeCount = 0;
      drawTreeFromTop(currentlevelList: AccountNameNode []){
        debugger;
        console.log("data count: ", this.AccountsNameList.length);
        console.log("node count: ", this.nodeCount);
        console.log("current nodes: ", this.nodeList);
        
        if(this.nodeCount >= this.AccountsNameList.length){
          console.log("we did it, men. we found the deepest nodes.");
          return currentlevelList;
        }
        else{
          console.log("we're not yet done... descending one level deeper into this madness");
          
          let nextLevelList = [];
          
          currentlevelList.forEach(node => {
            this.AccountsNameList.filter(x=>x.Parentcode == node.Id).forEach(childNode => {
              let tempNode: AccountNameNode = {};
              tempNode.Id = childNode.Id;
              tempNode.Children = [];
              tempNode.Level = node.Level + 1;
              node.Children.push(tempNode)
              this.nodeCount++;
              nextLevelList.push(tempNode);
            });
          });
          return this.drawTreeFromTop(nextLevelList);
        }
      }
      levelLimit = 5;
      treeControl = new NestedTreeControl<AccountNameNode>(node => node.Children);
      dataSource = new MatTreeNestedDataSource<AccountNameNode>();
      //limiting depth level to 4 + base (total of 5 nested nodes)
      hasChild = (_: number, node: AccountNameNode) => !!node.Children && node.Children.length > 0 && node.Level+1<this.levelLimit;
      formView = '';
    
      getAccountNameById(id: any){
        return this.AccountsNameList.find(x=>x.Id===id);
      }
      AddNewCostCenter(){
        this.costCenter.Id = 0;
        this.costCenter.Level = 1;
        this.costCenter.ParentId = 0;
        this.costCenter.ParentName = '';
        if(this.LastAccountNumber == 0){
          this.costCenter.CostCenterNumber  = 1;
        }
        else{

          this.costCenter.CostCenterNumber = this.LastAccountNumber;
        }
        this.Form = this.createForm();
        this.formCreated = true;
        this.CenterRatingChanged();
      }
      addChildCostCenter(Id){
        var i = this.centers.findIndex(x=>x.Id == Id);
        this.costCenter.Id = 0;

        if(i > -1){
          this.costCenter.Level = this.centers[i].Level + 1;
          this.costCenter.ParentId = Id;
          this.lang =='ar-EG' ? this.costCenter.ParentName = this.centers[i].NameAr : this.costCenter.ParentName = this.centers[i].NameEn;
          this.costCenter.CostCenterNumber = this.LastAccountNumber;
          this.Form = this.createForm();
          this.CenterRatingChanged();
      }
        else{
          this.costCenter.Level = 1;
          this.costCenter.ParentId = 0;
          this.costCenter.CostCenterNumber = this.LastAccountNumber;
          this.Form = this.createForm();
          this.CenterRatingChanged();

        }
        this.formCreated = true;

      }
      EditNode(Id){
        var i = this.centers.findIndex(x=>x.Id == Id);
        if(i > -1){
          this.costCenter = this.centers[i];
          
          this.Form = this.createForm();
          this.CenterRatingChanged();
        this.formCreated = true;

        }
      }
      formCreated = false;
      LastAccountNumber = 0;

      
      Save() {
        this.costCenter = this.Form.getRawValue();
        this.costCenterService.insert(this.costCenter).subscribe(
            result => {
                if (result > 0 ) {
                    // this.Form = this.createForm();
                    this.costCenter.Id = result;
                    this.centers.push(this.costCenter);
                    this.getDataNodes();
                    this.costCenter = new CostCenterModel();
                    this.formCreated = false;
                    this.LastAccountNumber++;
                    this.onSuccess();
                } else {
                    this.onError();
                }
            }
        )

    }

    Update() {
      debugger
      this.costCenter = this.Form.getRawValue();
      this.costCenterService.update(this.costCenter).subscribe(
          result => {
              if (result) {
                this.costCenter = new CostCenterModel();
                this.formCreated = false;

                  this.onSuccess();
              } else {
                  this.onError();
              }
          }
      )
    }
    Delete(){
      this.costCenterService.delete(this.costCenter)
      .subscribe(data => {
        var i = this.centers.findIndex(x=>x.Id == this.costCenter.Id);
        if(i > -1){
          this.centers.splice(i,1);
          this.getDataNodes();
          this.costCenter = new CostCenterModel();
          this.formCreated = false;
        }
      })
    }
    onSuccess(): void {
      debugger;
      this.notifications.create(this.translate.instant('Success'),
          this.translate.instant('Action Success'),
          NotificationType.Success, { timeOut: 300, showProgressBar: true });
  }
 
  onWarning(): void {

    this.notifications.create(this.translate.instant('Wait'),
        this.translate.instant('Action processing '),
        NotificationType.Warn, { timeOut: 3000, showProgressBar: true });
}

onError(): void {
    this.notifications.create(this.translate.instant('Error'),
        this.translate.instant('Something wrong'),
        NotificationType.Error, { timeOut: 3000, showProgressBar: true });
}
}
