import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, retry } from 'rxjs/operators';
import { BalanceNameModel } from './balance-name-model';

@Injectable({
  providedIn: 'root'
})
export class BalanceNameService {

  constructor(private http:HttpClient ) { }

  
  baseUrl = environment.baseApiUrl;
  token = localStorage.getItem("token");
  httpOptions = {
   headers: new HttpHeaders({
     // 'Content-Type': 'application/json; charset=utf-8',
     // 'Authorization': "Bearer " + token,
      "Authorization": "Bearer " + this.token,
      "Content-Type": "application/json"
   })
 }; 

 getAll(): Observable<any> {
   
  return this.http
    .get<any>(`${this.baseUrl}InventoryBalanceName/GetAll`,this.httpOptions)
    .pipe(catchError((error: any) => Observable.throw(error => error)));
}
getById(id:number): Observable<any> {

  return this.http
    .get<any>(`${this.baseUrl}InventoryBalanceName/GetById/${id}`,this.httpOptions)
    .pipe(catchError((error: any) => Observable.throw(error => error)));
}
insert(payload: BalanceNameModel): Observable<any> {
  
  return this.http
    .post<any>(`${this.baseUrl}InventoryBalanceName/Insert`, payload,this.httpOptions)
    .pipe(catchError((error: any) => Observable.throw(error => error)));
}

update(payload: BalanceNameModel): Observable<any> {
  return this.http
    .put<any>(`${this.baseUrl}InventoryBalanceName/Update`, payload,this.httpOptions)
    .pipe(catchError((error: any) => Observable.throw(error => error)));
}

delete(payload: BalanceNameModel): Observable<any> {
  return this.http
    .get<any>(`${this.baseUrl}InventoryBalanceName/Delete/${payload.Id}`,this.httpOptions)
    .pipe(catchError((error: any) => Observable.throw(error => error)));
}

  deleteList(payload: BalanceNameModel []): Observable<any> {
    return this.http
      .post<any>(`${this.baseUrl}InventoryBalanceName/DeleteList`,JSON.stringify(payload),this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error => error)));
  }
// catchError(error: any){
//   let erroeMessage = "";
//     if (error.error instanceof ErrorEvent) {
//       erroeMessage = error.error.message;
//     }
//     else {
//       erroeMessage = `Error code : ${error.status} \n Error Message :${error.message}`
//     }
//     window.alert(erroeMessage);
//     return throwError(erroeMessage);
// }
}
// function catchError(arg0: (error: any) => Observable<never>): import("rxjs").OperatorFunction<any, any> {
//   throw new Error('Function not implemented.');
// }

