export class CostCenterModel{
    Id?:number;
    NameAr          ?:string;
    NameEn          ?:string;
    CostCenterNumber?:number;
    Level           ?:number;
    Stop            ?:boolean;
    ParentId        ?:number;
    ProjectId        ?:number;
    ParentName       ?:string;
    CenterType      ?:string;
    CenterRating    ?:string;
    Acronym         ?:string;
    constructor(item ?: CostCenterModel){
        item = item ? item : {};
        this.Id = item.Id ? item.Id : 0;
        this.NameAr           = item.NameAr           ? item.NameAr           : '';
        this.NameEn           = item.NameEn           ? item.NameEn           : '';
        this.CostCenterNumber = item.CostCenterNumber ? item.CostCenterNumber : 0;
        this.Level            = item.Level            ? item.Level            : 0;
        this.Stop             = item.Stop             ? item.Stop             : false;
        this.ParentId         = item.ParentId         ? item.ParentId         : 0;
        this.ProjectId         = item.ProjectId         ? item.ProjectId         : 0;
        this.CenterType       = item.CenterType       ? item.CenterType       : '';
        this.CenterRating     = item.CenterRating     ? item.CenterRating     : '';
        this.Acronym          = item.Acronym          ? item.Acronym          : '';
        this.ParentName          = item.ParentName          ? item.ParentName          : '';
    }
}