<div class="pagecontent">
    <div id="redirectCards" style="display: block;">
        <div class="glide__slide ng-star-inserted glide__slide--active d-inline-block redirect-card">
            <div class="icon-row-item">
                <div class="card">
                    <div class="card-body text-center" routerLink="Purchase-Orders"><i class="iconsminds-shopping-cart iconfont-30"></i>
                        <a>{{"PurchaseOrders" | translate}}</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="glide__slide ng-star-inserted glide__slide--active d-inline-block redirect-card">
            <div class="icon-row-item">
                <div class="card">
                    <div class="card-body text-center" routerLink="Request-Receipts"><i class="iconsminds-receipt-4 iconfont-30"></i>
                        <a>{{"RequestReceipts" | translate}}</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="glide__slide ng-star-inserted glide__slide--active d-inline-block redirect-card">
            <div class="icon-row-item">
                <div class="card">
                    <div class="card-body text-center" routerLink="Purchase-Invoices"><i class="iconsminds-testimonal iconfont-30"></i>
                        <a>{{"PurchaseInvoices" | translate}}</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="glide__slide ng-star-inserted glide__slide--active d-inline-block redirect-card">
            <div class="icon-row-item">
                <div class="card">
                    <div class="card-body text-center" routerLink="Returned-Purchases"><i class="simple-icon-action-undo iconfont-30"></i>
                        <a>{{"ReturnedPurchases" | translate}}</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="glide__slide ng-star-inserted glide__slide--active d-inline-block redirect-card">
            <div class="icon-row-item">
                <div class="card">
                    <div class="card-body text-center" routerLink="Quotation-Requests"><i class="iconsminds-cart-quantity iconfont-30"></i>
                        <a>{{"QuotationRequests" | translate}}</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="glide__slide ng-star-inserted glide__slide--active d-inline-block redirect-card">
            <div class="icon-row-item">
                <div class="card">
                    <div class="card-body text-center" routerLink="Purchase-Quotations"><i class="iconsminds-checkout iconfont-30"></i>
                        <a>{{"PurchaseQuotations" | translate}}</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="glide__slide ng-star-inserted glide__slide--active d-inline-block redirect-card">
            <div class="icon-row-item">
                <div class="card">
                    <div class="card-body text-center" routerLink="Config"><i class="iconsminds-gears iconfont-30"></i>
                        <a>{{"ConfigPurchases" | translate}}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="pagecontent">
    <div id="redirectCards" style="display: block;">
        <div class="glide__slide ng-star-inserted glide__slide--active d-inline-block redirect-card">
            <div class="icon-row-item">
                <div class="card">
                    <div class="card-body text-center" routerLink="Purchase-Orders"><i class="iconsminds-user iconfont-30"></i>
                        <h3>{{"PurchaseOrders" | translate}}</h3>
                    </div>
                </div>
            </div>
        </div>
        <div class="glide__slide ng-star-inserted glide__slide--active d-inline-block redirect-card">
            <div class="icon-row-item">
                <div class="card">
                    <div class="card-body text-center" routerLink="Request-Receipts"><i class="iconsminds-box-close iconfont-30"></i>
                        <h3>{{"RequestReceipts" | translate}}</h3>
                    </div>
                </div>
            </div>
        </div>
        <div class="glide__slide ng-star-inserted glide__slide--active d-inline-block redirect-card">
            <div class="icon-row-item">
                <div class="card">
                    <div class="card-body text-center" routerLink="Purchase-Invoices"><i class="iconsminds-box-close iconfont-30"></i>
                        <h3>{{"PurchaseInvoices" | translate}}</h3>
                    </div>
                </div>
            </div>
        </div>
        <div class="glide__slide ng-star-inserted glide__slide--active d-inline-block redirect-card">
            <div class="icon-row-item">
                <div class="card">
                    <div class="card-body text-center" routerLink="Returned-Purchases"><i class="iconsminds-box-close iconfont-30"></i>
                        <h3>{{"ReturnedPurchases" | translate}}</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
