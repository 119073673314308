<!--component html goes here -->
<div style="text-align: center;">
  <a class="btn btn-light" 
      routerLink=".."
      style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Back" |
      translate}}</a>
  <button 
      *ngIf="Form && Form.controls['Id'].value == 0 && formCreated"
       id="smallModalSubmitButton"
      class="btn btn-secondary" 
      (click)="Save()"
      style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Add" |
      translate}}</button>
  <button 
     *ngIf="Form && Form.controls['Id'].value > 0&& formCreated"
      id="smallModalSubmitButton"
      class="btn btn-secondary" 
      (click)="Update()"
      style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Edit"
      | translate}}</button>
      <button 
      *ngIf="Form && Form.controls['Id'].value > 0 && formCreated"
       id="smallModalSubmitButton"
       (click)="Delete()"
       class="btn btn-danger" 
       style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Delete"
       | translate}}</button>
</div>
<div class="big-wrapper row" >

<div class="card mb-4 tree-explorer-side col-md-3">
  <div class="card-body ">
  <div class="row">
      <h1>{{"Cost Centers" | translate}}</h1>
   <div>
      <button  
      type="button" class="btn btn-default add-node-button" style="margin-top:  7px; ;" (click)="AddNewCostCenter()">
          <a class="plus-sign" style="color: black;">+</a>
         </button>
   </div>
  </div>
      <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
          <!-- This is the tree node template for leaf nodes -->


          <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
              <li class="mat-tree-node">
                  <!-- use a disabled button to provide padding for tree leaf -->
                  <a class="node-text-clickable" (click)="EditNode(node.Id)">{{node.Name}}</a>
                  <button  *ngIf="node.Level<levelLimit-1"
                  type="button" class="btn btn-default add-node-button" (click)="addChildCostCenter(node.Id)">
                      <a class="plus-sign" style="color: black;">+</a>
                     </button>
              </li>
          </mat-tree-node>
          <!-- This is the tree node template for expandable nodes -->
          <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
              <li>
                  <div class="mat-tree-node">
                      <button mat-icon-button matTreeNodeToggle class="mat-toggle-button-custom"
                          [attr.aria-label]="'Toggle ' + node.Name">
                          <mat-icon class="mat-icon-rtl-mirror mat-toggle-icon-custom">
                              {{treeControl.isExpanded(node) ? 'expand_more' :
                              (lang==='ar-EG'?'chevron_left':'chevron_right')}}
                          </mat-icon>
                      </button>
                      <a class="node-text-clickable" (click)="EditNode(node.Id)">
                          {{node.Name}}
                          </a>
                          
                      <button  type="button" class="btn btn-default add-node-button" (click)="addChildCostCenter(node.Id)">
                       <a class="plus-sign" style="color: black;">+</a>
                      </button>
                  </div>
                  <ul [class.example-tree-invisible]="!treeControl.isExpanded(node)">
                      <ng-container matTreeNodeOutlet></ng-container>
                  </ul>
              </li>
          </mat-nested-tree-node>

      </mat-tree>
  </div>
</div>
<!-- <simple-notifications></simple-notifications> -->
<div class="mb-8 col-md-8">
<form *ngIf="Form&& formCreated" [formGroup]="Form" >
  <div class="form-row ">
  <div class="form-group col-md-2"></div>

  <div class="form-group col-md-5">
    <label for="inputState">{{'Center Type'|translate}}</label>
    <select id="inputState" formControlName="CenterType" class="form-control">
      <option selected="" value="">{{'Choose...'|translate}}</option>
      <option value="Main">{{'Main'|translate}}</option>
      <option value="Sub">{{'Sub'|translate}}</option>
    </select>
  </div>

  <div class="form-group col-md-5">
    <label for="inputState">{{'Center rating'|translate}}</label>
    <select id="inputState" formControlName="CenterRating" class="form-control" (change)="CenterRatingChanged()" >
      <option selected="" value="">{{'Choose...'|translate}}</option>
      <option value="Cost Center">{{'Cost Center'|translate}}</option>
      <option value="Project">{{'Project'|translate}}</option>
    </select>
  </div>
</div>

  <div class="form-group row" *ngIf="openProjects" >
    <label class="col-sm-2  col-form-label">{{'Projects'|translate}}</label>
    
    <div class="col-sm-10">
      <select id="inputState" formControlName="ProjectId" class="form-control">
      <option selected="" value="0">{{'Choose...'|translate}}</option>
      <option *ngFor="let item of projects" value="item.Id">{{lang==='ar-EG'?item.NameAr:item.NameEn}}</option>
    </select>
  </div>
  </div>
<div class="form-group row">
    <label  class="col-sm-2  col-form-label">{{"NumberCostCenter" | translate}}</label>
    <div class="col-sm-5"><input type="number" formControlName="CostCenterNumber" class="form-control" readonly>
    </div>
    
    <label  class=" col-form-label">{{'Level'|translate}}</label>
    <div class="col-sm-3"><input type="text" formControlName="Level"  class="form-control" readonly>
    </div>
    <div class="mb-4"><div class="custom-control custom-checkbox "><input formControlName="Stop" type="checkbox" id="customCheckThis" class="custom-control-input"><label for="customCheckThis" class="custom-control-label">{{'Stop'|translate}}</label></div></div>
  </div>

  <div class="form-group row">
    <label for="inputEmail3" class="col-sm-2 col-form-label">{{'Name English'|translate}}</label>
    <div class="col-sm-10"><input type="text" id="inputEmail3" formControlName="NameEn" class="form-control" >
    </div>
  </div>
  <div class="form-group row">
    <label for="inputEmail3" class="col-sm-2 col-form-label">{{'Name Arabic'|translate}}</label>
    <div class="col-sm-10"><input type="text" id="inputEmail3" formControlName="NameAr" class="form-control" >
    </div>
  </div>
  <div class="form-group row">
    <label for="inputEmail3" class="col-sm-2 col-form-label">{{'Higher Center'|translate}}</label>
    <div class="col-sm-4"><input type="number" id="inputEmail3" formControlName="ParentId" class="form-control" readonly>
    </div>
      <div class="col-sm-6"><input type="text"  formControlName="ParentName"  id="inputEmail3" class="form-control" readonly>
    </div>
  </div>

  <div class="form-group row">
    <label for="inputEmail3" class="col-sm-2 col-form-label">{{'Acronym'|translate}}</label>
    <div class="col-sm-10"><input type="text" formControlName="Acronym" id="inputEmail3" class="form-control" >
    </div>
  </div>
</form>
</div>
</div>