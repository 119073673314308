<app-list-page-header *ngIf="tableView" [itemOptionsPerPage]="[5,10,20]" [itemsPerPage]="tableEngine.itemsPerPage"
    [selectAllState]="tableEngine.selectAllState" (addNewItem)="showModal(null,'Add')" [tableEngine]="this.tableEngine"
    (selectAllChange)="tableEngine.selectAll($event)" (changeOrderBy)="prepChangeSort($event)"
    [SearchKey]="'Date'" [AddEnabled]="false"
    (searchKeyUp)="tableEngine.filterDataByDateRange(this.SearchFrom,this.SearchTo,'Date')" [dateSearch]=true>
</app-list-page-header>



<div class="form-group" *ngIf="!tableView" charset=UTF8>
    <div class="modal-body">
        <form class="md-float-material form-material" [formGroup]="mainForm">

            <div style="text-align: center;">
                <button class="btn btn-light" (click)="goBack()"
                    style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Back"  | translate}}</button>
                <button class="btn btn-primary" (click)="exportTable()"
                    style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Print"  | translate}}</button>
            </div>
            <div class="form-group" id="printingTest" style="margin-top: 10px;">
                <div *ngIf="printing" class="form-group form-primary" style=" background-color: #dddddd;">
                    <label class="float-label push righ" style="display: block; font-size: 40px; text-align: center;">{{"CashierOrders" | translate}}</label>
                    <label class="float-label push righ" style="display: block; font-size: 20px; text-align: center;">{{"PrintedOnDate" | translate}} : {{printingDate | date:"dd/MM/yyyy - HH:mm:ss"}}</label>
                  <span class="form-bar"></span>
                </div>
  
                <br>

                <div class="row">
                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"CashierOrderCode" | translate}}</label>
                        <label class="form-control" type="text" style="border: unset;" name="Code">
                            {{modelObject.Id>0?modelObject.Code:0}}
                        </label>
                    </div>

                    <br>

                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"CashierOrderSessionCode" | translate}}</label>
                        <label class="form-control" type="text" style="border: unset;" name="SessionCode">
                            {{modelObject.Id>0?modelObject.SessionCode:0}}
                        </label>
                    </div>

                    <br>

                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"CashierOrderCashRegisterCode" | translate}}</label>
                        <label class="form-control" type="text" style="border: unset;" name="CashRegisterCode">
                            {{modelObject.Id>0?modelObject.CashRegisterCode:0}}
                        </label>
                    </div>

                </div>

                <br>

                <div class="row">
                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"CashierOrderCashierName" | translate}}</label>
                        <label class="form-control" type="text" style="border: unset;" name="CashierName">
                            {{modelObject.Id>0?(lang==='ar-EG'?modelObject.CashierNameAr:modelObject.CashierNameEn):'None' | translate}}
                        </label>
                    </div>

                    <br>

                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"CashierOrderClientName" | translate}}</label>
                        <label class="form-control" type="text" style="border: unset;" name="ClientName">
                            {{modelObject.Id>0?(lang==='ar-EG'?modelObject.ClientNameAr:modelObject.ClientNameEn):'NoClient' | translate}}
                        </label>
                    </div>

                    <br>

                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"CashierOrderDate" | translate}}</label>
                        <label class="form-control" type="text" style="border: unset;" name="Date">
                            {{modelObject.Id>0?(modelObject.ModifiedDate | date:"dd/MM/yyyy"):"None" | translate}}
                        </label>
                    </div>

                </div>

                <br>

                <div class="row">
                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"CashierOrderIsRefund" | translate}}</label>
                        <label class="form-control" type="text" style="border: unset;" name="IsRefund">
                            {{modelObject.Id>0?(modelObject.IsRefund?("CashierOrderIsRefundTrue"|translate):("CashierOrderIsRefundFalse"|translate)):"None" | translate}}
                        </label>
                    </div>

                    <br>

                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"CashierOrderStatus" | translate}}</label>
                        <label class="form-control" type="text" style="border: unset;" name="Status">
                            {{modelObject.Id>0?(modelObject.Status | translate):"None" | translate}}
                        </label>
                    </div>

                </div>

                <br>

                <div class="row" style="height: 160px;">
                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"CashierOrderNote" | translate}}</label>
                        <label class="form-control" type="text"
                            style="white-space:pre-wrap; word-wrap:break-word; width: 319%; height: 150px; overflow: auto;"
                            name="Note">{{modelObject.Note}}</label>
                        <span class="form-bar"></span>
                    </div>
                </div>

                <br>
                <br>

                <label class="float-label push righ" style="margin-top: 15px;">{{"CashierOrderItems" |
                    translate}}</label>
                <div class="card" style="min-width: 900px !important;">
                    <div class="card-body">
                        <div>
                            <div style="width: 100%;">
                                <div>
                                    <table class="table">
                                        <thead class="thead-light">
                                            <th>{{"CashierOrderItemName" | translate}}</th>
                                            <th>{{"CashierOrderItemUnit" | translate}}</th>
                                            <th>{{"CashierOrderItemUnitCost" | translate}}</th>
                                            <th>{{"CashierOrderItemQuantity" | translate}}</th>
                                            <th>{{"CashierOrderItemTotalPrice" | translate}}</th>
                                            <th>{{"CashierOrderItemDiscountValue" | translate}}</th>
                                            <th>{{"CashierOrderItemNetPrice" | translate}}</th>
                                            <th>{{"CashierOrderItemTotalTaxValues" | translate}}</th>
                                            <th>{{"CashierOrderItemGrossPrice" | translate}}</th>
                                            <th>{{"CashierOrderItemNote" | translate}}</th>
                                        </thead>
                                        <tbody>

                                            <tr *ngFor="let obj of modelObject.Items;  let i=index">
                                                <td style="vertical-align: middle;">
                                                    {{obj.ItemId>0?(lang==='ar-EG'?obj.ItemNameAr:obj.ItemNameEn):"None"|translate}}
                                                </td>
                                                <td style="vertical-align: middle;">
                                                    {{obj.UnitId>0?(lang==='ar-EG'?obj.UnitNameAr:obj.UnitNameEn):"None"|translate}}
                                                </td>
                                                <td style="vertical-align: middle;">
                                                    {{obj.UnitCost.toFixed(2)}}
                                                </td>
                                                <td style="vertical-align: middle;">
                                                    {{obj.Quantity.toFixed(2)}}
                                                </td>
                                                <td style="vertical-align: middle;">
                                                    {{obj.TotalPrice.toFixed(2)}}
                                                </td>
                                                <td style="vertical-align: middle;">
                                                    {{obj.DiscountValue.toFixed(2)}}{{obj.DiscountIsRate?'%':''}}
                                                </td>
                                                <td style="vertical-align: middle;">
                                                    {{obj.NetPrice.toFixed(2)}}
                                                </td>
                                                <td style="vertical-align: middle;">
                                                    {{obj.AddedValueTax.toFixed(2)}}
                                                </td>
                                                <td style="vertical-align: middle;">
                                                    {{obj.GrossPrice.toFixed(2)}}
                                                </td>
                                                <td style="vertical-align: middle; white-space:pre-wrap; word-wrap:break-word;">
                                                    {{obj.Note}}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <br>

                <div class="row">
                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"CashierOrderItemTotalPrices" | translate}}</label>
                        <label class="form-control" type="text" style="border: unset;" name="ItemTotalPrices">
                            {{modelObject.Id>0?modelObject.ItemTotalPrices:0}}
                        </label>
                    </div>

                    <br>

                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"CashierOrderItemTotalDiscounts" | translate}}</label>
                        <label class="form-control" type="text" style="border: unset;" name="ItemTotalDiscounts">
                            {{modelObject.Id>0?modelObject.ItemTotalDiscounts.toFixed(2):0}}
                        </label>
                    </div>

                </div>


                <br>

                <div class="row">

                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"CashierOrderItemTotalNetPrices" | translate}}</label>
                        <label class="form-control" type="text" style="border: unset;" name="ItemTotalNetPrices">
                            {{modelObject.Id>0?modelObject.ItemTotalNetPrices.toFixed(2):0}}
                        </label>
                    </div>

                    <br>

                    <!-- <div class=" col-md-4">
                        <label class="float-label push righ">{{"CashierOrderItemTotalTaxes" | translate}}</label>
                        <label class="form-control" type="text" style="border: unset;" name="ItemTotalTaxes">
                            {{modelObject.Id>0?modelObject.ItemTotalTaxes.toFixed(2):0}}
                        </label>
                    </div> -->

                </div>


                <br>

                <div class="row">

                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"CashierOrderTotalValue" | translate}}</label>
                        <label class="form-control" type="text" style="border: unset;" name="TotalValue">
                            {{modelObject.Id>0?modelObject.TotalValue.toFixed(2):0}}
                        </label>
                    </div>

                    <br>

                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"CashierOrderPaymentMethod" | translate}}</label>
                        <label class="form-control" type="text" style="border: unset;" name="PaymentMethodId">
                            {{modelObject.Id>0?(lang==='ar-EG'?modelObject.PaymentMethodAr:modelObject.PaymentMethodEn):'None' | translate}}
                        </label>
                    </div>
                </div>


                <br>

                <div class="row">

                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"CashierOrderPaidAmount" | translate}}</label>
                        <label class="form-control" type="text" style="border: unset;" name="PaidAmount">
                            {{modelObject.Id>0?modelObject.PaidAmount:0}}
                        </label>
                    </div>

                    <br>

                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"CashierOrderChangeGiven" | translate}}</label>
                        <label class="form-control" type="text" style="border: unset;" name="ChangeGiven">
                            {{modelObject.Id>0?modelObject.ChangeGiven:0}}
                        </label>
                    </div>
                </div>


                <br>

                <div class="row">

                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"CashierOrderCounterCashBeforeCheckout" | translate}}</label>
                        <label class="form-control" type="text" style="border: unset;" name="TotalValue">
                            {{modelObject.Id>0?modelObject.CounterCashBeforeCheckout.toFixed(2):0}}
                        </label>
                    </div>

                    <br>

                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"CashierOrderCounterCashAfterCheckout" | translate}}</label>
                        <label class="form-control" type="text" style="border: unset;" name="PaymentMethodId">
                            {{modelObject.Id>0?modelObject.CounterCashAfterCheckout.toFixed(2):0}}
                        </label>
                    </div>
                </div>

            </div>
        </form>
    </div>
</div>


<div *ngIf="tableView" class="FormScreen" class="tableScreen">

    <div id="divmiddle" style="display: block; margin-top: 5px;">

        <div style="width: 100%;">
            <div>
                <div class="card" style="min-width: 900px !important;">
                    <div class="card-body">
                        <table class="table table-hover" style="margin: unset !important;">
                            <thead class="no-border-top">
                                <!-- <th style="text-align: center; padding:5px;">
                                    <div class=" text-zero" style="display: inline-block;">
                                        <div class="top-right-button-container2 btn-group" dropdown>
                                            <div class="btn btn-primary pl-4 pr-0 check-button">
                                                <label class="custom-control custom-checkbox mb-0 d-inline-block"
                                                    style="width: 10px;">
                                                    <input type="checkbox" class="custom-control-input"
                                                        [checked]="tableEngine.selectAllState==='checked'"
                                                        [indeterminate]="tableEngine.selectAllState === 'indeterminate'"
                                                        (change)="tableEngine.selectAll($event)">
                                                    <span class="custom-control-label">&nbsp;</span>
                                                </label>
                                            </div>
                                            <button id="button-split" type="button" dropdownToggle style="width: 10px;"
                                                [ngClass]="(tableEngine.selected.length == 0)?
                                                'btn btn-outline-primary dropdown-toggle dropdown-toggle-split':
                                                'btn btn-primary dropdown-toggle dropdown-toggle-split'"
                                                aria-controls="dropdown-split">
                                                <span class="caret"></span>
                                            </button>
                                            <ul id="dropdown-split" *dropdownMenu
                                                class="dropdown-menu dropdown-menu-right" role="menu"
                                                aria-labelledby="button-split">
                                                <li role="menuitem"><a class="dropdown-item">
                                                        <button class="btn btn-danger d-inline-block"
                                                            style=" font-weight: bold; text-align: center; padding: 0px; width: 150px; height: 30px;"
                                                            (click)="tableEngine.showDelModal('delMulti')"
                                                            [disabled]="tableEngine.selected.length===0 || tableEngine.currentAction==='loading'">{{"DeleteSelected"
                                                            |
                                                            translate}}</button></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </th> -->

                                <th class="sorticon" (click)="prepChangeSort('CashierOrderCode')"
                                    [ngClass]="(itemOrder.label==='CashierOrderCode')?'sortkey sorticon':'sorticon'">
                                    <div
                                        [ngClass]="{'iconsminds-up---down': (itemOrder.label!='CashierOrderCode'), 
                                    'iconsminds-down': ((itemOrder.label==='CashierOrderCode') && tableEngine.orderAsc === true),
                                    'iconsminds-up': ((itemOrder.label==='CashierOrderCode') && tableEngine.orderAsc === false)}">
                                    </div>
                                </th>
                                <th class="sortOption" (click)="prepChangeSort('CashierOrderCode')"
                                    [ngClass]="{'sortkey': (itemOrder.label==='CashierOrderCode')}">
                                    {{"CashierOrderCode" | translate}}
                                </th>

                                <th class="sorticon" (click)="prepChangeSort('CashierOrderDate')"
                                    [ngClass]="(itemOrder.label==='CashierOrderDate')?'sortkey sorticon':'sorticon'">
                                    <div
                                        [ngClass]="{'iconsminds-up---down': (itemOrder.label!='CashierOrderDate'), 
                                    'iconsminds-down': ((itemOrder.label==='CashierOrderDate') && tableEngine.orderAsc === true),
                                    'iconsminds-up': ((itemOrder.label==='CashierOrderDate') && tableEngine.orderAsc === false)}">
                                    </div>
                                </th>
                                <th class="sortOption" (click)="prepChangeSort('CashierOrderDate')"
                                    [ngClass]="{'sortkey': (itemOrder.label==='CashierOrderDate')}">
                                    {{"CashierOrderDate" | translate}}
                                </th>

                                <th class="sorticon" (click)="prepChangeSort('CashierOrderCashierName')"
                                    [ngClass]="(itemOrder.label==='CashierOrderCashierName')?'sortkey sorticon':'sorticon'">
                                    <div
                                        [ngClass]="{'iconsminds-up---down': (itemOrder.label!='CashierOrderCashierName'), 
                                    'iconsminds-down': ((itemOrder.label==='CashierOrderCashierName') && tableEngine.orderAsc === true),
                                    'iconsminds-up': ((itemOrder.label==='CashierOrderCashierName') && tableEngine.orderAsc === false)}">
                                    </div>
                                </th>
                                <th class="sortOption" (click)="prepChangeSort('CashierOrderCashierName')"
                                    [ngClass]="{'sortkey': (itemOrder.label==='CashierOrderCashierName')}">
                                    {{"CashierOrderCashierName" | translate}}
                                </th>

                                <th class="sorticon" (click)="prepChangeSort('CashierOrderClientName')"
                                    [ngClass]="(itemOrder.label==='CashierOrderClientName')?'sortkey sorticon':'sorticon'">
                                    <div
                                        [ngClass]="{'iconsminds-up---down': (itemOrder.label!='CashierOrderClientName'), 
                                    'iconsminds-down': ((itemOrder.label==='CashierOrderClientName') && tableEngine.orderAsc === true),
                                    'iconsminds-up': ((itemOrder.label==='CashierOrderClientName') && tableEngine.orderAsc === false)}">
                                    </div>
                                </th>
                                <th class="sortOption" (click)="prepChangeSort('CashierOrderClientName')"
                                    [ngClass]="{'sortkey': (itemOrder.label==='CashierOrderClientName')}">
                                    {{"CashierOrderClientName" | translate}}
                                </th>

                                <th class="sorticon" (click)="prepChangeSort('CashierOrderIsRefund')"
                                    [ngClass]="(itemOrder.label==='CashierOrderIsRefund')?'sortkey sorticon':'sorticon'">
                                    <div
                                        [ngClass]="{'iconsminds-up---down': (itemOrder.label!='CashierOrderIsRefund'), 
                                    'iconsminds-down': ((itemOrder.label==='CashierOrderIsRefund') && tableEngine.orderAsc === true),
                                    'iconsminds-up': ((itemOrder.label==='CashierOrderIsRefund') && tableEngine.orderAsc === false)}">
                                    </div>
                                </th>
                                <th class="sortOption" (click)="prepChangeSort('CashierOrderIsRefund')"
                                    [ngClass]="{'sortkey': (itemOrder.label==='CashierOrderIsRefund')}">
                                    {{"CashierOrderIsRefund" | translate}}
                                </th>

                                <th class="sorticon" (click)="prepChangeSort('CashierOrderStatus')"
                                    [ngClass]="(itemOrder.label==='CashierOrderStatus')?'sortkey sorticon':'sorticon'">
                                    <div
                                        [ngClass]="{'iconsminds-up---down': (itemOrder.label!='CashierOrderStatus'), 
                                    'iconsminds-down': ((itemOrder.label==='CashierOrderStatus') && tableEngine.orderAsc === true),
                                    'iconsminds-up': ((itemOrder.label==='CashierOrderStatus') && tableEngine.orderAsc === false)}">
                                    </div>
                                </th>
                                <th class="sortOption" (click)="prepChangeSort('CashierOrderStatus')"
                                    [ngClass]="{'sortkey': (itemOrder.label==='CashierOrderStatus')}">
                                    {{"CashierOrderStatus" | translate}}
                                </th>

                                <th class="sorticon" (click)="prepChangeSort('CashierOrderTotalValue')"
                                    [ngClass]="(itemOrder.label==='CashierOrderTotalValue')?'sortkey sorticon':'sorticon'">
                                    <div
                                        [ngClass]="{'iconsminds-up---down': (itemOrder.label!='CashierOrderTotalValue'), 
                                    'iconsminds-down': ((itemOrder.label==='CashierOrderTotalValue') && tableEngine.orderAsc === true),
                                    'iconsminds-up': ((itemOrder.label==='CashierOrderTotalValue') && tableEngine.orderAsc === false)}">
                                    </div>
                                </th>
                                <th class="sortOption" (click)="prepChangeSort('CashierOrderTotalValue')"
                                    [ngClass]="{'sortkey': (itemOrder.label==='CashierOrderTotalValue')}">
                                    {{"CashierOrderTotalValue" | translate}}
                                </th>
                                <th [colSpan]="3"
                                    style="text-align: center; padding: unset !important; vertical-align: middle;">

                                    <div id="displayOptions" style="
                                        display: inline; margin-top: 10px; margin-left: 5px; margin-right: 5px;">
                                        <span class="text-muted text-small" style=" width: 50px; height: 40px;">
                                            {{"ResultCount" | translate}}
                                        </span>
                                        <span dropdown class="d-inline-block position-relative">
                                            <button class="btn btn-outline-dark btn-xs dropdown-toggle" type="button"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                                dropdownToggle>
                                                {{tableEngine.itemsPerPage}}
                                            </button>
                                            <div class="dropdown-menu dropdown-menu-right dropdown-menu-sm text-right"
                                                *dropdownMenu>
                                                <li role="menuitem" *ngFor="let item of tableEngine.itemOptionsPerPage">
                                                    <a [ngClass]="item === tableEngine.itemsPerPage ? 'dropdown-item active' : 'dropdown-item'"
                                                        (click)="tableEngine.paginateData(item, 1)">{{item}}</a>
                                                </li>
                                            </div>
                                        </span>
                                    </div>

                                </th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let obj of tableEngine.paginatedData;  let i=index">
                                    <!-- <td style="text-align: center; padding:5px;">
                                        <div class="itemCheck mb-0 custom-checkbox custom-control"
                                            style="display: inline-block;">
                                            <input type="checkbox" class="custom-control-input" [id]="'customCheck'+i"
                                                [checked]="tableEngine.isSelected(obj)"
                                                (change)="tableEngine.onSelect(obj)">
                                            <label class="custom-control-label" [for]="'customCheck'+i"></label>
                                        </div>
                                    </td> -->
                                    <td colspan="2">{{obj.Code}}</td>
                                    <td colspan="2">{{obj.ModifiedDate | date:"dd/MM/yyyy - hh:mm:ssa"}}</td>
                                    <td colspan="2">{{obj.CashierId>0?(lang==='ar-EG'?obj.CashierNameAr:obj.CashierNameEn):"None" | translate}}</td>
                                    <td colspan="2">{{obj.ClientId>0?(lang==='ar-EG'?obj.ClientNameAr:obj.ClientNameEn):'NoClient' | translate}}</td>
                                    <td colspan="2">{{obj.IsRefund?("CashierOrderIsRefundTrue"|translate):("CashierOrderIsRefundFalse"|translate)}}</td>
                                    <td colspan="2">{{obj.Status}}</td>
                                    <td colspan="2">{{obj.TotalValue.toFixed(2)}}</td>
                                    <td style="text-align: center;">
                                        <i class="simple-icon-info" (click)="showModal(obj.Id, 'Info')"
                                            tooltip="{{'Info'|translate}}" placement="auto" style="font-size: 20px; font-weight: bolder; cursor: pointer; 
                                                vertical-align: middle; color: #17A2B8;"></i>
                                    </td>
                                    <!-- <td style="text-align: center;">
                                        <i class="simple-icon-pencil" (click)="showModal(obj.Id, 'Edit')"
                                            tooltip="{{'Edit'|translate}}" placement="auto" style="font-size: 20px; font-weight: bolder; cursor: pointer; 
                                                vertical-align: middle; color: #FFC107;"></i>
                                    </td>
                                    <td style="text-align: center;">
                                        <i class="simple-icon-close" (click)="tableEngine.showDelModal(obj.Id)"
                                            tooltip="{{'Delete'|translate}}" placement="auto" style="font-size: 20px; font-weight: bolder; cursor: pointer; 
                                                vertical-align: middle; color: #DC3545;"></i>
                                    </td> -->
                                </tr>
                            </tbody>
                        </table>

                        <div id="divsearchmiddle" style="display: inline-block; width: 100%; height: 100%;">
                            <div style="text-align: center;">
                                <!-- <div class="centerside" style="display: inline-block; width: 15%; height: 100%; ">
                                    &nbsp;

                                </div> -->
                                <div class="centercenter"
                                    style="display: inline-block; width: 70%; height: 100%;  text-align: center;">

                                    <bdo dir="ltr">
                                        <pagination class="pagination-sm2" style="padding: 0px, 0px, 0px, 0px; margin: 0px, 0px, 0px, 0px;
                                        display: inline-block;" [(ngModel)]="currentPage" [boundaryLinks]="true"
                                            [totalItems]="tableEngine.filteredData.length"
                                            [itemsPerPage]="tableEngine.itemsPerPage"
                                            [customFirstTemplate]="firstTemplate" [customLastTemplate]="lastTemplate"
                                            [customPreviousTemplate]="prevTemplate" [customNextTemplate]="nextTemplate"
                                            [maxSize]="5" (pageChanged)="tableEngine.changePage($event)">
                                        </pagination>
                                    </bdo>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

            </div>
        </div>
    </div>

    <div id="divbottom" style="display: block; height: 47px;">

        <div id="divsearchright" style="float: right;  width: 33.33%; padding: 0px;">&nbsp;</div>


        <div id="divsearchleft" style="float: left; width: 33.33%; height: 100%;">&nbsp;</div>

    </div>
</div>

<div>

</div>

<ng-template #nextTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i *ngIf="lang==='en-US'" class='simple-icon-arrow-right'></i>
    <i *ngIf="lang==='ar-EG'" class='simple-icon-arrow-left'></i>
</ng-template>

<ng-template #prevTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i *ngIf="lang==='en-US'" class='simple-icon-arrow-left'></i>
    <i *ngIf="lang==='ar-EG'" class='simple-icon-arrow-right'></i>
</ng-template>

<ng-template #lastTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i class='simple-icon-control-end'></i>
</ng-template>

<ng-template #firstTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i class='simple-icon-control-start'></i>
</ng-template>


<simple-notifications></simple-notifications>