import { Component, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NotificationType, NotificationsService } from 'angular2-notifications';
import { DataFilterer } from 'src/app/views/app/aio/shared/DataFilterer';
import { DataSorter } from 'src/app/views/app/aio/shared/DataSorter';
import { DataPaginator } from 'src/app/views/invoice/shared/DataPaginator';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { ModalDirective } from 'ngx-bootstrap/modal';
import {JobTypesModel} from '../JobTypesModel';
import{JobTypesService} from '../job-types.service';
import { of } from 'rxjs';
import { SidePopupComponent } from 'src/app/views/app/aio/shared/side-popup/side-popup.component';
import { Router } from '@angular/router';
@Component({
    // moduleId: module.id,
    selector: 'list-job-types',
    templateUrl: 'list-job-types.component.html',
    styleUrls: ['list-job-types.component.scss']
})
export class ListJobTypesComponent {
    lang: string;
    itemOrder: { label: string; property: string; };
    constructor(private formBuilder: FormBuilder, private modelService: JobTypesService, private renderer: Renderer2,
        private notifications: NotificationsService, private translate: TranslateService, private router : Router) {
      }
      currentScreen: any = 'Table';
    
      translationName: string = 'JobType'
    
      sortableKeys = [];
      searchableKeys = ['Name'];
      
      modelObject: JobTypesModel = new JobTypesModel();
      ogData: JobTypesModel[] = [];
      mainForm: FormGroup = new FormGroup({
          Id: new FormControl(),
          Name: new FormControl(),
          Description: new FormControl(),
          Status: new FormControl(),
       
      });
   
    
      ngOnInit(): void {
        this.lang = localStorage.getItem('theme_lang');
        this.itemOrder = { label: 'Name', property:  this.lang === 'ar-EG' ? 'Name' : 'Name' };
        this.sortableKeys = [
          { label: 'Name',property:  this.lang === 'ar-EG' ? 'Name' : 'Name'},
          { label: 'limitation', property: 'limitation' },
        ];
        this.loadData();
        //this.dataSorter.sortData('NameEn', this.ogData);
      }
    
      createForm(): FormGroup {
        return this.formBuilder.group({
          Id: [this.modelObject.Id >= 0 ? this.modelObject.Id : 0],
          Name: [this.modelObject.Name, Validators.compose([Validators.required, Validators.minLength(2)])],
          Description: [this.modelObject.Description],
          Status: [this.modelObject.Status, Validators.compose([Validators.required])],
          });
      }

    


      addObject() {
        //prevent multiclick submits. enable again after API response.
        debugger;
        this.currentAction = 'loading';
    
        this.modelObject = new JobTypesModel();
        this.modelObject.Id = 0;
        this.modelObject.Name = this.mainForm.getRawValue().Name;
        this.modelObject.Description = this.mainForm.getRawValue().Description;
        this.modelObject.Status = this.mainForm.getRawValue().Status;
        this.executeAddObject();
      }
    
      executeAddObject(){
        this.modelService.insert(this.modelObject).subscribe(result => {
          if (result === true) {
            this.loadData();
            this.currentAction = '';
            this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyAdded"));
            // this.smallModal.hide();
        this.smallModal.hide();

          }
          else {
            this.toastError(this.translate.instant("Error"), this.translate.instant("FailedAdd"));
            this.currentAction = '';
          }
        })
      }
    
      editObject() {
        this.savedPage = this.currentPage;
        this.currentAction = 'loading';
    
        let tempObj = new JobTypesModel({
          Id: this.modelObject.Id,
          Name: this.mainForm.getRawValue().Name,
          Description: this.mainForm.getRawValue().Description,
          Status : this.mainForm.getRawValue().Status,
        });
        
        if(tempObj != this.modelObject){
          this.executeEditObject(tempObj);
        }
        else{
          this.smallModal.hide();
        }
      }
    
      executeEditObject(Object)
      {
               
        this.modelService.update(Object).subscribe(result => {
          if (result === true) {
            this.loadData();
            this.currentAction = '';
            this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyEdited"));
    
        this.smallModal.hide();
        // this.sideModalRef();
          }
          else {
            this.toastError(this.translate.instant("Error"), this.translate.instant("FailedEdit"));
            this.currentAction = '';
          }
        })
      }
    
      //Deletion methods:
      //Customize if needed.
      //Pass the target object as paraMetre if the target API can only delete singular objects and not lists.
      deleteObject() {
        this.savedPage = this.currentPage;
        //prevent multiclicks. re enable after API response
        this.currentAction = 'loading';
        this.executeDeleteObject();
      }
    
      //Same here...
      executeDeleteObject(){
        this.modelService.deleteList(this.deletionList).subscribe(result => {
          if (result === true) {
            this.loadData();
            this.currentAction = '';
            this.finalizeDeletion();
          }
          else {
            this.toastError(this.translate.instant("Error"), this.translate.instant("FailedDelete"));
            this.currentAction = '';
          }
        })
      }
      ////////////////////////////////////////////////////////////////////////////
      //
      //
      //
      //
      //
      ////////////////////////////////////////////////////////////////////////////
      //  EXCLUSIVE :
      //  Specifically for this component.
      ///////////////////////////////
      
    
      ////////////////////////////////////////////////////////////////////////////
      //
      //
      //
      //
      //
      ////////////////////////////////////////////////////////////////////////////
      //  CONSTANT :
      //  Do not change.
      ///////////////////////////////
    //    lang: any;
    //    itemOrder: any;
      currentFormMode: string = '';
      currentAction: string = '';
    
    
      //ogData = [];
      filteredData = [...this.ogData];
      sortedData = [...this.filteredData];
      paginatedData = [...this.filteredData];
      lastId: number = this.ogData.length + 1;
    
      SelectAllBox: any = false;
    
      itemsPerPage = 10;
      itemOptionsPerPage = [5, 10, 20];
      selected = [];
      selectAllState = '';
      orderAsc: boolean = true;
    
      currentPage = 1;
      savedPage = 0;
    
    
      dataFilterer = new DataFilterer();
      dataSorter = new DataSorter();
      dataPaginator = new DataPaginator();
    
      loadData() {
        debugger;
        this.currentAction = 'loading';
        this.modelService.getAll().subscribe(
          data => {
            this.ogData = data;
            this.sortData();
            this.loadSearch();
            this.currentAction = '';
          }
        )
        
      }
    
      sortData() {
        debugger;
        this.sortedData = this.dataSorter.sortData(this.itemOrder.property, this.ogData);
        this.filterData();
      }
    
      reverseSortData() {
        this.savedPage = this.currentPage;
        this.sortedData = this.sortedData.slice().reverse();
        this.filterData();
      }
    
      filterData() {
        debugger;

        this.filteredData = this.dataFilterer.filterData(this.SearchTerm, this.searchableKeys, this.sortedData)
        if (this.savedPage === 0) {
          this.paginateData(this.itemsPerPage, 1);
        }
        else {
          this.paginateData(this.itemsPerPage,
            Math.ceil((this.filteredData.length / this.itemsPerPage)) >= this.savedPage ?
              this.savedPage :
              Math.ceil((this.filteredData.length / this.itemsPerPage))
          );
        }
        this.savedPage = 0;
      }
    
      paginateData(newItemsPerPage: number, newCurrentPage: number) {
        debugger;

        this.itemsPerPage = newItemsPerPage;
        this.currentPage = newCurrentPage;
        this.paginatedData = this.filteredData.slice((newCurrentPage - 1) * (newItemsPerPage), (newItemsPerPage * newCurrentPage));
      }
    
      changeSort(newSort: any) {
        if (this.itemOrder.label === newSort.label) {
          this.orderAsc = !this.orderAsc;
          const oldPage = this.currentPage;
          this.reverseSortData()
          this.currentPage = oldPage;
        }
        else {
          this.orderAsc = true;
          this.itemOrder = newSort;
          this.sortData();
        }
      }
    
      getSort(label: any) {
        return this.sortableKeys.find(i => i.label === label);
      }
    
      changePage(event: PageChangedEvent): void {
        this.currentPage = event.page;
        this.paginateData(this.itemsPerPage, this.currentPage);
      }
    
      changeFilter() {
        this.deletionList = [];
        this.selected = [];
        this.selectAllState = '';
        this.filterData();
      }
    
      selectAll($event): void {
        if ($event.target.checked) {
          this.selected = [...this.filteredData];
        } else {
          this.selected = [];
        }
        this.setSelectAllState();
      }
    
      setSelectAllState(): void {
        if (this.selected.length >= this.filteredData.length) {
          this.selectAllState = 'checked';
        } else if (this.selected.length !== 0) {
          this.selectAllState = 'indeterminate';
        } else  {
          this.selectAllState = '';
        }
      }
    
      isSelected(p: JobTypesModel): boolean {
        return this.selected.findIndex(x => x.Id === p.Id) > -1;
      }
    
      onSelect(item: JobTypesModel): void {
        if (this.isSelected(item)) {
          this.selected = this.selected.filter(x => x.Id !== item.Id);
        } else {
          this.selected.push(item);
        }
        this.setSelectAllState();
      }
    
      findByCode(list: any, code: any): any {
        return list.find(x => x.Code === code)
      }
      @ViewChild('sideModalRef', { static: true }) sideModalRef: SidePopupComponent;
    
      @ViewChild('smallModal') smallModal: ModalDirective;
      @ViewChild('delModal') delModal: ModalDirective;
      showAddNewModalRef(): void {
        this.currentFormMode = 'Add'
        this.modelObject = new JobTypesModel;
        this.mainForm = this.createForm();
        this.smallModal.show();
      }
    
      showEditModalRef(Id: any): void {
        this.currentFormMode = 'Edit'
        this.modelObject = this.ogData.find(i => i.Id === Id);
        of(this.modelObject).subscribe(data => {
          this.modelObject = data;
        })
        this.mainForm = this.createForm();
        this.smallModal.show();
      }
    
      showInfoModalRef(Id: any): void {
        this.currentFormMode = 'Info'
    this.router.navigate(['app/HR/Info-JobTypes',Id])

        // this.modelObject = this.ogData.find(i => i.Id === Id);
        // of(this.modelObject).subscribe(data => {
        //   this.modelObject = data;
        // })
        // this.mainForm = this.createForm();
        // this.sideModalRef.show();
      }
    
      deletionList: JobTypesModel[] = [];
      showDelModal(Id: any): void {
        if (Id >= 0) {
          this.currentFormMode = 'Del'
          this.modelObject = this.ogData.find(i => i.Id === Id);
          this.deletionList = [this.ogData.find(i => i.Id === Id)];
          this.delModal.show();
        }
        else if (Id === 'delMulti') {
          this.deletionList = [...this.selected]
          this.delModal.show();
        }
      }
    
      SearchTerm: any = '';
    
    
      finalizeDeletion(){
        if (this.deletionList.length === 1) {
          this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyDeleted") + " " +
            this.translate.instant("One" + this.translationName));
        }
        else if (this.deletionList.length === 2) {
          this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyDeleted") + " " +
            this.translate.instant("Two" + this.translationName + "s"));
        }
        else if (this.deletionList.length > 2 && this.deletionList.length < 11) {
          this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyDeleted") + " " +
            this.deletionList.length + " " + this.translate.instant(this.translationName + "RowsPlur"));
        }
        else {
          this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyDeleted") + " " +
            this.deletionList.length + " " + this.translate.instant(this.translationName + "Rows"));
        }
    
        this.deletionList = [];
        this.selected = [];
    
        this.setSelectAllState();
        if (this.selectAllState === '') {
          this.SelectAllBox = false;
        }
        this.delModal.hide();
      }
    
      toastSuccess(toastHeader: string, toastBody: string): void {
        this.notifications.create(toastHeader, toastBody,
          NotificationType.Success, { timeOut: 3000, showProgressBar: true });
      }
      toastError(toastHeader: string, toastBody: string): void {
        this.notifications.create(toastHeader, toastBody,
          NotificationType.Error, { timeOut: 3000, showProgressBar: true });
      }
      deleteMultiTaxTypes() {
        this.loadData();
        this.delModal.hide();
      }
    
      loadAllData() {
        this.modelService.getAll().subscribe(
          data => {
            this.ogData = data;
            this.loadSearch();
          }
        )
      }
    
      loadSearch() {
        const prevSearch = this.SearchTerm;
        this.SearchTerm = '';
        setTimeout(() => {
          this.SearchTerm = prevSearch;
        }, 1);
      }
}
