<!--component html goes here -->
<div class="pagecontent">
    <div style="display: block;">
            <h2>{{'Finance'|translate}}</h2>
    </div>
    <div id="linkButtons" style="display:block;">
        <div class="glide__slide ng-star-inserted glide__slide--active d-inline-block"
            style="width: 210px; margin: 5px;">
            <div class="icon-row-item" style="width: inherit; cursor: pointer;">
                <div class="card">
                    <div class="card-body text-center" routerLink="ExpensessType">
                        <!-- <i class="simple-icon-wallet"></i> -->
                        <img src="assets/icons/expense.png" width="40px;" height="40px;">
                        <h3>{{"ExpensessType" | translate}}</h3>
                    </div>
                </div>
            </div>
        </div>
        <div class="glide__slide ng-star-inserted glide__slide--active d-inline-block"
        style="width: 210px; margin: 5px;">
        <div class="icon-row-item" style="width: inherit; cursor: pointer;">
            <div class="card">
                <div class="card-body text-center" routerLink="expenses">
                    <!-- <i class="iconsminds-add"></i> -->
                    <img src="assets/icons/Expenses.jpg" width="40px;" height="40px;">
        
                    <h3>{{"Expenses" | translate}}</h3>
                </div>
            </div>
        </div>
        </div>
        
        <div class="glide__slide ng-star-inserted glide__slide--active d-inline-block"
        style="width: 210px; margin: 5px;">
        <div class="icon-row-item" style="width: inherit; cursor: pointer;">
            <div class="card">
                <div class="card-body text-center" routerLink="catch-receipt-categories">
                    <!-- <i class="iconsminds-add"></i> -->
                    <img src="assets/icons/CatchReceiptCategories.png" width="40px;" height="40px;">
        
                    <h3>{{"Catch Receipt Categories" | translate}}</h3>
                </div>
            </div>
        </div>
        </div>
        <div class="glide__slide ng-star-inserted glide__slide--active d-inline-block"
style="width: 210px; margin: 5px;">
<div class="icon-row-item" style="width: inherit; cursor: pointer;">
    <div class="card">
        <div class="card-body text-center" routerLink="catch-receipt">
            <!-- <i class="iconsminds-add"></i> -->
            <img src="assets/icons/CostCenter.png" width="40px;" height="40px;">

            <h3>{{"Catch Receipt" | translate}}</h3>
        </div>
    </div>
</div>
</div>
        <div class="glide__slide ng-star-inserted glide__slide--active d-inline-block"
        style="width: 210px; margin: 5px;">
        <div class="icon-row-item" style="width: inherit; cursor: pointer;">
            <div class="card">
                <div class="card-body text-center" routerLink="treasury-and-bank-accounts">
                    <!-- <i class="iconsminds-add"></i> -->
                    <img src="assets/icons/bank.png" width="40px;" height="40px;">
        
                    <h3>{{"Treasury And Bank Accounts" | translate}}</h3>
                </div>
            </div>
        </div>
        </div>
        <br />
        <div style="display: block;">
            <h2>{{'General Accounts'|translate}}</h2>
    </div>
<div class="glide__slide ng-star-inserted glide__slide--active d-inline-block"
style="width: 210px; margin: 5px;">
<div class="icon-row-item" style="width: inherit; cursor: pointer;">
    <div class="card">
        <div class="card-body text-center" routerLink="all-daily">
            <img src="assets/icons/calendar.png" width="40px;" height="40px;">

            <h3>{{"Dailyrestrictions" | translate}}</h3>
        </div>
    </div>
</div>
</div>
<div class="glide__slide ng-star-inserted glide__slide--active d-inline-block"
style="width: 210px; margin: 5px;">
<div class="icon-row-item" style="width: inherit; cursor: pointer;">
    <div class="card">
        <div class="card-body text-center" routerLink="Accounts">
            <!-- <i class="iconsminds-add"></i> -->
            <img src="assets/icons/Accountingguide.png" width="40px;" height="40px;">

            <h3>{{"Accounting guide" | translate}}</h3>
        </div>
    </div>
</div>
</div>
<div class="glide__slide ng-star-inserted glide__slide--active d-inline-block"
style="width: 210px; margin: 5px;">
<div class="icon-row-item" style="width: inherit; cursor: pointer;">
    <div class="card">
        <div class="card-body text-center" routerLink="CostCenter">
            <!-- <i class="iconsminds-add"></i> -->
            <img src="assets/icons/CostCenters.jpg" width="40px;" height="40px;">

            <h3>{{"Cost Centers" | translate}}</h3>
        </div>
    </div>
</div>
</div>
<div class="glide__slide ng-star-inserted glide__slide--active d-inline-block"
style="width: 210px; margin: 5px;">
<div class="icon-row-item" style="width: inherit; cursor: pointer;">
    <div class="card">
        <div class="card-body text-center" routerLink="assets">
            <!-- <i class="iconsminds-add"></i> -->
            <img src="assets/icons/asset.png" width="40px;" height="40px;">

            <h3>{{"Assets" | translate}}</h3>
        </div>
    </div>
</div>
</div>
<div class="glide__slide ng-star-inserted glide__slide--active d-inline-block"
style="width: 210px; margin: 5px;">
<div class="icon-row-item" style="width: inherit; cursor: pointer;">
    <div class="card">
        <div class="card-body text-center" routerLink="Revenues">
            <!-- <i class="iconsminds-add"></i> -->
            <img src="assets/icons/Revenues.png" width="40px;" height="40px;">

            <h3>{{"Revenues" | translate}}</h3>
        </div>
    </div>
</div>
</div>


<br />
<div style="display: block;">
    <h2>{{'Check cycle'|translate}}</h2>
</div>


<div class="glide__slide ng-star-inserted glide__slide--active d-inline-block"
style="width: 210px; margin: 5px;">
<div class="icon-row-item" style="width: inherit; cursor: pointer;">
    <div class="card">
        <div class="card-body text-center" routerLink="checks-received">
            <!-- <i class="iconsminds-add"></i> -->
            <img src="assets/icons/chequeStatus.png" width="40px;" height="40px;">

            <h3>{{"Checks Received" | translate}}</h3>
        </div>
    </div>
</div>
</div>
<div class="glide__slide ng-star-inserted glide__slide--active d-inline-block"
style="width: 210px; margin: 5px;">
<div class="icon-row-item" style="width: inherit; cursor: pointer;">
    <div class="card">
        <div class="card-body text-center" routerLink="check-book">
            <!-- <i class="iconsminds-add"></i> -->
            <img src="assets/icons/cheque.png" width="40px;" height="40px;">

            <h3>{{"Check Book" | translate}}</h3>
        </div>
    </div>
</div>
</div>
<div class="glide__slide ng-star-inserted glide__slide--active d-inline-block"
style="width: 210px; margin: 5px;">
<div class="icon-row-item" style="width: inherit; cursor: pointer;">
    <div class="card">
        <div class="card-body text-center" routerLink="paid-checks">
            <!-- <i class="iconsminds-add"></i> -->
            <img src="assets/icons/PaidChecks.png" width="40px;" height="40px;">

            <h3>{{"Paid Checks" | translate}}</h3>
        </div>
    </div>
</div>
</div>
<br />
<div style="display: block;">
    <h2>{{'Reports'|translate}}</h2>
</div>
<div class="glide__slide ng-star-inserted glide__slide--active d-inline-block"
style="width: 210px; margin: 5px;">
<div class="icon-row-item" style="width: inherit; cursor: pointer;">
    <div class="card">
        <div class="card-body text-center" routerLink="daily-restrictions-report">
            <!-- <i class="iconsminds-add"></i> -->
            <img src="assets/icons/reportIcons/DailyRestrictions.png" width="40px;" height="40px;">

            <h3>{{"Daily Restrictions Report" | translate}}</h3>
        </div>
    </div>
</div>
</div>
<div class="glide__slide ng-star-inserted glide__slide--active d-inline-block"
style="width: 210px; margin: 5px;">
<div class="icon-row-item" style="width: inherit; cursor: pointer;">
    <div class="card">
        <div class="card-body text-center" routerLink="cost-center-report">
            <!-- <i class="iconsminds-add"></i> -->
            <img src="assets/icons/reportIcons/costcenterreport.png" width="40px;" height="40px;">

            <h3>{{"Cost Center Report" | translate}}</h3>
        </div>
    </div>
</div>
</div>
<div class="glide__slide ng-star-inserted glide__slide--active d-inline-block"
style="width: 210px; margin: 5px;">
<div class="icon-row-item" style="width: inherit; cursor: pointer;">
    <div class="card">
        <div class="card-body text-center" routerLink="income-list">
            <!-- <i class="iconsminds-add"></i> -->
            <img src="assets/icons/reportIcons/IncomeList.png" width="40px;" height="40px;">

            <h3>{{"Income List" | translate}}</h3>
        </div>
    </div>
</div>
</div>

<div class="glide__slide ng-star-inserted glide__slide--active d-inline-block"
style="width: 210px; margin: 5px;">
<div class="icon-row-item" style="width: inherit; cursor: pointer;">
    <div class="card">
        <div class="card-body text-center" routerLink="BalanceSheet">
            <!-- <i class="iconsminds-add"></i> -->
            <img src="assets/icons/BalanceSheet.png" width="40px;" height="40px;">

            <h3>{{"Balance Sheet" | translate}}</h3>
        </div>
    </div>
</div>
</div>
<div class="glide__slide ng-star-inserted glide__slide--active d-inline-block"
style="width: 210px; margin: 5px;">
<div class="icon-row-item" style="width: inherit; cursor: pointer;">
    <div class="card">
        <div class="card-body text-center" routerLink="assets-report">
            <!-- <i class="iconsminds-add"></i> -->
            <img src="assets/icons/reportIcons/AssetsReport.png" width="40px;" height="40px;">

            <h3>{{"Assets Report" | translate}}</h3>
        </div>
    </div>
</div>
</div>
<div class="glide__slide ng-star-inserted glide__slide--active d-inline-block"
style="width: 210px; margin: 5px;">
<div class="icon-row-item" style="width: inherit; cursor: pointer;">
    <div class="card">
        <div class="card-body text-center" routerLink="trial-balance-balances">
            <!-- <i class="iconsminds-add"></i> -->
            <img src="assets/icons/reportIcons/TrialBalanceBalances.png" width="40px;" height="40px;">

            <h3>{{"Trial Balance Balances Report" | translate}}</h3>
        </div>
    </div>
</div>
</div>
<div class="glide__slide ng-star-inserted glide__slide--active d-inline-block"
style="width: 210px; margin: 5px;">
<div class="icon-row-item" style="width: inherit; cursor: pointer;">
    <div class="card">
        <div class="card-body text-center" routerLink="ledger-account">
            <!-- <i class="iconsminds-add"></i> -->
            <img src="assets/icons/reportIcons/LedgerAccount.jpeg" width="40px;" height="40px;">

            <h3>{{"Ledger Account" | translate}}</h3>
        </div>
    </div>
</div>
</div>
<div class="glide__slide ng-star-inserted glide__slide--active d-inline-block"
style="width: 210px; margin: 5px;">
<div class="icon-row-item" style="width: inherit; cursor: pointer;">
    <div class="card">
        <div class="card-body text-center" routerLink="tax-report">
            <!-- <i class="iconsminds-add"></i> -->
            <img src="assets/icons/reportIcons/Taxes.png" width="40px;" height="40px;">

            <h3>{{"Taxes Report" | translate}}</h3>
        </div>
    </div>
</div>
</div>
<div class="glide__slide ng-star-inserted glide__slide--active d-inline-block"
style="width: 210px; margin: 5px;">
<div class="icon-row-item" style="width: inherit; cursor: pointer;">
    <div class="card">
        <div class="card-body text-center" routerLink="tax-return-report">
            <!-- <i class="iconsminds-add"></i> -->
            <img src="assets/icons/reportIcons/TaxesReturn.png" width="40px;" height="40px;">

            <h3>{{"Taxes Return Report" | translate}}</h3>
        </div>
    </div>
</div>
</div>
<div class="glide__slide ng-star-inserted glide__slide--active d-inline-block"
style="width: 210px; margin: 5px;">
<div class="icon-row-item" style="width: inherit; cursor: pointer;">
    <div class="card">
        <div class="card-body text-center" routerLink="cash-flows">
            <!-- <i class="iconsminds-add"></i> -->
            <img src="assets/icons/reportIcons/CashFlows.png" width="40px;" height="40px;">

            <h3>{{"Cash Flows" | translate}}</h3>
        </div>
    </div>
</div>
</div>
    </div>
</div>
