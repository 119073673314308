import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import{StockAdjustmentService}from './Add-stock-adjustment.service';
import { WarehouseService } from '../../warehouses/warehouse-service.service';
import { ProductesService } from '../productes.service';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { TableEngine } from 'src/app/views/app/aio/shared/TableEngine';
import { StockAdjustmentModel } from '../../models/StockAdjustmentModel';
import { SidePopupComponent } from 'src/app/views/invoice/side-popup/side-popup.component';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { WarehouseModel } from '../../models/WarehouseModel';
import { StoreItemsModel } from '../../models/StoreItemsModel';
import {AccountesNameModel} from '../../models/AccountesNameModel';
import {OperationTypeModel }from '../../models/OperationTypeModel';
import {OperationTypeService} from '../stock-adjustment/operation-type.service';
import {AdjustmentOperationModel} from 'src/app/Invintory/models/AdjustmentOperationModel';
import { WarehouseItemModel } from '../../models/WarehouseItem';
import { SubtractStockAdjustmentService} from '../../ProductesAndServices/stock-adjustment/subtract-stock-adjustment.service';
@Component({
    // moduleId: module.id,
    selector: 'stock-adjustment',
    templateUrl: 'stock-adjustment.component.html',
    styleUrls: ['stock-adjustment.component.scss']
})
export class StockAdjustmentComponent {
    Id = 0;

    constructor(
      public router: Router,
      private fb: FormBuilder,
      private modelService: StockAdjustmentService,
      private SubtractService:SubtractStockAdjustmentService,
      private WarehouseService: WarehouseService,
      private OperationTypeService: OperationTypeService,
     
      private ProductesService: ProductesService,
      private notifications: NotificationsService,
      private translate: TranslateService,
      private route: ActivatedRoute
    ) {
      this.Id = +route.snapshot.paramMap.get("Id");
    }
  
    tableEngine = new TableEngine(this.translate, this.notifications);
    currentScreen: any = "Table";
    translationName: string = "Item";
    sortableKeys = [];
    searchableKeys = ["Name", "Address"];
    tableView: any = true;
  
    StockAdjustmentModel:StockAdjustmentModel[]=[];
  
    // WarehouseData: WarehouseModel[] = [];
  
    modelObject: StockAdjustmentModel = new StockAdjustmentModel();
    Form: FormGroup;
    declare lang: any;
    declare itemOrder: any;
    currentAction = "";
    savedPage = 0;
    currentPage = 1;
    deletionList: any[] = [];
    prevAction = "";
    addToListId = 0;
    ItemsBalanceHeaderId = 0;
    ItemBalanceId = 0;
    currentFormMode: string = "";
    type = 0;
    visable = "";
    visable2 = "";
    visable3 = "";
    MovementNumber=0;


    ogData: StockAdjustmentModel[] = [];
    mainForm: FormGroup = new FormGroup({
        Id: new FormControl(),
        QuantityEditType: new FormControl(),
        OperationTypeId:new FormControl(),
        WarehouseId:new FormControl(),
        SubAccountId:new FormControl(),
        InventoryBeforeQuantity:new FormControl(),
        InventoryAfterQuantity:new FormControl(),
        ItemId:new FormControl(),
        Quantity:new FormControl(),
        UnitPrice:new FormControl(),
        Notes:new FormControl(),
        Time:new FormControl(),
        Date:new FormControl(),
      
    });
  
    @ViewChild("sideModalRef", { static: true })
    sideModalRef: SidePopupComponent = null;
    @ViewChild("delModal", { static: true }) delModal: ModalDirective;
    @ViewChild("smallModal", { static: true }) smallModal: ModalDirective = null;
    formPresentationModal: any;
    ngOnInit(): void {
      // this.getAllCountryCode();
  
      this.lang = localStorage.getItem("theme_lang");
      this.itemOrder = {
        label: "Quantity",
        property: this.lang === "ar-EG" ? "Quantity" : "Quantity",
      };
    //   this.sortableKeys = [
    //     { label: "Name", property: this.lang === "ar-EG" ? "Name" : "Name" },
    //     { label: "ServiceCode", property: "ServiceCode" },
    //     { label: "Description", property: "Description" },
    //     { label: "PurchasingPrice", property: "PurchasingPrice" },
    //     { label: "SalePrice", property: "SalePrice" },
    //     { label: "FirstTaxId", property: "FirstTaxId" },
    //     { label: "SecondTaxId", property: "SecondTaxId" },
    //     { label: "LowestSellingPrice", property: "LowestSellingPrice" },
    //     { label: "Discount", property: "Discount" },
    //     { label: "ProfitPercentage", property: "ProfitPercentage" },
    //     { label: "PriceListId", property: "PriceListId" },
    //     { label: "UnitPrice", property: "UnitPrice" },
    //     { label: "Time", property: "Time" },
    //     { label: "Notes", property: "Notes" },
    //     { label: "Tags", property: "Tags" },
    //     { label: "IsDisable", property: "IsDisable" },
    //     { label: "IsAlertDisable", property: "Alert" },
    //     { label: "FeaturedProduct", property: "FeaturedProduct" },
    //     { label: "AvailableOnline", property: "AvailableOnline" },
        
    //   ];
  
      this.tableEngine.loadTableEngine(
        this.ogData,
        this.modelService,
        this.modelObject,
        this.searchableKeys,
        this.sortableKeys,
        this.mainForm,
        this.translationName,
        this.itemOrder,
        this.sideModalRef,
        this.delModal,
        this.smallModal,
        this.lang
      );

      this.loadServices();
  
      this.modelService.GetAllSubAccountes(0).subscribe((data)=>{
        this.AccountesList=data;
      })
      this.WarehouseService.getAll().subscribe((data)=>{
        this.WarehouseList=data;
      })
      this.modelService.getLastMoveNumber().subscribe(data=>{
        this.MovementNumber=data;
      })
     this.OperationTypeService.getAll().subscribe(data=>{
      this.OperationList=data;
     })
     
  
      
          if(this.Id > 0)
          {   debugger;                            
                  this.ProductesService.getById(this.Id).subscribe(data=>{                        
                  this.ProducteList = data;
                  this.modelObject.InventoryAfterQuantity = this.ProducteList.Quantity;
                  this.WarehouseItemList=data.WarhouseItem;
                  this.mainForm = this.createForm(this.modelObject);
  
              });
              // this.mainForm = this.createForm(this.modelObject);
             
  
          }
          else{
                  
             this.mainForm = this.createForm(this.modelObject);                              
  
  
          }
      // this.mainForm = this.createForm(this.modelObject);
}


createForm(modelObject): FormGroup {
    debugger;
    return this.fb.group({
      // Id:[this.ItemBalance.Id],
      Id: [modelObject.Id >= 0 ? modelObject.Id : 0],
      InventoryAfterQuantity: [modelObject.InventoryAfterQuantity],
      InventoryBeforeQuantity: [ modelObject.InventoryBeforeQuantity],
      ItemId: [modelObject.ItemId],
      Notes: [modelObject.Notes],
      OperationTypeId: [modelObject.OperationTypeId],
      QuantityEditType: [modelObject.QuantityEditType],
      SubAccountId: [modelObject.SubAccountId],
      Discount: [modelObject.Discount],
      Quantity: [modelObject.Quantity],
      WarehouseId:[modelObject.WarehouseId],
      UnitPrice:[modelObject.UnitPrice],
      Time: [modelObject.Time],
      Date:[modelObject.Date],
      
      
    });
  }


  AccountesList: AccountesNameModel[] = [];
  OperationList:OperationTypeModel []=[];
  WarehouseList: WarehouseModel[] = [];
  WarehouseItemList: WarehouseItemModel[] = [];
  selectedWarehouse:WarehouseItemModel={};
  ProducteList:StoreItemsModel={}
  onMaterialGroupChange(event) {
    this.OperationList = event;
  
  }
  QuantityBefour()
  {
    debugger;
    var WarehouseId = this.mainForm.controls['WarehouseId'].value;
    var i = this.WarehouseItemList.findIndex(x=>x.WarehouseDataId == WarehouseId);
    if(i>-1){
      this.selectedWarehouse = this.WarehouseItemList[i];
    }
    
    else{
      this.selectedWarehouse=new WarehouseItemModel();

    }
  }
  QuantityAfter(){
    debugger;
    this.modelObject.InventoryAfterQuantity = this.selectedWarehouse.Quantity;
    if(this.mainForm.getRawValue().QuantityEditType==1 && this.mainForm.getRawValue().Quantity > 0){
        this.modelObject.InventoryAfterQuantity += +this.mainForm.getRawValue().Quantity;
        this.mainForm.controls['InventoryAfterQuantity'].setValue(this.modelObject.InventoryAfterQuantity)

    }
    else if(this.mainForm.getRawValue().QuantityEditType==2&& this.mainForm.getRawValue().Quantity > 0){
      this.modelObject.InventoryAfterQuantity -= +this.mainForm.getRawValue().Quantity;
      this.mainForm.controls['InventoryAfterQuantity'].setValue(this.modelObject.InventoryAfterQuantity)
    }
  else
  {
return;
  }


  }
  goBack(Id) {
    this.router.navigate(['app/aio/Inventory/Info-Productes/',Id])
}
  loadServices() {
    // debugger;
    this.currentAction = "loading";
    this.tableEngine.loadData();
  }
  
  Save(QuantityEditType) {
    debugger;
    this.currentAction = "loading";
    this.tableEngine.savedPage = this.tableEngine.currentPage;
    this.modelObject = new StockAdjustmentModel();
    this.modelObject.Id = 0;
    this.modelObject.InventoryAfterQuantity = this.mainForm.getRawValue().InventoryAfterQuantity;
    this.modelObject.InventoryBeforeQuantity = this.mainForm.getRawValue().InventoryBeforeQuantity;
    this.modelObject.ItemId = this.Id;
    this.modelObject.Notes = this.mainForm.getRawValue().Notes;
    this.modelObject.OperationTypeId = 0;
    this.modelObject.MoveNumber=this.MovementNumber;
    this.modelObject.QuantityEditType = +this.mainForm.getRawValue().QuantityEditType;
    this.modelObject.SubAccountId =+ this.mainForm.getRawValue().SubAccountId;
    this.modelObject.WarehouseId =+ this.mainForm.getRawValue().WarehouseId;
    this.modelObject.UnitPrice =+ this.ProducteList.SalePrice;
    this.modelObject.Quantity =+ this.mainForm.getRawValue().Quantity;
    this.modelObject.Date = new Date(this.mainForm.getRawValue().Date);
    this.modelObject.Time = this.mainForm.getRawValue().Time;
    this.OperationList.forEach(element => {
      var cat = new AdjustmentOperationModel();
      cat.OperationTypeId = element.Id;
      cat.Id = 0;
      cat.StockAdjustmentId = this.Id;
      this.modelObject.OperationType.push(cat);
    });
    if(QuantityEditType==1)
    this.modelService.insert(this.modelObject).subscribe((data) => {
      this.router.navigate(['app/aio/Inventory/Info-Productes/',this.Id])
    });
    if(QuantityEditType==2){
      this.SubtractService.insert(this.modelObject).subscribe((data) => {
        this.router.navigate(['app/aio/Inventory/Info-Productes/',this.Id])
      });
    }
  }

}
