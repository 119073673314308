import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CashRegisterModel } from "./CashRegisterModel";

@Injectable({
  providedIn: 'root'
})
export class CashRegisterService {
    constructor(private http: HttpClient) {
  
    }

    debugData: CashRegisterModel [] = [
    ];
    // debugData: CashRegisterModel [] = [
    //   {
    //     Id:1, Code:1001, BranchId:1, BranchNameAr:"فرع القاهرة", BranchNameEn:"Cairo Branch", LastActivityDate:new Date(2021, 0, 13),
    //     LastUserId: 1, LastUserNameAr: "احمد سعيد", LastUserNameEn:"Ahmad Saeed",
    //     LastDailyRevenue:100, LastMonthlyRevenue: 1000, LastAnnualRevenue:10000, TotalRevenue: 100000
    //   },
    //   {
    //     Id:2, Code:1002, BranchId:2, BranchNameAr:"فرع السعودية", BranchNameEn:"Saudi Branch", LastActivityDate:new Date(2022, 1, 13),
    //     LastUserId: 2, LastUserNameAr: "محمد فيصل", LastUserNameEn:"Mohamad Faysal",
    //     LastDailyRevenue:200, LastMonthlyRevenue: 2000, LastAnnualRevenue:20000, TotalRevenue: 200000
    //   },
    // ];
    debugMode: boolean = false;

    baseUrl = environment.baseApiUrl;
    // baseUrl = 'http://localhost:60289/';
     token = localStorage.getItem("token");
     httpOptions = {
      headers: new HttpHeaders({
        // 'Content-Type': 'application/json; charset=utf-8',
        // 'Authorization': "Bearer " + token,
         "Authorization": "Bearer " + this.token,
         "Content-Type": "application/json"
      })
    };
    getAll(): Observable<any> {
      if(this.debugMode){
        return of(this.debugData)
      }
      return this.http
        .get<any>(`${this.baseUrl}CashRegister/GetAll`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    getById(id:number): Observable<any> {
      if(this.debugMode){
        return of(this.debugData.find(x=>x.Id==id))
      }
      return this.http
        .get<any>(`${this.baseUrl}CashRegister/GetById/${id}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    insert(payload: CashRegisterModel): Observable<any> {
      if(this.debugMode){
        this.debugData.push(payload);
        return of(true)
      }
      return this.http
        .post<any>(`${this.baseUrl}CashRegister/Insert`, payload,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
    update(payload: CashRegisterModel): Observable<any> {
      if(this.debugMode){
        this.debugData[this.debugData.findIndex(x=>x.Id==payload.Id)] = payload;
        return of(true)
      }
      return this.http
        .post<any>(`${this.baseUrl}CashRegister/Update`, payload,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
    delete(payload: CashRegisterModel): Observable<any> {
      if(this.debugMode){
        this.debugData.splice(this.debugData.findIndex(x=>x.Id==payload.Id),1);
        return of(true)
      }
      return this.http
        .get<any>(`${this.baseUrl}CashRegister/Delete/${payload.Id}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
    deleteList(payload: CashRegisterModel []): Observable<any> {
      if(this.debugMode){
        payload.forEach(element => {
          this.debugData.splice(this.debugData.findIndex(x=>x.Id==element.Id),1);
        });
        return of(true)
      }
      return this.http
        .post<any>(`${this.baseUrl}CashRegister/DeleteList`,JSON.stringify(payload),this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
}
