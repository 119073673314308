export class BudgetAccountsModel{
    Id ?: number;
    AccountId ?: number;
    Amount ?: number;
    BudgetId?:number;
    constructor(item ?: BudgetAccountsModel){
        item = item ? item : {};
        this.Id = item.Id ? item.Id : 0;
        this.AccountId = item.AccountId ? item.AccountId : 0;
        this.Amount = item.Amount ? item.Amount : 0;
        this.BudgetId = item.BudgetId ? item.BudgetId : 0;
    }
}