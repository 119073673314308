import { Component } from '@angular/core';

@Component({
    // moduleId: module.id,
    selector: 'project-info',
    templateUrl: 'project-info.component.html',
    styleUrls: ['project-info.component.scss']
})
export class ProjectInfoComponent {

}
