import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {  CostCenterModel } from '../models/cost-center.model';


@Injectable({
  providedIn: 'root'
})
export class CostCenterService {
    constructor(private http: HttpClient) {
  
    }

    baseUrl = environment.baseApiUrl;
     token = localStorage.getItem("token");
     httpOptions = {
      headers: new HttpHeaders({
        // 'Content-Type': 'application/json; charset=utf-8',
        // 'Authorization': "Bearer " + token,
         "Authorization": "Bearer " + this.token,
         "Content-Type": "application/json"
      })
    };
    getAll(id:number): Observable<any> {
   
      return this.http
        .get<any>(`${this.baseUrl}CostElement/GetAll/${id}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }

    GetAllByAccountId(AccountId:number): Observable<any> {
   
      return this.http
        .get<any>(`${this.baseUrl}CostElement/GetAllByAccountId/${AccountId}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    
    GetAllSub(id:number): Observable<any> {
   
      return this.http
        .get<any>(`${this.baseUrl}CostElement/GetAllSub/${id}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    
    GetLastCode(): Observable<number> {
      return this.http
        .get<number>(`${this.baseUrl}CostElement/GetLastCode`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    getById(id:number): Observable<any> {
   
      return this.http
        .get<any>(`${this.baseUrl}CostElement/GetById/${id}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    insert(payload: CostCenterModel): Observable<any> {
      
      return this.http
        .post<any>(`${this.baseUrl}CostElement/Insert`, payload,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
    update(payload: CostCenterModel): Observable<any> {
      return this.http
        .put<any>(`${this.baseUrl}CostElement/Update`, payload,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
    delete(payload: CostCenterModel): Observable<any> {
      return this.http
        .post<any>(`${this.baseUrl}CostElement/Delete`,payload,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
    deleteList(payload: CostCenterModel []): Observable<any> {
      return this.http
        .post<any>(`${this.baseUrl}CostElement/DeleteList`,JSON.stringify(payload),this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
}
  