<div class="pagecontent">
    <div id="redirectCards" style="display: block;">
        <div class="glide__slide ng-star-inserted glide__slide--active d-inline-block redirect-card">
            <div class="icon-row-item">
                <div class="card">
                    <div class="card-body text-center" routerLink="Contractors"><i class="iconsminds-user iconfont-30"></i>
                        <h3>{{"Contractors" | translate}}</h3>
                    </div>
                </div>
            </div>
        </div>
        <div class="glide__slide ng-star-inserted glide__slide--active d-inline-block redirect-card">
            <div class="icon-row-item">
                <div class="card">
                    <div class="card-body text-center" routerLink="Work-Items"><i class="iconsminds-box-close iconfont-30"></i>
                        <h3>{{"WorkItems" | translate}}</h3>
                    </div>
                </div>
            </div>
        </div>
        <div class="glide__slide ng-star-inserted glide__slide--active d-inline-block redirect-card">
            <div class="icon-row-item">
                <div class="card">
                    <div class="card-body text-center" routerLink="projects"><i class="iconsminds-box-close iconfont-30"></i>
                        <h3>{{"Projects" | translate}}</h3>
                    </div>
                </div>
            </div>
        </div>
        <div class="glide__slide ng-star-inserted glide__slide--active d-inline-block redirect-card">
            <div class="icon-row-item">
                <div class="card">
                    <div class="card-body text-center" routerLink="client-business-extracts"><i class="iconsminds-box-close iconfont-30"></i>
                        <h3>{{"Clients Extracts" | translate}}</h3>
                    </div>
                </div>
            </div>
        </div>
        <div class="glide__slide ng-star-inserted glide__slide--active d-inline-block redirect-card">
            <div class="icon-row-item">
                <div class="card">
                    <div class="card-body text-center" routerLink="contractors-business-extracts"><i class="iconsminds-box-close iconfont-30"></i>
                        <h3>{{"Contractors Extracts" | translate}}</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>