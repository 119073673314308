<div class="card mb-4">
  <div class="card-body">
    <h5 class="card-title"><span>{{ 'pages.photos' | translate }}</span><a class="btn-link float-right text-small pt-1"
        href="javascript:;">{{ 'pages.view-all' | translate }}</a>
    </h5>
    <div class="row social-image-row gallery">
      <div class="col-6" *ngFor="let image of album; let i=index">
        <img class="img-fluid border-radius c-pointer" [src]="image.thumb" (click)="openLightbox(i)" />
      </div>
    </div>
  </div>
</div>



