<app-list-page-header *ngIf="tableView"[itemOptionsPerPage]="[5,10,20]"
    [itemsPerPage]="tableEngine.itemsPerPage" [selectAllState]="tableEngine.selectAllState" (addNewItem)="showModal(null,'Add')"
    [tableEngine]="this.tableEngine" (selectAllChange)="tableEngine.selectAll($event)" (changeOrderBy)="prepChangeSort($event)"
    (searchKeyUp)="tableEngine.filterDataByDateRange(this.SearchFrom,this.SearchTo,'Date')" [dateSearch]=true>
</app-list-page-header>

<div class="form-group" *ngIf="!tableView">
    <div class="modal-body">
        <form class="md-float-material form-material" [formGroup]="mainForm">

            <div style="text-align: center;">
                <button class="btn btn-light" (click)="goBack()"
                    style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Back"  | translate}}</button>
                <button *ngIf="tableEngine.currentFormMode==='Add'"
                    [disabled]="mainForm.invalid || tableEngine.currentAction==='loading' || addedItemList.length===0 || currentTotal<0 || badValue || !codeWasChecked"
                    id="smallModalSubmitButton" class="btn btn-secondary" (click)="addObject()"
                    style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Add"  | translate}}</button>
                <button *ngIf="tableEngine.currentFormMode==='Add'" class="btn btn-secondary" (click)="addAndNew()"
                    [disabled]="mainForm.invalid || tableEngine.currentAction==='loading' || addedItemList.length===0 || currentTotal<0 || badValue|| !codeWasChecked"
                    style="font-weight: bold; width: 250px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"AddAndNew"  | translate}}</button>
                <button *ngIf="tableEngine.currentFormMode==='Edit'"
                    [disabled]="mainForm.invalid || tableEngine.currentAction==='loading' || addedItemList.length===0 || currentTotal<0 || badValue || (!codeWasChecked && lastCode != modelObject.Code)"
                    id="smallModalSubmitButton" class="btn btn-secondary" (click)="editObject()"
                    style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Edit" | translate}}</button>
            </div>
            <div class="form-group" style="margin-top: 10px;">

                <div class="row">

                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"PurchaseInvoiceCode" | translate}}</label>
                        <div *ngIf="(tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit') 
                                        && !configPurchases.EnableAutoPurchaseInvoiceCodes">
                            <input type="number" min="0" name="Code" class="form-control" [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="lastCode" style=" margin: 0px;" (change)="checkCode()" (keyup)="checkCode()"
                            placeholder='{{"PurchaseInvoiceCode" | translate}}'>
                        </div>
                        <div *ngIf="((tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit') 
                                        && configPurchases.EnableAutoPurchaseInvoiceCodes)">
                        <label class="form-control" type="text" style="border: unset; width: 120px;"
                            name="Code">{{lastCode}}</label>
                        </div>
                        <div *ngIf="tableEngine.currentFormMode == 'Info'">
                        <label class="form-control" type="text" style="border: unset; width: 120px;"
                            name="Code">{{modelObject.Code}}</label>
                        </div>
                        <div class="messages text-danger"
                            *ngIf="!(lastCode > 0)">
                            {{"PurchaseInvoiceCodeRequired" | translate}}</div>
                        <div class="messages text-danger"
                            *ngIf="!(codeWasChecked) && (tableEngine.currentFormMode != 'Info' && lastCode != modelObject.Code)">
                            {{"PurchaseInvoiceCodeAlreadyTaken" | translate}}</div>
                    </div>

                    <br>

                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"PurchaseInvoiceRequestReceipt" | translate}}</label>
                        <div *ngIf="tableEngine.currentFormMode==='Add'">
                            <ng-select appearance="outline" name="RequestReceiptId"
                            (change)="changeRequestReceiptHeader()"
                                formControlName="RequestReceiptId"
                                placeholder='{{"PurchaseInvoiceRequestReceipt" | translate}}'
                                #PurchaseInvoiceRequestReceiptSelect labelForId="txtycd" [searchable]="true">
                                <ng-option *ngFor="let requestReceipt of requestReceiptList" [value]="requestReceipt.Id">
                                    {{requestReceipt.Code}}</ng-option>
                            </ng-select>
                        </div>
                        <label *ngIf="tableEngine.currentFormMode==='Info'||tableEngine.currentFormMode==='Edit'" class="form-control" type="text"
                            style="border: unset;"
                            name="RequestReceiptId">{{modelObject.RequestReceiptCode}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('RequestReceiptId').hasError('required') || mainForm.get('RequestReceiptId').invalid) && ( mainForm.get('RequestReceiptId').dirty ||  mainForm.get('RequestReceiptId').touched)">
                            {{"PurchaseInvoiceRequestReceiptRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>

                    <br>

                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"PurchaseInvoicePaymentMethod" | translate}}</label>
                        <div *ngIf="tableEngine.currentFormMode==='Add'">
                            <ng-select appearance="outline" name="PaymentMethodId"
                                formControlName="PaymentMethodId"
                                placeholder='{{"PurchaseInvoicePaymentMethod" | translate}}'
                                #PurchaseInvoicePaymentMethodSelect labelForId="txtycd" [searchable]="true">
                                <ng-option *ngFor="let paymentMethod of paymentMethodList" [value]="paymentMethod.Id">
                                    {{lang==='ar-EG'?paymentMethod.NameAr:paymentMethod.NameEn}}</ng-option>
                            </ng-select>
                        </div>
                        <label *ngIf="tableEngine.currentFormMode==='Info'||tableEngine.currentFormMode==='Edit'" class="form-control" type="text"
                            style="border: unset;"
                            name="PaymentMethodId">{{
                            modelObject.PaymentMethodId?
                            lang==='ar-EG'?modelObject.PaymentMethodNameAr:modelObject.PaymentMethodNameEn:
                            ("None" | translate)
                            }}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('PaymentMethodId').hasError('required') || mainForm.get('PaymentMethodId').invalid) && ( mainForm.get('PaymentMethodId').dirty ||  mainForm.get('PaymentMethodId').touched)">
                            {{"PurchaseInvoicePaymentMethodRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>

                </div>

                <br>

                <div class="row">



                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"PurchaseInvoiceSupplier" | translate}}</label>
                        <label class="form-control" type="text" style="border: unset;" name="SupplierId">{{
                            modelObject.SupplierId?
                            lang==='ar-EG'?modelObject.SupplierNameAr:modelObject.SupplierNameEn:
                            ("None" | translate)
                            }}</label>

                        <span class="form-bar"></span>
                    </div>

                    <br>


                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"PurchaseInvoiceStoreData" | translate}}</label>

                        <label class="form-control" type="text" style="border: unset;" name="StoreDataId">{{
                            modelObject.StoreDataId?
                            lang==='ar-EG'?modelObject.StoreNameAr:modelObject.StoreNameEn:
                            ("None" | translate)
                            }}</label>
                        <span class="form-bar"></span>
                    </div>
                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"PurchaseInvoiceDate" | translate}}</label>
                        <label class="form-control" type="text" style="border: unset;"
                            name="Date">{{modelObject.Date?(modelObject.Date | date:"dd/MM/yyyy"):("None" |
                            translate)}}</label>
                        <span class="form-bar"></span>
                    </div>
                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"Payment Plan" | translate}}</label>
                        <div *ngIf="tableEngine.currentFormMode==='Add'">
                            <ng-select appearance="outline" name="PaymentPlanId"
                            (change)="changePaymentPlan()"
                                formControlName="PaymentPlanId"
                                placeholder='{{"Payment Plan" | translate}}'
                                #PurchaseInvoiceRequestReceiptSelect labelForId="txtycd" [searchable]="true">
                                <ng-option *ngFor="let requestReceipt of paymentPlans" [value]="requestReceipt.Id">
                                    {{requestReceipt.Name}}</ng-option>
                            </ng-select>
                        </div>
                        <label *ngIf="tableEngine.currentFormMode==='Info'||tableEngine.currentFormMode==='Edit'" class="form-control" type="text"
                            style="border: unset;"
                            name="PaymentPlanId">{{getPaymentPlan(modelObject.PaymentPlanId)}}</label>
                    
                        <span class="form-bar"></span>
                    </div>
                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"Due Date" | translate}}</label>
                        <div *ngIf="tableEngine.currentFormMode==='Add'">
                            <input formControlName="DueDate" type="date" (change)="changeDueDate()" id="inputPassword4" class="form-control">

                        </div>
                        <label *ngIf="tableEngine.currentFormMode==='Info'||tableEngine.currentFormMode==='Edit'" class="form-control" type="text"
                            style="border: unset;"
                            name="PaymentPlanId">{{modelObject.DueDate|date:'dd/MM/yyyy'}}</label>
                    
                        <span class="form-bar"></span>
                    </div>


                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"PurchaseInvoiceIsPosted" | translate}}</label>
                        <label class="form-control" type="text" style="border: unset;"
                            name="IsPosted">{{(modelObject.IsPosted!=null?(modelObject.IsPosted?"PurchaseInvoiceIsPostedTrue":"PurchaseInvoiceIsPostedFalse"):
                            "None" )| translate}}</label>
                        <span class="form-bar"></span>
                    </div>



                </div>


                <br>

                <div class="row" style="height: 160px;">
                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"PurchaseInvoiceDescription" | translate}}</label>
                        <label class="form-control" type="text" style="border: unset; white-space:pre-wrap; word-wrap:break-word; 
                            width: 319%; height: 150px; overflow: auto;"
                            name="Description">{{modelObject.Description?modelObject.Description:("None" |
                            translate)}}</label>
                        <span class="form-bar"></span>
                    </div>
                </div>

                <br>

                <div>
                    <div style="width: 100%; margin-top: 15px;">
                        <div>
                            <table class="table">
                                <thead class="thead-light" style="border-bottom: unset !important;">
                                    <th></th>
                                    <th>{{"PurchaseInvoiceItemName" | translate}}</th>
                                    <th>{{"PurchaseInvoiceItemUnit" | translate}}</th>
                                    <th>{{"PurchaseInvoiceItemPrice" | translate}}</th>
                                    <th>{{"PurchaseInvoiceItemQuantity" | translate}}</th>
                                    <th>{{"PurchaseInvoiceItemDiscount" | translate}}</th>
                                    <th>{{"PurchaseInvoiceItemTax" | translate}}</th>
                                    <th>{{"PurchaseInvoiceItemValue" | translate}}</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let obj of addedItemList;  let i=index" style="margin-bottom: 5px !important;">
                                        <td [ngClass]="obj.GrossPrice<0?(lang=='ar-EG'?'bad-row-right':'bad-row-left'):''" style="vertical-align: middle;">
                                                <i *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                                                class="simple-icon-close" (click)="removePurchaseInvoiceItem(obj.Id)" style="font-size: 20px; font-weight: bolder; cursor: pointer; 
                                        vertical-align: middle; color: #DC3545;"></i>
                                        </td>
                                        <td [ngClass]="obj.GrossPrice<0?'bad-row':''" style="vertical-align: middle;">
                                            {{obj.ItemId>0?(lang==='ar-EG'?obj.ItemNameAr:obj.ItemNameEn):"None"|translate}}
                                        </td>
                                        <td [ngClass]="obj.GrossPrice<0?'bad-row':''" style="vertical-align: middle;">
                                            {{obj.UnitId>0?(lang==='ar-EG'?obj.UnitNameAr:obj.UnitNameEn):"None"|translate}}
                                        </td>
                                        <td [ngClass]="obj.GrossPrice<0?'bad-row':''" style="vertical-align: middle;">
                                            {{obj.UnitCost}}
                                        </td>
                                        <td [ngClass]="obj.GrossPrice<0?'bad-row':''">
                                            <input
                                                *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                                                (change)="calculateTotalItemValue()"
                                                type="number" min="0" [max]="obj.MaxQuantity" name="Quantity" class="form-control"
                                                [(ngModel)]="obj.Quantity" style="width: 150px; margin: 0px;"
                                                [ngModelOptions]="{standalone: true}"
                                                [ngClass]="!(obj.Quantity>0) || obj.Quantity===null?'form-control bad-value':'form-control'"
                                                placeholder='{{"PurchaseInvoiceItemQuantity" | translate}}'>

                                            <!-- <div class="messages text-danger"
                                                *ngIf="!(obj.Quantity>0)">
                                                {{"PurchaseInvoiceItemQuantityRequired" | translate}}</div> -->
                                            <div *ngIf="tableEngine.currentFormMode==='Info'">
                                                {{obj.Quantity}}
                                            </div>
                                        </td>
                                        <td [ngClass]="obj.GrossPrice<0?'bad-row':''">
                                            <input
                                                *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                                                (change)="calculateTotalItemValue()"
                                                type="number" min="0" name="DiscountValue" class="form-control"
                                                [ngClass]="!(obj.DiscountValue>=0) || obj.DiscountValue===null?'form-control bad-value':'form-control'"
                                                [(ngModel)]="obj.DiscountValue" style="width: 150px; margin: 0px;"
                                                [ngModelOptions]="{standalone: true}"
                                                placeholder='{{"PurchaseInvoiceItemDiscountValue" | translate}}'>

                                            <!-- <div class="messages text-danger"
                                                *ngIf="!(obj.DiscountValue>=0) || obj.DiscountValue===null">
                                                {{"PurchaseInvoiceItemDiscountValueRequired" | translate}}</div> -->
                                            <div *ngIf="tableEngine.currentFormMode==='Info'">
                                                {{obj.DiscountValue}}
                                            </div>
                                        </td>
                                        <td [ngClass]="obj.GrossPrice<0?'bad-row':''">
                                            <div
                                            *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'">
                                            <ng-select appearance="outline" name="TaxId"
                                            [ngModelOptions]="{standalone: true}"
                                                [(ngModel)]="TaxId"
                                                placeholder='{{"TaxId" | translate}}'
                                                #PurchaseQuotationItemIdSelect labelForId="txtycd2"
                                                [searchable]="true" (change)="changeTax(obj)"
                                                (keyup)="changeTax(obj)">
                                                <ng-option *ngFor="let item of allTaxes" [value]="item.Id">
                                                    {{lang==='ar-EG'?item.NameAr:item.NameEn}}</ng-option>
                                            </ng-select>
                                        </div>
                                            <!-- <input
                                                *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                                                (change)="calculateTotalItemValue()"
                                                type="number" min="0" name="TaxValue" class="form-control"
                                                [ngClass]="!(obj.TaxValue>=0) || obj.TaxValue===null?'form-control bad-value':'form-control'"
                                                [(ngModel)]="obj.TaxValue" style="width: 150px; margin: 0px;"
                                                [ngModelOptions]="{standalone: true}"
                                                placeholder='{{"PurchaseInvoiceItemTaxValue" | translate}}'> -->
                      
                                            <div *ngIf="tableEngine.currentFormMode==='Info'">
                                                {{obj.TaxValue}}
                                            </div>
                                        </td>
                                        <td [ngClass]="obj.GrossPrice<0?(lang=='ar-EG'?'bad-row-left':'bad-row-right'):''
                                        " style="vertical-align: middle; direction: ltr; unicode-bidi: bidi-override;">
                                        {{(obj.GrossPrice)}}
                                        </td>
                                    </tr>
                                </tbody>
                                <thead class="thead-light">
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                </thead>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class=" col-md-4">
                        <div style="display: inline-block;">
                            {{"PurchaseInvoiceTotalValue" | translate}}
                        </div>
                        <label
                            style="display: inline-block; 
                            min-width: 150px; border: unset; font-size: 25px;
                            direction: ltr;
                            unicode-bidi: bidi-override;"
                            class="form-control" type="text"  [ngClass]="currentTotal<0?'form-control bad-value':'form-control'"
                            name="TotalValue">{{currentTotal}}</label>
                        <div class="messages text-danger"*ngIf="badValue" style="font-size: 20px; justify-content: flex-end;">{{"PurchaseInvoiceInvalidTotal" | translate}}</div>    
                    </div>
                </div>
            </div>
        </form>
        <div>
            <div style="width: 100%; margin-top: 15px;">
                <div>
                    <label class="float-label push righ">{{"Cost Center" | translate}}</label>

                    <ng-select  *ngIf="lang==='ar-EG'" (change)="selectCenter()" [(ngModel)]="costCenterId" bindValue="Id"[items]="centers"   bindLabel="NameAr">
                    </ng-select>
                    <ng-select *ngIf="lang!=='ar-EG'" (change)="selectCenter()" [(ngModel)]="costCenterId" bindValue="Id"[items]="centers"   bindLabel="NameEn">
                  </ng-select>
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">{{'Cost Center'| translate}}</th>
                                <th scope="col">{{'Percent'| translate}}</th>
                                <th scope="col">{{'Amount'| translate}}</th>
                                <th scope="col">{{'Delete'| translate}}</th>
             
                            </tr>
                        </thead>
                        <tbody>
                            <tr  *ngFor="let cost of selectedCostCenters; let i=index;">
                                <th style="width: 10%;" scope="row">{{i + 1}}</th>
                                <td style="width: 30%;">
                                    {{getCostName(cost.CostCenterId)}}
                                </td>
                        
                                <td style="width: 20%;"><input [(ngModel)]="cost.Percent" [max]="100" (change)="changePercent(cost,$event)"type="number" class="form-control"></td>
                                <td style="width: 20%;"><input [(ngModel)]="cost.Amount" [max]="totalAfterTax" (change)="changeAmount(cost,$event)"  type="number" class="form-control "></td>
                                <td style="width: 20%;">
                                    <a href="javascript:" tooltip="{{'Delete'|translate}}" (click)="deleteCostCenter(cost)"><i class="simple-icon-trash"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="card">
        
            <div class="card-body">
                
                <label for="inputEmail4">{{ 'Documents' | translate }}</label>
                <upload-invoice-purchase-file *ngIf="tableEngine.currentFormMode!='Info'" [(url)]=fileUrl (urlChange)="setFileToList()" [editmode]=editEnabled></upload-invoice-purchase-file>
                <table class="table">
                   <tbody>
                    <tr *ngFor="let file of files;let i = index">
                        <td>#{{i+1}}</td>
                        <td><a href="{{file.FileUrl}}" target="_blank" style="cursor: pointer;" download="">{{"Download"|translate}}</a></td>
                        <td *ngIf="tableEngine.currentFormMode!='Info'">
                            <a href="javascript:" (click)="deleteFile(file)"><i class="simple-icon-trash"></i></a>
                        </td>
                    </tr>
                   </tbody>
                </table>
            </div>
        
        </div>
    
    </div>
</div>

<div bsModal #delModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <span aria-hidden="true">&nbsp;</span>
            <div class="modal-header" style="display: inline; padding: 0px 0px 5px 0px;">
                <h4 id="dialog-child-name" class="modal-title pull-left" style="text-align: center; color: #DC3545;">
                    {{"ConfirmDel"+translationName |
                    translate}}</h4>
            </div>
            <div class="modal-body">
                <div class="form-group form-primary" *ngIf="tableEngine.deletionList.length < tableEngine.ogData.length 
                || tableEngine.deletionList.length === 1" style="text-align: center;">
                    <div *ngFor="let item of tableEngine.deletionList;" style="text-align: center;">
                        <label type="text">- {{translationName|translate}} {{"Number" | translate}}
                            {{lang==='ar-EG'?item.Code:item.Code}}</label>
                        <span class="form-bar"></span>
                    </div>
                </div>
                <div class="form-group form-primary" style="text-align: center;" *ngIf="tableEngine.deletionList.length > 1 && 
                    tableEngine.deletionList.length === tableEngine.ogData.length">
                    <label type="text">
                        <h1 style="font-weight: bolder; text-align: center;">{{translationName+"DeleteAll" | translate}}
                        </h1>
                    </label>
                    <span class="form-bar"></span>
                </div>

                <div style="margin-left: 5px; text-align: center; margin-top: 30px;">
                    <button class="btn btn-danger" id="smallModalDeleteButton" (click)="deleteObject()"
                        [disabled]="tableEngine.currentAction==='loading'"
                        style="font-weight: bold; width: 100%; text-align: center;">{{"Delete" | translate}}</button>
                    <button class="btn btn-light" (click)="delModal.hide()"
                        style="font-weight: bold; width: 100%; text-align: center; margin-top: 10px;">{{"Close" |
                        translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="tableView" class="FormScreen" class="tableScreen">
    <!-- <div id="divtop" style="display: block;height: 47px; width: 100%;">
        <div id="divsearchmiddle" style="display: inline-block; width: 70%;
        height: inherit;  text-align: center; padding: 0px; margin: 0px;">
            <div class="search" style="display: inline-block; margin-left: 50px; margin-right: 50px;">
                <div class="d-inline-block">
                    <div class="d-inline-block mr-1 mb-1 align-top" style="width: 1000px;">
                        {{"PurchaseInvoiceShowResultsFrom"|translate}}
                        <div class="search-sm-nosearch d-inline-block mr-1 mb-1 align-top">
                            <input style="display: inline-block; width: 150px; margin-left: 5px;  margin-right: 5px;"
                                type="text" onfocus="(this.type='date')" onblur="if(!this.value)this.type='text'"
                                onchange="if(!this.value)this.type='text'" name="Date" class="form-control"
                                [(ngModel)]="SearchFrom"
                                (change)="tableEngine.filterDataByDateRange(this.SearchFrom,this.SearchTo,'Date')"
                                placeholder='{{"PurchaseInvoiceSearchDateFrom" | translate}}'>
                        </div>
                        {{"PurchaseInvoiceShowResultsTo"|translate}}
                        <div class="search-sm-nosearch d-inline-block mr-1 mb-1 align-top">
                            <input style="display: inline-block; width: 150px; margin-left: 5px;  margin-right: 5px;"
                                type="text" onfocus="(this.type='date')" onblur="if(!this.value)this.type='text'"
                                onchange="if(!this.value)this.type='text'" name="Date" class="form-control"
                                [(ngModel)]="SearchTo"
                                (change)="tableEngine.filterDataByDateRange(this.SearchFrom,this.SearchTo,'Date')"
                                placeholder='{{"PurchaseInvoiceSearchDateTo" | translate}}'>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
<!-- 
        <div id="divsearchleft" style="display: inline-block; width: 30%; height: inherit;">
            <div style="display: block; height: 30%; ">
                &nbsp;
            </div>
            <div class="search" style="display: block; height: 70%; text-align: center;">
                <button class="btn btn-primary d-inline-block"
                    style="font-weight: bold; text-align: center; padding: 0px; width: 150px; height: 30px;"
                    (click)="showModal(null,'Add')"
                    [disabled]="tableEngine.currentAction==='loading'">{{"Add"+translationName |
                    translate}}</button>

            </div>
        </div>

    </div> -->


    <div id="divmiddle" style="display: block; margin-top: 5px;">

        <div style="width: 100%;">
            <div>
                <div class="card" style="min-width: 900px !important;">
                    <div class="card-body">
                        <table class="table table-hover" style="margin: unset !important;">
                            <thead class="no-border-top">
                                <th style="text-align: center; padding:5px;">
                                    <div class=" text-zero" style="display: inline-block;">
                                        <div class="top-right-button-container2 btn-group" dropdown>
                                            <div class="btn btn-primary pl-4 pr-0 check-button">
                                                <label class="custom-control custom-checkbox mb-0 d-inline-block"
                                                    style="width: 10px;">
                                                    <input type="checkbox" class="custom-control-input"
                                                        [checked]="tableEngine.selectAllState==='checked'"
                                                        [indeterminate]="tableEngine.selectAllState === 'indeterminate'"
                                                        (change)="tableEngine.selectAll($event)">
                                                        <span class="custom-control-label">&nbsp;</span>
                                                </label>
                                            </div>
                                    <button id="button-split" type="button" dropdownToggle style="width: 10px;"
                                        class="btn btn-outline-primary dropdown-toggle dropdown-toggle-split"
                                        aria-controls="dropdown-split">
                                        <span class="caret"></span>
                                    </button>
                                    <ul id="dropdown-split" *dropdownMenu class="dropdown-menu dropdown-menu-right"
                                        role="menu" aria-labelledby="button-split">
                                        <li role="menuitem"><a class="dropdown-item">
                                                <button class="btn btn-danger d-inline-block"
                                                    style=" font-weight: bold; text-align: center; padding: 0px; width: 150px; height: 30px;"
                                                    (click)="tableEngine.showDelModal('delMulti')"
                                                    [disabled]="tableEngine.selected.length===0 || tableEngine.currentAction==='loading'">{{"DeleteSelected"
                                                    |
                                                    translate}}</button></a></li>
                                    </ul>
                                </div>
                            </div>
                        </th>


                        <th class="sorticon" (click)="prepChangeSort('PurchaseInvoiceCode')"
                            [ngClass]="(itemOrder.label==='PurchaseInvoiceCode')?'sortkey sorticon':'sorticon'">
                            <div
                                [ngClass]="{'iconsminds-up---down': (itemOrder.label!='PurchaseInvoiceCode'), 
                            'iconsminds-down': ((itemOrder.label==='PurchaseInvoiceCode') && tableEngine.orderAsc === true),
                            'iconsminds-up': ((itemOrder.label==='PurchaseInvoiceCode') && tableEngine.orderAsc === false)}">
                            </div>
                        </th>
                        <th class="sortOption" (click)="prepChangeSort('PurchaseInvoiceCode')"
                            [ngClass]="{'sortkey': (itemOrder.label==='PurchaseInvoiceCode')}">
                            {{"PurchaseInvoiceCode" | translate}}
                        </th>


                        <th class="sorticon" (click)="prepChangeSort('PurchaseInvoiceSupplier')"
                            [ngClass]="(itemOrder.label==='PurchaseInvoiceSupplier')?'sortkey sorticon':'sorticon'">
                            <div
                                [ngClass]="{'iconsminds-up---down': (itemOrder.label!='PurchaseInvoiceSupplier'), 
                            'iconsminds-down': ((itemOrder.label==='PurchaseInvoiceSupplier') && tableEngine.orderAsc === true),
                            'iconsminds-up': ((itemOrder.label==='PurchaseInvoiceSupplier') && tableEngine.orderAsc === false)}">
                            </div>
                        </th>
                        <th class="sortOption" (click)="prepChangeSort('PurchaseInvoiceSupplier')"
                            [ngClass]="{'sortkey': (itemOrder.label==='PurchaseInvoiceSupplier')}">
                            {{"PurchaseInvoiceSupplier" | translate}}
                        </th>


                        <th class="sorticon" (click)="prepChangeSort('PurchaseInvoiceDate')"
                            [ngClass]="(itemOrder.label==='PurchaseInvoiceDate')?'sortkey sorticon':'sorticon'">
                            <div
                                [ngClass]="{'iconsminds-up---down': (itemOrder.label!='PurchaseInvoiceDate'), 
                            'iconsminds-down': ((itemOrder.label==='PurchaseInvoiceDate') && tableEngine.orderAsc === true),
                            'iconsminds-up': ((itemOrder.label==='PurchaseInvoiceDate') && tableEngine.orderAsc === false)}">
                            </div>
                        </th>
                        <th class="sortOption" (click)="prepChangeSort('PurchaseInvoiceDate')"
                            [ngClass]="{'sortkey': (itemOrder.label==='PurchaseInvoiceDate')}">
                            {{"PurchaseInvoiceDate" | translate}}
                        </th>


                        <th class="sorticon" (click)="prepChangeSort('PurchaseInvoiceStoreName')"
                            [ngClass]="(itemOrder.label==='PurchaseInvoiceStoreName')?'sortkey sorticon':'sorticon'">
                            <div
                                [ngClass]="{'iconsminds-up---down': (itemOrder.label!='PurchaseInvoiceStoreName'), 
                            'iconsminds-down': ((itemOrder.label==='PurchaseInvoiceStoreName') && tableEngine.orderAsc === true),
                            'iconsminds-up': ((itemOrder.label==='PurchaseInvoiceStoreName') && tableEngine.orderAsc === false)}">
                            </div>
                        </th>
                        <th class="sortOption" (click)="prepChangeSort('PurchaseInvoiceStoreName')"
                            [ngClass]="{'sortkey': (itemOrder.label==='PurchaseInvoiceStoreName')}">
                            {{"PurchaseInvoiceStoreName" | translate}}
                        </th>

                        <th class="sorticon" (click)="prepChangeSort('PurchaseInvoiceTotalValue')"
                            [ngClass]="(itemOrder.label==='PurchaseInvoiceTotalValue')?'sortkey sorticon':'sorticon'">
                            <div
                                [ngClass]="{'iconsminds-up---down': (itemOrder.label!='PurchaseInvoiceTotalValue'), 
                            'iconsminds-down': ((itemOrder.label==='PurchaseInvoiceTotalValue') && tableEngine.orderAsc === true),
                            'iconsminds-up': ((itemOrder.label==='PurchaseInvoiceTotalValue') && tableEngine.orderAsc === false)}">
                            </div>
                        </th>
                        <th class="sortOption" (click)="prepChangeSort('PurchaseInvoiceTotalValue')"
                            [ngClass]="{'sortkey': (itemOrder.label==='PurchaseInvoiceTotalValue')}">
                            {{"PurchaseInvoiceTotalValue" | translate}}
                        </th>
                        <th class="sorticon" (click)="prepChangeSort('PurchaseInvoiceIsPosted')"
                            [ngClass]="(itemOrder.label==='PurchaseInvoiceIsPosted')?'sortkey sorticon':'sorticon'">
                            <div
                                [ngClass]="{'iconsminds-up---down': (itemOrder.label!='PurchaseInvoiceIsPosted'), 
                            'iconsminds-down': ((itemOrder.label==='PurchaseInvoiceIsPosted') && tableEngine.orderAsc === true),
                            'iconsminds-up': ((itemOrder.label==='PurchaseInvoiceIsPosted') && tableEngine.orderAsc === false)}">
                            </div>
                        </th>
                        <th class="sortOption" (click)="prepChangeSort('PurchaseInvoiceIsPosted')"
                            [ngClass]="{'sortkey': (itemOrder.label==='PurchaseInvoiceIsPosted')}">
                            {{"PurchaseInvoiceIsPosted" | translate}}
                        </th>

                        <th [colSpan]="3" style="text-align: center;">
                            <div id="displayOptions" style="
                            display: inline; margin-top: 10px; margin-left: 5px; margin-right: 5px;">
                                <span class="text-muted text-small" style=" width: 50px; height: 40px;">
                                    {{"ResultCount" | translate}}
                                </span>
                                <span dropdown class="d-inline-block position-relative">
                                    <button class="btn btn-outline-dark btn-xs dropdown-toggle" type="button"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                        dropdownToggle>
                                        {{tableEngine.itemsPerPage}}
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-sm text-right"
                                        *dropdownMenu>
                                        <li role="menuitem" *ngFor="let item of tableEngine.itemOptionsPerPage"><a
                                                [ngClass]="item === tableEngine.itemsPerPage ? 'dropdown-item active' : 'dropdown-item'"
                                                (click)="tableEngine.paginateData(item, 1)">{{item}}</a></li>
                                    </div>
                                </span>
                            </div>
                        </th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let obj of tableEngine.paginatedData;  let i=index">
                            <td style="text-align: center;">
                                <div class="itemCheck mb-0 custom-checkbox custom-control"
                                    style="display: inline-block;">
                                    <input type="checkbox" class="custom-control-input" [id]="'customCheck'+i"
                                        [checked]="tableEngine.isSelected(obj)" (change)="tableEngine.onSelect(obj)">
                                    <label class="custom-control-label" [for]="'customCheck'+i"></label>
                                </div>
                            </td>
                            <td colspan="2">{{obj.Code}}</td>
                            <td colspan="2">
                                {{lang==='ar-EG'?obj.SupplierNameAr:obj.SupplierNameEn}}
                            </td>
                            <td colspan="2">{{obj.Date | date:"dd/MM/yyyy"}}</td>
                            <td colspan="2">
                                {{lang==='ar-EG'?obj.StoreNameAr:obj.StoreNameEn}}
                            </td>
                            <td colspan="2">{{obj.TotalItemGrossPrices}}</td>
                            <td colspan="2" style="text-align: center;">
                                <span style="width: 90px; text-align: center; margin-top: 5px;"
                                    [ngClass]="obj.IsPosted?'badge badge-pill badge-success mb-1':'badge badge-pill badge-warning mb-1'">
                                    {{(obj.IsPosted?"PurchaseInvoiceIsPostedTrue":"PurchaseInvoiceIsPostedFalse")
                                    | translate}}</span>

                            </td>

                            <td style="text-align: center;">
                                <i class="simple-icon-info" (click)="showModal(obj.Id, 'Info')" style="font-size: 20px; font-weight: bolder; cursor: pointer; 
                                        vertical-align: middle; color: #17A2B8;"></i>
                            </td>
                            <td style="text-align: center;">
                                <i class="simple-icon-pencil" (click)="showModal(obj.Id, 'Edit')" style="font-size: 20px; font-weight: bolder; cursor: pointer; 
                                        vertical-align: middle; color: #FFC107;"></i>
                            </td>
                            <td style="text-align: center;">
                                <i class="simple-icon-close" (click)="tableEngine.showDelModal(obj.Id)" style="font-size: 20px; font-weight: bolder; cursor: pointer; 
                                        vertical-align: middle; color: #DC3545;"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div id="divsearchmiddle" style="display: inline-block; width: 100%; height: 100%;">
                    <div style="text-align: center;">
                        <!-- <div class="centerside" style="display: inline-block; width: 15%; height: 100%; ">
                            &nbsp;

                        </div> -->
                        <div class="centercenter"
                            style="display: inline-block; width: 70%; height: 100%;  text-align: center;">

                            <bdo dir="ltr">
                                <pagination class="pagination-sm2" style="padding: 0px, 0px, 0px, 0px; margin: 0px, 0px, 0px, 0px;
                                display: inline-block;" [(ngModel)]="currentPage" [boundaryLinks]="true"
                                    [totalItems]="tableEngine.filteredData.length"
                                    [itemsPerPage]="tableEngine.itemsPerPage"
                                    [customFirstTemplate]="firstTemplate" [customLastTemplate]="lastTemplate"
                                    [customPreviousTemplate]="prevTemplate" [customNextTemplate]="nextTemplate"
                                    [maxSize]="5" (pageChanged)="tableEngine.changePage($event)">
                                </pagination>
                            </bdo>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div id="divbottom" style="display: block; height: 47px;">

        <div id="divsearchright" style="float: right;  width: 33.33%; padding: 0px;">&nbsp;</div>

        <div id="divsearchmiddle" style="display: inline-block; width: 33.33%; height: 100%;">

            <!-- <div style="text-align: center;">
                <bdo dir="ltr">
                    <pagination class="pagination-sm2" style="padding: 0px, 0px, 0px, 0px; margin: 0px, 0px, 0px, 0px;
                    display: inline-block;" [(ngModel)]="currentPage" [boundaryLinks]="true"
                        [totalItems]="tableEngine.filteredData.length" [itemsPerPage]="tableEngine.itemsPerPage"
                        [customFirstTemplate]="firstTemplate" [customLastTemplate]="lastTemplate"
                        [customPreviousTemplate]="prevTemplate" [customNextTemplate]="nextTemplate" [maxSize]="5"
                        (pageChanged)="tableEngine.changePage($event)">
                    </pagination>
                </bdo>
            </div> -->
        </div>

        <div id="divsearchleft" style="float: left; width: 33.33%; height: 100%;">&nbsp;</div>

    </div>
</div>

<div>

</div>

<ng-template #nextTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i *ngIf="lang==='en-US'" class='simple-icon-arrow-right'></i>
    <i *ngIf="lang==='ar-EG'" class='simple-icon-arrow-left'></i>
</ng-template>

<ng-template #prevTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i *ngIf="lang==='en-US'" class='simple-icon-arrow-left'></i>
    <i *ngIf="lang==='ar-EG'" class='simple-icon-arrow-right'></i>
</ng-template>

<ng-template #lastTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i class='simple-icon-control-end'></i>
</ng-template>

<ng-template #firstTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i class='simple-icon-control-start'></i>
</ng-template>


<simple-notifications></simple-notifications>