<div class="row">
    <div class="col-12">
      <div>
        <h1>{{membership.EnglishName}}</h1>
        <div class="text-zero top-right-button-container">
          <div dropdown>
            <button id="button-basic" dropdownToggle type="button" placement="bottom right"
              class="btn btn-outline-primary dropdown-toggle btn-lg" aria-controls="dropdown-basic">{{ 'pages.actions' | translate }} <span
                class="caret"></span>
            </button>
            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
              aria-labelledby="button-basic">
              <li role="menuitem"><a class="dropdown-item" href="javascript:;">{{ 'pages.action' | translate }}</a></li>
              <li role="menuitem"><a class="dropdown-item"
                  href="javascript:;">{{ 'pages.another-action' | translate }}</a></li>
            </ul>
          </div>
        </div>
        <app-breadcrumb></app-breadcrumb>
      </div>
  
      <div>
   <div class="row">
              <div class="col-12 mb-5">
                <img class="social-header card-img" src="/assets/img/background.png" />
              </div>
              <div class="col-12 col-lg-5 col-xl-4 col-left">
                <membership-profile></membership-profile>
                <!-- <app-profile-photos></app-profile-photos>
                <app-profile-who-to-follow></app-profile-who-to-follow>
                <app-profile-recent-posts></app-profile-recent-posts> -->
              </div>
              <div class="col-12 col-lg-7 col-xl-8 col-right">
                <!-- <app-profile-posts></app-profile-posts> -->
                <div class="card mb-4">
                    <div class="card-body">
                
                        <tabset class="card-tabs">
                            <tab heading="{{'Basic Data' | translate}}" class="card-body">
                              <!-- <h6 class="mb-4">{{'Basic Data' | translate}}</h6> -->
                              <table class="table">
                                <tr>
                                    <th>{{'Membership Number'|translate}}:</th>
                                    <td>{{membership.VolunteerNumber}}</td>
                                </tr>
                                <tr>
                                    <th>{{'Name'|translate}}:</th>
                                    <td>{{membership.EnglishName}}</td>
                                </tr>
                                <tr>
                                    <th>{{'Phone'|translate}}:</th>
                                    <td>{{membership.Phone}}</td>
                                </tr>
                                <tr>
                                    <th>{{'Email'|translate}}:</th>
                                    <td>{{membership.Email}}</td>
                                </tr>
                                <tr>
                                    <th>{{'Address'|translate}}:</th>
                                    <td>{{membership.Address}}</td>
                                </tr>
                              </table>
                            </tab>
                            <tab heading="{{'Education And Work'| translate}}" class="card-body">
                                <table class="table">
                                    <tr>
                                        <th>{{'Graduation Year'|translate}}:</th>
                                        <td>{{membership.GraduationYear}}</td>
                                    </tr>
                                    <tr>
                                        <th>{{'Educational Qualification'|translate}}:</th>
                                        <td>{{membership.EducationalQualification}}</td>
                                    </tr>
                                    <tr>
                                        <th>{{'Employer'|translate}}:</th>
                                        <td>{{membership.Employer}}</td>
                                    </tr>
                                    <tr>
                                        <th>{{'CurrentPosition'|translate}}:</th>
                                        <td>{{membership.CurrentPosition}}</td>
                                    </tr>
                                   
                                  </table>
                              <!-- <button type="button" class="btn btn-sm btn-outline-primary">{{'cards.edit' | translate}}</button> -->
                            </tab>
                            <tab heading="{{'Files' | translate}}" class="card-body">
                              <div class="form-row">
                                <div class="form-group col-md-4">
                                  <div style="margin: 20px;">
                                    <br>
                                    <h5>{{'Profile Image'|translate}}</h5>
                                    <br>
                                    <div class="profile-picture">
                                      <img *ngIf="membership.ImageURL" width=300 height=300 src="{{membership.ImageURL}}" />
                           
                                      <!-- <input *ngIf="editmode" type="file" ng2FileSelect  [uploader]="MembershipProfile" id="uploader-MembershipProfile" style="display:none" /> -->
                                    </div>                            
                                     </div>
                                </div>
                                <div class="form-group col-md-4">
                                    <div style="margin: 20px;">
                                        <br>
                                        <h5>{{'National Id'|translate}}</h5>
                                        <br>
                                        <div class="profile-picture">
                                          <img *ngIf="membership.NationalIDImage" width=300 height=300 src="{{membership.NationalIDImage}}" />
                               
                                          <!-- <input *ngIf="editmode" type="file" ng2FileSelect  [uploader]="MembershipProfile" id="uploader-MembershipProfile" style="display:none" /> -->
                                        </div>
                                   </div>
                                </div>
                                <div class="form-group col-md-4">
                                    <div style="margin: 20px;">
                                        <br>
                                        <h5>{{'Graduation document'|translate}}</h5>
                                        <br>
                                        <div class="profile-picture">
                                          <!-- <img *ngIf="url" width=300 height=300 src="{{url}}" /> -->
                                          <label for="uploader-GraduationDocument" >
                                            <div class="infotext"><span  ></span></div>
                                          </label>
                                          <a *ngIf="membership.GraduationDocument" target="_blank" href="{{membership.GraduationDocument}}" download="">{{"Download"|translate}}</a>

                                          <!-- <input  href="{{membership.GraduationDocument}}" download style="display:none" /> -->
                                        </div>
                                        
                                   </div>
                                </div>
                           
                            </div>
                            </tab>
                          </tabset>
                    </div>
                  </div>
                  
              </div>
            </div>
      </div>
  
    </div>
  </div>
  
  