import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-config-pos',
  templateUrl: './config-pos.component.html',
  styleUrls: ['./config-pos.component.scss']
})
export class ConfigPosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
