<div class="pagecontent">
    <div id="redirectCards" style="display: block;">
        <div class="glide__slide ng-star-inserted glide__slide--active d-inline-block redirect-card">
            <div class="icon-row-item">
                <div class="card">
                    <div class="card-body text-center" routerLink="General-Settings"><i class="iconsminds-gears iconfont-30"></i>
                        <a>{{"ConfigPoSGeneral" | translate}}</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="glide__slide ng-star-inserted glide__slide--active d-inline-block redirect-card">
            <div class="icon-row-item">
                <div class="card">
                    <div class="card-body text-center" routerLink="Cash-Registers"><i class="iconsminds-cash-register-2 iconfont-30"></i>
                        <a>{{"CashRegisters" | translate}}</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="glide__slide ng-star-inserted glide__slide--active d-inline-block redirect-card">
            <div class="icon-row-item">
                <div class="card">
                    <div class="card-body text-center" routerLink="Work-Shifts"><i class="simple-icon-clock iconfont-30"></i>
                        <a>{{"CashierWorkShifts" | translate}}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> 

<simple-notifications></simple-notifications>