<!--component html goes here -->
<!--component html goes here -->
<div class="row" *ngIf="mainForm">
    <div class="col-12">
      <div class="card mb-4">
        <div class="card-body">
            <strong style="font-size: 22px; margin-left:10px ;">{{modelObject.InventoryNumber}}#</strong>
            <span class="badge badge-pill badge-success mb-1" *ngIf="modelObject.IsSettlement">{{'IsSettlement' | translate}}</span>
            <span class="badge badge-pill badge-info mb-1" style="background-color: darkslategrey;" *ngIf="!modelObject.IsSettlement"> {{'Draft' | translate}}</span>
         
          <div class="top-right-button-container text-zero float-right"
          >
          <button
          class="top-right-button btn btn-primary btn-lg mr-1"
          (click)="goBack()"
        >
          {{ "Back" | translate }}
        </button>  
         
        <button
        style="background-color: #13b272;"
        [disabled]="modelObject.IsSettlement==true"
        class="top-right-button btn btn-primary btn-lg mr-1"
        (click)="IsSettlement(modelObject)"
         >
          {{ "Settlement" | translate }}
        </button>          
           
                 
        
            <button
             
              class="simple-icon-pencil"
              [disabled]="modelObject.IsSettlement==true"

              (click)="showModal(modelObject.Id, 'Edit')"
              type="button"
              class="top-right-button btn btn-primary btn-lg mr-1"
            >
            {{ "TakeInventory" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
</div>
<div class="card"*ngIf="modelObject" >
    <tabset class="card-tabs">
        <tab heading="{{'Information' | translate}}" class="card-body" >
            <div class="modal-body">
                <form>
                    <div  *ngIf="tableView" class="FormScreen" class="value_highlight col-md-12">
                        <table class="table table-borderless">
                          <thead>                
                            <tr style="background-color: #f6f9fc;">
                              <td>{{'Warehouse'|translate}}</td>
                              <td>{{'Date'|translate}}</td>
                              <td>{{'InventoryNumber'|translate}}</td>
                              <td>{{'Summary'|translate}}</td>
                            </tr>
            
                            <tr >
                                <!-- <td >
                                    <label  *ngIf="modelObject.BillType==1">{{'AdditionBill' | translate}}</label>
                                    <label  *ngIf="modelObject.BillType==2">{{'SubBill' | translate}}</label>                               
                  
                                </td> -->
                              <td>
                                {{modelObject.WarehouseName}}
                              </td>
                              <td >
                                {{modelObject.Date}}
                                
                              </td>
                              
                              <td >                 
                                {{modelObject.InventoryNumber}}#
                   
                              </td>
                              <td *ngIf="modelObject.IsSettlement">
                             <input  class="form-control" type="number" [(ngModel)]="currentTotal" [ngModelOptions]="{standalone: true}" readonly>
                              </td>
                             
                              
                            </tr>
                            <tr style="background-color: #f6f9fc;">
                                  <td>{{'Notes'|translate}}</td>
                                  <td></td>
                                  <td></td>
                                  <td></td>

                            </tr>
                            <tr>
                             <td>
                               {{modelObject.Notes}}
                             </td>
                             <td></td>
                             <td></td>
                             <td></td>
                            </tr>
                            <tr></tr>
                            <tr class="solid"></tr>
            
                            
                          </thead>
                          
                        </table>
                      </div>
                  
                </form>
                <tr></tr>
                <tr></tr>
                <tr></tr>
                <tr></tr>
               <form>
                <div  *ngIf="tableView" class="FormScreen" class="value_highlight col-md-12"> 
                    <h3 class="head-bar theme-color-a"><span class="details-info">{{'ProducteLIst' | translate}}</span></h3>
                    <table class="table table-borderless">
                      <thead>                
                        <tr style="background-color: #f6f9fc;">
                            <th>{{'Item'|translate}}</th>            
                            <th>{{'Unit'|translate}}</th>                 
                            <th>{{'NumberInStock'|translate}}</th>
                            <th>{{'NumberInProgram'|translate}}</th>
                            <th>{{'decrease/excess'|translate}}</th>
                         
                        </tr>
        
                        <tr *ngFor="let item of InventoryItemsList; let i = index">
                            <td style="vertical-align: middle">
                                {{item.ItemName}}
                            </td >
                            <td style="vertical-align: middle"> 
                                {{item.UnitId}}
                            </td>
                            <td style="vertical-align: middle">
                                {{item.QuantityNumberInProgram}}
                            </td>
                            <td style="vertical-align: middle">
                                {{item.QuantityNumberInStock}}
                            </td >
                            <td style="vertical-align: middle">
                                {{item.QuantityNumberInStock-item.QuantityNumberInProgram}}
                            </td>                            
                          </tr>
                                               
                      </thead>
                      
                    </table>
                                 
                  <div class="value_highlight col-md-3 ">
                  </div>
                </div>
               </form>
            </div>
        </tab>  
    </tabset>
</div>