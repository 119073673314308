export class RestaurantCategoryModel {
    Id?: number;
    Code?: number;
    Name?: string;
    //Items?: RestaurantMenuItem [];
    constructor(obj?: RestaurantCategoryModel) {
        obj = obj ? obj : {};
        this.Id = obj.Id ? obj.Id : 0;
        this.Code = obj.Code ? obj.Code : 0;
        this.Name = obj.Name ? obj.Name : '';
        //this.Items = obj.Items ? obj.Items : [];
    }
}