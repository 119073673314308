import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-purchases-portal',
  templateUrl: './purchases-portal.component.html',
  styleUrls: ['./purchases-portal.component.scss']
})
export class PurchasesPortalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
