<div class="row mb-12">
    <div class="col-md-12 col-lg-12 col-12 mb-12">
      <div class="card"></div>
    </div>
  </div>
  <div class="row" *ngIf="mainForm">
    <div class="col-12">
      <div class="card mb-4">
        <div class="card-body">
          <h5 class="float-left">{{ "SalariesManager" | translate }}</h5>
          <div class="top-right-button-container text-zero float-right">
            <button
              class="top-right-button btn btn-primary btn-lg mr-1"
              (click)="goBack()"
            >
              {{ "Back" | translate }}
            </button>
            <button
              type="button"
              (click)="Save()"
              [disabled]="mainForm.invalid"
              class="top-right-button btn btn-primary btn-lg mr-1"
            >
              {{ "Save" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div></div>
  <div class="row">
    <div class="col-md-12">
      <!-- <div class="col-md-12 col-lg-12 col-12 mb-12"> -->
      <div class="card">
        <tabset class="card-tabs" [justified]="true">
          <tab
            style="font-weight: bold"
            heading="{{ 'Salaries' | translate }}"
            class="card-body"
          >
            <!-- <h6 class="mb-4">{{ "Basic Data" | translate }}</h6> -->
            <div class="modal-body">
              <form
                class="md-float-material form-material"
                [formGroup]="mainForm"
              >
                <div class="row">
                    <div class="col-md-6">
                   
                        <label for="inputState">{{ "Employee" | translate }} </label>
                        <select
                          id="inputState"
                          class="form-control"
                          formControlName="EmployeeId"
                        >
                          <option selected value="1">
                            {{ "ChoiceEmployee" | translate }}
                          </option>
                          <option
                            *ngFor="let Employees of EmployeesList"
                            value="{{ Employees.Id }}"
                          >
                            {{ Employees.Name}}
                          </option>
                        </select>
                  </div>
                  <div class="col-md-6">
                    <label for="inputState">{{
                      "dateOfRegistration" | translate
                    }}</label>
                    <span class="required">*</span>
                    <!-- <barcode type="code128b">Hello</barcode> -->
                    <input
                      class="form-control"
                      formControlName="dateOfRegistration"
                      rows="2"
                      placeholder="{{ 'dateOfRegistration' | translate }}"
                      type="date"
                    />
                    <div
                      class="messages text-danger"
                      *ngIf="
                        (mainForm
                          .get('dateOfRegistration')
                          .hasError('required') ||
                          mainForm.get('dateOfRegistration').invalid) &&
                        (mainForm.get('dateOfRegistration').dirty ||
                          mainForm.get('dateOfRegistration').touched)
                      "
                    >
                      {{ "DateRequired" | translate }}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <label for="inputState">{{
                      "StartingDate" | translate
                    }}</label>
                    <span class="required">*</span>
                    <!-- <barcode type="code128b">Hello</barcode> -->
                    <input
                      class="form-control"
                      formControlName="StartingDate"
                      rows="2"
                      placeholder="{{ 'StartingDate' | translate }}"
                      type="date"
                    />
                    <div
                      class="messages text-danger"
                      *ngIf="
                        (mainForm.get('StartingDate').hasError('required') ||
                          mainForm.get('StartingDate').invalid) &&
                        (mainForm.get('StartingDate').dirty ||
                          mainForm.get('StartingDate').touched)
                      "
                    >
                      {{ "DateRequired" | translate }}
                    </div>
                  </div>
  
                  <div class="col-md-6">
                    <label for="inputState">{{ "EndDate" | translate }}</label>
                    <span class="required">*</span>
                    <!-- <barcode type="code128b">Hello</barcode> -->
                    <input
                      class="form-control"
                      formControlName="EndDate"
                      rows="2"
                      placeholder="{{ 'EndDate' | translate }}"
                      type="date"
                    />
                    <div
                      class="messages text-danger"
                      *ngIf="
                        (mainForm.get('EndDate').hasError('required') ||
                          mainForm.get('EndDate').invalid) &&
                        (mainForm.get('EndDate').dirty ||
                          mainForm.get('EndDate').touched)
                      "
                    >
                      {{ "DateRequired" | translate }}
                    </div>
                  </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                   
                        <label for="inputState">{{ "Currency" | translate }} </label>
                        <select
                          id="inputState"
                          class="form-control"
                          formControlName="CurrencyId"
                        >
                          <option selected value="1">
                            {{ "ChoiceCurrency" | translate }}
                          </option>
                          <option
                            *ngFor="let Currency of CurrencyList"
                            value="{{ Currency.Id }}"
                          >
                            {{ lang==='ar-EG'?Currency.NameAr:Currency.NameEn}}
                          </option>
                        </select>
                  </div>
                </div>
  
                <div class="card" style="margin-top: 30px">
                <div class="row">
                    <div style="width: 100%; margin-top: 15px;">
                        <form [formGroup]="dueForm">
                            <table class="table">
                                <thead class="thead-light">
                                    <th>{{'Salary Due'|translate}}</th>
                                    <th>{{"Salary Item Name" | translate}}</th>
                                    <th>{{"Amount" | translate}}</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let obj of addedDuesList;  let i=index">
                                        <td style="text-align: center; max-width: 80px;">
                                            <button *ngIf="currentFormMode==='Add'||currentFormMode==='Edit'" type="text"
                                            class="btn btn-danger text-center" (click)="removeDue(obj)"
                                            [disabled]="currentAction==='loading'"
                                            style="font-weight: bold; width: 80px;">{{"Remove"
                                            | translate}}</button>
                                        </td>
                                        <td>
                                            {{obj.SalaryItemName}}
                                        </td>
                                        <td >{{obj.Amount}}</td>
                                    </tr>
                                    <tr *ngIf="currentFormMode==='Add'||currentFormMode==='Edit'" type="text">
                                        <td style="text-align: center; max-width: 80px;">
                                            <button class="btn btn-info text-center" (click)="addDue()"
                                            [disabled]="dueForm.invalid "
                                            style="font-weight: bold; width: 80px;">{{"Add"
                                            | translate}}</button>
                                        </td>
                                        <td>
                                            <div>
                                                <div class="form-group form-primary">
                                                    <!-- <label class="float-label push righ">{{"Salary Item" | translate}}</label> -->
                                                    <select  formControlName="SalaryItemId"  id="inputState" class="form-control" >
                                                        <option disabled selected [value]="0">{{'Salary Item' | translate}}</option>
                                                        <option *ngFor="let item of duesItems" [value]="item.Id">{{item.ItemName}}</option>
                                                    </select> 
                                                 
                                                    <span class="form-bar"></span>
                                                </div>
                                       
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <div class="form-group form-primary">
                                                    <!-- <label class="float-label push righ">{{"Amount" | translate}}</label> -->
                                                    <input *ngIf="currentFormMode==='Add'||currentFormMode==='Edit'" type="number" name="Date"
                                                    class="form-control" formControlName="Amount"
                                                    placeholder='{{"Amount" | translate}}'>
                                                <label *ngIf="currentFormMode==='Info'" class="form-control" type="text"
                                                    name="Date" (change)="TotalSalary()">{{modelObject.Amount}}</label>
                                              
                                                    <span class="form-bar"></span>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                <thead class="thead-light">
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                </thead>
                            </table>
                        </form>
                    </div>
                    <div style="width: 100%; margin-top: 15px;">
                        <form [formGroup]="DeductionsForm">
                            <table class="table">
                                <thead class="thead-light">
                                    <th>{{'Salary Deductions'|translate}}</th>
                                    <th>{{"Salary Item Name" | translate}}</th>
                                    <th>{{"Amount" | translate}}</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let obj of addedDeductionsList;  let i=index">
                                        <td style="text-align: center; max-width: 80px;">
                                            <button *ngIf="currentFormMode==='Add'||currentFormMode==='Edit'" type="text"
                                            class="btn btn-danger text-center" (click)="removeDeductions(obj)"
                                            [disabled]="currentAction==='loading'"
                                            style="font-weight: bold; width: 80px;">{{"Remove"
                                            | translate}}</button>
                                        </td>
                                        <td>
                                            {{obj.SalaryItemName}}
                                        </td>
                                        <td>{{obj.Amount}}</td>
                                    </tr>
                                    <tr *ngIf="currentFormMode==='Add'||currentFormMode==='Edit'" type="text">
                                        <td style="text-align: center; max-width: 80px;">
                                            <button class="btn btn-info text-center" (click)="addDeductions()"
                                            [disabled]="DeductionsForm.invalid "
                                            style="font-weight: bold; width: 80px;">{{"Add"
                                            | translate}}</button>
                                        </td>
                                        <td>
                                            <div>
                                                <div class="form-group form-primary">
                                                    <!-- <label class="float-label push righ">{{"Salary Item" | translate}}</label> -->
                                                    <select  formControlName="SalaryItemId"  id="inputState" class="form-control" >
                                                        <option disabled selected [value]="0">{{'Salary Item' | translate}}</option>
                                                        <option *ngFor="let item of deducationsItems" [value]="item.Id">{{item.ItemName}}</option>
                                                    </select> 
                                                 
                                                    <span class="form-bar"></span>
                                                </div>
                                       
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <div class="form-group form-primary">
                                                    <!-- <label class="float-label push righ">{{"Amount" | translate}}</label> -->
                                                    <input *ngIf="currentFormMode==='Add'||currentFormMode==='Edit'" type="number" name="Date"
                                                    class="form-control" formControlName="Amount"
                                                    placeholder='{{"Amount" | translate}}'>
                                                <label *ngIf="currentFormMode==='Info'" class="form-control" type="text"
                                                    name="Date" (change)="TotalDiscount()">{{modelObject.Amount}} </label>
                                              
                                                    <span class="form-bar"></span>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                <thead class="thead-light">
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                </thead>
                            </table>
                        </form>
                    </div>
                </div>
                <div class="row"  *ngIf="modelObject">
                    <div class="col-md-3">
                        <label for="inputState">{{"TotalSalary" | translate }}</label>

                        <input  type="number" class="form-control" formControlName="TotalSalary" [(ngModel)]="modelObject.TotalSalary" [ngModelOptions]="{standalone: true}" readonly >

                        <!-- <label [(ngModel)]="modelObject.TotalSalary" [ngModelOptions]="{standalone: true}" readonly> </label> -->
                    </div>
                    <div class="col-md-3">
                        <label for="inputState">{{"TotalDiscount" | translate }}</label>

                        <input  type="number" class="form-control" formControlName="TotalDiscount" [(ngModel)]="modelObject.TotalDiscount" [ngModelOptions]="{standalone: true}" readonly >

                        <!-- <label [(ngModel)]="modelObject.TotalDiscount" [ngModelOptions]="{standalone: true}" readonly></label> -->
                    </div>
                    <div class="col-md-3">
                        <label for="inputState">{{"NetSalary" | translate }}</label>

                        <label formControlName="TotalSalary" [(ngModel)]="modelObject.TotalSalary" [ngModelOptions]="{standalone: true}" readonly></label>
                    </div>
                </div>
                </div>
              </form>
            </div>
          </tab>
        </tabset>
      </div>
    </div>
  </div>
  