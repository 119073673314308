<div class="row mb-12">
    <div class="col-md-12 col-lg-12 col-12 mb-12">
      <div class="card"></div>
    </div>
  </div>
  <div class="row" *ngIf="mainForm">
    <div class="col-12">
      <div class="card mb-4">
        <div class="card-body">
          <div class="top-right-button-container text-zero float-right">
            <button
              class="top-right-button btn btn-primary btn-lg mr-1"
              (click)="goBack()"
            >
              {{ "Back" | translate }}
            </button>
            <button
              type="button"
              (click)=" showModal(Id, 'Edit')"
              [disabled]="mainForm.invalid"
              class="top-right-button btn btn-primary btn-lg mr-1"
            >
              {{ "Edit" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div></div>
<div class="card"*ngIf="modelObject" >
  <tabset class="card-tabs">
    <tab heading="{{'Information' | translate}}" class="card-body" >    
        <div class="modal-body">
            <form   >
                <div class="row flex-row" >                
                <div class="value_highlight col-md-3 ">
                  <h5 for="inputState" style="color: #0189DE;font-size: 14px;">{{ "TotalPiecesSold" | translate }}</h5>
                  <label  type="text"  style=" font-size: x-large;" name="SalePrice">{{modelObject.SalePrice}}</label>         
                </div>
                <div class="vl"></div>

                <div class="value_highlight col-md-3 ">
                  <h5 for="inputState" style="color: #0189DE;font-size: 14px;">{{ "The last 28 days" | translate }}</h5>
                  <label  type="text"  style=" font-size: x-large;" name="SalePrice">{{modelObject.SalePrice}}</label>
         
                </div>
                <div class="vl"></div>

                <div class="value_highlight col-md-3 ">
                  <h5 for="inputState" style="color: #0189DE;font-size: 14px;">{{ "The last 7 days" | translate }}</h5>
                  <label  type="text"  style=" font-size: x-large;" name="SalePrice">{{modelObject.SalePrice}}</label>
         
                </div>
                <div class="vl"></div>

                <div class="value_highlight col-md-2 ">
                  <h5 for="inputState" style="color: #0189DE; font-size: 14px;">{{ "AverageCostPrice" | translate }}</h5>
                  <label  type="text"  style=" font-size: x-large;" name="SalePrice">{{modelObject.SalePrice}}</label>
         
                </div>
              </div>
              <div class="row">
              
          </div>
          
            </form>
        </div>
        <hr class="solid">
        <h3 class="head-bar theme-color-a"><span class="details-info">التفاصيل</span></h3>
        <div class="row">
          <div class="value_highlight col-md-3 ">

          </div>
          <div  *ngIf="tableView" class="FormScreen" class="value_highlight col-md-4">
            <table class="table table-borderless">
              <thead>                
                <tr >
                  <td>{{'Producte Code:'|translate}}</td>
                  <td>{{modelObject.ServiceCode>0?modelObject.ServiceCode:modelObject.ServiceCode}}
                 </td>
                </tr>

                <tr >
                  <td>{{'SalePrice:'|translate}}</td>
                  <td>{{modelObject.SalePrice>0?modelObject.SalePrice:modelObject.SalePrice}}
                 </td>
                </tr>

                <tr >
                  <td>{{'ProfitPercentage:'|translate}}</td>
                  <td>{{modelObject.ProfitPercentage>0?modelObject.ProfitPercentage:modelObject.ProfitPercentage}}%
                 </td>
                </tr>

                <tr >
                    <td>{{'ProfitPercentage:'|translate}}</td>
                    <td>{{modelObject.Time>0?modelObject.Time:modelObject.Time}}
                   </td>
                  </tr>
              </thead>              
            </table>
          </div>
          <div  *ngIf="tableView" class="FormScreen" class="value_highlight col-md-4">
            <table class="table table-borderless">
              <thead>                
                <tr >                    
                  <td>{{'PurchasingPrice:'|translate}}</td>
                  <td>{{modelObject.PurchasingPrice>0?modelObject.PurchasingPrice:modelObject.PurchasingPrice}}
                 </td>
                </tr>
                <tr >
                  <td>{{'Category:'|translate}}</td>
                  <td>
                      <tr *ngFor="let Category of modelObject.ServiceCategory">
                        <label >{{ Category.CategoryName }}</label>
                      </tr>
                  </td>                  
                  </tr>
                <!-- <tr>
                    <td>{{'Category:'|translate}}</td>
                    <td>
                    <lable *ngFor="let Category of CategoryList" >
                      {{ lang === "ar-EG" ? Category.Name : Category.Name }}
                    </lable>
                    </td>
               </tr> -->
              </thead>              
            </table>
          </div>
        </div>
      
    </tab>
    
      <tab heading="{{'TimeLine' | translate}}" class="card-body">
        <!-- <p class="font-weight-bold">Augue Vitae Commodo</p>
        <p>
          Vivamus ultricies augue vitae commodo condimentum. Nullam
          faucibus eros eu mauris feugiat, eget consectetur tortor tempus. Sed volutpat
          mollis dui eget fringilla. Vestibulum blandit urna ut tellus lobortis tristique.
          Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia
          Curae; Pellentesque quis cursus mauris. Nam in ornare erat. Vestibulum convallis
          enim ac massa dapibus consectetur. Maecenas facilisis eros ac felis mattis, eget
          auctor sapien varius.
          <br />
          <br />
          Nulla non purus fermentum, pulvinar dui condimentum,
          malesuada nibh. Sed viverra quam urna, at condimentum ante viverra non. Mauris
          posuere erat sapien, a convallis libero lobortis sit amet. Suspendisse in orci
          tellus.
        </p>
        <br />
  
        <p class="font-weight-bold">Phasellus Efficitur</p>
  
        <p>
          Tellus a sem condimentum, vitae convallis sapien feugiat.
          Aenean non nibh nec nunc aliquam iaculis. Ut quis suscipit nunc. Duis at lectus
          a est aliquam venenatis vitae eget arcu. Sed egestas felis eget convallis
          maximus. Curabitur maximus, ligula vel sagittis iaculis, risus nisi tincidunt
          sem, ut ultricies libero nulla eu ligula. Nam ultricies mollis nulla, sed
          laoreet leo convallis ac. Mauris nisl risus, tincidunt ac diam aliquet,
          convallis pellentesque nisi. Nam sit amet libero at odio malesuada ultricies a
          vitae dolor. Cras in viverra felis, non consequat quam. Praesent a orci enim.
          Vivamus porttitor nisi at nisl egestas iaculis. Nullam commodo eget dui
          sollicitudin sagittis. Duis id nibh mollis, hendrerit metus consectetur,
          ullamcorper risus. Morbi elementum ultrices nunc, quis porta nisi ornare sit
          amet.
          <br />
          <br />
          Etiam tincidunt orci in nisi aliquam placerat. Aliquam finibus in sem ut
          vehicula. Morbi eget consectetur leo. Quisque consectetur lectus eros, sed
          sodales libero ornare cursus. Etiam elementum ut dolor eget hendrerit.
          Suspendisse eu lacus eu eros lacinia feugiat sit amet non purus.
          <br />
          <br />
          Pellentesque quis cursus mauris. Nam in ornare erat. Vestibulum convallis enim
          ac massa dapibus consectetur. Maecenas facilisis eros ac felis mattis, eget
          auctor sapien varius.
        </p>
        <br />
        <p class="font-weight-bold">Elementum Ultrices</p>
   -->
      
    </tab>
   
  </tabset>
</div>
 