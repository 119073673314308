import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CashierStaffModel } from "./CashierStaffModel";

@Injectable({
  providedIn: 'root'
})
export class CashierStaffService {
    constructor(private http: HttpClient) {
  
    }

    debugData: CashierStaffModel [] = []
    // debugData: CashierStaffModel [] = [
    //   {
    //     Id:1, Code:1001, StaffId:1, NameAr:"احمد سعيد", NameEn:"Ahmad Saeed", Role:"Cashier", Email:"AhmadSaeed10@gmail.com",
    //     BranchId:1, BranchNameAr:"فرع القاهرة", BranchNameEn:"Cairo Branch", 
    //     LastRegisterId:1, LastRegisterCode:1001, LastRegisterBranchNameAr:"فرع القاهرة", LastRegisterBranchNameEn:"Cairo Branch",
    //   },
    //   {
    //     Id:2, Code:1002, StaffId:2, NameAr:"محمد فيصل", NameEn:"Mohamad Faysal", Role:"Cashier", Email:"MohamadFaysal20@gmail.com",
    //     BranchId:2, BranchNameAr:"فرع السعودية", BranchNameEn:"Saudi Branch", 
    //     LastRegisterId:2, LastRegisterCode:1002,  LastRegisterBranchNameAr:"فرع السعودية", LastRegisterBranchNameEn:"Saudi Branch",
    //   },
    // ];
    debugMode: boolean = false;

    baseUrl = environment.baseApiUrl;
    // baseUrl = 'http://localhost:60289/';
     token = localStorage.getItem("token");
     httpOptions = {
      headers: new HttpHeaders({
        // 'Content-Type': 'application/json; charset=utf-8',
        // 'Authorization': "Bearer " + token,
         "Authorization": "Bearer " + this.token,
         "Content-Type": "application/json"
      })
    };
    getAll(): Observable<any> {
      if(this.debugMode){
        return of(this.debugData)
      }
      return this.http
        .get<any>(`${this.baseUrl}Cashier/GetAll`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    getById(id:number): Observable<any> {
      if(this.debugMode){
        return of(this.debugData.find(x=>x.Id==id))
      }
      return this.http
        .get<any>(`${this.baseUrl}Cashier/GetById/${id}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    insert(payload: CashierStaffModel): Observable<any> {
      if(this.debugMode){
        this.debugData.push(payload);
        return of(true)
      }
      return this.http
        .post<any>(`${this.baseUrl}Cashier/Insert`, payload,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
    update(payload: CashierStaffModel): Observable<any> {
      if(this.debugMode){
        this.debugData[this.debugData.findIndex(x=>x.Id==payload.Id)] = payload;
        return of(true)
      }
      return this.http
        .post<any>(`${this.baseUrl}Cashier/Update`, payload,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
    delete(payload: CashierStaffModel): Observable<any> {
      if(this.debugMode){
        this.debugData.splice(this.debugData.findIndex(x=>x.Id==payload.Id),1);
        return of(true)
      }
      return this.http
        .get<any>(`${this.baseUrl}Cashier/Delete/${payload.Id}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
    deleteList(payload: CashierStaffModel []): Observable<any> {
      if(this.debugMode){
        payload.forEach(element => {
          this.debugData.splice(this.debugData.findIndex(x=>x.Id==element.Id),1);
        });
        return of(true)
      }
      return this.http
        .post<any>(`${this.baseUrl}Cashier/DeleteList`,JSON.stringify(payload),this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
}
