
import { CashierOrderItemModel } from "./CashierOrderItemModel";
export class CashierOrderModel{
    Id?:number;
    Code?:number;
    SessionId?:number;
    SessionCode?:number;
    CashRegisterId?:number;
    CashRegisterCode?:number;
    CashRegisterBranchId?:number;
    CashRegisterBranchNameAr?:string;
    CashRegisterBranchNameEn?:string;
    CashierId?:number;
    CashierNameAr?:string;
    CashierNameEn?:string;
    ClientId?:number;
    ClientNameAr?:string;
    ClientNameEn?:string;
    ModifiedDate?:Date;
    Note?:string;
    IsRefund?:boolean;
    Status?:string;
    Items?:CashierOrderItemModel[];
    ItemTotalPrices?:number;
    ItemTotalDiscounts?:number;
    ItemTotalNetPrices?:number;
    ItemTotalTaxes?:number;
    ItemTotalGrossPrices?:number;
    TotalValue?:number;
    OrderDiscountValue?: number;
    OrderDiscountIsRate?: boolean;
    PaymentMethodId?:number;
    PaymentMethodAr?:string;
    PaymentMethodEn?:string;
    CounterCashBeforeCheckout?:number;
    CounterCashAfterCheckout?:number;
    PaidAmount?:number;
    ChangeGiven?:number;
    constructor(obj?:CashierOrderModel){
        obj = obj? obj:{};
        this.Id = obj.Id? obj.Id:0;
        this.Code = obj.Code? obj.Code:0;
        this.SessionId = obj.SessionId? obj.SessionId:0;
        this.SessionCode = obj.SessionCode? obj.SessionCode:0;
        this.CashRegisterId = obj.CashRegisterId? obj.CashRegisterId:0;
        this.CashRegisterCode = obj.CashRegisterCode? obj.CashRegisterCode:0;
        this.CashRegisterBranchId = obj.CashRegisterBranchId? obj.CashRegisterBranchId:0;
        this.CashRegisterBranchNameAr = obj.CashRegisterBranchNameAr? obj.CashRegisterBranchNameAr:'';
        this.CashRegisterBranchNameEn = obj.CashRegisterBranchNameEn? obj.CashRegisterBranchNameEn:'';
        this.CashierId = obj.CashierId? obj.CashierId:0;
        this.CashierNameAr = obj.CashierNameAr? obj.CashierNameAr:'';
        this.CashierNameEn = obj.CashierNameEn? obj.CashierNameEn:'';
        this.ClientId = obj.ClientId? obj.ClientId:0;
        this.ClientNameAr = obj.ClientNameAr? obj.ClientNameAr:'';
        this.ClientNameEn = obj.ClientNameEn? obj.ClientNameEn:'';
        this.ModifiedDate = obj.ModifiedDate? obj.ModifiedDate:new Date();
        this.Note = obj.Note? obj.Note:'';
        this.IsRefund = obj.IsRefund? obj.IsRefund:false;
        this.Status = obj.Status? obj.Status: '';
        this.Items = obj.Items? obj.Items:[];
        this.ItemTotalPrices = obj.ItemTotalPrices? obj.ItemTotalPrices:0;
        this.ItemTotalDiscounts = obj.ItemTotalDiscounts? obj.ItemTotalDiscounts:0;
        this.ItemTotalNetPrices = obj.ItemTotalNetPrices? obj.ItemTotalNetPrices:0;
        this.ItemTotalTaxes = obj.ItemTotalTaxes? obj.ItemTotalTaxes:0;
        this.ItemTotalGrossPrices = obj.ItemTotalGrossPrices? obj.ItemTotalGrossPrices:0;
        this.TotalValue = obj.TotalValue? obj.TotalValue:0;
        this.OrderDiscountValue = obj.OrderDiscountValue? obj.OrderDiscountValue:0;
        this.OrderDiscountIsRate = obj.OrderDiscountIsRate? obj.OrderDiscountIsRate:false;
        this.PaymentMethodId = obj.PaymentMethodId? obj.PaymentMethodId:0;
        this.PaymentMethodAr = obj.PaymentMethodAr? obj.PaymentMethodAr:'';
        this.PaymentMethodEn = obj.PaymentMethodEn? obj.PaymentMethodEn:'';
        this.CounterCashBeforeCheckout = obj.CounterCashBeforeCheckout? obj.CounterCashBeforeCheckout:0;
        this.CounterCashAfterCheckout = obj.CounterCashAfterCheckout? obj.CounterCashAfterCheckout:0;
        this.PaidAmount = obj.PaidAmount? obj.PaidAmount:0;
        this.ChangeGiven = obj.ChangeGiven? obj.ChangeGiven:0;
    }
}