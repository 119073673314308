import { Component, HostBinding, Renderer2, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { SidePopupComponent } from 'src/app/views/app/aio/shared/side-popup/side-popup.component';
import { Observable } from 'rxjs';
import { WorkItemService } from './WorkItemService';
import { CodingUnitService } from '../../invoices/coding-units/CodingUnitService';
import { TableEngine } from 'src/app/invoices/TableEngine';
import { CodingUnitModel } from 'src/app/invoices/coding-units/CodingUnitModel';
import { WorkItemModel } from './WorkItemModel';
@Component({
  selector: 'app-workitems',
  templateUrl: './workitems.component.html',
  styleUrls: ['./workitems.component.scss']
})
export class WorkitemsComponent {
  ////////////////////////////////////////////////////////////////////////////
  //  VARYING :
  //  Modify per similar component.
  ////////////////////////////////////////////////////////////////////////////
  
  constructor(private formBuilder: FormBuilder, private modelService: WorkItemService, private renderer: Renderer2,
    private notifications: NotificationsService, private translate: TranslateService
    , private CodingUnitService: CodingUnitService,
  ) {
  }

  tableEngine = new TableEngine(this.translate, this.notifications);
  currentScreen: any = 'Table';
  tableView: any = true;

  

  translationName: string = 'WorkItem'

  sortableKeys = [];
  searchableKeys = ['NameEn', 'NameAr', 'ParentAr', 'ParentEn', 'Code'];

  modelObject: WorkItemModel = new WorkItemModel();

  ogData: WorkItemModel[] = [];
  mainForm: FormGroup = new FormGroup({
    Id: new FormControl(),
    Code: new FormControl(),
    NameEn: new FormControl(),
    NameAr: new FormControl(),
    ParentId: new FormControl(),
    UnitId: new FormControl(),
    Cost: new FormControl(),
    //WorkItemMain: new FormControl(),
  });


  declare lang: any;
  declare itemOrder: any;
  currentAction = '';
  savedPage = 0;
  currentPage = 1;
  deletionList: any[] = [];

  @ViewChild('sideModalRef', { static: true }) sideModalRef: SidePopupComponent;
  @ViewChild('delModal', { static: true }) delModal: ModalDirective;
  @ViewChild('smallModal', { static: true }) smallModal: ModalDirective = null;
  formPresentationModal: any;
  ngOnInit(): void {
    this.lang = localStorage.getItem('theme_lang');
    this.itemOrder = { label: 'WorkItemName', property: this.lang === 'ar-EG' ? 'NameAr' : 'NameEn' };
    this.sortableKeys = [
      { label: 'WorkItemCode', property: 'Code' },
      { label: 'WorkItemParent', property: this.lang === 'ar-EG' ? 'ParentAr' : 'ParentEn' },
      { label: 'WorkItemSub', property: this.lang === 'ar-EG' ? 'NameAr' : 'NameEn' },
      { label: 'WorkItemParent', property: this.lang === 'ar-EG' ? 'ParentAr' : 'ParentEn' },
      { label: 'WorkItemUnit', property: this.lang === 'ar-EG' ? 'UnitAr' : 'UnitEn' },
      { label: 'WorkItemCost', property: 'Cost' },
    ];
    this.loadServices();

    this.tableEngine.loadTableEngine(this.ogData, this.modelService, this.modelObject,
      this.searchableKeys, this.sortableKeys, this.mainForm, this.translationName, this.itemOrder,
      this.sideModalRef, this.delModal, this.smallModal, this.lang
    )
  }

  showModal(Id, action) {
    
    if (action === 'Add') {
      //mainForm.get
      this.parentList = [new WorkItemModel({Id:0, NameAr:'لا يوجد', NameEn:'None'})];
      this.parentList = this.parentList.concat([...this.tableEngine.ogData]);
      this.tableEngine.showAddNewModalRef(this.createForm, this.formBuilder)
    }
    else if (action === 'Edit') {
      
      
      this.parentList = [];
      this.parentList.push(new WorkItemModel({Id:0, NameAr:'-', NameEn:'-'}));
      for (let index = 0; index < this.tableEngine.ogData.length; index++) {
        if(this.tableEngine.ogData[index].Id != Id){
          this.parentList.push(this.tableEngine.ogData[index])
        }
      }
      
      this.tableEngine.showEditModalRef(Id, this.createForm, this.formBuilder)
    }
    else if (action === 'Info') {
      this.tableEngine.showInfoModalRef(Id, this.createForm, this.formBuilder)
    }
    //this.currentScreen = "Form";
    this.tableView = false;
    this.modelObject = this.tableEngine.modelObject;
    this.mainForm = this.tableEngine.mainForm;
  }

  createForm(modelObject, formBuilder): FormGroup {
    return formBuilder.group({
      Id: [modelObject.Id >= 0 ? modelObject.Id : 0],
      Code: [modelObject.Code, Validators.compose([Validators.required, Validators.pattern(/^\d+$/)])],
      NameEn: [modelObject.NameEn, Validators.compose([Validators.required, Validators.minLength(2)])],
      // NameAr: [modelObject.NameAr, Validators.compose([Validators.required, Validators.minLength(2)])],
      NameAr: [modelObject.NameAr, Validators.compose([Validators.required, Validators.pattern(/^[\u0621-\u064A\u0660-\u06690-9]+([\s]*[\u0621-\u064A\u0660-\u06690-9]+)*$/)])],
      
      //WorkItemMain: [modelObject.ParentId>0?false:true, Validators.compose([Validators.required])],
      ParentId: [modelObject.ParentId, Validators.compose([Validators.required])],
      UnitId: [modelObject.UnitId, Validators.compose([Validators.required])],
      Cost: [modelObject.Cost, Validators.compose([Validators.required, Validators.pattern(/^([1-9]+)([0-9])*(\.[0-9]+)?$/)])],
    });
  }

  addObject() {
    this.currentAction = 'loading';

    this.modelObject = new WorkItemModel();
    this.modelObject.Id = 0;
    this.modelObject.Code = this.mainForm.getRawValue().Code;
    this.modelObject.NameEn = this.mainForm.getRawValue().NameEn;
    this.modelObject.NameAr = this.mainForm.getRawValue().NameAr;
    this.modelObject.ParentId = this.mainForm.getRawValue().ParentId;
    this.modelObject.UnitId = this.mainForm.getRawValue().UnitId;
    this.modelObject.Cost = this.mainForm.getRawValue().Cost;
    
    this.executeAddObject();
  }

  executeAddObject() {
    this.modelService.insert(this.modelObject).subscribe(result => {
      if (result === true) {
        this.tableEngine.loadData();
        this.currentAction = '';
        this.tableEngine.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyAdded"));
        if(this.sideModalRef === undefined){
          if(this.smallModal != undefined){
            if(this.prevAction!='AddThenNew'){
              this.tableEngine.smallModal.hide();
            }
          }
          else{
            //this.tableView = this.prevAction==='AddThenNew'?false:true;
          }
        }
        else{
          if(this.prevAction!='AddThenNew'){
            this.tableEngine.sideModalRef.modalRef.hide();
          }
        }
      }
      else {
        this.tableEngine.toastError(this.translate.instant("Error"), this.translate.instant("FailedAdd"));
        this.currentAction = '';
      }
    })
  }

  editObject() {
    this.currentAction = 'loading';
    this.tableEngine.savedPage = this.tableEngine.currentPage;

    let tempObj = new WorkItemModel({
      Id: this.modelObject.Id,
      Code: this.mainForm.getRawValue().Code,
      NameEn: this.mainForm.getRawValue().NameEn,
      NameAr: this.mainForm.getRawValue().NameAr,
      ParentId: this.mainForm.getRawValue().ParentId,
      UnitId: this.mainForm.getRawValue().UnitId,
      Cost: this.mainForm.getRawValue().Cost,
      
    });
    if (JSON.stringify(tempObj) != JSON.stringify(this.modelObject)) {
      this.executeEditObject(tempObj);
    }
    else {
      if(this.sideModalRef === undefined){
        if(this.smallModal != undefined){
          this.tableEngine.smallModal.hide();
        }
      }
      else{
        this.tableEngine.sideModalRef.modalRef.hide();
      }
    }
  }

  executeEditObject(Object) {
    this.modelService.update(Object).subscribe(result => {
      if (result === true) {
        this.tableEngine.loadData();
        this.currentAction = '';
        this.tableEngine.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyEdited"));

        if(this.sideModalRef === undefined){
          if(this.smallModal != undefined){
            this.tableEngine.smallModal.hide();
          }
        }
        else{
          this.tableEngine.sideModalRef.modalRef.hide();
        }
      }
      else {
        this.tableEngine.toastError(this.translate.instant("Error"), this.translate.instant("FailedEdit"));
        this.currentAction = '';
      }
    })
  }

  //Deletion methods:
  //Customize if needed.
  //Pass the target object as parameter if the target API can only delete singular objects and not lists.
  deleteObject() {
    this.savedPage = this.currentPage;
    //prevent multiclicks. re enable after API response
    this.currentAction = 'loading';
    this.executeDeleteObject();
  }

  //Same here...
  executeDeleteObject() {
    this.modelService.deleteList(this.tableEngine.deletionList).subscribe(result => {
      if (result === true) {
        this.tableEngine.loadData();
        this.currentAction = '';
        this.tableEngine.finalizeDeletion();
      }
      else {
        this.tableEngine.toastError(this.translate.instant("Error"), this.translate.instant("FailedDelete"));
        this.currentAction = '';
      }
    })
  }
  ////////////////////////////////////////////////////////////////////////////
  //
  //
  //
  //
  //
  ////////////////////////////////////////////////////////////////////////////
  //  EXCLUSIVE :
  //  Specifically for this component.
  ///////////////////////////////
  //Get any additional data here
  prevAction = '';
  addAndNew(){
    this.prevAction = 'AddThenNew';
    this.addObject();
    this.parentList = [new WorkItemModel({Id:0, NameAr:'WorkItemNoParent', NameEn:'WorkItemNoParent'})];
    this.parentList = this.parentList.concat([...this.tableEngine.ogData]);
    
    
    
    this.tableEngine.sideModalRef.modalRef.hide();
    this.createForm(this.tableEngine.modelObject, this.formBuilder);
    this.tableEngine.showAddNewModalRef(this.createForm, this.formBuilder)
  }

  loadServices() {
    this.currentAction = 'loading';
    
    this.CodingUnitService.getAll().subscribe(
      codingUnitData => {
        this.codingUnitList = codingUnitData;
        this.tableEngine.loadData();
      }
    )
    
  }

  codingUnitList: CodingUnitModel[] = [];
  getCodingUnitById(Id: any): any {
    return this.codingUnitList.find(i => i.Id === Id);
  }
  parentList: any [] = [];
  getParentById(Id: any): any {
    return this.tableEngine.ogData.find(i => i.Id === Id);
  }

  //WorkItemMain = false;

  ////////////////////////////////////////////////////////////////////////////
  //
  //
  //
  //
  //
  ////////////////////////////////////////////////////////////////////////////
  //  CONSTANT :
  //  Do not change.
  ///////////////////////////////
  prepChangeSort(sortKey){
    this.tableEngine.changeSort(this.tableEngine.getSort(sortKey));
    this.itemOrder = this.tableEngine.itemOrder;

  }
}
