import { QuotationItemTaxModel } from "./QuotationItemTaxModel";

export class QuotationItemModel {
    Id?: number;
    QuotationId ?: number;

    ItemId?: number;
    ItemNameAr?: string;
    ItemNameEn?: string;

    UnitId?: number;
    UnitNameAr?: string;
    UnitNameEn?: string;

    Description?: string;

    UnitCost?: number;
    Quantity?: number;
    TotalPrice?: number;

    DiscountValue?: number;
    DiscountIsRate?: boolean;
    NetPrice?: number;

    Taxes?: QuotationItemTaxModel[];
    TotalTaxValues?: number;
    GrossPrice?: number;


    constructor(obj?: QuotationItemModel) {
        obj = obj ? obj : {};
    
        this.Id = obj.Id ? obj.Id : 0;
        this.QuotationId = obj.QuotationId ? obj.QuotationId : 0;

        this.ItemId = obj.ItemId ? obj.ItemId : 0;
        this.ItemNameAr = obj.ItemNameAr ? obj.ItemNameAr : '';
        this.ItemNameEn = obj.ItemNameEn ? obj.ItemNameEn : '';

        this.UnitId = obj.UnitId ? obj.UnitId : 0;
        this.UnitNameAr = obj.UnitNameAr ? obj.UnitNameAr : '';
        this.UnitNameEn = obj.UnitNameEn ? obj.UnitNameEn : '';

        this.Description = obj.Description ? obj.Description : '';

        this.UnitCost = obj.UnitCost ? obj.UnitCost : 0;
        this.Quantity = obj.Quantity ? obj.Quantity : 0;
        this.TotalPrice = obj.TotalPrice ? obj.TotalPrice : 0;

        this.DiscountValue = obj.DiscountValue ? obj.DiscountValue : 0;
        this.DiscountIsRate = obj.DiscountIsRate ? obj.DiscountIsRate : true;
        this.NetPrice = obj.NetPrice ? obj.NetPrice : 0;

        this.Taxes = obj.Taxes ? obj.Taxes : [];
        this.TotalTaxValues = obj.TotalTaxValues ? obj.TotalTaxValues : 0;
        this.GrossPrice = obj.GrossPrice ? obj.GrossPrice : 0;
    }

}