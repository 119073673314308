import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ThirdPartyModel } from '../../models/third-party.model';
@Component({
    // moduleId: module.id,
    selector: 'add-third-party',
    templateUrl: 'add-third-party.component.html',
    styleUrls: ['add-third-party.component.scss']
})
export class AddThirdPartyComponent implements OnInit {
    showCard: boolean = false;
    ItemHolderList: any[] = [];
    Form: FormGroup;
    lang: string;
  
    constructor(
      public router:Router, 
      public fb:FormBuilder, 
      ) {
    
   
    }
 
    ngOnInit() {
        this.Form = this.createForm(new ThirdPartyModel());        
    }

  
    Save() {
        this.router.navigate(['app/council-affairs/board-of-directors'])

    }
 
  goBack(){
    this.router.navigate(['app/council-affairs/board-of-directors'])
  }

  
  createForm(modelObject:ThirdPartyModel): FormGroup {
    debugger;
    return this.fb.group({
     Id:[modelObject.Id],
     ArabicName                       :[modelObject.ArabicName                       ],
     EnglishName                      :[modelObject.EnglishName                      ],
     Phone                            :[modelObject.Phone                            ],
     Email                            :[modelObject.Email                            ],
     Address                          :[modelObject.Address                          ],
     
     IsBlocked                        :[modelObject.IsBlocked                        ],
     UserId                           :[modelObject.UserId                           ],
     CreateBy                         :[modelObject.CreateBy                         ],
     CreateDate                       :[modelObject.CreateDate                       ],
     ModifiedBy                       :[modelObject.ModifiedBy                       ],
     ModifiedDate                     :[modelObject.ModifiedDate                     ],
     IsDeleted                        :[modelObject.IsDeleted                        ],
     DeletedBy                        :[modelObject.DeletedBy                        ],
     DeletedDate                      :[modelObject.DeletedDate                      ],
    });
  }
  public editEnabled = true;
  public picurl: string ='';

  clear(){
    this.picurl = '';
 }
 public NationalIdEditEnabled = true;
 public NationalIdPicurl: string ='';

 clearNationalId(){
   this.NationalIdPicurl = '';
}
public graduationEditEnabled = true;
public graduationPicurl: string ='';

cleargraduation(){
  this.graduationPicurl = '';
}

  }
  
