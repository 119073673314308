import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import{AdditionAdjustmentModel} from '../addition-adjustment/AdditionAdjustmentModel';

@Injectable({
  providedIn: 'root'
})
export class AdditionAdjustmentService {
  constructor(private http: HttpClient) {
  
  }

  baseUrl = environment.baseApiUrl;
   token = localStorage.getItem("token");
   httpOptions = {
    headers: new HttpHeaders({
      // 'Content-Type': 'application/json; charset=utf-8',
      // 'Authorization': "Bearer " + token,
       "Authorization": "Bearer " + this.token,
       "Content-Type": "application/json"
    })
  };
  getAll(): Observable<any> {
 
    return this.http
      .get<any>(`${this.baseUrl}AdditionAdjustment/GetAll`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error => error)));
  }

  getAllItems(): Observable<any> {
   
    return this.http
      .get<any>(`${this.baseUrl}Item/GetAll`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error => error)));
  }
  getById(id:number): Observable<any> {
 
    return this.http
      .get<any>(`${this.baseUrl}AdditionAdjustment/GetById/${id}`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error => error)));
  }

  insert(payload: AdditionAdjustmentModel): Observable<any> {
    
    return this.http
      .post<any>(`${this.baseUrl}AdditionAdjustment/Insert`, payload,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error => error)));
  }

  update(payload: AdditionAdjustmentModel): Observable<any> {
    return this.http
      .put<any>(`${this.baseUrl}AdditionAdjustment/Update`, payload,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error => error)));
  }

  delete(payload: AdditionAdjustmentModel): Observable<any> {
    return this.http
      .put<any>(`${this.baseUrl}AdditionAdjustment/Delete/${payload.Id}`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error => error)));
  }

  deleteList(payload: AdditionAdjustmentModel []): Observable<any> {
    return this.http
      .put<any>(`${this.baseUrl}AdditionAdjustment/Delete`,JSON.stringify(payload),this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error => error)));
  }
}
