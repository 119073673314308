export class VacationModel{
    Id?: number;
    Name          ?: string;
    Nots          ?: string;
    EmployeeId    ?: number;
    From          ?: Date | null;
    To            ?: Date | null;
    SubmissionDate?: Date | null;
    VactionTypeId ?: number;
    EmployeeName?: string;
    VactionTypeName?: string;
    constructor(item ?: VacationModel){
        item = item ? item : {};
        this.Id = item.Id ? item.Id : 0;
        this.Name = item.Name ? item.Name : '';
        this.Nots = item.Nots ? item.Nots : '';
        this.EmployeeId = item.EmployeeId ? item.EmployeeId : 0;
        this.VactionTypeId = item.VactionTypeId ? item.VactionTypeId : 0;
        this.EmployeeName = item.EmployeeName ? item.EmployeeName : '';
        this.VactionTypeName = item.VactionTypeName ? item.VactionTypeName : '';
        this.From = item.From ? item.From : null;
        this.To = item.To ? item.To : null;
        this.SubmissionDate = item.SubmissionDate ? item.SubmissionDate : null;
    }
}