import { Component, OnInit } from '@angular/core';
import { MonthlyDistributionModel } from '../../models/monthly-distribution.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MonthlyDistributionService } from '../../monthly-distribution.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationType, NotificationsService } from 'angular2-notifications';
import { MonthlyDistributionPercentagesModel } from '../../models/monthly-distribution-percentages.model';
@Component({
    selector: 'create-monthly-distribution',
    templateUrl: 'create-monthly-distribution.component.html',
    styleUrls: ['create-monthly-distribution.component.scss']
})
export class CreateMonthlyDistributionComponent implements OnInit{
    MonthlyDistribution:MonthlyDistributionModel={};
    declare lang: any;
    Id=0;
    Form:FormGroup;
    buttonName='Add';
    users:any[]=[];
    public editEnabled = true;
   public picurl: string ='';
   monthes:MonthlyDistributionPercentagesModel[]=[
    {Id:0,MonthlyNameAr:'يناير',MonthlyNameEn:'January',Percentage:8.33},
    {Id:0,MonthlyNameAr:'فبراير',MonthlyNameEn:'February',Percentage:8.33},
    {Id:0,MonthlyNameAr:'مارس',MonthlyNameEn:'March',Percentage:8.33},
    {Id:0,MonthlyNameAr:'أبريل',MonthlyNameEn:'April',Percentage:8.33},
    {Id:0,MonthlyNameAr:'مايو',MonthlyNameEn:'May',Percentage:8.33},
    {Id:0,MonthlyNameAr:'يونيو',MonthlyNameEn:'June',Percentage:8.33},
    {Id:0,MonthlyNameAr:'يوليو',MonthlyNameEn:'July',Percentage:8.33},
    {Id:0,MonthlyNameAr:'أغسطس',MonthlyNameEn:'August',Percentage:8.33},
    {Id:0,MonthlyNameAr:'سبتمبر',MonthlyNameEn:'September',Percentage:8.33},
    {Id:0,MonthlyNameAr:'أكتوبر',MonthlyNameEn:'October',Percentage:8.33},
    {Id:0,MonthlyNameAr:'نوفمبر',MonthlyNameEn:'November',Percentage:8.33},
    {Id:0,MonthlyNameAr:'ديسمبر',MonthlyNameEn:'December',Percentage:8.33},
   ]
    constructor(
       private route: ActivatedRoute,
       public router:Router,
       private MonthlyDistributionService:MonthlyDistributionService,
       public fb:FormBuilder,
       private translate: TranslateService,
        private notifications: NotificationsService,
        ){
     this.lang = localStorage.getItem('theme_lang');
     this.Id = + route.snapshot.paramMap.get('Id');
       
   }
    ngOnInit(): void {
        if(this.Id == 0){
            this.MonthlyDistribution = new MonthlyDistributionModel();
            this.Form = this.createForm();
       
        }
        else{
            this.MonthlyDistributionService.getById(this.Id)
            .subscribe(data=>{
                this.buttonName = "Edit"
                this.MonthlyDistribution =data;
                this.monthes = this.MonthlyDistribution.MonthlyDistributionPercentages;
                this.Form = this.createForm();
            })
        }
        
    }
    createForm():FormGroup{
        return this.fb.group({
            Id:[this.MonthlyDistribution.Id],
            FiscalYear:[this.MonthlyDistribution.FiscalYear],
            Name:[this.MonthlyDistribution.Name],
            MonthlyDistributionPercentages:[this.MonthlyDistribution.MonthlyDistributionPercentages],
            
        })
    }
    Save(){
        debugger;
        this.MonthlyDistribution = this.Form.getRawValue();
        this.MonthlyDistribution.Id = this.Id;
        this.MonthlyDistribution.MonthlyDistributionPercentages = this.monthes;
        if(this.Id > 0 ){
            this.MonthlyDistributionService.update(this.MonthlyDistribution)
            .subscribe(result => {
                if(result == true){
                    this.router.navigate(['app/accounts/monthly-distribution']);
                    this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyEdited"));
    
                }
                else{
                 this.toastError(this.translate.instant("Error"), this.translate.instant("FailedEdit"));

                }
            })
        }
        else{
            this.MonthlyDistributionService.insert(this.MonthlyDistribution)
            .subscribe(result => {
                if(result == true){
                    this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyAdd"));
                    this.router.navigate(['app/accounts/monthly-distribution']);
    
                }
                else{
                 this.toastError(this.translate.instant("Error"), this.translate.instant("FailedAdd"));

                }
            })
        }
   
    }
    addAndNew(){
        debugger;
        this.MonthlyDistribution = this.Form.getRawValue();
        this.MonthlyDistribution.Id = this.Id;
        this.MonthlyDistribution.MonthlyDistributionPercentages = this.monthes;
        if(this.Id > 0 ){
            this.MonthlyDistributionService.update(this.MonthlyDistribution)
            .subscribe(result => {
                if(result == true){
                    this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyEdited"));
                    this.MonthlyDistribution = new MonthlyDistributionModel();
                   this.picurl = '';
                }
                else{
                 this.toastError(this.translate.instant("Error"), this.translate.instant("FailedEdit"));
                    
                }
            })
        }
        else{
            this.MonthlyDistributionService.insert(this.MonthlyDistribution)
            .subscribe(result => {
                if(result == true){
                    this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyAdd"));
                    this.MonthlyDistribution = new MonthlyDistributionModel();
                    this.picurl = '';

                }
                else{
                    this.toastError(this.translate.instant("Error"), this.translate.instant("FailedAdd"));

                }
            })
        }
   
    }

    toastSuccess(toastHeader: string, toastBody: string): void {
        this.notifications.create(toastHeader, toastBody,
          NotificationType.Success, { timeOut: 3000, showProgressBar: true });
      }
      toastError(toastHeader: string, toastBody: string): void {
        this.notifications.create(toastHeader, toastBody,
          NotificationType.Error, { timeOut: 3000, showProgressBar: true });
      }
}

