import { Component } from '@angular/core';

@Component({
  selector: 'app-profile-process',
  templateUrl: './profile-process.component.html'
})
export class ProfileProcessComponent  {

  constructor() { }



}
