export class HealthCareSubscriptionModel{
    Id          ?:number;
    MemberId    ?:number;
    MmemberName ?:string;
    PackageId   ?:number;
    PackageName ?:string;
    PackagePrice?:number;
    StartDate   ?:Date|null;
    EndDate     ?:Date|null;
    constructor(item ?:HealthCareSubscriptionModel){
        item = item ? item : {};
        this.Id           = item.Id           ? item.Id          : 0;
        this.MemberId     = item.MemberId     ? item.MemberId    : 0;
        this.MmemberName  = item.MmemberName  ? item.MmemberName : '';
        this.PackageId    = item.PackageId    ? item.PackageId   : 0;
        this.PackageName  = item.PackageName  ? item.PackageName : '';
        this.PackagePrice = item.PackagePrice ? item.PackagePrice: 1000;
        this.StartDate    = item.StartDate    ? item.StartDate   : null;
        this.EndDate      = item.EndDate      ? item.EndDate     : null;
    }
}