import { PurchasesBySupplierDataModel } from './../../models/purchases-by-supplier.data.model';
import { Component, OnInit } from '@angular/core';
import { PurchasesBySupplierService } from '../../purchases-by-supplier.service';
import { PurchasesBySupplierSearchModel } from '../../models/purchases-by-supplier-search.model';
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';
@Component({
    selector: 'purchases-by-supplier',
    templateUrl: 'purchases-by-supplier.component.html',
    styleUrls: ['purchases-by-supplier.component.scss']
})
export class PurchasesBySupplierComponent implements OnInit{
    declare lang: any;
    today:Date=new Date();
    list:PurchasesBySupplierDataModel[]=[];
    liabilities:PurchasesBySupplierDataModel[]=[];
    PropertyRights:PurchasesBySupplierDataModel[]=[];
    employees=[];
    nodeList:PurchasesBySupplierDataModel[]=[];
    constructor(private PurchasesBySupplierService:PurchasesBySupplierService){
      this.lang = localStorage.getItem('theme_lang');
    }
    TotalAssets = 0;
    Totalliabilities = 0;
    TotalPropertyRights = 0;
    data:PurchasesBySupplierDataModel[]=[];
    assets:PurchasesBySupplierDataModel[]=[];
    search:PurchasesBySupplierSearchModel={};
    ngOnInit(): void {
        this.PurchasesBySupplierService.search(this.search)
        .subscribe(data => {
          this.data = data;
          this.assets = data;
        })
    
    }
    Search(){
      this.PurchasesBySupplierService.search(this.search)
        .subscribe(data => {
          this.data = data;
        })
    }
    exportTable(){
      var data = document.getElementById('contentToConvert');
      data.style.display = 'inline-block';
      html2canvas(data,{}).then(canvas => {
      var imgWidth = 208;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save('new-file.pdf'); // Generated PDF
      data.style.display = 'block';

    //   data.style.display = 'none';
      
    });
    }
    print(){
        let printContents, popupWin;
        printContents = document.getElementById('contentToConvert').innerHTML;
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.open();
        window.print();
       
      }
}
