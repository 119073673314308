import { Component } from '@angular/core';

@Component({
    selector: 'health-care',
    templateUrl: 'health-care.component.html',
    styleUrls: ['health-care.component.scss']
})
export class HealthCareComponent {

}
