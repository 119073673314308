import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { FileUploader } from 'ng2-file-upload';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'upload-item-image',
    templateUrl: 'upload-image.component.html',
    styleUrls: ['upload-image.component.scss']
})
export class UploadItemImageComponent implements OnInit {
    public uploader: FileUploader;
     hasDragOver = false;
  
    @Input()
     editmode = false;
  
    @Input()
     url = '';
  
    @Output()
     urlChange = new EventEmitter();
      currentLang           : any = 'ar';
  
    constructor(public translate : TranslateService) {
      this.uploader = new FileUploader({
        url: environment.baseApiUrl+'RestaurantMenuItem/UploadImage',
        disableMultipart: false,
        autoUpload: true,
        
      });
      
      this.uploader.response.subscribe(res => {
        // Upload returns a JSON with the image ID
         debugger;
         if(res != null && res != "" && res != undefined){
          this.url = environment.apiUrl+'/'+ res;
          this.urlChange.emit(this.url);
         }
       else{
 
       }
      });
    }
  
    public fileOver(e: any): void {
       
      this.hasDragOver = e;
    }
  
    ngOnInit() {
    }
  
  }
  