<!--component html goes here -->
<!--component html goes here -->
<div class="row" *ngIf="mainForm">
    <div class="col-12">
      <div class="card mb-4">
        <div class="card-body">
          <!-- <h5 class="float-left">{{ "Create Product" | translate }}</h5> -->
          <div class="top-right-button-container text-zero float-right">         
            <button
              type="button"
              (click)="Save()"
              [disabled]="mainForm.invalid"
              class="top-right-button btn btn-primary btn-lg mr-1"
            >
              {{ "TakeInventory" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
        <!-- <div class="col-md-12 col-lg-12 col-12 mb-12"> -->
          <div class="card">
            <tabset class="card-tabs" [justified]="true">
              <tab heading="{{ 'InventorySheet' | translate }}" class="card-body">
                <!-- <h6 class="mb-4">{{ "Basic Data" | translate }}</h6> -->
                <div class="modal-body">
                  <form
                    class="md-float-material form-material"
                    [formGroup]="mainForm"
                  >
                    <div class="row">
                      <div class="col-md-6">
                          <label for="inputState">{{ "InventoryNumber" | translate }}</label>
                          <input
                            class="form-control"
                            formControlName="InventoryNumber"
                            rows="2"
                            placeholder="{{ 'InventoryNumber' | translate }}"
                          />
                          <div
                            class="messages text-danger"
                            *ngIf="
                              (mainForm.get('InventoryNumber').hasError('required') ||
                                mainForm.get('InventoryNumber').invalid) &&
                              (mainForm.get('InventoryNumber').dirty ||
                                mainForm.get('InventoryNumber').touched)
                            "
                          >
                            {{ "InventoryNumberRequired" | translate }}
                          </div>
                        </div>
                       <div class="col-md-6">
                        <label for="inputState">{{ "from" | translate }}</label>
                        <lable *ngIf="modelObject.Id>0">
                          {{modelObject.WarehouseName}}
                        </lable>
                        <select
                           id="inputState"
                           class="form-control"
                           formControlName="WarehouseId"
                         >
                          <option selected value="0">
                           {{ "Warehouse" | translate }}
                          </option>
                          <option
                            *ngFor="let warehouse of WarehouseList"
                            value="{{ warehouse.Id }}"
                            >
                            {{warehouse.Name }}
                         </option>
                        </select>
                      </div>
                    </div>
                    
                  </form>
                </div>
              </tab>
            </tabset>
          </div>
   </div>
   <div class="col-md-6">
    <div class="card">
      <tabset class="card-tabs" [justified]="true">
        <tab heading="{{ 'Warehouse' | translate }}" class="card-body">
          <div class="modal-body">
            <form
                    class="md-float-material form-material"
                    [formGroup]="mainForm"
                  >
                  <div class="row">
                    <div class="col-md-6">
                        <label for="inputState">{{ "Date" | translate }}</label>
                        <!-- <barcode type="code128b">Hello</barcode> -->
                        <input
                          class="form-control"
                          formControlName="Date"
                          rows="2"
                          placeholder="{{ 'Date' | translate }}"
                          type="date" />
                        <div
                          class="messages text-danger"
                          *ngIf="
                            (mainForm.get('Date').hasError('required') ||
                              mainForm.get('Date').invalid) &&
                            (mainForm.get('Date').dirty ||
                              mainForm.get('Date').touched)
                          "
                        >
                          {{ "DateRequired" | translate }}
                        </div>
                      </div>
  
                  <div class="col-md-4">
                    <label for="inputState">{{ "Time" | translate }}</label>
                    <!-- <barcode type="code128b">Hello</barcode> -->
                    <input
                      class="form-control"
                      formControlName="Time"
                      rows="2"
                      placeholder="{{ 'Time' | translate }}"
                      type="time"
                    />
                    <div
                      class="messages text-danger"
                      *ngIf="
                        (mainForm.get('Time').hasError('required') ||
                          mainForm.get('Time').invalid) &&
                        (mainForm.get('Time').dirty ||
                          mainForm.get('Time').touched)
                      "
                    >
                      {{ "NameRequired" | translate }}
                    </div>
                  </div>
                </div>
            </form>
          </div>
        </tab>
      </tabset>
    </div>
   </div>
  </div>
  <td></td>
  <td></td>

  <td></td>
  <div class="row">
  <div class="col-md-12">
    <div class="card">
      <tabset class="card-tabs" [justified]="true">
        <tab class="card-body">
          <div class="modal-body">
            <form
                    class="md-float-material form-material"
                    [formGroup]="mainForm"
                  >
                  <div class="row">
                    
                      <div class="col-md-12">
                        <label for="inputState">{{
                          "Notes" | translate
                        }}</label>
                     
                        <textarea formControlName="Notes" id="" cols="8" rows="3"
                          class="form-control"  placeholder="{{ 'Notes' | translate }}"></textarea>
                        <div
                          class="messages text-danger"
                          *ngIf="
                            (mainForm.get('Notes').hasError('required') ||
                              mainForm.get('Notes').invalid) &&
                            (mainForm.get('Notes').dirty ||
                              mainForm.get('Notes').touched)
                          "
                        >
                          {{ "NotesRequired" | translate }}
                        </div>
                      </div>
                </div>
            </form>
          </div>
        </tab>
      </tabset>
    </div>
   </div>
  </div>
  <td></td>
  <td></td>
  <!-- <div class="row">
    <div class="col-md-12">
      <div class="card">
        <tabset class="card-tabs" [justified]="true">
          <tab heading="{{ 'Items' | translate }}" class="card-body">
           
                  <table  *ngIf="mainForm.get('BillType').value == 1||mainForm.get('BillType').value ==2 " class="tableView" >
                  <thead class="no-border-top">
                  <tr class="thead-light"  *ngIf="ItemsStoreBell.length > 0">
                    <td>{{'Item'|translate}}</td>
                    <td>
                      {{'UnitPrice'|translate}}
                    </td>
                    <td>{{'Quantity'|translate}}</td>                 
                    <td>{{'InventoryBeforeQuantity'|translate}}</td>
                    <td>{{'InventoryAfterQuantity'|translate}}</td>
                    <td>{{'Total'|translate}}</td>
                  </tr>
           
     
              
                </thead>
                <tbody>
                  
                  <tr *ngFor="let bell of ItemsStoreBell; let i = index">
                  <td style="vertical-align: middle">
                    <select
                    id="inputState"
                    class="form-control"
                    [(ngModel)]="bell.ItemId"
                    [ngModelOptions]="{standalone: true}"
                    (change)="setItem(bell)"
                    
                  >
                    <option selected value="0">
                      {{ "Items" | translate }}
                    </option>
                    <option
                      *ngFor="let producte of ProducteList"
                      value="{{ producte.Id }}"                    
                    >
                      {{producte.Name }}
                    </option>
                  </select>
                </td>
               
                <td style="vertical-align: middle">
                  <input
                    [(ngModel)]="bell.SalePrice"
                    type="number"
                    class="form-control"
                  />
                </td>
                
                <td >                 
                  <input class="form-control" type="number" (change)="calculateTotalItemValue()" [(ngModel)]="bell.AdjustedQuantity">               
                </td>
                <td style="vertical-align: middle">
                  <input
                    [(ngModel)]="bell.Quantity"
                    type="number"
                    class="form-control"
                    readonly
                  />
                </td>
                <td >     
                  <input *ngIf="mainForm.get('BillType').value == 1" class="form-control" type="number" [(ngModel)]="bell.Quantity+bell.AdjustedQuantity " readonly>    
                  <input *ngIf="mainForm.get('BillType').value == 2" class="form-control" type="number" [(ngModel)]="bell.Quantity-bell.AdjustedQuantity " readonly>    
                </td>
                <td > 
                  <input class="form-control" type="number" [(ngModel)]=" bell.SalePrice*bell.AdjustedQuantity"readonly>                
                </td>
               

                <td colspan="2" style="text-align: center; vertical-align: middle" >
                  <i 
                  
                    class="simple-icon-close"
                    (click)="removeItemsFooter(bell.Id)"
                    style="
                      font-size: 20px;
                      font-weight: bolder;
                      cursor: pointer;
                      color: #dc3545;
                    "
                  ></i>
                </td>
              </tr>
              <tr *ngIf="ItemsStoreBell">
                <td>
                  <button class="top-right-button btn btn-primary btn-lg mr-1"  (click)="
                  !(
                    ProducteList.invalid ||
                    tableEngine.currentAction === 'loading'
                  )
                    ? AddonClick()
                    : ''
                ">
                {{ "Add" | translate }}
                   
                  </button>
                </td>
              
                <td></td>
                <td></td>
                <td></td>
                <td></td>
               <td>
                <label>{{"Total" | translate}}</label>
                <input class="form-control" type="number" [(ngModel)]="currentTotal" readonly>
               </td>
              
              </tr>
                </tbody>
              </table>
           
          </tab>
        </tabset>
      </div>
    </div>
  </div> -->