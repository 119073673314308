import { ViewPurchaseInvoiceComponent } from './views/purchases/view-purchase-invoice/view-purchase-invoice.component';
import { SuppliersDirectoryComponent } from './views/purchases/reports/suppliers-directory/components/suppliers-directory/suppliers-directory.component';
import { PurchasesBySupplierComponent } from './views/purchases/reports/purchases-by-supplier/components/purchases-by-supplier/purchases-by-supplier.component';
import { HRModule } from './HR/hr.module';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { PosPortalComponent } from './views/points-of-sales/pos-portal/pos-portal.component';
import { CashierComponent } from './views/points-of-sales/cashier/cashier.component';
import { RestaurantsPosPortalComponent } from './views/restaurants/pos-portal/pos-portal.component';
import { RestaurantsCashierComponent   } from './views/restaurants/cashier/cashier.component';
import { QuotationsComponent } from './views/sales/quotations/quotations.component';
import { AllClientBusinessExtractsComponent } from './Contracting/client-business-extract/components/all-client-business-extracts/all-client-business-extracts.component';
import { InvoicesModule } from './invoices/invoices.module';
import { PagesContainersModule } from './containers/pages/pages.containers.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { ViewsModule } from './views/views.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CreateClientBusinessExtractComponent } from './Contracting/client-business-extract/components/create-client-business-extract/create-client-business-extract.component';

import { HttpClientModule } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { LayoutContainersModule } from './containers/layout/layout.containers.module';
import { CommonModule, DatePipe } from '@angular/common';


import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ModalModule } from 'ngx-bootstrap/modal';

import {SharedModule} from './shared/shared.module';
import { TableFilterPipe } from './pipes/table-filter.pipe';
import { SearchPipePipe } from './pipes/search-pipe.pipe';
import { ArchwizardModule } from 'angular-archwizard';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireAuthGuardModule } from '@angular/fire/auth-guard';
import { FormsModule as FormsModuleAngular } from '@angular/forms';
import { ComponentsCarouselModule } from './components/carousel/components.carousel.module';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { HeadroomModule } from '@ctrl/ngx-headroom';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';


import { SimpleNotificationsModule } from 'angular2-notifications';
import { AllProjectsComponent } from './Contracting/projects/components/all-projects/all-projects.component';
import { CreateProjectComponent } from './Contracting/projects/components/create-project/create-project.component';
import { MatTabsModule } from '@angular/material/tabs';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { SortablejsModule } from 'ngx-sortablejs';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { CreateContractorBusinessExtractComponent } from 'src/app/Contracting/contractor-business-extract/components/create-contractor-business-extract/create-contractor-business-extract.component';
import { AllContractorBusinessExtractComponent } from 'src/app/Contracting/contractor-business-extract/components/all-contractor-business-extract/all-contractor-business-extract.component';
import { ContractorsComponent } from './Contracting/contractors/contractors.component';
import { ConstructionportalComponent } from './Contracting/constructionportal/constructionportal.component';
import { WorkitemsComponent } from './Contracting/workitems/workitems.component';
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon'; 
import { MatButtonModule    } from '@angular/material/button';
import { MatCheckboxModule  } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field'; 
import { MatInputModule     } from '@angular/material/input';

import { MatProgressBarModule } from '@angular/material/progress-bar';
import {CdkTreeModule} from '@angular/cdk/tree';
import { SidePopupComponent } from './views/app/aio/shared/side-popup/side-popup.component';
import { PurchaseOrdersComponent } from './views/purchases/purchase-orders/purchase-orders.component';
import { PurchasesPortalComponent } from './views/purchases/purchases-portal/purchases-portal.component';
import { ReturnPurchasesComponent } from './views/purchases/return-purchases/return-purchases.component';
import { InvoicePurchaseComponent } from './views/purchases/invoice-purchase/invoice-purchase.component';
import { UploadInvoicePurchaseFileComponent } from './views/purchases/upload-invoice-purchase-file/upload-invoice-purchase-file.component';
import { RequestReceiptComponent } from './views/purchases/request-receipt/request-receipt.component';
import { RatingModule } from 'ngx-bootstrap/rating';
import { ComponentsPlayerModule } from './components/player/components.player.module';
import { LightboxModule } from 'ngx-lightbox';
import { RouterModule } from '@angular/router';
import { ListPageHeaderComponent } from './containers/pages/list-page-header/list-page-header.component';
import { SalesPortalComponent } from './views/sales/sales-portal/sales-portal.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { BootstrapModule } from 'src/app/components/bootstrap/bootstrap.module';
import { PaginationConfig } from 'ngx-bootstrap/pagination';
import { CashierOrdersComponent } from './views/points-of-sales/cashier-orders/cashier-orders.component'
import { CashierSessionsComponent } from './views/points-of-sales/cashier-sessions/cashier-sessions.component'
import { CashierStaffComponent } from './views/points-of-sales/cashier-staff/cashier-staff.component'
import { RestaurantsCashierOrdersComponent   } from './views/restaurants/cashier-orders/cashier-orders.component'
import { RestaurantsCashierSessionsComponent } from './views/restaurants/cashier-sessions/cashier-sessions.component'
import { RestaurantsCashierStaffComponent    } from './views/restaurants/cashier-staff/cashier-staff.component'
import { QuotationRequestsComponent } from './views/purchases/quotation-requests/quotation-requests.component';
import { PurchaseQuotationsComponent } from './views/purchases/purchase-quotations/purchase-quotations.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import { CashierWorkShiftsComponent } from './views/points-of-sales/cashier-work-shifts/cashier-work-shifts.component';
import { ConfigPosComponent } from './views/points-of-sales/config-pos/config-pos.component';
import { ConfigPosGeneralComponent } from './views/points-of-sales/config-pos-general/config-pos-general.component'
import { CashRegistersComponent } from './views/points-of-sales/cashier-machines/cashier-machines.component';
import { RestaurantsCashierWorkShiftsComponent } from './views/restaurants/cashier-work-shifts/cashier-work-shifts.component';
import { RestaurantsConfigPosComponent         } from './views/restaurants/config-pos/config-pos.component';
import { RestaurantsConfigPosGeneralComponent  } from './views/restaurants/config-pos-general/config-pos-general.component'
import { RestaurantsCashRegistersComponent     } from './views/restaurants/cashier-machines/cashier-machines.component';
import { UploadItemImageComponent     } from './views/restaurants/upload-image/upload-image.component';
import { RestaurantCategoryComponent     } from './views/restaurants/restaurant-category/restaurant-category.component';
import { RestaurantMenuItemComponent     } from './views/restaurants/restaurant-menu-item/restaurant-menu-item.component';

import { FileUploadModule } from 'ng2-file-upload';
import { ConfigPurchasesComponent } from './views/purchases/config-purchases/config-purchases.component';

import { ListAllProductesComponent } from './Invintory/ProductesAndServices/list-all-productes/list-all-productes.component';
import { ListAllServicesComponent } from './Invintory/ProductesAndServices/ServicesComponents/list-all-services/list-all-services.component';
import { PriceListComponent } from './Invintory/Price List/price-list/price-list.component';
import{AddPriceListComponent} from './Invintory/Price List/add-price-list/add-price-list.component';
import { InfoPriceListComponent } from './Invintory/Price List/info-price-list/info-price-list.component';
import { ListAllWarehouseComponent } from './Invintory/warehouses/list-all-warehouse/list-all-warehouse.component';
import { AddWarehouseComponent } from './Invintory/warehouses/add-warehouse/add-warehouse.component';
import { InfoWarehouseComponent } from './Invintory/warehouses/Info-Warehouse/info-warehouse/info-warehouse.component';
import { AddProductesComponent } from './Invintory/ProductesAndServices/add-productes/add-productes.component';
import{InfoProductesComponent}from './Invintory/ProductesAndServices/info-productes/info-productes.component'
import { InvintoryDashbordComponent } from './Invintory/invintory-dashbord/invintory-dashbord.component';
import{InfoServiceComponent}from './Invintory/ProductesAndServices/ServicesComponents/info-service/info-service.component';
import{AddServicesComponent}from './Invintory/ProductesAndServices/ServicesComponents/add-services/add-services.component';
import{StockAdjustmentComponent} from './Invintory/ProductesAndServices/stock-adjustment/stock-adjustment.component';
import { StoreDataComponent } from './Inventory/components/store-data/store-data.component';
import { BalanceNameComponent } from './Inventory/components/Balance-Name/balance-name/balance-name.component';
import { ListItemBalanceComponent } from './Inventory/components/item-balance/list-item-balance/list-item-balance.component';
import { AddItemBalanceComponent } from './Inventory/components/item-balance/Add-Item/add-item-balance/add-item-balance.component';
import { AdditionAdjustmentComponent } from './Inventory/components/addition-adjustment/addition-adjustment.component';
import{BrandComponent}from 'src/app/Invintory/Category-And-Brand/brand/brand.component';
import{CategoryComponent}from 'src/app/Invintory/Category-And-Brand/category/category.component';
import {AddStoreBellComponent }from './Invintory/Stores-Bells/add-store-bell/add-store-bell.component';
import { InfoStoreBellComponent}from './Invintory/Stores-Bells/info-store-bell/info-store-bell.component';
import {ListStoreBellComponent }from './Invintory/Stores-Bells/list-store-bell/list-store-bell.component';
import {InfoInventoryManagementComponent} from './Invintory/InventoryManagement/info-inventory-management/info-inventory-management.component';
import{ AddInventoryManagementComponent}from './Invintory/InventoryManagement/add-inventory-management/add-inventory-management.component';
import { ListInventoryManagementComponent}from './Invintory/InventoryManagement/list-inventory-management/list-inventory-management.component';
import {InventorySheetComponent} from './Invintory/InventoryManagement/inventory-sheet/inventory-sheet.component';
import {TransferStoreBillComponent} from './Invintory/Stores-Bells/transfer-store-bill/transfer-store-bill.component';
import {SubtractStoreBillComponent}from './Invintory/Stores-Bells/subtract-store-bill/subtract-store-bill.component';
import{InfoTransferBillComponent }from './Invintory/Stores-Bells/info-transfer-bill/info-transfer-bill.component';
import {InfoSubtructBillComponent} from './Invintory/Stores-Bells/info-subtruct-bill/info-subtruct-bill.component';

import { AccountingModule } from './views/app/aio/acounting_components/accounting.module';
import { ShowSuppliersComponent } from './invoices/suppliers/show-suppliers/show-suppliers.component';
import { AddSuppliersComponent } from './invoices/suppliers/add-suppliers/add-suppliers.component';
import { SupplierBalancesComponent } from './views/purchases/reports/supplier-balances/components/supplier-balances/supplier-balances.component';
import { ProductPurchasesComponent } from './views/purchases/reports/product-purchases/components/product-purchases/product-purchases.component';
import { InventorySheetReportComponent } from './Invintory/reports/inventory-sheet/components/inventory-sheet-report/inventory-sheet-report.component';
import { SummaryOfInventoryOperationsComponent } from './Invintory/reports/summary-of-inventory-operations/components/summary-of-inventory-operations/summary-of-inventory-operations.component';
import { DetailedMovementOfInventoryComponent } from './Invintory/reports/detailed-movement-of-inventory/components/detailed-movement-of-inventory/detailed-movement-of-inventory.component';
import { EstimatedinventoryValueComponent } from './Invintory/reports/estimatedinventory-value/components/estimatedinventory-value/estimatedinventory-value.component';
import { StoreBalanceSummaryComponent } from './Invintory/reports/store-balance-summary/components/store-balance-summary/store-balance-summary.component';
import { MembershipModule } from './memberships/membership.module'
import { SubscriptionModule } from './Subscriptions/subscription.module';
import { HealthCareModule } from './health-care/health-care.module';
import { MyPharmacyModule } from './my-pharmacy/my-pharmacy.module';
import { CouncilAndMembersAffairsModule } from './Council-and-members-affairs/Council-And-Members-Affairs.module'
@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    SimpleNotificationsModule,
    LayoutContainersModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    FileUploadModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    FormsModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    ArchwizardModule,
    InvoicesModule,
    HRModule,
    MembershipModule,
    SubscriptionModule,
    HealthCareModule,
    CouncilAndMembersAffairsModule,
    MyPharmacyModule,
    AccountingModule,
    SharedModule,
    NgSelectModule,
    FormsModuleAngular,
    AngularFireAuthModule,
    AngularFireAuthGuardModule,
    ComponentsCarouselModule,
    TabsModule.forRoot(),
    HeadroomModule,
    ScrollToModule.forRoot(),
    NgxDatatableModule,
    CollapseModule,
    PagesContainersModule,
    PopoverModule.forRoot(),
    TooltipModule.forRoot(),
    SimpleNotificationsModule.forRoot(),
    AccordionModule,
    MatTabsModule,
    BsDropdownModule,
    SortablejsModule,
    PaginationModule,
    MatTreeModule,
    MatIconModule,
    MatButtonModule   ,
MatCheckboxModule ,
MatFormFieldModule,
MatInputModule    ,
MatProgressBarModule,
/////////////////////////
// ContextMenuModule.forRoot({
//   useBootstrap4: true,
// }),
// HotkeyModule.forRoot(),

RatingModule.forRoot(),
ComponentsPlayerModule,
LightboxModule,
RouterModule,
CommonModule,
PerfectScrollbarModule,
BootstrapModule,

TimepickerModule.forRoot(),

   
  ],
  declarations: [
    AppComponent,



    AllProjectsComponent,
    CreateProjectComponent,
    AllClientBusinessExtractsComponent,
    CreateClientBusinessExtractComponent,
    CreateContractorBusinessExtractComponent,
    AllContractorBusinessExtractComponent,
    ContractorsComponent,
    ConstructionportalComponent,
    WorkitemsComponent,
    
    StoreDataComponent,
    BalanceNameComponent,
   
    PurchaseOrdersComponent,
    PurchasesPortalComponent,
    ReturnPurchasesComponent,
    InvoicePurchaseComponent,
    RequestReceiptComponent,
    ListItemBalanceComponent,
    AddItemBalanceComponent,
    // ListPageHeaderComponent,
    QuotationsComponent,
    SalesPortalComponent,
    CashierComponent,
    PosPortalComponent,
    QuotationRequestsComponent,
    PurchaseQuotationsComponent,
    CashierSessionsComponent,
    CashierOrdersComponent,
    CashierStaffComponent,
    AdditionAdjustmentComponent,
    CashierWorkShiftsComponent,
    ConfigPosGeneralComponent,
    ConfigPosComponent,

    CashRegistersComponent,

    ConfigPurchasesComponent,
    RestaurantsPosPortalComponent,
    RestaurantsCashierComponent  ,
    RestaurantsCashierOrdersComponent  ,
RestaurantsCashierSessionsComponent,
RestaurantsCashierStaffComponent   ,
RestaurantsCashierWorkShiftsComponent ,
RestaurantsConfigPosComponent         ,
RestaurantsConfigPosGeneralComponent  ,
RestaurantsCashRegistersComponent     ,
RestaurantCategoryComponent,
RestaurantMenuItemComponent,


InvintoryDashbordComponent,
ListAllProductesComponent,
ListAllServicesComponent,
PriceListComponent,
AddPriceListComponent,
InfoPriceListComponent,
ListAllWarehouseComponent,
AddWarehouseComponent,
InfoWarehouseComponent,
AddProductesComponent,
InfoProductesComponent,
InfoServiceComponent,
AddServicesComponent,
StockAdjustmentComponent,
BrandComponent,
CategoryComponent,
UploadItemImageComponent,
AddStoreBellComponent,
InfoStoreBellComponent,
ListStoreBellComponent,
InfoInventoryManagementComponent,
AddInventoryManagementComponent,
ListInventoryManagementComponent,
InventorySheetComponent,
TransferStoreBillComponent,
SubtractStoreBillComponent,
InfoTransferBillComponent,
InfoSubtructBillComponent,
ShowSuppliersComponent,
AddSuppliersComponent,
UploadInvoicePurchaseFileComponent,

PurchasesBySupplierComponent,
SuppliersDirectoryComponent,
SupplierBalancesComponent,
ProductPurchasesComponent,
ViewPurchaseInvoiceComponent,
InventorySheetReportComponent,
SummaryOfInventoryOperationsComponent,
DetailedMovementOfInventoryComponent,
EstimatedinventoryValueComponent,
StoreBalanceSummaryComponent
  ],
  entryComponents:[UploadItemImageComponent,UploadInvoicePurchaseFileComponent],
  providers: [DatePipe,PaginationConfig],
  exports:[UploadItemImageComponent,UploadInvoicePurchaseFileComponent],

  bootstrap: [AppComponent]
})
export class AppModule { }
