export class expenseCodeModel{
    Id?:number;
    ExpenseNumber?:number;
    descriptionEn?:string;
    descriptionAr?:string;
    DebitAccount?:number;
    DebitAccountId?:number;
    DocumentaryCredits?:string;
    constructor(obj?:expenseCodeModel){
        obj = obj? obj:{};
        this.Id= obj.Id ? obj.Id:0;
        this.ExpenseNumber=obj.ExpenseNumber? obj.ExpenseNumber:0;
        this.descriptionAr=obj.descriptionAr? obj.descriptionAr:'';
        this.descriptionEn=obj.descriptionEn? obj.descriptionEn:'';
        this.DebitAccount=obj.DebitAccount? obj.DebitAccount:0;
        this.DocumentaryCredits=obj.DocumentaryCredits? obj.DocumentaryCredits:'';
        this.DebitAccountId = obj.DebitAccountId? obj.DebitAccountId:0;


    }
}