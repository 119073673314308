
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AllHealthCarePackagesComponent } from './health-care-packages/components/all-health-care-packages/all-health-care-packages.component';
import { HealthCareComponent } from './health-care/health-care.component';
import { AllHealthCareSubscriptionsComponent } from './subscriptions/components/all-health-care-subscriptions/all-health-care-subscriptions.component';
import { CreatrHealthCareSubscriptionComponent } from './subscriptions/components/creatr-health-care-subscription/creatr-health-care-subscription.component';
import { AllDoctorsComponent } from './doctors/components/all-doctors/all-doctors.component';
import { CreateDoctorComponent } from './doctors/components/create-doctor/create-doctor.component';

const routes: Routes = [
    {
        path: '', component: HealthCareComponent,
        
    },
    {
        path: 'pachages', component: AllHealthCarePackagesComponent,
        
    }
    ,
    {
        path: 'subscriptions', component: AllHealthCareSubscriptionsComponent,
        
    }
    ,
    {
        path: 'create-subscription/:Id', component: CreatrHealthCareSubscriptionComponent,
        
    },

    {
        path: 'doctors', component: AllDoctorsComponent,
        
    }
    ,
    {
        path: 'create-doctor/:Id', component: CreateDoctorComponent,
        
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class HealthCareRoutingModule { }