<div style="text-align: center;" *ngIf="Form">
    <a class="btn btn-light" 
    routerLink="../../budgets"
        style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Back" |
        translate}}</a>
    <button 
        *ngIf="Form"
         id="smallModalSubmitButton"
        class="btn btn-secondary" 
        (click)="Save()"
        [disabled]="Form.invalid || TotalCreditor != TotalDebtor"
        style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{buttonName |
        translate}}</button>
        <button 
        *ngIf="Form"
        [disabled]="Form.invalid || TotalCreditor != TotalDebtor"
        id="smallModalSubmitButton"
        class="btn btn-secondary" (click)="addAndNew()"
        style="font-weight: bold; width: 250px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{buttonName+"AndNew" |
        translate}}</button>
  </div>
<Form [formGroup]="Form" *ngIf="Form ">
    <div class="card">
        <div class="form-group  Card_Padding">
                    <br>
    
       <div class="row">
        <div class="col-md-10">
        <h3>{{ buttonName + ' Budget' | translate }}</h3>
    
        </div>
    
        <!-- <button type="button" class="btn btn-primary"(click)="onClick()">{{ 'Save' | translate }}</button>{{' '}} -->
    
       </div>
       <div class="row">
        <div class="col-md-4">
            <label for="inputEmail4">{{ 'Cost Center' | translate }}</label>
            <ng-select   formControlName="CostCenterId"  (change)="changeCostCenter()"  [hideSelected]="true">
              <ng-option selected desabled [value]="0">{{ "Cost Center"|translate}}</ng-option>
              <ng-option *ngFor="let car of centers" [value]="car.Id">{{lang==='ar-EG'?car.NameAr:car.NameEn}}</ng-option>
          </ng-select>
        </div>
 
        <div class="col-md-4">
            <label for="inputEmail4">{{ 'Monthly Distribution' | translate }}</label>
            <ng-select   formControlName="MonthlyDistributionId"  (change)="getAccounts()"  [hideSelected]="true">
                <ng-option selected desabled [value]="0">{{ "Monthly Distribution"|translate}}</ng-option>
                <ng-option *ngFor="let car of MonthlyDistributions" [value]="car.Id">{{car.Name}}</ng-option>
            </ng-select>
            <!-- <textarea formControlName="MonthlyDistributionId" type="text" id="inputEmail4" class="form-control"></textarea> -->
        </div>
        <div class="col-md-4">
            <label for="inputEmail4">{{ 'Total Amount' | translate }}</label>
            <input formControlName="TotalAmount" type="number" id="inputEmail4" class="form-control" readonly>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <input type="checkbox" 
                formControlName="ApplicableOnMaterialRequest"
                style="margin-top: 30px;font: bold;"
                > 
                {{'Applicable On Material Request'|translate}}
                <br />
                <div *ngIf="Form.get('ApplicableOnMaterialRequest').value" style="margin-top: 2%;">
                
                    <div class="row">
                        <div class="col-md-6">
                            <label for="inputEmail4">{{ 'Action if Annual Budget Exceeded on MR' | translate }}</label>
                            <select formControlName="ActionIfAnnualBudgetExceededOnMR" class="custom-select">
                                <option [value]="'Stop'">{{'Stop'|translate}}</option>
                                <option [value]="'Warn'">{{'Warn'|translate}}</option>
                                <option [value]="'Ignore'">{{'Ignore'|translate}}</option>
                            </select>
                        </div>
                        <div class="col-md-6">
                            <label for="inputEmail4">{{ 'Action if Accumulated Monthly Budget Exceeded on MR' | translate }}</label>
                            <select formControlName="ActionIfAccumulatedMonthlyBudgetExceededOnMR" class="custom-select">
                                <option [value]="'Stop'">{{'Stop'|translate}}</option>
                                <option [value]="'Warn'">{{'Warn'|translate}}</option>
                                <option [value]="'Ignore'">{{'Ignore'|translate}}</option>
                            </select>
                        </div>
                    </div>
                </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <input type="checkbox" 
                formControlName="ApplicableOnPurchaseOrder"
                style="margin-top: 30px;font: bold;"
                > 
                {{'Applicable on Purchase Order'|translate}}
                <br />
                <div *ngIf="Form.get('ApplicableOnPurchaseOrder').value" style="margin-top: 2%;">
                
                    <div class="row">
                        <div class="col-md-6">
                            <label for="inputEmail4">{{ 'Action if Annual Budget Exceeded on PO' | translate }}</label>
                            <select formControlName="ActionIfAnnualBudgetExceededOnPO" class="custom-select">
                                <option [value]="'Stop'">{{'Stop'|translate}}</option>
                                <option [value]="'Warn'">{{'Warn'|translate}}</option>
                                <option [value]="'Ignore'">{{'Ignore'|translate}}</option>
                            </select>
                        </div>
                        <div class="col-md-6">
                            <label for="inputEmail4">{{ 'Action if Accumulated Monthly Budget Exceeded on PO' | translate }}</label>
                            <select formControlName="ActionIfAccumulatedMonthlyBudgetExceededOnPO" class="custom-select">
                                <option [value]="'Stop'">{{'Stop'|translate}}</option>
                                <option [value]="'Warn'">{{'Warn'|translate}}</option>
                                <option [value]="'Ignore'">{{'Ignore'|translate}}</option>
                            </select>
                        </div>
                    </div>
                </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <input type="checkbox" 
                formControlName="ApplicableOnBookingActualExpenses"
                style="margin-top: 30px;font: bold;"
                > 
                {{'Applicable on booking actual expenses'|translate}}
                <br />
                <div *ngIf="Form.get('ApplicableOnBookingActualExpenses').value" style="margin-top: 2%;">
                
                    <div class="row">
                        <div class="col-md-6">
                            <label for="inputEmail4">{{ 'Action if Annual Budget Exceeded on Actual' | translate }}</label>
                            <select formControlName="ActionIfAnnualBudgetExceededOnActual" class="custom-select">
                                <option [value]="'Stop'">{{'Stop'|translate}}</option>
                                <option [value]="'Warn'">{{'Warn'|translate}}</option>
                                <option [value]="'Ignore'">{{'Ignore'|translate}}</option>
                            </select>
                        </div>
                        <div class="col-md-6">
                            <label for="inputEmail4">{{ 'Action if Accumulated Monthly Budget Exceeded on Actual' | translate }}</label>
                            <select formControlName="ActionIfAccumulatedMonthlyBudgetExceededOnActual" class="custom-select">
                                <option [value]="'Stop'">{{'Stop'|translate}}</option>
                                <option [value]="'Warn'">{{'Warn'|translate}}</option>
                                <option [value]="'Ignore'">{{'Ignore'|translate}}</option>
                            </select>
                        </div>
                    </div>
                </div>
        </div>
    </div>
    <br />
        </div>
    
    </div>
</Form>
<br />
<br />

<div class="card" *ngIf="Form">
    <div >
    <div class="row card-header" style="text-align: center; margin-top: 10px;">
        <div class="col-md-10" style="text-align: center;">
            <th>{{"Accounts"|translate}}</th>
        </div>
     
       </div>
    <div class="card-body">
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">{{'Account'| translate}}</th>
                    <th scope="col">{{'Amount'| translate}}</th>
                    <th scope="col">{{'Delete'| translate}}</th>
                </tr>
            </thead>
            <tbody>
                <tr  *ngFor="let Item of SelectedAccount; let i=index;">
                    <th style="width: 10%;" scope="row">{{i+1}}</th>
                    <td style="width: 30%;">
                        <ng-select  *ngIf="lang==='ar-EG'"  [(ngModel)]="Item.AccountId" bindValue="AccountId"[items]="accounts" (change)="splitAccount($event)"  [hideSelected]="true"  bindLabel="AccountNameAr">
                        </ng-select>
                        <ng-select *ngIf="lang!=='ar-EG'"  [(ngModel)]="Item.AccountId" bindValue="AccountId"[items]="accounts" (change)="splitAccount($event)"  [hideSelected]="true"  bindLabel="AccountNameEn">
                      </ng-select>
                    </td>
                    <td style="width: 20%;"><input [(ngModel)]="Item.Amount" (change)="ChangeAmount(Item.Amount)" type="number" class="form-control"></td>
                    <td style="width: 10%;">
                        <a href="javascript:" tooltip="{{'Delete'|translate}}" (click)="deleteSelectedAccount(Item)"><i class="simple-icon-trash"></i></a>
                    </td>
                </tr>
              
            </tbody>
        </table>

        <div class="col-12 row">
            <div class=" col-md-11">
            </div>
        <button type="button" class="btn btn-primary"(click)="AddonClick()">{{ '+ Add' | translate }}</button>{{' '}}

          </div>
        <br>
    </div>
</div>
</div>
