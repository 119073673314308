export class SalaryItemsModel{
    Id?: number;
    ItemName            ?: string;
    Description         ?: string;
    ItemTypeId          ?: number;
    ItemValueByAmount   ?: number;
    ItemValueByPercent  ?: number;
    SalaryItemTypeNameEn?: string;
    SalaryItemTypeNameAr?: string;
    constructor(item ?: SalaryItemsModel){
        item = item ? item :{};
        this.Id = item.Id ? item.Id : 0;
        this.ItemName             = item.ItemName             ? item.ItemName             : '';
        this.Description          = item.Description          ? item.Description          : '';
        this.SalaryItemTypeNameEn = item.SalaryItemTypeNameEn ? item.SalaryItemTypeNameEn : '';
        this.SalaryItemTypeNameAr = item.SalaryItemTypeNameAr ? item.SalaryItemTypeNameAr : '';
        this.ItemTypeId           = item.ItemTypeId           ? item.ItemTypeId           : 0;
        this.ItemValueByAmount    = item.ItemValueByAmount    ? item.ItemValueByAmount    : 0;
        this.ItemValueByPercent   = item.ItemValueByPercent   ? item.ItemValueByPercent   : 0;
    }
}