export class VacationsTypesModel{
    Id?:number;
    Name                  ?:string;
    Description           ?:string;
    MaxNumberInYear       ?:number;
    Color                 ?:string;
    MaximumConsecutiveDays?:number;
    NeedPermission        ?:boolean;
    constructor(item?:VacationsTypesModel){
        item = item ? item : {};
        this.Id = item.Id ? item.Id : 0;
        this.Name                   = item.Name                   ? item.Name                   : '';
        this.Description            = item.Description            ? item.Description            : '';
        this.MaxNumberInYear        = item.MaxNumberInYear        ? item.MaxNumberInYear        : 0;
        this.Color                  = item.Color                  ? item.Color                  : '#000';
        this.MaximumConsecutiveDays = item.MaximumConsecutiveDays ? item.MaximumConsecutiveDays : 0;
        this.NeedPermission         = item.NeedPermission         ? item.NeedPermission         : false;
    }
}