import { Component, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NotificationType, NotificationsService } from 'angular2-notifications';
import { PackageModel } from '../../models/package.model';
import { DataFilterer } from 'src/app/views/invoice/shared/DataFilterer';
import { DataSorter } from 'src/app/views/invoice/shared/DataSorter';
import { DataPaginator } from 'src/app/views/invoice/shared/DataPaginator';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { SidePopupComponent } from 'src/app/views/invoice/side-popup/side-popup.component';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { of } from 'rxjs';

@Component({
    selector: 'all-packages',
    templateUrl: 'all-packages.component.html',
    styleUrls: ['all-packages.component.scss']
})
export class AllPackagesComponent {
    constructor(private formBuilder: FormBuilder,  private renderer: Renderer2,
        private notifications: NotificationsService, private translate: TranslateService
       ) {  //Additional services for City and Country
      }
      currentScreen: any = 'Table';
    
      translationName: string = 'Package'
    
      sortableKeys = [];
      searchableKeys = ['NameEn', 'NameAr'];
      
      modelObject: PackageModel = new PackageModel({});
      ogData: PackageModel [] = [ 
        {Id:5, Name:'باقة 1', Status:'Active', Price:10000 }, 
        {Id:1, Name:'باقة 2', Status:'Active', Price:20000 },
        {Id:2, Name:'باقة 3', Status:'Active', Price:30000 },
        {Id:3, Name:'باقة 4', Status:'Active', Price:40000 }, 
        {Id:4, Name:'باقة 5', Status:'Active', Price:50000 } 
      ];
    //   ogData: PackageModel [] = [ ];
      mainForm: FormGroup = new FormGroup({
        Id: new FormControl(),
        Name: new FormControl(),
        Type: new FormControl(),
        Status: new FormControl(),
        Price: new FormControl(),
        PeriodValue: new FormControl(),
        PeriodType: new FormControl(),
        Description: new FormControl(),
      });
    
      ngOnInit(): void {
        this.lang = localStorage.getItem('theme_lang');
        this.itemOrder = { label: 'Name', property: this.lang === 'ar-EG' ? 'Name' : 'Name' };
        this.sortableKeys = [
          { label: 'Name', property: this.lang === 'ar-EG' ? 'Name' : 'Name' },
          { label: 'Status', property: 'Status' },
          { label: 'Price', property: 'Price' },
        ];
        //this.loadData();
        this.loadServices();
      }
    
      createForm(): FormGroup {
        return this.formBuilder.group({
          Id: [this.modelObject.Id >= 0 ? this.modelObject.Id : 0],
          Name: [this.modelObject.Name],
          Description: [this.modelObject.Description],
          PeriodType: [this.modelObject.PeriodType],
          PeriodValue: [this.modelObject.PeriodValue],
          Price: [this.modelObject.Price],
          Status: [this.modelObject.Status],
          Type: [this.modelObject.Type],
          });
      }
    
      addObject() {
        //prevent multiclick submits. enable again after API response.
        this.currentAction = '';
    
        this.modelObject = new PackageModel();
        this.modelObject = this.mainForm.getRawValue();
        this.modelObject.Id = 0;
      
        this.executeAddObject();
      }
    
      executeAddObject(){
        this.ogData.push(this.modelObject);
      }
    
      editObject() {
        this.savedPage = this.currentPage;
    
        
      }
    
      executeEditObject(Object)
      {
       
      }
    
      //Deletion methods:
      //Customize if needed.
      //Pass the target object as parameter if the target API can only delete singular objects and not lists.
      deleteObject() {
        this.savedPage = this.currentPage;
        //prevent multiclicks. re enable after API response
        this.currentAction = '';
        this.executeDeleteObject();
      }
    
      //Same here...
      executeDeleteObject(){
      
      }
      ////////////////////////////////////////////////////////////////////////////
      //
      //
      //
      //
      //
      ////////////////////////////////////////////////////////////////////////////
      //  EXCLUSIVE :
      //  Specifically for this component.
      ///////////////////////////////
    
      //Get any additional data here
      loadServices(){ 
        this.currentAction = '';
        this.loadData();
      }
   
      lang: string;
      currentFormMode: string = '';
      currentAction: string = '';
    
    
      //ogData = [];
      filteredData = [...this.ogData];
      sortedData = [...this.filteredData];
      paginatedData = [...this.filteredData];
      lastId: number = this.ogData.length + 1;
    
      SelectAllBox: any = false;
    
      itemsPerPage = 10;
      itemOptionsPerPage = [5, 10, 20];
      selected = [];
      selectAllState = '';
      itemOrder: any;
      orderAsc: boolean = true;
    
      currentPage = 1;
      savedPage = 0;
    
    
      dataFilterer = new DataFilterer();
      dataSorter = new DataSorter();
      dataPaginator = new DataPaginator();
    
      loadData() {
        this.sortData();
        this.loadSearch();
        this.currentAction = '';
        
      }
    
      sortData() {
        this.sortedData = this.dataSorter.sortData(this.itemOrder.property, this.ogData);
        this.filterData();
      }
    
      reverseSortData() {
        this.savedPage = this.currentPage;
        this.sortedData = this.sortedData.slice().reverse();
        this.filterData();
      }
    
      filterData() {
        this.filteredData = this.dataFilterer.filterData(this.SearchTerm, this.searchableKeys, this.sortedData)
        if (this.savedPage === 0) {
          this.paginateData(this.itemsPerPage, 1);
        }
        else {
          this.paginateData(this.itemsPerPage,
            Math.ceil((this.filteredData.length / this.itemsPerPage)) >= this.savedPage ?
              this.savedPage :
              Math.ceil((this.filteredData.length / this.itemsPerPage))
          );
        }
        this.savedPage = 0;
      }
    
      paginateData(newItemsPerPage: number, newCurrentPage: number) {
        this.itemsPerPage = newItemsPerPage;
        this.currentPage = newCurrentPage;
        this.paginatedData = this.filteredData.slice((newCurrentPage - 1) * (newItemsPerPage), (newItemsPerPage * newCurrentPage));
      }
    
      changeSort(newSort: any) {
        if (this.itemOrder.label === newSort.label) {
          this.orderAsc = !this.orderAsc;
          const oldPage = this.currentPage;
          this.reverseSortData()
          this.currentPage = oldPage;
        }
        else {
          this.orderAsc = true;
          this.itemOrder = newSort;
          this.sortData();
        }
      }
    
      getSort(label: any) {
        return this.sortableKeys.find(i => i.label === label);
      }
    
      changePage(event: PageChangedEvent): void {
        this.currentPage = event.page;
        this.paginateData(this.itemsPerPage, this.currentPage);
      }
    
      changeFilter() {
        this.deletionList = [];
        this.selected = [];
        this.selectAllState = '';
        this.filterData();
      }
    
      selectAll($event): void {
        if ($event.target.checked) {
          this.selected = [...this.filteredData];
        } else {
          this.selected = [];
        }
        this.setSelectAllState();
      }
    
      setSelectAllState(): void {
        if (this.selected.length >= this.filteredData.length) {
          this.selectAllState = 'checked';
        } else if (this.selected.length !== 0) {
          this.selectAllState = 'indeterminate';
        } else  {
          this.selectAllState = '';
        }
      }
    
      isSelected(p: PackageModel): boolean {
        return this.selected.findIndex(x => x.Id === p.Id) > -1;
      }
    
      onSelect(item: PackageModel): void {
        if (this.isSelected(item)) {
          this.selected = this.selected.filter(x => x.Id !== item.Id);
        } else {
          this.selected.push(item);
        }
        this.setSelectAllState();
      }
    
      findByCode(list: any, code: any): any {
        return list.find(x => x.Code === code)
      }
    
      @ViewChild('sideModalRef', { static: true }) sideModalRef: SidePopupComponent;
      @ViewChild('delModal') delModal: ModalDirective;
      showAddNewModalRef(): void {
        this.currentFormMode = 'Add'
        this.modelObject = new PackageModel;
        this.mainForm = this.createForm();
        this.sideModalRef.show();
      }
    
      showEditModalRef(Id: any): void {
        this.currentFormMode = 'Edit'
        this.modelObject = this.ogData.find(i => i.Id === Id);
        of(this.modelObject).subscribe(data => {
          this.modelObject = data;
        })
        this.mainForm = this.createForm();
        this.sideModalRef.show();
      }
    
      showInfoModalRef(Id: any): void {
        this.currentFormMode = 'Info'
        this.modelObject = this.ogData.find(i => i.Id === Id);
        of(this.modelObject).subscribe(data => {
          this.modelObject = data;
        })
        this.mainForm = this.createForm();
        this.sideModalRef.show();
      }
    
      deletionList: PackageModel[] = [];
      showDelModal(Id: any): void {
        if (Id >= 0) {
          this.currentFormMode = 'Del'
          this.modelObject = this.ogData.find(i => i.Id === Id);
          this.deletionList = [this.ogData.find(i => i.Id === Id)];
          this.delModal.show();
        }
        else if (Id === 'delMulti') {
          this.deletionList = [...this.selected]
          this.delModal.show();
        }
      }
    
      SearchTerm: any = '';
    
    
      finalizeDeletion(){
        if (this.deletionList.length === 1) {
          this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyDeleted") + " " +
            this.translate.instant("One" + this.translationName));
        }
        else if (this.deletionList.length === 2) {
          this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyDeleted") + " " +
            this.translate.instant("Two" + this.translationName + "s"));
        }
        else if (this.deletionList.length > 2 && this.deletionList.length < 11) {
          this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyDeleted") + " " +
            this.deletionList.length + " " + this.translate.instant(this.translationName + "RowsPlur"));
        }
        else {
          this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyDeleted") + " " +
            this.deletionList.length + " " + this.translate.instant(this.translationName + "Rows"));
        }
    
        this.deletionList = [];
        this.selected = [];
    
        this.setSelectAllState();
        if (this.selectAllState === '') {
          this.SelectAllBox = false;
        }
        this.delModal.hide();
      }
    
      toastSuccess(toastHeader: string, toastBody: string): void {
        this.notifications.create(toastHeader, toastBody,
          NotificationType.Success, { timeOut: 3000, showProgressBar: true });
      }
      toastError(toastHeader: string, toastBody: string): void {
        this.notifications.create(toastHeader, toastBody,
          NotificationType.Error, { timeOut: 3000, showProgressBar: true });
      }
      deleteMultiTaxTypes() {
        this.loadData();
        this.delModal.hide();
      }
    
      loadAllData() {
        this.loadSearch();

      }
    
      loadSearch() {
        const prevSearch = this.SearchTerm;
        this.SearchTerm = '';
        setTimeout(() => {
          this.SearchTerm = prevSearch;
        }, 1);
      }
    }
    