import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SupplierModel } from './SupplierModel';

@Injectable({
  providedIn: 'root'
})
export class SupplierService {
  baseUrl = environment.baseApiUrl;
  CompanyId:number =+ localStorage.getItem("CompanyId");
  //baseUrl = 'http://localhost:60289/';
   token = localStorage.getItem("token");
   httpOptions = {
    headers: new HttpHeaders({
      // 'Content-Type': 'application/json; charset=utf-8',
      // 'Authorization': "Bearer " + token,
       "Authorization": "Bearer " + this.token,
       "Content-Type": "application/json"
    })
  };
  constructor(private http: HttpClient) {

  }


  debugData: SupplierModel [] = [
    {Id: 1, NameAr:"مورد 1", NameEn:"Supplier 1", Phone:"011111111111", 
    Address:"Address 1", SupplierType:1, CountryId:1, CityId:1, CitiyName:"City 1", PostalCode:"11111"},

    {Id: 2, NameAr:"مورد 2", NameEn:"Supplier 2", Phone:"021111111111", 
    Address:"Address 2", SupplierType:1, CountryId:1, CityId:1, CitiyName:"City 1", PostalCode:"11111"},

    {Id: 3, NameAr:"مورد 3", NameEn:"Supplier 3", Phone:"031111111111", 
    Address:"Address 3", SupplierType:1, CountryId:1, CityId:1, CitiyName:"City 1", PostalCode:"11111"},

    {Id: 4, NameAr:"مورد 4", NameEn:"Supplier 4", Phone:"041111111111", 
    Address:"Address 4", SupplierType:1, CountryId:1, CityId:1, CitiyName:"City 1", PostalCode:"11111"},

    {Id: 5, NameAr:"مورد 5", NameEn:"Supplier 5", Phone:"051111111111", 
    Address:"Address 5", SupplierType:1, CountryId:1, CityId:1, CitiyName:"City 1", PostalCode:"11111"},

    {Id: 6, NameAr:"مورد 6", NameEn:"Supplier 6", Phone:"061111111111", 
    Address:"Address 6", SupplierType:1, CountryId:1, CityId:1, CitiyName:"City 1", PostalCode:"11111"},

    {Id: 7, NameAr:"مورد 7", NameEn:"Supplier 7", Phone:"071111111111", 
    Address:"Address 7", SupplierType:1, CountryId:1, CityId:1, CitiyName:"City 1", PostalCode:"11111"},

    {Id: 8, NameAr:"مورد 8", NameEn:"Supplier 8", Phone:"081111111111", 
    Address:"Address 8", SupplierType:1, CountryId:1, CityId:1, CitiyName:"City 1", PostalCode:"11111"},

    {Id: 9, NameAr:"مورد 9", NameEn:"Supplier 9", Phone:"091111111111", 
    Address:"Address 9", SupplierType:1, CountryId:1, CityId:1, CitiyName:"City 1", PostalCode:"11111"},

    {Id: 10, NameAr:"مورد 10", NameEn:"Supplier 10", Phone:"010111111111", 
    Address:"Address 10", SupplierType:1, CountryId:1, CityId:1, CitiyName:"City 1", PostalCode:"11111"},

  ]
  debugMode: boolean = false;

  getAll(): Observable<any> {
    if(this.debugMode){
      return of(this.debugData)
    }
    return this.http
      .get<any>(`${this.baseUrl}Supplier/GetAll/${this.CompanyId}`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error => error)));
  }
  getById(id:number): Observable<any> {
    if(this.debugMode){
      return of(this.debugData.find(x=>x.Id==id))
    }
    return this.http
      .get<any>(`${this.baseUrl}Supplier/GetById/${id}`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error => error)));
  }
  insert(payload: SupplierModel): Observable<any> {
    if(this.debugMode){
      this.debugData.push(payload);
      return of(true)
    }
    return this.http
      .post<any>(`${this.baseUrl}Supplier/Insert/${this.CompanyId}`, payload,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error => error)));
  }

  update(payload: SupplierModel): Observable<any> {
    if(this.debugMode){
      this.debugData[this.debugData.findIndex(x=>x.Id==payload.Id)] = payload;
      return of(true)
    }
    return this.http
      .post<any>(`${this.baseUrl}Supplier/Update`, payload,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error => error)));
  }

  delete(payload: SupplierModel): Observable<any> {
    if(this.debugMode){
      this.debugData.splice(this.debugData.findIndex(x=>x.Id==payload.Id),1);
      return of(true)
    }
    return this.http
      .get<any>(`${this.baseUrl}Supplier/Delete/${payload.Id}`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error => error)));
  }

  deleteList(payload: SupplierModel []): Observable<any> {
    if(this.debugMode){
      payload.forEach(element => {
        this.debugData.splice(this.debugData.findIndex(x=>x.Id==element.Id),1);
      });
      return of(true)
    }
    return this.http
      .post<any>(`${this.baseUrl}Supplier/DeleteList`,JSON.stringify(payload),this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error => error)));
  }


}
