import { AssignCostCentersComponent } from './../assign-cost-centers/assign-cost-centers.component';
import { AccountsNameService } from './../account-name/AccountNameService';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NestedTreeControl } from '@angular/cdk/tree';
import { BasicModel } from './../journal-entries-type/helper/model';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AccountNameModel } from '../account-name/AccountNameModel';
import { AccountMenuService } from '../account-menu/helper/serves';
import { AccountsTypeService } from '../account-type/helper/serves';
import { AccountFicationService } from '../account-fications/helper/serves';
import { AccountFicationCostTypeService } from '../account-fication-cost-type/helper/serves';
import { AccountExpensesTypeService } from '../expensess-type/helper/serves';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
interface AccountNameNode {
    Id?: number;
    Children?: AccountNameNode[];
    Level?: number;
    Parentcode?:number;
    Name?:string;
    Type?:number;
    AccountNumber?:number;
  }
@Component({
    selector: 'accounts',
    templateUrl: 'accounts.component.html',
    styleUrls: ['accounts.component.scss']
})
export class AccountsComponent implements OnInit{
  @ViewChild(AssignCostCentersComponent) AssignCostCentersComponent:AssignCostCentersComponent;
    declare lang: any;
     Form:FormGroup;
     ExpensesTypes:BasicModel[]=[];
     accountMenus:any[]=[];
     accountsTypes:any[]=[];
     accountFications:any[]=[];
     accountFicationCostType:any[]=[];
     accountExpensesTypes:any[]=[];
     AccountsNameList:AccountNameNode[]=[
      {Id:1,Level:0,Children:[
          {Id:2,Level:1,Children:[],Parentcode:1,Name:'Chiled 1'},
          {Id:3,Level:1,Children:[],Parentcode:1,Name:'Chiled 2'},
          {Id:4,Level:1,Children:[],Parentcode:1,Name:'Chiled 3'}
      ],Parentcode:0,Name:'Parent 1'},
      {Id:5,Level:0,Children:[
          {Id:6,Level:1,Children:[],Parentcode:5,Name:'Chiled 1'},
          {Id:7,Level:1,Children:[],Parentcode:5,Name:'Chiled 2'},
          {Id:8,Level:1,Children:[],Parentcode:5,Name:'Chiled 3'}
      ],Parentcode:0,Name:'Parent 2'},
      {Id:9,Level:0,Children:[
        {Id:10,Level:1,Children:[
          {Id:13,Level:2,Children:[],Parentcode:10,Name:'Chiled 1'},
          {Id:14,Level:2,Children:[],Parentcode:10,Name:'Chiled 2'},
          {Id:15,Level:2,Children:[],Parentcode:10,Name:'Chiled 3'}
        ],Parentcode:5,Name:'Chiled 1'},
        {Id:11,Level:1,Children:[],Parentcode:5,Name:'Chiled 2'},
        {Id:12,Level:1,Children:[],Parentcode:5,Name:'Chiled 3'}
    ],Parentcode:0,Name:'Parent 3'}
  ]
    centers:AccountNameModel[]=[
      {AccounExpensesTypeId:1,AccountFicationId:1,AccountFicationType:1,AccountMenuId:1,AccountNature:1,AccountNumber:1,AccountType:1,CompanyDataId:0,Id:1,IsNotMenu:true,Levelcode:1,NameAr:'الاصول',NameEn:'Assets',Parentcode:0},
      {AccounExpensesTypeId:1,AccountFicationId:1,AccountFicationType:1,AccountMenuId:1,AccountNature:1,AccountNumber:1,AccountType:1,CompanyDataId:0,Id:2,IsNotMenu:true,Levelcode:1,NameAr:'الخصوم',NameEn:'Liabilities',Parentcode:0},
      {AccounExpensesTypeId:1,AccountFicationId:1,AccountFicationType:1,AccountMenuId:1,AccountNature:1,AccountNumber:1,AccountType:1,CompanyDataId:0,Id:3,IsNotMenu:true,Levelcode:1,NameAr:'حقوق الملكية',NameEn:'Property Rights',Parentcode:0},
      {AccounExpensesTypeId:1,AccountFicationId:1,AccountFicationType:1,AccountMenuId:1,AccountNature:1,AccountNumber:1,AccountType:1,CompanyDataId:0,Id:4,IsNotMenu:true,Levelcode:1,NameAr:'الإيرادات',NameEn:'Incomes',Parentcode:0},
      
    ]
     accountModel:AccountNameModel=new AccountNameModel();
     constructor(
      private translate: TranslateService, 
      public notifications: NotificationsService,
      private fb:FormBuilder,
      private accountService:AccountsNameService,
      public accountMenuService: AccountMenuService,
      public accountsTypeService: AccountsTypeService,
      public accountFicationService: AccountFicationService,
      public  accountFicationCostTypeService: AccountFicationCostTypeService,
      public accountExpensesTypeService: AccountExpensesTypeService,
      ){
      this.lang = localStorage.getItem('theme_lang');
  
     }
     SetCostCenters = false;
     AccountId = 0;
     assignCostCenters(){
      this.AccountId = this.accountModel.Id;
      this.SetCostCenters = true;
      this.formCreated = false;
     }
    LastAccountNumber = 0; 
      ngOnInit(): void {
          this.accountService.getAll(0)
          .subscribe(data => {
            this.centers = data;
            console.log('accounts',this.centers);
            if(this.centers && this.centers.length > 0){
              
            }
            this.groupedByData = this.groupByKey(this.centers, 'Levelcode')
          this.nodeList = [];

            this.getDataNodes2();
                // (Object.keys(groupedByData)).forEach(x => {
            //   this.viewData.push(
            //     {
            //       LevelCode:x,
            //       // quantity : groupedByData[x].length + 'x',
            //       // subtotal :  (groupedByData[x].map(x=> x.price)).reduce(function(a, b) { return a + b; }, 0)
            //     }
            //   )
            // })
 
            // console.log(groupedByData)
            // console.log(Object.keys(groupedByData));
            // console.log(Object.values(groupedByData));
            // (Object.keys(groupedByData)).forEach(x => {
            //   this.viewData.push(
            //     {
            //       LevelCode:x,
            //       // quantity : groupedByData[x].length + 'x',
            //       // subtotal :  (groupedByData[x].map(x=> x.price)).reduce(function(a, b) { return a + b; }, 0)
            //     }
            //   )
            // })
            console.log('this.viewData',this.viewData)
            // this.getDataNodes2();
            // this.Form = this.createForm();
          })
          this.getData();
          this.accountService.GetLastAccountCode()
          .subscribe(accountNumber=>{
            this.LastAccountNumber = accountNumber;
          })

      }
      
      formCreated = false;
      createForm():FormGroup{
        this.formCreated = true;
        return this.fb.group({
          Id:[this.accountModel.Id],
          AccounExpensesTypeId:[this.accountModel.AccounExpensesTypeId],
          AccountFicationId:[this.accountModel.AccountFicationId],
          AccountFicationType:[this.accountModel.AccountFicationType],
          AccountMenuId:[this.accountModel.AccountMenuId],
          AccountNature:[this.accountModel.AccountNature],
          AccountNumber:[this.accountModel.AccountNumber],
          AccountType:[this.accountModel.AccountType],
          CompanyDataId:[this.accountModel.CompanyDataId],
          IsNotMenu:[this.accountModel.IsNotMenu],
          Levelcode:[this.accountModel.Levelcode],
          NameAr:[this.accountModel.NameAr],
          NameEn:[this.accountModel.NameEn],
          Parentcode:[this.accountModel.Parentcode],
          ParentName:[this.accountModel.ParentName],
          Debit:[this.accountModel.Debit],
          Credit:[this.accountModel.Credit],
          IsCurrencyEvaluation:[this.accountModel.IsCurrencyEvaluation],
          ItIsLinkedToAcostCenter:[this.accountModel.ItIsLinkedToAcostCenter],
        })
      }
      nodeList: AccountNameNode [] = [];
      openProjects = false;
    
      getDataNodes(){
          this.nodeCount = 0;
          this.nodeList = [];
          var level = 0;
          this.centers.forEach(element => {
            debugger;
            while(level < 6){
              if(element.Parentcode == 0){
                this.nodeList.push({
                  Id : element.Id,
                  Level : element.Levelcode,
                  AccountNumber:element.AccountNumber,
                  Name : this.lang =='ar-EG' ? element.NameAr : element.NameEn,
                  Parentcode : element.Parentcode,
                  Children : []
                });
              }
           else{
            this.nodeList.forEach(child => {
              if(child.AccountNumber == element.Parentcode){
                child.Children?.push({
                  Id : element.Id,
                  Level : element.Levelcode,
                  AccountNumber:element.AccountNumber,
                  Name : this.lang =='ar-EG' ? element.NameAr : element.NameEn,
                  Parentcode : element.Parentcode,
                  Children : []
                })
              }
              else{
                while(child.Children&&child.Children.length > 0){
                  if(child.AccountNumber == element.Parentcode){
                    child.Children.push({
                      Id : element.Id,
                      Level : element.Levelcode,
                      AccountNumber:element.AccountNumber,
                      Name : this.lang =='ar-EG' ? element.NameAr : element.NameEn,
                      Parentcode : element.Parentcode,
                      Children : []
                    })
                    break;
                  }
                  break;
    
                }
              }
    
              },
              );
           }
              this.nodeCount++;
              if(element.Levelcode > level){
                level = element.Levelcode;
              }
            }
     
          });
          
          console.log(this.nodeList);
          
          this.drawTreeFromTop(this.nodeList);
      
          this.dataSource.data = [];
          this.dataSource.data = this.nodeList;
      
      
      
        }
        groupedByData=[];
        getDataNodes2(){
          for (let index = 1; index <= Object.keys(this.groupedByData).length; index++) {
            debugger;
              const element = this.groupedByData[index];
            if(index == 1){
              element.forEach(value => {
                this.nodeList.push({
                  Id : value.Id,
                  Level : value.Levelcode,
                  AccountNumber:value.AccountNumber,
                  Name : this.lang =='ar-EG' ? value.NameAr : value.NameEn,
                  Parentcode : value.Parentcode,
                  Children : []
                });
              });
            }
          } 
          this.drawTreeFromTop(this.nodeList);
    
          this.dataSource.data = [];
          this.dataSource.data = this.nodeList;
      
        }
        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////
        // TREE CODE //
        ///////////////////////////////////////////////////////////
        nodeCount = 0;
         count = 0;

        drawTreeFromTop(currentlevelList: AccountNameNode []){
          debugger;
          console.log("data count: ", this.centers.length);
          console.log("node count: ", this.nodeCount);
          console.log("current nodes: ", this.nodeList);
          
          if(this.nodeCount >= this.centers.length){
            console.log("we did it, men. we found the deepest nodes.");
            return currentlevelList;
          }
          else{
            console.log("we're not yet done... descending one level deeper into this madness");
            if(this.levelLimit > this.count){
              let nextLevelList: AccountNameNode[] = [];
              if(currentlevelList.length > 0){
                currentlevelList.forEach(node => {
                  this.centers.filter(x=>x.Parentcode == node.AccountNumber).forEach(childNode => {
                    let tempNode: AccountNameNode = {};
                    tempNode.Id = childNode.Id;
                    tempNode.AccountNumber = childNode.AccountNumber;
                    tempNode.Children = [];
                    tempNode.Level = node.Level + 1;
                    tempNode.Name = this.lang =='ar-EG' ? childNode.NameAr : childNode.NameEn,
                    tempNode.Parentcode = childNode.Parentcode,
                    tempNode.Type = childNode.AccountType,
                    node.Children.push(tempNode)
                    this.nodeCount++;
                    nextLevelList.push(tempNode);
                  });
                });
              }
              this.count ++;
              return this.drawTreeFromTop(nextLevelList);
            }
            else
          {
            return;
          }
 
          }
        }
        levelLimit = 6;
        treeControl = new NestedTreeControl<AccountNameNode>(node => node.Children);
        dataSource = new MatTreeNestedDataSource<AccountNameNode>();
        //limiting depth level to 4 + base (total of 5 nested nodes)
        hasChild = (_: number, node: AccountNameNode) => !!node.Children && node.Children.length > 0 && node.Level+1<this.levelLimit;
        // formView = '';
      
        getAccountNameById(id: any){
          return this.centers.find(x=>x.Id===id);
        }
        AddNewCostCenter(){
          this.accountModel.Id = 0;
          this.accountModel.Levelcode = 1;
          this.accountModel.Parentcode = 0;
          this.accountModel.AccounExpensesTypeId = 0;
          this.accountModel.AccountFicationId = 0;
          this.accountModel.AccountFicationType = 0;
          this.accountModel.AccountMenuId = 0;
          this.accountModel.NameAr = '';
          this.accountModel.NameEn = '';
          this.accountModel.Parentcode = 0;
          this.accountModel.AccountNumber = this.LastAccountNumber + 1;
          this.Form = this.createForm();
        }
        openIsCurrencyEvaluation=false;
        openItIsLinkedToAcostCenter=false;
        addChildCostCenter(Id){
          debugger;
          var i = this.centers.findIndex(x=>x.Id == Id);
          this.accountModel.Id = 0;
  
          if(i > -1){
            this.accountModel.Levelcode = this.centers[i].Levelcode +1;
            this.accountModel.AccountNature = this.centers[i].AccountNature;
            this.accountModel.Parentcode = this.centers[i].AccountNumber;
            this.lang =='ar-EG' ? this.accountModel.ParentName = this.centers[i].NameAr : this.accountModel.ParentName = this.centers[i].NameEn;
            this.accountModel.AccountNumber = this.LastAccountNumber + 1;
            var level = this.centers[i].Levelcode;
            //for bahaa
            // for (let index = level; index > 1; index--) {
            //   if(index == level )
            //     {
            //       var largParentIndex  = i;
            //       var largParent = this.centers[largParentIndex];
            //     }
            //     if(largParent.AccountNumber == 24 || largParent.AccountNumber==25 || largParent.AccountNumber==37|| largParent.AccountNumber==38){
            //       this.openItIsLinkedToAcostCenter = true;
            //       break;
            //     }
            //     else{
            //       this.openItIsLinkedToAcostCenter = false;
            //     }
            //   if(largParent.AccountNumber == 6 || largParent.AccountNumber==15){
            //     this.openIsCurrencyEvaluation = true;
            //     break;
            //   }
            //   else{
            //     this.openIsCurrencyEvaluation = false;
            //   }
            //   largParentIndex = this.centers.findIndex(x=>x.AccountNumber == this.centers[largParentIndex].Parentcode);
            //   var largParent = this.centers[largParentIndex];
            // }
            this.Form = this.createForm();
        }
          else{
          this.accountModel.AccountNumber = this.LastAccountNumber + 1;

            this.accountModel.Levelcode = 1;
            this.accountModel.Parentcode = 0;
            this.Form = this.createForm();
          }
        }
        EditNode(Id){
          debugger;
          this.AccountId = Id;
          var i = this.centers.findIndex(x=>x.Id == Id);
          if(i > -1){
            this.accountModel = this.centers[i];
            
            this.Form = this.createForm();
          }
        }
        lodingNature=false;
        lodingMenu=false;
        lodingaccountsType=false;
        lodingaccountFications=false;
        lodingaccountFicationCostType=false;
        lodingaccountExpensesTypes=false;
        getData(){
     
          this.accountMenuService.getAll()
          .subscribe(data => {
            this.accountMenus = [...data]
            this.lodingMenu = true;
          })
      
          this.accountFicationService.getAll()
          .subscribe(data => {
            this.accountFications = [...data]
            this.lodingaccountFications = true;
          })
         
          this.accountExpensesTypeService.getAll()
          .subscribe(data => {
            this.accountExpensesTypes = [...data]
            this.lodingaccountExpensesTypes = true;
          })
        }

        Save() {
          this.accountModel = this.Form.getRawValue();
          this.accountService.insert(this.accountModel).subscribe(
              result => {
                  if (result > 0 ) {
                      // this.Form = this.createForm();
                      this.accountModel.Id = result;
                      this.centers.push(this.accountModel);
                      this.ngOnInit();
                      this.accountModel = new AccountNameModel();
                      this.formCreated = false;
                      this.LastAccountNumber++;
                      this.onSuccess();
                  } else {
                      this.onError();
                  }
              }
          )
  
      }
  
      Update() {
        debugger
        this.accountModel = this.Form.getRawValue();
        this.accountService.update(this.accountModel).subscribe(
            result => {
                if (result) {
                  this.accountModel = new AccountNameModel();
                  this.formCreated = false;

                    this.onSuccess();
                } else {
                    this.onError();
                }
            }
        )
      }
      Delete(){
        this.accountService.delete(this.accountModel)
        .subscribe(data => {
          var i = this.centers.findIndex(x=>x.Id == this.accountModel.Id);
          if(i > -1){
            this.centers.splice(i,1);
            this.ngOnInit();

            this.accountModel = new AccountNameModel();
            this.formCreated = false;
          }
        })
      }
      SaveCenters(){
        if(this.AssignCostCentersComponent.saveCostCenters() == true){
          this.AccountId = 0;
          this.SetCostCenters = false;
        }
        // this.AssignCostCentersComponent.saveCostCenters();
      }
      onSuccess(): void {
        debugger;
        this.notifications.create(this.translate.instant('Success'),
            this.translate.instant('Action Success'),
            NotificationType.Success, { timeOut: 300, showProgressBar: true });
    }
   
    onWarning(): void {

      this.notifications.create(this.translate.instant('Wait'),
          this.translate.instant('Action processing '),
          NotificationType.Warn, { timeOut: 3000, showProgressBar: true });
  }

  onError(): void {
      this.notifications.create(this.translate.instant('Error'),
          this.translate.instant('Something wrong'),
          NotificationType.Error, { timeOut: 3000, showProgressBar: true });
  }
  viewData = []

groupByKey(data, key) {
  debugger;
  return data.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};
  }
  