import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { InventoryManagementService } from '../inventory-management.service';
import {WarehouseService}from '../../warehouses/warehouse-service.service';
import { ProductesService}from '../../ProductesAndServices/productes.service';
import { NotificationType, NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { TableEngine } from 'src/app/views/app/aio/shared/TableEngine';
import { InventoryManagementModel } from '../../models/InventoryManagementModel';
import { SidePopupComponent } from 'src/app/views/app/aio/shared/side-popup/side-popup.component';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { InventoryItemsModel } from '../../models/InventoryItemsModel';
import { StoreItemsModel } from '../../models/StoreItemsModel';
import { WarehouseModel } from '../../models/WarehouseModel';

@Component({
    // moduleId: module.id,
    selector: 'inventory-sheet',
    templateUrl: 'inventory-sheet.component.html',
    styleUrls: ['inventory-sheet.component.scss']
})
export class InventorySheetComponent {

    Id = 0;

    constructor(
      public router: Router,
      private fb: FormBuilder,
      private modelService:InventoryManagementService,
      private WarehouseService: WarehouseService,
     private ProductesService:ProductesService,
      private notifications: NotificationsService,
      private translate: TranslateService,
      private route: ActivatedRoute
    ) {
      this.Id = +route.snapshot.paramMap.get("Id");
    }
  
    tableEngine = new TableEngine(this.translate, this.notifications);
    currentScreen: any = "Table";
    translationName: string = "IventoryManagement";
    sortableKeys = [];
    searchableKeys = ["Date"];
    tableView: any = true;
  
    // StoreItemsModel:StoreItemsModel[]=[];
  
   
    modelObject: InventoryManagementModel = new InventoryManagementModel();
    InventoryItemObject: InventoryItemsModel = new InventoryItemsModel();
    Form: FormGroup;
    declare lang: any;
    declare itemOrder: any;
    currentAction = "";
    savedPage = 0;
    currentPage = 1;
    deletionList: any[] = [];
    prevAction = "";
    addToListId = 0;
    ItemsBalanceHeaderId = 0;
    ItemBalanceId = 0;
    currentFormMode: string = "";
    type = 0;
    visable = "";
    visable2 = "";
    visable3 = "";
    ogData: InventoryManagementModel[] = [];
    mainForm: FormGroup = new FormGroup({
        Id: new FormControl(),
        InventoryNumber: new FormControl(),
        WarehouseId: new FormControl(),
        Notes: new FormControl(),
        Time: new FormControl(),
        Date: new FormControl(),
        IsSettlement: new FormControl(),
      
    });

    mainItemForm: FormGroup = new FormGroup({
        Id: new FormControl(),
        ItemId: new FormControl(),
        InventoryId: new FormControl(),
        UnitId: new FormControl(),
        QuantityNumberInStock: new FormControl(),
        QuantityNumberInProgram: new FormControl(),
        ItemName: new FormControl(),
          
    });
    @ViewChild("sideModalRef", { static: true })
    sideModalRef: SidePopupComponent = null;
    @ViewChild('smallModal') smallModal: ModalDirective;
    @ViewChild('delModal') delModal: ModalDirective;

    
    formPresentationModal: any;
    ngOnInit(): void {
      // this.getAllCountryCode();
  
      this.lang = localStorage.getItem("theme_lang");
      this.itemOrder = {
        label: "Date",
        property: this.lang === "ar-EG" ? "Date" : "Date",
      };
     
      this.tableEngine.loadTableEngine(
        this.ogData,
        this.modelService,
        this.modelObject,
        this.searchableKeys,
        this.sortableKeys,
        this.mainForm,
        this.translationName,
        this.itemOrder,
        this.sideModalRef,
        this.delModal,
        this.smallModal,
        this.lang
      );
     
  
      this.loadServices();
      this.modelService.getAll().subscribe((data) => {
        this.modelObject = data;
      });
     
      this.modelService.getLastNumber().subscribe((data) => {
          this.modelObject.InventoryNumber = data;
          this.mainForm.controls['InventoryNumber'].setValue(+this.modelObject.InventoryNumber+1);
        });
       
      this.WarehouseService.getAll().subscribe((data) => {
        this.WarehouseList = data;
      });

          if(this.Id > 0)
          {   debugger;   
                              
                  this.modelService.getById(this.Id).subscribe(data=>{                        
                  this.modelObject = data;                 
                  this.InventoryItemsList=data.InventoryItems;
                  this.InventoryItemsList=this.modelObject.InventoryItems;
                  this.mainForm = this.createForm(this.modelObject);
                  this.ProductesService.GetAllByInventoryId( this.modelObject.WarehouseId).subscribe(data=>{
                    this.ProducteList=data;
                  })     
              });
           
  
          }
          else{
                  
             this.mainForm = this.createForm(this.modelObject);                              
  
  
          }
      // this.mainForm = this.createForm(this.modelObject);
  
    }
    creatsmallForm(){

    }
  createForm(modelObject): FormGroup {
    debugger;
    return this.fb.group({
      // Id:[this.ItemBalance.Id],
      Id: [modelObject.Id >= 0 ? modelObject.Id : 0],
      InventoryNumber: [modelObject.InventoryNumber,Validators.compose([Validators.required])],
      
      WarehouseId: [modelObject.WarehouseId,Validators.compose([Validators.required])],
      Time: [modelObject.Time],
      Date: [modelObject.Date],
      IsSettlement: [modelObject.IsSettlement],
      Notes: [modelObject.Notes],
    
    });
  }
  createItemForm(InventoryItemObject): FormGroup {
    debugger;
    return this.fb.group({
      // Id:[this.ItemBalance.Id],
      Id: [InventoryItemObject.Id >= 0 ? InventoryItemObject.Id : 0],
      ItemId: [InventoryItemObject.ItemId],
      
      InventoryId: [InventoryItemObject.InventoryId],
      UnitId: [InventoryItemObject.UnitId],
      QuantityNumberInStock: [InventoryItemObject.QuantityNumberInStock],
      QuantityNumberInProgram: [InventoryItemObject.QuantityNumberInProgram],
      ItemName: [InventoryItemObject.ItemName],
    
    });
  }
  WarehouseList: WarehouseModel[] = [];
  ProducteList:StoreItemsModel[]=[];
  InventoryItemsList:InventoryItemsModel[]=[];
  showAddNewModalRef(): void {
    debugger;
    this.currentFormMode = 'Add'
    this.InventoryItemObject = new InventoryItemsModel;
    this.mainItemForm = this.createItemForm(this.InventoryItemObject);
    this.smallModal.show();
  }
  loadServices() {
    // debugger;
    this.currentAction = "loading";
    this.tableEngine.loadData();
  }

  getItemsById(Id:any):any{
    if(Id>0)
    {
    return this.ProducteList.find(i=>i.Id===Id)
    }
    else
    {
    return new StoreItemsModel();
    }
    }
  setItem(){
    debugger;
    var item = this.mainItemForm.getRawValue()
    var i = this.ProducteList.findIndex(x=>x.Id == item.ItemId);
    if(i>-1){
        item.QuantityNumberInProgram = this.ProducteList[i].Quantity;
    }
    // this.calculateTotalItemValue();
    }
    currentTotal = 0;

    // calculateQuantity() {
    //   let total = 0;
    //   this.InventoryItemsList.forEach(item => {
    //     total += item.QuantityNumberInStock*item.QuantityNumberInProgram;
    //   });
    //   this.currentTotal = total;
    //   this.mainItemForm = this.createItemForm(new InventoryItemsModel());
    //   return total;
    // }

    setItemQuentity(){
      debugger;
        var item = this.mainItemForm.getRawValue();
        var i = this.ProducteList.findIndex(x=>x.Id == item.ItemId);
        if(i > -1){
            this.mainItemForm.controls['QuantityNumberInProgram'].setValue(this.ProducteList[i].Quantity)
        }
        
    }



    // calcTotalQuentity(item){
    //   var Quantity = 0;
    //   item.WarhouseItem.forEach(element2 => {
    //     Quantity+=element2.Quantity
        
    //   });
    //   return Quantity;
    // }
    
  addproductes() {
    debugger;
    var item = this.mainItemForm.getRawValue();
    var Inventory= new InventoryItemsModel();
    Inventory.Id = 0;
    Inventory.InventoryId = this.Id;
    Inventory.ItemId = +item.ItemId;

    Inventory.QuantityNumberInProgram = item.QuantityNumberInProgram;
    Inventory.QuantityNumberInStock = item.QuantityNumberInStock;
    Inventory.UnitId = item.UnitId;
    var i = this.ProducteList.findIndex(x=>x.Id == item.ItemId);
    if(i > -1){
        Inventory.ItemName = this.ProducteList[i].Name;
        this.ProducteList.splice(i,1);
    }
    this.InventoryItemsList.push(Inventory);
    var Inventory= new InventoryItemsModel();
    this.mainItemForm = this.createItemForm(Inventory);
 }
 
  removeItemsFooter(Id: any): any {
    debugger;
    let index = this.InventoryItemsList.findIndex(x => x.ItemId === Id);
    this.InventoryItemsList.splice(index, 1);      
    // this.calculateQuantity();           
    }
    QuantityNumberInProgram = 0;
    AddObject() {
    this.savedPage = this.currentPage;
    //prevent multiclicks. re enable after API response
    this.currentAction = 'loading';
    this.executeAddeObject();
  }
  
  //Same here...
  executeAddeObject(){
    debugger;
    this.modelService.deleteList(this.tableEngine.deletionList).subscribe(result => {
      if (result === true) {
        this.tableEngine.loadData();
        this.currentAction = '';
        this.tableEngine.delModal.hide();
        // this.finalizeDeletion();
        this.tableEngine.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyDeleted"));
        if (this.sideModalRef === undefined) {
  
          this.tableEngine.smallModal.hide();
  
        } else {
  
          this.tableEngine.sideModalRef.modalRef.hide();
        }
      }
      else {
        this.toastError(this.translate.instant("Error"), this.translate.instant("FailedDelete"));
        this.currentAction = '';
      }
    })
  }
  selected : any[] = [];

  showDelModal(Id: any): void {
    debugger;
    if (Id >= 0) {
      this.currentFormMode = 'Del'
      this.modelObject = this.ogData.find(i => i.Id === Id);
      this.deletionList = [this.ogData.find(i => i.Id === Id)];
      this.delModal.show();
    }
    else if (Id === 'delMulti') {
      this.deletionList = [...this.selected]
      this.delModal.show();
    }
   
  }
  toastError(toastHeader: string, toastBody: string): void {
    this.notifications.create(toastHeader, toastBody,
      NotificationType.Error, { timeOut: 3000, showProgressBar: true });
  }
    goBack() {
        this.router.navigate(["app/aio/Inventory/List-All-InventoryManagement"]);
      }
      goInventory(){
        
      }
      Save() {
        debugger;
        this.currentAction = "loading";
        this.tableEngine.savedPage = this.tableEngine.currentPage;
        this.modelObject = new InventoryManagementModel();
        this.modelObject.Id =this.Id;
        this.modelObject.InventoryNumber = this.mainForm.getRawValue().InventoryNumber;
        this.modelObject.Date = new Date(this.mainForm.getRawValue().Date);
        this.modelObject.Notes = this.mainForm.getRawValue().Notes;
        this.modelObject.WarehouseName = this.mainForm.getRawValue().WarehouseName;
        
        this.modelObject.Time =
          this.mainForm.getRawValue().Time;
        this.modelObject.IsSettlement =false;
        this.modelObject.WarehouseId =+
          this.mainForm.getRawValue().WarehouseId;
      
        this.modelObject.InventoryItems = this.InventoryItemsList;
        if (this.Id == 0) {
          this.modelService.insert(this.modelObject).subscribe((data) => {
            this.router.navigate(["app/aio/Inventory/List-All-InventoryManagement"]);
          });
        } else {
          debugger;
          this.modelService.update(this.modelObject).subscribe((data) => {
            this.router.navigate(["app/aio/Inventory/List-All-InventoryManagement"]);
          });
        }
      }

}
