
import { WarehouseBrancheModel } from "./WarehousBranches";
import { WarehouseEmployeesModel } from "./WarehousEmployees";
import { WarehouseValidityModel } from "./WarehouseValidityModel";
import { WarehouseJobRoleModel } from "./WarehousJobRoles";

export class WarehouseModel{
    Id?: number;
    Name?:string;
    Address?: string;  
    Mobil?:string;
    Phone?:string;
    Fax?: string;
    Description?: string;  
    EmployeeId?: number;  
    BranchId?: number;  
    JobRoleId?: number;  
    IsPrime?: boolean;
    WarehouseValidityList?:WarehouseValidityModel[];
    Employees?:WarehouseEmployeesModel[];
    JobRoles?:WarehouseJobRoleModel[];
    Branches?:WarehouseBrancheModel[];
    constructor(item?:WarehouseModel){
        item = item? item:{};
        this.Id = item.Id? item.Id:0;
        this.Mobil = item.Mobil? item.Mobil:'';
        this.Phone = item.Phone? item.Phone:'';
        this.Fax = item.Fax? item.Fax:'';
        this.Address = item.Address? item.Address:'';
        this.Description = item.Description? item.Description:'';
        this.EmployeeId = item.EmployeeId? item.EmployeeId:0;
        this.BranchId = item.BranchId? item.BranchId:0;
        this.JobRoleId = item.JobRoleId? item.JobRoleId:0;      
        this.IsPrime = item.IsPrime? item.IsPrime:false;        
        this.WarehouseValidityList = item.WarehouseValidityList? item.WarehouseValidityList:[];
        this.Employees = item.Employees? item.Employees:[];
        this.Branches = item.Branches? item.Branches:[];
        this.JobRoles = item.JobRoles? item.JobRoles:[];
    
    }

}