<div class="row mb-12">
    <div class="col-md-12 col-lg-12 col-12 mb-12">
      <div class="card"></div>
    </div>
  </div>
  <div class="row" *ngIf="mainForm">
    <div class="col-12">
      <div class="card mb-4">
        <div class="card-body">
          <h3>
           
            <Strong style="font-size: 22px; margin-left:10px ;"> {{modelObject.Name}}</Strong>
            <span  style= "margin-left:10px; font-size: 14px" >{{modelObject.Code}}#</span>
            <span class="badge badge-pill badge-success  " style= "margin-left:10px; font-size: 14px"  *ngIf="modelObject.Status==true">{{'Active' | translate}}</span>
            <span class="badge badge-pill badge-danger mb-1 " style= "margin-left:10px; font-size: 14px;"   *ngIf="modelObject.Status==false">{{'Inactive' | translate}}</span>
        
          <div class="top-right-button-container text-zero float-right">
            <button
              class="top-right-button btn btn-primary btn-lg mr-1"
              (click)="goBack()"
            >
              {{ "Back" | translate }}
            </button>
           
          </div>
        </h3>
        </div>
      </div>
    </div>
  </div>


  <div class="row" *ngIf="mainForm">
    <div class="col-12">
      <div class="card mb-4">
        <div class="card-body">
            <div class="row">
               <div class="col-md-3">
                     <div class="flex-shrink-1">
                         <div class="media text-center">
                             <img class="img-fluid avatar-lg" src="" alt="name">
                         </div>
                     </div>
                    </div>

                    <div class="col-md-3">
                     <div class="px-4 pt-3 pt-sm-0">
                         <h3>
                             <Strong style="font-size: 22px; margin-left:10px ;"> {{modelObject.Name}}</Strong>
                             <span> ({{modelObject.Type}})</span>
                         </h3>
                     </div>
                    </div>

                    <div class="col-md-6">
                        <div >
                           <a class="btn btn-icon btn-dark-blue btn-block">
                            <!-- <i class='fa fa-mobile-phone' style='color: white'></i> -->
                            <span style="font-size: large; text-align:right;">&#9990;</span>
                               {{modelObject.MobileNumber}}</a>
                           <a class="btn btn-icon btn-dark-blue btn-block">
                            <!-- <i class='fa fa-mobile-phone' style='color: white'></i> -->
                            <span style="font-size: large; text-align:right;">&#9990;</span>
                               {{ "AttendanceAppSettings" | translate }}</a>

                        </div>
                    <!-- <div class="col-lg-3"> -->

                 <!-- <div class="col-md-6">
                    <span><button class="button button2">{{modelObject.MobileNumber}}</button></span>

                 </div> -->
        <!-- </div> -->
        <!-- <div class="row">
            <div class="col-md-6"></div>
            <div class="col-md-6">
                <span><button class="button button2">{{ "AttendanceAppSettings" | translate }}</button></span>
            </div>
        </div> -->
        </div>
      </div>
    </div>
  </div>


<div class="row" *ngIf="mainForm">
    <div class="col-12">
         <div class="btn-group">
           <button (click)="showModal(modelObject.Id, 'Edit')">{{ "Edit" | translate }}</button>
           <button (click)="deleteObject()">{{ "Delete" | translate }}</button>
           <button *ngIf="modelObject.Status==true" (click)="AddNotesOnClick(modelObject.Id)">{{ "Delete" | translate }}</button>
           <button>{{ "Export" | translate }}</button>
         </div>
    </div>
</div>
  <div class="card"*ngIf="modelObject" >
    <tabset class="card-tabs">
    <tab heading="{{'Information' | translate}}" class="card-body" >    
        
        <div >
            <div class="container">
                <!-- <h3>panelHeading</h3> -->
                <div class="panel" style="padding: 5px;">
                  <div class="panel-head" style="background-color:#f6f9fc;">
                    <h3>{{'GeneralInformation' | translate}}</h3></div>
                  <div class="panel-body">
                    <p class="text-muted mb-1"> {{ "DisplayLanguage:" | translate }}</p>
                    <p style="font-weight: bold;"> {{modelObject.Name}}</p>
                    
                  </div>
                </div>
              </div>
              <div class="container">
                <!-- <h3>panelHeading</h3> -->
                <div class="panel">
                  <div class="panel-head" style="background-color:#f6f9fc;">
                    <h3>{{'PersonalData' | translate}}</h3></div>
                    <div class="row" style="padding: 5px;">
                      <div class="col-md-6">                 
                         <div class="mb-4">
                             <p class="text-muted"> {{ "DateOfBirth:" | translate }}</p>
                             <p style="font-weight: bold;"> {{modelObject.BirthDate}}</p>
                         </div>
                     </div>                

                     <div class="col-md-6">
                         <div class="mb-4"> 
                             <p class="text-muted mb-1"> {{ "Country:" | translate }}</p>
                             <p style="font-weight: bold;"> {{modelObject.Country}}</p>
                         </div>
                        
                     </div>
                   </div>
                  </div>
              </div>

              <div class="container">
                <!-- <h3>panelHeading</h3> -->
                <div class="panel">
                  <div class="panel-head" style="background-color:#f6f9fc;">
                    <h3>{{'ContactInformation' | translate}}</h3></div>
                  <div class="row" style="padding: 5px;">
                    <div class="col-md-6">                 
                       <div class="mb-4">
                        <p class="text-muted mb-1"> {{ "MobileNumber:" | translate }}</p>
                        <p style="font-weight: bold;"> {{modelObject.MobileNumber}}</p>
                       </div>
                   </div>                

                   <div class="col-md-6">
                       <div class="mb-4"> 
                        <p class="text-muted mb-1"> {{ "GroundPhoneNumber:" | translate }}</p>
                        <p style="font-weight: bold;"> {{modelObject.GroundPhoneNumber}}</p>
                       </div>
                      
                   </div>
                 </div>
                 
                </div>
              </div>


              <div class="container">
                <!-- <h3>panelHeading</h3> -->
                <div class="panel">
                  <div class="panel-head" style="background-color:#f6f9fc;">
                    <h3>{{'Address' | translate}}</h3></div>
                  <div class="row " style="padding: 5px;">
                    <div class="col-md-6">                 
                       <div class="mb-4">
                        <p class="text-muted mb-1"> {{ "CurrentAddress:" | translate }}</p>
                        <p style="font-weight: bold;"> {{modelObject.FirstAddress}} <br>
                            {{modelObject.City}},
                            {{modelObject.Governorate}},
                            {{modelObject.Country}}
                        </p>
                       </div>
                   </div>                

                   <div class="col-md-6">
                    <div class="mb-4">
                        <p class="text-muted mb-1"> {{ "PermanentAddress:" | translate }}</p>
                        <p style="font-weight: bold;"> {{modelObject.FirstPermanentAddress}} <br>
                            {{modelObject.PermanentCity}},
                            {{modelObject.PermanentGovernorate}},
                            {{modelObject.Country}}
                        </p>
                       </div>
                      
                   </div>
                 </div>
                 
                </div>
              </div>


              <div class="container">
                <!-- <h3>panelHeading</h3> -->
                <div class="panel">
                  <div class="panel-head" style="background-color:#f6f9fc;">
                    <h3>{{'JobData' | translate}}</h3></div>
                  <div class="row" style="padding: 5px;">
                    <div class="col-md-6">                 
                       <div class="mb-4">
                        <p class="text-muted mb-1"> {{ "JobTitle:" | translate }}</p>
                        <p style="font-weight: bold;"> {{modelObject.JobTitelName}}</p>
                       </div>
                   </div>                

                   <div class="col-md-6">
                       <div class="mb-4"> 
                        <p class="text-muted mb-1"> {{ "Department:" | translate }}</p>
                        <p style="font-weight: bold;"> {{modelObject.DepartmentName}}</p>
                       </div>
                      
                   </div>
                 </div>

                 <div class="row" style="padding: 5px;">
                    <div class="col-md-6">                 
                       <div class="mb-4">
                        <p class="text-muted mb-1"> {{ "JobType:" | translate }}</p>
                        <p style="font-weight: bold;"> {{modelObject.JobTypeName}}</p>
                       </div>
                   </div>                

                   <div class="col-md-6">
                       <div class="mb-4"> 
                        <p class="text-muted mb-1"> {{ "JobLevel:" | translate }}</p>
                        <p style="font-weight: bold;"> {{modelObject.JobLevelName}}</p>
                       </div>
                      
                   </div>
                 </div>
                 

                 <div class="row" style="padding: 5px;">
                    <div class="col-md-6">                 
                       <div class="mb-4">
                        <p class="text-muted mb-1"> {{ "JoiningDate:" | translate }}</p>
                        <p style="font-weight: bold;"> {{modelObject.JoiningDate}}</p>
                       </div>
                   </div>                

                   <div class="col-md-6">
                       <div class="mb-4"> 
                        <p class="text-muted mb-1"> {{ "Branch:" | translate }}</p>
                        <p style="font-weight: bold;"> {{modelObject.BranchName}}</p>
                       </div>
                      
                   </div>
                 </div>

                 
                 <div class="row" style="padding: 5px;">
                    <div class="col-md-6">                 
                       <div class="mb-4">
                        <p class="text-muted mb-1"> {{ "FinancialHistory:" | translate }}</p>
                        <p style="font-weight: bold;"> {{modelObject.Day}} {{modelObject.Month}}</p>
                       </div>
                   </div>                
                 </div>


                </div>
              </div>
           
          </div>
          
      
    </tab>
    <tab heading="{{'Contracts' | translate}}" class="card-body">
      <div >
 
        <div id="divmiddle" style="display: block; margin-top: 5px">
          <div style="width: 100%">
            <div>
              <div class="card" style="min-width: 900px !important">
                <div class="card-body">
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </tab>
      <tab heading="{{'RecordActivities' | translate}}" class="card-body">
     
      </tab>
  
  </tabset>
</div>
 <td></td>
  <td></td>
  <td></td>  <td></td>
  <td></td>
  <td></td>
 