import { Component, ViewChild } from '@angular/core';
import { FiscalYearService } from '../../fiscal-year.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { TableEngine } from '../../../../shared/TableEngine';
import { FiscalYearModel } from '../../models/fiscal-year.model';
import { SidePopupComponent } from '../../../../shared/side-popup/side-popup.component';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'fiscal-year',
    templateUrl: 'fiscal-year.component.html',
    styleUrls: ['fiscal-year.component.scss']
})
export class FiscalYearComponent {
    ////////////////////////////////////////////////////////////////////////////
    //  VARYING :
    //  Modify per similar component.
    ////////////////////////////////////////////////////////////////////////////
  
    constructor(private formBuilder: FormBuilder, public modelService: FiscalYearService, 
      private notifications: NotificationsService, private translate: TranslateService,
      private datePipe: DatePipe
  
    ) {
    }
  
    tableEngine = new TableEngine(this.translate, this.notifications);
    currentScreen: any = 'Table';
  
    translationName: string = 'Fiscal Year'
  
    sortableKeys = [];
    searchableKeys = ['StartDate', 'EndDate','IsOpen'];
  
    modelObject: FiscalYearModel = new FiscalYearModel();
  
    ogData: FiscalYearModel[] = [];
    mainForm: FormGroup = new FormGroup({
      Id: new FormControl(),
      StartDate: new FormControl(),
      EndDate: new FormControl(),
      Description:new FormControl(),
      Period:new FormControl()
    });
  
  
    declare lang: any;
    declare itemOrder: any;
    currentAction = '';
    savedPage = 0;
    currentPage = 1;
    deletionList: any[] = [];
  
    @ViewChild('sideModalRef', { static: true }) sideModalRef: SidePopupComponent = null;
    @ViewChild('delModal', { static: true }) delModal: ModalDirective;
    @ViewChild('smallModal', { static: true }) smallModal: ModalDirective;
    ngOnInit(): void {
      this.lang = localStorage.getItem('theme_lang');
      this.itemOrder = { label: 'StartDate', property:'StartDate' };
      this.sortableKeys = [
        { label: 'StartDate', property: 'StartDate' },
        { label: 'EndDate', property: 'EndDate' },
        { label: 'IsOpen', property: 'IsOpen' },
      ];
  
      this.tableEngine.loadTableEngine(this.ogData, this.modelService, this.modelObject,
        this.searchableKeys, this.sortableKeys, this.mainForm, this.translationName, this.itemOrder,
        this.sideModalRef, this.delModal, this.smallModal, this.lang
      )
      this.loadServices();
    }
  
    showModal(Id, action) {
      if (action === 'Add') {
        this.tableEngine.showAddNewModalRef(this.createForm, this.formBuilder)
      }
      else if (action === 'Edit') {
  
        this.tableEngine.showEditModalRef(Id, this.createForm, this.formBuilder)
        console.log("this model object:" + this.modelObject);
        console.log(this.modelObject);
        console.log("table engine model object:" + this.tableEngine.modelObject);
        console.log(this.tableEngine.modelObject);
  
      }
      else if (action === 'Info') {
        this.tableEngine.showInfoModalRef(Id, this.createForm, this.formBuilder)
      }
      this.modelObject = this.tableEngine.modelObject;
      this.mainForm = this.tableEngine.mainForm;
    }
  
    createForm(modelObject:FiscalYearModel, formBuilder): FormGroup {
      debugger;
      if(!modelObject || modelObject.Id == undefined || modelObject.Id == null||modelObject.Id == 0){
        modelObject = new FiscalYearModel();
      }
      return formBuilder.group({
        Id: [modelObject.Id >= 0 ? modelObject.Id : 0],
        StartDate: [modelObject.StartDate?modelObject.StartDate.toString().slice(0, 10):new Date().toString().slice(0, 10), Validators.compose([Validators.required, Validators.minLength(2)])],
        EndDate: [modelObject.EndDate, Validators.compose([Validators.required])],
        Description: [modelObject.Description],
        IsOpen: [modelObject.IsOpen],
        Period:[1]
      });
    }
    changePeriod(){
      debugger;
      var type=this.mainForm.controls['Period'].value;
      var StartDate = new Date(this.mainForm.controls['StartDate'].value);
      if(type&&StartDate)
      if(type == 1){
        var end=new Date(StartDate);
        end.setUTCFullYear(StartDate.getFullYear()+1,StartDate.getMonth(),StartDate.getDay()-1);
        this.mainForm.controls['EndDate'].setValue(new Date(end));
      }
    }
    addObject() {
      debugger;
      this.currentAction = 'loading';
  
      this.modelObject = new FiscalYearModel();
      this.modelObject.Id = 0;
      this.modelObject= this.mainForm.getRawValue();
      this.modelObject.StartDate= new Date(this.modelObject.StartDate);
      this.modelObject.EndDate= new Date(this.modelObject.EndDate);
      this.executeAddObject();
    }
  
    executeAddObject() {
      console.log(this.modelObject);
  
      this.modelService.insert(this.modelObject).subscribe(result => {
        if (result === true) {
          this.tableEngine.loadData();
          this.currentAction = '';
          this.tableEngine.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyAdded"));
          if (this.sideModalRef === undefined) {
  
            this.tableEngine.smallModal.hide();
  
          } else {
  
            this.tableEngine.sideModalRef.modalRef.hide();
          }
        }
        else {
          this.tableEngine.toastError(this.translate.instant("Error"), this.translate.instant("FailedAdd"));
          this.currentAction = '';
        }
      })
    }
  
    editObject() {
      this.currentAction = 'loading';
      this.tableEngine.savedPage = this.tableEngine.currentPage;
  
      let tempObj = new FiscalYearModel({
        Id: this.modelObject.Id,
        StartDate: this.mainForm.getRawValue().StartDate,
        EndDate: this.mainForm.getRawValue().EndDate,
        IsOpen: this.mainForm.getRawValue().IsOpen,
        Description: this.mainForm.getRawValue().Description,
        // PostalCode : this.mainForm.getRawValue().PostalCode
      });
  
      if (JSON.stringify(tempObj) != JSON.stringify(this.modelObject)) {
        this.executeEditObject(tempObj);
      }
      else {if (this.sideModalRef === undefined) {
  
        this.tableEngine.smallModal.hide();
  
      } else {
  
        this.tableEngine.sideModalRef.modalRef.hide();
      }
      }
    }
  
    executeEditObject(Object) {
      console.log("ObjectObject", Object);
  
      this.modelService.update(Object).subscribe(result => {
        if (result === true) {
          this.tableEngine.loadData();
          this.currentAction = '';
          this.tableEngine.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyEdited"));
          if (this.sideModalRef === undefined) {
  
            this.tableEngine.smallModal.hide();
  
          } else {
  
            this.tableEngine.sideModalRef.modalRef.hide();
          }
        }
        else {
          this.tableEngine.toastError(this.translate.instant("Error"), this.translate.instant("FailedEdit"));
          this.currentAction = '';
        }
      })
    }
  
    //Deletion methods:
    //Customize if needed.
    //Pass the target object as parameter if the target API can only delete singular objects and not lists.
    deleteObject() {
      this.savedPage = this.currentPage;
      //prevent multiclicks. re enable after API response
      this.currentAction = 'loading';
      this.tableEngine.delModal.hide();
      this.showModal(new FiscalYearModel(),'Add')
      this.changePeriod();
    }
  
    //Same here...
    executeDeleteObject() {
      this.modelService.deleteList(this.tableEngine.deletionList).subscribe(result => {
        if (result === true) {
          this.tableEngine.loadData();
          this.currentAction = '';
          this.tableEngine.finalizeDeletion();
        }
        else {
          this.tableEngine.toastError(this.translate.instant("Error"), this.translate.instant("FailedDelete"));
          this.currentAction = '';
        }
      })
    }
  
  
    prepChangeSort(sortKey) {
      this.tableEngine.itemOrder = this.itemOrder;
      this.tableEngine.changeSort(this.tableEngine.getSort(sortKey))
    }
    ////////////////////////////////////////////////////////////////////////////
    //
    //
    //
    //
    //
    ////////////////////////////////////////////////////////////////////////////
    //  EXCLUSIVE :
    //  Specifically for this component.
    ///////////////////////////////
    //Get any additional data here
    loadServices() {
      this.currentAction = 'loading';
      this.tableEngine.loadData();
    }
  
    ////////////////////////////////////////////////////////////////////////////
    //
    //
    //
    //
    //
    ////////////////////////////////////////////////////////////////////////////
    //  CONSTANT :
    //  Do not change.
    ///////////////////////////////
    prepSort(sortKey) {
      this.tableEngine.changeSort(this.tableEngine.getSort(sortKey));
      this.itemOrder = this.tableEngine.itemOrder;
    }
  }
  