import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { InvoceExpensesModel } from '../model/expensesItemModel'
import { GetProject } from '../serves/project_serves'

import { ProjectModel } from '../model/projectModel'
import { SuppliersModel } from '../model/suppliersModel'
import { GetSupplier } from '../serves/suppliers_serves'
import { PostExpensesItemServes } from '../serves/expenses_item_serves'
import { GetExpensesCategory } from '../serves/expensesCategory'
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Params } from '@angular/router';
import { DatePipe } from '@angular/common';

@Component({
    // moduleId: module.id,
    selector: 'add-expenses-and-purchases',
    templateUrl: 'add-expenses-and-purchases.component.html',
    styleUrls: ['add-expenses-and-purchases.component.scss']
})
export class AddExpensesAndPurchasesComponent {
    expensesTypeList: any[] = [];
    projectList: ProjectModel[] = [];
    SuppliersList: SuppliersModel[] = [];
    expensesModel: InvoceExpensesModel;
    ExpensesForm: FormGroup;
    itemId:number;
    modelState:string;
    expensesList: InvoceExpensesModel[] = [];
    expensesItem: InvoceExpensesModel;
    titleButton: String = "";

    constructor(private activatedRoute: ActivatedRoute, private modelService: PostExpensesItemServes, public notifications: NotificationsService, private translate: TranslateService, supplierServes: GetSupplier, expensesCategoryServes: GetExpensesCategory, getProjectServes: GetProject, private postExpenses: PostExpensesItemServes) {
        this.activatedRoute.params.subscribe((params: Params) => {
            this.modelState = params['state'];
            this.itemId = +params['id'];
        });
        supplierServes.getAll().subscribe(
            item => {
                this.SuppliersList = item;
            }
        );
        expensesCategoryServes.getAll().subscribe(
            item => {
                this.expensesTypeList = item;
            }
        );
        getProjectServes.getAll().subscribe(
            item => {
                this.projectList = item;
            }
        );
    }
    ngOnInit() {
        if (this.modelState == "Add") {
            this.titleButton = "wizard.save";
        } else {
            this.titleButton = "update";
        }
        if (this.modelState != "Add") {
            this.modelService.getAll().subscribe(
                data => {
                    this.expensesList = data;
                    var index = this.expensesList.findIndex(index => index.Id == +this.itemId);
                    if (index > -1) {
                        this.expensesItem = this.expensesList[+index];
                        this.LoadCase();
                    }
                }
            )
        } else {
            this.LoadCase();
        }
    }
    LoadCase() {
        switch (this.modelState) {
            case 'Edit': {
                this.EditForm();
                break;
            }
            case 'Show': {
                this.ShowForm();
                break;
            }
            default: {
                this.AddForm();
                break;
            }
        }
    }
    AddForm() {
        this.ExpensesForm = new FormGroup({
            NameArInputForm: new FormControl(null, Validators.required,),
            NameEnInputForm: new FormControl(null, Validators.required,),
            QuantityInputForm: new FormControl(null, Validators.required,),
            PriceInputForm: new FormControl(null, Validators.required,),
            checkboxInputForm: new FormControl(false),
            DescriptionArInputForm: new FormControl(null, Validators.required,),
            DescriptionEnInputForm: new FormControl(null, Validators.required,),
            DateInputForm: new FormControl(null, Validators.required,),
            expensesTypeDropdwon: new FormControl(null, Validators.required,),
            projectDropdwon: new FormControl(null, Validators.required,),
            SuppliersDropdwon: new FormControl(null, Validators.required,),
        })
    }

    EditForm() {
        const datepipe: DatePipe = new DatePipe('en-US')
        let formattedDate = datepipe.transform(this.expensesItem.Date, "yyyy-MM-dd");
        this.ExpensesForm = new FormGroup({
            NameArInputForm: new FormControl(this.expensesItem.NameAr, Validators.required,),
            NameEnInputForm: new FormControl(this.expensesItem.NameEn, Validators.required,),
            QuantityInputForm: new FormControl(this.expensesItem.Quantity, Validators.required,),
            PriceInputForm: new FormControl(this.expensesItem.UnitPrice, Validators.required,),
            checkboxInputForm: new FormControl(this.expensesItem.CostOfMerchandise),
            DescriptionArInputForm: new FormControl(this.expensesItem.DescriptionAr, Validators.required,),
            DescriptionEnInputForm: new FormControl(this.expensesItem.DescriptionEn, Validators.required,),
            DateInputForm: new FormControl(formattedDate, Validators.required,),
            expensesTypeDropdwon: new FormControl(this.expensesItem.CategoryId, Validators.required,),
            projectDropdwon: new FormControl(this.expensesItem.ProjectId, Validators.required,),
            SuppliersDropdwon: new FormControl(this.expensesItem.SupplierId, Validators.required,),
        })
    }

    ShowForm() {
        const datepipe: DatePipe = new DatePipe('en-US')
        let formattedDate = datepipe.transform(this.expensesItem.Date, "yyyy-MM-dd");
        this.ExpensesForm = new FormGroup({
            NameArInputForm: new FormControl({ value: this.expensesItem.NameAr, disabled: true }),
            NameEnInputForm: new FormControl({ value: this.expensesItem.NameEn, disabled: true }),
            QuantityInputForm: new FormControl({ value: this.expensesItem.Quantity, disabled: true }),
            PriceInputForm: new FormControl({ value: this.expensesItem.UnitPrice, disabled: true }),
            checkboxInputForm: new FormControl({ value: this.expensesItem.CostOfMerchandise, disabled: true }),
            DescriptionArInputForm: new FormControl({ value: this.expensesItem.DescriptionAr, disabled: true }),
            DescriptionEnInputForm: new FormControl({ value: this.expensesItem.DescriptionEn, disabled: true }),
            DateInputForm: new FormControl({ value: formattedDate, disabled: true }),
            expensesTypeDropdwon: new FormControl({ value: this.expensesItem.CategoryId, disabled: true }),
            projectDropdwon: new FormControl({ value: this.expensesItem.ProjectId, disabled: true }),
            SuppliersDropdwon: new FormControl({ value: this.expensesItem.SupplierId, disabled: true }),
        })
    }

    onClick() {
        if (this.modelState == "Add") {
            this.Save();
        } else {
            this.Update();
        }
    }

    Save() {
        this.onWarning();
        var expensesForm = this.ExpensesForm.getRawValue();
        var invoceExpensesModel = new InvoceExpensesModel;
        invoceExpensesModel.Id = 0;
        invoceExpensesModel.NameAr = expensesForm.NameArInputForm;
        invoceExpensesModel.NameEn = expensesForm.NameEnInputForm;
        invoceExpensesModel.Quantity = expensesForm.QuantityInputForm;
        invoceExpensesModel.UnitPrice = expensesForm.PriceInputForm;
        invoceExpensesModel.CostOfMerchandise = expensesForm.checkboxInputForm;
        invoceExpensesModel.DescriptionAr = expensesForm.DescriptionArInputForm;
        invoceExpensesModel.DescriptionEn = expensesForm.DescriptionEnInputForm;
        invoceExpensesModel.Date = expensesForm.DateInputForm;
        invoceExpensesModel.CategoryId = expensesForm.expensesTypeDropdwon;
        invoceExpensesModel.ProjectId = expensesForm.projectDropdwon;
        invoceExpensesModel.SupplierId = expensesForm.SuppliersDropdwon;
        invoceExpensesModel.TotalPrice = expensesForm.QuantityInputForm * expensesForm.PriceInputForm;
        this.postExpenses.postData(invoceExpensesModel).subscribe(
            result => {
                if (result) {
                    this.onSuccess();
                } else {
                    this.onError();
                }
            }
        )

    }

    Update() {
        this.onWarning();
        var expensesForm = this.ExpensesForm.getRawValue();
        var invoceExpensesModel = new InvoceExpensesModel;
        invoceExpensesModel.Id = +this.itemId;
        invoceExpensesModel.NameAr = expensesForm.NameArInputForm;
        invoceExpensesModel.NameEn = expensesForm.NameEnInputForm;
        invoceExpensesModel.Quantity = expensesForm.QuantityInputForm;
        invoceExpensesModel.UnitPrice = expensesForm.PriceInputForm;
        invoceExpensesModel.CostOfMerchandise = expensesForm.checkboxInputForm;
        invoceExpensesModel.DescriptionAr = expensesForm.DescriptionArInputForm;
        invoceExpensesModel.DescriptionEn = expensesForm.DescriptionEnInputForm;
        invoceExpensesModel.Date = expensesForm.DateInputForm;
        invoceExpensesModel.CategoryId = expensesForm.expensesTypeDropdwon;
        invoceExpensesModel.ProjectId = expensesForm.projectDropdwon;
        invoceExpensesModel.SupplierId = expensesForm.SuppliersDropdwon;
        invoceExpensesModel.TotalPrice = expensesForm.QuantityInputForm * expensesForm.PriceInputForm;
        this.postExpenses.update(invoceExpensesModel).subscribe(
            result => {
                if (result) {
                    console.log("Succsess");
                    this.onSuccess();
                } else {
                    this.onError();
                }
            }
        )

    }

    onWarning(): void {

        this.notifications.create(this.translate.instant('Wait'),
            this.translate.instant('Action processing '),
            NotificationType.Warn, { timeOut: 3000, showProgressBar: true });
    }

    onError(): void {
        this.notifications.create(this.translate.instant('Error'),
            this.translate.instant('Something wrong'),
            NotificationType.Error, { timeOut: 3000, showProgressBar: true });
    }

    onSuccess(): void {

        this.notifications.create(this.translate.instant('Success'),
            this.translate.instant('Action Success'),
            NotificationType.Success, { timeOut: 3000, showProgressBar: true });
    }


}
