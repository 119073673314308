

export class WarehouseBrancheModel{
    Id?: number;
    BranchId?:number;
    WarehouseId?: number;  
 
    constructor(item?:WarehouseBrancheModel){
        item = item? item:{};
        this.Id = item.Id? item.Id:0;
        this.BranchId = item.BranchId? item.BranchId:0;
        this.WarehouseId = item.WarehouseId? item.WarehouseId:0;
       
    
    }

}
