import { Component, OnInit } from '@angular/core';
import { PaidChecksModel } from '../../models/paid-checks.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CurrencyModel } from 'src/app/invoices/currencies/CurrencyModel';
import { AccountNameModel } from '../../../../account-name/AccountNameModel';
import { CurrencyService } from 'src/app/invoices/currencies/CurrencyService';
import { AccountsNameService } from '../../../../account-name/AccountNameService';
import { ActivatedRoute, Router } from '@angular/router';
import { PaidChecksService } from '../../paid-checks.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationType, NotificationsService } from 'angular2-notifications';
import { TreasuryAndBankAccountsService } from '../../../../treasury-and-bank-accounts/treasury-and-bank-accounts.service';
import { TreasuryAndBankAccountsModel } from '../../../../treasury-and-bank-accounts/model/treasury-and-bank-accounts-model';
import { CheckBookService } from '../../../check-book/check-book.service';
import { CheckBookModel } from '../../../check-book/models/check-book.model';
@Component({
    selector: 'create-paid-checks',
    templateUrl: 'create-paid-checks.component.html',
    styleUrls: ['create-paid-checks.component.scss']
})
export class CreatePaidChecksComponent implements OnInit{
    PaidChecks:PaidChecksModel={};
    declare lang: any;
    Id=0;
    Form:FormGroup;
    buttonName='Issuing';
    
    curenncies:CurrencyModel[]=[];
    users:any[]=[];
    public editEnabled = true;
   public picurl: string ='';
   AccountsNameModel: AccountNameModel[] = [];
   PackUpAccounts: AccountNameModel[] = [];
   Treasuries:any[]=[];
   checks:CheckBookModel[]=[]
    constructor(
        private CurrencyService: CurrencyService, 
        private accountsNameService: AccountsNameService,
       private route: ActivatedRoute,
       public router:Router,
       private PaidChecksService:PaidChecksService,
       public fb:FormBuilder,
       private translate: TranslateService,
       private TreasuryAndBankAccountsService:TreasuryAndBankAccountsService,
        private notifications: NotificationsService,
        private CheckBookService: CheckBookService,
        ){
     this.lang = localStorage.getItem('theme_lang');
     this.Id = + route.snapshot.paramMap.get('Id');
       
   }
   centersLoaded= false;
   TreasuriesLoaded= false;
   taxesLoaded= false;
   AccountsLoaded= false;
   categoriesLoaded= false;
   curennciesLoaded= false;
   banckAccounts:TreasuryAndBankAccountsModel[]=[];

    ngOnInit(): void {
     this.CheckBookService.getAll()
     .subscribe(result =>{
        this.checks = result;
     })
        this.accountsNameService.GetAllSubAccountes(0).subscribe(
            data=>{
                this.AccountsNameModel=data;
                this.PackUpAccounts = data.map(item=>Object.assign({}, item))
                this.AccountsLoaded = true;

            }
        );
       
        this.CurrencyService.getAll()
        .subscribe(data => {
            this.curenncies = data;
            this.curenncies.forEach(element => {
                if(element.IsMain == true){
                    this.Form.controls['CurrencyId'].setValue(element.Id);
                }
            });
            this.curennciesLoaded = true;
        })
        this.TreasuryAndBankAccountsService.GetAllBankAccounts()
        .subscribe(result=>{
          this.banckAccounts = result;
     
        })
        if(this.Id == 0){
            this.buttonName = "Issuing"
            this.picurl = '';

            this.PaidChecks = new PaidChecksModel();
            this.Form = this.createForm();
            this.curenncies.forEach(element => {
                if(element.IsMain == true){
                    this.Form.controls['CurrencyId'].setValue(element.Id);
                }
            });
        }
        else{
            this.PaidChecksService.getById(this.Id)
            .subscribe(data=>{
                this.buttonName = "Edit"
                
                this.PaidChecks =data;
                this.DueDateString= new Date(this.PaidChecks.DueDate).toISOString().slice(0, 10);
                this.ReleaseDateString= new Date(this.PaidChecks.ReleaseDate).toISOString().slice(0, 10);
                this.picurl = this.PaidChecks.FileUrl;
                this.Form = this.createForm();
            })
        }
        
    }
    DueDateString = '';
    ReleaseDateString = '';
    createForm():FormGroup{
        return this.fb.group({
            Id                   :[this.PaidChecks.Id                   ],
            Amount               :[this.PaidChecks.Amount               ,Validators.required],
            CurrencyId           :[this.PaidChecks.CurrencyId          ,Validators.required ],
            Description          :[this.PaidChecks.Description          ],
            ReleaseDate          :[this.PaidChecks.ReleaseDate          ,Validators.required],
            DueDate              :[this.PaidChecks.DueDate             ,Validators.required ],
            CheckNumber          :[this.PaidChecks.CheckNumber          ,Validators.required],
            AccountReceivedId    :[this.PaidChecks.AccountReceivedId   ,Validators.required ],
            CheckBookId          :[this.PaidChecks.CheckBookId         ,Validators.required ],
            TheNameIsOnTheCheck  :[this.PaidChecks.TheNameIsOnTheCheck  ,Validators.required],
            FileUrl              :[this.PaidChecks.FileUrl              ],
            IsCollection         :[this.PaidChecks.IsCollection         ],
            CollectionDate       :[this.PaidChecks.CollectionDate       ],
            CollectionDescription:[this.PaidChecks.CollectionDescription],
            CollectionBy         :[this.PaidChecks.CollectionBy         ],
            IsRejected           :[this.PaidChecks.IsRejected           ],
            RejectedDate         :[this.PaidChecks.RejectedDate         ],
            RejectedDescription  :[this.PaidChecks.RejectedDescription  ],
            RejectedBy           :[this.PaidChecks.RejectedBy           ],
            BanckId:[this.PaidChecks.BanckId]

        })
    }

  
    Save(){
        debugger;
        this.PaidChecks = this.Form.getRawValue();
        this.PaidChecks.Id = this.Id;
        this.PaidChecks.FileUrl = this.picurl ;
        this.PaidChecks.DueDate = new Date(this.PaidChecks.DueDate);
        this.PaidChecks.ReleaseDate = new Date(this.PaidChecks.ReleaseDate);
        console.log(this.PaidChecks);
        if(this.Id > 0 ){
            this.PaidChecksService.update(this.PaidChecks)
            .subscribe(result => {
                if(result == true){
                    this.router.navigate(['app/accounts/paid-checks']);
                    this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyEdited"));
    
                }
                else{
                 this.toastError(this.translate.instant("Error"), this.translate.instant("FailedEdit"));

                }
            })
        }
        else{
            this.PaidChecksService.insert(this.PaidChecks)
            .subscribe(result => {
                if(result == true){
                    this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyAdd"));
                    this.router.navigate(['app/accounts/paid-checks']);
                }
                else{
                 this.toastError(this.translate.instant("Error"), this.translate.instant("FailedAdd"));
                }
            })
        }
   
    }
    addAndNew(){
        debugger;
        this.PaidChecks = this.Form.getRawValue();
        this.PaidChecks.Id = this.Id;
        this.PaidChecks.FileUrl = this.picurl ;
 
        if(this.Id > 0 ){
            this.PaidChecksService.update(this.PaidChecks)
            .subscribe(result => {
                if(result == true){
                    this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyEdited"));
                    this.PaidChecks = new PaidChecksModel();
                   this.picurl = '';
                }
                else{
                 this.toastError(this.translate.instant("Error"), this.translate.instant("FailedEdit"));
                }
            })
        }
        else{
            this.PaidChecksService.insert(this.PaidChecks)
            .subscribe(result => {
                if(result == true){
                    this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyAdd"));
                    this.PaidChecks = new PaidChecksModel();
                    this.picurl = '';
                }
                else{
                    this.toastError(this.translate.instant("Error"), this.translate.instant("FailedAdd"));
                }
            })
        }
   
    }
    clear(){
        this.picurl = '';
     }
    toastSuccess(toastHeader: string, toastBody: string): void {
        this.notifications.create(toastHeader, toastBody,
          NotificationType.Success, { timeOut: 3000, showProgressBar: true });
      }
      toastError(toastHeader: string, toastBody: string): void {
        this.notifications.create(toastHeader, toastBody,
          NotificationType.Error, { timeOut: 3000, showProgressBar: true });
      }
      changeBanck(){
        var BanckId=this.Form.controls['BanckId'].value;
        var banckBook=this.checks.find(x=>x.BankId == BanckId);
        if(banckBook != null){
            this.Form.controls['CheckBookId'].setValue(banckBook.Id);
            this.Form.controls['CurrencyId'].setValue(banckBook.CurrencyId);
            
        }
      }
      changeCheckBook(){
        var BanckId=this.Form.controls['CheckBookId'].value;
        var banck=this.banckAccounts.find(x=>x.Id == BanckId);
        if(banck != null){
            this.Form.controls['BanckId'].setValue(banck.Id);
            this.Form.controls['CurrencyId'].setValue(banck.CurrencyId);

        }
      }
}

