<div class="row mb-12">
    <div class="col-md-12 col-lg-12 col-12 mb-12">
      <div class="card">
        
       
        </div>
    </div>
</div>
<div class="row" *ngIf="AttendanceSession">
    <div class="col-12">
      <div class="card mb-6">
        <div class="card-body">
            <h5 class="float-left" style="display: inline;">#{{ AttendanceSession.Code}}

                <i style="margin: 10px; " [ngClass]="{'glyph-icon simple-icon-lock-open':AttendanceSession.IsOpen,'glyph-icon simple-icon-lock':!AttendanceSession.IsOpen}"></i>
                   <h5 style="display: inline;" *ngIf="AttendanceSession.IsOpen">{{"Open" | translate}}</h5> 
                   <h5 style="display: inline;" *ngIf="!AttendanceSession.IsOpen">{{ "Closed" | translate}}</h5> 
            </h5>
            <div class="top-right-button-container text-zero float-right">
                <button type="button" (click)="OpenRecord()" class="top-right-button btn btn-primary  btn-lg mr-1">{{'Record'|translate}}</button>
                <button type="button" (click)=" CloseSession()" class="top-right-button btn btn-danger  btn-lg mr-1">{{'Close'|translate}}</button>
             </div>
        </div>
      </div>
    </div>
  </div>
<div class="row mb-12" style="margin-top: 20px;">
<div class="col-md-12 col-lg-12 col-12 mb-12">
<div class="card">
  <tabset class="card-tabs" [justified]="true">
    <tab heading="{{'Details' | translate}}" class="card-body">
        <div  *ngIf="AttendanceSession" >
            <div class="form-group">
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <div>{{ 'Code' | translate }}</div>
                        <div>#{{AttendanceSession.Code}}</div>
                    </div>
                    <div class="form-group col-md-6">
                        <div>{{ 'Session Time' | translate }}</div>
                        <div>{{AttendanceSession.SessionTime | date:'dd/MM/yyyy HH:mm'}}</div>
                    </div>
                  
                </div>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <div >{{'Source'| translate}}</div>
                        <div>{{ lang === 'ar-EG' ? AttendanceSession.AttendanceSourceNameAr : AttendanceSession.AttendanceSourceNameEn }}</div>
                    </div>
                    <div class="form-group col-md-6">
                        <div>{{ 'Number Of Records' | translate }}</div>
                        <div>{{AttendanceSession.NumberOfRecords}}</div>
                    </div>
               
                </div>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <div>{{ 'Status' | translate }}</div>
                        <div>{{ AttendanceSession.IsOpen?'Open':'Closed'| translate}}</div>
                    </div>
                    <div class="form-group col-md-6">
                   </div>
                </div>
          
            </div>
        </div>
    </tab>
    <tab heading="{{'Session Logs' | translate}}" class="card-body">
      <h6 class="mb-4">{{'Session Logs' | translate}}</h6>
      <div class="row">
        <div class=" col-md-12">
            <table class="table">
                <thead>
                    <tr>
                      <th>{{"Employee"|translate}}</th>
                      <th>{{"Source"|translate}}</th>
                      <th>{{"Session Number"|translate}}</th>
                      <th>{{"Log Time"|translate}}</th>
                      <th>{{"Status"|translate}}</th>
                    </tr>
                </thead>   
                <tbody>
                    <tr *ngFor="let item of AttendanceSession.SessionLogs">
                        <td>{{item.EmployeName}}</td>
                        <td>{{item.AttendanceSourceName}}</td>
                        <td>{{AttendanceSession.Code}}</td>
                        <td>{{item.CheckIn | date:'dd/MM/yyyy HH:mm'}}</td>
                        <td *ngIf="item.Status">{{'Draft' | translate}}</td>
                        <td *ngIf="!item.Status">{{'Invalid' | translate}}</td>
                    </tr>
                </tbody> 
            </table>
        </div>
  
    </div>
         </tab>

  </tabset>
</div>
</div>
</div>
<div bsModal #Record="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">
    <div class="modal-dialog modal-sm">
        <div class="modal-content"  >
            <button type="button" class="close pull-right" aria-label="Close" (click)="Record.hide()"
                style="margin-right: 20px; margin-left: 20px; margin-top: 10px; width: 20px; display: inline;">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-header" style="display: inline; padding: 0px 0px 5px 0px;">
                <h4 id="dialog-child-name"  class="modal-title pull-left"  style="text-align: center;">
                    {{"Record" |
                    translate}}</h4>
            </div>
            <div class="modal-body">
           
           
                <div class="col-md-12">
                    <label for="inputEmail4">{{ 'Employee' | translate }}</label>
                    <ng-select  (change)="SelectEmployee()" [(ngModel)]="EmployeeId"    [hideSelected]="true">
                      <ng-option selected desabled [value]="0">{{ "Please Choose"|translate}}</ng-option>
                      <ng-option *ngFor="let employee of employees" [value]="employee.Id">{{ employee.Name}}</ng-option>
                  </ng-select>
                </div>
                
                <div class="col-md-12 form-group" *ngIf="EmployeeId > 0">
                    <div class="timerCard">
                    <ngx-countdown class="center" interval="{{count}}" (onComplete)="completed()"></ngx-countdown>
                </div>
                </div>
                <div style="margin-left: 5px; text-align: center; margin-top: 30px;">
                    <button class="btn btn-success" id="smallModalDeleteButton" (click)="SaveRecord()"
                        style="font-weight: bold; width: 100%; text-align: center;">{{"Record" | translate}}</button>
                    <button class="btn btn-light" (click)="Record.hide()"
                        style="font-weight: bold; width: 100%; text-align: center; margin-top: 10px;">{{"Close" |
                        translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>
