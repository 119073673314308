import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {WarehouseService} from '../../warehouses/warehouse-service.service';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import {ProductesService} from '../../ProductesAndServices/productes.service';
import { TableEngine } from 'src/app/invoices/TableEngine';
import { StoreItemsModel } from '../../models/StoreItemsModel';
import { WarehouseModel } from '../../models/WarehouseModel';
import {StoreBillModel} from '../../models/StoreBillModel';
import { SidePopupComponent } from 'src/app/views/app/aio/shared/side-popup/side-popup.component';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ItemsStoreBellModel } from '../../models/ItemsStoreBillModel';
import { AccountesNameModel } from '../../models/AccountesNameModel';
import { WarehouseItemModel } from '../../models/WarehouseItem';
import {TransfareBillService}from '../../Stores-Bells/transfare-bill.service';
@Component({
    // moduleId: module.id,
    selector: 'transfer-store-bill',
    templateUrl: 'transfer-store-bill.component.html',
    styleUrls: ['transfer-store-bill.component.scss']
})
export class TransferStoreBillComponent {
    Id = 0;

    constructor(
      public router: Router,
      private fb: FormBuilder,
      private modelService: TransfareBillService,
      private WarehouseService: WarehouseService,
     private ProductesService:ProductesService,
      private notifications: NotificationsService,
      private translate: TranslateService,
      private route: ActivatedRoute
    ) {
      this.Id = +route.snapshot.paramMap.get("Id");
    }
  
    tableEngine = new TableEngine(this.translate, this.notifications);
    currentScreen: any = "Table";
    translationName: string = "BellType";
    sortableKeys = [];
    searchableKeys = ["BellType", "Date"];
    tableView: any = true;
  
    // StoreItemsModel:StoreItemsModel[]=[];
  
   
    modelObject: StoreBillModel = new StoreBillModel();
    Form: FormGroup;
    declare lang: any;
    declare itemOrder: any;
    currentAction = "";
    savedPage = 0;
    currentPage = 1;
    deletionList: any[] = [];
    prevAction = "";
    addToListId = 0;
    ItemsBalanceHeaderId = 0;
    ItemBalanceId = 0;
    currentFormMode: string = "";
    MessagChoice:string="Please choose the type of ear first"
    type = 0;
    visable = "";
    visable2 = "";
    visable3 = "";
    MovementNumber=0;
    ogData: StoreBillModel[] = [];
    mainForm: FormGroup = new FormGroup({
      Id: new FormControl(),
      BillType: new FormControl(),
      SkuNumber: new FormControl(),
      WarehouseId: new FormControl(),
      SubAccountId: new FormControl(),
      Notes: new FormControl(),
      Time: new FormControl(),
      Date: new FormControl(),
      IsApproval: new FormControl(),
      SecondWarehouseId: new FormControl(),
      // SubAccountName: new FormControl(),
      

    });
    @ViewChild("sideModalRef", { static: true })
    sideModalRef: SidePopupComponent = null;
    @ViewChild("delModal", { static: true }) delModal: ModalDirective;
    @ViewChild("smallModal", { static: true }) smallModal: ModalDirective = null;
    formPresentationModal: any;
    ngOnInit(): void {
      // this.getAllCountryCode();
  
      this.lang = localStorage.getItem("theme_lang");
      this.itemOrder = {
        label: "Date",
        property: this.lang === "ar-EG" ? "Date" : "Date",
      };
     
      this.tableEngine.loadTableEngine(
        this.ogData,
        this.modelService,
        this.modelObject,
        this.searchableKeys,
        this.sortableKeys,
        this.mainForm,
        this.translationName,
        this.itemOrder,
        this.sideModalRef,
        this.delModal,
        this.smallModal,
        this.lang
      );
     
  
      this.loadServices();
      this.modelService.getAll().subscribe((data) => {
        this.modelObject = data;
        console.log("WarehouseValidity", this.modelObject);
        this.modelService.getLastNumber().subscribe((data) => {
          debugger;
          this.modelObject.SkuNumber = data;
          this.mainForm.controls['SkuNumber'].setValue(this.modelObject.SkuNumber+1);
          console.log("SkuNumber", this.modelObject.SkuNumber);

          this.modelService.getLastMoveNumber().subscribe(data=>{
            this.MovementNumber=data;
          })
          this.modelService.GetAllSubAccountes(0).subscribe((data)=>{
            this.AccountesList=data;
            this.WarehouseService.getAll().subscribe((data) => {
              this.WarehouseList = data;
                
                this.ProductesService.getAll().subscribe(data=>{
                  debugger;
                  this.ProducteList=data;
                  this.WarehouseItemList=[];
                  this.ProducteList.forEach(element => {
                    element.WarhouseItem.forEach(element => {
                      this.WarehouseItemList.push(element)
                    });
                    
                  });
                  if(this.Id > 0)
                  {   debugger;                            
                          this.modelService.getById(this.Id).subscribe(data=>{                        
                          this.modelObject = data;                 
                          this.ItemsStoreBell=data.ItemsStoreBillList;
                          this.ItemsStoreBell=this.modelObject.ItemsStoreBillList;
                          this.mainForm = this.createForm(this.modelObject);
                          this.ItemsStoreBell.forEach(element => {
                          this.setQiantity(element.ItemId);
                            
                          });
                          this.setQuantityByWarehouse();
                          this.calculateTotalItemValue();
          
                      });
                     
          
                  }
                  else{
                          
                     this.mainForm = this.createForm(this.modelObject);                              
          
          
                  }
                })
            });
          })
        });
      });
     

   


         
      // this.mainForm = this.createForm(this.modelObject);
  
    }

  createForm(modelObject): FormGroup {
    debugger;
    return this.fb.group({
      // Id:[this.ItemBalance.Id],
      Id: [modelObject.Id >= 0 ? modelObject.Id : 0],
      BillType: [modelObject.BillType=this.lang === "ar-EG" ? 'تحويل يدوي' : 'Transfer Manual',Validators.compose([Validators.required])],
      SkuNumber: [modelObject.SkuNumber],
      
      WarehouseId: [modelObject.WarehouseId],
      SubAccountId: [modelObject.SubAccountId],
      Time: [modelObject.Time],
      Date: [modelObject.Date],
      IsApproval: [modelObject.IsApproval],
      Notes: [modelObject.Notes],
      SecondWarehouseId: [modelObject.SecondWarehouseId],
      // SubAccountName: [modelObject.SubAccountName],
      
      
    });
  }
  WarehouseList: WarehouseModel[] = [];
  ProducteList:StoreItemsModel[]=[];
  ItemsStoreBell:ItemsStoreBellModel[]=[{Id:0,ItemId:0,StoreBillId:this.Id,Quantity:0,SalePrice:0,AdjustedQuantity:0,Total:0,Name:''}];
  AccountesList: AccountesNameModel[] = [];
  selectedWarehouse:WarehouseItemModel={};
  WarehouseItemList: WarehouseItemModel[] = [];

  loadServices() {
    // debugger;
    this.currentAction = "loading";
    this.tableEngine.loadData();
  }

  getItemsById(Id:any):any{
    if(Id>0)
    {
    return this.ProducteList.find(i=>i.Id===Id)
    }
    else
    {
    return new StoreItemsModel();
    }
    }
  setItem(bell){
    debugger;
    var i = this.ProducteList.findIndex(x=>x.Id == bell.ItemId);
    if(i>-1){
      bell.SalePrice = this.ProducteList[i].SalePrice;
    }
    
    // this.calculateTotalItemValue();
    }

    setQiantity(itemId){
      debugger;
      var WarehouseId = this.mainForm.controls['WarehouseId'].value;
      // var ItemId = this.mainForm.controls['WarehouseId'].value;
      var i = this.WarehouseItemList.findIndex(x=>x.WarehouseDataId == WarehouseId&&x.ItemId==itemId);
      if(i>-1){
        this.selectedWarehouse = this.WarehouseItemList[i];
      }
      else{
        this.selectedWarehouse.Quantity=0
      }
    }
    setQuantityByWarehouse(){
      debugger;
      var WarehouseId = this.mainForm.controls['WarehouseId'].value;
      // var ItemId = this.mainForm.controls['WarehouseId'].value;
      this.ItemsStoreBell.forEach(element => {
        var i = this.WarehouseItemList.findIndex(x=>x.WarehouseDataId == WarehouseId&&x.ItemId==element.ItemId);
      if(i>-1){
        this.selectedWarehouse = this.WarehouseItemList[i];
        this. calculateTotalQuantity();

      }
      else{
        this.selectedWarehouse.Quantity=0
      }
      });
      
    }
  


    currentTotal = 0;

    calculateTotalItemValue() {
      let total = 0;
      this.ItemsStoreBell.forEach(item => {
        total += item.AdjustedQuantity*item.SalePrice;
      });
      this.currentTotal = total;
      return total;
    }
    calculateTotalQuantity() {
      this.ItemsStoreBell.forEach(element => {
       element.Quantity =this.selectedWarehouse.Quantity-element.AdjustedQuantity
      });
     }

  addproductes() {
    debugger;
    var i = this.ProducteList.findIndex((x) => x.Id == this.addToListId);
    if (i >= 0) {
      var item = this.ProducteList[i];

      this.ProducteList.push(item);
      this.ProducteList.splice(i, 1);
      this.addToListId = 0;

    }
 }
  AddonClick(){
    var ItemStoreList= new ItemsStoreBellModel();
    ItemStoreList.Id = 0, 
    ItemStoreList.StoreBillId =this.Id, 
    ItemStoreList.ItemId = 0, 
    // ItemStoreList.IsDeleted= false, 
    this.ItemsStoreBell.push(ItemStoreList);
    // this.mainForm.controls['StoreBillId'].setValue(0);
    ItemStoreList={};    
  }

  removeItemsFooter(Id: any): any {
    debugger;
    let index = this.ItemsStoreBell.findIndex(x => x.ItemId === Id);
    this.ItemsStoreBell.splice(index, 1);      
    this.calculateTotalItemValue();           
    }
  
   
    goBack() {
        this.router.navigate(["app/aio/Inventory/List-All-Bells"]);
      }
Save() {
 debugger;
  
        this.currentAction = "loading";
        this.tableEngine.savedPage = this.tableEngine.currentPage;
        this.modelObject = new StoreBillModel();
        this.modelObject.Id = 0;
        this.modelObject.SkuNumber = this.mainForm.getRawValue().SkuNumber;
        this.modelObject.BillType = this.mainForm.getRawValue().BillType;
        this.modelObject.Date = new Date(this.mainForm.getRawValue().Date);
        this.modelObject.Notes ='';
        this.modelObject.SubAccountId = +this.mainForm.getRawValue().SubAccountId;     
        this.modelObject.SecondWarehouseId = +this.mainForm.getRawValue().SecondWarehouseId;     
        this.modelObject.MoveNumber=this.MovementNumber;
        this.modelObject.Time =
          this.mainForm.getRawValue().Time;
        this.modelObject.IsApproval =true;
        this.modelObject.WarehouseId =
          +this.mainForm.getRawValue().WarehouseId;
      
        this.modelObject.ItemsStoreBillList = this.ItemsStoreBell;
        console.log("Obj",this.modelObject);
        console.log(this.modelObject);
    
        if (this.Id == 0) {
          this.modelService.insert(this.modelObject).subscribe((data) => {
            this.router.navigate(["app/aio/Inventory/List-All-Bells"]);
          });
        }
         else {
          debugger;
          this.modelService.update(this.modelObject).subscribe((data) => {
            this.router.navigate(["app/aio/Inventory/List-All-Bells"]);
          });
        }
      }   
      

}
