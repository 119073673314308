import { Component, HostBinding, Renderer2, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';

import { TableEngine } from 'src/app/invoices/TableEngine'
import { ModalDirective } from 'ngx-bootstrap/modal';
import { SidePopupComponent } from 'src/app/views/app/aio/shared/side-popup/side-popup.component';
import { Observable, of, Subscription } from 'rxjs';

import { NgSelectComponent } from '@ng-select/ng-select';

import { PurchaseOrderModel } from "src/app/views/purchases/purchase-orders/PurchaseOrderModel";
import { PurchaseOrderService } from "src/app/views/purchases/purchase-orders/PurchaseOrderService";
import { PurchaseOrderItemModel } from "src/app/views/purchases/purchase-orders/PurchaseOrderItemModel";

import { CodingUnitModel } from "src/app/invoices/coding-units/CodingUnitModel";
import { CodingUnitService } from 'src/app/invoices/coding-units/CodingUnitService';
import { StoreDataService } from "src/app/views/purchases/purchase-orders/StoreDataService";
import { SupplierModel } from "src/app/views/purchases/purchase-orders/SupplierModel";
import { SupplierService } from "src/app/views/purchases/purchase-orders/SupplierService";
// import { PaymentMethodModel } from "src/app/views/purchases/purchase-orders/PaymentMethodModel";
// import { PaymentMethodService } from "src/app/views/purchases/purchase-orders/PaymentMethodService";
import { DatePipe, formatDate } from '@angular/common';
import { StoreDataModel } from './StoreDataModel';
import { ConfigPurchasesService } from '../config-purchases/ConfigPurchasesService';
import { ConfigPurchasesModel } from '../config-purchases/ConfigPurchasesModel';
import { ListPageHeaderComponent } from 'src/app/containers/pages/list-page-header/list-page-header.component';
import { StoreItemsService } from 'src/app/Invintory/store-items-service.service';

@Component({
  selector: 'app-purchase-orders',
  templateUrl: './purchase-orders.component.html',
  styleUrls: ['./purchase-orders.component.scss']
})
export class PurchaseOrdersComponent {
  ////////////////////////////////////////////////////////////////////////////
  //  VARYING :
  //  Modify per similar component.
  ////////////////////////////////////////////////////////////////////////////

  //Enable debugMode to make API modules return static data from within the service class
  debugMode: boolean = false;

  constructor(private formBuilder: FormBuilder, private modelService: PurchaseOrderService, private renderer: Renderer2,
    private notifications: NotificationsService, private translate: TranslateService,
    private itemService: StoreItemsService, private datePipe: DatePipe,
    private storeDataService: StoreDataService, private codingUnitService: CodingUnitService,
    private supplierService: SupplierService, private configPurchasesService: ConfigPurchasesService

  ) {
  }

  listHeader = new ListPageHeaderComponent();

  tableEngine = new TableEngine(this.translate, this.notifications);
  currentScreen: any = 'Table';
  tableView: any = true;

  translationName: string = 'PurchaseOrder'

  sortableKeys = [];
  searchableKeys = ['Date'];

  modelObject: PurchaseOrderModel = new PurchaseOrderModel();

  ogData: PurchaseOrderModel[] = [];

  Items?: PurchaseOrderItemModel[];
  mainForm: FormGroup = new FormGroup({
    Id: new FormControl(),
    //Code: new FormControl(),
    SupplierId: new FormControl(),
    //CompanyDataId: new FormControl(),
    //BranchId: new FormControl(),
    StoreDataId: new FormControl(),
    Date: new FormControl(),
    //TotalValue: new FormControl(),
    IsPosted: new FormControl(),
    Description: new FormControl(),
    //Items: new FormControl(),
  });

  declare lang: any;
  declare itemOrder: any;
  currentAction = '';
  savedPage = 0;
  currentPage = 1;
  deletionList: any[] = [];

  @ViewChild('sideModalRef', { static: true }) sideModalRef: SidePopupComponent = null;
  @ViewChild('delModal', { static: true }) delModal: ModalDirective;
  @ViewChild('smallModal', { static: true }) smallModal: ModalDirective = null;
  formPresentationModal: any;
  ngOnInit(): void {
    if (this.debugMode) {
      this.enableDebugOnAllServices();
    }

    this.lang = localStorage.getItem('theme_lang');
    this.itemOrder = { label: 'PurchaseOrderCode', property: 'Code' };
    this.sortableKeys = [
      { label: 'PurchaseOrderCode', property: 'Code' },
      { label: 'PurchaseOrderDate', property: 'Date' },
      { label: 'PurchaseOrderStoreName', property: this.lang === 'ar-EG' ? 'StoreNameAr':'StoreNameEn' },
      { label: 'PurchaseOrderTotalValue', property: 'TotalValue' },
      { label: 'PurchaseOrderSupplier', property: this.lang === 'ar-EG' ? 'SupplierNameAr':'SupplierNameEn' },
      { label: 'PurchaseOrderIsPosted', property: 'IsPosted' },
    ];
    this.tableEngine.loadTableEngine(this.ogData, this.modelService, this.modelObject,
      this.searchableKeys, this.sortableKeys, this.mainForm, this.translationName, this.itemOrder,
      this.sideModalRef, this.delModal, this.smallModal, this.lang
    )

    this.loadServices();
  }


  enableDebugOnAllServices() {
    //this.modelService.debugMode = true;
    // this.itemService.debugMode = true;
    //this.codingUnitService.debugMode = true;
    //this.storeDataService.debugMode = true;
    //this.supplierService.debugMode = true;
  }

  lastCode = 0;
  showModal(Id, action) {
    this.newPurchaseOrderItem = {};
    this.purchaseOrderItemForm = this.createPurchaseOrderItemForm(this.newPurchaseOrderItem, this.formBuilder);

    if (action === 'Add') {
      this.addedItemList = [];
      this.newPurchaseOrderItemInternalId = 0;
      if(this.configPurchases.EnableAutoPurchaseOrderCodes==true){
        this.lastCode = this.configPurchases.StartingPurchaseOrderCode;
        for (let index = 0; index < this.tableEngine.ogData.length; index++) {
          if(this.tableEngine.ogData.findIndex(x=>x.Code == this.lastCode) >= 0)
          { this.lastCode++; console.log(this.lastCode);
          } 
          else { break; }
        }
      }else{
        //this.lastCode = this.tableEngine.ogData.length>0?this.tableEngine.ogData[this.tableEngine.ogData.length - 1].Code + 1:0;
        this.lastCode = 0;
      }
      this.modelObject.Code = this.lastCode;
      this.checkCode();
      this.tableEngine.showAddNewModalRef(this.createForm, this.formBuilder)
    }
    else if (action === 'Edit') {
      //this.tableEngine.showEditModalRef(Id, this.createForm, this.formBuilder)
      this.modelService.getByIdWithItems(Id).subscribe(
        detailedObject => {
          console.log("detailed object", detailedObject);
          
          let targetIndex = this.tableEngine.ogData.findIndex(i => i.Id === Id);
          this.tableEngine.ogData[targetIndex] = detailedObject;
          this.tableEngine.showEditModalRef(Id, this.createForm, this.formBuilder)
          
          this.lastCode = this.tableEngine.modelObject.Code
          
          this.modelObject = this.tableEngine.modelObject;
          this.mainForm = this.tableEngine.mainForm;
          this.tableView = false;

          this.addedItemList = [];
          this.addedItemList = [...this.modelObject.Items];

          this.calculateTotalItemValue();
        }
      )
    }
    else if (action === 'Info') {
      console.log("entered info");
      this.modelService.getByIdWithItems(Id).subscribe(
        detailedObject => {
          console.log("detailed object", detailedObject);
          
          let targetIndex = this.tableEngine.ogData.findIndex(i => i.Id === Id);
          this.tableEngine.ogData[targetIndex] = detailedObject;
          this.tableEngine.showInfoModalRef(Id, this.createForm, this.formBuilder)
          
          this.lastCode = this.tableEngine.modelObject.Code
          
          this.modelObject = this.tableEngine.modelObject;
          this.mainForm = this.tableEngine.mainForm;
          this.tableView = false;

          this.addedItemList = [];
          this.addedItemList = [...this.modelObject.Items];

          this.calculateTotalItemValue();
        } 
      )
    }
    this.tableView = false;
    this.modelObject = this.tableEngine.modelObject;
    this.mainForm = this.tableEngine.mainForm;
    console.log((action + " object"), this.modelObject);

    this.calculateTotalItemValue();

    console.log("modelObject after entering edit", this.modelObject);
  }
  currentDate='';
  createForm(modelObject, formBuilder): FormGroup {
    
    return formBuilder.group({
      Id: [modelObject.Id >= 0 ? modelObject.Id : 0],
      SupplierId: [modelObject.SupplierId, Validators.compose([Validators.required])],
      StoreDataId: [modelObject.StoreDataId, Validators.compose([Validators.required])],
      //BranchId: [modelObject.BranchId, Validators.compose([Validators.required])],
      Date: [modelObject.Date?modelObject.Date.toString().slice(0, 10):modelObject.Date, Validators.compose([Validators.required])],
      Description: [modelObject.Description],
      IsPosted: [modelObject.IsPosted, Validators.compose([Validators.required])],

      //Items: [modelObject.Items],
    });
  }

  addObject() {
    this.currentAction = 'loading';
    
    let tempList: PurchaseOrderItemModel[] = [];
    let tempItem: PurchaseOrderItemModel;
    let debugId = 0;
    for (let index = 0; index < this.addedItemList.length; index++) {
      tempItem = new PurchaseOrderItemModel();
      tempItem = this.addedItemList[index]
      tempItem.Id = this.debugMode ? debugId : 0;
      tempItem.PurchaseOrderId = 0;
      tempList.push(tempItem)

      debugId++;
    }
    
    let lastId = this.tableEngine.ogData.length>0?this.tableEngine.ogData[this.tableEngine.ogData.length - 1].Id:0;

    this.modelObject = new PurchaseOrderModel();
    
    //this.modelObject.Id = this.debugMode ? lastId + 1 : 0;
    this.modelObject.Id = 0;
    this.modelObject.Code = this.lastCode;
    let supplier = this.getSupplierById(this.mainForm.getRawValue().SupplierId);
    this.modelObject.SupplierId = supplier.Id;
    this.modelObject.SupplierNameAr = supplier.NameAr;
    this.modelObject.SupplierNameEn = supplier.NameEn;
    let store = this.getStoreDataById(this.mainForm.getRawValue().StoreDataId);
    this.modelObject.StoreDataId = store.Id;
    this.modelObject.StoreNameAr = store.NameAr;
    this.modelObject.StoreNameEn = store.NameEn;
    this.modelObject.BranchId = store.BranchId;
    this.modelObject.BranchNameAr = store.BranchAr;
    this.modelObject.BranchNameEn = store.BranchEn;

    let date = new Date(this.mainForm.getRawValue().Date);
    this.modelObject.Date = date;
    //this.modelObject.Month = date.getMonth();
    //this.modelObject.Year = date.getFullYear();

    this.modelObject.Description = this.mainForm.getRawValue().Description;
    this.modelObject.IsPosted = this.mainForm.getRawValue().IsPosted;
    this.modelObject.Items = tempList;
    this.modelObject.TotalValue = this.calculateTotalItemValue();


    console.log(this.modelObject);
    this.executeAddObject();
  }

  executeAddObject() {
    this.modelService.insertWithItems(this.modelObject).subscribe(result => {
      if (result === true || result > 0) {
        this.tableEngine.loadData();
        this.currentAction = '';
        this.tableEngine.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyAdded"));
        if (this.sideModalRef === undefined) {
          if (this.smallModal != undefined) {
            this.tableEngine.smallModal.hide();
          }
          else {
            this.tableView = this.prevAction === 'AddThenNew' ? false : true;
            
            this.prevAction = '';
          }
        }
        else {
          this.tableEngine.sideModalRef.modalRef.hide();
        }
        
        if(this.prevAction != 'AddThenNew'){
          this.goBack();
        }
      }
      else {
        this.tableEngine.toastError(this.translate.instant("Error"), this.translate.instant("FailedAdd"));
        this.currentAction = '';
      }
    })
  }

  editObject() {
    this.currentAction = 'loading';
    this.tableEngine.savedPage = this.tableEngine.currentPage;

    let tempList: PurchaseOrderItemModel[] = [];
    let tempItem: PurchaseOrderItemModel;
    let debugId = 1;
    for (let index = 0; index < this.addedItemList.length; index++) {
      tempItem = new PurchaseOrderItemModel();
      tempItem = {...this.addedItemList[index]}
      //tempItem.Id = this.debugMode ? debugId : 0;
      tempItem.Id = 0;

      tempItem.PurchaseOrderId = this.modelObject.Id;
      tempList.push(tempItem)
      console.log("added temp item while editing", tempItem);
      
      debugId++;
    }

    //this.calculateTotalItemValue();
    //let newString = new DatePipe('en-US').transform(this.mainForm.getRawValue().Date, 'dd/MM/yyyy');
    //console.log("newstring", newString);
    
    //let date: Date = this.getFormattedDate(new DatePipe('en-US').transform(this.mainForm.getRawValue().Date, 'dd/MM/yyyy'));
    let tempObj = new PurchaseOrderModel();
    tempObj.Id = this.modelObject.Id;
    tempObj.Code = this.lastCode;
    let supplier = this.getSupplierById(this.mainForm.getRawValue().SupplierId);
    tempObj.SupplierId = supplier.Id;
    tempObj.SupplierNameAr = supplier.NameAr;
    tempObj.SupplierNameEn = supplier.NameEn;
    let store = this.getStoreDataById(this.mainForm.getRawValue().StoreDataId);
    tempObj.StoreDataId = store.Id;
    tempObj.StoreNameAr = store.NameAr;
    tempObj.StoreNameEn = store.NameEn;
    tempObj.BranchId = store.BranchId;
    tempObj.StoreNameAr = store.BranchAr;
    tempObj.StoreNameEn = store.BranchEn;

    let date = new Date(this.mainForm.getRawValue().Date);

    tempObj.Date = date;
    tempObj.Year = date.getFullYear();
    tempObj.Month = date.getMonth();
    tempObj.Description = this.mainForm.getRawValue().Description;
    tempObj.IsPosted = this.mainForm.getRawValue().IsPosted;
    tempObj.Items = tempList;
    tempObj.TotalValue = this.calculateTotalItemValue();
    this.executeEditObject(tempObj);
    console.log("saved object", tempObj);
  }

  executeEditObject(Object) {


    this.modelService.updateWithItems(Object).subscribe(result => {
      if (result === true || result > 0) {
        this.tableEngine.loadData();
        this.currentAction = '';
        this.tableEngine.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyEdited"));

        if (this.sideModalRef === undefined) {
          if (this.smallModal != undefined) {
            this.tableEngine.smallModal.hide();
          }
          else {
            this.tableView = true;
          }
        }
        else {
          this.tableEngine.sideModalRef.modalRef.hide();
        }
        
        this.goBack();
        
      }
      else {
        this.tableEngine.toastError(this.translate.instant("Error"), this.translate.instant("FailedEdit"));
        this.currentAction = '';
      }
    })
  }

  //Deletion methods:
  //Customize if needed.
  //Pass the target object as parameter if the target API can only delete singular objects and not lists.
  deleteObject() {
    this.savedPage = this.currentPage;
    //prevent multiclicks. re enable after API response
    this.currentAction = 'loading';
    this.executeDeleteObject();
  }

  //Same here...
  executeDeleteObject() {
    this.modelService.deleteList(this.tableEngine.deletionList).subscribe(result => {
      if (result === true || result > 0) {
        this.tableEngine.loadData();
        this.currentAction = '';
        this.tableEngine.finalizeDeletion();
      }
      else {
        this.tableEngine.toastError(this.translate.instant("Error"), this.translate.instant("FailedDelete"));
        this.currentAction = '';
      }
    })
  }

  
  //lastCode = 0;
  codeWasChecked = false;
  checkCode() : boolean{
    if(!(this.lastCode > 0)){
      this.codeWasChecked = false;
      console.log("bad code", this.lastCode);
      
      return this.codeWasChecked;
    }
    this.configPurchasesService.checkPurchaseOrderCode(this.lastCode).subscribe(
        result => {
          this.codeWasChecked = result;
        }
      );
      console.log("code availability:",this.codeWasChecked);
      
    return this.codeWasChecked;
  }
  ////////////////////////////////////////////////////////////////////////////
  //
  //
  //
  //
  //
  ////////////////////////////////////////////////////////////////////////////
  //  EXCLUSIVE :
  //  Specifically for this component.
  ///////////////////////////////
  //Get any additional data here
  SearchFrom: Date = null;
  SearchTo: Date = null;

  clearSearch() {
    this.SearchFrom = null;
    this.SearchTo = null;
  }

  prevAction = '';
  addAndNew() {
    this.prevAction = 'AddThenNew';
    this.addObject();
    this.showModal(null, 'Add');
  }


  configPurchases = new ConfigPurchasesModel();
  loadServices() {
    this.currentAction = 'loading';

    this.supplierService.getAll().subscribe(
      supplierData => {
        this.supplierList = supplierData;

        this.codingUnitService.getAll().subscribe(
          codingUnitData => {
            this.codingUnitList = codingUnitData;

            this.itemService.getAll().subscribe(
              itemData => {
                this.itemList = itemData;

                this.storeDataService.getAll().subscribe(
                  storeData => {
                    this.storeDataList = storeData;

                    this.configPurchasesService.getFirst().subscribe(
                      configPurchasesData => {
                        this.configPurchases = configPurchasesData;
    
                        this.tableEngine.loadData();
                      }
                    )
                  }
                )
              }
            )
          }
        )
      }
    )


  }
  
  addedItemList: PurchaseOrderItemModel[] = [];
  codingUnitList: CodingUnitModel[] = [];
  itemList: any[] = [];
  storeDataList: StoreDataModel[] = [];
  supplierList: SupplierModel[] = [];
  
  getItemById(Id: any): any {
    return this.itemList.find(i => i.Id === Id);
  }
  getUnityById(Id: any): any {
    return this.codingUnitList.find(i => i.Id === Id);
  }
  getStoreDataById(Id: any): any {
    return this.storeDataList.find(i => i.Id === Id);
  }
  getSupplierById(Id: any): any {
    return this.supplierList.find(i => i.Id === Id);
  }

  purchaseOrderItemForm: FormGroup = new FormGroup({
    Id: new FormControl(),
    ItemId: new FormControl(),
    //UnitId: new FormControl(),
    Quantity: new FormControl(),
    UnitCost: new FormControl(),
    TotalPrice: new FormControl(),
  });
  createPurchaseOrderItemForm(purchaseOrderItemObject, formBuilder): FormGroup {
    return formBuilder.group({
      Id: [purchaseOrderItemObject.Id >= 0 ? purchaseOrderItemObject.Id : 0],
      ItemId: [null ,Validators.compose([Validators.required])],
      Quantity: [null ,Validators.compose([Validators.required, Validators.pattern(/^([1-9]+)([0-9])*(\.[0-9]+)?$/)])],
      UnitCost: [null ,Validators.compose([Validators.required, Validators.pattern(/^([1-9]+)([0-9])*(\.[0-9]+)?$/)])],
      
      //Id: [purchaseOrderItemObject.Id >= 0 ? purchaseOrderItemObject.Id : 0],
      //ItemsHeaderId: [purchaseOrderItemObject.ItemsHeaderId, Validators.compose([Validators.required])],
      //Quantity: [purchaseOrderItemObject.Quantity, Validators.compose([Validators.required, Validators.pattern(/^([1-9]+)([0-9])*(\.[0-9]+)?$/)])],
      //Price: [purchaseOrderItemObject.Price, Validators.compose([Validators.required, Validators.pattern(/^([1-9]+)([0-9])*(\.[0-9]+)?$/)])],
    });
  }
  newPurchaseOrderItem: PurchaseOrderItemModel = new PurchaseOrderItemModel();
  newPurchaseOrderItemInternalId = 0;

  addPurchaseOrderItem(): any {

    let temp = new PurchaseOrderItemModel({
      Id: this.newPurchaseOrderItemInternalId,
      ItemId: this.purchaseOrderItemForm.getRawValue().ItemId,
      UnitId: this.newPurchaseOrderItem.UnitId,
      Quantity: this.purchaseOrderItemForm.getRawValue().Quantity,
      UnitCost: this.purchaseOrderItemForm.getRawValue().UnitCost,
      TotalPrice: this.purchaseOrderItemForm.getRawValue().UnitCost * this.purchaseOrderItemForm.getRawValue().Quantity,
    });
    let tempSourceItem = this.getItemById(this.purchaseOrderItemForm.getRawValue().ItemId);
    // let tempSourceUnit = this.getItemById(this.newPurchaseOrderItem.UnitId);
    temp.ItemNameAr = tempSourceItem.Name;
    temp.ItemNameEn = tempSourceItem.Name;
    // temp.UnitNameAr = tempSourceUnit.NameAr;
    // temp.UnitNameEn = tempSourceUnit.NameEn;
    
    
    this.addedItemList = this.addedItemList.concat(temp)
    this.newPurchaseOrderItemInternalId = this.newPurchaseOrderItemInternalId + 1;

    console.log("added item", temp);
    this.newPurchaseOrderItem = new PurchaseOrderItemModel();

    
    this.purchaseOrderItemForm = this.createPurchaseOrderItemForm(this.newPurchaseOrderItem, this.formBuilder);
    
    this.calculateTotalItemValue();
  }
  removePurchaseOrderItem(Id: any): any {

    let index = this.addedItemList.findIndex(x => x.Id === Id);
    this.addedItemList.splice(index, 1);

    this.calculateTotalItemValue();
  }
  changeItem(): any {
    debugger;
    let tempSourceItem = this.getItemById(this.purchaseOrderItemForm.getRawValue().ItemId);
    // let tempSourceUnit = this.getUnityById(tempSourceItem.CodingUnitId);
    this.newPurchaseOrderItem.ItemId = this.purchaseOrderItemForm.getRawValue().ItemId;
    this.newPurchaseOrderItem.UnitId = null // this.purchaseOrderItemForm.getRawValue().ItemId>0?tempSourceUnit.Id:0;
    this.newPurchaseOrderItem.ItemNameAr = tempSourceItem.Name;
    this.newPurchaseOrderItem.ItemNameEn = tempSourceItem.Name;
    // this.newPurchaseOrderItem.UnitNameAr = tempSourceUnit.NameAr;
    // this.newPurchaseOrderItem.UnitNameEn = tempSourceUnit.NameEn;
    this.calculateTotalItemValue();
  }
  currentTotal = 0;
  calculateTotalItemValue() {
    let total = 0;
    this.addedItemList.forEach(item => {
      total += item.TotalPrice;
    });
    this.currentTotal = total;
    return total;
  }
  goBack(){
    console.log("back");
    
    this.modelObject = {};
    this.addedItemList = [];
    this.currentTotal = 0;
    this.newPurchaseOrderItem = {};
    this.currentAction = "";
    this.tableView = true;

    
    console.log(this.modelObject);
    console.log(this.lastCode);
  }
  ////////////////////////////////////////////////////////////////////////////
  //
  //
  //
  //
  //
  ////////////////////////////////////////////////////////////////////////////
  //  CONSTANT :
  //  Do not change.
  ///////////////////////////////
  prepChangeSort(sortKey) {
    this.SearchFrom = null;
    this.SearchTo = null;
    
    
    this.tableEngine.changeSort(this.tableEngine.getSort(sortKey));
    this.itemOrder = this.tableEngine.itemOrder;
  }

  getFormattedDate(dateString: any){
    let splitDate: number[] = dateString.toString().split("/");

    return new Date(splitDate[2], splitDate[1]-1, splitDate[0])
  }
  ///////////////////////////////////////////////////////
  
  displayMode = 'list';
  changeDisplayMode(mode): void {
    this.displayMode = mode;
  }
  itemsPerPageChange(perPage: number): void {
    this.tableEngine.paginateData(perPage, 1)
  }
}
