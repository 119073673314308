export class CreditTreasuryAndBankRolesEmployeesModel{
    Id?: number;
    TreasuryAndBankAccountId?: number;
    EmployeeId?: number;
    constructor(item?:CreditTreasuryAndBankRolesEmployeesModel){
        item = item ? item : {};
        this.Id = item.Id ? item.Id : 0;
        this.TreasuryAndBankAccountId = item.TreasuryAndBankAccountId ? item.TreasuryAndBankAccountId : 0;
        this.EmployeeId = item.EmployeeId ? item.EmployeeId : 0;
    }
}