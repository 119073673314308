import { BranchModel } from './../../../../test-component/BranchModel';
import { IncomeListDataModel } from './../../models/income-list-data.model';
import { IncomeListSearchModel } from './../../models/income-list-search.model';
import { Component, OnInit } from '@angular/core';
import { IncomeListService } from '../../income-list.service'
import { BranchService } from 'src/app/invoices/branches/BranchService';
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';


@Component({
    selector: 'income-list',
    templateUrl: 'income-list.component.html',
    styleUrls: ['income-list.component.scss']
})
export class IncomeListComponent implements OnInit{
    declare lang: any;
    data:IncomeListDataModel;
    search:IncomeListSearchModel={};
    constructor(private IncomeListService:IncomeListService,private branchService:BranchService){
      this.lang = localStorage.getItem('theme_lang');
    }
    TotalAssets = 0;
    Totalliabilities = 0;
    TotalPropertyRights = 0;
    branches:BranchModel[]=[];
    today=new Date();
    totalIncomes=0
    totalOutComes=0
    ngOnInit(): void {
      debugger;
        this.IncomeListService.Search(this.search)
        .subscribe(data => {
          this.data =data;
          data.Incomes.forEach(element => {
            if(element.Value == null || element.Value == undefined ){
              element.Value = 0;
            }
            this.totalIncomes += element.Value;
          });
          data.Expenses.forEach(element => {
            if(element.Value == null || element.Value == undefined ){
              element.Value = 0;
            }
            this.totalOutComes += element.Value;
          });
          console.log('data',this.data)
        })
        this.branchService.getAll()
        .subscribe(branches=>{
          this.branches = branches;
        })
      
    }
    Search(){
        this.IncomeListService.Search(this.search)
        .subscribe(data => {
          this.data =data;
       
        })
    }
    exportTable(){
      var data = document.getElementById('contentToConvert');
      data.style.display = 'inline-block';
      html2canvas(data,{}).then(canvas => {
      var imgWidth = 208;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save('new-file.pdf'); // Generated PDF
      data.style.display = 'block';

    //   data.style.display = 'none';
      
    });
    }
    print(){
        let printContents, popupWin;
        printContents = document.getElementById('contentToConvert').innerHTML;
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.open();
        window.print();
       
      }
}
