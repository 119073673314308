import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PaymentMethodModel } from "./PaymentMethodModel";

@Injectable({
  providedIn: 'root'
})
export class PaymentMethodService {
  constructor(private http: HttpClient) {

  }
 
 
  debugData: PaymentMethodModel [] = [
    {Id:1, NameAr: "وسيلة دفع 1", NameEn: "Payment Method 1"},
    {Id:2, NameAr: "وسيلة دفع 2", NameEn: "Payment Method 2"},
    {Id:3, NameAr: "وسيلة دفع 3", NameEn: "Payment Method 3"},
    {Id:4, NameAr: "وسيلة دفع 4", NameEn: "Payment Method 4"},
    {Id:5, NameAr: "وسيلة دفع 5", NameEn: "Payment Method 5"},
    {Id:6, NameAr: "وسيلة دفع 6", NameEn: "Payment Method 6"},
    {Id:7, NameAr: "وسيلة دفع 7", NameEn: "Payment Method 7"},
    {Id:8, NameAr: "وسيلة دفع 8", NameEn: "Payment Method 8"},
    {Id:9, NameAr: "وسيلة دفع 9", NameEn: "Payment Method 9"},
    {Id:10, NameAr: "وسيلة دفع 10", NameEn: "Payment Method 10"},
  ];
  debugMode: boolean = false;

  baseUrl = environment.baseApiUrl;
  // baseUrl = 'http://localhost:60289/';
   token = localStorage.getItem("token");
   httpOptions = {
    headers: new HttpHeaders({
      // 'Content-Type': 'application/json; charset=utf-8',
      // 'Authorization': "Bearer " + token,
       "Authorization": "Bearer " + this.token,
       "Content-Type": "application/json"
    })
  };
  getAll(): Observable<any> {
    if(this.debugMode){
      return of(this.debugData)
    }
    return this.http
      .get<any>(`${this.baseUrl}PaymentMethod/GetAll`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error => error)));
  }
  getById(id:number): Observable<any> {
    if(this.debugMode){
      return of(this.debugData.find(x=>x.Id==id))
    }
    return this.http
      .get<any>(`${this.baseUrl}PaymentMethod/GetById/${id}`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error => error)));
  }
  insert(payload: PaymentMethodModel): Observable<any> {
    if(this.debugMode){
      this.debugData.push(payload);
      return of(true)
    }
    return this.http
      .post<any>(`${this.baseUrl}PaymentMethod/Insert`, payload,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error => error)));
  }

  update(payload: PaymentMethodModel): Observable<any> {
    if(this.debugMode){
      this.debugData[this.debugData.findIndex(x=>x.Id==payload.Id)] = payload;
      return of(true)
    }
    return this.http
      .post<any>(`${this.baseUrl}PaymentMethod/Update`, payload,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error => error)));
  }

  delete(payload: PaymentMethodModel): Observable<any> {
    if(this.debugMode){
      this.debugData.splice(this.debugData.findIndex(x=>x.Id==payload.Id),1);
      return of(true)
    }
    return this.http
      .get<any>(`${this.baseUrl}PaymentMethod/Delete/${payload.Id}`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error => error)));
  }

  deleteList(payload: PaymentMethodModel []): Observable<any> {
    if(this.debugMode){
      payload.forEach(element => {
        this.debugData.splice(this.debugData.findIndex(x=>x.Id==element.Id),1);
      });
      return of(true)
    }
    return this.http
      .post<any>(`${this.baseUrl}PaymentMethod/DeleteList`,JSON.stringify(payload),this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error => error)));
  }

}
