<div class="row">
  <!-- Larger screen layout -->
  <div class="col-12 d-none d-md-block">
    <div class="card mb-3 table-heading">
      <div class="d-flex flex-grow-1 min-width-zero">
        <div
          class="card-body align-self-center d-flex flex-column flex-md-row justify-content-between min-width-zero align-items-md-center">
          <p class="list-item-heading mb-0 truncate w-40 w-xs-100"></p>
          <p class="mb-0 text-primary w-20 w-xs-100 text-center">{{ 'pages.prices.developer' | translate }}</p>
          <p class="mb-0 text-primary w-20 w-xs-100 text-center">{{ 'pages.prices.team' | translate }}</p>
          <p class="mb-0 text-primary w-20 w-xs-100 text-center">{{ 'pages.prices.enterprise' | translate }}</p>
        </div>
      </div>
    </div>
    <div class="card flex flex-row mb-3">
      <div class="d-flex flex-grow-1 min-width-zero">
        <div
          class="card-body align-self-center d-flex flex-column flex-md-row justify-content-between min-width-zero align-items-md-center">
          <p class="list-item-heading mb-0 truncate w-40 w-xs-100">
            {{ 'pages.prices.twofactorauthentication' | translate }}
          </p>
          <p class="mb-0 text-primary w-20 w-xs-100 text-center">
            <i class="simple-icon-check"></i>
          </p>
          <p class="mb-0 text-primary w-20 w-xs-100 text-center">
            <i class="simple-icon-check"></i>
          </p>
          <p class="mb-0 text-primary w-20 w-xs-100 text-center">
            <i class="simple-icon-check"></i>
          </p>
        </div>
      </div>
    </div>
    <div class="card flex flex-row mb-3">
      <div class="d-flex flex-grow-1 min-width-zero">
        <div
          class="card-body align-self-center d-flex flex-column flex-md-row justify-content-between min-width-zero align-items-md-center">
          <p class="list-item-heading mb-0 truncate w-40 w-xs-100">
            {{ 'pages.prices.teampermissions' | translate }}
          </p>
          <p class="mb-0 text-primary w-20 w-xs-100 text-center">
          </p>
          <p class="mb-0 text-primary w-20 w-xs-100 text-center">
            <i class="simple-icon-check"></i>
          </p>
          <p class="mb-0 text-primary w-20 w-xs-100 text-center">
            <i class="simple-icon-check"></i>
          </p>
        </div>
      </div>
    </div>
    <div class="card flex flex-row mb-3">
      <div class="d-flex flex-grow-1 min-width-zero">
        <div
          class="card-body align-self-center d-flex flex-column flex-md-row justify-content-between min-width-zero align-items-md-center">
          <p class="list-item-heading mb-0 truncate w-40 w-xs-100">
            {{ 'pages.prices.245Support' | translate }}
          </p>
          <p class="mb-0 text-primary w-20 w-xs-100 text-center">
          </p>
          <p class="mb-0 text-primary w-20 w-xs-100 text-center">
            <i class="simple-icon-check"></i>
          </p>
          <p class="mb-0 text-primary w-20 w-xs-100 text-center">
          </p>
        </div>
      </div>
    </div>
    <div class="card flex flex-row mb-3">
      <div class="d-flex flex-grow-1 min-width-zero">
        <div
          class="card-body align-self-center d-flex flex-column flex-md-row justify-content-between min-width-zero align-items-md-center">
          <p class="list-item-heading mb-0 truncate w-40 w-xs-100">
            {{ 'pages.prices.247Support' | translate }}
          </p>
          <p class="mb-0 text-primary w-20 w-xs-100 text-center">
          </p>
          <p class="mb-0 text-primary w-20 w-xs-100 text-center">
          </p>
          <p class="mb-0 text-primary w-20 w-xs-100 text-center">
            <i class="simple-icon-check"></i>
          </p>
        </div>
      </div>
    </div>
    <div class="card flex flex-row mb-3">
      <div class="d-flex flex-grow-1 min-width-zero">
        <div
          class="card-body align-self-center d-flex flex-column flex-md-row justify-content-between min-width-zero align-items-md-center">
          <p class="list-item-heading mb-0 truncate w-40 w-xs-100">
            {{ 'pages.prices.useractionsauditlog' | translate }}
          </p>
          <p class="mb-0 text-primary w-20 w-xs-100 text-center">
          </p>
          <p class="mb-0 text-primary w-20 w-xs-100 text-center">
          </p>
          <p class="mb-0 text-primary w-20 w-xs-100 text-center">
            <i class="simple-icon-check"></i>
          </p>
        </div>
      </div>
    </div>
  </div>

  <!-- Smaller screen layout -->
  <div class="col-12 d-block d-md-none">
    <div class="card d-flex flex-row mb-3 table-heading">
      <div class="d-flex flex-grow-1 min-width-zero">
        <div class="card-body pl-0 pb-0">
          <p class="list-item-heading mb-0 text-primary">{{ 'pages.prices.twofactorauthentication' | translate }}</p>
        </div>
      </div>
    </div>
    <div class="card d-flex flex-row mb-3">
      <div class="d-flex flex-grow-1 min-width-zero">
        <div class="card-body align-self-center d-flex flex-row">
          <p class="list-item-heading mb-0 truncate w-70">
            {{ 'pages.prices.developer' | translate }}
          </p>
          <p class="text-primary text-right mb-0 w-30 text-one">
            <i class="simple-icon-check"></i>
          </p>
        </div>
      </div>
    </div>
    <div class="card d-flex flex-row mb-3">
      <div class="d-flex flex-grow-1 min-width-zero">
        <div class="card-body align-self-center d-flex flex-row">
          <p class="list-item-heading mb-0 truncate w-70">
            {{ 'pages.prices.team' | translate }}
          </p>
          <p class="text-primary text-right mb-0 w-30 text-one">
            <i class="simple-icon-check"></i>
          </p>
        </div>
      </div>
    </div>
    <div class="card d-flex flex-row mb-3">
      <div class="d-flex flex-grow-1 min-width-zero">
        <div class="card-body align-self-center d-flex flex-row">
          <p class="list-item-heading mb-0 truncate w-70">
            {{ 'pages.prices.enterprise' | translate }}
          </p>
          <p class="text-primary text-right mb-0 w-30 text-one">
            <i class="simple-icon-check"></i>
          </p>
        </div>
      </div>
    </div>
    <div class="card d-flex flex-row mb-3 table-heading">
      <div class="d-flex flex-grow-1 min-width-zero">
        <div class="card-body pl-0 pb-0">
          <p class="list-item-heading mb-0 text-primary">{{ 'pages.prices.teampermissions' | translate }}</p>
        </div>
      </div>
    </div>
    <div class="card d-flex flex-row mb-3">
      <div class="d-flex flex-grow-1 min-width-zero">
        <div class="card-body align-self-center d-flex flex-row">
          <p class="list-item-heading mb-0 truncate w-70">
            {{ 'pages.prices.developer' | translate }}
          </p>
          <p class="text-primary text-right mb-0 w-30 text-one">
          </p>
        </div>
      </div>
    </div>
    <div class="card d-flex flex-row mb-3">
      <div class="d-flex flex-grow-1 min-width-zero">
        <div class="card-body align-self-center d-flex flex-row">
          <p class="list-item-heading mb-0 truncate w-70">
            {{ 'pages.prices.team' | translate }}
          </p>
          <p class="text-primary text-right mb-0 w-30 text-one">
            <i class="simple-icon-check"></i>
          </p>
        </div>
      </div>
    </div>
    <div class="card d-flex flex-row mb-3">
      <div class="d-flex flex-grow-1 min-width-zero">
        <div class="card-body align-self-center d-flex flex-row">
          <p class="list-item-heading mb-0 truncate w-70">
            {{ 'pages.prices.enterprise' | translate }}
          </p>
          <p class="text-primary text-right mb-0 w-30 text-one">
            <i class="simple-icon-check"></i>
          </p>
        </div>
      </div>
    </div>
    <div class="card d-flex flex-row mb-3 table-heading">
      <div class="d-flex flex-grow-1 min-width-zero">
        <div class="card-body pl-0 pb-0">
          <p class="list-item-heading mb-0 text-primary">{{ 'pages.prices.245Support' | translate }}</p>
        </div>
      </div>
    </div>
    <div class="card d-flex flex-row mb-3">
      <div class="d-flex flex-grow-1 min-width-zero">
        <div class="card-body align-self-center d-flex flex-row">
          <p class="list-item-heading mb-0 truncate w-70">
            {{ 'pages.prices.developer' | translate }}
          </p>
          <p class="text-primary text-right mb-0 w-30 text-one">
          </p>
        </div>
      </div>
    </div>
    <div class="card d-flex flex-row mb-3">
      <div class="d-flex flex-grow-1 min-width-zero">
        <div class="card-body align-self-center d-flex flex-row">
          <p class="list-item-heading mb-0 truncate w-70">
            {{ 'pages.prices.team' | translate }}
          </p>
          <p class="text-primary text-right mb-0 w-30 text-one">
            <i class="simple-icon-check"></i>
          </p>
        </div>
      </div>
    </div>
    <div class="card d-flex flex-row mb-3">
      <div class="d-flex flex-grow-1 min-width-zero">
        <div class="card-body align-self-center d-flex flex-row">
          <p class="list-item-heading mb-0 truncate w-70">
            {{ 'pages.prices.enterprise' | translate }}
          </p>
          <p class="text-primary text-right mb-0 w-30 text-one">
          </p>
        </div>
      </div>
    </div>
    <div class="card d-flex flex-row mb-3 table-heading">
      <div class="d-flex flex-grow-1 min-width-zero">
        <div class="card-body pl-0 pb-0">
          <p class="list-item-heading mb-0 text-primary">{{ 'pages.prices.247Support' | translate }}</p>
        </div>
      </div>
    </div>
    <div class="card d-flex flex-row mb-3">
      <div class="d-flex flex-grow-1 min-width-zero">
        <div class="card-body align-self-center d-flex flex-row">
          <p class="list-item-heading mb-0 truncate w-70">
            {{ 'pages.prices.developer' | translate }}
          </p>
          <p class="text-primary text-right mb-0 w-30 text-one">
          </p>
        </div>
      </div>
    </div>
    <div class="card d-flex flex-row mb-3">
      <div class="d-flex flex-grow-1 min-width-zero">
        <div class="card-body align-self-center d-flex flex-row">
          <p class="list-item-heading mb-0 truncate w-70">
            {{ 'pages.prices.team' | translate }}
          </p>
          <p class="text-primary text-right mb-0 w-30 text-one">
          </p>
        </div>
      </div>
    </div>
    <div class="card d-flex flex-row mb-3">
      <div class="d-flex flex-grow-1 min-width-zero">
        <div class="card-body align-self-center d-flex flex-row">
          <p class="list-item-heading mb-0 truncate w-70">
            {{ 'pages.prices.enterprise' | translate }}
          </p>
          <p class="text-primary text-right mb-0 w-30 text-one">
            <i class="simple-icon-check"></i>
          </p>
        </div>
      </div>
    </div>
    <div class="card d-flex flex-row mb-3 table-heading">
      <div class="d-flex flex-grow-1 min-width-zero">
        <div class="card-body pl-0 pb-0">
          <p class="list-item-heading mb-0 text-primary">{{ 'pages.prices.useractionsauditlog' | translate }}</p>
        </div>
      </div>
    </div>
    <div class="card d-flex flex-row mb-3">
      <div class="d-flex flex-grow-1 min-width-zero">
        <div class="card-body align-self-center d-flex flex-row">
          <p class="list-item-heading mb-0 truncate w-70">
            {{ 'pages.prices.developer' | translate }}
          </p>
          <p class="text-primary text-right mb-0 w-30 text-one">
          </p>
        </div>
      </div>
    </div>
    <div class="card d-flex flex-row mb-3">
      <div class="d-flex flex-grow-1 min-width-zero">
        <div class="card-body align-self-center d-flex flex-row">
          <p class="list-item-heading mb-0 truncate w-70">
            {{ 'pages.prices.team' | translate }}
          </p>
          <p class="text-primary text-right mb-0 w-30 text-one">
          </p>
        </div>
      </div>
    </div>
    <div class="card d-flex flex-row mb-3">
      <div class="d-flex flex-grow-1 min-width-zero">
        <div class="card-body align-self-center d-flex flex-row">
          <p class="list-item-heading mb-0 truncate w-70">
            {{ 'pages.prices.enterprise' | translate }}
          </p>
          <p class="text-primary text-right mb-0 w-30 text-one">
            <i class="simple-icon-check"></i>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
