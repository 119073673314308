<!--component html goes here -->

<div style="text-align: center;" *ngIf="HeaderFormGroup">
    <a class="btn btn-light" 
    routerLink="../../all-daily"
        style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Back" |
        translate}}</a>
    <button 
        *ngIf="HeaderFormGroup"
         id="smallModalSubmitButton"
        class="btn btn-secondary" 
        (click)="onClick()"
        [disabled]="HeaderFormGroup.invalid || TotalCreditor != TotalDebtor"
        style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{buttonName |
        translate}}</button>
        <button 
        *ngIf="HeaderFormGroup"
        [disabled]="HeaderFormGroup.invalid || TotalCreditor != TotalDebtor"
        id="smallModalSubmitButton"
        class="btn btn-secondary" (click)="addAndNew()"
        style="font-weight: bold; width: 250px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{buttonName+"AndNew" |
        translate}}</button>
  </div>
<Form [formGroup]="HeaderFormGroup" *ngIf="HeaderFormGroup">
    <div class="card">
        <div class="form-group  Card_Padding">
                    <br>
    
       <div class="row">
        <div class="col-md-10">
        <h3>{{ 'Dailyrestrictions' | translate }}</h3>
    
        </div>
    
        <!-- <button type="button" class="btn btn-primary"(click)="onClick()">{{ 'Save' | translate }}</button>{{' '}} -->
    
       </div>
            <br>
            <div class="row">
                <div class="col-md-4">
                    <label for="inputEmail4">{{ 'Code' | translate }}</label>
                    <input (change)="ChckCode()" formControlName="Code" type="number" id="inputEmail4" class="form-control">
                    <div class="messages text-danger"
                    *ngIf=" (HeaderFormGroup.get('Code').hasError('required') ) && ( HeaderFormGroup.get('Code').dirty ||  HeaderFormGroup.get('Code').touched)">
                    {{"Code Required" | translate}}</div>
                    <div class="messages text-danger"
                    *ngIf="HeaderFormGroup.get('Code').hasError('incorrect')  && ( HeaderFormGroup.get('Code').dirty ||  HeaderFormGroup.get('Code').touched)">
                    {{"There is an entry with the same number, please enter another number" | translate}}</div>
                </div>
                <div class="col-md-4">
                    <label for="inputEmail4">{{ 'Notebook Reference' | translate }}</label>
                    <input formControlName="NotebookReference" type="number" id="inputEmail4" class="form-control">
                </div>
                <div class="col-md-4">
                    <label for="inputEmail4">{{ 'Movement Number' | translate }}</label>
                    <input formControlName="MovementNumber" type="number" id="inputEmail4" class="form-control">
                </div>
            </div>
        <br />

            <div class="row">
                <!-- <div class=" col-md-4">
                    <label for="inputState">{{ 'Account Name' | translate }}</label>
                    <select  formControlName="HeaderAccountNameDropdwon" id="inputState" class="form-control">
                        <option *ngFor="let Item of HeaderAccountNameList" [value]="Item.Id" >{{lang==='ar-EG'?Item.NameAr:Item.NameEn}}</option>
        
                    </select>
                </div> -->
                <div class=" col-md-4">
                    <label for="inputState">{{ 'Choose Banck' | translate }}</label>
                    <select formControlName="HeaderBankCodeDropdwon" id="inputState" [searchable]="true" class="form-control">
                        <option selected disabled value="0">{{'Choose Banck'|translate}}</option>
                        <option *ngFor="let Item of BankList" [value]="Item.Id" >{{Item.AccountName}}</option>
                    </select>
                </div>
                <div class=" col-md-4">
                    <label for="inputState">{{ 'Currency' | translate }}</label>
                    <select formControlName="HeaderCurrencyDropdwon" id="inputState" class="form-control">
                        <option selected disabled value="0">{{'Choose Currency'|translate}}</option>
                        <option *ngFor="let Item of CurrencyList" [value]="Item.Id" >{{lang==='ar-EG'?Item.NameAr:Item.NameEn}}</option>
                    </select>
                </div>
                <div class="col-md-4">
                    <label for="inputEmail4">{{ 'Date' | translate }}</label>
                    <input formControlName="Date" type="date" id="inputEmail4" class="form-control">
                </div>
         
                
            </div>
          
            <br>
            <div class="form-row">
                <div class=" col-md-12">
                    <label for="inputState">{{ 'Description' | translate }}</label>
                    <textarea class="form-control" formControlName="HeaderDescription" rows="2"></textarea>
                </div>
            </div>
        
        <br />
        <div class="row">
            <div class="col-md-4" style="margin-top: 30px;">
                <div class="itemCheck mb-0 custom-checkbox custom-control" style="display: inline-block;">
                    <input type="checkbox" class="custom-control-input" [id]="'checkbox'"
                    formControlName="PeriodicEntry"  >
                    <label class="custom-control-label" [for]="'checkbox'">{{'Periodic Entry'|translate}}</label>
                </div>
            </div>
            <div class="col-md-4" *ngIf="HeaderFormGroup.controls['PeriodicEntry'].value">
                <label for="inputState">{{ 'How the entry works' | translate }}</label>
                <select formControlName="PeriodicType" id="inputState" class="form-control">
                    <option selected disabled  [value]="''" >{{'Choose...'|translate}}</option>
                    <option  [value]="Daily" >{{'Daily'|translate}}</option>
                    <option  [value]="Weekly" >{{'Weekly'|translate}}</option>
                    <option  [value]="Monthly" >{{'Monthly'|translate}}</option>
                    <option  [value]="Midterm" >{{'Midterm'|translate}}</option>
                    <option  [value]="Quarterly" >{{'Quarterly'|translate}}</option>
                    <option  [value]="Annual" >{{'Annual'|translate}}</option>
                </select>
            </div>
            <div class="col-md-4"*ngIf="HeaderFormGroup.controls['PeriodicEntry'].value">
                <label for="inputEmail4">{{ 'start Date' | translate }}</label>
                <input formControlName="startDate" type="date" id="inputEmail4" class="form-control">
            </div>
        </div>
        </div>
    
    </div>
</Form>
    <br>
    <br>
    <div class="card">
        <div class="card-body">
            <!-- 
                [bindValue]="Id" 
    
                   (add)="OnAddSelectedItemTax($event)"
            (remove)="OnRemoveSelectedItemTax($event)"
                                (remove)="OnRemoveSelectedItemFunc($event)"
                (add)="OnAddSelectedItemFunc($event)"

                <h5 class="card-title">Basic Table</h5> -->
            <!-- <div class="col-12 row">
                <div class=" col-md-11">
                </div>
            <button type="button" class="btn btn-primary"(click)="AddonClick()">{{ '+ Add' | translate }}</button>{{' '}}

              </div> -->
         
            <!-- <br> -->

            <table class="table">
                <thead>
                    <tr>
                        <th scope="col" >#</th>
                        <th scope="col" >{{'Account'| translate}}</th>
                        <th scope="col" >{{'Description'| translate}}</th>
                        <th scope="col" >{{'Debit'| translate}}</th>
                        <th scope="col" >{{'Credit'| translate}}</th>
                        <th scope="col" >{{'Delete'| translate}}</th>
                        <th scope="col" >{{'Assign Cost Center'| translate}}</th>
                        <th scope="col" >{{'Add Tax'| translate}}</th>
                        
                    </tr>
                </thead>
                <tbody>
                    <!-- [(ngModel)]="item.quantity" +
                        [(ngModel)]="item.discount"
                        <div class="glyph ng-star-inserted">
                            <div class="glyph-icon simple-icon-plus"></div><div class="class-name">simple-icon-plus</div></div>
                    
                    *ngFor=" let item of ItemModelList"
                     (change)="changeDescount(item)"
                     formControlName="quantity" 
                        -->
                    
                    <tr  *ngFor="let Item of SelectedDailyrestrictionsFooterModel; let i=index;">
                        <th style="width: 5%;" scope="row">{{i+1}}</th>
                        <!-- <td>{{Item.NameEn}}</td> -->
                        <td style="width: 20%;">
                            <ng-select  *ngIf="lang==='ar-EG'"  [(ngModel)]="Item.AccountNameId" bindValue="Id"[items]="AccountsNameModel" (change)="splitAccount(Item,$event)"  [hideSelected]="true"  bindLabel="NameAr">
                            </ng-select>
                            <ng-select *ngIf="lang!=='ar-EG'"  [(ngModel)]="Item.AccountNameId" bindValue="Id"[items]="AccountsNameModel" (change)="splitAccount(Item,$event)"  [hideSelected]="true"  bindLabel="NameEn">
                          </ng-select>
                            <!-- <select  id="inputState" class="form-control">
                                <option *ngFor="let Item of FooterAccountNameList" value="Item.Id" >{{Item.NameEn}}</option>
                            </select> -->
                        </td>
                        <td style="width: 30%;">
                            <input [(ngModel)]="Item.Description" type="text" class="form-control">
                        </td>
                        <td style="width: 20%;"><input  [(ngModel)]="Item.Debit" (change)="changeDebtor(Item,$event)"type="number" class="form-control"></td>
                        <td style="width: 20%;"><input  [(ngModel)]="Item.Credit" (change)="changeCredit(Item,$event)"  type="number" class="form-control "></td>
                        <!-- <td>
                      
                        <ng-select  *ngIf="lang==='ar-EG'"  [(ngModel)]="Item.CostCenterId1" bindValue="Id"[items]="costCenters1" (change)="splitCenter($event)"  [hideSelected]="true"  bindLabel="NameAr">
                        </ng-select>
                        <ng-select *ngIf="lang!=='ar-EG'"  [(ngModel)]="Item.CostCenterId1" bindValue="Id"[items]="costCenters1" (change)="splitCenter($event)"  [hideSelected]="true"  bindLabel="NameEn">
                      </ng-select>
                    
                        </td> -->
                        <!-- <td>
                            <ng-select  *ngIf="lang==='ar-EG'"  [(ngModel)]="Item.CostCenterId2" bindValue="Id"[items]="costCenters1" (change)="splitCenter($event)"  [hideSelected]="true"  bindLabel="NameAr">
                          </ng-select>
                          <ng-select *ngIf="lang!=='ar-EG'"  [(ngModel)]="Item.CostCenterId2" bindValue="Id"[items]="costCenters1" (change)="splitCenter($event)"  [hideSelected]="true"  bindLabel="NameEn">
                        </ng-select>
                        </td> -->
                        <td style="width: 10%;">
                            <a href="javascript:" tooltip="{{'Delete'|translate}}" (click)="deleteSelectedAccount(Item)"><i class="simple-icon-trash"></i></a>
                        </td>
                        <td style="width: 10%;">
                            <a href="javascript:" tooltip="{{'Assign Cost Center'|translate}}" [disabled]="Item.AccountNameId == 0" (click)="AssignCostCenter(Item)"><i class="iconsminds-align-center"></i></a>
                        </td>
                        <td style="width: 10%;">
                            <a href="javascript:" tooltip="{{'Add Tax'|translate}}" [disabled]="Item.AccountNameId == 0 || taxes.length == 0"  (click)="AssignTax(Item)"><i class="iconsminds-add"></i></a>
                        </td>
                    </tr>
                    <!-- <td>{{'Tota'}}</td>
                    <td>{{TotalDebtor}}</td>
                    <td>{{TotalCreditor}}</td> -->
                  
                </tbody>
            </table>

            <div class="col-12 row">
                <div class=" col-md-11">
                </div>
            <button type="button" class="btn btn-primary"(click)="AddonClick()">{{ '+ Add' | translate }}</button>{{' '}}

              </div>
            <br>
            <table class="table">
                <thead>
                    <tr>
                        <th scope="row"></th>
                        <td>{{'Debtor' | translate}}</td>
                        <td>{{'Creditor' | translate}}</td>

                    </tr>
                </thead>
                <tbody>
                    
                    <tr>
                        <th style="width: 40%;" scope="row">{{ 'Total' | translate }}</th>
                        <td style="width: 20%;"  scope="row">{{TotalDebtor}}</td>
                        <td style="width: 20%;"  scope="row">{{TotalCreditor}}</td>
                    </tr>
                </tbody>
            </table>

        </div>
    
    </div>
    
    <div class="card">
        
        <div class="card-body">
            
            <label for="inputEmail4">{{ 'Documents' | translate }}</label>
            <upload-journal-file [(url)]=fileUrl (urlChange)="setFileToList()" [editmode]=editEnabled></upload-journal-file>
            <table class="table">
               <tbody>
                <tr *ngFor="let file of files;let i = index">
                    <td>#{{i+1}}</td>
                    <td><a href="{{file.FileUrl}}" target="_blank" style="cursor: pointer;" download="">{{"Download"|translate}}</a></td>
                    <td>
                        <a href="javascript:" (click)="deleteFile(file)"><i class="simple-icon-trash"></i></a>
                    </td>
                </tr>
               </tbody>
            </table>
        </div>
    
    </div>

    <ng-template #template>
        <div class="modal-header">
          <!-- <h4 class="modal-title pull-left">{{'modal.modal-title' | translate}}</h4> -->
          <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div class="card" style="margin-top: 20px;">
     
       
                <div class="card-body" *ngIf="SelectedDailyrestrictionsFooterModelForModal.addCostCenters">
                    <div class="row" style="text-align: center;">
                        <div class="col-md-10" style="text-align: center;">
                            <th>{{getAccountName(SelectedDailyrestrictionsFooterModelForModal.AccountNameId)}}</th>
                        <h3 *ngIf="SelectedDailyrestrictionsFooterModelForModal.Debit>0" style="text-align: center;">{{ 'Debit' | translate }}:{{SelectedDailyrestrictionsFooterModelForModal.Debit}}</h3>
                        <h3 *ngIf="SelectedDailyrestrictionsFooterModelForModal.Credit>0" style="text-align: center;">{{ 'Credit' | translate }}:{{SelectedDailyrestrictionsFooterModelForModal.Credit}}</h3>
                        </div>
                        <!-- <button type="button" class="btn btn-primary"(click)="onClick()">{{ 'Save' | translate }}</button>{{' '}} -->
                       </div>
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">{{'Cost Center'| translate}}</th>
                                <th scope="col">{{'Percent'| translate}}</th>
                                <th scope="col">{{'Amount'| translate}}</th>
                                <th scope="col">{{'Delete'| translate}}</th>
             
                            </tr>
                        </thead>
                        <tbody>
                            <tr  *ngFor="let cost of SelectedDailyrestrictionsFooterModelForModal.CostCenters; let i=index;">
                                <th style="width: 10%;" scope="row">{{i + 1}}</th>
                                <td style="width: 30%;">
                                    <ng-select  *ngIf="lang==='ar-EG'"  [(ngModel)]="cost.CostCenterId" bindValue="Id"[items]="SelectedDailyrestrictionsFooterModelForModal.centers"   [hideSelected]="true"  bindLabel="NameAr">
                                    </ng-select>
                                    <ng-select *ngIf="lang!=='ar-EG'"  [(ngModel)]="cost.CostCenterId" bindValue="Id"[items]="SelectedDailyrestrictionsFooterModelForModal.centers"  [hideSelected]="true"  bindLabel="NameEn">
                                  </ng-select>
                                </td>
                        
                                <td style="width: 20%;"><input [(ngModel)]="cost.Percent" (change)="changePercent(SelectedDailyrestrictionsFooterModelForModal,cost,$event)"type="number" class="form-control"></td>
                                <td style="width: 20%;"><input [(ngModel)]="cost.Amount" (change)="changeAmount(SelectedDailyrestrictionsFooterModelForModal,cost,$event)"  type="number" class="form-control "></td>
                                <td style="width: 20%;">
                                    <a href="javascript:" tooltip="{{'Delete'|translate}}" (click)="deleteCostCenter(SelectedDailyrestrictionsFooterModelForModal,cost)"><i class="simple-icon-trash"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    
                    <div class="col-12 row">
                        <div class=" col-md-11">
                        </div>
                    <button type="button" class="btn btn-primary"(click)="AddCostCenter(SelectedDailyrestrictionsFooterModelForModal)">{{ '+ Add' | translate }}</button>{{' '}}
        
                      </div>
                </div>
            </div>
        </div>
      </ng-template>


      <ng-template #taxModal>
        <div class="modal-header">
          <!-- <h4 class="modal-title pull-left">{{'modal.modal-title' | translate}}</h4> -->
          <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div class="card" style="margin-top: 20px;">
                <div class="card-body" >
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">{{'Tax'| translate}}</th>
                                <th scope="col">{{'Percent'| translate}}</th>
                                <th scope="col">{{'Add'| translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr  *ngFor="let tax of taxes; let i=index;">
                                <th style="width: 10%;" scope="row">{{i + 1}}</th>
                                <td style="width: 30%;">
                                    {{ lang==='ar-EG'?tax.NameAr:tax.NameEn }}
                                </td>
                        
                                <td style="width: 20%;">{{tax.Percentage}}</td>
                                <td style="width: 20%;">
                                <button type="button" class="btn btn-primary"(click)="AddTax(tax)">{{ '+ Add' | translate }}</button>{{' '}}
                                
                                </td>
                            
                            </tr>
                        </tbody>
                    </table>
                    
                    <div class="col-12 row">
                        <div class=" col-md-11">
                        </div>
        
                      </div>
                </div>
            </div>
        </div>
      </ng-template>
      <simple-notifications></simple-notifications>