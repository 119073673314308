import { ItemsPriceListModel } from './ItemsPriceListModel';
import { WarehouseItemModel } from './WarehouseItem';
import{BrandItemsModel}from '../models/BrandItemsModel';
import{CategoryItemsModel}from '../models/CategoryItemsMosel';

export class StoreItemsModel{
    Id?: number;
    Name?:string;
    SkuNumber?: number; 
    Description?: string; 
    PurchasingPrice?: number; 
    SalePrice?: number;
    FirstTaxId?: number;
    SecondTaxId?: number;
    LowestSellingPrice?: number;
    Discount?: number;
    ProfitPercentage?: number;
    PriceListId?: number;
    WarehouseDataId?: number;
    UnitId?: number;
    Quantity?: number;
    Alert?:number;
    TotalQuantitySoled?: number;
    Last28DayesQuantitySoled?: number;
    Last7DayesQuantitySoled?: number;
    AverageUnitPrice?:number;
    Notes?: string;
    Tags?: string;
    IsDisable?: boolean;
    AvailableOnline?: boolean;
    FeaturedProduct?: boolean;
    WarehouseName?:string;
    Barcode?:string;
    DiscountType?:string;
    
    ItemPriceList?:ItemsPriceListModel[];
    WarhouseItem?:WarehouseItemModel[];
    ItemBrand?:BrandItemsModel[];
    ItemCategory?:CategoryItemsModel[];

    constructor(item?:StoreItemsModel){
        item = item? item:{};
        this.Id = item.Id? item.Id:0;
        this.Name = item.Name? item.Name:'';
        this.SkuNumber = item.SkuNumber? item.SkuNumber:0;
        this.Description = item.Description? item.Description:'';
        this.PurchasingPrice = item.PurchasingPrice? item.PurchasingPrice:0;
        this.SalePrice = item.SalePrice? item.SalePrice:0;
        this.FirstTaxId = item.FirstTaxId? item.FirstTaxId:0;
        this.SecondTaxId = item.SecondTaxId? item.SecondTaxId:0;
        this.LowestSellingPrice = item.LowestSellingPrice? item.LowestSellingPrice:0;
        this.Discount = item.Discount? item.Discount:0;
        this.ProfitPercentage = item.ProfitPercentage? item.ProfitPercentage:0;
        this.PriceListId = item.PriceListId? item.PriceListId:0;
        this.WarehouseDataId = item.WarehouseDataId? item.WarehouseDataId:0;
        this.Quantity = item.Quantity? item.Quantity:0;
        this.TotalQuantitySoled = item.TotalQuantitySoled? item.TotalQuantitySoled:0;
        this.Last28DayesQuantitySoled = item.Last28DayesQuantitySoled? item.Last28DayesQuantitySoled:0;
        this.Last7DayesQuantitySoled = item.Last7DayesQuantitySoled? item.Last7DayesQuantitySoled:0;
        this.UnitId = item.UnitId? item.UnitId:0;
        this.Notes = item.Notes? item.Notes:'';
        this.Tags = item.Tags? item.Tags:'';
        this.DiscountType = item.DiscountType? item.DiscountType:'';
        this.IsDisable = item.IsDisable? item.IsDisable:false;
        this.AvailableOnline = item.AvailableOnline? item.AvailableOnline:false;
        this.FeaturedProduct = item.FeaturedProduct? item.FeaturedProduct:false;
        this.WarehouseName = item.WarehouseName? item.WarehouseName:'';
        this.Barcode = item.Barcode? item.Barcode:'';
        this.Alert = item.Alert? item.Alert:0;
        this.AverageUnitPrice = item.AverageUnitPrice? item.AverageUnitPrice:0;
        
        this.ItemPriceList = item.ItemPriceList? item.ItemPriceList:[];
        this.WarhouseItem = item.WarhouseItem? item.WarhouseItem:[];
        this.ItemBrand = item.ItemBrand? item.ItemBrand:[];
        this.ItemCategory = item.ItemCategory? item.ItemCategory:[];
        
    
    }

}