export class QuotationServiceModel {
    Id?: number;
    QuotationId?: number;
    NameAr?: string;
    NameEn?: string;
    Value?: number;
    IsRate?: boolean;

    constructor(obj?: QuotationServiceModel) {
        obj = obj ? obj : {};
        this.Id = obj.Id ? obj.Id : 0;
        this.QuotationId = obj.QuotationId ? obj.QuotationId : 0;
        this.NameAr = obj.NameAr ? obj.NameAr : '';
        this.NameEn = obj.NameEn ? obj.NameEn : '';
        this.Value = obj.Value ? obj.Value : 0;
        this.IsRate = obj.IsRate ? obj.IsRate : false;
    }

}