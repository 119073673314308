export class CashierOrderItemModel{
    Id?:number;
    Code?:number; //new
    CashierOrderId?:number;
    CashierOrderCode?:number; //new
    ItemId?:number;
    ItemNameAr?:string;
    ItemNameEn?:string;
    UnitId?:number;
    UnitNameAr?:string;
    UnitNameEn?:string;
    UnitCost?:number;
    Quantity?:number;
    TotalPrice?:number;
    DiscountValue?:number;
    DiscountIsRate?:boolean;
    NetPrice?:number;
    AddedValueTax?:number;
    GrossPrice?:number;
    Note?:string;
    constructor(obj?:CashierOrderItemModel){
        obj = obj? obj:{};
        this.Id = obj.Id? obj.Id:0;
        this.Code = obj.Code? obj.Code:0; //new
        this.CashierOrderId = obj.CashierOrderId? obj.CashierOrderId:0;
        this.CashierOrderCode = obj.CashierOrderCode? obj.CashierOrderCode:0; //new
        this.ItemId = obj.ItemId? obj.ItemId:0;
        this.ItemNameAr = obj.ItemNameAr? obj.ItemNameAr:'';
        this.ItemNameEn = obj.ItemNameEn? obj.ItemNameEn:'';
        this.UnitId = obj.UnitId? obj.UnitId:0;
        this.UnitNameAr = obj.UnitNameAr? obj.UnitNameAr:'';
        this.UnitNameEn = obj.UnitNameEn? obj.UnitNameEn:'';
        this.UnitCost = obj.UnitCost? obj.UnitCost:0;
        this.Quantity = obj.Quantity? obj.Quantity:0;
        this.TotalPrice = obj.TotalPrice? obj.TotalPrice:0;
        this.DiscountValue = obj.DiscountValue? obj.DiscountValue:0;
        this.DiscountIsRate = obj.DiscountIsRate? obj.DiscountIsRate:true;
        this.NetPrice = obj.NetPrice? obj.NetPrice:0;
        this.AddedValueTax = obj.AddedValueTax? obj.AddedValueTax:0;
        this.GrossPrice = obj.GrossPrice? obj.GrossPrice:0;
        this.Note = obj.Note? obj.Note:'';
    }
}