import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CashierOrderModel } from "./CashierOrderModel";

@Injectable({
  providedIn: 'root'
})
export class CashierOrderService {
    constructor(private http: HttpClient) {
  
    }
    
    debugData: CashierOrderModel [] = [];
    // debugData: CashierOrderModel [] = [
    //   {
    //     Id:1, Code:1001, Note:"desc1\nOrder1\n1", Status:'Complete', 
    //     CashierId:1, CashierNameAr:"احمد سعيد", CashierNameEn:"Ahmad Saeed", 
    //     ClientId:1, ClientNameAr:'احمد', ClientNameEn:'Ahmad', IsRefund:false,
    //     ModifiedDate: new Date(2021, 0, 13), SessionId:1, SessionCode:1001, CashRegisterId:1, CashRegisterCode:1001,
    //     CashRegisterBranchId:1, CashRegisterBranchNameAr:"فرع القاهرة", CashRegisterBranchNameEn:"Cairo Branch",
        
    //     Items: [
    //       { Id:1, Code:1001, CashierOrderId: 1, CashierOrderCode:1001, ItemId:1, ItemNameAr: "منتج 1", ItemNameEn: "Item 1", 
    //       UnitId:1, UnitNameAr:"وحدة 1", UnitNameEn:"Unit 1", Note:"item\ndescription\n1", 
    //       UnitCost:1000, Quantity:1, TotalPrice:1000, DiscountValue:1, DiscountIsRate:true, NetPrice:990,
    //       AddedValueTax:138.6, GrossPrice: 1128.6 },

    //       { Id:2, Code:1002, CashierOrderId: 1, CashierOrderCode:1001, ItemId:2, ItemNameAr: "منتج 2", ItemNameEn: "Item 2", 
    //       UnitId:2, UnitNameAr:"وحدة 2", UnitNameEn:"Unit 2", Note:"item\ndescription\n2", 
    //       UnitCost:1000, Quantity:2, TotalPrice:2000, DiscountValue:2, DiscountIsRate:true, NetPrice:1960,
    //       AddedValueTax:274.4, GrossPrice: 2234.4 },
    //     ],

    //     ItemTotalPrices:3000, ItemTotalDiscounts:50, ItemTotalNetPrices: 2950, 
    //     ItemTotalTaxes:413, ItemTotalGrossPrices: 3363, TotalValue:3363,

    //     PaymentMethodId:1, PaymentMethodAr: "كاش", PaymentMethodEn:"Cash",
    //     CounterCashBeforeCheckout:1000, CounterCashAfterCheckout: 4363,
    //     PaidAmount: 3400, ChangeGiven:37,
    //   },

    //   {
    //     Id:2, Code:1002, Note:"desc2\nOrder2\n2", Status:'In-Delivery',
    //     CashierId:2, CashierNameAr:"محمد فيصل", CashierNameEn:"Mohamad Faysal", 
    //     ClientId:2, ClientNameAr:'فواز', ClientNameEn:'Fawaz', IsRefund:false,
    //     ModifiedDate: new Date(2022, 1, 13), SessionId:2, SessionCode:1002, CashRegisterId:2, CashRegisterCode:1002,
    //     CashRegisterBranchId:2, CashRegisterBranchNameAr:"فرع السعودية", CashRegisterBranchNameEn:"Saudi Branch",
        
    //     Items: [
    //       { Id:3, CashierOrderId: 2, ItemId:2, ItemNameAr: "منتج 2", ItemNameEn: "Item 2", 
    //       UnitId:2, UnitNameAr:"وحدة 1", UnitNameEn:"Unit 1", Note:"item\ndescription\n3", 
    //       UnitCost:1000, Quantity:3, TotalPrice:3000, DiscountValue:3, DiscountIsRate:true, NetPrice:2910,
    //       AddedValueTax:407.4, GrossPrice: 3317.4 },

    //       { Id:4, CashierOrderId: 2, ItemId:1, ItemNameAr: "منتج 1", ItemNameEn: "Item 1", 
    //       UnitId:1, UnitNameAr:"وحدة 2", UnitNameEn:"Unit 2", Note:"item\ndescription\n4", 
    //       UnitCost:1000, Quantity:4, TotalPrice:4000, DiscountValue:4, DiscountIsRate:true, NetPrice:3840,
    //       AddedValueTax:537.6, GrossPrice: 4377.6 },
    //     ],

    //     ItemTotalPrices:7000, ItemTotalDiscounts:250, ItemTotalNetPrices: 6750, 
    //     ItemTotalTaxes:945, ItemTotalGrossPrices: 7695, 
    //     OrderDiscountValue:0, OrderDiscountIsRate:false, TotalValue:7695,

    //     PaymentMethodId:1, PaymentMethodAr: "كاش", PaymentMethodEn:"Cash",
    //     //CounterCashBeforeCheckout:2000, CounterCashAfterCheckout: 9695,
    //     CounterCashBeforeCheckout:2000, CounterCashAfterCheckout: 2000,
    //     PaidAmount: 7700, ChangeGiven:5,
    //   },
      
    // ];
    debugMode: boolean = false;

    baseUrl = environment.baseApiUrl;
    // baseUrl = 'http://localhost:60289/';
     token = localStorage.getItem("token");
     httpOptions = {
      headers: new HttpHeaders({
        // 'Content-Type': 'application/json; charset=utf-8',
        // 'Authorization': "Bearer " + token,
         "Authorization": "Bearer " + this.token,
         "Content-Type": "application/json"
      })
    };
    getAll(): Observable<any> {
      if(this.debugMode){
        return of(this.debugData)
      }
      return this.http
        .get<any>(`${this.baseUrl}CashierOrder/GetAll`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    getAllWithItems(): Observable<any> {
      if(this.debugMode){
        return of(this.debugData)
      }
      return this.http
        .get<any>(`${this.baseUrl}CashierOrder/GetAllWithItems`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    getById(id:number): Observable<any> {
      if(this.debugMode){
        return of(this.debugData.find(x=>x.Id==id))
      }
      return this.http
        .get<any>(`${this.baseUrl}CashierOrder/GetById/${id}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    getByIdWithItems(id:number): Observable<any> {
      if(this.debugMode){
        return of(this.debugData.find(x=>x.Id==id))
      }
      return this.http
        .get<any>(`${this.baseUrl}CashierOrder/GetByIdWithItems/${id}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    insert(payload: CashierOrderModel): Observable<any> {
      if(this.debugMode){
        this.debugData.push(payload);
        return of(true)
      }
      return this.http
        .post<any>(`${this.baseUrl}CashierOrder/Insert`, payload,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    insertWithItems(payload: CashierOrderModel): Observable<any> {
      if(this.debugMode){
        this.debugData.push(payload);
        return of(true)
      }
      return this.http
        .post<any>(`${this.baseUrl}CashierOrder/InsertWithItems`, payload,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
    update(payload: CashierOrderModel): Observable<any> {
      if(this.debugMode){
        this.debugData[this.debugData.findIndex(x=>x.Id==payload.Id)] = payload;
        return of(true)
      }
      return this.http
        .post<any>(`${this.baseUrl}CashierOrder/Update`, payload,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
    delete(payload: CashierOrderModel): Observable<any> {
      if(this.debugMode){
        this.debugData.splice(this.debugData.findIndex(x=>x.Id==payload.Id),1);
        return of(true)
      }
      return this.http
        .get<any>(`${this.baseUrl}CashierOrder/Delete/${payload.Id}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
    deleteList(payload: CashierOrderModel []): Observable<any> {
      if(this.debugMode){
        payload.forEach(element => {
          this.debugData.splice(this.debugData.findIndex(x=>x.Id==element.Id),1);
        });
        return of(true)
      }
      return this.http
        .post<any>(`${this.baseUrl}CashierOrder/DeleteList`,JSON.stringify(payload),this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
}
