import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MyPharmacySubscriptionModel } from '../../models/my-pharmacy-subscription.model';

@Component({
    selector: 'create-my-pharmacy-subscription',
    templateUrl: 'create-my-pharmacy-subscription.component.html',
    styleUrls: ['create-my-pharmacy-subscription.component.scss']
})
export class CreateMyPharmacySubscriptionComponent implements OnInit {
    showCard: boolean = false;
    ItemHolderList: any[] = [];
    Form: FormGroup;
    FamlyForm:FormGroup;
    lang: string;
  
    constructor(
      public router:Router, 
      public fb:FormBuilder, 
      ) {
    
   
    }
 
    ngOnInit() {
        this.Form = this.createForm(new MyPharmacySubscriptionModel());  
        this.FamlyForm = this.createFamlyForm();      
    }

  
    Save() {
        this.router.navigate(['app/memberships'])

    }
 
  goBack(){
    this.router.navigate(['app/memberships'])
  }

  
  createForm(modelObject:MyPharmacySubscriptionModel): FormGroup {
    debugger;
    return this.fb.group({
     Id          :[modelObject.Id          ],
     MemberId    :[modelObject.MemberId    ],
     MmemberName :[modelObject.MmemberName ],
     PackageId   :[modelObject.PackageId   ],
     PackageName :[modelObject.PackageName ],
     PackagePrice:[modelObject.PackagePrice],
     StartDate   :[modelObject.StartDate   ],
     EndDate     :[modelObject.EndDate     ],
     Description:['']
    });
  }
  createFamlyForm(): FormGroup {
    debugger;
    return this.fb.group({
     Name          :[''],
     Age    :[0],
     DegreeOfKinship :[''],
     Gender   :[''],
  
    });
  }
  famly=[
    {Name:'محمد',Age:14,DegreeOfKinship:'Close to first class',Gender:'Male'},
    {Name:'منة',Age:12,DegreeOfKinship:'Close to first class',Gender:'Female'},
    {Name:'ريهام',Age:8,DegreeOfKinship:'Close to first class',Gender:'Female'},
    {Name:'محمود',Age:18,DegreeOfKinship:'Close to second degree',Gender:'Male'},
]
  public editEnabled = true;
  public picurl: string ='';
  members=[
    {Name:'محمود احمد' },
    {Name:'عمرو محمد'  },
    {Name:'محمد نوح'   },
    {Name:'يوسف احمد'  },
    {Name:'ابراهيم حسن'},
]
packages=[
    {Name:'باقة 1', Price:10000},
    {Name:'باقة 2', Price:20000},
    {Name:'باقة 3', Price:30000},
    {Name:'باقة 4', Price:40000},
    {Name:'باقة 5', Price:50000},
]

  clear(){
    this.picurl = '';
 }
 public NationalIdEditEnabled = true;
 public NationalIdPicurl: string ='';

 clearNationalId(){
   this.NationalIdPicurl = '';
}
public graduationEditEnabled = true;
public graduationPicurl: string ='';

cleargraduation(){
  this.graduationPicurl = '';
}

  }
  