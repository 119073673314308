import { saveAs } from 'file-saver';
import { PurchasesModel } from './purchases-model';
import { Component, HostBinding, Renderer2, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { of } from 'rxjs';
import { SidePopupComponent } from 'src/app/views/app/aio/shared/side-popup/side-popup.component';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { DataFilterer } from 'src/app/views/app/aio/shared/DataFilterer';
import { DataSorter } from 'src/app/views/app/aio/shared/DataSorter';
import { DataPaginator } from 'src/app/views/app/aio/shared/DataPaginator';
import { InvoiceService } from '../invoices/InvoiceService';
import { InvoiceModel } from '../invoices/InvoiceModel';
import { environment } from 'src/environments/environment';



@Component({
  selector: 'app-purchases',
  templateUrl: './purchases.component.html',
  styleUrls: ['./purchases.component.scss']
})
export class PurchasesComponent {
  //IMPORTANT: ADD AND EDIT METHODS REMOVED
  //IMPORTANT: ADD AND EDIT METHODS REMOVED
  //IMPORTANT: ADD AND EDIT METHODS REMOVED
  //IMPORTANT: ADD AND EDIT METHODS REMOVED
  //IMPORTANT: ADD AND EDIT METHODS REMOVED
  //CTRL + F "IMPORTANT"
  //CTRL + F "IMPORTANT"
  //CTRL + F "IMPORTANT"
  //CTRL + F "IMPORTANT"
  //CTRL + F "IMPORTANT"
  
  ////////////////////////////////////////////////////////////////////////////
  //  VARYING :
  //  Modify per similar component.
  ////////////////////////////////////////////////////////////////////////////
  constructor(private formBuilder: FormBuilder, private modelService: InvoiceService, private renderer: Renderer2,
    private notifications: NotificationsService, private translate: TranslateService) {
  }
  currentScreen: any = 'Table';

  translationName: string = 'Invoice'

  sortableKeys = [];
  searchableKeys = ['Id'];
  
  modelObject: PurchasesModel = new InvoiceModel();
  ogData: PurchasesModel [] = [];
  mainForm: FormGroup = new FormGroup({
    Id: new FormControl(),
    NameEn: new FormControl(),
    NameAr: new FormControl(),
  });

  ngOnInit(): void {
    console.log("model");
    console.log(this.modelObject);
    
    
    this.lang = localStorage.getItem('theme_lang');
    this.itemOrder = { label: 'Id', property: 'Id' };
    this.sortableKeys = [
      { label: 'Id', property: 'Id' },
    ];
    this.loadData();
    console.log(this.ogData);
  }

  //Deletion methods:
  //Customize if needed.
  //Pass the target object as paraMetre if the target API can only delete singular objects and not lists.
  deleteObject() {
    this.savedPage = this.currentPage;
    //prevent multiclicks. re enable after API response
    this.currentAction = 'loading';
    this.executeDeleteObject();
  }

  //Same here...
  executeDeleteObject(){
    this.modelService.deleteList(this.deletionList).subscribe(result => {
      if (result === true) {
        this.loadData();
        this.currentAction = '';
        this.finalizeDeletion();
      }
      else {
        this.toastError(this.translate.instant("Error"), this.translate.instant("FailedDelete"));
        this.currentAction = '';
      }
    })
  }
  ////////////////////////////////////////////////////////////////////////////
  //
  //
  //
  //
  //
  ////////////////////////////////////////////////////////////////////////////
  //  EXCLUSIVE :
  //  Specifically for this component.
  ///////////////////////////////
  

  ////////////////////////////////////////////////////////////////////////////
  //
  //
  //
  //
  //
  ////////////////////////////////////////////////////////////////////////////
  //  CONSTANT :
  //  Do not change.
  ///////////////////////////////
  lang: string;
  currentFormMode: string = '';
  currentAction: string = '';


  //ogData = [];
  filteredData = [...this.ogData];
  sortedData = [...this.filteredData];
  paginatedData = [...this.filteredData];
  lastId: number = this.ogData.length + 1;

  SelectAllBox: any = false;

  itemsPerPage = 10;
  itemOptionsPerPage = [5, 10, 20];
  selected = [];
  selectAllState = '';
  itemOrder: any;
  orderAsc: boolean = true;

  currentPage = 1;
  savedPage = 0;


  dataFilterer = new DataFilterer();
  dataSorter = new DataSorter();
  dataPaginator = new DataPaginator();

  loadData() {
    this.currentAction = 'loading';
    this.modelService.GetPurchases().subscribe(
      data => {
        console.log("data:");
        console.log(data);
        
        this.ogData = data;
        this.sortData();
      }
    )
    
  }

  sortData() {
    this.sortedData = this.dataSorter.sortData(this.itemOrder.property, this.ogData);
    this.filterData();
  }

  reverseSortData() {
    this.savedPage = this.currentPage;
    this.sortedData = this.sortedData.slice().reverse();
    this.filterData();
  }

  filterData() {
    debugger;
    this.filteredData = this.dataFilterer.filterData(this.SearchTerm, this.searchableKeys, this.sortedData)
    if (this.savedPage === 0) {
      this.paginateData(this.itemsPerPage, 1);
    }
    else {
      this.paginateData(this.itemsPerPage,
        Math.ceil((this.filteredData.length / this.itemsPerPage)) >= this.savedPage ?
          this.savedPage :
          Math.ceil((this.filteredData.length / this.itemsPerPage))
      );
    }
    this.savedPage = 0;
  }

  paginateData(newItemsPerPage: number, newCurrentPage: number) {
    debugger;

    this.itemsPerPage = newItemsPerPage;
    this.currentPage = newCurrentPage;
    this.paginatedData = this.filteredData.slice((newCurrentPage - 1) * (newItemsPerPage), (newItemsPerPage * newCurrentPage));
  }

  changeSort(newSort: any) {
    if (this.itemOrder.label === newSort.label) {
      this.orderAsc = !this.orderAsc;
      const oldPage = this.currentPage;
      this.reverseSortData()
      this.currentPage = oldPage;
    }
    else {
      this.orderAsc = true;
      this.itemOrder = newSort;
      this.sortData();
    }
  }

  getSort(label: any) {
    return this.sortableKeys.find(i => i.label === label);
  }

  changePage(event: PageChangedEvent): void {
    this.currentPage = event.page;
    this.paginateData(this.itemsPerPage, this.currentPage);
  }

  changeFilter() {
    this.deletionList = [];
    this.selected = [];
    this.selectAllState = '';
    this.filterData();
  }

  selectAll($event): void {
    if ($event.target.checked) {
      this.selected = [...this.filteredData];
    } else {
      this.selected = [];
    }
    this.setSelectAllState();
  }

  setSelectAllState(): void {
    if (this.selected.length >= this.filteredData.length) {
      this.selectAllState = 'checked';
    } else if (this.selected.length !== 0) {
      this.selectAllState = 'indeterminate';
    } else  {
      this.selectAllState = '';
    }
  }

  isSelected(p: InvoiceModel): boolean {
    return this.selected.findIndex(x => x.Id === p.Id) > -1;
  }

  onSelect(item: InvoiceModel): void {
    if (this.isSelected(item)) {
      this.selected = this.selected.filter(x => x.Id !== item.Id);
    } else {
      this.selected.push(item);
    }
    this.setSelectAllState();
  }

  findByCode(list: any, code: any): any {
    return list.find(x => x.Code === code)
  }

  @ViewChild('delModal') delModal: ModalDirective;
  showAddNewModalRef(): void {
    //IMPORTANT
    //REDIRECT TO ADD PAGE
  }

  showInfoModalRef(Id: any): void {
    //IMPORTANT
    //REDIRECT TO INFO PAGE
  }

  deletionList: InvoiceModel[] = [];
  showDelModal(Id: any): void {
    if (Id >= 0) {
      this.currentFormMode = 'Del'
      this.modelObject = this.ogData.find(i => i.uuid === Id);
      this.deletionList = [this.ogData.find(i => i.uuid === Id)];
      this.delModal.show();
    }
    else if (Id === 'delMulti') {
      this.deletionList = [...this.selected]
      this.delModal.show();
    }
  }

  SearchTerm: any = '';


  finalizeDeletion(){
    if (this.deletionList.length === 1) {
      this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyDeleted") + " " +
        this.translate.instant("One" + this.translationName));
    }
    else if (this.deletionList.length === 2) {
      this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyDeleted") + " " +
        this.translate.instant("Two" + this.translationName + "s"));
    }
    else if (this.deletionList.length > 2 && this.deletionList.length < 11) {
      this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyDeleted") + " " +
        this.deletionList.length + " " + this.translate.instant(this.translationName + "RowsPlur"));
    }
    else {
      this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyDeleted") + " " +
        this.deletionList.length + " " + this.translate.instant(this.translationName + "Rows"));
    }

    this.deletionList = [];
    this.selected = [];

    this.setSelectAllState();
    if (this.selectAllState === '') {
      this.SelectAllBox = false;
    }
    this.delModal.hide();
  }

  toastSuccess(toastHeader: string, toastBody: string): void {
    this.notifications.create(toastHeader, toastBody,
      NotificationType.Success, { timeOut: 3000, showProgressBar: true });
  }
  toastError(toastHeader: string, toastBody: string): void {
    this.notifications.create(toastHeader, toastBody,
      NotificationType.Error, { timeOut: 3000, showProgressBar: true });
  }
  deleteMultiTaxTypes() {
    this.loadData();
    this.delModal.hide();
  }

  loadAllData() {
    this.modelService.GetPurchases().subscribe(
      data => {
        this.ogData = data;
        this.loadSearch();
      }
    )
  }

  loadSearch() {
    const prevSearch = this.SearchTerm;
    this.SearchTerm = '';
    setTimeout(() => {
      this.SearchTerm = prevSearch;
    }, 1);
  }
  print(Id){
    debugger;
  this.modelService.printInvoicePurshases(Id)
  .subscribe(result =>{
    console.log(result);
    saveAs(`${environment.apiUrl}/Resources/Invoices/${result}`, 'report.pdf');
    // window.open(`${environment.apiUrl}/Resources/Invoices/${result}`, '_blank');
  //  window.location.href =result
  //   window.open(result, '_blank');
  //   window.open(result);
  })
  }
}
