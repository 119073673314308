export class CompanyModel{
    Id?:number;
    NameEn?:string;
    NameAr?:string;
    Api?:string;
    CommercialRegister?:string;
    TaxCard?:string;
    AuthorizedPerson?:string;
    Email?:string;
    Landline?:string;
    Phone?:string;
    Notes?:string;
    Token?:string;
    constructor(obj?:CompanyModel){
        obj = obj? obj:{};
        this.Id = obj.Id? obj.Id:0;
        this.NameEn = obj.NameEn? obj.NameEn:'';
        this.NameAr = obj.NameAr? obj.NameAr:'';
        this.Api = obj.Api? obj.Api:'';
        this.CommercialRegister = obj.CommercialRegister? obj.CommercialRegister:'';
        this.TaxCard = obj.TaxCard? obj.TaxCard:'';
        this.AuthorizedPerson = obj.AuthorizedPerson? obj.AuthorizedPerson:'';
        this.Email = obj.Email? obj.Email:'';
        this.Landline = obj.Landline? obj.Landline:'';
        this.Phone = obj.Phone? obj.Phone:'';
        this.Notes = obj.Notes? obj.Notes:'';
        this.Token = obj.Token? obj.Token:'';
    }
}