import { Component, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { TableEngine } from 'src/app/invoices/TableEngine';
import { DataFilterer } from 'src/app/views/invoice/shared/DataFilterer';
import { DataPaginator } from 'src/app/views/invoice/shared/DataPaginator';
import { DataSorter } from 'src/app/views/invoice/shared/DataSorter';
import { SidePopupComponent } from 'src/app/views/invoice/side-popup/side-popup.component';
import { SalariesManagerModel } from '..//add-salaries-manager/SalariesManagerModel';
import { SalariesManagerService } from '../salaries-manager.service';

@Component({
    // moduleId: module.id,
    selector: 'list-salaries-manager',
    templateUrl: 'list-salaries-manager.component.html',
    styleUrls: ['list-salaries-manager.component.scss']
})
export class ListSalariesManagerComponent {
    Id=0;

    constructor(private formBuilder: FormBuilder, private modelService: SalariesManagerService, private renderer: Renderer2,
        private notifications: NotificationsService, private translate: TranslateService, private router : Router,
        private route: ActivatedRoute
        ) {   this.Id = + route.snapshot.paramMap.get('Id');   }

tableEngine = new TableEngine(this.translate, this.notifications);
currentScreen: any = 'Table';
tableView: any = true;
// PriceList : WarehouseModel[]=[];


translationName: string = 'Warehouse' 
sortableKeys = [];
searchableKeys = ['Name','Address'];
modelObject: SalariesManagerModel = new SalariesManagerModel();
// ProducteList:EmployeesManagementModel[]=[];

debugMode = true;

ogData: SalariesManagerModel[] = [];
mainForm: FormGroup = new FormGroup({
    Id: new FormControl(),
    Name: new FormControl(),
    dateOfRegistration: new FormControl(),
    StartingDate: new FormControl(),
    EndDate: new FormControl(),
    CheckAttendance: new FormControl(),
    GrammarChoose: new FormControl(),
    EmployeesChoose: new FormControl(),
    BranchId: new FormControl(),
    DepartmentId: new FormControl(),
    JobTitletId: new FormControl(),
    ArrestCycleId: new FormControl(),
});


declare lang: any;
declare itemOrder: any;
currentAction = '';
currentFormMode: string = '';
savedPage = 0;
currentPage = 1; 
deletionList: any[] = [];

@ViewChild('sideModalRef', { static: true }) sideModalRef: SidePopupComponent = null;
@ViewChild('delModal', { static: true }) delModal: ModalDirective;
@ViewChild('smallModal', { static: true }) smallModal: ModalDirective = null;
formPresentationModal: any;
ngOnInit(): void {
    // this.getAllCountryCode();    
     
    this.lang = localStorage.getItem('theme_lang');
    this.itemOrder = { label: 'Name', property: this.lang === 'ar-EG' ? 'Name' : 'Name' };      
    this.sortableKeys = [         
        // { label: 'Date', property: 'Date' },        
        { label: 'Name', property: this.lang === 'ar-EG' ? 'Name' : 'Name' },   
        { label: 'Nickname', property: this.lang === 'ar-EG' ? 'Nickname' : 'Nickname' },   
        { label: 'MobileNumber', property: this.lang === 'ar-EG' ? 'MobileNumber' : 'MobileNumber' }, 
             
    ];

    this.tableEngine.loadTableEngine(this.ogData, this.modelService, this.modelObject,
        this.searchableKeys, this.sortableKeys, this.mainForm, this.translationName, this.itemOrder,
        this.sideModalRef, this.delModal, this.smallModal, this.lang
    )
    //this.tableEngine.modelObject = {}
    this.loadServices();
    this.modelService.getAll().subscribe(data=>{
      this.modelObject=data;
    })

} 
showInfoModalRef(Id: any): void {
    this.router.navigate(['app/HR/Info-Salaries-Manager/',Id])
  }
  showDelModal(Id: any): void {
    debugger;
    if (Id >= 0) {
      this.currentFormMode = 'Del'
      this.modelObject = this.ogData.find(i => i.Id === Id);
      this.deletionList = [this.ogData.find(i => i.Id === Id)];
      this.delModal.show();
    }
    else if (Id === 'delMulti') {
      this.deletionList = [...this.selected]
      this.delModal.show();
    }
   
  }

  showModal(Id, action) {
  
    if (action === 'Add') {          
     debugger;
      this.router.navigate(['app/HR/Add-Salaries-Manager/',0])   
    }
    else if (action === 'Edit') {
      debugger;
      this.router.navigate(['app/HR/Add-Salaries-Manager/',Id])
  
      
    }
    else if (action === 'Info') {
     debugger;
      
      this.router.navigate(['app/HR/Info-Salaries-Manager/',Id])
      
     
    }
    //this.mainForm.get('IsActive').setValue(true);
     this.modelObject = this.tableEngine.modelObject;
     this.mainForm = this.tableEngine.mainForm;
  
  }
  
  
  deleteObject() {
    this.savedPage = this.currentPage;
    //prevent multiclicks. re enable after API response
    this.currentAction = 'loading';
    this.executeDeleteObject();
  }
  
  //Same here...
  executeDeleteObject(){
    debugger;
    this.modelService.deleteList(this.tableEngine.deletionList).subscribe(result => {
      if (result === true) {
        this.tableEngine.loadData();
        this.currentAction = '';
        this.tableEngine.delModal.hide();
        // this.finalizeDeletion();
        this.tableEngine.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyDeleted"));
        if (this.sideModalRef === undefined) {
  
          this.tableEngine.smallModal.hide();
  
        } else {
  
          this.tableEngine.sideModalRef.modalRef.hide();
        }
      }
      else {
        this.toastError(this.translate.instant("Error"), this.translate.instant("FailedDelete"));
        this.currentAction = '';
      }
    })
  }
  
  

  
  executeEditObject(Object) {
    this.modelService.update(Object).subscribe(result => {
      if (result === true) {
        this.tableEngine.loadData();
        this.currentAction = '';
        this.tableEngine.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyEdited"));
        if (this.sideModalRef === undefined) {
  
          this.tableEngine.smallModal.hide();
  
        } else {
  
          this.tableEngine.sideModalRef.modalRef.hide();
        }
      }
      else {
        this.tableEngine.toastError(this.translate.instant("Error"), this.translate.instant("FailedEdit"));
        this.currentAction = '';
      }
    })
  }

  toastError(toastHeader: string, toastBody: string): void {
    this.notifications.create(toastHeader, toastBody,
      NotificationType.Error, { timeOut: 3000, showProgressBar: true });
  }
  
  loadServices(){
      // debugger;
      this.currentAction = 'loading';
      this.tableEngine.loadData();         
    
     
  }
  filteredData = [...this.ogData];
  sortedData = [...this.filteredData];
  paginatedData = [...this.filteredData];
  itemsPerPage = 10;
  itemOptionsPerPage = [5, 10, 20];
  
  selected : any[] = [];
  selectAllState = '';
  SearchFrom: Date = null;
  SearchTo: Date = null;
        dataFilterer = new DataFilterer();
        dataSorter = new DataSorter();
        dataPaginator = new DataPaginator();
        SearchTerm: any = '';
  
  clearSearch() {
    this.SearchFrom = null;
    this.SearchTo = null;
  }
  changeFilter() {
    this.deletionList = [];
    this.selected = [];
    this.selectAllState = '';
    this.filterData();
  }
  filterData() {
    this.filteredData = this.dataFilterer.filterData(this.SearchTerm, this.searchableKeys, this.sortedData)
    if (this.savedPage === 0) {
      this.paginateData(this.itemsPerPage, 1);
    }
    else {
      this.paginateData(this.itemsPerPage,
        Math.ceil((this.filteredData.length / this.itemsPerPage)) >= this.savedPage ?
          this.savedPage :
          Math.ceil((this.filteredData.length / this.itemsPerPage))
      );
    }
    this.savedPage = 0;
  }
  changePage(event: PageChangedEvent): void {
      this.currentPage = event.page;
      this.paginateData(this.itemsPerPage, this.currentPage);
    }
    paginateData(newItemsPerPage: number, newCurrentPage: number) {
      this.itemsPerPage = newItemsPerPage;
      this.currentPage = newCurrentPage;
      this.paginatedData = this.filteredData.slice((newCurrentPage - 1) * (newItemsPerPage), (newItemsPerPage * newCurrentPage));
  }
    isSelected(p: SalariesManagerModel): boolean {
      return this.selected.findIndex(x => x.Id === p.Id) > -1;
    }
  
    onSelect(item: SalariesManagerModel): void {
      if (this.isSelected(item)) {
        this.selected = this.selected.filter(x => x.Id !== item.Id);
      } else {
        this.selected.push(item);
      }
      this.setSelectAllState();
    }
  
    selectAll($event): void {
      if ($event.target.checked) {
        this.selected = [...this.filteredData];
      } else {
        this.selected = [];
      }
      this.setSelectAllState();
    }
  
    setSelectAllState(): void {
      if (this.selected.length >= this.filteredData.length) {
        this.selectAllState = 'checked';
      } else if (this.selected.length !== 0) {
        this.selectAllState = 'indeterminate';
      } else  {
        this.selectAllState = '';
      }
    }
  
  prepChangeSort(sortKey) {
    this.SearchFrom = null;
    this.SearchTo = null;
    
    
    this.tableEngine.changeSort(this.tableEngine.getSort(sortKey));
    this.itemOrder = this.tableEngine.itemOrder;
  }
  
  getFormattedDate(dateString: any){
    let splitDate: number[] = dateString.toString().split("/");
  
    return new Date(splitDate[2], splitDate[1]-1, splitDate[0])
  }
  ///////////////////////////////////////////////////////
  
  displayMode = 'list';
  changeDisplayMode(mode): void {
    this.displayMode = mode;
  }
  itemsPerPageChange(perPage: number): void {
    this.tableEngine.paginateData(perPage, 1)
  }

}
