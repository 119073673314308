import { Component, HostBinding, Renderer2, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';

import { TableEngine } from 'src/app/invoices/TableEngine'
import { ModalDirective } from 'ngx-bootstrap/modal';
import { SidePopupComponent } from 'src/app/views/app/aio/shared/side-popup/side-popup.component';
import { Observable, of, Subscription } from 'rxjs';

import { NgSelectComponent } from '@ng-select/ng-select';

import { RequestReceiptModel } from "./RequestReceiptModel";
import { RequestReceiptService } from "./RequestReceiptService";
import { RequestReceiptItemModel } from "./RequestReceiptItemModel";

import { CodingUnitModel } from "src/app/invoices/coding-units/CodingUnitModel";
import { CodingUnitService } from 'src/app/invoices/coding-units/CodingUnitService';
import { StoreDataService } from "src/app/views/purchases/purchase-orders/StoreDataService";
import { SupplierModel } from "src/app/views/purchases/purchase-orders/SupplierModel";
import { SupplierService } from "src/app/views/purchases/purchase-orders/SupplierService";
// import { PaymentMethodModel } from "src/app/views/purchases/purchase-orders/PaymentMethodModel";
// import { PaymentMethodService } from "src/app/views/purchases/purchase-orders/PaymentMethodService";
import { formatDate } from '@angular/common';
import { StoreDataModel } from '../purchase-orders/StoreDataModel';
import { PurchaseOrderService } from '../purchase-orders/PurchaseOrderService';
import { PurchaseOrderModel } from '../purchase-orders/PurchaseOrderModel';
import { ConfigPurchasesService } from '../config-purchases/ConfigPurchasesService';
import { ConfigPurchasesModel } from '../config-purchases/ConfigPurchasesModel';
import { StoreItemsService } from 'src/app/Invintory/store-items-service.service';




@Component({
  selector: 'app-request-receipt',
  templateUrl: './request-receipt.component.html',
  styleUrls: ['./request-receipt.component.scss']
})
export class RequestReceiptComponent {
  ////////////////////////////////////////////////////////////////////////////
  //  VARYING :
  //  Modify per similar component.
  ////////////////////////////////////////////////////////////////////////////

  //Enable debugMode to make API modules return static data from within the service class
  debugMode: boolean = false;

  constructor(private formBuilder: FormBuilder, private modelService: RequestReceiptService, private renderer: Renderer2,
    private notifications: NotificationsService, private translate: TranslateService,
    private itemService: StoreItemsService,
    private storeDataService: StoreDataService, private codingUnitService: CodingUnitService,
    private supplierService: SupplierService, private purchaseOrderService: PurchaseOrderService,
    private configPurchasesService: ConfigPurchasesService
  ) {
  }

  tableEngine = new TableEngine(this.translate, this.notifications);
  currentScreen: any = 'Table';
  tableView: any = true;


  translationName: string = 'RequestReceipt'

  sortableKeys = [];
  searchableKeys = ['Date'];

  modelObject: RequestReceiptModel = new RequestReceiptModel();

  ogData: RequestReceiptModel[] = [];

  mainForm: FormGroup = new FormGroup({
    Id: new FormControl(),
    //Code: new FormControl(),
    PurchaseOrderId: new FormControl(),
    // SupplierId: new FormControl(),
    // CompanyDataId: new FormControl(),
    // //BranchId: new FormControl(),
    // StoreDataId: new FormControl(),
    // Date: new FormControl(),
    // TotalValue: new FormControl(),
    // IsPosted: new FormControl(),
    // Description: new FormControl(),
    // Items: new FormControl(),
  });


  declare lang: any;
  declare itemOrder: any;
  currentAction = '';
  savedPage = 0;
  currentPage = 1;
  deletionList: any[] = [];

  @ViewChild('sideModalRef', { static: true }) sideModalRef: SidePopupComponent = null;
  @ViewChild('delModal', { static: true }) delModal: ModalDirective;
  @ViewChild('smallModal', { static: true }) smallModal: ModalDirective = null;
  formPresentationModal: any;
  ngOnInit(): void {
    if (this.debugMode) {
      this.enableDebugOnAllServices();
    }

    this.lang = localStorage.getItem('theme_lang');
    this.itemOrder = { label: 'RequestReceiptCode', property: 'Code' };
    this.sortableKeys = [
      { label: 'RequestReceiptDate', property: 'Date' },
      { label: 'RequestReceiptStoreName', property: this.lang === 'ar-EG' ? 'StoreNameAr':'StoreNameEn' },
      { label: 'RequestReceiptPurchaseOrder', property: 'PurchaseOrderCode' },
      { label: 'RequestReceiptTotalValue', property: 'TotalValue' },
      { label: 'RequestReceiptSupplier', property: this.lang === 'ar-EG' ? 'SupplierNameAr':'SupplierNameEn' },
      { label: 'RequestReceiptCode', property: 'Code' },
      { label: 'RequestReceiptIsPosted', property: 'IsPosted' },
      
      
    ];


    this.tableEngine.loadTableEngine(this.ogData, this.modelService, this.modelObject,
      this.searchableKeys, this.sortableKeys, this.mainForm, this.translationName, this.itemOrder,
      this.sideModalRef, this.delModal, this.smallModal, this.lang
    )
    this.loadServices();
  }


  enableDebugOnAllServices() {
    //this.modelService.debugMode = true;
    // this.itemService.debugMode = true;
    //this.codingUnitService.debugMode = true;
    //this.storeDataService.debugMode = true;
    //this.supplierService.debugMode = true;
    //this.purchaseOrderService.debugMode = true;

  }


  lastCode = 0;
  showModal(Id, action) {
    console.log("entered showModal");
    
    this.newRequestReceiptItem = {};

    this.requestReceiptItemForm = this.createRequestReceiptItemForm(this.newRequestReceiptItem, this.formBuilder);

    if (action === 'Add') {
      this.addedItemList = [];
      this.newRequestReceiptItemInternalId = 0;
      if(this.configPurchases.EnableAutoRequestReceiptCodes==true){
        this.lastCode = this.configPurchases.StartingRequestReceiptCode;
        for (let index = 0; index < this.tableEngine.ogData.length; index++) {
          if(this.tableEngine.ogData.findIndex(x=>x.Code == this.lastCode) >= 0)
          { this.lastCode++; console.log(this.lastCode);
          } 
          else { break; }
        }
      }else{
        //this.lastCode = this.tableEngine.ogData.length>0?this.tableEngine.ogData[this.tableEngine.ogData.length - 1].Code + 1:0;
        this.lastCode = 0;
      }
      this.modelObject.Code = this.lastCode;
      this.checkCode();
      this.tableEngine.showAddNewModalRef(this.createForm, this.formBuilder)
    }
    else if (action === 'Edit') {
      this.modelService.getByIdWithItems(Id).subscribe(
        detailedObject => {
          console.log("detailed object", detailedObject);
          
          let targetIndex = this.tableEngine.ogData.findIndex(i => i.Id === Id);
          this.tableEngine.ogData[targetIndex] = detailedObject;
          this.tableEngine.showEditModalRef(Id, this.createForm, this.formBuilder)
          
          this.lastCode = this.tableEngine.modelObject.Code
          
          this.modelObject = this.tableEngine.modelObject;
          this.mainForm = this.tableEngine.mainForm;
          this.tableView = false;

          this.addedItemList = [];
          this.addedItemList = [...this.modelObject.Items];

          this.calculateTotalItemValue();
        }
      )
    }
    else if (action === 'Info') {
      console.log("entered info");
      this.modelService.getByIdWithItems(Id).subscribe(
        detailedObject => {
          console.log("detailed object", detailedObject);
          
          let targetIndex = this.tableEngine.ogData.findIndex(i => i.Id === Id);
          this.tableEngine.ogData[targetIndex] = detailedObject;
          this.tableEngine.showEditModalRef(Id, this.createForm, this.formBuilder)
          
          this.lastCode = this.tableEngine.modelObject.Code
          
          this.modelObject = this.tableEngine.modelObject;
          this.mainForm = this.tableEngine.mainForm;
          this.tableView = false;

          this.addedItemList = [];
          this.addedItemList = [...this.modelObject.Items];

          this.calculateTotalItemValue();
        } 
      )
    }
    this.tableView = false;
    this.modelObject = this.tableEngine.modelObject;
    this.mainForm = this.tableEngine.mainForm;
    console.log((action + " object"), this.modelObject);

    this.addedItemList.forEach(element => {
      element.MaxQuantity = element.Quantity;
      console.log(element.MaxQuantity);
      
    });

    this.calculateTotalItemValue();

    console.log("modelObject after entering edit", this.modelObject);
  }

  createForm(modelObject, formBuilder): FormGroup {
    return formBuilder.group({
      Id: [modelObject.Id >= 0 ? modelObject.Id : 0],
      PurchaseOrderId: [modelObject.PurchaseOrderId, Validators.compose([Validators.required])],
      // SupplierId: [modelObject.SupplierId, Validators.compose([Validators.required])],
      // StoreDataId: [modelObject.StoreDataId, Validators.compose([Validators.required])],
      // //BranchId: [modelObject.BranchId, Validators.compose([Validators.required])],
      // Date: [modelObject.Date?modelObject.Date.toString().slice(0, 10):modelObject.Date, Validators.compose([Validators.required])],
      // Description: [modelObject.Description],
      // IsPosted: [modelObject.IsPosted, Validators.compose([Validators.required])],

      // Items: [modelObject.Items],
    });
  }

  addObject() {
    this.currentAction = 'loading';
    
    let tempList: RequestReceiptItemModel[] = [];
    let tempItem: RequestReceiptItemModel;
    let debugId = 0;
    for (let index = 0; index < this.addedItemList.length; index++) {
      tempItem = new RequestReceiptItemModel();
      tempItem = this.addedItemList[index]
      tempItem.Id = this.debugMode ? debugId : 0;
      tempItem.RequestReceiptId = 0;
      tempList.push(tempItem)

      debugId++;
    }
    
    //let lastId = this.tableEngine.ogData.length>0?this.tableEngine.ogData[this.tableEngine.ogData.length - 1].Id:0;
    //
    this.modelObject = new RequestReceiptModel();
    
    //this.modelObject.Id = this.debugMode ? lastId + 1 : 0;
    this.modelObject.Id = 0;
    this.modelObject.PurchaseOrderId = this.purchaseOrderHeader.Id;
    this.modelObject.PurchaseOrderCode = this.purchaseOrderHeader.Code;

    this.modelObject.Code = this.lastCode;
    this.modelObject.SupplierId = this.purchaseOrderHeader.SupplierId;
    this.modelObject.SupplierNameAr = this.purchaseOrderHeader.SupplierNameAr;
    this.modelObject.SupplierNameEn = this.purchaseOrderHeader.SupplierNameEn;
    this.modelObject.StoreDataId = this.purchaseOrderHeader.StoreDataId;
    this.modelObject.StoreNameAr = this.purchaseOrderHeader.StoreNameAr;
    this.modelObject.StoreNameEn = this.purchaseOrderHeader.StoreNameEn;
    this.modelObject.BranchId = this.getStoreDataById(this.modelObject.StoreDataId).BranchId;
    this.modelObject.BranchNameAr = this.purchaseOrderHeader.BranchNameAr;
    this.modelObject.BranchNameEn = this.purchaseOrderHeader.BranchNameEn;
    this.modelObject.Lang = this.lang;
    let date = new Date(this.purchaseOrderHeader.Date);
    this.modelObject.Date = date;
    this.modelObject.Month = date.getMonth();
    this.modelObject.Year = date.getFullYear();

    this.modelObject.Description = this.purchaseOrderHeader.Description;
    this.modelObject.IsPosted = this.purchaseOrderHeader.IsPosted;
    this.modelObject.Items = tempList;
    this.modelObject.TotalValue = this.calculateTotalItemValue();


    console.log(this.modelObject);
    this.executeAddObject();
  }

  executeAddObject() {
    this.modelService.insertWithItems(this.modelObject).subscribe(result => {
      if (result === true || result > 0) {
        this.tableEngine.loadData();
        this.currentAction = '';
        this.tableEngine.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyAdded"));
        if (this.sideModalRef === undefined) {
          if (this.smallModal != undefined) {
            this.tableEngine.smallModal.hide();
          }
          else {
            this.tableView = this.prevAction === 'AddThenNew' ? false : true;

            this.prevAction = '';
          }
        }
        else {
          this.tableEngine.sideModalRef.modalRef.hide();
        }

        if(this.prevAction != 'AddThenNew'){
          this.goBack();
        }
      }
      else {
        this.tableEngine.toastError(this.translate.instant("Error"), this.translate.instant("FailedAdd"));
        this.currentAction = '';
      }
    })
  }

  editObject() {
    this.currentAction = 'loading';
    this.tableEngine.savedPage = this.tableEngine.currentPage;

    let tempList: RequestReceiptItemModel[] = [];
    let tempItem: RequestReceiptItemModel;
    let debugId = 1;
    for (let index = 0; index < this.addedItemList.length; index++) {
      tempItem = new RequestReceiptItemModel();
      tempItem = this.addedItemList[index]
      //tempItem.Id = this.debugMode ? debugId : 0;
      tempItem.Id = 0;

      tempItem.RequestReceiptId = this.modelObject.Id;
      tempList.push(tempItem)

      debugId++;
    }
      console.log("edited item list",tempList);
      

      //this.calculateTotalItemValue();

      let tempObj = JSON.parse(JSON.stringify(this.modelObject));
      console.log("Original Object", this.modelObject);
      console.log("Temp Object", tempObj);
      
      
      let date = new Date(this.modelObject.Date);
      tempObj.Date = date;

      tempObj.Items = tempList;

      tempObj.Id = this.modelObject.Id;

      tempObj.Code = this.lastCode;
      tempObj.SupplierId = this.modelObject.SupplierId;
      tempObj.StoreDataId = this.modelObject.StoreDataId;
      tempObj.BranchId = this.modelObject.BranchId;;

      tempObj.Description = this.modelObject.Description;
      tempObj.IsPosted = this.modelObject.IsPosted;
      
      tempObj.TotalValue = this.calculateTotalItemValue();

      console.log("editedObject", tempObj);
      this.executeEditObject(tempObj);
  }

  executeEditObject(Object) {


    this.modelService.updateWithItems(Object).subscribe(result => {
      if (result === true) {
        this.tableEngine.loadData();
        this.currentAction = '';
        this.tableEngine.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyEdited"));

        if (this.sideModalRef === undefined) {
          if (this.smallModal != undefined) {
            this.tableEngine.smallModal.hide();
          }
          else {
            this.tableView = true;
          }
        }
        else {
          this.tableEngine.sideModalRef.modalRef.hide();
        }
        
        this.goBack();

      }
      else {
        this.tableEngine.toastError(this.translate.instant("Error"), this.translate.instant("FailedEdit"));
        this.currentAction = '';
      }
    })
  }

  //Deletion methods:
  //Customize if needed.
  //Pass the target object as parameter if the target API can only delete singular objects and not lists.
  deleteObject() {
    this.savedPage = this.currentPage;
    //prevent multiclicks. re enable after API response
    this.currentAction = 'loading';
    this.executeDeleteObject();
  }

  //Same here...
  executeDeleteObject() {
    this.modelService.deleteList(this.tableEngine.deletionList).subscribe(result => {
      if (result === true) {
        this.tableEngine.loadData();
        this.currentAction = '';
        this.tableEngine.finalizeDeletion();
      }
      else {
        this.tableEngine.toastError(this.translate.instant("Error"), this.translate.instant("FailedDelete"));
        this.currentAction = '';
      }
    })
  }
  ////////////////////////////////////////////////////////////////////////////
  //
  //
  //
  //
  //
  ////////////////////////////////////////////////////////////////////////////
  //  EXCLUSIVE :
  //  Specifically for this component.
  ///////////////////////////////
  //Get any additional data here
  SearchFrom: Date = null;
  SearchTo: Date = null;

  clearSearch() {
    this.SearchFrom = null;
    this.SearchTo = null;
  }

  prevAction = '';
  addAndNew() {
    this.prevAction = 'AddThenNew';
    this.addObject();
    this.showModal(null, 'Add');
  }

  configPurchases = new ConfigPurchasesModel();
  loadServices() {
    this.currentAction = 'loading';

    this.supplierService.getAll().subscribe(
      supplierData => {
        this.supplierList = supplierData;

        this.codingUnitService.getAll().subscribe(
          codingUnitData => {
            this.codingUnitList = codingUnitData;

            this.itemService.getAll().subscribe(
              itemData => {
                this.itemList = itemData;

                this.storeDataService.getAll().subscribe(
                  storeData => {
                    this.storeDataList = storeData;

                    this.purchaseOrderService.getAll().subscribe(
                      purchaseOrderData => {
                        this.purchaseOrderList = purchaseOrderData;

                        this.configPurchasesService.getFirst().subscribe(
                          configPurchasesData => {
                            this.configPurchases = configPurchasesData;
        
                            this.tableEngine.loadData();
                          }
                        )
                      }
                    )
                  }
                )
              }
            )
          }
        )
      }
    )


  }
  
  addedItemList: RequestReceiptItemModel[] = [];
  codingUnitList: CodingUnitModel[] = [];
  itemList: any[] = [];
  storeDataList: StoreDataModel[] = [];
  supplierList: SupplierModel[] = [];
  purchaseOrderList: PurchaseOrderModel[] = [];

  getItemById(Id: any): any {
    return this.itemList.find(i => i.Id === Id);
  }
  getUnityById(Id: any): any {
    return this.codingUnitList.find(i => i.Id === Id);
  }
  getStoreDataById(Id: any): any {
    return this.storeDataList.find(i => i.Id === Id);
  }
  getSupplierById(Id: any): any {
    return this.supplierList.find(i => i.Id === Id);
  }
  getPurchaseOrderById(Id: any): any {
    return this.purchaseOrderList.find(i => i.Id === Id);
  }

  requestReceiptItemForm: FormGroup = new FormGroup({
    Id: new FormControl(),
    ItemId: new FormControl(),
    //UnitId: new FormControl(),
    Quantity: new FormControl(),
    UnitCost: new FormControl(),
    //TotalPrice: new FormControl(),
  });
  createRequestReceiptItemForm(requestReceiptItemObject, formBuilder): FormGroup {
    return formBuilder.group({
      Id: [requestReceiptItemObject.Id >= 0 ? requestReceiptItemObject.Id : 0],
      ItemId: [Validators.compose([Validators.required])],
      Quantity: [Validators.compose([Validators.required, Validators.pattern(/^([1-9]+)([0-9])*(\.[0-9]+)?$/)])],
      UnitCost: [Validators.compose([Validators.required, Validators.pattern(/^([1-9]+)([0-9])*(\.[0-9]+)?$/)])],
    });
  }
  newRequestReceiptItem: RequestReceiptItemModel = new RequestReceiptItemModel();
  newRequestReceiptItemInternalId = 0;

  addRequestReceiptItem(): any {
    let temp = new RequestReceiptItemModel({
      Id: this.newRequestReceiptItemInternalId,
      ItemId: this.requestReceiptItemForm.getRawValue().ItemsHeaderId,
      UnitId: this.newRequestReceiptItem.UnitId,
      Quantity: this.requestReceiptItemForm.getRawValue().Quantity,
      UnitCost: this.requestReceiptItemForm.getRawValue().UnitCost,
      TotalPrice: this.requestReceiptItemForm.getRawValue().UnitCost * this.requestReceiptItemForm.getRawValue().Quantity,
    });
    this.addedItemList = this.addedItemList.concat(temp)
    this.newRequestReceiptItemInternalId = this.newRequestReceiptItemInternalId + 1;


    this.newRequestReceiptItem = new RequestReceiptItemModel();

    
    this.requestReceiptItemForm = this.createRequestReceiptItemForm(this.newRequestReceiptItem, this.formBuilder);
    
    this.calculateTotalItemValue();
  }
  removeRequestReceiptItem(Id: any): any {

    let index = this.addedItemList.findIndex(x => x.Id === Id);
    this.addedItemList.splice(index, 1);

    this.calculateTotalItemValue();
  }
  // changeItem(): any {

  //   this.newRequestReceiptItem.ItemsHeaderId = this.requestReceiptItemForm.getRawValue().ItemsHeaderId;
  //   this.newRequestReceiptItem.UnitTypeId = this.getItemById(this.requestReceiptItemForm.getRawValue().ItemsHeaderId).CodingUnitId;

  //   this.calculateTotalItemValue();
  // }
  currentTotal = 0;
  calculateTotalItemValue() {
    console.log("calculating");
    
    let total = 0;
    this.addedItemList.forEach(item => {
      if(item.Quantity > item.MaxQuantity){
        item.Quantity = item.MaxQuantity
      }
      item.TotalPrice = item.Quantity * item.UnitCost;
      total += item.TotalPrice;
    });
    this.currentTotal = total;
    console.log(this.currentTotal);
    return total;
  }
  goBack(){
    console.log("back");
    
    this.modelObject = {};
    //this.createForm(this.modelObject, this.formBuilder);
    this.addedItemList = [];
    this.currentTotal = 0;
    this.newRequestReceiptItem = {};
    this.purchaseOrderHeader = {};
    this.currentAction = "";
    this.tableView = true;

    console.log(this.modelObject);
    console.log(this.lastCode);
  }
  purchaseOrderHeader: PurchaseOrderModel = new PurchaseOrderModel();
  changePurchaseOrderHeader(){
    this.addedItemList = [];

    this.purchaseOrderHeader = this.getPurchaseOrderById(this.mainForm.getRawValue().PurchaseOrderId?this.mainForm.getRawValue().PurchaseOrderId:this.modelObject.PurchaseOrderId);
    
    this.purchaseOrderService.getByIdWithItems(this.purchaseOrderHeader.Id).subscribe(
      detailedObject => { 
        this.purchaseOrderHeader = {...detailedObject};
        this.modelObject = JSON.parse(JSON.stringify(detailedObject));
        this.addedItemList = JSON.parse(JSON.stringify(detailedObject.Items));
        console.log(this.modelObject);
        console.log(this.addedItemList);
        this.addedItemList.forEach(element => {
          element.MaxQuantity = element.Quantity;
          console.log(element.MaxQuantity);
          
        });
        this.calculateTotalItemValue();
      }
    );
  }

  //lastCode = 0;
  codeWasChecked = false;
  checkCode() : boolean{
    if(!(this.lastCode > 0)){
      this.codeWasChecked = false;
      console.log("bad code", this.lastCode);
      
      return this.codeWasChecked;
    }
    this.configPurchasesService.checkRequestReceiptCode(this.lastCode).subscribe(
        result => {
          this.codeWasChecked = result;
        }
      );
      console.log("code availability:",this.codeWasChecked);
      
    return this.codeWasChecked;
  }
  ////////////////////////////////////////////////////////////////////////////
  //
  //
  //
  //
  //
  ////////////////////////////////////////////////////////////////////////////
  //  CONSTANT :
  //  Do not change.
  ///////////////////////////////

  prepChangeSort(sortKey) {
    this.SearchFrom = null;
    this.SearchTo = null;
    
    this.tableEngine.changeSort(this.tableEngine.getSort(sortKey));
    this.itemOrder = this.tableEngine.itemOrder;
  }

}
