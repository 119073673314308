import { CashierSessionModel } from "../cashier-sessions/CashierSessionModel";

export class CashRegisterModel {
    Id?: number;
    Code?: number;
    BranchId?: number;
    BranchNameAr?: string;
    BranchNameEn?: string;
    Sessions?: CashierSessionModel [];
    LastDailyRevenue?: number;
    LastMonthlyRevenue?: number;
    LastAnnualRevenue?: number;
    TotalRevenue?: number;
    LastActivityDate?: Date;
    LastUserId?: number;
    LastUserNameAr?: string;
    LastUserNameEn?: string;
    constructor(obj?: CashRegisterModel) {
        obj = obj ? obj : {};
        this.Id = obj.Id ? obj.Id : 0;
        this.Code = obj.Code ? obj.Code : 0;
        this.BranchId = obj.BranchId ? obj.BranchId : 0;
        this.BranchNameAr = obj.BranchNameAr ? obj.BranchNameAr : '';
        this.BranchNameEn = obj.BranchNameEn ? obj.BranchNameEn : '';
        this.Sessions = obj.Sessions ? obj.Sessions : [];
        this.LastDailyRevenue = obj.LastDailyRevenue ? obj.LastDailyRevenue : 0;
        this.LastMonthlyRevenue = obj.LastMonthlyRevenue ? obj.LastMonthlyRevenue : 0;
        this.LastAnnualRevenue = obj.LastAnnualRevenue ? obj.LastAnnualRevenue : 0;
        this.TotalRevenue = obj.TotalRevenue ? obj.TotalRevenue : 0;
        this.LastActivityDate = obj.LastActivityDate ? obj.LastActivityDate : new Date();
        this.LastUserId = obj.LastUserId ? obj.LastUserId : 0;
        this.LastUserNameAr = obj.LastUserNameAr ? obj.LastUserNameAr : '';
        this.LastUserNameEn = obj.LastUserNameEn ? obj.LastUserNameEn : '';
    }

}