<!--component html goes here -->
<Form [formGroup]="HeaderFormGroup">
    <div class="card">
        <div class="form-group  Card_Padding">
                    <br>
    
       <div class="row">
        <div class="col-md-10">
        <h3>{{ 'AccountBalance' | translate }}</h3>
    
        </div>
    
        <button type="button" class="btn btn-primary"(click)="onClick()">{{ 'Save' | translate }}</button>{{' '}}
    
       </div>
            <br>
            <!-- <div class="row">
                <div class=" col-md-4">
                    <label for="inputState">{{ 'company Datum' | translate }}</label>
                    <select formControlName="TypeDropdwon" id="inputState" class="form-control">
                        <option value="Invoive" >{{'Invoive'|translate}}</option>
                        <option value="Discount notice" >{{'Discount notice'|translate}}</option>
                        <option value="Add notice" >{{'Add notice'|translate}}</option>
                    </select>
                </div>
                <div class=" col-md-4">
                    <label for="inputState">{{ 'project' | translate }}</label>
                    <select formControlName="company" id="inputState" class="form-control">
                        <option value="Discount notice" >projrect0</option>
                        <option value="Discount notice" >projrect1</option>
                    </select>
                </div>
                <div class=" col-md-4">
                    <label for="inputState">{{ 'project' | translate }}</label>
                    <select formControlName="company" id="inputState" class="form-control">
                        <option value="Discount notice" >projrect0</option>
                        <option value="Discount notice" >projrect1</option>
                    </select>
                </div>
            </div>
            <br> -->
            <div class="form-row">
    
                <div class=" col-md-6">
                    <label for="inputState">{{ 'Balance Name' | translate }}</label>
                    <select formControlName="BalanceNameDropdwon" id="inputState" class="form-control">
                        <option *ngFor="let Item of accountsTypeModel" value="Item.Id" >{{lang==='ar-EG'?Item.NameAr:Item.NameEn}}</option>
        
                    </select>
                </div>
                <div class="form-group col-md-6">
                    <label for="inputEmail4">{{ 'start Date' | translate }}</label>
                    <input formControlName="startDate" type="date" id="inputEmail4" class="form-control">
                </div>
               
            </div>
        </div>
    
    </div>
</Form>
    <br>
    <br>
    <div class="card">
        <div class="card-body">
            <!-- 
                [bindValue]="Id" 
    
                   (add)="OnAddSelectedItemTax($event)"
            (remove)="OnRemoveSelectedItemTax($event)"
                
                <h5 class="card-title">Basic Table</h5> -->
            <div class="col-12 ">
           
              </div>
            <div class=" col-md-8">
                <!-- <label for="inputState">{{ 'Account Name' | translate }}</label> -->
                <!-- <ng-select  [items]="AccountsNameModel"  [hideSelected]="true" multiple="true" bindLabel="NameEn"
                (remove)="OnRemoveSelectedItemFunc($event)"

                (add)="OnAddSelectedItemFunc($event)"
                [(ngModel)]="SelectedAccountBalanceFooterModel"
                >
              </ng-select> -->
                <!-- <select formControlName="TypeDropdwon" id="inputState" class="form-control">
                    <option *ngFor="let Item of AccountsNameModel" value="Item.Id" >{{Item.NameEn}}</option>
    
                </select> -->
            </div>
            <br>

            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">{{'Account'| translate}}</th>
                        <th scope="col">{{'Debtor'| translate}}</th>
                        <th scope="col">{{'Creditor'| translate}}</th>
     
                    </tr>
                </thead>
                <tbody>
                    <!-- [(ngModel)]="item.quantity" +
                        [(ngModel)]="item.discount"
                        <div class="glyph ng-star-inserted">
                            <div class="glyph-icon simple-icon-plus"></div><div class="class-name">simple-icon-plus</div></div>
                    
                    *ngFor=" let item of ItemModelList"
                     (change)="changeDescount(item)"
                     formControlName="quantity" 
                        -->
                    
                    <tr  *ngFor="let Item of SelectedAccountBalanceFooterModel">
                        <th scope="row">{{Item.Id}}</th>
                        <td>{{lang==='ar-EG'?Item.NameAr:Item.NameEn}}</td>
                        <td><input [(ngModel)]="Item.Debit" (change)="changeDebtor(Item,$event)"type="number" class="form-control"></td>
                        <td><input [(ngModel)]="Item.Credit" (change)="changeCredit(Item,$event)"  type="number" class="form-control "></td>
                      
                    </tr>
                    <!-- <td>{{'Tota'}}</td>
                    <td>{{TotalDebtor}}</td>
                    <td>{{TotalCreditor}}</td> -->
                  
                </tbody>
            </table>

            <br>
            <table class="table">

                <tbody>
            
                    <tr>
                        <th scope="row">{{ 'Total' | translate }}</th>
                        <td>{{TotalDebtor}}</td>
                        <td>{{TotalCreditor}}</td>
                    </tr>
             
                </tbody>
            </table>

        </div>
    
    </div>
    
