import { Component } from '@angular/core';

@Component({
    // moduleId: module.id,
    selector: 'invintory-dashbord',
    templateUrl: 'invintory-dashbord.component.html',
    styleUrls: ['invintory-dashbord.component.scss']
})
export class InvintoryDashbordComponent {

}
