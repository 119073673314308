export class CountryModel{
    Id?: number;
    NameAr?: string;
    NameEn?: string;
    Code?: string;
    constructor(item?: CountryModel) {
        item = item ? item : {};
        this.Id = item.Id ? item.Id : 0;
        this.NameAr = item.NameAr ? item.NameAr : '';
        this.NameEn = item.NameEn ? item.NameEn : '';
        this.Code = item.Code ? item.Code : '';
    }
}