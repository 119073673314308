import { environment } from 'src/environments/environment';
import { UserRole } from '../shared/auth.roles';
const adminRoot = environment.adminRoot;


const basePath = environment.basePath;
const invoicesPath = environment.invoicesPath;
const AccountingPath = environment.accountPath;
const InventoryPath = `${environment.basePath}/Inventory`;
const membershipsPath = `${environment.membershipsPath}`;
const healthCarePath = `${environment.healthCarePath}`;
const myPharmacyPath = `${environment.myPharmacyPath}`;
const myCouncilAndMembers =`${environment.myCouncilAndMembers}`;
const HRPath = `${environment.hrPath}`;
export interface IMenuItem {
  id?: string;
  icon?: string;
  label: string;
  to: string;
  imgurl?:string;
  newWindow?: boolean;
  subs?: IMenuItem[];
  roles?: UserRole[];
}

const data: IMenuItem[] = [
  // {
  //   imgurl:'assets/icons/line-chart.png',
  //   icon: '',
  //   label: 'dashboards',
  //   to: `${adminRoot}/dashboards`,
  //   // roles: [UserRole.Admin, UserRole.Editor],
  //   subs: [
  //     {
  //       icon: 'simple-icon-pie-chart',
  //       label: 'home',
  //       to: `${adminRoot}/dashboards/default`,
  //       // roles: [UserRole.Admin],
  //     },
  //     {
  //       icon: 'simple-icon-pie-chart',
  //       label: 'accounts',
  //       to: `${adminRoot}/dashboards/analytics`,
  //       // roles: [UserRole.Admin],
  //     },
  //     {
  //       icon: 'simple-icon-basket-loaded',
  //       label: 'sales',
  //       to: `${adminRoot}/dashboards/ecommerce`,
  //       // roles: [UserRole.Admin, UserRole.Editor],
  //     },
  //     {
  //       icon: 'simple-icon-doc',
  //       label: 'stores',
  //       to: `${adminRoot}/dashboards/content`,
  //       // roles: [UserRole.Admin, UserRole.Editor],
  //     },
  //   ],
  // },
  {
    imgurl:'assets/icons/gantt-chart.png',
    icon: 'iconsminds-speach-bubble-13',
    label: 'Home',
    to: './aio/start',
    // roles: [UserRole.Admin, UserRole.Editor],
  
  },
  // {
  //   imgurl:'assets/icons/chat.png',
  //   icon: 'iconsminds-speach-bubble-13',
  //   label: 'chat',
  //   to: ``,
  //   // roles: [UserRole.Admin, UserRole.Editor],
  
  // },
  {
    imgurl:'assets/icons/account.png',
    icon: 'iconsminds-calendar-4',
    label: 'accounts',
    to: `${AccountingPath}`,
    // roles: [UserRole.Admin, UserRole.Editor],
    subs: [
      //old Menu
      // {
      //   icon: 'simple-icon-doc',
      //   label: 'MainData',
      //   to: `${AccountingPath}/MainData`,
      //   subs: [
      //     {
      //       icon: 'iconsminds-conference',
      //       label: 'ExpensessType',
      //       to: `${AccountingPath}/ExpensessType`
      //     },
      //     {
      //       icon: 'iconsminds-box-close',
      //       label: 'AccountFications',
      //       to: `${AccountingPath}/AccountFications`,
      //     },
      //     {
      //       icon: 'iconsminds-tag',
      //       label: 'AccountMenu',
      //       to: `${AccountingPath}/AccountMenu`,
      //     },
      //     {
      //       icon: 'iconsminds-project',
      //       label: 'Daily Restrictions',
      //       to: `${AccountingPath}/all-daily`,
      //     },
      //     {
      //       icon: 'simple-icon-basket',
      //       label: 'CheckTypes',
      //       to: `${AccountingPath}/CheckTypes`,
      //     },
      //     {
      //       icon: 'iconsminds-engineering',
      //       label: 'CheckStatuses',
      //       to: `${AccountingPath}/CheckStatuses`,
      //     },
      //     {
      //       icon: 'iconsminds-conference',
      //       label: 'AccountType',
      //       to: `${AccountingPath}/AccountType`
      //     },
      //     {
      //       icon: 'iconsminds-box-close',
      //       label: 'BankCode',
      //       to: `${AccountingPath}/BankCode`,
      //     },
      //     {
      //       icon: 'iconsminds-tag',
      //       label: 'CreatCountry',
      //       to: `${AccountingPath}/CreatCountry`,
      //     },
      //     {
      //       icon: 'iconsminds-project',
      //       label: 'CreatGovernorate',
      //       to: `${invoicesPath}/CreatGovernorate`,
      //     },
      //     {
      //       icon: 'simple-icon-basket',
      //       label: 'CreatGovernorate',
      //       to: `${AccountingPath}/CreatGovernorate`,
      //     },
      //     {
      //       icon: 'iconsminds-engineering',
      //       label: 'CityCode',
      //       to: `${AccountingPath}/CityCode`,
      //     },

      //     {
      //       icon: 'iconsminds-tree-3',
      //       label: 'Accounting guide',
      //       to: `${AccountingPath}/Accounts`,
      //     },

      //     {
      //       icon: 'iconsminds-data-center',
      //       label: 'Cost Centers',
      //       to: `${AccountingPath}/CostCenter`,
      //     },
      //     {
      //       icon: 'simple-icon-exclamation',
      //       label: 'Expenses',
      //       to: `${AccountingPath}/Expenses`,
      //     },

      //     {
      //       icon: 'iconsminds-reverbnation',
      //       label: 'Revenues',
      //       to: `${AccountingPath}/Revenues`,
      //     },
      //     {
      //       icon: 'iconsminds-calendar-4',
      //       label: 'Balance Sheet',
      //       to: `${AccountingPath}/BalanceSheet`,
      //     },
      //     {
      //       icon: 'iconsminds-receipt-4',
      //       label: 'Catch Receipt Categories',
      //       to: `${AccountingPath}/catch-receipt-categories`,
      //     },
      //     {
      //       icon: 'iconsminds-recycling-2',
      //       label: 'Assets',
      //       to: `${AccountingPath}/assets`,
      //     },
      //     {
      //       icon: 'iconsminds-bank',
      //       label: 'Treasury And Bank Accounts',
      //       to: `${AccountingPath}/treasury-and-bank-accounts`,
      //     },
      //     {
      //       icon: 'iconsminds-mail-2',
      //       label: 'Catch Receipt',
      //       to: `${AccountingPath}/catch-receipt`,
      //     },
      //   ]
      //   // roles: [UserRole.Admin],
      // },
      {
        icon: 'simple-icon-doc',
        label: 'Finance',
        to: `${AccountingPath}`,
        subs: [
          {
            icon: 'iconsminds-conference',
            label: 'ExpensessType',
            to: `${AccountingPath}/ExpensessType`
          },
         
          {
            icon: 'simple-icon-exclamation',
            label: 'Expenses',
            to: `${AccountingPath}/expenses`,
          },
          {
            icon: 'iconsminds-receipt-4',
            label: 'Catch Receipt Categories',
            to: `${AccountingPath}/catch-receipt-categories`,
          },
          {
            icon: 'iconsminds-mail-2',
            label: 'Catch Receipt',
            to: `${AccountingPath}/catch-receipt`,
          },
          {
            icon: 'iconsminds-bank',
            label: 'Treasury And Bank Accounts',
            to: `${AccountingPath}/treasury-and-bank-accounts`,
          },
       
        ]
        // roles: [UserRole.Admin],
      },
      {
        icon: 'simple-icon-doc',
        label: 'General Accounts',
        to: `${AccountingPath}/all-daily`,
        subs: [
          
          {
            icon: 'iconsminds-project',
            label: 'Daily Restrictions',
            to: `${AccountingPath}/all-daily`,
          },
         

          {
            icon: 'iconsminds-tree-3',
            label: 'Accounting guide',
            to: `${AccountingPath}/Accounts`,
          },

          {
            icon: 'iconsminds-data-center',
            label: 'Cost Centers',
            to: `${AccountingPath}/CostCenter`,
          },
        
          {
            icon: 'iconsminds-recycling-2',
            label: 'Assets',
            to: `${AccountingPath}/assets`,
          },
          {
            icon: 'iconsminds-recycling-2',
            label: 'Payment Plans',
            to: `${AccountingPath}/payment-plan`,
          },
          
        ]
        // roles: [UserRole.Admin],
      },
      {
        icon: 'simple-icon-doc',
        label: 'Check cycle',
        to: `${AccountingPath}/checks-received`,
        subs: [
          {
            icon: 'iconsminds-conference',
            label: 'Checks Received',
            to: `${AccountingPath}/checks-received`
          },
          {
            icon: 'iconsminds-box-close',
            label: 'Check Book',
            to: `${AccountingPath}/check-book`,
          },
          {
            icon: 'iconsminds-tag',
            label: 'Paid Checks',
            to: `${AccountingPath}/paid-checks`,
          }
        ]
        // roles: [UserRole.Admin],
      },
      {
        icon: 'simple-icon-doc',
        label: 'Budgets',
        to: `${AccountingPath}/budgets`,
        subs: [
          {
            icon: 'iconsminds-box-close',
            label: 'Monthly Distribution',
            to: `${AccountingPath}/monthly-distribution`,
          },
          {
            icon: 'iconsminds-conference',
            label: 'Budgets',
            to: `${AccountingPath}/budgets`
          },
          {
            icon: 'iconsminds-conference',
            label: 'Budgets report',
            to: `${AccountingPath}/budget-report`
          },
          
          
        ]
        // roles: [UserRole.Admin],
      },
      
      {
        icon: 'simple-icon-doc',
        label: 'Reports',
        to: `${AccountingPath}/income-list`,
        subs: [
        
          {
            icon: 'iconsminds-box-close',
            label: 'Daily Restrictions Report',
            to: `${AccountingPath}/daily-restrictions-report`,
          },
          {
            icon: 'iconsminds-tag',
            label: 'Cost Center Report',
            to: `${AccountingPath}/cost-center-report`,
          },  {
            icon: 'iconsminds-conference',
            label: 'Income List',
            to: `${AccountingPath}/income-list`
          },
          {
            icon: 'iconsminds-conference',
            label: 'Balance Sheet',
            to: `${AccountingPath}/BalanceSheet`
            
          },
          {
            icon: 'iconsminds-conference',
            label: 'Assets Report',
            to: `${AccountingPath}/assets-report`
            
          },
          {
            icon: 'iconsminds-conference',
            label: 'Trial Balance Balances Report',
            to: `${AccountingPath}/trial-balance-balances`
            
          },
          {
            icon: 'iconsminds-conference',
            label: 'Ledger Account',
            to: `${AccountingPath}/ledger-account`
            
          },
          {
            icon: 'iconsminds-conference',
            label: 'Taxes Report',
            to: `${AccountingPath}/tax-report`
            
          }
          ,
          {
            icon: 'iconsminds-conference',
            label: 'Taxes Return Report',
            to: `${AccountingPath}/tax-return-report`
            
          }
          ,
          {
            icon: 'iconsminds-conference',
            label: 'Cash Flows',
            to: `${AccountingPath}/cash-flows`
            
          }
          
        ]
        // roles: [UserRole.Admin],
      },
      {
        icon: 'simple-icon-doc',
        label: 'Settings',
        to: `${AccountingPath}/fiscal-years`,
        subs: [
        
          {
            icon: 'iconsminds-box-close',
            label: 'Fiscal Years',
            to: `${AccountingPath}/fiscal-years`,
          },
      
        ]
        // roles: [UserRole.Admin],
      },
    ]
  },
  //K
  ////////////////////////////////////////////////////////////////////////////
  
  {
    imgurl: 'assets/icons/Construction.png',
    icon: 'iconsminds-calendar-4',
    label: 'Projects',
    to: `${basePath}/Construction`,
    // roles: [UserRole.Admin, UserRole.Editor],
    subs: [
      {
        icon: 'iconsminds-user',
        label: 'Contractors',
        to: `${basePath}/Construction/Contractors`
      },
      {
        icon: 'iconsminds-box-close',
        label: 'WorkItems',
        to: `${basePath}/Construction/Work-Items`
      },
      {
        icon: 'iconsminds-box-close',
        label: 'Projects',
        to: `${basePath}/Construction/projects`
      },
      {
        icon: 'iconsminds-box-close',
        label: 'Clients Extracts',
        to: `${basePath}/Construction/client-business-extracts`
      },
      {
        icon: 'iconsminds-box-close',
        label: 'Contractors Extracts',
        to: `${basePath}/Construction/contractors-business-extracts`
      },
    ]
  },
  // {
  //   imgurl:'assets/icons/accounting.png',
  //   icon: 'iconsminds-calendar-4',
  //   label: 'Companies',
  //   to: `${basePath}/Companies`,
  //   // roles: [UserRole.Admin, UserRole.Editor],
  //   subs: [
     
  //   ],
  // },
  // {
  //   imgurl:'assets/icons/receipt.png',
  //   icon: 'simple-icon-doc',
  //   label: 'e-invoice',
  //   to: `${invoicesPath}`,
  //   // roles: [UserRole.Admin, UserRole.Editor],
  //   subs: [
  //     {
  //       icon: 'simple-icon-doc',
  //       label: 'MainData',
  //       to: `${invoicesPath}/MainData`,
  //       subs: [
  //         {
  //           icon: 'iconsminds-conference',
  //           label: 'Vendors',
  //           to: `${invoicesPath}/MainData/Vendors`
  //         },
  //         {
  //           icon: 'iconsminds-box-close',
  //           label: 'Items',
  //           to: `${invoicesPath}/MainData/Items`,
  //         },
  //         {
  //           icon: 'iconsminds-tag',
  //           label: 'ProductTypes',
  //           to: `${invoicesPath}/MainData/Product-Types`,
  //         },
  //         // {
  //         //   icon: 'iconsminds-project',
  //         //   label: 'Projects',
  //         //   to: `${invoicesPath}/MainData/Projects`,
  //         // },
  //         {
  //           icon: 'simple-icon-basket',
  //           label: 'Purchases',
  //           to: `${invoicesPath}/MainData/Purchases`,
  //         },
  //         {
  //           icon: 'iconsminds-engineering',
  //           label: 'Staff',
  //           to: `${invoicesPath}/MainData/Staff`,
  //         },
  //       ]
  //       // roles: [UserRole.Admin],
  //     },
  //     {
  //       icon: 'simple-icon-wallet',
  //       label: 'Clients',
  //       to: `${invoicesPath}/Clients`,
  //       subs: [
  //         {
  //           icon: 'simple-icon-people ',
  //           label: 'AllClients',
  //           to: `${invoicesPath}/Clients/All-Clients`
  //         },
  //         {
  //           icon: 'iconsminds-factory',
  //           label: 'Branches',
  //           to: `${invoicesPath}/Clients/Branches`
  //         },
  //         {
  //           icon: 'iconsminds-dollar',
  //           label: 'Currencies',
  //           to: `${invoicesPath}/Clients/Currencies`
  //         },
  //         {
  //           icon: 'iconsminds-dashboard',
  //           label: 'CodingUnits',
  //           to: `${invoicesPath}/Clients/Coding-Units`
  //         },
  //         {
  //           icon: 'iconsminds-coins',
  //           label: 'TaxTypes',
  //           to: `${invoicesPath}/Clients/Tax-Types`
  //         }
  //       ]
  //       // roles: [UserRole.Admin],
  //     },
  //     {
  //       icon: 'simple-icon-wallet',
  //       label: 'Suppliers',
  //       to: `${invoicesPath}/Supplier/Show-ExpensesAndPurchasesComponent`,
  //       subs: [
  //         {
  //           icon: 'simple-icon-people ',
  //           label: 'Projects',
  //           to: `${invoicesPath}/Supplier/Show-ProjectViewComponent`
  //         },
  //         {
  //           icon: 'iconsminds-factory',
  //           label: 'ExpensCategory',
  //           to: `${invoicesPath}/Supplier/Show-ExpensCategoryComponent`
  //         },
  //         {
  //           icon: 'iconsminds-dollar',
  //           label: 'Suppliers',
  //           to: `${invoicesPath}/Supplier/Show-SuppliersComponent`
  //         },
  //         {
  //           icon: 'iconsminds-dashboard',
  //           label: 'Expenses_And_Purchases_Header',
  //           to: `${invoicesPath}/Supplier/Show-ExpensesAndPurchasesComponent`
  //         },
     
  //       ]
  //       // roles: [UserRole.Admin],
  //     },
  //     {
  //       icon: 'simple-icon-wallet',
  //       label: 'Reports',
  //       to: `${invoicesPath}/Reports`,
  //       subs: [
  //         {
  //           icon: 'iconsminds-receipt-4',
  //           label: 'Invoices',
  //           to: `${invoicesPath}/Reports/Invoices`
  //         },
  //         {
  //           icon: 'iconsminds-line-chart-1',
  //           label: 'Sales',
  //           to: `${invoicesPath}/Reports/Sales`
  //         },
  //         {
  //           icon: 'iconsminds-billing',
  //           label: 'Expenses',
  //           to: `${invoicesPath}/Reports/Expenses`
  //         }
  //       ]
  //       // roles: [UserRole.Admin],
  //     },
  //     {
  //       icon: 'simple-icon-wallet',
  //       label: 'Settings',
  //       to: `${invoicesPath}/Settings`,
  //       subs: [
  //         {
  //           icon: 'simple-icon-settings',
  //           label: 'TaxingApiSettings',
  //           to: `${invoicesPath}/Settings/TaxingApi`
  //         }
  //       ]
  //       // roles: [UserRole.Admin],
  //     },
  //     // {
  //     //   icon: 'simple-icon-wallet',
  //     //   label: 'Clients',
  //     //   to: `${basePath} /Clients`,
  //     //   subs: [
  //     //     {
  //     //       icon: 'simple-icon-wallet',
  //     //       label: 'Clients',
  //     //       to: `${basePath} /Clients/All`
  //     //     },
  //     //     {
  //     //       icon: 'simple-icon-wallet',
  //     //       label: 'Branches',
  //     //       to: `${basePath} /Clients/Branches`
  //     //     },
  //     //     {
  //     //       icon: 'simple-icon-wallet',
  //     //       label: 'Currencies',
  //     //       to: `${basePath} /Clients/Currencies`
  //     //     }
  //     //   ]
  //     //   // roles: [UserRole.Admin],
  //     // },
  //     // {
  //     //   icon: 'simple-icon-wallet',
  //     //   label: 'Currencies',
  //     //   to: `${basePath} /Currencies`,
  //     //   // roles: [UserRole.Admin],
  //     // },
  //     // {
  //     //   icon: 'simple-icon-people',
  //     //   label: 'Clients',
  //     //   to: `${basePath} /Clients`,
  //     //   // roles: [UserRole.Admin],
  //     // },
  //     // {
  //     //   icon: 'simple-icon-organization',
  //     //   label: 'Branches',
  //     //   to: `${basePath} /Branches`,
  //     //   // roles: [UserRole.Admin],
  //     // },
  //     // {
  //     //   icon: 'iconsminds-box-close',
  //     //   label: 'Items',
  //     //   to: `${basePath} /Items`,
  //     //   // roles: [UserRole.Admin],
  //     // },
  //     // {
  //     //   icon: 'iconsminds-receipt-4',
  //     //   label: 'Invoices',
  //     //   to: `${basePath} /Invoices`,
  //     //   // roles: [UserRole.Admin],
  //     // },
  //     // {
  //     //   icon: 'iconsminds-add',
  //     //   label: 'CreateInvoice',
  //     //   to: `${basePath} /Create-Invoice`,
  //     //   // roles: [UserRole.Admin],
  //     // }
     
  //   ],
  // },
  ///////////////////////////////////////////////////////////////
  /*
  
  {
    imgurl:'assets/icons/receipt.png',
    icon: 'simple-icon-doc',
    label: 'e-invoice',
    to: `${adminRoot}/E-Invoice`,
    // roles: [UserRole.Admin, UserRole.Editor],
    subs: [
      {
        icon: 'simple-icon-doc',
        label: 'E-Invoice',
        to: `${adminRoot} /default`,
        // roles: [UserRole.Admin],
      },
     
    ],
  },
  */
  
 


  {
    imgurl: 'assets/icons/Purchases.png',
    icon: 'iconsminds-calendar-4',
    label: 'Purchases',
    to: `${basePath}/Purchases`,
    // roles: [UserRole.Admin, UserRole.Editor],
    subs: [
      {
        icon: 'iconsminds-conference',
        label: 'Vendors',
        to: `${basePath}/Purchases/Vendors`
      },
      {
        icon: 'iconsminds-shopping-cart',
        label: 'PurchaseOrders',
        to: `${basePath}/Purchases/Purchase-Orders`
      },
      {
        icon: 'iconsminds-receipt-4',
        label: 'RequestReceipts',
        to: `${basePath}/Purchases/Request-Receipts`
      },
      {
        icon: 'iconsminds-testimonal',
        label: 'PurchaseInvoices',
        to: `${basePath}/Purchases/Purchase-Invoices`
      },
      {
        icon: 'simple-icon-action-undo',
        label: 'ReturnedPurchases',
        to: `${basePath}/Purchases/Returned-Purchases`
      },
      {
        icon: 'iconsminds-cart-quantity',
        label: 'QuotationRequests',
        to: `${basePath}/Purchases/Quotation-Requests`
      },
      {
        icon: 'iconsminds-checkout',
        label: 'PurchaseQuotations',
        to: `${basePath}/Purchases/Purchase-Quotations`
      },
      {
        icon: 'iconsminds-gears',
        label: 'ConfigPurchases',
        to: `${basePath}/Purchases/Config`
      },
      {
        icon: 'simple-icon-basket',
        label: 'Purchases',
        to: `${invoicesPath}/MainData/Purchases`,
      },
      {
        icon: 'simple-icon-wallet',
        label: 'Reports',
        to: `${basePath}/Reports`,
        subs: [
          {
            icon: 'simple-icon-settings',
            label: 'Purchases By Supplier',
            to: `${basePath}/Purchases/Reports/purchases-by-supplier`
          },{
            icon: 'simple-icon-settings',
            label: 'Suppliers Directory',
            to: `${basePath}/Purchases/Reports/suppliers-directory`
          },{
            icon: 'simple-icon-settings',
            label: 'Suppliers Balances',
            to: `${basePath}/Purchases/Reports/suppliers-balances`
          }
          ,{
            icon: 'simple-icon-settings',
            label: 'Product Purchases',
            to: `${basePath}/Purchases/Reports/product-purchases`
          }
          
        ]
        // roles: [UserRole.Admin],
      },
    ],
  },

  {
    imgurl: 'assets/icons/Sales.png',
    icon: 'iconsminds-handshake',
    label: 'Sales',
    to: `${basePath}/Sales`,
    // roles: [UserRole.Admin, UserRole.Editor],
    subs: [
     
      {
        icon: 'simple-icon-doc',
        label: 'MainData',
        to: `${invoicesPath}/MainData`,
        subs: [
          {
            icon: 'iconsminds-financial',
            label: 'Quotations',
            to: `${basePath}/Sales/Quotations`
          },
      
          // {
          //   icon: 'iconsminds-box-close',
          //   label: 'Items',
          //   to: `${invoicesPath}/MainData/Items`,
          // },
          // {
          //   icon: 'iconsminds-tag',
          //   label: 'ProductTypes',
          //   to: `${invoicesPath}/MainData/Product-Types`,
          // },
          // {
          //   icon: 'iconsminds-project',
          //   label: 'Projects',
          //   to: `${invoicesPath}/MainData/Projects`,
          // },
          // {
          //   icon: 'simple-icon-basket',
          //   label: 'Purchases',
          //   to: `${invoicesPath}/MainData/Purchases`,
          // },
          // {
          //   icon: 'iconsminds-engineering',
          //   label: 'Staff',
          //   to: `${invoicesPath}/MainData/Staff`,
          // },
        ]
        // roles: [UserRole.Admin],
      },
      {
        icon: 'simple-icon-wallet',
        label: 'Clients',
        to: `${invoicesPath}/Clients`,
        subs: [
          {
            icon: 'simple-icon-people ',
            label: 'AllClients',
            to: `${invoicesPath}/Clients/All-Clients`
          },
         
         
        ]
        // roles: [UserRole.Admin],
      },
      {
        icon: 'simple-icon-wallet',
        label: 'Suppliers',
        to: `${invoicesPath}/Supplier/Show-ExpensesAndPurchasesComponent`,
        subs: [
          // {
          //   icon: 'simple-icon-people ',
          //   label: 'Projects',
          //   to: `${invoicesPath}/Supplier/Show-ProjectViewComponent`
          // },
          // {
          //   icon: 'iconsminds-factory',
          //   label: 'ExpensCategory',
          //   to: `${invoicesPath}/Supplier/Show-ExpensCategoryComponent`
          // },
          {
            icon: 'iconsminds-receipt-4',
            label: 'Invoices',
            to: `${invoicesPath}/Reports/Invoices`
          },
        
          // {
          //   icon: 'iconsminds-dashboard',
          //   label: 'Expenses_And_Purchases_Header',
          //   to: `${invoicesPath}/Supplier/Show-ExpensesAndPurchasesComponent`
          // },
     
        ]
        // roles: [UserRole.Admin],
      },
      {
        icon: 'simple-icon-wallet',
        label: 'Reports',
        to: `${invoicesPath}/Reports`,
        subs: [
         
          {
            icon: 'iconsminds-line-chart-1',
            label: 'Sales',
            to: `${invoicesPath}/Reports/Sales`
          },
          {
            icon: 'iconsminds-line-chart-1',
            label: 'Daily Invoices',
            to: `${invoicesPath}/Reports/daily-invoices`
          },
          {
            icon: 'iconsminds-line-chart-1',
            label: 'Client Invoices',
            to: `${invoicesPath}/Reports/client-invoices`
          },
          {
            icon: 'iconsminds-line-chart-1',
            label: 'Debt Reconstruction',
            to: `${invoicesPath}/Reports/debt-reconstruction`
          },
          {
            icon: 'iconsminds-line-chart-1',
            label: 'Customer Balances',
            to: `${invoicesPath}/Reports/customer-balances`
          },
          {
            icon: 'iconsminds-line-chart-1',
            label: 'Customer Sales',
            to: `${invoicesPath}/Reports/customer-sales`
          },
          {
            icon: 'iconsminds-line-chart-1',
            label: 'Customer Payments',
            to: `${invoicesPath}/Reports/customer-payments`
          },
          {
            icon: 'iconsminds-line-chart-1',
            label: 'Customer Account Statement',
            to: `${invoicesPath}/Reports/customer-account-statement`
          },
          // {
          //   icon: 'iconsminds-billing',
          //   label: 'Expenses',
          //   to: `${invoicesPath}/Reports/Expenses`
          // }
        ]
        // roles: [UserRole.Admin],
      },
      {
        icon: 'simple-icon-wallet',
        label: 'Settings',
        to: `${invoicesPath}/Settings`,
        subs: [
          {
            icon: 'simple-icon-settings',
            label: 'TaxingApiSettings',
            to: `${invoicesPath}/Settings/TaxingApi`
          }
        ]
        // roles: [UserRole.Admin],
      },
    ],
  },
  // {
  //   imgurl:'assets/icons/shopping-list.png',
  //   icon: 'iconsminds-shopping-cart',
  //   label: 'purchases',
  //   to: `${adminRoot}/purchases`,
  //   // roles: [UserRole.Admin, UserRole.Editor],
  //   subs: [
  //     {
  //       icon: 'simple-icon-doc',
  //       label: 'purchases',
  //       to: `${adminRoot}/purchases/default`,
  //       // roles: [UserRole.Admin],
  //     },
     
  //   ],
  // },
  
  {
    imgurl:'assets/icons/Inventory.png',
    icon: 'iconsminds-calendar-4',
    label: 'Inventory',
    to: `${basePath}/Inventory`,
    // roles: [UserRole.Admin, UserRole.Editor],
    subs: [
      {
        icon: 'simple-icon-doc',
        label: 'MainData',
        to: `${InventoryPath}/MainData`,
        subs: [
          {
            icon: 'simple-icon-wallet',
            label: 'Category',
            to: `${InventoryPath}/Category`,
          },
          {
            icon: 'iconsminds-tag',
            label: 'Brand',
            to: `${InventoryPath}/Brand`,
          },
          {
            icon: 'iconsminds-factory',
            label: 'TheWarehouses',
            to: `${InventoryPath}/List-All-Warehouse`,
          },
          {
            icon: 'iconsminds-box-close',
            label: 'Productes',
            to: `${InventoryPath}/List-All-Productes`
          },
          {
            icon: 'iconsminds-conference',
            label: 'Services',
            to: `${InventoryPath}/List-All-Services`
          },
       
          {
            icon: 'iconsminds-receipt-4',
            label: 'PriceLists',
            to: `${InventoryPath}/List-All-PriceList`,
          },
          {
            icon: 'iconsminds-receipt-4',
            label: 'BillsStore',
            to: `${InventoryPath}/List-All-Bells`,
          },
          {
            icon: 'iconsminds-receipt-4',
            label: 'InventoryManagement',
            to: `${InventoryPath}/List-All-InventoryManagement`,
          },
          {
            icon: 'iconsminds-receipt-4',
            label: 'Inventory Sheet Report',
            to: `${InventoryPath}/Reports/inventory-sheet-report`,
          },
          
        ]
        // roles: [UserRole.Admin],
      },
      {
        icon: 'simple-icon-doc',
        label: 'Reports',
        to: `${InventoryPath}/Reports`,
        subs: [
          {
            icon: 'iconsminds-receipt-4',
            label: 'Inventory Sheet Report',
            to: `${InventoryPath}/Reports/inventory-sheet-report`,
          },
          {
            icon: 'iconsminds-receipt-4',
            label: 'Summary Of Inventory Operations',
            to: `${InventoryPath}/Reports/summary-of-inventory-operations`,
          },
          {
            icon: 'iconsminds-receipt-4',
            label: 'Detailed Of Inventory Operations',
            to: `${InventoryPath}/Reports/detailed-of-inventory-operations`,
          },
          {
            icon: 'iconsminds-receipt-4',
            label: 'Estimated Inventory Value',
            to: `${InventoryPath}/Reports/estimated-inventory-value`,
          },
          {
            icon: 'iconsminds-receipt-4',
            label: 'Store Balance Summary',
            to: `${InventoryPath}/Reports/store-balance-summary`,
          },
        ]
        // roles: [UserRole.Admin],
      },
     
     
    ],
  },
  {
    imgurl:'assets/icons/HR.png',
    icon: 'iconsminds-business-mens',
    label: 'hr',
    to: `${HRPath}`,
    // roles: [UserRole.Admin, UserRole.Editor],
    subs: [
      {
        icon: 'simple-icon-doc',
        label: 'MainData',
        to: `${HRPath}/MainData`,
        subs: [
          {
            icon: 'simple-icon-wallet',
            label: 'Department',
            to: `${HRPath}/List-Department`,
          },
          {
            icon: 'simple-icon-wallet',
            label: 'JobLevels',
            to: `${HRPath}/List-JobLevels`,
          },
          {
            icon: 'simple-icon-wallet',
            label: 'JobTypes',
            to: `${HRPath}/List-JobTypes`,
          }
          ,
          {
            icon: 'simple-icon-wallet',
            label: 'Employees',
            to: `${HRPath}/List-Employees`,
          }
       
        ]
        
      },
      {
        icon: 'simple-icon-doc',
        label: 'Attendance',
        to: `${HRPath}/Attendance`,
        subs: [
          {
            icon: 'simple-icon-wallet',
            label: 'Attendance Record',
            to: `${HRPath}/all-sessions`,
          },
          {
            icon: 'simple-icon-wallet',
            label: 'Vacations Types',
            to: `${HRPath}/vacations-types`,
          },
          {
            icon: 'simple-icon-wallet',
            label: 'Vacations',
            to: `${HRPath}/vacations`,
          },
          {
            icon: 'simple-icon-wallet',
            label: 'Holidays',
            to: `${HRPath}/holidays`,
          }
          ,
          {
            icon: 'simple-icon-wallet',
            label: 'Shifts',
            to: `${HRPath}/shifts`,
          }
       
        ]
        
      },
      {
        icon: 'simple-icon-doc',
        label: 'Salaries',
        to: `${HRPath}/Salaries`,
        subs: [
          {
            icon: 'simple-icon-wallet',
            label: 'Salary Items',
            to: `${HRPath}/salary-items`,
          },
          {
            icon: 'simple-icon-wallet',
            label: 'Contracts',
            to: `${HRPath}/contracts`,
          },
          {
            icon: 'simple-icon-wallet',
            label: 'Predecessors',
            to: `${HRPath}/predecessors`,
          },
          {
            icon: 'simple-icon-wallet',
            label: 'Salaries Manager',
            to: `${HRPath}/List-Salaries-Manager`,
          },
          {
            icon: 'simple-icon-wallet',
            label: 'Salaries Slip',
            to: `${HRPath}/List-Salaries-Slip`,
          },
          
       
        ]
        
      },
    ],
  },
  // {
  //   imgurl:'assets/icons/cashier.png',
  //   icon: 'iconsminds-green-house',
  //   label: 'pos',
  //   to: `${adminRoot}/pos`,
  //   // roles: [UserRole.Admin, UserRole.Editor],
  //   subs: [
  //     {
  //       icon: 'simple-icon-doc',
  //       label: 'Cashier',
  //       to: `${basePath}/Points-Of-Sales/Cashier`,
  //       // roles: [UserRole.Admin],
  //     },

  //   ],
  // },

  {
    imgurl: 'assets/icons/PointsOfSales.png',
    icon: 'iconsminds-green-house',
    label: 'PointsOfSales',
    to: `${basePath}/Points-Of-Sale`,
    // roles: [UserRole.Admin, UserRole.Editor],
    subs: [
      {
        icon: 'simple-icon-calculator',
        label: 'Cashier',
        to: `${basePath}/Points-Of-Sale/Cashier-Screen`,
        // roles: [UserRole.Admin],
      },
      {
        icon: 'iconsminds-business-man-woman',
        label: 'Cashiers',
        to: `${basePath}/Points-Of-Sale/Cashiers`,
        // roles: [UserRole.Admin],
      },
      {
        icon: 'iconsminds-checkout',
        label: 'CashierOrders',
        to: `${basePath}/Points-Of-Sale/Orders`,
        // roles: [UserRole.Admin],
      },
      {
        icon: 'iconsminds-over-time-2',
        label: 'CashierSessions',
        to: `${basePath}/Points-Of-Sale/Sessions`,
        // roles: [UserRole.Admin],
      },
      {
        icon: 'iconsminds-over-time-2',
        label: 'ConfigPoS',
        to: `${basePath}/Points-Of-Sale/Config`,
        // roles: [UserRole.Admin],,
        subs: [
          {
            icon: 'iconsminds-gears',
            label: 'ConfigPoSGeneral',
            to: `${basePath}/Points-Of-Sale/Config/General-Settings`,
            // roles: [UserRole.Admin],
          },
          {
            icon: 'iconsminds-cash-register-2',
            label: 'CashRegisters',
            to: `${basePath}/Points-Of-Sale/Config/Cash-Registers`,
            // roles: [UserRole.Admin],
          },
          {
            icon: 'simple-icon-clock',
            label: 'CashierWorkShifts',
            to: `${basePath}/Points-Of-Sale/Config/Work-Shifts`,
            // roles: [UserRole.Admin],
          },
        ],
      }

    ],
  },

  
  {
    imgurl: 'assets/icons/Restaurant.png',
    icon: 'iconsminds-green-house',
    label: 'Restaurant',
    to: `${basePath}/restaurant`,
    // roles: [UserRole.Admin, UserRole.Editor],
    subs: [
      {
        icon: 'simple-icon-calculator',
        label: 'Cashier',
        to: `${basePath}/restaurant/Cashier-Screen`,
        // roles: [UserRole.Admin],
      },
      {
        icon: 'iconsminds-business-man-woman',
        label: 'Cashiers',
        to: `${basePath}/restaurant/Cashiers`,
        // roles: [UserRole.Admin],
      },
      {
        icon: 'iconsminds-checkout',
        label: 'CashierOrders',
        to: `${basePath}/restaurant/Orders`,
        // roles: [UserRole.Admin],
      },
      {
        icon: 'iconsminds-over-time-2',
        label: 'CashierSessions',
        to: `${basePath}/restaurant/Sessions`,
        // roles: [UserRole.Admin],
      },
      {
        icon: 'iconsminds-over-time-2',
        label: 'ConfigPoS',
        to: `${basePath}/restaurant/Config`,
        // roles: [UserRole.Admin],,
        subs: [
          {
            icon: 'iconsminds-gears',
            label: 'ConfigPoSGeneral',
            to: `${basePath}/restaurant/Config/General-Settings`,
            // roles: [UserRole.Admin],
          },
          {
            icon: 'iconsminds-cash-register-2',
            label: 'CashRegisters',
            to: `${basePath}/restaurant/Config/Cash-Registers`,
            // roles: [UserRole.Admin],
          },
          {
            icon: 'simple-icon-clock',
            label: 'CashierWorkShifts',
            to: `${basePath}/restaurant/Config/Work-Shifts`,
            // roles: [UserRole.Admin],
          },
          {
            icon: 'simple-icon-organization',
            label: 'Categories',
            to: `${basePath}/restaurant/categories`,
            // roles: [UserRole.Admin],
          },
          {
            icon: 'iconsminds-box-close',
            label: 'Items',
            to: `${basePath}/restaurant/items`,
            // roles: [UserRole.Admin],
          },
          
        ],
      }

    ],
  },
 
  {
    imgurl: 'assets/icons/membership.png',
    icon: 'iconsminds-green-house',
    label: 'Memberships',
    to: `${membershipsPath}`,
    // roles: [UserRole.Admin, UserRole.Editor],
    subs: [
      {
        icon: 'simple-icon-calculator',
        label: 'All Memberships',
        to: `${membershipsPath}`,
        // roles: [UserRole.Admin],
      },
      {
        icon: 'iconsminds-business-man-woman',
        label: 'Add Membership',
        to: `${membershipsPath}/create-membership/0`,
        // roles: [UserRole.Admin],
      },
      {
        icon: 'iconsminds-business-man-woman',
        label: 'Membership Details',
        to: `${membershipsPath}/view-membership/0`,
        // roles: [UserRole.Admin],
      },

    ],
  },
  {
    imgurl: 'assets/icons/health.jpg',
    icon: 'iconsminds-green-house',
    label: 'Health',
    to: `${healthCarePath}`,
    // roles: [UserRole.Admin, UserRole.Editor],
    subs: [
      {
        icon: 'simple-icon-calculator',
        label: 'Doctors',
        to: `${healthCarePath}/doctors`,
        // roles: [UserRole.Admin],
      },
      {
        icon: 'simple-icon-calculator',
        label: 'All Health Care Packages',
        to: `${healthCarePath}/pachages`,
        // roles: [UserRole.Admin],
      },
  
      {
        icon: 'simple-icon-calculator',
        label: 'All Health Care Subscriptions',
        to: `${healthCarePath}/subscriptions`,
        // roles: [UserRole.Admin],
      },
      {
        icon: 'simple-icon-calculator',
        label: 'Add Health Care Subscriptions',
        to: `${healthCarePath}/create-subscription/0`,
        // roles: [UserRole.Admin],
      },
      
    

    ],
  },
  {
    imgurl: 'assets/icons/pharmacy.png',
    icon: 'iconsminds-green-house',
    label: 'My Pharmacy',
    to: `${myPharmacyPath}`,
    // roles: [UserRole.Admin, UserRole.Editor],
    subs: [
      {
        icon: 'simple-icon-calculator',
        label: 'All Pharmacy Packages',
        to: `${myPharmacyPath}/packages`,
        // roles: [UserRole.Admin],
      },
      {
        icon: 'simple-icon-calculator',
        label: 'All Pharmacy Subscriptions',
        to: `${myPharmacyPath}/subscriptions`,
        // roles: [UserRole.Admin],
      },
      {
        icon: 'simple-icon-calculator',
        label: 'Add Pharmacy Subscription',
        to: `${myPharmacyPath}/create-subscription/0`,
        // roles: [UserRole.Admin],
      },
      
    

    ],
  },
  {
    imgurl: 'assets/icons/membership.png',
    icon: 'iconsminds-green-house',
    label: 'Council affairs',
    to: `${myCouncilAndMembers}`,
    subs: [
      
      {
        icon: 'simple-icon-calculator',
        label: 'Board of directors',
        to: `${myCouncilAndMembers}/board-of-directors`,
      }, 
      {
        icon: 'simple-icon-calculator',
        label: 'All positions',
        to: `${myCouncilAndMembers}/positions-of-council-members`,
      },
      {
        icon: 'simple-icon-calculator',
        label: 'Types Of Topics',
        to: `${myCouncilAndMembers}/types-of-topics`,
      },
      {
        icon: 'simple-icon-calculator',
        label: 'Third Party',
        to: `${myCouncilAndMembers}/third-party`,
      },
      {
        icon: 'simple-icon-calculator',
        label: 'Boards of directors',
        to: `${myCouncilAndMembers}/boards-of-directors`,
      },
      
    ]
  },
  // {
  //   imgurl:'assets/icons/cost.png',

  //   icon: 'iconsminds-factory',
  //   label: 'production',
  //   to: `${adminRoot}/production`,
  //   // roles: [UserRole.Admin, UserRole.Editor],
  //   subs: [
  //     {
  //       icon: 'simple-icon-doc',
  //       label: 'production',
  //       to: `${adminRoot}/production/default`,
  //       // roles: [UserRole.Admin],
  //     },
     
  //   ],
  // },
  // {
  //   imgurl:'assets/icons/project-management.png',

  //   icon: 'iconsminds-project',
  //   label: 'projects-management',
  //   to: `${adminRoot}/projects-management`,
  //   // roles: [UserRole.Admin, UserRole.Editor],
  //   subs: [
  //     {
  //       icon: 'simple-icon-doc',
  //       label: 'projects-management',
  //       to: `${adminRoot}/projects-management/default`,
  //       // roles: [UserRole.Admin],
  //     },
     
  //   ],
  // },
  // {
  //   imgurl:'assets/icons/planning.png',

  //   icon: 'iconsminds-light-bulb-leaf',
  //   label: 'planning',
  //   to: `${adminRoot}/planning`,
  //   // roles: [UserRole.Admin, UserRole.Editor],
  //   subs: [
  //     {
  //       icon: 'simple-icon-doc',
  //       label: 'planning',
  //       to: `${adminRoot}/planning/default`,
  //       // roles: [UserRole.Admin],
  //     },
     
  //   ],
  // },
];
export default data;
