import data from "src/app/data/follow";

export class EmployeesManagementModel{
    Id?: number;
    Name?:string;
    Code?:number;
    Nickname?:string;
    MiddleName?:string;
    Notes?:string;
    EmployeeType?:string;
    EMail?:string;
    BirthDate?:Date;
    Type?:string;
    CountryId?:number;
    MobileNumber?:string;
    GroundPhoneNumber?:string;
    PersonalEmail?:string;
    FirstAddress?:string;
    SecondAddress?:string;
    City?:string;
    Governorate?:string;
    PostalCode?:string;
    FirstPermanentAddress?:string;
    SecondPermanentAddress?:string;
    PermanentCity?:string;
    PermanentGovernorate?:string;
    PermanentPostalCode?:string;
    JobTitelId?:number;
    DepartmentId?:number;
    JobLevelId?:number;
    JoiningDate?:string;
    BranchId?:number;
    Month?:string;
    Day?:string;
    AttendanceShiftId?:number;
    VacationPolicyId?:number;
    AttendanceDeterminantsId?:number;
    Status?:boolean;
    IsAccessToTheSystem?:boolean;
    JobLevelName?:string;
    DepartmentName?:string;
    JobTitelName?:string;
    BranchName?:string;
    JobTypeName?:string;
    CountryNameAr?:string;
    CountryNameEn?:string;
    JobTypeId?:number;
    
    LanguageId?:number;
    JobRoleId?:number;
    SendLoginDataByMail?:boolean;
    IsDefaultFiscalDate?:boolean;
    
    
    constructor(item?:EmployeesManagementModel){
        item = item? item:{};
        this.Id = item.Id? item.Id:0;
        this.Code = item.Code? item.Code:0;
        this.Name = item.Name? item.Name:'';
        this.Nickname = item.Nickname? item.Nickname:'';
        this.MiddleName = item.MiddleName? item.MiddleName:'';
        this.Notes = item.Notes? item.Notes:'';
        this.EmployeeType = item.EmployeeType? item.EmployeeType:'';
        this.EMail = item.EMail? item.EMail:'';
        this.BirthDate = item.BirthDate? item.BirthDate:new Date();
        this.Type = item.Type? item.Type:'';
        this.CountryId = item.CountryId? item.CountryId:0;
        this.MobileNumber = item.MobileNumber? item.MobileNumber:'';
        this.GroundPhoneNumber = item.GroundPhoneNumber? item.GroundPhoneNumber:'';
        this.PersonalEmail = item.PersonalEmail? item.PersonalEmail:'';
        this.FirstAddress = item.FirstAddress? item.FirstAddress:'';
        this.SecondAddress = item.SecondAddress? item.SecondAddress:'';
        this.City = item.City? item.City:'';
        this.Governorate = item.Governorate? item.Governorate:'';
        this.City = item.City? item.City:'';
        this.PostalCode = item.PostalCode? item.PostalCode:'';
        this.FirstPermanentAddress = item.FirstPermanentAddress? item.FirstPermanentAddress:'';
        this.SecondPermanentAddress = item.SecondPermanentAddress? item.SecondPermanentAddress:'';
        this.PermanentCity = item.PermanentCity? item.PermanentCity:'';
        this.PermanentGovernorate = item.PermanentGovernorate? item.PermanentGovernorate:'';
        this.PermanentPostalCode = item.PermanentPostalCode? item.PermanentPostalCode:'';
        this.JobTitelId = item.JobTitelId? item.JobTitelId:0;
        this.DepartmentId = item.DepartmentId? item.DepartmentId:0;
        this.JobLevelId = item.JobLevelId? item.JobLevelId:0;
        this.JoiningDate = item.JoiningDate? item.JoiningDate:'';
        this.BranchId = item.BranchId? item.BranchId:0;
        this.LanguageId = item.LanguageId? item.LanguageId:0;
        this.JobRoleId = item.JobRoleId? item.JobRoleId:0;
        this.Day = item.Day? item.Day:'';
        this.AttendanceShiftId = item.AttendanceShiftId? item.AttendanceShiftId:0;
        this.VacationPolicyId = item.VacationPolicyId? item.VacationPolicyId:0;
        this.AttendanceDeterminantsId = item.AttendanceDeterminantsId? item.AttendanceDeterminantsId:0;
        this.Status = item.Status? item.Status:true;
        this.IsAccessToTheSystem = item.IsAccessToTheSystem? item.IsAccessToTheSystem:true;
        this.SendLoginDataByMail = item.SendLoginDataByMail? item.SendLoginDataByMail:false;
        this.JobLevelName = item.JobLevelName? item.JobLevelName:'';
        this.JobTypeName = item.JobTypeName? item.JobTypeName:'';
        this.DepartmentName = item.DepartmentName? item.DepartmentName:'';
        this.JobTitelName = item.JobTitelName? item.JobTitelName:'';
        this.BranchName = item.BranchName? item.BranchName:'';
        this.CountryNameAr = item.CountryNameAr? item.CountryNameAr:'';
        this.CountryNameEn = item.CountryNameEn? item.CountryNameEn:'';
        this.JobTypeId = item.JobTypeId? item.JobTypeId:0;
        this.IsDefaultFiscalDate = item.IsDefaultFiscalDate? item.IsDefaultFiscalDate:false;
        
    }

}