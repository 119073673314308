

<div class="row">
    <div class="col-12">
      <div class="card mb-4">
        <div class="card-body">
          <!-- <h5 class="mb-4">{{ 'From Date' | translate }}</h5> -->
          <div class="row">
            <div class="col-12 col-lg-6 mb-4">
                <label><span>{{ 'Name' | translate }}</span></label>
                <ng-select placeholder='{{"Name" | translate}}'   [items]="assets" bindLabel="Name" bindValue="Id" [(ngModel)]="search.AssetId">
                </ng-select>
            
              </div>
            <div class="col-12 col-lg-6 mb-4">
              <label><span>{{ 'DateFrom' | translate }}</span></label>
              <input 
              type="text" onfocus="(this.type='date')" onblur="if(!this.value)this.type='text'"
              onchange="if(!this.value)this.type='text'" name="Date" class="form-control"
              [(ngModel)]="search.From"
              placeholder='{{"DateFrom" | translate}}'>
            </div>
            <div class="col-12 col-lg-6 mb-4">
              <label><span>{{ 'DateTo' | translate }}</span></label>
              <input 
              type="text" onfocus="(this.type='date')" onblur="if(!this.value)this.type='text'"
              onchange="if(!this.value)this.type='text'" name="Date" class="form-control"
              [(ngModel)]="search.To"
              placeholder='{{"DateTo" | translate}}'>
            </div>
            <div class="col-12 col-lg-6 mb-4">
              <label><span>{{ 'Branch' | translate }}</span></label>
         
              <ng-select placeholder='{{"Branch" | translate}}' *ngIf="lang==='ar-EG'" [items]="branches" bindLabel="NameAr" bindValue="Id" [(ngModel)]="search.BranchId">
              </ng-select>
              <ng-select placeholder='{{"Branch" | translate}}' *ngIf="lang!='ar-EG'" [items]="branches" bindLabel="NameEn" bindValue="Id" [(ngModel)]="search.BranchId">
              </ng-select>
            </div>
         
            <div class="col-12 col-lg-6 mb-4">
              <label><span>{{ 'Employee' | translate }}</span></label>
              <ng-select placeholder='{{"Employee" | translate}}'   [items]="employees" bindLabel="NameAr" bindValue="Id" [(ngModel)]="search.EmployeeId">
              </ng-select>
          
            </div>
            <div class="col-12 col-lg-6 mb-4">
              <label><span>{{ 'Currency' | translate }}</span></label>
              <ng-select  placeholder='{{"Currency" | translate}}'  *ngIf="lang==='ar-EG'" [items]="currencies" bindLabel="NameAr" bindValue="Id" [(ngModel)]="search.CurrencyId">
              </ng-select>
              <ng-select placeholder='{{"Currency" | translate}}'  *ngIf="lang!='ar-EG'" [items]="currencies" bindLabel="NameEn" bindValue="Id" [(ngModel)]="search.CurrencyId">
              </ng-select>
            </div>
         
            <div class="col-12 col-lg-6 mb-4 "  >
              <button type="button" (click)="Search()" class="btn btn-primary default mb-1" style="margin: 1px;">{{'Search' | translate}}</button>{{" "}}
              <button type="button" (click)="print()" class="btn btn-secondary default mb-1 " style="margin: 1px;">{{'Print' | translate}}</button>{{" "}}
              <button type="button" (click)="exportTable()" class="btn btn-success default mb-1" style="margin: 1px;">{{'PDF' | translate}}</button>{{" "}}
              <button type="button"  class="btn btn-primary default mb-1" style="margin: 1px;">{{'Excel' | translate}}</button>{{" "}}
            
            </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  <div id="contentToConvert">
    <div class="row" >
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-body">
              <h3 class="float-center" style="text-align: center;">{{ 'Assets Report' | translate }}</h3>
              <h4 class="float-center" style="text-align: center;">{{  today| date:'dd/MM/yyyy' }}</h4>
              <h6 class="float-center" style="text-align: center;">{{ 'Future Core' | translate }}</h6>
             
          </div>
        </div>
      </div>
    </div>
  
  
  <div class="card mb-12 tree-explorer-side col-md-12" *ngIf="data">
      <div class="card-body ">
      <div class="row">
          <table class="table table-striped">
              <thead>
                  <th>{{ 'Name' | translate }}                               </th>
                  <th>{{ 'Employee' | translate }}                           </th>
                  <th>{{ 'Start Service Date' | translate }}                 </th>
                  <th>{{ 'Purchase Date' | translate }}                      </th>
                  <th>{{ 'Purchase Amount' | translate }}                    </th>
                  <th>{{ 'Discounts' | translate }}                          </th>
                  <th>{{ 'Increases' | translate }}                          </th>
                  <th>{{ 'Current Amount Before Depreciations' | translate }}</th>
                  <th>{{ 'Balance Of Previous Depreciations' | translate }}  </th>
                  <th>{{ 'Periodic Devastations' | translate }}              </th>
                  <th>{{ 'Total Devastations' | translate }}                 </th>
                  <th>{{ 'Current Amount' | translate }}                     </th>

              </thead>
              <tbody>
                  <tr *ngFor="let item of data">
                      <td>{{item.Name}}</td>
                      <td>{{item.EmployeeName}}</td>
                      <td>{{item.ServiceStartDate|date:'dd/MM/yyyy'}}</td>
                      <td>{{item.PurchaseDate|date:'dd/MM/yyyy'}}</td>
                      <td>{{item.PurchaseAmount}}</td>
                      <td>{{item.Discounts}}</td>
                      <td>{{item.Increases}}</td>
                      <td>{{item.CurrentAmountBeforeDepreciations}}</td>
                      <td>{{item.BalanceOfPreviousDepreciations}}</td>
                      <td>{{item.PeriodicDevastations}}</td>
                      <td>{{item.TotalDevastations}}</td>
                      <td>{{item.CurrentAmount}}</td>
                  </tr>
              </tbody>
          </table>
      
      </div>
  
      </div>
    </div>
  </div>