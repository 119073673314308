export class CashierWorkShiftModel {
    Id?: number;
    Code?: number;
    Name?: string;
    From?: Date;
    To?: Date;
    constructor(obj?: CashierWorkShiftModel) {
        obj = obj ? obj : {};
        this.Id = obj.Id ? obj.Id : 0;
        this.Code = obj.Code ? obj.Code : 0;
        this.Name = obj.Name ? obj.Name : '';
        this.From = obj.From ? obj.From : new Date();
        this.To = obj.To ? obj.To : new Date();
    }
}