import { CreateNewItemModel } from './helper/model/create-new-item.model';
import { PaymentPlanService } from './../../views/app/aio/acounting_components/payment-plan/payment-plan.service';
import { CurrencyModel } from 'src/app/views/invoice/currencies/CurrencyModel';
import { Component, OnInit, ViewChild } from '@angular/core';
import data from 'src/app/constants/menu';
import { InvoiceItemModel } from './helper/model/get_item.model'
import { BranchItemModel } from './helper/model/get_branch.model'
import { ClientInvoceModel } from './helper/model/get_client_model'
import { TaxModel } from './helper/model/taxModel'

import { GetItem } from './helper/serves/get_item_serves'
import { GetBranch } from './helper/serves/get_branch_serves'
import { GetClient } from './helper/serves/get_client_server'
import { PostInvoiceServes } from './helper/serves/post_invoice_serves'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PostInvoiceModel } from './helper/model/invoive.model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TemplateRef } from '@angular/core';
import { of } from 'rxjs';
import { BranchModel } from '../branches/BranchModel';
import { ItemPostModel } from './helper/model/Item_post_model';
import { ItemModel } from './helper/model/invoice-item.model';
import { TaxTypesService } from '../tax-types/TaxTypesService';
import { TaxTypesModel } from '../tax-types/TaxTypesModel';
import { Router } from '@angular/router';
import { CurrencyService } from '../currencies/CurrencyService';
import { PaymentPlanModel } from 'src/app/views/app/aio/acounting_components/payment-plan/models/payment-plan.model';
import { CostCenterModel } from 'src/app/views/app/aio/acounting_components/cost-center/models/cost-center.model';
import { CostCenterService } from 'src/app/views/app/aio/acounting_components/cost-center/services/cost-center.service';
import { JournalFooterCostCenterModel } from 'src/app/views/app/aio/acounting_components/dailyrestrictions/journal-footer-cost-center.model';
import { SidePopupComponent } from 'src/app/views/invoice/side-popup/side-popup.component';
import { CodingUnitModel } from '../coding-units/CodingUnitModel';
import { BrandServiceService } from 'src/app/Invintory/warehouses/brand-service.service';
import { CategoryServiceService } from 'src/app/Invintory/warehouses/category-service.service';
import {UnitTypeService} from 'src/app/Invintory/unit-type.service';
import { WarehouseService } from "src/app/Invintory/warehouses/warehouse-service.service";
import { StoreItemsModel } from 'src/app/Invintory/models/StoreItemsModel';
import { CategoryModel } from 'src/app/Invintory/models/CategoryModel';
import { BrandModel } from 'src/app/Invintory/models/BrandModel';
import { CategoryItemsModel } from 'src/app/Invintory/models/CategoryItemsMosel';
import { BrandItemsModel } from 'src/app/Invintory/models/BrandItemsModel';

@Component({
  selector: 'app-create-invoice',
  templateUrl: './create-invoice.component.html',
  styleUrls: ['./create-invoice.component.scss']
})
export class CreateInvoiceComponent implements OnInit {
  showCard: boolean = false;
  ItemHolderList: any[] = [];
  SelectedItemList: InvoiceItemModel[] = [];
  BranchHolderList: BranchModel[] = [];
  ItemModelList: ItemModel[] = [];
  ClientHolderList: ClientInvoceModel[] = [];
  FinalListItemPost: ItemPostModel[] = [];
  postInvocemodel: PostInvoiceModel;
  branchID: number;
  ClientID: number;
  Name: String;
  ClientType: String;
  TaxNumber: String;
  InvoiceForm: FormGroup;
  popupTax: FormGroup;
  ItemForm: FormGroup;
  totalSalesAmount: number = 0;
  totalTax: number = 0;
  totalDiscountAmount: number = 0;
  valueAddedTax: number = 0;
  amountAfterDiscount: number = 0;
  totalAfterTax: number = 0;
  TaxId: number;
  TaxHeaderName: String;
  TaxItemSelected: number;
  // getTaxModel:TaxModel[]=[];
  selectedCompanies: TaxModel[] = [];
  companies: TaxModel[] = [];
  companiesNames:TaxTypesModel[]=[];
  currencies:CurrencyModel[]=[];
  // companiesNames = [{ TaxId: 1, TaxName: '14%', value: 14 }, { TaxId: 2, TaxName: '15%', value: 15 }, { TaxId: 3, TaxName: '10%', value: 10 }, { TaxId: 4, TaxName: '2%', value: 2 }];
  lang: string;

  PaymentForm:FormGroup;
  DelivaryForm:FormGroup;
  paymentPlans:PaymentPlanModel[]=[];
  centers:CostCenterModel[]=[]
  constructor(private currencyService:CurrencyService, 
    public router:Router, 
    private getItemServes: GetItem,
    public fb:FormBuilder, 
    private TaxTypesService: TaxTypesService, 
    private modalService: BsModalService, 
    getBranchServes: GetBranch, 
    getClientServes: GetClient, 
    private postInvoiceServes: PostInvoiceServes,
    private PaymentPlanService:PaymentPlanService,
    private costCenterService:CostCenterService,
    public formBuilder : FormBuilder,
    private UnitTypeService:UnitTypeService,
    private WarehouseService: WarehouseService,private BrandService: BrandServiceService,
    private CategorySevice: CategoryServiceService,
    ) {
      this.costCenterService.getAll(0)
      .subscribe(result=>{
        this.centers = result
      })
      this.PaymentPlanService.getAll()
      .subscribe(result=>{
        this.paymentPlans = result;
      })
   currencyService.getAll()
   .subscribe(currency=>{
    this.currencies = currency;
   })
    getItemServes.getAll().subscribe(
      data => {
        this.ItemHolderList = data;
        console.log('this.ItemHolderList',data);

      }
    );
    getBranchServes.getAll().subscribe(
      data => {
        this.BranchHolderList = data;
        console.log(data);

      }
    );
    getClientServes.getAll().subscribe(
      data => {
        this.ClientHolderList = data;
        console.log(data);

      }
    );

    TaxTypesService.getAll().subscribe(
      data => {
        this.companiesNames = data;
        console.log("companiesNamescompaniesNames",data);
        this.companiesNames.forEach(element => {
          this.companies.push(element);
    
        });
        // this.companies.splice(0,0,{Id:0,IsRate:false,ItemID:0,NameAr:"اختر الضريبة",NameEn:"Choose Tax",Percentage:0,SubType:"",TaxType:'',TotalPriceAfterTax:0,TotalTax:0})

      }
    );
  }
  createPaymentForm():FormGroup{
    return this.fb.group({
      BankName:[this.postInvocemodel.BankName],
      BankAddress:[this.postInvocemodel.BankAddress],
      BankAccountNo:[this.postInvocemodel.BankAccountNo],
      BankAccountIBAN:[this.postInvocemodel.BankAccountIBAN],
      SwiftCode:[this.postInvocemodel.SwiftCode],
    })
  }
  createDelivaryForm():FormGroup{
    return this.fb.group({
      Approach:[this.postInvocemodel.Approach],
      Packaging:[this.postInvocemodel.Packaging],
      DateValidity:[this.postInvocemodel.DateValidity],
      ExportPort:[this.postInvocemodel.ExportPort],
      CountryOfOrigin:[this.postInvocemodel.CountryOfOrigin],
      GrossWeight:[this.postInvocemodel.GrossWeight],
      NetWeight:[this.postInvocemodel.NetWeight],
    })
  }
  WarehouseList:any[]=[];
  UnitList:any[]=[];
  units:CodingUnitModel[]=[];
  BrandList:BrandModel[]=[];
  CategoryList:CategoryModel[]=[];
  lastCode = 0;
  FirstTaxesList:any[]=[];
  SecondTaxesList:any[]=[];
  ngOnInit() {
   this.getDataForAddItem();
   this.postInvoiceServes.getLastCode()
   .subscribe(last=>{

    if(+last > 0){

      this.lastCode = +last + 1;
    }
    else{
      this.lastCode = 1;
    }
    this.postInvocemodel = new BranchItemModel();
    this.lang = localStorage.getItem('theme_lang');
    this.PaymentForm = this.createPaymentForm();
    this.DelivaryForm = this.createDelivaryForm();

    /////////////// ToDO



    this.popupTax = new FormGroup({
      taxItemSelected: new FormControl(null),
    })
    this.InvoiceForm = new FormGroup({
      InvoiceNumber: new FormControl(this.lastCode.toString() , Validators.required,),
      TaxActivityCode: new FormControl(null),
      ReleaseDate: new FormControl(null, Validators.required),
      DueDate: new FormControl(null, Validators.required),
      salesorder: new FormControl(null, Validators.required),
      Initialinvoicenumber: new FormControl(null, Validators.required),
      PurchaseOrder: new FormControl(null, Validators.required),
      BranchDropdwon: new FormControl(0, Validators.required),
      TypeDropdwon: new FormControl(null, Validators.required),
      company: new FormControl(null, Validators.required),
      Currency:new FormControl("",Validators.required),
      PaymentPlanId:new FormControl(0)
    });

    this.ItemForm = new FormGroup({
      quantity: new FormControl(1),
      discount: new FormControl(0),
    });
   })
   
    // this.ClintForm = new FormGroup({
    //   Name : new FormControl( null,Validators.required,),  
    //   ClientType : new FormControl( null ,Validators.required),  
    //   TaxNumber : new FormControl( null ,Validators.required),  
    // });

    // this.InvoiceSummary = new FormGroup({
    //   TotalSales : new FormControl( "0",Validators.required,),  
    //   TotalDiscount : new FormControl( "0" ,Validators.required),  
    //   TotalValueAdded : new FormControl( "0" ,Validators.required),  
    //   amountAfter : new FormControl( "0" ,Validators.required),  
    // });
  }
  // ClintForm: FormGroup;
  // InvoiceSummary: FormGroup;
  func() {
    // this.InvoiceForm=this.InvoiceForm.getRawValue().InvoiceNumber
    console.log(this.InvoiceForm);
  }
  modalRef: BsModalRef;
  config = {
    backdrop: true,
    ignoreBackdropClick: false
  };
  openModal(template: TemplateRef<any>, Id: number): void {
    debugger;
    let index = this.ItemModelList.findIndex(i => i.id == Id);
    console.log("index", index);
    // of(this.ItemModelList).subscribe(data => {
    //   this. = data;
    // })
    console.log("ID", Id);

    this.TaxItemSelected = Id;
    this.TaxHeaderName = this.ItemModelList[index].name;
    this.selectedCompanies = this.ItemModelList[index].taxs;

    this.modalRef = this.modalService.show(template, this.config);
  }
  update(e) {
    this.branchID = e.target.value;
    console.log(this.branchID)
  }

  getItemID(e) {
    // this.SelectedItemList.push(this.ItemHolderList[e.target.value]);
    var test = this.ItemForm.getRawValue();

    var selectedItem = new ItemModel();
    var i = this.ItemHolderList.findIndex(x => x.Id == e);
    if (i > -1) {
      test.discount=0;
      test.quantity=1;
      selectedItem.id = this.ItemHolderList[i].Id;
      selectedItem.discount =test.discount;
      selectedItem.price = this.ItemHolderList[i].SalePrice;
      selectedItem.quantity = test.quantity;
      selectedItem.totalPrice = (this.ItemHolderList[i].SalePrice * selectedItem.quantity);
      selectedItem.totalAfterDiscount = selectedItem.totalPrice - selectedItem.discount;
      selectedItem.TotalPriceAfterTax = selectedItem.totalAfterDiscount;
      console.log(" selectedItem.TotalPriceAfterTax ", selectedItem.TotalPriceAfterTax);
      console.log("this.ItemHolderList[i].NameEn",this.ItemHolderList[i].NameEn);
      
      this.lang==='ar-EG'?selectedItem.name = this.ItemHolderList[i].NameAr:selectedItem.name = this.ItemHolderList[i].NameEn  ;
      this.totalSalesAmount += selectedItem.totalPrice;
      // this.valueAddedTax += (selectedItem.totalPrice*14)/100;
      console.log(selectedItem);

      this.ItemModelList.push(selectedItem);
    }

    this. InvoiceSummary();

  }

  changeQuanity(sel: ItemModel) {

    var test = this.ItemForm.getRawValue();
    sel.totalPrice = sel.price * test.quantity
    sel.totalAfterDiscount = sel.totalPrice - test.discount;

    this.totalSalesAmount = 0;
    // this.valueAddedTax = 0;
    this.ItemModelList.forEach(element => {
      this.totalSalesAmount += element.totalPrice;
      element.TotalPriceAfterTax=  element.totalAfterDiscount ;
      // this.valueAddedTax += (element.totalPrice*14)/100;
    });
    

    this. InvoiceSummary();
   this. OnChangePriceSelectedItemTax();
  }
  changeDescount(sel: ItemModel) {
    var test = this.ItemForm.getRawValue();
    console.log("test.discount",test.discount);
    sel.discount = test.discount;
    sel.totalAfterDiscount = sel.totalPrice - test.discount;
     this.totalDiscountAmount = 0;
    this.ItemModelList.forEach(element => {
      this.totalDiscountAmount += element.totalAfterDiscount;
      element.TotalPriceAfterTax=  element.totalAfterDiscount ;

    });
    this. InvoiceSummary();

    this. OnChangePriceSelectedItemTax();
  
  }


  OnClickClientItem(e) {
    // debugger;
    this.ClientID = e;
    // console.log(e.target.value);

    var i = this.ClientHolderList.findIndex(x => x.Id == e);
    this.showCard = true;
    // if(i>0){
    // var test = this.ClintForm.getRawValue();
    if(this.lang==='ar-EG'){

      this.Name = this.ClientHolderList[i].NameAr;
    }
    else{
      this.Name = this.ClientHolderList[i].NameEn;

    }
    this.ClientType = this.ClientHolderList[i].Type;
    this.TaxNumber = this.ClientHolderList[i].TaxNumber;
    // }
  }
  InvoiceSummary(){
    this.totalSalesAmount =0;
    this.totalDiscountAmount=0;
    this.amountAfterDiscount=0;
    this.totalAfterTax=0;
     this.totalTax=0;
    this.ItemModelList.forEach(
      item=>{
       this.totalSalesAmount += item.totalPrice;
       this.totalDiscountAmount+= item.discount;
       this.amountAfterDiscount+= item.totalAfterDiscount;
       this.totalAfterTax +=item.TotalPriceAfterTax;
       this.totalTax+=item.TotalTax;
      }
    )
    // debugger;
    // console.log(this.totalSalesAmount);

    //   var test = this.ClintForm.getRawValue();
    //   test.TotalSales = this.totalSalesAmount;
    //   test. TotalDiscount=  this.totalDiscountAmount;
    //   test.  TotalValueAdded=   this. valueAddedTax;
    //   test. amountAfter =  this.amountAfterDiscount;

  }

  Save() {
    debugger;
    var invoiceForm = this.InvoiceForm.getRawValue();
    console.log(this.branchID);
    this.postInvocemodel = new PostInvoiceModel();
    this.postInvocemodel.BranchId = +this.branchID;
    this.postInvocemodel.Lang = this.lang;
    this.postInvocemodel.ClientId = this.ClientID;
    this.postInvocemodel.Id = 0;
    this.postInvocemodel.InvoiceNumber = invoiceForm.InvoiceNumber;
    this.postInvocemodel.PurchaseDate = invoiceForm.ReleaseDate;
    this.postInvocemodel.ReleaseDate = invoiceForm.DueDate;
    this.postInvocemodel.SalesOrder = invoiceForm.salesorder;
    this.postInvocemodel.PurchaseOrder = invoiceForm.PurchaseOrder;
    this.postInvocemodel.Type = invoiceForm.TypeDropdwon;
    this.postInvocemodel.PaymentPlanId = invoiceForm.PaymentPlanId;
    this.postInvocemodel.TaxActivityCode = invoiceForm.TaxActivityCode
    this.postInvocemodel.TotalPrice = invoiceForm.totalSalesAmount
    var paymentData = this.PaymentForm.getRawValue();
    this.postInvocemodel.BankAccountIBAN = paymentData.BankAccountIBAN;
    this.postInvocemodel.BankAccountNo = paymentData.BankAccountNo;
    this.postInvocemodel.BankAddress = paymentData.BankAddress;
    this.postInvocemodel.BankName = paymentData.BankName;
    this.postInvocemodel.SwiftCode = paymentData.SwiftCode;
    var delivaryData = this.DelivaryForm.getRawValue();
    this.postInvocemodel.Approach = delivaryData.Approach;
    this.postInvocemodel.Packaging = delivaryData.Packaging;
    this.postInvocemodel.DateValidity = delivaryData.DateValidity;
    this.postInvocemodel.ExportPort = delivaryData.ExportPort;
    this.postInvocemodel.CountryOfOrigin = delivaryData.CountryOfOrigin;
    this.postInvocemodel.GrossWeight = delivaryData.GrossWeight;
    this.postInvocemodel.NetWeight = delivaryData.NetWeight;
    this.postInvocemodel.Currency = invoiceForm.Currency;
    this.postInvocemodel.CostCenters = this.selectedCostCenters;
    this.postInvocemodel.Status = 'ReadyToSend';
    this.postInvocemodel.extraDiscountAmount = 0;
    var selectedItem = new ItemPostModel();
    this.ItemModelList.forEach((item) => {
      selectedItem.ItemId = item.id;
      selectedItem.Discount = item.discount;
      selectedItem.Price = item.price;
      selectedItem.Quantity = item.quantity;
      selectedItem.InvoiceId = 0;
      selectedItem.Name = item.name;
      selectedItem.AmountEGP = item.totalPrice;
      selectedItem.AmountSold = item.totalPrice;
      selectedItem.SsalesTotal  = item.totalPrice;
      selectedItem.Discount = item.discount;
      selectedItem.DiscountAmount = item.discount;
      selectedItem.DiscountRate = (item.discount * 100) / item.totalPrice;
      selectedItem.NetTotal = (item.quantity*item.price) - item.discount ;
      selectedItem.TotalTaxableFees = item.TotalTax;
      selectedItem.Total = selectedItem.NetTotal - selectedItem.TotalTaxableFees;
      selectedItem.Rate = 0;
      selectedItem.ItemsDiscount = item.discount;
      selectedItem.CurrencySold = this.postInvocemodel.Currency;
      selectedItem.Taxes = item.taxs;
      selectedItem.TotalTax = item.TotalTax;
      this.postInvocemodel.totalItemsDiscountAmount += selectedItem.Discount;
      this.postInvocemodel.totalSalesAmount += selectedItem.SsalesTotal;
      selectedItem.Taxes.forEach(element => {
        debugger;
        selectedItem.Rate += element.Percentage;
        element.Rate = element.Percentage;
        
      });
      console.log(selectedItem);
      this.FinalListItemPost.push(selectedItem);
    }
    );
    this.postInvocemodel.netAmount = this.postInvocemodel.totalSalesAmount - this.postInvocemodel.totalItemsDiscountAmount;
    this.postInvocemodel.totalDiscountAmount = this.postInvocemodel.totalItemsDiscountAmount;
    this.postInvocemodel.totalAmount = this.postInvocemodel.netAmount + this.totalTax;
    console.log(this.FinalListItemPost);
    this.postInvocemodel.items = this.FinalListItemPost;
    this.postInvoiceServes.postData(this.postInvocemodel).subscribe(result => {
      this.router.navigate(['app/invoices/Reports/Invoices'])
    })
  }
  // (change)="SetTaxItem($event)"
  OnAddSelectedItemTax($event) {
    debugger;
    var addItem = $event;
    var taxlist = new TaxModel;
    console.log('OnAdd $event', addItem.Id);
    let indexselectedCompanies = this.selectedCompanies.findIndex(i => i.Id == addItem.Id);
    let index = this.ItemModelList.findIndex(i => i.id == this.TaxItemSelected);
    taxlist.TotalTax =(this.ItemModelList[index].totalAfterDiscount * this.selectedCompanies[indexselectedCompanies].Percentage / 100);
    this.ItemModelList[index].TotalTax +=taxlist.TotalTax;
    console.log("taxlist.TotalTax",taxlist.TotalTax);
    console.log("this.ItemModelList[index].TotalTax",this.ItemModelList[index].TotalTax);
    this.selectedCompanies[indexselectedCompanies].TotalTax=taxlist.TotalTax;
    this.ItemModelList[index].TotalPriceAfterTax += (this.ItemModelList[index].TotalPriceAfterTax * this.selectedCompanies[indexselectedCompanies].Percentage / 100);
    addItem.value = this.selectedCompanies[indexselectedCompanies].Percentage;
    this.selectedCompanies[indexselectedCompanies].TotalPriceAfterTax = this.ItemModelList[index].TotalPriceAfterTax;
    taxlist.TotalPriceAfterTax = this.ItemModelList[index].TotalPriceAfterTax;
    console.log("OnAdd this.ItemModelList[index].TotalPriceAfterTax", this.ItemModelList[index].TotalPriceAfterTax);
    taxlist.Id = addItem.Id;
    taxlist.TaxId = addItem.Id;
    taxlist.Percentage = addItem.value;
    taxlist.NameEn = addItem.NameEn;
    taxlist.NameAr = addItem.NameAr;
    taxlist.ItemID = this.TaxItemSelected;
    this.ItemModelList[index].taxs.push(taxlist);
    console.log(" this.ItemModelList[index].TotalPriceAfterTax", this.ItemModelList[index]);
    this. InvoiceSummary();
  }
  OnRemoveSelectedItemTax($event) {
    var removeItem = $event;
    console.log('OnRemove $event', removeItem.value.value);


    console.log('OnRemove $event', $event);
    let index = this.ItemModelList.findIndex(i => i.id == this.TaxItemSelected);
    let indexselectedCompanies = this.ItemModelList[index].taxs.findIndex(i => i.Id == removeItem.value.Id);
    console.log("indexselectedCompanies", indexselectedCompanies);
    this.ItemModelList[index].taxs.splice(indexselectedCompanies, 1);
    this.ItemModelList[index].TotalPriceAfterTax = this.ItemModelList[index].totalAfterDiscount;
    this.ItemModelList[index].taxs.forEach(
      item => {
        this.ItemModelList[index].TotalPriceAfterTax += (this.ItemModelList[index].TotalPriceAfterTax * item.Percentage / 100);
        console.log(" TotalPriceAfterTax", this.ItemModelList[index].TotalPriceAfterTax);

        item.TotalPriceAfterTax = this.ItemModelList[index].TotalPriceAfterTax;
      }
    )

    console.log(" TotalPriceAfterTax", this.ItemModelList[index].TotalPriceAfterTax);
    console.log(" this.ItemModelList[index].TotalPriceAfterTax", this.ItemModelList[index]);

    this. InvoiceSummary();

  }
  OnChangePriceSelectedItemTax(){
      // debugger;
    console.log("this.TaxItemSelected",this.TaxItemSelected);
    
        let index = this.ItemModelList.findIndex(i => i.id == this.TaxItemSelected);
console.log("this.ItemModelList[index].totalAfterDiscount",this.ItemModelList[+index]);

    this.ItemModelList[index].TotalPriceAfterTax = this.ItemModelList[+index].totalAfterDiscount;
    this.ItemModelList[index].taxs.forEach(
      item => {
        this.ItemModelList[index].TotalPriceAfterTax += (this.ItemModelList[+index].TotalPriceAfterTax * item.Percentage / 100);
        console.log(" TotalPriceAfterTax", this.ItemModelList[+index].TotalPriceAfterTax);

        item.TotalPriceAfterTax = this.ItemModelList[+index].TotalPriceAfterTax;
      }
    )

    this. InvoiceSummary(); 
  }
  changePaymentPlan(){
    debugger
    var paymentPlanId = this.InvoiceForm.controls['PaymentPlanId'].value;
    if(paymentPlanId > 0){
      var i = this.paymentPlans.findIndex(x=>x.Id == paymentPlanId);
      if(i>-1){
        var date= new Date();
       var year = date.getFullYear();
       var manth = date.getUTCMonth();
       var day = date.getDate();
        var today = new Date()
        today.setFullYear(year);
        today.setUTCMonth(manth);
        today.setDate(day+this.paymentPlans[i].NumberOfDays);
        this.InvoiceForm.controls['DueDate'].setValue(new Date(today).toISOString().slice(0, 10) );
      }
    }
  }
  changeDueDate(){
    this.InvoiceForm.controls['PaymentPlanId'].setValue(0);
  }
  selectedCostCenters:JournalFooterCostCenterModel[]=[]  
  
  costCenterId=0;
  selectCenter(){
    debugger;
    var i = this.centers.findIndex(x=>x.Id == this.costCenterId);
    if(i>-1){
      var selected = new JournalFooterCostCenterModel();
      selected.CostCenterId = this.centers[i].Id;
      selected.Amount = 0;
      selected.Id = 0;
      selected.JournalFooterId = 0;
      selected.Percent = 0;
      this.selectedCostCenters.push(selected);
      this.costCenterId = 0;
    }
  }
  getCostName(id){
    return this.centers.find(x=>x.Id == id).NameAr
  }

  changePercent(cost,$event){
    debugger;
    cost.Percent = + $event.target.value;
    if(cost.Percent > 100){
      cost.Percent = 100
    }
    var amount = this.totalAfterTax;
    var totalPercent = 0;
    var i = this.selectedCostCenters.indexOf(cost);
    if(i>-1){
      this.selectedCostCenters[i].Percent = cost.Percent;
      this.selectedCostCenters[i].Amount = (amount * cost.Percent) / 100;
    }

}
changeAmount(cost,$event){
    debugger;
    cost.Amount = + $event.target.value;
    var amount = this.totalAfterTax;
    if(cost.Amount > amount){
      cost.Amount = amount
    }
    var i = this.selectedCostCenters.indexOf(cost);
    if(i>-1){
      this.selectedCostCenters[i].Amount = cost.Amount;
      this.selectedCostCenters[i].Percent = (cost.Amount * 100) / amount;
    }

}
deleteCostCenter(cost){
    var i = this.selectedCostCenters.indexOf(cost);
    if(i > -1){
      this.selectedCostCenters.splice(i,1);
    }
 
}
goBack(){
  this.router.navigate(['app/invoices/Reports/Invoices'])
}
@ViewChild('sideModalRef', { static: true }) sideModalRef: SidePopupComponent;
currentFormMode = '';
modelObject: StoreItemsModel = new StoreItemsModel();
@ViewChild('itemtemplate', { static: true }) itemtemplate=null;

showAddNewModalRef(): void {
  this.currentFormMode ='Add'
  this.mainForm = this.createForm(this.modelObject);
  // this.sideModalRef.show();
  this.openModalWithClass(this.itemtemplate);
}
mainForm: FormGroup = new FormGroup({
  Id: new FormControl(),
  Name: new FormControl(),
  SkuNumber: new FormControl(),
  Description: new FormControl(),
  PurchasingPrice: new FormControl(),
  SalePrice: new FormControl(),
  FirstTaxId: new FormControl(),
  SecondTaxId: new FormControl(),
  LowestSellingPrice: new FormControl(),
  Discount: new FormControl(),
  ProfitPercentage: new FormControl(),
  PriceListId: new FormControl(),
  StoreDataId: new FormControl(),
  Quantity: new FormControl(),
  Notes: new FormControl(),
  Tags: new FormControl(),
  IsDisable: new FormControl(),
  WarehouseName: new FormControl(),
  Alert: new FormControl(),
  AvailableOnline: new FormControl(),
  FeaturedProduct: new FormControl(),
  Barcode:new FormControl(),
  UnitId:new FormControl(),
  DiscountType:new FormControl(),
  
});

createForm(modelObject): FormGroup {
  debugger;
  return this.fb.group({
    Id: [modelObject.Id >= 0 ? modelObject.Id : 0],
    Name: [modelObject.Name, Validators.compose([Validators.required])],
    SkuNumber: [
      modelObject.SkuNumber,
      Validators.compose([Validators.required, Validators.minLength(2)]),
    ],
    PurchasingPrice: [
      modelObject.PurchasingPrice,
      Validators.compose([Validators.required]),
    ],
    SalePrice: [modelObject.SalePrice],
    FirstTaxId: [modelObject.FirstTaxId],
    SecondTaxId: [modelObject.SecondTaxId],
    LowestSellingPrice: [modelObject.LowestSellingPrice],
    Description: [modelObject.Description],
    Discount: [modelObject.Discount],
    ProfitPercentage: [modelObject.ProfitPercentage],
    // WarehouseDataId:[modelObject.WarehouseDataId],
    // Quantity: [modelObject.Quantity],
    Notes: [modelObject.Notes],
    Tags: [modelObject.Tags],
    WarehouseName: [modelObject.WarehouseName],
    Alert: [modelObject.Alert],
    AvailableOnline: [modelObject.AvailableOnline],
    FeaturedProduct: [modelObject.FeaturedProduct],
    Barcode: [modelObject.Barcode],
    IsDisable: [modelObject.IsDisable == null ? true : modelObject.IsDisable],
    UnitId:[modelObject.UnitId],
    DiscountType:[modelObject.DiscountType],
  
    
  });
}
addObject() {
  this.modelObject = new StoreItemsModel();
  this.executeAddObject();
}
executeAddObject(){
  debugger;
  this.getItemServes.insert(this.modelObject).subscribe(result => {
    if (result > 0 ) {
      
      this.modelObject.Id = result;
      this.ItemHolderList.push(this.modelObject);
      this.sideModalRef.modalRef.hide();
    }
    else {
    }
  })
}
openModalWithClass(template: TemplateRef<any>): void {
  this.modalRef = this.modalService.show(
    template,
    Object.assign({}, { class: 'gray modal-xl' })
  );
}
SelectedCategoryList: CategoryModel[] = [];
SelectedBrandList:BrandModel[]=[];
onMaterialGroupChangeCategory(event) {
  console.log(event);
  this.SelectedCategoryList = event;

}
SaveItem() {
  debugger;
  this.modelObject = new StoreItemsModel();
  this.modelObject.Id = 0;
  this.modelObject.Name = this.mainForm.getRawValue().Name;
  this.modelObject.SkuNumber = this.mainForm.getRawValue().SkuNumber;
  this.modelObject.IsDisable = this.mainForm.getRawValue().IsDisable;
  this.modelObject.Description = this.mainForm.getRawValue().Description;
  this.modelObject.Discount = this.mainForm.getRawValue().Discount;
  this.modelObject.LowestSellingPrice =
    +this.mainForm.getRawValue().LowestSellingPrice;
  this.modelObject.ProfitPercentage =
    this.mainForm.getRawValue().ProfitPercentage;
  this.modelObject.PurchasingPrice =
    +this.mainForm.getRawValue().PurchasingPrice;
  this.modelObject.SalePrice = +this.mainForm.getRawValue().SalePrice;
  this.modelObject.Notes = this.mainForm.getRawValue().Notes;
  this.modelObject.FirstTaxId = +this.mainForm.getRawValue().FirstTaxId;
  this.modelObject.SecondTaxId = +this.mainForm.getRawValue().SecondTaxId;
  this.modelObject.UnitId = +this.mainForm.getRawValue().UnitId;
  this.modelObject.Quantity = 0;
  this.modelObject.Barcode = this.mainForm.getRawValue().Barcode;
  this.modelObject.Tags = this.mainForm.getRawValue().Tags;
  this.modelObject.AvailableOnline = this.mainForm.getRawValue().AvailableOnline;
  this.modelObject.FeaturedProduct = this.mainForm.getRawValue().FeaturedProduct;
  this.modelObject.DiscountType = this.mainForm.getRawValue().DiscountType;
  this.modelObject.Alert = +this.mainForm.getRawValue().Alert;
  
  this.SelectedCategoryList.forEach(element => {
    var cat = new CategoryItemsModel();
    cat.CategoryId = element.Id;
    cat.Id = 0;
    cat.ItemId = 0;
    this.modelObject.ItemCategory.push(cat);
  });
  this.SelectedBrandList.forEach(element => {
    var cat = new BrandItemsModel();
    cat.BrandId = element.Id;
    cat.Id = 0;
    cat.ItemId = 0;
    this.modelObject.ItemBrand.push(cat);
  });
  console.log(this.modelObject);
  console.log(this.modelObject);
  this.getItemServes.insert(this.modelObject).subscribe((data) => {
    // this.router.navigate(["app/aio/Inventory/List-All-Productes"]);
    this.modelObject.Id = data;
    this.ItemHolderList.push(this.modelObject);
    this.modelObject = new StoreItemsModel();
    this.getDataForAddItem();
    console.log('result : ',data)
    this.modalRef.hide();
  });
}


onMaterialGroupChangeBrand(event) {
  console.log(event);
  this.SelectedBrandList = event;

}
selectAllForDropdownItems(items: any[]) {
  let allSelect = (items) => {
    items.forEach((element) => {
      element['selectedAllGroup'] = 'selectedAllGroup';
    });
  };

  allSelect(items);
}
calcProfitPrice(){
  this.modelObject.PurchasingPrice = +this.mainForm.getRawValue().PurchasingPrice;
  this.modelObject.SalePrice = +this.mainForm.getRawValue().SalePrice;

  // var pre=+(this.modelObject.SalePrice-this.modelObject.PurchasingPrice);

      var pre=this.modelObject.SalePrice-this.modelObject.PurchasingPrice;
      this.modelObject.ProfitPercentage = +((pre/this.modelObject.PurchasingPrice)*100);
      // this.modelObject.ProfitPercentage = +(pre/(this.modelObject.PurchasingPrice*100));

      this.mainForm.controls['ProfitPercentage'].setValue(this.modelObject.ProfitPercentage)
}
getDataForAddItem(){
  this.TaxTypesService.getAll().subscribe((data) => {
    this.FirstTaxesList = data;
    this.SecondTaxesList = data;
    console.log("ItemsTaxesList", this.FirstTaxesList);
  });
  this.BrandService.getAll()            
  .subscribe(data=>
      {
          this.BrandList=data
          console.log('BrandList',this.BrandList)
      });
      
      this.CategorySevice.getAll()            
      .subscribe(data=>
          {
              this.CategoryList=data
              console.log('CategoryList',this.CategoryList)
          });
  this.getItemServes.getLastNumber().subscribe((data) => {
    debugger;
      this.modelObject.SkuNumber = data;
      this.mainForm.controls['SkuNumber'].setValue(this.modelObject.SkuNumber+1);
      console.log("SkuNumber", this.modelObject.SkuNumber);
    });
 
  this.WarehouseService.getAll().subscribe((data) => {
    this.WarehouseList = data;
  });
  this.UnitTypeService.getAll().subscribe(data=>{
    this.UnitList=data;
    console.log('this.UnitList',this.UnitList)
  })
}

}
