import { SalaryItemsService } from './../../salary-items.service';
import { Component, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NotificationType, NotificationsService } from 'angular2-notifications';
import { SalaryItemsModel } from '../../models/salary-items.model';
import { DataFilterer } from 'src/app/views/invoice/shared/DataFilterer';
import { DataSorter } from 'src/app/views/invoice/shared/DataSorter';
import { DataPaginator } from 'src/app/views/invoice/shared/DataPaginator';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { SidePopupComponent } from 'src/app/views/invoice/side-popup/side-popup.component';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { of } from 'rxjs';

@Component({
    selector: 'salary-items',
    templateUrl: 'salary-items.component.html',
    styleUrls: ['salary-items.component.scss']
})
export class SalaryItemsComponent  {
    ////////////////////////////////////////////////////////////////////////////
    //  VARYING :
    //  Modify per similar component.
    ////////////////////////////////////////////////////////////////////////////
    constructor(private formBuilder: FormBuilder, private modelService: SalaryItemsService, private renderer: Renderer2,
      private notifications: NotificationsService, private translate: TranslateService
      ) {  //Additional services for City and Country
    }
    currentScreen: any = 'Table';
  
    translationName: string = ' Salary Item'
  
    sortableKeys = [];
    searchableKeys = ['ItemName'];
    
    modelObject: SalaryItemsModel = new SalaryItemsModel({});
    ogData: SalaryItemsModel [] = [ ];
    mainForm: FormGroup = new FormGroup({
      Id: new FormControl(),
      ItemName           : new FormControl(),
      Description        : new FormControl(),
      ItemTypeId         : new FormControl(),
      ItemValueByAmount  : new FormControl(),
      ItemValueByPercent : new FormControl(),
    });
  
    ngOnInit(): void {
      this.lang = localStorage.getItem('theme_lang');
      this.itemOrder = { label: 'Name', property: 'ItemName' };
      this.sortableKeys = [
        { label: 'Name', property: 'ItemName' },
        { label: 'Description', property: 'Description' },
        { label: 'ItemValueByAmount', property: 'ItemValueByAmount' },
        { label: 'ItemValueByPercent', property: 'ItemValueByPercent' },
        { label: 'SalaryItemTypeName', property: this.lang === 'ar-EG' ?'SalaryItemTypeNameAr':'SalaryItemTypeNameEn' },
      ];
      //this.loadData();
      this.loadServices();
    }
  
    createForm(): FormGroup {
        debugger;
      return this.formBuilder.group({
        Id: [this.modelObject.Id >= 0 ? this.modelObject.Id : 0],
        ItemName           : [this.modelObject.ItemName           , Validators.compose([Validators.required])],
        Description        : [this.modelObject.Description        ],
        ItemTypeId         : [this.modelObject.ItemTypeId         , Validators.compose([Validators.required])],
        ItemValueByAmount  : [this.modelObject.ItemValueByAmount  ],
        ItemValueByPercent : [this.modelObject.ItemValueByPercent ],
        });
    }
  
    addObject() {
        debugger;
      //prevent multiclick submits. enable again after API response.
      this.currentAction = 'loading';
  
      this.modelObject = new SalaryItemsModel();
      this.modelObject = this.mainForm.getRawValue();
      this.modelObject.Id = 0;
      this.executeAddObject();
    }
  
    executeAddObject(){
      this.modelService.insert(this.modelObject).subscribe(result => {
        if (result === true) {
          this.loadData();
          this.currentAction = '';
          this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyAdded"));
          this.sideModalRef.modalRef.hide();
        }
        else {
          this.toastError(this.translate.instant("Error"), this.translate.instant("FailedAdd"));
          this.currentAction = '';
        }
      })
    }
  
    editObject() {
      this.savedPage = this.currentPage;
      this.currentAction = 'loading';
  
      let tempObj = this.mainForm.getRawValue();
      if(tempObj != this.modelObject){
        this.executeEditObject(tempObj);
      }
      else{
        this.sideModalRef.modalRef.hide();
      }
    }
  
    executeEditObject(Object)
    {
      this.modelService.update(Object).subscribe(result => {
        if (result === true) {
          this.loadData();
          this.currentAction = '';
          this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyEdited"));
  
          this.sideModalRef.modalRef.hide();
        }
        else {
          this.toastError(this.translate.instant("Error"), this.translate.instant("FailedEdit"));
          this.currentAction = '';
        }
      })
    }
  
    //Deletion methods:
    //Customize if needed.
    //Pass the target object as parameter if the target API can only delete singular objects and not lists.
    deleteObject() {
      this.savedPage = this.currentPage;
      //prevent multiclicks. re enable after API response
      this.currentAction = 'loading';
      this.executeDeleteObject();
    }
  
    //Same here...
    executeDeleteObject(){
      this.modelService.deleteList(this.deletionList).subscribe(result => {
        if (result === true) {
          this.loadData();
          this.currentAction = '';
          this.finalizeDeletion();
        }
        else {
          this.toastError(this.translate.instant("Error"), this.translate.instant("FailedDelete"));
          this.currentAction = '';
        }
      })
    }
    ////////////////////////////////////////////////////////////////////////////
    //
    //
    //
    //
    //
    ////////////////////////////////////////////////////////////////////////////
    //  EXCLUSIVE :
    //  Specifically for this component.
    ///////////////////////////////
  
    //Get any additional data here
    types:any;
    loadServices(){ 
        this.modelService.getAllSalaryItemType()
        .subscribe(result => {
          this.types = result;
        })
      this.currentAction = 'loading';
      this.loadData();

    }
  
  
  
    ////////////////////////////////////////////////////////////////////////////
    //
    //
    //
    //
    //
    ////////////////////////////////////////////////////////////////////////////
    //  CONSTANT :
    //  Do not change.
    ///////////////////////////////
    lang: string;
    currentFormMode: string = '';
    currentAction: string = '';
  
  
    //ogData = [];
    filteredData = [...this.ogData];
    sortedData = [...this.filteredData];
    paginatedData = [...this.filteredData];
    lastId: number = this.ogData.length + 1;
  
    SelectAllBox: any = false;
  
    itemsPerPage = 10;
    itemOptionsPerPage = [5, 10, 20];
    selected = [];
    selectAllState = '';
    itemOrder: any;
    orderAsc: boolean = true;
  
    currentPage = 1;
    savedPage = 0;
  
  
    dataFilterer = new DataFilterer();
    dataSorter = new DataSorter();
    dataPaginator = new DataPaginator();
  
    loadData() {
        debugger;
      this.currentAction = 'loading';
      this.modelService.getAll().subscribe(
        data => {
          this.ogData = data;
          this.sortData();
          this.loadSearch();
          this.currentAction = '';
        }
      )
      
    }
  
    sortData() {
        debugger;
      this.sortedData = this.dataSorter.sortData(this.itemOrder.property, this.ogData);
      this.filterData();
    }
  
    reverseSortData() {
      this.savedPage = this.currentPage;
      this.sortedData = this.sortedData.slice().reverse();
      this.filterData();
    }
  
    filterData() {
        debugger;
      this.filteredData = this.dataFilterer.filterData(this.SearchTerm, this.searchableKeys, this.sortedData)
      if (this.savedPage === 0) {
        this.paginateData(this.itemsPerPage, 1);
      }
      else {
        this.paginateData(this.itemsPerPage,
          Math.ceil((this.filteredData.length / this.itemsPerPage)) >= this.savedPage ?
            this.savedPage :
            Math.ceil((this.filteredData.length / this.itemsPerPage))
        );
      }
      this.savedPage = 0;
    }
  
    paginateData(newItemsPerPage: number, newCurrentPage: number) {
        debugger;

      this.itemsPerPage = newItemsPerPage;
      this.currentPage = newCurrentPage;
      this.paginatedData = this.filteredData.slice((newCurrentPage - 1) * (newItemsPerPage), (newItemsPerPage * newCurrentPage));
    }
  
    changeSort(newSort: any) {
      if (this.itemOrder.label === newSort.label) {
        this.orderAsc = !this.orderAsc;
        const oldPage = this.currentPage;
        this.reverseSortData()
        this.currentPage = oldPage;
      }
      else {
        this.orderAsc = true;
        this.itemOrder = newSort;
        this.sortData();
      }
    }
  
    getSort(label: any) {
      return this.sortableKeys.find(i => i.label === label);
    }
  
    changePage(event: PageChangedEvent): void {
      this.currentPage = event.page;
      this.paginateData(this.itemsPerPage, this.currentPage);
    }
  
    changeFilter() {
      this.deletionList = [];
      this.selected = [];
      this.selectAllState = '';
      this.filterData();
    }
  
    selectAll($event): void {
      if ($event.target.checked) {
        this.selected = [...this.filteredData];
      } else {
        this.selected = [];
      }
      this.setSelectAllState();
    }
  
    setSelectAllState(): void {
      if (this.selected.length >= this.filteredData.length) {
        this.selectAllState = 'checked';
      } else if (this.selected.length !== 0) {
        this.selectAllState = 'indeterminate';
      } else  {
        this.selectAllState = '';
      }
    }
  
    isSelected(p: SalaryItemsModel): boolean {
      return this.selected.findIndex(x => x.Id === p.Id) > -1;
    }
  
    onSelect(item: SalaryItemsModel): void {
      if (this.isSelected(item)) {
        this.selected = this.selected.filter(x => x.Id !== item.Id);
      } else {
        this.selected.push(item);
      }
      this.setSelectAllState();
    }
  
    findByCode(list: any, code: any): any {
      return list.find(x => x.Code === code)
    }
  
    @ViewChild('sideModalRef', { static: true }) sideModalRef: SidePopupComponent;
    @ViewChild('delModal') delModal: ModalDirective;
    showAddNewModalRef(): void {
      this.currentFormMode = 'Add'
      this.modelObject = new SalaryItemsModel;
      this.mainForm = this.createForm();
      this.sideModalRef.show();
    }
  
    showEditModalRef(Id: any): void {
      this.currentFormMode = 'Edit'
      this.modelObject = this.ogData.find(i => i.Id === Id);
      of(this.modelObject).subscribe(data => {
        this.modelObject = data;
      })
      this.mainForm = this.createForm();
      this.sideModalRef.show();
    }
  
    showInfoModalRef(Id: any): void {
      this.currentFormMode = 'Info'
      this.modelObject = this.ogData.find(i => i.Id === Id);
      of(this.modelObject).subscribe(data => {
        this.modelObject = data;
      })
      this.mainForm = this.createForm();
      this.sideModalRef.show();
    }
  
    deletionList: SalaryItemsModel[] = [];
    showDelModal(Id: any): void {
      if (Id >= 0) {
        this.currentFormMode = 'Del'
        this.modelObject = this.ogData.find(i => i.Id === Id);
        this.deletionList = [this.ogData.find(i => i.Id === Id)];
        this.delModal.show();
      }
      else if (Id === 'delMulti') {
        this.deletionList = [...this.selected]
        this.delModal.show();
      }
    }
  
    SearchTerm: any = '';
  
  
    finalizeDeletion(){
      if (this.deletionList.length === 1) {
        this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyDeleted") + " " +
          this.translate.instant("One" + this.translationName));
      }
      else if (this.deletionList.length === 2) {
        this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyDeleted") + " " +
          this.translate.instant("Two" + this.translationName + "s"));
      }
      else if (this.deletionList.length > 2 && this.deletionList.length < 11) {
        this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyDeleted") + " " +
          this.deletionList.length + " " + this.translate.instant(this.translationName + "RowsPlur"));
      }
      else {
        this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyDeleted") + " " +
          this.deletionList.length + " " + this.translate.instant(this.translationName + "Rows"));
      }
  
      this.deletionList = [];
      this.selected = [];
  
      this.setSelectAllState();
      if (this.selectAllState === '') {
        this.SelectAllBox = false;
      }
      this.delModal.hide();
    }
  
    toastSuccess(toastHeader: string, toastBody: string): void {
      this.notifications.create(toastHeader, toastBody,
        NotificationType.Success, { timeOut: 3000, showProgressBar: true });
    }
    toastError(toastHeader: string, toastBody: string): void {
      this.notifications.create(toastHeader, toastBody,
        NotificationType.Error, { timeOut: 3000, showProgressBar: true });
    }
    deleteMultiTaxTypes() {
      this.loadData();
      this.delModal.hide();
    }
  
    loadAllData() {
      this.modelService.getAll().subscribe(
        data => {
          this.ogData = data;
          this.loadSearch();
        }
      )
    }
  
    loadSearch() {
      const prevSearch = this.SearchTerm;
      this.SearchTerm = '';
      setTimeout(() => {
        this.SearchTerm = prevSearch;
      }, 1);
    }
  }
  