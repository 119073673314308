import { Component, OnInit } from '@angular/core';
import { DetailedMovementOfInventoryDataModel } from '../../models/detailed-movement-of-inventory-data.model';
import { DetailedMovementOfInventoryService } from '../../detailed-movement-of-inventory.service';
import { SummaryOfInventoryOperationsSearchModel } from '../../../summary-of-inventory-operations/models/summary-of-inventory-operations-search.model';
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';
import { WarehouseService } from 'src/app/Invintory/warehouses/warehouse-service.service';


@Component({
    selector: 'detailed-movement-of-inventory',
    templateUrl: 'detailed-movement-of-inventory.component.html',
    styleUrls: ['detailed-movement-of-inventory.component.scss']
})
export class DetailedMovementOfInventoryComponent implements OnInit{
    declare lang: any;
    today:Date=new Date();
    list:DetailedMovementOfInventoryDataModel[]=[];
    liabilities:DetailedMovementOfInventoryDataModel[]=[];
    PropertyRights:DetailedMovementOfInventoryDataModel[]=[];
    employees=[];
    nodeList:DetailedMovementOfInventoryDataModel[]=[];
    categories=[];
    brnads=[];
    warehouses=[];
    constructor(private DetailedMovementOfInventoryService:DetailedMovementOfInventoryService,
      private warehouseService:WarehouseService
      ){
      this.lang = localStorage.getItem('theme_lang');
    }
    TotalAssets = 0;
    Totalliabilities = 0;
    TotalPropertyRights = 0;
    data:DetailedMovementOfInventoryDataModel[]=[];
    assets:DetailedMovementOfInventoryDataModel[]=[];
    search:SummaryOfInventoryOperationsSearchModel={};
    ngOnInit(): void {
        this.DetailedMovementOfInventoryService.search(this.search)
        .subscribe(data => {
          this.data = data;
          this.assets = data;
        
          this.warehouseService.getAll()
          .subscribe(warehouses=>{
            this.warehouses = warehouses;
          })
        })
    
    }
    Search(){
      this.DetailedMovementOfInventoryService.search(this.search)
        .subscribe(data => {
          this.data = data;
        })
    }
    exportTable(){
      var data = document.getElementById('contentToConvert');
      data.style.display = 'inline-block';
      html2canvas(data,{}).then(canvas => {
      var imgWidth = 208;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save('new-file.pdf'); // Generated PDF
      data.style.display = 'block';

    //   data.style.display = 'none';
      
    });
    }
    print(){
        let printContents, popupWin;
        printContents = document.getElementById('contentToConvert').innerHTML;
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.open();
        window.print();
       
      }
}
