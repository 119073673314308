<div class="form-group">
    <div class="modal-body">
        <form class="md-float-material form-material" [formGroup]="mainForm">

            <div style="text-align: center;">
                <button class="btn btn-light" (click)="goBack()"
                    style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Back"  | translate}}</button>
                <button [disabled]="mainForm.invalid || currentAction==='loading'"
                    id="smallModalSubmitButton" class="btn btn-secondary" (click)="editObject()"
                    style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Edit"
                    | translate}}</button>
            </div>
            <div class="form-group" style="margin-top: 10px;">

                <div class="row">

                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"ConfigPoSDefaultClient" | translate}}</label>
                        <ng-select appearance="outline" name="DefaultClientId" formControlName="DefaultClientId"
                                placeholder='{{"ConfigPoSDefaultClient" | translate}}' [searchable]="true">
                                <ng-option *ngFor="let client of clientList" [value]="client.Id">
                                    {{lang==='ar-EG'?client.NameAr:client.NameEn}}</ng-option>
                            </ng-select>
                    </div>

                    <br>

                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"ConfigPoSDefaultPaymentMethod" | translate}}</label>
                        <ng-select appearance="outline" name="DefaultPaymentMethodId" formControlName="DefaultPaymentMethodId"
                                placeholder='{{"ConfigPoSDefaultPaymentMethod" | translate}}' 
                                #DefaultPaymentMethodSelect labelForId="txtycd" [searchable]="true">
                                <ng-option *ngFor="let paymentMethod of paymentMethodList" [value]="paymentMethod.Id">
                                    {{lang==='ar-EG'?paymentMethod.NameAr:paymentMethod.NameEn}}</ng-option>
                        </ng-select>
                    </div>

                </div>

                <br>

                <div class="row">

                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"ConfigPoSDiscountLimit" | translate}}</label>
                        <div>
                            <input type="number" min="0" max="100" name="DiscountLimit" class="form-control" 
                            formControlName="DiscountLimit" style=" margin: 0px;" (change)="setDiscountLimit()"
                            placeholder='{{"ConfigPoSDiscountLimit" | translate}}'>
                        </div>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('DiscountLimit').hasError('required') || mainForm.get('DiscountLimit').invalid) && ( mainForm.get('DiscountLimit').dirty ||  mainForm.get('DiscountLimit').touched)">
                            {{"ConfigPoSDiscountLimitRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>
                    
                </div>

                <br>

                <div class="row">
                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"ConfigPoSEnableAutoCodesForCashiers" | translate}}</label>
                        <div class="itemCheck mb-0 custom-checkbox custom-control">
                            <!-- <input type="checkbox" [id]="'configPoSEnableAutoCodesForCashiers'" 
                            (change)="toggleInput(this.mainForm.getRawValue().EnableAutoCodesForCashiers, this.mainForm.get('StartingCodeCashiers'))"
                            class="custom-control-input" formControlName="EnableAutoCodesForCashiers"> -->
                            <input type="checkbox" [id]="'configPoSEnableAutoCodesForCashiers'" 
                            class="custom-control-input" formControlName="EnableAutoCodesForCashiers">
                            <label class="custom-control-label" [for]="'configPoSEnableAutoCodesForCashiers'"></label>
                        </div>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('EnableAutoCodesForCashiers').hasError('required') || mainForm.get('EnableAutoCodesForCashiers').invalid) && ( mainForm.get('EnableAutoCodesForCashiers').dirty ||  mainForm.get('EnableAutoCodesForCashiers').touched)">
                            {{"ConfigPoSEnableAutoCodesForCashiersRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>

                    <br>

                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"ConfigPoSEnableAutoCodesForCashRegisiters" | translate}}</label>
                        <div class="itemCheck mb-0 custom-checkbox custom-control">
                            <input type="checkbox" [id]="'configPoSEnableAutoCodesForCashRegisiters'" 
                            class="custom-control-input" formControlName="EnableAutoCodesForCashRegisiters">
                            <label class="custom-control-label" [for]="'configPoSEnableAutoCodesForCashRegisiters'"></label>
                        </div>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('EnableAutoCodesForCashRegisiters').hasError('required') || mainForm.get('EnableAutoCodesForCashRegisiters').invalid) && ( mainForm.get('EnableAutoCodesForCashRegisiters').dirty ||  mainForm.get('EnableAutoCodesForCashRegisiters').touched)">
                            {{"ConfigPoSEnableAutoCodesForCashRegisitersRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>

                    <br>

                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"ConfigPoSEnableAutoCodesForWorkShifts" | translate}}</label>
                        <div class="itemCheck mb-0 custom-checkbox custom-control">
                            <input type="checkbox" [id]="'configPoSEnableAutoCodesForWorkShifts'" 
                            class="custom-control-input" formControlName="EnableAutoCodesForWorkShifts">
                            <label class="custom-control-label" [for]="'configPoSEnableAutoCodesForWorkShifts'"></label>
                        </div>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('EnableAutoCodesForWorkShifts').hasError('required') || mainForm.get('EnableAutoCodesForWorkShifts').invalid) && ( mainForm.get('EnableAutoCodesForWorkShifts').dirty ||  mainForm.get('EnableAutoCodesForWorkShifts').touched)">
                            {{"ConfigPoSEnableAutoCodesForWorkShiftsRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>
                    
                </div>

                <br>

                <div class="row">
                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"ConfigPoSStartingCodeCashiers" | translate}}</label>
                        <div>
                            <input type="number" min="0" name="StartingCodeCashiers" class="form-control" 
                            formControlName="StartingCodeCashiers" style=" margin: 0px;"
                            placeholder='{{"ConfigPoSStartingCodeCashiers" | translate}}'>
                        </div>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('StartingCodeCashiers').hasError('required') || mainForm.get('StartingCodeCashiers').invalid) && ( mainForm.get('StartingCodeCashiers').dirty ||  mainForm.get('StartingCodeCashiers').touched)">
                            {{"ConfigPoSStartingCashiersCodeRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>

                    <br>

                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"ConfigPoSStartingCodeCashRegisiters" | translate}}</label>
                        <div>
                            <input type="number" min="0" name="StartingCodeCashRegisiters" class="form-control" 
                            formControlName="StartingCodeCashRegisiters" style=" margin: 0px;" 
                            placeholder='{{"ConfigPoSStartingCodeCashRegisiters" | translate}}'>
                        </div>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('StartingCodeCashRegisiters').hasError('required') || mainForm.get('StartingCodeCashRegisiters').invalid) && ( mainForm.get('StartingCodeCashRegisiters').dirty ||  mainForm.get('StartingCodeCashRegisiters').touched)">
                            {{"ConfigPoSStartingCashRegisitersCodeRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>

                    <br>

                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"ConfigPoSStartingCodeWorkShifts" | translate}}</label>
                        <div>
                            <input type="number" min="0" name="StartingCodeWorkShifts" class="form-control" 
                            formControlName="StartingCodeWorkShifts" style=" margin: 0px;" 
                            placeholder='{{"ConfigPoSStartingCodeWorkShifts" | translate}}'>
                        </div>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('StartingCodeWorkShifts').hasError('required') || mainForm.get('StartingCodeWorkShifts').invalid) && ( mainForm.get('StartingCodeWorkShifts').dirty ||  mainForm.get('StartingCodeWorkShifts').touched)">
                            {{"ConfigPoSStartingWorkShiftsCodeRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>
                </div>

                <br>

                <div class="row">
                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"ConfigPoSStartingCodeSessions" | translate}}</label>
                        <div>
                            <input type="number" min="0" name="StartingCodeSessions" class="form-control" 
                            formControlName="StartingCodeSessions" style=" margin: 0px;" 
                            placeholder='{{"ConfigPoSStartingCodeSessions" | translate}}'>
                        </div>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('StartingCodeSessions').hasError('required') || mainForm.get('StartingCodeSessions').invalid) && ( mainForm.get('StartingCodeSessions').dirty ||  mainForm.get('StartingCodeSessions').touched)">
                            {{"ConfigPoSStartingSessionsCodeRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>

                    <br>

                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"ConfigPoSStartingCodeOrders" | translate}}</label>
                        <div>
                            <input type="number" min="0" name="StartingCodeOrders" class="form-control" 
                            formControlName="StartingCodeOrders" style=" margin: 0px;" 
                            placeholder='{{"ConfigPoSStartingCodeOrders" | translate}}'>
                        </div>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('StartingCodeOrders').hasError('required') || mainForm.get('StartingCodeOrders').invalid) && ( mainForm.get('StartingCodeOrders').dirty ||  mainForm.get('StartingCodeOrders').touched)">
                            {{"ConfigPoSStartingOrdersCodeRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>
                </div>
            </div>

        </form>
    </div>
</div>