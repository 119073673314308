
export class WarehouseJobRoleModel{
    Id?: number;
    JobRoleId?:number;
    WarehouseId?: number;  
 
    constructor(item?:WarehouseJobRoleModel){
        item = item? item:{};
        this.Id = item.Id? item.Id:0;
        this.JobRoleId = item.JobRoleId? item.JobRoleId:0;
        this.WarehouseId = item.WarehouseId? item.WarehouseId:0;
       
    
    }

}
