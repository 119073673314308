import { Component, HostBinding, Renderer2, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';

import { TableEngine } from 'src/app/invoices/TableEngine'
import { ModalDirective } from 'ngx-bootstrap/modal';
import { SidePopupComponent } from '../../app/aio/shared/side-popup/side-popup.component';
import { Observable, of, Subscription } from 'rxjs';

import { NgSelectComponent } from '@ng-select/ng-select';

import { DatePipe, formatDate } from '@angular/common';


import { ConfigPurchasesService } from "./ConfigPurchasesService";
import { ConfigPurchasesModel } from "./ConfigPurchasesModel";
import { ClientService } from 'src/app/invoices/clients/ClientService';
import { PaymentMethodService } from '../../purchases/purchase-orders/PaymentMethodService';
import { ClientModel } from 'src/app/invoices/clients/ClientModel';
import { Router } from '@angular/router';
import { ItemModel } from 'src/app/invoices/items/ItemModel';
import { PaymentMethodModel } from '../../purchases/purchase-orders/PaymentMethodModel';
import { ListPageHeaderComponent } from 'src/app/containers/pages/list-page-header/list-page-header.component';
import { StoreItemsService } from 'src/app/Invintory/store-items-service.service';

type CloneItemModel = ItemModel & {Hidden: boolean};
type ClonePaymentMethodModel = PaymentMethodModel & {Hidden: boolean};


@Component({
  selector: 'app-config-purchases',
  templateUrl: './config-purchases.component.html',
  styleUrls: ['./config-purchases.component.scss']
})
export class ConfigPurchasesComponent {
  // ////////////////////////////////////////////////////////////////////////////
  // //  VARYING :
  // //  Modify per similar component.
  // ////////////////////////////////////////////////////////////////////////////

  //Enable debugMode to make API modules return static data from within the service class
  debugMode: boolean = false;

  constructor(private formBuilder: FormBuilder, private modelService: ConfigPurchasesService, private renderer: Renderer2,
    private notifications: NotificationsService, private translate: TranslateService,
    private datePipe: DatePipe, private clientService: ClientService, private paymentMethodService: PaymentMethodService,
    private itemService: StoreItemsService, private _router: Router
  ) {
  }

  listHeader = new ListPageHeaderComponent();
  translationName: string = 'ConfigPurchases'

  modelObject: ConfigPurchasesModel = new ConfigPurchasesModel();

  declare lang: any;
  ngOnInit(): void {
    if (this.debugMode) {
      this.enableDebugOnAllServices();
    }
    this.lang = localStorage.getItem('theme_lang');
    this.loadServices();
  }

  currentAction = '';

  loadServices() {
    this.loadModelObject();
  }

  activeItemList: ItemModel [] = [];
  activePaymentMethodList: PaymentMethodModel [] = [];
  tempActiveItemList: ItemModel [] = [];
  tempAddedItem: ItemModel = {};
  tempItemId = null;
  tempPaymentMethodId = null;
  tempActivePaymentMethodList: PaymentMethodModel [] = [];
  tempAddedMethod: ItemModel = {};
  
  loadModelObject(){
    this.currentAction = 'loading';
    this.modelService.getFirst().subscribe(
      config => {
        this.modelObject = config;
        this.mainForm = this.createForm(this.modelObject, this.formBuilder);
        this.currentAction = '';
      }
    )
  }
  
  enableDebugOnAllServices() {
    //this.modelService.debugMode = true;
  }

  mainForm: FormGroup = new FormGroup({
    Id: new FormControl(),
    //AllowedDiscounts: new FormControl(),
    EnableAutoPurchaseOrderCodes: new FormControl(),
    EnableAutoRequestReceiptCodes: new FormControl(),
    EnableAutoPurchaseInvoiceCodes: new FormControl(),
    EnableAutoReturnRequestCodes: new FormControl(),
    EnableAutoPurchaseQuotationCodes: new FormControl(),
    EnableAutoQuotationRequestCodes: new FormControl(),
    StartingPurchaseOrderCode: new FormControl(),
    StartingRequestReceiptCode: new FormControl(),
    StartingPurchaseInvoiceCode: new FormControl(),
    StartingReturnRequestCode: new FormControl(),
    StartingPurchaseQuotationCode: new FormControl(),
    StartingQuotationRequestCode: new FormControl(),
    //UpdateItemValuesAfterInvoiceCreation: new FormControl(),
    //AutoPaymentIfSupplierHasCredit: new FormControl(),
    //PurchaseInvoicesPaidForByDefault: new FormControl(),
    //PurchaseInvoicesRecievedByDefault: new FormControl(),
    //PurchaseInvoicesManualStatusEnabled: new FormControl(),
  });

  createForm(modelObject, formBuilder): FormGroup {
    return formBuilder.group({
      //AllowedDiscounts: [modelObject.AllowedDiscounts, Validators.compose([Validators.required])],
      EnableAutoPurchaseOrderCodes: [modelObject.EnableAutoPurchaseOrderCodes, Validators.compose([Validators.required])],
      EnableAutoRequestReceiptCodes: [modelObject.EnableAutoRequestReceiptCodes, Validators.compose([Validators.required])],
      EnableAutoPurchaseInvoiceCodes: [modelObject.EnableAutoPurchaseInvoiceCodes, Validators.compose([Validators.required])],
      EnableAutoReturnRequestCodes: [modelObject.EnableAutoReturnRequestCodes, Validators.compose([Validators.required])],
      EnableAutoPurchaseQuotationCodes: [modelObject.EnableAutoPurchaseQuotationCodes, Validators.compose([Validators.required])],
      EnableAutoQuotationRequestCodes: [modelObject.EnableAutoQuotationRequestCodes, Validators.compose([Validators.required])],
      StartingPurchaseOrderCode: [modelObject.StartingPurchaseOrderCode, Validators.compose([Validators.required])],
      StartingRequestReceiptCode: [modelObject.StartingRequestReceiptCode, Validators.compose([Validators.required])],
      StartingPurchaseInvoiceCode: [modelObject.StartingPurchaseInvoiceCode, Validators.compose([Validators.required])],
      StartingReturnRequestCode: [modelObject.StartingReturnRequestCode, Validators.compose([Validators.required])],
      StartingPurchaseQuotationCode: [modelObject.StartingPurchaseQuotationCode, Validators.compose([Validators.required])],
      StartingQuotationRequestCode: [modelObject.StartingQuotationRequestCode, Validators.compose([Validators.required])],
      //UpdateItemValuesAfterInvoiceCreation: [modelObject.UpdateItemValuesAfterInvoiceCreation, Validators.compose([Validators.required])],
      //AutoPaymentIfSupplierHasCredit: [modelObject.AutoPaymentIfSupplierHasCredit, Validators.compose([Validators.required])],
      //PurchaseInvoicesPaidForByDefault: [modelObject.PurchaseInvoicesPaidForByDefault, Validators.compose([Validators.required])],
      //PurchaseInvoicesRecievedByDefault: [modelObject.PurchaseInvoicesRecievedByDefault, Validators.compose([Validators.required])],
      //PurchaseInvoicesManualStatusEnabled: [modelObject.PurchaseInvoicesManualStatusEnabled, Validators.compose([Validators.required])],
    });
  }

  editObject() {
    this.currentAction = 'loading';
    let tempObj = new ConfigPurchasesModel();
    tempObj.Id =this.modelObject.Id;
    tempObj.Code = this.modelObject.Code;
    //tempObj.AllowedDiscounts = this.mainForm.getRawValue().AllowedDiscounts;
    tempObj.EnableAutoPurchaseOrderCodes = this.mainForm.getRawValue().EnableAutoPurchaseOrderCodes;
    tempObj.EnableAutoRequestReceiptCodes = this.mainForm.getRawValue().EnableAutoRequestReceiptCodes;
    tempObj.EnableAutoPurchaseInvoiceCodes = this.mainForm.getRawValue().EnableAutoPurchaseInvoiceCodes;
    tempObj.EnableAutoReturnRequestCodes = this.mainForm.getRawValue().EnableAutoReturnRequestCodes;
    tempObj.EnableAutoPurchaseQuotationCodes = this.mainForm.getRawValue().EnableAutoPurchaseQuotationCodes;
    tempObj.EnableAutoQuotationRequestCodes = this.mainForm.getRawValue().EnableAutoQuotationRequestCodes;
    tempObj.StartingPurchaseOrderCode = this.mainForm.getRawValue().StartingPurchaseOrderCode;
    tempObj.StartingRequestReceiptCode = this.mainForm.getRawValue().StartingRequestReceiptCode;
    tempObj.StartingPurchaseInvoiceCode = this.mainForm.getRawValue().StartingPurchaseInvoiceCode;
    tempObj.StartingReturnRequestCode = this.mainForm.getRawValue().StartingReturnRequestCode;
    tempObj.StartingPurchaseQuotationCode = this.mainForm.getRawValue().StartingPurchaseQuotationCode;
    tempObj.StartingQuotationRequestCode = this.mainForm.getRawValue().StartingQuotationRequestCode;
    //tempObj.UpdateItemValuesAfterInvoiceCreation = this.mainForm.getRawValue().UpdateItemValuesAfterInvoiceCreation;
    //tempObj.AutoPaymentIfSupplierHasCredit = this.mainForm.getRawValue().AutoPaymentIfSupplierHasCredit;
    //tempObj.PurchaseInvoicesPaidForByDefault = this.mainForm.getRawValue().PurchaseInvoicesPaidForByDefault;
    //tempObj.PurchaseInvoicesRecievedByDefault = this.mainForm.getRawValue().PurchaseInvoicesRecievedByDefault;
    //tempObj.PurchaseInvoicesManualStatusEnabled = this.mainForm.getRawValue().PurchaseInvoicesManualStatusEnabled;
    this.executeEditObject(tempObj);
    console.log("saved object", tempObj);
  }

  executeEditObject(Object) {
    this.modelService.update(Object).subscribe(result => {
      if (result === true) {
        this.currentAction = '';
        this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyEdited"));
        this.goBack();
      }
      else {
        this.toastError(this.translate.instant("Error"), this.translate.instant("FailedEdit"));
        this.currentAction = '';
      }
    })
  }

  toastSuccess(toastHeader: string, toastBody: string): void {
    this.notifications.create(toastHeader, toastBody,
      NotificationType.Success, { timeOut: 3000, showProgressBar: true });
  }
  toastError(toastHeader: string, toastBody: string): void {
    this.notifications.create(toastHeader, toastBody,
      NotificationType.Error, { timeOut: 3000, showProgressBar: true });
  }

  goBack(){
    this.modelObject = {};
    this.currentAction = "";
    this._router.navigate(['app/aio/Purchases']); 
  }
}
