export class PurchaseQuotationFileModel {
    Id?: number;
    Code?: number;
    PurchaseQuotationId?: number;
    PurchaseQuotationCode?: number;
    FileUrl?: string;
    constructor(obj?: PurchaseQuotationFileModel) {
        obj = obj ? obj : {};
        this.Id = obj.Id ? obj.Id : 0;
        this.Code = obj.Code ? obj.Code : 0;
        this.PurchaseQuotationId = obj.PurchaseQuotationId ? obj.PurchaseQuotationId : 0;
        this.PurchaseQuotationCode = obj.PurchaseQuotationCode ? obj.PurchaseQuotationCode : 0;
        this.FileUrl = obj.FileUrl ? obj.FileUrl : '';
    }

}