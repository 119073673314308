export class PurchaseQuotationItemTaxModel {
    Id?: number;
    Code?: number;
    PurchaseQuotationItemId?: number;
    PurchaseQuotationItemCode?: number;
    TaxId?: number;
    TaxNameAr?: string;
    TaxNameEn?: string;
    TaxIsRate?: boolean;
    TaxValue?: number; 
    constructor(obj?: PurchaseQuotationItemTaxModel) {
        obj = obj ? obj : {};
        this.Id = obj.Id ? obj.Id : 0;
        this.Code = obj.Code ? obj.Code : 0;
        this.PurchaseQuotationItemId = obj.PurchaseQuotationItemId ? obj.PurchaseQuotationItemId : 0;
        this.PurchaseQuotationItemCode = obj.PurchaseQuotationItemCode ? obj.PurchaseQuotationItemCode : 0;
        this.TaxId = obj.TaxId ? obj.TaxId : 0;
        this.TaxNameAr = obj.TaxNameAr ? obj.TaxNameAr : '';
        this.TaxNameEn = obj.TaxNameEn ? obj.TaxNameEn : '';
        this.TaxIsRate = obj.TaxIsRate ? obj.TaxIsRate : true;
        this.TaxValue = obj.TaxValue ? obj.TaxValue : 0;
    }

}