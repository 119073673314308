import { JournalFooterCostCenterModel } from '../../dailyrestrictions/journal-footer-cost-center.model';
import { AssetsFileModel } from './assets-files.model';
import { DepreciationOfAssetsModel } from './depreciation-of-assets.model';
export class AssetsModel{
    Id                      ?: number;
    Name                    ?: string;
    PurchaseDate            ?: Date;
    ServiceStartDate        ?: Date;
    MainAccountId           ?: number;
    PlaceId                 ?: number;
    ProductiveAge           ?: number;
    EmployeeId              ?: number;
    Description             ?: string;
    PurchaseAmount          ?: number;
    CurrencyId              ?: number;
    CashAccountId           ?: number;
    Tax1Id                  ?: number;
    Tax2Id                  ?: number;
    ScrapValue              ?: number;
    ScrapType               ?: string;
    PeriodValue             ?: number;
    PeriodType              ?: string;
    FixedDepreciationValue  ?: number;
    PeriodDepreciation      ?: number;
    PricePerUnit            ?: number;
    TheNameOfTheLargestUnit ?: string;
    TotalParentUnits        ?: number;
    EpreciationExpenseAccountId        ?: number;
    AccumulatedDepreciationAccountId        ?: number;
    TheEndDateOfDepreciation?:Date;
    Files?:AssetsFileModel[];
    CostCenters?:JournalFooterCostCenterModel[];
    DepreciationList?:DepreciationOfAssetsModel[];
    constructor(item?:AssetsModel){
        item = item ? item : {};
        this.Id                       = item.Id                       ? item.Id                       : 0;
        this.EpreciationExpenseAccountId      = item.EpreciationExpenseAccountId       ? item.EpreciationExpenseAccountId      : 0;
        this.AccumulatedDepreciationAccountId = item.AccumulatedDepreciationAccountId  ? item.AccumulatedDepreciationAccountId : 0;
        this.Name                     = item.Name                     ? item.Name                     : '';
        this.PurchaseDate             = item.PurchaseDate             ? item.PurchaseDate             : new Date();
        this.ServiceStartDate         = item.ServiceStartDate         ? item.ServiceStartDate         : new Date();
        this.MainAccountId            = item.MainAccountId            ? item.MainAccountId            : 0;
        this.PlaceId                  = item.PlaceId                  ? item.PlaceId                  : 0;
        this.ProductiveAge            = item.ProductiveAge            ? item.ProductiveAge            : 0;
        this.EmployeeId               = item.EmployeeId               ? item.EmployeeId               : 0;
        this.Description              = item.Description              ? item.Description              : '';
        this.PurchaseAmount           = item.PurchaseAmount           ? item.PurchaseAmount           : 0;
        this.CurrencyId               = item.CurrencyId               ? item.CurrencyId               : 0;
        this.CashAccountId            = item.CashAccountId            ? item.CashAccountId            : 0;
        this.Tax1Id                   = item.Tax1Id                   ? item.Tax1Id                   : 0;
        this.Tax2Id                   = item.Tax2Id                   ? item.Tax2Id                   : 0;
        this.ScrapValue               = item.ScrapValue               ? item.ScrapValue               : 0;
        this.ScrapType                = item.ScrapType                ? item.ScrapType                : '';
        this.PeriodValue              = item.PeriodValue              ? item.PeriodValue              : 0;
        this.PeriodType               = item.PeriodType               ? item.PeriodType               : 'Month';
        this.FixedDepreciationValue   = item.FixedDepreciationValue   ? item.FixedDepreciationValue   : 0;
        this.PeriodDepreciation       = item.PeriodDepreciation       ? item.PeriodDepreciation       : 0;
        this.PricePerUnit             = item.PricePerUnit             ? item.PricePerUnit             : 0;
        this.TheNameOfTheLargestUnit  = item.TheNameOfTheLargestUnit  ? item.TheNameOfTheLargestUnit  : '';
        this.TotalParentUnits         = item.TotalParentUnits         ? item.TotalParentUnits         : 0;
        this.TheEndDateOfDepreciation         = item.TheEndDateOfDepreciation         ? item.TheEndDateOfDepreciation         : null;
        this.Files = item.Files ? item.Files : [];
        this.CostCenters = item.CostCenters ? item.CostCenters : [];
        this.DepreciationList = item.DepreciationList ? item.DepreciationList : [];
    }
}