export class NotesAndAttachmentModel{
    Id?: number;
    Date?:Date;
    Time?:Date;
    ActionId?:number;
    ActionStatusId?:number;
    Notes?:string;
    EmployeeId?:number;
    ActionStatusName?:string;
    ActionName?:string;

    
 
    constructor(item?:NotesAndAttachmentModel){
        item = item? item:{};
        this.Id = item.Id? item.Id:0;
        this.Date = item.Date? item.Date:new Date();
        this.Time = item.Time? item.Time:new Date();
        this.Notes = item.Notes? item.Notes:'';
        this.ActionId = item.ActionId? item.ActionId:0;
        this.ActionStatusId = item.ActionStatusId? item.ActionStatusId:0;
        this.EmployeeId = item.EmployeeId? item.EmployeeId:0;
        this.ActionStatusName = item.ActionStatusName? item.ActionStatusName:'';
        this.ActionName = item.ActionName? item.ActionName:'';
    }

}