
export class TaxModel {
    Id?: number;
    ItemID?: number;
    TotalTax?: number;
    TaxId?: number;
    TotalPriceAfterTax?: number;
    NameAr?:string;
    NameEn?:string;
    Percentage?:number;
    IsRate?:boolean;
    Rate?:number;
    TaxType ?: string;
    SubType ?: string;  
  constructor(item?: TaxModel) {
        item = item ? item : {};
        this.Id = item.Id ? item.Id : 0;
        this.Percentage = item.Percentage ? item.Percentage : 0;
        this.Rate = item.Rate ? item.Rate : 0;
        this.TotalTax = item.TotalTax ? item.TotalTax : 0;
        this.ItemID = item.ItemID ? item.ItemID : 0;
        this.TaxId = item.TaxId ? item.TaxId : 0;
        this.TotalPriceAfterTax = item.TotalPriceAfterTax ? item.TotalPriceAfterTax : 0;
        this.IsRate = item.IsRate ? item.IsRate : false;
        this.NameAr = item.NameAr ? item.NameAr : '';
        this.NameEn = item.NameEn ? item.NameEn : '';
        this.TaxType = item.TaxType ? item.TaxType : '';
        this.SubType = item.SubType ? item.SubType : '';
    }
}