import { Component } from '@angular/core';

@Component({
    // moduleId: module.id,
    selector: 'accounting-dashboard',
    templateUrl: 'accounting-dashboard.component.html',
    styleUrls: ['accounting-dashboard.component.scss']
})
export class AccountingDashboardComponent {

}
