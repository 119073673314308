<!--component html goes here -->
<!--component html goes here -->
<div class="pagecontent">
    <div style="display: block;">
        <!-- <app-company-api></app-company-api> 
        
            "ExpensessType":"Expensess Type",
"AccountFications":"Account Fications",
"AccountMenu":"Account Menu",
"AccountFicationCostType":"Account Fication Cost Type",
"CheckTypes":"Check Types",
"CheckStatuses":"Check Statuses",
"GenerateAccountType":"Generate Account Type",
"AccountType":"Account Type",
"BankCode":"Bank Code",
"CreatCountry":"Creat Country",
"CreatGovernorate":"Creat Governorate",
"CityCode":"City Code",
"AccountingDashboard":"Accounting Dashboard"
        -->
    </div>
    <div id="linkButtons" style="display:block;">
        <!-- <div class="glide__slide ng-star-inserted glide__slide--active d-inline-block"
            style="width: 200px; margin: 5px;">
            <div class="icon-row-item" style="width: inherit; cursor: pointer;">
                <div class="card">
                    <div class="card-body text-center" routerLink="List-All-Productes">
                        <img src="assets/icons/expense.png" width="40px;" height="40px;">
                        <h3>{{"Productes" | translate}}</h3>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="glide__slide ng-star-inserted glide__slide--active d-inline-block"
        style="width: 200px; margin: 5px;">
        <div class="icon-row-item" style="width: inherit; cursor: pointer;">
            <div class="card">
                <div class="card-body text-center" routerLink="Category"><i class="simple-icon-wallet"></i>
                    <h3>{{"Categories" | translate}}</h3>
                </div>
            </div>
        </div>
    </div>
    <div class="glide__slide ng-star-inserted glide__slide--active d-inline-block"
    style="width: 200px; margin: 5px;">
    <div class="icon-row-item" style="width: inherit; cursor: pointer;">
        <div class="card">
            <div class="card-body text-center" routerLink="Brand"><i class="iconsminds-tag"></i>
                <h3>{{"Brands" | translate}}</h3>
            </div>
        </div>
    </div>
    </div>

        <!-- <div class="glide__slide ng-star-inserted glide__slide--active d-inline-block"
            style="width: 200px; margin: 5px;">
            <div class="icon-row-item" style="width: inherit; cursor: pointer;">
                <div class="card">
                    <div class="card-body text-center" routerLink="List-All-Warehouse">
                        <img src="assets/icons/budget.png" width="40px;" height="40px;">

                        <h3>{{"TheWarehouses" | translate}}</h3>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="glide__slide ng-star-inserted glide__slide--active d-inline-block"
        style="width: 200px; margin: 5px;">
        <div class="icon-row-item" style="width: inherit; cursor: pointer;">
            <div class="card">
                <div class="card-body text-center" routerLink="List-All-Warehouse"><i class="iconsminds-factory"></i>
                    <h3>{{"TheWarehouses" | translate}}</h3>
                </div>
            </div>
        </div>
    </div>
    <div class="glide__slide ng-star-inserted glide__slide--active d-inline-block"
    style="width: 200px; margin: 5px;">
    <div class="icon-row-item" style="width: inherit; cursor: pointer;">
        <div class="card">
            <div class="card-body text-center" routerLink="List-All-Productes"><i class="iconsminds-box-close"></i>
                <h3>{{"Productes" | translate}}</h3>
            </div>
        </div>
    </div>
</div>
        <!-- <div class="glide__slide ng-star-inserted glide__slide--active d-inline-block"
            style="width: 200px; margin: 5px;">
            <div class="icon-row-item" style="width: inherit; cursor: pointer;">
                <div class="card">
                    <div class="card-body text-center" routerLink="List-All-PriceList">
                        <img src="assets/icons/hamburger.png" width="40px;" height="40px;">

                        <h3>{{"PriceLists" | translate}}</h3>
                    </div>
                </div>
            </div>
        </div> -->
       
        <div class="glide__slide ng-star-inserted glide__slide--active d-inline-block"
        style="width: 200px; margin: 5px;">
        <div class="icon-row-item" style="width: inherit; cursor: pointer;">
            <div class="card">
                <div class="card-body text-center" routerLink="List-All-PriceList"><i class="iconsminds-receipt-4"></i>
                    <h3>{{"PriceLists" | translate}}</h3>
                </div>
            </div>
        </div>
    </div>

    </div>
</div>
<!--             { path: 'aio/ExpensessTypeComponent', component: ExpensessTypeComponent },
{ path: 'aio/AccountFicationsComponent', component: AccountFicationsComponent },
{ path: 'aio/', component: AccountMenuComponent },
{ path: 'aio/', component: AccountFicationCostTypeComponent },
{ path: 'aio/CheckTypesComponent', component: CheckTypesComponent },
{ path: 'aio/', component: CheckStatusesComponent },
{ path: 'aio/GenerateAccountTypeComponent', component: GenerateAccountTypeComponent },
{ path: 'aio/AccountTypeComponent', component: AccountTypeComponent },
{ path: 'aio/BankCodeComponent', component: BankCodeComponent },
{ path: 'aio/CreatCountryComponent', component: CreatCountryComponent },
{ path: '', component: CreatGovernorateComponent },
{ path: 'aio/CityCodeComponent', component: CityCodeComponent }, -->