import { environment } from './../../../../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AccountsCostCentersModel } from '../models/account-cost-center.model';



@Injectable({
  providedIn: 'root'
})
export class AccountsCostCentersService {
  
  constructor(private http: HttpClient) {
  
  }

    baseUrl = environment.baseApiUrl;
     token = localStorage.getItem("token");
     httpOptions = {
      headers: new HttpHeaders({
        // 'Content-Type': 'application/json; charset=utf-8',
        // 'Authorization': "Bearer " + token,
         "Authorization": "Bearer " + this.token,
         "Content-Type": "application/json"
      })
    };
    GetByAccountId(AccountId:number): Observable<any> {
   
      return this.http
        .get<any>(`${this.baseUrl}AccountsCostCenters/GetByAccountId/${AccountId}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    GetByCostCenterId(CostCenterId:number): Observable<any> {
   
      return this.http
        .get<any>(`${this.baseUrl}AccountsCostCenters/GetByCostCenterId/${CostCenterId}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
    insert(payload: AccountsCostCentersModel[]): Observable<any> {
      
      return this.http
        .post<any>(`${this.baseUrl}AccountsCostCenters/Insert`, payload,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
    update(payload: AccountsCostCentersModel[]): Observable<any> {
      return this.http
        .post<any>(`${this.baseUrl}AccountsCostCenters/Update`, payload,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
    delete(payload: AccountsCostCentersModel): Observable<any> {
        return this.http
        .post<any>(`${this.baseUrl}AccountsCostCenters/Delete`, payload,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
  
    deleteList(payload: AccountsCostCentersModel []): Observable<any> {
      return this.http
        .post<any>(`${this.baseUrl}AccountsCostCenters/DeleteList`,JSON.stringify(payload),this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error => error)));
    }
  
}
