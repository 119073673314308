import { SidePopupComponent } from 'src/app/views/app/aio/shared/side-popup/side-popup.component';
import { TableEngine } from '../../../../../invoices/TableEngine';
import { Component, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ItemBalanceService } from '../../shared/item-balance.service';
import { ItemBalanceModel } from '../../shared/Models/ItemBalanceModel';
import { analytics } from 'firebase';
import { StoreDataModel } from '../../../store-data/StoreDataModel';
import { ItemsModel } from '../../shared/Models/ItemsModel';
import { BalanceNameModel } from '../../../Balance-Name/balance-name/balance-name-model';
import { BalanceNameService } from '../../../Balance-Name/balance-name/balance-name.service';
import { FooterService } from '../../shared/footer.service';
import { StoreDataService } from '../../../store-data/store-data.service';
import { FooterModel } from '../../shared/Models/FooterModel';

@Component({
    // moduleId: module.id,
    selector: 'add-item-balance',
    templateUrl: 'add-item-balance.component.html',
    styleUrls: ['add-item-balance.component.scss']
})
export class AddItemBalanceComponent {
    // StoreDataService: any;
    // BalanceNameService: any;
    
    
   
//     constructor(private formBuilder: FormBuilder, private modelService: ItemBalanceService, private renderer: Renderer2,
//         private notifications: NotificationsService, private translate: TranslateService,
//         private StoreDataService:StoreDataService, private BalanceNameService :BalanceNameService
// ) {
//     }
    tableEngine = new TableEngine(this.translate, this.notifications);
    currentScreen: any = 'Table';
    translationName: string = 'Item'
    sortableKeys = [];
    searchableKeys = ['BalanceNameEn', 'BalanceNameAr'];
    tableView: any = true;
    ItemBalance : ItemBalanceModel={};
    FooterModel:FooterModel[]=[];  
    FModel:FooterModel={};  
    // Footer:ItemBalanceModel[]=[];  
    
    // SelectedItems:ItemsModel[]=[];
    modelObject: ItemBalanceModel = new ItemBalanceModel();
    StoreDataList:StoreDataModel[]=[];
    BalanceDataList:BalanceNameModel[]=[];
    // ItemsModel:ItemsModel[]=[]
    Form:FormGroup;
    declare lang: any;
    declare itemOrder: any;
    currentAction = '';
    savedPage = 0;
    currentPage = 1;
    deletionList: any[] = [];
    prevAction = '';    
    ItemBalanceModel: ItemBalanceModel[] = [];
    addToListId = 0;
    ItemsBalanceHeaderId=0;
    ItemBalanceId=0;
    ItemsList:ItemsModel[]=[];
    Id=0;
    // columnDefs = [{ field: "Description" },{ field: "Date" }, { field: "TotalValue" }, { field: "BalanceNameAr" }, { field: "BalanceNameEn" }];
  
  
   
    TotalQuantity:number=0;
    constructor(public router:Router, 
        private fb:FormBuilder,
        private modelService: ItemBalanceService,
        private FooterService: FooterService,
        private StoreDataService:StoreDataService, private BalanceNameService :BalanceNameService,
        private notifications: NotificationsService, private translate: TranslateService,
        private route: ActivatedRoute
        ){
            this.Id = + route.snapshot.paramMap.get('Id');

            
        }
        // Form:FormGroup;

        createForm(modelObject):FormGroup{
            debugger;
            return this.fb.group({
                // Id:[this.ItemBalance.Id],
                Id: [modelObject.Id >= 0 ? modelObject.Id : 0],
                BalanceNameAr:[modelObject.BalanceNameAr],
                BalanceNameEn:[modelObject.BalanceNameEn],
                BalanceNameId:[modelObject.BalanceNameId,Validators.required],
                StoreAr:[modelObject.StoreAr],
                StoreEn:[modelObject.StoreEn],
                StoreDataId:[modelObject.StoreDataId,Validators.required],
                Description:[modelObject.Description],
                Date:[modelObject.Date,Validators.required],
                TotalValue:[modelObject.TotalValue,Validators.required],
                BranchesDataId:[this.ItemBalance.BranchesDataId=0],
                CompanyDataId:[this.ItemBalance.CompanyDataId=0],
                Month:[this.ItemBalance.Month=0],
                Year:[this.ItemBalance.Year=0],
                IsPosted:[this.ItemBalance.IsPosted=false],                
            }) 
        }
     
        ItemValueDetails:FooterModel={};

// DetailsForm:FormGroup;

        // public lang;
        rowData:any[]=[];
        Item:any;
        $event:any;
        @ViewChild('sideModalRef', { static: true }) sideModalRef: SidePopupComponent = null;
        @ViewChild('delModal', { static: true }) delModal: ModalDirective;
        @ViewChild('smallModal', { static: true }) smallModal: ModalDirective = null;
        ngOnInit(): void {
            debugger;

            if(this.Id > 0)
            {                
                this.lang = localStorage.getItem('theme_lang');
               
                this.modelService.getAll()
                .subscribe(data=>{
                    this.ItemBalance=data;
                });
                this.BalanceNameService.getAll()
                .subscribe(data=>{
                    this.BalanceDataList=data;
                });
                this.StoreDataService.getAll()
                .subscribe(data=>{
                    this.StoreDataList=data;
                });
                this.modelService.getById(this.Id).subscribe(data=>{
                    this.ItemBalance=data;
                    this.modelObject = data;
                    this.FooterModel = this.ItemBalance.Footer;
                    this.Form = this.createForm(this.ItemBalance);
                });

            }
            else{
                this.lang = localStorage.getItem('theme_lang');
             
                this.modelService.getAll()
                .subscribe(data=>{
                    this.ItemBalance=data;
                });
                this.BalanceNameService.getAll()
                .subscribe(data=>{
                    this.BalanceDataList=data;
                });
                this.StoreDataService.getAll()
                .subscribe(data=>{
                    this.StoreDataList=data;
                });
                this.AddonClick();
            this.Form = this.createForm(this.modelObject);

            }
            // this.Form=this.createForm(this.modelObject);
            
            this.modelService.getAllItems()            
            .subscribe(data=>
                {
                    this.ItemsList=data
                });
        }


    addToList(){     
        debugger; 
        var i = this.FooterModel.findIndex(x=>x.ItemsBalanceHeaderId == this.addToListId);
        if(i >= 0){
            var item = this.FooterModel[i];
            
            this.FooterModel.push(item);            
            this.FooterModel.splice(i,1);
            this.addToListId = 0;
        }
    }

    
    AddonClick(){

        var FooterModelDet = new FooterModel();
        FooterModelDet.ItemBalanceId = 0, 
        FooterModelDet.ItemBalanceId= 0;
        FooterModelDet.ItemsBalanceHeaderId = 0 ;
        FooterModelDet.ItemsHeaderId = 0 ;
        FooterModelDet.ItemsHeaderId = 0 ;
        FooterModelDet.ItemsUnitId = 0 ;
        FooterModelDet.ItemQuantity = 0 ;
        FooterModelDet.ItemPrice = 0 ;
        FooterModelDet.ItemValue = 0 ;
        FooterModelDet.Items=[]
        this.FooterModel.push(FooterModelDet);
    }

    
    getBalanceById(Id: any): any {
        debugger;

        if (Id > 0) {
            return this.BalanceDataList.find(i => i.Id === Id);
        }
        else {
            return new BalanceNameModel();
        }
    }
    getStoreDataById(Id: any): any{
        debugger;

        if (Id > 0) {
            return this.StoreDataList.find(i => i.Id === Id);
        }
        else {
            return new StoreDataModel();
        }
    }
    getItemsById(Id:any):any{
    if(Id>0)
    {
    return this.ItemsList.find(i=>i.Id===Id)
    }
    else
    {
    return new ItemsModel();
    }
    }


    TValue=0; 
    calculateTotalItemValue() {
        let total = 0;
        this.FooterModel.forEach(item => {
          total +=item.ItemQuantity * item.ItemPrice;
        });
        this.TValue = total;
        return total;
      }

    setItem(Item){
    debugger;
    var i = this.ItemsList.findIndex(x=>x.Id == Item.ItemsHeaderId);
    if(i>-1){
        Item.ItemPrice = this.ItemsList[i].Cost;
    }
    }

    removeItemsFooter(Id: any): any {

        let index = this.FooterModel.findIndex(x => x.ItemsBalanceHeaderId === Id);
        this.FooterModel.splice(index, 1);
         this.calculateTotalItemValue();

      }
   

    Save(){
       
    debugger;
    this.currentAction = 'loading';
    this.tableEngine.savedPage = this.tableEngine.currentPage;
    this.modelObject = new ItemBalanceModel();
    this.modelObject.BalanceNameAr=this.Form.getRawValue().BalanceNameAr;
    this.modelObject.BalanceNameEn=this.Form.getRawValue().BalanceNameEn;
    this.modelObject.BalanceNameId=this.Form.getRawValue().BalanceNameId;
    this.modelObject.Date=this.Form.getRawValue().Date;
    this.modelObject.Description=this.Form.getRawValue().Description;
    this.modelObject.Footer=this.Form.getRawValue().Footer;
    this.modelObject.StoreAr=this.Form.getRawValue().StoreAr;
    this.modelObject.StoreEn=this.Form.getRawValue().StoreEn;
    this.modelObject.StoreDataId=this.Form.getRawValue().StoreDataId;
    this.modelObject.TotalValue=this.TValue;
    this.modelObject.Month=0;
    this.modelObject.Year=0;
    this.modelObject.Id=0;
    this.modelObject.IsPosted=false;
    this.modelObject.BranchesDataId=0;
    this.modelObject.CompanyDataId=0;  
    this.modelObject.Footer = this.FooterModel;
    if(this.Id == 0){
        this.modelService.insert(this.modelObject)
        .subscribe(data => {
          this.router.navigate(['app/aio/Inventory/items-list']);
        })
      }
      else{
        this.modelService.update(this.modelObject)
        .subscribe(data => {
          this.router.navigate(['app/aio/Inventory/items-list']);
        })
      }
    }
  goBack(){
    this.router.navigate(['app/aio/Inventory/items-list']);
  }

}
