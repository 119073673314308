

export class CouncilAndMembersAffairsModel{
    Id?: number;
    ArabicName                       ?: string;
    EnglishName                      ?: string;
    Phone                            ?: string;
    Email                            ?: string;
    Gender                           ?: string;
    IsEgyptian                       ?: boolean;
    NationalID                       ?: string;
    PositionId                     ?: number;
    
    PassportNumber                   ?: string;
    BranchNameEn                     ?: string;
    BranchNameAr                     ?: string;
    CountryEn                        ?: string;
    CountryAr                        ?: string;
    NationaltyID                     ?: number;
    City                             ?: number;
    GovernorateId                    ?: number;
    EmergencyContactNumber           ?: string;
    BirthDate                        ?: Date|null;
    ImageURL                         ?: string;
    PWS                              ?: string;
    IsActive                         ?: boolean;
    Address                          ?: string;
    TeamId                           ?: number;
    BranchId                         ?: number;
    RoleId                           ?: number;
    IsBlocked                        ?: boolean;
    IsStudent                        ?: boolean;
    FieldsStudyOrWorkId              ?: number;
    SubFieldsStudyOrWorkId           ?: number;
    CollegeId                        ?: number;
    SchoolId                         ?: number;
    AcademicYear                     ?: string;
    VolunteerItherFiled              ?: string;
    CurrentPosition                  ?: string;
    Employer                         ?: string;
    UserId                           ?: string;
    IsHaveExpereanceInVolunteerFildes?: boolean;
    HowDidYouKnowAboutUs             ?: string;
    IsMembershipExpered              ?: boolean;
    IsDisability?:boolean;
    DisabilityType?:string;
    CreateBy                         ?: string;
    CreateDate                       ?: Date|null;
    ModifiedBy                       ?: string;
    ModifiedDate                     ?: Date|null;
    IsDeleted                        ?: boolean;
    DeletedBy                        ?: string;
    DeletedDate                      ?: Date|null;
    GovernorateNameEn                ?:string;
    GovernorateNameAr                ?:string;
    CityNameEn                       ?:string;
    CityNameAr                       ?:string;
    SectorNameAr                     ?: string;
    SectorNameEn                     ?: string;
    FieldNameAr                      ?: string;
    FieldNameEn                      ?: string;
    HasWork                          ?: boolean;
    StudentType                      ?: string;
    CollegeNameAr                    ?: string;
    CollegeNameEn                    ?: string;
    SchoolNameAr                     ?: string;
    SchoolNameEn                     ?: string;
    VolunteerNumber                  ?:string;  
    UniversityNameAr                 ?:string;
    UniversityNameEn                 ?:string;
    InstituteNameAr                  ?:string;
    InstituteNameEn                  ?:string;
    EventsCount                      ?:number;
    ActivitiesCount                  ?:number;
    NumberOfActivityPoints           ?:number;
    NumberOfActivityHours            ?:number;
    EndMembershipDate                ?:Date|null;
    MembershipIsIsEnded              ?:boolean;
    Latitude                         ?: number;
	Langitude                        ?: number;
	Zoom                             ?:number;
    EndMembershipRequest             ?:boolean;
    TransferMembershipRequest        ?:boolean;
    RenewMembershipRequest           ?:boolean;
    HasOtherExperinceField           ?:boolean;
    OtherExperinceField              ?:string;
    OtherNationalty                  ?:string;
    IsAccepted                       ?: boolean;
    IsObsolete                       ?:boolean;
    IsRejected                       ?:boolean;
    PrefierdInterview                ?: number;
    FirstArabicName                  ?: string;
    SecondArabicName                 ?: string;
    LastArabicName                   ?: string;
    SocialStatus?:string;
    EducationalQualification?:string;
    GraduationYear?:string;
    constructor(item ?: CouncilAndMembersAffairsModel){
        item = item ? item : {};
        this.Id = item.Id ? item.Id : 0;
        this.EducationalQualification = item.EducationalQualification ? item.EducationalQualification : '';
        this.GraduationYear = item.GraduationYear ? item.GraduationYear : '';
        
        this.PositionId = item.PositionId ? item.PositionId : 0;
        this.PrefierdInterview = item.PrefierdInterview ? item.PrefierdInterview : 1;
        this.IsAccepted = item.IsAccepted ? item.IsAccepted : false;
        this.IsObsolete = item.IsObsolete ? item.IsObsolete : false;
        this.IsRejected = item.IsRejected ? item.IsRejected : false;
        this.HasOtherExperinceField = item.HasOtherExperinceField ? item.HasOtherExperinceField : false;
        this.OtherExperinceField = item.OtherExperinceField ? item.OtherExperinceField : '';
        this.OtherNationalty = item.OtherNationalty ? item.OtherNationalty : '';
        this.RenewMembershipRequest = item.RenewMembershipRequest ? item.RenewMembershipRequest : false;
        this.TransferMembershipRequest = item.TransferMembershipRequest ? item.TransferMembershipRequest : false;
        this.EndMembershipRequest = item.EndMembershipRequest ? item.EndMembershipRequest : false;
        this.Latitude  = item.Latitude  ? item.Latitude  : 0;
        this.Langitude = item.Langitude ? item.Langitude : 0;
        this.Zoom      = item.Zoom      ? item.Zoom      : 0;
        this.ArabicName                        = item.ArabicName                        ? item.ArabicName                        : '';
        this.EnglishName                       = item.EnglishName                       ? item.EnglishName                       : '';
        this.UniversityNameAr= item.UniversityNameAr ? item.UniversityNameAr : '';
        this.UniversityNameEn= item.UniversityNameEn ? item.UniversityNameEn : '';
        this.InstituteNameAr= item.InstituteNameAr ? item.InstituteNameAr : '';
        this.InstituteNameEn= item.InstituteNameEn ? item.InstituteNameEn : '';
        this.CountryEn                       = item.CountryEn                       ? item.CountryEn                       : '';
        this.CountryAr                       = item.CountryAr                       ? item.CountryAr                       : '';
        this.BranchNameEn                       = item.BranchNameEn                       ? item.BranchNameEn                      : '';
        this.BranchNameAr                       = item.BranchNameAr                       ? item.BranchNameAr                      : '';
        this.GovernorateNameEn                       = item.GovernorateNameEn                       ? item.GovernorateNameEn                      : '';
        this.GovernorateNameAr                       = item.GovernorateNameAr                       ? item.GovernorateNameAr                      : '';
        this.CityNameEn = item.CityNameEn? item.CityNameEn : '';
        this.CityNameAr = item.CityNameAr? item.CityNameAr : '';
        this.Phone                             = item.Phone                             ? item.Phone                             : '';
        this.Email                             = item.Email                             ? item.Email                             : '';
        this.Gender                            = item.Gender                            ? item.Gender                            : '';
        this.IsEgyptian                        = item.IsEgyptian                        ? item.IsEgyptian                        : true;
        this.NationalID                        = item.NationalID                        ? item.NationalID                        : '';
        this.PassportNumber                    = item.PassportNumber                    ? item.PassportNumber                    : '';
        this.NationaltyID                      = item.NationaltyID                      ? item.NationaltyID                      : 0;
        this.City                              = item.City                              ? item.City                              : 0;
        this.GovernorateId                     = item.GovernorateId                     ? item.GovernorateId                     : 0;
        this.EmergencyContactNumber            = item.EmergencyContactNumber            ? item.EmergencyContactNumber            : '';
        this.BirthDate                         = item.BirthDate                         ? item.BirthDate                         : null;
        this.ImageURL                          = item.ImageURL                          ? item.ImageURL                          : '';
        this.PWS                               = item.PWS                               ? item.PWS                               : '';
        this.IsActive                          = item.IsActive                          ? item.IsActive                          : false;
        this.Address                           = item.Address                           ? item.Address                           : '';
        this.TeamId                            = item.TeamId                            ? item.TeamId                            : 0;
        this.BranchId                          = item.BranchId                          ? item.BranchId                          : 0;
        this.RoleId                            = item.RoleId                            ? item.RoleId                            : 0;
        this.IsBlocked                         = item.IsBlocked                         ? item.IsBlocked                         : false;
        this.IsStudent                         = item.IsStudent                         ? item.IsStudent                         : false;
        this.FieldsStudyOrWorkId               = item.FieldsStudyOrWorkId               ? item.FieldsStudyOrWorkId               : 0;
        this.SubFieldsStudyOrWorkId               = item.SubFieldsStudyOrWorkId               ? item.SubFieldsStudyOrWorkId               : 0;
        this.CollegeId                         = item.CollegeId                         ? item.CollegeId                         : 0;
        this.SchoolId                          = item.SchoolId                          ? item.SchoolId                          : 0;
        this.AcademicYear                      = item.AcademicYear                      ? item.AcademicYear                      : '';
        this.VolunteerItherFiled               = item.VolunteerItherFiled               ? item.VolunteerItherFiled               : '';
        this.CurrentPosition                   = item.CurrentPosition                   ? item.CurrentPosition                   : '';
        this.Employer                          = item.Employer                          ? item.Employer                          : '';
        this.UserId                            = item.UserId                            ? item.UserId                            : '';
        this.IsHaveExpereanceInVolunteerFildes = item.IsHaveExpereanceInVolunteerFildes ? item.IsHaveExpereanceInVolunteerFildes : false;
        this.HowDidYouKnowAboutUs              = item.HowDidYouKnowAboutUs              ? item.HowDidYouKnowAboutUs              : '';
        this.IsDisability                      = item.IsDisability                      ? item.IsDisability                      : false;
        this.IsMembershipExpered               = item.IsMembershipExpered               ? item.IsMembershipExpered               : false;
        this.DisabilityType                    = item.DisabilityType                    ? item.DisabilityType                    : '';
        this.CreateBy                          = item.CreateBy                          ? item.CreateBy                          : '';
        this.CreateDate                        = item.CreateDate                        ? item.CreateDate                        : null;
        this.ModifiedBy                        = item.ModifiedBy                        ? item.ModifiedBy                        : '';
        this.ModifiedDate                      = item.ModifiedDate                      ? item.ModifiedDate                      : null;
        this.IsDeleted                         = item.IsDeleted                         ? item.IsDeleted                         : false;
        this.DeletedBy                         = item.DeletedBy                         ? item.DeletedBy                         : '';
        this.DeletedDate                       = item.DeletedDate                       ? item.DeletedDate                       : null;
        this.SectorNameAr= item.SectorNameAr? item.SectorNameAr: '';
        this.SectorNameEn= item.SectorNameEn? item.SectorNameEn: '';
        this.FieldNameAr= item.FieldNameAr? item.FieldNameAr: '';
        this.FieldNameEn= item.FieldNameEn? item.FieldNameEn: '';
        this.HasWork = item.HasWork ? item.HasWork : true;
        this.StudentType = item.StudentType ? item.StudentType : '';
        this.CollegeNameAr = item.CollegeNameAr  ? item.CollegeNameAr  : '';
        this.CollegeNameEn = item.CollegeNameEn  ? item.CollegeNameEn  : '';
        this.SchoolNameAr  = item.SchoolNameAr   ? item.SchoolNameAr   : '';
        this.SchoolNameEn  = item.SchoolNameEn   ? item.SchoolNameEn   : '';
        this.VolunteerNumber  = item.VolunteerNumber   ? item.VolunteerNumber   : '';
        this.EventsCount      = item.EventsCount   ? item.EventsCount   : 0;
        this.ActivitiesCount  = item.ActivitiesCount   ? item.ActivitiesCount   : 0;
        this.NumberOfActivityPoints  = item.NumberOfActivityPoints   ? item.NumberOfActivityPoints   : 0;
        this.NumberOfActivityHours   = item.NumberOfActivityHours    ? item.NumberOfActivityHours    : 0;
        this.EndMembershipDate = item.EndMembershipDate ? item.EndMembershipDate : null;
        this.MembershipIsIsEnded = item.MembershipIsIsEnded ? item.MembershipIsIsEnded : false;
        this.FirstArabicName   = item.FirstArabicName   ? item.FirstArabicName    : '';
        this.SecondArabicName  = item.SecondArabicName  ? item.SecondArabicName   : '';
        this.LastArabicName    = item.LastArabicName    ? item.LastArabicName     : '';
        this.SocialStatus = item.SocialStatus?item.SocialStatus:'';
    }
}