import { Component,  ViewChild, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'app-list-page-header',
  templateUrl: './list-page-header.component.html',
  styleUrls: ['./list-page-header.component.scss']
})
export class ListPageHeaderComponent {
  displayOptionsCollapsed = false;

  @Input() showOrderBy = true;
  @Input() showSearch = true;
  @Input() showItemsPerPage = true;
  @Input() showDisplayMode = true;
  @Input() displayMode = 'list';
  @Input() selectAllState = '';
  @Input() itemsPerPage = 10;
  @Input() itemOptionsPerPage = [5, 10, 20];
  @Input() itemOrder = { label: 'Product Name', value: 'title' };
  @Input()  itemOptionsOrders = [
    { label: 'Product Name', value: 'title' },
    { label: 'Category', value: 'category' },
    { label: 'Status', value: 'status' }];

  @Output() changeDisplayMode: EventEmitter<string> = new EventEmitter<string>();
  @Output() addNewItem: EventEmitter<any> = new EventEmitter();
  @Output() selectAllChange: EventEmitter<any> = new EventEmitter();
  @Output() searchKeyUp: EventEmitter<any> = new EventEmitter();
  @Output() itemsPerPageChange: EventEmitter<any> = new EventEmitter();
  @Output() changeOrderBy: EventEmitter<any> = new EventEmitter();

  @ViewChild('search') search: any;
  constructor() { }


   ngOnInit(): void {
   }

  onSelectDisplayMode(mode: string): void {
    this.changeDisplayMode.emit(mode);
  }
  onAddNewItem(): void {
    this.addNewItem.emit(null);
  }
  selectAll(event): void  {
    this.selectAllChange.emit(event);
  }
  onChangeItemsPerPage(item): void  {
    this.itemsPerPageChange.emit(item);
  }

  onChangeOrderBy(item): void  {
    this.itemOrder = item;
    this.changeOrderBy.emit(item);
  }

  onSearchKeyUp($event): void {
    this.searchKeyUp.emit($event);
  }

//////////////////////////////////////////
//K



//@Input() tableFrom = new Date();
//@Input() tableTo = new Date();
@Input() dateSearch: boolean = false;
@Input() SearchKey: string = '';
@Input() AddEnabled: boolean = true;
@Input() tableEngine: any = null;
SearchFrom: Date = null;
SearchTo: Date = null;
SearchTerm = null;

searchDateRange(): void {
  this.tableEngine.filterDataByDateRange(this.SearchFrom,this.SearchTo,this.SearchKey==''?'Date':this.SearchKey);
}
searchUsingKeys(): void {
  this.tableEngine.filterData();
}

deleteSelected(): void {
  this.tableEngine.showDelModal('delMulti');
}

}
