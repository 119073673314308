export class WorkItemModel{
    Id?:number;
    Code?:string;
    NameAr?:string;
    NameEn?:string;
    ParentId?:number;
    ParentAr?:string;
    ParentEn?:string;
    Level?:number;
    UnitId?:number;
    UnitAr?:string;
    UnitEn?:string;
    Cost?:number;
    
    
    constructor(obj?:WorkItemModel){
        obj = obj? obj:{};
        this.Id = obj.Id? obj.Id:0;
        this.Code = obj.Code? obj.Code:'';
        this.NameAr = obj.NameAr? obj.NameAr:'';
        this.NameEn = obj.NameEn? obj.NameEn:'';
        this.ParentId = obj.ParentId? obj.ParentId:0;
        this.ParentAr = obj.ParentAr? obj.ParentAr:'';
        this.ParentEn = obj.ParentEn? obj.ParentEn:'';
        this.Level = obj.Level? obj.Level:0;
        this.UnitId = obj.UnitId? obj.UnitId:0;
        this.UnitAr = obj.UnitAr? obj.UnitAr:'';
        this.UnitEn = obj.UnitEn? obj.UnitEn:'';
        this.Cost = obj.Cost? obj.Cost:0;
        
    }

}