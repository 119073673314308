export class DataFilterer{
    constructor(){
        //console.log('data presenter loaded');

    }
    OnInit(){
        //console.log('data presenter used');
    }
    filterData(searchTerm:any, searchKeys:any[], ogData:any[]){
        let objectKeys : any [] = [];
        let result : any [] = [];
        for (let index = 0; index < searchKeys.length; index++) {
            objectKeys.push(searchKeys[index] as keyof typeof ogData[0]);
        }
        //console.log(objectKeys);
        for (let dataIndex = 0; dataIndex < ogData.length; dataIndex++) {
            for (let keyIndex = 0; keyIndex < objectKeys.length; keyIndex++) {
                if (result.includes(ogData[dataIndex]))
                {
                    //console.log(ogData[dataIndex].NameAr +  " is a duplicate. Ignored.");
                    break;
                }
                else
                {
                    if (ogData[dataIndex][objectKeys[keyIndex]].toString().toLowerCase().includes(searchTerm.toString().toLowerCase()))
                    {
                        result.push(ogData[dataIndex])
                    }
                }
            }
        }
        //console.log(result);
        return result;
    }
}