export class CatchReceiptCostCenterModel{
    Id?: number;
    CostCenterId?: number;
    Percent?: number;
    Amount?: number;
    CatchReceiptId?: number;
    constructor(item ?: CatchReceiptCostCenterModel){
        item = item ? item : {};
        this.Id = item.Id ? item.Id : 0;
        this.CostCenterId = item.CostCenterId ? item.CostCenterId : 0;
        this.Percent = item.Percent ? item.Percent : 0;
        this.Amount = item.Amount ? item.Amount : 0;
        this.CatchReceiptId = item.CatchReceiptId ? item.CatchReceiptId : 0;
    }
}