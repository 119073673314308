

<div class="row">
    <div class="col-12">
      <div class="card mb-4">
        <div class="card-body">
          <!-- <h5 class="mb-4">{{ 'From Date' | translate }}</h5> -->
          <div class="row">
            <div class="col-12 col-lg-6 mb-4">
              <label><span>{{ 'DateFrom' | translate }}</span></label>
              <input 
              type="text" onfocus="(this.type='date')" onblur="if(!this.value)this.type='text'"
              onchange="if(!this.value)this.type='text'" name="Date" class="form-control"
              [(ngModel)]="search.From"
              placeholder='{{"DateFrom" | translate}}'>
            </div>
            <div class="col-12 col-lg-6 mb-4">
              <label><span>{{ 'DateTo' | translate }}</span></label>
              <input 
              type="text" onfocus="(this.type='date')" onblur="if(!this.value)this.type='text'"
              onchange="if(!this.value)this.type='text'" name="Date" class="form-control"
              [(ngModel)]="search.To"
              placeholder='{{"DateTo" | translate}}'>
            </div>
            <div class="col-12 col-lg-6 mb-4 "  >
              <button type="button" (click)="Search()" class="btn btn-primary default mb-1" style="margin: 1px;">{{'Search' | translate}}</button>{{" "}}
              <button type="button" (click)="print()" class="btn btn-secondary default mb-1 " style="margin: 1px;">{{'Print' | translate}}</button>{{" "}}
              <button type="button" (click)="exportTable()" class="btn btn-success default mb-1" style="margin: 1px;">{{'PDF' | translate}}</button>{{" "}}
              <button type="button"  class="btn btn-primary default mb-1" style="margin: 1px;">{{'Excel' | translate}}</button>{{" "}}
           
            </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  <div id="contentToConvert">
    <div class="row" >
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-body">
              <h3 class="float-center" style="text-align: center;">{{ 'Trial Balance Balances Report' | translate }}</h3>
              <h4 class="float-center" style="text-align: center;">{{  today| date:'dd/MM/yyyy' }}</h4>
              <h6 class="float-center" style="text-align: center;">{{ 'Future Core' | translate }}</h6>
             
          </div>
        </div>
      </div>
    </div>
    <div class="big-wrapper row" *ngIf="dataSource ">
  
      <div class="card mb-5 tree-explorer-side col-md-12">
        <div class="card-body ">
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" dir="rtl">
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef> 
                {{'Name'|translate}}
              </th>
              <td mat-cell *matCellDef="let data"> 
                <button mat-icon-button 
                        [style.visibility]="!data.expandable ? 'hidden' : ''"
                        [style.marginLeft.px]="data.Level * 32"
                        (click)="tabletreeControl.toggle(data)">
                  <mat-icon class="mat-icon-rtl-mirror">
                    {{tabletreeControl.isExpanded(data) ? 'expand_more' : 'chevron_right'}}
                  </mat-icon>
                </button>
          
                {{data.Name}}
              </td>
            </ng-container> 
          
            <ng-container matColumnDef="Credit">
              <th mat-header-cell *matHeaderCellDef> {{'Credit'|translate}} </th>
              <td mat-cell *matCellDef="let data"> {{data.Credit}} </td>
            </ng-container> 
              
            <ng-container matColumnDef="Debit">
              <th mat-header-cell *matHeaderCellDef> {{'Debit'|translate}} </th>
              <td mat-cell *matCellDef="let data"> {{data.Debit}} </td>
            </ng-container> 
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
      </div>
      </div>

    

   
  </div>