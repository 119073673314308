export class CodingUnitModel{
    Id?:number;
    NameAr?:string;
    NameEn?:string;
    UnitInTheTaxSystem?:string;
    UnitInTheCompanySystem?:string;
    AcceptFromTaxs?:boolean;
    constructor(obj?:CodingUnitModel){
        obj = obj? obj:{};
        this.Id = obj.Id? obj.Id:0;
        this.NameAr = obj.NameAr? obj.NameAr:'';
        this.NameEn = obj.NameEn? obj.NameEn:'';
        this.UnitInTheTaxSystem = obj.UnitInTheTaxSystem? obj.UnitInTheTaxSystem:'';
        this.UnitInTheCompanySystem = obj.UnitInTheCompanySystem? obj.UnitInTheCompanySystem:'';
        this.AcceptFromTaxs = obj.AcceptFromTaxs? obj.AcceptFromTaxs:true;
    }
}