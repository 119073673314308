<div bsModal #smallModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">
    <div class="modal-dialog modal-sm">
        <div class="modal-content"
        style="width: 90%; text-align: center; margin-top: 20px; margin-left: auto; margin-right: auto;">
        <div style="align-content: center; display: inline-block;">
            <div class="modal-header" style="display: block; padding: 0px 0px 5px 0px; text-align: center; ">
                <h1 id="dialog-child-name" class="modal-title">
                    {{tableEngine.currentFormMode+translationName | translate}}</h1>
            </div>
            <div class="modal-body">
                <form class="md-float-material form-material" [formGroup]="mainForm">
                    <div class="form-group form-primary">
                        <label class="float-label push righ">{{"StartDate" | translate}}</label>
       

                            <input (change)="changePeriod()" *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                            [type]="modelObject.Id>0?'date':'text'" onfocus="(this.type='date')"
                            onblur="if(!this.value)this.type='text'" onchange="if(!this.value)this.type='text'"
                            name="Date" class="form-control" formControlName="StartDate"
                            placeholder='{{"Start Date" | translate}}'>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" 
                        type="text"
                       name="StartDate">{{modelObject.StartDate}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('StartDate').hasError('required') || mainForm.get('StartDate').invalid) && ( mainForm.get('StartDate').dirty ||  mainForm.get('StartDate').touched)">
                            {{"StartDateRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>
                    <div class="form-group form-primary">
                        <label class="float-label push righ">{{"Period" | translate}}</label>
                        <ng-select (change)="changePeriod()"  formControlName="Period"    [hideSelected]="true">
                            <ng-option selected desabled [value]="1">{{ "1 Yesr"|translate}}</ng-option>
                            <ng-option selected desabled [value]="2">{{ "6 Months"|translate}}</ng-option>
                            <ng-option selected desabled [value]="3">{{ "3 Months"|translate}}</ng-option>
                            <ng-option selected desabled [value]="4">{{ "1 Month"|translate}}</ng-option>
                            <ng-option selected desabled [value]="5">{{ "Custom"|translate}}</ng-option>
            
                        </ng-select>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="Period">{{modelObject.Period}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('Period').hasError('required') || mainForm.get('Period').invalid) && ( mainForm.get('Period').dirty ||  mainForm.get('Period').touched)">
                            {{"PeriodRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>
                    <div class="form-group form-primary">
                        <label class="float-label push righ">{{"EndDate" | translate}}</label>
                        <input  *ngIf="(tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit')&&mainForm.controls['Period'].value==5" type="date" name="EndDate"
                            class="form-control" formControlName="EndDate"
                            placeholder='{{"EndDate" | translate}}'>
                        <label *ngIf="tableEngine.currentFormMode==='Info'||mainForm.controls['Period'].value!=5" class="form-control" type="text"
                            name="EndDate">{{mainForm.controls['EndDate'].value|date:'dd/MM/yyyy'}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('EndDate').hasError('required') || mainForm.get('EndDate').invalid) && ( mainForm.get('EndDate').dirty ||  mainForm.get('EndDate').touched)">
                            {{"EndDateRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>
                    <div class="form-group form-primary">
                        <label class="float-label push righ">{{"Description" | translate}}</label>
                        <input *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'" type="text" name="Description"
                            class="form-control" formControlName="Description"
                            placeholder='{{"Description" | translate}}'>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="Description">{{modelObject.Description}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('Description').hasError('required') || mainForm.get('Description').invalid) && ( mainForm.get('Description').dirty ||  mainForm.get('Description').touched)">
                            {{"Description Required" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>
                    <div style="margin-left: 5px; text-align: center; margin-top: 30px;">
                        <button *ngIf="tableEngine.currentFormMode==='Add'"
                            [disabled]="mainForm.invalid || tableEngine.currentAction==='loading'" id="smallModalSubmitButton"
                            class="btn btn-secondary" (click)="addObject()"
                            style="font-weight: bold; width: 100%; text-align: center;">{{"Add" |
                            translate}}</button>
                        <button *ngIf="tableEngine.currentFormMode==='Edit'"
                            [disabled]="mainForm.invalid || tableEngine.currentAction==='loading'" id="smallModalSubmitButton"
                            class="btn btn-secondary" (click)="editObject()"
                            style="font-weight: bold; width: 100%; text-align: center;">{{"Edit"
                            | translate}}</button>
                        <button class="btn btn-light" (click)="smallModal.hide()"
                            style="font-weight: bold; width: 100%; text-align: center; margin-top: 10px;">{{"Close" |
                            translate}}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    </div>
</div>


<div bsModal #delModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">
    <div class="modal-dialog modal-sm">
        <div class="modal-content"  >
            <button type="button" class="close pull-right" aria-label="Close" (click)="delModal.hide()"
                style="margin-right: 20px; margin-left: 20px; margin-top: 10px; width: 20px; display: inline;">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-header" style="display: inline; padding: 0px 0px 5px 0px;">
                <h4 id="dialog-child-name" class="modal-title pull-left"  style="text-align: center;">
                    {{"Are you sure you have closed the financial year?" |
                    translate}}</h4>
            </div>
            <div class="modal-body">
                <div class="form-group form-primary" *ngIf="tableEngine.deletionList.length < tableEngine.ogData.length 
                || tableEngine.deletionList.length === 1" 
                style="text-align: center;">
                    <div *ngFor="let item of tableEngine.deletionList;" style="text-align: center;">
                        <label type="text">- {{"To close the current open period of"|translate}}{{item.StartDate|date:'dd/MM/yyyy'}}{{"To"|translate}}
                            {{item.EndDate|date:'dd/MM/yyyy'}}{{"Please select dates for the next period."|translate}}
                        </label>
                        <span class="form-bar"></span>
                    </div>
                </div>
                <div class="form-group form-primary" style="text-align: center;" *ngIf="tableEngine.deletionList.length > 1 && 
                    tableEngine.deletionList.length === tableEngine.ogData.length">
                    <label type="text">
                        <h1 style="font-weight: bolder; text-align: center;">{{translationName+"DeleteAll" | translate}}</h1>
                    </label>
                    <span class="form-bar"></span>
                </div>

                <div style="margin-left: 5px; text-align: center; margin-top: 30px;">
                    <button class="btn btn-danger" id="smallModalDeleteButton" (click)="deleteObject()"
                        [disabled]="tableEngine.currentAction==='loading'"
                        style="font-weight: bold; width: 100%; text-align: center;">{{"Set up the next period" | translate}}</button>
                    <button class="btn btn-light" (click)="delModal.hide()"
                        style="font-weight: bold; width: 100%; text-align: center; margin-top: 10px;">{{"Close" |
                        translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div  *ngIf="currentScreen === 'Form'" class="FormScreen">
    <a (click)="currentScreen='Table'">back</a>
</div>

<div *ngIf="currentScreen === 'Table'" class="FormScreen" class="tableScreen">
    <div id="divtop" style="display: block;height: 47px; width: 100%;">
        <div id="divsearchmiddle" style="display: inline-block; width: 70%;
        height: inherit;  text-align: center; padding: 0px; margin: 0px;">
            <div class="search" style="display: inline-block; margin-left: 50px; margin-right: 50px;">
                <div class="d-inline-block">
                    <div class="search-sm d-inline-block mr-1 mb-1 align-top" style="width: 600px;">
                        <input type="search" ngDefaultControl [(ngModel)]="tableEngine.SearchTerm" (keyup)="tableEngine.changeFilter()" (change)="tableEngine.changeFilter()"
                            class="form-control input-sm full-data-search" placeholder="{{translationName+'Search' | translate}}">
                    </div>
                </div>
            </div>
        </div>
    
        <!-- <div id="divsearchleft" style="display: inline-block; width: 30%; height: inherit;">
            <div style="display: block; height: 30%; ">
                &nbsp;
            </div>
            <div class="search" style="display: block; height: 70%; text-align: center;">
                <button class="btn btn-primary d-inline-block"
                    style="font-weight: bold; text-align: center; padding: 0px; width: 150px; height: 30px;"
                    (click)="showModal(null,'Add')" [disabled]="tableEngine.currentAction==='loading'">{{"Add"+translationName |
                    translate}}</button>
    
            </div>
        </div> -->
    
    </div>
    
        
    <div id="divmiddle" style="display: block; margin-top: 5px;">
    
        <div style="width: 100%;">
            <div>
                <table class="table">
                    <thead class="thead-light">
                        <th style="text-align: center; padding:5px;">
                            <div class=" text-zero" style="display: inline-block;">
                                <div class="top-right-button-container2 btn-group" dropdown>
                                    <div class="btn btn-primary pl-4 pr-0 check-button">
                                        <label class="custom-control custom-checkbox mb-0 d-inline-block"
                                            style="width: 10px;">
                                            <input type="checkbox" class="custom-control-input"
                                                [checked]="tableEngine.selectAllState==='checked'"
                                                [indeterminate]="tableEngine.selectAllState === 'indeterminate'"
                                                (change)="tableEngine.selectAll($event)">
                                            <span class="custom-control-label">&nbsp;</span>
                                        </label>
                                    </div>
                                    <button id="button-split" type="button" dropdownToggle style="width: 10px;"
                                        class="btn btn-primary dropdown-toggle dropdown-toggle-split"
                                        aria-controls="dropdown-split">
                                        <span class="caret"></span>
                                    </button>
                                    <ul id="dropdown-split" *dropdownMenu class="dropdown-menu dropdown-menu-right"
                                        role="menu" aria-labelledby="button-split">
                                        <li role="menuitem"><a class="dropdown-item">
                                                <button class="btn btn-danger d-inline-block"
                                                    style=" font-weight: bold; text-align: center; padding: 0px; width: 150px; height: 30px;"
                                                    (click)="tableEngine.showDelModal('delMulti')"
                                                    [disabled]="tableEngine.selected.length===0 || tableEngine.currentAction==='loading'">{{"DeleteSelected"
                                                    |
                                                    translate}}</button></a></li>
                                    </ul>
                                </div>
                            </div>
                        </th>
                        <th class="sorticon" (click)="tableEngine.changeSort(tableEngine.getSort('StartDate'))" [ngClass]="(itemOrder.label==='StartDate')?'sortkey sorticon':'sorticon'">
                            <div [ngClass]="{'iconsminds-up---down': (itemOrder.label!='StartDate'), 
                            'iconsminds-down': ((itemOrder.label==='StartDate') && tableEngine.orderAsc === true),
                            'iconsminds-up': ((itemOrder.label==='StartDate') && tableEngine.orderAsc === false)}"></div></th>
                        <th class="sortOption" (click)="tableEngine.changeSort(tableEngine.getSort('StartDate'))" 
                        [ngClass]="{'sortkey': (itemOrder.label==='StartDate')}">
                                {{"StartDate" | translate}}
                        </th>
                        <th class="sorticon" (click)="tableEngine.changeSort(tableEngine.getSort('EndDate'))" [ngClass]="(itemOrder.label==='EndDate')?'sortkey sorticon':'sorticon'">
                            <div [ngClass]="{'iconsminds-up---down': (itemOrder.label!='EndDate'), 
                            'iconsminds-down': ((itemOrder.label==='EndDate') && tableEngine.orderAsc === true),
                            'iconsminds-up': ((itemOrder.label==='EndDate') && tableEngine.orderAsc === false)}"></div></th>
                        <th class="sortOption" (click)="tableEngine.changeSort(tableEngine.getSort('EndDate'))" 
                        [ngClass]="{'sortkey': (itemOrder.label==='EndDate')}">
                                {{"EndDate" | translate}}
                        </th>
                        <th class="sorticon" (click)="tableEngine.changeSort(tableEngine.getSort('IsOpen'))" [ngClass]="(itemOrder.label==='IsOpen')?'sortkey sorticon':'sorticon'">
                            <div [ngClass]="{'iconsminds-up---down': (itemOrder.label!='IsOpen'), 
                            'iconsminds-down': ((itemOrder.label==='IsOpen') && tableEngine.orderAsc === true),
                            'iconsminds-up': ((itemOrder.label==='IsOpen') && tableEngine.orderAsc === false)}"></div></th>
                        <th class="sortOption" (click)="tableEngine.changeSort(tableEngine.getSort('IsOpen'))" 
                        [ngClass]="{'sortkey': (itemOrder.label==='IsOpen')}">
                                {{"Status" | translate}}
                        </th>
       
                        <th [colSpan]="2" style="text-align: center;">
                            <div id="displayOptions" style="
                            display: inline; margin-top: 10px; margin-left: 5px; margin-right: 5px;">
                                <span class="text-muted text-small" style=" width: 50px; height: 40px;">
                                    {{"ResultCount" | translate}}
                                </span>
                                <span dropdown class="d-inline-block position-relative">
                                    <button class="btn btn-outline-dark btn-xs dropdown-toggle" type="button"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" dropdownToggle>
                                        {{tableEngine.itemsPerPage}}
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-sm text-right"
                                        *dropdownMenu>
                                        <li role="menuitem" *ngFor="let item of tableEngine.itemOptionsPerPage"><a
                                                [ngClass]="item === tableEngine.itemsPerPage ? 'dropdown-item active' : 'dropdown-item'"
                                                (click)="tableEngine.paginateData(item, 1)">{{item}}</a></li>
                                    </div>
                                </span>
                            </div>
                        </th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let obj of tableEngine.paginatedData;  let i=index">
                            <td style="text-align: center;">
                                <div class="itemCheck mb-0 custom-checkbox custom-control" style="display: inline-block;">
                                    <input type="checkbox" class="custom-control-input" [id]="'customCheck'+i"
                                        [checked]="tableEngine.isSelected(obj)" (change)="tableEngine.onSelect(obj)">
                                    <label class="custom-control-label" [for]="'customCheck'+i"></label>
                                </div>
                            </td>
                            <td colspan="2">{{obj.StartDate|date:'dd/MM/yyyy'}}</td>
                            <td colspan="2">{{obj.EndDate|date:'dd/MM/yyyy'}}</td>
                            <td colspan="2" *ngIf="obj.IsOpen">{{'Open'|translate}}</td>
                            <td colspan="2" *ngIf="!obj.IsOpen">{{'Colsed'|translate}}</td>
                   
                                    <td style="text-align: center">
                                        <i
                                          class="simple-icon-info"
                                          (click)="showModal(obj.Id, 'Info')"                  
                                          tooltip="{{ 'Info' | translate }}"
                                          placement="auto"
                                          style="
                                            font-size: 20px;
                                            font-weight: bolder;
                                            cursor: pointer;
                                            vertical-align: middle;
                                            color: #17a2b8;
                                            margin: 10px;
                                          "
                                          
                                         
                                        ></i>
                                        <i
                                        class="simple-icon-pencil"
                                        (click)="showModal(obj.Id, 'Edit')"
                                        tooltip="{{ 'Edit' | translate }}"
                                        placement="auto"
                                        style="
                                          font-size: 20px;
                                          font-weight: bolder;
                                          cursor: pointer;
                                          vertical-align: middle;
                                          color: #ffc107;
                                          margin: 10px;
                                        "
                                       
                                       
                                      ></i>
                                      <i
                                      class="simple-icon-close"
                                      (click)="tableEngine.showDelModal(obj.Id)"
                                      tooltip="{{ 'Close' | translate }}"
                                      placement="auto"
                                      style="
                                        font-size: 20px;
                                        font-weight: bolder;
                                        cursor: pointer;
                                        vertical-align: middle;
                                        color: #dc3545;
                                        margin: 10px;
                                      "
                                    ></i>
                                      </td>
                        </tr>
                    </tbody>
                    <thead class="thead-light">
                  
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
            
             
                    </thead>
                </table>
            </div>
        </div>
    </div>
    
    <div id="divbottom" style="display: block; height: 47px;">
    
        <div id="divsearchright" style="float: right;  width: 33.33%; padding: 0px;">&nbsp;</div>
    
        <div id="divsearchmiddle" style="display: inline-block; width: 33.33%; height: 100%;">
    
            <div style="text-align: center;">
                <bdo dir="ltr">
                    <pagination class="pagination-sm2" style="padding: 0px, 0px, 0px, 0px; margin: 0px, 0px, 0px, 0px;
                    display: inline-block;" ngDefaultControl [(ngModel)]="currentPage" [boundaryLinks]="true"
                        [totalItems]="tableEngine.filteredData.length" [itemsPerPage]="tableEngine.itemsPerPage"
                        [customFirstTemplate]="firstTemplate" [customLastTemplate]="lastTemplate"
                        [customPreviousTemplate]="prevTemplate" [customNextTemplate]="nextTemplate" [maxSize]="5"
                        (pageChanged)="tableEngine.changePage($event)">
                    </pagination>
                </bdo>
            </div>
        </div>
    
        <div id="divsearchleft" style="float: left; width: 33.33%; height: 100%;">&nbsp;</div>
    
    </div>
</div>

<div>

</div>

<ng-template #nextTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i *ngIf="lang==='en-US'" class='simple-icon-arrow-right'></i>
    <i *ngIf="lang==='ar-EG'" class='simple-icon-arrow-left'></i>
</ng-template>

<ng-template #prevTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i *ngIf="lang==='en-US'" class='simple-icon-arrow-left'></i>
    <i *ngIf="lang==='ar-EG'" class='simple-icon-arrow-right'></i>
</ng-template>

<ng-template #lastTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i class='simple-icon-control-end'></i>
</ng-template>

<ng-template #firstTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i class='simple-icon-control-start'></i>
</ng-template>


<simple-notifications></simple-notifications>