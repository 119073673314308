import { Component, ViewChild } from '@angular/core';
import productItems from 'src/app/data/products';

import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { GetProject } from '../../serves/project_serves';
import { ProjectModel } from '../../model/projectModel';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
    // moduleId: module.id,
    selector: 'project-view',
    templateUrl: 'project-view.component.html',
    styleUrls: ['project-view.component.scss']
})
export class ProjectViewComponent {
  projectList:ProjectModel[]=[];

  projectModel : ProjectModel = new ProjectModel({});
  @ViewChild('staticModal') addModal: ModalDirective;


  constructor(private formBuilder:FormBuilder,private getProjectServes:GetProject,){

    getProjectServes.getAll().subscribe(
      item=>{
          this.projectList=item;
          console.log("this.projectList",this.projectList);
          
      }
  );
  }

ProjectForm : FormGroup = new FormGroup({
  Id: new FormControl(),
  Name: new FormControl(),
  ArName: new FormControl(),
});

    createForm():FormGroup{
        return this.formBuilder.group({  
          Id: [this.projectModel.Id>=0? this.projectModel.Id : 0],
          Name: [this.projectModel.NameEn, Validators.compose([Validators.required, Validators.pattern(/^[a-zA-Z]+([\s]*[a-zA-Z]+)*$/)])],
          ArName: [this.projectModel.NameAr, Validators.compose([Validators.required, Validators.pattern(/^[\u0621-\u064A]+([\s]*[\u0621-\u064A]+)*$/)])],
        })}
        showAddModal(): void {
          debugger;
            this.projectModel = new ProjectModel;
            this.ProjectForm = this.createForm();
            this.addModal.show();
          }
          hideAddModal(): void {
            this.addModal.hide();
          }


          showEditModal(): void {
            var item= this.projectList[0]
            this.ProjectForm.getRawValue().Name= item.NameEn;
            this.ProjectForm.getRawValue().ArName = item.NameAr;
            
            // this.hideEditModal();

              // this.projectModel = new ProjectModel;
              // this.ProjectForm.Id=
               this.ProjectForm = this.createForm();
              this.addModal.show();

            }

          addProject(){
            this.projectModel = new ProjectModel();
            //  this.projectModel.Id = this.projectList.length > 0 ?this. projectList.length+1 : 0;
            this.projectModel.NameAr = this.ProjectForm.getRawValue().Name;
            this.projectModel.NameEn = this.ProjectForm.getRawValue().ArName;
            // this.currency.Value = this.ProjectForm.getRawValue().Value;
            //  this.projectList.push(this.projectModel);
        console.log("projectList",this.projectList);
        this.getProjectServes.postData(this.projectModel).subscribe(
          item=>{
            if(item){
              console.log("succsess");
              
            }
          }
        );
            // this.lastId += 1;
            this.addModal.hide();
          }
        
          
  // sanitizeInput(input:string)
  // {
  //   switch(input){
  //     case 'currencyEN': {
  //       let inputValue = this.ProjectForm.get('Name');
  //       var english = /^[a-zA-Z\s]+$/;
  //       if(!english.test(inputValue.value.toString())){
  //         inputValue.setValue(inputValue.value.replace(/[^a-zA-Z\s]/gi, ''));
  //       }
  //       break;
  //     }
  //     case 'currencyAR': {
  //       let inputValue = this.ProjectForm.get('ArName');
  //       var arabic = /^[\u0621-\u064A]+$/;
  //       if(!arabic.test(inputValue.value.toString())){
  //         inputValue.setValue(inputValue.value.replace(/[^\u0621-\u064A\s]/gi, ''));
  //       }
  //       break;
  //     }
  //     // case 'currencyVal': {
  //     //   let inputValue = this.ProjectForm.get('Value');
  //     //   if(inputValue.value===null){
  //     //     inputValue.setValue(0);
  //     //   }
  //     //   break;
  //     // }
  //   }
  // }

  sanitizeNumbers()
  {
    let inputValue = this.ProjectForm.get('Value');
    if(inputValue.value===null){
      inputValue.setValue(0);
    }
  }
  sanitizeEngNames()
  {
    let inputValue = this.ProjectForm.get('Name');
    var english = /^[a-zA-Z\s]+$/;
    if(!english.test(inputValue.value.toString())){
      inputValue.setValue(inputValue.value.replace(/[^a-zA-Z\s]/gi, ''));
    }
  }
  sanitizeArNames()
  {
    let inputValue = this.ProjectForm.get('ArName');
    var arabic = /^[\u0621-\u064A]+$/;
    if(!arabic.test(inputValue.value.toString())){
      inputValue.setValue(inputValue.value.replace(/[^\u0621-\u064A\s]/gi, ''));
    }
  }
  }