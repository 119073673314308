export class RevenueCodesModule{
    Id?:number;
    RevenueCode?:number;
    RevenueEn?:string;
    RevenueAr?:string;
    CreditorAccountNumber?:number;
    CreditorAccountNumberId?:number;
    constructor(obj?:RevenueCodesModule){
        obj = obj? obj:{};
        this.Id= obj.Id ? obj.Id:0;
        this.RevenueCode=obj.RevenueCode? obj.RevenueCode:0;
        this.RevenueEn=obj.RevenueEn? obj.RevenueEn:'';
        this.RevenueAr=obj.RevenueAr? obj.RevenueAr:'';
        this.CreditorAccountNumber=obj.CreditorAccountNumber? obj.CreditorAccountNumber:0;
        this.CreditorAccountNumberId = obj.CreditorAccountNumberId? obj.CreditorAccountNumberId:0;


    }
}