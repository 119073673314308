import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { from, of } from 'rxjs';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { DataFilterer } from 'src/app/views/app/aio/shared/DataFilterer';
import { DataSorter } from 'src/app/views/app/aio/shared/DataSorter';
import { DataPaginator } from 'src/app/views/app/aio/shared/DataPaginator';

@Component({
  selector: 'table-engine',
  template: ''
})
export class TableEngine{
    ogData: any [] = [];
    modelService : any = [];
    declare modelObject : any;
    searchableKeys : any [] = [];
    sortableKeys : any [] = [];
    declare mainForm: FormGroup;
    declare translationName: any;
    
    constructor(private translate: TranslateService, private notifications: NotificationsService ){
    }
    
    OnInit(){
      //for testing purposes
    }
    
    loadTableEngine(ogData, modelService, modelObject, searchableKeys, sortableKeys, mainForm, translationName, 
      itemOrder, sideModalRef, delModal, smallModal, lang){
      this.ogData = ogData;
      this.modelService = modelService;
      this.modelObject = modelObject;
      this.searchableKeys = searchableKeys;
      this.sortableKeys = sortableKeys;
      this.mainForm = mainForm;
      this.translationName = translationName;
      this.itemOrder = itemOrder;
      this.sideModalRef = sideModalRef;
      this.delModal = delModal;
      this.smallModal = smallModal;
      this.lang = lang;
    }

    lang: string;
    currentFormMode: string = '';
    currentAction: string = '';
  
    SearchTerm: any = '';

    filteredData: any [] = [...this.ogData];
    sortedData = [...this.filteredData];
    paginatedData = [...this.filteredData];
    lastId: number = this.ogData.length + 1;
  
    SelectAllBox: any = false;
  
    itemsPerPage = 10;
    itemOptionsPerPage = [5, 10, 20];
    selected: any [] = [];
    selectAllState = '';
    declare itemOrder: any;
    orderAsc: boolean = true;
  
    currentPage = 1;
    savedPage = 0;
  
    dataFilterer = new DataFilterer();
    dataSorter = new DataSorter();
    dataPaginator = new DataPaginator();
  
    loadData(id?:number) {
      debugger;
      this.currentAction = 'loading';
      this.modelService.getAll().subscribe(
        data => {
          this.ogData = data;
          this.sortData();
          this.loadSearch();
          this.currentAction = '';
          console.log('this.ogData',this.ogData);
          
        }
      )
      
    }
    loadDataAndReverse(id?:number) {
      debugger;
      this.currentAction = 'loading';
      this.modelService.getAll().subscribe(
        data => {
          this.ogData = data;
          this.sortData();
          this.reverseSortData() ;
          this.loadSearch();
          this.currentAction = '';
          console.log('this.ogData',this.ogData);
          
        }
      )
      
    }
  
    sortData() {
      debugger;

      this.sortedData = this.dataSorter.sortData(this.itemOrder.property, this.ogData);
      this.filterData();
    }
  
    reverseSortData() {
      this.savedPage = this.currentPage;
      this.sortedData = this.sortedData.slice().reverse();
      this.filterData();
    }
  
    filterDataByDateRange(From:Date, To:Date, dateKeyName:string){
      console.log("datefrom tableengine", From)
      console.log(this.paginatedData);
      
      if(From || To){
        if(From && !To){
          this.filteredData = this.dataFilterer.filterByDateRange(From, new Date(8640000000000000), dateKeyName, this.ogData)
        }
        else if (!From && To){
          this.filteredData = this.dataFilterer.filterByDateRange(new Date(-8640000000000000), To, dateKeyName, this.ogData)
        }
        else if (From && To){
          this.filteredData = this.dataFilterer.filterByDateRange(From, To, dateKeyName, this.ogData)
        }

        if (this.savedPage === 0) {
          this.paginateData(this.itemsPerPage, 1);
        }
        else {
          this.paginateData(this.itemsPerPage,
            Math.ceil((this.filteredData.length / this.itemsPerPage)) >= this.savedPage ?
              this.savedPage :
              Math.ceil((this.filteredData.length / this.itemsPerPage)));
              
              console.log(this.paginatedData);
        }
        this.savedPage = 0;
      }
      else{
        this.filterData();
      }
    }

    DateSearch = false;
    filterData() {
      debugger;

      this.filteredData = this.dataFilterer.filterData(this.SearchTerm, this.searchableKeys, this.sortedData)
      if (this.savedPage === 0) {
        this.paginateData(this.itemsPerPage, 1);
      }
      else {
        this.paginateData(this.itemsPerPage,
          Math.ceil((this.filteredData.length / this.itemsPerPage)) >= this.savedPage ?
            this.savedPage :
            Math.ceil((this.filteredData.length / this.itemsPerPage))
        );
      }
      this.savedPage = 0;
    }
  
    paginateData(newItemsPerPage: number, newCurrentPage: number) {
      debugger;
      this.itemsPerPage = newItemsPerPage;
      this.currentPage = newCurrentPage;
      this.paginatedData = this.filteredData.slice((newCurrentPage - 1) * (newItemsPerPage), (newItemsPerPage * newCurrentPage));
    }
  
    changeSort(newSort: any) {
      console.log(newSort.label);
      
      if (this.itemOrder.label === newSort.label) {
        this.orderAsc = !this.orderAsc;
        const oldPage = this.currentPage;
        this.reverseSortData()
        this.currentPage = oldPage;
        console.log("itemOrder.label === newSort.label");
      }
      else {
        this.orderAsc = true;
        this.itemOrder = newSort;
        this.sortData();
        console.log("itemOrder.label != newSort.label");
        
      }
    }
  
    getSort(label: any) {
      return this.sortableKeys.find(i => i.label === label);
    }
  
    changePage(event: PageChangedEvent): void {
      this.currentPage = event.page;
      this.paginateData(this.itemsPerPage, this.currentPage);
    }
  
    changeFilter() {
      this.deletionList = [];
      this.selected = [];
      this.selectAllState = '';
      this.filterData();
    }
  
    selectAll($event): void {
      console.log(this.selected.length);
      
      if ($event.target.checked) {
        this.selected = [...this.filteredData];
      } else {
        this.selected = [];
      }
      this.setSelectAllState();
    }
  
    setSelectAllState(): void {
      if (this.selected.length >= this.filteredData.length) {
        this.selectAllState = 'checked';
      } else if (this.selected.length !== 0) {
        this.selectAllState = 'indeterminate';
      } else  {
        this.selectAllState = '';
      }
    }
  
    isSelected(p: any): boolean {
      return this.selected.findIndex(x => x.Id === p.Id) > -1;
    }
  
    onSelect(item: any): void {
      if (this.isSelected(item)) {
        this.selected = this.selected.filter(x => x.Id !== item.Id);
      } else {
        this.selected.push(item);
      }
      this.setSelectAllState();
    }
  
    findByCode(list: any, code: any): any {
      return list.find(x => x.Code === code)
    }

    declare sideModalRef: any;
    declare delModal: any;
    declare smallModal: any;

    showAddNewModalRef( func , formBuilder ): void {  //pass "createform()" here
      this.currentFormMode = 'Add'
      this.modelObject = {};
      this.mainForm = func;
      this.mainForm = func(this.modelObject, formBuilder);
      if(this.sideModalRef === undefined){
        if(this.smallModal != undefined){
          this.smallModal.show();
        }
      }
      else{
        this.sideModalRef.show();
      }
    }
    showAddNewWithModelObjectModalRef( func , formBuilder,modelObject ): void {  //pass "createform()" here
      debugger;
      this.currentFormMode = 'Add'
      this.modelObject = modelObject;
      this.mainForm = func;
      this.mainForm = func(this.modelObject, formBuilder);
      if(this.sideModalRef === undefined){
        if(this.smallModal != undefined){
          this.smallModal.show();
        }
      }
      else{
        this.sideModalRef.show();
      }
    }
  
    showEditModalRef(Id: any, func, formBuilder): void {
      this.currentFormMode = 'Edit'
      this.modelObject = this.ogData.find(i => i.Id === Id);
      // of(this.modelObject).subscribe(data => {
      //   this.modelObject = data;
      // })
      this.mainForm = func(this.modelObject, formBuilder);
      if(this.sideModalRef === undefined){
        if(this.smallModal != undefined){
          this.smallModal.show();
        }
      }
      else{
        this.sideModalRef.show();
      }
    }
  
    showInfoModalRef(Id: any, func, formBuilder): void {
      this.currentFormMode = 'Info'
      this.modelObject = this.ogData.find(i => i.Id === Id);
      // of(this.modelObject).subscribe(data => {
      //   this.modelObject = data;
      // })
      //console.log("model object from info engine", this.modelObject);
      
      this.mainForm = func(this.modelObject, formBuilder);
      if(this.sideModalRef === undefined){
        if(this.smallModal != undefined){
          this.smallModal.show();
        }
      }
      else{
        this.sideModalRef.show();
      }
    }
  
    deletionList: any[] = [];
    showDelModal(Id: any) {
      if (Id === 'delMulti') {
        this.deletionList = [...this.selected]
        this.delModal.show();
      }
      else {
        this.currentFormMode = 'Del'
        this.modelObject = this.ogData.find(i => i.Id === Id);
        this.deletionList = [this.ogData.find(i => i.Id === Id)];
        this.delModal.show();
      }
    }
  
    finalizeDeletion(){
      if (this.deletionList.length === 1) {
        this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyDeleted") + " " +
          this.translate.instant("One" + this.translationName));
      }
      else if (this.deletionList.length === 2) {
        this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyDeleted") + " " +
          this.translate.instant("Two" + this.translationName + "s"));
      }
      else if (this.deletionList.length > 2 && this.deletionList.length < 11) {
        this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyDeleted") + " " +
          this.deletionList.length + " " + this.translate.instant(this.translationName + "RowsPlur"));
      }
      else {
        this.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyDeleted") + " " +
          this.deletionList.length + " " + this.translate.instant(this.translationName + "Rows"));
      }

      this.deletionList = [];
      this.selected = [];
  
      this.setSelectAllState();
      if (this.selectAllState === '') {
        this.SelectAllBox = false;
      }
      this.delModal.hide();
    }
  
    toastSuccess(toastHeader: string, toastBody: string): void {
      this.notifications.create(toastHeader, toastBody,
        NotificationType.Success, { timeOut: 3000, showProgressBar: true });
    }
    toastError(toastHeader: string, toastBody: string): void {
      this.notifications.create(toastHeader, toastBody,
        NotificationType.Error, { timeOut: 3000, showProgressBar: true });
    }
    deleteMultiTaxTypes() {
      this.loadData();
      this.delModal.hide();
    }
  
    loadAllData() {
      this.modelService.getAll().subscribe(
        data => {
          this.ogData = data;
          this.loadSearch();
        }
      )
    }
  
    loadSearch() {
      const prevSearch = this.SearchTerm;
      this.SearchTerm = '';
      setTimeout(() => {
        this.SearchTerm = prevSearch;
      }, 1);
    }
}