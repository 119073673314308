import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import data from './products';

@Component({
    // moduleId: module.id,
    selector: 'expense-definition',
    templateUrl: 'expense-definition.component.html',
    styleUrls: ['expense-definition.component.scss']
})
export class ExpenseDefinitionComponent {
    @ViewChild('myTable') table: any;
    expanded: any = {};
    timeout: any;
    rows = data.slice(0, 20).map(({ Id, expensesNumber, DescriptionAr, DescriptionEn, LinkedAccountDebit,distributionMethod,isClearance }) =>
      ({ Id, expensesNumber, DescriptionAr, DescriptionEn, LinkedAccountDebit,distributionMethod,isClearance }));
    itemsPerPage = 10;
    ColumnMode = ColumnMode;
    columns = [
      { prop: 'expensesNumber', name: 'expensesNumber' },
      { prop: 'DescriptionAr', name: 'DescriptionAr' },
      { prop: 'DescriptionEn', name: 'DescriptionEn' },
      { prop: 'LinkedAccountDebit', name: 'LinkedAccountDebit' },
      { prop: 'distributionMethod', name: 'distributionMethod' },
    ];
    temp = [...this.rows];
    modalRef: BsModalRef;
    constructor(private modalService: BsModalService) { }
  
    openModalWithClass(templateshow: TemplateRef<any>): void {
      this.modalRef = this.modalService.show(
        templateshow,
        Object.assign({}, { class: 'gray modal-lg' })
      );
    }
  
    onPage(event): void {
    }
  
    toggleExpandRow(row): void {
      this.table.rowDetail.toggleExpandRow(row);
    }
  
    onDetailToggle(event): void {
    }
  
    updateFilter(event): void {
      const val = event.target.value.toLowerCase().trim();
      const count = this.columns.length;
      const keys = Object.keys(this.temp[0]);
      const temp = this.temp.filter(item => {
        for (let i = 0; i < count; i++) {
          if ((item[keys[i]] && item[keys[i]].toString().toLowerCase().indexOf(val) !== -1) || !val) {
            return true;
          }
        }
      });
      this.rows = temp;
      this.table.offset = 0;
    }
  
  }
  