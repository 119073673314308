import { CountryModel } from 'src/app/views/app/aio/shared/Shared Objects/countrymodel';
import { SidePopupComponent } from 'src/app/views/app/aio/shared/side-popup/side-popup.component';
import { BranchModel } from './BranchModel';
import { TableEngine } from './../TableEngine';
import { CityServes } from 'src/app/views/app/aio/shared/Shared Objects/cityServes';
import { BranchService } from './BranchService';
import { Component, HostBinding, Renderer2, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CityModel } from 'src/app/views/app/aio/shared/Shared Objects/citymodel';
import { CountryServes } from 'src/app/views/app/aio/shared/Shared Objects/countryServes';

@Component({
  selector: 'app-branches',
  templateUrl: './branches.component.html',
  styleUrls: ['./branches.component.scss']
})
export class BranchesComponent {
  ////////////////////////////////////////////////////////////////////////////
  //  VARYING :
  //  Modify per similar component.
  ////////////////////////////////////////////////////////////////////////////

  constructor(private formBuilder: FormBuilder, private modelService: BranchService, private renderer: Renderer2,
    private notifications: NotificationsService, private translate: TranslateService
    , private cityService: CityServes, private countryService: CountryServes
  ) {
  }

  tableEngine = new TableEngine(this.translate, this.notifications);
  currentScreen: any = 'Table';

  translationName: string = 'Branch'

  sortableKeys = [];
  searchableKeys = ['NameEn', 'NameAr'];

  modelObject: BranchModel = new BranchModel();

  ogData: BranchModel[] = [];
  mainForm: FormGroup = new FormGroup({
    Id: new FormControl(),
    NameEn: new FormControl(),
    NameAr: new FormControl(),
    CountryId: new FormControl(),
    Governorate: new FormControl(),
    BuildingNo: new FormControl(),
    PostalCode: new FormControl(),
    Street: new FormControl(),
    NotableMark: new FormControl(),
    CityName: new FormControl(),
  });


  declare lang: any;
  declare itemOrder: any;
  currentAction = '';
  savedPage = 0;
  currentPage = 1;
  deletionList: any[] = [];

  @ViewChild('sideModalRef', { static: true }) sideModalRef: SidePopupComponent;
  @ViewChild('delModal', { static: true }) delModal: ModalDirective;
  @ViewChild('smallModal', { static: true }) smallModal: ModalDirective = null;
  ngOnInit(): void {
    this.lang = localStorage.getItem('theme_lang');
    this.itemOrder = { label: 'BranchName', property: this.lang === 'ar-EG' ? 'NameAr' : 'NameEn' };
    this.sortableKeys = [
      { label: 'BranchName', property: this.lang === 'ar-EG' ? 'NameAr' : 'NameEn' },
      { label: 'BranchCode', property: 'Code' },
      { label: 'BranchCountry', property: 'CountryId' },
      { label: 'BranchGovernorate', property: 'Governorate' },
      { label: 'BranchStreet', property: 'Street' },
      { label: 'BranchBuildingNo', property: 'BuildingNo' },
      { label: 'BranchNotableMark', property: 'NotableMark' },
      { label: 'BranchPostalCode', property: 'PostalCode' }
    ];
    this.loadServices();

    this.tableEngine.loadTableEngine(this.ogData, this.modelService, this.modelObject,
      this.searchableKeys, this.sortableKeys, this.mainForm, this.translationName, this.itemOrder,
      this.sideModalRef, this.delModal, this.smallModal, this.lang
    )
  }

  showModal(Id, action) {
    if (action === 'Add') {
      this.tableEngine.showAddNewModalRef(this.createForm, this.formBuilder)
    }
    else if (action === 'Edit') {
      this.tableEngine.showEditModalRef(Id, this.createForm, this.formBuilder)
    }
    else if (action === 'Info') {
      this.tableEngine.showInfoModalRef(Id, this.createForm, this.formBuilder)
    }
    this.modelObject = this.tableEngine.modelObject;
    this.mainForm = this.tableEngine.mainForm;
  }

  createForm(modelObject, formBuilder): FormGroup {
    console.log("from craeteform");

    console.log(modelObject);

    return formBuilder.group({
      Id: [modelObject.Id >= 0 ? modelObject.Id : 0],
      NameEn: [modelObject.NameEn, Validators.compose([Validators.required, Validators.minLength(2)])],
      NameAr: [modelObject.NameAr, Validators.compose([Validators.required, Validators.pattern(/^[\u0621-\u064A]+([\s]*[\u0621-\u064A]+)*$/)])],
      CountryId: [modelObject.CountryId],
      CityName: [modelObject.CityName, Validators.required],
      Governorate: [modelObject.Governorate, Validators.compose([Validators.required, Validators.minLength(2)])],
      Street: [modelObject.Street, Validators.compose([Validators.required, Validators.minLength(2)])],
      BuildingNo: [modelObject.BuildingNo, Validators.compose([Validators.required, Validators.pattern(/^\d+$/)])],
      NotableMark: [modelObject.NotableMark, Validators.compose([Validators.required, Validators.minLength(2)])],
      // PostalCode: [modelObject.PostalCode, Validators.compose([Validators.required, Validators.pattern(/^\d{5}$/)])],
    });
  }

  addObject() {
    this.currentAction = 'loading';

    this.modelObject = new BranchModel();
    this.modelObject.Id = 0;
    this.modelObject.NameEn = this.mainForm.getRawValue().NameEn;
    this.modelObject.NameAr = this.mainForm.getRawValue().NameAr;
    this.modelObject.CountryId = this.mainForm.getRawValue().CountryId;
    this.modelObject.Governorate = this.mainForm.getRawValue().Governorate;
    this.modelObject.Street = this.mainForm.getRawValue().Street;
    this.modelObject.BuildingNo = this.mainForm.getRawValue().BuildingNo;
    this.modelObject.NotableMark = this.mainForm.getRawValue().NotableMark;
    this.modelObject.CityName = this.mainForm.getRawValue().CityName;
    var i = this.countryList.findIndex(x => x.Id == this.modelObject.CountryId);
    if (i > -1) {
      this.modelObject.CountryCode = this.countryList[i].Code;
    }
    // this.modelObject.PostalCode = this.mainForm.getRawValue().PostalCode;

    this.executeAddObject();
  }

  executeAddObject() {
    console.log(this.modelObject);

    this.modelService.insert(this.modelObject).subscribe(result => {
      if (result === true) {
        this.tableEngine.loadData();
        this.currentAction = '';
        this.tableEngine.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyAdded"));
        this.tableEngine.sideModalRef.modalRef.hide();
      }
      else {
        this.tableEngine.toastError(this.translate.instant("Error"), this.translate.instant("FailedAdd"));
        this.currentAction = '';
      }
    })
  }

  editObject() {
    this.currentAction = 'loading';
    this.tableEngine.savedPage = this.tableEngine.currentPage;

    let tempObj = new BranchModel({
      Id: this.modelObject.Id,
      NameEn: this.mainForm.getRawValue().NameEn,
      NameAr: this.mainForm.getRawValue().NameAr,
      CountryId: this.mainForm.getRawValue().CountryId,
      Governorate: this.mainForm.getRawValue().Governorate,
      Street: this.mainForm.getRawValue().Street,
      BuildingNo: this.mainForm.getRawValue().BuildingNo,
      NotableMark: this.mainForm.getRawValue().NotableMark,
      CityName: this.mainForm.getRawValue().CityName

      // PostalCode : this.mainForm.getRawValue().PostalCode
    });
    var i = this.countryList.findIndex(x => x.Id == tempObj.CountryId);
    if (i > -1) {
      tempObj.CountryCode = this.countryList[i].Code;
    }
    if (JSON.stringify(tempObj) != JSON.stringify(this.modelObject)) {
      this.executeEditObject(tempObj);
    }
    else {
      this.tableEngine.sideModalRef.modalRef.hide();
    }
  }

  executeEditObject(Object) {
    this.modelService.update(Object).subscribe(result => {
      if (result === true) {
        this.tableEngine.loadData();
        this.currentAction = '';
        this.tableEngine.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyEdited"));

        this.tableEngine.sideModalRef.modalRef.hide();
      }
      else {
        this.tableEngine.toastError(this.translate.instant("Error"), this.translate.instant("FailedEdit"));
        this.currentAction = '';
      }
    })
  }

  //Deletion methods:
  //Customize if needed.
  //Pass the target object as parameter if the target API can only delete singular objects and not lists.
  deleteObject() {
    this.savedPage = this.currentPage;
    //prevent multiclicks. re enable after API response
    this.currentAction = 'loading';
    this.executeDeleteObject();
  }

  //Same here...
  executeDeleteObject() {
    this.modelService.deleteList(this.tableEngine.deletionList).subscribe(result => {
      if (result === true) {
        this.tableEngine.loadData();
        this.currentAction = '';
        this.tableEngine.finalizeDeletion();
      }
      else {
        this.tableEngine.toastError(this.translate.instant("Error"), this.translate.instant("FailedDelete"));
        this.currentAction = '';
      }
    })
  }
  ////////////////////////////////////////////////////////////////////////////
  //
  //
  //
  //
  //
  ////////////////////////////////////////////////////////////////////////////
  //  EXCLUSIVE :
  //  Specifically for this component.
  ///////////////////////////////
  //Get any additional data here
  loadServices() {
    this.currentAction = 'loading';
    this.countryService.getAll().subscribe(
      countryData => {
        console.log('countryData', countryData);
        this.countryList = countryData;
        this.tableEngine.loadData();

      }
    )
  }

  cityList: CityModel[] = [];
  countryList: CountryModel[] = [];
  getCountryById(Id: any): any {
    return this.countryList.find(i => i.Id === Id);
  }
  getCityById(Id: any): any {
    return this.cityList.find(i => i.Id === Id);
  }

  ////////////////////////////////////////////////////////////////////////////
  //
  //
  //
  //
  //
  ////////////////////////////////////////////////////////////////////////////
  //  CONSTANT :
  //  Do not change.
  ///////////////////////////////
}
