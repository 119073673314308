export class ClientModel{
    Id?:number;
    NameEn?:string;
    NameAr?:string;
    Code?:string;
    TaxNumber?:string;
    Type?:string;
    //Country?:string;
    Governorate?:string;
    PostalCode?:string;
    CountryAr?:string;
    CountryEn?:string;
    CountryId?:number;
    CountryCode?:string;
    CityName?:string;
    constructor(obj?:ClientModel){
        obj = obj? obj:{};
        this.Id = obj.Id? obj.Id:0;
        this.Code = obj.Code? obj.Code:'';
        this.NameEn = obj.NameEn? obj.NameEn:'';
        this.NameAr = obj.NameAr? obj.NameAr:'';
        this.TaxNumber = obj.TaxNumber? obj.TaxNumber:'';
        this.Type = obj.Type? obj.Type:null;
        //this.Country = obj.Country? obj.Country:'';
        this.Governorate = obj.Governorate? obj.Governorate:'';
        this.PostalCode = obj.PostalCode? obj.PostalCode:'';
        this.CountryAr = obj.CountryAr? obj.CountryAr:'';
        this.CountryEn = obj.CountryEn? obj.CountryEn:'';
        this.CountryId = obj.CountryId? obj.CountryId:null;
        this.CountryCode = obj.CountryCode? obj.CountryCode:'';
        this.CityName = obj.CityName? obj.CityName:'';
    }
}