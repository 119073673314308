import { ServicePriceListModel } from './ServicePriceListModel';
import { ServiceCategoryModel } from './ServiceCategoryModel';

export class ServiceModel{
    Id?: number;
    Name?:string;
    ServiceCode?: number; 
    Description?: string; 
    PurchasingPrice?: number; 
    SalePrice?: number;
    FirstTaxId?: number;
    SecondTaxId?: number;
    LowestSellingPrice?: number;
    Discount?: number;
    ProfitPercentage?: number;
    PriceListId?: number;
    UnitPrice?: number;
    Notes?: string;
    Tags?: string;
    IsDisable?: boolean;
    AvailableOnline?: boolean;
    FeaturedProduct?: boolean;
    Time?:number;
    ServicePriceList?:ServicePriceListModel[];

    ServiceCategory?:ServiceCategoryModel[];



    constructor(item?:ServiceModel){
        item = item? item:{};
        this.Id = item.Id? item.Id:0;
        this.Name = item.Name? item.Name:'';
        this.ServiceCode = item.ServiceCode? item.ServiceCode:0;
        this.Description = item.Description? item.Description:'';
        this.PurchasingPrice = item.PurchasingPrice? item.PurchasingPrice:0;
        this.SalePrice = item.SalePrice? item.SalePrice:0;
        this.FirstTaxId = item.FirstTaxId? item.FirstTaxId:0;
        this.SecondTaxId = item.SecondTaxId? item.SecondTaxId:0;
        this.LowestSellingPrice = item.LowestSellingPrice? item.LowestSellingPrice:0;
        this.Discount = item.Discount? item.Discount:0;
        this.ProfitPercentage = item.ProfitPercentage? item.ProfitPercentage:0;
        this.PriceListId = item.PriceListId? item.PriceListId:0;
        this.UnitPrice = item.UnitPrice? item.UnitPrice:0;
        this.Time = item.Time? item.Time:0;
        this.Notes = item.Notes? item.Notes:'';
        this.Tags = item.Tags? item.Tags:'';
        this.IsDisable = item.IsDisable? item.IsDisable:false;
        this.AvailableOnline = item.AvailableOnline? item.AvailableOnline:false;
        this.FeaturedProduct = item.FeaturedProduct? item.FeaturedProduct:false;

        this.ServicePriceList = item.ServicePriceList? item.ServicePriceList:[];

        this.ServiceCategory = item.ServiceCategory? item.ServiceCategory:[];
        
    
    }

}