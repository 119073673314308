import { SidePopupComponent } from 'src/app/views/app/aio/shared/side-popup/side-popup.component';
import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ItemBalanceService} from '../shared/item-balance.service';
import { ItemBalanceModel} from '../shared/Models/ItemBalanceModel';
import { StoreDataModel } from '../../store-data/StoreDataModel';
import { BalanceNameModel } from '../../Balance-Name/balance-name/balance-name-model';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { Router } from '@angular/router';
import { TableEngine } from '../../../../invoices/TableEngine';
import { BalanceNameService } from '../../Balance-Name/balance-name/balance-name.service';
import { StoreDataService } from '../../store-data/store-data.service';

@Component({
    // moduleId: module.id,
    selector: 'list-item-balance',
    templateUrl: 'list-item-balance.component.html',
    styleUrls: ['list-item-balance.component.scss']
})
export class ListItemBalanceComponent implements OnInit {
    constructor(private formBuilder: FormBuilder, private modelService: ItemBalanceService, private renderer: Renderer2,
        private notifications: NotificationsService, private translate: TranslateService, private StoreDataService:StoreDataService,
        private BalanceNameService:BalanceNameService,private router : Router
) {
    }


    tableEngine = new TableEngine(this.translate, this.notifications);
    currentScreen: any = 'Table';
    tableView: any = true;
    ItemBalanceList : ItemBalanceModel[]=[];
    StoreDataList:StoreDataModel[]=[];
    BalanceDataList:BalanceNameModel[]=[];
   
    translationName: string = ' Item Balance' 
    sortableKeys = [];
    searchableKeys = ['TotalValue','Date'];
    modelObject: ItemBalanceModel = new ItemBalanceModel();
 
    // debugMode = true;

    ogData: ItemBalanceModel[] = [];
    mainForm: FormGroup = new FormGroup({
        Id: new FormControl(),
        CompanyDataId: new FormControl(),
        BranchesDataId: new FormControl(),
        Year: new FormControl(),
        Month: new FormControl(),
        Date: new FormControl(),
        BalanceNameId: new FormControl(),
        BalanceNameAr: new FormControl(),
        BalanceNameEn: new FormControl(),
        StoreDataId: new FormControl(),
        StoreAr: new FormControl(),
        StoreEn: new FormControl(),
        TotalValue: new FormControl(),
        Description: new FormControl(),
        IsPosted: new FormControl(),
        Footer: new FormControl(),
    });



    declare lang: any;
    declare itemOrder: any;
    currentAction = '';
    currentFormMode: string = '';
    savedPage = 0;
    currentPage = 1;
    deletionList: any[] = [];

    @ViewChild('sideModalRef', { static: true }) sideModalRef: SidePopupComponent = null;
    @ViewChild('delModal', { static: true }) delModal: ModalDirective;
    @ViewChild('smallModal', { static: true }) smallModal: ModalDirective = null;
    formPresentationModal: any;
    ngOnInit(): void {
        // this.getAllCountryCode();    
         
        this.lang = localStorage.getItem('theme_lang');
        this.itemOrder = { label: 'StoreName', property: this.lang === 'ar-EG' ? 'StoreAr' : 'StoreEn' };      
        this.sortableKeys = [         
            { label: 'Date', property: 'Date' },        
            { label: 'BalanceName', property: this.lang === 'ar-EG' ? 'BalanceNameAr' : 'BalanceNameEn' },      
            { label: 'StoreData', property: this.lang === 'ar-EG' ? 'StoreAr' : 'StoreEn' },
            { label: 'TotalValue', property: 'TotalValue' },
            { label: 'Description', property: 'Description' },
                 
        ];

        this.tableEngine.loadTableEngine(this.ogData, this.modelService, this.modelObject,
            this.searchableKeys, this.sortableKeys, this.mainForm, this.translationName, this.itemOrder,
            this.sideModalRef, this.delModal, this.smallModal, this.lang
        )
        this.loadServices();
    } 

    showInfoModalRef(Id: any): void {
      this.router.navigate(['app/aio/Stores/AddItemBalance',Id])
    }
    showDelModal(Id: any): void {
      if (Id >= 0) {
        this.currentFormMode = 'Del'
        this.modelObject = this.ogData.find(i => i.Id === Id);
        this.deletionList = [this.ogData.find(i => i.Id === Id)];
        this.delModal.show();
      }
      else if (Id === 'delMulti') {
        this.deletionList = [...this.selected]
        this.delModal.show();
      }
    }
    showModal(Id, action) {
      debugger;
      this.modelObject = new ItemBalanceModel();
      this.tableEngine.showAddNewModalRef(this.createForm, this.formBuilder)
      if (action === 'Add') {          
        this.ItemBalanceList = [];        
        this.tableEngine.showAddNewModalRef(this.createForm, this.formBuilder)
        this.router.navigate(['app/aio/Inventory/add-item-balance',0])  
      }
      else if (action === 'Edit') {
        this.router.navigate(['app/aio/Inventory/add-item-balance',Id])        
        this.ItemBalanceList = [];
        this.ItemBalanceList = [...this.tableEngine.ogData.find(i => i.Id === Id).Footers];             
        this.tableEngine.showEditModalRef(Id, this.createForm, this.formBuilder) 
        
      }
      else if (action === 'Info') {
          this.router.navigate(['app/aio/Inventory/add-item-balance',Id])
        this.ItemBalanceList = [];
        this.ItemBalanceList = [...this.tableEngine.ogData.find(i => i.Id === Id).Footers];
        this.tableEngine.showInfoModalRef(Id, this.createForm, this.formBuilder)
      }
       
    }
    deleteObject() {
      this.savedPage = this.currentPage;
      //prevent multiclicks. re enable after API response
      this.currentAction = 'loading';
      this.executeDeleteObject();
    }
  
    //Same here...
    executeDeleteObject(){
      this.modelService.deleteList(this.deletionList).subscribe(result => {
        if (result === true) {
          // this.loadData();
          this.currentAction = '';
          // this.finalizeDeletion();
        }
        else {
          this.toastError(this.translate.instant("Error"), this.translate.instant("FailedDelete"));
          this.currentAction = '';
        }
      })
    }
    toastError(toastHeader: string, toastBody: string): void {
      this.notifications.create(toastHeader, toastBody,
        NotificationType.Error, { timeOut: 3000, showProgressBar: true });
    }
    createForm(modelObject, formBuilder): FormGroup {
        return formBuilder.group({
            Id: [modelObject.Id >= 0 ? modelObject.Id : 0],
            CompanyDataId: [modelObject.CompanyDataId, Validators.compose([Validators.required,Validators.minLength(2)])],
            BranchesDataId: [modelObject.BranchesDataId, Validators.compose([Validators.required, Validators.minLength(2)])],
            Year: [modelObject.Year, Validators.compose([Validators.required, Validators.minLength(2)])],
            Month: [modelObject.Month, Validators.compose([Validators.required, Validators.pattern(/^[\u0621-\u064A\u0660-\u06690-9]+([\s]*[\u0621-\u064A\u0660-\u06690-9]+)*$/)])],

            Date: [modelObject.Date, Validators.compose([Validators.required])],
            BalanceNameId: [modelObject.BalanceNameId, Validators.compose([Validators.required])],

            BalanceNameEn: [modelObject.BalanceNameEn, Validators.compose([Validators.required, Validators.minLength(2)])],
            BalanceNameAr: [modelObject.BalanceNameAr, Validators.compose([Validators.required, Validators.pattern(/^[\u0621-\u064A\u0660-\u06690-9]+([\s]*[\u0621-\u064A\u0660-\u06690-9]+)*$/)])],
            
            StoreDataId: [modelObject.StoreDataId, Validators.compose([Validators.required])],

            StoreEn: [modelObject.StoreEn, Validators.compose([Validators.required, Validators.minLength(2)])],
            StoreAr: [modelObject.StoreAr, Validators.compose([Validators.required, Validators.pattern(/^[\u0621-\u064A\u0660-\u06690-9]+([\s]*[\u0621-\u064A\u0660-\u06690-9]+)*$/)])],
            
            TotalValue: [modelObject.TotalValue, Validators.compose([Validators.required, Validators.minLength(2)])],

            Description: [modelObject.Description, Validators.compose([Validators.required, Validators.pattern(/^\d{11}$/)])],
            IsPosted: [modelObject.IsPosted, Validators.compose([Validators.required, Validators.pattern(/^\d{11}$/)])],
            
        });
    }
    

    loadServices(){
        debugger;
        this.currentAction = 'loading';
        this.tableEngine.loadData();         
      
       
    }
    filteredData = [...this.ogData];
    sortedData = [...this.filteredData];
    paginatedData = [...this.filteredData];
    itemsPerPage = 10;
    itemOptionsPerPage = [5, 10, 20];

    selected : any[] = [];
    selectAllState = '';
    SearchFrom: Date = null;
    SearchTo: Date = null;
  
    clearSearch() {
      this.SearchFrom = null;
      this.SearchTo = null;
    }
    changePage(event: PageChangedEvent): void {
        this.currentPage = event.page;
        this.paginateData(this.itemsPerPage, this.currentPage);
      }
      paginateData(newItemsPerPage: number, newCurrentPage: number) {
        this.itemsPerPage = newItemsPerPage;
        this.currentPage = newCurrentPage;
        this.paginatedData = this.filteredData.slice((newCurrentPage - 1) * (newItemsPerPage), (newItemsPerPage * newCurrentPage));
    }
      isSelected(p: ItemBalanceModel): boolean {
        return this.selected.findIndex(x => x.Id === p.Id) > -1;
      }
    
      onSelect(item: ItemBalanceModel): void {
        if (this.isSelected(item)) {
          this.selected = this.selected.filter(x => x.Id !== item.Id);
        } else {
          this.selected.push(item);
        }
        this.setSelectAllState();
      }

      selectAll($event): void {
        if ($event.target.checked) {
          this.selected = [...this.filteredData];
        } else {
          this.selected = [];
        }
        this.setSelectAllState();
      }
    
      setSelectAllState(): void {
        if (this.selected.length >= this.filteredData.length) {
          this.selectAllState = 'checked';
        } else if (this.selected.length !== 0) {
          this.selectAllState = 'indeterminate';
        } else  {
          this.selectAllState = '';
        }
      }
  
    prepChangeSort(sortKey) {
      this.SearchFrom = null;
      this.SearchTo = null;
      
      
      this.tableEngine.changeSort(this.tableEngine.getSort(sortKey));
      this.itemOrder = this.tableEngine.itemOrder;
    }
  
    getFormattedDate(dateString: any){
      let splitDate: number[] = dateString.toString().split("/");
  
      return new Date(splitDate[2], splitDate[1]-1, splitDate[0])
    }
    ///////////////////////////////////////////////////////
    
    displayMode = 'list';
    changeDisplayMode(mode): void {
      this.displayMode = mode;
    }
    itemsPerPageChange(perPage: number): void {
      this.tableEngine.paginateData(perPage, 1)
    }
}
