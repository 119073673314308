export class CompanyApiModel{
    Id?:number;
    NameAr?:string;
    NameEn?:string;
    ApisURL?:string;
    Token             ?: string;
    type              ?: string;
    RegistrationNumber?: string;
    RegistratioName   ?: string;
    client_id         ?: string;
    client_secret     ?: string;
    client_secret2    ?: string;
    ActivityCode      ?: string;
    constructor(obj?:CompanyApiModel){
        obj = obj? obj:{};
        this.Id = obj.Id? obj.Id:0;
        this.NameAr = obj.NameAr? obj.NameAr:'';
        this.NameEn = obj.NameEn? obj.NameEn:'';
        this.ApisURL = obj.ApisURL? obj.ApisURL:'';
        this.Token              = obj.Token             ? obj.Token              : '' ;
        this.type               = obj.type              ? obj.type               : '' ;
        this.RegistrationNumber = obj.RegistrationNumber? obj.RegistrationNumber : '' ;
        this.RegistratioName    = obj.RegistratioName   ? obj.RegistratioName    : '' ;
        this.client_id          = obj.client_id         ? obj.client_id          : '' ;
        this.client_secret      = obj.client_secret     ? obj.client_secret      : '' ;
        this.client_secret2     = obj.client_secret2    ? obj.client_secret2     : '' ;
        this.ActivityCode       = obj.ActivityCode      ? obj.ActivityCode       : '' ;
    }
}