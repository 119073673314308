<!--component html goes here -->
 <app-list-page-header
  [showOrderBy]="false" [showDisplayMode]="false" (addNewItem)="openModalWithClass(templateshow)" (searchKeyUp)="updateFilter($event)" [itemsPerPage]="itemsPerPage" [showItemsPerPage]="false">
</app-list-page-header>

<div class="row">
  <div class="col-12 mb-4 data-table-rows data-table-responsive">
    <ngx-datatable #myTable class="expandable" [columnMode]="ColumnMode.force" [rowHeight]="50" [rows]="rows"
      [footerHeight]="100" [limit]="itemsPerPage" [headerHeight]="20" (page)="onPage($event)">
      <ngx-datatable-row-detail [rowHeight]="95" (toggle)="onDetailToggle($event)" cellClass="datatable-row-detail">
        <ng-template let-column="column" let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
          <div class="detail-content">
            <div class="d-flex flex-row">
              <div>
                <div class="text-muted mr-3">
                  {{'expenseNumber' | translate}}
                </div>
                <div class="text-muted mr-3">
                  {{'DescriptionAr' | translate}}   
                </div>
                <div class="text-muted mr-3">
                  {{'DescriptionEn' | translate}}
                </div>
                <div class="text-muted mr-3">
                  {{'LinkedAccountDebit' | translate}}     
                </div>
                <div class="text-muted mr-3">
                  {{'distributionMethod' | translate}}     
                  </div>
                  <div class="text-muted mr-3">
                    {{'LinkedAccountDebit' | translate}}   
                  
                  </div>
              </div>
              <div>
                <div>
                  {{ row.expensesNumber }}
                </div>
                <div>
                  {{ row.DescriptionAr }}
                </div>
                <div>
                  {{ row.DescriptionEn }}
                </div>
                <div>
                  {{ row.distributionMethod }}
                </div>
                <div>
                    {{ row.LinkedAccountDebit }}
                  </div>
              </div>
            </div>
          </div>
        </ng-template>
      </ngx-datatable-row-detail>

      <ngx-datatable-column [width]="10" [resizeable]="false" [sortable]="false" [draggable]="false"
        [canAutoResize]="false" class="expand-row-button" headerClass="expand-row-button">
        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
          <a [class.datatable-icon-right]="!expanded" [class.datatable-icon-down]="expanded"   title="Expand/Collapse Row"
            (click)="toggleExpandRow(row)" class="d-block d-xl-none c-pointer">
            <i class="glyph-icon simple-icon-arrow-down"></i>
          </a>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="expensesNumber"  [flexGrow]="1.2" [minWidth]="200">
        <ng-template let-value="value" ngx-datatable-cell-template>
          <a (click)="openModalWithClass(templateshow)">{{ value }}</a>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="DescriptionAr" [flexGrow]="0.6" headerClass="d-none d-xl-block">
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <span class="d-none d-xl-block">{{ value }}</span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="DescriptionEn" [flexGrow]="0.6" headerClass="d-none d-xl-block">
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <span class="d-none d-xl-block">{{ value }}</span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="distributionMethod" [flexGrow]="1" headerClass="d-none d-xl-block">
        <ng-template let-value="value" ngx-datatable-cell-template>
          <span class="d-none d-xl-block">{{ value }}</span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="LinkedAccountDebit" [flexGrow]="1" headerClass="d-none d-xl-block">
        <ng-template let-value="value" ngx-datatable-cell-template>
          <span class="d-none d-xl-block">{{ value }}</span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-footer *ngIf="true">
        <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
          let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-limit="limit">
          <div class="w-100 d-flex justify-content-center">
            <pagination [totalItems]="rowCount" [itemsPerPage]="pageSize" [maxSize]="5" (pageChanged)="table.onFooterPage($event)"
            [customNextTemplate]="nextTemplate" [customPreviousTemplate]="prevTemplate"
            >
            </pagination>
          </div>
        </ng-template>
      </ngx-datatable-footer>

    </ngx-datatable>
  </div>
</div>

<ng-template #nextTemplate let-disabled="disabled" let-currentPage="currentPage">
  <i class='simple-icon-arrow-right'></i>
</ng-template>
<ng-template #prevTemplate let-disabled="disabled" let-currentPage="currentPage">
  <i class='simple-icon-arrow-left'></i>
</ng-template>
<ng-template #templateshow>
    <div class="modal-header">
      <h4 class="modal-title pull-left">{{'modal.modal-title' | translate}}</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <div>
            <div class="form-group row">
             <label for="inputEmail3" class="col-sm-3 col-form-label">{{'expenseNumber' | translate}}</label>
             <div class="col-sm-7"><input type="email" id="inputEmail3" class="form-control" >
             </div>
           </div>
            <div class="form-row ">
             <div class="form-group col-md-5"><label for="inputCity">{{'DescriptionAr' | translate}}</label>
               <input type="text" id="inputCity" class="form-control" placeholder="acount number">
             </div>
             <div class="col-md-1"> </div>
             <div class="form-group col-md-5"><label for="inputCity">{{'DescriptionEn' | translate}}</label>
               <input type="text" id="inputCity" class="form-control" placeholder="acount number">
             </div>
           </div>
           <div class="form-group row"><label for="inputEmail3" class="col-sm-3 col-form-label">{{'LinkedAccountDebit' | translate}}</label>
             <div class="col-sm-3"><input type="email" id="inputEmail3" class="form-control" >
             </div>
             <mat-icon fontSet="myfont"
             fontIcon="my-icon-search"
             class="search-icon"></mat-icon>
                     <div class="col-sm-5"><input type="email" id="inputEmail3" class="form-control" >
             </div>
           </div>
           <div class="form-group row"><label for="inputEmail3" class="col-sm-3 col-form-label">{{'distributionMethod'-'LinkedAccountDebit' | translate}}</label>
             <div class="col-sm-3"><input type="email" id="inputEmail3" class="form-control" >
             </div>
             <div class="col-sm-5"><input type="email" id="inputEmail3" class="form-control" >
             </div>
           </div>
           <div class="col-sm-8"><div class="form-check"><input type="checkbox" id="gridCheck1" class="form-check-input"><label for="gridCheck1" class="form-check-label">{{'Prohibition of use in customs clearance' | translate}}  </label></div></div>
         <br>
         <div>
            <div class="col-sm-8"></div>
                       <button type="button" class="btn btn-primary mb-3 ">{{'wizard.save' | translate}}</button>{{" "}}

         </div> 
        </div>
            </div>
  </ng-template>
   
 