
import { WorkItemService } from './../workitems/WorkItemService';
import { ContractorService } from './ContractorService';
import { Component, HostBinding, Input, Renderer2, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { CityModel } from 'src/app/views/app/aio/shared/Shared Objects/citymodel';
import { CountryModel } from 'src/app/views/app/aio/shared/Shared Objects/countrymodel';
import { CityServes } from 'src/app/views/app/aio/shared/Shared Objects/cityServes';
import { CountryServes } from 'src/app/views/app/aio/shared/Shared Objects/countryServes';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { SidePopupComponent } from 'src/app/views/app/aio/shared/side-popup/side-popup.component';
import { Observable } from 'rxjs';
import {  ContractorWorkItemService } from './ContractorWorkItemService'
import { NgSelectComponent } from '@ng-select/ng-select';
import { CodingUnitService } from 'src/app/invoices/coding-units/CodingUnitService';
import { TableEngine } from 'src/app/invoices/TableEngine';
import { CodingUnitModel } from 'src/app/invoices/coding-units/CodingUnitModel';
import { ContractorModel } from './ContractorModel';
import { ContractorWorkItemModel } from './ContractorWorkItemModel';
import { WorkItemModel } from '../workitems/WorkItemModel';
@Component({
  selector: 'app-contractors',
  templateUrl: './contractors.component.html',
  styleUrls: ['./contractors.component.scss']
})
export class ContractorsComponent {
  ////////////////////////////////////////////////////////////////////////////
  //  VARYING :
  //  Modify per similar component.
  ////////////////////////////////////////////////////////////////////////////

  constructor(private formBuilder: FormBuilder, private modelService: ContractorService, private renderer: Renderer2,
    private notifications: NotificationsService, private translate: TranslateService
    , private cityService: CityServes, private countryService: CountryServes
    , private workItemService: WorkItemService, private contractorWorkItemService: ContractorWorkItemService
    ,private codingUnitService: CodingUnitService
  ) {
  }

  tableEngine = new TableEngine(this.translate, this.notifications);
  currentScreen: any = 'Table';
  tableView: any = true;

  

  translationName: string = 'Contractor'

  sortableKeys = [];
  searchableKeys = ['NameEn', 'NameAr', 'IdNumber', 'Phone'];

  modelObject: ContractorModel = new ContractorModel();

  ogData: ContractorModel[] = [];
  mainForm: FormGroup = new FormGroup({
    Id: new FormControl(),
    Code: new FormControl(),
    NameEn: new FormControl(),
    NameAr: new FormControl(),
    TaxCard: new FormControl(),
    CommercialRegister: new FormControl(),
    IdNumber: new FormControl(),
    Nationality: new FormControl(),
    Phone: new FormControl(),
    BankAccount: new FormControl(),
    Description: new FormControl(),
    CountryId: new FormControl(),
    CityId: new FormControl(),
    Governorate: new FormControl(),
    Street: new FormControl(),
    Landmark: new FormControl(),
    BuildingNumber: new FormControl(),
    Floor: new FormControl(),
    Room: new FormControl(),
    ZipCode: new FormControl(),
  });


  declare lang: any;
  declare itemOrder: any;
  currentAction = '';
  savedPage = 0;
  currentPage = 1;
  deletionList: any[] = [];

  @ViewChild('sideModalRef', { static: true }) sideModalRef: SidePopupComponent = null;
  @ViewChild('delModal', { static: true }) delModal: ModalDirective;
  @ViewChild('smallModal', { static: true }) smallModal: ModalDirective = null;
  formPresentationModal: any;
  ngOnInit(): void {
    this.lang = localStorage.getItem('theme_lang');
    this.itemOrder = { label: 'ContractorName', property: this.lang === 'ar-EG' ? 'NameAr' : 'NameEn' };
    this.sortableKeys = [
      { label: 'ContractorCode', property: 'Code' },
      { label: 'ContractorName', property: this.lang === 'ar-EG' ? 'NameAr' : 'NameEn' },
      { label: 'ContractorIdNumber', property: 'IdNumber' },
      { label: 'ContractorCommercialRegister', property: 'CommercialRegister' },
      { label: 'ContractorTaxCard', property: 'TaxCard' },
      { label: 'ContractorNationality', property: 'Nationality' },
      { label: 'ContractorPhone', property: 'Phone' },
      { label: 'ContractorBankAccount', property: 'BankAccount' },
      { label: 'ContractorDescription', property: 'Description' },
      { label: 'ContractorCountry',  property: this.lang === 'ar-EG' ? 'CountryAr' : 'CountryEn' },
      { label: 'ContractorCity',  property: this.lang === 'ar-EG' ? 'CityAr' : 'CityEn' },
      { label: 'ContractorGovernorate', property: 'Governorate' },
      { label: 'ContractorStreet', property: 'Street' },
      { label: 'ContractorLandmark', property: 'Landmark' },
      { label: 'ContractorBuildingNumber', property: 'BuildingNumber' },
      { label: 'ContractorBuildingFloor', property: 'Floor' },
      { label: 'ContractorBuildingRoom', property: 'Room' },
      { label: 'ContractorZipCode', property: 'ZipCode' }
    ];
    this.loadServices();

    this.tableEngine.loadTableEngine(this.ogData, this.modelService, this.modelObject,
      this.searchableKeys, this.sortableKeys, this.mainForm, this.translationName, this.itemOrder,
      this.sideModalRef, this.delModal, this.smallModal, this.lang
    )
  }

  showModal(Id, action) {
    
    if (action === 'Add') {
      this.addedItemList = [];
      this.newItemInternalId = 0;
      this.tableEngine.showAddNewModalRef(this.createForm, this.formBuilder)
    }
    else if (action === 'Edit') {
      this.addedItemList = this.tableEngine.ogData.find(i => i.Id === Id).ContractorWorkItems;
      this.newItemInternalId = 0;
      let tempItem: ContractorWorkItemModel;
      for (let index = 0; index < this.addedItemList.length; index++) {
        tempItem =  new WorkItemModel();
        tempItem.ParentAr = tempItem.ParentId>0?tempItem.ParentAr:tempItem.NameAr;
        tempItem.ParentEn = tempItem.ParentId>0?tempItem.ParentEn:tempItem.NameEn;
        tempItem.NameAr = tempItem.ParentId>0?tempItem.NameAr:"";
        tempItem.NameEn = tempItem.ParentId>0?tempItem.NameEn:"";
        tempItem.WorkItemId = tempItem.ParentId>0?tempItem.WorkItemId:0;
        tempItem.ParentId = tempItem.ParentId>0?tempItem.ParentId:tempItem.WorkItemId;
        
        //tempItem.Id = this.newItemInternalId;
        //tempItem.ContractorId = 0;
        this.newItemInternalId = tempItem.Id+1;
      }
      this.tableEngine.showEditModalRef(Id, this.createForm, this.formBuilder)
    }
    else if (action === 'Info') {
      this.addedItemList = this.tableEngine.ogData.find(i => i.Id === Id).ContractorWorkItems;
      this.newItemInternalId = 0;
      let tempItem: ContractorWorkItemModel;
      for (let index = 0; index < this.addedItemList.length; index++) {
        tempItem =  new WorkItemModel();
        tempItem = this.addedItemList[index];
        tempItem.ParentAr = tempItem.ParentId>0?tempItem.ParentAr:tempItem.NameAr;
        tempItem.ParentEn = tempItem.ParentId>0?tempItem.ParentEn:tempItem.NameEn;
        tempItem.NameAr = tempItem.ParentId>0?tempItem.NameAr:"";
        tempItem.NameEn = tempItem.ParentId>0?tempItem.NameEn:"";
        tempItem.WorkItemId = tempItem.ParentId>0?tempItem.WorkItemId:0;
        tempItem.ParentId = tempItem.ParentId>0?tempItem.ParentId:tempItem.WorkItemId;
        
        //tempItem.Id = this.newItemInternalId;
        //tempItem.ContractorId = 0;
        this.newItemInternalId = tempItem.Id+1;
      }
      this.tableEngine.showInfoModalRef(Id, this.createForm, this.formBuilder)
    }
    //this.currentScreen = "Form";
    this.tableView = false;
    this.modelObject = this.tableEngine.modelObject;
    this.mainForm = this.tableEngine.mainForm;
  }

  createForm(modelObject, formBuilder): FormGroup {
    return formBuilder.group({
      Id: [modelObject.Id >= 0 ? modelObject.Id : 0],
      Code: [modelObject.Code, Validators.compose([Validators.required, Validators.pattern(/^\d+$/)])],
      NameEn: [modelObject.NameEn, Validators.compose([Validators.required, Validators.minLength(2)])],
      NameAr: [modelObject.NameAr, Validators.compose([Validators.required, Validators.pattern(/^[\u0621-\u064A\u0660-\u06690-9]+([\s]*[\u0621-\u064A\u0660-\u06690-9]+)*$/)])],
     
      IdNumber: [modelObject.IdNumber, Validators.compose([Validators.required, Validators.minLength(2)])],
      TaxCard: [modelObject.TaxCard, Validators.compose([Validators.required, Validators.minLength(2)])],
      CommercialRegister: [modelObject.CommercialRegister, Validators.compose([Validators.required, Validators.minLength(2)])],
      Nationality: [modelObject.Nationality, Validators.compose([Validators.required, Validators.minLength(2)])],
      
      Phone: [modelObject.Phone, Validators.compose([Validators.required,  Validators.pattern(/^\d{11}$/)])],
      BankAccount: [modelObject.BankAccount, Validators.compose([Validators.required, Validators.minLength(2)])],
      Description: [modelObject.Description, Validators.compose([Validators.minLength(2)])],
      CountryId: [modelObject.CountryId, Validators.compose([Validators.required])],
      CityId: [modelObject.CityId, Validators.compose([Validators.required])],
      Governorate: [modelObject.Governorate, Validators.compose([Validators.required, Validators.minLength(2)])],
      Street: [modelObject.Street, Validators.compose([Validators.required, Validators.minLength(2)])],
      Landmark: [modelObject.Landmark, Validators.compose([Validators.minLength(2)])],
      BuildingNumber: [modelObject.BuildingNumber, Validators.compose([Validators.required, Validators.pattern(/^\d+$/)])],
      Floor: [modelObject.Floor, Validators.compose([Validators.minLength(2)])],
      Room: [modelObject.Room, Validators.compose([Validators.minLength(2)])],
      ZipCode: [modelObject.ZipCode, Validators.compose([Validators.required, Validators.pattern(/^\d{5}$/)])],
    });
  }

  addObject() {
    this.currentAction = 'loading';

    this.modelObject = new ContractorModel();
    this.modelObject.Id = 0;
    this.modelObject.Code = this.mainForm.getRawValue().Code;
    this.modelObject.NameEn = this.mainForm.getRawValue().NameEn;
    this.modelObject.NameAr = this.mainForm.getRawValue().NameAr;
    this.modelObject.IdNumber = this.mainForm.getRawValue().IdNumber;
    this.modelObject.CommercialRegister = this.mainForm.getRawValue().CommercialRegister;
    this.modelObject.TaxCard = this.mainForm.getRawValue().TaxCard;
    this.modelObject.Nationality = this.mainForm.getRawValue().Nationality;
    this.modelObject.Phone = this.mainForm.getRawValue().Phone;
    this.modelObject.BankAccount = this.mainForm.getRawValue().BankAccount;
    this.modelObject.Description = this.mainForm.getRawValue().Description;
    this.modelObject.CountryId = this.mainForm.getRawValue().CountryId;
    this.modelObject.CityId = this.mainForm.getRawValue().CityId;
    this.modelObject.Governorate = this.mainForm.getRawValue().Governorate;
    this.modelObject.Street = this.mainForm.getRawValue().Street;
    this.modelObject.Landmark = this.mainForm.getRawValue().Landmark;
    this.modelObject.BuildingNumber = this.mainForm.getRawValue().BuildingNumber;
    this.modelObject.Floor = this.mainForm.getRawValue().Floor;
    this.modelObject.Room = this.mainForm.getRawValue().Room;
    this.modelObject.ZipCode = this.mainForm.getRawValue().ZipCode;

    let tempList: ContractorWorkItemModel[] = [];
    let tempItem: ContractorWorkItemModel;
    for (let index = 0; index < this.addedItemList.length; index++) {
        tempItem =  new WorkItemModel();
        tempItem.Id = 0;
        tempItem.ContractorId = 0;
        tempItem.WorkItemId = this.addedItemList[index].Id>0?this.addedItemList[index].Id:this.addedItemList[index].ParentId;
        tempList.push(tempItem)
    }
    this.modelObject.ContractorWorkItems = tempList;
    this.executeAddObject();
  }

  executeAddObject() {
    this.modelService.insert(this.modelObject).subscribe(result => {
      if (result === true) {
        this.tableEngine.loadData();
        this.currentAction = '';
        this.tableEngine.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyAdded"));
        if(this.sideModalRef === undefined){
          if(this.smallModal != undefined){
            this.tableEngine.smallModal.hide();
          }
          else{
            this.tableView = this.prevAction==='AddThenNew'?false:true;
          }
        }
        else{
          this.tableEngine.sideModalRef.modalRef.hide();
        }
      }
      else {
        this.tableEngine.toastError(this.translate.instant("Error"), this.translate.instant("FailedAdd"));
        this.currentAction = '';
      }
    })
  }

  editObject() {
    this.currentAction = 'loading';
    this.tableEngine.savedPage = this.tableEngine.currentPage;

    let tempList: ContractorWorkItemModel[] = [];
    let tempItem: ContractorWorkItemModel;
    for (let index = 0; index < this.addedItemList.length; index++) {
        let addeditem = this.addedItemList[index];
        tempItem =  new WorkItemModel();
        tempItem.Id = 0;
        tempItem.ContractorId = 0;
        tempItem.WorkItemId = addeditem.WorkItemId>0?this.addedItemList[index].WorkItemId:this.addedItemList[index].ParentId;
        tempList.push(tempItem)
    }
    
    let tempObj = new ContractorModel({
      Id: this.modelObject.Id,
      Code: this.mainForm.getRawValue().Code,
      NameEn: this.mainForm.getRawValue().NameEn,
      NameAr: this.mainForm.getRawValue().NameAr,
      IdNumber: this.mainForm.getRawValue().IdNumber,
      CommercialRegister: this.mainForm.getRawValue().CommercialRegister,
      TaxCard: this.mainForm.getRawValue().TaxCard,
      Nationality: this.mainForm.getRawValue().Nationality,
      Phone: this.mainForm.getRawValue().Phone,
      BankAccount: this.mainForm.getRawValue().BankAccount,
      Description: this.mainForm.getRawValue().Description,
      CountryId: this.mainForm.getRawValue().CountryId,
      CityId: this.mainForm.getRawValue().CityId,
      Governorate: this.mainForm.getRawValue().Governorate,
      Street: this.mainForm.getRawValue().Street,
      Landmark: this.mainForm.getRawValue().Landmark,
      BuildingNumber: this.mainForm.getRawValue().BuildingNumber,
      Floor: this.mainForm.getRawValue().Floor,
      Room: this.mainForm.getRawValue().Room,
      ZipCode: this.mainForm.getRawValue().ZipCode,
      ContractorWorkItems: tempList
      
    });

    if (JSON.stringify(tempObj) != JSON.stringify(this.modelObject)) {
      this.executeEditObject(tempObj);
    }
    else {
      if(this.sideModalRef === undefined){
        if(this.smallModal != undefined){
          this.tableEngine.smallModal.hide();
        }
      }
      else{
        this.tableEngine.sideModalRef.modalRef.hide();
      }
    }
  }

  executeEditObject(Object) {
    
    let tempList: ContractorWorkItemModel[] = [];
    let tempItem: ContractorWorkItemModel;
    for (let index = 0; index < this.addedItemList.length; index++) {
        tempItem =  new WorkItemModel();
        tempItem.Id = 0;
        tempItem.ContractorId = Object.Id;
        tempItem.WorkItemId = this.addedItemList[index].Id>0?this.addedItemList[index].Id:this.addedItemList[index].ParentId;
        tempList.push(tempItem)
    }
    this.modelObject.ContractorWorkItems = tempList;
    this.modelService.update(Object).subscribe(result => {
      if (result === true) {
        this.tableEngine.loadData();
        this.currentAction = '';
        this.tableEngine.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyEdited"));

        if(this.sideModalRef === undefined){
          if(this.smallModal != undefined){
            this.tableEngine.smallModal.hide();
          }
          else{
            this.tableView = true;
          }
        }
        else{
          this.tableEngine.sideModalRef.modalRef.hide();
        }
      }
      else {
        this.tableEngine.toastError(this.translate.instant("Error"), this.translate.instant("FailedEdit"));
        this.currentAction = '';
      }
    })
  }

  //Deletion methods:
  //Customize if needed.
  //Pass the target object as parameter if the target API can only delete singular objects and not lists.
  deleteObject() {
    this.savedPage = this.currentPage;
    //prevent multiclicks. re enable after API response
    this.currentAction = 'loading';
    this.executeDeleteObject();
  }

  //Same here...
  executeDeleteObject() {
    this.modelService.deleteList(this.tableEngine.deletionList).subscribe(result => {
      if (result === true) {
        this.tableEngine.loadData();
        this.currentAction = '';
        this.tableEngine.finalizeDeletion();
      }
      else {
        this.tableEngine.toastError(this.translate.instant("Error"), this.translate.instant("FailedDelete"));
        this.currentAction = '';
      }
    })
  }
  ////////////////////////////////////////////////////////////////////////////
  //
  //
  //
  //
  //
  ////////////////////////////////////////////////////////////////////////////
  //  EXCLUSIVE :
  //  Specifically for this component.
  ///////////////////////////////
  //Get any additional data here
  prevAction = '';
  addAndNew(){
    this.prevAction = 'AddThenNew';
    this.addObject();
    this.showModal(null,'Add');
  }
  loadServices() {
    this.currentAction = 'loading';
    
    this.countryService.getAll().subscribe(
      countryData => {
        this.countryList = countryData;
        this.cityService.getAll().subscribe(
          cityData => {
            this.cityList = cityData;
            this.tableEngine.loadData();
          }
        )
      }
    )
    this.codingUnitService.getAll().subscribe(
      codingUnitData => {
        this.codingUnitList = codingUnitData;
        this.workItemService.getAll().subscribe(
          workItemData => {
            this.workItemList = workItemData;
            this.contractorWorkItemService.getAll().subscribe(
              contractorWorkItemData => {
                this.contractorWorkItemList = contractorWorkItemData;
              }
            )
          }
        )
      }
    )
    
  }
  cityList: CityModel[] = [];
  countryList: CountryModel[] = [];
  getCountryById(Id: any): any {
    if(Id > 0){
      return this.countryList.find(i => i.Id === Id);
    }
    else{
      return new CountryModel();
    }
  }
  getCityById(Id: any): any {
    return this.cityList.find(i => i.Id === Id);
  }
  workItemList: WorkItemModel[] = [];
  codingUnitList: CodingUnitModel[] = [];
  contractorWorkItemList: ContractorWorkItemModel[] = [];
  addedItemList: ContractorWorkItemModel[] = [];
  subItemList: WorkItemModel[] = [];
  contractorWorkItemForm: FormGroup = new FormGroup({
    Id: new FormControl(),
    WorkItemId: new FormControl(),
    ParentId: new FormControl(),
  });
  contractorWorkItemObject: ContractorWorkItemModel = null;
  getUnityById(Id: any): any {
    return this.codingUnitList.find(i => i.Id === Id);
  }
  getWorkItemById(Id: any): any {
    return this.workItemList.find(i => i.Id === Id);
  }
  createContractorWorkItemForm(contractorWorkItemObject, formBuilder): FormGroup {
    return formBuilder.group({
      Id: [contractorWorkItemObject.Id >= 0 ? contractorWorkItemObject.Id : 0],
      WorkItemId: [contractorWorkItemObject.WorkItemId, Validators.compose([Validators.required])],
      ParentId: [contractorWorkItemObject.ParentId, Validators.compose([Validators.required])],
    });
  }
  newWorkItem: WorkItemModel = null;
  newWorkItemId: number = null;
  newWorkItemParentId: number = null;
  newItemInternalId= 0;
  @ViewChild(NgSelectComponent) subItemSelect: NgSelectComponent;
  changeParent(){
    this.subItemList = [new WorkItemModel({Id:0, NameAr:'لا يوجد', NameEn:'WorkItemNoSub'})];
    this.newWorkItemId = null;
    
    this.subItemList = this.subItemList.concat(this.workItemList.filter(x=>x.ParentId===this.newWorkItemParentId));
  }
  addContractorWorkItem(): any {
    let parent = this.getWorkItemById(this.newWorkItemParentId);
    let sub = this.newWorkItemId>0?this.getWorkItemById(this.newWorkItemId):new WorkItemModel();
    let temp = new ContractorWorkItemModel({
      Id: this.newItemInternalId,
      WorkItemId: sub.Id,
      NameAr: sub.NameAr,
      NameEn: sub.NameEn,
      ParentId: parent.Id,
      ParentAr: parent.NameAr,
      ParentEn: parent.NameEn,
      Level: 0
    });
    this.addedItemList = this.addedItemList.concat(temp)
    this.newItemInternalId = this.newItemInternalId + 1;
    this.newWorkItemId = null;
    this.subItemList = [];
    this.newWorkItemParentId = null;
  }
  removeContractorWorkItem(Id: any): any {
    let index = this.addedItemList.findIndex(x=>x.Id === Id);
    this.addedItemList.splice(index,1)
    
    //this.addedItemList = this.addedItemList.slice(0,index-1).concat(this.addedItemList.slice(index));
  }
  ////////////////////////////////////////////////////////////////////////////
  //
  //
  //
  //
  //
  ////////////////////////////////////////////////////////////////////////////
  //  CONSTANT :
  //  Do not change.
  ///////////////////////////////
  prepChangeSort(sortKey){
    this.tableEngine.changeSort(this.tableEngine.getSort(sortKey));
    this.itemOrder = this.tableEngine.itemOrder;
  }
}
