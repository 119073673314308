

<div class="row">
    <div class="col-12">
      <div class="card mb-4">
        <div class="card-body">
          <!-- <h5 class="mb-4">{{ 'From Date' | translate }}</h5> -->
          <div class="row">
          
            
            <div class="col-12 col-lg-4 mb-4">
              <label><span>{{ 'Category' | translate }}</span></label>
         
              <ng-select placeholder='{{"Category" | translate}}' [items]="categories" bindLabel="Name" bindValue="Id" [(ngModel)]="search.CategoryId">
              </ng-select>
            
            </div>
            <div class="col-12 col-lg-4 mb-4">
                <label><span>{{ 'Brand' | translate }}</span></label>
           
                <ng-select placeholder='{{"Brand" | translate}}' [items]="brnads" bindLabel="Name" bindValue="Id" [(ngModel)]="search.BrandId">
                </ng-select>
              
              </div>
              <div class="col-12 col-lg-4 mb-4">
                <label><span>{{ 'Warehouse' | translate }}</span></label>
           
                <ng-select placeholder='{{"Warehouse" | translate}}' [items]="warehouses" bindLabel="Name" bindValue="Id" [(ngModel)]="search.WarehouseId">
                </ng-select>
              
              </div>
         
            <div class="col-12 col-lg-6 mb-4 "  >
              <button type="button" (click)="Search()" class="btn btn-primary default mb-1" style="margin: 1px;">{{'Search' | translate}}</button>{{" "}}
              <button type="button" (click)="print()" class="btn btn-secondary default mb-1 " style="margin: 1px;">{{'Print' | translate}}</button>{{" "}}
              <button type="button" (click)="exportTable()" class="btn btn-success default mb-1" style="margin: 1px;">{{'PDF' | translate}}</button>{{" "}}
            </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  <div id="contentToConvert">
    <div class="row" >
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-body">
              <h3 class="float-center" style="text-align: center;">{{ 'Store Balance Summaryt' | translate }}</h3>
              <h4 class="float-center" style="text-align: center;">{{  today| date:'dd/MM/yyyy' }}</h4>
              <h6 class="float-center" style="text-align: center;">{{ 'Future Core' | translate }}</h6>
             
          </div>
        </div>
      </div>
    </div>
  
  
  <div class="card mb-12 tree-explorer-side col-md-12" *ngIf="data">
      <div class="card-body ">
      <div class="row">
          <table class="table table-striped" >
              <thead>
               
                <tr>
                    <th>{{ 'Code' | translate }} </th>
                    <th>{{ 'Name' | translate }} </th>
                    <th>{{ 'Category' | translate }} </th>
                    <th>{{ 'Brand' | translate }}</th>
                    <th>{{ 'Quantity' | translate }} </th>
                </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let item of data">
                    <td>{{item.Code}}#</td>
                      <td>{{item.Name}}</td>
                      <td>
                        <span *ngFor="let catrgory of item.ItemCategory">{{catrgory.CategoryName}} <br /></span>
                    </td>
                    <td>
                        <span *ngFor="let brand of item.ItemBrand">{{brand.BrandName}}</span>
                    </td>
                      <td>{{item.Quantity}}</td>
                      
                  </tr>
               
              </tbody>
          </table>
      
      </div>
  
      </div>
    </div>
  </div>