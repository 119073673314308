// import { StoreItemsModel } from "./StoreItemsModel";


export class ItemsPriceListModel{
    Id?: number;
    ItemId?:number;
    PriceListId?:number;
    SalePrice?:number;
    IsDeleted?: boolean;
    PriceListNameAr?:string;
    PriceListNameEn?:string;

    // Items? :StoreItemsModel[];

    constructor(item?:ItemsPriceListModel){
        item = item? item:{};
        this.Id = item.Id? item.Id:0;
        this.ItemId = item.ItemId? item.ItemId:0;
        this.PriceListId = item.PriceListId? item.PriceListId:0;          
        this.SalePrice = item.SalePrice? item.SalePrice:0;          
        this.PriceListNameAr = item.PriceListNameAr? item.PriceListNameAr:'';          
        this.PriceListNameEn = item.PriceListNameEn? item.PriceListNameEn:'';          
        this.IsDeleted = item.IsDeleted? item.IsDeleted:true;
        // this.Items = item.Items? item.Items:[];

    }

}