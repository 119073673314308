<app-list-page-header *ngIf="tableView" [itemOptionsPerPage]="[5,10,20]" [itemsPerPage]="tableEngine.itemsPerPage" 
    [selectAllState]="tableEngine.selectAllState" (addNewItem)="showModal(null,'Add')" [tableEngine]="this.tableEngine"
    (selectAllChange)="tableEngine.selectAll($event)" (changeOrderBy)="prepChangeSort($event)"
    (searchKeyUp)="tableEngine.filterDataByDateRange(this.SearchFrom,this.SearchTo,'Date')" [dateSearch]=true>
</app-list-page-header>


<div bsModal #taxModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name"
    style="text-align: center;">
    <div class="modal-dialog modal-sm" style=" display: inline-block; position: absolute !important;
    left: 55% !important; margin: 0px !important; margin-top: 15px !important;">
        <div class="modal-content" style="display: inline-block; width: 800px !important; ">
            <div class="modal-header"
                style="display: block; margin-top: 5px; padding: 0px 0px 5px 0px; text-align: center; ">
                <h1 id="dialog-child-name" class="modal-title">
                    {{"PurchaseQuotationItemTaxes" | translate}}</h1>
            </div>
            <div class="modal-body" style="padding: 5px; text-align: center;">
                <div style="padding: 5px;">
                    <table class="table" style="margin: 0px;">
                        <thead class="thead-light">
                            <th></th>
                            <th style="padding: 1px !important;">{{"PurchaseQuotationItemTaxName" |
                                translate}}</th>
                            <th style="padding: 1px !important;">{{"PurchaseQuotationItemTaxValue" |
                                translate}}</th>
                            <th style="padding: 1px !important;">{{"PurchaseQuotationItemAppliedTaxValue"
                                | translate}}</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let tax of newPurchaseQuotationItem.Taxes;  let i=index">
                                <td style="text-align: center; vertical-align: middle;">
                                    <i *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                                    tooltip="{{'Remove'|translate}}"
                                        class="simple-icon-close" (click)="removePurchaseQuotationItemTax(tax.Id)"
                                        style="font-size: 20px; font-weight: bolder; 
                            cursor: pointer; color: #DC3545;"></i>
                                </td>
                                <td>
                                    <div style="margin-top: 8px; margin-bottom: 8px;">
                                        {{lang==='ar-EG'?tax.TaxNameAr:tax.TaxNameEn}}
                                    </div>
                                </td>
                                <td>
                                    <div style="margin-top: 8px; margin-bottom: 8px;">
                                        {{(tax.TaxValue>0?tax.TaxValue.toFixed(2):0)}}{{tax.TaxIsRate?'%':''}}
                                    </div>
                                </td>
                                <td style="vertical-align: middle;">
                                    <div style="margin-top: 8px; margin-bottom: 8px;">
                                        {{newPurchaseQuotationItem.NetPrice>0&&tax.TaxValue>0?
                                        (tax.TaxIsRate?
                                        ((newPurchaseQuotationItem.NetPrice
                                        * tax.TaxValue /
                                        100).toFixed(2))
                                        :tax.TaxValue.toFixed(2))
                                        :0}}
                                    </div>
                                </td>
                            </tr>
                            <tr *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                                type="text" [formGroup]=quotationItemTaxForm>

                                <td style="text-align: center; vertical-align: middle;">
                                    <i class="simple-icon-plus" tooltip="{{'Add'|translate}}"
                                        [ngClass]="!(quotationItemTaxForm.invalid)?'simple-icon-plus':'simple-icon-plus inactive-icon'"
                                        (click)="!(quotationItemTaxForm.invalid||tableEngine.currentAction==='loading')?addPurchaseQuotationItemTax():''"
                                        style="font-size: 20px; font-weight: bolder; cursor: pointer; color: #17A2B8;"></i>
                                </td>
                                <td>
                                    <div>
                                        <ng-select appearance="outline" name="TaxId" formControlName="TaxId"
                                            placeholder='{{"PurchaseQuotationItemTaxId" | translate}}'
                                            #PurchaseQuotationItemNameSelect labelForId="txtycd2" [searchable]="true"
                                            (change)="changeTax()">
                                            <ng-option *ngFor="let tax of taxTypeList" [value]="tax.Id">
                                                {{lang==='ar-EG'?tax.NameAr:tax.NameEn}}</ng-option>
                                        </ng-select>
                                    </div>
                                </td>
                                <td>
                                    <div style="margin-top: 8px; margin-bottom: 8px;">
                                        {{(newPurchaseQuotationItemTax.TaxId>0?newPurchaseQuotationItemTax.TaxValue.toFixed(2):0)}}{{newPurchaseQuotationItemTax.TaxIsRate?'%':''}}
                                    </div>
                                </td>
                                <td style="vertical-align: middle;">
                                    <div style="margin-top: 8px; margin-bottom: 8px;">
                                        {{newPurchaseQuotationItem.NetPrice>0&&newPurchaseQuotationItemTax.TaxValue>0?
                                        (newPurchaseQuotationItemTax.TaxIsRate?
                                        (newPurchaseQuotationItem.NetPrice
                                        *
                                        newPurchaseQuotationItemTax.TaxValue
                                        / 100).toFixed(2)
                                        :newPurchaseQuotationItemTax.TaxValue)
                                        :0}}
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- <button class="btn btn-primary" (click)="addOrder()"
                style="font-weight: bold; width: 100%; text-align: center; margin-top: 10px;">{{"Save" |
                translate}}</button> -->
                <button class="btn btn-light" (click)="taxModal.hide()"
                    style="font-weight: bold; width: 30%; text-align: center; margin-top: 10px;">{{"Close" |
                    translate}}</button>
            </div>
        </div>
    </div>
</div>

<div class="form-group" *ngIf="!tableView">
    <div class="modal-body">
        <form class="md-float-material form-material" [formGroup]="mainForm">

            <div style="text-align: center;">
                <button class="btn btn-light" (click)="goBack()"
                    style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Back"  | translate}}</button>
                <button *ngIf="tableEngine.currentFormMode==='Add'"
                    [disabled]="mainForm.invalid || tableEngine.currentAction==='loading' || addedItemList.length===0 || !codeWasChecked"
                    id="smallModalSubmitButton" class="btn btn-secondary" (click)="addObject()"
                    style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Add"  | translate}}</button>
                <button *ngIf="tableEngine.currentFormMode==='Add'" class="btn btn-secondary" (click)="addAndNew()"
                    [disabled]="mainForm.invalid || tableEngine.currentAction==='loading' || addedItemList.length===0 || !codeWasChecked"
                    style="font-weight: bold; width: 250px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"AddAndNew"  | translate}}</button>
                <button *ngIf="tableEngine.currentFormMode==='Edit'"
                    [disabled]="mainForm.invalid || tableEngine.currentAction==='loading' || addedItemList.length===0 || (!codeWasChecked && lastCode != modelObject.Code)"
                    id="smallModalSubmitButton" class="btn btn-secondary" (click)="editObject()"
                    style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Edit" | translate}}</button>
            </div>

            <div class="form-group" style="margin-top: 10px;">

                <div class="row">

                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"PurchaseQuotationCode" | translate}}</label>
                        <div *ngIf="(tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit') 
                                        && !configPurchases.EnableAutoPurchaseQuotationCodes">
                            <input type="number" min="0" name="Code" class="form-control" [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="lastCode" style=" margin: 0px;" (change)="checkCode()" (keyup)="checkCode()"
                            placeholder='{{"PurchaseQuotationCode" | translate}}'>
                        </div>
                        <div *ngIf="((tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit') 
                                        && configPurchases.EnableAutoPurchaseQuotationCodes)">
                        <label class="form-control" type="text" style="border: unset; width: 120px;"
                            name="Code">{{lastCode}}</label>
                        </div>
                        <div *ngIf="tableEngine.currentFormMode == 'Info'">
                        <label class="form-control" type="text" style="border: unset; width: 120px;"
                            name="Code">{{modelObject.Code}}</label>
                        </div>
                        <div class="messages text-danger"
                            *ngIf="!(lastCode > 0)">
                            {{"PurchaseQuotationCodeRequired" | translate}}</div>
                        <div class="messages text-danger"
                            *ngIf="!(codeWasChecked) && (tableEngine.currentFormMode != 'Info' && lastCode != modelObject.Code)">
                            {{"PurchaseQuotationCodeAlreadyTaken" | translate}}</div>
                    </div>

                </div>

                <br>

                <div class="row">
                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"PurchaseQuotationSupplierId" | translate}}</label>
                        <div *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'">
                            <ng-select appearance="outline" name="SupplierId" formControlName="SupplierId"
                                placeholder='{{"PurchaseQuotationSupplierId" | translate}}'
                                #PurchaseQuotationStoreDataSelect labelForId="txtycd" [searchable]="true">
                                <ng-option *ngFor="let supplier of supplierList" [value]="supplier.Id">
                                    {{lang==='ar-EG'?supplier.NameAr:supplier.NameEn}}</ng-option>
                            </ng-select>
                        </div>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="SupplierId">{{lang==='ar-EG'?modelObject.SupplierNameAr:modelObject.SupplierNameEn}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('SupplierId').hasError('required') || mainForm.get('SupplierId').invalid) && ( mainForm.get('SupplierId').dirty ||  mainForm.get('SupplierId').touched)">
                            {{"PurchaseQuotationSupplierIdRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>

                    <br>

                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"PurchaseQuotationDate" | translate}}</label>

                        <input *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                            [type]="modelObject.Id>0?'date':'text'" onfocus="(this.type='date')"
                            onblur="if(!this.value)this.type='text'" onchange="if(!this.value)this.type='text'"
                            name="Date" class="form-control" formControlName="Date"
                            placeholder='{{"PurchaseQuotationDate" | translate}}'>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="Date">{{modelObject.Date | date:"dd/MM/yyyy"}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('Date').hasError('required') || mainForm.get('Date').invalid) && ( mainForm.get('Date').dirty ||  mainForm.get('Date').touched)">
                            {{"PurchaseQuotationDateRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>

                    <br>

                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"PurchaseQuotationExpirationDate" | translate}}</label>

                        <input *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                            [type]="modelObject.Id>0?'date':'text'" onfocus="(this.type='date')"
                            onblur="if(!this.value)this.type='text'" onchange="if(!this.value)this.type='text'"
                            name="ExpirationDate" class="form-control" formControlName="ExpirationDate"
                            placeholder='{{"PurchaseQuotationExpirationDate" | translate}}'>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            name="ExpirationDate">{{modelObject.ExpirationDate | date:"dd/MM/yyyy"}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('ExpirationDate').hasError('required') || mainForm.get('ExpirationDate').invalid) && ( mainForm.get('ExpirationDate').dirty ||  mainForm.get('ExpirationDate').touched)">
                            {{"PurchaseQuotationExpirationDateRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>

                </div>


                <br>

                <div class="row">
                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"PurchaseQuotationShippingAddress1" | translate}}</label>
                        <input *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                            type="text" name="ShippingAddress1" class="form-control" formControlName="ShippingAddress1"
                            style=" margin: 0px;" placeholder='{{"PurchaseQuotationShippingAddress1" | translate}}'>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('ShippingAddress1').hasError('required') || mainForm.get('ShippingAddress1').invalid) && ( mainForm.get('ShippingAddress1').dirty || mainForm.get('ShippingAddress1').touched)">
                            {{"PurchaseQuotationShippingAddress1Required" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>

                    <br>

                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"PurchaseQuotationShippingAddress2" | translate}}</label>
                        <input *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                            type="text" name="ShippingAddress2" class="form-control" formControlName="ShippingAddress2"
                            style=" margin: 0px;" placeholder='{{"PurchaseQuotationShippingAddress2" | translate}}'>
                        <span class="form-bar"></span>
                    </div>

                </div>

                <br>

                <div class="row" style="height: 160px;">
                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"PurchaseQuotationDescription" | translate}}</label>
                        <textarea *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                            type="text" name="Description" class="form-control" formControlName="Description"
                            style="width: 319%; height: 150px;"
                            placeholder='{{"PurchaseQuotationDescription" | translate}}'></textarea>
                        <label *ngIf="tableEngine.currentFormMode==='Info'" class="form-control" type="text"
                            style="white-space:pre-wrap; word-wrap:break-word; width: 319%; height: 150px; overflow: auto;"
                            name="Description">{{modelObject.Description}}</label>
                        <span class="form-bar"></span>
                    </div>
                </div>

                <br>
                <br>

                <label class="float-label push righ" style="margin-top: 15px;">{{"PurchaseQuotationItems" |
                    translate}}</label>
                <div class="card" style="min-width: 900px !important;">
                    <div class="card-body">
                        <div>
                            <div style="width: 100%;">
                                <div>
                                    <table class="table">
                                        <thead class="thead-light">
                                            <th></th>
                                            <th>{{"PurchaseQuotationItemName" | translate}}</th>
                                            <th>{{"PurchaseQuotationItemUnit" | translate}}</th>
                                            <!-- <th>{{"PurchaseQuotationItemDescription" | translate}}</th> -->
                                            <th>{{"PurchaseQuotationItemUnitCost" | translate}}</th>
                                            <th>{{"PurchaseQuotationItemQuantity" | translate}}</th>
                                            <th>{{"PurchaseQuotationItemTotalPrice" | translate}}</th>
                                            <th>{{"PurchaseQuotationItemDiscountValue" | translate}}</th>
                                            <th>{{"PurchaseQuotationItemNetPrice" | translate}}</th>
                                            <th>{{"PurchaseQuotationItemTotalTaxValues" | translate}}</th>
                                            <th>{{"PurchaseQuotationItemGrossPrice" | translate}}</th>
                                        </thead>
                                        <tbody>

                                            <tr *ngFor="let obj of addedItemList;  let i=index">
                                                <td style="text-align: center; vertical-align: middle;">
                                                    <i *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                                                        class="simple-icon-close" tooltip="{{'Remove'|translate}}"
                                                        (click)="removePurchaseQuotationItem(obj.Id)" style="font-size: 20px; font-weight: bolder; cursor: pointer; 
                                        color: #DC3545;"></i>
                                                </td>
                                                <td style="vertical-align: middle;">
                                                    {{obj.ItemId>0?(lang==='ar-EG'?obj.ItemNameAr:obj.ItemNameEn):"None"|translate}}
                                                </td>
                                                <td style="vertical-align: middle;">
                                                    {{obj.UnitId>0?(lang==='ar-EG'?obj.UnitNameAr:obj.UnitNameEn):"None"|translate}}
                                                </td>
                                                <!-- <td style="vertical-align: middle;">
                                            {{obj.Description}}
                                        </td> -->
                                                <td style="vertical-align: middle;">
                                                    {{obj.UnitCost.toFixed(2)}}
                                                </td>
                                                <td style="vertical-align: middle;">
                                                    {{obj.Quantity.toFixed(2)}}
                                                </td>
                                                <td style="vertical-align: middle;">
                                                    {{obj.TotalPrice.toFixed(2)}}
                                                </td>
                                                <td style="vertical-align: middle;">
                                                    {{obj.DiscountValue.toFixed(2)}}{{obj.DiscountIsRate?'%':''}}
                                                </td>
                                                <td style="vertical-align: middle;">
                                                    {{obj.NetPrice.toFixed(2)}}
                                                </td>
                                                <td style="vertical-align: middle;">
                                                    {{obj.TotalTaxValues.toFixed(2)}}
                                                </td>
                                                <td style="vertical-align: middle;">
                                                    {{obj.GrossPrice.toFixed(2)}}
                                                </td>
                                            </tr>
                                            <tr *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                                                type="text" [formGroup]=quotationItemForm>
                                                <td style="text-align: center; vertical-align: middle;">
                                                    <i class="simple-icon-plus" tooltip="{{'Add'|translate}}"
                                                        [ngClass]="!(quotationItemForm.invalid)?'simple-icon-plus':'simple-icon-plus inactive-icon'"
                                                        (click)="!(quotationItemForm.invalid||tableEngine.currentAction==='loading')?addPurchaseQuotationItem():''"
                                                        style="font-size: 20px; font-weight: bolder; cursor: pointer; color: #17A2B8;"></i>
                                                </td>
                                                <td>
                                                    <div
                                                        *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'">
                                                        <ng-select appearance="outline" name="ItemId"
                                                            formControlName="ItemId"
                                                            placeholder='{{"PurchaseQuotationItemId" | translate}}'
                                                            #PurchaseQuotationItemIdSelect labelForId="txtycd2"
                                                            [searchable]="true" (change)="changeItem()"
                                                            (keyup)="changeItem()">
                                                            <ng-option *ngFor="let item of itemList" [value]="item.Id">
                                                                {{lang==='ar-EG'?item.Name:item.Name}}</ng-option>
                                                        </ng-select>
                                                    </div>
                                                    <div class="messages text-danger"
                                                        *ngIf=" (quotationItemForm.get('ItemId').hasError('required') || quotationItemForm.get('ItemId').invalid) && ( quotationItemForm.get('ItemId').dirty ||  quotationItemForm.get('ItemId').touched)">
                                                        {{"PurchaseQuotationItemIdRequired" | translate}}
                                                    </div>
                                                </td>
                                                <td>
                                                    <label class="form-control" type="text" style="border: unset; "
                                                        name="UnitId">
                                                        {{newPurchaseQuotationItem.UnitId>0?(lang==='ar-EG'?newPurchaseQuotationItem.UnitNameAr:newPurchaseQuotationItem.UnitNameEn):"None"|translate}}
                                                    </label>
                                                </td>
                                                <td>
                                                    <div
                                                        *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'">
                                                        <input
                                                            *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                                                            type="number" min="0" name="UnitCost" class="form-control"
                                                            formControlName="UnitCost" style=" margin: 0px;"
                                                            (change)="calculateItemValues()"
                                                            (keyup)="calculateItemValues()"
                                                            placeholder='{{"PurchaseQuotationItemUnitCost" | translate}}'>
                                                        <div class="messages text-danger"
                                                            *ngIf=" (quotationItemForm.get('UnitCost').hasError('required') || quotationItemForm.get('UnitCost').invalid) && ( quotationItemForm.get('UnitCost').dirty || quotationItemForm.get('UnitCost').touched)">
                                                            {{"PurchaseQuotationItemUnitCostRequired" |
                                                            translate}}</div>
                                                        <span class="form-bar"></span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <input
                                                        *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                                                        type="number" min="0" name="Quantity" class="form-control"
                                                        formControlName="Quantity" style="margin: 0px;"
                                                        (change)="calculateItemValues()" (keyup)="calculateItemValues()"
                                                        placeholder='{{"PurchaseQuotationItemQuantity" | translate}}'>

                                                    <div class="messages text-danger"
                                                        *ngIf=" (quotationItemForm.get('Quantity').hasError('required') || quotationItemForm.get('Quantity').invalid) && ( quotationItemForm.get('Quantity').dirty || quotationItemForm.get('Quantity').touched)">
                                                        {{"PurchaseQuotationItemQuantityRequired" |
                                                        translate}}</div>
                                                    <span class="form-bar"></span>
                                                </td>
                                                <td>
                                                    <label class="form-control" type="text" style="border: unset; "
                                                        name="TotalPrice">
                                                        {{newPurchaseQuotationItem.TotalPrice>0?newPurchaseQuotationItem.TotalPrice.toFixed(2):0}}
                                                    </label>
                                                </td>
                                                <td>
                                                    <div
                                                        *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'">
                                                        <input
                                                            *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                                                            type="number" min="0"
                                                            [max]="quotationItemForm.getRawValue().DiscountIsRate?100:2147483647"
                                                            (change)="calculateItemValues()"
                                                            (keyup)="calculateItemValues()" name="DiscountValue"
                                                            class="form-control" formControlName="DiscountValue"
                                                            style=" margin: 0px;"
                                                            placeholder='{{"PurchaseQuotationItemDiscountValue" | translate}}'>
                                                        <div class="itemCheck mb-0 custom-checkbox custom-control"
                                                            style="display: inline-block;">
                                                            <input type="checkbox" class="custom-control-input"
                                                                [id]="'valueCheck'" (change)="calculateItemValues()"
                                                                (keyup)="calculateItemValues()"
                                                                formControlName="DiscountIsRate">
                                                            <label class="custom-control-label"
                                                                [for]="'valueCheck'"></label>
                                                        </div>
                                                        {{'ValueIsRate' | translate}}
                                                        <div class="messages text-danger"
                                                            *ngIf=" (quotationItemForm.get('DiscountValue').hasError('required') || quotationItemForm.get('DiscountValue').invalid) && ( quotationItemForm.get('DiscountValue').dirty || quotationItemForm.get('DiscountValue').touched)">
                                                            {{"PurchaseQuotationItemDiscountValueRequired" |
                                                            translate}}</div>
                                                        <span class="form-bar"></span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <label class="form-control" type="text" style="border: unset; "
                                                        name="NetPrice">
                                                        {{newPurchaseQuotationItem.NetPrice>0?newPurchaseQuotationItem.NetPrice.toFixed(2):0}}
                                                    </label>
                                                </td>
                                                <td>
                                                    <div style="display: block; margin-bottom: 0px;">
                                                        <label class="form-control" type="text" style="border: unset; "
                                                            name="TotalTaxValues">
                                                            <a>
                                                                {{newPurchaseQuotationItem.TotalTaxValues>0?newPurchaseQuotationItem.TotalTaxValues.toFixed(2):0}}</a>

                                                        </label>
                                                    </div>
                                                    <div style="display: block; margin-top: 0px;">

                                                        <button *ngIf="tableEngine.currentFormMode==='Add' || tableEngine.currentFormMode==='Edit'"
                                                            [disabled]="!(newPurchaseQuotationItem.ItemId>0) || !(newPurchaseQuotationItem.UnitCost>0) || !(newPurchaseQuotationItem.Quantity>0)"
                                                            id="taxEditButton" class="btn btn-secondary"
                                                            (click)="showTaxModal()"
                                                            style="font-weight: bold; width: 65px; height: 25px; font-size: 12px; text-align: center; padding: 2px;">
                                                            {{"Edit" | translate}}</button>
                                                    </div>
                                                </td>
                                                <td>
                                                    <label class="form-control" type="text" style="border: unset; "
                                                        name="UnitId">
                                                        {{newPurchaseQuotationItem.GrossPrice>0?newPurchaseQuotationItem.GrossPrice.toFixed(2):0}}
                                                    </label>
                                                </td>
                                                <!-- </td>
                                                <td colspan="9">
                                                                <div class="card" style="min-width: 600px !important;">
                                                                    <div class="card-body">
                                                                        <div class="row">
                                                                            <div style="display: inline-block;">
                                                                                {{"" | translate}}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                    </td> -->
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <br>

                    <div class="row">
                        <div class=" col-md-4">
                            &nbsp;
                        </div>

                        <br>

                        <div class=" col-md-4">
                            &nbsp;
                        </div>

                        <br>
                        <div class=" col-md-4">
                            <div style="display: block;">
                                {{"PurchaseQuotationTotalGrossPrices" | translate}}
                            </div>
                            <label
                                style="display: block; margin-left: 8px; margin-right: 8px; width: 85% !important; font-size: 25px;"
                                class="form-control" type="number" name="totalItemGrossPrices">
                                {{currentTotalItemGrossPrices>0?this.currentTotalItemGrossPrices.toFixed(2):0}}
                            </label>
                        </div>
                    </div>
                </div>


                <br>

                <label class="float-label push righ" style="margin-top: 15px;">{{"PurchaseQuotationDiscount" |
                    translate}}</label>
                <div class="card" style="min-width: 900px !important;">
                    <div class="card-body">
                        <div class="row">

                            <div class=" col-md-4">
                                <label class="float-label push righ">{{"PurchaseQuotationDiscountValue" |
                                    translate}}</label>
                                <div *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'">
                                    <input
                                        *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                                        type="number" min="0"
                                        [max]="mainForm.getRawValue().DiscountIsRate?100:2147483647"
                                        (change)="calculateTotalPurchaseQuotationValue()"
                                        (keyup)="calculateTotalPurchaseQuotationValue()" name="DiscountValue"
                                        class="form-control" formControlName="DiscountValue" style=" margin: 0px;"
                                        placeholder='{{"PurchaseQuotationDiscountValue" | translate}}'>
                                    <div class="itemCheck mb-0 custom-checkbox custom-control"
                                        style="display: inline-block;">
                                        <input type="checkbox" class="custom-control-input"
                                            [id]="'quotationDiscountCheck'"
                                            (change)="calculateTotalPurchaseQuotationValue()"
                                            (keyup)="calculateTotalPurchaseQuotationValue()"
                                            formControlName="DiscountIsRate">
                                        <label class="custom-control-label" [for]="'quotationDiscountCheck'"></label>
                                    </div>
                                    {{'ValueIsRate' | translate}}
                                    <div class="messages text-danger"
                                        *ngIf=" (mainForm.get('DiscountValue').hasError('required') || mainForm.get('DiscountValue').invalid) && ( mainForm.get('DiscountValue').dirty || mainForm.get('DiscountValue').touched)">
                                        {{"PurchaseQuotationDiscountValueRequired" | translate}}</div>
                                    <span class="form-bar"></span>
                                </div>
                            </div>


                            <br>

                            <div class=" col-md-4">
                                <label class="float-label push righ">{{"PurchaseQuotationAppliedDiscountValue" |
                                    translate}}</label>
                                <label class="form-control" type="text" name="UnitId">
                                    {{
                                    this.mainForm.getRawValue().DiscountValue>0&&currentTotalItemGrossPrices>0?
                                    (this.mainForm.getRawValue().DiscountIsRate?
                                    (this.mainForm.getRawValue().DiscountValue*currentTotalItemGrossPrices/100).toFixed(2)
                                    :this.mainForm.getRawValue().DiscountValue)
                                    :0
                                    }}
                                </label>
                            </div>


                        </div>


                        <div class="row">
                            <div class=" col-md-4">
                                &nbsp;
                            </div>

                            <br>

                            <div class=" col-md-4">
                                &nbsp;
                            </div>

                            <br>
                            <div class=" col-md-4">
                                <div style="display: inline-block;">
                                    {{"PurchaseQuotationTotalAfterDiscount" | translate}}
                                </div>
                                <label
                                    style="display: block; margin-left: 8px; margin-right: 8px; width: 85% !important; font-size: 25px;"
                                    class="form-control" type="number" name="totalAfterPurchaseQuotationDiscount">
                                    {{this.currentTotalAfterPurchaseQuotationDiscount>0?this.currentTotalAfterPurchaseQuotationDiscount.toFixed(2):0}}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>



                <br>

                <label class="float-label push righ" style="margin-top: 15px;">{{"PurchaseQuotationShippingFees" |
                    translate}}</label>
                <div class="card" style="min-width: 900px !important;">
                    <div class="card-body">
                        <div class="row">



                            <div class=" col-md-4">
                                <label class="float-label push righ">{{"PurchaseQuotationShippingFeesValue" |
                                    translate}}</label>
                                <div *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'">
                                    <input
                                        *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                                        type="number" min="0" name="ShippingFees" class="form-control"
                                        formControlName="ShippingFees" style=" margin: 0px;"
                                        (change)="calculateTotalPurchaseQuotationValue()"
                                        (keyup)="calculateTotalPurchaseQuotationValue()"
                                        placeholder='{{"PurchaseQuotationShippingFees" | translate}}'>
                                    <div class="messages text-danger"
                                        *ngIf=" (mainForm.get('ShippingFees').hasError('required') || mainForm.get('ShippingFees').invalid) && ( mainForm.get('ShippingFees').dirty || mainForm.get('ShippingFees').touched)">
                                        {{"PurchaseQuotationShippingFeesRequired" | translate}}</div>
                                    <span class="form-bar"></span>
                                </div>
                            </div>

                        </div>

                        <div class="row">
                            <div class=" col-md-4">
                                &nbsp;
                            </div>

                            <br>

                            <div class=" col-md-4">
                                &nbsp;
                            </div>

                            <br>
                            <div class=" col-md-4">
                                <div style="display: inline-block;">
                                    {{"PurchaseQuotationTotalAfterShippingFees" | translate}}
                                </div>
                                <label
                                    style="display: block; margin-left: 8px; margin-right: 8px; width: 85% !important; font-size: 25px;"
                                    class="form-control" type="number" name="totalAfterShippingFees">
                                    {{this.currentTotalAfterShippingFees>0?this.currentTotalAfterShippingFees.toFixed(2):0}}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>


                <br>

                <!-- <div id="divmiddle" style="display: block; margin-top: 5px;"> -->

                <label class="float-label push righ" style="margin-top: 15px;">{{"PurchaseQuotationServices" |
                    translate}}</label>
                <div style="text-align: center;">
                    <div style="width: 100%; display: inline-block;">
                        <div>
                            <div class="card" style="min-width: 900px !important;">
                                <div class="card-body">
                                    <table class="table">
                                        <thead class="no-border-top">
                                            <th></th>
                                            <th>{{"PurchaseQuotationServiceName" | translate}}</th>
                                            <th>{{"PurchaseQuotationServiceValue" | translate}}</th>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let obj of addedServiceList;  let i=index">
                                                <td style="text-align: center; vertical-align: middle;">
                                                    <i *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                                                        class="simple-icon-close" tooltip="{{'Remove'|translate}}"
                                                        (click)="removePurchaseQuotationService(obj.Id)" style="font-size: 20px; font-weight: bolder; cursor: pointer; 
                                        color: #DC3545;"></i>
                                                </td>
                                                <td style="vertical-align: middle;">
                                                    {{lang==='ar-EG'?obj.NameAr:obj.NameEn}}
                                                </td>
                                                <td style="vertical-align: middle;">
                                                    {{obj.Value.toFixed(2)}}
                                                </td>
                                            </tr>
                                            <tr *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                                                type="text" [formGroup]=quotationServiceForm>
                                                <td style="text-align: center; vertical-align: middle;">
                                                    <i class="simple-icon-plus" tooltip="{{'Add'|translate}}"
                                                        [ngClass]="!(quotationServiceForm.invalid)?'simple-icon-plus':'simple-icon-plus inactive-icon'"
                                                        (click)="!(quotationServiceForm.invalid||tableEngine.currentAction==='loading')?addPurchaseQuotationService():''"
                                                        style="font-size: 20px; font-weight: bolder; cursor: pointer; color: #17A2B8;"></i>
                                                </td>
                                                <td>
                                                    <input
                                                        *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                                                        type="text" name="NameAr" class="form-control"
                                                        formControlName="NameAr" style=" margin: 0px;"
                                                        placeholder='{{"PurchaseQuotationServiceNameAr" | translate}}'>
                                                    <div class="messages text-danger"
                                                        *ngIf=" (quotationServiceForm.get('NameAr').hasError('required') || quotationServiceForm.get('NameAr').invalid) && ( quotationServiceForm.get('NameAr').dirty || quotationServiceForm.get('NameAr').touched)">
                                                        {{"PurchaseQuotationServiceNameArRequired" | translate}}</div>
                                                </td>
                                                <td>
                                                    <input
                                                        *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                                                        type="number" min="0" name="Value" class="form-control"
                                                        formControlName="Value" style=" margin: 0px;"
                                                        placeholder='{{"PurchaseQuotationServiceValue" | translate}}'>
                                                    <div class="messages text-danger"
                                                        *ngIf=" (quotationServiceForm.get('Value').hasError('required') || quotationServiceForm.get('Value').invalid) && ( quotationServiceForm.get('Value').dirty || quotationServiceForm.get('Value').touched)">
                                                        {{"PurchaseQuotationValueRequired" | translate}}</div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <br>


                <div class="row">
                    <div class=" col-md-4">
                        &nbsp;
                    </div>

                    <br>

                    <div class=" col-md-4">
                        &nbsp;
                    </div>

                    <br>
                    <div class=" col-md-4">
                        <div style="display: inline-block;">
                            {{"PurchaseQuotationTotalValue" | translate}}
                        </div>
                        <label
                            style="display: block; margin-left: 8px; margin-right: 8px; width: 85% !important; font-size: 25px;"
                            class="form-control" type="number" name="quotationTotalValue">
                            {{this.currentTotalPurchaseQuotationValue>0?this.currentTotalPurchaseQuotationValue.toFixed(2):0}}
                        </label>
                    </div>
                </div>
            </div>

            <div style="text-align: center;">
                <button class="btn btn-light" (click)="goBack()"
                    style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Back"  | translate}}</button>
                <button *ngIf="tableEngine.currentFormMode==='Add'"
                    [disabled]="mainForm.invalid || tableEngine.currentAction==='loading' || addedItemList.length===0"
                    id="smallModalSubmitButton" class="btn btn-secondary" (click)="addObject()"
                    style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Add"  | translate}}</button>
                <button *ngIf="tableEngine.currentFormMode==='Add'" class="btn btn-secondary" (click)="addAndNew()"
                    [disabled]="mainForm.invalid || tableEngine.currentAction==='loading' || addedItemList.length===0"
                    style="font-weight: bold; width: 250px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"AddAndNew"  | translate}}</button>
                <button *ngIf="tableEngine.currentFormMode==='Edit'"
                    [disabled]="mainForm.invalid || tableEngine.currentAction==='loading' || addedItemList.length===0"
                    id="smallModalSubmitButton" class="btn btn-secondary" (click)="editObject()"
                    style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Edit" | translate}}</button>
            </div>
        </form>
    </div>
</div>

<div bsModal #delModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <!-- <button type="button" class="close pull-right" aria-label="Close" (click)="delModal.hide()"
                style="margin-right: 20px; margin-left: 20px; margin-top: 10px; width: 20px; display: inline;">
            <span aria-hidden="true">&nbsp;</span>
            </button> -->
            <span aria-hidden="true">&nbsp;</span>
            <div class="modal-header" style="display: inline; padding: 0px 0px 5px 0px;">
                <h4 id="dialog-child-name" class="modal-title pull-left" style="text-align: center; color: #DC3545;">
                    {{"ConfirmDel"+translationName |
                    translate}}</h4>
            </div>
            <div class="modal-body">
                <div class="form-group form-primary" *ngIf="tableEngine.deletionList.length < tableEngine.ogData.length 
                || tableEngine.deletionList.length === 1" style="text-align: center;">
                    <div *ngFor="let item of tableEngine.deletionList;" style="text-align: center;">
                        <label type="text">- {{translationName|translate}} {{"Number" | translate}}
                            {{lang==='ar-EG'?item.Code:item.Code}}</label>
                        <span class="form-bar"></span>
                    </div>
                </div>
                <div class="form-group form-primary" style="text-align: center;" *ngIf="tableEngine.deletionList.length > 1 && 
                    tableEngine.deletionList.length === tableEngine.ogData.length">
                    <label type="text">
                        <h1 style="font-weight: bolder; text-align: center;">{{translationName+"DeleteAll" | translate}}
                        </h1>
                    </label>
                    <span class="form-bar"></span>
                </div>

                <div style="margin-left: 5px; text-align: center; margin-top: 30px;">
                    <button class="btn btn-danger" id="smallModalDeleteButton" (click)="deleteObject()"
                        [disabled]="tableEngine.currentAction==='loading'"
                        style="font-weight: bold; width: 100%; text-align: center;">{{"Delete" | translate}}</button>
                    <button class="btn btn-light" (click)="delModal.hide()"
                        style="font-weight: bold; width: 100%; text-align: center; margin-top: 10px;">{{"Close" |
                        translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>



<div *ngIf="tableView" class="FormScreen" class="tableScreen">

    <div id="divmiddle" style="display: block; margin-top: 5px;">

        <div style="width: 100%;">
            <div>
                <div class="card" style="min-width: 900px !important;">
                    <div class="card-body">
                        <table class="table table-hover" style="margin: unset !important;">
                            <thead class="no-border-top">
                                <th style="text-align: center; padding:5px;">
                                    <div class=" text-zero" style="display: inline-block;">
                                        <div class="top-right-button-container2 btn-group" dropdown>
                                            <div class="btn btn-primary pl-4 pr-0 check-button">
                                                <label class="custom-control custom-checkbox mb-0 d-inline-block"
                                                    style="width: 10px;">
                                                    <input type="checkbox" class="custom-control-input"
                                                        [checked]="tableEngine.selectAllState==='checked'"
                                                        [indeterminate]="tableEngine.selectAllState === 'indeterminate'"
                                                        (change)="tableEngine.selectAll($event)">
                                                    <span class="custom-control-label">&nbsp;</span>
                                                </label>
                                            </div>
                                            <button id="button-split" type="button" dropdownToggle style="width: 10px;"
                                                [ngClass]="(tableEngine.selected.length == 0)?
                                                'btn btn-outline-primary dropdown-toggle dropdown-toggle-split':
                                                'btn btn-primary dropdown-toggle dropdown-toggle-split'"
                                                aria-controls="dropdown-split">
                                                <span class="caret"></span>
                                            </button>
                                            <ul id="dropdown-split" *dropdownMenu
                                                class="dropdown-menu dropdown-menu-right" role="menu"
                                                aria-labelledby="button-split">
                                                <li role="menuitem"><a class="dropdown-item">
                                                        <button class="btn btn-danger d-inline-block"
                                                            style=" font-weight: bold; text-align: center; padding: 0px; width: 150px; height: 30px;"
                                                            (click)="tableEngine.showDelModal('delMulti')"
                                                            [disabled]="tableEngine.selected.length===0 || tableEngine.currentAction==='loading'">{{"DeleteSelected"
                                                            |
                                                            translate}}</button></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </th>

                                <th class="sorticon" (click)="prepChangeSort('PurchaseQuotationCode')"
                                    [ngClass]="(itemOrder.label==='PurchaseQuotationCode')?'sortkey sorticon':'sorticon'">
                                    <div
                                        [ngClass]="{'iconsminds-up---down': (itemOrder.label!='PurchaseQuotationCode'), 
                                    'iconsminds-down': ((itemOrder.label==='PurchaseQuotationCode') && tableEngine.orderAsc === true),
                                    'iconsminds-up': ((itemOrder.label==='PurchaseQuotationCode') && tableEngine.orderAsc === false)}">
                                    </div>
                                </th>
                                <th class="sortOption" (click)="prepChangeSort('PurchaseQuotationCode')"
                                    [ngClass]="{'sortkey': (itemOrder.label==='PurchaseQuotationCode')}">
                                    {{"PurchaseQuotationCode" | translate}}
                                </th>


                                <th class="sorticon" (click)="prepChangeSort('PurchaseQuotationDate')"
                                    [ngClass]="(itemOrder.label==='PurchaseQuotationDate')?'sortkey sorticon':'sorticon'">
                                    <div
                                        [ngClass]="{'iconsminds-up---down': (itemOrder.label!='PurchaseQuotationDate'), 
                                    'iconsminds-down': ((itemOrder.label==='PurchaseQuotationDate') && tableEngine.orderAsc === true),
                                    'iconsminds-up': ((itemOrder.label==='PurchaseQuotationDate') && tableEngine.orderAsc === false)}">
                                    </div>
                                </th>
                                <th class="sortOption" (click)="prepChangeSort('PurchaseQuotationDate')"
                                    [ngClass]="{'sortkey': (itemOrder.label==='PurchaseQuotationDate')}">
                                    {{"PurchaseQuotationDate" | translate}}
                                </th>


                                <th class="sorticon" (click)="prepChangeSort('PurchaseQuotationExpirationDate')"
                                    [ngClass]="(itemOrder.label==='PurchaseQuotationExpirationDate')?'sortkey sorticon':'sorticon'">
                                    <div
                                        [ngClass]="{'iconsminds-up---down': (itemOrder.label!='PurchaseQuotationExpirationDate'), 
                                    'iconsminds-down': ((itemOrder.label==='PurchaseQuotationExpirationDate') && tableEngine.orderAsc === true),
                                    'iconsminds-up': ((itemOrder.label==='PurchaseQuotationExpirationDate') && tableEngine.orderAsc === false)}">
                                    </div>
                                </th>
                                <th class="sortOption" (click)="prepChangeSort('PurchaseQuotationExpirationDate')"
                                    [ngClass]="{'sortkey': (itemOrder.label==='PurchaseQuotationExpirationDate')}">
                                    {{"PurchaseQuotationExpirationDate" | translate}}
                                </th>



                                <th class="sorticon" (click)="prepChangeSort('PurchaseQuotationSupplierName')"
                                    [ngClass]="(itemOrder.label==='PurchaseQuotationSupplierName')?'sortkey sorticon':'sorticon'">
                                    <div
                                        [ngClass]="{'iconsminds-up---down': (itemOrder.label!='PurchaseQuotationSupplierName'), 
                                    'iconsminds-down': ((itemOrder.label==='PurchaseQuotationSupplierName') && tableEngine.orderAsc === true),
                                    'iconsminds-up': ((itemOrder.label==='PurchaseQuotationSupplierName') && tableEngine.orderAsc === false)}">
                                    </div>
                                </th>
                                <th class="sortOption" (click)="prepChangeSort('PurchaseQuotationSupplierName')"
                                    [ngClass]="{'sortkey': (itemOrder.label==='PurchaseQuotationSupplierName')}">
                                    {{"PurchaseQuotationSupplierName" | translate}}
                                </th>

                                <th class="sorticon" (click)="prepChangeSort('PurchaseQuotationShippingAddress')"
                                    [ngClass]="(itemOrder.label==='PurchaseQuotationShippingAddress')?'sortkey sorticon':'sorticon'">
                                    <div
                                        [ngClass]="{'iconsminds-up---down': (itemOrder.label!='PurchaseQuotationShippingAddress'), 
                                    'iconsminds-down': ((itemOrder.label==='PurchaseQuotationShippingAddress') && tableEngine.orderAsc === true),
                                    'iconsminds-up': ((itemOrder.label==='PurchaseQuotationShippingAddress') && tableEngine.orderAsc === false)}">
                                    </div>
                                </th>
                                <th class="sortOption" (click)="prepChangeSort('PurchaseQuotationShippingAddress')"
                                    [ngClass]="{'sortkey': (itemOrder.label==='PurchaseQuotationShippingAddress')}">
                                    {{"PurchaseQuotationShippingAddress" | translate}}
                                </th>


                                <th class="sorticon"
                                    (click)="prepChangeSort('PurchaseQuotationTotalPurchaseQuotationValue')"
                                    [ngClass]="(itemOrder.label==='PurchaseQuotationTotalPurchaseQuotationValue')?'sortkey sorticon':'sorticon'">
                                    <div
                                        [ngClass]="{'iconsminds-up---down': (itemOrder.label!='PurchaseQuotationTotalPurchaseQuotationValue'), 
                                    'iconsminds-down': ((itemOrder.label==='PurchaseQuotationTotalPurchaseQuotationValue') && tableEngine.orderAsc === true),
                                    'iconsminds-up': ((itemOrder.label==='PurchaseQuotationTotalPurchaseQuotationValue') && tableEngine.orderAsc === false)}">
                                    </div>
                                </th>
                                <th class="sortOption"
                                    (click)="prepChangeSort('PurchaseQuotationTotalPurchaseQuotationValue')"
                                    [ngClass]="{'sortkey': (itemOrder.label==='PurchaseQuotationTotalPurchaseQuotationValue')}">
                                    {{"PurchaseQuotationTotalPurchaseQuotationValue" | translate}}
                                </th>

                                <th [colSpan]="3"
                                    style="text-align: center; padding: unset !important; vertical-align: middle;">

                                    <div id="displayOptions" style="
                                        display: inline; margin-top: 10px; margin-left: 5px; margin-right: 5px;">
                                        <span class="text-muted text-small" style=" width: 50px; height: 40px;">
                                            {{"ResultCount" | translate}}
                                        </span>
                                        <span dropdown class="d-inline-block position-relative">
                                            <button class="btn btn-outline-dark btn-xs dropdown-toggle" type="button"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                                dropdownToggle>
                                                {{tableEngine.itemsPerPage}}
                                            </button>
                                            <div class="dropdown-menu dropdown-menu-right dropdown-menu-sm text-right"
                                                *dropdownMenu>
                                                <li role="menuitem" *ngFor="let item of tableEngine.itemOptionsPerPage">
                                                    <a [ngClass]="item === tableEngine.itemsPerPage ? 'dropdown-item active' : 'dropdown-item'"
                                                        (click)="tableEngine.paginateData(item, 1)">{{item}}</a>
                                                </li>
                                            </div>
                                        </span>
                                    </div>

                                </th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let obj of tableEngine.paginatedData;  let i=index">
                                    <td style="text-align: center; padding:5px;">
                                        <div class="itemCheck mb-0 custom-checkbox custom-control"
                                            style="display: inline-block;">
                                            <input type="checkbox" class="custom-control-input" [id]="'customCheck'+i"
                                                [checked]="tableEngine.isSelected(obj)"
                                                (change)="tableEngine.onSelect(obj)">
                                            <label class="custom-control-label" [for]="'customCheck'+i"></label>
                                        </div>
                                    </td>
                                    <td colspan="2">{{obj.Code}}</td>
                                    <td colspan="2">{{obj.Date | date:"dd/MM/yyyy"}}</td>
                                    <td colspan="2">{{obj.ExpirationDate | date:"dd/MM/yyyy"}}</td>
                                    <td colspan="2">
                                        {{lang==='ar-EG'?obj.SupplierNameAr:obj.SupplierNameEn}}
                                    </td>
                                    <td colspan="2">{{obj.ShippingAddress1}}</td>
                                    <!-- <td colspan="2">{{obj.ShippingAddress2}}</td> -->
                                    <!-- <td colspan="2" style="white-space:initial; word-wrap:break-word;  max-width: 250px;">
                                        {{obj.Description}}
                                    </td> -->
                                    <td colspan="2">{{obj.TotalPurchaseQuotationValue.toFixed(2)}}</td>
                                    <td style="text-align: center;">
                                        <i class="simple-icon-info" (click)="showModal(obj.Id, 'Info')"
                                            tooltip="{{'Info'|translate}}" placement="auto" style="font-size: 20px; font-weight: bolder; cursor: pointer; 
                                                vertical-align: middle; color: #17A2B8;"></i>
                                    </td>
                                    <td style="text-align: center;">
                                        <i class="simple-icon-pencil" (click)="showModal(obj.Id, 'Edit')"
                                            tooltip="{{'Edit'|translate}}" placement="auto" style="font-size: 20px; font-weight: bolder; cursor: pointer; 
                                                vertical-align: middle; color: #FFC107;"></i>
                                    </td>
                                    <td style="text-align: center;">
                                        <i class="simple-icon-close" (click)="tableEngine.showDelModal(obj.Id)"
                                            tooltip="{{'Delete'|translate}}" placement="auto" style="font-size: 20px; font-weight: bolder; cursor: pointer; 
                                                vertical-align: middle; color: #DC3545;"></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div id="divsearchmiddle" style="display: inline-block; width: 100%; height: 100%;">
                            <div style="text-align: center;">
                                <!-- <div class="centerside" style="display: inline-block; width: 15%; height: 100%; ">
                                    &nbsp;

                                </div> -->
                                <div class="centercenter"
                                    style="display: inline-block; width: 70%; height: 100%;  text-align: center;">

                                    <bdo dir="ltr">
                                        <pagination class="pagination-sm2" style="padding: 0px, 0px, 0px, 0px; margin: 0px, 0px, 0px, 0px;
                                        display: inline-block;" [(ngModel)]="currentPage" [boundaryLinks]="true"
                                            [totalItems]="tableEngine.filteredData.length"
                                            [itemsPerPage]="tableEngine.itemsPerPage"
                                            [customFirstTemplate]="firstTemplate" [customLastTemplate]="lastTemplate"
                                            [customPreviousTemplate]="prevTemplate" [customNextTemplate]="nextTemplate"
                                            [maxSize]="5" (pageChanged)="tableEngine.changePage($event)">
                                        </pagination>
                                    </bdo>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

            </div>
        </div>
    </div>

    <div id="divbottom" style="display: block; height: 47px;">

        <div id="divsearchright" style="float: right;  width: 33.33%; padding: 0px;">&nbsp;</div>


        <div id="divsearchleft" style="float: left; width: 33.33%; height: 100%;">&nbsp;</div>

    </div>
</div>

<div>

</div>

<ng-template #nextTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i *ngIf="lang==='en-US'" class='simple-icon-arrow-right'></i>
    <i *ngIf="lang==='ar-EG'" class='simple-icon-arrow-left'></i>
</ng-template>

<ng-template #prevTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i *ngIf="lang==='en-US'" class='simple-icon-arrow-left'></i>
    <i *ngIf="lang==='ar-EG'" class='simple-icon-arrow-right'></i>
</ng-template>

<ng-template #lastTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i class='simple-icon-control-end'></i>
</ng-template>

<ng-template #firstTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i class='simple-icon-control-start'></i>
</ng-template>


<simple-notifications></simple-notifications>