import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, OnInit } from '@angular/core';
import { BalanceSheetService } from '../../balance-sheet.service';
import { BalanceSheetModel } from '../../models/balance-sheet.model';
import { SearchPeriodModel } from '../../models/search-period.model';
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';
@Component({
    selector: 'balance-sheet',
    templateUrl: 'balance-sheet.component.html',
    styleUrls: ['balance-sheet.component.scss']
})
export class BalanceSheetComponent implements OnInit{
    declare lang: any;
    list:BalanceSheetModel[]=[];
    liabilities:BalanceSheetModel[]=[];
    PropertyRights:BalanceSheetModel[]=[];
    today=new Date();
    
    nodeList:BalanceSheetModel[]=[];
    constructor(private balanceSheetService:BalanceSheetService){
      this.lang = localStorage.getItem('theme_lang');
    }
    TotalAssets = 0;
    Totalliabilities = 0;
    TotalPropertyRights = 0;
    data:BalanceSheetModel;
    search:SearchPeriodModel={};
    ngOnInit(): void {
      debugger;
        this.balanceSheetService.search(this.search)
        .subscribe(data => {
          this.data = data;
          this.data.Assets.forEach(element => {
            this.TotalAssets += element.Value;
          });
          this.data.Liabilities.forEach(element => {
            this.Totalliabilities += element.Value;
          });
          this.data.CapitalAndPropertyRights.forEach(element => {
            this.TotalPropertyRights += element.Value;
          });
          var i = this.data.Liabilities.findIndex(x=>x.KeyEn == "other credit parties");
          if(i > -1){
            if(this.TotalAssets > (this.Totalliabilities + this.TotalPropertyRights )){
              this.data.Liabilities[i].Value = this.TotalAssets - (this.Totalliabilities + this.TotalPropertyRights );
              this.Totalliabilities += this.data.Liabilities[i].Value;
            }
            else{
              this.data.Liabilities[i].Value = (this.Totalliabilities + this.TotalPropertyRights ) - this.TotalAssets ;
              this.Totalliabilities += this.data.Liabilities[i].Value;
            }
           
          }
        })
    
    }
    Search(){
      this.balanceSheetService.search(this.search)
        .subscribe(data => {
          this.data = data;
          this.data.Assets.forEach(element => {
            this.TotalAssets += element.Value;
          });
          this.data.Liabilities.forEach(element => {
            this.Totalliabilities += element.Value;
          });
          this.data.CapitalAndPropertyRights.forEach(element => {
            this.TotalPropertyRights += element.Value;
          });
        })
    }
    exportTable(){
      var data = document.getElementById('contentToConvert');
      data.style.display = 'inline-block';
      html2canvas(data,{}).then(canvas => {
      var imgWidth = 208;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save('new-file.pdf'); // Generated PDF
      data.style.display = 'block';

    //   data.style.display = 'none';
      
    });
    }
    print(){
        let printContents, popupWin;
        printContents = document.getElementById('contentToConvert').innerHTML;
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.open();
        window.print();
       
      }

}
