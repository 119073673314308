export class CurrencyModel{
    Id?:number;
    NameEn?:string;
    NameAr?:string;
    Value?:number;
    Code?:string;
    IsMain ?:boolean;

    constructor(obj?:CurrencyModel){
        obj = obj? obj:{};
        this.Id = obj.Id? obj.Id:0;
        this.NameEn = obj.NameEn? obj.NameEn:'';
        this.NameAr = obj.NameAr? obj.NameAr:'';
        this.Code = obj.Code? obj.Code:'';
        this.Value = obj.Value? obj.Value:0;
        this.IsMain = obj.IsMain? obj.IsMain:false;
    }
}