
export class CountryBasicModel{
        Id?: number;
        NameAr?: string;
        NameEn?: string;
        CodeTaxTabel?: string;
        IsPosted?: boolean;
        
        constructor(item?: CountryBasicModel) {
            item = item ? item : {};
            this.Id = item.Id ? item.Id : 0;
            this.NameAr = item.NameAr ? item.NameAr : '';
            this.NameEn = item.NameEn ? item.NameEn : '';
            this.CodeTaxTabel = item.CodeTaxTabel ? item.CodeTaxTabel : '';
            this.IsPosted = item.IsPosted ? item.IsPosted : false;
        }
    }