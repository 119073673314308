

export class WarehouseItemModel{
    Id?: number;
    WarehouseDataId?:number;
    ItemId?: number;  
    WarehouseName?:string; 
    Quantity?: number;  
    Alert?: number;  

    constructor(item?:WarehouseItemModel){
        item = item? item:{};
        this.Id = item.Id? item.Id:0;
        this.WarehouseDataId = item.WarehouseDataId? item.WarehouseDataId:0;
        this.ItemId = item.ItemId? item.ItemId:0;
        this.WarehouseName = item.WarehouseName? item.WarehouseName:'';      
        this.Alert = item.Alert? item.Alert:0;
        this.Quantity = item.Quantity? item.Quantity:0;
    
    }

}
