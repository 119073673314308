<div class="row mb-12">
    <div class="col-md-12 col-lg-12 col-12 mb-12">
      <div class="card"></div>
    </div>
  </div>
  <div class="row" *ngIf="mainForm">
    <div class="col-12">
      <div class="card mb-4">
        <div class="card-body">
          <strong style="margin-left:10px" >  
            <span class="badge badge-pill badge-info mb-1 " style="background-color: #ffc107;" *ngIf="modelObject.IsActive==true">{{'Active' | translate}}</span>
            <span class="badge badge-pill badge-danger mb-1 " *ngIf="modelObject.IsActive!=true">{{'NotActive' | translate}}</span>
           </strong>
          <h5 class="float-left">{{ "Create Price List" | translate }}</h5>
          <div class="top-right-button-container text-zero float-right">
            <button  class="top-right-button btn btn-primary btn-lg mr-1"
            (click)="goBack()" >
            {{ "Back" | translate }}
          </button>
            <!-- <button
              type="button"
              (click)="Save()"
              [disabled]="mainForm.invalid"
              class="top-right-button btn btn-primary btn-lg mr-1"
            >
              {{ "Save" | translate }}
            </button> -->
           
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mb-12">
    <div class="col-md-12 col-lg-12 col-12 mb-12">
      <div class="card">
        <tabset class="card-tabs" [justified]="true">
          <tab heading="{{ 'Price List' | translate }}" class="card-body">
            <!-- <h6 class="mb-4">{{ "Basic Data" | translate }}</h6> -->
            <div class="modal-body">
            

                <form class="md-float-material form-material" [formGroup]="mainForm">
                    <div class="row">
                        <div class="col-md-6">
                          <label for="inputState">{{
                            "NameAr" | translate
                          }}</label>
                          <input
                          
                            class="form-control"
                            formControlName="NameAr"
                            rows="2"
                            placeholder='{{"NameAr" | translate}}'
                            readonly
                          >
                          <div class="messages text-danger"
                            *ngIf=" (mainForm.get('NameAr').hasError('required') || mainForm.get('NameAr').invalid) && ( mainForm.get('NameAr').dirty ||  mainForm.get('NameAr').touched)">
                            {{"NameArRequired" | translate}}</div>
                        </div>
                        <div class="col-md-6">
                            <label for="inputState">{{
                              "NameEn" | translate
                            }}</label>
                            <input 
                              class="form-control"
                              formControlName="NameEn"
                              rows="2"
                              placeholder='{{"NameEn" | translate}}'
                            readonly

                            >
                            <div class="messages text-danger"
                              *ngIf=" (mainForm.get('NameEn').hasError('required') || mainForm.get('NameEn').invalid) && ( mainForm.get('NameEn').dirty ||  mainForm.get('NameEn').touched)">
                              {{"NameEnRequired" | translate}}</div>
                          </div>
                      </div>
                      <div class="row">
                        
                      </div>
                    <!-- <div class="itemCheck mb-0 custom-checkbox custom-control"
                         style="display: inline-block;">
                     <input type="checkbox" class="custom-control-input"
                       [id]="'valueCheck'" 
                       formControlName="IsActive"
                       readonly
                       >  
                                                                                      
                      <label class="custom-control-label"
                       [for]="'valueCheck'">{{"Active"|translate}}
                      </label>
                    </div> -->
                    
                </form>
            </div>
          </tab>
        </tabset>
        <div style="text-align: center;"> 
            <!-- <button  class=" btn btn-primary btn-lg mr-1"
            >
            {{ "Add Items" | translate }}
          </button> -->
        </div>
        <div></div>
        <tabset class="card-tabs" [justified]="true">
                <div class="modal-body">                    
            <div >               
                <table class="table">
                  <th></th>
                    <thead class="thead-light">

                      <th>{{"Items" | translate}}</th>
                      <th>{{"Sale Price" | translate}}</th>
                      <th></th>
                      <th></th>
                      \
                    </thead>
                  <tbody>
                   
       
                    <tr *ngFor="let Items of modelObject.Items">   
                      <td>
                          <label >{{ lang === "ar-EG" ? Items.PriceListNameAr : Items.PriceListNameEn }}</label>
                    </td>  
                    <td>
                      <label >{{Items.SalePrice}}</label>
                </td>  
                      
                     
                  
                      
                    </tr>
                   <tr *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                    type="text" > 
      
                    <!-- <td style="text-align: center; vertical-align: middle;"> -->
                        <!-- <i class="simple-icon-plus"
                            [ngClass]="!(ItemsPriceList.invalid)?'simple-icon-plus':'simple-icon-plus inactive-icon'"
                            (click)="!(ItemsPriceList.invalid||tableEngine.currentAction==='loading')?AddonClick():''"
                            style="font-size: 20px; font-weight: bolder; cursor: pointer; color: #17A2B8;"></i> -->
                    <!-- </td> -->
                </tr>
                  </tbody>
                </table>
              </div>

            </div>
           
          
        </tabset>

      </div>
    </div>
  </div>
  