import { ExpensesModel } from "./expense_definaition_data_modle";


const data: ExpensesModel[] = [
  {
    Id:1,
    expensesNumber:1,
    DescriptionAr:"لقد وقعنا فى الفخ",
    DescriptionEn:"lakad wakaana fe el fakaa",
    distributionMethod:"unknow just string now",
    LinkedAccountDebit:"unknow just string now"
  ,isClearance:false
  },
  {
    Id:2,
    expensesNumber:2,
    DescriptionAr:"لقد وقعنا فى الفخ",
    DescriptionEn:"unknow just string now",
    distributionMethod:"unknow just string now",
    LinkedAccountDebit:"unknow just string now"
    ,isClearance:true
  },  {
    Id:3,
    expensesNumber:3,
    DescriptionAr:"لقد وقعنا فى الفخ",
    DescriptionEn:"lakad wakaana fe el fakaa",
    distributionMethod:"unknow just string now",
    LinkedAccountDebit:"unknow just string now"
    ,isClearance:false

  },  {
    Id:4,
    expensesNumber:4,
    DescriptionAr:"لقد وقعنا فى الفخ",
    DescriptionEn:"lakad wakaana fe el fakaa",
    distributionMethod:"unknow just string now",
    LinkedAccountDebit:"unknow just string now"
    ,isClearance:false

  },  {
    Id:5,
    expensesNumber:5,
    DescriptionAr:"لقد وقعنا فى الفخ",
    DescriptionEn:"lakad wakaana fe el fakaa",
    distributionMethod:"unknow just string now",
    LinkedAccountDebit:"unknow just string now"
    ,isClearance:true
  },  {
    Id:6,
    expensesNumber:6,
    DescriptionAr:"لقد وقعنا فى الفخ",
    DescriptionEn:"lakad wakaana fe el fakaa",
    distributionMethod:"unknow just string now",
    LinkedAccountDebit:"unknow just string now"
    ,isClearance:false
  },{
    Id:7,
    expensesNumber:7,
    DescriptionAr:"لقد وقعنا فى الفخ",
    DescriptionEn:"lakad wakaana fe el fakaa",
    distributionMethod:"unknow just string now",
    LinkedAccountDebit:"unknow just string now"
    ,isClearance:false
  },{
    Id:8,
    expensesNumber:8,
    DescriptionAr:"لقد وقعنا فى الفخ",
    DescriptionEn:"lakad wakaana fe el fakaa",
    distributionMethod:"unknow just string now",
    LinkedAccountDebit:"unknow just string now"
    ,isClearance:false
  },{
    Id:9,
    expensesNumber:9,
    DescriptionAr:"لقد وقعنا فى الفخ",
    DescriptionEn:"lakad wakaana fe el fakaa",
    distributionMethod:"unknow just string now",
    LinkedAccountDebit:"unknow just string now"
    ,isClearance:false
  },{
    Id:10,
    expensesNumber:10,
    DescriptionAr:"لقد وقعنا فى الفخ",
    DescriptionEn:"lakad wakaana fe el fakaa",
    distributionMethod:"unknow just string now",
    LinkedAccountDebit:"unknow just string now"
    ,isClearance:false
  },{
    Id:11,
    expensesNumber:11,
    DescriptionAr:"لقد وقعنا فى الفخ",
    DescriptionEn:"lakad wakaana fe el fakaa",
    distributionMethod:"unknow just string now",
    LinkedAccountDebit:"unknow just string now"
    ,isClearance:false
  },{
    Id:12,
    expensesNumber:12,
    DescriptionAr:"لقد وقعنا فى الفخ",
    DescriptionEn:"lakad wakaana fe el fakaa",
    distributionMethod:"unknow just string now",
    LinkedAccountDebit:"unknow just string now"
    ,isClearance:false
  },{
    Id:13,
    expensesNumber:13,
    DescriptionAr:"لقد وقعنا فى الفخ",
    DescriptionEn:"lakad wakaana fe el fakaa",
    distributionMethod:"unknow just string now",
    LinkedAccountDebit:"unknow just string now"
    ,isClearance:false
  },{
    Id:14,
    expensesNumber:14,
    DescriptionAr:"لقد وقعنا فى الفخ",
    DescriptionEn:"lakad wakaana fe el fakaa",
    distributionMethod:"unknow just string now",
    LinkedAccountDebit:"unknow just string now"
    ,isClearance:false
  },{
    Id:15,
    expensesNumber:15,
    DescriptionAr:"لقد وقعنا فى الفخ",
    DescriptionEn:"lakad wakaana fe el fakaa",
    distributionMethod:"unknow just string now",
    LinkedAccountDebit:"unknow just string now"
    ,isClearance:false
  },
];

export default data;
