export class ConfigPoSModel {
    Id?: number;
    Code?: number;
    DefaultClientId?: number;
    DefaultTemplateId?: number;
    DefaultPaymentMethodId?: number;
    EnableDiscountLimit?: boolean;
    DiscountLimit?: number;
    EnableAutoCodes?: boolean;
    StartingCode?: number;
    EnableAutoCodesForCashRegisiters?: boolean;
    StartingCodeCashRegisiters?: number;
    EnableAutoCodesForCashiers?: boolean;
    StartingCodeCashiers?: number;
    EnableAutoCodesForWorkShifts?: boolean;
    StartingCodeWorkShifts?: number;
    StartingCodeOrders?: number;
    StartingCodeSessions?: number;
    ActivePaymentMethods?: string;
    ActiveItems?: string;
    EnableDigitButtons?: boolean;
    EnableItemImages?: boolean;
    EnableAccountingPerInvoice?: boolean;
    EnableAddingItemsViaSerial?: boolean;
    EnableDelayedPayment?: boolean;
    EnableAutoSettlement?: boolean;
    DefaultEarningsAccountId?: number;
    DefaultLossesAccountId?: number;
    constructor(obj?: ConfigPoSModel) {
        obj = obj ? obj : {};
        this.Id = obj.Id ? obj.Id : 0;
        this.Code = obj.Code ? obj.Code : 0;
        this.DefaultClientId = obj.DefaultClientId ? obj.DefaultClientId : 0;
        this.DefaultTemplateId = obj.DefaultTemplateId ? obj.DefaultTemplateId : 0;
        this.DefaultPaymentMethodId = obj.DefaultPaymentMethodId ? obj.DefaultPaymentMethodId : 0;
        this.EnableDiscountLimit = obj.EnableDiscountLimit ? obj.EnableDiscountLimit : false;
        this.DiscountLimit = obj.DiscountLimit ? obj.DiscountLimit : 0;
        this.EnableAutoCodesForCashRegisiters = obj.EnableAutoCodesForCashRegisiters ? obj.EnableAutoCodesForCashRegisiters : false;
        this.StartingCodeCashRegisiters = obj.StartingCodeCashRegisiters ? obj.StartingCodeCashRegisiters : 0;
        this.EnableAutoCodesForCashiers = obj.EnableAutoCodesForCashiers ? obj.EnableAutoCodesForCashiers : false;
        this.StartingCodeCashiers = obj.StartingCodeCashiers ? obj.StartingCodeCashiers : 0;
        this.EnableAutoCodesForWorkShifts = obj.EnableAutoCodesForWorkShifts ? obj.EnableAutoCodesForWorkShifts : false;
        this.StartingCodeWorkShifts = obj.StartingCodeWorkShifts ? obj.StartingCodeWorkShifts : 0;
        this.StartingCodeOrders = obj.StartingCodeOrders ? obj.StartingCodeOrders : 0;
        this.StartingCodeSessions = obj.StartingCodeSessions ? obj.StartingCodeSessions : 0;
        this.ActivePaymentMethods = obj.ActivePaymentMethods ? obj.ActivePaymentMethods : '';
        this.ActiveItems = obj.ActiveItems ? obj.ActiveItems : '';
        this.EnableDigitButtons = obj.EnableDigitButtons ? obj.EnableDigitButtons : false;
        this.EnableItemImages = obj.EnableItemImages ? obj.EnableItemImages : false;
        this.EnableAccountingPerInvoice = obj.EnableAccountingPerInvoice ? obj.EnableAccountingPerInvoice : false;
        this.EnableAddingItemsViaSerial = obj.EnableAddingItemsViaSerial ? obj.EnableAddingItemsViaSerial : false;
        this.EnableDelayedPayment = obj.EnableDelayedPayment ? obj.EnableDelayedPayment : false;
        this.EnableAutoSettlement = obj.EnableAutoSettlement ? obj.EnableAutoSettlement : false;
        this.DefaultEarningsAccountId = obj.DefaultEarningsAccountId ? obj.DefaultEarningsAccountId : 0;
        this.DefaultLossesAccountId = obj.DefaultLossesAccountId ? obj.DefaultLossesAccountId : 0;
    }

}