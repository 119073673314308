import { ActivatedRoute, Router } from '@angular/router';
import { ContractorWorkItemModel } from './../../models/contractor-work-item.model';
import { ContractorModel } from './../../models/contractor.model';
import { WorkItemModel } from './../../models/work-item.model';
import { ClientModel } from './../../../../views/invoice/clients/ClientModel';
import { ClientService } from './../../../../invoices/clients/ClientService';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ProjectModel } from '../../models/project.model';
import questionData, { IQuestion } from 'src/app/data/questions';
import { ProjectService } from '../../projects.service';
@Component({
    selector: 'create-project',
    templateUrl: 'create-project.component.html',
    styleUrls: ['create-project.component.scss']
})
export class CreateProjectComponent implements OnInit {
    Form:FormGroup;
    project:ProjectModel={};
    clients:ClientModel[]=[];
    questions: IQuestion[] = questionData;
    workItems:WorkItemModel[]=[];
    selectedWorkItems:WorkItemModel[]=[];
    openGrid = false;
    Contractors:ContractorModel[]=[];
    SelectedContractors:ContractorModel[]=[];
    Id=0;
    columnDefs = [{ field: "Code" },{ field: "ParentAr" }, { field: "NameAr" }, { field: "UnitAr" }, { field: "Cost" }];
    constructor(public router:Router, 
        private fb:FormBuilder,
        private clientService:ClientService,
        private projectService:ProjectService,
        private route: ActivatedRoute
        ){
            this.Id = + route.snapshot.paramMap.get('Id');
        }
    createForm():FormGroup{
        return this.fb.group({
            Id:[this.project.Id],
            NameAr:[this.project.NameAr,Validators.required],
            NameEn:[this.project.NameEn,Validators.required],
            ClientId:[this.project.ClientId,Validators.required],
            Code:[this.project.Code,Validators.required],
            Description:[this.project.Description],
            EndDate:[this.project.EndDate,Validators.required],
            ProjectAmount:[this.project.ProjectAmount,Validators.required],
            StartDate:[this.project.StartDate,Validators.required],
        })
    }
    public lang;
    rowData:any[]=[];
    ngOnInit(): void {
        if(this.Id > 0)
        {
            this.lang = localStorage.getItem('theme_lang');
            this.clientService.getAll()
            .subscribe(data => {
                this.clients = data;
                this.projectService.getAllContractors()
                .subscribe(data => {
                    this.Contractors = data;
                    this.Contractors.forEach(element => {
                        element.SelectedContractorWorkItems = [];
                    });
                    this.projectService.getAllWorkItem()
                    .subscribe(data => {
                        this.workItems = data;
                        this.workItems.forEach(element => {
                            var item = {Code : element.Code,ParentAr:element.ParentAr,NameAr:element.NameAr,UnitAr:element.UnitAr,Cost:element.Cost};
                            this.rowData.push(item);
                            this.projectService.getById(this.Id)
                            .subscribe(project => {
                                debugger;
                                this.project = new ProjectModel();
                                this.project = project;
                                this.SelectedContractors=this.project.Contractors ;
                                this.selectedWorkItems  =this.project.WorkItems   ;
                                this.Form = this.createForm();
                            })
                        });
                    })
                })
           
            })
       
      
        }
        else{
            this.lang = localStorage.getItem('theme_lang');
            this.clientService.getAll()
            .subscribe(data => {
                this.clients = data;
                this.project = new ProjectModel();
                this.Form = this.createForm();
            })
            this.projectService.getAllContractors()
            .subscribe(data => {
                this.Contractors = data;
                this.Contractors.forEach(element => {
                    element.SelectedContractorWorkItems = [];
                });
            })
            this.projectService.getAllWorkItem()
            .subscribe(data => {
                this.workItems = data;
                this.workItems.forEach(element => {
                    var item = {Code : element.Code,ParentAr:element.ParentAr,NameAr:element.NameAr,UnitAr:element.UnitAr,Cost:element.Cost};
                    this.rowData.push(item);
                    
                });
            })
        }
    }
    selectedBusinessItemId = 0;
    selectWorkItem(){
        var i = this.workItems.findIndex(x=>x.Id == this.selectedBusinessItemId);
        if(i >= 0){
            var item = this.workItems[i];
            this.selectedWorkItems.push(item);
            this.workItems.splice(i,1);
            this.selectedBusinessItemId = 0;
        }
 
      
    }
    delectWorkItem(item:WorkItemModel){
        this.workItems.push(item);
        var i = this.selectedWorkItems.findIndex(x=>x.Id == item.Id);
        if(i >= 0){
            this.selectedWorkItems.splice(i,1);
            this.selectedBusinessItemId = 0;

        }
    }
    selectedContractorId = 0;
    selectContractor(){
        var i = this.Contractors.findIndex(x=>x.Id == this.selectedContractorId);
        if(i >= 0){
            var item = this.Contractors[i];
            this.SelectedContractors.push(item);
            this.Contractors.splice(i,1);
            this.selectedContractorId = 0;
        }
    }
    selectedContractorBusinessItemId = 0;
    selectContractorWorkItem(Contractor:ContractorModel){
        debugger;
        var i = Contractor.ContractorWorkItems.findIndex(x=>x.Id == this.selectedContractorBusinessItemId);
        if(i >= 0){
            var item = Contractor.ContractorWorkItems[i];
            Contractor.SelectedContractorWorkItems.push(item);
            Contractor.ContractorWorkItems.splice(i,1);
            this.selectedContractorBusinessItemId = 0;
        }
    }
    deleteContractorWorkItem(Contractor:ContractorModel,item:ContractorWorkItemModel){
        Contractor.ContractorWorkItems.push(item);
        var i = Contractor.SelectedContractorWorkItems.findIndex(x=>x.Id == item.Id);
        if(i >= 0){
            Contractor.SelectedContractorWorkItems.splice(i,1);
            this.selectedContractorBusinessItemId = 0;

        }
    }
    Save(){
        debugger;
        if(this.Id > 0){
            this.project = this.Form.getRawValue();
            this.project.Contractors = this.SelectedContractors;
            this.project.WorkItems= this.selectedWorkItems;
            this.projectService.update(this.project)
            .subscribe(data => {
                this.router.navigate(['app/aio/Construction/projects']);
            })
        }
        else{
            this.project = this.Form.getRawValue();
            this.project.Contractors = this.SelectedContractors;
            this.project.WorkItems= this.selectedWorkItems;
            this.projectService.insert(this.project)
            .subscribe(data => {
                this.router.navigate(['app/aio/Construction/projects']);

            })
        }
    
    }
}
