import { Component, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { StoreDataService } from '../store-data/store-data.service';
import { CountryCodeService } from './Helper/./Service/country-code.service';
import { CityCodeService } from './Helper/./Service/city-code.service';
import { GovernorateCodeService } from './Helper/./Service/governorate-code.service';
import { TableEngine } from 'src/app/invoices/TableEngine';
import { StoreDataModel } from './StoreDataModel';
import { SidePopupComponent } from 'src/app/views/app/aio/shared/side-popup/side-popup.component';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CityCodeModel } from './Helper/CityCodeModel';
import { CountryCodeModel } from './Helper/CountryCodeModel';
import { GovernorateCodeModel } from './Helper/GovernorateCodeModel';


@Component({
    // moduleId: module.id,
    selector: 'store-data',
    templateUrl: 'store-data.component.html',
    styleUrls: ['store-data.component.scss']
})
export class StoreDataComponent {

    constructor(private formBuilder: FormBuilder, private modelService: StoreDataService, private renderer: Renderer2,
        private notifications: NotificationsService, private translate: TranslateService
        , private cityCodeService: CityCodeService, private countryService: CountryCodeService
        , private governorateCodeService: GovernorateCodeService) {
    }
    tableEngine = new TableEngine(this.translate, this.notifications);
    currentScreen: any = 'Table';
    tableView: any = true;
    CountryCodeList : CountryCodeModel[]=[];
    StoreDataList: StoreDataModel[] = [];
    cityCodeList: CityCodeModel[] = [];  
    GovernorateCodeList: GovernorateCodeModel[] = [];
    translationName: string = 'Store'
    sortableKeys = [];
    searchableKeys = ['NameEn', 'NameAr'];
    modelObject: StoreDataModel = new StoreDataModel();

    ogData: StoreDataModel[] = [];
    mainForm: FormGroup = new FormGroup({
        Id: new FormControl(),
        // CompanyDataId: new FormControl(),
        // BranchesDataId: new FormControl(),
        NameAr: new FormControl(),
        NameEn: new FormControl(),
        CountryCodeId: new FormControl(),
        GovernorateCodeId: new FormControl(),
        CityCodeId: new FormControl(),
        StoreAddress: new FormControl(),
        StoreMobil: new FormControl(),
        StorePhone: new FormControl(),
        StoreFax: new FormControl(),
        StoreWhatsapp: new FormControl(),
        StoreEmail: new FormControl(),
        StoreManager: new FormControl(),
        Description: new FormControl(),

    });



    declare lang: any;
    declare itemOrder: any;
    currentAction = '';
    savedPage = 0;
    currentPage = 1;
    deletionList: any[] = [];

    @ViewChild('sideModalRef', { static: true }) sideModalRef: SidePopupComponent = null;
    @ViewChild('delModal', { static: true }) delModal: ModalDirective;
    @ViewChild('smallModal', { static: true }) smallModal: ModalDirective = null;
    formPresentationModal: any;
    ngOnInit(): void {
        this.getAllCountryCode();
     
        this.lang = localStorage.getItem('theme_lang');
        this.itemOrder = { label: 'StoreName', property: this.lang === 'ar-EG' ? 'NameAr' : 'NameEn' };
        this.sortableKeys = [
            { label: 'StoreName', property: this.lang === 'ar-EG' ? 'NameAr' : 'NameEn' },
            { label: 'StoreCompanyDataId', property: 'CompanyDataId' },
            { label: 'StoreBranchesDataId', property: 'BranchesDataId' },
            { label: 'CountryCodeId', property: 'CountryCodeId' },
            { label: 'StoreGovernorateCodeId', property: 'GovernorateCodeId' },
            { label: 'StoreCityCodeId', property: 'CityCodeId' },
            { label: 'StoreAddress', property: 'StoreAddress' },
            { label: 'StoreMobil', property: 'StoreMobil' },
            { label: 'StorePhone', property: 'StorePhone' },
            { label: 'StoreFax', property: 'StoreFax' },
            { label: 'StoreWhatsapp', property: 'StoreWhatsapp' },
            { label: 'StoreEmail', property: 'StoreEmail' },
            { label: 'StoreManager', property: 'StoreEmail' },
            { label: 'StoreDescription', property: 'Description' },

        ];
        this.loadServices();

        this.tableEngine.loadTableEngine(this.ogData, this.modelService, this.modelObject,
            this.searchableKeys, this.sortableKeys, this.mainForm, this.translationName, this.itemOrder,
            this.sideModalRef, this.delModal, this.smallModal, this.lang
        )


    }

    addedItemList: StoreDataModel[] = [];

    showModal(Id, action) {
        if (action === 'Add') {
            this.modelObject = new StoreDataModel();
            this.tableEngine.showAddNewModalRef(this.createForm, this.formBuilder)
        }
        else if (action === 'Edit') {

            this.tableEngine.showEditModalRef(Id, this.createForm, this.formBuilder)

        }
        else if (action === 'Info') {
            this.tableEngine.showInfoModalRef(Id, this.createForm, this.formBuilder)
        }
        this.tableView = false;
        this.modelObject = this.tableEngine.modelObject;
        this.mainForm = this.tableEngine.mainForm;
    }


    createForm(modelObject, formBuilder): FormGroup {
        return formBuilder.group({
            Id: [modelObject.Id >= 0 ? modelObject.Id : 0],
            // CompanyDataId: [modelObject.CompanyDataId, Validators.compose([Validators.required,Validators.minLength(2)])],
            // BranchesDataId: [modelObject.BranchesDataId, Validators.compose([Validators.required, Validators.minLength(2)])],
            NameEn: [modelObject.NameEn, Validators.compose([Validators.required, Validators.minLength(2)])],
            NameAr: [modelObject.NameAr, Validators.compose([Validators.required, Validators.pattern(/^[\u0621-\u064A\u0660-\u06690-9]+([\s]*[\u0621-\u064A\u0660-\u06690-9]+)*$/)])],

            CountryCodeId: [modelObject.CountryCodeId, Validators.compose([Validators.required])],
            GovernorateCodeId: [modelObject.GovernorateCodeId, Validators.compose([Validators.required])],
            CityCodeId: [modelObject.CityCodeId, Validators.compose([Validators.required])],
            StoreAddress: [modelObject.StoreAddress, Validators.compose([Validators.required, Validators.minLength(2)])],

            StoreMobil: [modelObject.StoreMobil, Validators.compose([Validators.required, Validators.pattern(/^\d{11}$/)])],
            StorePhone: [modelObject.StorePhone, Validators.compose([Validators.required, Validators.pattern(/^\d{11}$/)])],
            StoreFax: [modelObject.StoreFax, Validators.compose([Validators.minLength(2)])],
            StoreWhatsapp: [modelObject.StoreWhatsapp, Validators.compose([])],
            StoreEmail: [modelObject.StoreEmail, Validators.compose([Validators.required, Validators.minLength(2)])],
            StoreManager: [modelObject.StoreManager, Validators.compose([])],
            Description: [modelObject.Description, Validators.compose([Validators.minLength(2)])],
        });
    }

    addObject() {
        debugger;
        this.currentAction = 'loading';
        this.tableEngine.savedPage = this.tableEngine.currentPage;
        this.modelObject = new StoreDataModel();
        this.modelObject.Id = 0;
        this.modelObject.CompanyDataId = 0;
        this.modelObject.BranchesDataId = 0;
        this.modelObject.NameEn = this.mainForm.getRawValue().NameEn;
        this.modelObject.NameAr = this.mainForm.getRawValue().NameAr;
        this.modelObject.CountryCodeId = this.mainForm.getRawValue().CountryCodeId;
        this.modelObject.GovernorateCodeId = this.mainForm.getRawValue().GovernorateCodeId;
        this.modelObject.CityCodeId = this.mainForm.getRawValue().CityCodeId;
        this.modelObject.StoreAddress = this.mainForm.getRawValue().StoreAddress;
        this.modelObject.StoreMobil = this.mainForm.getRawValue().StoreMobil;
        this.modelObject.StorePhone = this.mainForm.getRawValue().StorePhone;

        this.modelObject.StoreFax = this.mainForm.getRawValue().StoreFax;
        this.modelObject.StoreWhatsapp = this.mainForm.getRawValue().StoreWhatsapp;
        this.modelObject.StoreEmail = this.mainForm.getRawValue().StoreEmail;
        this.modelObject.StoreManager = 0;
        this.modelObject.Description = this.mainForm.getRawValue().Description;
        this.executeAddObject();
        // this.getAllCountryCode();
    }

    executeAddObject() {
debugger;
        this.modelService.insert(this.modelObject).subscribe(result => {
            if (result === true) {
                this.tableEngine.loadData();
                this.currentAction = '';
                this.tableEngine.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyAdded"));
                if (this.sideModalRef === undefined) {
                    if (this.smallModal != undefined) {
                        this.tableEngine.smallModal.hide();
                    }
                    else {
                        this.tableView = this.prevAction === 'AddThenNew' ? false : true;
                    }
                }
                else {
                    this.tableEngine.sideModalRef.modalRef.hide();
                }
            }
            else {
                this.tableEngine.toastError(this.translate.instant("Error"), this.translate.instant("FailedAdd"));
                this.currentAction = '';
            }
        })
    }
    editObject() {
        this.currentAction = 'loading';
        this.tableEngine.savedPage = this.tableEngine.currentPage;

        let tempObj = new StoreDataModel({
            Id: this.modelObject.Id,
            CompanyDataId: this.mainForm.getRawValue().CompanyDataId,
            BranchesDataId: this.mainForm.getRawValue().BranchesDataId,
            NameAr: this.mainForm.getRawValue().NameAr,
            NameEn: this.mainForm.getRawValue().NameEn,
            CountryCodeId: this.mainForm.getRawValue().CountryCodeId,
            GovernorateCodeId: this.mainForm.getRawValue().GovernorateCodeId,
            CityCodeId: this.mainForm.getRawValue().CityCodeId,
            StoreAddress: this.mainForm.getRawValue().StoreAddress,
            StoreMobil: this.mainForm.getRawValue().StoreMobil,
            StorePhone: this.mainForm.getRawValue().StorePhone,
            StoreFax: this.mainForm.getRawValue().StoreFax,
            StoreWhatsapp: this.mainForm.getRawValue().StoreWhatsapp,
            StoreEmail: this.mainForm.getRawValue().StoreEmail,
            StoreManager: this.mainForm.getRawValue().StoreManager,
            Description: this.mainForm.getRawValue().Description,


            // PostalCode : this.mainForm.getRawValue().PostalCode
        });

        if (JSON.stringify(tempObj) != JSON.stringify(this.modelObject)) {
            this.executeEditObject(tempObj);
        }
        else {
            if (this.sideModalRef === undefined) {

                this.tableEngine.smallModal.hide();

            } else {

                this.tableEngine.sideModalRef.modalRef.hide();
            }
        }
    }
    executeEditObject(Object) {
        this.modelService.update(Object).subscribe(result => {
            if (result === true) {
                this.tableEngine.loadData();
                this.currentAction = '';
                this.tableEngine.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyEdited"));

                if (this.sideModalRef === undefined) {
                    if (this.smallModal != undefined) {
                        this.tableEngine.smallModal.hide();
                    }
                    else {
                        this.tableView = true;
                    }
                }
                else {
                    this.tableEngine.sideModalRef.modalRef.hide();
                }
            }
            else {
                this.tableEngine.toastError(this.translate.instant("Error"), this.translate.instant("FailedEdit"));
                this.currentAction = '';
            }
        })
    }

    //Deletion methods:
    //Customize if needed.
    //Pass the target object as parameter if the target API can only delete singular objects and not lists.
    deleteObject() {
        // this.savedPage = this.currentPage;
        // //prevent multiclicks. re enable after API response
        // this.currentAction = 'loading';
        // this.executeDeleteObject(tempObj);
        let tempObj = new StoreDataModel({
            Id: this.modelObject.Id,
            CompanyDataId: this.mainForm.getRawValue().CompanyDataId,
            BranchesDataId: this.mainForm.getRawValue().BranchesDataId,
            NameAr: this.mainForm.getRawValue().NameAr,
            NameEn: this.mainForm.getRawValue().NameEn,
            CountryCodeId: this.mainForm.getRawValue().CountryCodeId,
            GovernorateCodeId: this.mainForm.getRawValue().GovernorateCodeId,
            CityCodeId: this.mainForm.getRawValue().CityCodeId,
            StoreAddress: this.mainForm.getRawValue().StoreAddress,
            StoreMobil: this.mainForm.getRawValue().StoreMobil,
            StorePhone: this.mainForm.getRawValue().StorePhone,
            StoreFax: this.mainForm.getRawValue().StoreFax,
            StoreWhatsapp: this.mainForm.getRawValue().StoreWhatsapp,
            StoreEmail: this.mainForm.getRawValue().StoreEmail,
            StoreManager: this.mainForm.getRawValue().StoreManager,
            Description: this.mainForm.getRawValue().Description,


            // PostalCode : this.mainForm.getRawValue().PostalCode
        });
        this.executeDeleteObject(tempObj);

        // if (JSON.stringify(tempObj) == JSON.stringify(this.modelObject)) {
        //     this.executeDeleteObject(tempObj);
        // }
        // else {
            
        //     // if (this.sideModalRef === undefined) {

        //     //     this.tableEngine.smallModal.hide();

        //     // } else {

        //     //     this.tableEngine.sideModalRef.modalRef.hide();
        //     // }
        // }
    }

    //Same here...
    executeDeleteObject(Object) {

        // this.modelService.delete(Object).subscribe()
        this.modelService.delete(Object).subscribe(result => {
            if (result === true) {
                this.tableEngine.loadData();
                this.currentAction = '';
                this.tableEngine.finalizeDeletion();
            }
            else {
                this.tableEngine.toastError(this.translate.instant("Error"), this.translate.instant("FailedDelete"));
                this.currentAction = '';
            }
        })
    }
    ////////////////////////////////////////////////////////////////////////////
    //
    //
    //
    //
    //
    ////////////////////////////////////////////////////////////////////////////
    //  EXCLUSIVE :
    //  Specifically for this component.
    ///////////////////////////////
    //Get any additional data here
    prevAction = '';
    addAndNew() {
        this.prevAction = 'AddThenNew';
        this.addObject();
        this.showModal(null, 'Add');
    }

    getAllGovernorateCode(){
        debugger;
        var CountryId = this.mainForm.controls['CountryCodeId'].value;
        this.governorateCodeService.getAll(CountryId).subscribe(
            governorateData => {
                this.GovernorateCodeList = governorateData;
          
            }
        )
    }
    getAllCityCode() {
        debugger;
        var GovernorateId = this.mainForm.controls["GovernorateCodeId"].value;
        this.cityCodeService.getAll(GovernorateId).subscribe(
            CityData => {
                this.cityCodeList = CityData;

            }
        )
    }
      getAllCountryCode() {
        this.countryService.getAll().subscribe(
            data => {              
              this.CountryCodeList = data;              
            }
          )
        // var CountryId = this.mainForm.controls["CountryCodeId"].value;
        
      

    }
    loadServices() {

        this.currentAction = 'loading';
        this.countryService.getAll().subscribe(
            countryData => {
                this.CountryCodeList = countryData;

                this.tableEngine.loadData();

            }
        )

    }
   
    getCountryCodeById(Id: any): any {
        if (Id > 0) {
            return this.CountryCodeList.find(i => i.Id === Id);
        }
        else {
            return new CountryCodeModel();
        }
    }
    getGovernorateCodeById(Id: any): any {
        debugger;
        if (Id > 0) {
            return this.GovernorateCodeList.find(i => i.GovernorateId === Id);
        }
        else {
            return new CountryCodeModel();
        }
    }
    getCityCodeById(Id: any): any {
        debugger;

        if (Id>0){
            return this.cityCodeList.find(i => i.CityId === Id);

        }
        else{
            return new GovernorateCodeModel();
        }
    }

    ////////////////////////////////////////////////////////////////////////////
    //
    //
    //
    //
    //
    ////////////////////////////////////////////////////////////////////////////
    //  CONSTANT :
    //  Do not change.
    ///////////////////////////////
    prepChangeSort(sortKey) {
        this.tableEngine.changeSort(this.tableEngine.getSort(sortKey));
        this.itemOrder = this.tableEngine.itemOrder;
    }
}
