import { ItemsModel } from "./ItemsModel";

export class FooterModel{
    ItemBalanceId?: number;
    ItemsBalanceHeaderId?:number;
    ItemsHeaderId?: number; 
    ItemsUnitId?: number; 
    ItemQuantity?: number; 
    ItemPrice?: number; 
    ItemValue?: number;
    Items? :ItemsModel[];
    
    constructor(item?:FooterModel){
        item = item? item:{};
        this.ItemBalanceId = item.ItemBalanceId? item.ItemBalanceId:0;
        this.ItemsBalanceHeaderId = item.ItemsBalanceHeaderId? item.ItemsBalanceHeaderId:0;
        this.ItemsHeaderId = item.ItemsHeaderId? item.ItemsHeaderId:0;
        this.ItemsUnitId = item.ItemsUnitId? item.ItemsUnitId:0;
        this.ItemQuantity = item.ItemQuantity? item.ItemQuantity:0;
        this.ItemPrice = item.ItemPrice? item.ItemPrice:0;
        this.ItemValue = item.ItemValue? item.ItemValue:0;
        this.Items = item.Items? item.Items:[];
        
    
    }

}