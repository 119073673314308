export class JournalFileModel{
    Id?:number;
    JournalHeaderId ?: number;
    FileUrl ?: string;
    constructor(item ?: JournalFileModel){
        item = item ? item : {};
        this.Id = item.Id ? item.Id : 0;
        this.JournalHeaderId = item.JournalHeaderId ? item.JournalHeaderId : 0;
        this.FileUrl = item.FileUrl ? item.FileUrl : '';
    }
}