import { EmployeesManagementModel } from 'src/app/HR/Employees/EmployeesManagement/EmployeesManagementModel';
import { TaxTypesService } from 'src/app/invoices/tax-types/TaxTypesService';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { SidePopupComponent } from 'src/app/views/app/aio/shared/side-popup/side-popup.component';
import { TableEngine } from 'src/app/invoices/TableEngine';
import { ListPageHeaderComponent } from 'src/app/containers/pages/list-page-header/list-page-header.component';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { AssetsService } from './../../assets.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, Renderer2, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { AssetsModel } from '../../models/assets.model';
import { AccountNameModel } from '../../../account-name/AccountNameModel';
import { AccountsNameService } from '../../../account-name/AccountNameService';
import { CurrencyService } from 'src/app/invoices/currencies/CurrencyService';
import { CurrencyModel } from 'src/app/invoices/currencies/CurrencyModel';
import { TaxTypesModel } from 'src/app/invoices/tax-types/TaxTypesModel';
import { EmployeesManagementService } from 'src/app/HR/Employees/EmployeesManagement/employees-management.service';
import { AssetsFileModel } from '../../models/assets-files.model';
import { CostCenterService } from '../../../cost-center/services/cost-center.service';
import { CostCenterModel } from '../../../cost-center/models/cost-center.model';
import { JournalFooterCostCenterModel } from '../../../dailyrestrictions/journal-footer-cost-center.model';
import { Router } from '@angular/router';

@Component({
    selector: 'all-assets',
    templateUrl: 'all-assets.component.html',
    styleUrls: ['all-assets.component.scss']
})
export class AllAssetsComponent {
    ////////////////////////////////////////////////////////////////////////////
    //  VARYING :
    //  Modify per similar component.
    ////////////////////////////////////////////////////////////////////////////
  
    //Enable debugMode to make API modules return static data from within the service class
    debugMode: boolean = false;
    employees:EmployeesManagementModel[]=[];
    centers:CostCenterModel[]=[];
    constructor(private formBuilder: FormBuilder, private modelService: AssetsService, private renderer: Renderer2,
      private notifications: NotificationsService, private translate: TranslateService,
       private datePipe: DatePipe,
       private accountService:AccountsNameService,
       private currencyService:CurrencyService,
       private TaxTypesService:TaxTypesService,
       private employeeService:EmployeesManagementService,
       private costCenterService:CostCenterService,
       private router:Router
    ) {
    }
    currencies:CurrencyModel[]=[];
    taxes:TaxTypesModel[]=[];
    listHeader = new ListPageHeaderComponent();
  
    tableEngine = new TableEngine(this.translate, this.notifications);
    currentScreen: any = 'Table';
    tableView: any = true;
  
    translationName: string = 'Assets'
  
    sortableKeys = [];
    searchableKeys = ['Id'];
  
    modelObject: AssetsModel = new AssetsModel();
  
    ogData: AssetsModel[] = [];
    mainAccounts:AccountNameModel[]=[];
    subAccounts:AccountNameModel[]=[];
    mainForm: FormGroup = new FormGroup({
      Id                      : new FormControl(),
      Name                    : new FormControl(),
      PurchaseDate            : new FormControl(),
      ServiceStartDate        : new FormControl(),
      MainAccountId           : new FormControl(0),
      PlaceId                 : new FormControl(0),
      ProductiveAge           : new FormControl(),
      EmployeeId              : new FormControl(),
      Description             : new FormControl(),
      PurchaseAmount          : new FormControl(),
      CurrencyId              : new FormControl(),
      CashAccountId           : new FormControl(),
      Tax1Id                  : new FormControl(),
      Tax2Id                  : new FormControl(),
      ScrapValue              : new FormControl(),
      ScrapType               : new FormControl(),
      PeriodValue             : new FormControl(),
      PeriodType              : new FormControl(),
      FixedDepreciationValue  : new FormControl(),
      PeriodDepreciation      : new FormControl(),
      PricePerUnit            : new FormControl(),
      TheNameOfTheLargestUnit : new FormControl(),
      TotalParentUnits        : new FormControl(),

    });
  
    declare lang: any;
    declare itemOrder: any;
    currentAction = '';
    savedPage = 0;
    currentPage = 1;
    deletionList: any[] = [];
    selectedCostCenters:JournalFooterCostCenterModel[]=[]
    @ViewChild('sideModalRef', { static: true }) sideModalRef: SidePopupComponent = null;
    @ViewChild('delModal', { static: true }) delModal: ModalDirective=null;
    @ViewChild('smallModal', { static: true }) smallModal: ModalDirective =null;
    formPresentationModal: any;
    ngOnInit(): void {
      this.costCenterService.getAll(0)
      .subscribe(result=>{
        this.centers = result
        console.log('this.centers',this.centers)
      })
      this.employeeService.getAll()
      .subscribe(data=>{
        this.employees = data;
      })
      this.accountService.GetAllFixedAssets(0)
      .subscribe(data => {
        this.mainAccounts = data;
      })
      this.accountService.GetAllSubAccountes(0)
      .subscribe(data => {
        this.subAccounts = data;
      })
      this.currencyService.getAll()
      .subscribe(data => {
        this.currencies = data;
      })
      this.TaxTypesService.getAll()
      .subscribe(taxes =>{
        this.taxes = taxes
      })
      if (this.debugMode) {
        this.enableDebugOnAllServices();
      }
  
      this.lang = localStorage.getItem('theme_lang');
      this.itemOrder = { label: 'Name', property: 'Name' };
      this.sortableKeys = [
        { label: 'Name', property: 'Name' },
        { label: 'PurchaseDate', property: 'PurchaseDate' },
        { label: 'ServiceStartDate', property: 'ServiceStartDate' },
   
      ];
      this.tableEngine.loadTableEngine(this.ogData, this.modelService, this.modelObject,
        this.searchableKeys, this.sortableKeys, this.mainForm, this.translationName, this.itemOrder,
        this.sideModalRef, this.delModal, this.smallModal, this.lang
      )
  
      this.loadServices();
    }
  
  
    enableDebugOnAllServices() {
      //this.modelService.debugMode = true;
      // this.itemService.debugMode = true;
      //this.codingUnitService.debugMode = true;
      //this.storeDataService.debugMode = true;
      //this.supplierService.debugMode = true;
    }
  
    lastCode = 0;
    showModal(Id, action) {
      debugger;
      this.mainForm = this.createForm(this.modelObject, this.formBuilder);
  
      if (action === 'Add') {
        this.newAssetsItemInternalId = 0;
        this.checkCode();
        this.files = [];
        this.tableEngine.showAddNewModalRef(this.createForm, this.formBuilder)
      }
      else if (action === 'Edit') {
        //this.tableEngine.showEditModalRef(Id, this.createForm, this.formBuilder)
        this.modelService.getById(Id).subscribe(
          detailedObject => {
            console.log("detailed object", detailedObject);
            
            let targetIndex = this.tableEngine.ogData.findIndex(i => i.Id === Id);
            this.tableEngine.ogData[targetIndex] = detailedObject;
            this.tableEngine.showEditModalRef(Id, this.createForm, this.formBuilder)
            
            this.lastCode = this.tableEngine.modelObject.Code
            
            this.modelObject = this.tableEngine.modelObject;
            this.files = this.modelObject.Files;
            this.mainForm = this.tableEngine.mainForm;
            this.tableView = false;
  
  
          }
        )
      }
      else if (action === 'Info') {
        this.router.navigate(['app/accounts/view-asset',Id])
        // console.log("entered info");
        // this.modelService.getById(Id).subscribe(
        //   detailedObject => {
        //     console.log("detailed object", detailedObject);
            
        //     let targetIndex = this.tableEngine.ogData.findIndex(i => i.Id === Id);
        //     this.tableEngine.ogData[targetIndex] = detailedObject;
        //     this.tableEngine.showInfoModalRef(Id, this.createForm, this.formBuilder)
            
        //     this.lastCode = this.tableEngine.modelObject.Code
            
        //     this.modelObject = this.tableEngine.modelObject;
        //     this.files = this.modelObject.Files;

        //     this.mainForm = this.tableEngine.mainForm;
        //     this.tableView = false;
  
  
        //   } 
        // )
      }
      this.tableView = false;
      this.modelObject = this.tableEngine.modelObject;
      this.mainForm = this.tableEngine.mainForm;
      console.log((action + " object"), this.modelObject);
  
  
      console.log("modelObject after entering edit", this.modelObject);
    }
    currentDate='';
    createForm(modelObject, formBuilder): FormGroup {
      
      return formBuilder.group({
        Id: [modelObject.Id >= 0 ? modelObject.Id : 0],
        Name                    : [modelObject.Name                    ],
        PurchaseDate            : [modelObject.PurchaseDate            ],
        ServiceStartDate        : [modelObject.ServiceStartDate        ],
        MainAccountId           : [modelObject.MainAccountId           ],
        PlaceId                 : [modelObject.PlaceId                 ],
        ProductiveAge           : [modelObject.ProductiveAge           ],
        EmployeeId              : [modelObject.EmployeeId              ],
        Description             : [modelObject.Description             ],
        PurchaseAmount          : [modelObject.PurchaseAmount          ],
        CurrencyId              : [modelObject.CurrencyId              ],
        CashAccountId           : [modelObject.CashAccountId           ],
        Tax1Id                  : [modelObject.Tax1Id                  ],
        Tax2Id                  : [modelObject.Tax2Id                  ],
        ScrapValue              : [modelObject.ScrapValue              ],
        ScrapType               : [modelObject.ScrapType               ],
        PeriodValue             : [modelObject.PeriodValue             ],
        PeriodType              : [modelObject.PeriodType              ],
        FixedDepreciationValue  : [modelObject.FixedDepreciationValue  ],
        PeriodDepreciation      : [modelObject.PeriodDepreciation      ],
        PricePerUnit            : [modelObject.PricePerUnit            ],
        TheNameOfTheLargestUnit : [modelObject.TheNameOfTheLargestUnit ],
        TotalParentUnits        : [modelObject.TotalParentUnits        ],
        TheEndDateOfDepreciation:[modelObject.TheEndDateOfDepreciation]
      });
    }
  
    addObject() {
      this.currentAction = 'loading';
      let lastId = this.tableEngine.ogData.length>0?this.tableEngine.ogData[this.tableEngine.ogData.length - 1].Id:0;
      this.modelObject = new AssetsModel();
      //this.modelObject.Id = this.debugMode ? lastId + 1 : 0;
      this.modelObject.Id = 0;
      // let date = new Date(this.mainForm.getRawValue().Date);
      this.modelObject = this.mainForm.getRawValue();
      this.modelObject.Files = this.files;
      this.modelObject.CostCenters = this.selectedCostCenters;
      console.log(this.modelObject);
      this.executeAddObject();
    }
  
    executeAddObject() {
      this.modelService.insert(this.modelObject).subscribe(result => {
        if (result === true || result > 0) {
          this.tableEngine.loadData();
          this.currentAction = '';
          this.tableEngine.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyAdded"));
          if (this.sideModalRef === undefined) {
            if (this.smallModal != undefined) {
              this.tableEngine.smallModal.hide();
            }
            else {
              this.tableView = this.prevAction === 'AddThenNew' ? false : true;
              
              this.prevAction = '';
            }
          }
          else {
            this.tableEngine.sideModalRef.modalRef.hide();
          }
          
          if(this.prevAction != 'AddThenNew'){
            this.goBack();
          }
        }
        else {
          this.tableEngine.toastError(this.translate.instant("Error"), this.translate.instant("FailedAdd"));
          this.currentAction = '';
        }
      })
    }
  
    editObject() {
      this.currentAction = 'loading';
      this.tableEngine.savedPage = this.tableEngine.currentPage;
      //this.calculateTotalItemValue();
      //let newString = new DatePipe('en-US').transform(this.mainForm.getRawValue().Date, 'dd/MM/yyyy');
      //console.log("newstring", newString);
      
      //let date: Date = this.getFormattedDate(new DatePipe('en-US').transform(this.mainForm.getRawValue().Date, 'dd/MM/yyyy'));
      let tempObj = new AssetsModel();
      tempObj = this.mainForm.getRawValue();
      tempObj.Id = this.modelObject.Id;
      tempObj.Files = this.files;
      tempObj.CostCenters = this.selectedCostCenters;

      this.executeEditObject(tempObj);
      console.log("saved object", tempObj);
    }
  
    executeEditObject(Object) {
  
  
      this.modelService.update(Object).subscribe(result => {
        if (result === true || result > 0) {
          this.tableEngine.loadData();
          this.currentAction = '';
          this.tableEngine.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyEdited"));
  
          if (this.sideModalRef === undefined) {
            if (this.smallModal != undefined) {
              this.tableEngine.smallModal.hide();
            }
            else {
              this.tableView = true;
            }
          }
          else {
            this.tableEngine.sideModalRef.modalRef.hide();
          }
          
          this.goBack();
          
        }
        else {
          this.tableEngine.toastError(this.translate.instant("Error"), this.translate.instant("FailedEdit"));
          this.currentAction = '';
        }
      })
    }
  
    //Deletion methods:
    //Customize if needed.
    //Pass the target object as parameter if the target API can only delete singular objects and not lists.
    deleteObject() {
      this.savedPage = this.currentPage;
      //prevent multiclicks. re enable after API response
      this.currentAction = 'loading';
      this.executeDeleteObject();
    }
  
    //Same here...
    executeDeleteObject() {
      this.modelService.deleteList(this.tableEngine.deletionList).subscribe(result => {
        if (result === true || result > 0) {
          this.tableEngine.loadData();
          this.currentAction = '';
          this.tableEngine.finalizeDeletion();
        }
        else {
          this.tableEngine.toastError(this.translate.instant("Error"), this.translate.instant("FailedDelete"));
          this.currentAction = '';
        }
      })
    }
  
    
    //lastCode = 0;
    codeWasChecked = false;
    checkCode() : boolean{
      if(!(this.lastCode > 0)){
        this.codeWasChecked = false;
        console.log("bad code", this.lastCode);
        
        return this.codeWasChecked;
      }
      return this.codeWasChecked;
    }
    ////////////////////////////////////////////////////////////////////////////
    //
    //
    //
    //
    //
    ////////////////////////////////////////////////////////////////////////////
    //  EXCLUSIVE :
    //  Specifically for this component.
    ///////////////////////////////
    //Get any additional data here
    SearchFrom: Date = null ;
    SearchTo: Date= null;
  
    clearSearch() {
      this.SearchFrom= null ;
      this.SearchTo = null;
    }
  
    prevAction = '';
    addAndNew() {
      this.prevAction = 'AddThenNew';
      this.addObject();
      this.showModal(null, 'Add');
    }
  
  
    loadServices() {
      this.currentAction = 'loading';
      this.tableEngine.loadData();

  
  
    }
  
    AssetsItemForm: FormGroup = new FormGroup({
      Id: new FormControl(),
      ItemId: new FormControl(),
      //UnitId: new FormControl(),
      Quantity: new FormControl(),
      UnitCost: new FormControl(),
      TotalPrice: new FormControl(),
    });
    createAssetsItemForm(AssetsItemObject, formBuilder): FormGroup {
      return formBuilder.group({

      });
    }
    newAssetsItemInternalId = 0;
  
    currentTotal = 0;
 
    goBack(){
      console.log("back");
      
      this.modelObject = {};
      this.currentTotal = 0;
      this.currentAction = "";
      this.tableView = true;
    }
    ////////////////////////////////////////////////////////////////////////////
    //
    //
    //
    //
    //
    ////////////////////////////////////////////////////////////////////////////
    //  CONSTANT :
    //  Do not change.
    ///////////////////////////////
    prepChangeSort(sortKey) {
      this.SearchFrom ;
      this.SearchTo ;
      
      
      this.tableEngine.changeSort(this.tableEngine.getSort(sortKey));
      this.itemOrder = this.tableEngine.itemOrder;
    }
  
    getFormattedDate(dateString: any){
      let splitDate: number[] = dateString.toString().split("/");
  
      return new Date(splitDate[2], splitDate[1]-1, splitDate[0])
    }
    ///////////////////////////////////////////////////////
    
    displayMode = 'list';
    changeDisplayMode(mode): void {
      this.displayMode = mode;
    }
    itemsPerPageChange(perPage: number): void {
      this.tableEngine.paginateData(perPage, 1)
    }
    selectedCurrency = '';
    changeCurrency(){
      var i = this.currencies.findIndex(x=>x.Id == this.mainForm.controls['CurrencyId'].value);
      if(i>-1){
        this.selectedCurrency = this.currencies[i].Code
      }
    }
    getMainAccountName(accountId){
      return this.mainAccounts.findIndex(x=>x.Id == accountId)>-1?(this.lang=='ar-EG'? this.mainAccounts.find(x=>x.Id == accountId).NameAr:this.mainAccounts.find(x=>x.Id == accountId).NameEn):""
    }
    getSubAccountName(accountId){
      return this.subAccounts.findIndex(x=>x.Id == accountId)>-1?(this.lang=='ar-EG'? this.subAccounts.find(x=>x.Id == accountId).NameAr:this.subAccounts.find(x=>x.Id == accountId).NameEn):""
    }
    getTax(TaxId){
      return this.taxes.findIndex(x=>x.Id == TaxId)>-1?(this.lang=='ar-EG'? this.taxes.find(x=>x.Id == TaxId).NameAr:this.taxes.find(x=>x.Id == TaxId).NameEn):""
    }
    getCurrency(CurrencyId){
      return this.currencies.findIndex(x=>x.Id == CurrencyId)>-1?(this.lang=='ar-EG'? this.currencies.find(x=>x.Id == CurrencyId).NameAr:this.currencies.find(x=>x.Id == CurrencyId).NameEn):""
    }
    getEmployee(EmployeeId){
      return  this.employees.find(x=>x.Id == EmployeeId).Name;
    }
    getPlace(PlaceId){
      return "" //this.lang=='ar-EG'? this..find(x=>x.Id == PlaceId).NameAr:this.mainAccounts.find(x=>x.Id == accountId).NameEn
    }

    public editEnabled = true;
    public fileUrl: string ='';
    files:AssetsFileModel[]=[];
    setFileToList(){
        var file = new AssetsFileModel();
        file.AssetId = this.modelObject.Id ;
        file.FileUrl = this.fileUrl;
        file.Id = 0;
        this.files.push(file);
    }
    deleteFile(file){
        var i = this.files.indexOf(file);
        if(i>-1){
            this.files.splice(i,1);
        }
    }
    costCenterId=0;
    selectCenter(){
      var i = this.centers.findIndex(x=>x.Id == this.costCenterId);
      if(i>-1){
        var selected = new JournalFooterCostCenterModel();
        selected.CostCenterId = this.centers[i].Id;
        selected.Amount = 0;
        selected.Id = 0;
        selected.JournalFooterId = 0;
        selected.Percent = 0;
        this.selectedCostCenters.push(selected);
        this.costCenterId = 0;
      }
    }
    getCostName(id){
      return this.centers.find(x=>x.Id == id).NameAr
    }

    changePercent(cost,$event){
      debugger;
      cost.Percent = + $event.target.value;
      if(cost.Percent > 100){
        cost.Percent = 100
      }
      var amount = this.mainForm.controls['PurchaseAmount'].value;
      var totalPercent = 0;
      var i = this.selectedCostCenters.indexOf(cost);
      if(i>-1){
        this.selectedCostCenters[i].Percent = cost.Percent;
        this.selectedCostCenters[i].Amount = (amount * cost.Percent) / 100;
      }
  
  }
  changeAmount(cost,$event){
      debugger;
      cost.Amount = + $event.target.value;
      var amount = this.mainForm.controls['PurchaseAmount'].value;
      if(cost.Amount > amount){
        cost.Amount = amount
      }
      var i = this.selectedCostCenters.indexOf(cost);
      if(i>-1){
        this.selectedCostCenters[i].Amount = cost.Amount;
        this.selectedCostCenters[i].Percent = (cost.Amount * 100) / amount;
      }

  }
  deleteCostCenter(cost){
      var i = this.selectedCostCenters.indexOf(cost);
      if(i > -1){
        this.selectedCostCenters.splice(i,1);
      }
   
  }
  }
  