

<div class="row">
  <div class="col-12">
    <div class="card mb-4">
      <div class="card-body">
        <!-- <h5 class="mb-4">{{ 'From Date' | translate }}</h5> -->
        <div class="row">
          <div class="col-12 col-lg-6 mb-4">
            <label><span>{{ 'DateFrom' | translate }}</span></label>
            <input 
            type="text" onfocus="(this.type='date')" onblur="if(!this.value)this.type='text'"
            onchange="if(!this.value)this.type='text'" name="Date" class="form-control"
            [(ngModel)]="search.From"
            placeholder='{{"DateFrom" | translate}}'>
          </div>
          <div class="col-12 col-lg-6 mb-4">
            <label><span>{{ 'DateTo' | translate }}</span></label>
            <input 
            type="text" onfocus="(this.type='date')" onblur="if(!this.value)this.type='text'"
            onchange="if(!this.value)this.type='text'" name="Date" class="form-control"
            [(ngModel)]="search.To"
            placeholder='{{"DateTo" | translate}}'>
          </div>
          <div class="col-12 col-lg-6 mb-4">
            <label><span>{{ 'Branch' | translate }}</span></label>
       
            <ng-select placeholder='{{"Branch" | translate}}' *ngIf="lang==='ar-EG'" [items]="branches" bindLabel="NameAr" bindValue="Id" [(ngModel)]="search.BranchId">
            </ng-select>
            <ng-select placeholder='{{"Branch" | translate}}' *ngIf="lang!='ar-EG'" [items]="branches" bindLabel="NameEn" bindValue="Id" [(ngModel)]="search.BranchId">
            </ng-select>
          </div>
       
          <div class="col-12 col-lg-6 mb-4">
            <label><span>{{ 'Account' | translate }}</span></label>
            <ng-select placeholder='{{"Account" | translate}}'  *ngIf="lang==='ar-EG'" [items]="accounts" bindLabel="NameAr" bindValue="Id" [(ngModel)]="search.AccountId">
            </ng-select>
            <ng-select placeholder='{{"Account" | translate}}'  *ngIf="lang!='ar-EG'" [items]="accounts" bindLabel="NameEn" bindValue="Id" [(ngModel)]="search.AccountId">
            </ng-select>
          </div>
          <div class="col-12 col-lg-6 mb-4">
            <label><span>{{ 'Source' | translate }}</span></label>
            <ng-select  placeholder='{{"Source" | translate}}'  *ngIf="lang==='ar-EG'" [items]="sources" bindLabel="sourceAr" bindValue="Value" [(ngModel)]="search.Source">
            </ng-select>
            <ng-select placeholder='{{"Source" | translate}}'  *ngIf="lang!='ar-EG'" [items]="sources" bindLabel="source" bindValue="Value" [(ngModel)]="search.Source">
            </ng-select>
          </div>
          <div class="col-12 col-lg-6 mb-4 "  >
       </div>
          <div class="col-12 col-lg-6 mb-4 "  >
            <button type="button" (click)="Search()" class="btn btn-primary default mb-1" style="margin: 1px;">{{'Search' | translate}}</button>{{" "}}
            <button type="button" (click)="print()" class="btn btn-secondary default mb-1 " style="margin: 1px;">{{'Print' | translate}}</button>{{" "}}
            <button type="button" (click)="exportTable()" class="btn btn-success default mb-1" style="margin: 1px;">{{'PDF' | translate}}</button>{{" "}}
            <button type="button"  class="btn btn-primary default mb-1" style="margin: 1px;">{{'Excel' | translate}}</button>{{" "}}
         
          </div>
      </div>
    </div>
  </div>
</div>
</div>
<div id="contentToConvert">
  <div class="row" >
    <div class="col-12">
      <div class="card mb-4">
        <div class="card-body">
            <h3 class="float-center" style="text-align: center;">{{ 'Daily Restrictions' | translate }}</h3>
            <h4 class="float-center" style="text-align: center;">{{  today| date:'dd/MM/yyyy' }}</h4>
            <h6 class="float-center" style="text-align: center;">{{ 'Future Core' | translate }}</h6>
           
        </div>
      </div>
    </div>
  </div>


<div class="card mb-12 tree-explorer-side col-md-12" *ngIf="data">
    <div class="card-body ">
    <div class="row">
        <table class="table table-striped">
            <thead>
                <th>{{'Date'|translate}}</th>
                <th>{{'Code'|translate}}</th>
                <th>{{'Description'|translate}}</th>
                <th>{{'Source'|translate}}</th>
                <th>{{'Branch'|translate}}</th>
                <th>{{'Amount'|translate}}</th>
            </thead>
            <tbody>
                <tr *ngFor="let item of data">
                    <td>{{item.Date|date:'dd/MM/yyyy'}}</td>
                    <td>{{item.Code}}</td>
                    <td>{{item.Description}}</td>
                    <td>{{item.Source | translate}}</td>
                    <td>{{lang==='ar-EG'?item.BranchNameAr:item.BranchNameEn}}</td>
                    <td>{{item.Amount}}</td>
                </tr>
            </tbody>
        </table>
    
    </div>

    </div>
  </div>
</div>