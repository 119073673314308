export class PaymentPlanModel{
    Id?: number;
    PaymentDate?: Date|null;
    Name?: string;
    NumberOfDays?: number;
    constructor(item ?: PaymentPlanModel){
        item = item ? item : {};
        this.Id = item.Id ? item.Id : 0;
        this.PaymentDate = item.PaymentDate ? item.PaymentDate : null;
        this.Name = item.Name ? item.Name : '';
        this.NumberOfDays = item.NumberOfDays ? item.NumberOfDays : 0;
    }
}