export class SalaryDeductionsModel{
    Id?: number;
    ContractId    ?: number;
    SalaryItemName?: string;
    SalaryItemId  ?: number;
    Amount        ?: number;
    constructor(item ?: SalaryDeductionsModel){
        item = item ? item : {}
        this.Id = item.Id ? item.Id : 0;
        this.ContractId     = item.ContractId     ? item.ContractId     : 0;
        this.SalaryItemName = item.SalaryItemName ? item.SalaryItemName : '';
        this.SalaryItemId   = item.SalaryItemId   ? item.SalaryItemId   : 0;
        this.Amount         = item.Amount         ? item.Amount         : 0;

    }
}