<app-list-page-header *ngIf="tableView"[itemOptionsPerPage]="[5,10,20]"
    [itemsPerPage]="tableEngine.itemsPerPage" [selectAllState]="tableEngine.selectAllState" (addNewItem)="showModal(null,'Add')"
    [tableEngine]="this.tableEngine" (selectAllChange)="tableEngine.selectAll($event)" (changeOrderBy)="prepChangeSort($event)"
    (searchKeyUp)="tableEngine.filterDataByDateRange(this.SearchFrom,this.SearchTo,'Date')" [dateSearch]=true>
</app-list-page-header>

<div class="form-group" *ngIf="!tableView">
    <div class="modal-body">
        <form class="md-float-material form-material" [formGroup]="mainForm">

            <div style="text-align: center;">
                <button class="btn btn-light" (click)="goBack()"
                    style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Back"  | translate}}</button>
                <button *ngIf="tableEngine.currentFormMode==='Add'"
                    [disabled]="mainForm.invalid || tableEngine.currentAction==='loading' || addedItemList.length===0 || !codeWasChecked"
                    id="smallModalSubmitButton" class="btn btn-secondary" (click)="addObject()"
                    style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Add"  | translate}}</button>
                <button *ngIf="tableEngine.currentFormMode==='Add'" class="btn btn-secondary" (click)="addAndNew()"
                    [disabled]="mainForm.invalid || tableEngine.currentAction==='loading' || addedItemList.length===0 || !codeWasChecked"
                    style="font-weight: bold; width: 250px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"AddAndNew"  | translate}}</button>
                <button *ngIf="tableEngine.currentFormMode==='Edit'"
                    [disabled]="mainForm.invalid || tableEngine.currentAction==='loading' || addedItemList.length===0 || (!codeWasChecked && lastCode != modelObject.Code)"
                    id="smallModalSubmitButton" class="btn btn-secondary" (click)="editObject()"
                    style="font-weight: bold; width: 150px; text-align: center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">{{"Edit" | translate}}</button>
            </div>
            <div class="form-group" style="margin-top: 10px;">

                <div class="row">

                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"RequestReceiptCode" | translate}}</label>
                        <div *ngIf="(tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit') 
                                        && !configPurchases.EnableAutoRequestReceiptCodes">
                            <input type="number" min="0" name="Code" class="form-control" [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="lastCode" style=" margin: 0px;" (change)="checkCode()" (keyup)="checkCode()"
                            placeholder='{{"RequestReceiptCode" | translate}}'>
                        </div>
                        <div *ngIf="((tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit') 
                                        && configPurchases.EnableAutoRequestReceiptCodes)">
                        <label class="form-control" type="text" style="border: unset; width: 120px;"
                            name="Code">{{lastCode}}</label>
                        </div>
                        <div *ngIf="tableEngine.currentFormMode == 'Info'">
                        <label class="form-control" type="text" style="border: unset; width: 120px;"
                            name="Code">{{modelObject.Code}}</label>
                        </div>
                        <div class="messages text-danger"
                            *ngIf="!(lastCode > 0)">
                            {{"RequestReceiptCodeRequired" | translate}}</div>
                        <div class="messages text-danger"
                            *ngIf="!(codeWasChecked) && (tableEngine.currentFormMode != 'Info' && lastCode != modelObject.Code)">
                            {{"RequestReceiptCodeAlreadyTaken" | translate}}</div>
                    </div>

                    <br>

                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"RequestReceiptPurchaseOrder" | translate}}</label>
                        <div *ngIf="tableEngine.currentFormMode==='Add'">
                            <ng-select appearance="outline" name="PurchaseOrderId"
                            (change)="changePurchaseOrderHeader()"
                                formControlName="PurchaseOrderId"
                                placeholder='{{"RequestReceiptPurchaseOrder" | translate}}'
                                #RequestReceiptPurchaseOrderSelect labelForId="txtycd" [searchable]="true">
                                <ng-option *ngFor="let purchaseOrder of purchaseOrderList" [value]="purchaseOrder.Id">
                                    {{purchaseOrder.Code}}</ng-option>
                            </ng-select>
                        </div>
                        <label *ngIf="tableEngine.currentFormMode==='Info'||tableEngine.currentFormMode==='Edit'" class="form-control" type="text"
                            style="border: unset;"
                            name="PurchaseOrderId">{{modelObject.PurchaseOrderCode}}</label>
                        <div class="messages text-danger"
                            *ngIf=" (mainForm.get('PurchaseOrderId').hasError('required') || mainForm.get('PurchaseOrderId').invalid) && ( mainForm.get('PurchaseOrderId').dirty ||  mainForm.get('PurchaseOrderId').touched)">
                            {{"RequestReceiptPurchaseOrderRequired" | translate}}</div>
                        <span class="form-bar"></span>
                    </div>

                </div>

                <br>

                <div class="row">



                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"RequestReceiptSupplier" | translate}}</label>
                        <!-- <div *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'">
                            <ng-select appearance="outline" name="SupplierId" formControlName="SupplierId"
                                placeholder='{{"RequestReceiptSupplier" | translate}}' #RequestReceiptSupplierSelect
                                labelForId="txtycd" [searchable]="true">
                                <ng-option *ngFor="let supplier of supplierList" [value]="supplier.Id">
                                    {{lang==='ar-EG'?supplier.NameAr:supplier.NameEn}}</ng-option>
                            </ng-select>
                        </div> -->
                        <label class="form-control" type="text" style="border: unset;" name="SupplierId">{{
                            modelObject.SupplierId?
                            lang==='ar-EG'?modelObject.SupplierNameAr:modelObject.SupplierNameEn:
                            ("None" | translate)
                            }}</label>
                        <!-- <div class="messages text-danger"
                            *ngIf=" (mainForm.get('SupplierId').hasError('required') || mainForm.get('SupplierId').invalid) && ( mainForm.get('SupplierId').dirty ||  mainForm.get('SupplierId').touched)">
                            {{"RequestReceiptSupplierRequired" | translate}}</div> -->
                        <span class="form-bar"></span>
                    </div>

                    <br>


                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"RequestReceiptStoreData" | translate}}</label>
                        <!-- <div *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'">
                            <ng-select appearance="outline" name="StoreDataId" formControlName="StoreDataId"
                                placeholder='{{"RequestReceiptStoreData" | translate}}' #RequestReceiptStoreDataSelect
                                labelForId="txtycd" [searchable]="true">
                                <ng-option *ngFor="let store of storeDataList" [value]="store.Id">
                                    {{lang==='ar-EG'?store.NameAr:store.NameEn}}</ng-option>
                            </ng-select>
                        </div> -->
                        <label class="form-control" type="text" style="border: unset;" name="StoreDataId">{{
                            modelObject.StoreDataId?
                            lang==='ar-EG'?modelObject.StoreNameAr:modelObject.StoreNameEn:
                            ("None" | translate)
                            }}</label>
                        <!-- <div class="messages text-danger"
                            *ngIf=" (mainForm.get('StoreDataId').hasError('required') || mainForm.get('StoreDataId').invalid) && ( mainForm.get('StoreDataId').dirty ||  mainForm.get('StoreDataId').touched)">
                            {{"RequestReceiptStoreDataRequired" | translate}}</div> -->
                        <span class="form-bar"></span>
                    </div>

                    <br>


                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"RequestReceiptDate" | translate}}</label>
                        <!-- <input *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                            type="text" onfocus="(this.type='date')" onblur="if(!this.value)this.type='text'"
                            onchange="if(!this.value)this.type='text'" name="Date" class="form-control"
                            formControlName="Date" placeholder='{{"RequestReceiptDate" | translate}}'> -->
                        <label class="form-control" type="text" style="border: unset;"
                            name="Date">{{modelObject.Date?(modelObject.Date | date:"dd/MM/yyyy"):("None" |
                            translate)}}</label>
                        <!-- <div class="messages text-danger"
                            *ngIf=" (mainForm.get('Date').hasError('required') || mainForm.get('Date').invalid) && ( mainForm.get('Date').dirty ||  mainForm.get('Date').touched)">
                            {{"RequestReceiptDateRequired" | translate}}</div> -->
                        <span class="form-bar"></span>
                    </div>



                </div>

                <br>

                <div class="row">
                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"RequestReceiptIsPosted" | translate}}</label>
                        <!-- <div *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'">
                            <ng-select appearance="outline" name="IsPosted" formControlName="IsPosted"
                                placeholder='{{"RequestReceiptIsPosted" | translate}}' #RequestReceiptIsPostedSelect
                                labelForId="txtycd">
                                <ng-option [value]="false">{{"RequestReceiptIsPostedFalse" | translate}}</ng-option>
                                <ng-option [value]="true">{{"RequestReceiptIsPostedTrue" | translate}}</ng-option>
                            </ng-select>
                        </div> -->
                        <label class="form-control" type="text" style="border: unset;"
                            name="IsPosted">{{(modelObject.IsPosted!=null?(modelObject.IsPosted?"RequestReceiptIsPostedTrue":"RequestReceiptIsPostedFalse"):
                            "None" )| translate}}</label>
                        <!-- <div class="messages text-danger"
                            *ngIf=" (mainForm.get('IsPosted').hasError('required') || mainForm.get('IsPosted').invalid) && ( mainForm.get('IsPosted').dirty ||  mainForm.get('IsPosted').touched)">
                            {{"RequestReceiptIsPostedRequired" | translate}}</div> -->
                        <span class="form-bar"></span>
                    </div>
                </div>

                <br>

                <div class="row" style="height: 160px;">
                    <div class=" col-md-4">
                        <label class="float-label push righ">{{"RequestReceiptDescription" | translate}}</label>
                        <!-- <textarea *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                            type="text" name="Description" class="form-control" formControlName="Description"
                            style="width: 319%; height: 150px;"
                            placeholder='{{"RequestReceiptDescription" | translate}}'></textarea> -->
                        <label class="form-control" type="text" style="border: unset; white-space:pre-wrap; word-wrap:break-word; 
                            width: 319%; height: 150px; overflow: auto;"
                            name="Description">{{modelObject.Description?modelObject.Description:("None" |
                            translate)}}</label>
                        <span class="form-bar"></span>
                    </div>
                </div>

                <br>

                <!-- <div id="divmiddle" style="display: block; margin-top: 5px;"> -->
                <div>
                    <div style="width: 100%; margin-top: 15px;">
                        <div>
                            <table class="table">
                                <thead class="thead-light">
                                    <th></th>
                                    <th>{{"RequestReceiptItemName" | translate}}</th>
                                    <th>{{"RequestReceiptItemUnit" | translate}}</th>
                                    <th>{{"RequestReceiptItemPrice" | translate}}</th>
                                    <th>{{"RequestReceiptItemQuantity" | translate}}</th>
                                    <th>{{"RequestReceiptItemValue" | translate}}</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let obj of addedItemList;  let i=index">
                                        <td style="text-align: center; vertical-align: middle;">
                                            <i *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                                            class="simple-icon-close" (click)="removeRequestReceiptItem(obj.Id)" style="font-size: 20px; font-weight: bolder; cursor: pointer; 
                                        vertical-align: middle; color: #DC3545;"></i>
                                        </td>
                                        <td style="vertical-align: middle;">
                                            {{obj.ItemId>0?(lang==='ar-EG'?obj.ItemNameAr:obj.ItemNameEn):"None"|translate}}
                                        </td>
                                        <td style="vertical-align: middle;">
                                            {{obj.UnitId>0?(lang==='ar-EG'?obj.UnitNameAr:obj.UnitNameEn):"None"|translate}}
                                        </td>
                                        <td style="vertical-align: middle;">
                                            {{obj.UnitCost}}
                                        </td>
                                        <td>
                                            <input
                                                *ngIf="tableEngine.currentFormMode==='Add'||tableEngine.currentFormMode==='Edit'"
                                                (change)="calculateTotalItemValue()"
                                                type="number" min="0" [max]="obj.MaxQuantity" name="Quantity" class="form-control"
                                                [(ngModel)]="obj.Quantity" style="width: 150px; margin: 0px;"
                                                [ngModelOptions]="{standalone: true}"
                                                placeholder='{{"RequestReceiptItemQuantity" | translate}}'>

                                            <div class="messages text-danger"
                                                *ngIf="!(obj.Quantity>0)">
                                                {{"RequestReceiptItemQuantityRequired" | translate}}</div>
                                            <div *ngIf="tableEngine.currentFormMode==='Info'">
                                                {{obj.Quantity}}
                                            </div>
                                        </td>
                                        <td style="vertical-align: middle;">
                                            {{obj.Quantity * obj.UnitCost}}
                                        </td>
                                    </tr>
                                </tbody>
                                <thead class="thead-light">
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                </thead>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class=" col-md-4">
                        <div style="display: inline-block;">
                            {{"RequestReceiptTotalValue" | translate}}
                        </div>
                        <label
                            style="display: inline-block; margin-left: 8px; margin-right: 8px; min-width: 150px; border: unset; font-size: 25px;"
                            class="form-control" type="text"
                            name="TotalValue">{{currentTotal>0?currentTotal:0}}</label>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<div bsModal #delModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <!-- <button type="button" class="close pull-right" aria-label="Close" (click)="delModal.hide()"
                style="margin-right: 20px; margin-left: 20px; margin-top: 10px; width: 20px; display: inline;">
            <span aria-hidden="true">&nbsp;</span>
            </button> -->
            <span aria-hidden="true">&nbsp;</span>
            <div class="modal-header" style="display: inline; padding: 0px 0px 5px 0px;">
                <h4 id="dialog-child-name" class="modal-title pull-left" style="text-align: center; color: #DC3545;">
                    {{"ConfirmDel"+translationName |
                    translate}}</h4>
            </div>
            <div class="modal-body">
                <div class="form-group form-primary" *ngIf="tableEngine.deletionList.length < tableEngine.ogData.length 
                || tableEngine.deletionList.length === 1" style="text-align: center;">
                    <div *ngFor="let item of tableEngine.deletionList;" style="text-align: center;">
                        <label type="text">- {{translationName|translate}} {{"Number" | translate}}
                            {{lang==='ar-EG'?item.Code:item.Code}}</label>
                        <span class="form-bar"></span>
                    </div>
                    <!-- <div style="text-align: center;">
                        <label type="text">- {{(tableEngine.deletionList.length>2?tableEngine.deletionList.length:"")}}
                            {{(tableEngine.deletionList.length===1?"One"+translationName:
                            tableEngine.deletionList.length===2?"Two"+translationName:
                            tableEngine.deletionList.length>2 && tableEngine.deletionList.length
                            <11?translationName+"RowsPlur": translationName+"Rows") | translate}} </label>
                                <span class="form-bar"></span>
                    </div> -->
                </div>
                <div class="form-group form-primary" style="text-align: center;" *ngIf="tableEngine.deletionList.length > 1 && 
                    tableEngine.deletionList.length === tableEngine.ogData.length">
                    <label type="text">
                        <h1 style="font-weight: bolder; text-align: center;">{{translationName+"DeleteAll" | translate}}
                        </h1>
                    </label>
                    <span class="form-bar"></span>
                </div>

                <div style="margin-left: 5px; text-align: center; margin-top: 30px;">
                    <button class="btn btn-danger" id="smallModalDeleteButton" (click)="deleteObject()"
                        [disabled]="tableEngine.currentAction==='loading'"
                        style="font-weight: bold; width: 100%; text-align: center;">{{"Delete" | translate}}</button>
                    <button class="btn btn-light" (click)="delModal.hide()"
                        style="font-weight: bold; width: 100%; text-align: center; margin-top: 10px;">{{"Close" |
                        translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="tableView" class="FormScreen" class="tableScreen">
    <!-- <div id="divtop" style="display: block;height: 47px; width: 100%;">
        <div id="divsearchmiddle" style="display: inline-block; width: 70%;
        height: inherit;  text-align: center; padding: 0px; margin: 0px;">
            <div class="search" style="display: inline-block; margin-left: 50px; margin-right: 50px;">
                <div class="d-inline-block">
                    <div class="d-inline-block mr-1 mb-1 align-top" style="width: 1000px;">
                        {{"RequestReceiptShowResultsFrom"|translate}}
                        <div class="search-sm-nosearch d-inline-block mr-1 mb-1 align-top">
                            <input style="display: inline-block; width: 150px; margin-left: 5px;  margin-right: 5px;"
                                type="text" onfocus="(this.type='date')" onblur="if(!this.value)this.type='text'"
                                onchange="if(!this.value)this.type='text'" name="Date" class="form-control"
                                [(ngModel)]="SearchFrom"
                                (change)="tableEngine.filterDataByDateRange(this.SearchFrom,this.SearchTo,'Date')"
                                placeholder='{{"RequestReceiptSearchDateFrom" | translate}}'>
                        </div>
                        {{"RequestReceiptShowResultsTo"|translate}}
                        <div class="search-sm-nosearch d-inline-block mr-1 mb-1 align-top">
                            <input style="display: inline-block; width: 150px; margin-left: 5px;  margin-right: 5px;"
                                type="text" onfocus="(this.type='date')" onblur="if(!this.value)this.type='text'"
                                onchange="if(!this.value)this.type='text'" name="Date" class="form-control"
                                [(ngModel)]="SearchTo"
                                (change)="tableEngine.filterDataByDateRange(this.SearchFrom,this.SearchTo,'Date')"
                                placeholder='{{"RequestReceiptSearchDateTo" | translate}}'>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="divsearchleft" style="display: inline-block; width: 30%; height: inherit;">
            <div style="display: block; height: 30%; ">
                &nbsp;
            </div>
            <div class="search" style="display: block; height: 70%; text-align: center;">
                <button class="btn btn-primary d-inline-block"
                    style="font-weight: bold; text-align: center; padding: 0px; width: 150px; height: 30px;"
                    (click)="showModal(null,'Add')"
                    [disabled]="tableEngine.currentAction==='loading'">{{"Add"+translationName |
                    translate}}</button>

            </div>
        </div>

    </div> -->


    <div id="divmiddle" style="display: block; margin-top: 5px;">

        <div style="width: 100%;">
            <div>
                <div class="card" style="min-width: 900px !important;">
                    <div class="card-body">
                        <table class="table table-hover" style="margin: unset !important;">
                            <thead class="no-border-top">
                                <th style="text-align: center; padding:5px;">
                                    <div class=" text-zero" style="display: inline-block;">
                                        <div class="top-right-button-container2 btn-group" dropdown>
                                            <div class="btn btn-primary pl-4 pr-0 check-button">
                                                <label class="custom-control custom-checkbox mb-0 d-inline-block"
                                                    style="width: 10px;">
                                                    <input type="checkbox" class="custom-control-input"
                                                        [checked]="tableEngine.selectAllState==='checked'"
                                                        [indeterminate]="tableEngine.selectAllState === 'indeterminate'"
                                                        (change)="tableEngine.selectAll($event)">
                                                        <span class="custom-control-label">&nbsp;</span>
                                                </label>
                                            </div>
                                    <button id="button-split" type="button" dropdownToggle style="width: 10px;"
                                        class="btn btn-outline-primary dropdown-toggle dropdown-toggle-split"
                                        aria-controls="dropdown-split">
                                        <span class="caret"></span>
                                    </button>
                                    <ul id="dropdown-split" *dropdownMenu class="dropdown-menu dropdown-menu-right"
                                        role="menu" aria-labelledby="button-split">
                                        <li role="menuitem"><a class="dropdown-item">
                                                <button class="btn btn-danger d-inline-block"
                                                    style=" font-weight: bold; text-align: center; padding: 0px; width: 150px; height: 30px;"
                                                    (click)="tableEngine.showDelModal('delMulti')"
                                                    [disabled]="tableEngine.selected.length===0 || tableEngine.currentAction==='loading'">{{"DeleteSelected"
                                                    |
                                                    translate}}</button></a></li>
                                    </ul>
                                </div>
                            </div>
                        </th>

                        <th class="sorticon" (click)="prepChangeSort('RequestReceiptCode')"
                            [ngClass]="(itemOrder.label==='RequestReceiptCode')?'sortkey sorticon':'sorticon'">
                            <div
                                [ngClass]="{'iconsminds-up---down': (itemOrder.label!='RequestReceiptCode'), 
                            'iconsminds-down': ((itemOrder.label==='RequestReceiptCode') && tableEngine.orderAsc === true),
                            'iconsminds-up': ((itemOrder.label==='RequestReceiptCode') && tableEngine.orderAsc === false)}">
                            </div>
                        </th>
                        <th class="sortOption" (click)="prepChangeSort('RequestReceiptCode')"
                            [ngClass]="{'sortkey': (itemOrder.label==='RequestReceiptCode')}">
                            {{"RequestReceiptCode" | translate}}
                        </th>


                        <th class="sorticon" (click)="prepChangeSort('RequestReceiptSupplier')"
                            [ngClass]="(itemOrder.label==='RequestReceiptSupplier')?'sortkey sorticon':'sorticon'">
                            <div
                                [ngClass]="{'iconsminds-up---down': (itemOrder.label!='RequestReceiptSupplier'), 
                            'iconsminds-down': ((itemOrder.label==='RequestReceiptSupplier') && tableEngine.orderAsc === true),
                            'iconsminds-up': ((itemOrder.label==='RequestReceiptSupplier') && tableEngine.orderAsc === false)}">
                            </div>
                        </th>
                        <th class="sortOption" (click)="prepChangeSort('RequestReceiptSupplier')"
                            [ngClass]="{'sortkey': (itemOrder.label==='RequestReceiptSupplier')}">
                            {{"RequestReceiptSupplier" | translate}}
                        </th>


                        <th class="sorticon" (click)="prepChangeSort('RequestReceiptDate')"
                            [ngClass]="(itemOrder.label==='RequestReceiptDate')?'sortkey sorticon':'sorticon'">
                            <div
                                [ngClass]="{'iconsminds-up---down': (itemOrder.label!='RequestReceiptDate'), 
                            'iconsminds-down': ((itemOrder.label==='RequestReceiptDate') && tableEngine.orderAsc === true),
                            'iconsminds-up': ((itemOrder.label==='RequestReceiptDate') && tableEngine.orderAsc === false)}">
                            </div>
                        </th>
                        <th class="sortOption" (click)="prepChangeSort('RequestReceiptDate')"
                            [ngClass]="{'sortkey': (itemOrder.label==='RequestReceiptDate')}">
                            {{"RequestReceiptDate" | translate}}
                        </th>


                        <th class="sorticon" (click)="prepChangeSort('RequestReceiptStoreName')"
                            [ngClass]="(itemOrder.label==='RequestReceiptStoreName')?'sortkey sorticon':'sorticon'">
                            <div
                                [ngClass]="{'iconsminds-up---down': (itemOrder.label!='RequestReceiptStoreName'), 
                            'iconsminds-down': ((itemOrder.label==='RequestReceiptStoreName') && tableEngine.orderAsc === true),
                            'iconsminds-up': ((itemOrder.label==='RequestReceiptStoreName') && tableEngine.orderAsc === false)}">
                            </div>
                        </th>
                        <th class="sortOption" (click)="prepChangeSort('RequestReceiptStoreName')"
                            [ngClass]="{'sortkey': (itemOrder.label==='RequestReceiptStoreName')}">
                            {{"RequestReceiptStoreName" | translate}}
                        </th>

                        <th class="sorticon" (click)="prepChangeSort('RequestReceiptTotalValue')"
                            [ngClass]="(itemOrder.label==='RequestReceiptTotalValue')?'sortkey sorticon':'sorticon'">
                            <div
                                [ngClass]="{'iconsminds-up---down': (itemOrder.label!='RequestReceiptTotalValue'), 
                            'iconsminds-down': ((itemOrder.label==='RequestReceiptTotalValue') && tableEngine.orderAsc === true),
                            'iconsminds-up': ((itemOrder.label==='RequestReceiptTotalValue') && tableEngine.orderAsc === false)}">
                            </div>
                        </th>
                        <th class="sortOption" (click)="prepChangeSort('RequestReceiptTotalValue')"
                            [ngClass]="{'sortkey': (itemOrder.label==='RequestReceiptTotalValue')}">
                            {{"RequestReceiptTotalValue" | translate}}
                        </th>
                        <th class="sorticon" (click)="prepChangeSort('RequestReceiptIsPosted')"
                            [ngClass]="(itemOrder.label==='RequestReceiptIsPosted')?'sortkey sorticon':'sorticon'">
                            <div
                                [ngClass]="{'iconsminds-up---down': (itemOrder.label!='RequestReceiptIsPosted'), 
                            'iconsminds-down': ((itemOrder.label==='RequestReceiptIsPosted') && tableEngine.orderAsc === true),
                            'iconsminds-up': ((itemOrder.label==='RequestReceiptIsPosted') && tableEngine.orderAsc === false)}">
                            </div>
                        </th>
                        <th class="sortOption" (click)="prepChangeSort('RequestReceiptIsPosted')"
                            [ngClass]="{'sortkey': (itemOrder.label==='RequestReceiptIsPosted')}">
                            {{"RequestReceiptIsPosted" | translate}}
                        </th>

                        <th [colSpan]="3"
                            style="text-align: center; padding: unset !important; vertical-align: middle;">

                            <div id="displayOptions" style="
                                display: inline; margin-top: 10px; margin-left: 5px; margin-right: 5px;">
                                <span class="text-muted text-small" style=" width: 50px; height: 40px;">
                                    {{"ResultCount" | translate}}
                                </span>
                                <span dropdown class="d-inline-block position-relative">
                                    <button class="btn btn-outline-dark btn-xs dropdown-toggle" type="button"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                        dropdownToggle>
                                        {{tableEngine.itemsPerPage}}
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-sm text-right"
                                        *dropdownMenu>
                                        <li role="menuitem" *ngFor="let item of tableEngine.itemOptionsPerPage">
                                            <a [ngClass]="item === tableEngine.itemsPerPage ? 'dropdown-item active' : 'dropdown-item'"
                                                (click)="tableEngine.paginateData(item, 1)">{{item}}</a></li>
                                    </div>
                                </span>
                            </div>

                        </th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let obj of tableEngine.paginatedData;  let i=index">
                            <td style="text-align: center;">
                                <div class="itemCheck mb-0 custom-checkbox custom-control"
                                    style="display: inline-block;">
                                    <input type="checkbox" class="custom-control-input" [id]="'customCheck'+i"
                                        [checked]="tableEngine.isSelected(obj)" (change)="tableEngine.onSelect(obj)">
                                    <label class="custom-control-label" [for]="'customCheck'+i"></label>
                                </div>
                            </td>
                            <td colspan="2">{{obj.Code}}</td>
                            <td colspan="2">
                                {{lang==='ar-EG'?obj.SupplierNameAr:obj.SupplierNameEn}}
                            </td>
                            <td colspan="2">{{obj.Date | date:"dd/MM/yyyy"}}</td>
                            <td colspan="2">
                                {{lang==='ar-EG'?obj.StoreNameAr:obj.StoreNameEn}}
                            </td>
                            <td colspan="2">{{obj.TotalValue}}</td>
                            <td colspan="2" style="text-align: center;">
                                <span style="width: 90px; text-align: center; margin-top: 5px;"
                                    [ngClass]="obj.IsPosted?'badge badge-pill badge-success mb-1':'badge badge-pill badge-warning mb-1'">{{(obj.IsPosted?"RequestReceiptIsPostedTrue":"RequestReceiptIsPostedFalse")
                                    | translate}}</span>

                            </td>
                            <td style="text-align: center;">
                                <i class="simple-icon-info" (click)="showModal(obj.Id, 'Info')" style="font-size: 20px; font-weight: bolder; cursor: pointer; 
                                        vertical-align: middle; color: #17A2B8;"></i>
                            </td>
                            <td style="text-align: center;">
                                <i class="simple-icon-pencil" (click)="showModal(obj.Id, 'Edit')" style="font-size: 20px; font-weight: bolder; cursor: pointer; 
                                        vertical-align: middle; color: #FFC107;"></i>
                            </td>
                            <td style="text-align: center;">
                                <i class="simple-icon-close" (click)="tableEngine.showDelModal(obj.Id)" style="font-size: 20px; font-weight: bolder; cursor: pointer; 
                                        vertical-align: middle; color: #DC3545;"></i>
                            </td>
                        </tr>
                    </tbody>

                </table>
                <div id="divsearchmiddle" style="display: inline-block; width: 100%; height: 100%;">
                    <div style="text-align: center;">
                        <!-- <div class="centerside" style="display: inline-block; width: 15%; height: 100%; ">
                            &nbsp;

                        </div> -->
                        <div class="centercenter"
                            style="display: inline-block; width: 70%; height: 100%;  text-align: center;">

                            <bdo dir="ltr">
                                <pagination class="pagination-sm2" style="padding: 0px, 0px, 0px, 0px; margin: 0px, 0px, 0px, 0px;
                                display: inline-block;" [(ngModel)]="currentPage" [boundaryLinks]="true"
                                    [totalItems]="tableEngine.filteredData.length"
                                    [itemsPerPage]="tableEngine.itemsPerPage"
                                    [customFirstTemplate]="firstTemplate" [customLastTemplate]="lastTemplate"
                                    [customPreviousTemplate]="prevTemplate" [customNextTemplate]="nextTemplate"
                                    [maxSize]="5" (pageChanged)="tableEngine.changePage($event)">
                                </pagination>
                            </bdo>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div id="divbottom" style="display: block; height: 47px;">

        <div id="divsearchright" style="float: right;  width: 33.33%; padding: 0px;">&nbsp;</div>
<!-- 
        <div id="divsearchmiddle" style="display: inline-block; width: 33.33%; height: 100%;">

            <div style="text-align: center;">
                <bdo dir="ltr">
                    <pagination class="pagination-sm2" style="padding: 0px, 0px, 0px, 0px; margin: 0px, 0px, 0px, 0px;
                    display: inline-block;" [(ngModel)]="currentPage" [boundaryLinks]="true"
                        [totalItems]="tableEngine.filteredData.length" [itemsPerPage]="tableEngine.itemsPerPage"
                        [customFirstTemplate]="firstTemplate" [customLastTemplate]="lastTemplate"
                        [customPreviousTemplate]="prevTemplate" [customNextTemplate]="nextTemplate" [maxSize]="5"
                        (pageChanged)="tableEngine.changePage($event)">
                    </pagination>
                </bdo>
            </div>
        </div> -->

        <div id="divsearchleft" style="float: left; width: 33.33%; height: 100%;">&nbsp;</div>

    </div>
</div>

<div>

</div>

<ng-template #nextTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i *ngIf="lang==='en-US'" class='simple-icon-arrow-right'></i>
    <i *ngIf="lang==='ar-EG'" class='simple-icon-arrow-left'></i>
</ng-template>

<ng-template #prevTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i *ngIf="lang==='en-US'" class='simple-icon-arrow-left'></i>
    <i *ngIf="lang==='ar-EG'" class='simple-icon-arrow-right'></i>
</ng-template>

<ng-template #lastTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i class='simple-icon-control-end'></i>
</ng-template>

<ng-template #firstTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i class='simple-icon-control-start'></i>
</ng-template>


<simple-notifications></simple-notifications>