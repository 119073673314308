import { CustomerAccountStatementComponent } from './Reports/customer-account-statement/components/customer-account-statement/customer-account-statement.component';
import { ItemsComponent } from './items/items.component';
import { BranchesComponent } from './branches/branches.component';
import { CurrenciesComponent } from './currencies/currencies.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { InvoiceComponent } from './invoice.component';
import { ClientsComponent } from './clients/clients.component';
import { CreateInvoiceComponent } from './create-invoice/create-invoice.component';
import { CompaniesComponent } from '../views/companies/companies.component';
import { ProductTypesComponent } from './product-types/product-types.component';
import { ProjectsComponent } from './projects/projects.component';
import { StaffComponent } from './staff/staff.component';
import { CodingUnitsComponent } from './coding-units/coding-units.component';
import { TaxTypesComponent } from './tax-types/tax-types.component';
import { AddExpensesAndPurchasesComponent } from './suppliers/add-expenses-and-purchases/add-expenses-and-purchases.component';
import { ExpensesAndPurchasesComponent } from './suppliers/expenses-and-purchases/expenses-and-purchases.component';
import { ProjectViewComponent } from './suppliers/project/project-view/project-view.component';
import { ExpensCategoryComponent } from './suppliers/expens-category/expens-category.component';
import { ShowSuppliersComponent } from './suppliers/show-suppliers/show-suppliers.component';
import { AddSuppliersComponent } from './suppliers/add-suppliers/add-suppliers.component';
import { PurchasesComponent } from './purchases/purchases.component';
import { InvoiceReportComponent } from './Reports/components/invoice-report/invoice-report.component';
import { PurshasesReportComponent } from './Reports/components/purshases-report/purshases-report.component';
import { DailyInvoicesComponent } from './Reports/daily-invoices/components/daily-invoices/daily-invoices.component';
import { ClientInvoicesComponent } from './Reports/client-invoices/components/client-invoices/client-invoices.component';
import { ViewInvoiceComponent } from './view-invoice/view-invoice.component';
import { DebtReconstructionComponent } from './Reports/debt-reconstruction/components/debt-reconstruction/debt-reconstruction.component';
import { CustomerBalancesComponent } from './Reports/customer-balances/components/customer-balances/customer-balances.component';
import { CustomerSalesComponent } from './Reports/customer-sales/components/customer-sales/customer-sales.component';
import { CustomerPaymentsComponent } from './Reports/customer-payments/components/customer-payments/customer-payments.component';

const routes: Routes = [
    {
        path: '', component: InvoiceComponent,
        
    },
   
    ////////////////////////////////////////////
     { path: 'Companies', component: CompaniesComponent},
            { path: 'E-Invoice', component: InvoiceComponent },
            //Main Data
            { path: 'MainData', component: InvoiceComponent },
            { path: 'MainData/Items', component: ItemsComponent},
            { path: 'MainData/Product-Types', component: ProductTypesComponent},
            // { path: 'MainData/Projects', component: ProjectsComponent},
            { path: 'MainData/Purchases', component: PurchasesComponent},
            { path: 'MainData/Staff', component: StaffComponent },
            //Clients
            { path: 'Clients', component: InvoiceComponent},
            { path: 'Clients/All-Clients', component: ClientsComponent},
            { path: 'Clients/Branches', component: BranchesComponent},
            { path: 'Clients/Currencies', component: CurrenciesComponent},
            { path: 'Clients/Coding-Units', component: CodingUnitsComponent},
            { path: 'Clients/Tax-Types', component: TaxTypesComponent},
            //Reports
            { path: 'Reports', component: InvoiceComponent },
            { path: 'Reports/Invoices', component: InvoicesComponent },
            { path: 'Reports/Sales', component: InvoiceReportComponent},
            { path: 'Reports/Purshases', component: PurshasesReportComponent},
            { path: 'Reports/daily-invoices', component: DailyInvoicesComponent},
            { path: 'Reports/client-invoices', component: ClientInvoicesComponent},
            { path: 'Reports/view-invoice/:Id', component: ViewInvoiceComponent},
            { path: 'Reports/debt-reconstruction', component: DebtReconstructionComponent},
            { path: 'Reports/customer-balances', component: CustomerBalancesComponent},
            { path: 'Reports/customer-sales', component: CustomerSalesComponent},
            { path: 'Reports/customer-payments', component: CustomerPaymentsComponent},
            { path: 'Reports/customer-account-statement', component: CustomerAccountStatementComponent},
            
            //Settings
            { path: 'Settings', component: InvoicesComponent },
            { path: 'Settings/TaxingApi', component: InvoicesComponent },

            { path: 'Reports/Create-Invoice', component: CreateInvoiceComponent},

            { path: 'Supplier/Create-ExpensesAndPurchasesComponent/:state/:id', component: AddExpensesAndPurchasesComponent},
            { path: 'Supplier/Show-ExpensesAndPurchasesComponent', component: ExpensesAndPurchasesComponent},
            { path: 'Supplier/Show-ProjectViewComponent', component: ProjectsComponent},
            { path: 'Supplier/Show-ExpensCategoryComponent', component: ExpensCategoryComponent},

];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class InvoicesRoutingModule { }