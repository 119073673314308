export class CountryCodeModel {
    Id?: Number;
    CodeTaxTabel?: string;
    NameAr?: string;
    NameEn?: string;
    IsPosted?: Boolean;

    constructor(obj?:CountryCodeModel){
        obj=obj?obj:{};
        this.Id=obj?.Id? obj.Id:0;
        this.NameAr=obj?.NameAr? obj.NameAr:'';
        this.NameEn=obj?.NameEn? obj.NameEn:'';
        this.CodeTaxTabel=obj?.CodeTaxTabel? obj.CodeTaxTabel:'';
        this.IsPosted=obj?.IsPosted? obj.IsPosted:false;

    }
}