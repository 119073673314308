<!--component html goes here -->
<!--component html goes here -->
<!--component html goes here -->
<!--component html goes here -->
<div class="row mb-12">
    <div class="col-md-12 col-lg-12 col-12 mb-12">
      <div class="card"></div>
    </div>
  </div>
  <div class="row" *ngIf="mainForm">
    <div class="col-12">
      <div class="card mb-4">
        <div class="card-body">
          <h3>
           
            <Strong style="font-size: 22px; margin-left:10px ;"> {{modelObject.Name}}</Strong>
            <span  style= "margin-left:10px; font-size: 14px" >{{modelObject.Code}}#</span>
            <span class="badge badge-pill badge-success  " style= "margin-left:10px; font-size: 14px"  *ngIf="modelObject.Status==true">{{'Active' | translate}}</span>
            <span class="badge badge-pill badge-danger mb-1 " style= "margin-left:10px; font-size: 14px;"   *ngIf="modelObject.Status==false">{{'Inactive' | translate}}</span>
        
          <div class="top-right-button-container text-zero float-right">
            <button
              class="top-right-button btn btn-primary btn-lg mr-1"
              (click)="goBack()"
            >
              {{ "Back" | translate }}
            </button>
           
          </div>
        </h3>
        </div>
      </div>
    </div>
  </div>


  <div class="row" *ngIf="mainForm">
    <div class="col-12">
      <div class="card mb-2">
        <div class="card-body">
            <div class="row">
               <div class="col-md-3">
                     <div class="flex-shrink-1">
                         <div class="media text-center">
                             <img class="img-fluid avatar-lg" src="" alt="name">
                         </div>
                     </div>
                    </div>

                    <div class="col-md-3">
                     <div class="px-4 pt-3 pt-sm-0">
                         <h3>
                             <Strong style="font-size: 22px; margin-left:10px ;"> {{modelObject.Name}}</Strong>
                             <span> ({{modelObject.Type}})</span>
                         </h3>
                     </div>
                    </div>

                    <div class="col-md-6">
                        <div >
                           <a class="btn btn-icon btn-dark-blue btn-block">
                            <!-- <i class='fa fa-mobile-phone' style='color: white'></i> -->
                            <span style="font-size: large; text-align:right;">&#9990;</span>
                               {{modelObject.MobileNumber}}</a>
                           <a *ngIf="modelObject.Status==true" class="btn btn-icon btn-dark-blue btn-block">
                            
                            <!-- <i class='fa fa-mobile-phone' style='color: white'></i> -->
                            <span   style="font-size: large; text-align:right;"> <i class="fa-solid fa-gear"></i> </span>
                               {{ "AttendanceAppSettings" | translate }}</a>

                        </div>
                    <!-- <div class="col-lg-3"> -->

                 <!-- <div class="col-md-6">
                    <span><button class="button button2">{{modelObject.MobileNumber}}</button></span>

                 </div> -->
        <!-- </div> -->
        <!-- <div class="row">
            <div class="col-md-6"></div>
            <div class="col-md-6">
                <span><button class="button button2">{{ "AttendanceAppSettings" | translate }}</button></span>
            </div>
        </div> -->
        </div>
      </div>
    </div>
  </div>


<div class="row" *ngIf="mainForm">
    <div class="col-12">
      <div class="mb-4">
         <div class="btn-group">
           <button (click)="showModal(modelObject.Id, 'Edit')">{{ "Edit" | translate }}</button>
           <button (click)="AddNotesOnClick(modelObject.Id)">{{ "ProcedureAndAttachments" | translate }}</button>
           <button (click)="DisableEmployee()" *ngIf="modelObject.Status==true">{{ "Disable" | translate }}</button>
           <button (click)="DisableEmployee()" *ngIf="modelObject.Status==false">{{ "Activation" | translate }}</button>
         </div>
        </div>
    </div>
</div>
<td></td>
<td></td>
  <div class="card"*ngIf="modelObject" >
    <tabset class="card-tabs">
    <tab heading="{{'Information' | translate}}" class="card-body" >    
        
        <div >
            <div class="container">
                <!-- <h3>panelHeading</h3> -->
                <div class="panel" style="padding: 5px;">
                  <div class="panel-head" style="background-color:#f6f9fc;">
                    <h3>{{'GeneralInformation' | translate}}</h3></div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="mb-4">
                          <p class="text-muted mb-1"> {{ "FullName:" | translate }}</p>
                          <p style="font-weight: bold;"> {{modelObject.Name}} {{modelObject.MiddleName}}</p>      
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="mb-4">
                          <p class="text-muted mb-1"> {{ "NickName:" | translate }}</p>
                          <p style="font-weight: bold;"> {{modelObject.Nickname}}</p>      
                        </div>
                      </div>
                    </div>
                </div>
              </div>
              <div class="container">
                <!-- <h3>panelHeading</h3> -->
                <div class="panel">
                  <div class="panel-head" style="background-color:#f6f9fc;">
                    <h3>{{'PersonalData' | translate}}</h3></div>
                    <div class="row" style="padding: 5px;">
                      <div class="col-md-6">                 
                         <div class="mb-4">
                             <p class="text-muted"> {{ "DateOfBirth:" | translate }}</p>
                             <p style="font-weight: bold;"> {{modelObject.BirthDate}}</p>
                         </div>
                     </div>                

                     <div class="col-md-6">
                         <div class="mb-4"> 
                             <p class="text-muted mb-1"> {{ "Country:" | translate }}</p>
                             <p style="font-weight: bold;">  {{ lang === "ar-EG" ? modelObject.CountryNameAr : modelObject.CountryNameEn }}</p>
                         </div>
                        
                     </div>
                   </div>
                  </div>
              </div>

              <div class="container">
                <!-- <h3>panelHeading</h3> -->
                <div class="panel">
                  <div class="panel-head" style="background-color:#f6f9fc;">
                    <h3>{{'ContactInformation' | translate}}</h3></div>
                  <div class="row" style="padding: 5px;">
                    <div class="col-md-6">                 
                       <div class="mb-4">
                        <p class="text-muted mb-1"> {{ "MobileNumber:" | translate }}</p>
                        <p style="font-weight: bold;"> {{modelObject.MobileNumber}}</p>
                       </div>
                   </div>                

                   <div class="col-md-6">
                       <div class="mb-4"> 
                        <p class="text-muted mb-1"> {{ "GroundPhoneNumber:" | translate }}</p>
                        <p style="font-weight: bold;"> {{modelObject.GroundPhoneNumber}}</p>
                       </div>
                      
                   </div>
                 </div>
                 
                </div>
              </div>


              <div class="container">
                <!-- <h3>panelHeading</h3> -->
                <div class="panel">
                  <div class="panel-head" style="background-color:#f6f9fc;">
                    <h3>{{'Address' | translate}}</h3></div>
                  <div class="row " style="padding: 5px;">
                    <div class="col-md-6">                 
                       <div class="mb-4">
                        <p class="text-muted mb-1"> {{ "CurrentAddress:" | translate }}</p>
                        <p style="font-weight: bold;"> {{modelObject.FirstAddress}} <br>
                            {{modelObject.City}},
                            {{modelObject.Governorate}},
                            {{ lang === "ar-EG" ? modelObject.CountryNameAr : modelObject.CountryNameEn }}
                        </p>
                       </div>
                   </div>                

                   <div class="col-md-6">
                    <div class="mb-4">
                        <p class="text-muted mb-1"> {{ "PermanentAddress:" | translate }}</p>
                        <p style="font-weight: bold;"> {{modelObject.FirstPermanentAddress}} <br>
                            {{modelObject.PermanentCity}},
                            {{modelObject.PermanentGovernorate}},
                            {{ lang === "ar-EG" ? modelObject.CountryNameAr : modelObject.CountryNameEn }}                        </p>
                       </div>
                      
                   </div>
                 </div>
                 
                </div>
              </div>


              <div class="container">
                <!-- <h3>panelHeading</h3> -->
                <div class="panel">
                  <div class="panel-head" style="background-color:#f6f9fc;">
                    <h3>{{'JobData' | translate}}</h3></div>
                  <div class="row" style="padding: 5px;">
                    <div class="col-md-6">                 
                       <div class="mb-4">
                        <p class="text-muted mb-1"> {{ "JobTitle:" | translate }}</p>
                        <p style="font-weight: bold;"> {{modelObject.JobTitelName}}</p>
                       </div>
                   </div>                

                   <div class="col-md-6">
                       <div class="mb-4"> 
                        <p class="text-muted mb-1"> {{ "Department:" | translate }}</p>
                        <p style="font-weight: bold;"> {{modelObject.DepartmentName}}</p>
                       </div>
                      
                   </div>
                 </div>

                 <div class="row" style="padding: 5px;">
                    <div class="col-md-6">                 
                       <div class="mb-4">
                        <p class="text-muted mb-1"> {{ "JobType:" | translate }}</p>
                        <p style="font-weight: bold;"> {{modelObject.JobTypeName}}</p>
                       </div>
                   </div>                

                   <div class="col-md-6">
                       <div class="mb-4"> 
                        <p class="text-muted mb-1"> {{ "JobLevel:" | translate }}</p>
                        <p style="font-weight: bold;"> {{modelObject.JobLevelName}}</p>
                       </div>
                      
                   </div>
                 </div>
                 

                 <div class="row" style="padding: 5px;">
                    <div class="col-md-6">                 
                       <div class="mb-4">
                        <p class="text-muted mb-1"> {{ "JoiningDate:" | translate }}</p>
                        <p style="font-weight: bold;"> {{modelObject.JoiningDate}}</p>
                       </div>
                   </div>                

                   <div class="col-md-6">
                       <div class="mb-4"> 
                        <p class="text-muted mb-1"> {{ "Branch:" | translate }}</p>
                        <p style="font-weight: bold;"> {{modelObject.BranchName}}</p>
                       </div>
                      
                   </div>
                 </div>

                 
                 <div class="row" style="padding: 5px;">
                    <div class="col-md-6">                 
                       <div class="mb-4">
                        <p class="text-muted mb-1"> {{ "FinancialHistory:" | translate }}</p>
                        <p style="font-weight: bold;"> {{modelObject.Day}} {{modelObject.Month}}</p>
                       </div>
                   </div>                
                 </div>


                </div>
              </div>
           
          </div>
          
      
    </tab>
    <tab heading="{{'Contracts' | translate}}" class="card-body">
      <div >
 
        <div id="divmiddle" style="display: block; margin-top: 5px">
          <div style="width: 100%">
            <div>
              <div class="card" style="min-width: 900px !important">
                <div class="card-body">
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </tab>
      <tab heading="{{'NotesAndAttachments' | translate}}" class="card-body">
        <div >
          <div id="divmiddle" style="display: block; margin-top: 5px">
            <div style="width: 100%">
              <div>
                <div class="card" style="min-width: 900px !important">
                  <div class="card-body">
                    <table class="table table-hover" style="margin: unset !important" >
                      <thead class="no-border-top">
                      
                        <tr style="font-size: large ; color: #0189DE;"  >
                          <td>{{'User'|translate}}</td>
                          <td>
                          </td>
                          <td>{{'Date'|translate}}</td>
                          <td>{{'Attachments'|translate}}</td>
                          <td>{{'Status'|translate}}</td>
                          <td></td>
                          <td></td>
                        </tr>
                 
           
                    
                      </thead>
                      <tbody>
                      <tr  *ngFor="let obj of NotesList" style="font-size: 18px;">
  
                        <td>
                        </td>
                        <td>
                        </td>
                        <td >
                          {{obj.Date| date:'fullDate'}}
                        
                          <p>
                            {{obj.Date | date:'mediumTime'}}                 

                          </p>
                        </td>     
                        <td>
                          {{obj.ActionName}}
                        </td>  
                        <td>
                          {{obj.ActionStatusName}}
                        </td>               
                        <td style="text-align: center">
                          <i
                            class="simple-icon-pencil"
                            (click)="EditNotes(obj.Id)"
                            tooltip="{{ 'Edit' | translate }}"
                            placement="auto"
                            style="
                              font-size: 20px;
                              font-weight: bolder;
                              cursor: pointer;
                              vertical-align: middle;
                              color: #ffc107;
                            "
                           
                           
                          ></i>
                        </td>
                        <td style="text-align: center">
                          <i
                            class="simple-icon-close"
                            (click)="deleteNotes(obj.Id)"
                            tooltip="{{ 'Delete' | translate }}"
                            placement="auto"
                            style="
                              font-size: 20px;
                              font-weight: bolder;
                              cursor: pointer;
                              vertical-align: middle;
                              color: #dc3545;
                            "
                          ></i>
                        </td>
                      
                      </tr>
  
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </tab>
      <tab heading="{{'RecordActivities' | translate}}" class="card-body">
     
      </tab>
  
  </tabset>
</div>
<div bsModal #delModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">
  <div class="modal-dialog modal-sm">
      <div class="modal-content" style="border: 3px solid red;">
          <button type="button" class="close pull-right" aria-label="Close" (click)="delModal.hide()"
              style="margin-right: 20px; margin-left: 20px; margin-top: 10px; width: 20px; display: inline;">
              <span aria-hidden="true">&times;</span>
          </button>
          <div class="modal-header" style="display: inline; padding: 0px 0px 5px 0px;">
              <h4 id="dialog-child-name" class="modal-title pull-left" style="text-align: center; color: red;">
                  {{"ConfirmDel"+translationName |
                  translate}}</h4>
          </div>
          <div class="modal-body">
              <!-- <div class="form-group form-primary" *ngIf="deletionList.length < NotesObject.length || deletionList.length === 1" style="text-align: center;">
                  <div *ngFor="let item of deletionList;" style="text-align: center;">
                      <label type="text">- {{item.Name}}</label>
                      <span class="form-bar"></span>
                  </div>
              </div> -->
              <div class="form-group form-primary" style="text-align: center;" *ngIf="deletionList.length > 1 && 
                  deletionList.length === NotesObject.length">
                  <label type="text">
                      <h1 style="font-weight: bolder; text-align: center;">{{translationName+"DeleteAll" | translate}}</h1>
                  </label>
                  <span class="form-bar"></span>
              </div>

              <div  *ngFor="let item of deletionList;" style="margin-left: 5px; text-align: center; margin-top: 30px;">
                  <button class="btn btn-danger" id="sideModalRefDeleteButton" (click)="deleteNotes(item.Id)"
                      [disabled]="currentAction==='loading'"
                      style="font-weight: bold; width: 100%; text-align: center;">{{"Delete" | translate}}</button>
                  <button class="btn btn-light" (click)="delModal.hide()"
                      style="font-weight: bold; width: 100%; text-align: center; margin-top: 10px;">{{"Close" |
                      translate}}</button>
              </div>
          </div>
      </div>
  </div>
</div>
 <td></td>
  <td></td>
  <td></td>  <td></td>
  <td></td>
  <td></td>
 