import { Component, HostBinding, Renderer2, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { SidePopupComponent } from '../../app/aio/shared/side-popup/side-popup.component';
import { PurchaseQuotationDetailsModel } from "./PurchaseQuotationDetailsModel";
import { PurchaseQuotationService } from "./PurchaseQuotationService";
import { PurchaseQuotationItemModel } from "./PurchaseQuotationItemModel";
import { PurchaseQuotationFileModel } from "./PurchaseQuotationFileModel";
import { PurchaseQuotationServiceModel } from "./PurchaseQuotationServiceModel";
import { CodingUnitModel } from "src/app/invoices/coding-units/CodingUnitModel";
import { CodingUnitService } from 'src/app/invoices/coding-units/CodingUnitService';
import { SupplierService } from "../../purchases/purchase-orders/SupplierService";
import { SupplierModel } from "../../purchases/purchase-orders/SupplierModel";
import { TaxTypesModel } from "src/app/invoices/tax-types/TaxTypesModel";
import { TaxTypesService } from "src/app/invoices/tax-types/TaxTypesService";
import { DatePipe, formatDate } from '@angular/common';
import { PurchaseQuotationItemTaxModel } from './PurchaseQuotationItemTaxModel';
import { ConfigPurchasesService } from '../config-purchases/ConfigPurchasesService';
import { ConfigPurchasesModel } from '../config-purchases/ConfigPurchasesModel';
import { TableEngine } from '../../app/aio/shared/TableEngine';
import { ListPageHeaderComponent } from 'src/app/containers/pages/list-page-header/list-page-header.component';
import { StoreItemsService } from 'src/app/Invintory/store-items-service.service';

@Component({
  selector: 'app-purchase-quotations',
  templateUrl: './purchase-quotations.component.html',
  styleUrls: ['./purchase-quotations.component.scss']
})
export class PurchaseQuotationsComponent {
  ////////////////////////////////////////////////////////////////////////////
  //  VARYING :
  //  Modify per similar component.
  ////////////////////////////////////////////////////////////////////////////

  //Enable debugMode to make API modules return static data from within the service class
  debugMode: boolean = false;

  constructor(private formBuilder: FormBuilder, private modelService: PurchaseQuotationService, private renderer: Renderer2,
    private notifications: NotificationsService, private translate: TranslateService,
    private itemService: StoreItemsService, private datePipe: DatePipe,
    private supplierService: SupplierService, private codingUnitService: CodingUnitService,
    private taxTypeService: TaxTypesService, private configPurchasesService: ConfigPurchasesService
  ) {
  }


  listHeader = new ListPageHeaderComponent();

  tableEngine = new TableEngine(this.translate, this.notifications);
  currentScreen: any = 'Table';
  tableView: any = true;

  translationName: string = 'PurchaseQuotation'

  sortableKeys = [];
  searchableKeys = ['Date'];

  modelObject: PurchaseQuotationDetailsModel = new PurchaseQuotationDetailsModel();

  ogData: PurchaseQuotationDetailsModel[] = [];
  
  mainForm: FormGroup = new FormGroup({
    Id: new FormControl(),
    Date: new FormControl(),
    ExpirationDate: new FormControl(),
    SupplierId: new FormControl(),
    ShippingFees: new FormControl(),
    ShippingAddress1: new FormControl(),
    ShippingAddress2: new FormControl(),
    Description: new FormControl(),
    DiscountValue: new FormControl(),
    DiscountIsRate: new FormControl(),
  });

  declare lang: any;
  declare itemOrder: any;
  currentAction = '';
  savedPage = 0;
  currentPage = 1;
  deletionList: any[] = [];

  @ViewChild('sideModalRef', { static: true }) sideModalRef: SidePopupComponent = null;
  @ViewChild('delModal', { static: true }) delModal: ModalDirective;
  @ViewChild('smallModal', { static: true }) smallModal: ModalDirective = null;
  @ViewChild('taxModal', { static: true }) taxModal: ModalDirective;
  formPresentationModal: any;
  ngOnInit(): void {
    if (this.debugMode) {
      this.enableDebugOnAllServices();
    }

    this.lang = localStorage.getItem('theme_lang');
    this.itemOrder = { label: 'PurchaseQuotationCode', property: 'Code' };
    this.sortableKeys = [
      { label: 'PurchaseQuotationCode', property: 'Code' },
      { label: 'PurchaseQuotationDate', property: 'Date' },
      { label: 'PurchaseQuotationExpirationDate', property: 'ExpirationDate' },
      { label: 'PurchaseQuotationSupplierName', property: this.lang === 'ar-EG' ? 'SupplierNameAr' : 'SupplierNameEn' },
      { label: 'PurchaseQuotationShippingAddress1', property: 'ShippingAddress1' },
      { label: 'PurchaseQuotationShippingAddress2', property: 'ShippingAddress2' },
      { label: 'PurchaseQuotationTotalPurchaseQuotationValue', property: 'TotalPurchaseQuotationValue' },
    ];


    this.tableEngine.loadTableEngine(this.ogData, this.modelService, this.modelObject,
      this.searchableKeys, this.sortableKeys, this.mainForm, this.translationName, this.itemOrder,
      this.sideModalRef, this.delModal, this.smallModal, this.lang
    )

    this.loadServices();
  }


  enableDebugOnAllServices() {
    //this.modelService.debugMode = true;
    //this.supplierService.debugMode = true;
    //this.taxTypeService.debugMode = true;
    // this.itemService.debugMode = true;
    //this.codingUnitService.debugMode = true;
    //this.storeDataService.debugMode = true;
    //this.supplierService.debugMode = true;

  }

  lastCode = 0;
  showModal(Id, action) {
    console.log("Items",this.itemList);
    console.log("Units",this.codingUnitList);
    
    this.newPurchaseQuotationItem = {};
    this.newPurchaseQuotationItem.Taxes = [];
    this.newPurchaseQuotationItemTax = {};
    this.newPurchaseQuotationFile = {};
    this.newPurchaseQuotationService = {};

    this.quotationItemForm = this.createPurchaseQuotationItemForm(this.newPurchaseQuotationItem, this.formBuilder);
    this.quotationItemTaxForm = this.createPurchaseQuotationItemTaxForm(this.newPurchaseQuotationItem, this.formBuilder);
    this.quotationServiceForm = this.createPurchaseQuotationServiceForm(this.newPurchaseQuotationItem, this.formBuilder);

    if (action === 'Add') {
      this.addedItemList = [];
      this.addedServiceList = [];
      this.addedFileList = [];
      this.newPurchaseQuotationItemInternalId = 0;
      this.newPurchaseQuotationItemTaxInternalId = 0;
      this.newPurchaseQuotationServiceInternalId = 0;
      this.newPurchaseQuotationFileInternalId = 0;
      if(this.configPurchases.EnableAutoQuotationRequestCodes==true){
        this.lastCode = this.configPurchases.StartingQuotationRequestCode;
        for (let index = 0; index < this.tableEngine.ogData.length; index++) {
          if(this.tableEngine.ogData.findIndex(x=>x.Code == this.lastCode) >= 0)
          { this.lastCode++; console.log(this.lastCode);
          } 
          else { break; }
        }
      }else{
        //this.lastCode = this.tableEngine.ogData.length>0?this.tableEngine.ogData[this.tableEngine.ogData.length - 1].Code + 1:0;
        this.lastCode = 0;
      }
      this.modelObject.Code = this.lastCode;
      this.checkCode();
      
      this.tableEngine.showAddNewModalRef(this.createForm, this.formBuilder)
    }
    else if (action === 'Edit') {

      
      this.tableEngine.showEditModalRef(Id, this.createForm, this.formBuilder);
      //this.tableEngine.showEditModalRef(Id, this.createForm, this.formBuilder)
      this.modelService.getByIdWithItems(Id).subscribe(
        detailedObject => {
          console.log("detailed object", detailedObject);
          
          let targetIndex = this.tableEngine.ogData.findIndex(i => i.Id === Id);
          this.tableEngine.ogData[targetIndex] = detailedObject;
          this.tableEngine.showEditModalRef(Id, this.createForm, this.formBuilder)
          
          this.lastCode = this.tableEngine.modelObject.Code
          
          this.modelObject = this.tableEngine.modelObject;
          this.mainForm = this.tableEngine.mainForm;
          this.tableView = false;

          this.addedItemList = [];
          this.addedServiceList = [];
          this.addedFileList = [];
          this.addedItemList = [...this.modelObject.Items];
          this.addedServiceList = [...this.modelObject.Services];
          this.addedFileList = [...this.modelObject.Files];
      
          this.calculateTotalPurchaseQuotationValue();
        }
      )
    }
    else if (action === 'Info') {
      this.modelService.getByIdWithItems(Id).subscribe(
        detailedObject => {
          console.log("detailed object", detailedObject);
          
          let targetIndex = this.tableEngine.ogData.findIndex(i => i.Id === Id);
          this.tableEngine.ogData[targetIndex] = detailedObject;
          this.tableEngine.showInfoModalRef(Id, this.createForm, this.formBuilder)
          
          this.lastCode = this.tableEngine.modelObject.Code
          
          this.modelObject = this.tableEngine.modelObject;
          this.mainForm = this.tableEngine.mainForm;
          this.tableView = false;

          this.addedItemList = [];
          this.addedServiceList = [];
          this.addedFileList = [];
          this.addedItemList = [...this.modelObject.Items];
          this.addedServiceList = [...this.modelObject.Services];
          this.addedFileList = [...this.modelObject.Files];
      
          this.calculateTotalPurchaseQuotationValue();
        } 
      )
    }
    this.tableView = false;
    this.modelObject = this.tableEngine.modelObject;
    this.mainForm = this.tableEngine.mainForm;
    console.log((action + " object"), this.modelObject);
    console.log("modelObject after entering ", this.modelObject);
  }
  currentDate='';
  currentExpirationDate='';
  createForm(modelObject, formBuilder): FormGroup {
    return formBuilder.group({
      Date: [modelObject.Date?modelObject.Date.toString().slice(0, 10) :modelObject.Date, Validators.compose([Validators.required])],
      ExpirationDate: [modelObject.ExpirationDate?modelObject.ExpirationDate.toString().slice(0, 10) :modelObject.ExpirationDate, Validators.compose([Validators.required])],
      SupplierId: [modelObject.SupplierId, Validators.compose([Validators.required])],
      ShippingFees: [modelObject.ShippingFees, Validators.compose([Validators.required, Validators.pattern(/^([0-9]+)([0-9])*(\.[0-9]+)?$/)])],
      ShippingAddress1: [modelObject.ShippingAddress1, Validators.compose([Validators.required])],
      ShippingAddress2: [modelObject.ShippingAddress2],
      Description: [modelObject.Description],
      DiscountValue: [modelObject.DiscountValue, Validators.compose([Validators.required, Validators.pattern(/^([0-9]+)([0-9])*(\.[0-9]+)?$/)])],
      DiscountIsRate: [modelObject.DiscountIsRate],
    });
  }

  addObject() {

    this.currentAction = 'loading';
    
    let tempItemList: PurchaseQuotationItemModel[] = [];
    let tempItem: PurchaseQuotationItemModel;
    let itemDebugId = 0;
    let taxDebugId = 0;
    for (let index = 0; index < this.addedItemList.length; index++) {
      tempItem = new PurchaseQuotationItemModel();
      tempItem = this.addedItemList[index];
      tempItem.Id = this.debugMode ? itemDebugId : 0;
      tempItem.PurchaseQuotationId = 0;
      tempItemList.push(tempItem);  

      itemDebugId++;
      
      let tempTaxList: PurchaseQuotationItemTaxModel[] = [];
      let tempTax: PurchaseQuotationItemTaxModel;
      for (let taxIndex = 0; taxIndex < this.addedItemList[index].Taxes.length; taxIndex++) {
        tempTax = new PurchaseQuotationItemTaxModel();
        tempTax = this.addedItemList[index].Taxes[taxIndex];
        tempTax.Id = this.debugMode ? taxDebugId : 0;
        tempTax.PurchaseQuotationItemId = 0;
        tempTaxList.push(tempTax);
  
        taxDebugId++;``
      }
    }
    
    let tempServiceList: PurchaseQuotationServiceModel[] = [];
    let tempService: PurchaseQuotationServiceModel;
    let serviceDebugId = 0;
    for (let index = 0; index < this.addedServiceList.length; index++) {
      tempService = new PurchaseQuotationServiceModel();
      tempService = this.addedServiceList[index];
      tempService.Id = this.debugMode ? serviceDebugId : 0;
      tempService.PurchaseQuotationId = 0;
      tempServiceList.push(tempService);

      serviceDebugId++;
    }
    
    let tempFileList: PurchaseQuotationFileModel[] = [];
    let tempFile: PurchaseQuotationFileModel;
    let fileDebugId = 0;
    for (let index = 0; index < this.addedFileList.length; index++) {
      tempFile = new PurchaseQuotationFileModel();
      tempFile = this.addedFileList[index];
      tempFile.Id = this.debugMode ? fileDebugId : 0;
      tempFile.PurchaseQuotationId = 0;
      tempFileList.push(tempFile);

      fileDebugId++;
    }
    let lastId = this.tableEngine.ogData.length>0?this.tableEngine.ogData[this.tableEngine.ogData.length - 1].Id:0;

    this.modelObject = new PurchaseQuotationDetailsModel();
    //this.modelObject.Id = this.debugMode ? lastId + 1 : 0;
    this.modelObject.Id = 0;

    this.modelObject.Code = this.lastCode;
    console.log(this.lastCode);
    
    let supplier = this.getSupplierById(this.mainForm.getRawValue().SupplierId);
    this.modelObject.SupplierId = supplier.Id;
    this.modelObject.SupplierNameAr = supplier.NameAr;
    this.modelObject.SupplierNameEn = supplier.NameEn;

    let date = new Date(this.mainForm.getRawValue().Date);
    let expirationDate = new Date(this.mainForm.getRawValue().ExpirationDate);
    this.modelObject.Date = date;
    this.modelObject.ExpirationDate = expirationDate;
    this.modelObject.Month = date.getMonth();
    this.modelObject.Year = date.getFullYear();

    this.modelObject.ShippingFees = this.mainForm.getRawValue().ShippingFees;
    this.modelObject.ShippingAddress2 = this.mainForm.getRawValue().ShippingAddress2;
    this.modelObject.ShippingAddress1 = this.mainForm.getRawValue().ShippingAddress1;
    this.modelObject.DiscountValue = this.mainForm.getRawValue().DiscountValue;
    this.modelObject.DiscountIsRate = this.mainForm.getRawValue().DiscountIsRate;

    this.modelObject.Description = this.mainForm.getRawValue().Description;
    this.modelObject.Items = tempItemList;
    this.modelObject.Services = tempServiceList;
    this.modelObject.Files = tempFileList;

    this.modelObject.TotalPurchaseQuotationValue = this.calculateTotalPurchaseQuotationValue();

    this.modelObject.TotalItemPrices = this.currentTotalItemPrices;
    this.modelObject.TotalItemDiscounts = this.currentTotalItemDiscounts;
    this.modelObject.TotalItemNetPrices = this.currentTotalItemNetPrices;
    this.modelObject.TotalItemTaxes = this.currentTotalItemTaxes;
    this.modelObject.TotalItemGrossPrices = this.currentTotalItemGrossPrices;
    this.modelObject.TotalServices = this.currentTotalServices;

    this.executeAddObject();
  }

  executeAddObject() {
    this.modelService.insertWithItems(this.modelObject).subscribe(result => {
      if (result === true || result > 0 ) {
        this.tableEngine.loadData();
        this.currentAction = '';
        this.tableEngine.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyAdded"));
        if (this.sideModalRef === undefined) {
          if (this.smallModal != undefined) {
            this.tableEngine.smallModal.hide();
          }
          else {
            this.tableView = this.prevAction === 'AddThenNew' ? false : true;
            
            this.prevAction = '';
          }
        }
        else {
          this.tableEngine.sideModalRef.modalRef.hide();
        }
        
        if(this.prevAction != 'AddThenNew'){
          this.goBack();
        }
      }
      else {
        this.tableEngine.toastError(this.translate.instant("Error"), this.translate.instant("FailedAdd"));
        this.currentAction = '';
      }
    })
  }

  editObject() {
    this.currentAction = 'loading';
    this.tableEngine.savedPage = this.tableEngine.currentPage;

    let tempItemList: PurchaseQuotationItemModel[] = [];
    let tempItem: PurchaseQuotationItemModel;
    let itemDebugId = 1;
    let taxDebugId = 0;
    for (let index = 0; index < this.addedItemList.length; index++) {
      tempItem = new PurchaseQuotationItemModel();
      tempItem = this.addedItemList[index];
      tempItem.Id = this.debugMode ? itemDebugId : 0;
      tempItem.PurchaseQuotationId = 0;
      tempItemList.push(tempItem);

      itemDebugId++;
      
      let tempTaxList: PurchaseQuotationItemTaxModel[] = [];
      let tempTax: PurchaseQuotationItemTaxModel;
      for (let taxIndex = 0; taxIndex < this.addedItemList[index].Taxes.length; taxIndex++) {
        tempTax = new PurchaseQuotationItemTaxModel();
        tempTax = this.addedItemList[index].Taxes[taxIndex];
        tempTax.Id = this.debugMode ? taxDebugId : 0;
        tempTax.PurchaseQuotationItemId = 0;
        tempTaxList.push(tempTax);
  
        taxDebugId++;``
      }
    }
    
    let tempServiceList: PurchaseQuotationServiceModel[] = [];
    let tempService: PurchaseQuotationServiceModel;
    let serviceDebugId = 1;
    for (let index = 0; index < this.addedServiceList.length; index++) {
      tempService = new PurchaseQuotationServiceModel();
      tempService = this.addedServiceList[index];
      tempService.Id = this.debugMode ? serviceDebugId : 0;
      tempService.PurchaseQuotationId = 0;
      tempServiceList.push(tempService);

      serviceDebugId++;
    }
    
    let tempFileList: PurchaseQuotationFileModel[] = [];
    let tempFile: PurchaseQuotationFileModel;
    let fileDebugId = 1;
    for (let index = 0; index < this.addedFileList.length; index++) {
      tempFile = new PurchaseQuotationFileModel();
      tempFile = this.addedFileList[index];
      tempFile.Id = this.debugMode ? fileDebugId : 0;
      tempFile.PurchaseQuotationId = 0;
      tempFileList.push(tempFile);

      fileDebugId++;
    }
    
    //this.calculateTotalItemValue();
    //let newString = new DatePipe('en-US').transform(this.mainForm.getRawValue().Date, 'dd/MM/yyyy');
    //console.log("newstring", newString);
    
    //let date: Date = this.getFormattedDate(new DatePipe('en-US').transform(this.mainForm.getRawValue().Date, 'dd/MM/yyyy'));
    let date = new Date(this.mainForm.getRawValue().Date);
    let expirationDate = new Date(this.mainForm.getRawValue().ExpirationDate);
    let tempObj = new PurchaseQuotationDetailsModel();
    tempObj.Id= this.modelObject.Id,
    tempObj.Code = this.lastCode;

    let supplier = this.getSupplierById(this.mainForm.getRawValue().SupplierId);
    tempObj.SupplierId = supplier.Id;
    tempObj.SupplierNameAr = supplier.NameAr;
    tempObj.SupplierNameEn = supplier.NameEn;

    tempObj.ShippingFees= this.mainForm.getRawValue().ShippingFees,
    tempObj.ShippingAddress1= this.mainForm.getRawValue().ShippingAddress1,
    tempObj.ShippingAddress2= this.mainForm.getRawValue().ShippingAddress2,
    tempObj.DiscountValue= this.mainForm.getRawValue().DiscountValue,
    tempObj.DiscountIsRate= this.mainForm.getRawValue().DiscountIsRate,
    tempObj.Date= date,
    tempObj.ExpirationDate= expirationDate,
    tempObj.Year = date.getFullYear();
    tempObj.Month = date.getMonth();
    tempObj.Description= this.mainForm.getRawValue().Description,

    tempObj.Items= tempItemList,
    tempObj.Services= tempServiceList,
    tempObj.Files= tempFileList,
  
    tempObj.TotalPurchaseQuotationValue= this.calculateTotalPurchaseQuotationValue(),

    tempObj.TotalItemPrices= this.currentTotalItemPrices,
    tempObj.TotalItemDiscounts= this.currentTotalItemDiscounts,
    tempObj.TotalItemNetPrices= this.currentTotalItemNetPrices,
    tempObj.TotalItemTaxes= this.currentTotalItemTaxes,
    tempObj.TotalItemGrossPrices= this.currentTotalItemGrossPrices,
    tempObj.TotalServices= this.currentTotalServices,
      
    this.executeEditObject(tempObj);
    console.log("saved object", tempObj);
  }

  executeEditObject(Object) {
    this.modelService.updateWithItems(Object).subscribe(result => {
      if (result === true) {
        this.tableEngine.loadData();
        this.currentAction = '';
        this.tableEngine.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyEdited"));

        if (this.sideModalRef === undefined) {
          if (this.smallModal != undefined) {
            this.tableEngine.smallModal.hide();
          }
          else {
            this.tableView = true;
          }
        }
        else {
          this.tableEngine.sideModalRef.modalRef.hide();
        }
        
        this.goBack();
        
      }
      else {
        this.tableEngine.toastError(this.translate.instant("Error"), this.translate.instant("FailedEdit"));
        this.currentAction = '';
      }
    })
  }

  //Deletion methods:
  //Customize if needed.
  //Pass the target object as parameter if the target API can only delete singular objects and not lists.
  deleteObject() {
    this.savedPage = this.currentPage;
    //prevent multiclicks. re enable after API response
    this.currentAction = 'loading';
    this.executeDeleteObject();
  }

  //Same here...
  executeDeleteObject() {
    this.modelService.deleteList(this.tableEngine.deletionList).subscribe(result => {
      if (result === true) {
        this.tableEngine.loadData();
        this.currentAction = '';
        this.tableEngine.finalizeDeletion();
      }
      else {
        this.tableEngine.toastError(this.translate.instant("Error"), this.translate.instant("FailedDelete"));
        this.currentAction = '';
      }
    })
  }
  ////////////////////////////////////////////////////////////////////////////
  //
  //
  //
  //
  //
  ////////////////////////////////////////////////////////////////////////////
  //  EXCLUSIVE :
  //  Specifically for this component.
  ///////////////////////////////
  //Get any additional data here
  SearchFrom: Date = null;
  SearchTo: Date = null;

  clearSearch() {
    this.SearchFrom = null;
    this.SearchTo = null;
  }

  prevAction = '';
  addAndNew() {
    this.prevAction = 'AddThenNew';
    this.addObject();
    this.showModal(null, 'Add');
  }

  configPurchases = new ConfigPurchasesModel();
  loadServices() {
    this.currentAction = 'loading';

    this.supplierService.getAll().subscribe(
      supplierData => {
        this.supplierList = supplierData;

        this.codingUnitService.getAll().subscribe(
          codingUnitData => {
            this.codingUnitList = codingUnitData;

            this.itemService.getAll().subscribe(
              itemData => {
                this.itemList = itemData;

                this.taxTypeService.getAll().subscribe(
                  taxTypeData => {
                    this.taxTypeList = taxTypeData;

                    this.configPurchasesService.getFirst().subscribe(
                      configPurchasesData => {
                        this.configPurchases = configPurchasesData;
      
                        this.tableEngine.loadData();
                      }
                    )
                  }
                )
              }
            )
          }
        )
      }
    )


  }
  
  addedItemList: PurchaseQuotationItemModel[] = [];
  addedServiceList: PurchaseQuotationServiceModel[] = [];
  addedFileList: PurchaseQuotationFileModel[] = [];
  codingUnitList: CodingUnitModel[] = [];
  itemList: any[] = [];
  supplierList: SupplierModel[] = [];
  taxTypeList: TaxTypesModel[] = [];
  
  getItemById(Id: any): any {
    return this.itemList.find(i => i.Id === Id);
  }
  getUnityById(Id: any): any {
    return this.codingUnitList.find(i => i.Id === Id);
  }
  getTaxTypeById(Id: any): any {
    return this.taxTypeList.find(i => i.Id === Id);
  }
  getSupplierById(Id: any): any {
    return this.supplierList.find(i => i.Id === Id);
  }

  quotationItemForm: FormGroup = new FormGroup({
    ItemId: new FormControl(),
    Description: new FormControl(),
    UnitCost: new FormControl(),
    Quantity: new FormControl(),
    DiscountValue: new FormControl(),
    DiscountIsRate: new FormControl(),
  });
  
  quotationItemTaxForm: FormGroup = new FormGroup({
    Id: new FormControl(),
    TaxId: new FormControl(),
  });

  quotationServiceForm: FormGroup = new FormGroup({
    Id: new FormControl(),
    NameAr: new FormControl(),
    //NameEn: new FormControl(),
    Value: new FormControl(),
    //IsRate: new FormControl(),
  });

  createPurchaseQuotationItemForm(quotationItemObject, formBuilder): FormGroup {
    return formBuilder.group({
      Id: [quotationItemObject.Id >= 0 ? quotationItemObject.Id : 0],
      ItemId: [null ,Validators.compose([Validators.required])],
      Quantity: [null ,Validators.compose([Validators.required, Validators.pattern(/^([1-9]+)([0-9])*$/)])],
      UnitCost: [null ,Validators.compose([Validators.required, Validators.pattern(/^([0-9]+)([0-9])*(\.[0-9]+)?$/)])],
      Description: [null],
      DiscountValue: [null ,Validators.compose([Validators.required, Validators.pattern(/^([0-9]+)([0-9])*(\.[0-9]+)?$/)])],
      DiscountIsRate: [true],
    });
  }
  
  createPurchaseQuotationItemTaxForm(quotationItemTaxObject, formBuilder): FormGroup {
    return formBuilder.group({
      Id: [quotationItemTaxObject.Id >= 0 ? quotationItemTaxObject.Id : 0],
      TaxId: [null ,Validators.compose([Validators.required])],
    });
  }
  
  createPurchaseQuotationServiceForm(quotationServiceObject, formBuilder): FormGroup {
    return formBuilder.group({
      Id: [quotationServiceObject.Id >= 0 ? quotationServiceObject.Id : 0],
      //NameEn: [null , Validators.compose([Validators.required, Validators.minLength(2)])],
      NameAr: [null , Validators.compose([Validators.required])],
      Value: [null ,Validators.compose([Validators.required, Validators.pattern(/^([0-9]+)([0-9])*(\.[0-9]+)?$/)])],
      //IsRate: [null ,Validators.compose([Validators.required])],
    });
  }
  newPurchaseQuotationItem: PurchaseQuotationItemModel = new PurchaseQuotationItemModel();
  newPurchaseQuotationItemInternalId = 0;
  newPurchaseQuotationItemTax: PurchaseQuotationItemTaxModel = new PurchaseQuotationItemTaxModel();
  newPurchaseQuotationItemTaxInternalId = 0;

  newPurchaseQuotationService: PurchaseQuotationServiceModel = new PurchaseQuotationServiceModel();
  newPurchaseQuotationServiceInternalId = 0;
  
  newPurchaseQuotationFile: PurchaseQuotationFileModel = new PurchaseQuotationFileModel();
  newPurchaseQuotationFileInternalId = 0;

  //items
  addPurchaseQuotationItem(): any {
    
    let unitCost = this.quotationItemForm.getRawValue().UnitCost;
    let quantity = this.quotationItemForm.getRawValue().Quantity;
    let totalPrice = unitCost * quantity;
    let discountValue = this.quotationItemForm.getRawValue().DiscountValue;
    let netPrice = this.quotationItemForm.getRawValue().DiscountIsRate? totalPrice - (discountValue * totalPrice / 100) : totalPrice -  discountValue;
    let totalTaxValues = 0;
    this.newPurchaseQuotationItem.Taxes.forEach(tax => {
      totalTaxValues += tax.TaxIsRate ? tax.TaxValue * netPrice / 100 : tax.TaxValue;
    });
    let grossPrice = netPrice + totalTaxValues;

    
    let tempSourceItem = this.getItemById(this.quotationItemForm.getRawValue().ItemId);
    // let tempSourceUnit = this.getItemById(this.newPurchaseQuotationItem.UnitId);
    let temp = new PurchaseQuotationItemModel();
    temp.Id = this.newPurchaseQuotationItemInternalId,
    temp.ItemId = this.quotationItemForm.getRawValue().ItemId,
    temp.ItemNameAr = tempSourceItem.NameAr;
    temp.ItemNameEn = tempSourceItem.NameEn;
    // temp.UnitId = this.newPurchaseQuotationItem.UnitId,
    // temp.UnitNameAr = tempSourceUnit.NameAr;
    // temp.UnitNameEn = tempSourceUnit.NameEn;
    temp.Description = this.quotationItemForm.getRawValue().Description,
    temp.UnitCost = unitCost,
    temp.Quantity = quantity,
    temp.TotalPrice = totalPrice,
    temp.DiscountValue = discountValue,
    temp.DiscountIsRate =this.quotationItemForm.getRawValue().DiscountIsRate;
    temp.NetPrice = netPrice,
    temp.Taxes = this.newPurchaseQuotationItem.Taxes,
    temp.TotalTaxValues = totalTaxValues,
    temp.GrossPrice = grossPrice,
    
    console.log("saving",this.quotationItemForm.getRawValue().DiscountIsRate);
    console.log(temp.DiscountIsRate);
    
    this.addedItemList = this.addedItemList.concat(temp)
    console.log(this.addedItemList);
    
    this.newPurchaseQuotationItemInternalId = this.newPurchaseQuotationItemInternalId + 1;

    this.newPurchaseQuotationItem = new PurchaseQuotationItemModel();

    
    this.quotationItemForm = this.createPurchaseQuotationItemForm(this.newPurchaseQuotationItem, this.formBuilder);
    
    this.calculateTotalPurchaseQuotationValue();
  }
  removePurchaseQuotationItem(Id: any): any {

    let index = this.addedItemList.findIndex(x => x.Id === Id);
    this.addedItemList.splice(index, 1);

    this.calculateTotalPurchaseQuotationValue();
  }
  changeItem(): any {
    let tempSourceItem = this.getItemById(this.quotationItemForm.getRawValue().ItemId);
    // let tempSourceUnit = this.getUnityById(tempSourceItem.CodingUnitId);
    this.newPurchaseQuotationItem.ItemId = this.quotationItemForm.getRawValue().ItemId;
    // this.newPurchaseQuotationItem.UnitId = this.quotationItemForm.getRawValue().ItemId>0?tempSourceUnit.Id:0;
    this.newPurchaseQuotationItem.ItemNameAr = tempSourceItem.NameAr;
    this.newPurchaseQuotationItem.ItemNameEn = tempSourceItem.NameEn;
    // this.newPurchaseQuotationItem.UnitNameAr = tempSourceUnit.NameAr;
    // this.newPurchaseQuotationItem.UnitNameEn = tempSourceUnit.NameEn;
    this.calculateItemValues();
    //this.calculateTotalPurchaseQuotationValue();
  }
  
  //services
  addPurchaseQuotationService(): any {

    let temp = new PurchaseQuotationServiceModel();
    temp.Id= this.newPurchaseQuotationServiceInternalId,
    //PurchaseQuotationId: this.modelObject.Id,
    temp.NameAr= this.quotationServiceForm.getRawValue().NameAr,
    temp.NameEn= this.quotationServiceForm.getRawValue().NameEn,
    temp.Value= this.quotationServiceForm.getRawValue().Value,
    //IsRate= this.quotationServiceForm.getRawValue().IsRate,
    temp.IsRate = this.quotationServiceForm.getRawValue().IsRate;
    
    this.addedServiceList = this.addedServiceList.concat(temp)
    this.newPurchaseQuotationServiceInternalId = this.newPurchaseQuotationServiceInternalId + 1;
    this.newPurchaseQuotationService = new PurchaseQuotationServiceModel();
    this.quotationServiceForm = this.createPurchaseQuotationServiceForm(this.newPurchaseQuotationService, this.formBuilder);
    
    this.calculateTotalPurchaseQuotationValue();
  }
  removePurchaseQuotationService(Id: any): any {

    let index = this.addedServiceList.findIndex(x => x.Id === Id);
    this.addedServiceList.splice(index, 1);

    this.calculateTotalPurchaseQuotationValue();
  }
  
  //taxes
  addPurchaseQuotationItemTax(): any {
    
    let tax: TaxTypesModel = this.getTaxTypeById(this.quotationItemTaxForm.getRawValue().TaxId);
    let temp = new PurchaseQuotationItemTaxModel();
    temp.Id= this.newPurchaseQuotationItemTaxInternalId,
    //temp.PurchaseQuotationItemId= this.modelObject.Id,
    temp.TaxId= tax.Id,
    temp.TaxNameAr= tax.NameAr,
    temp.TaxNameEn = tax.NameEn,
    //temp.TaxIsRate= tax.IsRate,
    temp.TaxValue= tax.Percentage,
    temp.TaxIsRate = tax.IsRate;

    this.newPurchaseQuotationItem.Taxes = this.newPurchaseQuotationItem.Taxes.concat(temp)
    this.newPurchaseQuotationItemTaxInternalId = this.newPurchaseQuotationItemTaxInternalId + 1;

    this.newPurchaseQuotationItemTax = new PurchaseQuotationItemTaxModel();

    
    this.quotationItemTaxForm = this.createPurchaseQuotationItemTaxForm(this.newPurchaseQuotationItemTax, this.formBuilder);
    
    this.calculateItemValues()
    //this.calculateTotalPurchaseQuotationValue();
  }
  removePurchaseQuotationItemTax(Id: any): any {

    let index = this.newPurchaseQuotationItem.Taxes.findIndex(x => x.Id === Id);
    this.newPurchaseQuotationItem.Taxes.splice(index, 1);

    this.calculateItemValues()
    //this.calculateTotalPurchaseQuotationValue();
  }
  changeTax(): any {
    let tax: TaxTypesModel = this.getTaxTypeById(this.quotationItemTaxForm.getRawValue().TaxId);
    this.newPurchaseQuotationItemTax.TaxId = tax.Id;
    this.newPurchaseQuotationItemTax.TaxNameAr = tax.NameAr;
    this.newPurchaseQuotationItemTax.TaxNameEn = tax.NameEn;
    this.newPurchaseQuotationItemTax.TaxValue = tax.Percentage;
    this.newPurchaseQuotationItemTax.TaxIsRate = tax.IsRate;

    this.calculateItemValues()
    //this.calculateTotalPurchaseQuotationValue();
  }

  //files
  addedFileUrl: string = '';
  addPurchaseQuotationFile(): any {
    let temp = new PurchaseQuotationFileModel();
    temp.Id= this.newPurchaseQuotationFileInternalId,
    //temp.PurchaseQuotationId: this.modelObject.Id,
    temp.FileUrl= this.addedFileUrl,

    this.addedFileList = this.addedFileList.concat(temp)
    this.newPurchaseQuotationFileInternalId = this.newPurchaseQuotationFileInternalId + 1;

    this.newPurchaseQuotationFile = new PurchaseQuotationFileModel();

    //reset form here?
  }
  removePurchaseQuotationFile(Id: any): any {

    let index = this.addedFileList.findIndex(x => x.Id === Id);
    this.addedFileList.splice(index, 1);
  }

  currentTotalItemPrices = 0;
  currentTotalItemDiscounts = 0;
  currentTotalItemNetPrices = 0;
  currentTotalItemTaxes = 0;
  currentTotalItemGrossPrices = 0;
  currentTotalAfterPurchaseQuotationDiscount = 0;
  currentTotalAfterShippingFees = 0;
  currentTotalServices = 0;
  currentTotalPurchaseQuotationValue = 0;
  calculateTotalPurchaseQuotationValue() {
    

      console.log(this.addedItemList);
      console.log("chagngesD");
      let value = this.mainForm.getRawValue().DiscountValue;
      if(this.mainForm.getRawValue().DiscountIsRate && value>100){
        value=100;
        this.mainForm.controls.DiscountValue.setValue(value);
      }

      console.log("entered");
      console.log();
      
      
      let totalItemPrices = 0;
      let totalItemDiscounts = 0;
      let totalItemNetPrices = 0;
      let totalItemTaxes = 0;
      let totalItemGrossPrices = 0;
      let totalAfterPurchaseQuotationDiscount = 0;
      let totalAfterShippingFees = 0;
      let totalServices = 0;
      let totalPurchaseQuotationValue = 0;
      this.addedItemList.forEach(item => {
        item.TotalPrice = item.UnitCost * item.Quantity;
        totalItemPrices += item.TotalPrice;

        totalItemDiscounts += item.DiscountIsRate?item.DiscountValue*item.TotalPrice/100:item.DiscountValue;
        item.NetPrice = item.TotalPrice - (item.DiscountIsRate?item.DiscountValue*item.TotalPrice/100:item.DiscountValue);
        totalItemNetPrices += item.NetPrice;

        item.TotalTaxValues = 0;
        item.Taxes.forEach(tax => {
          item.TotalTaxValues += (tax.TaxIsRate?(tax.TaxValue*item.NetPrice)/100:tax.TaxValue);
        });
        totalItemTaxes += item.TotalTaxValues;

        item.GrossPrice = item.NetPrice + item.TotalTaxValues;
        totalItemGrossPrices += item.GrossPrice;
      });

      
      this.addedServiceList.forEach(service => {
        totalServices += (service.IsRate?(service.Value*totalItemGrossPrices)/100:service.Value);
      });

      let finalDiscountValue = this.mainForm.getRawValue().DiscountValue;
      let finalDiscountIsRate = this.mainForm.getRawValue().DiscountIsRate;
      let finalPurchaseQuotationDiscount = finalDiscountIsRate?finalDiscountValue*totalItemGrossPrices/100:finalDiscountValue;
      totalAfterPurchaseQuotationDiscount = totalItemGrossPrices - finalPurchaseQuotationDiscount;
      totalAfterShippingFees = totalAfterPurchaseQuotationDiscount + this.mainForm.getRawValue().ShippingFees;
      totalPurchaseQuotationValue = totalAfterShippingFees + totalServices;
      
      this.currentTotalItemPrices = totalItemPrices;
      this.currentTotalItemDiscounts = totalItemDiscounts;
      this.currentTotalItemNetPrices = totalItemNetPrices;
      this.currentTotalItemTaxes = totalItemTaxes;
      this.currentTotalItemGrossPrices = totalItemGrossPrices;
      this.currentTotalServices = totalServices;
      this.currentTotalPurchaseQuotationValue = totalPurchaseQuotationValue;
      this.currentTotalAfterPurchaseQuotationDiscount = totalAfterPurchaseQuotationDiscount;
      this.currentTotalAfterShippingFees = totalAfterShippingFees;
      
      console.log("neemo",totalItemGrossPrices);
      
      return totalPurchaseQuotationValue;
    
  }
  goBack(){
    console.log("back");
    
    this.modelObject = {};
    this.addedItemList = [];
    this.addedServiceList = [];
    this.addedFileList = [];

    this.currentTotalItemPrices = 0;
    this.currentTotalItemNetPrices = 0;
    this.currentTotalItemDiscounts = 0;
    this.currentTotalServices = 0;
    this.currentTotalItemGrossPrices = 0;
    this.currentTotalItemTaxes = 0;
    this.currentTotalAfterPurchaseQuotationDiscount = 0;
    this.currentTotalAfterShippingFees = 0;
    this.currentTotalPurchaseQuotationValue = 0;

    this.newPurchaseQuotationItem = {};
    this.newPurchaseQuotationItem.Taxes = [];
    this.currentAction = "";
    this.tableView = true;

    
    console.log(this.modelObject);
    console.log(this.lastCode);
  }

  calculateItemValues(){
    if(this.quotationItemForm.getRawValue().ItemId>0){

      console.log(this.addedItemList);
      console.log("chagngesD");
      let value = this.quotationItemForm.getRawValue().DiscountValue;
      if(this.quotationItemForm.getRawValue().DiscountIsRate && value>100){
        value=100;
        this.quotationItemForm.controls.DiscountValue.setValue(value);
      }
      
      let unitCost = this.quotationItemForm.getRawValue().UnitCost;
      let quantity = this.quotationItemForm.getRawValue().Quantity;
      let totalPrice = unitCost * quantity;
      let discountValue = this.quotationItemForm.getRawValue().DiscountValue;
      let netPrice = this.quotationItemForm.getRawValue().DiscountIsRate? totalPrice - (discountValue * totalPrice / 100) : totalPrice -  discountValue;
      let totalTaxValues = 0;
      this.newPurchaseQuotationItem.Taxes.forEach(tax => {
        totalTaxValues += tax.TaxIsRate ? tax.TaxValue * netPrice / 100 : tax.TaxValue;
      });
      console.log("KEEMO",totalTaxValues);
      
      let grossPrice = netPrice + totalTaxValues;
  
      
      let temp = new PurchaseQuotationItemModel({
        Id: this.newPurchaseQuotationItemInternalId,
        ItemId: this.quotationItemForm.getRawValue().ItemId,
        ItemNameAr: this.getItemById(this.quotationItemForm.getRawValue().ItemId).Name,
        ItemNameEn: this.getItemById(this.quotationItemForm.getRawValue().ItemId).Name,
        // UnitId: this.newPurchaseQuotationItem.UnitId,
        // UnitNameAr: this.getUnityById(this.newPurchaseQuotationItem.UnitId).NameAr,
        // UnitNameEn: this.getUnityById(this.newPurchaseQuotationItem.UnitId).NameEn,
        Description: this.quotationItemForm.getRawValue().Description,
        UnitCost: unitCost,
        Quantity: quantity,
        TotalPrice: totalPrice,
        DiscountValue: discountValue,
        //DiscountIsRate: this.quotationItemForm.getRawValue().DiscountIsRate,
        NetPrice: netPrice,
        Taxes: this.newPurchaseQuotationItem.Taxes,
        TotalTaxValues: totalTaxValues,
        GrossPrice: grossPrice,
      })
      temp.DiscountIsRate=this.quotationItemForm.getRawValue().DiscountIsRate;
      
      this.newPurchaseQuotationItem = temp;
    }
  }

  showTaxModal(){
    this.taxModal.show();
  }
  
  //lastCode = 0;
  codeWasChecked = false;
  checkCode() : boolean{
    if(!(this.lastCode > 0)){
      this.codeWasChecked = false;
      console.log("bad code", this.lastCode);
      
      return this.codeWasChecked;
    }
    this.configPurchasesService.checkPurchaseQuotationCode(this.lastCode).subscribe(
        result => {
          this.codeWasChecked = result;
        }
      );
      console.log("code availability:",this.codeWasChecked);
      
    return this.codeWasChecked;
  }
  ////////////////////////////////////////////////////////////////////////////
  //
  //
  //
  //
  //
  ////////////////////////////////////////////////////////////////////////////
  //  CONSTANT :
  //  Do not change.
  ///////////////////////////////
  prepChangeSort(sortKey) {
    this.SearchFrom = null;
    this.SearchTo = null;
    
    
    this.tableEngine.changeSort(this.tableEngine.getSort(sortKey));
    this.itemOrder = this.tableEngine.itemOrder;
  }

  getFormattedDate(dateString: any){
    let splitDate: number[] = dateString.toString().split("/");

    return new Date(splitDate[2], splitDate[1]-1, splitDate[0])
  }
  ///////////////////////////////////////////////////////
  
  displayMode = 'list';
  changeDisplayMode(mode): void {
    this.displayMode = mode;
  }
  itemsPerPageChange(perPage: number): void {
    this.tableEngine.paginateData(perPage, 1)
  }
}
