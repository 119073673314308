import { Component, HostBinding, Renderer2, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';

import { ModalDirective } from 'ngx-bootstrap/modal';
import { SidePopupComponent } from '../../app/aio/shared/side-popup/side-popup.component';
import { Observable, of, Subscription } from 'rxjs';

import { NgSelectComponent } from '@ng-select/ng-select';

import { QuotationDetailsModel } from "./QuotationDetailsModel";
import { QuotationService } from "./QuotationService";
import { QuotationItemModel } from "./QuotationItemModel";
import { QuotationFileModel } from "./QuotationFileModel";
import { QuotationServiceModel } from "./QuotationServiceModel";

import { ClientModel } from "../../invoice/clients/ClientModel";
//import { StoreDataService } from "../../purchases/purchase-orders/StoreDataService";
//import { SupplierModel } from "../../purchases/purchase-orders/SupplierModel";
// import { PaymentMethodModel } from "src/app/views/purchases/purchase-orders/PaymentMethodModel";
// import { PaymentMethodService } from "src/app/views/purchases/purchase-orders/PaymentMethodService";
import { DatePipe, formatDate } from '@angular/common';
//import { StoreDataModel } from '../../purchases/purchase-orders/StoreDataModel';
import { QuotationItemTaxModel } from './QuotationItemTaxModel';
import { ItemService } from 'src/app/invoices/items/ItemService';
import { ClientService } from 'src/app/invoices/clients/ClientService';
import { TaxTypesService } from 'src/app/invoices/tax-types/TaxTypesService';
import { CodingUnitService } from 'src/app/invoices/coding-units/CodingUnitService';
import { ListPageHeaderComponent } from 'src/app/containers/pages/list-page-header/list-page-header.component';
import { TableEngine } from '../../app/aio/shared/TableEngine';
import { CodingUnitModel } from 'src/app/invoices/coding-units/CodingUnitModel';
import { ItemModel } from 'src/app/invoices/items/ItemModel';
import { TaxTypesModel } from 'src/app/invoices/tax-types/TaxTypesModel';
import { StoreItemsService } from 'src/app/Invintory/store-items-service.service';
import { StoreItemsModel } from 'src/app/Invintory/models/StoreItemsModel';




@Component({
  selector: 'app-quotations',
  templateUrl: './quotations.component.html',
  styleUrls: ['./quotations.component.scss']
})
export class QuotationsComponent {
  ////////////////////////////////////////////////////////////////////////////
  //  VARYING :
  //  Modify per similar component.
  ////////////////////////////////////////////////////////////////////////////

  //Enable debugMode to make API modules return static data from within the service class
  debugMode: boolean = true;

  constructor(private formBuilder: FormBuilder, private modelService: QuotationService, private renderer: Renderer2,
    private notifications: NotificationsService, private translate: TranslateService,
    private itemService: StoreItemsService, private datePipe: DatePipe,
    private clientService: ClientService, private codingUnitService: CodingUnitService,
    private taxTypeService: TaxTypesService,
  ) {
  }


  listHeader = new ListPageHeaderComponent();

  tableEngine = new TableEngine(this.translate, this.notifications);
  currentScreen: any = 'Table';
  tableView: any = true;

  translationName: string = 'Quotation'

  sortableKeys = [];
  searchableKeys = ['Date'];

  modelObject: QuotationDetailsModel = new QuotationDetailsModel();

  ogData: QuotationDetailsModel[] = [];
  
  mainForm: FormGroup = new FormGroup({
    Id: new FormControl(),
    Date: new FormControl(),
    ClientId: new FormControl(),
    ShippingFees: new FormControl(),
    ShippingAddress1: new FormControl(),
    ShippingAddress2: new FormControl(),
    Description: new FormControl(),
    DiscountValue: new FormControl(),
    DiscountIsRate: new FormControl(),
  });


  declare lang: any;
  declare itemOrder: any;
  currentAction = '';
  savedPage = 0;
  currentPage = 1;
  deletionList: any[] = [];

  @ViewChild('sideModalRef', { static: true }) sideModalRef: SidePopupComponent = null;
  @ViewChild('delModal', { static: true }) delModal: ModalDirective;
  @ViewChild('smallModal', { static: true }) smallModal: ModalDirective = null;
  formPresentationModal: any;
  ngOnInit(): void {
    if (this.debugMode) {
      this.enableDebugOnAllServices();
    }

    this.lang = localStorage.getItem('theme_lang');
    this.itemOrder = { label: 'QuotationCode', property: 'Code' };
    this.sortableKeys = [
      { label: 'QuotationCode', property: 'Code' },
      { label: 'QuotationDate', property: 'Date' },
      { label: 'QuotationClientName', property: this.lang === 'ar-EG' ? 'ClientNameAr' : 'ClientNameEn' },
      { label: 'QuotationShippingAddress1', property: 'ShippingAddress1' },
      { label: 'QuotationShippingAddress2', property: 'ShippingAddress2' },
      { label: 'QuotationTotalQuotationValue', property: 'TotalQuotationValue' },
    ];


    this.tableEngine.loadTableEngine(this.ogData, this.modelService, this.modelObject,
      this.searchableKeys, this.sortableKeys, this.mainForm, this.translationName, this.itemOrder,
      this.sideModalRef, this.delModal, this.smallModal, this.lang
    )

    this.loadServices();
  }


  enableDebugOnAllServices() {
    this.modelService.debugMode = false;
    // this.clientService.debugMode = true;
    // this.taxTypeService.debugMode = true;
    // this.itemService.debugMode = true;
    //this.codingUnitService.debugMode = true;
    //this.storeDataService.debugMode = true;
    //this.supplierService.debugMode = true;

  }

  lastCode = 0;
  showModal(Id, action) {
    console.log("Items",this.itemList);
    console.log("Units",this.codingUnitList);
    
    this.newQuotationItem = {};
    this.newQuotationItem.Taxes = [];
    this.newQuotationItemTax = {};
    this.newQuotationFile = {};
    this.newQuotationService = {};

    this.quotationItemForm = this.createQuotationItemForm(this.newQuotationItem, this.formBuilder);
    this.quotationItemTaxForm = this.createQuotationItemTaxForm(this.newQuotationItem, this.formBuilder);
    this.quotationServiceForm = this.createQuotationServiceForm(this.newQuotationItem, this.formBuilder);

    if (action === 'Add') {
      this.addedItemList = [];
      this.addedServiceList = [];
      this.addedFileList = [];
      this.newQuotationItemInternalId = 0;
      this.newQuotationItemTaxInternalId = 0;
      this.newQuotationServiceInternalId = 0;
      this.newQuotationFileInternalId = 0;
      this.lastCode = this.tableEngine.ogData.length>0?this.tableEngine.ogData[this.tableEngine.ogData.length - 1].Code:0;
      
      this.tableEngine.showAddNewModalRef(this.createForm, this.formBuilder);
    }
    else if (action === 'Edit') {
      this.addedItemList = [];
      this.addedServiceList = [];
      this.addedFileList = [];

      this.addedItemList = [...this.tableEngine.ogData.find(i => i.Id === Id).Items];
      this.addedServiceList = [...this.tableEngine.ogData.find(i => i.Id === Id).Services];
      this.addedFileList = [...this.tableEngine.ogData.find(i => i.Id === Id).Files];
      
      this.tableEngine.showEditModalRef(Id, this.createForm, this.formBuilder);
    }
    else if (action === 'Info') {
      this.addedItemList = [];
      this.addedServiceList = [];
      this.addedFileList = [];

      this.addedItemList = [...this.tableEngine.ogData.find(i => i.Id === Id).Items];
      this.addedServiceList = [...this.tableEngine.ogData.find(i => i.Id === Id).Services];
      this.addedFileList = [...this.tableEngine.ogData.find(i => i.Id === Id).Files];

      this.tableEngine.showInfoModalRef(Id, this.createForm, this.formBuilder);
    }
    this.tableView = false;
    this.modelObject = this.tableEngine.modelObject;
    this.mainForm = this.tableEngine.mainForm;
    console.log((action + " object"), this.modelObject);

    this.calculateTotalQuotationValue();
    
    console.log("modelObject after entering edit", this.modelObject);
  }
  currentDate='';
  createForm(modelObject, formBuilder): FormGroup {
    
    return formBuilder.group({
      Date: [modelObject.Date?modelObject.Date.toISOString().slice(0, 10) :modelObject.Date, Validators.compose([Validators.required])],
      ClientId: [modelObject.ClientId, Validators.compose([Validators.required])],
      ShippingFees: [modelObject.ShippingFees, Validators.compose([Validators.required, Validators.pattern(/^([0-9]+)([0-9])*(\.[0-9]+)?$/)])],
      ShippingAddress1: [modelObject.ShippingAddress1, Validators.compose([Validators.required])],
      ShippingAddress2: [modelObject.ShippingAddress2],
      Description: [modelObject.Description],
      DiscountValue: [modelObject.DiscountValue, Validators.compose([Validators.required, Validators.pattern(/^([0-9]+)([0-9])*(\.[0-9]+)?$/)])],
      DiscountIsRate: [modelObject.DiscountIsRate],
    });
  }

  addObject() {
debugger;
    this.currentAction = 'loading';
    
    let tempItemList: QuotationItemModel[] = [];
    let tempItem: QuotationItemModel;
    let itemDebugId = 0;
    let taxDebugId = 0;
    for (let index = 0; index < this.addedItemList.length; index++) {
      tempItem = new QuotationItemModel();
      tempItem = this.addedItemList[index];
      tempItem.Id = this.debugMode ? itemDebugId : 0;
      tempItem.QuotationId = 0;
      tempItemList.push(tempItem);  

      itemDebugId++;
      
      let tempTaxList: QuotationItemTaxModel[] = [];
      let tempTax: QuotationItemTaxModel;
      for (let taxIndex = 0; taxIndex < this.addedItemList[index].Taxes.length; taxIndex++) {
        tempTax = new QuotationItemTaxModel();
        tempTax = this.addedItemList[index].Taxes[taxIndex];
        tempTax.Id = this.debugMode ? taxDebugId : 0;
        tempTax.QuotationItemId = 0;
        tempTaxList.push(tempTax);
  
        taxDebugId++;``
      }
    }
    
    let tempServiceList: QuotationServiceModel[] = [];
    let tempService: QuotationServiceModel;
    let serviceDebugId = 0;
    for (let index = 0; index < this.addedServiceList.length; index++) {
      tempService = new QuotationServiceModel();
      tempService = this.addedServiceList[index];
      tempService.Id = this.debugMode ? serviceDebugId : 0;
      tempService.QuotationId = 0;
      tempServiceList.push(tempService);

      serviceDebugId++;
    }

    
    let tempFileList: QuotationFileModel[] = [];
    let tempFile: QuotationFileModel;
    let fileDebugId = 0;
    for (let index = 0; index < this.addedFileList.length; index++) {
      tempFile = new QuotationFileModel();
      tempFile = this.addedFileList[index];
      tempFile.Id = this.debugMode ? fileDebugId : 0;
      tempFile.QuotationId = 0;
      tempFileList.push(tempFile);

      fileDebugId++;
    }
    let lastId = this.tableEngine.ogData.length>0?this.tableEngine.ogData[this.tableEngine.ogData.length - 1].Id:0;

    this.modelObject = new QuotationDetailsModel();
    
    this.modelObject.Id = this.debugMode ? lastId + 1 : 0;
    
    this.modelObject.Code = this.lastCode + 1;
    console.log(this.lastCode);
    
    
    this.modelObject.ClientId = this.mainForm.getRawValue().ClientId;
    let date = new Date(this.mainForm.getRawValue().Date);

    this.modelObject.Date = date;
    this.modelObject.ShippingFees = this.mainForm.getRawValue().ShippingFees;
    this.modelObject.ShippingAddress2 = this.mainForm.getRawValue().ShippingAddress2;
    this.modelObject.ShippingAddress1 = this.mainForm.getRawValue().ShippingAddress1;
    this.modelObject.DiscountValue = this.mainForm.getRawValue().DiscountValue;
    this.modelObject.DiscountIsRate = this.mainForm.getRawValue().DiscountIsRate;

    this.modelObject.Description = this.mainForm.getRawValue().Description;
    this.modelObject.Items = tempItemList;
    this.modelObject.Services = tempServiceList;
    this.modelObject.Files = tempFileList;

    this.modelObject.TotalQuotationValue = this.calculateTotalQuotationValue();

    this.modelObject.TotalItemPrices = this.currentTotalItemPrices;
    this.modelObject.TotalItemDiscounts = this.currentTotalItemDiscounts;
    this.modelObject.TotalItemNetPrices = this.currentTotalItemNetPrices;
    this.modelObject.TotalItemTaxes = this.currentTotalItemTaxes;
    this.modelObject.TotalItemGrossPrices = this.currentTotalItemGrossPrices;
    this.modelObject.TotalServices = this.currentTotalServices;


    this.executeAddObject();
  }

  executeAddObject() {
    this.modelService.insert(this.modelObject).subscribe(result => {
      if (result === true) {
        this.tableEngine.loadData();
        this.currentAction = '';
        this.tableEngine.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyAdded"));
        if (this.sideModalRef === undefined) {
          if (this.smallModal != undefined) {
            this.tableEngine.smallModal.hide();
          }
          else {
            this.tableView = this.prevAction === 'AddThenNew' ? false : true;
            
            this.prevAction = '';
          }
        }
        else {
          this.tableEngine.sideModalRef.modalRef.hide();
        }
        
        if(this.prevAction != 'AddThenNew'){
          this.goBack();
        }
      }
      else {
        this.tableEngine.toastError(this.translate.instant("Error"), this.translate.instant("FailedAdd"));
        this.currentAction = '';
      }
    })
  }

  editObject() {
    this.currentAction = 'loading';
    this.tableEngine.savedPage = this.tableEngine.currentPage;


    let tempItemList: QuotationItemModel[] = [];
    let tempItem: QuotationItemModel;
    let itemDebugId = 1;
    let taxDebugId = 0;
    for (let index = 0; index < this.addedItemList.length; index++) {
      tempItem = new QuotationItemModel();
      tempItem = this.addedItemList[index];
      tempItem.Id = this.debugMode ? itemDebugId : 0;
      tempItem.QuotationId = 0;
      tempItemList.push(tempItem);

      itemDebugId++;
      
      let tempTaxList: QuotationItemTaxModel[] = [];
      let tempTax: QuotationItemTaxModel;
      for (let taxIndex = 0; taxIndex < this.addedItemList[index].Taxes.length; taxIndex++) {
        tempTax = new QuotationItemTaxModel();
        tempTax = this.addedItemList[index].Taxes[taxIndex];
        tempTax.Id = this.debugMode ? taxDebugId : 0;
        tempTax.QuotationItemId = 0;
        tempTaxList.push(tempTax);
  
        taxDebugId++;``
      }
    }
    
    let tempServiceList: QuotationServiceModel[] = [];
    let tempService: QuotationServiceModel;
    let serviceDebugId = 1;
    for (let index = 0; index < this.addedServiceList.length; index++) {
      tempService = new QuotationServiceModel();
      tempService = this.addedServiceList[index];
      tempService.Id = this.debugMode ? serviceDebugId : 0;
      tempService.QuotationId = 0;
      tempServiceList.push(tempService);

      serviceDebugId++;
    }

    
    let tempFileList: QuotationFileModel[] = [];
    let tempFile: QuotationFileModel;
    let fileDebugId = 1;
    for (let index = 0; index < this.addedFileList.length; index++) {
      tempFile = new QuotationFileModel();
      tempFile = this.addedFileList[index];
      tempFile.Id = this.debugMode ? fileDebugId : 0;
      tempFile.QuotationId = 0;
      tempFileList.push(tempFile);

      fileDebugId++;
    }
    
  



    //this.calculateTotalItemValue();
    //let newString = new DatePipe('en-US').transform(this.mainForm.getRawValue().Date, 'dd/MM/yyyy');
    //console.log("newstring", newString);
    
    //let date: Date = this.getFormattedDate(new DatePipe('en-US').transform(this.mainForm.getRawValue().Date, 'dd/MM/yyyy'));
    let date = new Date(this.mainForm.getRawValue().Date);
    let tempObj = new QuotationDetailsModel({
      Id: this.modelObject.Id,
      Code: this.modelObject.Code,
      ClientId: this.mainForm.getRawValue().ClientId,
      ShippingFees: this.mainForm.getRawValue().ShippingFees,
      ShippingAddress1: this.mainForm.getRawValue().ShippingAddress1,
      ShippingAddress2: this.mainForm.getRawValue().ShippingAddress2,
      DiscountValue: this.mainForm.getRawValue().DiscountValue,
      DiscountIsRate: this.mainForm.getRawValue().DiscountIsRate,
      Date: date,
      Description: this.mainForm.getRawValue().Description,

      Items: tempItemList,
      Services: tempServiceList,
      Files: tempFileList,
    
      TotalQuotationValue: this.calculateTotalQuotationValue(),

      TotalItemPrices: this.currentTotalItemPrices,
      TotalItemDiscounts: this.currentTotalItemDiscounts,
      TotalItemNetPrices: this.currentTotalItemNetPrices,
      TotalItemTaxes: this.currentTotalItemTaxes,
      TotalItemGrossPrices: this.currentTotalItemGrossPrices,
      TotalServices: this.currentTotalServices,

    });
    this.executeEditObject(tempObj);
    console.log("saved object", tempObj);
    
  }

  executeEditObject(Object) {


    this.modelService.update(Object).subscribe(result => {
      if (result === true) {
        this.tableEngine.loadData();
        this.currentAction = '';
        this.tableEngine.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyEdited"));

        if (this.sideModalRef === undefined) {
          if (this.smallModal != undefined) {
            this.tableEngine.smallModal.hide();
          }
          else {
            this.tableView = true;
          }
        }
        else {
          this.tableEngine.sideModalRef.modalRef.hide();
        }
        
        this.goBack();
        
      }
      else {
        this.tableEngine.toastError(this.translate.instant("Error"), this.translate.instant("FailedEdit"));
        this.currentAction = '';
      }
    })
  }

  //Deletion methods:
  //Customize if needed.
  //Pass the target object as parameter if the target API can only delete singular objects and not lists.
  deleteObject() {
    this.savedPage = this.currentPage;
    //prevent multiclicks. re enable after API response
    this.currentAction = 'loading';
    this.executeDeleteObject();
  }

  //Same here...
  executeDeleteObject() {
    this.modelService.deleteList(this.tableEngine.deletionList).subscribe(result => {
      if (result === true) {
        this.tableEngine.loadData();
        this.currentAction = '';
        this.tableEngine.finalizeDeletion();
      }
      else {
        this.tableEngine.toastError(this.translate.instant("Error"), this.translate.instant("FailedDelete"));
        this.currentAction = '';
      }
    })
  }
  ////////////////////////////////////////////////////////////////////////////
  //
  //
  //
  //
  //
  ////////////////////////////////////////////////////////////////////////////
  //  EXCLUSIVE :
  //  Specifically for this component.
  ///////////////////////////////
  //Get any additional data here
  SearchFrom: Date = null;
  SearchTo: Date = null;

  clearSearch() {
    this.SearchFrom = null;
    this.SearchTo = null;
  }

  prevAction = '';
  addAndNew() {
    this.prevAction = 'AddThenNew';
    this.addObject();
    this.showModal(null, 'Add');
  }

  loadServices() {
    this.currentAction = 'loading';

    this.clientService.getAll().subscribe(
      clientData => {
        this.clientList = clientData;

        this.codingUnitService.getAll().subscribe(
          codingUnitData => {
            this.codingUnitList = codingUnitData;

            this.itemService.getAll().subscribe(
              itemData => {
                this.itemList = itemData;

                this.taxTypeService.getAll().subscribe(
                  taxTypeData => {
                    this.taxTypeList = taxTypeData;

                    this.tableEngine.loadData();
                  }
                )
              }
            )
          }
        )
      }
    )


  }
  
  addedItemList: QuotationItemModel[] = [];
  addedServiceList: QuotationServiceModel[] = [];
  addedFileList: QuotationFileModel[] = [];
  codingUnitList: CodingUnitModel[] = [];
  itemList: StoreItemsModel[] = [];
  clientList: ClientModel[] = [];
  taxTypeList: TaxTypesModel[] = [];
  
  getItemById(Id: any): any {
    return this.itemList.find(i => i.Id === Id);
  }
  getUnityById(Id: any): any {
    return this.codingUnitList.find(i => i.Id === Id);
  }
  getTaxTypeById(Id: any): any {
    return this.taxTypeList.find(i => i.Id === Id);
  }
  getClientById(Id: any): any {
    return this.clientList.find(i => i.Id === Id);
  }

  quotationItemForm: FormGroup = new FormGroup({
    ItemId: new FormControl(),
    Description: new FormControl(),
    UnitCost: new FormControl(),
    Quantity: new FormControl(),
    DiscountValue: new FormControl(),
    DiscountIsRate: new FormControl(),
  });
  
  quotationItemTaxForm: FormGroup = new FormGroup({
    Id: new FormControl(),
    TaxId: new FormControl(),
  });

  quotationServiceForm: FormGroup = new FormGroup({
    Id: new FormControl(),
    NameAr: new FormControl(),
    //NameEn: new FormControl(),
    Value: new FormControl(),
    //IsRate: new FormControl(),
  });

  createQuotationItemForm(quotationItemObject, formBuilder): FormGroup {
    return formBuilder.group({
      Id: [quotationItemObject.Id >= 0 ? quotationItemObject.Id : 0],
      ItemId: [null ,Validators.compose([Validators.required])],
      Quantity: [null ,Validators.compose([Validators.required, Validators.pattern(/^([1-9]+)([0-9])*$/)])],
      UnitCost: [null ,Validators.compose([Validators.required, Validators.pattern(/^([0-9]+)([0-9])*(\.[0-9]+)?$/)])],
      Description: [null],
      DiscountValue: [null ,Validators.compose([ Validators.pattern(/^([0-9]+)([0-9])*(\.[0-9]+)?$/)])],
      DiscountIsRate: [true],
    });
  }
  
  createQuotationItemTaxForm(quotationItemTaxObject, formBuilder): FormGroup {
    return formBuilder.group({
      Id: [quotationItemTaxObject.Id >= 0 ? quotationItemTaxObject.Id : 0],
      TaxId: [null ,Validators.compose([Validators.required])],
    });
  }
  
  createQuotationServiceForm(quotationServiceObject, formBuilder): FormGroup {
    return formBuilder.group({
      Id: [quotationServiceObject.Id >= 0 ? quotationServiceObject.Id : 0],
      //NameEn: [null , Validators.compose([Validators.required, Validators.minLength(2)])],
      NameAr: [null , Validators.compose([Validators.required, Validators.pattern(/^[\u0621-\u064A\u0660-\u06690-9]+([\s]*[\u0621-\u064A\u0660-\u06690-9]+)*$/)])],
      Value: [null ,Validators.compose([Validators.required, Validators.pattern(/^([0-9]+)([0-9])*(\.[0-9]+)?$/)])],
      //IsRate: [null ,Validators.compose([Validators.required])],
    });
  }
  newQuotationItem: QuotationItemModel = new QuotationItemModel();
  newQuotationItemInternalId = 0;
  newQuotationItemTax: QuotationItemTaxModel = new QuotationItemTaxModel();
  newQuotationItemTaxInternalId = 0;

  newQuotationService: QuotationServiceModel = new QuotationServiceModel();
  newQuotationServiceInternalId = 0;
  
  newQuotationFile: QuotationFileModel = new QuotationFileModel();
  newQuotationFileInternalId = 0;

  //items
  addQuotationItem(): any {
    debugger;
    let unitCost = this.quotationItemForm.getRawValue().UnitCost;
    let quantity = this.quotationItemForm.getRawValue().Quantity;
    let totalPrice = unitCost * quantity;
    let discountValue = this.quotationItemForm.getRawValue().DiscountValue;
    let netPrice = this.quotationItemForm.getRawValue().DiscountIsRate? totalPrice - (discountValue * totalPrice / 100) : totalPrice -  discountValue;
    let totalTaxValues = 0;
    this.newQuotationItem.Taxes.forEach(tax => {
      totalTaxValues += tax.TaxIsRate ? tax.TaxValue * netPrice / 100 : tax.TaxValue;
    });
    let grossPrice = netPrice + totalTaxValues;

    
    let temp = new QuotationItemModel({
      Id: this.newQuotationItemInternalId,
      ItemId: this.quotationItemForm.getRawValue().ItemId,
      ItemNameAr: this.getItemById(this.quotationItemForm.getRawValue().ItemId).NameAr,
      ItemNameEn: this.getItemById(this.quotationItemForm.getRawValue().ItemId).NameEn,
      UnitId: this.newQuotationItem.UnitId,
      UnitNameAr: this.getUnityById(this.newQuotationItem.UnitId).NameAr,
      UnitNameEn: this.getUnityById(this.newQuotationItem.UnitId).NameEn,
      Description: this.quotationItemForm.getRawValue().Description,
      UnitCost: unitCost,
      Quantity: quantity,
      TotalPrice: totalPrice,
      DiscountValue: discountValue,
      //DiscountIsRate: this.quotationItemForm.getRawValue().DiscountIsRate,
      NetPrice: netPrice,
      Taxes: this.newQuotationItem.Taxes,
      TotalTaxValues: totalTaxValues,
      GrossPrice: grossPrice,
    })
    temp.DiscountIsRate=this.quotationItemForm.getRawValue().DiscountIsRate;
    
    console.log("saving",this.quotationItemForm.getRawValue().DiscountIsRate);
    console.log(temp.DiscountIsRate);
    
    this.addedItemList = this.addedItemList.concat(temp)
    console.log(this.addedItemList);
    
    this.newQuotationItemInternalId = this.newQuotationItemInternalId + 1;

    this.newQuotationItem = new QuotationItemModel();

    
    this.quotationItemForm = this.createQuotationItemForm(this.newQuotationItem, this.formBuilder);
    
    this.calculateTotalQuotationValue();
  }
  removeQuotationItem(Id: any): any {

    let index = this.addedItemList.findIndex(x => x.Id === Id);
    this.addedItemList.splice(index, 1);

    this.calculateTotalQuotationValue();
  }
  changeItem(): any {
    this.newQuotationItem.ItemId = this.quotationItemForm.getRawValue().ItemsHeaderId;
    this.newQuotationItem.UnitId = this.quotationItemForm.getRawValue().ItemId>0?
      this.getItemById(this.quotationItemForm.getRawValue().ItemId).UnitId:
      0;

    this.calculateItemValues();
    //this.calculateTotalQuotationValue();
  }
  
  //services
  addQuotationService(): any {

    let temp = new QuotationServiceModel({
      Id: this.newQuotationServiceInternalId,
      //QuotationId: this.modelObject.Id,
      NameAr: this.quotationServiceForm.getRawValue().NameAr,
      NameEn: this.quotationServiceForm.getRawValue().NameEn,
      Value: this.quotationServiceForm.getRawValue().Value,
      //IsRate: this.quotationServiceForm.getRawValue().IsRate,
    });
    temp.IsRate = this.quotationServiceForm.getRawValue().IsRate;
    
    this.addedServiceList = this.addedServiceList.concat(temp)
    this.newQuotationServiceInternalId = this.newQuotationServiceInternalId + 1;

    this.newQuotationService = new QuotationServiceModel();

    
    this.quotationServiceForm = this.createQuotationServiceForm(this.newQuotationService, this.formBuilder);
    
    this.calculateTotalQuotationValue();
  }
  removeQuotationService(Id: any): any {

    let index = this.addedServiceList.findIndex(x => x.Id === Id);
    this.addedServiceList.splice(index, 1);

    this.calculateTotalQuotationValue();
  }
  
  //taxes
  addQuotationItemTax(): any {
    debugger;
    let tax: TaxTypesModel = this.getTaxTypeById(this.quotationItemTaxForm.getRawValue().TaxId);
    let temp = new QuotationItemTaxModel({
      Id: this.newQuotationItemTaxInternalId,
      //QuotationItemId: this.modelObject.Id,
      TaxId: tax.Id,
      TaxNameAr: tax.NameAr,
      TaxNameEn : tax.NameEn,
      //TaxIsRate: tax.IsRate,
      TaxValue: tax.Percentage,
    })
    temp.TaxIsRate = tax.IsRate;

    this.newQuotationItem.Taxes = this.newQuotationItem.Taxes.concat(temp)
    this.newQuotationItemTaxInternalId = this.newQuotationItemTaxInternalId + 1;

    this.newQuotationItemTax = new QuotationItemTaxModel();

    
    this.quotationItemTaxForm = this.createQuotationItemTaxForm(this.newQuotationItemTax, this.formBuilder);
    
    this.calculateItemValues()
    //this.calculateTotalQuotationValue();
  }
  removeQuotationItemTax(Id: any): any {

    let index = this.newQuotationItem.Taxes.findIndex(x => x.Id === Id);
    this.newQuotationItem.Taxes.splice(index, 1);

    this.calculateItemValues()
    //this.calculateTotalQuotationValue();
  }
  changeTax(): any {
    debugger;
    let tax: TaxTypesModel = this.getTaxTypeById(this.quotationItemTaxForm.getRawValue().TaxId);
    this.newQuotationItemTax.TaxId = tax.Id;
    this.newQuotationItemTax.TaxNameAr = tax.NameAr;
    this.newQuotationItemTax.TaxNameEn = tax.NameEn;
    this.newQuotationItemTax.TaxValue = tax.Percentage;
    this.newQuotationItemTax.TaxIsRate = tax.IsRate;

    this.calculateItemValues()
    //this.calculateTotalQuotationValue();
  }

  //files
  addedFileUrl: string = '';
  addQuotationFile(): any {
    let temp = new QuotationFileModel({
      Id: this.newQuotationFileInternalId,
      //QuotationId: this.modelObject.Id,
      FileUrl: this.addedFileUrl,
    })

    this.addedFileList = this.addedFileList.concat(temp)
    this.newQuotationFileInternalId = this.newQuotationFileInternalId + 1;

    this.newQuotationFile = new QuotationFileModel();

    //reset form here?
  }
  removeQuotationFile(Id: any): any {

    let index = this.addedFileList.findIndex(x => x.Id === Id);
    this.addedFileList.splice(index, 1);
  }

  currentTotalItemPrices = 0;
  currentTotalItemDiscounts = 0;
  currentTotalItemNetPrices = 0;
  currentTotalItemTaxes = 0;
  currentTotalItemGrossPrices = 0;
  currentTotalAfterQuotationDiscount = 0;
  currentTotalAfterShippingFees = 0;
  currentTotalServices = 0;
  currentTotalQuotationValue = 0;
  calculateTotalQuotationValue() {
    

      console.log(this.addedItemList);
      console.log("chagngesD");
      let value = this.mainForm.getRawValue().DiscountValue;
      if(this.mainForm.getRawValue().DiscountIsRate && value>100){
        value=100;
        this.mainForm.controls.DiscountValue.setValue(value);
      }

      console.log("entered");
      console.log();
      
      
      let totalItemPrices = 0;
      let totalItemDiscounts = 0;
      let totalItemNetPrices = 0;
      let totalItemTaxes = 0;
      let totalItemGrossPrices = 0;
      let totalAfterQuotationDiscount = 0;
      let totalAfterShippingFees = 0;
      let totalServices = 0;
      let totalQuotationValue = 0;
      this.addedItemList.forEach(item => {
        item.TotalPrice = item.UnitCost * item.Quantity;
        totalItemPrices += item.TotalPrice;

        totalItemDiscounts += item.DiscountIsRate?item.DiscountValue*item.TotalPrice/100:item.DiscountValue;
        item.NetPrice = item.TotalPrice - (item.DiscountIsRate?item.DiscountValue*item.TotalPrice/100:item.DiscountValue);
        totalItemNetPrices += item.NetPrice;

        item.TotalTaxValues = 0;
        item.Taxes.forEach(tax => {
          item.TotalTaxValues += (tax.TaxIsRate?(tax.TaxValue*item.NetPrice)/100:tax.TaxValue);
        });
        totalItemTaxes += item.TotalTaxValues;

        item.GrossPrice = item.NetPrice + item.TotalTaxValues;
        totalItemGrossPrices += item.GrossPrice;
      });

      
      this.addedServiceList.forEach(service => {
        totalServices += (service.IsRate?(service.Value*totalItemGrossPrices)/100:service.Value);
      });

      let finalDiscountValue = this.mainForm.getRawValue().DiscountValue;
      let finalDiscountIsRate = this.mainForm.getRawValue().DiscountIsRate;
      let finalQuotationDiscount = finalDiscountIsRate?finalDiscountValue*totalItemGrossPrices/100:finalDiscountValue;
      totalAfterQuotationDiscount = totalItemGrossPrices - finalQuotationDiscount;
      totalAfterShippingFees = totalAfterQuotationDiscount + this.mainForm.getRawValue().ShippingFees;
      totalQuotationValue = totalAfterShippingFees + totalServices;
      
      this.currentTotalItemPrices = totalItemPrices;
      this.currentTotalItemDiscounts = totalItemDiscounts;
      this.currentTotalItemNetPrices = totalItemNetPrices;
      this.currentTotalItemTaxes = totalItemTaxes;
      this.currentTotalItemGrossPrices = totalItemGrossPrices;
      this.currentTotalServices = totalServices;
      this.currentTotalQuotationValue = totalQuotationValue;
      this.currentTotalAfterQuotationDiscount = totalAfterQuotationDiscount;
      this.currentTotalAfterShippingFees = totalAfterShippingFees;
      
      console.log("neemo",totalItemGrossPrices);
      
      return totalQuotationValue;
    
  }
  goBack(){
    console.log("back");
    
    this.modelObject = {};
    this.addedItemList = [];
    this.addedServiceList = [];
    this.addedFileList = [];

    this.currentTotalItemPrices = 0;
    this.currentTotalItemNetPrices = 0;
    this.currentTotalItemDiscounts = 0;
    this.currentTotalServices = 0;
    this.currentTotalItemGrossPrices = 0;
    this.currentTotalItemTaxes = 0;
    this.currentTotalAfterQuotationDiscount = 0;
    this.currentTotalAfterShippingFees = 0;
    this.currentTotalQuotationValue = 0;

    this.newQuotationItem = {};
    this.newQuotationItem.Taxes = [];
    this.currentAction = "";
    this.tableView = true;

    
    console.log(this.modelObject);
    console.log(this.lastCode);
  }

  calculateItemValues(){
    if(this.quotationItemForm.getRawValue().ItemId>0){

      console.log(this.addedItemList);
      console.log("chagngesD");
      let value = this.quotationItemForm.getRawValue().DiscountValue;
      if(this.quotationItemForm.getRawValue().DiscountIsRate && value>100){
        value=100;
        this.quotationItemForm.controls.DiscountValue.setValue(value);
      }
      
      let unitCost = this.quotationItemForm.getRawValue().UnitCost;
      let quantity = this.quotationItemForm.getRawValue().Quantity;
      let totalPrice = unitCost * quantity;
      let discountValue = this.quotationItemForm.getRawValue().DiscountValue;
      let netPrice = this.quotationItemForm.getRawValue().DiscountIsRate? totalPrice - (discountValue * totalPrice / 100) : totalPrice -  discountValue;
      let totalTaxValues = 0;
      this.newQuotationItem.Taxes.forEach(tax => {
        totalTaxValues += tax.TaxIsRate ? tax.TaxValue * netPrice / 100 : tax.TaxValue;
      });
      console.log("KEEMO",totalTaxValues);
      
      let grossPrice = netPrice + totalTaxValues;
  
      
      let temp = new QuotationItemModel({
        Id: this.newQuotationItemInternalId,
        ItemId: this.quotationItemForm.getRawValue().ItemId,
        ItemNameAr: this.getItemById(this.quotationItemForm.getRawValue().ItemId).NameAr,
        ItemNameEn: this.getItemById(this.quotationItemForm.getRawValue().ItemId).NameEn,
        UnitId: this.newQuotationItem.UnitId,
        UnitNameAr: this.getUnityById(this.newQuotationItem.UnitId).NameAr,
        UnitNameEn: this.getUnityById(this.newQuotationItem.UnitId).NameEn,
        Description: this.quotationItemForm.getRawValue().Description,
        UnitCost: unitCost,
        Quantity: quantity,
        TotalPrice: totalPrice,
        DiscountValue: discountValue,
        //DiscountIsRate: this.quotationItemForm.getRawValue().DiscountIsRate,
        NetPrice: netPrice,
        Taxes: this.newQuotationItem.Taxes,
        TotalTaxValues: totalTaxValues,
        GrossPrice: grossPrice,
      })
      temp.DiscountIsRate=this.quotationItemForm.getRawValue().DiscountIsRate;
      
      this.newQuotationItem = temp;
    }
  }

  test(){
    console.log(this.quotationItemForm.getRawValue().DiscountIsRate);
  }
  ////////////////////////////////////////////////////////////////////////////
  //
  //
  //
  //
  //
  ////////////////////////////////////////////////////////////////////////////
  //  CONSTANT :
  //  Do not change.
  ///////////////////////////////
  prepChangeSort(sortKey) {
    this.SearchFrom = null;
    this.SearchTo = null;
    
    
    this.tableEngine.changeSort(this.tableEngine.getSort(sortKey));
    this.itemOrder = this.tableEngine.itemOrder;
  }

  getFormattedDate(dateString: any){
    let splitDate: number[] = dateString.toString().split("/");

    return new Date(splitDate[2], splitDate[1]-1, splitDate[0])
  }
  ///////////////////////////////////////////////////////
  
  displayMode = 'list';
  changeDisplayMode(mode): void {
    this.displayMode = mode;
  }
  itemsPerPageChange(perPage: number): void {
    this.tableEngine.paginateData(perPage, 1)
  }
}
