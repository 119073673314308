import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchPipe'
})
export class SearchPipePipe implements PipeTransform {


  transform(list: any[], value: string, target: string): any {
    switch(target){
      case 'item': {
        return value ? list.filter(item => item.Name.toLowerCase().includes(value.toLowerCase())
        || item.Code.toLowerCase().includes(value.toLowerCase()) 
        || item.Description.toLowerCase().includes(value.toLowerCase()) ) : list;
        break;
      }
      
      case 'currency': {
        return value ? list.filter(item => item.NameEn.toLowerCase().includes(value.toLowerCase()) 
        || item.NameAr.toLowerCase().includes(value.toLowerCase()) ) : list;    
        break;
      }
      
      case 'branch': {
        return value ? list.filter(item => item.Name.toLowerCase().includes(value.toLowerCase()) ) : list;
        break;
      }
      
      case 'client': {
        return value ? list.filter(item => item.Name.toLowerCase().includes(value.toLowerCase()) 
        || item.Code.toLowerCase().includes(value.toLowerCase()) ) : list;    
        break;
      }
      
      case 'invoice': {
        return value ? list.filter(item => item.InvoiceNumber.toLowerCase().includes(value.toLowerCase()) ) : list;
        break;
      }
    }
  }

}
