import { filter } from 'rxjs/operators';
import { Component, HostBinding, Renderer2, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';

import { TableEngine } from '../../../invoices/TableEngine'
import { ModalDirective, ModalOptions } from 'ngx-bootstrap/modal';
import { SidePopupComponent } from '../../app/aio/shared/side-popup/side-popup.component';

import { ClientService } from "../../../invoices/clients/ClientService";
import { ClientModel } from "../../../invoices/clients/ClientModel";
import { DatePipe, formatDate } from '@angular/common';
import { PaymentMethodModel } from "../../purchases/purchase-orders/PaymentMethodModel";
import { PaymentMethodService } from "../../purchases/purchase-orders/PaymentMethodService";
import { CashierOrderModel } from "../cashier-orders/CashierOrderModel";
import { CashierSessionModel } from "../cashier-sessions/CashierSessionModel";
import { CashierOrderService } from "../cashier-orders/CashierOrderService";
import { CashierOrderItemService } from '../cashier-orders/CashierOrderItemService';
import { CashierOrderItemModel } from '../cashier-orders/CashierOrderItemModel';
import { CashierSessionService } from '../cashier-sessions/CashierSessionService';
import { CashRegisterService } from '../cashier-machines/CashRegisterService';
import { Router } from '@angular/router';
import { DateAdapter } from '@angular/material/core';


import html2canvas from 'html2canvas';
import jspdf from 'jspdf';
import { ConfigPoSService } from '../config-pos-general/ConfigPoSService';
import { ConfigPoSModel } from '../config-pos-general/ConfigPoSModel';
import { ListPageHeaderComponent } from 'src/app/containers/pages/list-page-header/list-page-header.component';
import { RestaurantMenuItemService } from '../restaurant-menu-item/RestaurantMenuItemService';
import { RestaurantMenuItemModel } from '../restaurant-menu-item/RestaurantMenuItemModel';
import { RestaurantCategoryModel } from './../restaurant-category/RestaurantCategoryModel';
import { RestaurantCategoryService } from './../restaurant-category/RestaurantCategoryService';

@Component({
  selector: 'app-cashier',
  templateUrl: './cashier.component.html',
  styleUrls: ['./cashier.component.scss']
})
export class RestaurantsCashierComponent  {
  ////////////////////////////////////////////////////////////////////////////
  //  VARYING :
  //  Modify per similar component.
  ////////////////////////////////////////////////////////////////////////////

  //Enable debugMode to make API modules return static data from within the service class
  debugMode: boolean = false;

  constructor(private formBuilder: FormBuilder, private modelService: CashierOrderService, private renderer: Renderer2,
    private notifications: NotificationsService, private translate: TranslateService,
    private itemService: RestaurantMenuItemService, private datePipe: DatePipe, private paymentMethodService: PaymentMethodService,
    private clientService: ClientService, private categoryService: RestaurantCategoryService, 
    private sessionService: CashierSessionService, private cashRegisterService: CashRegisterService,
    private cashierOrderService: CashierOrderService, private cashierOrderItemService: CashierOrderItemService,
    private configPoSService: ConfigPoSService,
    private _router: Router
    //private cashierOrderService: CashierOrderService,
  ) {
  }


  listHeader = new ListPageHeaderComponent();

  tableEngine = new TableEngine(this.translate, this.notifications);
  currentScreen: any = 'Table';
  tableView: any = false;

  translationName: string = 'CashierOrder'

  sortableKeys = [];
  searchableKeys = ['NameAr', 'NameEn'];

  modelObject: CashierSessionModel = new CashierSessionModel();

  ogData: CashierSessionModel[] = [];

  declare lang: any;
  declare itemOrder: any;
  currentAction = '';
  savedPage = 0;
  currentPage = 1;
  deletionList: any[] = [];

  @ViewChild('infoModal', { static: true }) infoModal: ModalDirective;
  @ViewChild('orderModal', { static: true }) orderModal: ModalDirective;
  @ViewChild('printModal', { static: true }) printModal: ModalDirective;
  @ViewChild('refundModal', { static: true }) refundModal: ModalDirective;
  @ViewChild('itemNoteModal', { static: true }) itemNoteModal: ModalDirective;
  @ViewChild('clientModal', { static: true }) clientModal: ModalDirective;
  @ViewChild('endSessionModal', { static: true }) endSessionModal: ModalDirective;
  //@ViewChild('sessionOrdersModal', { static: true }) sessionOrdersModal: ModalDirective;
  @ViewChild('orderItemsModal', { static: true }) orderItemsModal: ModalDirective;
  @ViewChild('orderStatusModal', { static: true }) orderStatusModal: ModalDirective;
  @ViewChild('orderStatusReminderModal', { static: true }) orderStatusReminderModal: ModalDirective;
  
  ngOnInit(): void {
    this.printOrder = new CashierOrderModel();
    if (!(this.sessionService.currentSession.Id>0)){
      this._router.navigate(['app/aio/restaurant']);
    }
    else{
      if(this.currentSession.Orders){
        console.log(this.currentSession);
        
      }else{
        this.currentSession.Orders = [];
      }
    }
    if (this.debugMode) {
      this.enableDebugOnAllServices();
    }

    this.currentSession =  this.sessionService.currentSession;

    this.lang = localStorage.getItem('theme_lang');
    this.itemOrder = { label: 'ItemName', property: this.lang === 'ar-EG' ? 'NameAr' : 'NameEn' },
    this.sortableKeys = [
      { label: 'ItemName', property: this.lang === 'ar-EG' ? 'NameAr' : 'NameEn' },
    ];


    this.tableEngine.loadTableEngine(this.itemList, this.itemService, this.modelObject,
      this.searchableKeys, this.sortableKeys, null, this.translationName, this.itemOrder,
      null, null, null, this.lang
    )

    this.loadServices();

    this.tableEngine.itemsPerPage = 999999999;
    this.tableEngine.currentPage = 1;

  }


  enableDebugOnAllServices() {
    //this.modelService.debugMode = true;
    // this.clientService.debugMode = true;
    // this.paymentMethodService.debugMode = true;
    // this.itemService.debugMode = true;
    // this.codingUnitService.debugMode = true;
    //this.sessionService.debugMode = true;
    //this.cashRegisterService.debugMode = true;
  }

  viewedOrder: CashierOrderModel = new CashierOrderModel;
  printOrder: CashierOrderModel = new CashierOrderModel;
  showModal(modal:any, action:any){
    //this.currentOrder.IsRefund=(action=="Refund")?true:false;
    this.calculateTotalCashierOrderValue();
    if((action=='Info'||action=='Note')&&!(this.currentItem.Id>0)){
      return;
    }
    if((action=='Order') || (action=='Refund')){
      if(!(this.currentOrder.Items.length>0)){
        return;
      }
      else {
        this.tempPaidAmount = 0;
        this.tempChangeGiven = 0;
        this.currentOrder.IsRefund = action=='Refund'?true:false;
        this.currentOrder.PaymentMethodId = this.configPoS.DefaultPaymentMethodId;
        this.currentOrder.Status = null;
      }
    }

    if((action=='SessionOrders')){
      this.tableView = true;
      return;
    }

    if((action == 'ExitTableView')){
      this.tableView = false;
      return;
    }

    if((modal == this.orderStatusModal)){
      this.viewedOrder = action;
      this.tempOrderStatus = this.viewedOrder.Status;
      //this.exportTable();
    }
    

    if((modal == this.endSessionModal)){
      this.tempChangeGiven = 0;
      this.tempPaidAmount = 0;
      //this.endingSession = true;
      this.tempSessionEndCash = { Amount:0, 
        Bill05:0, Bill025:0, Bill1:0, Bill5:0, Bill10:0, Bill20:0, Bill50:0, Bill100:0, Bill200:0,
        BillTotal:0};
      this.calculateSessionTotal();
    }
    modal.show();
  }
  showOrderItemsModal(order: CashierOrderModel){
    this.viewedOrder = order;
    this.showModal(this.orderItemsModal, 'ShowItems');
  }
  currentSession: CashierSessionModel = new CashierSessionModel({
    //Id:1, Code:1001, CashierId:1, CashierNameAr: "محمد احمد", CashierNameEn: "Mohamad Ahmad", StartingCash: 1000, LeaveCash:1000
  })
  currentItem: CashierOrderItemModel = new CashierOrderItemModel();
  currentOrder: CashierOrderModel = new CashierOrderModel({
    Id:1,
    PaymentMethodId:null,
    Status:'Complete'
  });

  lastSessionCode = 0;
  orderItemInternalId = 1

  addOrderItem(ItemId: number){
    let foundItem = this.currentOrder.Items.find(x=>x.ItemId==ItemId);
    if(foundItem){
      foundItem.Quantity += 1;
      this.calculateTotalCashierOrderValue();
      return;
    }
    let item: RestaurantMenuItemModel = this.getItemById(ItemId);
    let category = this.getCategoryById(item.CategoryId);
    let temp = new CashierOrderItemModel();
    temp.Id = this.orderItemInternalId;
    temp.CashierOrderId = this.currentOrder.Id;
    temp.ItemId = ItemId;
    temp.ItemNameAr = item.Name;
    temp.ItemNameEn = item.Name;
    temp.RestaurantCategoryId = item.CategoryId;
    temp.CategoryName = category.Name;
    temp.UnitCost = item.Cost;
    temp.Quantity = 1;
    temp.NetPrice = temp.UnitCost;
    temp.AddedValueTax = Number((temp.NetPrice * 0.14).toFixed(2));
    temp.GrossPrice = item.Cost + temp.AddedValueTax;

    this.currentOrder.Items = this.currentOrder.Items.concat(temp)
    this.orderItemInternalId += 1;

    console.log(temp);
    
    this.calculateTotalCashierOrderValue();
  }

  removeOrderItem(Id: number){
    let index = this.currentOrder.Items.findIndex(x => x.Id === Id);
    this.currentOrder.Items.splice(index, 1);
    this.currentItem = new CashierOrderItemModel();

    this.calculateTotalCashierOrderValue();
  }

  selectOrderItem(Id: number){
    this.currentItem = this.currentItem == this.currentOrder.Items.find(x => x.Id === Id)?
    new CashierOrderItemModel():
    this.currentOrder.Items.find(x => x.Id === Id);
  }
  deselectItem(){
    this.currentItem = new CashierOrderItemModel();
  }

  currentMode: string = 'Qty'
  lastMode: string = ''
  lastButton: string = ''
  quantityLimit = 999;
  priceLimit = 999999;
  //discountLimit = 100;
  setMode(mode:string){
    this.lastButton = '';
    this.lastMode = '';
    this.currentMode = mode;
  }
  modifyItem(mode:string, button:any){
    if(this.currentOrder.Items.length==0 || !(this.currentItem.Id>0)){
      return;
    }
    
    switch(button){
      case '.':{
        let val = mode=='Qty'?this.currentItem.Quantity:(mode=='Price'?this.currentItem.UnitCost:this.currentItem.DiscountValue);
        if(val.toString().includes('.')){
          this.lastMode = mode;
          return;
        }
        this.lastMode = mode;
        this.lastButton = button;
        return;
      }
      case '+/-':{
        this.currentItem.UnitCost = -this.currentItem.UnitCost;
        this.calculateTotalCashierOrderValue();
        this.lastMode = mode;
        this.lastButton = button;
        return;
      }
      case 'Del':{
        let val = mode=='Qty'?this.currentItem.Quantity:(mode=='Price'?this.currentItem.UnitCost:this.currentItem.DiscountValue);
        if(val==0){
          this.removeOrderItem(this.currentItem.Id);
          return;
        }
        else{
          if(val.toString().length==1){
            val = 0;
          }
          else{
            val = Number(val.toString().slice(0,-1));
          }
          if(mode=='Qty'){this.currentItem.Quantity=val}
          else if(mode=='Price'){this.currentItem.UnitCost=val}
          else {this.currentItem.DiscountValue=val};
        }
        this.calculateTotalCashierOrderValue();
        return;
      }
    }


    switch(mode){
      case 'Qty': {//Quantity
        if(this.lastMode==mode){
          let quantity = this.currentItem.Quantity.toString();
          if(this.lastButton=='.'){
            console.log("adding after (.)");
            
            this.currentItem.Quantity = Number(quantity.concat("."+button));
            console.log("number conversion",Number(quantity.concat("."+button)));
            
          }
          else if(this.currentItem.Quantity<this.quantityLimit){
            quantity = quantity.concat(button.toString());
            if(Number(quantity)<this.quantityLimit){
              
              this.currentItem.Quantity = Number(quantity);
            }
            else{
              this.currentItem.Quantity = this.quantityLimit;
            }
          }
          else{
            this.currentItem.Quantity = this.quantityLimit;
          }
        }
        else{
          this.currentItem.Quantity = Number(button.toString().replace('.','0'));
        }
        break;
      }
      
      case 'Price': {
        if(this.lastMode==mode){
          let price = this.currentItem.UnitCost.toString();
          if(this.lastButton=='.'){
            this.currentItem.UnitCost = Number(price.concat("."+button));
          }
          else if(this.currentItem.UnitCost<this.priceLimit){
            price = price.concat(button.toString());
            if(Number(price)<this.priceLimit){
              this.currentItem.UnitCost = Number(price);
            }
            else{
              this.currentItem.UnitCost = this.priceLimit;
            }
          }
          else{
            this.currentItem.UnitCost = this.priceLimit;
          }
        }
        else{
          this.currentItem.UnitCost = Number(button.toString().replace('.','0'));
        }
        break;
      }
      
      case 'Dsc': {//Discount %s
        if(this.lastMode==mode){
          let discount = this.currentItem.DiscountValue.toString();
          if(this.lastButton=='.'){
            this.currentItem.DiscountValue = Number(discount.concat("."+button));
          }
          else if(this.currentItem.DiscountValue<this.configPoS.DiscountLimit){
            discount = discount.concat(button.toString());
            if(Number(discount)<this.configPoS.DiscountLimit){
              this.currentItem.DiscountValue = Number(discount);
            }
            else{
              this.currentItem.DiscountValue = this.configPoS.DiscountLimit;
            }
          }
          else{
            this.currentItem.DiscountValue = this.configPoS.DiscountLimit;
          }
        }
        else{
          this.currentItem.DiscountValue = Number(button.toString().replace('.','0'));
        }
        break;
      }
    }
    
    this.calculateTotalCashierOrderValue();
    this.lastMode = mode;
    this.lastButton = button;
  }

  calculateTotalCashierOrderValue() {
    let totalItemPrices = 0;
    let totalItemDiscounts = 0;
    let totalItemNetPrices = 0;
    let totalItemTaxes = 0;
    let totalItemGrossPrices = 0;
    let totalCashierOrderValue = 0;
    this.currentOrder.Items.forEach(item => {
      item.TotalPrice = item.UnitCost * item.Quantity;
      totalItemPrices += item.TotalPrice;

      totalItemDiscounts += item.DiscountValue*item.TotalPrice/100;
      item.NetPrice = item.TotalPrice - (item.DiscountValue*item.TotalPrice/100);
      totalItemNetPrices += item.NetPrice;

      item.AddedValueTax = item.NetPrice * 0.14;
      totalItemTaxes += Number(item.AddedValueTax.toFixed(2));

      item.GrossPrice = item.NetPrice + item.AddedValueTax;
      totalItemGrossPrices += item.GrossPrice;
    });
    

    //let finalDiscountValue = this.mainForm.getRawValue().DiscountValue;
    //let finalDiscountIsRate = this.mainForm.getRawValue().DiscountIsRate;
    //let finalCashierOrderDiscount = finalDiscountIsRate?finalDiscountValue*totalItemGrossPrices/100:finalDiscountValue;
    //totalAfterCashierOrderDiscount = totalItemGrossPrices - finalCashierOrderDiscount;
    
    this.currentOrder.ItemTotalPrices = totalItemPrices;
    this.currentOrder.ItemTotalDiscounts = totalItemDiscounts;
    this.currentOrder.ItemTotalNetPrices = totalItemNetPrices;
    this.currentOrder.ItemTotalTaxes = totalItemTaxes;
    this.currentOrder.ItemTotalGrossPrices = totalItemGrossPrices;
    this.currentOrder.TotalValue = totalItemGrossPrices;
    
    //this.currentTotalAfterCashierOrderDiscount = totalAfterCashierOrderDiscount;
    
    return totalCashierOrderValue;
}

lastOrderCode=0;
refund(){
  this.currentOrder.IsRefund = true;
  this.addOrder();
}
addOrder(){
  if(this.currentOrder.IsRefund){
    this.currentOrder.Items.forEach(item => {
      item.UnitCost = -item.UnitCost;
    });
  }
  if(this.currentOrder.Status=='In-Delivery'){
    this.currentSession.HasOrdersInDelivery = true;
  }
  this.calculateTotalCashierOrderValue();
  //let nextOrderCode = this.configPoSService.getNextCode(this.configPoS.StartingCodeOrders, this.cashierOrderList);
  this.currentOrder.Code = this.lastOrderCode;
  

  this.currentOrder.SessionId = this.currentSession.Id;
  this.currentOrder.SessionCode = this.currentSession.Code;
  this.currentOrder.CashRegisterId = this.currentSession.CashRegisterId;
  this.currentOrder.CashRegisterCode = this.currentSession.CashRegisterCode;
  let paymentMethod = this.paymentMethodList.find(x=>x.Id = this.currentOrder.PaymentMethodId);
  this.currentOrder.PaymentMethodAr = paymentMethod.NameAr;
  this.currentOrder.PaymentMethodEn = paymentMethod.NameEn;
  this.currentOrder.Note = this.tempOrderNote;
  this.currentOrder.PaidAmount = this.tempPaidAmount;
  this.currentOrder.ChangeGiven = this.tempChangeGiven;

  this.currentOrder.ClientId = this.currentOrder.ClientId>0?this.currentOrder.ClientId:this.configPoS.DefaultClientId;
  this.currentOrder.CashierId = this.currentSession.CashierId;
  this.currentOrder.CashRegisterBranchId = this.currentSession.CashRegisterBranchId;
  this.currentOrder.CounterCashBeforeCheckout = this.currentSession.LeaveCash;
  this.currentOrder.CounterCashAfterCheckout = 
    this.currentSession.LeaveCash + this.currentOrder.PaidAmount - this.currentOrder.ChangeGiven;

  this.cashierOrderService.insertWithItems(this.currentOrder).subscribe(result => {
      if (result === true || result > 0) {
        this.printOrder = this.currentOrder;

        //this.tableEngine.loadData();
        this.currentSession.Orders = this.currentSession.Orders.concat(this.currentOrder);
        this.calculateSessionTotal();

        this.tempPaidAmount = 0;
        this.tempChangeGiven = 0;

        let id = this.currentOrder.Id;
        this.currentOrder = new CashierOrderModel({Id:id+1});
        this.lastButton = '';
        this.lastMode = '';
        this.currentMode = 'Qty';
        //this.lastOrderCode += 1;

        this.tableEngine.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyAddedOrder"));

        this.orderModal.hide();
        //this.refundModal.hide();


        this.updateSession();
      }
      else {
        this.tableEngine.toastError(this.translate.instant("Error"), this.translate.instant("FailedAddingOrder"));
      }
    })
}

calculateSessionTotal(){
  this.currentSession.HasOrdersInDelivery = false;
  this.currentSession.LeaveCash = 0;
  this.currentSession.SessionTotal = 0;
  this.currentSession.OrderCount = 0;
  this.currentSession.RefundCount = 0;
  this.currentSession.Orders.forEach(order => {
    if(order.Status=='Complete'){
      this.currentSession.LeaveCash += order.TotalValue;
      this.currentSession.SessionTotal += order.TotalValue;
      this.currentSession.OrderCount += order.IsRefund?0:1;
      this.currentSession.RefundCount += order.IsRefund?1:0;
    }
    if(order.Status=='In-Delivery'){
      this.currentSession.HasOrdersInDelivery = true;
    }
  });
}
endingSession: boolean = false;
tempSessionEndNote='';
closeEndSessionModal(){
  this.endingSession = false;
  this.endSessionModal.hide();
}

endSession(){
  this.printOrder = new CashierOrderModel();
  this.endingSession = true;
  this.updateSession();
}

updateSession() {

    this.currentAction = 'loading';
    
    let tempOrderList: CashierOrderModel[] = [];
    let tempOrder: CashierOrderModel;
    for (let index = 0; index < this.currentSession.Orders.length; index++) {
      tempOrder = new CashierOrderItemModel();
      tempOrder = this.currentSession.Orders[index];
      tempOrder.Id = this.debugMode ? this.currentSession.Orders[index].Id : 0;
      this.currentSession.HasOrdersInDelivery = (tempOrder.Status=='In-Delivery')?true:this.currentSession.HasOrdersInDelivery;
      tempOrderList.push(tempOrder);
    }

    //let lastId = this.tableEngine.ogData.length>0?this.tableEngine.ogData[this.tableEngine.ogData.length - 1].Id:0;

    //this.modelObject = new CashierSessionModel();
    
    //this.currentSession.Id = this.debugMode ? lastId + 1 : 0;
    
    //this.currentSession.Code = this.lastSessionCode + 1;
    console.log(this.lastSessionCode);
    this.currentSession.Orders = tempOrderList;
    
    
    this.calculateSessionTotal();

    let tempLeaveCash = this.currentSession.LeaveCash;
    if(this.endingSession){
      this.currentSession.LeaveCash = this.tempSessionEndCash.BillTotal;
      this.currentSession.LeaveBill025 = this.tempSessionEndCash.Bill025;
      this.currentSession.LeaveBill05 = this.tempSessionEndCash.Bill05;
      this.currentSession.LeaveBill1 = this.tempSessionEndCash.Bill1;
      this.currentSession.LeaveBill5 = this.tempSessionEndCash.Bill5;
      this.currentSession.LeaveBill10 = this.tempSessionEndCash.Bill10;
      this.currentSession.LeaveBill20 = this.tempSessionEndCash.Bill20;
      this.currentSession.LeaveBill50 = this.tempSessionEndCash.Bill50;
      this.currentSession.LeaveBill100 = this.tempSessionEndCash.Bill100;
      this.currentSession.LeaveBill200 = this.tempSessionEndCash.Bill200;
  
      let timezoneOffset = (new Date()).getTimezoneOffset();
      let nowUTC = new Date()
      nowUTC = new Date(nowUTC.setTime(nowUTC.getTime() - (timezoneOffset * 60 * 1000)));
      this.currentSession.LeaveNote = this.tempSessionEndNote;
      this.currentSession.EndTime = nowUTC;
      this.currentSession.Active = !this.endingSession;
    }
    else{
      this.currentSession.LeaveCash = 0;
    }

    this.executeUpdateSession();
    this.currentSession.LeaveCash = tempLeaveCash;
  }

  executeUpdateSession() {
    this.sessionService.update(this.currentSession).subscribe(result => {
      if (result === true) {
        //this.tableEngine.loadData();
        //NEW
        this.sessionService.getByIdWithOrders(this.currentSession.Id).subscribe(newResult => {
          if (newResult === true || newResult.Id > 0) {
            this.currentSession = newResult;
            if(this.printOrder.Id>0){
              console.log(this.currentSession.Orders);
              console.log(this.printOrder);
              
              this.printOrder = this.currentSession.Orders[this.currentSession.Orders.length-1];
              console.log(this.printOrder);
              if(!this.tableView && !this.endingSession){
                this.showModal(this.printModal, '');
              }
            }
            this.currentAction = '';
            //this.tableEngine.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyUpdatedCashierSession"));
            if(this.endingSession){
              this.goBack();
              this._router.navigate(['app/aio/restaurant']);
            }
            else{
              this.endSessionModal.hide();
              this.orderStatusModal.hide();
            }
           } 
           else {
            this.tableEngine.toastError(this.translate.instant("Error"), this.translate.instant("FailedLoadingUpdate"));
            this.currentAction = '';
           }
        }
        );
      }
      else {
        this.endingSession = false;
        this.tableEngine.toastError(this.translate.instant("Error"), this.translate.instant("FailedUpdate"));
        this.currentAction = '';
      }
    })
  }

  ////////////////////////////////////////////////////////////////////////////
  //
  //
  //
  //
  //
  ////////////////////////////////////////////////////////////////////////////
  //  EXCLUSIVE :
  //  Specifically for this component.
  ///////////////////////////////
  //Get any additional data here

  prevAction = '';
  configPoS: ConfigPoSModel = new ConfigPoSModel();

  loadServices() {
    this.currentAction = 'loading';

    this.clientService.getAll().subscribe(
      clientData => {
        this.clientList = clientData;

        this.categoryService.getAll().subscribe(
          categoryData => {
            this.categoryList = categoryData;
            this.activeCategories =  this.activeCategories.concat([...categoryData]);
            console.log(this.activeCategories);
            

            this.itemService.getAll().subscribe(
              itemData => {
                this.itemList = itemData;
                // this.activeItems = [...itemData];

                this.paymentMethodService.getAll().subscribe(
                  paymentMethodData => {
                    this.paymentMethodList = paymentMethodData;
                    console.log('this.paymentMethodList',this.paymentMethodList)
                    this.configPoSService.getFirst().subscribe(
                      configPoSData => {
                        this.configPoS = configPoSData;
                        this.lastSessionCode = this.configPoS.StartingCodeSessions;
                        this.lastOrderCode = this.configPoS.StartingCodeOrders;
                        
                        this.tableEngine.loadData();
                        if(this.currentSession.Orders.length>0){
                          this.orderStatusReminderModal.config = new ModalOptions();
                          this.showModal(this.orderStatusReminderModal, 'Reminder');
                        }

                        this.tempClientId = this.configPoS.DefaultClientId;
                      }
                    )
                  }
                )
              }
            )
          }
        )
      }
    )


  }
  
  categoryList: RestaurantCategoryModel[] = [];
  itemList: RestaurantMenuItemModel[] = [];
  clientList: ClientModel[] = [];
  paymentMethodList: PaymentMethodModel [] = [];
  
  getItemById(Id: any): any {
    return this.itemList.find(i => i.Id === Id);
  }
  getCategoryById(Id: any): any {
    return this.categoryList.find(i => i.Id === Id);
  }
  getClientById(Id: any): any {
    return this.clientList.find(i => i.Id === Id);
  }

  currentTotalItemPrices = 0;
  currentTotalItemDiscounts = 0;
  currentTotalItemNetPrices = 0;
  currentTotalItemTaxes = 0;
  currentTotalItemGrossPrices = 0;
  currentTotalAfterCashierOrderDiscount = 0;
  currentTotalCashierOrderValue = 0;

  goBack(){
    console.log("back");
    
    this.modelObject = {};
    this.currentSession = new CashierSessionModel();
    this.sessionService.currentSession = new CashierSessionModel();
    this.currentOrder = new CashierOrderModel();

    this.currentTotalItemPrices = 0;
    this.currentTotalItemNetPrices = 0;
    this.currentTotalItemDiscounts = 0;
    this.currentTotalItemGrossPrices = 0;
    this.currentTotalItemTaxes = 0;
    this.currentTotalAfterCashierOrderDiscount = 0;
    this.currentTotalCashierOrderValue = 0;

    this.currentAction = "";
    this.tableView = true;

    
    console.log(this.modelObject);
    console.log(this.lastSessionCode);
  }

  tempNote = '';
  tempOrderNote = '';
  tempOrderStatus = '';
  setOrderItemNote(){
    this.currentItem.Note = this.tempNote;
    this.itemNoteModal.hide();
  }

  setOrderStatus(){
    let oldStatus = this.viewedOrder.Status;
    this.viewedOrder.Status = this.tempOrderStatus;
    this.calculateSessionTotal();
    //this.orderStatusModal.hide();
    this.viewedOrder.Status = oldStatus;
  }

  applyOrderStatusUpdate(){
    this.viewedOrder.Status = this.tempOrderStatus;
    this.calculateSessionTotal();
    this.cashierOrderService.update(this.viewedOrder).subscribe(result => {
      if (result === true || result > 0) {
        //this.tableEngine.loadData();
        //this.currentSession.Orders = this.currentSession.Orders.concat(this.currentOrder);
        this.calculateSessionTotal();

        this.tempPaidAmount = 0;
        this.tempChangeGiven = 0;

        let id = this.currentOrder.Id;
        this.currentOrder = new CashierOrderModel({Id:id+1});
        this.lastButton = '';
        this.lastMode = '';
        this.currentMode = 'Qty';
        //this.lastOrderCode += 1;

        this.tableEngine.toastSuccess(this.translate.instant("Success"), this.translate.instant("SuccessfullyUpdatedOrder"));

        this.orderModal.hide();
        //this.refundModal.hide();

        this.updateSession();
      }
      else {
        this.tableEngine.toastError(this.translate.instant("Error"), this.translate.instant("FailedUpdatingOrder"));
      }
    })
  }
  tempClientId = null;
  setOrderClient(){
    console.log(this.tempClientId);
    if(this.tempClientId>0){
      let client =  this.getClientById(this.tempClientId);
      this.currentOrder.ClientId = client.Id;
      this.currentOrder.ClientNameAr = client.NameAr;
      this.currentOrder.ClientNameEn = client.NameEn;
    }
    else{
      this.currentOrder.ClientId = 0;
    }
    this.clientModal.hide();
  }

  tempSessionEndCash = 
  { Amount:0, 
    Bill05:0, Bill025:0, Bill1:0, Bill5:0, Bill10:0, Bill20:0, Bill50:0, Bill100:0, Bill200:0,
    BillTotal:0};

  updateBills(){
    this.tempSessionEndCash.Bill05 = this.tempSessionEndCash.Bill05>0?this.tempSessionEndCash.Bill05:0;
    this.tempSessionEndCash.Bill025 = this.tempSessionEndCash.Bill025>0?this.tempSessionEndCash.Bill025:0;
    this.tempSessionEndCash.Bill1 = this.tempSessionEndCash.Bill1>0?this.tempSessionEndCash.Bill1:0;
    this.tempSessionEndCash.Bill5 = this.tempSessionEndCash.Bill5>0?this.tempSessionEndCash.Bill5:0;
    this.tempSessionEndCash.Bill10 = this.tempSessionEndCash.Bill10>0?this.tempSessionEndCash.Bill10:0;
    this.tempSessionEndCash.Bill20 = this.tempSessionEndCash.Bill20>0?this.tempSessionEndCash.Bill20:0;
    this.tempSessionEndCash.Bill50 = this.tempSessionEndCash.Bill50>0?this.tempSessionEndCash.Bill50:0;
    this.tempSessionEndCash.Bill100 = this.tempSessionEndCash.Bill100>0?this.tempSessionEndCash.Bill100:0;
    this.tempSessionEndCash.Bill200 = this.tempSessionEndCash.Bill200>0?this.tempSessionEndCash.Bill200:0;

    this.tempSessionEndCash.BillTotal = 
    this.tempSessionEndCash.Bill05 * 0.5 + this.tempSessionEndCash.Bill025 * 0.25 + 
    this.tempSessionEndCash.Bill1 + this.tempSessionEndCash.Bill5 * 5 +
    this.tempSessionEndCash.Bill10 * 10 + this.tempSessionEndCash.Bill20 * 20 + 
    this.tempSessionEndCash.Bill50 * 50 + this.tempSessionEndCash.Bill100 * 100 +
    this.tempSessionEndCash.Bill200 * 200;
    
    console.log("actual end cash", this.tempSessionEndCash.BillTotal);
    
  }

  tempPaidAmount = 0;
  tempChangeGiven = 0;
  checkValue(value:any, action:any){
    value = value>0?value:0;
    if(action=='CheckEndSession'){
      this.tempChangeGiven = this.tempPaidAmount-this.currentOrder.ItemTotalGrossPrices;
    }
   }

   openForExport = false;
   exportTable(){
     this.openForExport = true;
     // TableUtil.exportToPdf("ExampleTable");
     var data = document.getElementById('contentToConvert');
     data.style.display = 'inline-block';
       // html2canvas.useCORS = true;
     html2canvas(data,{}).then(canvas => {
     // Few necessary setting options
     var imgWidth = 208;
     var pageHeight = 295;
     var imgHeight = canvas.height * imgWidth / canvas.width;
     var heightLeft = imgHeight;
     const contentDataURL = canvas.toDataURL('image/png')
     let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
     var position = 0;
     pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
     pdf.save('new-file.pdf'); // Generated PDF
     data.style.display = 'none';
     
     this.printModal.hide();
   });
   }
   categoryId = 0;
changeCategory(){
  if(this.categoryId==0){
    this.activeItems = [...this.itemList]; 
  }
  else{
    let categoryItems: RestaurantMenuItemModel[] = this.itemList.filter(x=>x.CategoryId==this.categoryId);
    this.activeItems = [...categoryItems];
  }
}
activeItems : RestaurantMenuItemModel[] =  [];
activeCategories : RestaurantCategoryModel[] =  [{Id:0,Name:'All'}];
  //  exportTable(){
  //   var data = document.getElementById('printingTest');
  //   data.style.display = 'inline-block';
  //   html2canvas(data,{}).then(canvas => {
  //   var imgWidth = 208;
  //   var pageHeight = 295;
  //   var imgHeight = canvas.height * imgWidth / canvas.width;
  //   var heightLeft = imgHeight;
  //   const contentDataURL = canvas.toDataURL('image/png')
  //   let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
  //   var position = 0;
  //   pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
  //   pdf.save('new-file.pdf'); // Generated PDF
  //   data.style.display = 'none';
    
  // });
  // }
  ////////////////////////////////////////////////////////////////////////////
  //
  //
  //
  //
  //
  ////////////////////////////////////////////////////////////////////////////
  //  CONSTANT :
  //  Do not change.
  ///////////////////////////////
  //
  ///////////////////////////////////////////////////////
  // itemsPerPageChange(perPage: number): void {
  //   this.tableEngine.paginateData(perPage, 1)
  // }
}
